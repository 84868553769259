import { ServerCommands } from "./servercommands";
import { itemTypes } from "../enums/enums";
import { TDFRequest } from "../services/request";
import { GimmeGUID, DomSafeID } from "./allutils";
import { RaiseEvent } from "../infrastructure/events/ui_events";
import { EventTypes, eventNameSpace } from "../enums/webevents/enums";
import { Dialogs } from "../interfaces/dialogs";
import { Dialog } from "components/dialogs/dialog"
import * as LongJob from "../util/longjob"
import { LongJobManager } from "../admin/longjobmanager";
import { LoadCompany } from "../infrastructure/context";
import { ILongJob } from "../interfaces/interfaces";
import { confirm } from "devextreme/ui/dialog";


export module TDFMassUpdate {

  // Corresponds to the "options" of the MassUpdateService on the server.
  export interface MassUpdateOptions {
    // The list of fields to be changed. 
    Changes: ChangeSet[],
    // Whether or not to log our changes. 
    LogChanges: boolean,
    // For controls that are "list box controls" - indicates whether or not the values we select here should REPLACE or APPEND to the existing value.
    AppendListBoxValues: boolean
  }

  // The response we get from the server when "previewing" a selection
  export interface PreviewSelection {
    // The number of items that the selection represents.
    ItemCount: number,
    // The "selection" we are previewing; it is returned in the case that what we previewed in the first place was a "GridViewSelection".
    Selection: ServerCommands.TDFSelectionDescriptor
  }
  
  export class MassUpdateDataService {

    // Get UDF information about the item type we are updating.
    public GetFieldInfo(itemType: itemTypes) {
      return new  TDFRequest({
        url: `${TDFRequest.ApiPath}/commands/mass-update/field-info/${itemType}`
      });
    }

    // Gets a preview of the number of items that a selection contains.
    public Preview(selection: ServerCommands.TDFSelectionDescriptor) {
      return new TDFRequest({
        url: `${TDFRequest.ApiPath}/commands/preview-selection`, type: "POST", data: selection
      });
    }


    public PreviewFromGrid(selection: ServerCommands.GridSelectionInfo) {

      // Need add override settings, etc.
      let data = { GridViewID: selection.ViewGUID}

      return new TDFRequest({
        url: `${TDFRequest.ApiPath}/commands/preview-grid-selection`, type: "POST", data: data
      });
    }

    // Start a MassUpdate command on the server. A LongJob will be returned.
    public Initiate(selection: ServerCommands.TDFSelectionDescriptor, values: ChangeSet[]) {

      let cmd: ServerCommands.ServerCommand = {
        With: selection, 
        Apply: {
          Options: <MassUpdateOptions>{
            Changes: values, 
            AppendListBoxValues: true,
            LogChanges: false
          },
          Async: true
        }
      }

      return new TDFRequest({
        url: `${TDFRequest.ApiPath}/commands/mass-update`, type: "POST", data: cmd
      });

    }

    public GetSupportedItems() {
      return new TDFRequest({
        url: `${TDFRequest.ApiPath}/commands/mass-update/supported-items/`
      });
    }


  }

  // Represents a change to a single field.
  export interface ChangeSet {
    FieldName: string,
    NewValue: any
  }

  // Describes a UDF and its possible values/type.
  export interface FieldInfo {
    FieldName: string,
    ControlType: string
    Values: Array<{ Value: string, Display: String }>
  }
  
  export class MassUpdateDlg {

    // Indicates whether the "selection" that the dialog is working with should be expanded on the server.
    private expandSelection: boolean = false;
    
    private gridSelectionInfo: ServerCommands.GridSelectionInfo = null;
    
    private typeInfo: any[];

    private originalSimpleSelection: ServerCommands.TDFSelectionDescriptor;

    private fieldInfo: FieldInfo[];

    private dataService: MassUpdateDataService;

    private relevantItemType: itemTypes;

    private selection: ServerCommands.TDFSelectionDescriptor;

    // True if the dialog was launched from a grid; if so then that grid's selection is what we will work with.
    private launchedFromGrid: boolean = false;

    // A guid for this dialog; used when creating unique DOM ids.
    private guid: string;

    constructor(fromGrid:boolean, selection?: ServerCommands.TDFSelectionDescriptor) {

      let that = this;

      that.guid = GimmeGUID();

      that.launchedFromGrid = fromGrid;
      that.dataService = new MassUpdateDataService();

      // Save our original selection in case we want to revert to it.
      that.originalSimpleSelection = selection;

      // may or may not get a "simple selection".
      that.selection = selection;

      if (that.selection) {
        that.relevantItemType = that.selection.ItemType;
      }

        LoadCompany().done((e) => {
        that.dataService.GetSupportedItems().MakeRequest().done((e) => {
          that.typeInfo = e;
          if (!that.relevantItemType) {
            that.relevantItemType = itemTypes.itemAccount;
          }
          that.dataService.GetFieldInfo(that.relevantItemType).MakeRequest().done((e: FieldInfo[]) => {
            that.fieldInfo = e;
            that.ShowDialog()
          });
        })
      })

    }

    // Updates our button with the predicted number of affected items.
    public showCalculatedItemCount() {

      let that = this;

      if (that.launchedFromGrid === true) {

        // If our dialog was launched from a grid, then we'll need to ask that grid for its current selection.

        let gridSelection: ServerCommands.GridSelectionInfo = {
          ViewGUID: "",
          ClientFilter: "",
          SelectedItemCount: 0, 
          SelectedItems: []
          };

          RaiseEvent( EventTypes.GridEventTypes.ExpandSelection, eventNameSpace.request, gridSelection);

        // If the selected item count is equal to the user's "Client Side page Count" property,
        // then assume they likely want to expand the selection beyond.

        that.gridSelectionInfo = gridSelection;

        console.log(gridSelection);

        if (that.gridSelectionInfo && that.gridSelectionInfo.ViewGUID != "") {
            that.updatePreviewCount();
        } else if (that.selection) {
          that.updatePreviewCount()
        }
      
      }

    }

    private updatePreviewCount() {

      let that = this;

      let req: TDFRequest = null;

      if (that.expandSelection === true && that.gridSelectionInfo && that.gridSelectionInfo.ViewGUID != "") {
        req = that.dataService.PreviewFromGrid(that.gridSelectionInfo);
      } else if (that.selection) {
        req = that.dataService.Preview(that.selection);
      }

      if (req) {

        req.MakeRequest().done((e: PreviewSelection) => {

          console.log(that.selection);

          that.selection = e.Selection

          console.log("Adjusted Selection: ");
          console.log(that.selection);

          let btn = $(that.createElementId("#button-start-mass-update")).dxButton("instance");
          btn.option("text", "Update " + e.ItemCount + " items")
          btn.option("disabled", false);

        });

      }

    }

    private createElementId(id: string) {
      let that = this;
      return DomSafeID(id + "_" + that.guid);
    }

    private ShowDialog() {

      let that = this;
      let formData = {};
      let form: DevExpress.ui.dxForm;

      let fieldInfo = that.fieldInfo;

      let fieldCategories = ["A-B", "C-D", "E-F", "G-H", "I-J", "K-L", "M-N", "O-P", "Q-R", "S-T", "U-V", "W-X", "Y-Z"];

      let menuData = fieldCategories.map((val, i) => {

        return {
          text: val, 
          items: fieldInfo.filter((v) => {
            return v.FieldName.toUpperCase().startsWith(val[0], 0) || v.FieldName.toUpperCase().startsWith(val[2], 0);
          }).map((v) => {
            return {text: v.FieldName}
          })
        }

      });


      let gridData: ChangeSet[] = [];
                 
      let formOptions: DevExpress.ui.dxFormOptions = {
        formData: formData, 
        items: [
          {
            itemType: "simple",

            template: (e) => {
              return $("<div />").dxToolbar({
                items: [

                  // Select box for item Types (hidden)
                  <DevExpress.ui.dxPopupToolbarItem>{
                    itemType: "simple",
                    widget: "dxSelectBox",
                    location: "before",
                    visible: !that.launchedFromGrid,
                    options: {
                      dataSource: that.typeInfo, 
                      valueExpr: "TypeNum", 
                      displayExpr: "DisplayName"
                      
                    }
                  },

                  // Menu for adding fields to update
                  <DevExpress.ui.dxPopupToolbarItem>{
                    widget: "dxMenu",
                    location: "before",
                    options: {
                      closeMenuOnClick: false,
                      onItemClick: (e) => {
                        if (e.itemData.text !== "Available Fields") {

                          let grid = (form.getEditor("SourceGrid") as DevExpress.ui.dxDataGrid);
                          gridData = grid.option("dataSource");

                          if (!gridData) gridData = [];

                          let menuText: string = e.itemData.text;
                          menuText = menuText.split("(")[0].trim();

                          gridData.push( { "FieldName": menuText, NewValue: null })
                          grid.option("dataSource", gridData);

                        }
                        
                      },

                      dataSource: [
                        {
                          text: "Available Fields",
                          icon: "fa fa-plus",
                          items: menuData //   that.fieldInfo.map((v, i) => {
                            //return { text: `${v.FieldName} (${v.ControlType})` }
                          //})
                          
                        }
                      ]
                    }

                  },

                  // Button for edit filter (hidden)
                  <DevExpress.ui.dxPopupToolbarItem>{
                    widget: "dxButton",
                    location: "before",
                    visible: !that.launchedFromGrid,
                    options: {
                      text: "Edit Filter",
                      onClick: (e) => {
                        alert("Show dialog to edit current filter.");
                      }
                    }
                  },

                  // Menu for Selection Mode - Client or Server.
                  <DevExpress.ui.dxPopupToolbarItem>{
                    widget: "dxMenu",
                    location: "before",
                    
                    options: {
                      elementAttr: { id: that.createElementId("mnu-selection-options")},
                      closeMenuOnClick: false,

                      onItemClick: (e) => {

                        if (e.itemData.text === "Client Selection Only") {
                          that.expandSelection = false;
                          that.selection = that.originalSimpleSelection;
                          // This line doesn't work?
                          $(that.createElementId("#mnu-selection-options")).dxMenu("instance").option("text", "Selection: CLIENT");
                          that.updatePreviewCount();
                        } else if (e.itemData.text === "Expand Selection on Server") {
                          that.expandSelection = true;
                          // This line doesn't work?
                          $(that.createElementId("#mnu-selection-options")).dxMenu("instance").option("text", "Selection: SERVER");
                          that.updatePreviewCount();
                        }

                      },

                      dataSource: [
                        {
                          text: "Selection: CLIENT",
                          icon: "fa fa-server",
                          items: [
                            { text: "Client Selection Only" },
                            { text: "Expand Selection on Server" }
                          ]
                        }
                      ]
                    }

                  }

                ]
              })
            }
          },

          {
            itemType: "simple", 
            editorType: "dxDataGrid", 
            dataField: "SourceGrid", 
            label: {
              text: "Fields to update",
              location: "top"
            },

            editorOptions: <DevExpress.ui.dxDataGridOptions>{

              noDataText: `Add one or more fields to update using the "Available Fields" menu.`,

              editing: {
                allowUpdating: true,
                mode: 'cell',
                allowDeleting: true,
                useIcons: true,
                texts: {
                  confirmDeleteMessage: ""
                }
              }, 

              columns: <DevExpress.ui.dxDataGridColumn>[
                {
                  dataField: "FieldName",

                  lookup: {
                    dataSource: that.fieldInfo,
                    displayExpr: "FieldName",
                    valueExpr: "FieldName"
                  }
                }, 
                <DevExpress.ui.dxDataGridColumn>{
                  dataField: "NewValue",
                  caption: "New Value",

                  editorOptions: <DevExpress.ui.dxDataGridOptions> {
                    editing: {
                      allowUpdating: true, 
                      mode: "cell"
                    } 
                  },

                  editCellTemplate: (cellElement, cellInfo) => {
                    
                    let field = cellInfo.data.FieldName;
                    let matches = fieldInfo.filter((val, index) => {
                      return (val.FieldName === field);
                    })

                    if (matches.length > 0) {

                      if (matches[0].ControlType === "controlDateTime") {
                        cellElement.append($("<div />").dxDateBox(
                          {
                            onValueChanged: (e) => {
                              cellInfo.setValue(e.value, e.value);
                            }
                          }
                        ));


                      } else if (matches[0].ControlType == "controlListBox") {

                        cellElement.append($("<div />").dxList(
                          <DevExpress.ui.dxListOptions>{
                            dataSource: matches[0].Values.map((e) => { return e.Value; }), 
                            selectionMode: "multiple",
                           
                            onSelectionChanged: (e) => {

                              let selected: string[] = [];

                              if (cellInfo.value) selected = cellInfo.value.split(", ");

                              let added = e.addedItems;

                              selected = selected.concat(e.addedItems);
                                                           
                              if (e.removedItems.length > 0) {
                                selected = selected.filter((v, i) => {
                                  return (e.removedItems.indexOf(v) === -1);
                                });
                              }

                              // TODO: ENsure unique values.
                              let val = selected.sort().join(", ");
                              cellInfo.setValue(val, val);
                              
                            }


                            //valueExpr: "Value",
                            //displayExpr: "Display",
                            //onValueChanged: (e) => {
                            //  cellInfo.setValue(e.value, e.value);
                            //}
                          }
                        ));

                        //console.log("Need list box control");
                        

                      } else if (matches[0].ControlType === "controlCheckbox") {

                        cellElement.append($("<div />").dxCheckBox(
                          {
                            onValueChanged: (e) => {
                              cellInfo.setValue(e.value, e.value);
                            }
                          }
                        ));

                      } else if (matches[0].ControlType === "controlMemoBox") {

                        cellElement.append($("<div />").dxTextArea(
                          {
                            onValueChanged: (e) => {
                              cellInfo.setValue(e.value, e.value);
                            }
                          }
                        ));


                      } else if (matches[0].ControlType === "controlTextBox") {

                        cellElement.append($("<div />").dxTextBox(
                          {
                            onValueChanged: (e) => {
                              cellInfo.setValue(e.value, e.value);
                            }
                          }
                        ));

                      } else if (matches[0].Values.length > 0) {

                        cellElement.append($("<div />").dxSelectBox(
                          {
                            dataSource: matches[0].Values,
                            valueExpr: "Value",
                            displayExpr: "Display",
                            onValueChanged: (e) => {
                              cellInfo.setValue(e.value, e.value);
                            }
                          }
                        ));
                      }
                    }


                    
                  }

                }

              ], 
              dataSource: gridData
                            
            }
           
          },

          {
            itemType: "button",
            alignment: "center",
            buttonOptions: {
              disabled: true,
              elementAttr: { id: that.createElementId("button-start-mass-update")},
              text: "Update",
              icon: "fa fa-play",
              onClick: (e) => {

                let grid = <DevExpress.ui.dxDataGrid>form.getEditor("SourceGrid");
                let values = <ChangeSet[]>grid.option("dataSource");

                if (values.length === 0) return;

                if (values.filter((v, i) => {
                  return (v.FieldName === "");
                }).length > 0) {
                  return;
                }

                confirm("Are you sure you want to mass update the selected items?", "Please confirm.").done((e) => {
                  if (e) {

                    
                    that.dataService.Initiate(that.selection, values).MakeRequest().done((j: ILongJob) => {

                      let jobMgr = new LongJobManager();
                      jobMgr.showJobProgress(j, "Performing mass update...");

                    });
                  }
                });
              }
            }

          }
        ]
      }
    
      form = $("<div />").dxForm(formOptions).dxForm("instance");

      let dlg = new Dialog({
        body: form.element(),
        type: "type-primary",
        size: "size-normal",
        title: "Mass Update",
        closable: true,
        modal: "no"
      }
      );

      dlg.open().done((e) => {
        that.showCalculatedItemCount();
      });

    }

  }

}