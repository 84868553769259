export class QuoteResultForm {
  static readonly groupkeys = [
    "AdditionalDiscountGroup",
    "BaseTaxGroup",
    "SurTaxGroup",
    "FreightGroup",
    "TotalsGroup",
    "MaintenanceGroup"
  ];
  static readonly CurrencyFields = [
    "AdditionalDiscountTotal",
    "BaseTaxTotal",
    "DepositTotal",
    "ExtendedTotalTotal",
    "FreightTotal",
    "GrossProfitTotal",
    "SubTotalAfterDiscountTotal",
    "SubTotalTotal",
    "SurTaxTotal",
    "TotalCost",
    "TotalMinusDepositTotal",
    "GlobalDiscountAmount",
    "FreightInbound",
    "FreightOutbound",
    "SurTaxRateMaximum"
  ];
  static readonly PercentageFields = [
    "GrossProfitPercentTotal",
    "BaseTaxRate",
    "GlobalDiscountRate",
    "SurTaxRate"
  ];
  // #region AdditionalDiscountGroup
  static GlobalDiscountRate: DevExpress.ui.dxFormSimpleItem | any = {
    dataField: "UserProperties.GlobalDiscountRate",
    editorType: "dxNumberBox",
    label: { text: "Rate" },
    visible: true,
    editorOptions: {
      valueChangeEvent: "blur",
      //onValueChanged: QuotePropertyChanged,
      min: 0,
      max: 100,
      step: 1,
      mode: "number",
      showSpinButtons: false
    },
    ChangeAction: "QuotePropertyChanged",
    IsRateField: true,
    visibleIndex: 0
  };
  static GlobalDiscountAmount: DevExpress.ui.dxFormSimpleItem | any = {
    dataField: "UserProperties.GlobalDiscountAmount",
    editorType: "dxTextBox",
    label: { text: "Amount" },
    visible: true,
    editorOptions: {
      valueChangeEvent: "blur"
    },
    ChangeAction: "QuotePropertyChanged",
    IsRateField: false,
    visibleIndex: 1
  };
  static SubTotalTotal: DevExpress.ui.dxFormSimpleItem = {
    dataField: "Results.SubTotalTotal",
    editorType: "dxTextBox",
    label: { text: "SubTotal" },
    visible: true,
    editorOptions: {
      valueChangeEvent: "blur",
      disabled: false,
      focusStateEnabled: true
    },
    visibleIndex: 2
  };
  static AdditionalDiscountTotal: DevExpress.ui.dxFormSimpleItem | any = {
    dataField: "Results.AdditionalDiscountTotal",
    editorType: "dxTextBox",
    label: { text: "Additional Discount" },
    visible: true,
    editorOptions: {
      valueChangeEvent: "blur",
      onValueChanged: function(e) {},
      disabled: true
    },
    ApplyCalculation:
      "{{fromDataVariable}}[Results.AdditionalDiscountTotal] = {{fromDataVariable}}[Results.GlobalDiscountRate] * {{fromDataVariable}}[Results.SubTotalToal]"
  };
  static AdditionalDiscountGroupSpacer: DevExpress.ui.dxFormEmptyItem = {
    itemType: "empty",
    colSpan: 2,
    visibleIndex: 3
  };
  static AdditionalDiscountGroup: DevExpress.ui.dxFormGroupItem = {
    itemType: "group",
    caption: "Additional Discount",
    items: [
      QuoteResultForm.GlobalDiscountRate,
      QuoteResultForm.GlobalDiscountAmount,
      QuoteResultForm.SubTotalTotal,
      QuoteResultForm.AdditionalDiscountGroupSpacer,
      QuoteResultForm.AdditionalDiscountTotal
    ],
    visible: true,
    visibleIndex: 0,
    colCountByScreen: {
      lg: 3,
      md: 3,
      sm: 2,
      xs: 1
    },
    cssClass: "quoteformgroup"
  };
  // #endregion
  // #region BaseTaxGroup
  static BaseTaxRate: DevExpress.ui.dxFormSimpleItem | any = {
    dataField: "UserProperties.BaseTaxRate",
    editorType: "dxNumberBox",
    label: { text: "Rate" },
    visible: true,
    editorOptions: {
      valueChangeEvent: "blur",
      min: 0,
      max: 100,
      step: 1,
      mode: "number",
      showSpinButtons: false
    },
    ChangeAction: "QuotePropertyChanged",
    IsRateField: true,
    visibleIndex: 0
  };
  static SubTotalAfterDiscountTotal: DevExpress.ui.dxFormSimpleItem | any = {
    dataField: "Results.SubTotalAfterDiscountTotal",
    editorType: "dxTextBox",
    label: { text: "SubTotal After Discount" },
    visible: true,
    editorOptions: {
      valueChangeEvent: "blur",
      onValueChanged: function(e) {},
      disabled: true
    },
    visibleIndex: 2
  };
  static BaseTaxTotal: DevExpress.ui.dxFormSimpleItem | any = {
    dataField: "Results.BaseTaxTotal",
    editorType: "dxTextBox",
    label: { text: "Base Tax" },
    visible: true,
    editorOptions: {
      valueChangeEvent: "blur",
      onValueChanged: function(e) {},
      disabled: true
    },
    visibleIndex: 4,
    ApplyCalculation:
      "{{fromDataVariable}}Results.BaseTaxTotal = {{fromDataVariable}}[UserProperties.BaseTaxRate] * [UserProperties.SubTotalAfterDiscountTotal] "
  };
  static BaseTaxGroup: DevExpress.ui.dxFormGroupItem = {
    itemType: "group",
    caption: "Base Tax",
    items: [
      QuoteResultForm.BaseTaxRate,
      {
        itemType: "empty",
        colSpan: 1,
        visibleIndex: 1
      },
      QuoteResultForm.SubTotalAfterDiscountTotal,
      {
        itemType: "empty",
        colSpan: 2,
        visibleIndex: 3
      },
      QuoteResultForm.BaseTaxTotal
    ],
    visible: true,
    visibleIndex: 1,
    colCountByScreen: {
      lg: 3,
      md: 3,
      sm: 2,
      xs: 1
    },
    cssClass: "quoteformgroup"
  };
  // #endregion
  // #region SurTaxGroup
  static SurTaxRate: DevExpress.ui.dxFormSimpleItem | any = {
    dataField: "UserProperties.SurTaxRate",
    editorType: "dxNumberBox",
    label: { text: "Rate" },
    visible: true,
    editorOptions: {
      valueChangeEvent: "blur",
      min: 0,
      max: 100,
      step: 1,
      mode: "number",
      showSpinButtons: false
    },
    ChangeAction: "QuotePropertyChanged",
    IsRateField: true,
    visibleIndex: 0
  };
  static SurTaxRateMaximum: DevExpress.ui.dxFormSimpleItem | any = {
    dataField: "UserProperties.SurTaxRateMaximum",
    editorType: "dxTextBox",
    label: { text: "Maximum" },
    visible: true,
    editorOptions: {
      valueChangeEvent: "blur",
      onValueChanged: function(e) {}
    },
    ChangeAction: "QuotePropertyChanged",
    IsRateField: false,
    visibleIndex: 1
  };
  static SurTaxTotal: DevExpress.ui.dxFormSimpleItem | any = {
    dataField: "Results.SurTaxTotal",
    editorType: "dxTextBox",
    label: { text: "Surtax" },
    visible: true,
    editorOptions: {
      valueChangeEvent: "blur",
      disabled: true
    },
    visibleIndex: 2,
    ApplyCalculation:
      "{{fromDataVariable}}[Results.SurTaxTotal] = {{fromDataVariable}}[UserProperties.SurTaxRate] * {{fromDataVariable}}[UserProperties.SurTaxRateMaximum] "
  };
  static SurTaxGroupSpacer: DevExpress.ui.dxFormEmptyItem = {
    itemType: "empty",
    colSpan: 1
  };
  static SurTaxGroup: DevExpress.ui.dxFormGroupItem = {
    itemType: "group",
    caption: "SurTax",
    items: [
      QuoteResultForm.SurTaxRate,
      QuoteResultForm.SurTaxRateMaximum,
      QuoteResultForm.SurTaxTotal
    ],
    visible: true,
    visibleIndex: 2,
    colCountByScreen: {
      lg: 3,
      md: 3,
      sm: 2,
      xs: 1
    },
    cssClass: "quoteformgroup"
  };
  // #endregion
  // #region FreightGroup
  static FreightInbound: DevExpress.ui.dxFormSimpleItem | any = {
    dataField: "UserProperties.FreightInbound",
    editorType: "dxTextBox",
    label: { text: "Inbound" },
    visible: true,
    editorOptions: {
      valueChangeEvent: "blur",
      onValueChanged: function(e) {}
    },
    visibleIndex: 0,
    ChangeAction: "UpdateFreight",
    IsRateField: false
  };
  static FreightOutbound: DevExpress.ui.dxFormSimpleItem | any = {
    dataField: "UserProperties.FreightOutbound",
    editorType: "dxTextBox",
    label: { text: "OutBound" },
    visible: true,
    editorOptions: {
      valueChangeEvent: "blur",
      onValueChanged: function(e) {}
    },
    visibleIndex: 1,
    ChangeAction: "UpdateFreight",
    IsRateField: false
  };
  static FreightTotal: DevExpress.ui.dxFormSimpleItem | any = {
    dataField: "Results.FreightTotal",
    editorType: "dxTextBox",
    label: { text: "Freight" },
    visible: true,
    editorOptions: {
      valueChangeEvent: "blur",
      onValueChanged: function(e) {},
      disabled: true
    },
    visibleIndex: 2
  };
  static FreightGroupSpacer: DevExpress.ui.dxFormEmptyItem = {
    itemType: "empty",
    colSpan: 1
  };
  static FreightGroup: DevExpress.ui.dxFormGroupItem = {
    itemType: "group",
    caption: "Freight",
    items: [
      QuoteResultForm.FreightInbound,
      QuoteResultForm.FreightOutbound,
      QuoteResultForm.FreightTotal
    ],
    visible: true,
    visibleIndex: 3,
    colCountByScreen: {
      lg: 3,
      md: 3,
      sm: 2,
      xs: 1
    },
    cssClass: "quoteformgroup"
  };
  // #endregion
  // #region TotalsGroup
  static TotalCost: DevExpress.ui.dxFormSimpleItem = {
    dataField: "Results.TotalCost",
    editorType: "dxTextBox",
    label: { text: "Total Cost" },
    visible: true,
    editorOptions: {
      valueChangeEvent: "blur",
      onValueChanged: function(e) {},
      disabled: true
    },
    visibleIndex: 0
  };
  static Deposit: DevExpress.ui.dxFormSimpleItem | any = {
    dataField: "UserProperties.Deposit",
    editorType: "dxTextBox",
    label: { text: "Deposit" },
    visible: true,
    editorOptions: {
      valueChangeEvent: "blur",
      onValueChanged: function(e) {}
    },
    ChangeAction: "QuotePropertyChanged",
    IsRateField: false,
    visibleIndex: 2
  };
  static ExtendedTotalTotal: DevExpress.ui.dxFormSimpleItem | any = {
    dataField: "Results.ExtendedTotalTotal",
    editorType: "dxTextBox",
    label: { text: "Extended Total" },
    visible: true,
    editorOptions: {
      valueChangeEvent: "blur",
      onValueChanged: function(e) {},
      disabled: true
    },
    visibleIndex: 3
  };
  static GrossProfitTotal: DevExpress.ui.dxFormSimpleItem = {
    dataField: "Results.GrossProfitTotal",
    editorType: "dxTextBox",
    label: { text: "Gross Profit" },
    visible: true,
    editorOptions: {
      valueChangeEvent: "blur",
      onValueChanged: function(e) {},
      disabled: true
    },
    visibleIndex: 4
  };
  static DepositTotal: DevExpress.ui.dxFormSimpleItem | any = {
    dataField: "Results.DepositTotal",
    editorType: "dxTextBox",
    label: { text: "Deposit" },
    visible: true,
    editorOptions: {
      valueChangeEvent: "blur",
      onValueChanged: function(e) {},
      disabled: true
    },
    ChangeAction: "TotalChanged",
    visibleIndex: 6
  };
  static GrossProfitPercentTotal: DevExpress.ui.dxFormSimpleItem = {
    dataField: "Results.GrossProfitPercentTotal",
    editorType: "dxSlider",
    label: { text: "Gross Profit Percent" },
    visible: true,
    editorOptions: {
      valueChangeEvent: "blur",
      onValueChanged: function(e) {},
      min: 0,
      max: 100,
      rtlEnabled: false,
      tooltip: {
        enabled: true,
        format: function(value) {
          return value + "%";
        },
        showMode: "always",
        position: "bottom"
      },
      disabled: true
    },
    visibleIndex: 7
  };
  static TotalMinusDepositTotal: DevExpress.ui.dxFormSimpleItem = {
    dataField: "Results.TotalMinusDepositTotal",
    editorType: "dxTextBox",
    label: { text: "Total Minus Deposit" },
    visible: true,
    editorOptions: {
      valueChangeEvent: "blur",
      onValueChanged: function(e) {},
      disabled: true
    },
    visibleIndex: 9
  };
  static TotalsGroup: DevExpress.ui.dxFormGroupItem = {
    itemType: "group",
    caption: "-",
    items: [
      QuoteResultForm.TotalCost,
      {
        itemType: "empty",
        colSpan: 1,
        visibleIndex: 1
      },
      QuoteResultForm.Deposit,
      QuoteResultForm.ExtendedTotalTotal,
      QuoteResultForm.GrossProfitTotal,
      {
        itemType: "empty",
        colSpan: 2,
        visibleIndex: 5
      },
      QuoteResultForm.DepositTotal,
      QuoteResultForm.GrossProfitPercentTotal,
      {
        itemType: "empty",
        colSpan: 2,
        visibleIndex: 8
      },
      QuoteResultForm.TotalMinusDepositTotal
    ],
    visible: true,
    visibleIndex: 4,
    colCountByScreen: {
      lg: 4,
      md: 3,
      sm: 2,
      xs: 1
    },
    cssClass: "quoteformgroup"
  };
  // #endregion
  // #region MaintenanceGroup
  static RecurringMaintenance: DevExpress.ui.dxFormSimpleItem = {
    dataField: "Results.RecurringMaintenance",
    editorType: "dxCheckBox",
    label: { text: "Recurring Maintenance" },
    visible: false,
    editorOptions: {
      valueChangeEvent: "blur"
    }
  };
  static IncludeMaintenanceInFinancePlan: DevExpress.ui.dxFormSimpleItem = {
    dataField: "Results.IncludeMaintenanceInFinancePlan",
    editorType: "dxCheckBox",
    label: { text: "Include Maintenance In Finance Plan" },
    visible: false,
    editorOptions: {
      valueChangeEvent: "blur"
    }
  };
  static MaintenanceGroup: DevExpress.ui.dxFormGroupItem = {
    itemType: "group",
    caption: "Maintenance Options",
    items: [
      QuoteResultForm.RecurringMaintenance,
      QuoteResultForm.IncludeMaintenanceInFinancePlan
    ],
    visible: false,
    visibleIndex: 4,
    colCountByScreen: {
      lg: 3,
      md: 3,
      sm: 2,
      xs: 1
    }
  };
}
