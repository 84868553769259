import { RaiseEvent2 } from "../../../../infrastructure/events/ui_events";
import { eventNameSpace, EventTypes } from "../../../../enums/webevents/enums";
import { ToolbarTemplateFactory } from "./toolbartemplatefactory";

export function ViewButton(args) {
  let centerName: string = "";

  if (args && args.length) {
    args.map((v, k) => {
      if ($.isPlainObject(v)) {
        Object.keys(v).forEach((val, key) => {
          if (val.toLowerCase() === "uniquecentername") {
            centerName = v[val];
          }
        });
      }
    });
  }
  return ToolbarTemplateFactory(
    "before",
    "never",
    false,
    <DevExpress.ui.dxButtonOptions>{
      icon: "dx-icon icon-window",
      text: "Views",
      //   type:"warning",
      onClick(e) {
        RaiseEvent2(
          EventTypes.CenterEventTypes.itemviews,
          centerName,
          eventNameSpace.request,
          e.element
        );
      }
    },
    "dxButton",
    null,
    false
  );
}
