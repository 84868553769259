import { RaiseEvent2 } from "../../../../infrastructure/events/ui_events";
import { eventNameSpace, EventTypes } from "../../../../enums/webevents/enums";
import { ToolbarTemplateFactory } from "./toolbartemplatefactory";

export function BIDataCenterSummarySelector(args, config?) {
  let centerName: string = "";
  if (args && args.length) {
    args.map((v, k) => {
      if ($.isPlainObject(v)) {
        Object.keys(v).forEach((val, key) => {
          if (val.toLowerCase() === "uniquecentername") {
            centerName = v[val];
          }
        });
      }
    });
  }

  return ToolbarTemplateFactory(
    "before",
    "never",
    false,
    {
      type: "info",
      text: "Summary Settings",
      onClick: () => {
        RaiseEvent2(
          EventTypes.BIEventTypes.DisplayBISummarySelection,
          centerName,
          eventNameSpace.request
        );
      },
      width: '120px'
    },
    "dxButton",
    null,
    true,
    "always"
  );
}
