import { RaiseEvent2 } from "../../../../infrastructure/events/ui_events";
import { eventNameSpace, EventTypes } from "../../../../enums/webevents/enums";
import { ToolbarTemplateFactory } from "./toolbartemplatefactory";
import { IToolbarItemOverrideOptions } from "interfaces/grids/interfaces";
import { Debounce } from "util/allutils";

export function OptionsButton(args, config?: IToolbarItemOverrideOptions) {
  let centerName: string = "";

  if (args && args.length) {
    args.map((v, k) => {
      if ($.isPlainObject(v)) {
        Object.keys(v).forEach((val, key) => {
          if (val.toLowerCase() === "uniquecentername") {
            centerName = v[val];
          }
        });
      }
    });
  }

  return ToolbarTemplateFactory(
    config && config.location ? config.location : "after",
    config && config.locateInMenu ? config.locateInMenu : "auto",
    false,
    <DevExpress.ui.dxButtonOptions>{
      text: "Options",
      icon: "dx-icon fa fa-cogs",
      onClick: Debounce(
        (e) => {
          RaiseEvent2(
            EventTypes.CenterEventTypes.optionsforgridtype,
            centerName,
            eventNameSpace.request,
            null
          );
        },
        250,
        true
      )
    },
    "dxButton"
  );
}
