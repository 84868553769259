import {
  ByorReportList,
  ByorReportListTileSettings,
  ByorReportSelection
} from '../tiles-general/byorreports';
import { itemAccountTypes, itemTypes, tdfRights } from '../../enums/enums';
import { LeadForensicsDataCenter } from '../../integrations/leadforensics';
import { ItemListingNameModel } from '../../interfaces/interfaces';
import { ItemBase } from './itembase';
//import { BiVendorCenter } from '../grids/bi/legacy/legacybi';
import { DomSafeID } from '../../util/allutils';
import { DisplayInfo, DoesUserHaveRight } from '../../infrastructure/context';
import { AcctBIDataCenter } from '../../datacenters/bidatacenters/bi_data_center_acct';
import { VendorBIDataCenter } from '../../datacenters/bidatacenters/bi_data_center_vendor';
import { GetCompanyPreference } from 'infrastructure/user/getpreference';
import { Preferences } from 'infrastructure/user/preferences';

export class Account extends ItemBase {
  public DisplayName: string;
  get TypeName() {
    return 'Account';
  }
  set TypeName(name) {
    if (name && this.TypeName !== name) {
      this.TypeName = name;
    }
  }
  get ItemType() {
    return itemTypes.itemAccount;
  }
  get RequiresParentItem(): boolean {
    return false;
  }

  constructor(args) {
    super(args);
    const theAccount = this;
    $.each(args, function (key, val) {
      if (theAccount.hasOwnProperty(key)) {
        theAccount[key] = val;
      }
    });

    if (!theAccount.DisplayName) {
      let displayinfo;
      DisplayInfo(theAccount.ItemType).done(function (response) {
        displayinfo = response;
        theAccount.DisplayName = displayinfo.DisplayName;
        theAccount.TypeName = displayinfo.TypeName;
      });
    }
  }

  public VendorBI() { }
  public Save() {
    const theAccount = this;
    const d = $.Deferred();

    super.Save().done(d.resolve);

    return d.promise(theAccount);
  }

  public RenderCustomTab(itemData, container, dfd) {
    const theAccount = this;
    super.RenderCustomTab(itemData, container, dfd);
    if (itemData.ControlGroupID === 'BYOR_Reports') {
      theAccount.RenderCustomTab_ByorReports(itemData, container);
    } else if (itemData.ControlGroupID === 'SalesData') {
      if (parseInt(GetCompanyPreference('ReplaceBI', 'MitsIntegration', '0'))) {
        import('../../integrations/mits/mitscontainer')
          .then(mod => {
            container.height(() => {
              return $(window).innerHeight() - $("#tdffooter").innerHeight() - 175;
            });

            new mod.MitsContainer(
              container,
              Preferences.GetCompanyPreference('AcctBIDashPath', 'MitsIntegration', '')
            ).Init();
          })
          .catch(ex => {
            console.log(ex);
          });
      } else {
        if (theAccount.iMain.ItemAccountType === itemAccountTypes.Vendor) {
          theAccount.RenderCustomTab_VendorData(itemData, container);
        } else {
          theAccount.RenderCustomTab_SalesData(itemData, container);
        }
      }
    }
    dfd.resolve();
  }

  public RenderCustomTab_ByorReports(itemData, container) {
    const theAccount = this;
    const tabContentID =
      'byor_accordion_' + theAccount.ItemID.replace('{', '').replace('}', '');
    const groupElem = container.append(
      $("<div id='" + tabContentID + "'></div>")
    );

    const reportListOptions = new ByorReportListTileSettings();
    reportListOptions.ReportSelectionCriteria =
      ByorReportSelection.WithoutAutoBSID;
    reportListOptions.Bsid = theAccount.ItemID;
    reportListOptions.AccordianWidth = '500px';

    const reportListRenderer = new ByorReportList(
      'byor_customTab_' + DomSafeID(theAccount.ItemID),
      reportListOptions
    );
    reportListRenderer.RenderTile($(`#${tabContentID}`));
  }

  public RenderCustomTab_SalesData(itemData, container) {
    const theAccount = this;
    const customName = DomSafeID(theAccount.ItemID);
    const biCenterName = 'AccountBi_' + customName;

    // Frame for the Center
    const html = $("<div id='" + biCenterName + "'>").height(() => {
      return $(window).innerHeight() - $("#tdffooter").innerHeight() - 175;
    });

    container.append(html);

    const itemListNameModel: ItemListingNameModel = { ItemListWithNames: [] };
    itemListNameModel.ItemListWithNames.push({
      ID: theAccount.ItemID,
      Name: theAccount.iMain.ItemSubject
    });

    let biCenter = new AcctBIDataCenter(
      theAccount.ItemID,
      itemListNameModel,
      { MainAreaContainer: html },
      biCenterName
    );
    biCenter.InitAndRender();
  }

  public RenderCustomTab_VendorData(itemData, container) {
    const theAccount = this;
    const customName = DomSafeID(theAccount.ItemID);
    const biCenterName = 'VendorBi_' + customName;

    // Frame for the Center
    const html = $("<div id='" + biCenterName + "'>").height(() => {
      return $(window).innerHeight() - $("#tdffooter").innerHeight() - 175;
    });
    container.append(html);

    const itemListNameModel: ItemListingNameModel = { ItemListWithNames: [] };
    itemListNameModel.ItemListWithNames.push({
      ID: theAccount.ItemID,
      Name: theAccount.iMain.ItemSubject
    });
    let biVendorCenter = new VendorBIDataCenter(
      itemListNameModel,
      { MainAreaContainer: html },
      biCenterName
    );
    biVendorCenter.InitAndRender();
  }

  public ToolbarItems() {
    const theAccount = this;
    super.ToolbarItems();
    if (DoesUserHaveRight(tdfRights.rightViewLeadForensics)) {
      theAccount.DefaultToolbarButtons.push(theAccount.btnLeadForensics());
    }

    return theAccount.DefaultToolbarButtons;
  }

  public btnLeadForensics(): DevExpress.ui.dxToolbarItemTemplate {
    const theAccount = this;

    return {
      location: 'before',
      locateInMenu: 'auto',
      showText: 'inMenu',
      options: {
        icon: 'info',
        hint: 'View visits (provided by Lead Forensics)',
        text: 'Lead Forensics',
        type: 'normal',
        visible: true,
        onClick() {
          if (theAccount.isNew) return;
          theAccount.RenderLeadForensicsVisits();
        }
      },
      widget: 'dxButton'
    };
  }

  public RenderLeadForensicsVisits() {
    const theAccount = this;
    const lf = new LeadForensicsDataCenter(theAccount.ItemID);
    lf.ViewVisitsForAccount();
  }
}
