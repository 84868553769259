import { CenterType } from "../../../../enums/enums";
import { DataScopeSelection } from "../../../dashboards/dynamicdatascopeselector";
import { AddHandler2, RaiseEvent2 } from "../../../../infrastructure/events/ui_events";
import { eventNameSpace, EventTypes } from "../../../../enums/webevents/enums";
import { ToolbarTemplateFactory } from "./toolbartemplatefactory";
import { IToolbarItemOverrideOptions } from "interfaces/grids/interfaces";

export class BITagCloud {
  private _container: JQuery;
  private _centerType: CenterType;
  private _SummarySettings: DataScopeSelection;
  private EventLimiterName: string = "";
  constructor(container, centerType, eventLimiterName: string) {
    this._container = container;
    this._centerType = centerType;
    this.EventLimiterName = eventLimiterName;
    this.AddListeners();
    this.init();
  }

  AddListeners() {
    let tagCloud = this;
    AddHandler2(
      EventTypes.BIEventTypes.currentsummaryselection,
      tagCloud.EventLimiterName,
      eventNameSpace.notify,
      this._container,
      this.UpdateSummarySettings.bind(this)
    );

    AddHandler2(
      EventTypes.BIEventTypes.SummarySelectionNamesUpdated,
      tagCloud.EventLimiterName,
      eventNameSpace.notify,
      this._container,
      this.UpdateSummarySettings.bind(this)
    );
  }

  private UpdateSummarySettings(e: JQueryEventObject, data) {
    let tagCloud = this;
    tagCloud._SummarySettings = data.NewSummarySettings;
    tagCloud.RefreshTagCloud();
  }

  init() {
    let tagCloud = this;
    let sendDFD: JQueryDeferred<DataScopeSelection> = $.Deferred();

    RaiseEvent2(
      EventTypes.BIEventTypes.currentsummaryselection,
      tagCloud.EventLimiterName,
      eventNameSpace.request,
      { deferred: sendDFD }
    );

    sendDFD.done(function (scopeSettings) {
      tagCloud._SummarySettings = scopeSettings;
      tagCloud.RefreshTagCloud();
    });
  }

  RefreshTagCloud() {
    let tagCloud = this;

    tagCloud._container.empty();

    let theItems: any[] = tagCloud._SummarySettings.CurrentlySelectedItemsAsArray();

    $.each(theItems, function (index, item) {
      let name = (item.Name != "") ? item.Name : item.ItemID;

      let tag = $(`<span>`)
        .text(name)
        .addClass('badge dialog-info')
        .css('margin', '1px')
        .attr({
          'data-folderid': item.ItemID,
          'data-name': name
        });

      tagCloud._container.append(tag);

      if (index === 5) {
        tag.text(`${theItems.length - 5} More...`);

      } else if (index > 5) {
        tag.css('display', 'none');
      }
    });
  }
}

export function BIDCTagCloud(args, config?: IToolbarItemOverrideOptions) {
  let centerType = CenterType.Bi;
  let centerName: string = "";

  if (args && args.length) {
    args.map((v, k) => {
      if ($.isPlainObject(v)) {
        Object.keys(v).forEach((val, key) => {
          if (val.toLowerCase() === "centertype") {
            centerType = v[val];
          } else if (val.toLowerCase() === "uniquecentername") {
            centerName = v[val];
          }
        });
      }
    });
  }

  let itemTemplateFunct = function (itemData, itemIndex, itemElement) {
    let tagCloudID = "biTagCloud" + "_" + centerName;
    let tagCloudContainer = $(`<div id='${tagCloudID}' />`); //$(`#${theBICenter.CenterContainerID} #${tagCloudID}`)
    itemElement.append(tagCloudContainer);

    new BITagCloud(tagCloudContainer, centerType, centerName);
  };

  return ToolbarTemplateFactory(
    "before",
    "never",
    false,
    {},
    undefined,
    itemTemplateFunct,
    config && config.visible !== undefined ? config.visible : true
  );
}
