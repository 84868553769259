import { CenterType } from "../../enums/enums";

import {
  BSIGrids,
  ChildGridTypes,
  BSISummarySource
} from "../../enums/bi/enums";

import {
  BasicCrumbInfo
} from "../../components/grids/bi/legacy/legacybi";

import { BI__ItemDetailInvoiceForm } from "../../components/items/bi/bi_itemdetailinvoiceform";
import { BI__ItemDetailOrderForm } from "../../components/items/bi/bi_itemdetailorderform";
import { BI__ItemDetailQuoteForm } from "../../components/items/bi/bi_itemdetailquoteform";
import { BI__ItemDetailPurchaseOrderForm } from "../../components/items/bi/bi_itemdetailpurchaseorderform";
import { BI__ItemDetailVendorInvoiceForm } from "../../components/items/bi/bi_itemdetailvendorinvoiceform";

import { RaiseEvent2 } from "../../infrastructure/events/ui_events";
import { eventNameSpace, EventTypes } from "../../enums/webevents/enums";

import { DataScopeSelection } from "../../components/dashboards/dynamicdatascopeselector";
import { DrillHelper } from "./drillhelper";

//TODO:  the biGridType is only used by the LoadBI_DrillInFromGroupSummary column template.  Might need to find another way to handle it....
export function BiColumnTemplateHandler(
  col: any,
  centerType: CenterType,
  eventLimiterName: string,
  biGridType?: BSIGrids
) {
  switch (col.itemTemplateType) {
    case "LoadBI_Invoice":
      col.cellTemplate = function (container, options) {
        $(
          "<a title'Show Invcoice Details' href='javascript:void (0)'>" +
          options.value +
          "</a>"
        )
          .on("click", function () {
            let item = new BI__ItemDetailInvoiceForm(options.value, centerType);
            item.Initialize();
          })
          .appendTo(container);
      };
      break;
    case "LoadBI_Order":
      // Note from Bill A: 
      // Drilling into OrderNumber is not allowed for VendorBI because these are orders that have already
      // been invoiced, so there is nothing within TDF to show for them since TDF only has open orders
      if (centerType !== CenterType.VendorBi) {

        col.cellTemplate = function (container, options) {
          $(
            "<a title'Show Order Details' href='javascript:void (0)'>" +
            options.value +
            "</a>"
          )
            .on("click", function () {
              let item = new BI__ItemDetailOrderForm(options.value, centerType);
              item.Initialize();
            })
            .appendTo(container);
        };
      }
      break;
    case "LoadBI_Quote":
      col.cellTemplate = function (container, options) {
        $(
          "<a title'Show Quote Details' href='javascript:void (0)'>" +
          options.value +
          "</a>"
        )
          .on("click", function () {
            let item = new BI__ItemDetailQuoteForm(options.value, centerType);
            item.Initialize();
          })
          .appendTo(container);
      };
      break;
    case "LoadBI_PurchaseOrder":
      col.cellTemplate = function (container, options) {
        $(
          "<a title'Show Purchase Order Details' href='javascript:void (0)'>" +
          options.value +
          "</a>"
        )
          .on("click", function () {
            let item = new BI__ItemDetailPurchaseOrderForm(
              options.value,
              centerType
            );
            item.Initialize();
          })
          .appendTo(container);
      };
      break;
    case "LoadBI_VendorInvoice":
      col.cellTemplate = function (container, options) {
        $(
          "<a title'Show Vendor Invoice Details' href='javascript:void (0)'>" +
          options.value +
          "</a>"
        )
          .on("click", function () {
            let item = new BI__ItemDetailVendorInvoiceForm(
              options.value,
              centerType
            );
            item.Initialize();
          })
          .appendTo(container);
      };
      break;
    case "LoadBI_DrillIn":
      col.cellTemplate = function (container, options) {
        $(
          "<a title'Show BI Drillin Details' href='javascript:void (0)'>" +
          options.text +
          "</a>"
        )
          .on("click", function () {
            let sendDFD: JQueryDeferred<DrillHelper> = $.Deferred();
            RaiseEvent2(
              EventTypes.BIEventTypes.currentDrillHelper,
              eventLimiterName,
              eventNameSpace.request,
              { deferred: sendDFD }
            );
            sendDFD.promise(sendDFD.resolve);
            sendDFD.done(function (drillHelper: DrillHelper) {
              drillHelper.DisplayDrillIn(
                options.column.dataField,
                options.value,
                options.data["ClassID"],
                options.data["Description"]
              );
            });
          })
          .appendTo(container);
      };
      break;
    case "LoadBI_DrillInFromGroupSummary":
      col.cellTemplate = function (container, options) {
        $(
          "<a title'Show BI Drillin Details' href='javascript:void (0)'>" +
          options.text +
          "</a>"
        )
          .on("click", function () {
            let sendDFD: JQueryDeferred<DataScopeSelection> = $.Deferred();
            RaiseEvent2(
              EventTypes.BIEventTypes.currentsummaryselection,
              eventLimiterName,
              eventNameSpace.request,
              { deferred: sendDFD }
            );

            sendDFD.done(function (scopeSettings) {
              let helper = new DrillHelper(
                centerType,
                eventLimiterName,
                scopeSettings
              );
              helper.Initialize().done(function () {
                let crumb: BasicCrumbInfo = GetCrumbInfoFromGroupSummaryRow(
                  options.data,
                  biGridType,
                  eventLimiterName
                );



                helper.DisplayDrillIn(
                  options.column.dataField,
                  options.value,
                  crumb.Value,
                  crumb.Description,
                  crumb.ChildGridType,
                  crumb.UseAsFilter
                );
              });
            });
          })
          .appendTo(container);
      };
      break;
  }
}

export function GetCrumbInfoFromGroupSummaryRow(
  rowData,
  biGridType: BSIGrids,
  eventLimiterName: string
): BasicCrumbInfo {
  let theDrillHelper: DrillHelper = this;
  let crumb = new BasicCrumbInfo();

  switch (biGridType) {
    // Account BI Crumbs
    case BSIGrids.GenericGroupSummary:
      crumb.ChildGridType = ChildGridTypes.acct;

      // This adds the crumb.Value in genericgroupsummary.ts by reference.
      RaiseEvent2(
        EventTypes.BIEventTypes.currentAccountFieldPivot,
        eventLimiterName,
        eventNameSpace.request,
        { crumb: crumb }
      );

      crumb.Description = rowData.GroupField;
      crumb.UseAsFilter = true;
      break;
    case BSIGrids.GapAnalysis:
    //let gapItem: IGapGroupSummaryItem = <IGapGroupSummaryItem>theDrillHelper.ParentCenter.GapSummariesTab.CurrentSelectedItem;
    //NEEDTODO

    //switch (gapItem.RollUpType) {
    //    case Enums.BI.GapRollUpType.AccountID:
    //        crumb.ChildGridType = ChildGridTypes.acct;
    //        crumb.Value = data.AccountID;
    //        crumb.Description = data.AccountName;
    //        break;
    //    case Enums.BI.GapRollUpType.BSIMasterAcctID:
    //        crumb.ChildGridType = ChildGridTypes.master;
    //        crumb.Value = data.GroupField
    //        crumb.Description = data.GroupField
    //        break;
    //    case Enums.BI.GapRollUpType.BSICustomerID:
    //        crumb.ChildGridType = ChildGridTypes.custid;
    //        crumb.Value = data.GroupField
    //        crumb.Description = data.GroupField
    //        break;
    //    case Enums.BI.GapRollUpType.AcctPkgID:
    //        crumb.ChildGridType = ChildGridTypes.acctpkg;
    //        crumb.Value = data.acctpkgid;
    //        crumb.Description = data.AcctPkg
    //        break;
    //    case Enums.BI.GapRollUpType.SalesmanID:
    //        crumb.ChildGridType = ChildGridTypes.slsman;
    //        crumb.Value = data.SalesmanID;
    //        crumb.Description = data.Description;
    //        break;
    //    case Enums.BI.GapRollUpType.AcctRootID:
    //        crumb.ChildGridType = ChildGridTypes.acctroot;
    //        crumb.Value = data.acctrootid;
    //        crumb.Description = data.acctroot;
    //        break;
    //}
    //break;
    case BSIGrids.Acct:
      crumb.ChildGridType = ChildGridTypes.acct;
      crumb.Value = rowData.AccountID;
      crumb.Description = rowData.AccountName;
      break;
    case BSIGrids.Alt1:
      crumb.ChildGridType = ChildGridTypes.alt1;
      crumb.Value = rowData.Alternate1;
      crumb.Description = rowData.Alternate1;
      break;
    case BSIGrids.Alt2:
      crumb.ChildGridType = ChildGridTypes.alt2;
      crumb.Value = rowData.Alternate2;
      crumb.Description = rowData.Alternate2;
      break;
    case BSIGrids.Alt3:
      crumb.ChildGridType = ChildGridTypes.alt3;
      crumb.Value = rowData.Alternate3;
      crumb.Description = rowData.Alternate3;
      break;
    //case  BSIGrids.County:
    //    columnName = "";
    //    crumb.Value = data("");
    //    crumb.Description = data("");
    //    break;
    case BSIGrids.CustomerID:
      crumb.ChildGridType = ChildGridTypes.custid;
      crumb.Value = rowData.GroupField;
      crumb.Description = rowData.GroupField;
      break;
    case BSIGrids.ProductGroup:
      crumb.ChildGridType = ChildGridTypes.prod;
      crumb.Value = rowData.PRClassID;
      crumb.Description = rowData["Product Group"];
      break;
    case BSIGrids.ShipTo:
      crumb.ChildGridType = ChildGridTypes.shipto;
      //crumb.Value = data("");
      //crumb.Description = data("");
      break;
    case BSIGrids.Vendor:
      crumb.ChildGridType = ChildGridTypes.vend;
      crumb.Value = rowData.VendorID;
      crumb.Description = rowData.VendorID;
      break;
    case BSIGrids.Salesperson:
      crumb.ChildGridType = ChildGridTypes.slsman;
      crumb.Value = rowData.SalesmanID;
      crumb.Description = rowData.Description;
      break;
    case BSIGrids.AcctPkg:
      crumb.ChildGridType = ChildGridTypes.acctpkg;
      crumb.Value = rowData.AcctPkgID;
      crumb.Description = rowData.AcctPkg;
      break;
    case BSIGrids.MasterID:
      crumb.ChildGridType = ChildGridTypes.master;
      crumb.Value = rowData.GroupField;
      crumb.Description = rowData.GroupField;
      break;
    case BSIGrids.AcctRoot:
      crumb.ChildGridType = ChildGridTypes.acctroot;
      crumb.Value = rowData.acctrootid;
      crumb.Description = rowData.acctroot;
      break;

    // Contact BI Crumbs
    case BSIGrids.BSIContactAcctGroupSummary:
      crumb.ChildGridType = ChildGridTypes.acct;
      crumb.Value = rowData["tdf guid"];
      crumb.Description = rowData.AccountName;
      break;
    case BSIGrids.BSIContactAcctPkgGroupSummary:
      crumb.ChildGridType = ChildGridTypes.acctpkg;
      crumb.Value = rowData.AcctPkgID; //TODO:  This isn't in the data.
      crumb.Description = rowData.acctpkg;
      break;
    case BSIGrids.BSIContactAlt1GroupSummary:
      crumb.ChildGridType = ChildGridTypes.alt1;
      crumb.Value = rowData.alternate1;
      crumb.Description = rowData.alternate1;
      break;
    case BSIGrids.BSIContactAlt2GroupSummary:
      crumb.ChildGridType = ChildGridTypes.alt2;
      crumb.Value = rowData.alternate2;
      crumb.Description = rowData.alternate2;
      break;
    case BSIGrids.BSIContactAlt3GroupSummary:
      crumb.ChildGridType = ChildGridTypes.alt3;
      crumb.Value = rowData.alternate3;
      crumb.Description = rowData.alternate3;
      break;
    case BSIGrids.BSIContactProductGroupGroupSummary:
      crumb.ChildGridType = ChildGridTypes.prod;
      crumb.Value = rowData.prclassid;
      crumb.Description = rowData["description"];
      break;
    case BSIGrids.BSIContactVendorGroupSummary:
      crumb.ChildGridType = ChildGridTypes.vend;
      crumb.Value = rowData.vendorid;
      crumb.Description = rowData.vendorname;
      break;

    // Opportunity BI Crumbs
    case BSIGrids.BSIJobAcctGroupSummary:
      crumb.ChildGridType = ChildGridTypes.acct;
      crumb.Value = rowData["TDF GUID"];
      crumb.Description = rowData.AccountName;
      break;
    case BSIGrids.BSIJobAcctPkgGroupSummary:
      crumb.ChildGridType = ChildGridTypes.acctpkg;
      crumb.Value = rowData.AcctPkgID;
      crumb.Description = rowData.AcctPkg;
      break;
    case BSIGrids.BSIJobAlt1GroupSummary:
      crumb.ChildGridType = ChildGridTypes.alt1;
      crumb.Value = rowData.Alternate1;
      crumb.Description = rowData.Alternate1;
      break;
    case BSIGrids.BSIJobAlt2GroupSummary:
      crumb.ChildGridType = ChildGridTypes.alt2;
      crumb.Value = rowData.Alternate2;
      crumb.Description = rowData.Alternate2;
      break;
    case BSIGrids.BSIJobAlt3GroupSummary:
      crumb.ChildGridType = ChildGridTypes.alt3;
      crumb.Value = rowData.Alternate3;
      crumb.Description = rowData.Alternate3;
      break;
    case BSIGrids.BSIJobProductGroupGroupSummary:
      crumb.ChildGridType = ChildGridTypes.prod;
      crumb.Value = rowData.PRClassID;
      crumb.Description = rowData["Product Group"];
      break;
    case BSIGrids.BSIJobVendorGroupSummary:
      crumb.ChildGridType = ChildGridTypes.vend;
      crumb.Value = rowData.VendorID;
      crumb.Description = rowData.VendorName;
      break;

    // Vendor BI Crumbs
    case BSIGrids.VendorAcctPkgGroupSummary:
      crumb.ChildGridType = ChildGridTypes.acctpkg;
      crumb.Value = rowData.AcctPkgID;
      crumb.Description = rowData.AcctPkg;
      break;
    case BSIGrids.VendorAlt1GroupSummary:
      crumb.ChildGridType = ChildGridTypes.alt1;
      crumb.Value = rowData.alternate1;
      crumb.Description = rowData.alternate1;
      break;
    case BSIGrids.VendorAlt2GroupSummary:
      crumb.ChildGridType = ChildGridTypes.alt2;
      crumb.Value = rowData.alternate2;
      crumb.Description = rowData.alternate2;
      break;
    case BSIGrids.VendorAlt3GroupSummary:
      crumb.ChildGridType = ChildGridTypes.alt3;
      crumb.Value = rowData.alternate3;
      crumb.Description = rowData.alternate3;
      break;
    case BSIGrids.VendorProductGroupGroupSummary:
      crumb.ChildGridType = ChildGridTypes.prod;
      crumb.Value = rowData.prclassid;
      crumb.Description = rowData.Description;
      break;
  }

  return crumb;
}

export function GetBIPrefModuleFromBISummarySource(
  biSource: BSISummarySource
): string {
  let prefModule = "";

  switch (biSource) {
    case BSISummarySource.Customer:
      prefModule = "BSISummary";
      break;
    case BSISummarySource.Contact:
      prefModule = "ContactSummary";
      break;
    case BSISummarySource.Opportunity:
      prefModule = "BSIJobSummary";
      break;
    case BSISummarySource.Vendor:
      prefModule = "BSIVendorSummary";
      break;
  }

  return prefModule;
}

export function GetBISummarySourceFromCenterType(
  centerType: CenterType
): BSISummarySource {
  let summarySource: BSISummarySource;

  switch (centerType) {
    case CenterType.Bi:
    case CenterType.AccountBi:
      summarySource = BSISummarySource.Customer;
      break;
    case CenterType.OppBi:
      summarySource = BSISummarySource.Opportunity;
      break;
    case CenterType.ContactBi:
      summarySource = BSISummarySource.Contact;
      break;
    case CenterType.VendorBi:
      summarySource = BSISummarySource.Vendor;
      break;
  }

  return summarySource;
}

export function TranslateFieldName(
  lineType: string,
  columnCaption: string
): string {
  //cellInfo.data.Item, cellInfo.column.caption)
  let fieldName: string = "";

  switch (lineType) {
    case "Sales":
      fieldName = TranslateSalesFieldName(columnCaption);
      break;
    case "Profit":
      fieldName = TranslateProfitFieldName(columnCaption);
      break;
  }

  return fieldName;
}

export function TranslateSalesFieldName(columnCaption: string): string {
  let fieldName: string = "";

  switch (columnCaption) {
    case "2 Years Prior":
      fieldName = "sales_2y_total";
      break;
    case "Last Year":
      fieldName = "sales_ly_total";
      break;
    case "LYTD":
      fieldName = "sales_lytd";
      break;
    case "YTD":
      fieldName = "sales_ytd";
      break;
    case "2 Years QTD":
      fieldName = "2yqtd";
      break;
    case "Last Quarter":
      fieldName = "lastquarter";
      break;
    case "Last Year QTD":
      fieldName = "lyqtd";
      break;
    case "Quarter to Date":
      fieldName = "qtd";
      break;
    case "2 Years MTD":
      fieldName = "sales_2ymtd";
      break;
    case "Last Month":
      fieldName = "lastmonth";
      break;
    case "Last Year MTD":
      fieldName = "sales_lymtd";
      break;
    case "Month to Date":
      fieldName = "sales_mtd";
      break;
    case "Value":
      fieldName = "sales_day";
      break;
  }

  return fieldName;
}

export function TranslateProfitFieldName(columnCaption: string): string {
  let fieldName: string = "";

  switch (columnCaption) {
    case "2 Years Prior":
      fieldName = "profit_2y_total";
      break;
    case "Last Year":
      fieldName = "profit_ly_total";
      break;
    case "LYTD":
      fieldName = "profit_lytd";
      break;
    case "YTD":
      fieldName = "profit_ytd";
      break;
    case "2 Years QTD":
      fieldName = "2yqtd";
      break;
    case "Last Quarter":
      fieldName = "lastquarter";
      break;
    case "Last Year QTD":
      fieldName = "lyqtd";
      break;
    case "Quarter to Date":
      fieldName = "qtd";
      break;
    case "2 Years MTD":
      fieldName = "profit_2ymtd";
      break;
    case "Last Month":
      fieldName = "lastmonth";
      break;
    case "Last Year MTD":
      fieldName = "profit_lymtd";
      break;
    case "Month to Date":
      fieldName = "profit_mtd";
      break;
    case "Value":
      fieldName = "profit_day";
      break;
  }

  return fieldName;
}
