
    "use strict";
    // This class is used by Preferences.GetPreference.
    // It provides any default values for various user preferences.
    // Properties should be named ModuleName_PropertyName.
    // Properties should all be of type string.
    export namespace UserPreferenceDefaults {
	export const BSISummary_HomeList: string = "";
	export const BSISummary_HomeType: string = "-1";
	export const BSISummary_LeaveBiMenuOpen: string = "0";
	export const BSISummary_TabsByType: string = "True";
	export const BSISummary_GeneralSummaryStyle: string = "0";
	export const BSISummary_OpenSummaryTabByDefault: string = "true";

        export const BSIJobSummary_GeneralSummaryStyle: string = "0";
        export const ContactSummary_GeneralSummaryStyle: string = "0";
        export const BSIVendorSummary_GeneralSummaryStyle: string = "0";

	export const BSISummary_AnnualExpanded: string = "0";
	export const BSISummary_QuarterExpanded: string = "0";
	export const BSISummary_MonthExpanded: string = "0";
	export const BSISummary_DayExpanded: string = "0";

	export const BSISummary_LoadGoals: string = "True";
	export const BSISummary_GoalAcct: string = "0";
	export const BSISummary_GoalAcctPkg: string = "2";
	export const BSISummary_GoalAcctRoot: string = "4";
	export const BSISummary_GoalLevel3: string = "4";
	export const BSISummary_GoalLevel4: string = "4";
	export const BSISummary_GoalSalesman: string = "6";

	export const ManagersConsole_ExpenseAnalysisIncludeGeneral: string = "0";
	export const ManagersConsole_HomeList: string = "";
	export const ManagersConsole_HomeType: string = "-1";
	export const ManagersConsole_KeepItemHeaderOpen: string = "0";
	export const ManagersConsole_LeaveInfoCenterMenuOpen: string = "0";
	export const ManagersConsole_TabsByType: string = "True";

	export const TDFMobile_ClientGridPageSize: string = "50";
	export const TDFMobile_DailySummaryItemSource: string = "1";
	export const TDFMobile_DailySummaryNavMode: string = "0";
	export const TDFMobile_DailySummaryTasksToDisplay: string = "1";
	export const TDFMobile_DailySummaryUseBatchMode: string = "0";
	export const TDFMobile_EnableDebugger: string = "0";
	export const TDFMobile_IncludeBSIData: string = "1";
	export const TDFMobile_ServerPageSize: string = "1000";
	export const TDFMobile_ShowGridFilterEnterKeyNotification: string = "1";
	export const TDFMobile_ShowPastDueTasks: string = "1";
	export const TDFMobile_Theme: string = "light";
	export const TDFMobile_ShowHelpTextAutomatically: string = "0";
	export const TDFMobile_ShowHelpTextTutorial: string = "1";
	export const TDFMobile_ShowFeedbackRipple: string = "1";

	export const Dialogs_Appointment_Include: string = "2";
	export const Dialogs_Appointment_NewActivityDelete: string = "0";
	export const Dialogs_Appointment_NewActivityDialog: string = "1";
	export const Dialogs_Task_Include: string = "2";
	export const Dialogs_Task_NewActivityDelete: string = "0";
	export const Dialogs_Task_NewActivityDialog: string = "1";

	export const Planning_AcctSortStickySetting: string = "";
	    export const Integrations_DefaultIntegration: string = "Trello_Integration";
	export const Dialogs_Appointment_SendConfirmation = "1";

	export const Dialogs_Appointment_SendConfirmationDialog = "0";
    }

    // This class is used by Preferences.GetCompanyPreference.
    // It provides any default values for various company preferences.
    // Properties should be named ModuleName_PropertyName.
    // Properties should all be of type string.
    export module CompanyPreferenceDefaults {
	export const TDFItem_DetailSendMode: string = "0";
	export const TDFItem_CustomAcctPkgLookup: string = "0";
	export const SPAIntegration_ProductImageField: string = "ResourceURL1";
	export const TDFMobile_MaxPersonalDashboardCount: string = "5";
			export const TDFMobile_MaxGlobalDashboardCount: string = "1";
			export const TDFMobile_IncludeLinkButtonOnItemMenu: string = "0";
    }
