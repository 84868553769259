import * as Globalize from "globalize";
import * as moment from "moment";

import {
  BIGeneralSummaryStyle,
  BSIGrids,
  BSISummarySource,
  ChildGridTypes,
  GapRollUpType,
  ISummary
} from "../../../../enums/bi/enums";
import { CenterType, GridContainerTypes } from "../../../../enums/enums";
import {
  CurrentUser,
  LoadCompany
} from "../../../../infrastructure/context";
import { BISummarySettingsSelector } from "../../../../infrastructure/user/bisummarysettingsselector";
import { GetPreference } from "../../../../infrastructure/user/getpreference";
import { Preferences } from "../../../../infrastructure/user/preferences";
import {
  IAdvancedGroupSummaryBasicItem,
  IAdvancedGroupSummaryItem,
  IAdvancedTabItems,
  IGapGroupSummaryBasicItem,
  IGapGroupSummaryItem,
  IGridDataResponse,
  IGridInfo,
  IGridViewMenuItem,
} from "../../../../interfaces/interfaces";
import {
  CustomCenterTab
} from "../../../../legacy-centers-grids/centerbase";
import { Grid } from "../../../../legacy-centers-grids/grid";
import { GridSetup } from "../../../../services/grid/helpers/gridsetup";
import { TDFRequest } from "../../../../services/request";
import { Dialog } from "../../../dialogs/dialog";
import { Notification } from "../../../dialogs/notification";
import {
  DomSafeID,
  ToggleToolbarButtonVisibility,
  GetDevice,
  BuildBreadcrumbs
} from "../../../../util/allutils";
import dxDataSource from "devextreme/data/data_source";
import dxArrayStore from "devextreme/data/array_store";
import { GridToolbar } from "../../gridtoolbars/gridtoolbar";
import { BI__ItemDetailInvoiceForm } from "../../../items/bi/bi_itemdetailinvoiceform";
import { BI__ItemDetailOrderForm } from "../../../items/bi/bi_itemdetailorderform";
import { BI__ItemDetailQuoteForm } from "../../../items/bi/bi_itemdetailquoteform";
import { BI__ItemDetailPurchaseOrderForm } from "../../../items/bi/bi_itemdetailpurchaseorderform";
import { BI__ItemDetailVendorInvoiceForm } from "../../../items/bi/bi_itemdetailvendorinvoiceform";
import devices from "devextreme/core/devices";

export class ArbitraryGridPanel {
  public CustomPanelName: string = "";
  public DisplayTitle: string = "Query Lookup Results";

  // Internal Objects
  protected DetailForm: Dialog;
  protected ToolbarModel: any;
  protected Toolbar: GridToolbar;
  protected MyGrid: Grid;
  protected GridContainer: JQuery;
  protected RequestModel: GridSetup.BaseGridRequestModel;
  protected ColumnTemplateHandler: BiColumnTemplateHandler;

  // Query Info Data
  private QueryID: number = -1;
  private DomSafeQueryID: string = "";
  private ItemType: number = -1;
  private TDFContainerType: number;
  private ViewID: string = "";

  // HTML IDs
  private RandomID: string = "";
  private FormID: string;
  private ContentID: string;
  private ContentHeaderID: string;
  private ContentLinesID: string;
  private HeaderFormID: string;
  private GridWrapperContainerID: string;
  private ToolbarContainerID: string;
  private GridContainerID: string;

  constructor(
    customPanelName: string,
    queryID: number,
    itemType: number,
    containerType: number,
    title: string,
    viewID?: string
  ) {
    const arbitraryGridPanel = this;

    arbitraryGridPanel.RandomID = Math.ceil(Math.random() * 999 + 1).toString();
    arbitraryGridPanel.CustomPanelName = customPanelName || "arbitrary";
    arbitraryGridPanel.QueryID = queryID;
    arbitraryGridPanel.DomSafeQueryID = DomSafeID(this.QueryID.toString());
    arbitraryGridPanel.ItemType = itemType;
    arbitraryGridPanel.TDFContainerType = containerType;
    arbitraryGridPanel.DisplayTitle = title;
    arbitraryGridPanel.ViewID = viewID || "";
    arbitraryGridPanel.ColumnTemplateHandler = new BiColumnTemplateHandler();
    //TODO: i will probably need to figure this out .... does the webpack globalization plugin handle this??
    //Context.LoadGlobalization();
    LoadCompany();
  }

  public SetElementIDs() {
    const arbitraryGridPanel = this;

    arbitraryGridPanel.FormID =
      arbitraryGridPanel.CustomPanelName +
      "Form_" +
      arbitraryGridPanel.DomSafeQueryID +
      "_" +
      arbitraryGridPanel.RandomID;
    arbitraryGridPanel.ContentID =
      arbitraryGridPanel.CustomPanelName +
      "Content_" +
      arbitraryGridPanel.DomSafeQueryID +
      "_" +
      arbitraryGridPanel.RandomID;
    arbitraryGridPanel.ContentHeaderID =
      arbitraryGridPanel.CustomPanelName +
      "ContentHeader_" +
      arbitraryGridPanel.DomSafeQueryID +
      "_" +
      arbitraryGridPanel.RandomID;
    arbitraryGridPanel.ContentLinesID =
      arbitraryGridPanel.CustomPanelName +
      "ContentLines_" +
      arbitraryGridPanel.DomSafeQueryID +
      "_" +
      arbitraryGridPanel.RandomID;
    arbitraryGridPanel.HeaderFormID =
      arbitraryGridPanel.CustomPanelName +
      "HeaderForm_" +
      arbitraryGridPanel.DomSafeQueryID +
      "_" +
      arbitraryGridPanel.RandomID;
    arbitraryGridPanel.GridWrapperContainerID =
      arbitraryGridPanel.CustomPanelName +
      "GridWrapper_" +
      arbitraryGridPanel.DomSafeQueryID +
      "_" +
      arbitraryGridPanel.RandomID;
    arbitraryGridPanel.ToolbarContainerID =
      arbitraryGridPanel.CustomPanelName +
      "Toolsbar_" +
      arbitraryGridPanel.DomSafeQueryID +
      "_" +
      arbitraryGridPanel.RandomID;
    arbitraryGridPanel.GridContainerID =
      arbitraryGridPanel.CustomPanelName +
      "Grid_" +
      arbitraryGridPanel.DomSafeQueryID +
      "_" +
      arbitraryGridPanel.RandomID;
  }

  public Initialize() {
    const arbitraryGridPanel = this;
    arbitraryGridPanel.SetElementIDs();

    const griddfd = $.Deferred();

    arbitraryGridPanel.Form().done(function () {
      griddfd.resolve(arbitraryGridPanel);
    });

    return griddfd.promise();
  }

  public Form() {
    const arbitraryGridPanel = this;

    const griddfd = $.Deferred();

    arbitraryGridPanel.DetailForm = new Dialog(
      {
        id: arbitraryGridPanel.FormID,
        title: arbitraryGridPanel.DisplayTitle,
        size: "size-wide",
        closable: true,
        body: $("<div />")
          .attr("id", arbitraryGridPanel.ContentID)
          .append($("<div />").attr({ id: arbitraryGridPanel.ContentHeaderID }))
          .append($("<hr>"))
          .append($("<div />").attr({ id: arbitraryGridPanel.ContentLinesID })),
        type: "type-primary"
      },
      null,
      false
    );

    arbitraryGridPanel.DetailForm.open().done(function (args) {
      const htmlTop: string =
        "<div id='" + arbitraryGridPanel.HeaderFormID + "'></div>";
      $("#" + arbitraryGridPanel.ContentHeaderID).append(htmlTop);

      const htmlBottom: string =
        "<div id='" +
        arbitraryGridPanel.GridWrapperContainerID +
        "'><div id='" +
        arbitraryGridPanel.ToolbarContainerID +
        "'></div><div id='" +
        arbitraryGridPanel.GridContainerID +
        "'></div></div>";
      $("#" + arbitraryGridPanel.ContentLinesID).append(htmlBottom);

      arbitraryGridPanel.GridContainer = $(
        "#" + arbitraryGridPanel.GridContainerID
      );

      arbitraryGridPanel.GetToolbar().done(function (toolbarResponse) {
        griddfd.resolve(arbitraryGridPanel);
      });
    });

    return griddfd.promise();
  }

  public MakeGrid(detailData) {
    const arbitraryGridPanel = this;

    const overriddenGridOptions: any = {};

    // Bookings and Line Items come back with a GridContainerType of BSISummary.  On these, make sure the open/action/view buttons do not show when you click in the grid.
    if (arbitraryGridPanel.TDFContainerType === GridContainerTypes.BSISummary) {
      overriddenGridOptions.selection = {
        mode: "none"
      };
      overriddenGridOptions.oncontextMenuPreparing = null;
      overriddenGridOptions.onSelectionChanged = function (e) { };
    }

    arbitraryGridPanel.MyGrid = new Grid(
      detailData,
      {
        GridContainer: arbitraryGridPanel.GridContainer,
        GridToolbar: arbitraryGridPanel.Toolbar
      },
      overriddenGridOptions,
      arbitraryGridPanel,
      e => {
        arbitraryGridPanel.Toolbar.SelectionChanged(e);
      },
      arbitraryGridPanel.ColumnTemplateHandler.BiColumnTemplateHandler
    );
    arbitraryGridPanel.MyGrid.Render();
    arbitraryGridPanel.Toolbar.CurrentGrid = arbitraryGridPanel.MyGrid;
  }

  public GetToolbar() {
    const arbitraryGridPanel = this;
    const dfd = $.Deferred();

    // check toolbar data list
    if (arbitraryGridPanel.ToolbarModel) {
      return dfd.promise(dfd.resolve(arbitraryGridPanel));
    } else {
      const request = new TDFRequest({
        url: "/core/user/GetToolbarInfo/",
        type: "GET",
        data: {
          type: arbitraryGridPanel.ItemType,
          containertype: arbitraryGridPanel.TDFContainerType
        }
      });
      request.MakeRequest().done(function (response) {
        arbitraryGridPanel.ToolbarModel = response;
        arbitraryGridPanel.ToolbarModel.ToolbarContainerID =
          arbitraryGridPanel.ToolbarContainerID;

        arbitraryGridPanel.Toolbar = new GridToolbar(
          arbitraryGridPanel.ToolbarModel
        );
        arbitraryGridPanel.Toolbar.GridDataRequestModel = arbitraryGridPanel.GetItemLineRequestModel();
        arbitraryGridPanel.Toolbar.GridDataRequestType = "POST";
        arbitraryGridPanel.Toolbar.MakeGrid = arbitraryGridPanel.MakeGrid.bind(
          arbitraryGridPanel
        );
        if (!arbitraryGridPanel.Toolbar.CurrentView) {
          arbitraryGridPanel.Toolbar.CurrentView =
            arbitraryGridPanel.Toolbar.DefaultView;
        }

        arbitraryGridPanel.Toolbar.RenderAndLoad();
        return dfd.resolve(arbitraryGridPanel);
      });
    }

    return dfd.promise(arbitraryGridPanel);
  }

  public GetItemLineRequestModel(viewGUID?: string) {
    const arbitraryGridPanel = this;

    const requestArgs = new GridSetup.ArbitraryGridRequestArgs();
    requestArgs.GUID =
      arbitraryGridPanel.ViewID || arbitraryGridPanel.Toolbar.DefaultView;
    requestArgs.ItemType = arbitraryGridPanel.ItemType;
    requestArgs.QueryID = arbitraryGridPanel.QueryID;

    arbitraryGridPanel.RequestModel = new GridSetup.ArbitraryGridDataRequestModel(
      requestArgs
    );
    return arbitraryGridPanel.RequestModel;
  }
}

export abstract class BiCustomCenterTab extends CustomCenterTab {
  public MyBICenter: BiCenter;
  constructor(centerObject: BiCenter) {
    super(centerObject);
    this.MyBICenter = centerObject;
  }
}

export abstract class BI_AdvancedGroupSummariesTab extends BiCustomCenterTab {
  public CenterType = -1;
  public abstract AdvancedTypeName: string;
  public abstract AdvancedSelectorPlaceholderMessage: string;
  public abstract TabPanelPlaceholderMessage: string;

  public AdvancedItems: IAdvancedTabItems;

  public ContainerID: string;
  public AdvFieldsToolbarContainerID: string;
  public ViewToolbarContainerID: string;
  public AdvFieldsTabPanelID: string;
  public AdvFieldsLookupID: string;

  public AdvFieldTabPanel: DevExpress.ui.dxTabPanel;
  public CurrentSelectedItem: IAdvancedGroupSummaryItem;
  public AcctFieldItems: string[];

  public ViewToolbarContainer: JQuery;
  public CurrentToolbar: GridToolbar;
  public RequestModel: GridSetup.BaseGridRequestModel;

  constructor(centerObject: BiCenter) {
    super(centerObject);
  }

  public abstract GetAdvancedItems();
  public abstract GetRequestModel(
    viewGUID?: string
  ): GridSetup.BaseGridRequestModel;

  public ClearCachedData() {
    const theAdvancedSummariesTab = this;
    theAdvancedSummariesTab.AdvancedItems = undefined;
  }

  public GetSummariesList() {
    const theAdvancedSummariesTab = this;
    const dfd = $.Deferred();

    if (theAdvancedSummariesTab.AdvancedItems) {
      return dfd.resolve(theAdvancedSummariesTab.AdvancedItems);
    } else {
      theAdvancedSummariesTab
        .GetAdvancedItems()
        .done(function (result: IAdvancedTabItems) {
          theAdvancedSummariesTab.AdvancedItems = result;
          return dfd.resolve(theAdvancedSummariesTab.AdvancedItems);
        });
    }
    return dfd.promise();
  }

  public RenderTab(data, index, element) {
    const theAdvancedSummariesTab = this;

    theAdvancedSummariesTab.GetSummariesList().done(function (response) {
      theAdvancedSummariesTab.ContainerID = (
        "Container_" + theAdvancedSummariesTab.AdvancedTypeName
      ).replace(/[{}]/g, "");
      theAdvancedSummariesTab.AdvFieldsToolbarContainerID =
        "Toolbar_" + theAdvancedSummariesTab.AdvancedTypeName;
      theAdvancedSummariesTab.ViewToolbarContainerID =
        "ViewToolbar_" + theAdvancedSummariesTab.AdvancedTypeName;
      theAdvancedSummariesTab.AdvFieldsLookupID =
        "selAdvancedFields_" + theAdvancedSummariesTab.AdvancedTypeName;
      theAdvancedSummariesTab.AdvFieldsTabPanelID =
        "Tabs_" + theAdvancedSummariesTab.AdvancedTypeName;

      const container = $(
        "<div id='" +
        theAdvancedSummariesTab.ContainerID +
        "' style='padding:3px'><div id='" +
        theAdvancedSummariesTab.AdvFieldsToolbarContainerID +
        "'></div><div id='" +
        theAdvancedSummariesTab.ViewToolbarContainerID +
        "'></div><div id='" +
        theAdvancedSummariesTab.AdvFieldsTabPanelID +
        "'></div></div>"
      );
      element.append(container);

      const toolbarItems: any = [
        {
          locateInMenu: "never",
          location: "after",
          widget: "dxLookup",
          options: {
            elementAttr: {
              id: theAdvancedSummariesTab.AdvFieldsLookupID
            },
            dataSource: new dxDataSource({
              store: new dxArrayStore({
                data: theAdvancedSummariesTab.AdvancedItems.Items
              })
            }),
            placeholder:
              theAdvancedSummariesTab.AdvancedSelectorPlaceholderMessage,
            closeOnOutsideClick: true,
            value: -1,
            displayExpr: "Caption",
            valueExpr: "Index",
            width: "200px",
            height: "30px",
            onItemClick(selectedItem) {
              const item = theAdvancedSummariesTab.GetItem(
                selectedItem.itemIndex
              );
              theAdvancedSummariesTab.CurrentSelectedItem = item;
              // Note:  Resetting the default view to blank so that it picks up the actual default view
              if (theAdvancedSummariesTab.CurrentSelectedItem.ToolbarModel) {
                theAdvancedSummariesTab.CurrentSelectedItem.ToolbarModel.DefaultView =
                  "";
              }

              theAdvancedSummariesTab.LoadAdvFieldTabPanel(selectedItem);
            }
          }
        }
      ];

      $("#" + theAdvancedSummariesTab.AdvFieldsToolbarContainerID)
        .dxToolbar({
          dataSource: toolbarItems,
          height: "33px"
        })
        .dxToolbar("instance");

      theAdvancedSummariesTab.ViewToolbarContainer = $(
        "#" + theAdvancedSummariesTab.ViewToolbarContainerID
      );
      theAdvancedSummariesTab.RenderAdvancedFieldTabPanel();
      $("#" + theAdvancedSummariesTab.AdvFieldsLookupID).addClass("flash");
    });
  }

  public RefreshTabs() {
    const theAdvancedSummariesTab = this;
    if (theAdvancedSummariesTab.AdvFieldTabPanel) {
      const source: DevExpress.data.DataSource = theAdvancedSummariesTab.AdvFieldTabPanel.option(
        "dataSource"
      );
      source.filter(theAdvancedSummariesTab.GetDefaultFilter());
      source.load();
    }
  }

  public LoadGrid(
    view: IGridViewMenuItem,
    element,
    addCol: boolean = false,
    page?: number
  ) {
    const theAdvancedSummariesTab = this;
    const grid: IGridDataResponse = theAdvancedSummariesTab.GetGridFromCache(
      view
    );
    if (grid) {
      // alert("handle cached grid");

      const myGridOptions = theAdvancedSummariesTab.MyBICenter.GridOptions(
        view
      );
      myGridOptions.summary = null;
      myGridOptions.export = {
        enabled: view.Grid.GridDataResponse.Meta.CanExport
      };

      (theAdvancedSummariesTab.CurrentToolbar as GridToolbar).CurrentGrid = new Grid(
        theAdvancedSummariesTab.MyBICenter.MassageData(grid),
        {
          GridContainer: element,
          GridToolbar: theAdvancedSummariesTab.CurrentToolbar as GridToolbar
        },
        myGridOptions,
        theAdvancedSummariesTab,
        e => {
          (theAdvancedSummariesTab.CurrentToolbar as GridToolbar).SelectionChanged(
            e
          );
        },
        theAdvancedSummariesTab.MyBICenter.BiColumnTemplateHandler
      );

      (theAdvancedSummariesTab.CurrentToolbar as GridToolbar).CurrentGrid.Render();

      view.Grid = (theAdvancedSummariesTab.CurrentToolbar as GridToolbar).CurrentGrid;
      // theCenter.CurrentSelectedItem.CurrentTabIndex = theCenter.TabPanel.option("selectedIndex");
      theAdvancedSummariesTab.CurrentSelectedItem.CurrentView = view;

      (theAdvancedSummariesTab.CurrentToolbar as GridToolbar).CurrentView =
        view.ViewGUID;
    } else {
      /* If we are not simply addign a column to the current grid then we are probably changing views so go get the request model*/
      if (!addCol) {
        theAdvancedSummariesTab.RequestModel = theAdvancedSummariesTab.GetRequestModel(
          view.ViewGUID
        );
      }
      /* if we are getting the next page change it on the request model*/
      if (page) {
        theAdvancedSummariesTab.RequestModel.RequestArgs.page = page;
      }

      /* Set the requestmodel on the toolbar in case we need it there */
      const realToolbar = theAdvancedSummariesTab.CurrentToolbar as GridToolbar;
      realToolbar.GridDataRequestModel = theAdvancedSummariesTab.RequestModel;

      if (!theAdvancedSummariesTab.RequestModel.RequestArgs.GUID) {
        /*there must not be a default view flash the selector so the user knows why there is no grid loading */
        (theAdvancedSummariesTab.CurrentToolbar as GridToolbar).ViewSelector.element().addClass(
          "flash"
        );
        return;
      }

      const request = new TDFRequest({
        url: theAdvancedSummariesTab.RequestModel.Path,
        type: "POST",
        data: theAdvancedSummariesTab.RequestModel.RequestArgs
      });

      request.MakeRequest().done(function (response: IGridDataResponse) {
        // Clear the grid state out of the session the first time we load it so that the grid is loaded in the default state.
        const storageKey =
          "storage_" +
          (element ? ($(element) ? $(element).attr("id") : "") : "");
        sessionStorage.removeItem(storageKey);

        const options: DevExpress.ui.dxDataGridOptions = theAdvancedSummariesTab.MyBICenter.GridOptions();
        options.summary = null;
        options.export = {
          enabled: response.Meta.CanExport
        };
        if (typeof options.height === "number") {
          options.height = options.height - 100;
        } else {
          options.height = parseInt(options.height as string) - 100;
        }

        // Save the most recent metadata for each grid load [used for paging ]
        theAdvancedSummariesTab.MyBICenter.GridViewMetaCache = response.Meta;
        const theGrid = new Grid(
          theAdvancedSummariesTab.MyBICenter.MassageData(response),
          { GridContainer: element, GridToolbar: realToolbar },
          options,
          theAdvancedSummariesTab,
          e => {
            (theAdvancedSummariesTab.CurrentToolbar as GridToolbar).SelectionChanged(
              e
            );
          },
          theAdvancedSummariesTab.MyBICenter.BiColumnTemplateHandler
        );
        theGrid.Render();

        realToolbar.CurrentGrid = theGrid;
        view.Grid = realToolbar.CurrentGrid;
        realToolbar.CurrentView = view.ViewGUID;
        theAdvancedSummariesTab.CurrentSelectedItem.CurrentView = view;
        theAdvancedSummariesTab.AddToGridCache(view, response);
        theAdvancedSummariesTab.CurrentSelectedItem.CurrentTabIndex = theAdvancedSummariesTab.AdvFieldTabPanel.option(
          "selectedIndex"
        );
      });
    }
  }

  public AddToGridCache(
    view: IGridViewMenuItem | any,
    gridresponse: IGridDataResponse
  ) {
    const theAdvancedSummariesTab = this;
    const CacheSize = GetDevice().isDevice ? 5 : 10;

    const gapType = view.AcctFieldIndex;
    // theAdvancedSummariesTab.CurrentSelectedItem.Index;
    const item = theAdvancedSummariesTab.GetItem(gapType);

    if (item.LoadedGrids.length === CacheSize) {
      item.LoadedGrids.shift();
    }
    const info: IGridInfo = {
      GridView: view,
      GridData: gridresponse
    };
    item.LoadedGrids.push(info);
  }

  public GetGridFromCache(view: IGridViewMenuItem | any): IGridDataResponse {
    const theAdvancedSummariesTab = this;
    const gapType = view.AcctFieldIndex;
    const item = theAdvancedSummariesTab.GetItem(gapType);

    const gridList: IGridInfo[] = $.grep(item.LoadedGrids, function (
      val: IGridInfo
    ) {
      return val.GridView.ViewGUID === view.ViewGUID;
    });
    if (gridList.length) {
      theAdvancedSummariesTab.MyBICenter.GridViewMetaCache =
        gridList[0].GridData.Meta;
      return gridList[0].GridData;
    }
    return null;
  }

  public RemoveGridFromCache(view: IGridViewMenuItem | any) {
    const theAdvancedSummariesTab = this;
    const gapType = view.AcctFieldIndex;
    const item = theAdvancedSummariesTab.GetItem(gapType);

    $.each(item.LoadedGrids, function (key, val: IGridInfo) {
      if (val && val.GridView && val.GridView.ViewGUID === view.ViewGUID) {
        item.LoadedGrids.splice(key, 1);
      }
    });
  }

  public RenderAdvancedFieldTabPanel() {
    const theAdvancedSummariesTab = this;
    const taboptions: DevExpress.ui.dxTabPanelOptions = {};
    taboptions.itemTitleTemplate = function (data, index, element) {
      const typeText = data.AdvancedTypeCaption || "";
      const viewText =
        data.View && data.View.ViewName ? data.View.ViewName : "";
      // append text to data.viewname, and fix name length
      let titleCaption = typeText + " | " + viewText;

      if (titleCaption.length > 25) {
        titleCaption = titleCaption.substring(0, 17) + "...";
      }

      const container = $("<div />").css({
        "text-overflow": "ellipsis",
        overflow: "hidden",
        "max-width": "100%"
      });
      // container.append($("<i />").css({ "font-style": "normal", "color": "#fff", "margin-right": "10px", position: "absolute", left: "3px" }));
      const title = $("<span />")
        .text(titleCaption || "")
        .css({ "text-overflow": "hidden" })
        .attr({ title: viewText || "" })
        .appendTo(container);
      const close = $("<span />")
        .addClass("close btn-default")
        .attr({
          style:
            "position:absolute; right:3px;top:2px; font-size:16px;  padding:2px 7px;line-height:1;"
        })
        .text("x")
        .on(
          "click",
          { view: data.View, index, element },
          theAdvancedSummariesTab.MyBICenter.CloseTab.bind(
            theAdvancedSummariesTab.MyBICenter
          )
        )
        .appendTo(element);
      element.parent().css("width", "200px");
      element.append(container);
    };
    taboptions.itemTemplate = function (itemData, index, element) {
      const gridcontainer = $("<div/>")
        .attr("id", itemData.Key.replace(/[{}]/g, ""))
        .css("padding", "3px");

      element.append(gridcontainer);
      itemData.TabContent = element;

      theAdvancedSummariesTab.LoadGrid(itemData, gridcontainer);
      itemData.Active = true;
    };
    taboptions.deferRendering = true;
    taboptions.onTitleClick = function (data) {
      // theBICenter.TabPanel_OnTitleClick(data);
      const item = theAdvancedSummariesTab.GetItem(
        data.itemData.AcctFieldIndex
      );
      theAdvancedSummariesTab.CurrentSelectedItem = item;

      // theAdvancedSummariesTab.CurrentSelectedItem.ToolbarModel.UsePersonalViews = data.itemData.View.Scope === "Personal" ? true : false;
      // console.warn(" we were changing the default scope of the toolbar model wich makes us loose track of the actual default!!!!!!!!!!!!!!!   theAdvancedSummariesTab.CurrentSelectedItem.ToolbarModel.UsePersonalViews = data.itemData.View.Scope === 'Personal' ? true : false;");
      theAdvancedSummariesTab.CurrentSelectedItem.ToolbarModel.DefaultView =
        data.itemData.ViewGUID; // Note:  DefaultView is the view to load at this time, not necessarily the actual default.
      theAdvancedSummariesTab.CurrentSelectedItem.CurrentView =
        data.itemData.View;

      theAdvancedSummariesTab.CurrentSelectedItem.CurrentTabIndex = data.component.option(
        "selectedIndex"
      );

      $("#" + theAdvancedSummariesTab.AdvFieldsLookupID)
        .dxLookup("instance")
        .option("value", data.itemData.AcctFieldIndex);

      theAdvancedSummariesTab.GetToolbar().done(function () {
        theAdvancedSummariesTab.CreateToolbar();
        (theAdvancedSummariesTab.CurrentToolbar as GridToolbar).CurrentGrid =
          data.itemData.Grid;

        if (data.itemData.Grid) {
          const length = data.itemData.Grid.GridObject.getSelectedRowsData().length;
          // .CurrentGrid ? (theToolbar.CurrentGrid.GridObject.getSelectedRowsData().length) : 0;
          ToggleToolbarButtonVisibility(theAdvancedSummariesTab.CurrentToolbar.ToolbarContainerID, length);
        }
      });
    };

    taboptions.dataSource = theAdvancedSummariesTab.GetAcctFieldTabsDataSource();
    taboptions.showNavButtons = true;
    taboptions.height = "90%";
    taboptions.noDataText = theAdvancedSummariesTab.TabPanelPlaceholderMessage;
    theAdvancedSummariesTab.AdvFieldTabPanel = $(
      "#" + theAdvancedSummariesTab.AdvFieldsTabPanelID
    )
      .dxTabPanel(taboptions)
      .dxTabPanel("instance");
  }

  public GetAcctFieldTabsDataSource() {
    const theAdvancedSummariesTab = this;

    return new dxDataSource({
      store: new dxArrayStore({
        data: [], // Initialize with nothing.  The items get added later.
        key: "Key"
      }),
      filter: theAdvancedSummariesTab.GetDefaultFilter()
    });
  }

  public LoadAdvFieldTabPanel(selectedItem) {
    const theAdvancedSummariesTab = this;
    // Build Toolbar and create views
    theAdvancedSummariesTab.GetToolbar().done(function () {
      theAdvancedSummariesTab.CreateToolbar();
    });
  }

  public GetToolbarType() {
    const theAdvancedSummariesTab = this;
    return theAdvancedSummariesTab.MyBICenter.CurrentSelectedItem.Type;
  }

  public GetToolbar(): JQueryPromise<any> {
    const theAdvancedSummariesTab = this;
    const dfd = $.Deferred();

    // check toolbar data list
    if (theAdvancedSummariesTab.CurrentSelectedItem.ToolbarModel) {
      dfd.promise(
        dfd.resolve(theAdvancedSummariesTab.CurrentSelectedItem.ToolbarModel)
      );
    } else {
      // How to get the toolbar for an account field group summary
      const request = new TDFRequest({
        url: "/core/user/GetToolbarInfo/",
        type: "GET",
        data: {
          type: theAdvancedSummariesTab.GetToolbarType(),
          containertype: theAdvancedSummariesTab.MyBICenter.TDFContainerType,
          advancedItemViews: true
        }
      });
      request.MakeRequest().done(function (response) {
        response.OnViewChanged = theAdvancedSummariesTab.ViewChangedEvent.bind(
          theAdvancedSummariesTab
        );
        theAdvancedSummariesTab.CurrentSelectedItem.ToolbarModel = response;
        dfd.resolve(theAdvancedSummariesTab);
      });
    }

    return dfd.promise(theAdvancedSummariesTab);
  }

  public ViewChangedEvent(e) {
    const theAdvancedSummariesTab = this;
    const store: DevExpress.data.ArrayStore = theAdvancedSummariesTab.AdvFieldTabPanel.option(
      "dataSource"
    ).store();
    if (!e.value || e.value == "_") {
      return;
    }
    store
      .update(
        theAdvancedSummariesTab.CurrentSelectedItem.Index + "_" + e.value,
        { Active: true, Load: true }
      )
      .done(function (key) {
        const newitem = store
          .byKey(key)
          .done(function (data: IGridViewMenuItem) {
            const oldlist: IGridViewMenuItem[] = theAdvancedSummariesTab.AdvFieldTabPanel.option(
              "items"
            );
            const t = $.inArray(data, oldlist);
            if (t > -1) {
              theAdvancedSummariesTab.AdvFieldTabPanel.option(
                "selectedIndex",
                t
              );
              return;
            }
            data.Load = true;
            const newlist = oldlist.concat(data);
            theAdvancedSummariesTab.AdvFieldTabPanel.option("items", newlist);
            theAdvancedSummariesTab.AdvFieldTabPanel.option(
              "selectedIndex",
              newlist.length - 1
            );
          });
      });
  }

  public CreateToolbar() {
    const theAdvancedSummariesTab = this;
    theAdvancedSummariesTab.ViewToolbarContainer.empty();

    let loaded = false;

    theAdvancedSummariesTab.CurrentToolbar = new GridToolbar(
      theAdvancedSummariesTab.CurrentSelectedItem.ToolbarModel
    );
    // theCenter.CurrentToolbar.ExtraItems = theCenter.GetExtraTabToolbarItems();
    theAdvancedSummariesTab.CurrentToolbar.ToolbarContainerID =
      theAdvancedSummariesTab.ViewToolbarContainerID;
    const realToolbar = theAdvancedSummariesTab.CurrentToolbar as GridToolbar;
    realToolbar.RefreshCurrentGrid = theAdvancedSummariesTab.RefreshCurrentGrid.bind(
      theAdvancedSummariesTab
    );
    theAdvancedSummariesTab.CurrentToolbar.Render();

    const loadGlobal = !realToolbar.UsePersonalViews;

    if (!theAdvancedSummariesTab.CurrentSelectedItem.ViewList) {
      const defaultView = $.grep(realToolbar.GetViews(), function (val: any) {
        return val.ViewGUID === realToolbar.DefaultView;
      });

      if (defaultView.length > 0) {
        if (!theAdvancedSummariesTab.CurrentSelectedItem.CurrentView) {
          theAdvancedSummariesTab.CurrentSelectedItem.CurrentView = {
            ViewCategory: defaultView[0].ViewCategory,
            IsDefault: defaultView[0].IsDefault,
            ViewGUID: defaultView[0].ViewGUID,
            ViewName: defaultView[0].ViewName,
            Icon: "",
            ItemType: 0,
            Scope: loadGlobal ? "Global" : "Personal"
          };
        } else {
          theAdvancedSummariesTab.CurrentSelectedItem.CurrentView.ViewCategory =
            defaultView[0].ViewCategory;
          theAdvancedSummariesTab.CurrentSelectedItem.CurrentView.IsDefault =
            defaultView[0].IsDefault;
          theAdvancedSummariesTab.CurrentSelectedItem.CurrentView.ViewGUID =
            defaultView[0].ViewGUID;
          theAdvancedSummariesTab.CurrentSelectedItem.CurrentView.ViewName =
            defaultView[0].ViewName;
        }
      }

      const allviews: IGridViewMenuItem[] = [];

      $.each(realToolbar.GetGlobalViews(), function (key, val) {
        val.Scope = "Global";
        if (val.IsDefault) {
          val.Load = loadGlobal;
        } else {
          val.Load = false;
        }

        if (val.ViewGUID) {
          allviews.push(val);
        }
      });

      $.each(realToolbar.GetPersonalViews(), function (key, val) {
        val.Scope = "Personal";
        if (val.IsDefault) {
          val.Load = !loadGlobal;
        } else {
          val.Load = false;
        }

        if (val.ViewGUID) {
          allviews.push(val);
        }
      });

      theAdvancedSummariesTab.CurrentSelectedItem.ViewList = [];

      $.each(allviews, function (key, val) {
        val.ItemType =
          theAdvancedSummariesTab.MyBICenter.CurrentSelectedItem.Type;
        val.Icon = "dx-icon tdfitem" + val.ItemType;
        theAdvancedSummariesTab.CurrentSelectedItem.ViewList.push(val);
      });
    } else {
      loaded = true;
    }

    if (!loaded) {
      theAdvancedSummariesTab.AddTabs(loadGlobal ? "Global" : "Personal");
    }

    if (!realToolbar.ViewSelector) {
      realToolbar.ViewSelector = $("#" + realToolbar.ViewSelectorID).dxLookup(
        "instance"
      );
    }

    if (
      theAdvancedSummariesTab.CurrentSelectedItem.CurrentView &&
      theAdvancedSummariesTab.CurrentSelectedItem.CurrentView.ViewGUID
    ) {
      realToolbar.ViewSelector.option(
        "value",
        theAdvancedSummariesTab.CurrentSelectedItem.CurrentView.ViewGUID
      );
    } else {
      realToolbar.ViewSelector.element().addClass("flash");
    }
  }

  public AddTabs(currentScope) {
    const theAdvancedSummariesTab = this;

    const source: DevExpress.data.DataSource = theAdvancedSummariesTab.AdvFieldTabPanel.option(
      "dataSource"
    );
    const store: DevExpress.data.ArrayStore = theAdvancedSummariesTab.AdvFieldTabPanel.option(
      "dataSource"
    ).store();
    $.each(theAdvancedSummariesTab.CurrentSelectedItem.ViewList, function (
      key,
      val
    ) {
      if (val.ViewGUID) {
        const newTab: any = {};
        newTab.View = val;
        newTab.ViewGUID = val.ViewGUID;
        newTab.Load = val.IsDefault && val.Scope === currentScope;
        newTab.ItemType = val.ItemType;
        newTab.AdvancedTypeCaption =
          theAdvancedSummariesTab.CurrentSelectedItem.Caption;
        newTab.AcctFieldIndex =
          theAdvancedSummariesTab.CurrentSelectedItem.Index;
        newTab.Key =
          theAdvancedSummariesTab.CurrentSelectedItem.Index +
          "_" +
          val.ViewGUID;

        store.insert(newTab);
      }
    });
    source.filter(theAdvancedSummariesTab.GetDefaultFilter());
    source.load();

    // theAdvancedSummariesTab.AdvFieldTabPanel.option("selectedIndex", theAdvancedSummariesTab.AdvFieldTabPanel.option("items").length - 1);
  }

  public GetDefaultFilter() {
    return [["Load", "=", true]];
  }

  public RefreshCurrentGrid() {
    const theAdvancedSummariesTab = this;
    const toolbar = theAdvancedSummariesTab.CurrentToolbar as GridToolbar;
    const container = toolbar.CurrentGrid.GridContainer;
    const itemType = theAdvancedSummariesTab.CurrentSelectedItem.Index;
    const item = theAdvancedSummariesTab.GetItem(itemType);

    $.each(item.LoadedGrids, function (key, val: IGridInfo) {
      if (
        val.GridView.ViewGUID ===
        (theAdvancedSummariesTab.CurrentToolbar as GridToolbar).CurrentGrid
          .GridDataResponse.ViewID
      ) {
        item.LoadedGrids.splice(key, 1);
        return false;
      }
      return false;
    });

    // TODO:  Does refresh work?

    const source: DevExpress.data.DataSource = toolbar.ViewSelector.option(
      "dataSource"
    );
    source
      .store()
      .byKey(toolbar.CurrentGrid.GridDataResponse.ViewID)
      .done(function (view: IGridViewMenuItem) {
        theAdvancedSummariesTab.LoadGrid(view, container);
      });
  }

  public GetItem(index: number) {
    const theAdvancedSummariesTab = this;
    const item = $.grep(theAdvancedSummariesTab.AdvancedItems.Items, function (
      val: IAdvancedGroupSummaryItem
    ) {
      return val.Index === index;
    });

    if (!item.length) {
      // throw error or warning or something
      return null;
    }

    return item[0];
  }
}

export class BI_AcctFieldSummaries extends BI_AdvancedGroupSummariesTab {
  public AdvancedTypeName: string = "AcctFields";
  public AdvancedSelectorPlaceholderMessage = "Choose an Account Field";
  public TabPanelPlaceholderMessage = "Choose an Account Field from the menu.";

  public acctFieldSummaries: IAdvancedGroupSummaryBasicItem[];
  public acctFieldItems: IAdvancedGroupSummaryItem[];
  public TabInfo: IAdvancedTabItems;

  constructor(centerObject: BiCenter) {
    super(centerObject);
  }

  public ClearCachedData() {
    const theAcctFieldSummariesTab = this;
    theAcctFieldSummariesTab.TabInfo = undefined;
    super.ClearCachedData();
  }

  public GetAdvancedItems() {
    const theAcctFieldSummariesTab = this;
    const dfd: JQueryDeferred<IAdvancedTabItems> = $.Deferred();

    if (theAcctFieldSummariesTab.TabInfo) {
      return dfd.resolve(theAcctFieldSummariesTab.TabInfo);
    } else {
      const request = new TDFRequest({
        url: "/bi/GenericGroupSummariesList",
        type: "POST",
        data: {}
      });
      request.MakeRequest().done(function (response) {
        // Save Response for future use
        theAcctFieldSummariesTab.acctFieldSummaries =
          response.GenericFields;
        theAcctFieldSummariesTab.acctFieldItems = [];

        // Set up the items collection for the acct fields
        $.each(theAcctFieldSummariesTab.acctFieldSummaries, function (
          index,
          value
        ) {
          if (value) {
            const item: IAdvancedGroupSummaryItem = {
              Caption: value.Caption,
              Index: value.Index,
              ViewList: undefined, // don't initialize this as it determines whether we need to set this or not to the list of views.
              LoadedGrids: []
            };
            theAcctFieldSummariesTab.acctFieldItems.push(item);
          }
        });

        // Add count on Main Center Menu
        theAcctFieldSummariesTab.MyBICenter.IncrementBadge();

        theAcctFieldSummariesTab.TabInfo = {
          AdvancedSummaries: theAcctFieldSummariesTab.acctFieldItems,
          Items: theAcctFieldSummariesTab.acctFieldItems
        };
        return dfd.resolve(theAcctFieldSummariesTab.TabInfo);
      });
    }
    return dfd.promise();
  }

  public GetRequestModel(viewGUID?: string): GridSetup.BaseGridRequestModel {
    const theAcctFieldSummariesTab = this;

    const requestArgs = new GridSetup.BIRequestArgs();
    requestArgs.GUID =
      viewGUID ||
      (theAcctFieldSummariesTab.CurrentToolbar as GridToolbar).DefaultView;
    requestArgs.ContainerID = theAcctFieldSummariesTab.MyBICenter.BISummarySelectionPanel.CurrentlySelectedItemsAsString();
    requestArgs.BISummaryType =
      theAcctFieldSummariesTab.MyBICenter.BISummarySelectionPanel.ActiveSelectionLevel;
    requestArgs.OptionalGroupSummaryOptions = {
      GroupingField: theAcctFieldSummariesTab.CurrentSelectedItem.Caption
    };

    const requestModel: GridSetup.BaseGridRequestModel = new GridSetup.BIRequestModel(
      requestArgs
    );
    return requestModel;
  }
}

export class BiColumnTemplateHandler {
  public eCenterType: CenterType;

  constructor(eCenterType: CenterType = 1) {
    this.eCenterType = eCenterType;
  }

  public BiColumnTemplateHandler(col: any) {
    const theColumnTemplateHandler = this;

    switch (col.itemTemplateType) {
      case "LoadBI_Invoice":
        col.cellTemplate = function (container, options) {
          $(
            "<a title'Show Invcoice Details' href='javascript:void (0)'>" +
            options.value +
            "</a>"
          )
            .on("click", function () {
              const item = new BI__ItemDetailInvoiceForm(
                options.value,
                theColumnTemplateHandler.eCenterType
              );
              item.Initialize();
            })
            .appendTo(container);
        };
        break;
      case "LoadBI_Order":
        col.cellTemplate = function (container, options) {
          $(
            "<a title'Show Order Details' href='javascript:void (0)'>" +
            options.value +
            "</a>"
          )
            .on("click", function () {
              const item = new BI__ItemDetailOrderForm(
                options.value,
                theColumnTemplateHandler.eCenterType
              );
              item.Initialize();
            })
            .appendTo(container);
        };
        break;
      case "LoadBI_Quote":
        col.cellTemplate = function (container, options) {
          $(
            "<a title'Show Quote Details' href='javascript:void (0)'>" +
            options.value +
            "</a>"
          )
            .on("click", function () {
              const item = new BI__ItemDetailQuoteForm(
                options.value,
                theColumnTemplateHandler.eCenterType
              );
              item.Initialize();
            })
            .appendTo(container);
        };
        break;
      case "LoadBI_PurchaseOrder":
        col.cellTemplate = function (container, options) {
          $(
            "<a title'Show Purchase Order Details' href='javascript:void (0)'>" +
            options.value +
            "</a>"
          )
            .on("click", function () {
              const item = new BI__ItemDetailPurchaseOrderForm(
                options.value,
                theColumnTemplateHandler.eCenterType
              );
              item.Initialize();
            })
            .appendTo(container);
        };
        break;
      case "LoadBI_VendorInvoice":
        col.cellTemplate = function (container, options) {
          $(
            "<a title'Show Vendor Invoice Details' href='javascript:void (0)'>" +
            options.value +
            "</a>"
          )
            .on("click", function () {
              const item = new BI__ItemDetailVendorInvoiceForm(
                options.value,
                theColumnTemplateHandler.eCenterType
              );
              item.Initialize();
            })
            .appendTo(container);
        };
        break;
    }
  }
}

export class BiColumnTemplateHandler_Drilldown extends BiColumnTemplateHandler {
  public ParentCenter: any /*BiCenter*/;

  constructor(eCenterType = 1, theCenter: any /*BiCenter*/) {
    super(eCenterType);

    this.ParentCenter = theCenter;
  }

  public BiColumnTemplateHandler(col: any) {
    const theTemplateHandler = this;

    switch (col.itemTemplateType) {
      case "LoadBI_DrillIn":
        col.cellTemplate = function (container, options) {
          $(
            "<a title'Show BI Drillin Details' href='javascript:void (0)'>" +
            options.text +
            "</a>"
          )
            .on("click", function () {
              const data = options.data;
              theTemplateHandler.ParentCenter.DrillHelper.DisplayDrillIn(
                options.column.dataField,
                options.value,
                data.ClassID,
                data.Description
              );
            })
            .appendTo(container);
        };
        break;
      case "LoadBI_DrillInFromGroupSummary":
        col.cellTemplate = function (container, options) {
          $(
            "<a title'Show BI Drillin Details' href='javascript:void (0)'>" +
            options.text +
            "</a>"
          )
            .on("click", function () {
              const crumb: BasicCrumbInfo = theTemplateHandler.ParentCenter.DrillHelper.GetCrumbInfoFromGroupSummaryRow(
                options.data
              );
              theTemplateHandler.ParentCenter.DrillHelper.DisplayDrillIn(
                options.column.dataField,
                options.value,
                crumb.Value,
                crumb.Description,
                crumb.ChildGridType,
                crumb.UseAsFilter
              );
            })
            .appendTo(container);
        };
        break;
      default:
        super.BiColumnTemplateHandler(col);
        break;
    }
  }
}

export class BI_BreadcrumbInfo {
  public Value: string;
  public Caption: string;
  public DrilldownItem: BI_DrilldownItem;
  public UseAsFilter: boolean = false;
  constructor(
    value: string,
    caption: string,
    drilldownItem: BI_DrilldownItem,
    useAsFilter = false
  ) {
    const crumb = this;
    crumb.Value = value;
    crumb.Caption = caption;
    crumb.DrilldownItem = drilldownItem;
    crumb.UseAsFilter = useAsFilter;
  }
}

export class BI_DrillHelper {
  public ParentCenter: any;
  public SummarySettingsSelectionPanel: BISummarySettingsSelector;

  public IsInitialized: boolean = false;
  public InitializationPromise: JQueryPromise<BI_DrillHelper>;
  public IsDrilldown: boolean = false;

  public DrillInfo: BI_DrilldownItem[];
  public CurrentDrilldownInfo: BI_DrilldownItem;
  public BreadcrumbHistory: BI_BreadcrumbInfo[] = [];
  public AccountList: string = "";
  public GoalType: number = -1;

  // uRLs
  public BIDrillModelURL: string = "/bio/drillinmenuitems/";

  constructor(parentCenter: any, goalType: number, accountList?: string) {
    const theDrillHelper: BI_DrillHelper = this;
    theDrillHelper.ParentCenter = parentCenter;
    theDrillHelper.SummarySettingsSelectionPanel =
      parentCenter.BISummarySelectionPanel;
    theDrillHelper.GoalType = goalType;
    if (accountList) {
      theDrillHelper.AccountList = accountList;
    }
  }

  public Initialize(): JQueryPromise<BI_DrillHelper> {
    const theDrillHelper: BI_DrillHelper = this;
    const displayDrillinDFD: JQueryDeferred<BI_DrillHelper> = $.Deferred();

    if (theDrillHelper.IsInitialized) {
      return displayDrillinDFD.promise(
        displayDrillinDFD.resolve(theDrillHelper)
      );
    }

    if (theDrillHelper.InitializationPromise != undefined) {
      return theDrillHelper.InitializationPromise;
    }
    theDrillHelper.DrillModel().done(function () {
      theDrillHelper
        .GetInitialBreadcrumbInfo()
        .done(function (initialCrumb: BI_BreadcrumbInfo) {
          theDrillHelper.BreadcrumbHistory = [];
          theDrillHelper.AddCrumb(initialCrumb);
          theDrillHelper.IsInitialized = true;
          return displayDrillinDFD.resolve(theDrillHelper);
        });
    });

    theDrillHelper.InitializationPromise = displayDrillinDFD.promise();
    return theDrillHelper.InitializationPromise;
  }

  public Reinitialize(): JQueryPromise<BI_DrillHelper> {
    const theDrillHelper: BI_DrillHelper = this;
    theDrillHelper.IsInitialized = false;
    theDrillHelper.InitializationPromise = undefined;
    return theDrillHelper.Initialize();
  }

  public GetInitialBreadcrumbInfo(): JQueryPromise<BI_BreadcrumbInfo> {
    const theDrillHelper: BI_DrillHelper = this;
    const breadcrumbDFD: JQueryDeferred<BI_BreadcrumbInfo> = $.Deferred();

    let levelColumn: string = "";
    const ids: string = theDrillHelper.SummarySettingsSelectionPanel.CurrentlySelectedItemsAsString();
    let caption: string = "";
    let childGridType: number;

    switch (theDrillHelper.SummarySettingsSelectionPanel.ActiveSelectionLevel) {
      case ISummary.SummaryInterfaces.cBSID.EnumType.AcctPkg:
        levelColumn = "AcctPkgID";
        childGridType = 8;
        break;
      case ISummary.SummaryInterfaces.cBSID.EnumType.AcctID:
        switch (theDrillHelper.ParentCenter.SummarySource) {
          case BSISummarySource.Contact:
            levelColumn = "BIContactID";
            break;
          case BSISummarySource.Opportunity:
            levelColumn = "R.OpportunityID";
            break;
          default:
            levelColumn = "AccountID";
        }
        childGridType = 4;
        break;
      case ISummary.SummaryInterfaces.cBSID.EnumType.AcctRoot:
        levelColumn = "AcctRootID";
        childGridType = 8;
        break;
      case ISummary.SummaryInterfaces.cBSID.EnumType.SalesRep:
        levelColumn = "SalesRepID";
        childGridType = 7;
        break;
      case ISummary.SummaryInterfaces.cBSID.EnumType.BillTo:
        levelColumn = "CompIDCustID";
        childGridType = 8;
        break;
      case ISummary.SummaryInterfaces.cBSID.EnumType.Master:
        levelColumn = "AV.BSIMasterAcctID";
        childGridType = 8;
        break;
      case ISummary.SummaryInterfaces.cBSID.EnumType.Custom:
        levelColumn = "CustomID";
        childGridType = 4;
        break;
      case ISummary.SummaryInterfaces.cBSID.EnumType.Level3:
        levelColumn = "Level3ID";
        childGridType = 8;
        break;
      case ISummary.SummaryInterfaces.cBSID.EnumType.Level4:
        levelColumn = "Level4ID";
        childGridType = 8;
        break;
      case ISummary.SummaryInterfaces.cBSID.EnumType.InvalidType:
      default:
        alert("Active Selection Level is not yet handled");
    }

    caption = theDrillHelper.SummarySettingsSelectionPanel.CurrentlySelectedItemsAsString();
    if (ids.indexOf(",") < 0) {
      caption = theDrillHelper.SummarySettingsSelectionPanel.CurrentlySelectedItemsAsArray()[0]
        .name;
    } else {
      caption =
        ISummary.SummaryInterfaces.cBSID.EnumType[
        theDrillHelper.SummarySettingsSelectionPanel.ActiveSelectionLevel
        ];
    }

    const drilldownItem: BI_DrilldownItem = new BI_DrilldownItem();
    drilldownItem.Caption =
      ISummary.SummaryInterfaces.cBSID.EnumType[
      theDrillHelper.SummarySettingsSelectionPanel.ActiveSelectionLevel
      ];
    drilldownItem.LevelColumn = levelColumn;
    // drilldownItem.GridItemType = BSIGrids        // TODO:
    drilldownItem.ChildGridType = childGridType;
    drilldownItem.DisallowedChildGridTypeChildren = [];
    drilldownItem.BILevelsUsed = [
      theDrillHelper.SummarySettingsSelectionPanel.ActiveSelectionLevel
    ];
    drilldownItem.Used = true;
    drilldownItem.Hidden = false;

    const theCrumb: BI_BreadcrumbInfo = new BI_BreadcrumbInfo(
      ids,
      caption,
      drilldownItem
    );
    return breadcrumbDFD.promise(breadcrumbDFD.resolve(theCrumb));
  }

  public DisplayDrilldown(
    crumbStarter?: BasicCrumbInfo,
    nextItem?: BI_DrilldownItem
  ): JQueryPromise<BI_DrillHelper> {
    const displayDrillinDFD: JQueryDeferred<BI_DrillHelper> = $.Deferred();
    const theDrillHelper: BI_DrillHelper = this;
    // require("./bi_drilldowndialog").then((BI_DrilldownDialog: any) => {

    theDrillHelper.IsDrilldown = true;

    theDrillHelper.Initialize().done(function () {
      if (crumbStarter) {
        const drilldownItems = theDrillHelper.DrillInfo.filter(
          value => value.ChildGridType === crumbStarter.ChildGridType
        );

        if (drilldownItems && drilldownItems.length > 0) {
          const starterCrumb = new BI_BreadcrumbInfo(
            crumbStarter.Value,
            crumbStarter.Description,
            drilldownItems[0]
          );
          starterCrumb.DrilldownItem.Used = true;
          theDrillHelper.AddCrumb(starterCrumb);
        }
      }

      const myDrillDown = new BI_DrilldownDialog(theDrillHelper);
      myDrillDown.DisplayDrilldown(nextItem).done(function (response) {
        return displayDrillinDFD.resolve(theDrillHelper);
      });
    });

    //});
    return displayDrillinDFD.promise(theDrillHelper);
  }

  public DisplayDrillIn(
    fieldName: string,
    drillinValue: string,
    crumbValue?: string,
    crumbDescription?: string,
    crumbChildGridType?: ChildGridTypes,
    crumbIsFilter?: boolean
  ): JQueryPromise<BI_DrillHelper> {
    const theDrillHelper: BI_DrillHelper = this;
    const displayDrillinDFD: JQueryDeferred<BI_DrillHelper> = $.Deferred();

    let newCrumb: BI_BreadcrumbInfo;
    if (!crumbIsFilter) {
      if (crumbValue && crumbDescription) {
        if (!crumbChildGridType) {
          crumbChildGridType =
            theDrillHelper.CurrentDrilldownInfo.ChildGridType;
        }

        const drillInfo: BI_DrilldownItem[] = theDrillHelper.DrillInfo.filter(
          function (
            value: BI_DrilldownItem,
            index: number,
            array: BI_DrilldownItem[]
          ) {
            return value.ChildGridType === crumbChildGridType;
          }
        );
        if (drillInfo && drillInfo.length > 0) {
          newCrumb = new BI_BreadcrumbInfo(
            crumbValue,
            crumbDescription,
            drillInfo[0]
          );
        }
      }
    } else {
      newCrumb = new BI_BreadcrumbInfo(
        crumbValue,
        crumbDescription,
        null,
        crumbIsFilter
      );
    }

    const myDrillIn = new BI_DrillInDialog_LineItems(theDrillHelper);
    myDrillIn
      .DisplayDrillin(fieldName, drillinValue, newCrumb)
      .done(function (response) {
        return displayDrillinDFD.resolve(theDrillHelper);
      });

    return displayDrillinDFD.promise(theDrillHelper);
  }

  public AddCrumb(breadcrumbInfo: BI_BreadcrumbInfo) {
    const theDrillHelper: BI_DrillHelper = this;
    theDrillHelper.BreadcrumbHistory.push(breadcrumbInfo);
    // TODO:  SHould this do the filtering and hiding of disallowed child grid items?
  }

  public TruncateDrilldownBreadcrumbs(index: number): number {
    const theDrillHelper: BI_DrillHelper = this;

    let getType: boolean = true;
    let lastSelectedChildGridType: number;

    $.each(theDrillHelper.BreadcrumbHistory, function (
      crumbIndex: number,
      crumb: BI_BreadcrumbInfo
    ) {
      if (crumbIndex > index) {
        // Set used back to false.
        crumb.DrilldownItem.Used = false;

        // Unhide drilldown items that were jumped over if they can be.
        if (crumb.DrilldownItem.DisallowedChildGridTypeChildren) {
          $.each(crumb.DrilldownItem.DisallowedChildGridTypeChildren, function (
            disallowedIndex: number,
            dissallowedItem: ChildGridTypes
          ) {
            let hidden: boolean = false;

            // 1.  Find the drilldownInfo item for the disallowedChildGridType.
            const matchingDrilldownItems: BI_DrilldownItem[] = theDrillHelper.DrillInfo.filter(
              function (
                value: BI_DrilldownItem,
                index: number,
                array: BI_DrilldownItem[]
              ) {
                return value.ChildGridType === dissallowedItem;
              }
            );

            // 2.  Loop through breadcrumbs remaining to see if it's hidden by any of those.
            $.each(theDrillHelper.BreadcrumbHistory, function (
              preCrumbIndex: number,
              preCrumb: BI_BreadcrumbInfo
            ) {
              if (preCrumbIndex <= crumbIndex) {
                if (
                  preCrumb.DrilldownItem.DisallowedChildGridTypeChildren.indexOf(
                    crumb.DrilldownItem.ChildGridType
                  ) >= 0
                ) {
                  hidden = true;
                }
              }
            });

            // 3.  Update the hidden property.
            $.each(matchingDrilldownItems, function (
              index,
              drilldownItem: BI_DrilldownItem
            ) {
              drilldownItem.Hidden = hidden;
            });
          });
        }

        if (getType) {
          // getType will be true the first item we hit greater than the index we are truncating to.
          // So we'll get it's child type, and then use that after truncating the breadcrumbs to set the picker back to the last used value.
          lastSelectedChildGridType = crumb.DrilldownItem.ChildGridType;
          getType = false;
        }
      }
    });

    theDrillHelper.BreadcrumbHistory = theDrillHelper.BreadcrumbHistory.slice(
      0,
      index + 1
    );
    return lastSelectedChildGridType;
  }

  public DoesItemTypeAllowDrilldown(itemType: number): boolean {
    let retVal: boolean = false;
    if (
      itemType === BSIGrids.Acct ||
      itemType === BSIGrids.AcctPkg ||
      itemType === BSIGrids.AcctRoot ||
      itemType === BSIGrids.Alt1 ||
      itemType === BSIGrids.Alt2 ||
      itemType === BSIGrids.Alt3 ||
      itemType === BSIGrids.County ||
      itemType === BSIGrids.CustomerID ||
      itemType === BSIGrids.MasterID ||
      itemType === BSIGrids.ProductGroup ||
      itemType === BSIGrids.Salesperson ||
      itemType === BSIGrids.ShipTo ||
      itemType === BSIGrids.Vendor ||
      itemType === BSIGrids.GapAnalysis ||
      // Contact BI
      itemType === BSIGrids.BSIContactAcctGroupSummary ||
      itemType === BSIGrids.BSIContactAcctPkgGroupSummary ||
      itemType === BSIGrids.BSIContactAlt1GroupSummary ||
      itemType === BSIGrids.BSIContactAlt2GroupSummary ||
      itemType === BSIGrids.BSIContactAlt3GroupSummary ||
      itemType === BSIGrids.BSIContactProductGroupGroupSummary ||
      itemType === BSIGrids.BSIContactVendorGroupSummary ||
      // Opportunity BI
      itemType === BSIGrids.BSIJobProductGroupGroupSummary ||
      itemType === BSIGrids.BSIJobAlt1GroupSummary ||
      itemType === BSIGrids.BSIJobAlt2GroupSummary ||
      itemType === BSIGrids.BSIJobAlt3GroupSummary ||
      itemType === BSIGrids.BSIJobAcctPkgGroupSummary ||
      itemType === BSIGrids.BSIJobAcctGroupSummary ||
      itemType === BSIGrids.BSIJobVendorGroupSummary ||
      // Vendor BI
      itemType === BSIGrids.VendorProductGroupGroupSummary ||
      itemType === BSIGrids.VendorAlt1GroupSummary ||
      itemType === BSIGrids.VendorAlt2GroupSummary ||
      itemType === BSIGrids.VendorAlt3GroupSummary ||
      itemType === BSIGrids.VendorAcctPkgGroupSummary
    ) {
      retVal = true;
    }
    return retVal;
  }

  public GetDrilldownFilter() {
    const theDrillHelper: BI_DrillHelper = this;

    return [
      [
        "BILevelsUsed",
        "contains",
        theDrillHelper.SummarySettingsSelectionPanel.ActiveSelectionLevel
      ],
      "&&",
      ["Used", "=", false],
      "&&",
      ["Hidden", "=", false],
      "&&",
      [
        "GridItemType",
        "<>",
        theDrillHelper.TranslateGroupSummaryToGridItemType(
          theDrillHelper.ParentCenter.CurrentSelectedItem.Type
        )
      ]
    ];
  }

  public TranslateGroupSummaryToGridItemType(itemType: number): number {
    const theDrillHelper: BI_DrillHelper = this;
    let translation: number = itemType;

    if (
      itemType == BSIGrids.BSIContactProductGroupGroupSummary ||
      itemType == BSIGrids.BSIJobProductGroupGroupSummary ||
      itemType == BSIGrids.VendorProductGroupGroupSummary
    ) {
      translation = BSIGrids.ProductGroup;
    } else if (
      itemType == BSIGrids.BSIContactVendorGroupSummary ||
      itemType == BSIGrids.BSIJobVendorGroupSummary
    ) {
      translation = BSIGrids.Vendor;
    } else if (
      itemType == BSIGrids.BSIContactAlt1GroupSummary ||
      itemType == BSIGrids.BSIJobAlt1GroupSummary ||
      itemType == BSIGrids.VendorAlt1GroupSummary
    ) {
      translation = BSIGrids.Alt1;
    } else if (
      itemType == BSIGrids.BSIContactAlt2GroupSummary ||
      itemType == BSIGrids.BSIJobAlt2GroupSummary ||
      itemType == BSIGrids.VendorAlt2GroupSummary
    ) {
      translation = BSIGrids.Alt2;
    } else if (
      itemType == BSIGrids.BSIContactAlt3GroupSummary ||
      itemType == BSIGrids.BSIJobAlt3GroupSummary ||
      itemType == BSIGrids.VendorAlt3GroupSummary
    ) {
      translation = BSIGrids.Alt3;
    } else if (
      itemType == BSIGrids.BSIContactAcctGroupSummary ||
      itemType == BSIGrids.BSIJobAcctGroupSummary
    ) {
      translation = BSIGrids.Acct;
    } else if (
      itemType == BSIGrids.BSIContactAcctPkgGroupSummary ||
      itemType == BSIGrids.BSIJobAcctPkgGroupSummary ||
      itemType == BSIGrids.VendorAcctPkgGroupSummary
    ) {
      translation = BSIGrids.AcctPkg;
    }

    return translation;
  }

  public CreateDrilldownColumn(): DevExpress.ui.dxDataGridColumn | any {
    const theDrillHelper: BI_DrillHelper = this;

    const drillDownCol: DevExpress.ui.dxDataGridColumn | any = {
      alignment: "center",
      width: GetDevice().isDevice ? "25px" : "40px",
      visible: true,
      allowEditing: false,
      fixed: true,
      cellTemplate(container: any, options: any) {
        const drilldownActionButton = $(
          "<div id='drilldownActionButton" + options.rowIndex + "'>+</div>"
        );
        const popoverItem = $("<div id='popover" + options.rowIndex + "' />");

        drilldownActionButton.appendTo(container);
        popoverItem.appendTo(container);

        drilldownActionButton.on("dxclick", function (e) {
          const childTypeSelection = popoverItem
            .dxActionSheet({
              dataSource: {
                store: new dxArrayStore({
                  data: theDrillHelper.DrillInfo
                }),
                filter: theDrillHelper.GetDrilldownFilter()
              },
              itemTemplate(itemData: any, itemIndex: number, itemElement: any) {
                return itemData.Caption;
              },
              onItemClick(event) {
                const data = options.data;
                const crumb: BasicCrumbInfo = theDrillHelper.GetCrumbInfoFromGroupSummaryRow(
                  options.data
                );
                const nextChild = event.itemData;
                theDrillHelper.DisplayDrilldown(crumb, nextChild);
              },
              showTitle: false,
              usePopover: !GetDevice().isDevice,
              target: popoverItem,
              visible: false
            })
            .dxActionSheet("instance");

          childTypeSelection.show();
        });
      }
    };

    return drillDownCol;
  }

  public GetCrumbInfoFromGroupSummaryRow(data): BasicCrumbInfo {
    const theDrillHelper: BI_DrillHelper = this;
    const crumb = new BasicCrumbInfo();

    switch (theDrillHelper.ParentCenter.CurrentSelectedItem.Type) {
      // Account BI Crumbs
      case BSIGrids.GenericGroupSummary:
        const otherItem: IAdvancedGroupSummaryItem = theDrillHelper.ParentCenter
          .AcctFieldSummariesTab
          .CurrentSelectedItem as IAdvancedGroupSummaryItem;
        crumb.ChildGridType = ChildGridTypes.acct;
        crumb.Value = otherItem.Caption;
        crumb.Description = data.GroupField;
        crumb.UseAsFilter = true;
        break;
      case BSIGrids.GapAnalysis:
        const gapItem: IGapGroupSummaryItem = theDrillHelper.ParentCenter
          .GapSummariesTab.CurrentSelectedItem as IGapGroupSummaryItem;

        switch (gapItem.RollUpType) {
          case GapRollUpType.AccountID:
            crumb.ChildGridType = ChildGridTypes.acct;
            crumb.Value = data.AccountID;
            crumb.Description = data.AccountName;
            break;
          case GapRollUpType.BSIMasterAcctID:
            crumb.ChildGridType = ChildGridTypes.master;
            crumb.Value = data.GroupField;
            crumb.Description = data.GroupField;
            break;
          case GapRollUpType.BSICustomerID:
            crumb.ChildGridType = ChildGridTypes.custid;
            crumb.Value = data.GroupField;
            crumb.Description = data.GroupField;
            break;
          case GapRollUpType.AcctPkgID:
            crumb.ChildGridType = ChildGridTypes.acctpkg;
            crumb.Value = data.acctpkgid;
            crumb.Description = data.AcctPkg;
            break;
          case GapRollUpType.SalesmanID:
            crumb.ChildGridType = ChildGridTypes.slsman;
            crumb.Value = data.SalesmanID;
            crumb.Description = data.Description;
            break;
          case GapRollUpType.AcctRootID:
            crumb.ChildGridType = ChildGridTypes.acctroot;
            crumb.Value = data.acctrootid;
            crumb.Description = data.acctroot;
            break;
        }
        break;
      case BSIGrids.Acct:
        crumb.ChildGridType = ChildGridTypes.acct;
        crumb.Value = data.AccountID;
        crumb.Description = data.AccountName;
        break;
      case BSIGrids.Alt1:
        crumb.ChildGridType = ChildGridTypes.alt1;
        crumb.Value = data.Alternate1;
        crumb.Description = data.Alternate1;
        break;
      case BSIGrids.Alt2:
        crumb.ChildGridType = ChildGridTypes.alt2;
        crumb.Value = data.Alternate2;
        crumb.Description = data.Alternate2;
        break;
      case BSIGrids.Alt3:
        crumb.ChildGridType = ChildGridTypes.alt3;
        crumb.Value = data.Alternate3;
        crumb.Description = data.Alternate3;
        break;
      // case BSIGrids.County:
      //    columnName = "";
      //    crumb.Value = data("");
      //    crumb.Description = data("");
      //    break;
      case BSIGrids.CustomerID:
        crumb.ChildGridType = ChildGridTypes.custid;
        crumb.Value = data.GroupField;
        crumb.Description = data.GroupField;
        break;
      case BSIGrids.ProductGroup:
        crumb.ChildGridType = ChildGridTypes.prod;
        crumb.Value = data.PRClassID;
        crumb.Description = data["Product Group"];
        break;
      case BSIGrids.ShipTo:
        crumb.ChildGridType = ChildGridTypes.shipto;
        // crumb.Value = data("");
        // crumb.Description = data("");
        break;
      case BSIGrids.Vendor:
        crumb.ChildGridType = ChildGridTypes.vend;
        crumb.Value = data.VendorID;
        crumb.Description = data.VendorID;
        break;
      case BSIGrids.Salesperson:
        crumb.ChildGridType = ChildGridTypes.slsman;
        crumb.Value = data.SalesmanID;
        crumb.Description = data.Description;
        break;
      case BSIGrids.AcctPkg:
        crumb.ChildGridType = ChildGridTypes.acctpkg;
        crumb.Value = data.AcctPkgID;
        crumb.Description = data.AcctPkg;
        break;
      case BSIGrids.MasterID:
        crumb.ChildGridType = ChildGridTypes.master;
        crumb.Value = data.GroupField;
        crumb.Description = data.GroupField;
        break;
      case BSIGrids.AcctRoot:
        crumb.ChildGridType = ChildGridTypes.acctroot;
        crumb.Value = data.acctrootid;
        crumb.Description = data.acctroot;
        break;

      // Contact BI Crumbs
      case BSIGrids.BSIContactAcctGroupSummary:
        crumb.ChildGridType = ChildGridTypes.acct;
        crumb.Value = data["tdf guid"];
        crumb.Description = data.AccountName;
        break;
      case BSIGrids.BSIContactAcctPkgGroupSummary:
        crumb.ChildGridType = ChildGridTypes.acctpkg;
        crumb.Value = data.AcctPkgID; // TODO:  This isn't in the data.
        crumb.Description = data.acctpkg;
        break;
      case BSIGrids.BSIContactAlt1GroupSummary:
        crumb.ChildGridType = ChildGridTypes.alt1;
        crumb.Value = data.alternate1;
        crumb.Description = data.alternate1;
        break;
      case BSIGrids.BSIContactAlt2GroupSummary:
        crumb.ChildGridType = ChildGridTypes.alt2;
        crumb.Value = data.alternate2;
        crumb.Description = data.alternate2;
        break;
      case BSIGrids.BSIContactAlt3GroupSummary:
        crumb.ChildGridType = ChildGridTypes.alt3;
        crumb.Value = data.alternate3;
        crumb.Description = data.alternate3;
        break;
      case BSIGrids.BSIContactProductGroupGroupSummary:
        crumb.ChildGridType = ChildGridTypes.prod;
        crumb.Value = data.prclassid;
        crumb.Description = data.description;
        break;
      case BSIGrids.BSIContactVendorGroupSummary:
        crumb.ChildGridType = ChildGridTypes.vend;
        crumb.Value = data.vendorid;
        crumb.Description = data.vendorname;
        break;

      // Opportunity BI Crumbs
      case BSIGrids.BSIJobAcctGroupSummary:
        crumb.ChildGridType = ChildGridTypes.acct;
        crumb.Value = data["TDF GUID"];
        crumb.Description = data.AccountName;
        break;
      case BSIGrids.BSIJobAcctPkgGroupSummary:
        crumb.ChildGridType = ChildGridTypes.acctpkg;
        crumb.Value = data.AcctPkgID;
        crumb.Description = data.AcctPkg;
        break;
      case BSIGrids.BSIJobAlt1GroupSummary:
        crumb.ChildGridType = ChildGridTypes.alt1;
        crumb.Value = data.Alternate1;
        crumb.Description = data.Alternate1;
        break;
      case BSIGrids.BSIJobAlt2GroupSummary:
        crumb.ChildGridType = ChildGridTypes.alt2;
        crumb.Value = data.Alternate2;
        crumb.Description = data.Alternate2;
        break;
      case BSIGrids.BSIJobAlt3GroupSummary:
        crumb.ChildGridType = ChildGridTypes.alt3;
        crumb.Value = data.Alternate3;
        crumb.Description = data.Alternate3;
        break;
      case BSIGrids.BSIJobProductGroupGroupSummary:
        crumb.ChildGridType = ChildGridTypes.prod;
        crumb.Value = data.PRClassID;
        crumb.Description = data["Product Group"];
        break;
      case BSIGrids.BSIJobVendorGroupSummary:
        crumb.ChildGridType = ChildGridTypes.vend;
        crumb.Value = data.VendorID;
        crumb.Description = data.VendorName;
        break;

      // Vendor BI Crumbs
      case BSIGrids.VendorAcctPkgGroupSummary:
        crumb.ChildGridType = ChildGridTypes.acctpkg;
        crumb.Value = data.AcctPkgID;
        crumb.Description = data.AcctPkg;
        break;
      case BSIGrids.VendorAlt1GroupSummary:
        crumb.ChildGridType = ChildGridTypes.alt1;
        crumb.Value = data.alternate1;
        crumb.Description = data.alternate1;
        break;
      case BSIGrids.VendorAlt2GroupSummary:
        crumb.ChildGridType = ChildGridTypes.alt2;
        crumb.Value = data.alternate2;
        crumb.Description = data.alternate2;
        break;
      case BSIGrids.VendorAlt3GroupSummary:
        crumb.ChildGridType = ChildGridTypes.alt3;
        crumb.Value = data.alternate3;
        crumb.Description = data.alternate3;
        break;
      case BSIGrids.VendorProductGroupGroupSummary:
        crumb.ChildGridType = ChildGridTypes.prod;
        crumb.Value = data.prclassid;
        crumb.Description = data.Description;
        break;
    }

    return crumb;
  }

  public DrillModel(): JQueryPromise<any> {
    const theDrillHelper: BI_DrillHelper = this;

    const drillModelDFD: JQueryDeferred<any> = $.Deferred();
    if (theDrillHelper.DrillInfo) {
      return drillModelDFD.promise(drillModelDFD.resolve(theDrillHelper));
    }

    const request = new TDFRequest({
      url: theDrillHelper.BIDrillModelURL,
      type: "GET",
      data: { biType: theDrillHelper.ParentCenter.SummarySource }
    });

    request.MakeRequest().done(function (response: any) {
      theDrillHelper.DrillInfo = response.DrilldownItems;
      drillModelDFD.resolve(theDrillHelper);
    });

    return drillModelDFD.promise(theDrillHelper);
  }
}

export class BI_DrilldownDialog {
  public DrillManager;
  public CenterType = -1;
  public DrilldownForm: Dialog;
  public DrilldownToolbar: GridToolbar;
  public DrilldownGrid: Grid;

  public _RequestModel: GridSetup.BIDrillDataRequestModel;
  public TDFContainerType: GridContainerTypes = GridContainerTypes.BIGroupSummaryDrilldown;

  // uRLs
  public BIDrillDataURL: string = "/GridData/BI";

  // element IDs
  public MyDialogID: string = "";
  public CustomIDString: string = ""; // todo:  Customize this.
  public DrilldownMainDivID: string = "";
  public DrilldownHeaderAreaID: string = "";
  public DrilldownBreadcrumbContainerID: string = "";
  public DrilldownToolbarContainerID: string = "";
  public DrilldownContentAreaID: string = "";
  public DrilldownGridContainerID: string = "";
  public DrilldownBreadCrumbToolbarID: string = "biBreadCrumbToolbar";
  public DrilldownBreadCrumbToolbarTypeSelectorID: string = "biBreadCrumbTypeSelector";
  public DrilldownBreadcrumbListID: string = "biBreadCrumbList";
  public DrilldownTagCloudID: string = "";

  public InitialDropdownItem;

  constructor(manager) {
    const theDrilldownDialog: BI_DrilldownDialog = this;
    theDrilldownDialog.DrillManager = manager;

    theDrilldownDialog.SetIDs();
  }

  public SetIDs() {
    const theDrilldownDialog: BI_DrilldownDialog = this;
    // todo:  Further customize these IDs to be very unique.
    const uniquePiece: string =
      String(
        theDrilldownDialog.DrillManager.SummarySettingsSelectionPanel
          .ActiveFolderLevel
      ) +
      "_" +
      Math.ceil(Math.random() * 999 + 1).toString();
    theDrilldownDialog.MyDialogID = "BI_Drilldown_Dialog_" + uniquePiece;

    theDrilldownDialog.DrilldownMainDivID =
      "BI_Drilldown_MainDiv_" + uniquePiece;
    theDrilldownDialog.DrilldownHeaderAreaID =
      "BI_Drilldown_HeaderArea_" + uniquePiece;
    theDrilldownDialog.DrilldownToolbarContainerID =
      "BI_Drilldown_ToolbarContainer_" + uniquePiece;
    theDrilldownDialog.DrilldownContentAreaID =
      "BI_Drilldown_ContentArea_" + uniquePiece;
    theDrilldownDialog.DrilldownGridContainerID =
      "BI_Drilldown_GridContainer_" + uniquePiece;
    theDrilldownDialog.DrilldownBreadcrumbContainerID =
      "BI_Drilldown_BreadcrumbContainer_" + uniquePiece;

    theDrilldownDialog.DrilldownBreadCrumbToolbarID =
      "BI_BreadCrumbToolbar_" + uniquePiece;
    theDrilldownDialog.DrilldownBreadCrumbToolbarTypeSelectorID =
      "BI_BreadCrumbTypeSelector_" + uniquePiece;
    theDrilldownDialog.DrilldownBreadcrumbListID =
      "BI_BreadCrumbList_" + uniquePiece;
    theDrilldownDialog.DrilldownTagCloudID =
      "BI_DrilldownTagCloud_" + uniquePiece;
  }
  public DisplayDrilldown(
    initialDropdownItem?: BI_DrilldownItem
  ): JQueryPromise<BI_DrilldownDialog> {
    const theDrilldownDialog: BI_DrilldownDialog = this;
    const displayDrilldownDFD: JQueryDeferred<
      BI_DrilldownDialog
    > = $.Deferred();
    theDrilldownDialog.InitialDropdownItem = initialDropdownItem;

    const dialogMessage = $("<div />")
      .attr("id", theDrilldownDialog.DrilldownMainDivID)
      .append(
        $("<div />")
          .attr("id", theDrilldownDialog.DrilldownHeaderAreaID)
          .append(
            $("<div />").attr(
              "id",
              theDrilldownDialog.DrilldownBreadcrumbContainerID
            )
          )
          .append(
            $("<div />").attr(
              "id",
              theDrilldownDialog.DrilldownToolbarContainerID
            )
          )
      )
      .append(
        $("<div />")
          .attr("id", theDrilldownDialog.DrilldownContentAreaID)
          .css("padding", "3px")
          .append(
            $("<div />").attr("id", theDrilldownDialog.DrilldownGridContainerID)
          )
      );

    theDrilldownDialog.DrilldownForm = new Dialog({
      size: "size-wide",
      id: theDrilldownDialog.MyDialogID,
      title: "Drill-Down Summary Details", // todo:  Or Drill-Down???
      closable: true,
      body: dialogMessage,
      type: "type-primary",
      onHiding(e) {
        theDrilldownDialog.DrillManager.TruncateDrilldownBreadcrumbs(0);
      }
    });

    theDrilldownDialog.DrilldownForm.open().done(function () {
      theDrilldownDialog.InitializeDrilldownBreadcrumbs();
      displayDrilldownDFD.resolve();
    });

    return displayDrilldownDFD.promise(theDrilldownDialog);
  }

  public GetChildTypeDrilldownFilter() {
    const theDrilldownDialog: BI_DrilldownDialog = this;
    return [
      [
        "BILevelsUsed",
        "contains",
        theDrilldownDialog.DrillManager.SummarySettingsSelectionPanel
          .ActiveSelectionLevel
      ],
      "&&",
      ["Used", "=", false],
      "&&",
      ["Hidden", "=", false]
    ];
  }

  public GetNextChildTypeFilter() {
    const theDrilldownDialog: BI_DrilldownDialog = this;
    return [
      [
        "BILevelsUsed",
        "contains",
        theDrilldownDialog.DrillManager.SummarySettingsSelectionPanel
          .ActiveSelectionLevel
      ],
      "&&",
      ["Used", "=", false],
      "&&",
      ["Hidden", "=", false],
      "&&",
      [
        "ChildGridType",
        "<>",
        theDrilldownDialog.DrillManager.CurrentDrilldownInfo.ChildGridType
      ]
      // "&&",
      // This is not working because the first item in filter expression is the getter and the 3rd item is the value.
      // [
      //    "!",
      //    [theDrilldownDialog.CurrentDrilldownInfo.DisallowedChildGridTypeChildren, "contains", "ChildGridType"]
      // ]
    ];
  }

  public InitializeDrilldownBreadcrumbs() {
    const theDrilldownDialog: BI_DrilldownDialog = this;

    $("#" + theDrilldownDialog.DrilldownBreadcrumbContainerID).append(
      "<div id='" + theDrilldownDialog.DrilldownBreadCrumbToolbarID + "' />"
    );

    const toolbarItems = [
      {
        locateInMenu: "auto",
        location: "before",
        template(itemData: any, itemIndex: number, itemElement: any) {
          const html =
            "<div id='" +
            theDrilldownDialog.DrilldownTagCloudID +
            "' class='biTagCloud'><small><ol id='" +
            theDrilldownDialog.DrilldownBreadcrumbListID +
            "' class='breadcrumb'/></small></div>";
          itemElement.append(html);
        }
      },
      {
        locateInMenu: "auto",
        location: "after",
        widget: "dxSelectBox",
        options: {
          elementAttr: {
            id: theDrilldownDialog.DrilldownBreadCrumbToolbarTypeSelectorID
          },
          dataSource: theDrilldownDialog.GetChildTypePickerDataSource(),
          displayExpr: "Caption",
          title: "Choose a type",
          // value: theDrilldownDialog.DrilldownInfo[0],     // Don't set the value here because it might be filtered out.  Instead, onMenuload, we select the first item in the drop down.
          onValueChanged(e: any) {
            theDrilldownDialog.DrillManager.CurrentDrilldownInfo = e.value;
            // Changing the Child Type will cause the grid to need to re-load.  I am clearing the grid so it's not confusing if it hasn't reloaded or not.
            // I ended up emptying the grid content and re-adding the div for the grid because if I simply emptied the GridContainer, the dxDataGrid would not show back up.
            $("#" + theDrilldownDialog.DrilldownContentAreaID).empty();
            $("#" + theDrilldownDialog.DrilldownContentAreaID).append(
              $("<div />").attr(
                "id",
                theDrilldownDialog.DrilldownGridContainerID
              )
            );

            theDrilldownDialog
              .DrilldownToolbarModel()
              .done(function (toolbarResponse) {
                theDrilldownDialog.GetDrilldownRequest().done(function () {
                  theDrilldownDialog.DrilldownToolbar.GridDataRequestModel =
                    theDrilldownDialog._RequestModel;
                  theDrilldownDialog.DrilldownToolbar.RenderAndLoad();
                });
              });
          }
        }
      }
    ];

    const toolbarOptions2 = {
      dataSource: {
        store: toolbarItems
      },
      onContentReady(e) {
        theDrilldownDialog.BuildDrilldownBreadcrumbs();
        const childPicker: DevExpress.ui.dxSelectBox | any = $(
          "#" + theDrilldownDialog.DrilldownBreadCrumbToolbarTypeSelectorID
        ).dxSelectBox("instance");
        childPicker._dataSource.load();
        childPicker.repaint();

        if (theDrilldownDialog.InitialDropdownItem) {
          childPicker.option("value", theDrilldownDialog.InitialDropdownItem);
        } else {
          childPicker.option(
            "value",
            childPicker.option("dataSource").items()[0]
          );
        }
      }
    };
    $("#" + theDrilldownDialog.DrilldownBreadCrumbToolbarID).dxToolbar(
      toolbarOptions2
    );
  }

  public RebuildDrilldownBreadCrumbs() {
    const theDrilldownDialog: BI_DrilldownDialog = this;
    $("#" + theDrilldownDialog.DrilldownBreadcrumbListID).empty();
    theDrilldownDialog.BuildDrilldownBreadcrumbs();
  }

  public BuildDrilldownBreadcrumbs() {
    const theDrilldownDialog: BI_DrilldownDialog = this;
    const count = theDrilldownDialog.DrillManager.BreadcrumbHistory.length;

    $.each(theDrilldownDialog.DrillManager.BreadcrumbHistory, function (
      index: number,
      breadcrumbInfo: BI_BreadcrumbInfo
    ) {
      const crumb: JQuery = $("<li />");
      let link: JQuery = $("<span />").text(breadcrumbInfo.Caption);

      if (index < count - 1) {
        link = $("<a />").text(breadcrumbInfo.Caption);
        link.addClass("breadButton").css("cursor", "pointer");
        crumb.on("click", function (ev) {
          const newChildItem: number = theDrilldownDialog.DrillManager.TruncateDrilldownBreadcrumbs(
            index
          );

          theDrilldownDialog.RebuildDrilldownBreadCrumbs();
          theDrilldownDialog.RefreshChildTypePicker();

          // select the old top item after refreshing the child type picker.
          const childTypePicker: DevExpress.ui.dxSelectBox | any = $(
            "#" + theDrilldownDialog.DrilldownBreadCrumbToolbarTypeSelectorID
          ).dxSelectBox("instance");
          childTypePicker.option(
            "value",
            childTypePicker._dataSource._items.filter(function (
              element: any,
              index: number,
              array: any[]
            ) {
              return element.ChildGridType === newChildItem;
            })[0]
          );
        });
      }
      crumb.append(link);
      $("#" + theDrilldownDialog.DrilldownBreadcrumbListID).append(crumb);
    });
  }

  public RefreshCurrentGrid() {
    const theDrilldownDialog: BI_DrilldownDialog = this;
    theDrilldownDialog.DrilldownToolbar.RefreshCurrentGrid(theDrilldownDialog);
  }

  public DrilldownToolbarModel(): JQueryPromise<BI_DrilldownDialog> {
    const theDrilldownDialog: BI_DrilldownDialog = this;
    const toolbarDFD: JQueryDeferred<BI_DrilldownDialog> = $.Deferred();

    const request = new TDFRequest({
      url: "/core/user/GetToolbarInfo/",
      type: "GET",
      data: {
        type: theDrilldownDialog.DrillManager.CurrentDrilldownInfo.GridItemType,
        containertype: theDrilldownDialog.TDFContainerType
      }
    });

    request.MakeRequest().done(function (response: any) {
      response.GridDataRequestType = "POST";
      theDrilldownDialog.DrilldownToolbar = new GridToolbar(response);
      theDrilldownDialog.DrilldownToolbar.MakeColumns = theDrilldownDialog.MakeDrilldownColumns.bind(
        theDrilldownDialog
      );
      theDrilldownDialog.DrilldownToolbar.ToolbarContainerID =
        theDrilldownDialog.DrilldownToolbarContainerID;
      theDrilldownDialog.DrilldownToolbar.MakeGrid = theDrilldownDialog.MakeDrilldownGrid.bind(
        theDrilldownDialog
      );
      theDrilldownDialog.DrilldownToolbar.OnViewChanged = theDrilldownDialog.OnViewChanged.bind(
        theDrilldownDialog
      );
      if (!theDrilldownDialog.DrilldownToolbar.CurrentView) {
        theDrilldownDialog.DrilldownToolbar.CurrentView =
          theDrilldownDialog.DrilldownToolbar.DefaultView;
      }

      toolbarDFD.resolve(theDrilldownDialog);
    });

    return toolbarDFD.promise(theDrilldownDialog);
  }

  public OnViewChanged(data) {
    const theDrilldownDialog: BI_DrilldownDialog = this;

    theDrilldownDialog.DrilldownToolbar.GetGridRequestModel(data).done(
      response => {
        if (response) {
          theDrilldownDialog.MakeDrilldownGrid(response);
        }
      }
    );
  }

  public RefreshChildTypePicker() {
    const theDrilldownDialog: BI_DrilldownDialog = this;

    const childTypePicker: DevExpress.ui.dxSelectBox | any = $(
      "#" + theDrilldownDialog.DrilldownBreadCrumbToolbarTypeSelectorID
    ).dxSelectBox("instance");
    childTypePicker._dataSource = theDrilldownDialog.GetChildTypePickerDataSource();
    (childTypePicker._dataSource as any).reload();
    childTypePicker.repaint();
  }

  public GetChildTypePickerDataSource() {
    const theDrilldownDialog: BI_DrilldownDialog = this;

    return new dxDataSource({
      store: new dxArrayStore({
        data: theDrilldownDialog.DrillManager.DrillInfo
      }),
      filter: theDrilldownDialog.GetChildTypeDrilldownFilter()
    });
  }

  public GetDrilldownCol() {
    const theDrilldownDialog = this;

    return {
      alignment: "center",
      width: GetDevice().isDevice ? "25px" : "40px",
      visible: true,
      allowEditing: false,
      fixed: true,
      cellTemplate(container: any, options: any) {
        const childTypePicker: DevExpress.ui.dxSelectBox | any = $(
          "#" + theDrilldownDialog.DrilldownBreadCrumbToolbarTypeSelectorID
        ).dxSelectBox("instance");

        if (childTypePicker._dataSource._items.length > 1) {
          const drilldownActionButton = $(
            "<div id='drilldownActionButton" + options.rowIndex + "'>+</div>"
          );
          const popoverItem = $("<div id='popover" + options.rowIndex + "' />");

          drilldownActionButton.appendTo(container);
          popoverItem.appendTo(container);

          drilldownActionButton.on("dxclick", function (e) {
            const childTypeSelection = popoverItem
              .dxActionSheet({
                dataSource: {
                  store: new dxArrayStore({
                    data: theDrilldownDialog.DrillManager.DrillInfo.filter(
                      function (value, index, array) {
                        return (
                          theDrilldownDialog.DrillManager.CurrentDrilldownInfo.DisallowedChildGridTypeChildren.indexOf(
                            value.ChildGridType
                          ) < 0
                        );
                      }
                    )
                  }),
                  filter: theDrilldownDialog.GetNextChildTypeFilter()
                },
                itemTemplate(
                  itemData: any,
                  itemIndex: number,
                  itemElement: any
                ) {
                  return itemData.Caption;
                },
                onItemClick(e) {
                  const data = options.data;

                  theDrilldownDialog.DrillManager.CurrentDrilldownInfo.Used = true;

                  if (
                    theDrilldownDialog.DrillManager.CurrentDrilldownInfo
                      .DisallowedChildGridTypeChildren.length > 0
                  ) {
                    const items = theDrilldownDialog.DrillManager.DrillInfo.filter(
                      function (element: any, index: number, array: any[]) {
                        // return the items that are disallowed.
                        return (
                          theDrilldownDialog.DrillManager.CurrentDrilldownInfo.DisallowedChildGridTypeChildren.indexOf(
                            element.ChildGridType
                          ) >= 0
                        );
                      }
                    );

                    $.each(items, function (index, value) {
                      value.Hidden = true;
                    });
                  }

                  const newCrumb: BI_BreadcrumbInfo = new BI_BreadcrumbInfo(
                    data.ClassID,
                    data.Description,
                    theDrilldownDialog.DrillManager.CurrentDrilldownInfo
                  );
                  theDrilldownDialog.DrillManager.AddCrumb(newCrumb);
                  theDrilldownDialog.RebuildDrilldownBreadCrumbs();
                  theDrilldownDialog.RefreshChildTypePicker();

                  // select the next top item after refreshing the child type picker.
                  const childTypePicker: DevExpress.ui.dxSelectBox | any = $(
                    "#" +
                    theDrilldownDialog.DrilldownBreadCrumbToolbarTypeSelectorID
                  ).dxSelectBox("instance");
                  childTypePicker.option(
                    "value",
                    childTypePicker._dataSource._items.filter(function (
                      element: any,
                      index: number,
                      array: any[]
                    ) {
                      return element.ChildGridType === e.itemData.ChildGridType;
                    })[0]
                  );
                },
                showTitle: false,
                usePopover: !GetDevice().isDevice,
                target: popoverItem,
                visible: false
              })
              .dxActionSheet("instance");

            childTypeSelection.show();
          });
        }
      }
    };
  }

  public GridOptions() {
    const theDrilldownDialog: BI_DrilldownDialog = this;

    const overriddenGridOptions: DevExpress.ui.dxDataGridOptions = {};

    if (
      theDrilldownDialog.DrillManager.CurrentDrilldownInfo.ChildGridType !=
      ChildGridTypes.acct
    ) {
      overriddenGridOptions.selection = {
        mode: "single",
        showCheckBoxesMode: "none"
      };

      overriddenGridOptions.stateStoring = {};

      overriddenGridOptions.onRowClick = function (e) {
        // overriding this method that otherwise is defaulted.
      };

      overriddenGridOptions.onSelectionChanged = function (e) {
        // overriding this method that otherwise is defaulted.
      };
    }

    overriddenGridOptions.onContentReady = function (e) {
      const id = DomSafeID(theDrilldownDialog.DrilldownGridContainerID);

      $(window).on("orientationchange", function () {
        if (devices.orientation() === "landscape") {
          $("#" + id).css("width", "100%");
          $("#recentitemcontainer").css("width", "100%");
        }
        // put this outside if, because some reason orientaion change on quote manager doesnt read landscape?
        $("#quotemanagergrid").css("width", "100%");
      });

      if (theDrilldownDialog._RequestModel) {
        const btnNextPage = e.element.find(".dx-next-button");
        const btnPrevPage = e.element.find(".dx-prev-button");
        const pageSizes = e.element.find(".dx-page-sizes");
        const grid = e.component as DevExpress.ui.dxDataGrid;

        $(btnNextPage).off("click");
        $(btnNextPage).on("click", function (event) {
          if ($(event.currentTarget).hasClass("dx-button-disable")) {
            return;
          }
          if (grid.pageIndex() === grid.pageCount() - 1) {
            event.stopPropagation();
            theDrilldownDialog._RequestModel.RequestArgs.page += 1;
            //    theDrilldownDialog.RemoveGridFromCache(theGridWOViews.ObjRef.CurrentSelectedItem.CurrentView);
            grid.pageIndex(0);
            theDrilldownDialog.RefreshCurrentGrid();
            // theDrilldownDialog.LoadGrid(theCenter.CurrentSelectedItem.CurrentView, e.element, false, theCenter.RequestModel.RequestArgs.page);
          }
        });

        $(btnPrevPage).off("click");
        $(btnPrevPage).on("click", function (event) {
          if ($(event.currentTarget).hasClass("dx-button-disable")) {
            return;
          }
          if (grid.pageIndex() === 0) {
            if (theDrilldownDialog._RequestModel.RequestArgs.page > 1) {
              theDrilldownDialog._RequestModel.RequestArgs.page -= 1;
              // theCenter.RemoveGridFromCache(theCenter.CurrentSelectedItem.CurrentView);
              const newIndex =
                Math.ceil(
                  theDrilldownDialog._RequestModel.RequestArgs.pageSize /
                  grid.pageSize()
                ) - 1;
              grid.pageIndex(newIndex);
              theDrilldownDialog.RefreshCurrentGrid();
              // theCenter.LoadGrid(theCenter.CurrentSelectedItem.CurrentView, e.element, false, theCenter.RequestModel.RequestArgs.page)
            }
          }
        });

        $(pageSizes).on("dxclick", function (e) {
          Preferences.SetPreference(
            "ClientGridPageSize",
            $(e.target).text(),
            "TDFMobile"
          );
        });

        if ($(btnNextPage).hasClass("dx-button-disable")) {
          if (
            theDrilldownDialog._RequestModel.RequestArgs.page <
            theDrilldownDialog.DrilldownGrid.GridDataResponse.Meta.TotalPages
          ) {
            btnNextPage.removeClass("dx-button-disable");
          }
        }

        if ($(btnPrevPage).hasClass("dx-button-disable")) {
          if (theDrilldownDialog._RequestModel.RequestArgs.page > 1) {
            btnPrevPage.removeClass("dx-button-disable");
          }
        }

        $(e.element)
          .find(".dx-header-row .dx-datagrid-action")
          .off("dxclick")
          .on("dxclick", function (event) {
            if (GetPreference("SortOnServer", "WebGrid")) {
              event.stopPropagation();
              let field;
              const cname = $(this)
                .find(".dx-datagrid-text-content")
                .text();

              $.each(grid.option("columns"), function (k, v) {
                if (v.caption) {
                  if (cname === v.caption) {
                    field = "[" + v.dataField.trim() + "]";
                  }
                } else {
                  if (cname === v.dataField) {
                    field = "[" + v.dataField.trim() + "]";
                  }
                }
              });

              if (
                theDrilldownDialog._RequestModel &&
                theDrilldownDialog._RequestModel.RequestArgs.Sorts &&
                theDrilldownDialog._RequestModel.RequestArgs.Sorts.length
              ) {
                alert("add to sorts array");
              } else {
                alert("add to sorts array");
              }

              //if (theDrilldownDialog._RequestModel.RequestArgs.sortDirection) {
              //  theDrilldownDialog._RequestModel.RequestArgs.sortDirection =
              //    theDrilldownDialog._RequestModel.RequestArgs.sortDirection.indexOf(
              //      "asc"
              //    ) === -1
              //      ? "asc"
              //      : "desc";
              //} else {
              //  theDrilldownDialog._RequestModel.RequestArgs.sortDirection =
              //    "asc";
              //}
              //theDrilldownDialog._RequestModel.RequestArgs.sortExpression = field;
              theDrilldownDialog.RefreshCurrentGrid();
            }
          });
      }
    };

    return overriddenGridOptions;
  }

  public MakeDrilldownGrid(data) {
    const theDrilldownDialog: BI_DrilldownDialog = this;

    const drillDownCol:
      | DevExpress.ui.dxDataGridColumn
      | any = theDrilldownDialog.GetDrilldownCol();
    data.GridView.Layout.Columns.unshift(drillDownCol);

    theDrilldownDialog.DrilldownGrid = new Grid(
      data,
      {
        GridContainer: $("#" + theDrilldownDialog.DrilldownGridContainerID),
        GridToolbar: theDrilldownDialog.DrilldownToolbar
      },
      theDrilldownDialog.GridOptions(),
      theDrilldownDialog,
      e => {
        theDrilldownDialog.DrilldownToolbar.SelectionChanged(e);
      },
      theDrilldownDialog.DrillManager.ParentCenter.ColumnTemplateHandler
    );

    theDrilldownDialog.DrilldownGrid.Render();
    theDrilldownDialog.DrilldownToolbar.CurrentGrid =
      theDrilldownDialog.DrilldownGrid;
  }

  public MakeDrilldownColumns(data) {
    const theDrilldownDialog: BI_DrilldownDialog = this;

    const drillDownCol:
      | DevExpress.ui.dxDataGridColumn
      | any = theDrilldownDialog.GetDrilldownCol();

    data.GridView.Layout.Columns.unshift(drillDownCol);

    return data.GridView.Layout.Columns;
  }

  public GetDrilldownRequest(viewGUID?: string) {
    const theDrilldownDialog: BI_DrilldownDialog = this;
    const getRequestDFD = $.Deferred();

    const currentDrilldownType = $(
      "#" + theDrilldownDialog.DrilldownBreadCrumbToolbarTypeSelectorID
    )
      .dxSelectBox("instance")
      .option("value");

    const requestArgs = new GridSetup.BIDrillRequestArgs();
    requestArgs.ItemType = currentDrilldownType.ChildGridType;
    requestArgs.GUID =
      viewGUID || theDrilldownDialog.DrilldownToolbar.DefaultView;
    requestArgs.ContainerID = theDrilldownDialog.DrillManager.SummarySettingsSelectionPanel.CurrentlySelectedItemsAsString();
    requestArgs.BISummaryType =
      theDrilldownDialog.DrillManager.SummarySettingsSelectionPanel.ActiveSelectionLevel;
    requestArgs.GoalType = theDrilldownDialog.DrillManager.GoalType;
    requestArgs.OptionalGroupSummaryOptions = { DrillDown: true };

    requestArgs.DataDrillSettings = {
      SummaryType:
        theDrilldownDialog.DrillManager.SummarySettingsSelectionPanel
          .ActiveSelectionLevel,
      LevelColumns: [], // ex:  ["Level4ID", "AccountID", "PRClassID", "VendorID", "Alternate1"],
      LevelValues: [], // ex:  ["{1103FA20-16B2-4CE2-9C28-994A795DAE2B}", "{6DFAA46D-D6C0-4A1E-8B03-679A092DBBC0}", "HN000050", "20428", "%"],
      LevelGridTypes: [], // ex:  [8, 4, 1, 0, 2],
      AccountIDs:
        theDrilldownDialog.DrillManager.SummarySettingsSelectionPanel
          .ActiveSelectionLevel === 3
          ? theDrilldownDialog.DrillManager.AccountList
          : "",
      SalesmanID: "",
      // Note:  Desktop doesn't seem to change this anywhere.  I think there's some salesman logic that isn't being used and/or tested by desktop and isn't needed.  Bill is looking into it, and I'm just going to set this to false all the time.
      SalesmanSummary: false, // theDrilldownDialog.SummarySettingsSelectionPanel.ActiveSelectionLevel === 3 ? true : false,
      IsVendorSummary:
        theDrilldownDialog.DrillManager.ParentCenter.CenterType ===
        CenterType.VendorBi
    };

    // loop through the breadcrumb history to add to the request args.
    $.each(theDrilldownDialog.DrillManager.BreadcrumbHistory, function (
      index: number,
      crumb: BI_BreadcrumbInfo
    ) {
      requestArgs.DataDrillSettings.LevelColumns.push(
        crumb.DrilldownItem.LevelColumn
      );
      requestArgs.DataDrillSettings.LevelValues.push(crumb.Value);
      requestArgs.DataDrillSettings.LevelGridTypes.push(
        crumb.DrilldownItem.ChildGridType
      );
    });

    // push the final wildcard option.
    requestArgs.DataDrillSettings.LevelColumns.push(
      currentDrilldownType.LevelColumn
    );
    requestArgs.DataDrillSettings.LevelValues.push("%");
    requestArgs.DataDrillSettings.LevelGridTypes.push(
      currentDrilldownType.ChildGridType
    );

    theDrilldownDialog._RequestModel = new GridSetup.BIDrillDataRequestModel(
      requestArgs
    );

    return getRequestDFD.promise(getRequestDFD.resolve(theDrilldownDialog));
  }
}

export class BI_DrilldownItem {
  public Caption: string;
  public LevelColumn: string;
  public GridItemType: BSIGrids;
  public ChildGridType: ChildGridTypes;
  public DisallowedChildGridTypeChildren: ChildGridTypes[];
  public BILevelsUsed: ISummary.SummaryInterfaces.cBSID.EnumType[];
  public Used?: boolean;
  public Hidden?: boolean;
}

export class BI_DrillInDialog_LineItems {
  // SummarySettingsSelectionPanel: BISummarySettingsSelector;
  public DrillManager: BI_DrillHelper;
  public DrillInFieldName: string = "";
  public GeneralFilter: string = "";
  public CenterType = -1;
  public DrillinForm: Dialog;
  public Toolbar: GridToolbar;
  public MyGrid: Grid;

  public _RequestModel: GridSetup.BIDrillDataRequestModel;
  public TDFContainerType: GridContainerTypes = GridContainerTypes.BIDrillIn;
  public GridType: BSIGrids;
  // AccountList: string = "";
  // GoalType: number = -1;

  // element IDs
  // CustomIDString: string = "";            // todo:  Customize this.
  public MainDivID: string = "";
  public HeaderAreaID: string = "";
  // BreadcrumbContainerID: string = "";
  public ToolbarContainerID: string = "";
  public ContentAreaID: string = "";
  public GridContainerID: string = "";
  public MyDialogID: string = "";

  public TheDrillinCrumb: BI_BreadcrumbInfo;
  // BreadCrumbToolbarID: string = "biBreadCrumbToolbar";
  // BreadCrumbToolbarTypeSelectorID: string = "biBreadCrumbTypeSelector";
  // BreadcrumbListID: string = "biBreadCrumbList";
  // TagCloudID: string = "";

  constructor(manager: BI_DrillHelper) {
    const theDrillInDialog: BI_DrillInDialog_LineItems = this;
    theDrillInDialog.DrillManager = manager;

    // theDrillInDialog.SummarySettingsSelectionPanel = summarySettingsSelectionPanel;
    // theDrillInDialog.BreadcrumbHistory = currentBreadcrumbs;
    // theDrillInDialog.GoalType = goalType;
    // if (accountList) {
    // theDrillInDialog.AccountList = accountList;
    // }
    theDrillInDialog.SetIDs();

    if (
      theDrillInDialog.DrillManager.ParentCenter.CenterType ===
      CenterType.ContactBi
    ) {
      theDrillInDialog.GridType = BSIGrids.BSIContactItemSalesHistory;
    } else if (
      theDrillInDialog.DrillManager.ParentCenter.CenterType === CenterType.OppBi
    ) {
      theDrillInDialog.GridType = BSIGrids.BSIJobLineItems;
    } else if (
      theDrillInDialog.DrillManager.ParentCenter.CenterType ===
      CenterType.VendorBi
    ) {
      theDrillInDialog.GridType = BSIGrids.VendorSalesLineItems;
    } else {
      theDrillInDialog.GridType = BSIGrids.LineItems;
    }
  }

  public SetIDs() {
    const theDrillInDialog: BI_DrillInDialog_LineItems = this;
    // todo:  Further customize these IDs to be very unique.
    const uniquePiece: string =
      String(
        theDrillInDialog.DrillManager.SummarySettingsSelectionPanel
          .ActiveFolderLevel
      ) +
      "_" +
      Math.ceil(Math.random() * 999 + 1).toString();
    theDrillInDialog.MyDialogID = "BI_DrillinLines_Dialog_" + uniquePiece;

    theDrillInDialog.MainDivID = "BI_DrillinLines_MainDiv_" + uniquePiece;
    theDrillInDialog.HeaderAreaID = "BI_DrillinLines_HeaderArea_" + uniquePiece;
    theDrillInDialog.ToolbarContainerID =
      "BI_DrillinLines_ToolbarContainer_" + uniquePiece;
    theDrillInDialog.ContentAreaID =
      "BI_DrillinLines_ContentArea_" + uniquePiece;
    theDrillInDialog.GridContainerID =
      "BI_DrillinLines_GridContainer_" + uniquePiece;

    // theDrillInDialog.BreadcrumbContainerID = "BI_DrillinLines_BreadcrumbContainer_" + uniquePiece;

    // theDrillInDialog.BreadCrumbToolbarID = "biBreadCrumbToolbar_" + uniquePiece;
    // theDrillInDialog.BreadCrumbToolbarTypeSelectorID = "biBreadCrumbTypeSelector_" + uniquePiece;
    // theDrillInDialog.BreadcrumbListID = "biBreadCrumbList_" + uniquePiece;
    // theDrillInDialog.TagCloudID = "bidrillinCloud_" + uniquePiece;
  }

  public GetTitle(
    fieldName: string,
    amount: string,
    drillInCrumb?: BI_BreadcrumbInfo
  ): JQueryPromise<string> {
    const theDrillInDialog: BI_DrillInDialog_LineItems = this;
    let typeDesc: string = "";
    let fieldDesc: string = fieldName;
    let checkForFirstDayOfPeriod: boolean = false;
    let checkForFirstDayOfQuarter: boolean = false;
    const today = new Date();

    switch (fieldName.toLowerCase()) {
      case "sales_2y_total":
        typeDesc = "Sales";
        fieldDesc = "2Y Total";
        break;
      case "sales_ly_total":
        typeDesc = "Sales";
        fieldDesc = "LY Total";
        break;
      case "sales_lytd":
        typeDesc = "Sales";
        fieldDesc = "LYTD";
        break;
      case "sales_ytd":
        typeDesc = "Sales";
        fieldDesc = "YTD";
        break;
      case "2yqtd":
        typeDesc = "Sales";
        fieldDesc = "2YQTD";
        break;
      case "lastquarter":
        typeDesc = "Sales";
        fieldDesc = "Last Quarter";
        break;
      case "lyqtd":
        typeDesc = "Sales";
        fieldDesc = "LYQTD";
        break;
      case "qtd":
        typeDesc = "Sales";
        fieldDesc = "QTD";
        checkForFirstDayOfQuarter = true;
        break;
      case "sales_2ymtd":
        typeDesc = "Sales";
        fieldDesc = "2YMTD";
        today.setFullYear(today.getFullYear() - 2);
        checkForFirstDayOfPeriod = true;
        break;
      case "lastmonth":
        typeDesc = "Sales";
        fieldDesc = "Last Month";
        break;
      case "sales_lymtd":
        typeDesc = "Sales";
        fieldDesc = "LYMTD";
        today.setFullYear(today.getFullYear() - 1);
        checkForFirstDayOfPeriod = true;
        break;
      case "sales_mtd":
        typeDesc = "Sales";
        fieldDesc = "MTD";
        checkForFirstDayOfPeriod = true;
        break;
      case "sales_day":
        typeDesc = "Sales";
        fieldDesc = "Value";
        break;
      case "profit_2y_total":
        typeDesc = "Profit";
        fieldDesc = "2Y";
        break;
      case "profit_ly_total":
        typeDesc = "Profit";
        fieldDesc = "LY Total";
        break;
      case "profit_lytd":
        typeDesc = "Profit";
        fieldDesc = "LYTD";
        checkForFirstDayOfPeriod = true;
        break;
      case "profit_ytd":
        typeDesc = "Profit";
        fieldDesc = "YTD";
        checkForFirstDayOfPeriod = true;
        break;
      case "2yqtd":
        typeDesc = "Profit";
        fieldDesc = "2YQTD";
        checkForFirstDayOfQuarter = true;
        break;
      case "lastquarter":
        typeDesc = "Profit";
        fieldDesc = "Last Quarter";
        break;
      case "lyqtd":
        typeDesc = "Profit";
        fieldDesc = "LYQTD";
        checkForFirstDayOfQuarter = true;
        break;
      case "qtd":
        typeDesc = "Profit";
        fieldDesc = "QTD";
        checkForFirstDayOfQuarter = true;
        break;
      case "profit_2ymtd":
        typeDesc = "Profit";
        fieldDesc = "2YMTD";
        today.setFullYear(today.getFullYear() - 2);
        checkForFirstDayOfPeriod = true;
        break;
      case "lastmonth":
        typeDesc = "Profit";
        fieldDesc = "Last Month";
        break;
      case "profit_lymtd":
        typeDesc = "Profit";
        fieldDesc = "LYMTD";
        today.setFullYear(today.getFullYear() - 1);
        checkForFirstDayOfPeriod = true;
        break;
      case "profit_mtd":
        typeDesc = "Profit";
        fieldDesc = "MTD";
        checkForFirstDayOfPeriod = true;
        break;
      case "profit_day":
        typeDesc = "Profit";
        fieldDesc = "Value";
        break;
    }

    let crumbText: string = "";
    if (drillInCrumb) {
      crumbText = drillInCrumb.Caption;
    }
    const drillInTitleDFD: JQueryDeferred<string> = $.Deferred();
    let titleString: string = `Drilling into ${crumbText} ${typeDesc} ${fieldDesc} of ${Globalize.formatCurrency(
      parseFloat(amount),
      CurrentUser.Currency
    )}`;

    if (checkForFirstDayOfPeriod) {
      let FirstDayOfPeriod: boolean = false;
      const dateStr = today.toDateString();

      new TDFRequest({
        url: TDFRequest.ApiPath + "/fiscalcalendar/IsFirstDayOfPeriod",
        data: { currentDate: today },
        type: "GET"
      })
        .MakeRequest()
        .done(function (response) {
          FirstDayOfPeriod = response;
          let FirstDayNotification: string = ` This Day is the First Day of the Period - No Invoice Data Exists For This Day (${dateStr}). ${typeDesc} ${fieldDesc} Summarized Value is as of Previous Night's Connector Run.`;
          if (!FirstDayOfPeriod) {
            FirstDayNotification = "";
          }
          titleString = titleString + `.${FirstDayNotification}`;
          drillInTitleDFD.resolve(titleString);
        });
    } else if (checkForFirstDayOfQuarter) {
      let FirstDayOfQuarter: boolean = false;
      // var today = new Date();
      const dateStr = today.toDateString();
      new TDFRequest({
        url: TDFRequest.ApiPath + "/fiscalcalendar/IsFirstDayOfQuarter",
        data: { currentDate: today },
        type: "GET"
      })
        .MakeRequest()
        .done(function (response) {
          FirstDayOfQuarter = response;
          let FirstDayNotification: string = ` Today is the First Day of the Quarter - No Invoice Data Exists For Today (${dateStr}). ${typeDesc} ${fieldDesc} Summarized Value is as of Previous Night's Connector Run.`;
          if (!FirstDayOfQuarter) {
            FirstDayNotification = "";
          }
          titleString = titleString + `.${FirstDayNotification}`;
          drillInTitleDFD.resolve(titleString);
        });
    } else {
      return drillInTitleDFD.promise(drillInTitleDFD.resolve(titleString));
    }

    return drillInTitleDFD.promise();
  }
  public DisplayDrillin(
    fieldName: string,
    drillinValue: string,
    drillInCrumb?: BI_BreadcrumbInfo
  ): JQueryPromise<BI_DrillInDialog_LineItems> {
    const theDrillInDialog: BI_DrillInDialog_LineItems = this;
    theDrillInDialog.DrillInFieldName = fieldName;
    if (drillInCrumb) {
      if (!drillInCrumb.UseAsFilter) {
        theDrillInDialog.TheDrillinCrumb = drillInCrumb;
      } else {
        theDrillInDialog.GeneralFilter = `[${drillInCrumb.Value}] = '${
          drillInCrumb.Caption
          }'`;
        if (!drillInCrumb.Caption) {
          drillInCrumb.Caption = theDrillInDialog.GeneralFilter;
        }
      }
    }

    const displayDrillinDFD: JQueryDeferred<
      BI_DrillInDialog_LineItems
    > = $.Deferred();

    const dialogMessage = $("<div />")
      .attr("id", theDrillInDialog.MainDivID)
      .append(
        $("<div />")
          .attr("id", theDrillInDialog.HeaderAreaID)
          .append($("<div />").attr("id", theDrillInDialog.ToolbarContainerID))
      )
      .append(
        $("<div />")
          .attr("id", theDrillInDialog.ContentAreaID)
          .css("padding", "3px")
          .append($("<div />").attr("id", theDrillInDialog.GridContainerID))
      );

    theDrillInDialog
      .GetTitle(fieldName, drillinValue, drillInCrumb)
      .done(function (drillInTitle) {
        theDrillInDialog.DrillinForm = new Dialog({
          size: "size-wide",
          id: theDrillInDialog.MyDialogID,
          title: drillInTitle,
          closable: true,
          body: dialogMessage,
          type: "type-primary"
        });

        theDrillInDialog.DrillinForm.open().done(function () {
          // theDrillInDialog.DrilldownModel().done(function () {
          // theDrillInDialog.GetInitialBreadcrumbInfo().done(function (initialCrumb: BI_BreadcrumbInfo) {
          //    theDrillInDialog.InitializeBreadcrumbs(initialCrumb);
          theDrillInDialog.ToolbarModel().done(function (toolbarResponse) {
            theDrillInDialog.GetRequest().done(function () {
              theDrillInDialog.Toolbar.GridDataRequestModel =
                theDrillInDialog._RequestModel;
              theDrillInDialog.Toolbar.RenderAndLoad();
              displayDrillinDFD.resolve();
            });
          });
          // });
          // });
        });
        return displayDrillinDFD.promise(theDrillInDialog);
      });

    return displayDrillinDFD.promise(theDrillInDialog);
  }

  public ToolbarModel(): JQueryPromise<BI_DrillInDialog_LineItems> {
    const theDrillInDialog: BI_DrillInDialog_LineItems = this;
    const toolbarDFD: JQueryDeferred<BI_DrillInDialog_LineItems> = $.Deferred();

    const request = new TDFRequest({
      url: "/core/user/GetToolbarInfo/",
      type: "GET",
      data: {
        type: theDrillInDialog.GridType,
        containertype: theDrillInDialog.TDFContainerType
      }
    });

    request.MakeRequest().done(function (response: any) {
      response.GridDataRequestType = "POST";
      theDrillInDialog.Toolbar = new GridToolbar(response);
      theDrillInDialog.Toolbar.ToolbarContainerID =
        theDrillInDialog.ToolbarContainerID;
      theDrillInDialog.Toolbar.MakeGrid = theDrillInDialog.MakeDrillInGrid.bind(
        theDrillInDialog
      );
      if (!theDrillInDialog.Toolbar.CurrentView) {
        theDrillInDialog.Toolbar.CurrentView =
          theDrillInDialog.Toolbar.DefaultView;
      }

      toolbarDFD.resolve(theDrillInDialog);
    });

    return toolbarDFD.promise();
  }

  public RefreshCurrentGrid() {
    const theDrillInDialog: BI_DrillInDialog_LineItems = this;
    theDrillInDialog.Toolbar.RefreshCurrentGrid(theDrillInDialog);
  }

  public GridOptions() {
    const theDrillInDialog: BI_DrillInDialog_LineItems = this;
    const overriddenGridOptions: DevExpress.ui.dxDataGridOptions = {
      selection: {
        mode: "single",
        showCheckBoxesMode: "none"
      },
      stateStoring: {},
      onRowClick(e) {
        // overriding this method that otherwise is defaulted.
      },
      onSelectionChanged(e) {
        // overriding this method that otherwise is defaulted.
      }
    };

    overriddenGridOptions.onContentReady = function (e) {
      const id = DomSafeID(theDrillInDialog.GridContainerID);

      $(window).on("orientationchange", function () {
        if (devices.orientation() === "landscape") {
          $("#" + id).css("width", "100%");
          $("#recentitemcontainer").css("width", "100%");
        }
        // put this outside if, because some reason orientaion change on quote manager doesnt read landscape?
        $("#quotemanagergrid").css("width", "100%");
      });

      if (theDrillInDialog._RequestModel) {
        const btnNextPage = e.element.find(".dx-next-button");
        const btnPrevPage = e.element.find(".dx-prev-button");
        const pageSizes = e.element.find(".dx-page-sizes");
        const grid = e.component as DevExpress.ui.dxDataGrid;

        $(btnNextPage).off("click");
        $(btnNextPage).on("click", function (event) {
          if ($(event.currentTarget).hasClass("dx-button-disable")) {
            return;
          }
          if (grid.pageIndex() === grid.pageCount() - 1) {
            event.stopPropagation();
            theDrillInDialog._RequestModel.RequestArgs.page += 1;
            grid.pageIndex(0);
            theDrillInDialog.RefreshCurrentGrid();
          }
        });

        $(btnPrevPage).off("click");
        $(btnPrevPage).on("click", function (event) {
          if ($(event.currentTarget).hasClass("dx-button-disable")) {
            return;
          }
          if (grid.pageIndex() === 0) {
            if (theDrillInDialog._RequestModel.RequestArgs.page > 1) {
              theDrillInDialog._RequestModel.RequestArgs.page -= 1;
              const newIndex =
                Math.ceil(
                  theDrillInDialog._RequestModel.RequestArgs.pageSize /
                  grid.pageSize()
                ) - 1;
              grid.pageIndex(newIndex);
              theDrillInDialog.RefreshCurrentGrid();
            }
          }
        });

        $(pageSizes).on("dxclick", function (e) {
          Preferences.SetPreference(
            "ClientGridPageSize",
            $(e.target).text(),
            "TDFMobile"
          );
        });

        if ($(btnNextPage).hasClass("dx-button-disable")) {
          if (
            theDrillInDialog._RequestModel.RequestArgs.page <
            theDrillInDialog.MyGrid.GridDataResponse.Meta.TotalPages
          ) {
            btnNextPage.removeClass("dx-button-disable");
          }
        }

        if ($(btnPrevPage).hasClass("dx-button-disable")) {
          if (theDrillInDialog._RequestModel.RequestArgs.page > 1) {
            btnPrevPage.removeClass("dx-button-disable");
          }
        }

        $(e.element)
          .find(".dx-header-row .dx-datagrid-action")
          .off("dxclick")
          .on("dxclick", function (event) {
            if (GetPreference("SortOnServer", "WebGrid")) {
              event.stopPropagation();
              let field;
              const cname = $(this)
                .find(".dx-datagrid-text-content")
                .text();

              $.each(grid.option("columns"), function (k, v) {
                if (v.caption) {
                  if (cname === v.caption) {
                    field = "[" + v.dataField.trim() + "]";
                  }
                } else {
                  if (cname === v.dataField) {
                    field = "[" + v.dataField.trim() + "]";
                  }
                }
              });

              if (
                theDrillInDialog._RequestModel &&
                theDrillInDialog._RequestModel.RequestArgs.Sorts &&
                theDrillInDialog._RequestModel.RequestArgs.Sorts.length
              ) {
                alert("add to sorts array");
              } else {
                alert("add to sorts array");
              }

              //if (theDrillInDialog._RequestModel.RequestArgs.sortDirection) {
              //  theDrillInDialog._RequestModel.RequestArgs.sortDirection =
              //    theDrillInDialog._RequestModel.RequestArgs.sortDirection.indexOf(
              //      "asc"
              //    ) === -1
              //      ? "asc"
              //      : "desc";
              //} else {
              //  theDrillInDialog._RequestModel.RequestArgs.sortDirection =
              //    "asc";
              //}
              //theDrillInDialog._RequestModel.RequestArgs.sortExpression = field;
              theDrillInDialog.RefreshCurrentGrid();
            }
          });
      }
    };

    return overriddenGridOptions;
  }

  public MakeDrillInGrid(data) {
    const theDrillInDialog: BI_DrillInDialog_LineItems = this;

    theDrillInDialog.MyGrid = new Grid(
      data,
      {
        GridContainer: $("#" + theDrillInDialog.GridContainerID),
        GridToolbar: theDrillInDialog.Toolbar
      },
      theDrillInDialog.GridOptions(),
      theDrillInDialog,
      () => { },
      theDrillInDialog.DrillManager.ParentCenter.ColumnTemplateHandler
    );
    theDrillInDialog.MyGrid.Render();
    theDrillInDialog.Toolbar.CurrentGrid = theDrillInDialog.MyGrid;
  }

  public GetRequest(viewGUID?: string) {
    const theDrillInDialog: BI_DrillInDialog_LineItems = this;
    const getRequestDFD = $.Deferred();

    // let currentDrilldownType = $("#" + theDrillInDialog.BreadCrumbToolbarTypeSelectorID).dxSelectBox("instance").option("value");

    const requestArgs = new GridSetup.BIDrillRequestArgs();
    requestArgs.ItemType = theDrillInDialog.GridType;
    requestArgs.GUID = viewGUID || theDrillInDialog.Toolbar.DefaultView;
    requestArgs.ContainerID = theDrillInDialog.DrillManager.SummarySettingsSelectionPanel.CurrentlySelectedItemsAsString();
    requestArgs.BISummaryType =
      theDrillInDialog.DrillManager.SummarySettingsSelectionPanel.ActiveSelectionLevel;
    // TODO:  Do I need this?
    // requestArgs.OptionalGroupSummaryOptions = { SummarySource: theBICenter.SummarySource };
    requestArgs.GoalType = theDrillInDialog.DrillManager.GoalType;
    requestArgs.filterExpression = theDrillInDialog.GeneralFilter;

    requestArgs.DataDrillSettings = {
      SummaryType:
        theDrillInDialog.DrillManager.SummarySettingsSelectionPanel
          .ActiveSelectionLevel,
      LevelColumns: [], // ex:  ["Level4ID", "AccountID", "PRClassID", "VendorID", "Alternate1"],
      LevelValues: [], // ex:  ["{1103FA20-16B2-4CE2-9C28-994A795DAE2B}", "{6DFAA46D-D6C0-4A1E-8B03-679A092DBBC0}", "HN000050", "20428", "%"],
      LevelGridTypes: [], // ex:  [8, 4, 1, 0, 2],
      AccountIDs:
        theDrillInDialog.DrillManager.SummarySettingsSelectionPanel
          .ActiveSelectionLevel === 3
          ? theDrillInDialog.DrillManager.AccountList
          : "",
      SalesmanID: "",
      SalesmanSummary: false, // Note:  Desktop doesn't seem to change this anywhere.  I think there's some salesman logic that isn't being used and/or tested by desktop and isn't needed.  Bill is looking into it, and I'm just going to set this to false all the time.
      IsVendorSummary:
        theDrillInDialog.DrillManager.ParentCenter.CenterType ===
        CenterType.VendorBi,
      DrillInField: theDrillInDialog.DrillInFieldName
    };

    // loop through the breadcrumb history to add to the request args.
    $.each(theDrillInDialog.DrillManager.BreadcrumbHistory, function (
      index: number,
      crumb: BI_BreadcrumbInfo
    ) {
      // Skip the first item for Drill INs.
      if (index != 0) {
        // The Salesman column is named differently in drillins than in drill downs.  So, this is a little hack to adjust it here.
        requestArgs.DataDrillSettings.LevelColumns.push(
          crumb.DrilldownItem.LevelColumn === "SalesmanID"
            ? "SalesPersonID"
            : crumb.DrilldownItem.LevelColumn
        );
        requestArgs.DataDrillSettings.LevelValues.push(crumb.Value);
        requestArgs.DataDrillSettings.LevelGridTypes.push(
          crumb.DrilldownItem.ChildGridType
        );
      }
    });

    // push the final drill-in option.
    if (theDrillInDialog.TheDrillinCrumb) {
      // The Salesman column is named differently in drillins than in drill downs.  So, this is a little hack to adjust it here.
      requestArgs.DataDrillSettings.LevelColumns.push(
        theDrillInDialog.TheDrillinCrumb.DrilldownItem.LevelColumn ===
          "SalesmanID"
          ? "SalesPersonID"
          : theDrillInDialog.TheDrillinCrumb.DrilldownItem.LevelColumn
      );
      requestArgs.DataDrillSettings.LevelValues.push(
        theDrillInDialog.TheDrillinCrumb.Value
      );
      requestArgs.DataDrillSettings.LevelGridTypes.push(
        theDrillInDialog.TheDrillinCrumb.DrilldownItem.ChildGridType
      );
    }

    theDrillInDialog._RequestModel = new GridSetup.BIDrillDataRequestModel(
      requestArgs
    );
    // TODO:  Apply filter and sorts
    // if (theBICenter._RequestModel && theBICenter._RequestModel.RequestArgs.filterExpression && requestModel.RequestArgs.ViewID === theBICenter._RequestModel.RequestArgs.ViewID) {
    //    requestModel.RequestArgs.filterExpression = theBICenter._RequestModel.RequestArgs.filterExpression;
    // }
    // if (theBICenter._RequestModel && theBICenter._RequestModel.RequestArgs.sortExpression && requestModel.RequestArgs.ViewID === theBICenter._RequestModel.RequestArgs.ViewID) {
    //    requestModel.RequestArgs.sortExpression = theBICenter._RequestModel.RequestArgs.sortExpression;
    //    requestModel.RequestArgs.sortDirection = theBICenter._RequestModel.RequestArgs.sortDirection;
    // }

    return getRequestDFD.promise(getRequestDFD.resolve(theDrillInDialog));
  }
}

export class BI_GapAnalysisSummaries extends BI_AdvancedGroupSummariesTab {
  public AdvancedTypeName: string = "GapAnalysis";
  public AdvancedSelectorPlaceholderMessage = "Choose a Gap Analysis";
  public TabPanelPlaceholderMessage = "Choose a Gap Analysis from the menu.";

  public gapSummaries: IAdvancedGroupSummaryBasicItem[];
  public gapFieldItems: IAdvancedGroupSummaryItem[];
  public TabInfo: IAdvancedTabItems;

  constructor(centerObject: BiCenter) {
    super(centerObject);
  }

  public ClearCachedData() {
    const theGapAnalysisSummariesTab = this;
    theGapAnalysisSummariesTab.TabInfo = undefined;
    super.ClearCachedData();
  }

  public GetAdvancedItems() {
    const theGapAnalysisSummariesTab = this;
    const dfd: JQueryDeferred<IAdvancedTabItems> = $.Deferred();

    if (theGapAnalysisSummariesTab.TabInfo) {
      return dfd.resolve(theGapAnalysisSummariesTab.TabInfo);
    } else {
      const request = new TDFRequest({
        url: "/bi/GapAnalysisList",
        type: "POST",
        data: {
          biLevel:
            theGapAnalysisSummariesTab.MyBICenter.BISummarySelectionPanel
              .ActiveSelectionLevel,
          isSalesRep: false
        }
      });
      request.MakeRequest().done(function (response) {
        // Save Response for future use
        theGapAnalysisSummariesTab.gapSummaries = response.GapAnalysisList;
        theGapAnalysisSummariesTab.gapFieldItems = [];

        // Set up the items collection for the acct fields
        $.each(theGapAnalysisSummariesTab.gapSummaries, function (index, value) {
          if (value) {
            const gapValue = value as IGapGroupSummaryBasicItem;

            const item: IGapGroupSummaryItem = {
              Caption: gapValue.Caption,
              Index: gapValue.Index,
              ID: gapValue.ID,
              ViewList: undefined, // don't initialize this as it determines whether we need to set this or not to the list of views.
              LoadedGrids: [],
              Type: 740,
              RollUpType: gapValue.RollUpType,
              Measure: gapValue.Measure
            };
            theGapAnalysisSummariesTab.gapFieldItems.push(item);
          }
        });

        // Add count on Main Center Menu
        theGapAnalysisSummariesTab.MyBICenter.IncrementBadge();

        theGapAnalysisSummariesTab.TabInfo = {
          AdvancedSummaries: theGapAnalysisSummariesTab.gapSummaries,
          Items: theGapAnalysisSummariesTab.gapFieldItems
        };
        return dfd.resolve(theGapAnalysisSummariesTab.TabInfo);
      });
    }
    return dfd.promise();
  }

  public GetRequestModel(viewGUID?: string): GridSetup.BaseGridRequestModel {
    const theGapAnalysisSummariesTab = this;

    const requestArgs = new GridSetup.BIRequestArgs();
    requestArgs.GUID =
      viewGUID ||
      (theGapAnalysisSummariesTab.CurrentToolbar as GridToolbar).DefaultView;
    requestArgs.ContainerID = theGapAnalysisSummariesTab.MyBICenter.BISummarySelectionPanel.CurrentlySelectedItemsAsString();
    requestArgs.BISummaryType =
      theGapAnalysisSummariesTab.MyBICenter.BISummarySelectionPanel.ActiveSelectionLevel;
    requestArgs.OptionalGroupSummaryOptions = {
      // itemType: theGapAnalysisSummariesTab.GetToolbarType(),
      GapID: theGapAnalysisSummariesTab.CurrentSelectedItem.ID
    };
    requestArgs.GoalType = theGapAnalysisSummariesTab.MyBICenter.GetGoalSelection(
      theGapAnalysisSummariesTab.MyBICenter.CurrentSelectedItem.Type,
      theGapAnalysisSummariesTab.MyBICenter.BISummarySelectionPanel
        .ActiveSelectionLevel
    );

    const requestModel: GridSetup.BaseGridRequestModel = new GridSetup.BIRequestModel(
      requestArgs
    );
    return requestModel;
  }

  public GetToolbarType() {
    const theAdvancedSummariesTab = this;
    let toolbarType: number =
      theAdvancedSummariesTab.MyBICenter.CurrentSelectedItem.Type;

    const rollupType: GapRollUpType = (theAdvancedSummariesTab.CurrentSelectedItem as IGapGroupSummaryItem)
      .RollUpType;

    switch (rollupType) {
      case GapRollUpType.AccountID:
        toolbarType = BSIGrids.Acct;
        break;
      case GapRollUpType.AcctPkgID:
        toolbarType = BSIGrids.AcctPkg;
        break;
      case GapRollUpType.BSICustomerID:
        toolbarType = BSIGrids.CustomerID;
        break;
      case GapRollUpType.BSIMasterAcctID:
        toolbarType = BSIGrids.MasterID;
        break;
      case GapRollUpType.AcctRootID:
        toolbarType = BSIGrids.AcctRoot;
        break;
      case GapRollUpType.SalesmanID:
        toolbarType = BSIGrids.Salesperson;
        break;
    }

    return toolbarType;
  }
}
export class BI_GeneralSummary extends BiCustomCenterTab {
  public GeneralSummaryStyleProperty: string = "GeneralSummaryStyle";
  public GeneralSummaryStyle: BIGeneralSummaryStyle;
  public generalSummaryData: any;

  constructor(centerObject: BiCenter) {
    super(centerObject);
    this.GeneralSummaryStyle = parseInt(
      GetPreference(
        this.GeneralSummaryStyleProperty,
        this.MyBICenter.PreferenceModule
      )
    ) as BIGeneralSummaryStyle;
  }

  public ClearCachedData() {
    const theGeneralSummaryTab = this;
    theGeneralSummaryTab.generalSummaryData = undefined;
  }

  public RenderTab(data, index, element) {
    const theGeneralSummaryTab = this;

    theGeneralSummaryTab
      .GetGeneralSummaryData()
      .done(function (response: any) {
        const items = [];
        if (true) {
          Object.keys(response).forEach(function (key) {
            items.push(response[key]);
          });

          const container = $("<div />")
            .attr("id", DomSafeID(data.ViewGUID))
            .css("padding", "3px");
          element.append(container);

          const formatValue = function (
            itemKey: string,
            itemValue,
            cardName?: string
          ) {
            let formattedValue = itemValue;

            if (formattedValue !== "") {
              if (typeof itemValue != "string") {
                if (itemKey.indexOf("%") >= 0) {
                  formattedValue = Globalize.formatNumber(itemValue, {
                    style: "percent",
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                  });
                } else {
                  if (
                    cardName &&
                    (cardName.toLowerCase().indexOf("qty") === -1 &&
                      cardName.toLowerCase().indexOf("units") === -1)
                  ) {
                    formattedValue = Globalize.formatCurrency(
                      itemValue,
                      CurrentUser.Currency
                    );
                  }
                }
              } else if (!isNaN(Number(itemValue))) {
                itemValue = Globalize.parseNumber(itemValue);

                if (itemKey.indexOf("%") >= 0) {
                  formattedValue = Globalize.formatNumber(itemValue, {
                    style: "percent"
                  });
                } else {
                  if (
                    itemKey.toLowerCase().indexOf("po number") === -1 &&
                    itemKey.toLowerCase().indexOf("buyer id") === -1
                  ) {
                    formattedValue = Globalize.formatCurrency(
                      itemValue,
                      CurrentUser.Currency
                    );
                  }
                }
              } else if (!isNaN(Date.parse(itemValue))) {
                itemValue = itemValue.substr(0, itemValue.indexOf("T"));
                formattedValue = Globalize.formatDate(
                  moment(itemValue, "YYYY-MM-DD").toDate()
                );
              }
            } else {
              formattedValue = "---";
            }

            return formattedValue;
          };

          const makeInnerCard = function (obj, itemID) {
            const cardInfo = $("<div />");
            let formattedValue = "";
            const theTitle = $("<div />");
            const theData = $("<div />");

            Object.keys(obj).forEach(function (key) {
              let value = obj[key];
              if (key != "Name") {
                if (value === null) {
                  value = "";
                }
                formattedValue = formatValue(key, value, obj.Name);
                // store data with ptags
                $(theData).append("<p>" + key + ": " + formattedValue + "</p>");
              } else {
                formattedValue = formatValue(key, value);
                // store data with h3 tags
                $(theTitle).append("<h3>" + formattedValue + "</h3><hr/>");
              }
            });

            $(cardInfo)
              .append(theTitle)
              .append(theData);

            return cardInfo;
          };

          if (
            theGeneralSummaryTab.GeneralSummaryStyle ==
            BIGeneralSummaryStyle.CardView
          ) {
            const id = "GeneralSummaryCardView";
            const cardView = $("<div />").attr("id", id);
            const cardRow = $("<div />").addClass("row");
            let card = $("<div />").addClass("col-md-2");
            let innerCard = $("<div />");
            let counter = 0;

            $(items).each(function (index, val) {
              const obj = val[0];

              const itemID = "biGeneralSumData_" + index;

              if (Object.keys(obj).length > 4) {
                const cardInfo = makeInnerCard(obj, itemID);
                cardInfo
                  .addClass("biGeneralSumHead")
                  .css("min-height", "450px");
                card.append(cardInfo);
                card.appendTo(cardRow);
                card = $("<div />").addClass("col-md-2");
              } else {
                const cardInfo = makeInnerCard(obj, itemID);
                cardInfo
                  .addClass("biGeneralSumHead")
                  .css("min-height", "225px");
                const cardInfoContain = $("<div />").addClass("col-sm-12");
                cardInfoContain.append(cardInfo);
                innerCard.append(
                  $("<div />")
                    .addClass("row")
                    .append(cardInfoContain)
                );
                counter++;

                if (counter == 2) {
                  innerCard.appendTo(card);
                  card.appendTo(cardRow);
                  card = $("<div />").addClass("col-md-2");
                  counter = 0;
                  innerCard = $("<div />");
                }
              }
            });

            if (counter == 1) {
              innerCard.appendTo(card);
              card.appendTo(cardRow);
              card = $("<div />").addClass("col-md-2");
              counter = 0;
              innerCard = $("<div />");
            }

            $(cardView).append(cardRow);
            container.append(cardView);
          } else {
            const id = "GeneralSummaryListView";
            const listView = $(
              "<div id='listViewWrapper' class='col-sm-4 offset-sm-3'><div id='" +
              id +
              "'></div></div>"
            );
            container.append(listView);

            const accordion: DevExpress.ui.dxAccordion = $("#" + id)
              .dxAccordion({
                collapsible: true,
                multiple: true,

                items,
                width: "100%",
                itemTitleTemplate(itemData, itemIndex, itemElement) {
                  const dataItem = itemData[0];
                  const itemID = "biGeneralSumDataTitle_" + itemIndex;
                  $(itemElement).attr("id", itemID);
                  itemElement.append("<h3>" + dataItem.Name + "</h3>");
                },
                itemTemplate(itemData, itemIndex, itemElement) {
                  const dataItem = itemData[0];
                  const itemID = "biGeneralSumData_" + itemIndex;
                  let html =
                    "<div id='" +
                    itemID +
                    "items' class='table-responsive'><table class='table summaryTable'><tbody>";

                  Object.keys(dataItem).forEach(function (key) {
                    if (key != "Name") {
                      let itemValue = dataItem[key];
                      if (itemValue === null) {
                        itemValue = "";
                      }

                      const formattedValue = formatValue(key, itemValue);
                      html =
                        html +
                        "<tr><td>" +
                        key +
                        "</td><td>" +
                        formattedValue +
                        "</td></tr>";
                    }
                  });

                  html = html + "</tbody></table></div>";
                  itemElement.append(html);
                },
                onItemRendered(e) {
                  (e.component as DevExpress.ui.dxAccordion).expandItem(
                    e.itemIndex
                  );
                }
              })
              .dxAccordion("instance");

            $("#listViewWrapper").dxScrollView({
              showScrollbar: "always",
              bounceEnabled: false
            });
          }

          data.TabContent = element;
          data.Active = true;
        } else {
          new Notification({
            message: response.Message,
            type: "error",
            displayTime: 5000
          });
        }
      });
  }

  public GetGeneralSummaryData(): any {
    const theGeneralSummaryTab = this;
    const dfd = $.Deferred();

    if (theGeneralSummaryTab.generalSummaryData) {
      return dfd.resolve(theGeneralSummaryTab.generalSummaryData);
    } else {
      const request = new TDFRequest({
        url: "/bi/GeneralSummary",
        type: "POST",
        data: {
          biListing: theGeneralSummaryTab.MyBICenter.BISummarySelectionPanel.CurrentlySelectedItemsAsString(),
          biLevel:
            theGeneralSummaryTab.MyBICenter.BISummarySelectionPanel
              .ActiveSelectionLevel,
          summarySource: theGeneralSummaryTab.MyBICenter.SummarySource
        }
      });
      request.MakeRequest().done(function (response) {
        theGeneralSummaryTab.generalSummaryData = response;
        theGeneralSummaryTab.MyBICenter.IncrementBadge();
        return dfd.resolve(response);
      });
    }

    return dfd.promise(theGeneralSummaryTab);
  }

  public GetTabSettingsItems() {
    const theGeneralSummaryTab = this;
    const settingsItems: any = [
      {
        template(itemData, itemIndex, itemElement) {
          itemElement.append(
            "<div id='chkWrapperOuterGenSumStyle' >" +
            "<div id='chkWrapperGenSumStyle'> </div>" +
            "</div> "
          );

          const selectionData = [
            { name: "Card View", value: BIGeneralSummaryStyle.CardView },
            { name: "List View", value: BIGeneralSummaryStyle.ListView }
          ];
          $("#chkWrapperGenSumStyle").append(
            $("<div />").dxSelectBox({
              dataSource: selectionData,
              valueExpr: "value",
              displayExpr: "name",
              value: theGeneralSummaryTab.GeneralSummaryStyle,
              width: "auto",
              text: "Use Card View",
              onValueChanged(data) {
                theGeneralSummaryTab.GeneralSummaryStyle = data.value as BIGeneralSummaryStyle;
                Preferences.SetPreference(
                  theGeneralSummaryTab.GeneralSummaryStyleProperty,
                  data.value,
                  theGeneralSummaryTab.MyBICenter.PreferenceModule
                );
                if (theGeneralSummaryTab.MyBICenter.TabPanel) {
                  // This forces the items to repaint based on the item templates and thus it'll be repainted in the new style.
                  theGeneralSummaryTab.MyBICenter.RefreshTabDataSource();
                }
              }
            })
          );
        }
      }
    ];

    return settingsItems;
  }
}
export class BI_VendorInventorySummaries extends BI_AdvancedGroupSummariesTab {
  public AdvancedTypeName: string = "VendorInventorySummaries";
  public AdvancedSelectorPlaceholderMessage = "Choose a Value";
  public TabPanelPlaceholderMessage = "Choose a value from the menu to group inventory information";

  public inventorySummaries: IAdvancedGroupSummaryBasicItem[];
  public inventoryFieldItems: IAdvancedGroupSummaryItem[];
  public TabInfo: IAdvancedTabItems;

  constructor(centerObject: BiCenter) {
    super(centerObject);
  }

  public ClearCachedData() {
    const theInventorySummariesTab = this;
    theInventorySummariesTab.TabInfo = undefined;
    super.ClearCachedData();
  }

  public GetAdvancedItems() {
    const theInventorySummariesTab = this;
    const dfd: JQueryDeferred<IAdvancedTabItems> = $.Deferred();

    if (theInventorySummariesTab.TabInfo) {
      return dfd.resolve(theInventorySummariesTab.TabInfo);
    } else {
      const request = new TDFRequest({
        url: "/bi/VendorInventoryGroupList",
        type: "POST",
        data: {}
      });
      request.MakeRequest().done(function (response) {
        // Save Response for future use
        theInventorySummariesTab.inventorySummaries =
          response.VendorInventoryGroupList;
        theInventorySummariesTab.inventoryFieldItems = [];

        // Set up the items collection for the acct fields
        $.each(theInventorySummariesTab.inventorySummaries, function (
          index,
          value
        ) {
          if (value) {
            const item: IAdvancedGroupSummaryItem = {
              Caption: value.Caption,
              Index: value.Index,
              ViewList: undefined, // don't initialize this as it determines whether we need to set this or not to the list of views.
              LoadedGrids: []
            };
            theInventorySummariesTab.inventoryFieldItems.push(item);
          }
        });

        // Add count on Main Center Menu
        theInventorySummariesTab.MyBICenter.IncrementBadge();

        theInventorySummariesTab.TabInfo = {
          AdvancedSummaries: theInventorySummariesTab.inventorySummaries,
          Items: theInventorySummariesTab.inventoryFieldItems
        };
        return dfd.resolve(theInventorySummariesTab.TabInfo);
      });
    }
    return dfd.promise();
  }

  public GetRequestModel(viewGUID?: string): GridSetup.BaseGridRequestModel {
    const theInventorySummariesTab = this;

    const requestArgs = new GridSetup.BIRequestArgs();
    requestArgs.GUID =
      viewGUID ||
      (theInventorySummariesTab.CurrentToolbar as GridToolbar).DefaultView;
    requestArgs.ContainerID = theInventorySummariesTab.MyBICenter.BISummarySelectionPanel.CurrentlySelectedItemsAsString();
    requestArgs.BISummaryType =
      theInventorySummariesTab.MyBICenter.BISummarySelectionPanel.ActiveSelectionLevel;
    requestArgs.OptionalGroupSummaryOptions = {
      GroupingField: theInventorySummariesTab.CurrentSelectedItem.Caption
    };

    const requestModel: GridSetup.BaseGridRequestModel = new GridSetup.BIRequestModel(
      requestArgs
    );
    return requestModel;
  }
}

export class BasicCrumbInfo {
  public ChildGridType: ChildGridTypes;
  public Value: string = "";
  public Description: string = "";
  public UseAsFilter: boolean = false;
}
