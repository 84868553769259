import {BI__ItemDetailBase} from "./bi_itemdetailbase"
import { CenterType, GridContainerTypes } from "../../../enums/enums";
import {  BSIGrids } from "../../../enums/bi/enums";
import { Preferences } from "../../../infrastructure/user/preferences";

export class BI__ItemDetailVendorInvoiceForm extends BI__ItemDetailBase {
    public readonly TDFContainerType: number = GridContainerTypes.BIDetailFormDialogs;
    public ItemType: number;
    public readonly CustomName: string = "VendorInvoiceDetails";
    public readonly DisplayName = "Vendor Invoice Details";
    public readonly ItemHeaderURL: string = "/bi/BIVendorInvoiceHeaderFormData/";
    public readonly HeaderDataCaption: string = "Vendor Invoice Header";
  
    constructor(ItemID: string, centerType: CenterType = CenterType.VendorBi) {
      super(ItemID);
        const invoiceDetailForm = this;

        this.DisplayName = this.getCustomDisplayName(this.DisplayName);
        this.HeaderDataCaption = this.getCustomHeaderCaption(this.HeaderDataCaption);
  
      switch (centerType) {
        case CenterType.VendorBi:
          invoiceDetailForm.ItemType = BSIGrids.BIFormDialogVendorInvoiceFromVBI;
          break;
        case CenterType.OppBi:
          invoiceDetailForm.ItemType = BSIGrids.BIFormDialogVendorInvoiceFromOBI;
          break;
      }
    }
  }