
        export namespace Helpers {

            export class QuoteDetailControlTypes {
                public static Description: DevExpress.ui.dxFormSimpleItem = {
                    dataField: "Description", editorType: "dxTextBox",
                    label: { text: "Description" }, visible: true, visibleIndex: 0, colSpan: 3,
                };
                public static ClosedDate: DevExpress.ui.dxFormSimpleItem = {
                    dataField: "ClosedDate", editorType: "dxDateBox",
                    label: { text: "Closed On" }, visible: true, visibleIndex: 5, colSpan: 1,
                };
                public static Status: DevExpress.ui.dxFormSimpleItem = {
                    dataField: "Status", editorType: "dxSelectBox", label:
                        { text: "Status" }, visible: true, visibleIndex: 3, colSpan: 1,
                };

                public static OrderNumber: DevExpress.ui.dxFormSimpleItem = {
                    dataField: "OrderNumber", editorType: "dxTextBox",
                    label: { text: "Order #" }, visible: true, visibleIndex: 7, colSpan: 1,
                };
                public static QuoteID: DevExpress.ui.dxFormSimpleItem = {
                    dataField: "QuoteID", editorType: "dxTextBox", label:
                        { text: "QuoteID" }, visible: false, visibleIndex: 6, colSpan: 1,
                };
                public static QuoteDate: DevExpress.ui.dxFormSimpleItem = {
                    dataField: "QuoteDate", editorType: "dxDateBox",
                    label: { text: "Quote Date" }, visible: true, visibleIndex: 4, colSpan: 1,
                };
                public static BSIQuoteNo: DevExpress.ui.dxFormSimpleItem = {
                    dataField: "BSIQuoteNo", editorType: "dxTextBox",
                    label: { text: "BI Quote #" }, visible: true, visibleIndex: 8, colSpan: 1,
                };
                public static UniqueID: DevExpress.ui.dxFormSimpleItem = {
                    dataField: "UniqueID", editorType: "dxTextBox",
                    label: { text: "Unique ID" }, visible: true, visibleIndex: 1, colSpan: 1,
                };
                public static ERPProcessStatus: DevExpress.ui.dxFormSimpleItem = {
                    dataField: "ERPProcessStatus", editorType: "dxSelectBox",
                    label: { text: "ERPProcess Status" }, visible: false, visibleIndex: 1, colSpan: 1,
                };
                public static ShipToID: DevExpress.ui.dxFormSimpleItem = {
                    dataField: "ShipToID", editorType: "dxDropDownBox",
                    label: { text: "ShipTo" }, visible: true, visibleIndex: 9, colSpan: 1,
                };
                public static Contract: DevExpress.ui.dxFormSimpleItem = {
                    dataField: "Contract", editorType: "dxLookup",
                    label: { text: "Contract" }, visible: true, visibleIndex: 2, colSpan: 1,
                };
                public static ActualBSIShipToID: DevExpress.ui.dxFormSimpleItem = {
                    dataField: "ActualBSIShipToID",
                    editorType: "dxTextBox", label: { text: "Actual BSI ShipTo ID" }, visible: false, visibleIndex: 1, colSpan: 1,
                };
                public static IsBillOfMaterials: DevExpress.ui.dxFormSimpleItem = {
                    dataField: "IsBillOfMaterials",
                    editorType: "dxCheckBox", editorOptions() { },
                    label: { text: "Bill of Materials" }, visible: false, visibleIndex: 9, colSpan: 1,
                };
                public static ImportedByBISync: DevExpress.ui.dxFormSimpleItem = {
                    dataField: "ImportedByBISync",
                    editorType: "dxCheckBox", editorOptions() { },
                    label: { text: "ImportedByBISync" }, visible: false, visibleIndex: 10, colSpan: 1,
                };
            }

            export class QuoteResultForm {

                public static readonly groupkeys = ["AdditionalDiscountGroup", "BaseTaxGroup", "SurTaxGroup", "FreightGroup", "TotalsGroup", "MaintenanceGroup"];
                public static readonly CurrencyFields = ["AdditionalDiscountTotal", "BaseTaxTotal", "DepositTotal", "ExtendedTotalTotal",
                    "FreightTotal", "GrossProfitTotal", "SubTotalAfterDiscountTotal", "SubTotalTotal", "SurTaxTotal", "TotalCost", "TotalMinusDepositTotal",
                    "GlobalDiscountAmount", "FreightInbound", "FreightOutbound", "SurTaxRateMaximum"];
                public static readonly PercentageFields = ["GrossProfitPercentTotal", "BaseTaxRate", "GlobalDiscountRate", "SurTaxRate"];

                // #region AdditionalDiscountGroup

                public static GlobalDiscountRate: DevExpress.ui.dxFormSimpleItem | any = {
                    dataField: "UserProperties.GlobalDiscountRate", editorType: "dxNumberBox", label: { text: "Rate" }, visible: true,
                    editorOptions: {
                        valueChangeEvent: "blur",
                        // onValueChanged: QuotePropertyChanged,
                        min: 0,
                        max: 100,
                        step: 1,
                        mode: "number",
                        showSpinButtons: false,
                    },
                    ChangeAction: "QuotePropertyChanged",
                    IsRateField: true,
                    visibleIndex: 0,
                };
                public static GlobalDiscountAmount: DevExpress.ui.dxFormSimpleItem | any = {
                    dataField: "UserProperties.GlobalDiscountAmount", editorType: "dxTextBox", label: { text: "Amount" }, visible: true,
                    editorOptions: {
                        valueChangeEvent: "blur",
                    },
                    ChangeAction: "QuotePropertyChanged",
                    IsRateField: false,
                    visibleIndex: 1,
                };
                public static SubTotalTotal: DevExpress.ui.dxFormSimpleItem = {
                    dataField: "Results.SubTotalTotal", editorType: "dxTextBox", label: { text: "SubTotal" }, visible: true,
                    editorOptions: {
                        valueChangeEvent: "blur",
                        disabled: false,
                        focusStateEnabled: true,
                    },
                    visibleIndex: 2,
                };
                public static AdditionalDiscountTotal: DevExpress.ui.dxFormSimpleItem | any = {
                    dataField: "Results.AdditionalDiscountTotal", editorType: "dxTextBox", label: { text: "Additional Discount" }, visible: true,
                    editorOptions: {
                        valueChangeEvent: "blur",
                        onValueChanged(e) {

                        },
                        disabled: true,
                    },

                    ApplyCalculation: "{{fromDataVariable}}[Results.AdditionalDiscountTotal] = {{fromDataVariable}}[Results.GlobalDiscountRate] * {{fromDataVariable}}[Results.SubTotalToal]",
                };
                public static AdditionalDiscountGroupSpacer: DevExpress.ui.dxFormEmptyItem = {
                    itemType: "empty",
                    colSpan: 2,
                    visibleIndex: 3,
                };
                public static AdditionalDiscountGroup: DevExpress.ui.dxFormGroupItem = {
                    itemType: "group",
                    caption: "Additional Discount",
                    items: [QuoteResultForm.GlobalDiscountRate,
                    QuoteResultForm.GlobalDiscountAmount,
                    QuoteResultForm.SubTotalTotal,
                    QuoteResultForm.AdditionalDiscountGroupSpacer,
                    QuoteResultForm.AdditionalDiscountTotal,
                    ],
                    visible: true,
                    visibleIndex: 0,
                    colCountByScreen: {
                        lg: 3,
                        md: 3,
                        sm: 2,
                        xs: 1,
                    },
                    cssClass: "quoteformgroup",
                };

                // #endregion

                // #region BaseTaxGroup

                public static BaseTaxRate: DevExpress.ui.dxFormSimpleItem | any = {
                    dataField: "UserProperties.BaseTaxRate", editorType: "dxNumberBox", label: { text: "Rate" }, visible: true,
                    editorOptions: {
                        valueChangeEvent: "blur",
                        min: 0,
                        max: 100,
                        step: 1,
                        mode: "number",
                        showSpinButtons: false,
                    },
                    ChangeAction: "QuotePropertyChanged",
                    IsRateField: true,
                    visibleIndex: 0,
                };
                public static SubTotalAfterDiscountTotal: DevExpress.ui.dxFormSimpleItem | any = {
                    dataField: "Results.SubTotalAfterDiscountTotal", editorType: "dxTextBox", label: { text: "SubTotal After Discount" }, visible: true,
                    editorOptions: {
                        valueChangeEvent: "blur",
                        onValueChanged(e) {

                        },
                        disabled: true,
                    },

                    visibleIndex: 2,
                };
                public static BaseTaxTotal: DevExpress.ui.dxFormSimpleItem | any = {
                    dataField: "Results.BaseTaxTotal", editorType: "dxTextBox", label: { text: "Base Tax" }, visible: true,
                    editorOptions: {
                        valueChangeEvent: "blur",
                        onValueChanged(e) {

                        },
                        disabled: true,
                    },

                    visibleIndex: 4,
                    ApplyCalculation: "{{fromDataVariable}}Results.BaseTaxTotal = {{fromDataVariable}}[UserProperties.BaseTaxRate] * [UserProperties.SubTotalAfterDiscountTotal] ",
                };

                public static BaseTaxGroup: DevExpress.ui.dxFormGroupItem = {
                    itemType: "group",
                    caption: "Base Tax",
                    items: [
                        QuoteResultForm.BaseTaxRate,
                        {
                            itemType: "empty",
                            colSpan: 1,
                            visibleIndex: 1,
                        },
                        QuoteResultForm.SubTotalAfterDiscountTotal,
                        {
                            itemType: "empty",
                            colSpan: 2,
                            visibleIndex: 3,
                        },
                        QuoteResultForm.BaseTaxTotal,
                    ],
                    visible: true,
                    visibleIndex: 1,
                    colCountByScreen: {
                        lg: 3,
                        md: 3,
                        sm: 2,
                        xs: 1,
                    },
                    cssClass: "quoteformgroup",
                };

                // #endregion

                // #region SurTaxGroup

                public static SurTaxRate: DevExpress.ui.dxFormSimpleItem | any = {
                    dataField: "UserProperties.SurTaxRate", editorType: "dxNumberBox", label: { text: "Rate" }, visible: true,
                    editorOptions: {
                        valueChangeEvent: "blur",
                        min: 0,
                        max: 100,
                        step: 1,
                        mode: "number",
                        showSpinButtons: false,
                    },
                    ChangeAction: "QuotePropertyChanged",
                    IsRateField: true,
                    visibleIndex: 0,
                };
                public static SurTaxRateMaximum: DevExpress.ui.dxFormSimpleItem | any = {
                    dataField: "UserProperties.SurTaxRateMaximum", editorType: "dxTextBox", label: { text: "Maximum" }, visible: true,
                    editorOptions: {
                        valueChangeEvent: "blur",
                        onValueChanged(e) {

                        },
                    },
                    ChangeAction: "QuotePropertyChanged",
                    IsRateField: false,
                    visibleIndex: 1,
                };
                public static SurTaxTotal: DevExpress.ui.dxFormSimpleItem | any = {
                    dataField: "Results.SurTaxTotal", editorType: "dxTextBox", label: { text: "Surtax" }, visible: true,
                    editorOptions: {
                        valueChangeEvent: "blur",
                        disabled: true,
                    },
                    visibleIndex: 2,
                    ApplyCalculation: "{{fromDataVariable}}[Results.SurTaxTotal] = {{fromDataVariable}}[UserProperties.SurTaxRate] * {{fromDataVariable}}[UserProperties.SurTaxRateMaximum] ",

                };
                public static SurTaxGroupSpacer: DevExpress.ui.dxFormEmptyItem = {
                    itemType: "empty",
                    colSpan: 1,
                };

                public static SurTaxGroup: DevExpress.ui.dxFormGroupItem = {
                    itemType: "group",
                    caption: "SurTax",
                    items: [QuoteResultForm.SurTaxRate,
                    QuoteResultForm.SurTaxRateMaximum,
                    QuoteResultForm.SurTaxTotal,
                    ],
                    visible: true,
                    visibleIndex: 2,
                    colCountByScreen: {
                        lg: 3,
                        md: 3,
                        sm: 2,
                        xs: 1,
                    },
                    cssClass: "quoteformgroup",
                };

                // #endregion

                // #region FreightGroup

                public static FreightInbound: DevExpress.ui.dxFormSimpleItem | any = {
                    dataField: "UserProperties.FreightInbound", editorType: "dxTextBox", label: { text: "Inbound" }, visible: true,
                    editorOptions: {
                        valueChangeEvent: "blur",
                        onValueChanged(e) {

                        },
                    },
                    visibleIndex: 0,
                    ChangeAction: "UpdateFreight",
                    IsRateField: false,

                };
                public static FreightOutbound: DevExpress.ui.dxFormSimpleItem | any = {
                    dataField: "UserProperties.FreightOutbound", editorType: "dxTextBox", label: { text: "OutBound" }, visible: true,
                    editorOptions: {
                        valueChangeEvent: "blur",
                        onValueChanged(e) {

                        },
                    },
                    visibleIndex: 1,
                    ChangeAction: "UpdateFreight",
                    IsRateField: false,

                };
                public static FreightTotal: DevExpress.ui.dxFormSimpleItem | any = {
                    dataField: "Results.FreightTotal", editorType: "dxTextBox", label: { text: "Freight" }, visible: true,
                    editorOptions: {
                        valueChangeEvent: "blur",
                        onValueChanged(e) {

                        },
                        disabled: true,
                    },
                    visibleIndex: 2,

                };
                public static FreightGroupSpacer: DevExpress.ui.dxFormEmptyItem = {
                    itemType: "empty",
                    colSpan: 1,
                };
                public static FreightGroup: DevExpress.ui.dxFormGroupItem = {
                    itemType: "group",
                    caption: "Freight",
                    items: [QuoteResultForm.FreightInbound, QuoteResultForm.FreightOutbound, QuoteResultForm.FreightTotal],
                    visible: true,
                    visibleIndex: 3,
                    colCountByScreen: {
                        lg: 3,
                        md: 3,
                        sm: 2,
                        xs: 1,
                    },
                    cssClass: "quoteformgroup",
                };

                // #endregion

                // #region TotalsGroup
                public static TotalCost: DevExpress.ui.dxFormSimpleItem = {
                    dataField: "Results.TotalCost", editorType: "dxTextBox", label: { text: "Total Cost" }, visible: true,
                    editorOptions: {
                        valueChangeEvent: "blur",
                        onValueChanged(e) {

                        },
                        disabled: true,

                    },

                    visibleIndex: 0,
                };

                public static Deposit: DevExpress.ui.dxFormSimpleItem | any = {
                    dataField: "UserProperties.Deposit", editorType: "dxTextBox", label: { text: "Deposit" }, visible: true,
                    editorOptions: {
                        valueChangeEvent: "blur",
                        onValueChanged(e) {

                        },
                    },
                    ChangeAction: "QuotePropertyChanged",
                    IsRateField: false,
                    visibleIndex: 2,
                };
                public static ExtendedTotalTotal: DevExpress.ui.dxFormSimpleItem | any = {
                    dataField: "Results.ExtendedTotalTotal", editorType: "dxTextBox", label: { text: "Extended Total" }, visible: true,
                    editorOptions: {
                        valueChangeEvent: "blur",
                        onValueChanged(e) {

                        },
                        disabled: true,
                    },
                    visibleIndex: 3,
                };
                public static GrossProfitTotal: DevExpress.ui.dxFormSimpleItem = {
                    dataField: "Results.GrossProfitTotal", editorType: "dxTextBox", label: { text: "Gross Profit" }, visible: true,
                    editorOptions: {
                        valueChangeEvent: "blur",
                        onValueChanged(e) {

                        },
                        disabled: true,
                    },
                    visibleIndex: 4,
                };
                public static DepositTotal: DevExpress.ui.dxFormSimpleItem | any = {
                    dataField: "Results.DepositTotal", editorType: "dxTextBox", label: { text: "Deposit" }, visible: true,
                    editorOptions: {
                        valueChangeEvent: "blur",
                        onValueChanged(e) {

                        },
                        disabled: true,
                    },
                    ChangeAction: "TotalChanged",
                    visibleIndex: 6,
                };

                public static GrossProfitPercentTotal: DevExpress.ui.dxFormSimpleItem = {
                    dataField: "Results.GrossProfitPercentTotal", editorType: "dxSlider", label: { text: "Gross Profit Percent" }, visible: true,
                    editorOptions: {
                        valueChangeEvent: "blur",
                        onValueChanged(e) {

                        },
                        min: 0,
                        max: 100,
                        rtlEnabled: false,
                        tooltip: {
                            enabled: true,
                            format(value) {
                                return value + "%";
                            },
                            showMode: "always",
                            position: "bottom",
                        },
                        disabled: true,
                    },
                    visibleIndex: 7,
                };

                public static TotalMinusDepositTotal: DevExpress.ui.dxFormSimpleItem = {
                    dataField: "Results.TotalMinusDepositTotal", editorType: "dxTextBox", label: { text: "Total Minus Deposit" }, visible: true,
                    editorOptions: {
                        valueChangeEvent: "blur",
                        onValueChanged(e) {

                        },
                        disabled: true,
                    },
                    visibleIndex: 9,
                };

                public static TotalsGroup: DevExpress.ui.dxFormGroupItem = {
                    itemType: "group",
                    caption: "-",
                    items: [
                        QuoteResultForm.TotalCost,
                        {
                            itemType: "empty",
                            colSpan: 1,
                            visibleIndex: 1,
                        },
                        QuoteResultForm.Deposit,
                        QuoteResultForm.ExtendedTotalTotal,
                        QuoteResultForm.GrossProfitTotal,
                        {
                            itemType: "empty",
                            colSpan: 2,
                            visibleIndex: 5,
                        },
                        QuoteResultForm.DepositTotal,
                        QuoteResultForm.GrossProfitPercentTotal,
                        {
                            itemType: "empty",
                            colSpan: 2,
                            visibleIndex: 8,
                        },

                        QuoteResultForm.TotalMinusDepositTotal,
                    ],
                    visible: true,
                    visibleIndex: 4,
                    colCountByScreen: {
                        lg: 4,
                        md: 3,
                        sm: 2,
                        xs: 1,
                    },
                    cssClass: "quoteformgroup",
                };

                // #endregion

                // #region MaintenanceGroup

                public static RecurringMaintenance: DevExpress.ui.dxFormSimpleItem = {
                    dataField: "Results.RecurringMaintenance", editorType: "dxCheckBox", label: { text: "Recurring Maintenance" }, visible: false,
                    editorOptions: {
                        valueChangeEvent: "blur",
                    },
                };
                public static IncludeMaintenanceInFinancePlan: DevExpress.ui.dxFormSimpleItem = {
                    dataField: "Results.IncludeMaintenanceInFinancePlan", editorType: "dxCheckBox", label: { text: "Include Maintenance In Finance Plan" }, visible: false,
                    editorOptions: {
                        valueChangeEvent: "blur",
                    },
                };
                public static MaintenanceGroup: DevExpress.ui.dxFormGroupItem = {
                    itemType: "group",
                    caption: "Maintenance Options",
                    items: [QuoteResultForm.RecurringMaintenance, QuoteResultForm.IncludeMaintenanceInFinancePlan],
                    visible: false,
                    visibleIndex: 4,
                    colCountByScreen: {
                        lg: 3,
                        md: 3,
                        sm: 2,
                        xs: 1,
                    },

                };

                // #endregion

            }

        }
