import { RaiseEvent2 } from "../../../../infrastructure/events/ui_events";
import { eventNameSpace, EventTypes } from "../../../../enums/webevents/enums";
import { ToolbarTemplateFactory } from "./toolbartemplatefactory";
import { Debounce } from "../../../../util/allutils";

export function OpenButton(args) {
  let centerName: string = "";
  if (args && args.length) {
    args.map((v, k) => {
      if ($.isPlainObject(v)) {
        Object.keys(v).forEach((val, key) => {
          if (val.toLowerCase() === "uniquecentername") {
            centerName = v[val];
          }
        });
      }
    });
  }
  //TODO: Add Config paramter and respect it
  return ToolbarTemplateFactory(
    "before",
    "never",
    false,
    <DevExpress.ui.dxButtonOptions>{
      icon: "dx-icon icon-open",
      text: "Open",
      //type:"warning",
      onClick: Debounce(
        () => {
          RaiseEvent2(EventTypes.CenterEventTypes.openitem, centerName, eventNameSpace.request);
        },
        250,
        true
      )
    },
    "dxButton",
    null,
    false
  );
}
