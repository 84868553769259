import { GetDevice } from "util/allutils";

export class EventDetailControlTypes {
    static Name: DevExpress.ui.dxFormSimpleItem = {
        dataField: "Name",
        editorType: "dxTextBox",
        label: { text: "Event Name" },
        visible: true,
        visibleIndex: 0,
        colSpan: 1
    };
    static Description: DevExpress.ui.dxFormSimpleItem = {
        dataField: "Description",
        editorType: "dxTextBox",
        label: { text: "Description" },
        visible: true,
        visibleIndex: GetDevice().isDevice ? 1 : 3,
        colSpan: 1
    };
    static Organizer: DevExpress.ui.dxFormSimpleItem = {
        dataField: "Organizer",
        editorType: "dxSelectBox",
        label: { text: "Organizer" },
        visible: true,
        visibleIndex: GetDevice().isDevice ? 2 : 6,
        colSpan: 1
    };
    static Start: DevExpress.ui.dxFormSimpleItem = {
        dataField: "Start",
        editorType: "dxDateBox",
        label: { text: "Start Date" },
        visible: true,
        visibleIndex: GetDevice().isDevice ? 3 : 1,
        colSpan: 1
    };
    static End: DevExpress.ui.dxFormSimpleItem = {
        dataField: "End",
        editorType: "dxDateBox",
        label: { text: "End Date" },
        visible: true,
        visibleIndex: 4,
        colSpan: 1
    };
    static Type: DevExpress.ui.dxFormSimpleItem = {
        dataField: "Type",
        editorType: "dxSelectBox",
        label: { text: "Event Type" },
        visible: true,
        visibleIndex: GetDevice().isDevice ? 5 : 7,
        colSpan: 1
    };
    static EnrollmentFee: DevExpress.ui.dxFormSimpleItem = {
        dataField: "EnrollmentFee",
        editorType: "dxTextBox",
        label: { text: "Enrollment Fee" },
        visible: true,
        visibleIndex: GetDevice().isDevice ? 6 : 2,
        colSpan: 1
    };
    static EnrollmentType: DevExpress.ui.dxFormSimpleItem = {
        dataField: "EnrollmentType",
        editorType: "dxSelectBox",
        label: { text: "Enrollment Type" },
        visible: true,
        visibleIndex: GetDevice().isDevice ? 7 : 5,
        colSpan: 1
    };
    static EnrollmentLimit: DevExpress.ui.dxFormSimpleItem = {
        dataField: "EnrollmentLimit",
        editorType: "dxNumberBox",
        label: { text: "Enrollment Limit" },
        visible: true,
        visibleIndex: 8,
        colSpan: 1
    };
}
