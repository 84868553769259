import { EventCenter } from "./baseeventcenter";
import { DataCenterMenuDrawer } from "datacenters/datacentermenudrawer";
import { IToolbarItemOverrideOptions } from "interfaces/grids/interfaces";
import { AwesomeToolbar } from "components/grids/gridtoolbars/datacentertoolbar";
import { itemTypes, GridContainerTypes, CenterType } from "enums/enums";
import { DataCenterTabs } from "datacenters/datacentertabs";
import { AddHandler2, RaiseEvent2 } from "infrastructure/events/ui_events";
import { eventNameSpace, EventTypes } from "enums/webevents/enums";
import { Preferences } from "infrastructure/user/preferences";
import { GridSetup } from "services/grid/helpers/gridsetup";
import { AttendeeSummary } from "components/event/attendeesummary";
import { TDFDataCenters } from "interfaces/datacenters/tdfdatacenters";


export class InvitationListCenter extends EventCenter {
    _CenterType = CenterType.EventInvitation;

    constructor(eventID: string, customName: string, MyCreationArgs: TDFDataCenters.ICRMDataCenterCreationArgs) {
        super(eventID, customName, MyCreationArgs);
    }

    init() {
        let invdc = this;

        invdc.initContainers();
        invdc.initToolbar();
        invdc.initMenu();
        invdc.initTabs();
        invdc.SubscribeToMenuEvents();

        setTimeout(() => {
            RaiseEvent2(
                EventTypes.CenterEventTypes.itemtype,
                invdc.CustomName,
                eventNameSpace.modify,
                { ItemType: itemTypes.itemEventInvitation }
            );
        }, 1e3);
    }

    initMenu() {
        let invdc = this;
        new DataCenterMenuDrawer(
            invdc.MenuAreaContainer,
            invdc.CenterType,
            invdc.CustomName,
            invdc.MainAreaContainer,
            invdc.InDefaultContainer
        ).Init();
    }

    initToolbar() {
        let invdc = this;

        if (invdc.DataToolbarAreaContainer.children().length === 0) {
            let ToolbarItems: IToolbarItemOverrideOptions[] = [
                { name: "MenuButton", index: 0, location: "before" },
                { name: "CustomOpenButton", index: 2, location: "before" },
                { name: "ViewLookup", index: 4, location: "after" },
                { name: "RefreshButton", index: 5, location: "after" },
            ];

            new AwesomeToolbar(
                invdc.DataToolbarAreaContainer,
                itemTypes.itemEventInvitation,
                GridContainerTypes.Event,
                invdc.CustomName,
                ToolbarItems,
                {
                    ItemType: itemTypes.itemEvent,
                    gridContainerType: GridContainerTypes.Event,
                    UniqueCenterName: invdc.CustomName,
                    OpenButtonOnClick: invdc.OpenButtonOnClick.bind(invdc),
                }
            ).init();
        }
    }

    initTabs() {
        let invdc = this;
        let tabs = new DataCenterTabs(
            invdc.DataTabsAreaContainer,
            invdc.CenterType,
            invdc.DataGridAreaContainer,
            invdc.CustomName,
            invdc.InDefaultContainer,
            <GridSetup.EventRequestArgs>{
                EventID: invdc.EventID
            }
        );

        tabs.Welcome = `<div class="center-centered-content">  
            <object data = "/Content/img/customerIcon.svg" style = "max-width: 200px" />
            <h5 style = "color:#A4A4A4" > Select an item from the menu to get started.</h5>
            </div>`;

        tabs.OveriddenGridOptions = {
            selection: {
                mode: 'single'
            }
        }

        tabs.Init();
    }

    private SubscribeToMenuEvents() {
        let invdc = this;
        AddHandler2(
            EventTypes.CenterEventTypes.itemtype,
            invdc.CustomName,
            eventNameSpace.modify,
            invdc.DataToolbarAreaContainer,
            invdc.OnMenuSelectionChanged.bind(invdc)
        );
    }

    OnMenuSelectionChanged(e: JQueryEventObject, data) {
        let invdc = this;

        if (invdc.DataToolbarAreaContainer.children().length === 0) {
            invdc.MenuAreaContainer.hide();

            let ToolbarItems: IToolbarItemOverrideOptions[] = [
                { name: "MenuButton", index: 0, location: "before" },
                { name: "OpenButton", index: 1, location: "before" },
                { name: "ActionButton", index: 2, location: "before" },
                { name: "ViewLookup", index: 4, location: "after" },
                { name: "RefreshButton", index: 5, location: "after" },
                { name: "OptionsButton", index: 6, location: "after" }
            ];

            if ((Preferences.GetCompanyPreference("ShowViewsBtn", "TDFMobile", "1") === "1")) {
                ToolbarItems.push({ name: "ViewButton", index: 3, location: "before" });
            }

            new AwesomeToolbar(
                invdc.DataToolbarAreaContainer,
                data.ItemType,
                GridContainerTypes.InfoCenter,
                invdc.CustomName,
                ToolbarItems,
                {
                    ItemType: data.ItemType,
                    gridContainerType: GridContainerTypes.InfoCenter,
                    UniqueCenterName: invdc.CustomName
                }
            ).init();
        }
    }

    protected OpenButtonOnClick() {
        let invdc = this;

        let data = { items: [] };
        RaiseEvent2(EventTypes.CenterEventTypes.gridselecteditems, invdc.CustomName, eventNameSpace.request, data);

        if (data.items.length) {
            new AttendeeSummary(invdc.EventID, data.items[0].ContactID, parseInt(data.items[0].ItemType));
        }
    }

}

