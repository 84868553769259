import * as Globalize from 'globalize';
//import { SearchRecFav } from "../../1recyclebin/srf";
import { Dialog } from '../dialogs/dialog';
import { Notification } from '../dialogs/notification';
import { EnumParent, itemTypes, GridContainerTypes } from '../../enums/enums';
import { IGridDataResponse } from '../../interfaces/interfaces';
import { NewExpenseArgs } from '../../interfaces/items/interfaces';
import { Grid } from '../../legacy-centers-grids/grid';
import { GridSetup } from '../../services/grid/helpers/gridsetup';
import { TDFRequest, TDFRequestSettings } from '../../services/request';
import { AttachmentViewer } from '../controls/attachmentviewer';
import { Expense } from './expense';
import { IcExpense } from './icexpense';
import { ItemBase } from './itembase';
import { DomSafeID, GetDevice } from '../../util/allutils';
import dxDataSource from 'devextreme/data/data_source';
import { GridToolbar } from '../grids/gridtoolbars/gridtoolbar';
import { LoadCompany, CurrentUser } from '../../infrastructure/context';
import { SearchRecFav } from '../controls/searchrecfav';
import { ValidParents } from 'services/item/itemdataservice';

export class ExpenseWizard {
  Requestmodel: GridSetup.ExpenseWizardRequestModel;
  Toolbar: GridToolbar;
  GridContainer: JQuery;
  myGrid: Grid;
  myList: any;
  loadFor: string = '';
  CurrentItem: Expense = null;
  newLinkedDialog: Dialog;

  constructor(args: any) {
    let expenseWizard = this;
    let expenseID = args && args.ExpenseID ? args.ExpenseID : '';
    expenseWizard.GridContainer = $('#expenseWizardContainer');

    $.when(LoadCompany()).done(function () {
      // if (expenseID != "") {
      expenseWizard.CurrentItem = new Expense({
        ItemID: expenseID,
        ItemType: 12
      });
      //expenseWizard.CurrentItem.ItemID = expenseID;
      expenseWizard.CurrentItem.Initialize().done((theExpense: ItemBase) => {
        let itemOwnerName = theExpense.iMain['ItemOwner'] || '';
        expenseWizard.loadFor = theExpense.iMain['ItemOwnerId'] || '';
        //  expenseWizard.loadFor = itemOwnerID;        //TODO:  Don't set this if the user isn't an expense admin.
        expenseWizard.Load();
      });
      // }
      // else {
      //     expenseWizard.Load();
      // }
    });
  }

  GetRequest() {
    let expenseWizard = this;
    let expenseWizarddfd = $.Deferred();
    if (expenseWizard.Requestmodel)
      return expenseWizarddfd.promise(expenseWizarddfd.resolve(expenseWizard));

    let requestArgs = new GridSetup.ExpenseWizardRequestArgs();
    requestArgs.GUID = expenseWizard.Toolbar.DefaultView;
    requestArgs.pageSize = 75;
    if ($('#ExpenseWizardUserSelector').length) {
      requestArgs.LoadForOwner =
        $('#ExpenseWizardUserSelector')
          .dxSelectBox('instance')
          .option('value') || '';
    } else {
      requestArgs.LoadForOwner = '';
    }

    expenseWizard.Requestmodel = new GridSetup.ExpenseWizardRequestModel(
      requestArgs
    );

    return expenseWizarddfd.promise(expenseWizarddfd.resolve(expenseWizard));
  }

  Load() {
    let expenseWizard = this;
    let expenseWizarddfd = $.Deferred();

    expenseWizard.CreateButtonToolbar();

    expenseWizard.ToolbarModel().done(function (response) {
      expenseWizard.GetRequest().done(function () {
        if (expenseWizard.Requestmodel.RequestArgs.GUID) {
          expenseWizard.GetData();
        }
        expenseWizarddfd.resolve(expenseWizard);
      });
    });
    return expenseWizarddfd.promise(expenseWizard);
  }
  /***
   *
   * */
  GetData() {
    let expenseWizard = this;
    let expenseWizarddfd = $.Deferred();
    if (!expenseWizard.Requestmodel.RequestArgs.GUID) {
    }
    let request = new TDFRequest({
      url: expenseWizard.Requestmodel.Path,
      type: 'GET',
      data: expenseWizard.Requestmodel.RequestArgs
    });
    request.spinnerContainerID = 'expenseWizardContainer';
    expenseWizard.Toolbar.GridDataRequestModel = expenseWizard.Requestmodel;

    request.MakeRequest().done(function (response: IGridDataResponse) {
      if (expenseWizard.myGrid && expenseWizard.myGrid.GridObject) {
        expenseWizard.myGrid.GridDataResponse = response;

        //expenseWizard.Toolbar.CurrentGrid.GridDataResponse = response;
        expenseWizard.myGrid.GridObject.option(
          'dataSource',
          expenseWizard.myGrid.DataSource
        );
        expenseWizard.myGrid.GridObject.getDataSource()
          .reload()
          .done(() => {
            expenseWizard.Toolbar.CurrentGrid.GridObject.getDataSource().reload();
            expenseWizarddfd.resolve(expenseWizard);
          });
        return expenseWizarddfd.promise();
      }

      let t = $.grep(
        response.GridView.Layout.Columns,
        (val: DevExpress.ui.dxDataGridColumn, key) => {
          return val.dataField === 'TDF_AttachmentCount';
        }
      );
      if (t.length) {
        t[0].visible = true;
        t[0].visibleIndex = 0;
        t[0].width = 25;
        t[0].caption = '';
        t[0].cellTemplate = (el, args) => {
          if (args.data['TDF_AttachmentCount'] > 0) {
            $('<span />')
              .addClass('fa fa-paperclip fa-lg')
              .css('cursor', 'pointer')
              .on('click', function (e) {
                let viewer = new AttachmentViewer(args.data['TDF GUID'], 12);
                viewer.DisplayFiles();
              })
              .appendTo(el);
          }
          el.css('text-align', 'left');
        };
        t[0].allowHeaderFiltering = false;
      }
      response.GridView.Layout.Columns.push({
        visible: true,
        caption: '',
        dataField: 'TDF GUID',
        visibleIndex: 0,
        width: 30,
        cellTemplate: (el, args) => {
          $('<span />')
            .addClass('fa fa-camera fa-md')
            .css('cursor', 'pointer')
            .on('click', function (e) {
              let theID =
                'expensefileupload_' + DomSafeID(args.data['TDF GUID']);

              if ($('#' + theID).length) {
                $('#' + theID).click();
              } else {
                if (!$('#expensefileuploadform_' + theID).length) {
                    $('body').append(
                        $('<form />')
                            .attr({
                                id: 'expensefileuploadform_' + theID,
                                action:
                                    '/Attachment/Attach/?itemID=' +
                                    encodeURIComponent(args.data['TDF GUID']) +
                                    '&itemType=12',
                                method: 'POST',
                                enctype: 'multipart/form-data'
                            })
                            .append(
                                $('<input />')
                                    .addClass('hidden')
                                    .attr({
                                        type: 'file',
                                        name: 'file',
                                        accepts: 'image/*',
                                        capture: 'camera',
                                        id: theID
                                    })
                                    .on('change', function (e) {
                                        var formdata = new FormData();
                                        formdata.append(
                                            'files',
                                            (this as HTMLInputElement).files[0]
                                        );

                                        let settings: TDFRequestSettings = {
                                            url: '/Attachment/Attach/?itemID=' +
                                                encodeURIComponent(args.data['TDF GUID']) +
                                                '&itemType=12',
                                            type: 'POST',
                                            cache: false,
                                            processData: false
                                        };

                                        let req = new TDFRequest(settings);
                                        req.MultiPartUpload(formdata);
                                       
                                    })


                      )
                  );
                }
                $('#' + theID).click();
              }
            })
            .appendTo(el);

          el.css('text-align', 'left');
        },
        allowHeaderFiltering: false
      });
      expenseWizard.myGrid = new Grid(
        response,
        {
          GridContainer: expenseWizard.GridContainer,
          GridToolbar: expenseWizard.Toolbar
        },
        { height: $(window).innerHeight() - 110, columnAutoWidth: true },
        expenseWizard,
        e => {
          expenseWizard.Toolbar.SelectionChanged(e);
        }
      );

      expenseWizard.myGrid.Render();
      expenseWizard.Toolbar.CurrentGrid = expenseWizard.myGrid;

      expenseWizarddfd.resolve(expenseWizard);
    });
    return expenseWizarddfd.promise(expenseWizard);
  }


  BuildRequest(args: NewExpenseArgs) {

      let expenseWizard = this;
      expenseWizard.NewExpenseArgs = args;

    return {
        url: "/expense/createExpense",
        data: {
            args: expenseWizard.NewExpenseArgs,
            expenseData: expenseWizard.ExpenseDetailsForNewExpense,
            dirtylaundry: expenseWizard.GetUDFs(),
            mileageinfo: expenseWizard.CurrentItem.ExpenseDetails.ExpenseMileage || null
        },
        type: "POST"
    }
}

  CreateButtonToolbar() {
      
      let expenseWizard = this;

    $('#expenseWizardCreateToolbar')
        .css('margin-bottom', '3px')
        .css("margin-top", "3px")
      .dxToolbar({
        items: [
          {
            locateInMenu: 'never',
            location: 'before',
            widget: 'dxButton',
            options: {
              elementAttr: { id: 'newGeneralExpenseBtn' },
              icon: '',
              hint: 'New (General)',
              text: 'New (General)',
              type: 'default',
              visible: true,
              onClick: function () {
                // The only thing required for a new General Expense is a description.
                if (!expenseWizard.CurrentItem.ExpenseDetails.Description) {
                  let txtDescription = $('<div />')
                    .css('margin-top', '2em')
                    .dxTextBox({ width: '60%' })
                    .dxTextBox('instance');
                  let message: any = {};
                  message.text = $('<div />')
                    .append(
                      $('<span />')
                        .text('Please Enter a Description!')
                        .css('margin-bottom', '2em')
                    )
                    .append(txtDescription.element());
                  message.buttons = $('<div />')
                    .append(
                      $('<span />')
                        .css('margin', '0 5px')
                        .append(
                          $('<div />').dxButton({
                            text: 'Continue',
                            icon: 'check',
                            type: 'success',
                            onClick: function (e: any) {
                              let toast = $($(e.element[0]).parents())
                                .find(
                                  '.dx-overlay.dx-widget.dx-visibility-change-handler.dx-toast'
                                )
                                .dxToast('instance');
                              expenseWizard.CurrentItem.ExpenseDetails.Description = txtDescription.option(
                                'value'
                              );
                              if (
                                expenseWizard.CurrentItem.ExpenseDetails
                                  .Description
                              ) {
                                toast.hide();
                                expenseWizard.CreateGeneralExpense();
                              }

                              return;
                            }
                          })
                        )
                    )
                    .append(
                      $('<span />')
                        .css('margin', '0 5px')
                        .append(
                          $('<div />').dxButton({
                            text: 'Cancel',
                            icon: 'revert',
                            type: 'danger',
                            onClick: function (e: any) {
                              let toast = $($(e.element[0]).parents())
                                .find(
                                  '.dx-overlay.dx-widget.dx-visibility-change-handler.dx-toast'
                                )
                                .dxToast('instance');
                              toast.hide();

                              return;
                            }
                          })
                        )
                    );
                  new Notification({
                    type: 'warning',
                    shading: true,
                    displayTime: 2000000,
                    message: message
                  });
                  return;
                } else {
                  expenseWizard.CreateGeneralExpense();
                }
              }
            }
          },
          {
            locateInMenu: 'never',
            location: 'before',
            widget: 'dxButton',
            options: {
              elementAttr: { id: 'newExpenseWithParentBtn' },
              icon: '',
              hint: 'New (Keep Parent)',
              text: 'New (Keep Parent)',
              type: 'default',
              visible: true,
              disabled: !expenseWizard.CurrentItem.ItemID,
              onClick: function () {
                // There needs to be a current open expense to create a new with parent.
                if (!expenseWizard.CurrentItem.ExpenseDetails.Description) {
                  new Notification({
                    message: 'Please Enter a Description!',
                    type: 'warning',
                    shading: false
                  });
                  return;
                }

                expenseWizard.NewExpenseArgs = {
                  ParentID:
                    expenseWizard.CurrentItem.iMain.PrimaryParent.ParentId,
                  ParentInfo: EnumParent.KeepParent,
                  ReferenceExpenseID: expenseWizard.CurrentItem.ItemID,
                  ParentType:
                    expenseWizard.CurrentItem.iMain.PrimaryParent.ParentType
                };

                if (
                  expenseWizard.CurrentItem.ExpenseDetails.ExpenseMileage &&
                  typeof (expenseWizard.CurrentItem.ExpenseDetails
                    .ExpenseMileage.TotalMileageAmount as any) === 'string'
                ) {
                  expenseWizard.CurrentItem.ExpenseDetails.ExpenseMileage.TotalMileageAmount = Globalize.parseNumber(
                    (expenseWizard.CurrentItem.ExpenseDetails.ExpenseMileage
                      .TotalMileageAmount as any).replace('$', ''),
                    { style: 'decimal' }
                  );
                }

                  let requestBody = expenseWizard.BuildRequest(expenseWizard.NewExpenseArgs);

                  new TDFRequest(requestBody)
                      .MakeRequest()
                      .done(function (response: any) {
                          let exp = new Expense({ ItemID: response.ID });
                          exp.Initialize().done((expense: ItemBase) => {
                              expenseWizard.CurrentItem = <Expense>expense;
                              expenseWizard.GetData();
                          });
                      
                      }).catch((e) => {

                          new Notification({
                            message: e.Message,
                            type: "error",
                            shading: false
                          });

                      });
              }
            }
          },
          {
            locateInMenu: 'never', //GetDevice().isDevice ? 'always' : 'auto',
            location: 'before',
            widget: 'dxButton',
            options: {
              elementAttr: { id: 'newLinkedExpenseBtn' },
              icon: '',
              hint: 'New (Linked)',
              text: 'New (Linked)',
              type: 'default',
              visible: true,
              onClick: function () {
                let generalExpenseButton = {
                  id: 'btnGeneralExp',
                  autospin: true,
                  cssClass: 'btn-warning',
                  action: function (dlg) {
                    //let t = {
                    //    New: true,
                    //    Description: "General " + " -- " + Globalize.formatDate(new Date(), { date: "short" }),
                    //    Dirty: true,
                    //    ExpenseAmount: 0,
                    //    ExpenseDate: Globalize.formatDate(new Date(), { date: "short" }),
                    //    MileageID: -1
                    //};
                    expenseWizard.NewExpenseArgs = {
                      ParentID: '',
                      ParentInfo: EnumParent.General,
                      ReferenceExpenseID: '',
                      ParentType: itemTypes.itemUnknown
                    };

                    if (
                      expenseWizard.CurrentItem.ExpenseDetails.ExpenseMileage &&
                      typeof (expenseWizard.CurrentItem.ExpenseDetails
                        .ExpenseMileage.TotalMileageAmount as any) === 'string'
                    ) {
                      expenseWizard.CurrentItem.ExpenseDetails.ExpenseMileage.TotalMileageAmount = Globalize.parseNumber(
                        (expenseWizard.CurrentItem.ExpenseDetails.ExpenseMileage
                          .TotalMileageAmount as any).replace('$', ''),
                        { style: 'decimal' }
                      );
                    }

                      let requestBody = expenseWizard.BuildRequest(expenseWizard.NewExpenseArgs);

                    new TDFRequest(requestBody)
                      .MakeRequest()
                      .done(function (response: any) {
                        if (true) {
                          dlg.Dialog.close();
                          window.location.href =
                            '/?itemid=' + response;
                        }
                        new Notification({
                          message: response.Message,
                          type: response.Valid ? 'success' : 'error',
                          shading: false
                        });
                      });
                  },
                  icon: 'glyphicon glyphicon-pencil',
                  label: 'Create General Expense'
                };

                ValidParents(itemTypes.itemExpense).done(function (response: any) {
                  let SRF: SearchRecFav = new SearchRecFav(
                    itemTypes.itemExpense,
                    response,
                    itemTypes.itemUnknown,
                    false,
                    0,
                    generalExpenseButton
                  );

                  SRF.GetSRFDialog().done(selected => {
                    // let desc = selected[0]["Company"] || selected[0]["Subject"] || selected[0]["CompanyName"] || selected[0]["AccountName"] || selected[0]["Description"];

                      let parentId = selected[0]['TDF GUID'].toString().trim();

                      if (parentId === "") {

                          SRF.Dialog.close();

                          new Notification({
                              message: "The item you selected has no TDF GUID. This is a strange occurence. Please try your search again and select a different parent item",
                              type: 'error',
                              shading: false
                          });

                      } else {
                          expenseWizard.NewExpenseArgs = {
                              ParentID: parentId,
                              ParentType: selected[0]['ItemType'],
                              ReferenceExpenseID:
                                  expenseWizard.CurrentItem.ItemID || '',
                              ParentInfo: EnumParent.Linked
                          };
                          if (
                              expenseWizard.CurrentItem.ExpenseDetails.ExpenseMileage &&
                              typeof (expenseWizard.CurrentItem.ExpenseDetails
                                  .ExpenseMileage.TotalMileageAmount as any) === 'string'
                          ) {
                              expenseWizard.CurrentItem.ExpenseDetails.ExpenseMileage.TotalMileageAmount = Globalize.parseNumber(
                                  (expenseWizard.CurrentItem.ExpenseDetails.ExpenseMileage
                                      .TotalMileageAmount as any).replace('$', ''),
                                  { style: 'decimal' }
                              );
                          }

                          new TDFRequest({
                              url: '/Expense/CreateExpense/',
                              data: {
                                  args: expenseWizard.NewExpenseArgs,
                                  expensedata: expenseWizard.ExpenseDetailsForNewExpense,
                                  dirtylaundry: expenseWizard.GetUDFs(),
                                  mileageinfo:
                                      expenseWizard.CurrentItem.ExpenseDetails
                                          .ExpenseMileage || null
                              },
                              type: 'POST'
                          })
                              .MakeRequest()
                              .done(function (response: { ID: string, Message: string }) {

                                  if (window.location.href.indexOf('expensewizard') > -1) {
                                      let exp = new Expense({ ItemID: response.ID });
                                      exp.Initialize();
                                  } else {
                                      window.location.href = window.location.origin + '/expense/?itemid=' + response.ID;
                                  }

                                  SRF.Dialog.close();

                                  new Notification({
                                      message: response.Message,
                                      type: 'success',
                                      shading: false
                                  });
                              }).catch((err) => {

                                  new Notification({
                                      message: err.Message,
                                      type: 'error',
                                      shading: false
                                  });


                              });
                      }


                    
                  });
                });
              }
            }
          }
        ]
      })
      .dxToolbar('instance');
  }
  private _NewExpenseArgs: NewExpenseArgs;
  private set NewExpenseArgs(data: NewExpenseArgs) {
    let expenseWizard = this;
    expenseWizard._NewExpenseArgs = data;
  }
  private get NewExpenseArgs(): NewExpenseArgs {
    let expenseWizard = this;
    return expenseWizard._NewExpenseArgs;
  }

  private get ExpenseDetailsForNewExpense() {
    let expenseWizard = this;
    return {
      New: true,
      Description: expenseWizard.CurrentItem.ExpenseDetailForm.getEditor(
        'Description'
      ).option('value'),
      Dirty: true,
      ExpenseAmount:
        typeof expenseWizard.CurrentItem.ExpenseDetailForm.getEditor(
          'ExpenseAmount'
        ).option('value') === 'number'
          ? expenseWizard.CurrentItem.ExpenseDetailForm.getEditor(
            'ExpenseAmount'
          ).option('value')
          : Globalize.parseNumber(
            expenseWizard.CurrentItem.ExpenseDetailForm.getEditor(
              'ExpenseAmount'
            ).option('value'),
            { style: 'decimal' }
          ),
      ExpenseDate: expenseWizard.CurrentItem.ExpenseDetailForm.getEditor(
        'ExpenseDate'
      ).option('value'),
      MileageID: expenseWizard.CurrentItem.ExpenseDetails.MileageID,
      Reimbursement: expenseWizard.CurrentItem.ExpenseDetailForm.getEditor(
        'Reimbursement'
      ).option('value')
    };
  }

  private OverrideAccordionClick(e) {
    let expenseWizard = this;
    let parentid = e.itemData['TDF GUID'];
    let desc =
      e.itemData['Company'] ||
      e.itemData['Subject'] ||
      e.itemData['CompanyName'] ||
      e.itemData['AccountName'] ||
      e.itemData['Description'];
    let expenseDetails: IcExpense;

    if ($(e.jQueryEvent.target).hasClass('rec-fav-action-buttons')) return;

    expenseWizard.newLinkedDialog.close();

    if (!expenseWizard.CurrentItem) {
      expenseDetails = {
        New: true,
        Description:
          desc ||
          ' ' + ' -- ' + Globalize.formatDate(new Date(), { date: 'short' }),
        Dirty: true,
        ExpenseAmount: 0,
        ExpenseDate: Globalize.formatDate(new Date(), { date: 'short' }),
        MileageID: -1,
        Reimbursement: true
      };
    } else {
      expenseDetails = {
        New: true,
        Description: expenseWizard.CurrentItem.ExpenseDetails.Description,
        Dirty: true,
        ExpenseAmount:
          typeof expenseWizard.CurrentItem.ExpenseDetails.ExpenseAmount ===
            'number'
            ? expenseWizard.CurrentItem.ExpenseDetails.ExpenseAmount
            : Globalize.parseNumber(
              expenseWizard.CurrentItem.ExpenseDetails.ExpenseAmount,
              { style: 'decimal' }
            ),
        ExpenseDate: expenseWizard.CurrentItem.ExpenseDetails.ExpenseDate,
        MileageID: expenseWizard.CurrentItem.ExpenseDetails.MileageID,
        Reimbursement: expenseWizard.CurrentItem.ExpenseDetails.Reimbursement
      };
    }

    let nexpargs: NewExpenseArgs = {
      ParentID: parentid,
      ParentInfo: EnumParent.Linked,
      ReferenceExpenseID: expenseWizard.CurrentItem.ItemID || '',
      ParentType: e.itemData.ItemType || itemTypes.itemUnknown
    };

      new TDFRequest({
          url: '/ExpenseWizard/CreateExpense/',
          data: {
              args: nexpargs,
              expensedata: expenseDetails,
              dirtylaundry: expenseWizard.GetUDFs(),
              mileageinfo: expenseWizard.CurrentItem.MileageDirty
                  ? expenseWizard.CurrentItem.MileageInfo
                  : null
          },
          type: 'POST'
      })
          .MakeRequest()
          .done(function (response: any) {
              let exp = new Expense({ ItemID: response.ID });
              exp.Initialize().done((expense: ItemBase) => {
                  expenseWizard.CurrentItem = <Expense>expense;
                  expenseWizard.GetData();
              });
              $('#newExpenseWithParentBtn')
                  .dxButton('instance')
                  .option('disabled', false);
          }).catch((e) => {

              new Notification({
                  message: e.Message,
                  type: "error",
                  shading: false
              });


          });
  }

  private CreateGeneralExpense() {

      // RTM: I might have broken some of this, but it was pretty darn broken before.

      let expenseWizard = this;

      let nexpargs: NewExpenseArgs = {
          ParentID: '',
          ParentInfo: EnumParent.General,
          ReferenceExpenseID: expenseWizard.CurrentItem.ItemID || '',
          ParentType: itemTypes.itemUnknown
        };

     let data = expenseWizard.BuildRequest(nexpargs);
      
      new TDFRequest(data)
          .MakeRequest()
          .done(function (response: any) {
                let exp = new Expense({ ItemID: response.ID });
                exp.Initialize().done((expense: ItemBase) => {
                    expenseWizard.CurrentItem = <Expense>expense;
                    expenseWizard.GetData();
                });
                $('#newExpenseWithParentBtn')
                    .dxButton('instance')
                    .option('disabled', false);
          }).catch((e) => {

              new Notification({
                  message: e.Message,
                  type: 'error',
                  shading: false
              });

          });
  }

  private GetUDFs() {
    let expenseWizard = this;
    let controls = [];
    if (expenseWizard.CurrentItem) {
      $.each(
        expenseWizard.CurrentItem.Controls,
        (controlgroupid, controlgroup) => {
          $.each(controlgroup, (idx, controldata: any) => {
            if (controldata.Dx) {
              delete controldata.Dx;
            }
            if (
              !controldata.SaveValue &&
              controldata.DataValue &&
              controldata.DataValue.ValueName
            ) {
              controldata.SaveValue = controldata.DataValue.ValueName;
            }
            controls.push(controldata);
          });
        }
      );
    }
    return controls;
  }

  ToolbarModel() {
    let expenseWizard = this;
    let expenseWizarddfd = $.Deferred();
    if (expenseWizard.Toolbar)
      return expenseWizarddfd.promise(expenseWizarddfd.resolve(expenseWizard));

    let request = new TDFRequest({
      url: `/Expense/Toolbar/`,
      type: 'GET'
    });

    request.MakeRequest().done(function (response: any) {
      let ToolbarModel = response;
      response["ToolbarContainerID"] = 'expenseWizardToolbar';
      expenseWizard.Toolbar = new GridToolbar(response);

      expenseWizard.Toolbar.ExtraItems = [
        {
          locateInMenu: GetDevice().isDevice ? 'never' : 'auto',
          location: 'before',
          template: function (
            itemData: any,
            itemIndex: number,
            itemElement: JQuery
          ) {
            let expenseUserSelector = $(
              "<div id='ExpenseWizardUserSelector'/>"
            ).dxSelectBox({
              dataSource: new dxDataSource({
                store: response.UserListing,
                sort: 'Name'
              }),
              searchEnabled: true,
              disabled: !response.IsExpenseAdmin,
              displayExpr: 'Name',
              valueExpr: 'SearchKey',
              value: response.IsExpenseAdmin
                ? expenseWizard.loadFor || CurrentUser.ID
                : CurrentUser.ID,
              onValueChanged: function (e: any) {
                expenseWizard.Requestmodel.RequestArgs.LoadForOwner = e.value;
                expenseWizard.GetData().done(() => {
                  // expenseWizard
                });
              }
            });
            itemElement.append(expenseUserSelector);
          }
        }
      ];
      expenseWizard.Toolbar.RefreshCurrentGrid = expenseWizard.RefreshCurrentGrid.bind(
        expenseWizard
      );
      if (!expenseWizard.Toolbar.CurrentView)
        expenseWizard.Toolbar.CurrentView = expenseWizard.Toolbar.DefaultView;
      expenseWizard.Toolbar.Render();
      expenseWizarddfd.resolve(expenseWizard);
    });

    return expenseWizarddfd.promise(expenseWizard);
  }

  RefreshCurrentGrid(e) {
    let expenseWizard = this;
    expenseWizard.GetData();
  }

}

