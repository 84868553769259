import { TDFRequest } from "../request";
import { itemTypes, menuActions, tdfModules } from "../../enums/enums";
import { IsModuleLicensedAtCompany } from "infrastructure/context";

export function ValidParents(itemtype) {
  let d = $.Deferred();

  req({
    url: `${TDFRequest.ApiPath}/item/ValidParents/`,
    data: { itemtype: itemtype }
  }).done(response => {
    d.resolve(response);
  });
  return d.promise();
}

export function NewWithParent(args) {
  let d = $.Deferred();
  req({
    url: `${TDFRequest.ApiPath}/item/NewWithParent/`,
    data: args,
    type: "POST",
    dataType: "application/json"
  }).done(response => {
    d.resolve(response);
  });
  return d.promise();
}

export function ActionsOnly(args) {
  let d = $.Deferred();
  req({
    url: `${TDFRequest.ApiPath}/action/getactionsonly/?${$.param(args)}`,
    type: "GET"
  }).done(response => {
    if (!IsModuleLicensedAtCompany(tdfModules.TDF_InfoCenter)) {     // Note:  This isn't an ideal check, but it allows the New and Link actions to be hidden for Sherpa BI.
      if (response && response.ActionsModel && response.ActionsModel.ActionList && response.ActionsModel.ActionList.length > 0) {
        response.ActionsModel.ActionList = response.ActionsModel.ActionList.filter((actionItem) => { if ((actionItem.Action != menuActions.menuNewGeneralItem) && (actionItem.Action != menuActions.menuLinkItem)) { return actionItem; } })
      }
    }
    d.resolve(response);
  });
  return d.promise();
}

export function ViewsOnly(args) {
  let d = $.Deferred();
  req({
    url: `${TDFRequest.ApiPath}/action/getviewsonly/?${$.param(args)}`,
    type: "GET"
  }).done(response => {
    d.resolve(response);
  });
  return d.promise();
}

export function Save(args, spinnerid?) {
  let d = $.Deferred();
  req(
    {
      url: `${TDFRequest.ApiPath}/Item/Save/`,
      data: args,
      type: "POST",
      contentType: "application/json"
    },
    false
  )
    .done(response => {
      return d.resolve(response);
    })
    .fail(response => {
      return d.reject(response);
    });
  return d.promise();
}

export function VerifyRequiredFieldsOnly(args, spinnerid?) {

	let d = $.Deferred();
	req(
		{
			url: `${TDFRequest.ApiPath}/Item/VerifyRequiredFieldsBeforeSave`,
			data: args,
			type: "POST",
			contentType: "application/json"
		},
		false
	)
		.done(response => {
			return d.resolve(response);
		})
		.fail(response => {
			return d.reject(response);
		});
	return d.promise();

}

export function UpdateAddress(args) {
  let d = $.Deferred();
  const requests: Array<JQueryPromise<any>> = [];
  $.each(args, (k, v) => {
    requests.push(
      req({
        url: `${TDFRequest.ApiPath}/item/UpdateAddress/`,
        data: v,
        type: "POST"
      })
    );
  });
  $.when.apply($, requests).done(responses => {
    d.resolve(responses);
  });

  return d.promise();
}

export function Copy(args) {
  let d = $.Deferred();
  req({
    url: `${TDFRequest.ApiPath}/item/CopyItem/`,
    type: "POST",
    data: args
  }).done(r => {
    d.resolve(r)
  });
  return d.promise();
}

export function Delete(args) {
  let d = $.Deferred();
  req({
    url: `${TDFRequest.ApiPath}/item/DeleteItem/`,
    type: "POST",
    data: args
  }).done(r => {
    d.resolve(r)
  });
  return d.promise();
}

// TDFRequest.ApiPath + "/quicknotes/notes?itemId=" + iBase.ItemID
export function QuickNotes(itemid) {
  let d = $.Deferred();
  req({
    url: `${TDFRequest.ApiPath}/quicknotes/notes?itemId=${itemid}`,
    type: "GET"
  }).done(r => {
    d.resolve(r);
  });
  return d.promise();
}

export function SalesStageHistory(itemID) {
  let d = $.Deferred();
  req({
    url: `${TDFRequest.ApiPath}/item/SalesStageHistory/?oppid=${itemID}`,
    type: "GET"
  }).done(r => {
    d.resolve(r);
  });
  return d.promise();
}

export function UpdateSalesStageHistory(args) {
  let d = $.Deferred();
  req({
    url: `${TDFRequest.ApiPath}/item/UpdateSalesStageHistory/`,
    type: "POST",
    data: args
  }).done(r => {
    d.resolve(r);
  });
  return d.promise();
}
export function GenerateUniqueID(args) {
  let d = $.Deferred();
  req({
    url: `${TDFRequest.ApiPath}/item/GenerateUniqueID/?${$.param(args)}`,
    type: "GET"
  }).done(r => {
    d.resolve(r);
  });
  return d.promise();
}

export function GetChangeLogData(args) {
  let d = $.Deferred();
  req({
    url: `${TDFRequest.ApiPath}/item/GetChangeLogData/`,
    type: "GET",
    data: args
  }).done(r => {
    d.resolve(r);
  });
  return d.promise();
}

export function GetDefaultQuickViewType(args) {
  let d = $.Deferred();
  req({
    url: `${TDFRequest.ApiPath}/core/user/GetDefaultQuickViewType/`,
    type: "GET",
    data: args
  }).done(r => {
    d.resolve(r);
  });
  return d.promise();
}

export function GetContainerTypeFromItemType(args) {
  let d = $.Deferred();
  req({
    url: `${TDFRequest.ApiPath}/Action/GetContainerTypeFromItemType/`,
    type: "GET",
    data: args
  }).done(r => {
    d.resolve(r);
  });
  return d.promise();
}

export function DocCreationArgs(args) {
  let d = $.Deferred();
  req({ url: `/template/DocCreationArgs/`, type: "GET", data: args }).done(
    r => {
      d.resolve(r);
    }
  );
  return d.promise();
}

export function SaveDocument(args) {
  let d = $.Deferred();
  req({ url: TDFRequest.ApiPath + `/template/SaveDocument`, type: "POST", data: args }).done(r => {
    d.resolve(r);
  });
  return d.promise();
}



export function ContactMarketingLastSync(args) {
  let d = $.Deferred();
  req({
    url: `${
      TDFRequest.ApiPath
      }/marketing/marketingcore/ContactMarketingLastSync/`,
    type: "GET",
    data: args
  }).done(r => {
    d.resolve(r);
  });
  return d.promise();
}

export function contactreport(args) {
  let d = $.Deferred();
  req({
    url: `${TDFRequest.ApiPath}/marketing/marketingcore/contactreport/`,
    type: "GET",
    data: args
  }).done(r => {
    d.resolve(r);
  });
  return d.promise();
}

export function FormSubmissions(args) {
  let d = $.Deferred();
  req({
    url: `${TDFRequest.ApiPath}/marketing/marketingcore/FormSubmissions`,
    type: "GET",
    data: args
  }).done(r => {
    d.resolve(r);
  });
  return d.promise();
}

export function FormData(args) {
  let d = $.Deferred();
  req({
    url: `${TDFRequest.ApiPath}/marketing/marketingcore/FormData`,
    type: "GET",
    data: args
  }).done(r => {
    d.resolve(r);
  });
  return d.promise();
}

export function ScoreHistory(args) {
  let d = $.Deferred();
  req({
    url: `${TDFRequest.ApiPath}/marketing/marketingcore/ScoreHistory/`,
    type: "GET",
    data: args
  }).done(r => {
    d.resolve(r);
  });
  return d.promise();
}

export function NewNoParent(args) {
  let d = $.Deferred();
  req({
    url: `${TDFRequest.ApiPath}/item/NewNoParent/`,
    type: "GET",
    data: args
  }).done(r => {
    d.resolve(r);
  });
  return d.promise();
}

/*
export function NewNoParent(args) {
  let d = $.Deferred();
  req({url:`${TDFRequest.ApiPath}/item /NewNoParent/`, type:"GET", data:args}).done((r) => {d.resolve(r) })
  return d.promise();
}
 */

/**
 *
 * @param args
 */

export function ControlGroups(args) {
  let d = $.Deferred();
  req({
    url: `${TDFRequest.ApiPath}/item/getcontrolgroups/?${$.param(args)}`,
    type: "GET"
  }).done(r => {
    d.resolve(r);
  });
  return d.promise();
}

export function AddToRecent(itemid: string, itemtype: itemTypes) {
  let request = new TDFRequest({
    url: `${TDFRequest.ApiPath}/item/addrecentitem/`,
    data: { itemid: itemid, itemtype: itemtype },
    type: "POST"
  });
  request.MakeRequest();
}

export function ToggleActiveFlag(args) {
  let d = $.Deferred();
  req({
    url: `${TDFRequest.ApiPath}/action/UpdateTDFActive/`,
    type: "POST",
    data: args
  }).done(r => {
    d.resolve(r);
  });
  return d.promise();
}

export function UpdateItemFormType(args) {
  let d = $.Deferred();
  req({
    url: `${TDFRequest.ApiPath}/item/UpdateItemFormType/`,
    type: "POST",
    data: args
  }).done(r => {
    d.resolve(r);
  });
  return d.promise();
}

export function Controls(args) {
  let d = $.Deferred();
  req({
    url: `${TDFRequest.ApiPath}/item/getcontrols/`,
    type: "GET",
    data: args
  }).done(r => {
    d.resolve(r);
  });
  return d.promise();
}

export function ValuesForSubUDF(args) {
  let d = $.Deferred();
  req({
    url: `${TDFRequest.ApiPath}/item/GetValuesForSubUDF/`,
    type: "GET",
    data: args
  }).done(r => {
    d.resolve(r);
  });
  return d.promise();
}

export function Link(args) {
  let d = $.Deferred();
  req({
    url: `${TDFRequest.ApiPath}/item/Link/`,
    type: 'POST',
    data: args
  }).done(r => {
    d.resolve(r);
  });
  return d.promise();
}

export function LinkItems(args) {
  let d = $.Deferred();
  req({
    url: `${TDFRequest.ApiPath}/item/LinkItems/`,
    type: 'POST',
    data: args
  }).done(r => {
    d.resolve(r);
  });
  return d.promise();
}

export function AddItemLink(args) {
  let d = $.Deferred();
  req({
    url: `${TDFRequest.ApiPath}/item/AddItemLink/`,
    type: 'GET',
    data: args
  }).done(r => {
    d.resolve(r);
  });
  return d.promise();
}

export function LinkExistingContact(args) {
  let d = $.Deferred();
  req({
    url: `${TDFRequest.ApiPath}/item/LinkExistingContact/`,
    type: 'GET',
    data: args
  }).done(r => {
    d.resolve(r);
  });
  return d.promise();
}

function req(args, showErrors: boolean = true) {
  return new TDFRequest(args, showErrors).MakeRequest();
}
