import { CenterType, itemTypes } from "../enums/enums";
import { eventNameSpace, EventTypes } from "../enums/webevents/enums";
import { AddHandler2, RaiseEvent2 } from "../infrastructure/events/ui_events";
import { Preferences } from "../infrastructure/user/preferences";
import { TDFRequest } from "../services/request";
import { GetDevice, Debounce } from "../util/allutils";
import dxDataSource from "devextreme/data/data_source";
import dxCustomStore from "devextreme/data/custom_store";
import { GetQueryStringParam } from "util/getquerystringparam";
import { BaseDataCenterMenuDrawer } from "./basedatacentermenudrawer";

export class DataCenterMenuDrawer extends BaseDataCenterMenuDrawer {

  protected get MenuCtrlInstance(): DevExpress.ui.dxList {
    let md = this;
    if (md._MenuCtrlInstance) return md._MenuCtrlInstance as DevExpress.ui.dxList;
    md.MenuCtrlInstance = $(`#${md.FrameIDs.MenuDrawerListContainerID}`)
      .dxList(md.MenuListOptions)
      .dxList("instance");
    return md._MenuCtrlInstance as DevExpress.ui.dxList;
  }
  protected set MenuCtrlInstance(val: DevExpress.ui.dxList) {
    let md = this;
    md._MenuCtrlInstance = val;
  }

  protected get MenuListOptions(): DevExpress.ui.dxListOptions {
    let md = this;
    return {
      dataSource: md.MenuItemsDataSource,
      selectionMode: "single",
      onSelectionChanged(e) {
        $(`#${md.FrameIDs.SideNavContainerID} .activetype`).removeClass("activetype");
        let item_el = (md.MenuCtrlInstance as any)._selectedItemElement(
          (md.MenuCtrlInstance as any)._getSelectedItemIndices()
        );
        if (
          item_el &&
          !(item_el.children()[0].className.indexOf("activetype") > -1)
        )
          item_el.children()[0].className =
            item_el.children()[0].className + " activetype";
      },
      onItemClick(e) {
        RaiseEvent2(
          EventTypes.CenterEventTypes.itemtype,
          md.EventLimiterName,
          eventNameSpace.modify,
          e.itemData.refData
        );
      },

      menuMode: GetDevice().isDevice ? "context" : "slide",
      menuItems: [{ text: "Close All" }]
    };
  }

  constructor(
    container: JQuery,
    centertype: CenterType,
    eventLimiterName: string,
    baseDataCenterContainer: JQuery,
    inDefaultContainer: boolean
  ) {
    super(container, centertype, eventLimiterName, baseDataCenterContainer, inDefaultContainer)
  }

  protected SelectItem(item: JQuery) {
    let md = this;
    md.MenuCtrlInstance.selectItem(item);
    md.MenuCtrlInstance.scrollToItem(item);
  }

  protected BuildMenuItem(data) {
    return {
      html: `<span  class="datacenter_sidenav_item" data-sidenavitemtype="${
        data.ItemType
        }"> <span class='tdf_side_menu_text'>${
        data.DisplayName
        }</span> </span><i title='${
        data.DisplayName
        }' class='itemIcon  item${
        data.TypeName === "Opps w/Linked Accts"
          ? "Opportunity"
          : data.TypeName.replace(/ /g, "_").replace("(", "").replace(")", "")
        }'></i>`
    };
  }
}
