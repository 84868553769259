import { GetPreference } from "../../../infrastructure/user/getpreference";
import { PreferenceModuleNames, Preferences, TDFMobilePreferenceNames } from "../../../infrastructure/user/preferences";


export class HelpText {
    public HelpButton: DevExpress.ui.dxButton;

    constructor() {
        let HelpText = this;

        HelpText.CreateButton();
    }

    public ToggleHelpText() {
        let HelpText = this;

        if ($('body').hasClass('hideHelpText')) {
            $('body').removeClass('hideHelpText');
            Preferences.SetPreference(TDFMobilePreferenceNames.ShowHelpTextAutomatically, '1', PreferenceModuleNames.TDFMobile);
        } else {
            $('body').addClass('hideHelpText');
            Preferences.SetPreference(TDFMobilePreferenceNames.ShowHelpTextAutomatically, '0', PreferenceModuleNames.TDFMobile);
        }
    }

    public ShowHelpTextTutorial() {
        let theDialog = this;

        let showTut: boolean = Preferences.GetPreference("ShowHelpTextTutorial", "TDFMobile") !== "0";

        if (showTut) {
            let popDiv = $("<div id='helpPop'/>");
            popDiv.appendTo("body");

            let helpPop = $(popDiv).dxPopover({
                target: theDialog.HelpButton.element(),
                contentTemplate: function (contentElement) {
                    let dismissForNowBtnID = "";
                    let dismissForGoodBtnID = "";

                    let info = $("<div />").append(
                        $("<blockquote />")
                            .text("The help button can be used to toggle help text on most dialogs. Click this button to show and hide extra information describing each field. ")
                            .append($("<br>"))
                    ).append(
                        $(`<footer />`).css("color", "#fff")
                            .addClass("pull-right")
                            .append(
                                $('<span />').css("margin", "0 5px").append(
                                    $(`<div id='${dismissForNowBtnID}'></div>`).dxButton({
                                        text: "Dismiss for now",
                                        icon: "close",
                                        type: "success",
                                        onClick: function (e: any) {
                                            helpPop.hide();
                                            return;
                                        }
                                    })
                                ).append(
                                    $('<span />').css("margin", "0 5px").append(
                                        $(`<div id='${dismissForGoodBtnID}'></div>`).dxButton({
                                            text: "Dismiss for good",
                                            icon: "remove",
                                            type: "danger",
                                            onClick: function (e: any) {
                                                Preferences.SetPreference("ShowHelpTextTutorial", "0", "TDFMobile");
                                                helpPop.hide();
                                            }
                                        })
                                    )
                                )
                            )
                    );

                    contentElement.append(info);
                },
                width: 500
            }).dxPopover("instance");

            helpPop.show();
        }
    }

    private CreateButton() {
        let HelpText = this;

        HelpText.HelpButton = $('<div />').addClass('helpTextButton').css({
            'border': 'none',
            'background-color': 'inherit'
        }).dxButton(<DevExpress.ui.dxButtonOptions>{
            icon: 'fa fa-question',
            hint: 'Toggles helpful text on fields that have it',
            onClick: () => {
                HelpText.ToggleHelpText();
            }
        }).dxButton('instance');
    }
}

