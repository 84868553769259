import { eventNameSpace, EventTypes } from "../../../../enums/webevents/enums";
import { RaiseEvent2 } from "../../../../infrastructure/events/ui_events";

import { ToolbarTemplateFactory } from "./toolbartemplatefactory";
import { GetDevice } from "../../../../util/allutils";
import { IToolbarItemOverrideOptions } from "interfaces/grids/interfaces";


export function ClearButton(args, config?: IToolbarItemOverrideOptions) {
    let UniqueCenterName = '';
    if (args && args.length) {
        args.map((v, k) => {
            if ($.isPlainObject(v)) {
                Object.keys(v).forEach((val, key) => {
                    if (val.toLowerCase() === "uniquecentername") {
                        UniqueCenterName = v[val];
                    }
                });
            }
        });
    }

    //TODO: Add Config paramter and respect it
    return ToolbarTemplateFactory(
        config && config.location ? config.location : "after",
        config && config.locateInMenu ? config.locateInMenu : GetDevice().isDevice ? "always" : "auto",
        false,
        <DevExpress.ui.dxButtonOptions>{
            text: "Clear Items",
            hint: "Clear Recent Items",
            // elementAttr: { id: toolbar.scopeID },
            icon: "fa fa-minus-circle",
            onClick: function (e) {
                RaiseEvent2(EventTypes.RFCenterEventTypes.clear, UniqueCenterName, eventNameSpace.request, e);
            }
        }
        , "dxButton");
}


