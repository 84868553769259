import { ReportScopeSelection } from "../advancedreporting/reportscopeselection";
import { TileBase, TileScopeType } from "../dashboards/tilebase";
import { TileFactory } from "../dashboards/tilefactory";
import { TileSettingsBase } from "../dashboards/tilesettingsbase";
import { TDFRequest } from "../../services/request";
import { DomSafeID } from "../../util/allutils";

export enum ByorReportSelection {
  WithAutoBSID = 1,
  WithoutAutoBSID = 2,
  All = 3
}

export class ByorReportListTileSettings extends TileSettingsBase {
  ReportSelectionCriteria: ByorReportSelection = ByorReportSelection.All;
  AccordianWidth: string = "";
  Bsid: string = "";
  //ReportTitle: string = "";
  BsidType: string = "";
  SavedSelection: string = "";
}

export class ByorReportList extends TileBase {
  private ReportListURL: string = "/BYOR/ReportList/";
  protected _ScopeSupportLevel: TileScopeType = TileScopeType.ScopeNotSupported;
  protected _Name: string = "BYOR Report List";

  // IDs
  ReportListContainerID: string;
  SpinnerContainerID: string;
  ReportOptionsContainerID: string;

  constructor(
    tileInstanceID: string,
    tileSettings: Partial<ByorReportListTileSettings>,
    settingsClassRef: typeof TileSettingsBase = ByorReportListTileSettings
  ) {
    //reportTitle?: string, bsid?: string, bsidType?: string, savedSelection?: string) {
    super(tileInstanceID, tileSettings, settingsClassRef);

    let theByorReports = this;
    theByorReports.SetIDs();
    return theByorReports;
  }

  public Render(contentElement: JQuery): JQueryPromise<any> {
    let theByorReports = this;
    let dfd: JQueryDeferred<any> = $.Deferred();

    let content: string = `<div id="${theByorReports.SpinnerContainerID}"></div>
                <div id="${theByorReports.ReportListContainerID}"></div>`;

    $(contentElement).append(content);

    theByorReports.RequestReportList().done(() => {
      return dfd.resolve();
    });

    return dfd.promise();
  }

  private SetIDs() {
    let theByorReports = this;
    let domSafeID = DomSafeID(theByorReports.TileInstanceID);

    theByorReports.ReportListContainerID = "byor_accordion" + domSafeID;
    theByorReports.SpinnerContainerID = "byorSpinner" + domSafeID;

    theByorReports.ReportOptionsContainerID = "byorOptions" + domSafeID;
  }

  private get ReportListSettings(): ByorReportListTileSettings {
    let legacyReportTile = this;
    return <ByorReportListTileSettings>legacyReportTile.Settings;
  }

  private RequestReportList = function(): JQueryPromise<any> {
    var theByorReports = this;
    let dfd: JQueryDeferred<any> = $.Deferred();

    var request = new TDFRequest({
      url: theByorReports.ReportListURL,
      type: "Get",
      data: {}
    });
    request.spinnerContainerID = theByorReports.SpinnerContainerID;
    request.MakeRequest().done(function(data) {
      theByorReports.UpdateReportListContainer(data);
      return dfd.resolve();
    });

    return dfd.promise();
  };

  private UpdateReportListContainer(data) {
    var theByorReports = this;
    var tmpDataStore = {};
    var datastore = [];

    data.forEach(function(item) {
      if (
        theByorReports.ReportListSettings.ReportSelectionCriteria ==
          ByorReportSelection.WithAutoBSID &&
        !item.HasAutoBSID
      ) {
        //skipping results based on ReportSelectionCriteria
        return;
      } else if (
        theByorReports.ReportListSettings.ReportSelectionCriteria ==
          ByorReportSelection.WithoutAutoBSID &&
        item.HasAutoBSID
      ) {
        //skipping results based on ReportSelectionCriteria
        return;
      } else {
        var catID = item.CategoryID.trim();
        var catName = item.CategoryName;
        var datastoreID = "cat_" + catID;

        if (!tmpDataStore[datastoreID]) {
          tmpDataStore[datastoreID] = {
            CategoryID: catID,
            CategoryName: catName,
            ReportList: []
          };
        }

        var temp = {
          ReportTitle: item.Title,
          HasAutoDataScope: item.HasAutoBSID
        };
        tmpDataStore[datastoreID].ReportList.push(temp);
      }
    });

    var keys = Object.keys(tmpDataStore);

    for (var i = 0; i < keys.length; i++) {
      datastore.push(tmpDataStore[keys[i]]);
    }

    $("#" + theByorReports.ReportListContainerID)
      .dxAccordion({
        dataSource: datastore,
        collapsible: true,
        selectedIndex: -1,
        multiple: true,
        width: theByorReports.ReportListSettings.AccordianWidth,
        itemTitleTemplate: function(itemData, itemIndex, itemElement) {
          itemElement.append("<strong>" + itemData.CategoryName + "</strong>");
        },
        itemTemplate: function(itemData, itemIndex, itemElement) {
          var showReportFunction = function(caller) {
            let reportTitle = $(caller.target).data("reportTitle");
            let hasAutoDataScope = $(caller.target).data("hasAutoDataScope");
            theByorReports.ShowReport(reportTitle, hasAutoDataScope);
          };

          itemData.ReportList.forEach(function(report, index) {
            let thisItemID =
              theByorReports.ReportListContainerID +
              "_reportItem_" +
              DomSafeID(report.ReportTitle.replace(/\s/g, ""));

            let reportElem = $("<p></p>");
            let reportLink = $(
              "<a href='#' id= '" +
                thisItemID +
                "'  style= 'display: block; padding: 7px 5px; font-weight: bold;' > " +
                report.ReportTitle +
                " </a>"
            );
            reportLink.data({
              reportTitle: report.ReportTitle,
              hasAutoDataScope: report.HasAutoDataScope
            });
            reportLink.on("click", showReportFunction);

            $(itemElement[0]).append(reportElem.append(reportLink));
          });
        }
      })
      .dxAccordion("instance");

    return theByorReports;
  }

  private ShowReport(reportTitle, hasAutoDataScope) {
    var theByorReports = this;
    reportTitle = reportTitle.replace("'", "'");

    if (hasAutoDataScope !== true) {
      if (theByorReports.ReportListSettings.Bsid == "") {
        var selectionPanel = new ReportScopeSelection();
        selectionPanel.ReportTitle = reportTitle;
        selectionPanel.DisplayPanel();
      } else {
        var urlString =
          "/Report/?reportTitle=" +
          encodeURIComponent(reportTitle.replace("#", "%23")) +
          "&_bsid=" +
          theByorReports.ReportListSettings.Bsid +
          "&bsidType=1";
        window.open(urlString);
      }
    } else {
      var urlString =
        "/Report/?reportTitle=" + encodeURIComponent(reportTitle);
      window.open(urlString);
    }
  }
}

//TileFactory.RegisterTileType("ByorReportList", "A tile that can be used to display a ist of legacy BYOR reports.", "fa fa-line-chart", ByorReportListTileSettings, ByorReportList);
