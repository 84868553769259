import { DrillHelper } from "./drillhelper";
import { Dialog } from "../../components/dialogs/dialog";
import { Grid } from "../../legacy-centers-grids/grid";
import { GridSetup } from "../../services/grid/helpers/gridsetup";
import { GridContainerTypes, CenterType } from "../../enums/enums";
import { BSIGrids } from "../../enums/bi/enums";
import { TDFRequest } from "../../services/request";
import { Preferences } from "../../infrastructure/user/preferences";
import { GetPreference } from "../../infrastructure/user/getpreference";
import { BI_BreadcrumbInfo } from "../../components/grids/bi/legacy/legacybi";
import { DomSafeID } from "../../util/allutils";
import { GridToolbar } from "../../components/grids/gridtoolbars/gridtoolbar";
import * as Globalize from "globalize";
import { CurrentUser } from "../../infrastructure/context";
import { BiColumnTemplateHandler, GetBISummarySourceFromCenterType } from "./bi_utils";
import { ToggleToolbarButtonVisibility } from "../../util/allutils";
import devices from "devextreme/core/devices";

export class DrillInDialog_LineItems {
  //SummarySettingsSelectionPanel: BISummarySettingsSelector;
  DrillManager: DrillHelper;
  DrillInFieldName: string = "";
  GeneralFilter: string = "";
  CenterType = -1;
  DrillinForm: Dialog;
  Toolbar: GridToolbar;
  MyGrid: Grid;
  private EventLimiterName: string = "";
  _RequestModel: GridSetup.BIDrillDataRequestModel;
  TDFContainerType: GridContainerTypes = GridContainerTypes.BIDrillIn;
  GridType: BSIGrids;
  //AccountList: string = "";
  //GoalType: number = -1;

  // element IDs
  //CustomIDString: string = "";            // todo:  Customize this.
  MainDivID: string = "";
  HeaderAreaID: string = "";
  //BreadcrumbContainerID: string = "";
  ToolbarContainerID: string = "";
  ContentAreaID: string = "";
  GridContainerID: string = "";
  MyDialogID: string = "";
  //BreadCrumbToolbarID: string = "biBreadCrumbToolbar";
  //BreadCrumbToolbarTypeSelectorID: string = "biBreadCrumbTypeSelector";
  //BreadcrumbListID: string = "biBreadCrumbList";
  //TagCloudID: string = "";

  constructor(manager: DrillHelper, eventLimiterName: string) {
    let theDrillInDialog: DrillInDialog_LineItems = this;
    theDrillInDialog.DrillManager = manager;
    theDrillInDialog.EventLimiterName = eventLimiterName;

    theDrillInDialog.SetIDs();

    if (theDrillInDialog.DrillManager.CenterType === CenterType.ContactBi) {
      theDrillInDialog.GridType = BSIGrids.BSIContactItemSalesHistory;
    } else if (theDrillInDialog.DrillManager.CenterType === CenterType.OppBi) {
      theDrillInDialog.GridType = BSIGrids.BSIJobLineItems;
    } else if (
      theDrillInDialog.DrillManager.CenterType === CenterType.VendorBi
    ) {
      theDrillInDialog.GridType = BSIGrids.VendorSalesLineItems;
    } else {
      theDrillInDialog.GridType = BSIGrids.LineItems;
    }
  }
  SetIDs() {
    let theDrillInDialog: DrillInDialog_LineItems = this;
    // todo:  Further customize these IDs to be very unique.
    let uniquePiece: string =
      String(theDrillInDialog.DrillManager.SummarySettings.ActiveFolderLevel) +
      "_" +
      Math.ceil(Math.random() * 999 + 1).toString();
    theDrillInDialog.MyDialogID = "BI_DrillinLines_Dialog_" + uniquePiece;

    theDrillInDialog.MainDivID = "BI_DrillinLines_MainDiv_" + uniquePiece;
    theDrillInDialog.HeaderAreaID = "BI_DrillinLines_HeaderArea_" + uniquePiece;
    theDrillInDialog.ToolbarContainerID =
      "BI_DrillinLines_ToolbarContainer_" + uniquePiece;
    theDrillInDialog.ContentAreaID =
      "BI_DrillinLines_ContentArea_" + uniquePiece;
    theDrillInDialog.GridContainerID =
      "BI_DrillinLines_GridContainer_" + uniquePiece;

    //theDrillInDialog.BreadcrumbContainerID = "BI_DrillinLines_BreadcrumbContainer_" + uniquePiece;

    //theDrillInDialog.BreadCrumbToolbarID = "biBreadCrumbToolbar_" + uniquePiece;
    //theDrillInDialog.BreadCrumbToolbarTypeSelectorID = "biBreadCrumbTypeSelector_" + uniquePiece;
    //theDrillInDialog.BreadcrumbListID = "biBreadCrumbList_" + uniquePiece;
    //theDrillInDialog.TagCloudID = "bidrillinCloud_" + uniquePiece;
  }

  GetTitle(fieldName: string, amount: string, drillInCrumb?: BI_BreadcrumbInfo): string {
    let theDrillInDialog: DrillInDialog_LineItems = this;
    let titleString = "";
    let typeDesc: string = "";
    let fieldDesc: string = fieldName;

    switch (fieldName.toLowerCase()) {
      case "sales_2y_total":
        typeDesc = "Sales";
        fieldDesc = "2Y Total";
        break;
      case "sales_ly_total":
        typeDesc = "Sales";
        fieldDesc = "LY Total";
        break;
      case "sales_lytd":
        typeDesc = "Sales";
        fieldDesc = "LYTD";
        break;
      case "sales_ytd":
        typeDesc = "Sales";
        fieldDesc = "YTD";
        break;
      case "2yqtd":
        typeDesc = "Sales";
        fieldDesc = "2YQTD";
        break;
      case "lastquarter":
        typeDesc = "Sales";
        fieldDesc = "Last Quarter";
        break;
      case "lyqtd":
        typeDesc = "Sales";
        fieldDesc = "LYQTD";
        break;
      case "qtd":
        typeDesc = "Sales";
        fieldDesc = "QTD";
        break;
      case "sales_2ymtd":
        typeDesc = "Sales";
        fieldDesc = "2YMTD";
        break;
      case "lastmonth":
        typeDesc = "Sales";
        fieldDesc = "Last Month";
        break;
      case "sales_lymtd":
        typeDesc = "Sales";
        fieldDesc = "LYMTD";
        break;
      case "sales_mtd":
        typeDesc = "Sales";
        fieldDesc = "MTD";
        break;
      case "sales_day":
        typeDesc = "Sales";
        fieldDesc = "Value";
        break;
      case "profit_2y_total":
        typeDesc = "Profit";
        fieldDesc = "2Y";
        break;
      case "profit_ly_total":
        typeDesc = "Profit";
        fieldDesc = "LY Total";
        break;
      case "profit_lytd":
        typeDesc = "Profit";
        fieldDesc = "LYTD";
        break;
      case "profit_ytd":
        typeDesc = "Profit";
        fieldDesc = "YTD";
        break;
      case "2yqtd":
        typeDesc = "Profit";
        fieldDesc = "2YQTD";
        break;
      case "lastquarter":
        typeDesc = "Profit";
        fieldDesc = "Last Quarter";
        break;
      case "lyqtd":
        typeDesc = "Profit";
        fieldDesc = "LYQTD";
        break;
      case "qtd":
        typeDesc = "Profit";
        fieldDesc = "QTD";
        break;
      case "profit_2ymtd":
        typeDesc = "Profit";
        fieldDesc = "2YMTD";
        break;
      case "lastmonth":
        typeDesc = "Profit";
        fieldDesc = "Last Month";
        break;
      case "profit_lymtd":
        typeDesc = "Profit";
        fieldDesc = "LYMTD";
        break;
      case "profit_mtd":
        typeDesc = "Profit";
        fieldDesc = "MTD";
        break;
      case "profit_day":
        typeDesc = "Profit";
        fieldDesc = "Value";
        break;
    }

    let crumbText: string = "";
    if (drillInCrumb) {
      crumbText = drillInCrumb.Caption;
    }

    titleString = `Drilling into ${crumbText} ${typeDesc} ${fieldDesc} of ${Globalize.formatCurrency(
      parseFloat(amount),
      CurrentUser.Currency,
      {
        style: "accounting"
      }
    )}`;
    return titleString;
  }

  TheDrillinCrumb: BI_BreadcrumbInfo;
  DisplayDrillin(fieldName: string, drillinValue: string, drillInCrumb?: BI_BreadcrumbInfo): JQueryPromise<DrillInDialog_LineItems> {
    let theDrillInDialog: DrillInDialog_LineItems = this;
    theDrillInDialog.DrillInFieldName = fieldName;
    if (drillInCrumb) {
      if (!drillInCrumb.UseAsFilter) {
        theDrillInDialog.TheDrillinCrumb = drillInCrumb;
      } else {
        theDrillInDialog.GeneralFilter = `[${drillInCrumb.Value}] = '${
          drillInCrumb.Caption
          }'`;
        if (!drillInCrumb.Caption) {
          drillInCrumb.Caption = theDrillInDialog.GeneralFilter;
        }
      }
    }

    let displayDrillinDFD: JQueryDeferred<DrillInDialog_LineItems> = $.Deferred();

    let dialogMessage = $("<div />")
      .attr("id", theDrillInDialog.MainDivID)
      .append(
        $("<div />")
          .attr("id", theDrillInDialog.HeaderAreaID)
          .append($("<div />").attr("id", theDrillInDialog.ToolbarContainerID))
      )
      .append(
        $("<div />")
          .attr("id", theDrillInDialog.ContentAreaID)
          .css("padding", "3px")
          .append($("<div />").attr("id", theDrillInDialog.GridContainerID))
      );

    theDrillInDialog.DrillinForm = new Dialog({
      size: "size-wide",
      id: theDrillInDialog.MyDialogID,
      title: theDrillInDialog.GetTitle(fieldName, drillinValue, drillInCrumb), //" "Drill-In Summary Details",      // todo:  Or Drill-Down???
      closable: true,
      body: dialogMessage,
      type: "type-primary"
    });

    theDrillInDialog.DrillinForm.open().done(function () {
      //theDrillInDialog.DrilldownModel().done(function () {
      //theDrillInDialog.GetInitialBreadcrumbInfo().done(function (initialCrumb: BI_BreadcrumbInfo) {
      //    theDrillInDialog.InitializeBreadcrumbs(initialCrumb);
      theDrillInDialog.ToolbarModel().done(function (toolbarResponse) {
        theDrillInDialog.GetRequest().done(function () {
          theDrillInDialog.Toolbar.GridDataRequestModel = theDrillInDialog._RequestModel;
          theDrillInDialog.Toolbar.RenderAndLoad();
          displayDrillinDFD.resolve();
        });
      });
      //});
      //});
      return displayDrillinDFD.promise(theDrillInDialog);
    });

    return displayDrillinDFD.promise(theDrillInDialog);
  }

  ToolbarModel(): JQueryPromise<DrillInDialog_LineItems> {
    let theDrillInDialog: DrillInDialog_LineItems = this;
    let toolbarDFD: JQueryDeferred<DrillInDialog_LineItems> = $.Deferred();

    let request = new TDFRequest({
      url: "/core/user/GetToolbarInfo/",
      type: "GET",
      data: {
        type: theDrillInDialog.GridType,
        containertype: theDrillInDialog.TDFContainerType
      }
    });

    request.MakeRequest().done(function (response: any) {
      response.GridDataRequestType = "POST";
      theDrillInDialog.Toolbar = new GridToolbar(response);
      theDrillInDialog.Toolbar.ToolbarContainerID = theDrillInDialog.ToolbarContainerID;
      theDrillInDialog.Toolbar.MakeGrid = theDrillInDialog.MakeDrillInGrid.bind(theDrillInDialog);
      if (!theDrillInDialog.Toolbar.CurrentView) {
        theDrillInDialog.Toolbar.CurrentView = theDrillInDialog.Toolbar.DefaultView;
      }

      toolbarDFD.resolve(theDrillInDialog);
    });

    return toolbarDFD.promise();
  }

  RefreshCurrentGrid() {
    let theDrillInDialog: DrillInDialog_LineItems = this;
    theDrillInDialog.Toolbar.RefreshCurrentGrid(theDrillInDialog);
  }

  GridOptions() {
    let theDrillInDialog: DrillInDialog_LineItems = this;
    let overriddenGridOptions: DevExpress.ui.dxDataGridOptions = {
      selection: {
        mode: "single",
        showCheckBoxesMode: "none"
      },
      stateStoring: {},
      onRowClick: function (e) {
        // overriding this method that otherwise is defaulted.
      },
      onSelectionChanged: function (e) {
        // overriding this method that otherwise is defaulted.
      },
      height: theDrillInDialog.DrillinForm.getModalBody().height() - 75
    };

    overriddenGridOptions.onContentReady = function (e) {
      let id = DomSafeID(theDrillInDialog.GridContainerID);

      $(window).on("orientationchange", function () {
        if (devices.orientation() === "landscape") {
          $("#" + id).css("width", "100%");
          $("#recentitemcontainer").css("width", "100%");
        }
        // put this outside if, because some reason orientaion change on quote manager doesnt read landscape?
        $("#quotemanagergrid").css("width", "100%");
      });

      if (theDrillInDialog._RequestModel) {
        let btnNextPage = e.element.find(".dx-next-button");
        let btnPrevPage = e.element.find(".dx-prev-button");
        let pageSizes = e.element.find(".dx-page-sizes");
        let grid = e.component as DevExpress.ui.dxDataGrid;

        $(btnNextPage).off("click");
        $(btnNextPage).on("click", function (event) {
          if ($(event.currentTarget).hasClass("dx-button-disable")) return;
          if (grid.pageIndex() === grid.pageCount() - 1) {
            event.stopPropagation();
            theDrillInDialog._RequestModel.RequestArgs.page += 1;
            grid.pageIndex(0);
            theDrillInDialog.RefreshCurrentGrid();
          }
        });

        $(btnPrevPage).off("click");
        $(btnPrevPage).on("click", function (event) {
          if ($(event.currentTarget).hasClass("dx-button-disable")) return;
          if (grid.pageIndex() === 0) {
            if (theDrillInDialog._RequestModel.RequestArgs.page > 1) {
              theDrillInDialog._RequestModel.RequestArgs.page -= 1;
              let newIndex =
                Math.ceil(
                  theDrillInDialog._RequestModel.RequestArgs.pageSize /
                  grid.pageSize()
                ) - 1;
              grid.pageIndex(newIndex);
              theDrillInDialog.RefreshCurrentGrid();
            }
          }
        });

        $(pageSizes).on("dxclick", function (e) {
          Preferences.SetPreference(
            "ClientGridPageSize",
            $(e.target).text(),
            "TDFMobile"
          );
        });

        if ($(btnNextPage).hasClass("dx-button-disable")) {
          if (
            theDrillInDialog._RequestModel.RequestArgs.page <
            theDrillInDialog.MyGrid.GridDataResponse.Meta.TotalPages
          ) {
            btnNextPage.removeClass("dx-button-disable");
          }
        }

        if ($(btnPrevPage).hasClass("dx-button-disable")) {
          if (theDrillInDialog._RequestModel.RequestArgs.page > 1) {
            btnPrevPage.removeClass("dx-button-disable");
          }
        }

        $(e.element)
          .find(".dx-header-row .dx-datagrid-action")
          .off("dxclick")
          .on("dxclick", function (event) {
            if (Preferences.GetPreference("SortOnServer", "WebGrid")) {
              event.stopPropagation();
              let field;
              let cname = $(this)
                .find(".dx-datagrid-text-content")
                .text();

              let sorts = theDrillInDialog._RequestModel.RequestArgs.Sorts;

              $.each(grid.option("columns"), function (k, v) {
                if (v.caption) {
                  if (cname === v.caption) {
                    field = v.dataField.trim();
                  }
                } else {
                  if (cname === v.dataField) {
                    field = v.dataField.trim();
                  }
                }
              });

              if (sorts && sorts.length) {
                let found = false;

                sorts.forEach((val, idx) => {
                  if (val.Field === field) {
                    found = true;
                    switch (val.Direction) {
                      case "asc":
                        val.Direction = "desc";
                        break;
                      case "desc":
                        val.Direction = "asc";
                        break;
                    }
                  }
                });

                if (!found) {
                  sorts = [{ Field: field, Direction: "asc", Order: 0 }];
                }
              } else {
                sorts = [{ Field: field, Direction: "asc", Order: 0 }];
              }

              theDrillInDialog._RequestModel.RequestArgs.Sorts = sorts;
              theDrillInDialog.RefreshCurrentGrid();
            }
          });
      }
    };

    return overriddenGridOptions;
  }

  MakeDrillInGrid(data) {
    let theDrillInDialog: DrillInDialog_LineItems = this;
    theDrillInDialog.MyGrid = new Grid(
      data,
      {
        GridContainer: $("#" + theDrillInDialog.GridContainerID),
        GridToolbar: theDrillInDialog.Toolbar
      },
      theDrillInDialog.GridOptions(),
      theDrillInDialog,
      () => { },
      theDrillInDialog.BiColumnTemplate.bind(theDrillInDialog)
    );
    theDrillInDialog.MyGrid.Render();
    theDrillInDialog.Toolbar.CurrentGrid = theDrillInDialog.MyGrid;
  }

  BiColumnTemplate(col: any) {
    let theDrillInDialog: DrillInDialog_LineItems = this;
    let centerType = theDrillInDialog.DrillManager.CenterType;
    BiColumnTemplateHandler(col, centerType, theDrillInDialog.EventLimiterName);
  }

  GetRequest(viewGUID?: string) {
    let theDrillInDialog: DrillInDialog_LineItems = this;
    let getRequestDFD = $.Deferred();

    //let currentDrilldownType = $("#" + theDrillInDialog.BreadCrumbToolbarTypeSelectorID).dxSelectBox("instance").option("value");

    let requestArgs = new GridSetup.BIDrillRequestArgs();
    requestArgs.ItemType = theDrillInDialog.GridType;
    requestArgs.GUID = viewGUID || theDrillInDialog.Toolbar.DefaultView;
    requestArgs.ContainerID = theDrillInDialog.DrillManager.SummarySettings.CurrentlySelectedItemIDsAsString();
    requestArgs.BISummaryType =
      theDrillInDialog.DrillManager.SummarySettings.ActiveSelectionLevel;
    //TODO:  Do I need this?
    requestArgs.OptionalGroupSummaryOptions = {
      SummarySource: GetBISummarySourceFromCenterType(
        theDrillInDialog.DrillManager.CenterType
      )
    };
    requestArgs.GoalType = theDrillInDialog.DrillManager.GoalType;
    requestArgs.filterExpression = theDrillInDialog.GeneralFilter;

    requestArgs.DataDrillSettings = {
      SummaryType:
        theDrillInDialog.DrillManager.SummarySettings.ActiveSelectionLevel,
      LevelColumns: [], // ex:  ["Level4ID", "AccountID", "PRClassID", "VendorID", "Alternate1"],
      LevelValues: [], // ex:  ["{1103FA20-16B2-4CE2-9C28-994A795DAE2B}", "{6DFAA46D-D6C0-4A1E-8B03-679A092DBBC0}", "HN000050", "20428", "%"],
      LevelGridTypes: [], // ex:  [8, 4, 1, 0, 2],
      AccountIDs:
        theDrillInDialog.DrillManager.SummarySettings.ActiveSelectionLevel === 3
          ? theDrillInDialog.DrillManager.AccountList
          : "",
      SalesmanID: "",
      SalesmanSummary: false, // Note:  Desktop doesn't seem to change this anywhere.  I think there's some salesman logic that isn't being used and/or tested by desktop and isn't needed.  Bill is looking into it, and I'm just going to set this to false all the time.
      /*TODO: Again  with the center*/

      IsVendorSummary:
        theDrillInDialog.DrillManager.CenterType === CenterType.VendorBi,
      DrillInField: theDrillInDialog.DrillInFieldName
    };

    // loop through the breadcrumb history to add to the request args.
    $.each(theDrillInDialog.DrillManager.BreadcrumbHistory, function (
      index: number,
      crumb: BI_BreadcrumbInfo
    ) {
      // Skip the first item for Drill INs.
      if (index != 0) {
        // The Salesman column is named differently in drillins than in drill downs.  So, this is a little hack to adjust it here.
        requestArgs.DataDrillSettings.LevelColumns.push(
          crumb.DrilldownItem.LevelColumn === "SalesmanID"
            ? "SalesPersonID"
            : crumb.DrilldownItem.LevelColumn
        );
        requestArgs.DataDrillSettings.LevelValues.push(crumb.Value);
        requestArgs.DataDrillSettings.LevelGridTypes.push(
          crumb.DrilldownItem.ChildGridType
        );
      }
    });

    // push the final drill-in option.
    if (theDrillInDialog.TheDrillinCrumb) {
      // The Salesman column is named differently in drillins than in drill downs.  So, this is a little hack to adjust it here.
      requestArgs.DataDrillSettings.LevelColumns.push(
        theDrillInDialog.TheDrillinCrumb.DrilldownItem.LevelColumn ===
          "SalesmanID"
          ? "SalesPersonID"
          : theDrillInDialog.TheDrillinCrumb.DrilldownItem.LevelColumn
      );
      requestArgs.DataDrillSettings.LevelValues.push(
        theDrillInDialog.TheDrillinCrumb.Value
      );
      requestArgs.DataDrillSettings.LevelGridTypes.push(
        theDrillInDialog.TheDrillinCrumb.DrilldownItem.ChildGridType
      );
    }

    theDrillInDialog._RequestModel = new GridSetup.BIDrillDataRequestModel(
      requestArgs
    );
    //TODO:  Apply filter and sorts
    //if (theBICenter._RequestModel && theBICenter._RequestModel.RequestArgs.filterExpression && requestModel.RequestArgs.ViewID === theBICenter._RequestModel.RequestArgs.ViewID) {
    //    requestModel.RequestArgs.filterExpression = theBICenter._RequestModel.RequestArgs.filterExpression;
    //}
    //if (theBICenter._RequestModel && theBICenter._RequestModel.RequestArgs.sortExpression && requestModel.RequestArgs.ViewID === theBICenter._RequestModel.RequestArgs.ViewID) {
    //    requestModel.RequestArgs.sortExpression = theBICenter._RequestModel.RequestArgs.sortExpression;
    //    requestModel.RequestArgs.sortDirection = theBICenter._RequestModel.RequestArgs.sortDirection;
    //}

    return getRequestDFD.promise(getRequestDFD.resolve(theDrillInDialog));
  }
}
