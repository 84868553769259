import { CustomAcctPkgSelector } from '../controls/customacctpkgselector';
import { PackageSelection } from '../controls/packageselection';
import { Dialog } from '../dialogs/dialog';
import { Notification } from '../dialogs/notification';
import { itemTypes } from '../../enums/enums';
import { TDFRequest } from '../../services/request';
import { ItemBase } from './itembase';
import { DisplayInfo } from '../../infrastructure/context';
import { Search } from '../../legacy-centers-grids/search';
import { Preferences } from '../../infrastructure/user/preferences';
import "bootstrap/js/src/collapse"
import { IControlData } from 'interfaces/interfaces';
export class Lead extends ItemBase {
  DisplayName: string;
  NameParts: any;
  get TypeName() {
    return 'Lead';
  }
  get ItemType() {
    return itemTypes.itemLead;
  }
  set TypeName(name) {
    if (name && this.TypeName !== name) this.TypeName = name;
  }
  get RequiresParentItem(): boolean {
    return false;
  }
  linkId: string = '';
  AcctPkgID: string = '';
  closeReason: Array<string> = [];
  CloseControlID: string;
  constructor(args) {
    super(args);
    let theLead = this;
    $.each(args, function (key, val) {
      if (theLead.hasOwnProperty(key)) theLead[key] = val;
    });
    if (args.ItemId && !theLead.ItemID) {
      theLead.ItemID = args.ItemId;
    }
    if (!theLead.DisplayName) {
      DisplayInfo(theLead.ItemType).done(function (displayinfo) {
        theLead.DisplayName = displayinfo.DisplayName;
        theLead.TypeName = displayinfo.TypeName;
      });
    }
  }

  Save() {
    let theLead = this;
    let d = $.Deferred();
    super.Save().done(function () {
      if (theLead.NameParts) {
        let request = new TDFRequest({
          url: '/item/SaveFullName/',
          type: 'POST',
          data: {
            nameparts: theLead.NameParts,
            itemid: theLead.ItemID,
            itemtype: itemTypes.itemLead
          }
        });
        request.MakeRequest().done(function (response) {
          d.resolve(theLead);
        });
      } else {
        d.resolve(theLead);
      }
    });

    return d.promise(theLead);
  }

  OnCreate() {
    let theLead = this;
    let d = $.Deferred();

    super.OnCreate().done(() => {
      let timer;
      timer = setInterval(function () {
        let nameEle = $('#btn_Name_' + theLead.DomSafeID);
        if (nameEle.length > 0) {
          clearInterval(timer);
          let nameCtrl;
          for (const controlGroup in theLead.Controls) {
            if (theLead.Controls.hasOwnProperty(controlGroup)) {
              const currentCG = theLead.Controls[controlGroup];

              let nameCtrls = (currentCG as any).filter((a: any) => {
                const NameCtrlType = 25;
                return (a as IControlData).Properties.Control_Type === NameCtrlType;
              });

              if (nameCtrls.length > 0) {
                nameCtrl = nameCtrls[0];
              }
            }
          }
          if (!nameCtrl.DataValue.ValueName) {
            let b: DevExpress.ui.dxButton = nameEle.dxButton('instance');
            b.option('onClick')();
          }
        }
      }, 100);

      d.resolve();
    });

    return d.promise();
  }

  QualifyForm() {
    let theLead = this;

    let html = $("<div style='margin:0 10px'></div>");
    let Company = '';
    let Subject = '';
    theLead.SectionHeader(Company, Subject, html);
    let inputClickAction = function () {
      //theLead.openSearch($(this).data("search"))
      let type = $(this).data('search');

      let request = new TDFRequest({
        url: '/item/ValidParents/',
        data: { itemtype: itemTypes.itemLead }
      });
      request.MakeRequest().done(function (response: any) {
        let req = new TDFRequest({
          url: '/home/GetSearchSetup/?' + $.param({ type: response[0] }),
          type: 'GET'
        });

        req.MakeRequest().done(function (data) {
          let dialog = new Dialog({
            id: 'leadparentsearch',
            body: $("<div id='tdfsearch4'/>").append(
              $("<div id='tdfcontextmenu'/>")
            ),
            type: 'type-info',
            closable: true,
            title: 'Search'
          });
          dialog.open().done(function () {
            //let types = $.grep(data.Data.SearchTypes, function (val: any) {
            //    return $.inArray(val.Type, response) > -1
            //});
            //data.Data.SearchTypes = types;
            data.Data.DisableType = true;
            data.Data.SelectedType = parseInt(type);
            data.Data.SearchContainer = 'tdfsearch4';
            let search = new Search(data.Data);
            search.SelectionEvent = function (data) {
              dialog.close();
              theLead.linkId = data.currentSelectedRowKeys[0]['TDF GUID'];
              let parentid = data.currentSelectedRowKeys[0]['TDF GUID'];
            };
          });
        });
      });
    };

    let ul = $('<ul id="qualifyRecordType" class="list-group">');
    let li1 = $('<li class="list-group-item">');
    let labelAccount = $('<label>');
    let inputAccount = $(
      '<input type="radio" name="qualify-type" value="account">'
    );
    let divAccount = $('<div class="collapse" id="collapseAccount">');

    let pAccount = $(
      '<p style="margin:10px 0 0 20px">Qualify &amp; Convert to Account:</p>'
    );
    let d1 = $('<div style="margin-left:20px"></div>');
    let d1label = $('<label>Create a new Account</label>');
    let d1input = $(
      '<input type="radio" name="account-action" data-level="1" value="new" >'
    ).on('change', theLead.GetPackageID());
    let d1p = $(
      '<p style="margin-left:34px">Account-related values on this Lead will be copied to the new Account. The new Account will be linked back to this Lead.</p>'
    );

    let d2 = $('<div style="margin-left:20px"></div>');
    let d2label = $('<label></label>');
    let d2input = $(
      '<input type="radio" data-search="1" name="account-action" data-level="1" value="existing">'
    );
    d2input.on('click', inputClickAction);
    let d2text = ' Link an existing Account';

    let li2 = $('<li class="list-group-item">');
    let labelContact = $('<label>');
    let inputContact = $(
      '<input type="radio" name="qualify-type" value="contact">'
    );
    let divContact = $('<div class="collapse" id="collapseContact">');
    let pContact = $(
      '<p style="margin:10px 0 0 20px">Qualify &amp; Convert to Contact:</p>'
    );

    let d3 = $('<div style="margin-left:20px">');
    let d3label = $('<label>');
    let d3input = $(
      '<input type="radio" id="contact-new" name="contact-action" data-level="1" value="new" >'
    );
    let d3text = ' Create a new Contact';
    let d3p = $(
      '<p style="margin-left:20px">Contact-related values on this Lead will be copied to the new Contact. The new Contact will be linked back to this Lead.</p>'
    );

    let subInputContact1 = $('<div style="margin-left:40px"></div>');

    let subInputContact1Label = $('<label></label>');
    let subInputContact1Input = $(
      '<input type="radio" name="contact-action-sub" data-level="2" value="autocreate" >'
    ).on('click', theLead.GetPackageID());
    let subInputContact1Text = ' Auto-create parent Account from this Lead.';

    let subInputContact2 = $('<div style="margin-left:40px"></div>');
    let subInputContact2Label = $('<label></label>');
    let subInputContact2Input = $(
      '<input type="radio" name="contact-action-sub" data-search="1" data-level="2" value="selectexisting">'
    );
    subInputContact2Input.on('click', inputClickAction);
    let subInputContact2Text = ' Let me select an existing Account.';

    let d4 = $('<div style="margin-left:20px">');
    let d4label = $('<label>');
    let d4input = $(
      '<input type="radio" id="contact-existing" name="contact-action" data-search="5"  data-level="1" value="existing">'
    );
    d4input.on('click', inputClickAction);
    let d4text = ' Link an existing Contact';

    let li3 = $('<li class="list-group-item">');
    let labelOpportunity = $('<label>');
    let inputOpportunity = $(
      '<input type="radio" name="qualify-type" value="opportunity">'
    );
    let divOpportunity = $('<div class="collapse" id="collapseOpportunity">');
    let pOpportunity = $(
      '<p style="margin:10px 0 0 20px">Qualify &amp; Convert to Opportunity:</p>'
    );
    let d5 = $(
      '<div style="margin-left:20px"><label><input type="radio" id="opportunity-existing" name="opportunity-action" data-level="1" value="existing" > Create Opportunity under existing item:</label></div>'
    );

    let subInputOpportunity1 = $('<div style="margin-left:40px"></div>');
    let subInputOpportunity1Label = $('<label></label>');
    let subInputOpportunity1Input = $(
      '<input type="radio" name="opportunity-action-sub" data-search="1" data-level="2" value="existingaccount" >'
    );
    subInputOpportunity1Input.on('click', inputClickAction);
    let subInputOpportunity1Text = ' Existing Account';

    let subInputOpportunity2 = $('<div style="margin-left:40px"></div>');
    let subInputOpportunity2Label = $('<label></label>');
    let subInputOpportunity2Input = $(
      '<input type="radio" name="opportunity-action-sub" data-search="5" data-level="2" value="existingcontact">'
    );
    subInputOpportunity2Input.on('click', inputClickAction);
    let subInputOpportunity2Text = ' Existing Contact';

    let d6 = $(
      '<div style="margin-left:20px"><label><input type="radio" id="opportunity-new" name="opportunity-action" data-level="1" value="new"> Create Opportunity under new item:</label></div>'
    );

    let subInputOpportunity3 = $('<div style="margin-left:40px"></div>');
    let subInputOpportunity3Label = $('<label> </label>');
    let subInputOpportunity3Input = $(
      '<input type="radio" name="opportunity-action-sub2" data-level="2" value="newaccount"  disabled>'
    ).on('click', theLead.GetPackageID());
    let subInputOpportunity3Text = 'New Account';

    let subInputOpportunity4 = $('<div style="margin-left:40px"></div>');
    let subInputOpportunity4Label = $('<label> </label>');
    let subInputOpportunity4Input = $(
      '<input type="radio" name="opportunity-action-sub2" data-level="2" value="newaccountcontact" disabled>'
    ).on('click', theLead.GetPackageID());
    let subInputOpportunity4Text = 'New Account &#43; Contact';

    //let subInputOpportunity4 = $('<div style="margin-left:40px"><label><input type="radio" name="opportunity-action-sub2" data-level="2" value="newaccountcontact" disabled> New Account &#43; Contact</label></div>');

    let subInputOpportunity5 = $('<div style="margin-left:40px"></div>');
    let subInputOpportunity5Label = $('<label></label>');
    let subInputOpportunity5Input = $(
      '<input type="radio" name="opportunity-action-sub2" data-search="1" data-level="2" value="existingaccountnewcontact" disabled>'
    );
    subInputOpportunity5Input.on('click', inputClickAction);
    let subInputOpportunity5Text = ' New Contact at Existing Account';

    let mergeCheckboxes = $('<div style="margin-top:2rem"></div>');
    let merge1 = $(
      '<label><input type="checkbox" id="MoveChildren" data-level="4" value="1"> Move this Lead\'s child items to the target item.</label>'
    );
    let merge2 = $(
      '<label><input type="checkbox" id="MergeData" data-level="4" value="1"> Copy user-defined data from the Lead to the new item.</label></div>'
    );
    mergeCheckboxes.append(merge1).append(merge2);

    // Build Account section
    divAccount.append(pAccount);
    d1.append(d1label);

    d1label.prepend(d1input);
    divAccount.append(d1);
    divAccount.append(d1p);
    d2label.append(d2input).append(d2text);
    d2.append(d2label);
    divAccount.append(d2);
    labelAccount.append(inputAccount);
    labelAccount.append(' Account');
    li1.append(labelAccount);
    li1.append(divAccount);

    // Build Contact section
    divContact.append(pContact);
    d3label.append(d3input).append(d3text);
    d3.append(d3label);
    d3.append(d3p);

    subInputContact1Label
      .append(subInputContact1Input)
      .append(subInputContact1Text);
    subInputContact1.append(subInputContact1Label);

    subInputContact2Label
      .append(subInputContact2Input)
      .append(subInputContact2Text);
    subInputContact2.append(subInputContact2Label);

    d3.append(subInputContact1).append(subInputContact2);
    divContact.append(d3);

    d4label.append(d4input).append(d4text);
    d4.append(d4label);
    divContact.append(d4);
    labelContact.append(inputContact);
    labelContact.append(' Contact');
    li2.append(labelContact);
    li2.append(divContact);

    // Build Opportunity section
    divOpportunity.append(pOpportunity);
    divOpportunity.append(d5);
    divOpportunity.append(d6);

    subInputOpportunity1Label
      .append(subInputOpportunity1Input)
      .append(subInputOpportunity1Text);
    subInputOpportunity1.append(subInputOpportunity1Label);

    subInputOpportunity2Label
      .append(subInputOpportunity2Input)
      .append(subInputOpportunity2Text);
    subInputOpportunity2.append(subInputOpportunity2Label);

    subInputOpportunity3Label
      .append(subInputOpportunity3Input)
      .append(subInputOpportunity3Text);
    subInputOpportunity3.append(subInputOpportunity3Label);

    subInputOpportunity4Label
      .append(subInputOpportunity4Input)
      .append(subInputOpportunity4Text);
    subInputOpportunity4.append(subInputOpportunity4Label);

    subInputOpportunity5Label
      .append(subInputOpportunity5Input)
      .append(subInputOpportunity5Text);
    subInputOpportunity5.append(subInputOpportunity5Label);

    d5.append(subInputOpportunity1).append(subInputOpportunity2);
    d6.append(subInputOpportunity3)
      .append(subInputOpportunity4)
      .append(subInputOpportunity5);
    labelOpportunity.append(inputOpportunity);
    labelOpportunity.append(' Opportunity');
    li3.append(labelOpportunity);
    li3.append(divOpportunity);

    ul.append(li1)
      .append(li2)
      .append(li3);

    html.append(ul).append(mergeCheckboxes);

    // theLead.form.setMessage(html);

    inputAccount.on('click', function (e) {
      $('#collapseContact').collapse('hide');
      $('#collapseOpportunity').collapse('hide');
      $('#collapseAccount').collapse('show');
    });
    inputContact.on('click', function (e) {
      $('#collapseOpportunity').collapse('hide');
      $('#collapseAccount').collapse('hide');
      $('#collapseContact').collapse('show');
    });
    inputOpportunity.on('click', function (e) {
      $('#collapseAccount').collapse('hide');
      $('#collapseContact').collapse('hide');
      $('#collapseOpportunity').collapse('show');
    });
    d4input.on('click', function () {
      let bool = $(this).prop('checked');
      $('[name=contact-action-sub]').prop('disabled', bool);
    });
    d3input.on('click', function () {
      let bool = $(this).prop('checked');
      $('[name=contact-action-sub]').prop('disabled', !bool);
    });

    d5.on('click', function () {
      let bool = $(this)
        .find('input')
        .prop('checked');
      $('[name=opportunity-action-sub]').prop('disabled', !bool);
      $('[name=opportunity-action-sub2]').prop('disabled', bool);
    });
    d6.on('click', function () {
      let bool = $(this)
        .find('input')
        .prop('checked');
      $('[name=opportunity-action-sub]').prop('disabled', bool);
      $('[name=opportunity-action-sub2]').prop('disabled', !bool);
    });

    return html;
  }

  private GetPackageID() {
    let theLead = this;
    let pref = Preferences.GetCompanyPreference(
      'CustomAcctPkgLookup',
      'TDFItem'
    );
    if (!pref || pref === '0') {
      return theLead.LeadAcctPkgLookup();
    } else {
      return function () {
        let customSelector = new CustomAcctPkgSelector(
          theLead,
          theLead.LeadPackageSelectionCommit.bind(theLead),
          theLead.LeadAcctPkgLookup.bind(theLead)
        );
        customSelector.DisplayPanel();
      };
    }
  }

  private LeadAcctPkgLookup() {
    let theLead = this;
    return function () {
      let selector = new PackageSelection();
      selector.DisplayPanel();
      selector.OnCommit = function (response) {
        let folder = response['level' + selector.ActiveFolderLevel];
        let str = Object.keys(folder)[0];
        folder = folder[str];
        theLead.LeadPackageSelectionCommit(folder.folderid);
      };
    };
  }

  private LeadPackageSelectionCommit(selectedAcctPkg: string) {
    let theLead = this;
    theLead.AcctPkgID = selectedAcctPkg;
  }

  protected SectionHeader(Company: string, Subject: string, html: JQuery) {
    let theLead = this;
    if (theLead.Controls && theLead.Controls && theLead.Controls.length) {
      //TODO:this does not work.... looping through incorrect property
      // $.each(theLead.Controls,  (k, v: any)=> {
      //     //TODO: need to validate this
      //     if (v.Properties.TDFField === "CompanyName") {
      //         Company = v.DataValue.ValueName
      //     }
      //     if (v.Properties.TDFField === "Subject") {
      //         Subject = v.DataValue.ValueName
      //     }
      // });

      let chunk =
        '<div>Lead Company Name: <strong>' +
        Company +
        '</strong></div> \
                   <div>Lead Contact Name: <strong>' +
        Subject +
        '</strong></div>'; // \
      //<h4>Qualify this lead as...</h4>';

      html.append(chunk);
    }
    //else {
    //          html.append('<h4>Qualify this lead as...</h4>')
    //          // Do i need to go get these values or can i just skip them
    //      }
  }

  DeriveQualifyEnum() {
    let theLead = this;
    var qualifyRecordInput = $('[name=qualify-type]:checked');
    var qualifyRecordType = qualifyRecordInput.val();
    var qualifyRoot = qualifyRecordInput.closest('li.list-group-item');
    var subSelectionRoot = qualifyRoot
      .find('input[data-level=1]:checked')
      .eq(0)
      .closest('div');
    var subSelection = qualifyRoot
      .find('input[data-level=1]:checked')
      .eq(0)
      .val();
    var subSubSelection = subSelectionRoot
      .find('input[data-level=2]:checked')
      .eq(0)
      .val();
    var enumToPassToCoreMethod = 'None';

    switch (qualifyRecordType) {
      case 'account':
        if (subSelection === 'new') {
          enumToPassToCoreMethod = 'CreateAccount';
        } else if (subSelection === 'existing') {
          enumToPassToCoreMethod = 'LinkAccount';
        }
        break;
      case 'contact':
        if (subSelection === 'new') {
          if (subSubSelection === 'autocreate') {
            enumToPassToCoreMethod = 'CreateAccountAndContact';
          } else if (subSubSelection === 'selectexisting') {
            enumToPassToCoreMethod = 'LinkAccountAndCreateContact';
          }
        } else if (subSelection === 'existing') {
          enumToPassToCoreMethod = 'LinkContact';
        }
        break;
      case 'opportunity':
        if (subSelection === 'existing') {
          if (subSubSelection === 'existingaccount') {
            enumToPassToCoreMethod = 'LinkAccountAndCreateOpportunity';
          } else if (subSubSelection === 'existingcontact') {
            enumToPassToCoreMethod = 'LinkContactAndCreateOpportunity';
          }
        } else if (subSelection === 'new') {
          if (subSubSelection === 'newaccount') {
            enumToPassToCoreMethod = 'CreateAccountAndOpportunity';
          } else if (subSubSelection === 'newaccountcontact') {
            enumToPassToCoreMethod = 'CreateAccountAndContactWithOpportunity';
          } else if (subSubSelection === 'existingaccountnewcontact') {
            enumToPassToCoreMethod =
              'LinkAccountAndCreateContactAndCreateOpportunity';
          }
        }
        break;
    }
    return enumToPassToCoreMethod;
  }

  QualifyButtons(dialog) {
    var theLead = this;
    var buttons: DevExpress.ui.dxPopupToolbarItem[] = [
      {
        widget: 'dxButton',
        toolbar: 'bottom',
        location: 'after',
        options: {
          text: 'Qualify & Convert Lead',
          id: 'btnQualify',
          type: 'success',
          onClick: function () {
            // GRAB ALL RADIO BUTTONS THAT ARE SELECTED (STARTING AT RECORD TYPE, AND THEN TAKING ALL CHILDREN)

            var enumToPassToCoreMethod = theLead.DeriveQualifyEnum();

            if (!enumToPassToCoreMethod || enumToPassToCoreMethod === 'None') {
              let type: string = <string>$('[name=qualify-type]:checked').val();
              type = type.charAt(0).toUpperCase() + type.slice(1);
              new Notification({
                message: 'Please select an option for the new ' + type,
                type: 'warning',
                displayTime: 5000
              });
              $('#collapse' + type).removeClass('flash');
              setTimeout(() => {
                $('#collapse' + type).addClass('flash');
              }, 10);
              return;
            }
            var moveChildren = $('#MoveChildren').is(':checked');
            var mergeData = $('#MergeData').is(':checked');

            new TDFRequest({
              url: '/item/QualifyLead/',
              data: {
                ItemID: theLead.ItemID,
                ExistingItemId: theLead.linkId,
                Action: enumToPassToCoreMethod,
                mergeSettings: {
                  MoveChildren: moveChildren,
                  MergeData: mergeData
                },
                accountPackage: theLead.AcctPkgID || ''
              },
              type: 'POST'
            })
              .MakeRequest()
              .done(function (response: any) {
                if (true) {
                  new Dialog({
                    title: 'Qualified',
                    closable: true,
                    body: $('<div />').dxDataGrid({
                      dataSource: response.QualifyAndConvertResponse,
                      columns: [
                        {
                          dataField: 'ItemID',
                          caption: ' ',
                          cellTemplate: function (container, options) {
                            container.append(
                              $('<span />').addClass(
                                'item' + options.data.ItemType + ' iconWhite'
                              )
                            );
                          },
                          width: '10%',
                          visibleIndex: 0
                        },
                        {
                          dataField: 'ItemType',
                          visible: false
                        },
                        {
                          dataField: 'ItemName',
                          caption: 'Name'
                        },
                        {
                          dataField: 'ResponseType',
                          cellTemplate: function (container, options) {
                            container.append(
                              $('<span />').text(
                                options.data.ResponseType === 0
                                  ? 'Created'
                                  : 'Linked'
                              )
                            );
                          }
                        }
                      ],
                      selection: {
                        mode: 'single'
                      },
                      onSelectionChanged: function (selectedItems) {
                        var data = selectedItems.selectedRowsData[0];
                        $.when(DisplayInfo(data.ItemType)).done(function (info) {
                          //TODO:Figure this out when not in namespace TDF
                          new window[info.TypeName]({
                            ItemID: data.ItemID,
                            DisplayName: info.DisplayName,
                            ItemType: data.ItemType
                          }).Initialize();
                        });
                      }
                    }),
                    size: 'size-normal'
                    //
                  }).open();
                } else {
                  new Notification({
                    message: response.Message,
                    type: 'error',
                    displayTime: 5000,
                    shading: true
                  });
                }
              });

            dialog.close();
          }
        }
      },
      {
        widget: 'dxButton',
        toolbar: 'bottom',
        location: 'after',
        options: {
          text: 'Cancel',
          id: 'btnClose',
          type: 'danger',
          onClick: function () {
            dialog.close();
          }
        }
      }
    ];

    return buttons;
  }

  CloseButtons(dialog) {
    let theLead = this;
    var buttons: DevExpress.ui.dxPopupToolbarItem[] = [
      {
        widget: 'dxButton',
        toolbar: 'bottom',
        location: 'after',
        options: {
          text: 'Close Lead',
          type: 'success',
          elementAttr: { id: 'CloseLeadBtn' },
          onClick: function () {
            //var closeReason = theLead.closeReason.join(',');
            let req = new TDFRequest({
              url: '/item/closelead/',
              data: {
                ItemID: theLead.ItemID,
                Control_ID: theLead.CloseControlID,
                closeReasons: theLead.closeReason.join(',')
              },
              type: 'POST'
            });

            if (typeof theLead.closeReason === 'undefined') {
              //theLead.close();
              new Notification({
                message: 'Error: Failed to close lead. No reason selected.',
                type: 'error',
                displayTime: 5000
              });
              return false;
            }

            req.MakeRequest().done(function (response: any) {
              let action = $('#CloseLeadBtn')
                .dxButton('instance')
                .option('text');
              if (action === 'Close Lead') {
                action = 'closed';
              } else {
                action = 're-opened';
              }

              let message = response.Valid
                ? `Success: Lead ${action}.`
                : `Error: Lead was not ${action}.`;
              new Notification({
                message: message,
                type: response.Valid ? 'success' : 'error',
                displayTime: 5000
              });
            });
          }
        }
      },
      {
        widget: 'dxButton',
        toolbar: 'bottom',
        location: 'after',
        options: {
          text: 'Cancel',
          type: 'danger',
          onClick: function () {
            dialog.close();
          }
        }
      }
    ];

    return buttons;
  }

  BuildCloseForm() {
    let theLead = this;
    let dfd: JQueryDeferred<JQuery> = $.Deferred();
    var html = $("<div style='margin:0 10px'></div>");

    let Company = '';
    let Subject = '';
    theLead.SectionHeader(Company, Subject, html);
    new TDFRequest({
      url: '/item/GetLeadCloseReasons/',
      type: 'GET',
      data: { ItemID: theLead.ItemID }
    })
      .MakeRequest()
      .done(function (response: any) {
        var reasons = response.Reasons || [
          { Value_ID: 0, ValueName: 'No valid reasons found' }
        ];

        var currentValues = response.CurrentValues.split(',');
        var setReasons = reasons.filter(a => {
          return currentValues.indexOf(a.ValueName) !== -1;
        });

        theLead.CloseControlID = response.Control_ID;
        var row = $("<div style='margin:0 10px'></div>").addClass('row');

        if (setReasons.length) {
          row.append(
            $('<p></p>').text(
              'To Re-Open this Lead remove all Close Reasons and click the Re-Open Lead button.'
            )
          );
        }

        row.append($('<label></label>').text('Close Reasons'));
        row.append(
          $('<div />').dxTagBox({
            items: reasons,
            valueExpr: 'Value_ID',
            displayExpr: 'ValueName',
            value: setReasons.map(a => {
              return a.Value_ID;
            }),
            showSelectionControls: true,
            onSelectionChanged: function (e) {
              let addedItems = e.addedItems.map(a => {
                return a.ValueName;
              });
              let removedItems = e.removedItems.map(a => {
                return a.ValueName;
              });

              for (let i = 0, length = addedItems.length; i < length; i++) {
                theLead.closeReason.push(addedItems[i]);
              }

              for (let i = 0, length = removedItems.length; i < length; i++) {
                let index = theLead.closeReason.indexOf(removedItems[i]);
                theLead.closeReason.splice(index, 1);
              }

              let leadButton = $('#CloseLeadBtn').dxButton('instance');

              if (leadButton) {
                if (setReasons.length && !theLead.closeReason.length) {
                  leadButton.option('text', 'Re-Open Lead');
                } else {
                  leadButton.option('text', 'Close Lead');
                }
              }
              //if (e.addedItems.length) {
              //	theLead.closeReason.push(e.addedItems[0].ValueName);
              //}
            }
          })
        );

        html.append(row);
        dfd.resolve(html);
        // theLead.form.setMessage(html);
      });
    return dfd.promise();
  }

  RenderCustomTab(itemData, container, dfd) {
    var theLead = this;
    super.RenderCustomTab(itemData, container, dfd);

    if (itemData.ControlGroupID === 'MarketingIntegrationTab') {
      theLead.RenderCustomTab_MarketingIntegration(itemData, container);
    }
    dfd.resolve();
  }
}
