import { ItemBase } from "./itembase";
import { DisplayInfo } from "../../infrastructure/context";
import { itemTypes } from "../../enums/enums";

export class Literature extends ItemBase {
  DisplayName: string;
  get ItemType() {
    return itemTypes.itemLiterature;
  }
  get TypeName() {
    return "Literature";
  }
  set TypeName(name) {
    if (name && this.TypeName !== name) this.TypeName = name;
  }
  get RequiresParentItem(): boolean {
    return true;
  }
  constructor(args) {
    super(args);
    let theLiterature = this;
    $.each(args, function(key, val) {
      if (theLiterature.hasOwnProperty(key)) theLiterature[key] = val;
    });

    if (!theLiterature.DisplayName) {
      DisplayInfo(theLiterature.ItemType).done(function(displayinfo) {
        theLiterature.DisplayName = displayinfo.DisplayName;
        theLiterature.TypeName = displayinfo.TypeName;
      });
    }
  }
}
