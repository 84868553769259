export class TileSettingsBase {
    constructor() {
        let theTileSettings = this;
    }
    public InitSettings(initTileSettings?: Partial<TileSettingsBase>) {
        let theTileSettings = this;
        $.each(initTileSettings, function (k, v:any) {
            if (<string>k != "TileScope") {
                theTileSettings[k] = v;
            }
        });
    }
    toJSON(key) {
        let theTile = this;
        let it = {};
        Object.keys(theTile).map((v, k) => {
            it[v] = theTile[v];
        });
        return it;
    }
}
