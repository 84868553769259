import "devextreme/ui/lookup";
import * as Globalize from "globalize";
import * as moment from "moment";
import {
  EnumAddressTypes,
  EnumUDFFormat,
  itemTypes,
  PropertFormatCurrencyEnum,
  PropertyFormatNumberEnum,
  PropertyFormatPercentEnum,
  tdfRights
} from "../../enums/enums";

import {
  IControlData,
  IDialogOptions,
  ILinkedItemCounterResult,
  ILinkedItemsArgs,
  ITDFAddressBlock
} from "../../interfaces/interfaces";
import { TDFRequest } from "../../services/request";
import { Dialog } from "../dialogs/dialog";
import { Notification } from "../dialogs/notification";
import { Forecast } from "../items/forecast";
import { ItemBase } from "../items/itembase";
import { Opportunity } from "../items/opportunity";
import { Calculator } from "./calculator";

import {
  GetDevice,
  DomSafeID,
} from "../../util/allutils";
import dxDataSource from "devextreme/data/data_source";
import dxCustomStore from "devextreme/data/custom_store";
import notify from "devextreme/ui/notify";
import "devextreme/ui/tag_box";
import "devextreme/ui/button";
import "devextreme/ui/text_box";
import "devextreme/ui/form";
import "devextreme/ui/data_grid";
import "devextreme/ui/scroll_view";
import "devextreme/ui/select_box";
import "devextreme/ui/drop_down_box";
import "devextreme/ui/lookup";
import "devextreme/ui/check_box";
import "devextreme/ui/text_area";
import "devextreme/ui/date_box";
import "devextreme/ui/number_box";
import "devextreme/ui/html_editor";

import { CurrentUser, DoesUserHaveRight } from "../../infrastructure/context";
import { LinkedItems } from "../../legacy-centers-grids/linkeditems";
import { LinkedItemsCenter } from "datacenters/linkeditemscenter";

export class Control {
  jqElement: JQuery;

  ControlData: IControlData;
  TDFItem: ItemBase;
  ConvertedType: string;
  columnTypes = {
    ABCDEF: { colclass: "col-md-12", colwidth: 12 },
    ABCD: { colclass: "col-md-8", colwidth: 8 },
    ABC: { colclass: "col-md-6", colwidth: 6 },
    AB: { colclass: "col-md-4", colwidth: 4 },
    BC: { colclass: "col-md-4", colwidth: 4 },
    BCDEF: { colclass: "col-md-10", colwidth: 10 },
    CD: { colclass: "col-md-4", colwidth: 4 },
    DEF: { colclass: "col-md-6", colwidth: 6 },
    DE: { colclass: "col-md-4", colwidth: 4 },
    EF: { colclass: "col-md-4", colwidth: 4 },
    ABCDE: { colclass: "col-md-10", colwidth: 10 },
    BCDE: { colclass: "col-md-8", colwidth: 8 },
    BCD: { colclass: "col-md-6", colwidth: 6 },
    CDEF: { colclass: "col-md-8", colwidth: 8 },
    CDE: { colclass: "col-md-6", colwidth: 6 },
    "": { colclass: "col-md-12", colwidth: 12 }
  };

  offSets = {
    ABCDEF: { offset: "", colwidth: 12 },
    ABCD: { offset: "", colwidth: 8 },
    ABC: { offset: "", colwidth: 6 },
    AB: { offset: "", colwidth: 4 },
    BC: { offset: "offset-md-2", colwidth: 4 },
    BCDEF: { offset: "offset-md-2", colwidth: 10 },
    CD: { offset: "offset-md-4", colwidth: 4 },
    DEF: { offset: "offset-md-6", colwidth: 6 },
    DE: { offset: "offset-md-6", colwidth: 4 },
    EF: { offset: "offset-md-8", colwidth: 4 },
    ABCDE: { offset: "", colwidth: 10 },
    BCDE: { offset: "offset-md-2", colwidth: 8 },
    BCD: { offset: "offset-md-2", colwidth: 6 },
    CDEF: { offset: "offset-md-4", colwidth: 8 },
    CDE: { offset: "offset-md-4", colwidth: 6 },
    "": { offset: "", colwidth: 12 }
  };

  constructor(args) {
    let theControl = this;
    theControl.ControlData = args.Properties;
    theControl.TDFItem = args.TDFItem;
    if (
      theControl.TDFItem.isNew ||
      (theControl.TDFItem.ItemType === itemTypes.itemQuote &&
        window.location.href.indexOf("quotenew") > -1)
    ) {
      theControl.CheckPrepopulation();
      theControl.SetDefaultValue();
    }
    return theControl;
  }
  SetDefaultValue() {
    let theControl = this;
    if (
      theControl.ControlData.Properties.Control_Type !== 6 &&
      theControl.ControlData.Properties.Control_Type !== 18
    ) {
      if (theControl.ControlData.DataValue.ValueName == "") {
        if (theControl.ControlData.Properties.DefaultValue) {
          theControl.ControlData.DataValue.ValueName =
            theControl.ControlData.Properties.DefaultValue;
          if (
            theControl.ControlData.Properties.TDFField === "CalculationMethod"
          ) {
            let theval = theControl.ControlData.PossibleValues.filter(k => {
              return (
                k["ValueName"] ===
                theControl.ControlData.Properties.DefaultValue
              );
            });
            theControl.ControlData.DataValue.ValueName = theval[0].Value_ID;
          }
          theControl.ControlData.SaveValue =
            theControl.ControlData.DataValue.ValueName;
          theControl.TDFItem.SetDirty(theControl.ControlData);
        }
      }
    } else {
      if (theControl.ControlData.Properties.DefaultValue) {
        let num = parseInt(theControl.ControlData.Properties.DefaultValue);
        if (!isNaN(num) && typeof num === "number") {
          theControl.ControlData.DataValue.ValueName = moment()
            .add(num, "days")
            .toDate();
          theControl.ControlData.SaveValue =
            theControl.ControlData.DataValue.ValueName;
          theControl.TDFItem.SetDirty(theControl.ControlData);
        } else {
          if (
            theControl.ControlData.Properties.DefaultValue.toLowerCase() ===
            "today"
          ) {
            theControl.ControlData.DataValue.ValueName = moment().toDate();
            theControl.ControlData.SaveValue =
              theControl.ControlData.DataValue.ValueName;
            theControl.TDFItem.SetDirty(theControl.ControlData);
          }
        }
      }
    }
  }
  CheckPrepopulation() {
    let theControl = this;
    if (theControl.TDFItem.PrepopulationData) {
      if (
        theControl.TDFItem.PrepopulationData.TDFObjects &&
        theControl.TDFItem.PrepopulationData.TDFObjects.length
      ) {
        let d = $.grep(
          theControl.TDFItem.PrepopulationData.TDFObjects,
          (val, index) => {
            return (
              val.TDFField.replace("TDF_", "") ===
              theControl.ControlData.Properties.TDFField
            );
          }
        );
        if (d && d.length > 0) {
          theControl.ControlData.DataValue.ValueName = d[0].Value;
          theControl.ControlData.SaveValue = d[0].Value;
          theControl.TDFItem.SetDirty(theControl.ControlData);
        }
      }

      if (
        theControl.TDFItem.PrepopulationData.UDFData &&
        theControl.TDFItem.PrepopulationData.UDFData.length
      ) {
        let d = $.grep(
          theControl.TDFItem.PrepopulationData.UDFData,
          (val, index) => {
            return val.UDF_ID === theControl.ControlData.Properties.UDF_ID;
          }
        );
        if (d && d.length > 0) {
          theControl.ControlData.DataValue = d[0].SimpleUdfValue;
          theControl.ControlData.SaveValue = d[0].SimpleUdfValue.ValueName;
          theControl.TDFItem.SetDirty(theControl.ControlData);
        }
      }
    }
  }

  GetValueAsText() {
    let theControl = this;
    let vtext = "";
    if (typeof theControl.ControlData.DataValue === "object") {
      if (theControl.ControlData.DataValue.ValueName) {
        vtext = theControl.ControlData.DataValue.ValueName.toString();
      }
    }
    theControl.ControlData.DataValue.ValueName = vtext;
    return vtext;
  }

  GetValueAsNum() {
    let theControl = this;
    let vnum = 0;
    if (typeof theControl.ControlData.DataValue === "object") {
      if (theControl.ControlData.DataValue.ValueName) {
        vnum = parseFloat(theControl.ControlData.DataValue.ValueName);
      }
    } else if (typeof theControl.ControlData.DataValue === "string") {
      vnum = parseFloat(theControl.ControlData.DataValue);
    }
    theControl.ControlData.DataValue.ValueName = vnum;
    return vnum;
  }
  /**
   * AHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHH Internet Explorer will take 2 digit year to the wrong century
   * @param dstyle
   * @param time
   */
  GetValueAsDate(
    dstyle: "short" | "long" | "full" | "medium" = "short",
    time = false
  ) {
    let theControl = this;
    if (
      !moment(new Date(theControl.ControlData.DataValue.ValueName)).isValid()
    ) {
      //Check to see if am and pm are in the right spot
      let endString = theControl.ControlData.DataValue.ValueName.slice(
        -2
      ).toLowerCase();
      // if the last 2 characters in the date string are am or pm
      if (endString === "am" || endString === "pm") {
        let str =
          theControl.ControlData.DataValue.ValueName.substring(
            0,
            theControl.ControlData.DataValue.ValueName.length - 2
          ) +
          " " +
          endString;
        if (!moment(new Date(str)).isValid()) {
          theControl.ControlData.DataValue.ValueName = "";
        } else {
          theControl.ControlData.DataValue.ValueName = new Date(str);
          // if (new Date(str).getFullYear() < new Date().getFullYear()) {
          //  new Notification({ message: "The year is " + new Date(str).getFullYear()})
          // }
        }
      } else {
        theControl.ControlData.DataValue.ValueName = "";
      }
    } else {
      let unformattedDate = new Date(
        theControl.ControlData.DataValue.ValueName
      );

      if (unformattedDate.toUTCString().indexOf("00:00:00") > -1) {
        unformattedDate = new Date(
          unformattedDate.getUTCFullYear(),
          unformattedDate.getUTCMonth(),
          unformattedDate.getUTCDate(),
          0,
          0,
          0,
          0
        );
      }

      theControl.ControlData.DataValue.ValueName = unformattedDate; //Globalize.formatDate(unformattedDate, time ? { datetime: dstyle } : { date: dstyle });

      // if (new Date(theControl.ControlData.DataValue.ValueName).getFullYear() < new Date().getFullYear()) {
      //    new Notification({ message: "The year is " + new Date(theControl.ControlData.DataValue.ValueName).getFullYear() })
      // }
    }
    return theControl.ControlData.DataValue.ValueName;
  }

  GetFormattedValue(
    dstyle: "short" | "long" | "full" | "medium" = "short",
    time = false
  ) {
    let theControl = this;

    switch (theControl.ControlData.Properties.UDFFormat) {
      case EnumUDFFormat.udfCurrency:
        switch (theControl.ControlData.Properties.PropertyFormat) {
          case PropertFormatCurrencyEnum.propNoDecimals:
            theControl.ControlData.DataValue.ValueName = Globalize.formatCurrency(
              theControl.GetValueAsNum(),
              CurrentUser.Currency,
              { minimumFractionDigits: 0, maximumFractionDigits: 0 }
            );
            return theControl.ControlData.DataValue.ValueName;
          case PropertFormatCurrencyEnum.propTwoDecimals:
            theControl.ControlData.DataValue.ValueName = Globalize.formatCurrency(
              theControl.GetValueAsNum(),
              CurrentUser.Currency,
              { minimumFractionDigits: 2, maximumFractionDigits: 2 }
            );
            return theControl.ControlData.DataValue.ValueName;
          case PropertFormatCurrencyEnum.propThreeDecimals:
            theControl.ControlData.DataValue.ValueName = Globalize.formatCurrency(
              theControl.GetValueAsNum(),
              CurrentUser.Currency,
              { minimumFractionDigits: 3, maximumFractionDigits: 3 }
            );
            return theControl.ControlData.DataValue.ValueName;
          case PropertFormatCurrencyEnum.propFourDecimals:
            theControl.ControlData.DataValue.ValueName = Globalize.formatCurrency(
              theControl.GetValueAsNum(),
              CurrentUser.Currency,
              { minimumFractionDigits: 4, maximumFractionDigits: 4 }
            );
            return theControl.ControlData.DataValue.ValueName;
          default:
            theControl.ControlData.DataValue.ValueName = Globalize.formatCurrency(
              theControl.GetValueAsNum(),
              CurrentUser.Currency
            );
            return theControl.ControlData.DataValue.ValueName;
        }
      case EnumUDFFormat.udfPercent:
      case EnumUDFFormat.udfPercent2:
        switch (theControl.ControlData.Properties.PropertyFormat) {
          case PropertyFormatPercentEnum.propRounded:
            theControl.ControlData.DataValue.ValueName = Globalize.formatNumber(
              theControl.GetValueAsNum(),
              {
                style: "percent",
                minimumFractionDigits: 0,
                maximumFractionDigits: 0
              }
            );
            return theControl.ControlData.DataValue.ValueName;
          case PropertyFormatPercentEnum.propOneDecimals:
            theControl.ControlData.DataValue.ValueName = Globalize.formatNumber(
              theControl.GetValueAsNum(),
              {
                style: "percent",
                minimumFractionDigits: 1,
                maximumFractionDigits: 1
              }
            );
            return theControl.ControlData.DataValue.ValueName;
          case PropertyFormatPercentEnum.propTwoDecimals:
            theControl.ControlData.DataValue.ValueName = Globalize.formatNumber(
              theControl.GetValueAsNum(),
              {
                style: "percent",
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
              }
            );
            return theControl.ControlData.DataValue.ValueName;
          case PropertyFormatPercentEnum.propAllDigits:
            theControl.ControlData.DataValue.ValueName = Globalize.formatNumber(
              theControl.GetValueAsNum(),
              {
                style: "percent",
                minimumFractionDigits: 2,
                maximumFractionDigits: 20
              }
            );
            return theControl.ControlData.DataValue.ValueName;
          default:
            theControl.ControlData.DataValue.ValueName = Globalize.formatNumber(
              theControl.GetValueAsNum(),
              { style: "percent" }
            );
            return theControl.ControlData.DataValue.ValueName;
        }
      case EnumUDFFormat.udfDate:
        return theControl.GetValueAsDate(dstyle, time);
      case EnumUDFFormat.udfDuration:
        return theControl.GetValueAsNum();
      case EnumUDFFormat.udfCombination:
        break;
      case EnumUDFFormat.udfFormula:
        break;
      case EnumUDFFormat.udfKeywords:
        break;
      case EnumUDFFormat.udfNumber:
        switch (theControl.ControlData.Properties.PropertyFormat) {
          case PropertyFormatNumberEnum.propAllDigits:
            theControl.ControlData.DataValue.ValueName = Globalize.formatNumber(
              theControl.GetValueAsNum(),
              { minimumFractionDigits: 2, maximumFractionDigits: 20 }
            );
            return theControl.ControlData.DataValue.ValueName;
          case PropertyFormatNumberEnum.propTruncated:
            theControl.ControlData.DataValue.ValueName = Globalize.formatNumber(
              theControl.GetValueAsNum(),
              { minimumFractionDigits: 0, maximumFractionDigits: 0 }
            );
            return theControl.ControlData.DataValue.ValueName;
          case PropertyFormatNumberEnum.propOneDecimal:
            theControl.ControlData.DataValue.ValueName = Globalize.formatNumber(
              theControl.GetValueAsNum(),
              { minimumFractionDigits: 1, maximumFractionDigits: 1 }
            );
            return theControl.ControlData.DataValue.ValueName;
          case PropertyFormatNumberEnum.propTwoDecimal:
            theControl.ControlData.DataValue.ValueName = Globalize.formatNumber(
              theControl.GetValueAsNum(),
              { minimumFractionDigits: 2, maximumFractionDigits: 2 }
            );
            return theControl.ControlData.DataValue.ValueName;
          case PropertyFormatNumberEnum.propThreeDecimal:
            theControl.ControlData.DataValue.ValueName = Globalize.formatNumber(
              theControl.GetValueAsNum(),
              { minimumFractionDigits: 3, maximumFractionDigits: 3 }
            );
            return theControl.ControlData.DataValue.ValueName;
          case PropertyFormatNumberEnum.propComputer:
            let bytes = theControl.GetValueAsNum();
            // let gigabytes = Math.floor(bytes / 1000000000);
            // bytes -= gigabytes * 1000000;
            // let megabytes = Math.floor(bytes / 1000000);
            // bytes -= megabytes * 1000000;
            // let kilobytes = Math.floor(bytes / 1000);
            // bytes -= kilobytes * 1000;
            // let tempbytes = gigabytes !== 0 ? gigabytes + " GB, " : "";
            // tempbytes += megabytes !== 0 ? megabytes + " MB, " : "";
            // tempbytes += kilobytes !== 0 ? kilobytes + " KB, " : "";
            // tempbytes += bytes + " B";
            theControl.ControlData.DataValue.ValueName = bytes;
            return theControl.ControlData.DataValue.ValueName;
          case PropertyFormatNumberEnum.propTimespan:
            let origMnts = theControl.GetValueAsNum();

            // let hrs = Math.floor(origMnts / 60);
            // let mnts = origMnts - (hrs * 60);
            // let seconds = mnts === 0 ?
            //   0 : (Math.round((mnts % Math.floor(mnts)) * 100) / 100) * 60;

            // let timestamp = hrs !== 0 ? hrs + " Hrs, " : "";
            // timestamp += mnts !== 0 ? mnts + " Minutes, " : "";
            // timestamp += seconds + " Seconds";

            theControl.ControlData.DataValue.ValueName = origMnts;//tempseconds;
            return theControl.ControlData.DataValue.ValueName;
          default:
            theControl.GetValueAsNum();
            theControl.ControlData.DataValue.ValueName = Globalize.formatNumber(
              theControl.GetValueAsNum()
            );
            return theControl.ControlData.DataValue.ValueName;
        }
        break;
      case EnumUDFFormat.udfText:
        theControl.GetValueAsText();
        break;
      case EnumUDFFormat.udfYesNo:
        break;
      default:
        return theControl.ControlData.DataValue.ValueName;
    }

    return theControl.ControlData.DataValue.ValueName;
  }

  /**
   * Control_Type =0
   */
  TextBox() {
    let theControl = this;
    let control;

    if (theControl.ControlData.Properties.PropertyFormat === PropertyFormatNumberEnum.propComputer) {
      control = theControl.ComputerSpan();

    } else if (theControl.ControlData.Properties.PropertyFormat === PropertyFormatNumberEnum.propTimespan) {
      control = theControl.TimeSpan();

    } else {
      if (theControl.ControlData.Properties.MainUDF === "Attendees") {
        let isInitialLoad: boolean = true;

        control = $("<div />")
          .dxTagBox(<DevExpress.ui.dxTagBoxOptions>{
            dataSource: [],
            disabled: theControl.checkDisabled(), //((theControl.ControlData.Properties.Locked === 1) ? true : false),
            inputAttr: {
              id:
                theControl.ControlData.Properties.Control_ID +
                theControl.TDFItem.DomSafeID
            },
            elementAttr: {
              "tdf-form-controlid": theControl.ControlData.Properties.Control_ID
            },
            showClearButton: true,
            displayExpr: "name",
            valueExpr: "contactid",
            tagTemplate: function (itemData, tagElement) {
              tagElement.append(
                $("<div />")
                  .addClass("dx-tag-content attendee-tag-box")
                  .addClass(itemData.TDFUser ? "btn-info" : "")
                  .append($("<span />").text(itemData.name))
                  .append(
                    $("<div />")
                      .addClass("dx-tag-remove-button")
                      .on("click", e => {
                        let val = control.option("value");

                        let contactids: Array<string> = val.map(a => {
                          return a.contactid;
                        });

                        if (contactids.indexOf(itemData.contactid) === 0) {
                          if (contactids.length === 1) {
                            val = [];
                          } else {
                            val.shift();
                          }
                        } else {
                          val.splice(contactids.indexOf(itemData.contactid), 1);
                        }

                        control.option("value", val);
                      })
                  )
              );
            },
            onValueChanged: function (data) {
              if (data.value) {
                let idsonly = [];

                $.each(data.value, (k, v) => {
                  idsonly.push(v.contactid);
                });
                if (isInitialLoad) {
                  isInitialLoad = false;
                  return;
                }
                theControl.ControlData.SaveValue = idsonly.join(",");
                theControl.TDFItem.SetDirty(theControl.ControlData);
              }
            }
          })
          .dxTagBox("instance");

        if ((theControl.TDFItem as any).InitialAttendee) {
          isInitialLoad = false;
          control.option("value", [
            (theControl.TDFItem as any).InitialAttendee[0]
          ]);
          isInitialLoad = true;
        }
        theControl.jqElement = control.element();

        theControl.jqElement.find(".dx-texteditor-buttons-container").append(
          $("<div />").dxButton({
            icon: "search",
            text: "Add Attendees",
            onInitialized: function (data) {
              $(data.element).css({
                "border-width": " 0 0 0 1px",
                "border-radius": "0px"
              });
            },
            onClick: function (e) {
              let timer = 0;
              let form = $("<div />");

              let grid = $("<div />")
                .dxDataGrid(<DevExpress.ui.dxDataGridOptions>{
                  dataSource: [],
                  selection: { mode: "multiple" },
                  columns: [
                    {
                      dataField: "name",
                      visible: true,
                      caption: "Name",
                      sortIndex: 2,
                      sortOrder: "asc"
                    },
                    {
                      dataField: "AccountName",
                      visible: true,
                      caption: "Account",
                      sortIndex: 1,
                      sortOrder: "asc"
                    },
                    {
                      dataField: "Attending",
                      visible: true//,
                      //calculateDisplayValue: function (data) {
                      //  return data.Attending === 1 ? "Yes" : "No";
                      //}
                    },
                    { dataField: "BSIInfo", visible: true, caption: "BI Info" },
                    {
                      dataField: "Sort",
                      visible: false,
                      calculateSortValue: function (data) {
                        return Math.abs(data.Sort);
                      },
                      sortOrder: "desc",
                      sortIndex: 0
                    },
                    {
                      dataField: "TDFUser",
                      visible: false,
                      caption: "TDF User",
                    /*groupIndex: 0,*/ calculateDisplayValue: function (data) {
                        return data.TDFUser === 1 ? "Yes" : "No";
                      }
                    },
                    { dataField: "accountid", visible: false },
                    { dataField: "contactid", visible: false }
                  ],
                  columnAutoWidth: true,
                  columnResizingMode: "widget",
                  allowColumnResizing: true,
                  columnChooser: { enabled: true, allowSearch: true }
                })
                .dxDataGrid("instance");
              let opt: DevExpress.ui.dxTextBoxOptions = {
                mode: "search",
                disabled: theControl.checkDisabled(), //((theControl.ControlData.Properties.Locked === 1) ? true : false), //theControl.ControlData.Properties.Locked === 1,
                onInput: function (e) {
                  if (timer > 0) clearTimeout(timer);
                  timer = window.setTimeout(function () {
                    if (!e.component.option("text")) return;
                    new TDFRequest({
                      url: "/item/SearchAttendees/",
                      data: {
                        parentaccountid:
                          theControl.TDFItem.iMain.ItemParentAccountId,
                        query: e.component.option("text"),
                        appointmentid: theControl.TDFItem.ItemID
                      }
                    })
                      .MakeRequest()
                      .done(function (response: any) {
                        if (true) {
                          if (response.length > 0) {
                            grid.option("dataSource", response);
                          }
                        }
                      });
                  }, 2000);
                },
                onEnterKey: function (e) {
                  clearTimeout(timer);
                  new TDFRequest({
                    url: "/item/SearchAttendees/",
                    data: {
                      parentaccountid: theControl.TDFItem.iMain.ItemParentAccountId,
                      query: e.component.option("text"),
                      appointmentid: theControl.TDFItem.ItemID
                    }
                  })
                    .MakeRequest()
                    .done(function (response: any) {
                      if (true) {
                        if (response.length > 0) {
                          grid.option("dataSource", response);
                        }
                      }
                    });
                }
              };
              let search = $("<div/>").dxTextBox(opt);
              form.append(search).append(grid.element());

              let opts: IDialogOptions = {
                body: form,
                closable: true,
                buttons: [
                  {
                    widget: "dxButton",
                    toolbar: "bottom",
                    location: "after",
                    options: {
                      // cssClass: "btn-success",
                      text: "Add Selected",
                      type: "normal",
                      onClick: function (dlg) {
                        let selected = grid.getSelectedRowsData();
                        let vals = [];
                        let current = control.option("value");
                        if (current && current.length) {
                          $.each(current, (k, v) => {
                            vals.push(v);
                          });
                        }
                        $.each(selected, function (key, val) {
                          vals.push({
                            contactid: val.contactid,
                            name: selected[key].name
                          });
                        });
                        control.option("value", []);
                        control.option("value", vals);
                        attendeeDialog.close();
                      }
                    }
                  }
                ],
                title: "Search For Attendees",
                size: "size-large"
              };
              let attendeeDialog = new Dialog(opts, null, true, false);
              attendeeDialog.open().done(() => {
                grid.option(
                  "height",
                  attendeeDialog.Instance.content().height() - search.height()
                );
              });
            }
          })
        );

        //Handler for the clear all button.
        theControl.jqElement.find(".dx-clear-button-area").on("click", e => {
          theControl.ControlData.SaveValue = "";
          theControl.TDFItem.SetDirty(theControl.ControlData);
        });

        if (!theControl.TDFItem.isNew) {
          new TDFRequest({
            url: "/item/GetAttending/",
            data: { itemid: theControl.TDFItem.ItemID }
          })
            .MakeRequest()
            .done(function (response: any) {
              //  if (true) {

              control.option("value", response);

              //} else {
              //  new Notification({
              //    message: response.Message,
              //    type: "error",
              //    displayTime: 5000
              //  });
              //}
            });
        }

        theControl.ControlData.Dx = control;
        return theControl;
      } else {
        control = $("<div />")
          .dxTextBox({
            value: theControl.GetFormattedValue(),
            disabled: theControl.checkDisabled(), //((theControl.ControlData.Properties.Locked === 1) ? true : false),, //theControl.ControlData.Properties.Locked === 1,
            //readOnly: theControl.ControlData.Properties.ReadOnly,
            inputAttr: {
              id:
                theControl.ControlData.Properties.Control_ID +
                theControl.TDFItem.DomSafeID
            },
            elementAttr: {
              "tdf-form-controlid": theControl.ControlData.Properties.Control_ID
            },
            showClearButton: true,
            onValueChanged: function (data) {
              let item = theControl.TDFItem;
              theControl.ControlData.SaveValue = data.value;
              theControl.TDFItem.SetDirty(theControl.ControlData);
            }
          })
          .dxTextBox("instance");
        theControl.ControlData.Dx = control;
        theControl.jqElement = control.element();

        if (
          theControl.ControlData.Properties.TDFField === "LastActivitySubject" ||
          theControl.ControlData.Properties.TDFField === "NextActionStep"
        ) {
          if (theControl.ControlData.DataValue.Value_ID) {
            control.option("disabled", false);
            if (
              theControl.ControlData.Properties.TDFAction === 803 ||
              theControl.ControlData.Properties.TDFField === "LastActivitySubject"
            ) {
              control.option("readOnly", true);
            }

            let btn = $("<div />")
              .dxButton({
                icon: `fa tdfitem${
                  theControl.ControlData.DataValue.ValueID
                  } tdf-textbox-button`,
                text: "",
                hint:
                  "Open this item's (" +
                  theControl.ControlData.Properties.LabelCaption +
                  ")",
                onInitialized: function (data) {
                  $(data.element).css({
                    "border-width": " 0 0 0 1px",
                    "border-radius": "0px"
                  });
                },
                onClick: function (e) {
                  //TODO:Figure this out when not in namespace TDF
                  new window[
                    (itemTypes[theControl.ControlData.DataValue.ValueID].replace(
                      "item",
                      ""
                    ))
                  ]({
                    ItemID: theControl.ControlData.DataValue.Value_ID
                  }).Initialize();
                }
              })
              .dxButton("instance");
            theControl.jqElement
              .find(".dx-texteditor-buttons-container")
              .append(btn.element());
            // If the control is readOnly the button click wont fire so lets hijack it
            if (
              theControl.ControlData.Properties.ReadOnly ||
              control.option("readOnly")
            ) {
              btn.element().on("click", e => {
                new window[
                  (itemTypes[theControl.ControlData.DataValue.ValueID].replace(
                    "item",
                    ""
                  ))
                ]({
                  ItemID: theControl.ControlData.DataValue.Value_ID
                }).Initialize();
              });
            }
          }
        } else {
          if (theControl.TDFItem.ItemType === itemTypes.itemOpportunity) {
            if (theControl.ControlData.Properties.TDFField === "ProbableValue") {
              control.option("disabled", true);
              (theControl.TDFItem as Opportunity).ProbableValue = control;
            }
            if (theControl.ControlData.Properties.TDFField === "ForecastValue") {
              control.option("disabled", true);
              (theControl.TDFItem as Opportunity).ForecastValue = control;
            }
            if (theControl.ControlData.Properties.TDFField === "Opportunity") {
              (theControl.TDFItem as Opportunity).OpportunityValue = control;
            }
          } else {
            if (theControl.TDFItem.ItemType === itemTypes.itemForecast) {
              if (
                theControl.ControlData.Properties.TDFField ===
                "ForecastProbableValue"
              ) {
                control.option("disabled", true);
                (theControl.TDFItem as Forecast).ForecastProbableValue = control;
              }
              if (
                theControl.ControlData.Properties.TDFField === "ForecastValue"
              ) {
                (theControl.TDFItem as Forecast).ForecastValue = control;
                control.on("valueChanged", e => {
                  (theControl.TDFItem as Forecast).TryCalculateProbableValue();
                });
              }
            }
          }
        }
      }
      return theControl;
    }
  }

  /**
   * Control_Type =1
   */
  ComboBox() {
    let theControl = this;
    let selopts: DevExpress.ui.dxSelectBoxOptions = {
      dataSource: theControl.ControlData.PossibleValues,
      value: theControl.ControlData.DataValue,
      placeholder: "",
      displayExpr: "ValueName",
      searchEnabled: true,
      searchExpr: () => {
        return ["ValueName"];
      },
      valueExpr: theControl.ControlData.DataValue.Value_ID
        ? "Value_ID"
        : "ValueName",
      showClearButton: true,
      inputAttr: {
        id:
          theControl.ControlData.Properties.Control_ID +
          theControl.TDFItem.DomSafeID
      },
      elementAttr: {
        "tdf-form-controlid": theControl.ControlData.Properties.Control_ID
      },
      onValueChanged: function (data) {
        let item = theControl.TDFItem;
        theControl.ControlData.SaveValue = data.value;
        theControl.TDFItem.SetDirty(theControl.ControlData);
      },
      disabled: theControl.checkDisabled(), //((theControl.ControlData.Properties.Locked === 1) ? true : false),
      acceptCustomValue: true,
      onCustomItemCreating: function (data) {
        theControl.ControlData.PossibleValues.push({
          Value_ID: "",
          ValueName: data.text
        });
        data.component.option(
          "dataSource",
          theControl.ControlData.PossibleValues
        );
        theControl.ControlData.SaveValue = data.text;
        theControl.TDFItem.SetDirty(theControl.ControlData);
        return { Value_ID: "", ValueName: data.text };
      }
    };

    let control = $("<div />")
      .dxSelectBox(selopts)
      .dxSelectBox("instance");
    theControl.jqElement = control.element();
    theControl.ControlData.Dx = control;
    return theControl;
  }

  /**
   * DropDown Single Value.
   *  Control_Type = 2
   */
  DropDownList() {
    let theControl = this;
    let currentSavedVal = {
      Value_ID: theControl.ControlData.DataValue.Value_ID,
      ValueName: theControl.ControlData.DataValue.ValueName
    };
    let pushCurrent: boolean = true;

    //If the saved value for the control is not an option, the option is added to the list until the value is changed, saved, to something else, and refreshed.
    if (
      currentSavedVal.Value_ID === "" &&
      currentSavedVal.ValueName &&
      theControl.ControlData.Properties.TDFField !== "CalculationMethod"
    ) {
      for (let i = 0; i < theControl.ControlData.PossibleValues.length; i++) {
        if (
          theControl.ControlData.PossibleValues[i].ValueName ===
          currentSavedVal.ValueName
        ) {
          pushCurrent = false;
        }
      }
      if (pushCurrent) {
        theControl.ControlData.PossibleValues.push(currentSavedVal);
      }
    }

    //  let vals = [];
    let myStore: any;
    if (theControl.ControlData.Properties.TDFField === "Owner") {
      myStore = new dxCustomStore({
        load: function (loadOptions) {
          return $.get("/core/user/userlist/");
        },
        byKey: function (key) {
          let d = $.Deferred();
          d.resolve({ Name: key });
          return d.promise();
        }
      });
    } else {
      myStore = new dxCustomStore({
        load: function (loadOptions) {
          let d = $.Deferred();
          if (loadOptions.searchValue) {
            return d.promise(
              d.resolve(
                theControl.ControlData.PossibleValues.filter(function (k, v) {
                  return (
                    k.ValueName.toLowerCase(0).indexOf(
                      loadOptions.searchValue.toLowerCase()
                    ) > -1
                  );
                })
              )
            );
          }

          return d.promise(d.resolve(theControl.ControlData.PossibleValues));
        },
        byKey: function (key) {
          let d = $.Deferred();
          let theval = $.grep(
            theControl.ControlData.PossibleValues,
            (v: any, k) => {
              return v.ValueName.trim() === key || v.Value_ID.trim() === key;
            }
          );

          return d.promise(d.resolve(theval[0]));
        }
      });
    }

    let selopts: DevExpress.ui.dxSelectBoxOptions = {
      dataSource: myStore,
      placeholder: "",
      value: theControl.GetValueAsText(),
      showClearButton: true,
      searchEnabled: true,
      searchExpr: () => {
        return ["ValueName"];
      },
      displayExpr: "ValueName",
      valueExpr:
        theControl.ControlData.Properties.TDFField === "CalculationMethod"
          ? "Value_ID"
          : "ValueName",
      inputAttr: {
        id:
          theControl.ControlData.Properties.Control_ID +
          theControl.TDFItem.DomSafeID
      },
      elementAttr: {
        "tdf-form-controlid": theControl.ControlData.Properties.Control_ID
      },
      disabled: theControl.checkDisabled(), //((theControl.ControlData.Properties.Locked === 1) ? true : false),
      onValueChanged: function (data) {
        let item = theControl.TDFItem;
        theControl.ControlData.SaveValue = data.value;
        theControl.TDFItem.SetDirty(theControl.ControlData);
      }
    };
    let control = $("<div />")
      .dxSelectBox(selopts)
      .dxSelectBox("instance");
    if (theControl.ControlData.Properties.TDFField === "Owner") {
      control.option("displayExpr", "Name");
      control.option("valueExpr", "Name");
    }
    if (theControl.TDFItem.ItemType === itemTypes.itemOpportunity) {
      let theOpp = theControl.TDFItem as Opportunity;
      if (theControl.ControlData.Properties.TDFField === "CalculationMethod") {
        theOpp.CalculationMethod = control;
        control.on("valueChanged", function (e) {
          theOpp.UpdateValueControls(e.value);
        });
      } else if (
        theControl.ControlData.Properties.TDFField === "CloseProbability"
      ) {
        theOpp.CloseProbability = control;
        control.on("valueChanged", e => {
          theOpp.TryCalculateProbableValue();
        });
      }
    } else {
      if (theControl.TDFItem.ItemType === itemTypes.itemForecast) {
        if (
          theControl.ControlData.Properties.TDFField === "ForecastProbability"
        ) {
          let theForecast = theControl.TDFItem as Forecast;
          theForecast.ForecastProbability = control;
          control.on("valueChanged", e => {
            theForecast.TryCalculateProbableValue();
          });
        }
      }
    }

    theControl.jqElement = control.element();
    theControl.ControlData.Dx = control;
    return theControl;
  }

  /**
   * A list of check boxes ..
   * Control_Type = 3
   */
  ListBox() {
    let theControl = this;
    let vals = [];
    $.each(theControl.ControlData.PossibleValues, function (key, val) {
      vals.push(val.ValueName);
    });

    // vals.length === 0 ? theControl.ControlData.DataValue ? vals.push(theControl.ControlData.DataValue) : vals.push("Noting") : (function () { })();
    let control = $("<div />")
      .dxTagBox({
        dataSource: theControl.ControlData.PossibleValues,
        valueExpr: "ValueName",
        displayExpr: "ValueName",
        applyValueMode: "useButtons",
        showSelectionControls: true,
        searchEnabled: !GetDevice().isDevice,
        inputAttr: {
          id:
            theControl.ControlData.Properties.Control_ID +
            theControl.TDFItem.DomSafeID
        },
        elementAttr: {
          "tdf-form-controlid": theControl.ControlData.Properties.Control_ID
        },
        value: theControl.GetValueAsText()
          ? theControl.GetValueAsText().split(", ")
          : [],
        onValueChanged: function (data) {
          var item = theControl.TDFItem;
          theControl.ControlData.SaveValue = data.value.join(", ");
          theControl.TDFItem.SetDirty(theControl.ControlData);
        },
        disabled: theControl.checkDisabled()
      })
      .dxTagBox("instance");
    theControl.jqElement = control.element();
    theControl.ControlData.Dx = control;
    return theControl;
  }

  /**
   *  Control_Type = 4
   */
  CheckBox() {
    let theControl = this;
    let val = !(
      theControl.ControlData.DataValue.ValueName === "0" ||
      theControl.ControlData.DataValue.ValueName === 0 ||
      theControl.ControlData.DataValue.ValueName === false ||
      (typeof theControl.ControlData.DataValue.ValueName === "string"
        ? theControl.ControlData.DataValue.ValueName.toLowerCase() === "false"
        : false) ||
      theControl.ControlData.DataValue.ValueName === ""
    );

    let control = $("<div />")
      .dxCheckBox({
        value: val,
        elementAttr: {
          style: "margin-left:5px;",
          id:
            theControl.ControlData.Properties.Control_ID +
            theControl.TDFItem.DomSafeID,
          "tdf-form-controlid": theControl.ControlData.Properties.Control_ID
        },
        disabled: theControl.checkDisabled(), //((theControl.ControlData.Properties.Locked === 1) ? true : false),//((theControl.ControlData.Properties.Locked === 1) ? true : false),
        onValueChanged: function onValueChanged(data) {
          let item = theControl.TDFItem;
          theControl.ControlData.SaveValue = data.value;
          theControl.TDFItem.SetDirty(theControl.ControlData);
        }
      })
      .dxCheckBox("instance");
    theControl.jqElement = control.element();
    theControl.ControlData.Dx = control;

    return theControl;
  }

  /**
   * Control_Type = 5
   * Just a regular text area i believe.
   */
  MemoBox() {
    let theControl = this;

    let control = $("<div/>")
      .dxTextArea({
        value: theControl.GetValueAsText(),
        height: theControl.ControlData.Properties.RowsHigh * 20,
        inputAttr: {
          id:
            theControl.ControlData.Properties.Control_ID +
            theControl.TDFItem.DomSafeID
        },
        /*Note the clear button on a text area is not a documented feature... Dx reccommends not using it so ttfn :)
       https://www.devexpress.com/Support/Center/Question/Details/T356980/dxtextarea-the-clear-button-overlaps-the-scrollbar
       */
        //showClearButton: true,
        disabled: theControl.checkDisabled(), //((theControl.ControlData.Properties.Locked === 1) ? true : false),
        onValueChanged: function (data) {
          let item = theControl.TDFItem;
          theControl.ControlData.SaveValue = data.value;
          theControl.TDFItem.SetDirty(theControl.ControlData);
        }
      })
      .dxTextArea("instance");
    theControl.jqElement = control.element();
    theControl.ControlData.Dx = control;
    return theControl;
  }

  /**
   * Control_Type =6
   */
  Date() {
    let theControl = this;
    // dxOverlay.baseZIndex(15000);

    try {
      theControl.ControlData.Properties.UDFFormat !== EnumUDFFormat.udfDate
        ? (theControl.ControlData.Properties.UDFFormat = EnumUDFFormat.udfDate)
        : "";
      let control = $("<div />")
        .dxDateBox({
          type: "date",
          pickerType: GetDevice().isDevice ? "native" : "calendar",
          inputAttr: {
            id:
              theControl.ControlData.Properties.Control_ID +
              theControl.TDFItem.DomSafeID,
            style: `background-color:${
              theControl.ControlData.Properties.BackColor
              }`
          },
          elementAttr: {
            "tdf-form-controlid": theControl.ControlData.Properties.Control_ID
          },
          disabled: theControl.checkDisabled(), //((theControl.ControlData.Properties.Locked === 1) ? true : false),
          value: theControl.GetFormattedValue("short"),
          onValueChanged: function (data) {
            if (data.value) {
              try {
                let value: Date =
                  typeof data.value === "string"
                    ? new Date(data.value)
                    : data.value;
                theControl.ControlData.SaveValue = Globalize.formatDate(value);
                theControl.TDFItem.SetDirty(theControl.ControlData);
              } catch (e) { }
            }
          }
        })
        .dxDateBox("instance");

      theControl.jqElement = control.element();
      theControl.ControlData.Dx = control;
    } catch (ex) {
      // debugger;
    }

    return theControl;
  }

  /**
   * Control_Type =7
   */
  TextBoxWithAction() {
    /*
    * An exmple of this controls's use is the TDFField = UniqueID
    *
    */
    let theControl = this;

    let control = $("<div />")
      .dxTextBox({
        value: theControl.GetFormattedValue(),
        inputAttr: {
          id:
            theControl.ControlData.Properties.Control_ID +
            theControl.TDFItem.DomSafeID
        },
        elementAttr: {
          "tdf-form-controlid": theControl.ControlData.Properties.Control_ID
        },
        disabled: theControl.checkDisabled(), //((theControl.ControlData.Properties.Locked === 1) ? true : false),
        showClearButton: true,
        onValueChanged: function (data) {
          let item = theControl.TDFItem;
          theControl.ControlData.SaveValue = data.value;
          theControl.TDFItem.SetDirty(theControl.ControlData);
        }
      })
      .dxTextBox("instance");

    theControl.jqElement = control.element();

    //theControl.jqElement.find(".dx-texteditor-buttons-container").append($("<div />").dxButton({
    //    icon: "add",
    //    text: "",
    //    onInitialized (data) {
    //        $(data.element).css({ "border-width": " 0 0 0 1px", "border-radius": "0px" });
    //    },
    //    onClick(e) {

    //    }
    //}));
    theControl.ControlData.Dx = control;
    return theControl;
  }

  /**
   * Control_Type = 8
   */
  TextBoxWithSpinner() {
    /*
    * A text box type number. Has Arrows to increment\decrement the value.
    *
    */
    let theControl = this;
    //default value?
    let control;

    if (theControl.ControlData.Properties.PropertyFormat === PropertyFormatNumberEnum.propComputer) {
      control = theControl.ComputerSpan();

    } else if (theControl.ControlData.Properties.PropertyFormat === PropertyFormatNumberEnum.propTimespan) {
      control = theControl.TimeSpan();

    } else {
      control = $("<div />")
        .dxNumberBox({
          value: theControl.GetFormattedValue(),
          inputAttr: {
            id:
              theControl.ControlData.Properties.Control_ID +
              theControl.TDFItem.DomSafeID
          },
          elementAttr: {
            "tdf-form-controlid": theControl.ControlData.Properties.Control_ID
          },
          disabled: theControl.checkDisabled(),
          showSpinButtons: true,
          showClearButton: true,
          onValueChanged: function (data) {
            let item = theControl.TDFItem;
            theControl.ControlData.SaveValue = data.value;
            theControl.TDFItem.SetDirty(theControl.ControlData);
          }
        })
        .dxNumberBox("instance");
    }

    theControl.jqElement = control.element();
    theControl.ControlData.Dx = control;
    return theControl;
  }

  /**
   * Control_Type = 9
   */
  Label() {
    let theControl = this;

    theControl.jqElement = $("<label />")
      .addClass("control-label")
      .attr({
        for:
          "tdfcontrol_" +
          theControl.ControlData.Properties.Control_ID +
          theControl.TDFItem.DomSafeID
      })
      .css({
        "background-color": theControl.ControlData.Properties.LabelBackColor,
        color: theControl.ControlData.Properties.LabelForeColor,
        "text-decoration": "underline"
      })
      .text(theControl.ControlData.Properties.LabelCaption);
    return theControl;
  }

  /**
   * Control_Type =10
   */
  DropDownListWithAction() {
    /*
    * An exmple of this controls's use is the TDFField = SalesStage
    *
    */
    let theControl = this;

    let currentSavedVal = {
      Value_ID: theControl.ControlData.DataValue.Value_ID,
      ValueName: theControl.ControlData.DataValue.ValueName
    };
    let pushCurrent: boolean = true;

    //If the saved value for the control is not an option, the option is added to the list until the value is changed, saved to something else, and refreshed
    if (
      currentSavedVal.Value_ID === "" &&
      currentSavedVal.ValueName &&
      theControl.ControlData.Properties.TDFField !== "CalculationMethod"
    ) {
      for (let i = 0; i < theControl.ControlData.PossibleValues.length; i++) {
        if (
          theControl.ControlData.PossibleValues[i].ValueName ===
          currentSavedVal.ValueName
        ) {
          pushCurrent = false;
        }
      }
      if (pushCurrent) {
        theControl.ControlData.PossibleValues.push(currentSavedVal);
      }
    }

    if (theControl.ControlData.Properties.TDFField === "SalesStage") {
      let possibleValues = theControl.ControlData.PossibleValues;
      let dataValue = theControl.ControlData.DataValue;

      let control = $("<div />")
        .dxSelectBox({
          dataSource: theControl.ControlData.PossibleValues,
          placeholder: "",
          displayExpr: "ValueName",
          valueExpr: "ValueName",
          showClearButton: true,
          inputAttr: {
            id:
              theControl.ControlData.Properties.Control_ID +
              theControl.TDFItem.DomSafeID
          },
          elementAttr: {
            "tdf-form-controlid": theControl.ControlData.Properties.Control_ID
          },
          disabled: theControl.checkDisabled(), //((theControl.ControlData.Properties.Locked === 1) ? true : false),
          value: theControl.GetFormattedValue(),
          onValueChanged: function (data) {
            let item = theControl.TDFItem;
            theControl.ControlData.SaveValue = data.value;
            theControl.TDFItem.SetDirty(theControl.ControlData);
              try {

                  // GUID => ControlGroupID
                  // { 
                  //   GUID: [{}, {}, {}],
                  //   GUID: [{}, {}, {}]
                  // }

                  $.each(theControl.TDFItem.Controls, (index, controlgroup) => { // index: guid, controlgroup: 
                
                        let matches = controlgroup.filter((v, i) => {
                            return v.Properties.TDFField === "SalesStageDate"
                        })

                        if (matches && matches.length > 0) {
                            matches[0].Dx.option(
                                "value",
                                Globalize.formatDate(new Date(), { date: "short" })
                            );
                        }
                 
                   
                  });
            } catch (ex) {
              console.warn(ex);
            }
          }
        })
        .dxSelectBox("instance");
      theControl.jqElement = control.element();
      theControl.ControlData.Dx = control;
      theControl.jqElement.on("dxclick", e => {
        if ($(e.target).hasClass("dx-icon-clock")) {
          setTimeout(() => {
            control.close();
          }, 0);
        }
      });
    } else {
      let control = $("<div />")
        .dxSelectBox({
          dataSource: theControl.ControlData.PossibleValues,
          placeholder: "",
          displayExpr: "ValueName",
          valueExpr: "ValueName",
          showClearButton: true,
          inputAttr: {
            id:
              theControl.ControlData.Properties.Control_ID +
              theControl.TDFItem.DomSafeID
          },
          elementAttr: {
            "tdf-form-controlid": theControl.ControlData.Properties.Control_ID
          },
          disabled: theControl.checkDisabled(), //((theControl.ControlData.Properties.Locked === 1) ? true : false),
          value: theControl.GetFormattedValue(),
          onValueChanged: function (data) {
            let item = theControl.TDFItem;
            theControl.ControlData.SaveValue = data.value;
            theControl.TDFItem.SetDirty(theControl.ControlData);
          }
        })
        .dxSelectBox("instance");
      theControl.jqElement = control.element();
      theControl.ControlData.Dx = control;
    }

    return theControl;
  }

  /**
   * Control_Type =11
   */
  HyperLink() {
    let theControl = this;
    //default value?
    let text = theControl.ControlData.DataValue.ValueName;
    let href = theControl.ControlData.DataValue.ValueName;
    if (
      theControl.ControlData &&
      theControl.ControlData.DataValue &&
      theControl.ControlData.DataValue.ValueName
    ) {
      if (
        (theControl.ControlData.DataValue.ValueName as string).search(/|/i) > -1
      ) {
        let vals = (theControl.ControlData.DataValue.ValueName as string).split(
          "|"
        );
        if (vals.length > 1) {
          text = vals[0];
          href = vals[1];
        }
      }
    }
    /*TODO: Highly custom not future proof not tested need to find a better way
     WARNING: Highly custom not future proof not tested need to find a better way
     NOTE: Highly custom not future proof not tested need to find a better way

     */
    if (
      theControl.ControlData.DataValue.ValueID &&
      theControl.ControlData.DataValue.ValueID.toString().length === 38
    ) {
      theControl.jqElement = $(`<a>${text}</a>`)
        .attr({
          id:
            theControl.ControlData.Properties.Control_ID +
            theControl.TDFItem.DomSafeID,
          //"href": href,
          "tdf-form-controlid": theControl.ControlData.Properties.Control_ID
        })
        .css({ "margin-left": "1rem", cursor: "pointer" })
        .on("click", () => {
          let tab = $(
            `#item-control-group-${DomSafeID(
              theControl.ControlData.DataValue.ValueID.toString()
            )}`
          );
          if (tab.length > 0) {
            tab.click();
          }
        });
    } else {
      theControl.jqElement = $(`<a>${text}</a>`)
        .attr({
          id:
            theControl.ControlData.Properties.Control_ID +
            theControl.TDFItem.DomSafeID,
          href: href,
          target: "_blank",
          "tdf-form-controlid": theControl.ControlData.Properties.Control_ID
        })
        .css("margin-left", "1rem");
    }
    return theControl;
  }

  /**
   * ControlType 14
   */
  AddressBox() {
    let theControl = this;
    let url: string = "";

    if (
      theControl.TDFItem.isNew &&
      theControl.TDFItem.PrepopulationData &&
      theControl.TDFItem.PrepopulationData.AddressData &&
      theControl.TDFItem.PrepopulationData.AddressData.AddressString
    ) {
      theControl.ControlData.DataValue.ValueName =
        theControl.TDFItem.PrepopulationData.AddressData.AddressString;
      if (!theControl.TDFItem.AddressDataList)
        theControl.TDFItem.AddressDataList = [];
      theControl.TDFItem.AddressDataList.push({
        itemid: theControl.TDFItem.ItemID,
        itemtype: theControl.TDFItem.ItemType,
        addresstype: 1,
        block: theControl.TDFItem.PrepopulationData.AddressData
      });
    }

    if (theControl.GetValueAsText()) {
      new TDFRequest({
        url:
          "/item/GetMapAddress/?" +
          $.param({
            itemid: theControl.TDFItem.ItemID,
            itemtype: theControl.TDFItem.ItemType
          }),
        type: "GET"
      })
        .MakeRequest()
        .done(function (response: any) {
          url = response;
        });
    }
    let getAddress = new TDFRequest({
      url:
        "/item/getaddress/?" +
        $.param({
          itemid: theControl.TDFItem.ItemID,
          itemtype: theControl.TDFItem.ItemType
        }),
      type: "GET"
    });
    let getStatesAndCountries = new TDFRequest({
      url: "/core/user/GetStatesAndCountries/"
    });

    theControl.jqElement = $("<div />")
      .dxTextBox({
        value: theControl.GetValueAsText(),
        inputAttr: {
          id:
            theControl.ControlData.Properties.Control_ID +
            theControl.TDFItem.DomSafeID
        },
        elementAttr: {
          "tdf-form-controlid": theControl.ControlData.Properties.Control_ID
        },
        disabled: theControl.checkDisabled(),
        onFocusIn: function onFocusIn(opts) {
          $.when(
            getAddress.MakeRequest(),
            getStatesAndCountries.MakeRequest()
          ).done(function (
            response: any,
            statesAndCountries: any
          ) {
            let formData = response;
            if (
              theControl.TDFItem.isNew &&
              theControl.TDFItem.PrepopulationData
            ) {
              if (theControl.TDFItem.PrepopulationData.AddressData) {
                $.each(formData.Business, (key, val) => {
                  formData.Business[key] =
                    theControl.TDFItem.PrepopulationData.AddressData[key];
                });
              }
            }
            let addressform = $("<form id='theform'/>")
              .dxForm({
                formData: response,
                readOnly: false,
                showColonAfterLabel: false,
                minColWidth: 100,
                colCount: "auto"
              })
              .dxForm("instance");

            opts.element.parent().replaceWith(addressform.element());

            let items = [
              {
                itemType: "tabbed",
                tabs: [
                  theControl.getAddressFormTab(
                    "Business",
                    addressform,
                    response.Business,
                    statesAndCountries
                  ),
                  theControl.getAddressFormTab(
                    "Home",
                    addressform,
                    response.Home,
                    statesAndCountries
                  ),
                  theControl.getAddressFormTab(
                    "Other",
                    addressform,
                    response.Other,
                    statesAndCountries
                  )
                ]
              }
            ];
            addressform.option("items", items);
          });
        }
      })
      .dxTextBox("instance")
      .element();

    theControl.jqElement.find(".dx-texteditor-buttons-container").append(
      $("<div />").dxButton({
        icon: "map",
        text: GetDevice().isDevice ? "" : "Map",
        onInitialized: function (data) {
          $(data.element).css({
            "border-width": " 0 0 0 1px",
            "border-radius": "0px"
          });
        },
        onClick: function (e) {
          window.open(url);
        }
      })
    );

    return theControl;
  }

  /**
   * Control_Type = 15
   */
  UDFFormula() {
    let theControl = this;

    //if (theControl.ControlData.Properties.UDFFormat) {
    //    if (theControl.ControlData.Properties.UDFFormat ===  EnumUDFFormat.udfCurrency) {
    //        theControl.ControlData.DataValue = theControl.ControlData.DataValue ? Globalize.formatCurrency(parseInt(theControl.ControlData.DataValue),CurrentUser.Currency) : Globalize.formatCurrency(0,CurrentUser.Currency)
    //    }
    //}

    let control = $("<div />")
      .dxTextBox({
        value: theControl.GetFormattedValue(),
        inputAttr: {
          id:
            theControl.ControlData.Properties.Control_ID +
            theControl.TDFItem.DomSafeID
        },
        elementAttr: {
          "tdf-form-controlid": theControl.ControlData.Properties.Control_ID
        },
        disabled: theControl.checkDisabled(), //((theControl.ControlData.Properties.Locked === 1) ? true : false),
        showClearButton: true,

        onValueChanged: function (data) {
          let item = theControl.TDFItem;
          theControl.ControlData.SaveValue = data.value;
          theControl.TDFItem.SetDirty(theControl.ControlData);
        }
      })
      .dxTextBox("instance");
    theControl.jqElement = control.element();
    theControl.ControlData.Dx = control;
    return theControl;
  }

  /**
   * Control_Type = 17
   */
  BSIFields() {
    /*
     *
     * @property
     */
    let theControl = this;
    let BIObj = {
      CompanyID: "",
      MasterAcctID: "",
      CustomerID: "",
      ShipToID: ""
    };

    if (theControl.ControlData.DataValue.ValueName) {
      BIObj = {
        CompanyID:
          theControl.ControlData.DataValue.ValueName["BSICompanyID"] || "",
        MasterAcctID:
          theControl.ControlData.DataValue.ValueName["BSIMasterAccountID"] ||
          "",
        CustomerID:
          theControl.ControlData.DataValue.ValueName["BSIAccountID"] || "",
        ShipToID:
          theControl.ControlData.DataValue.ValueName["BSIShipToID"] || ""
      };
    }
    let canEditBSIDs: boolean = DoesUserHaveRight(tdfRights.rightEditBSIIDs);
    let canEditMasterAccountIDs: boolean = DoesUserHaveRight(
      tdfRights.rightEditBSIMasterAccountId
    );
    let items = Object.keys(BIObj).map(k => {
      let item = {
        dataField: k,
        editorOptions: {
          readOnly:
            k === "MasterAcctID"
              ? !(canEditBSIDs || canEditMasterAccountIDs)
              : !canEditBSIDs
        }
      };
      return item;
    });

    let options: DevExpress.ui.dxFormOptions;
    let formItem: DevExpress.ui.dxFormSimpleItem;
    theControl.jqElement = $(
      "<form id='" +
      theControl.ControlData.Properties.Control_ID +
      theControl.TDFItem.DomSafeID +
      "' />"
    )
      .dxForm({
        formData: BIObj,
        items: items,
        //readOnly: // This is set at the item level because the value differs per field based on user rights.  !(Context.DoesUserHaveRight( tdfRights.rightEditBSIIDs) ||DoesUserHaveRight( tdfRights.rightEditBSIMasterAccountId)),
        showColonAfterLabel: false,
        labelLocation: "top",
        minColWidth: 100,
        colCount: 2,
        elementAttr: {
          "tdf-form-controlid": theControl.ControlData.Properties.Control_ID
        },
        onFieldDataChanged: function (data: any) {
          if (!theControl.ControlData.DataValue.ValueName) {
            theControl.ControlData.DataValue.ValueName = {
              CompanyID: "",
              MasterAcctID: "",
              CustomerID: "",
              ShipToID: ""
            };
          }

          // Note:  The set of 4 fields here are treated as one value.
          // So, when any one changes, I need to update the DataValue for the control, and then set the SaveValue to the DataValue.  This ensures that the SaveValue always has all 4 up to date values.
          if (data.dataField === "CompanyID") {
            theControl.ControlData.DataValue.ValueName["BSICompanyID"] =
              data.value;
          } else if (data.dataField === "MasterAcctID") {
            theControl.ControlData.DataValue.ValueName["BSIMasterAccountID"] =
              data.value;
          } else if (data.dataField === "CustomerID") {
            theControl.ControlData.DataValue.ValueName["BSIAccountID"] =
              data.value;
          } else if (data.dataField === "ShipToID") {
            theControl.ControlData.DataValue.ValueName["BSIShipToID"] =
              data.value;
          }

          theControl.ControlData.SaveValue = JSON.stringify(
            theControl.ControlData.DataValue.ValueName
          );
          theControl.TDFItem.SetDirty(theControl.ControlData);
        }
      })
      .dxForm("instance")
      .element();
    return theControl;
  }

  /**
   * Control_Type = 18
   */
  DateTime() {
    let theControl = this;
    theControl.ControlData.Properties.UDFFormat !== EnumUDFFormat.udfDate
      ? (theControl.ControlData.Properties.UDFFormat = EnumUDFFormat.udfDate)
      : "";
		let opts: DevExpress.ui.dxDateBoxOptions = {

				

			type: "datetime",

      pickerType: GetDevice().isDevice ? "native" : "calendar",
      showClearButton: true,
      displayFormat: {
        type: "shortDateShortTime"
      },
      disabled: theControl.checkDisabled(), //((theControl.ControlData.Properties.Locked === 1) ? true : false),
      inputAttr: {
        id:
          theControl.ControlData.Properties.Control_ID +
          theControl.TDFItem.DomSafeID
      },
      elementAttr: {
        "tdf-form-controlid": theControl.ControlData.Properties.Control_ID
      },
      onValueChanged: function (data) {
        theControl.ControlData.SaveValue = Globalize.formatDate(
          new Date(data.value),
          { datetime: "short" }
        );
        theControl.TDFItem.SetDirty(theControl.ControlData);
        if (
          theControl.TDFItem.ItemType === itemTypes.itemAppointment &&
          theControl.ControlData.Properties.TDFField === "StartTime"
        ) {
          Object.keys(theControl.TDFItem.Controls).forEach(key => {
            let end = theControl.TDFItem.Controls[key].filter(element => {
              element.Properties.TDFField === "EndTime";
            });
            if (end.length) {
              let endctrl: IControlData | any = end[0];
              endctrl.needsCheckConflict = true;
              endctrl.Dx.option("value", moment(data.value).add("hours", 1));
            }
          });

          // $.each(theControl.TDFItem.Controls, (k, v: IControlData[]) => {
          //   let end = $.grep(v, function (val) {
          //     return val.Properties.TDFField === "EndTime";
          //   });
          //   if (end.length) {
          //     let endctrl: IControlData | any = end[0];
          //     endctrl.needsCheckConflict = true;
          //     endctrl.Dx.option("value", moment(data.value).add("hours", 1))
          //   }

          // });
        }
      },
			onOpened: function (e) {
			},

      value: theControl.ControlData.DataValue.ValueName
        ? theControl.GetFormattedValue("short", true)
        : null
    };
    let control = $("<div />")
      .dxDateBox(opts)
      .dxDateBox("instance");
    theControl.jqElement = control.element();
    theControl.ControlData.Dx = control;
    return theControl;
  }

  /**
   *  Control_Type =19
   */
  Note(id) {
    let theControl = this;


    let control = $(`#${id}`).dxHtmlEditor(<DevExpress.ui.dxHtmlEditorOptions>{
      toolbar: {
        items: [
          {
            widget: "dxButton",
            options: <DevExpress.ui.dxButtonOptions>{
              icon: "clock",
              text: "",
              stylingMode: "text",
              onClick() {
                let insertion = 0;
                let name = ` ${CurrentUser.FullName} `;
                let timestamp = `${Globalize.formatDate(new Date(), { datetime: "short" })} `;
                let range = control.getSelection();
                if (range.length == 0) {
                  insertion = range.index;
                } else {
                  control.formatText(range.index, range.length, { strike: true });
                  insertion = control.getSelection();
                  control.setSelection(range.index + range.length, 1);
                  range = control.getSelection();
                  insertion = range.index;
                }
                control.insertText(insertion, name, { strike: false });
                control.insertText(insertion + name.length, timestamp, {
                  'bold': true,
                  'strike': false
                });
              }
            }
          },
          {
            widget: "dxButton",
            options: <DevExpress.ui.dxButtonOptions>{
              icon: "doc",
              text: "",
              stylingMode: "text",
              onClick() {
                let body = $("#tdfbodycontent");

                body.remove(`#templatepopup${theControl.TDFItem.DomSafeID}`);
                body.append(`<div id="templatepopup${theControl.TDFItem.DomSafeID}" />`);
                let popup = $(`#templatepopup${theControl.TDFItem.DomSafeID}`).dxPopup({
                  contentTemplate(el) {
                    el.append(`<div id="templategrid${theControl.TDFItem.DomSafeID}" />`);
                  }
                }).dxPopup("instance");
                popup.show();

                //TODO: Move this to a service class
                new TDFRequest({ url: `core/user/${theControl.TDFItem.ItemType}/listdrafts` }).MakeRequest().done(drafts => {
                  let grid = $(`#templategrid${theControl.TDFItem.DomSafeID}`).dxDataGrid({
                    dataSource: drafts,
                    columnAutoWidth: true,
                    selection: {
                      mode: "single"
                    },
                    //height: "100%",
                    width: "auto",
                    showRowLines: true,
                    showColumnHeaders: false,
                    rowAlternationEnabled: true,
                    showBorders: true,
                    loadPanel: {
                      enabled: false
                    },
                    groupPanel: {
                      visible: true
                    },
                    grouping: {
                      autoExpandAll: true,
                      allowCollapsing: true,
                      expandMode: "rowClick"
                    },
                    searchPanel: {
                      visible: true,
                      width: 250
                    },
                    scrolling: {
                      mode: 'standard',
                      showScrollbar: "always",
                    },
                    columns: [

                      {
                        dataField: "Title",
                        caption: "Template Title",
                        sortIndex: 0
                      },
                      {
                        dataField: "ItemSpecific",
                        visible: false
                      },
                      {
                        dataField: "Scope",
                        caption: "Template Type",
                        groupIndex: 0,
                        showWhenGrouped: false,
                        visible: false
                      },
                      {
                        dataField: "Template",
                        visible: false
                      },
                      {
                        dataField: "TemplateID",
                        visible: false
                      },

                      {
                        dataField: "Sort",
                        visible: false
                      },

                    ],
                    onContentReady(e) {
                      e.element.find(".dx-loadindicator-icon").addClass("hidden");
                    },
                    onSelectionChanged: function (selectedItems) {
                      var data = selectedItems.selectedRowsData[0];
                      var component = selectedItems.component;
                      if (data) {

                        new TDFRequest({ url: `core/user/${data.TemplateID}/${theControl.TDFItem.ItemID}/${theControl.TDFItem.ItemType}/getdraft` }).MakeRequest()
                          .done(function (response, xhr, status) {
                            control.option("value", response.Template);
                            component.clearSelection();
                            popup.hide();
                          })

                      }
                    }
                  }).dxDataGrid("instance");
                });

              }
            }

          },
          "separator",
          "undo",
          "redo",
          "clear",
          "separator",
          "bold",
          "color",
          "italic",
          "separator",
          "link",
          "image",
          "separator",
          "strike",
          "subscript",
          "superscript",
          "underline",
          "blockquote",
          "header",
          "separator",
          "increaseIndent",
          "decreaseIndent",
          "separator",
          "orderedList",
          "bulletList",
          "separator",
          "alignLeft",
          "alignCenter",
          "alignRight",
          "alignJustify",
          "separator",
          "background",
          "codeBlock",

        ]
      },
      value: theControl.ControlData.DataValue.ValueName,
      onValueChanged(e) {
        if (
          theControl.ControlData.DataValue !== encodeURIComponent(e.value) &&
          theControl.ControlData.DataValue.ValueName !== e.value
        ) {
          theControl.ControlData.SaveValue = encodeURIComponent(e.value);
          theControl.TDFItem.SetDirty(theControl.ControlData);
        }
      }
    }).dxHtmlEditor("instance")
    theControl.ControlData.Dx = control;
    theControl.jqElement = control.element();

    return theControl;
  }

  /**
   * Control_Type = 24
   */
  PhoneNumber() {
    /*
    *
    * @property
    */
    let theControl = this;

    // Possible extension labels
    let ext = ["x", "X", "ext", "EXT", ","];

    // Parse Phone Number
    let phoneObj = getPhoneObj();

    // Set up editor
    let frag = document.createDocumentFragment();
    let div = $("<div />").attr("id", "phone-control");
    let mask = setUpMask(phoneObj.phoneNumber, ext);
    $(frag).append(div);

    // Editor Options
    let opts: DevExpress.ui.dxTextBoxOptions = {
      mask: mask,
      maskRules: { X: /[0-9]|\-|\s/ },
      maskChar: " ",
      mode: "tel",
      value: theControl.ControlData.DataValue.ValueName.replace(
        /[^0-9\.]+/g,
        ""
      ),
      disabled: theControl.checkDisabled(),
      inputAttr: {
        id:
          theControl.ControlData.Properties.Control_ID +
          theControl.TDFItem.DomSafeID
      },
      elementAttr: {
        "tdf-form-controlid": theControl.ControlData.Properties.Control_ID
      },
      onFocusIn(e) {
        phonebtn.click();
      },
      onValueChanged: function (data) {
        theControl.ControlData.SaveValue = data.value;
        theControl.TDFItem.SetDirty(theControl.ControlData);
      }
    };

    let editor = $(div)
      .dxTextBox(opts)
      .dxTextBox("instance");

    let formOptions: DevExpress.ui.dxFormOptions = {
      formData: phoneObj
    };
    let items: DevExpress.ui.dxFormSimpleItem[] = [
      {
        dataField: "countryCode",
        label: {
          text: "Country Code"
        },
        editorOptions: {
          placeholder: "Type or Paste Numbers Only"
        },

        editorType: "dxTextBox"
      },
      {
        dataField: "standard",

        label: {
          text: "Phone Number"
        },
        editorOptions: {
          placeholder: "Type or Paste Numbers Only"
        },
        editorType: "dxTextBox"
      },
      {
        dataField: "extension",

        label: {
          text: "Extension"
        },
        editorOptions: {
          placeholder: "Type or Paste Numbers Only"
        },
        editorType: "dxTextBox"
      }
    ];
    formOptions.items = items;
    // let options:  DevExpress.ui.dxFormOptions;
    theControl.jqElement = editor.element();
    theControl.ControlData.Dx = editor;

    // Edit Btn
    let phonebtn = $("<div />").dxButton({
      icon: "edit",
      text: GetDevice().isDevice ? "" : "Edit",
      type: "success",
      disabled: theControl.checkDisabled(),
      height: "100%",
      elementAttr: { id: "btn_Phone_" + theControl.TDFItem.DomSafeID },
      onInitialized: function (data) {
        $(data.element).css({
          "border-width": " 0 0 0 1px",
          "border-radius": "0px",
          padding: 0
        });
      },
      onClick: function (data) {
        $("#phonepop")
          .dxPopup({
            width: "300px",
            height: "300px",
            title: "Edit Phone",
            dragEnabled: true,
            contentTemplate: function (contentElement) {
              contentElement.append(
                $("<div />")
                  .attr("id", "editphoneform")
                  .dxForm(formOptions)
              );
            },
            toolbarItems: [
              {
                location: "after",
                toolbar: "bottom",
                widget: "dxButton",
                options: {
                  text: "Update Field",
                  type: "success",
                  onClick: function (e) {
                    let form = $("#editphoneform").dxForm("instance");

                    // update form field
                    let phoneparts = form.option("formData");

                    phoneparts.standard = (<string>phoneparts.standard).replace(
                      /[-() ]/g,
                      ""
                    );

                    let newNum = phoneparts.standard;

                    if (
                      phoneparts.standard.length === 10 ||
                      phoneparts.standard.length === 0
                    ) {
                      if (phoneparts.standard.length === 10) {
                        if (phoneparts.extension != "") {
                          newNum = newNum + "," + phoneparts.extension;
                        }

                        if (phoneparts.countryCode != "") {
                          newNum = phoneparts.countryCode + newNum;
                        }
                      } else if (phoneparts.standard.length === 0) {
                        if (
                          phoneparts.countryCode.length !== 0 ||
                          phoneparts.extension.length !== 0
                        ) {
                          notify(
                            "Phone Number must be filled in order to save Country Code and/or Extension.",
                            "warning",
                            4e3
                          );
                          return;
                        }
                      }

                      editor.option("mask", setUpMask(newNum, ext));
                      editor.option("value", newNum);
                      theControl.ControlData.SaveValue = newNum;
                      theControl.TDFItem.SetDirty(theControl.ControlData);

                      // also, update href to dial button
                      $(dialbtn).attr("href", "tel:" + newNum);

                      // close the pop-up
                      $("#phonepop")
                        .dxPopup("instance")
                        .hide();
                    } else {
                      notify(
                        "Phone Number must be 10 characters.",
                        "warning",
                        4e3
                      );
                    }
                  }
                }
              },
              {
                location: "after",
                toolbar: "bottom",
                widget: "dxButton",
                options: {
                  text: "Cancel",
                  type: "normal",
                  onClick: function (e) {
                    $("#phonepop")
                      .dxPopup("instance")
                      .hide();
                  }
                }
              }
            ],
            onShown(e) {
              let form = $("#editphoneform").dxForm("instance");
              (form.getEditor("standard") as DevExpress.ui.dxTextBox).focus();
            }
          })
          .dxPopup("instance")
          .show();
      }
    });

    // If there is an extension, format it for dialer.
    let dialableNumber = getDialableNumber(
      theControl.ControlData.DataValue.ValueName
    );

    // The dial btn
    let dialbtn = $("<a />").dxButton({
      icon: "dx-icon dx-icon-tel",
      text: GetDevice().isDevice ? "" : "Dial",
      type: "normal",
      height: "100%",
      elementAttr: {
        href: "tel:" + dialableNumber,
        id: "dial-" + theControl.TDFItem.DomSafeID
      },
      onInitialized: function (data) {
        $(data.element).css({
          "border-width": " 0 0 0 1px",
          "border-radius": "0px",
          padding: 0
        });
      }
    });
    $(frag)
      .find(".dx-texteditor-buttons-container")
      .append(phonebtn)
      .append(dialbtn);
    $(div).append($("<div />").attr("id", "phonepop"));

    // Set up Phone Object, return the object
    function getPhoneObj() {
      let phoneObj = {
        phoneNumber: theControl.ControlData.DataValue.ValueName,
        unformmatted: theControl.ControlData.DataValue.ValueName.replace(
          /[^0-9\.]+/g,
          ""
        ),
        extension: "",
        countryCode: "",
        standard: ""
      };

      // look for extension in number
      phoneObj.extension = lookForExtension(phoneObj.phoneNumber, ext);

      if (phoneObj.extension) {
        phoneObj.phoneNumber = phoneObj.phoneNumber.slice(
          0,
          phoneObj.extension.length * -1
        );
      }

      phoneObj.phoneNumber = phoneObj.phoneNumber.replace(/[^0-9\.]+/g, "");

      // if no extension, pull standard digits
      phoneObj.standard = phoneObj.phoneNumber.substr(
        phoneObj.phoneNumber.length - 10
      );
      phoneObj.phoneNumber = phoneObj.phoneNumber.slice(0, -10);

      // look for any remaining numbers and add that to country code
      phoneObj.countryCode = phoneObj.phoneNumber;

      phoneObj.phoneNumber = phoneObj.standard;

      // Add extension if there is one
      if (phoneObj.extension != "") {
        phoneObj.phoneNumber =
          phoneObj.phoneNumber + "EXT " + phoneObj.extension;
      }

      // Add country code if there is one
      if (phoneObj.countryCode != "") {
        phoneObj.phoneNumber =
          "+" + phoneObj.countryCode + " " + phoneObj.phoneNumber;
      }

      return phoneObj;
    }

    // set up correct mask, return the mask
    function setUpMask(phoneNum, ext) {
      let mask = "(XXX) XXX-XXXX";

      // Look for extension
      if (lookForExtension(phoneNum, ext)) {
        mask = "(XXX) XXX-XXXX ext XXXXX";
      }

      if (phoneObj.countryCode != "") {
        let num = "+";
        for (let i = 0; i < phoneObj.countryCode.length; i++) {
          num = num + "X";
        }

        mask = num + " " + mask;
      }

      return mask;
    }

    // Look for an extension, return extension without label
    function lookForExtension(num, ext) {
      let extension = "";

      $.each(ext, function (index, val) {
        if (num.indexOf(val) > -1) {
          extension = num.substr(num.indexOf(val));
          extension = extension.replace(ext[index], "");
        }
      });

      return extension;
    }

    // Get phonenumber formatted for dail button.
    function getDialableNumber(dialableNumber) {
      dialableNumber = dialableNumber.replace("EXT", ",");
      dialableNumber = dialableNumber.replace("ext", ",");
      dialableNumber = dialableNumber.replace("X", ",");
      dialableNumber = dialableNumber.replace("x", ",");
      dialableNumber = dialableNumber.replace(/-/g, "");
      dialableNumber = dialableNumber.replace(/ /g, "");
      return dialableNumber;
    }

    return theControl;
  }

  /**
   * Control_Type = 25
   */
  FullName() {
    /*
    *
    * @property
    */
    let theControl = this;
    let frag = document.createDocumentFragment();
    let div = $("<div />").attr("id", "name-control");
    $(frag).append(div);
    let opts: DevExpress.ui.dxTextBoxOptions = {
      value: theControl.GetValueAsText(),
      disabled: theControl.checkDisabled(), //((theControl.ControlData.Properties.Locked === 1) ? true : false),,
      inputAttr: {
        id:
          theControl.ControlData.Properties.Control_ID +
          theControl.TDFItem.DomSafeID
      },
      elementAttr: {
        "tdf-form-controlid": theControl.ControlData.Properties.Control_ID
      },
      onFocusIn(e) {
        namebtn.click();
      }
    };
    let editor = $(div)
      .dxTextBox(opts)
      .dxTextBox("instance");

    let formOptions: DevExpress.ui.dxFormOptions = {
      formData: {}
    };

    let items: DevExpress.ui.dxFormSimpleItem[] = [
      {
        dataField: "Title",

        label: {
          text: "Title"
        },
        editorType: "dxSelectBox",
        editorOptions: {
          dataSource: ["Sir", "Mr", "Dr", "Ms", "Mrs", "Madam", "Mx", "Miss"],
          acceptCustomValue: true
        }
      },
      {
        dataField: "FirstName",
        label: {
          text: "First Name"
        },
        editorType: "dxTextBox"
      },
      {
        dataField: "MiddleName",

        label: {
          text: "Middle Name"
        },
        editorType: "dxTextBox"
      },
      {
        dataField: "LastName",

        label: {
          text: "Last Name"
        },
        editorType: "dxTextBox"
      },
      {
        dataField: "Suffix",

        label: {
          text: "Suffix"
        },
        editorType: "dxSelectBox",
        editorOptions: {
          dataSource: ["I", "II", "III", "Jr.", "Sr."],
          acceptCustomValue: true
        }
      }
    ];
    formOptions.items = items;
    //  $().dxForm(formOptions);
    // let options:  DevExpress.ui.dxFormOptions;
    theControl.jqElement = editor.element();
    theControl.ControlData.Dx = editor;

    let namebtn = $("<div />").dxButton({
      icon: "edit",
      text: GetDevice().isDevice ? "" : "Edit",
      type: "success",
      disabled: theControl.checkDisabled(), //((theControl.ControlData.Properties.Locked === 1) ? true : false),
      height: "100%",
      elementAttr: { id: "btn_Name_" + theControl.TDFItem.DomSafeID },
      onInitialized: function (data) {
        $(data.element).css({
          "border-width": " 0 0 0 1px",
          "border-radius": "0px",
          padding: 0
        });
      },
      onClick: function (data) {

        let req = new TDFRequest({
          url: TDFRequest.ApiPath + "/core/user/getname/",
          type: "GET",
          data: {
            id: theControl.TDFItem.ItemID,
            type: theControl.TDFItem.ItemType
          }
        });

        req.MakeRequest().
          done(function (response) {
            formOptions.formData = response;
            if (theControl.TDFItem.isNew && (<any>theControl.TDFItem).NameParts) {
              formOptions.formData = (<any>theControl.TDFItem).NameParts;
            }
            $("#namepop")
              .dxPopup({
                width: "300px",
                height: "auto",
                title: "Edit Name",
                dragEnabled: true,
                contentTemplate: function (contentElement) {
                  contentElement.append(
                    $("<div />")
                      .attr("id", "editnameform")
                      .dxForm(formOptions)
                  );
                },
                toolbarItems: [
                  {
                    location: "after",
                    toolbar: "bottom",
                    widget: "dxButton",
                    options: {
                      text: "Save",
                      icon: "save",
                      type: "success",
                      onClick: function (e) {
                        let form = $("#editnameform").dxForm("instance");
                        if (!theControl.TDFItem.isNew) {
                          let request = new TDFRequest({
                            url: "/item/SaveFullName/",
                            type: "POST",
                            data: {
                              nameparts: form.option("formData"),
                              itemid: theControl.TDFItem.ItemID,
                              itemtype: theControl.TDFItem.ItemType
                            }
                          });
                          request.MakeRequest().done(function (response) {
                            let t = response;
                            //  $("#name-control").dxTextBox('instance').option("value", response["TDF_Subject"]);
                            editor.option("value", response["TDF_Subject"]);
                            theControl.ControlData.SaveValue =
                              response["TDF_Subject"];
                            theControl.TDFItem.SetDirty(theControl.ControlData);

                            $("#namepop")
                              .dxPopup("instance")
                              .hide();
                          });
                        } else {
                          let nameparts = form.option("formData");
                          let name =
                            nameparts.Title +
                            " " +
                            nameparts.FirstName +
                            " " +
                            nameparts.MiddleName +
                            " " +
                            nameparts.LastName +
                            " " +
                            nameparts.Suffix;
                          editor.option("value", name);
                          // $("#name-control").dxTextBox('instance').option("value", name);
                          if (
                            theControl.TDFItem.ItemType === itemTypes.itemLead ||
                            theControl.TDFItem.ItemType === itemTypes.itemContact
                          ) {
                            (<any>theControl.TDFItem).NameParts = form.option(
                              "formData"
                            );
                            theControl.ControlData.SaveValue = name;
                            theControl.TDFItem.SetDirty(theControl.ControlData);
                          }
                          $("#namepop")
                            .dxPopup("instance")
                            .hide();
                        }
                      }
                    }
                  }
                ]
              })
              .dxPopup("instance")
              .show();
          });
      }
    });

    $(frag)
      .find(".dx-texteditor-buttons-container")
      .append(namebtn);
    $(div).append($("<div />").attr("id", "namepop"));

    return theControl;
  }

  /**
   * Control_Type = 26
   */
  HyperLinkEdit() {
    /*
     * @property
     */
    let theControl = this;
    let frag = document.createDocumentFragment();
    let div = document.createElement("div");
    $(div).attr("id", "hLinkEdit");
    frag.appendChild(div);

    let control = $(div)
      .dxTextBox({
        showClearButton: true,
        value: theControl.GetValueAsText(),
        inputAttr: {
          id:
            theControl.ControlData.Properties.Control_ID +
            theControl.TDFItem.DomSafeID
        },
        elementAttr: {
          "tdf-form-controlid": theControl.ControlData.Properties.Control_ID
        },
        disabled: theControl.checkDisabled(), //((theControl.ControlData.Properties.Locked === 1) ? true : false),
        onValueChanged: function (data) {
          /*Set Dirty and add value to save list*/
          theControl.ControlData.SaveValue = data.value;
          theControl.TDFItem.SetDirty(theControl.ControlData);
        }
      })
      .dxTextBox("instance");
    theControl.jqElement = control.element();
    theControl.ControlData.Dx = control;

    $(frag)
      .find(".dx-texteditor-buttons-container")
      .append(
        $("<div />").dxButton({
          icon: "globe",
          text: GetDevice().isDevice ? "" : "Go",
          height: "100%",
          //  attr: { "style": "border:none" },
          onInitialized: function (data) {
            $(data.element).css({
              "border-width": " 0 0 0 1px",
              "border-radius": "0px"
            });
          },
          onClick: function (data) {
            let url =
              theControl.ControlData.DataValue &&
                theControl.ControlData.DataValue.ValueName.indexOf("http") < 0
                ? "http://" + theControl.ControlData.DataValue.ValueName
                : theControl.ControlData.DataValue.ValueName;
            if (url) {
              window.open(url, "_blank");
            }
          }
        })
      );

    // theControl.jqElement = frag;
    return theControl;
  }

  /**
   * Control_Type = 27
   */
  Checklist() {
    /*
     ** Like a text area with checkboxes a textbox a save and a delete button... adding a value in the textbox adds a checkbox to the text area ... checking the checkbox adds adds a completion date and saving it adds the value to [Data_Checklist_**TypeNameHere**]
    * The datavalue that comes back with this control will be an xml document
    * @property
    */
    let theControl = this;
    //let opt: DevExpress.ui.dxDataGridOptions= {
    //    editing: { editEnabled: true, allowAdding: true, allowDeleting: true },
    //    selection: { mode: "multiple", showCheckBoxesMode: "always" },
    //    showBorders: false,
    //    showColumnHeaders: false,
    //    showColumnLines: false,
    //    showRowLines: false,
    //    onSelectionChanged: function (e) {
    //        e.selectedRowsData["Checked"] = e.selectedRowsData["Checked"] === 1 ? 0 : 1;
    //        if (e.selectedRowsData["Checked"] === 1) {

    //        }
    //    },
    //    columns: [{ dataField: "Value_ID", visible: false }, { dataField: "ListItemName" }]

    //};

    let control = $(
      "<div id='" +
      theControl.ControlData.Properties.Control_ID +
      theControl.TDFItem.DomSafeID +
      "' />"
    )
      .dxDataGrid(<DevExpress.ui.dxDataGridOptions>{
        dataSource: theControl.ControlData.DataValue.ValueName,
        editing: { allowAdding: true, allowDeleting: true },

        selection: { mode: "multiple", showCheckBoxesMode: "always" },
        showBorders: true,
        showColumnHeaders: false,
        showColumnLines: false,
        showRowLines: false,
        elementAttr: {
          "tdf-form-controlid": theControl.ControlData.Properties.Control_ID
        },
        height: theControl.ControlData.Properties.RowsHigh * 20,
        columns: [
          { dataField: "Checked", visible: false },
          { dataField: "ListItemName" }
        ],
        disabled: theControl.checkDisabled() //((theControl.ControlData.Properties.Locked === 1) ? true : false),
      })
      .dxDataGrid("instance");

    theControl.jqElement = control.element();

    return theControl;
  }

  /**
   * Control_Type = 28
   */
  ContactSelector() {
    /*
    * Need to find out if i will use a contact id here or the name
    * @property
    */
    let theControl = this;
    let LinkedItemCounterResult: ILinkedItemCounterResult = {
      Result: 0,
      ChildFilter: "",
      ChildType: 0,
      ControlID: ""
    };

    let opts: DevExpress.ui.dxTextBoxOptions = {
      value: "0",
      elementAttr: {
        "tdf-form-controlid": theControl.ControlData.Properties.Control_ID
      }
    };
    let control = $("<idv />")
      .dxTextBox(opts)
      .dxTextBox("instance");
    theControl.jqElement = control.element();
    theControl.ControlData.Dx = control;

    if (theControl.ControlData.DataValue.ValueName) {
      new TDFRequest({
        url:
          "/item/GetContactSelectorValue/?" +
          $.param({ contactid: theControl.ControlData.DataValue.ValueName }),
        type: "GET"
      })
        .MakeRequest()
        .done(function (response: string) {
          control.option("value", response);
        });
    }
    theControl.jqElement.find(".dx-texteditor-buttons-container").append(
      $("<div />").dxButton({
        icon: "overflow",
        text: "",
        elementAttr: {
          id:
            theControl.ControlData.Properties.Control_ID +
            theControl.TDFItem.DomSafeID
        },
        onInitialized: function (data) {
          $(data.element).css({
            "border-width": " 0 0 0 1px",
            "border-radius": "0px"
          });
          $(".dx-icon-overflow").addClass("flip90");
        },
        onClick: function (e) {
          if (!$("#viewsdialog").length) {
            $("<div id='viewcontacts' />").appendTo("#tdfbody");
          }
          let opts: DevExpress.ui.dxPopupOptions = {
            contentTemplate: function (element: JQuery) {
              element
                .append(
                  "<div id='viewcontacts" +
                  theControl.TDFItem.DomSafeID +
                  "' />"
                )
                .append(
                  "<div id='viewcontactsitems" +
                  theControl.TDFItem.DomSafeID +
                  "' />"
                );
            },
            resizeEnabled: true
          };
          let ViewItemsDialog = $("#viewcontacts")
            .dxPopup(opts)
            .dxPopup("instance");



          new TDFRequest({
              url: "/core/user/ContactList/?" + $.param({itemid: theControl.TDFItem.ItemID, itemtype: theControl.TDFItem.ItemType}),
            type: "GET"
          })
            .MakeRequest()
            .done(function (response) {
              let o: DevExpress.ui.dxDataGridOptions = {
                dataSource: response,
                columns: [
                  { dataField: "Name", caption: "Name" },
                  { dataField: "ID", visible: false },
                  { dataField: "Email", caption: "Email" },
                  { dataField: "JobTitle", caption: "Job Title" },
                  { dataField: "Department", caption: "Department" },
                  { dataField: "Source", caption: "Source" }
                ],
                selection: { mode: "single", showCheckBoxesMode: "always" },
                onSelectionChanged: function (e) {
                  ViewItemsDialog.hide();
                  theControl.ControlData.SaveValue = e.selectedRowsData[0].ID;
                  control.option("value", e.selectedRowsData[0].Name);
                  theControl.TDFItem.SetDirty(theControl.ControlData);
                }
              };
              $("#viewcontactsitems" + theControl.TDFItem.DomSafeID).dxDataGrid(
                o
              );
            });
          ViewItemsDialog.show().done(function () { });
        }
      })
    );

    return theControl;
  }

  /**
   * Control_Type = 29
   */
  CalcEdit() {
    /*
    * An Example of this control is BillAble Amount in core this shows a calulator for this field... maybe implement that later until then just a textbox
    * @property
    */

    let theControl = this;

    //if (theControl.ControlData.Properties.UDFFormat) {
    //    if (theControl.ControlData.Properties.UDFFormat ===  EnumUDFFormat.udfCurrency) {
    //        theControl.ControlData.DataValue = theControl.ControlData.DataValue.ValueName ? Globalize.formatCurrency(parseInt(theControl.ControlData.DataValue.ValueName),CurrentUser.Currency) : Globalize.formatCurrency(0,CurrentUser.Currency)
    //    }
    //}
    let control = $("<div />")
      .dxTextBox({
        value: theControl.GetFormattedValue(),
        inputAttr: {
          id:
            theControl.ControlData.Properties.Control_ID +
            theControl.TDFItem.DomSafeID
        },
        elementAttr: {
          "tdf-form-controlid": theControl.ControlData.Properties.Control_ID
        },
        disabled: theControl.checkDisabled(), //((theControl.ControlData.Properties.Locked === 1) ? true : false),
        onValueChanged: function (data) {
          theControl.ControlData.SaveValue = data.value;
          theControl.TDFItem.SetDirty(theControl.ControlData);
        }
      })
      .dxTextBox("instance");
    theControl.jqElement = control.element();
    theControl.ControlData.Dx = control;
    let calculator: Calculator;

    theControl.jqElement.find(".dx-texteditor-buttons-container").append(
      $("<div />").dxButton({
        icon: "spindown",
        text: "",
        onInitialized: function (data) {
          $(data.element).css({
            "border-width": " 0 0 0 1px",
            "border-radius": "0px"
          });
        },
        onClick: function (e) {
          $("#tdfbody").append($("<div id='calcpop'/>"));
          $("#calcpop")
            .dxPopover({
              target:
                "#" +
                theControl.ControlData.Properties.Control_ID +
                theControl.TDFItem.DomSafeID,
              contentTemplate: function (el) {
                el.attr("id", "calcit");
                calculator = new Calculator("calcit");
              },
              toolbarItems: [
                {
                  toolbar: "bottom",
                  widget: "dxButton",
                  options: {
                    text: "OK",
                    onClick: function (e) {
                      let val = $("#screen").text();
                      let theVal = Globalize.formatCurrency(
                        parseInt(val),
                        CurrentUser.Currency
                      );
                      theControl.ControlData.SaveValue = theVal;
                      control.option("value", theVal);
                      $("#calcpop")
                        .dxPopover("instance")
                        .hide();
                    }
                  }
                }
              ]
            })
            .dxPopover("instance")
            .show();
        }
      })
    );

    return theControl;
  }

  /**
   * Control_Type = 30
   */
  StopWatch() {
    /*
    * Literally a timer control ... implement functionality later ..using textbox with a button
    * @property
    */
    let theControl = this;
    let timer;
    if (!theControl.ControlData.DataValue.ValueName) {
      theControl.ControlData.DataValue.ValueName = "00:00:00";
    }

    let control = $("<div />")
      .dxTextBox({
        value: theControl.ControlData.DataValue.ValueName,
        inputAttr: {
          id:
            theControl.ControlData.Properties.Control_ID +
            theControl.TDFItem.DomSafeID
        },
        elementAttr: {
          "tdf-form-controlid": theControl.ControlData.Properties.Control_ID
        },
        // focusStateEnabled: false,

        onFocusIn: e => {
          (e.component as DevExpress.ui.dxTextBox).blur();
          e.element
            .find(".dx-texteditor-buttons-container")
            .find(".dx-button.dx-widget")
            .children()[0]
            .click();
        }
      })
      .dxTextBox("instance");
    theControl.jqElement = control.element();
    theControl.ControlData.Dx = control;

    theControl.jqElement.find(".dx-texteditor-buttons-container").append(
      $("<div />").dxButton(<DevExpress.ui.dxButtonOptions>{
        icon: "clock",
        text: "Start",
        // type: "warning",
        height: "100%",
        disabled: theControl.checkDisabled(), //((theControl.ControlData.Properties.Locked === 1) ? true : false),
        onInitialized: function (data) {
          $(data.element).css({
            "border-width": " 0 0 0 1px",
            "border-radius": "0px",
            padding: 0
          });
        },
        onClick: function (e) {
          let time = control.option("value").split(":");
          let startTimeStamp;
          let stopTimeStamp;
          let orighours = parseInt(time[0]);
          let origminutes = parseInt(time[1]);
          let origseconds = parseInt(time[2]);
          function pad(number, length) {
            let str = "" + number;
            while (str.length < length) {
              str = "0" + str;
            }
            return str;
          }
          switch (e.element.text()) {
            case "Start":
              startTimeStamp = Date.now();

              timer = window.setInterval(function () {
                let delta = Math.abs(startTimeStamp - Date.now()) / 1000;

                delta +=
                  (orighours * 3.6e6 +
                    origminutes * 60000 +
                    origseconds * 1000) /
                  1000;

                let days = Math.floor(delta / 86400);
                delta -= days * 86400;

                let hours = Math.floor(delta / 3600) % 24;
                delta -= hours * 3600;

                let minutes = Math.floor(delta / 60) % 60;
                delta -= minutes * 60;

                let seconds = Math.floor(delta % 60);

                control.option(
                  "value",
                  pad(hours, 2) + ":" + pad(minutes, 2) + ":" + pad(seconds, 2)
                );
              }, 1000);

              e.component.option("text", "Stop");

              break;
            case "Stop":
              e.component.option("text", "Start");
              window.clearInterval(timer);
              theControl.ControlData.SaveValue = control.option("value");
              theControl.TDFItem.SetDirty(theControl.ControlData);
              break;
          }
        }
      })
    );

    return theControl;
  }

  /**
   * Control_Type = 31
   */
  LookUpEdit() {
    /*

    *  @property
    */
    let theControl = this;

    let control = $("<div />")
      .dxLookup({
        dataSource: theControl.ControlData.PossibleValues,
        placeholder: "",
        value: theControl.ControlData.DataValue.ValueName,
        showClearButton: true,
        valueExpr: theControl.ControlData.DataValue.Value_ID
          ? "Value_ID"
          : "ValueName",
        displayExpr: "ValueName",
        inputAttr: {
          id:
            theControl.ControlData.Properties.Control_ID +
            theControl.TDFItem.DomSafeID
        },
        elementAttr: {
          "tdf-form-controlid": theControl.ControlData.Properties.Control_ID
        },
        disabled: theControl.checkDisabled(), //((theControl.ControlData.Properties.Locked === 1) ? true : false),
        onValueChanged: function (data) {
          let item = theControl.TDFItem;
          theControl.ControlData.SaveValue = data.value;
          theControl.TDFItem.SetDirty(theControl.ControlData);
        }
      })
      .dxLookup("instance");
    theControl.jqElement = control.element();
    theControl.ControlData.Dx = control;

    return theControl;
  }

  /**
   * Control_Type = 32
   */
  ListBoxWithEdit() {
    /*
    * Text area with checkboxes ... and a button to add values... values are populates from the contols possible values
    *control example is Custom Groups on contact form on SalesDemo
    * @property
    */
    let theControl = this;

    let vals = [];
    $.each(theControl.ControlData.DataValue.ValueName.split(","), function (
      k,
      v
    ) {
      if (v) vals.push(v.trim());
    });
    let control = $("<div />")
      .dxTagBox({
        dataSource: theControl.ControlData.PossibleValues,
        displayExpr: "ValueName",
        valueExpr: "ValueName",
        inputAttr: {
          id:
            theControl.ControlData.Properties.Control_ID +
            theControl.TDFItem.DomSafeID
        },
        elementAttr: {
          "tdf-form-controlid": theControl.ControlData.Properties.Control_ID
        },
        value: vals,
        onValueChanged: function (e) {
          theControl.ControlData.SaveValue = e.value.join(",");
          theControl.TDFItem.SetDirty(theControl.ControlData);
        }
      })
      .dxTagBox("instance");
    $("#tdfbody").append($("<div id='udfadd'/>"));
    theControl.jqElement = control.element();
    theControl.ControlData.Dx = control;
    let pop = $("#udfadd")
      .dxPopup({
        width: $(window).innerWidth() > 500 ? 500 : $(window).innerWidth(),
        height: 520
      })
      .dxPopup("instance");

    theControl.jqElement.find(".dx-texteditor-buttons-container").append(
      $("<div />").dxButton({
        icon: "add",
        text: "",
        onInitialized: function (data) {
          $(data.element).css({
            "border-width": " 0 0 0 1px",
            "border-radius": "0px"
          });
        },
        onClick: function (e) {
          new TDFRequest({
            url:
              "/item/GetValuesForUDF/?" +
              $.param({
                ControlGroupID:
                  theControl.ControlData.Properties.ControlGroup_ID,
                UDF_ID: theControl.ControlData.Properties.UDF_ID,
                ItemId: theControl.TDFItem.ItemID,
                ItemType: theControl.TDFItem.ItemType,
                AcctRootRestrict: theControl.ControlData.Properties.AcctRoot,
                ItemFormTypeRestrict:
                  theControl.ControlData.Properties.ItemFormType
              }),
            type: "GET"
          })
            .MakeRequest()
            .done(function (response: any) {
              if (true) {
                let opts: DevExpress.ui.dxDataGridOptions = {
                  editing: { allowAdding: true },
                  dataSource: response,
                  //selection: { mode: "single", showCheckBoxesMode: "none" },
                  columnAutoWidth: true,
                  height: 500,
                  pager: { visible: false },
                  paging: { enabled: false },
                  columns: [
                    { dataField: "ValueName" },
                    { dataField: "Value_ID", visible: false }
                  ],
                  onRowInserting: function (e) {
                    let newVal: any = e.data;
                    new TDFRequest({
                      url: "/item/AddUDFValue/",
                      data: {
                        ControlGroupID:
                          theControl.ControlData.Properties.ControlGroup_ID,
                        ValueName: newVal.ValueName,
                        UDF_ID: theControl.ControlData.Properties.UDF_ID,
                        ItemId: theControl.TDFItem.ItemID,
                        ItemType: theControl.TDFItem.ItemType,
                        AcctRootRestrict:
                          theControl.ControlData.Properties.AcctRoot,
                        ItemFormTypeRestrict:
                          theControl.ControlData.Properties.ItemFormType
                      },
                      type: "POST"
                    })
                      .MakeRequest()
                      .done(function (response: any) {
                        if (true) {
                          new Notification({
                            message: "Value Added",
                            type: "success"
                          });
                          pop.hide();
                          theControl.TDFItem.Refresh();
                        } else {
                          new Notification({
                            message:
                              response.Message || "Error adding the value.",
                            type: "error"
                          });
                        }
                      });
                  }
                };
                pop.option("contentTemplate", function (element) {
                  element.append($("<div id='udfaddgrid' />").dxDataGrid(opts));
                });
                pop.show().done(function () {
                  $("#udfadd").dxScrollView({ height: 500 });
                });
              } else {
              }
            });
        }
      })
    );

    return theControl;
  }

  /**
   * Control_Type = 34
   */
  AccountUDF() {
    let theControl = this;
    /*This is always a read only text box */
    let vals = [];
    $.each(theControl.ControlData.PossibleValues, function (key, val) {
      vals.push(val.ValueName);
    });

    let control = $("<div />")
      .dxTextBox({
        placeholder: "",
        value: theControl.GetValueAsText(),
        inputAttr: {
          id:
            theControl.ControlData.Properties.Control_ID +
            theControl.TDFItem.DomSafeID
        },
        elementAttr: {
          "tdf-form-controlid": theControl.ControlData.Properties.Control_ID
        },
        disabled: true
      })
      .dxTextBox("instance");
    theControl.jqElement = control.element();
    theControl.ControlData.Dx = control;
    return theControl;
  }

  /**
   * Control_Type = 35
   */
  NoteList() {
    /*
    *  Support Notes
    * @property
    */
    let theControl = this;

    return theControl;
  }

  /**
   * Control_Type = 36
   */
  TDFUserSelector() {
    /*
    * Single selection user list where  the datavalue will be the tdf searchkey
    * @property
    */
    let theControl = this;

    let myStore = new dxDataSource({
      load: function (loadOptions) {
        // TODO: This request now goes to the API and requires a JWT be added to the header.
        return $.get(TDFRequest.ApiPath + "/core/user/userlist/");
      },
      byKey: function (key) {
        return $.get(TDFRequest.ApiPath + "/core/user/gettdfuser/" + key);
      }
    });

    let selopts: DevExpress.ui.dxSelectBoxOptions = {
      dataSource: myStore,
      placeholder: "",
      displayExpr: "Name",
      valueExpr: "Userid",
      value: theControl.ControlData.DataValue.ValueName,
      showClearButton: true,
      inputAttr: {
        id:
          theControl.ControlData.Properties.Control_ID +
          theControl.TDFItem.DomSafeID
      },
      elementAttr: {
        "tdf-form-controlid": theControl.ControlData.Properties.Control_ID
      },
      disabled: theControl.checkDisabled(), //((theControl.ControlData.Properties.Locked === 1) ? true : false),
      onValueChanged: function (data) {
        theControl.ControlData.SaveValue = data.value;
        theControl.TDFItem.SetDirty(theControl.ControlData);
      }
    };

    let control = $("<div />")
      .dxSelectBox(selopts)
      .dxSelectBox("instance");
    theControl.jqElement = control.element();
    theControl.ControlData.Dx = control;

    return theControl;
  }

  /**
   * Control_Type = 37
   */
  GridLookUpEdit() {
    /*
    *no example found yet
    * @property
    */
    let theControl = this;

    return theControl;
  }

  /**
   * Control_Type = 38
   */
  TDFUserList() {
    /*
    *
    * @property
    */
    let theControl = this;
    let selected = [];
    //TODO: Save the selection????????????
    let vals = (<string>theControl.ControlData.DataValue.ValueName).split(",");
    $.each(theControl.ControlData.PossibleValues, function (k, v) {
      if ($.inArray(v.Value_ID, vals) > -1) {
        selected.push(v);
      }
    });

    //let opt: DevExpress.ui.dxDataGridOptions= {
    //    dataSource: theControl.ControlData.PossibleValues,
    //    // editing: { editEnabled: true, allowAdding: true, allowDeleting: true },
    //    selection: { mode: "multiple", showCheckBoxesMode: "always" },
    //    showBorders: true,
    //    paging: { enabled: false },
    //    searchPanel: { visible: true, highlightSearchText: true },
    //    showColumnHeaders: false,
    //    showColumnLines: false,
    //    showRowLines: false,
    //    elementAttr: { "tdf-form-controlid": theControl.ControlData.Properties.Control_ID },
    //    selectedRowKeys: selected,
    //    height: theControl.ControlData.Properties.RowsHigh * 50,
    //    columns: [{ dataField: "Value_ID", visible: false }, { dataField: "ValueName" }],
    //    disabled: theControl.ControlData.Properties.Locked === 1,
    //    onSelectionChanged(data) {
    //        if (data.selectedRowsData.length) {
    //            let users = [];
    //            $.each(data.selectedRowKeys, function (k, v) {
    //                users[k] = v.Value_ID;
    //            });

    //            theControl.ControlData.SaveValue = users.join(",");
    //            theControl.TDFItem.SetDirty(theControl.ControlData);
    //        }
    //    }
    //};

    let opts: DevExpress.ui.dxDropDownBoxOptions = {
      dataSource: theControl.ControlData.PossibleValues,
      value: theControl.ControlData.DataValue.ValueName.split(","),
      displayExpr: "ValueName",
      valueExpr: "Value_ID",
      contentTemplate(e) {
        let opt: DevExpress.ui.dxDataGridOptions = {
          dataSource: theControl.ControlData.PossibleValues,
          // editing: { editEnabled: true, allowAdding: true, allowDeleting: true },
          selection: { mode: "multiple", showCheckBoxesMode: "always" },
          showBorders: true,
          paging: { enabled: false },
          searchPanel: { visible: true, highlightSearchText: true },
          showColumnHeaders: false,
          showColumnLines: false,
          showRowLines: false,
          elementAttr: {
            "tdf-form-controlid": theControl.ControlData.Properties.Control_ID
          },
          selectedRowKeys: selected,
          height: theControl.ControlData.Properties.RowsHigh * 50,
          columns: [
            { dataField: "Value_ID", visible: false },
            { dataField: "ValueName" }
          ],
          disabled: theControl.ControlData.Properties.Locked === 1,
          onSelectionChanged(data) {
            if (data.selectedRowsData.length) {
              let users = [];
              $.each(data.selectedRowKeys, function (k, v) {
                users[k] = v.Value_ID;
              });
              theControl.ControlData.SaveValue = users.join(",");
              theControl.TDFItem.SetDirty(theControl.ControlData);
              e.component.option("value", users);
              // e.component.close()
            }
          }
        };
        return $(
          "<div id='" +
          theControl.ControlData.Properties.Control_ID +
          theControl.TDFItem.DomSafeID +
          "' />"
        ).dxDataGrid(opt);
      }
    };
    let control = $("<div/>")
      .dxDropDownBox(opts)
      .dxDropDownBox("instance");

    // let control =
    theControl.jqElement = control.element();
    theControl.ControlData.Dx = control;

    return theControl;
  }

  /**
   * Control_Type = 39
   */
  LinkedAccountDropDown() {
    /*
    * Searchable probably dx lookup has button to open the linked account
    * @property
    */
    let theControl = this;

    let control = $("<div />")
      .dxLookup({
        dataSource: [],
        placeholder: "",
        displayExpr: "Subject",
        valueExpr: "ItemId",
        //value: theControl.ControlData.DataValue.ValueName,
        showClearButton: true,
        inputAttr: {
          id:
            theControl.ControlData.Properties.Control_ID +
            theControl.TDFItem.DomSafeID
        },
        elementAttr: {
          "tdf-form-controlid": theControl.ControlData.Properties.Control_ID
        },
        disabled: theControl.checkDisabled(), //((theControl.ControlData.Properties.Locked === 1) ? true : false),
        onValueChanged: function (data) {
          theControl.ControlData.SaveValue = data.value;
          theControl.TDFItem.SetDirty(theControl.ControlData);
        }
			}).dxLookup("instance");

    theControl.jqElement = control.element();
    theControl.ControlData.Dx = control;

    new TDFRequest({
      url:
        "/item/LoadLinkedItemList/?" +
        $.param({
          itemid: theControl.TDFItem.ItemID,
          itemtype: itemTypes.itemAccount
        }),
      type: "GET"
    })
      .MakeRequest()
			.done((response: { ItemId: string, Subject: string }[]) => {
				control.option("dataSource", response);
				control.option("value", theControl.ControlData.DataValue.ValueName);
      });

    return theControl;
  }

  /**
   * Control_Type = 40
   */
  LinkedContactDropDown() {
    /*40
    *Searchable probably dx lookup has button to open the linked contactS
    * @property
    */
    let theControl = this;

    let control = $("<div />")
      .dxLookup({
        dataSource: [],
        placeholder: "",
        displayExpr: "Subject",
				valueExpr: "ItemId",
        //value: theControl.ControlData.DataValue.ValueName, // unknown why yet, but don't set the value initially.
        showClearButton: true,
        inputAttr: {
          id:
            theControl.ControlData.Properties.Control_ID +
            theControl.TDFItem.DomSafeID
        },
        elementAttr: {
          "tdf-form-controlid": theControl.ControlData.Properties.Control_ID
        },
        disabled: (theControl.ControlData.Properties.Locked === 1),
				onValueChanged: function (data) {
					console.log(data);
          theControl.ControlData.SaveValue = data.value;
          theControl.TDFItem.SetDirty(theControl.ControlData);
        }
      })
      .dxLookup("instance");
    theControl.jqElement = control.element();
		theControl.ControlData.Dx = control;

		var theValue = theControl.ControlData.DataValue.ValueName;

    new TDFRequest({
      url:
        "/item/LoadLinkedItemList/?" +
        $.param({
          itemid: theControl.TDFItem.ItemID,
          itemtype: itemTypes.itemContact
        }),
      type: "GET"
    })
      .MakeRequest()
			.done((response: { ItemId: string, Subject: string }[]) => {
				control.option("dataSource", response);
				control.option("value", theValue);
			}).fail((e) => {
				control.option("value", theValue);
			})

    return theControl;
  }

  /**
   * No examples of this control found.. Hmmm
   * Control_Type = 41
   */
  Timestamp() {
    /*41
    *
    * @property
    */
    let theControl = this;

    return theControl;
  }

  /**
   * Control_Type = 42
   */
  LinkedItemGrid() {
    /*
    * Example ... each grid on the recent touches tab
    * @property
    */
    let theControl = this;
    theControl.jqElement =
      $(
        "<div id='container-_-" +
        theControl.ControlData.Properties.Control_ID +
        theControl.TDFItem.DomSafeID +
        theControl.ControlData.ChildItemType +
        "'/>"
      );



    let timer = setInterval(() => {
      if ($(theControl.jqElement).length > 0) {
        clearInterval(timer);
        let LiCenter = new LinkedItemsCenter(
          theControl.TDFItem.ItemID,
          theControl.TDFItem.ItemType,
          `LinkedItems_${DomSafeID(theControl.TDFItem.ItemID)}_${Date.now()}`,
          { MainAreaContainer: theControl.jqElement },
          theControl.ControlData.ChildItemType,
          false
        );

        LiCenter.MainDataAreaContainer.height(
          theControl.ControlData.Properties.RowsHigh * 50
        );

        LiCenter.init(theControl.ControlData.ViewGUID);
      }
    }, .5e3);



    return theControl;
  }

  /**
   * Control_Type = 43
   */
  EventSource() {
    /*
    * Selectable Grid populated with Events from ??? All Events maybe ??
    * @property
    */
    let theControl = this;

    let control = $("<div />")
      .dxTextBox({
        value: theControl.ControlData.DataValue.ValueName,
        inputAttr: {
          id:
            theControl.ControlData.Properties.Control_ID +
            theControl.TDFItem.DomSafeID
        },
        elementAttr: {
          "tdf-form-controlid": theControl.ControlData.Properties.Control_ID
        },
        disabled: theControl.checkDisabled(), //((theControl.ControlData.Properties.Locked === 1) ? true : false),
        onValueChanged: function (data) {
          // theControl.ControlData.SaveValue = data.value;
          theControl.TDFItem.SetDirty(theControl.ControlData);
        }
      })
      .dxTextBox("instance");

    theControl.jqElement = control.element();
    theControl.ControlData.Dx = control;

    theControl.jqElement.find(".dx-texteditor-buttons-container").append(
      $("<div />").dxButton({
        icon: "spindown",
        text: "",
        onInitialized: function (data) {
          $(data.element).css({
            "border-width": " 0 0 0 1px",
            "border-radius": "0px"
          });
        },
        onClick: function (e) {
          $("#eventsourcepop").remove();
          $("#tdfbody").append($("<div id='eventsourcepop'/>"));

          let pop = $("#eventsourcepop")
            .dxPopover({
              target: control.element(),
              width:
                $(window).innerWidth() > 500 ? 500 : $(window).innerWidth(),
              height: 520
            })
            .dxPopover("instance");

          new TDFRequest({ url: "/item/GetEventsList/", type: "GET" })
            .MakeRequest()
            .done(function (response: any) {
              if (true) {
                pop.option("contentTemplate", function (element) {
                  element.append(
                    $("<div id='eventslist' />").dxDataGrid({
                      dataSource: response,
                      // showColumnHeaders: false,
                      selection: { mode: "single", showCheckBoxesMode: "none" },
                      columnAutoWidth: true,
                      height: 500,
                      pager: { visible: false },
                      paging: { enabled: false },
                      columns: [
                        {
                          dataField: "Subject"
                        },
                        {
                          dataField: "Value_ID",
                          visible: false
                        },
                        {
                          dataField: "TDFActive",
                          caption: "Active ?",
                          alignment: "center",
                          cellTemplate: function (element, info) {
                            element.append(
                              $("<div/>").addClass(
                                info.data["TDFActive"] === 1
                                  ? "fa fa-check-square-o "
                                  : " fa fa-square-o"
                              )
                            );
                          }
                        },
                        {
                          dataField: "Deleted",
                          alignment: "center",
                          caption: "Deleted ?",
                          cellTemplate: function (element, info) {
                            element.append(
                              $("<div/>").addClass(
                                info.data["Deleted"] === 1 ? "fa fa-times " : ""
                              )
                            );
                          }
                        }
                      ],
                      onSelectionChanged: function (e) {
                        theControl.ControlData.DataValue.Value_ID =
                          e.selectedRowsData[0].Value_ID;
                        theControl.ControlData.SaveValue =
                          e.selectedRowsData[0].Value_ID;
                        control.option("value", e.selectedRowsData[0].Subject);
                        theControl.TDFItem.SetDirty(theControl.ControlData);
                        pop.hide();
                      }
                    })
                  );
                });
                pop.show().done(function () {
                  //$("#eventslist").dxScrollView({ height: 500 })
                });
              } else {
              }
            });
        }
      })
    );

    return theControl;
  }

  /**
   * Control_Type = 44
   */
  LinkedItemCounter() {
    /*44
    *
    * @property
    */
    let theControl = this;

    let LinkedItemCounterResult: ILinkedItemCounterResult = {
      Result: 0,
      ChildFilter: "",
      ChildType: 0,
      ControlID: ""
    };

    let opts: DevExpress.ui.dxTextBoxOptions = {
      value: "0",
      elementAttr: {
        "tdf-form-controlid": theControl.ControlData.Properties.Control_ID
      }
    };
    let control = $("<idv />")
      .dxTextBox(opts)
      .dxTextBox("instance");
    theControl.jqElement = control.element();
    theControl.ControlData.Dx = control;

    new TDFRequest({
      url:
        "/item/GetCounterValue/?" +
        $.param({
          itemid: theControl.TDFItem.ItemID,
          itemtype: theControl.TDFItem.ItemType,
          control_id: theControl.ControlData.Properties.Control_ID //+ theControl.TDFItem.DomSafeID
        }),
      type: "GET"
    })
      .MakeRequest()
      .done(function (response: ILinkedItemCounterResult) {
        LinkedItemCounterResult = response;
        control.option("value", LinkedItemCounterResult.Result);
      });

    theControl.jqElement.find(".dx-texteditor-buttons-container").append(
      $("<div />").dxButton({
        icon: "overflow",
        text: "",
        elementAttr: {
          id:
            theControl.ControlData.Properties.Control_ID +
            theControl.TDFItem.DomSafeID
        },
        onInitialized: function (data) {
          $(data.element).css({
            "border-width": " 0 0 0 1px",
            "border-radius": "0px"
          });
          $(".dx-icon-overflow").addClass("flip90");
        },
        onClick: function (e) {
          if (!$("#viewsdialog").length) {
            $("<div id='viewsdialog' />").appendTo("#tdfbody");
          }
          let opts: DevExpress.ui.dxPopupOptions = {
            contentTemplate: function (element: JQuery) {
              element
                .append(
                  "<div id='viewlinkeditemstoolbar" +
                  theControl.TDFItem.DomSafeID +
                  "' />"
                )
                .append(
                  "<div id='viewlinkeditems" +
                  theControl.TDFItem.DomSafeID +
                  "' />"
                );
            },
            resizeEnabled: true
          };
          let ViewItemsDialog = $("#viewsdialog")
            .dxPopup(opts)
            .dxPopup("instance");
          ViewItemsDialog.show().done(function () {
            let request = new TDFRequest({
              url:
                "/Action/GetContainerTypeFromItemType/?" +
                $.param({ type: theControl.TDFItem.ItemType }),
              type: "GET"
            });

            $.when(request.MakeRequest()).done(function (r) {
              let args: ILinkedItemsArgs = {
                HasToolbar: false,
                ContainerItemID: theControl.TDFItem.ItemID,
                GridItemType: LinkedItemCounterResult.ChildType,
                ContainerItemType: theControl.TDFItem.ItemType,
                TDFContainerType: r.Data,
                GridContainer: "viewlinkeditems" + theControl.TDFItem.DomSafeID,
                ToolbarContainer:
                  "viewlinkeditemstoolbar" + theControl.TDFItem.DomSafeID,
                GridHeight: $(window).innerHeight() - 200,
                DataFilter: LinkedItemCounterResult.ChildFilter
              };
              let linkeditems = new LinkedItems(args);
              linkeditems.GetToolbar().done(function () {
                linkeditems.Toolbar.ViewSelector.option("disabled", true);
                linkeditems.ItemTypeSwitcher.option("disabled", true);
              });
            });
          });
        }
      })
    );

    return theControl;
  }

  /**
   * Control_Type = 99
   * This control is already implemented in expense wizard
   */
  ExpenseMileageEdit() {
    /*
    * Dialog there is an example like this already in oldweb ... Mileage start, end, total, mileage rate , total amount
    * @property
    */
    let theControl = this;
    console.warn(
      "This controls is implemented in Expense code. Find out why it is being called here."
    );
    return theControl;
  }

  /**
   * Conver the integer value of Control_Type  to the control name.
   */
  ConvertType() {
    let theControl = this;
    let converted = {
      "0": "TextBox",
      "1": "ComboBox",
      "2": "DropDownList",
      "3": "ListBox",
      "4": "CheckBox",
      "5": "MemoBox",
      "6": "Date",
      "7": "TextBoxWithAction",
      "8": "TextBoxWithSpinner",
      "9": "Label",
      "10": "DropDownListWithAction",
      "11": "HyperLink",
      "14": "AddressBox",
      "15": "UDFFormula",
      "17": "BSIFields",
      "18": "DateTime",
      "19": "Note",
      "24": "PhoneNumber",
      "25": "FullName",
      "26": "HyperLinkEdit",
      "27": "Checklist",
      "28": "ContactSelector",
      "29": "CalcEdit",
      "30": "StopWatch",
      "31": "LookUpEdit",
      "32": "ListBoxWithEdit",
      "34": "AccountUDF",
      "35": "NoteList",
      "36": "TDFUserSelector",
      "37": "GridLookUpEdit",
      "38": "TDFUserList",
      "39": "LinkedAccountDropDown",
      "40": "LinkedContactDropDown",
      "41": "Timestamp",
      "42": "LinkedItemGrid",
      "43": "EventSource",
      "44": "LinkedItemCounter",
      "99": "ExpenseMileageEdit"
    };

    theControl.ConvertedType =
      converted[theControl.ControlData.Properties.Control_Type];

    return theControl;
  }

  private checkDisabled() {
    let theControl = this;
    let locked = theControl.ControlData.Properties.Locked;
    let readOnly = theControl.ControlData.Properties.ReadOnly;

    return !!(locked || readOnly);
  }

  private getAddressFormTab(
    addresstype: "Business" | "Home" | "Other",
    addressform: DevExpress.ui.dxForm,
    addressData: ITDFAddressBlock,
    statesAndCountries
  ) {
    let theControl = this;
    let states;
    if (addressData.Country) {
      let countryAndState = $.grep(
        statesAndCountries.CountriesAndStates,
        (val: any, key) => {
          return val.Country === addressData.Country; // || $.grep(val.States, (v: any, k) => { return v.Abv === addressData.State || v.Name === addressData.State }).length > 0
        }
      );
      if (countryAndState.length) {
        states = countryAndState[0].States;
        //if (addressData.Country !== countryAndState[0].Country) {
        //    addressData.Country = countryAndState[0].Country
        //}
      }
      //else {
      //    countryAndState = statesAndCountries.CurrentCultureStates
      //}
    } else {
      states = statesAndCountries.CurrentCultureStates;
      // TODO:  Handle the case where the addressData.State is not in the list of states.  This would likely be rare.
    }

    return {
      title: addresstype,
      colCount: 4,
      icon: addressData.IsMailingAddress ? "message" : "",
      items: [
        {
          colSpan: 4,
          dataField: addresstype + ".Street",
          label: { text: "Street Address" },
          editorType: "dxTextArea"
        },
        {
          colSpan: 4,
          dataField: addresstype + ".City",
          label: { text: "City / Town / Locality" }
        },
        {
          colSpan: 4,
          dataField: addresstype + ".State",
          label: { text: "State / Province" },
          editorType: "dxSelectBox",
          editorOptions: <DevExpress.ui.dxSelectBoxOptions>{
            dataSource: states, //rs2.Data.States,----------------------------------------------------------------------------------------------
            displayExpr: "Name",
            valueExpr: "Abv",
            searchEnabled: true
          }
        },
        {
          colSpan: 4,
          dataField: addresstype + ".PostalCode",
          label: { text: "Postal Code /Zip" }
        },
        {
          colSpan: 4,
          dataField: addresstype + ".County",
          label: { text: "County" }
        },
        {
          colSpan: 4,
          dataField: addresstype + ".Country",
          label: { text: "Country" },
          editorType: "dxSelectBox",
          editorOptions: {
            dataSource: statesAndCountries.Countries, // rs2.Data.Countries,----------------------------------------------------------------------------------------------
            onValueChanged(data) {
              new TDFRequest({
                url:
                  "/core/user/GetStatesByCountry/?" +
                  $.param({ country: data.value }),
                type: "GET"
              })
                .MakeRequest()
                .done((response: any) => {
                  if (true) {
                    if (response.length > 0) {
                      (addressform.getEditor(
                        addresstype + ".State"
                      ) as DevExpress.ui.dxLookup).option(
                        "dataSource",
                        response
                      );
                    }
                  }
                });
            },
            searchEnabled: true
          }
        },
        {
          dataField: addresstype + ".IsMailingAddress",
          colSpan: 2,
          label: { text: "", visible: false },
          name: "chk" + addresstype + "isMailing",
          editorType: "dxCheckBox",
          editorOptions: {
            text: "Mailing Address",
            //  value: response.MailingAddressType === 1,
            onValueChanged(data) {
              if (!data.value) return;
              new TDFRequest({
                url: "/item/UpdateMailingFlag/",
                data: {
                  itemid: theControl.TDFItem.ItemID,
                  itemtype: theControl.TDFItem.ItemType,
                  addresstype: EnumAddressTypes[addresstype]
                },
                type: "POST"
              }).MakeRequest();
              let icon = addressform
                .element()
                .find(".dx-icon.dx-icon-message")
                .remove();
              addressform
                .element()
                .find("span:contains('" + addresstype + "')")
                .parent()
                .prepend($("<i class='dx-icon dx-icon-message'></i>"));
            }
          }
        },
        { itemType: "empty", colSpan: 1 },
        //TICKET 27715 Google no longer support the way we lookup the address ... you are now required to have a token
        /* {
	  dataField: " ",
	  editorType: "dxButton",
	   colSpan: 1,
	  editorOptions: {
	   text: "Lookup Address",
	   type: "default",
	   onClick(data) {

	    let formData = addressform.option("formData." + addresstype);
	    if (!formData.PostalCode) {
	     new Notification({ message: "Please Enter a Postal Code.", type: "warning", displayTime: 4000 });
	     return;
	    }
	    if (!formData.Country) {
	     new Notification({ message: "Enter a country fo greater accuracy.", type: "warning", displayTime: 4000, position: { at: "bottom" } });
	    }

	    let thedata = { PostalCode: formData.PostalCode, country: formData.Country };

	    let request = new TDFRequest({
	     url: "/core/user/LookupAddress/?" + $.param(thedata),
	     type: "GET"
	    });

	    request.MakeRequest().done(function (response: IGoooleResult) {
	     if (response.status === "OK") {
	      let city = $.grep(response.results[0].address_components, (component, key) => { return component.types.indexOf("locality") > -1 });
	      let state = $.grep(response.results[0].address_components, (component, key) => { return component.types.indexOf("administrative_area_level_1") > -1 });
	      let county = $.grep(response.results[0].address_components, (component, key) => { return component.types.indexOf("administrative_area_level_2") > -1 });
	      let country = $.grep(response.results[0].address_components, (component, key) => { return component.types.indexOf("country") > -1 })
	      let foundaddress: ITDFAddressBlock = {

	       City: city.length ? city[0].short_name : "",
	       State: state.length ? state[0].short_name : "",
	       County: county.length ? county[0].short_name : "",
	       Country: country.length ? country[0].long_name : ""

	      }
	      $.each(formData, (key, val) => {
	       if ((foundaddress[key] && formData[key] === "") || (formData[key] !== foundaddress[key] && foundaddress[key])) {
		addressform.updateData(addresstype + "." + key, foundaddress[key]);
	       }
	      })
	     }
	    });
	   }
	  }
	 },*/
        {
          dataField: " ",
          editorType: "dxButton",
          colSpan: 1,
          editorOptions: {
            text: "Save",
            type: "success",
            onClick(data) {
              let formData = addressform.option("formData." + addresstype);
              if (!formData.Zip2) formData.Zip2 = "";
              let thedata = {
                itemid: theControl.TDFItem.ItemID,
                itemtype: theControl.TDFItem.ItemType,
                addresstype: EnumAddressTypes[addresstype],
                block: formData
              };

              let request = new TDFRequest({
                url: "/item/UpdateAddress/",
                data: thedata,
                type: "POST"
              });
              if (!theControl.TDFItem.isNew) {
                request.MakeRequest().done(function (response: any) {
                  if (true) {
                    theControl.TDFItem.Refresh();
                  }
                });
              } else {
                if (!theControl.TDFItem.AddressDataList)
                  theControl.TDFItem.AddressDataList = [];
                if (
                  $.inArray(thedata, theControl.TDFItem.AddressDataList) < 0
                ) {
                  theControl.TDFItem.AddressDataList.push(thedata);
                }
              }
            }
          }
        }
      ]
    };
  }

  TimeSpan() {
    let theControl = this;

    // Set up editor
    let div = $(`#durationPop_${DomSafeID(theControl.ControlData.Properties.Control_ID)}`);
    if ($('body').find(div).length === 0) {
      div = $("<div />").attr("id", `durationPop_${DomSafeID(theControl.ControlData.Properties.Control_ID)}`);
      $('body').append(div);
    }

    let showForm = function () {
      let formOptions: DevExpress.ui.dxFormOptions = {
        formData: theControl.ControlData.SaveValue ? theControl.GetTimeSpanObj(theControl.ControlData.SaveValue) : theControl.GetTimeSpanObj(theControl.GetValueAsNum())
      };

      let items: Array<DevExpress.ui.dxFormSimpleItem> = [
        {
          dataField: "Days",
          label: {
            text: "Days"
          },
          editorOptions: {
            placeholder: "Type or Paste Numbers Only"
          },
          editorType: "dxTextBox"
        },
        {
          dataField: "Hours",
          label: {
            text: "Hours"
          },
          editorOptions: {
            placeholder: "Type or Paste Numbers Only"
          },
          editorType: "dxTextBox"
        },
        {
          dataField: "Minutes",
          label: {
            text: "Minutes"
          },
          editorOptions: {
            placeholder: "Type or Paste Numbers Only"
          },
          editorType: "dxTextBox"
        },

      ];
      formOptions.items = items;


      $(div).dxPopup({
        width: '300px',
        height: '300px',
        title: "Edit Duration",
        dragEnabled: true,
        contentTemplate: function (contentElement) {
          contentElement.append($("<div />").attr("id", `editDurationForm_${DomSafeID(theControl.ControlData.Properties.Control_ID)}`).dxForm(formOptions));
        },
        toolbarItems: [
          {
            location: "after",
            toolbar: "bottom",
            widget: "dxButton",
            options: {
              text: "Update Field",
              type: "success",
              onClick: function (e) {
                let form = $(`#editDurationForm_${DomSafeID(theControl.ControlData.Properties.Control_ID)}`).dxForm("instance");

                // update form field 
                let timespan = form.option("formData");

                theControl.ControlData.SaveValue = (timespan.Days * (60 * 24)) + (timespan.Hours * 60) + (timespan.Minutes * 1);
                theControl.TDFItem.SetDirty(theControl.ControlData);
                editor.option("value", theControl.FormatTimeSpanValue(theControl.ControlData.SaveValue));

                // close the pop-up
                $(div).dxPopup("instance").hide();
              }
            }
          },
          {
            location: "after",
            toolbar: "bottom",
            widget: "dxButton",
            options: {
              text: "Cancel",
              type: "danger",
              onClick: function (e) {
                $(div).dxPopup("instance").hide();
              }
            }
          }
        ]
      }).dxPopup("instance").show();
    };

    let editor = $('<div />').dxTextBox({
      value: theControl.FormatTimeSpanValue(theControl.GetFormattedValue()),
      inputAttr: { "id": theControl.ControlData.Properties.Control_ID + theControl.TDFItem.DomSafeID },
      elementAttr: { "tdf-form-controlid": theControl.ControlData.Properties.Control_ID },
      disabled: theControl.checkDisabled(),
      onFocusIn(e) {
        showForm();
      },
      mode: "text"
    }).dxTextBox("instance");

    theControl.jqElement = editor.element();
    theControl.ControlData.Dx = editor;

    return editor;
  }

  GetTimeSpanObj(minutes: number) {
    let days = Math.floor(minutes / (60 * 24));
    minutes -= days * 60 * 24;
    let hrs = Math.floor(minutes / 60);
    minutes -= hrs * 60;

    let timeSpan = {
      Days: days,
      Hours: hrs,
      Minutes: minutes
    };

    return (timeSpan);
  }

  FormatTimeSpanValue(value) {
    let theControl = this;

    if (typeof value === "string") {
      value = parseFloat(value);
    }

    let timeSpan = theControl.GetTimeSpanObj(value);

    let temptime = "";
    if (timeSpan.Days > 0) {
      temptime += (timeSpan.Days > 1) ? timeSpan.Days + " days" : timeSpan.Days + " day";
      if ((timeSpan.Hours > 0) || (timeSpan.Minutes > 0)) {
        temptime = temptime + ", "
      }
    }

    if (timeSpan.Hours > 0) {
      temptime += (timeSpan.Hours > 1) ? timeSpan.Hours + " hrs" : timeSpan.Hours + " hr";
      if (timeSpan.Minutes > 0) {
        temptime = temptime + ", "
      }
    }

    if (timeSpan.Minutes > 0) {
      temptime += (timeSpan.Minutes > 1) ? timeSpan.Minutes + " minutes" : timeSpan.Minutes + "minute";
    }

    return temptime;
  }

  ComputerSpan() {
    let theControl = this;

    // Set up editor
    let div = $(`#compSizePop_${DomSafeID(theControl.ControlData.Properties.Control_ID)}`);
    if ($('body').find(div).length === 0) {
      div = $("<div />").attr("id", `compSizePop_${DomSafeID(theControl.ControlData.Properties.Control_ID)}`);
      $('body').append(div);
    }

    let showForm = function () {

      let formOptions: DevExpress.ui.dxFormOptions = {
        formData: theControl.ControlData.SaveValue ? theControl.GetComputerSpanObj(theControl.ControlData.SaveValue) : theControl.GetComputerSpanObj(theControl.GetValueAsNum())
      };

      let items: Array<DevExpress.ui.dxFormSimpleItem> = [
        {
          dataField: "Gigabytes",
          label: {
            text: "Gigabytes"
          },
          editorOptions: {
            placeholder: "Type or Paste Numbers Only"
          },
          editorType: "dxTextBox"
        },
        {
          dataField: "Megabytes",
          label: {
            text: "Megabytes"
          },
          editorOptions: {
            placeholder: "Type or Paste Numbers Only"
          },
          editorType: "dxTextBox"
        },
        {
          dataField: "Kilobytes",
          label: {
            text: "Kilobytes"
          },
          editorOptions: {
            placeholder: "Type or Paste Numbers Only"
          },
          editorType: "dxTextBox"
        },
        {
          dataField: "Bytes",
          label: {
            text: "Bytes"
          },
          editorOptions: {
            placeholder: "Type or Paste Numbers Only"
          },
          editorType: "dxTextBox"
        },

      ];
      formOptions.items = items;

      $(div).dxPopup({
        width: '300px',
        height: '300px',
        title: "Edit Size",
        dragEnabled: true,
        contentTemplate: function (contentElement) {
          contentElement.append($("<div />").attr("id", "editComputerSizeForm").dxForm(formOptions));
        },
        toolbarItems: [
          {
            location: "after",
            toolbar: "bottom",
            widget: "dxButton",
            options: {
              text: "Update Field",
              type: "success",
              onClick: function (e) {
                let form = $("#editComputerSizeForm").dxForm("instance");

                // update form field 
                let computerSpan = form.option("formData");

                theControl.ControlData.SaveValue = (computerSpan.Gigabytes * (1000000000)) + (computerSpan.Megabytes * 1000000) + (computerSpan.Kilobytes * 1000) + (computerSpan.Bytes * 1);
                theControl.TDFItem.SetDirty(theControl.ControlData);
                editor.option("value", theControl.FormatComputerSpanValue(theControl.ControlData.SaveValue));

                // close the pop-up
                $(div).dxPopup("instance").hide();
              }
            }
          },
          {
            location: "after",
            toolbar: "bottom",
            widget: "dxButton",
            options: {
              text: "Cancel",
              type: "danger",
              onClick: function (e) {
                $(div).dxPopup("instance").hide();
              }
            }
          }
        ],
      }).dxPopup("instance").show();
    };

    let editor = $('<div />').dxTextBox({
      value: theControl.FormatComputerSpanValue(theControl.GetFormattedValue()),
      inputAttr: { "id": theControl.ControlData.Properties.Control_ID + theControl.TDFItem.DomSafeID },
      elementAttr: { "tdf-form-controlid": theControl.ControlData.Properties.Control_ID },
      disabled: theControl.checkDisabled(),
      onFocusIn(e) {
        showForm();
      },
      mode: "text"
    }).dxTextBox("instance");

    theControl.jqElement = editor.element();
    theControl.ControlData.Dx = editor;

    return editor;
  }

  GetComputerSpanObj(totalBytes: number) {
    let theControl = this;

    let bytes = totalBytes;
    let gigabytes = Math.floor(bytes / 1000000000);
    bytes -= gigabytes * 1000000000;
    let megabytes = Math.floor(bytes / 1000000);
    bytes -= megabytes * 1000000;
    let kilobytes = Math.floor(bytes / 1000);
    bytes -= kilobytes * 1000;

    let computerSpan = {
      Bytes: bytes,
      Megabytes: megabytes,
      Kilobytes: kilobytes,
      Gigabytes: gigabytes
    };

    return (computerSpan);
  }

  FormatComputerSpanValue(value) {
    let theControl = this;

    let compScan = theControl.GetComputerSpanObj(value);

    let tempbytes = "";

    if (compScan.Gigabytes > 0) {
      tempbytes += compScan.Gigabytes + " GB";
      if ((compScan.Megabytes > 0) || (compScan.Kilobytes > 0) || (compScan.Bytes > 0)) {
        tempbytes = tempbytes + ", "
      }
    }

    if (compScan.Megabytes > 0) {
      tempbytes += compScan.Megabytes + " MB";
      if ((compScan.Kilobytes > 0) || (compScan.Bytes > 0)) {
        tempbytes = tempbytes + ", "
      }
    }

    if (compScan.Kilobytes > 0) {
      tempbytes += compScan.Kilobytes + " KB";
      if (compScan.Bytes > 0) {
        tempbytes = tempbytes + ", "
      }
    }

    if (compScan.Bytes > 0) {
      tempbytes += compScan.Bytes + " B";
    }

    return tempbytes;
  }
}

