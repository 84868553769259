import * as Globalize from 'globalize';
import * as moment from 'moment';
import { ActionDisplayOptions, GridContainerTypes, itemTypes, menuActions } from '../../enums/enums';
import { pendingItemType } from '../../enums/timetracker/enums';
import { Preferences } from '../../infrastructure/user/preferences';
import { TDFRequest } from '../../services/request';
import { TimeTrackerSearchRecFav } from '../controls/timetrackersearchrecfav';
import { Dialog } from '../dialogs/dialog';
import { Notification } from '../dialogs/notification';
import { TTQuickNotes } from '../timetracker/ttquicknotes';
import { FilterTarget } from '../../enums/rulesmanager/enums';
import { GetDevice, CreateCollapsibleGroups, GetDataResponseObject, Debounce, DomSafeID, OpenItemOrURL } from '../../util/allutils';
import dxDataSource from 'devextreme/data/data_source';
import dxCustomStore from 'devextreme/data/custom_store';
import 'devextreme/viz/linear_gauge';
import 'devextreme/ui/switch';
import 'devextreme/ui/scheduler';
import { confirm, custom } from 'devextreme/ui/dialog';
import { CurrentUser, LoadUser, LoadCompany, GetItemTypeDisplayInfo, DisplayInfo } from '../../infrastructure/context';
import { ToolbarWithoutViews } from '../grids/gridtoolbars/toolbarwithoutviews';
import { GridWithoutViews } from '../../legacy-centers-grids/gridwithoutviews';
import { MenuAction } from 'util/menuactions';
import { RulesManager } from 'components/controls/rulesmanager';
import { type } from 'os';

enum DataScope {
  Day = 1,
  Week = 2
}

export class TimeTracker {
  CenterType = 999;
  public WorkItems: Array<any> = [];
  public AgendaItems: Array<any> = [];
  public TimeTrackerUrlStub: string;
  public IsImpersonating: boolean = false;
  public ManageTimerBtnID: string = 'manageTimerBtnID';
  public IsDevice = GetDevice().isDevice;
  public AddTimeBtnID: string = 'addTimeBtn';
  public PendingBtnID: string = 'pendingBtn';
  public TimerCount: number;

  private DataScope: DataScope = DataScope.Day;
  private LoggedInUser;
  private CurrentUser;
  private IsAdmin = false;
  private TrackedData;
  private SelectedDay;
  private SelectedMonth;
  private SelectedYear;
  private DateDisplayContainer = $('<div />').addClass(
    'timetracker-date-display'
  );
  private LengthOfDay: number;
  private MeasuredIn: String;
  private DataPointMode: String;
  private MaxAgePendingAppt: number;
  private MaxAgePendingTask: number;
  private MaxAgePendingEmail: number;
  private MaxAgePendingScreenConnect: number;
  private MaxAgePendingPhone: number;
  private ChartStartValue: number = 0;
  private ChartEndValue: number = 480;
  private TickInterval = 60;
  private MeasuredInOptions: Array<string> = ['Minutes', 'Hours'];
  private DataPointModeOptions: Array<string> = [
    'Total',
    'Billable',
    'Nonbillable'
  ];
  private MainTabPanel: DevExpress.ui.dxTabPanel;
  private workListGridObj: WorkListGrid;
  private AgendaGridID: string = 'AgendaGrid';
  private DateSelector: string = 'Daily Summary Style'; // TODO: get pref?
  private ChartID: string = 'TTChart';
  private Chart: DevExpress.viz.dxLinearGauge;
  private SummaryDataPointID: string = 'SummaryData';
  private DayBoxID: string = 'DayBox';
  private WeekBoxID: string = 'WeekBox';
  private MonthBoxID: string = 'MonthBox';
  private YearBoxID: string = 'YearBox';
  private AgendaDelBtnID: string = 'agenda-delete-btn';
  private AgendaAddBtnID: string = 'agenda-add-btn';
  private _UserList: any[];
  private MainTabs: any = [
    {
      title: 'Time Entry',
      disabled: false
    },
    {
      title: 'Build Agenda',
      disabled: false
    }
  ];
  private AgendaGrid: GridWithoutViews;
  constructor() {
    let theTimeTracker = this;

    theTimeTracker.SetSelectedDate(moment());

    LoadCompany().done(() => {
      LoadUser().done(() => {
        theTimeTracker.LoggedInUser = CurrentUser.ID;
        theTimeTracker.CurrentUser = theTimeTracker.LoggedInUser;
        theTimeTracker.TimeTrackerUrlStub =
          TDFRequest.ApiPath + '/time-tracking/';

        new TDFRequest({ url: `${theTimeTracker.TimeTrackerUrlStub}/CheckAdminStatus`, type: 'GET' })
          .MakeRequest()
          .done((response: any) => {
            if (true) {
              theTimeTracker.IsAdmin = response;
            }
            theTimeTracker.RenderTimeTracker();
          });
      });
    });

    return theTimeTracker;
  }

  public GetUsersPossibleWorkItems() {
    let theTimeTracker: TimeTracker = this;
    let d = $.Deferred();

    new TDFRequest({
      url:
        theTimeTracker.TimeTrackerUrlStub +
        'agenda?userID=' +
        theTimeTracker.CurrentUser,
      type: 'GET'
    })
      .MakeRequest()
      .done(response => {
        d.resolve(response);
      });

    return d.promise();
  }

  public SetWorkItems() {
    let theTimeTracker = this;

    theTimeTracker.WorkItems = theTimeTracker.TrackedData.Data;

    return theTimeTracker.WorkItems;
  }

  public SetAgendaItems(addedData) {
    let theTimeTracker = this;
    if (theTimeTracker.AgendaItems.length > 0) {
      addedData = addedData.filter(function (val) {
        let agendaItemIDs = theTimeTracker.AgendaItems.map(a => {
          return a.TDFItemID;
        });

        return agendaItemIDs.indexOf(val.TDFItemID) === -1;
      });

      $.each(addedData, function (i, val) {
        theTimeTracker.AgendaItems.push(val);
      });
    } else {
      theTimeTracker.AgendaItems = addedData;
    }
  }

  public RefreshTimeTracker(
    refreshDataPoints: boolean,
    refreshChartAndGrid: boolean
  ) {
    let theTimeTracker = this;

    if (refreshDataPoints) {
      theTimeTracker.UpdateDataPointControlPanel();
    }
    if (refreshChartAndGrid) {
      theTimeTracker.GetLogData().done(() => {
        theTimeTracker.GetChart();
        theTimeTracker.workListGridObj.LoadGridData();
      });
    }
  }

  public RefreshAgendaTab() {
    let theTimeTracker = this;

    // let agendaGrid: DevExpress.ui.dxDataGrid = $('#' + theTimeTracker.AgendaGridID).dxDataGrid().dxDataGrid('instance');

    if (theTimeTracker.AgendaGrid && theTimeTracker.AgendaGrid.GridObject) {
      theTimeTracker.GetUsersPossibleWorkItems().done(workItems => {
        theTimeTracker.AgendaGrid.GridDataResponse = { Data: workItems };

        // theTimeTracker.AgendaGrid.GridObject.option(
        //   'dataSource',
        //   theTimeTracker.AgendaGrid.DataSource
        // );

        theTimeTracker.AgendaGrid.GridObject.getDataSource().reload().done(() => {
          theTimeTracker.RefreshTimeTracker(true, true);
        })
        // theTimeTracker.AgendaGrid.GridObject.getDataSource().reload().done(() => {

        //   theTimeTracker.RefreshTimeTracker(true, true);

        // })

        // theTimeTracker.AgendaGrid.DataSource.reload().done(() => {

        // })
        // theTimeTracker.AgendaGrid = new GridWithoutViews( GetDataResponseObject(workItems), { GridContainer: $('#' + theTimeTracker.AgendaGridID) }, {}, theTimeTracker)
        // theTimeTracker.AgendaGrid.GridObject.option("dataSource", workItems);
      });
    }

  }

  public GetLogData() {
    let theTimeTracker = this;
    let d = $.Deferred();
    let date = theTimeTracker.GetFullDate();
    let myUrl =
      theTimeTracker.TimeTrackerUrlStub +
      'log?userID=' +
      theTimeTracker.CurrentUser;

    if (theTimeTracker.DataScope === DataScope.Day) {
      let dayString =
        date.year() + '-' + (date.month() + 1) + '-' + date.date();

      myUrl = myUrl + '&day=' + dayString;
    } else if (theTimeTracker.DataScope === DataScope.Week) {
      let weekStart = moment(date);
      weekStart = weekStart.startOf('week');
      let weekEnd = moment(date);
      weekEnd = weekEnd.startOf('week').add('days', 7);

      let weekStartString =
        weekStart.year() +
        '-' +
        (weekStart.month() + 1) +
        '-' +
        weekStart.date();
      let weekEndString =
        weekEnd.year() + '-' + (weekEnd.month() + 1) + '-' + weekEnd.date();

      myUrl = myUrl + '&start=' + weekStartString + '&end=' + weekEndString;
    }

    new TDFRequest({ url: myUrl, type: 'GET' }).MakeRequest().done(response => {
      theTimeTracker.TrackedData = response;
      theTimeTracker.TimerCount = response.TimerCount;
      d.resolve(response);
    });

    return d.promise();
  }

  public CheckIsToday() {
    let theTimeTracker = this;

    let today = moment(
      moment().month() +
      1 +
      '/' +
      moment().date() +
      '/' +
      moment().year() +
      ' ' +
      moment().hour() +
      ':' +
      moment().minute(),
      'MMDDYYYY HH:mm'
    );

    let selectedDate = theTimeTracker.GetFullDate();
    let isToday: boolean = selectedDate.isSame(today);

    return isToday;
  }

  public GetFullDate() {
    let theTimeTracker = this;
    let date = moment(
      theTimeTracker.SelectedMonth +
      '/' +
      theTimeTracker.SelectedDay +
      '/' +
      theTimeTracker.SelectedYear +
      ' ' +
      moment().hour() +
      ':' +
      moment().minute(),
      'MMDDYYYY HH:mm'
    );
    return date;
  }

  public GeneratePendingPopup() {
    let theTimeTracker = this;
    let pendingDialog: DevExpress.ui.dxPopup;
    //let pendingGrid: DevExpress.ui.dxDataGrid;
    let tabsInstance: DevExpress.ui.dxTabPanel;

    let convertPendingBtnID: string = 'convertPending';
    let searchDlgID: string = 'searchDialog';

    if (!$('#' + searchDlgID).length) {
      $('body').append($("<div id='" + searchDlgID + "' />"));
    }
    GetItemTypeDisplayInfo().done(itemtypes => {
      let tabTitles = itemtypes.filter(a => {
        return (
          (a.TypeNum === itemTypes.itemAppointment ||
            a.TypeNum === itemTypes.itemTask ||
            a.TypeNum === itemTypes.itemEmail) &&
          CurrentUser.Items.indexOf(a.TypeNum) > -1
        );
      });

      let tabs = [];

      $.each(tabTitles, (k, v) => {
        tabs.push({ title: v.DisplayNamePlural });
      });

      new TDFRequest({
        url: theTimeTracker.TimeTrackerUrlStub + 'check-for-extra-tabs',
        type: 'GET'
      })
        .MakeRequest()
        .done(extraTabs => {
          if (extraTabs.ScreenConnect) {
            tabs.push({ title: 'ScreenConnects' });
          }
          if (extraTabs.PhoneLogging) {
            tabs.push({ title: 'Phone Logs' });
          }

          pendingDialog = $('#' + searchDlgID)
            .dxPopup({
              title: 'Pending Time',
              height: '99%',
              dragEnabled: true,
              width: '99%',
              showTitle: true,
              showCloseButton: true,
              toolbarItems: [
                {
                  location: 'after',
                  toolbar: 'bottom',
                  widget: 'dxButton',
                  options: {
                    text: 'Cancel',
                    type: 'danger',
                    icon: 'remove',
                    onClick: function (e) {
                      pendingDialog.hide();
                    }
                  }
                }
              ],
              contentTemplate: function (contentElement) {
                tabsInstance = $('<div />')
                  .dxTabPanel(<DevExpress.ui.dxTabPanelOptions>{
                    dataSource: tabs,
                    selectedIndex: 0,
                    itemTemplate: (itemData, itemIndex, itemElement) => {
                      let pendingActivitiesTarget: pendingItemType;

                      // Take care of tabs with item types.
                      $.each(tabTitles, (k, v) => {
                        if (itemData.title === v.DisplayNamePlural) {
                          switch (v.TypeNum) {
                            case itemTypes.itemAppointment:
                              pendingActivitiesTarget =
                                pendingItemType.Appointment;
                              break;

                            case itemTypes.itemTask:
                              pendingActivitiesTarget = pendingItemType.Task;
                              break;

                            case itemTypes.itemEmail:
                              pendingActivitiesTarget = pendingItemType.Email;
                              break;
                          }
                        }
                      });

                      // Take care of special tabs.
                      switch (itemData.title) {
                        case 'ScreenConnects':
                          pendingActivitiesTarget =
                            pendingItemType.ScreenConnect;
                          break;

                        case 'Phone Logs':
                          pendingActivitiesTarget =
                            pendingItemType.PhoneLogging;
                          break;
                      }

                      theTimeTracker.BuildPendingTab(
                        pendingActivitiesTarget,
                        itemElement
                      );
                    },
                    height: '100%'
                  })
                  .dxTabPanel('instance');

                contentElement.append(tabsInstance.element());
              }
            })
            .dxPopup('instance');

          pendingDialog.show();
        });
    });
  }

  public CreateNewTimeItem() {
    let theTimeTracker = this;

    let quickNoteContainerID = 'quickNoteContainer';
    if (!$('#' + quickNoteContainerID).length) {
      $('body').append($('<div id=' + quickNoteContainerID + ' />'));
    }

    let data = theTimeTracker.workListGridObj.grid.GridObject.getSelectedRowsData();

    if (data.length > 0) {
      theTimeTracker.GetTimeItem(data[0], quickNoteContainerID);
    } else {
      theTimeTracker.GetSRFForAgendaItems(false).done(selected => {
        theTimeTracker.GetTimeItem(selected[0], quickNoteContainerID);
      });
    }
  }

  private RenderTimeTracker() {
    let theTimeTracker = this;

    let opts: DevExpress.ui.dxTabPanelOptions = {
      items: theTimeTracker.MainTabs,
      selectedIndex: 0,
      swipeEnabled: false,
      height: '100%',
      itemTemplate: (itemData, itemIndex, itemElement) => {
        if (itemIndex === 0) {
          theTimeTracker.RenderTrackerTab(itemElement);
        } else {
          theTimeTracker.RenderAgendaTab(itemElement);
        }
      }
    };
    theTimeTracker.MainTabPanel = $('<div />')
      .css('margin-top', '20px')
      .dxTabPanel(opts)
      .dxTabPanel('instance');

    theTimeTracker.MainTabPanel.element().appendTo('#tdfbodycontent');
  }

  private RenderTrackerTab(element) {
    let theTimeTracker = this;

    let dateSelectorID: string = 'date-selector-section';
    theTimeTracker.workListGridObj = new WorkListGrid(theTimeTracker);

    theTimeTracker.workListGridObj.getToolbar();

    theTimeTracker
      .GetTrackerTabToolbar(dateSelectorID)
      .done((trackerTabToolbar: any) => {
        //let content = $("<div />").dxScrollView({
        //    showScrollbar:GetDevice().isDevice ? 'onScroll' : 'never'
        //})
        let content = $('<div />')
          .append(
            $('<div />')
              .addClass('container-fluid')
              .append(
                $('<div />')
                  .addClass('time-tracker-top-toolbar')
                  .append(
                    $('<div />')
                      .attr('id', dateSelectorID)
                      .append(trackerTabToolbar)
                  )
              )
          )
          .append(
            $('<div />') //.addClass("container-fluid")
              .append(
                $('<div />')
                  //.addClass('row')
                  .attr('id', 'chart-and-datapoints')
                  //.append($('<div />').addClass('col-md-3'))
                  .append(
                    $('<div />')
                      .addClass('col-md-12')
                      .append(
                        $('<div />').attr(
                          'id',
                          theTimeTracker.SummaryDataPointID
                        )
                      )
                      .css({
                        'margin-top': '10px',
                        'padding-right': '30px'
                      })
                      .append("<div id='" + theTimeTracker.ChartID + "' />")
                  )
              )
              .append(
                $('<div />')
                  //.addClass('row')
                  .attr('id', 'timetracker-grid-and-toolbar')
                  .append(
                    $('<div />')
                      .addClass('col-sm-12')
                      .css('padding-right', '30px')
                      .append(
                        theTimeTracker.workListGridObj.toolbar.ToolbarObject.element()
                      )
                      .append($('<div id="worklistgrid" />'))
                  )
              )
          );

        theTimeTracker.GetLogData().done(() => {
          theTimeTracker.GetDataPointControlPanel();

          let timeTrackerContainer = $('<div />').append(content);

          element.append(timeTrackerContainer);

          theTimeTracker.GetTimeTrackerSettings().done(() => {
            CreateCollapsibleGroups(
              $('#chart-and-datapoints'),
              'Visualizations'
            );
            CreateCollapsibleGroups($('#timetracker-grid-and-toolbar'), 'Grid');

            theTimeTracker.GetChart();
          });

          theTimeTracker.workListGridObj.LoadGridData();
        });
      });
  }

  private RenderAgendaTab(element) {
    let theTimeTracker = this;

    //theTimeTracker.Container.empty();

    // let agendaGrid: DevExpress.ui.dxDataGrid;

    theTimeTracker.GetUsersPossibleWorkItems().done(response => {
      theTimeTracker.SetAgendaItems(response);

      let opts2: DevExpress.ui.dxDataGridOptions = {
        width: '100%',
        showBorders: true,
        showColumnLines: true,
        showRowLines: true,
        selection: {
          mode: 'multiple'
        },
        filterRow: {
          visible: true
        },
        columns: [
          {
            dataField: 'TDFItemType',
            caption: '',
            cellTemplate: function (container: JQuery, options: any) {
              container.append(
                $('<span />').addClass(
                  'tdfitem' + parseInt(options.data.TDFItemType)
                )
              );
            },
            width: 'auto',
            alignment: 'center',
            visible: true
          },
          {
            dataField: 'Subject',
            caption: 'Description',
            minWidth: 200,
            visible: true
          },
          {
            dataField: 'AccountName',
            caption: 'Account Name',
            minWidth: 200,
            visible: true
          }
        ]
        //dataSource: theTimeTracker.AgendaItems

        //onSelectionChanged: function (e) {
        //    //let noteData = e.selectedRowsData[0];
        //    if (!theTimeTracker.IsImpersonating) {
        //        let theBtn = $('#' + agendaDelBtnID).dxButton('instance');
        //        theBtn.option("disabled", false);
        //    }
        //},
        //onRowClick: function (e) {
        //    if (!theTimeTracker.IsImpersonating) {
        //        let theBtn = $('#' + agendaDelBtnID).dxButton('instance');
        //        theBtn.option("disabled", false);
        //    }
        //},
      };

      let gr = {
        Data: theTimeTracker.AgendaItems,
        GridView: {
          Layout: {
            Columns: opts2.columns
          }
        }
      };
      theTimeTracker.AgendaGrid = new GridWithoutViews(
        GetDataResponseObject(gr),
        { GridContainer: $(`#${theTimeTracker.AgendaGridID}`) },
        opts2,
        theTimeTracker,
        $.noop
      );
      // agendaGrid = $("<div >").css('padding-top', '10px').attr('id', theTimeTracker.AgendaGridID).dxDataGrid(opts2).dxDataGrid("instance");

      let addBtn = $(`#${theTimeTracker.AgendaAddBtnID}`).dxButton(<
        DevExpress.ui.dxButtonOptions
        >{
          type: 'success',
          text: 'Add to Agenda',
          icon: 'plus',
          hint: 'Add Selected to Agenda',
          elementAttr: { id: theTimeTracker.AgendaAddBtnID },
          disabled: theTimeTracker.IsImpersonating,
          onClick: function (e) {
            theTimeTracker.GetSRFForAgendaItems(true);
          }
        });

      let deleteBtn = $(`#${theTimeTracker.AgendaDelBtnID}`).dxButton(<
        DevExpress.ui.dxButtonOptions
        >{
          type: 'normal',
          text: 'Remove From Agenda',
          icon: 'trash',
          hint: 'Remove Selected From Agenda',
          disabled: theTimeTracker.IsImpersonating,
          elementAttr: { id: theTimeTracker.AgendaDelBtnID },
          onClick: function (e) {
            //notify("The OK button was clicked");
            let data = theTimeTracker.AgendaGrid.GridObject.getSelectedRowsData();
            let itemsToRemove = [];
            let ids = data.map(a => {
              return a.TDFItemID;
            });

            $.each(theTimeTracker.AgendaItems, function (index, value) {
              $.each(data, function (i, val) {
                if (value.TDFItemID === val.TDFItemID) {
                  itemsToRemove.push(value);
                }
              });
            });

            $.each(itemsToRemove, (k, v) => {
              let indexOfRemovedItem = theTimeTracker.AgendaItems.indexOf(v);
              if (indexOfRemovedItem > 0) {
                theTimeTracker.AgendaItems.splice(indexOfRemovedItem);
              } else {
                theTimeTracker.AgendaItems.shift();
              }
            });

            new TDFRequest({
              url: theTimeTracker.TimeTrackerUrlStub + 'remove-items',
              type: 'POST',
              data: ids
            })
              .MakeRequest()
              .done(() => {
                theTimeTracker.RefreshAgendaTab();
              });

            //theTimeTracker.setAgendaItems(data);
          }
        });

      //let settingsBtnOpts: DevExpress.ui.dxButtonOptions = {
      //    type: "normal",
      //    icon: "fa fa-cog",
      //    onClick: function (e: any) {
      //        theTimeTracker.GenerateAutoAgendaSettingsPopup();
      //    }
      //};

      //let agendaSettingsBtn = $("<div >").dxButton(settingsBtnOpts).dxButton("instance");
      theTimeTracker.AgendaGrid.Render();
    });
    element
      .append(
        $('<div />').addClass('time-tracker-top-toolbar')
        //.append(agendaSettingsBtn.element().addClass("pull-right"))
      )
      .append(
        $('<div />')
        //.append(createTabPanel)
      )
      .append(
        $('<div/>')
          .addClass('container-fluid')
          .append(
            $('<div/>')
              .addClass('row')
              .append(
                $('<div/>')
                  .addClass('col-sm-6')
                  .append($('<h3 />').text('Your Agenda Items'))
              )
          )
          .append(
            $('<div />')
              .addClass('row col-sm-6')
              .css('margin-top', '20px')
              .append(
                $('<div />')
                  .addClass('pull-left')
                  .append($(`<div id='${theTimeTracker.AgendaAddBtnID}'/>`))
              )
              .append(
                $('<div />') //.addClass("col-sm-3")
                  .append(
                    $(
                      `<div id='${
                      theTimeTracker.AgendaDelBtnID
                      }' style:"margin-left;5px" />`
                    )
                  )
              )
          )
          .append($('<div />').addClass('row'))
          .append(
            $('<div />')
              .addClass('row')
              .append(
                $('<div />')
                  .addClass('col-sm-12')
                  .attr('id', 'agenda-grid-2')
                  .append(
                    $('<div >')
                      .css('padding-top', '10px')
                      .attr('id', theTimeTracker.AgendaGridID)
                  )
              )
          )
      );
  }

  private GetSRFForAgendaItems(addItemsToAgenda: boolean) {
    let theTimeTracker: TimeTracker = this;
    let d = $.Deferred();

    GetItemTypeDisplayInfo().done(itemtypes => {
      let itemtypesEnumList = itemtypes
        .filter(a => {
          return (
            a.TypeNum !== itemTypes.itemUnknown &&
            a.TypeNum !== itemTypes.itemMailing &&
            a.TypeNum !== itemTypes.itemProduct
          );
        })
        .map(b => {
          return b.TypeNum;
        });

      let TTSRF: TimeTrackerSearchRecFav = new TimeTrackerSearchRecFav(
        itemTypes.itemUnknown,
        itemtypesEnumList,
        itemTypes.itemUnknown,
        addItemsToAgenda
      );

      TTSRF.extraTab = theTimeTracker.BuildSuggestedItemsTab(TTSRF);

      TTSRF.GetSRFDialog().done(selected => {
        // If addItemsToAgenda is false we don't want to set the agenda items. Just return selected row from SRF.
        if (addItemsToAgenda) {
          $.each(selected, (k, v) => {
            if (!v.TDFItemType) {
              v.TDFItemType = v.ItemType;
            }
            if (!v.Subject) {
              v.Subject = v.Description;
            }
          });
          theTimeTracker.SetAgendaItems(selected);

          let guids = selected.map(a => {
            return a['TDF GUID'] || a.ItemID || a.TDFItemID;
          });
          new TDFRequest({
            url: theTimeTracker.TimeTrackerUrlStub + 'add-items',
            type: 'POST',
            data: guids
          })
            .MakeRequest()
            .done(() => {
              theTimeTracker.RefreshAgendaTab();
            });
        }

        TTSRF.Dialog.close();
        d.resolve(selected);
      });
    });
    return d.promise();
  }

  private _SuggestableItemTypes: { Data: { ItemType: itemTypes, DisplayName: string } };
  get SuggestableItemTypes() {
    let d = $.Deferred();

    if (!this._SuggestableItemTypes) {
      new TDFRequest({
        url:
          '/RulesManager/ActiveItemTypes?filterTarget=' +
          FilterTarget.Suggestions,
        type: 'GET'
      }).MakeRequest().done(response => {
        // response = response.filter(a => {
        //   return a.ItemType !== itemTypes.itemAccount;
        // });

        response.unshift({
          ItemType: itemTypes.itemAllItems,
          DisplayName: 'All Items'
        });

        this._SuggestableItemTypes = response;
        d.resolve(response);
      });

      return d.promise();
    } else {
      return d.promise(d.resolve(this._SuggestableItemTypes));
    }
  }

  private BuildSuggestedItemsTab(TTSRF: TimeTrackerSearchRecFav) {
    let theTimeTracker = this;

    let gridID = 'suggestedGrid';

    let suggestedItemTab = {
      title: 'Suggested Items',
      buildContent: $.noop,
      template: (itemData, index, itemElement) => {
        let gridContainer = $('<div id="suggestedGrid" />');
        let toolbarcontainer = $("<div id='suggestedToolbar' />");
        itemElement
          .append(toolbarcontainer)
          .append(gridContainer);

        let buildGrid = (itemType: itemTypes) => {

          new TDFRequest({
            url:
              theTimeTracker.TimeTrackerUrlStub +
              'auto-agenda?itemType=' +
              itemType,
            type: 'GET'
          })
            .MakeRequest()
            .done(response => {

              let extraToolbarItems: DevExpress.ui.dxToolbarItem[] = [
                {
                  location: 'before',
                  widget: 'dxSelectBox',
                  options: <DevExpress.ui.dxSelectBoxOptions>{
                    dataSource: new dxDataSource(new dxCustomStore({
                      load: () => {
                        let d = $.Deferred();
                        theTimeTracker.SuggestableItemTypes.done(types => {
                          d.resolve(types);
                        });
                        return d.promise();
                      },
                      key: 'ItemType',
                      byKey: (key) => {
                        let d = $.Deferred();
                        theTimeTracker.SuggestableItemTypes.done(types => {
                          let selectedType = types.filter(a => {
                            return a.ItemType === key;
                          })[0];
                          d.resolve(selectedType);
                        });
                        return d.promise();
                      }
                    })),
                    displayExpr: 'DisplayName',
                    valueExpr: 'ItemType',
                    //width: '20%',
                    value: itemType,
                    // onInitialized: e => {
                    //   buildGrid(itemTypes.itemAllItems);
                    // },
                    onValueChanged: e => {
                      buildGrid(e.value);
                    }
                  }
                }, {
                  location: 'after',
                  widget: 'dxButton',
                  options: <DevExpress.ui.dxButtonOptions>{
                    icon: 'fa fa-cog',
                    onClick: () => {
                      new RulesManager(FilterTarget.Suggestions).DFD.done(() => {
                        buildGrid(itemType);
                      });
                    }
                  }
                }
              ]

              let thisToolbar = new ToolbarWithoutViews({
                ExtraItems: extraToolbarItems
              });
              thisToolbar.ToolbarContainerID = 'suggestedToolbar';
              thisToolbar.Render();

              let thisGrid = new GridWithoutViews(
                GetDataResponseObject(response),
                { GridContainer: gridContainer },
                <DevExpress.ui.dxDataGridOptions>{
                  dataSource: response,
                  height: 'calc(100% - 52px)', //52px represents the height of this grid's toolbar
                  width: '100%',
                  elementAttr: {
                    id: gridID
                  },
                  selection: {
                    mode: TTSRF.multiSelectAllowed ? 'multiple' : 'single',
                    showCheckBoxesMode: 'always'
                  },
                  onRowClick: function (e) {
                    //Override default clicking checkbox
                    if (TTSRF.multiSelectAllowed) {
                      if (!e.isSelected)
                        (e.component as DevExpress.ui.dxDataGrid).selectRows(
                          e.key,
                          e.jQueryEvent.ctrlKey
                        );
                      else
                        (e.component as DevExpress.ui.dxDataGrid).deselectRows(
                          e.key
                        );
                    }
                  },
                  columns: [
                    {
                      dataField: 'TDFItemType',
                      caption: '',
                      cellTemplate: function (container: JQuery, options: any) {
                        container.append(
                          $('<span />').addClass(
                            'tdfitem' + parseInt(options.data.TDFItemType)
                          )
                        );
                      },
                      width: 'auto',
                      alignment: 'center',
                      allowFiltering: false,
                      allowSearch: false
                    },
                    {
                      caption: 'In Agenda',
                      dataField: 'InAgenda',
                      dataType: 'string',
                      width: 'auto'
                    },
                    {
                      dataField: 'UniqueID',
                      visible: true,
                      width: 'auto'
                    },
                    {
                      caption: 'Account Name',
                      dataField: 'AccountName',
                      width: 'auto'
                    },
                    {
                      caption: 'Item ID',
                      dataField: 'TDFItemID',
                      visible: false
                    },
                    {
                      dataField: 'Subject',
                      minWidth: 200
                    }
                  ]
                },
                theTimeTracker,
                e => {
                  thisToolbar.SelectionChanged(e);
                },
                null,
                'TimeTracker_SuggestedItems'
              );

              thisGrid.Render();

              //itemElement.append(
              // gridContainer.append(thisToolbar.ToolbarObject.element()).append(thisGrid.GridObject.element())
              //)
            });
        };

        buildGrid(itemTypes.itemAllItems);
      },
      getSelected: () => {
        let datagrid: DevExpress.ui.dxDataGrid = $('#' + gridID).dxDataGrid(
          'instance'
        );

        return datagrid.getSelectedRowsData();
      }
    };

    return suggestedItemTab;
  }

  private GenerateAutoAgendaSettingsPopup() {
    let theTimeTracker = this;
    let autoAgendaSettingsDialog: DevExpress.ui.dxPopup;

    let autoAgendaSettingsDlgID: string = 'autoAgendaSettingsDialog';
    let agendaSettingsFormID: string = 'agendaSettingsForm';

    if (!$('#' + autoAgendaSettingsDlgID).length) {
      $('body').append($("<div id='" + autoAgendaSettingsDlgID + "' />"));
    }

    let formOptions: DevExpress.ui.dxFormOptions = {
      colCount: 2
      //formData: formData
    };

    let items: DevExpress.ui.dxFormSimpleItem[] = [
      {
        itemType: 'empty',
        colSpan: 2
      },
      {
        dataField: 'itemType',
        label: {
          text: 'Item Type'
        },
        editorOptions: {
          // AARONS TODO: Get list from api, filter as needed.
          dataSource: [
            'Account',
            'Activity',
            'Affiliate',
            'Appointment',
            'Asset',
            'Change Order',
            'Contact',
            'Conversation',
            'Customer Objectives',
            'Document',
            'Email',
            'Event',
            'Expense',
            'Knowledge Base',
            'Lead',
            'Literature',
            'Opportunity',
            'Quote',
            'Quote Line',
            'Support',
            'Task',
            'Ticket for Dev',
            'Workflow'
          ]
        },
        editorType: 'dxSelectBox'
      },
      {
        itemType: 'empty',
        colSpan: 1
      }
    ];

    formOptions.items = items;

    let theForm = $('<div />')
      .attr('id', agendaSettingsFormID)
      .dxForm(formOptions)
      .dxForm('instance');

    autoAgendaSettingsDialog = $('#' + autoAgendaSettingsDlgID)
      .dxPopup({
        title: 'Agenda Settings',
        height: 600,
        dragEnabled: true,
        maxWidth: 600,
        showTitle: true,
        showCloseButton: true,
        toolbarItems: [
          {
            location: 'after',
            toolbar: 'bottom',
            widget: 'dxButton',
            options: {
              text: 'OK',
              type: 'success',
              icon: 'check',
              onClick: function (e) {
                new TDFRequest({
                  url: 'TimeTracker/SaveAgendaSettings',
                  type: 'POST',
                  data: ''
                })
                  .MakeRequest()
                  .done((response: any) => {
                    if (true) {
                      new Notification({
                        message: 'Agenda settings saved.',
                        type: 'success'
                      });
                    } else {
                    }
                    autoAgendaSettingsDialog.hide();
                  });
              }
            }
          },
          {
            location: 'after',
            toolbar: 'bottom',
            widget: 'dxButton',
            options: {
              text: 'Cancel',
              type: 'danger',
              icon: 'remove',
              onClick: function (e) {
                autoAgendaSettingsDialog.hide();
              }
            }
          }
        ],
        contentTemplate: function (contentElement) {
          contentElement.append(theForm.element());
        }
      })
      .dxPopup('instance');

    autoAgendaSettingsDialog.show();
  }

  private GetTimeTrackerSettings() {
    let theTimeTracker = this;
    let d = $.Deferred();

    theTimeTracker.MeasuredIn =
      Preferences.GetPreference('MeasureTimeIn', 'Time Tracker') || 'Hours';
    theTimeTracker.LengthOfDay =
      parseInt(Preferences.GetPreference('LengthOfDay', 'Time Tracker')) || 8;
    theTimeTracker.DataPointMode =
      Preferences.GetPreference('DataPointMode', 'Time Tracker') || 'Total';
    theTimeTracker.MaxAgePendingAppt =
      parseInt(
        Preferences.GetPreference('MaxAgePendingAppt', 'Time Tracker')
      ) || 1;
    theTimeTracker.MaxAgePendingTask =
      parseInt(
        Preferences.GetPreference('MaxAgePendingTask', 'Time Tracker')
      ) || 1;
    theTimeTracker.MaxAgePendingEmail =
      parseInt(
        Preferences.GetPreference('MaxAgePendingEmail', 'Time Tracker')
      ) || 1;
    theTimeTracker.MaxAgePendingScreenConnect =
      parseInt(
        Preferences.GetPreference('MaxAgePendingScreenConnect', 'Time Tracker')
      ) || 1;
    theTimeTracker.MaxAgePendingPhone =
      parseInt(
        Preferences.GetPreference('MaxAgePendingPhone', 'Time Tracker')
      ) || 1;

    return d.promise(d.resolve());
  }

  private GenerateSettingsPopup() {
    let theTimeTracker = this;
    let graphSettingsDialog: DevExpress.ui.dxPopup;

    let graphSettingsDlgID: string = 'graphSettingsDialog';

    if (!$('#' + graphSettingsDlgID).length) {
      $('body').append($("<div id='" + graphSettingsDlgID + "' />"));
    }

    let formOptions: DevExpress.ui.dxFormOptions = {
      colCount: 2,
      labelLocation: 'top'
      //formData: formData
    };

    let chartOpts = theTimeTracker.GetChartOpts();

    let items: Array<DevExpress.ui.dxFormSimpleItem | DevExpress.ui.dxFormButtonItem> = [
      {
        dataField: 'measuredIn',
        label: {
          text: 'Measure Time In'
        },
        editorOptions: {
          placeholder: '',
          dataSource: theTimeTracker.MeasuredInOptions,
          value:
            chartOpts.scale.tickInterval == 60
              ? 'Minutes'
              : chartOpts.scale.tickInterval == 1
                ? 'Hours'
                : ''
        },
        editorType: 'dxSelectBox'
      },
      {
        dataField: 'lengthOfDay',

        label: {
          text: 'Length of Day (Hours)'
        },
        editorOptions: {
          placeholder: '',
          dataType: 'number',
          value: theTimeTracker.LengthOfDay,
          invalidValueMessage: 'Value must be a number greater than zero.',
          min: 1
        },
        editorType: 'dxNumberBox'
      },
      /* // Removing slider, may reintroduce in later version.
                {
                    dataField: "selectorType",
                    label: {
                        text: "Date Selector Type"
                    },
                    editorOptions: {
                        placeholder: "",
                        dataSource: ["Daily Summary Style", "Range Slider"],
                        value: theTimeTracker.DateSelector,
                    },
                    editorType: "dxSelectBox",
 
                },   */
      {
        dataField: 'dataPointMode',
        label: {
          text: 'Data Point Mode'
        },
        editorOptions: {
          placeholder: '',
          dataSource: theTimeTracker.DataPointModeOptions,
          value: theTimeTracker.DataPointMode
        },
        editorType: 'dxSelectBox'
      },
      {
        itemType: 'empty',
        colSpan: 1
      },
      {
        dataField: 'maxAgePendingAppt',
        label: {
          text: 'Include Appointments in Pending (Days)'
        },
        editorOptions: {
          dataType: 'number',
          value: theTimeTracker.MaxAgePendingAppt,
          invalidValueMessage: 'value must be a number greater than zero.',
          min: 1
        },
        editorType: 'dxNumberBox'
      },
      {
        dataField: 'maxAgePendingTask',
        label: {
          text: 'Include Tasks in Pending (Days)'
        },
        editorOptions: {
          dataType: 'number',
          value: theTimeTracker.MaxAgePendingTask,
          invalidValueMessage: 'value must be a number greater than zero.',
          min: 1
        },
        editorType: 'dxNumberBox'
      },
      {
        dataField: 'maxAgePendingEmail',
        label: {
          text: 'Include Emails in Pending (Days)'
        },
        editorOptions: {
          dataType: 'number',
          value: theTimeTracker.MaxAgePendingEmail,
          invalidValueMessage: 'value must be a number greater than zero.',
          min: 1
        },
        editorType: 'dxNumberBox'
      }
    ];

    new TDFRequest({
      url: theTimeTracker.TimeTrackerUrlStub + 'check-for-extra-tabs',
      type: 'GET'
    })
      .MakeRequest()
      .done(extraTabs => {
        if (extraTabs.ScreenConnect) {
          items.push({
            dataField: 'maxAgePendingScreenConnect',
            label: {
              text: 'Include ScreenConnects in Pending (Days)'
            },
            editorOptions: {
              dataType: 'number',
              value: theTimeTracker.MaxAgePendingScreenConnect,
              invalidValueMessage: 'value must be a number greater than zero.',
              min: 1
            },
            editorType: 'dxNumberBox'
          });
        }

        if (extraTabs.PhoneLogging) {
          items.push({
            dataField: 'maxAgePendingPhone',
            label: {
              text: 'Include Phone Logs in Pending (Days)'
            },
            editorOptions: {
              dataType: 'number',
              value: theTimeTracker.MaxAgePendingPhone,
              invalidValueMessage: 'value must be a number greater than zero.',
              min: 1
            },
            editorType: 'dxNumberBox'
          });
        }

        formOptions.items = items;

        let theForm = $('<div />')
          .attr('id', 'settingsForm')
          .dxForm(formOptions)
          .dxForm('instance');

        graphSettingsDialog = $('#' + graphSettingsDlgID)
          .dxPopup({
            title: 'Time Tracker Settings',
            height: 600,
            dragEnabled: true,
            maxWidth: 800,
            showTitle: true,
            showCloseButton: true,
            toolbarItems: [
              {
                location: 'after',
                toolbar: 'bottom',
                widget: 'dxButton',
                options: {
                  text: 'OK',
                  type: 'success',
                  icon: 'check',
                  onClick: function (e) {
                    let data = theForm.option('formData');

                    theTimeTracker.MeasuredIn = data.measuredIn;
                    theTimeTracker.LengthOfDay = data.lengthOfDay;
                    theTimeTracker.DataPointMode = data.dataPointMode;
                    theTimeTracker.MaxAgePendingAppt = data.maxAgePendingAppt;
                    theTimeTracker.MaxAgePendingTask = data.maxAgePendingTask;
                    theTimeTracker.MaxAgePendingEmail = data.maxAgePendingEmail;
                    theTimeTracker.MaxAgePendingScreenConnect =
                      data.maxAgePendingScreenConnect;
                    theTimeTracker.MaxAgePendingPhone = data.maxAgePendingPhone;

                    $.when(
                      Preferences.SetPreferences([
                        { ModuleName: 'Time Tracker', PropertyName: 'MeasureTimeIn', PropertyValue: theTimeTracker.MeasuredIn },
                        { ModuleName: 'Time Tracker', PropertyName: 'LengthOfDay', PropertyValue: theTimeTracker.LengthOfDay },
                        { ModuleName: 'Time Tracker', PropertyName: 'DataPointMode', PropertyValue: theTimeTracker.DataPointMode },
                        { ModuleName: 'Time Tracker', PropertyName: 'MaxAgePendingAppt', PropertyValue: theTimeTracker.MaxAgePendingAppt },
                        { ModuleName: 'Time Tracker', PropertyName: 'MaxAgePendingTask', PropertyValue: theTimeTracker.MaxAgePendingTask },
                        { ModuleName: 'Time Tracker', PropertyName: 'MaxAgePendingEmail', PropertyValue: theTimeTracker.MaxAgePendingEmail },
                        { ModuleName: 'Time Tracker', PropertyName: 'MaxAgePendingScreenConnect', PropertyValue: theTimeTracker.MaxAgePendingScreenConnect },
                        { ModuleName: 'Time Tracker', PropertyName: 'MaxAgePendingPhone', PropertyValue: theTimeTracker.MaxAgePendingPhone },
                      ]),
                    ).done(() => {
                      theTimeTracker.RefreshTimeTracker(true, true);
                      graphSettingsDialog.hide();
                    });
                  }
                }
              },
              {
                location: 'after',
                toolbar: 'bottom',
                widget: 'dxButton',
                options: {
                  text: 'Cancel',
                  type: 'danger',
                  icon: 'remove',
                  onClick: function (e) {
                    graphSettingsDialog.hide();
                  }
                }
              }
            ],
            contentTemplate: function (contentElement) {
              contentElement.append(theForm.element());
            }
          })
          .dxPopup('instance');

        graphSettingsDialog.show();
      });
  }

  private GetTrackerTabToolbar(dateSelectorID: string) {
    let theTimeTracker = this;
    let d = $.Deferred();

    $('#' + dateSelectorID).empty();

    let element = $('<div />').addClass('row');

    let leftItems = [
      {
        location: 'before',
        locateInMenu: 'never',
        template: function (itemData, itemIndex, itemElement) {
          itemElement.append(theTimeTracker.DateDisplayContainer);
        }
      },
      {
        location: 'before',
        locateInMenu: 'never',
        widget: 'dxButton',
        options: {
          type: 'warning daily-toolbar-autohide',
          icon: 'arrowleft',
          onClick: (itemData, itemIndex, itemElement) => {
            let date = theTimeTracker.GetFullDate();

            if (theTimeTracker.DataScope === DataScope.Day) {
              date.add('days', -1);
            } else if (theTimeTracker.DataScope === DataScope.Week) {
              date.add('weeks', -1);
            }

            theTimeTracker.SetSelectedDate(date);

            theTimeTracker.RefreshTimeTracker(true, true);
          }
        }
      },
      {
        location: 'before',
        locateInMenu: GetDevice().isDevice ? 'always' : 'auto',
        widget: 'dxButton',
        options: {
          type: 'warning daily-toolbar-autohide',
          text: GetDevice().isDevice ? 'Calendar' : '',
          icon: 'event',
          onClick: Debounce((itemData, itemIndex, itemElement) => {
            let div = $('<div />').attr('id', 'UserCalendar');
            let legend = $('<div />');
            let container = $('<div />');
            container.append(legend).append(div);
            //  dxOverlay.baseZIndex(2000);
            let cal = new Dialog({
              body: container,
              title: 'Calendar',
              size: 'size-wide',
              closable: true
            });

            cal.open().done(function () {
              $(div).dxScheduler({
                views: ['day', 'week', 'workWeek', 'month' /*, 'agenda'*/],
                currentView: 'month',
                useDropDownViewSwitcher: true,
                firstDayOfWeek: 0,
                appointmentTooltipTemplate: function (data, content) { },
                startDayHour: 6,
                endDayHour: 19,
                height: $(window).innerHeight() - 75,
                onCellClick: e => {
                  let selectedCell = e.cellData.endDate;
                  let date = moment(selectedCell);

                  if (theTimeTracker.DataScope === DataScope.Week) {
                    date.startOf('week');
                  }

                  theTimeTracker.SetSelectedDate(date);

                  theTimeTracker.RefreshTimeTracker(true, true);

                  cal.close();
                }
              });
            });
          }, 0.5e3)
        }
      },
      {
        location: 'before',
        locateInMenu: 'never',
        widget: 'dxButton',
        options: {
          type: 'warning daily-toolbar-autohide',
          icon: 'arrowright',
          onClick: (itemData, itemIndex, itemElement) => {
            let date = theTimeTracker.GetFullDate();

            if (theTimeTracker.DataScope === DataScope.Day) {
              date.add('days', 1);
            } else if (theTimeTracker.DataScope === DataScope.Week) {
              date.add('weeks', 1);
            }

            theTimeTracker.SetSelectedDate(date);

            theTimeTracker.RefreshTimeTracker(true, true);
          }
        }
      },
      {
        location: 'before',
        locateInMenu: GetDevice().isDevice ? 'always' : 'auto',
        widget: 'dxButton',
        options: {
          text: 'Today',
          type: 'info daily-toolbar-autohide',
          onClick: Debounce((itemData, itemIndex, itemElement) => {
            let date = moment();

            if (theTimeTracker.DataScope === DataScope.Week) {
              date.startOf('week');
            }
            theTimeTracker.SetSelectedDate(date);

            theTimeTracker.RefreshTimeTracker(true, true);
          }, 0.5e3)
        }
      },
      {
        location: 'before',
        locateInMenu: GetDevice().isDevice ? 'always' : 'auto',
        widget: 'dxSwitch',
        text: '',
        options: <DevExpress.ui.dxSwitchOptions>{
          switchedOnText: 'Daily',
          switchedOffText: 'Weekly',
          value: true,
          width: 'auto',
          onValueChanged: e => {
            let date = theTimeTracker.GetFullDate();
            if (e.value) {
              theTimeTracker.DataScope = DataScope.Day;
            } else {
              date.startOf('week');
              theTimeTracker.DataScope = DataScope.Week;
            }

            theTimeTracker.SetSelectedDate(date);
            theTimeTracker.RefreshTimeTracker(true, true);
          }
        }
      }
    ];

    let rightItems = [
      {
        location: 'after',
        locateInMenu: 'auto',
        widget: 'dxSelectBox',
        options: {
          width: '200px',
          dataSource: new dxDataSource({
            paginate: false,
            store: new dxCustomStore({
              load(opts) {
                let d = $.Deferred();
                if (!theTimeTracker._UserList) {
                  new TDFRequest({ url: '/core/user/userlist' })
                    .MakeRequest()
                    .done(users => {
                      theTimeTracker._UserList = users;
                      d.resolve(theTimeTracker._UserList);
                    });
                } else {
                  d.resolve(theTimeTracker._UserList);
                }
                return d.promise();
              },
              byKey(key) {
                let d = $.Deferred();
                if (!theTimeTracker._UserList) {
                  new TDFRequest({ url: '/core/user/userlist' })
                    .MakeRequest()
                    .done(users => {
                      theTimeTracker._UserList = users;
                      let user = theTimeTracker._UserList.filter(k => {
                        return k.Userid === key;
                      });

                      d.resolve(user);
                    });
                } else {
                  let user = theTimeTracker._UserList.filter(k => {
                    return k.userid === key;
                  });

                  return d.promise(d.resolve(user));
                }
                return d.promise();
              }
            })
          }),
          searchEnabled: true,
          displayExpr: 'Name',
          valueExpr: 'Userid',

          value: theTimeTracker.CurrentUser, //response.IsExpenseAdmin ? theTimeTracker.loadFor ||CurrentUser.ID :CurrentUser.ID,
          onValueChanged: (e: any) => {
            theTimeTracker.CurrentUser = e.value;

            if (theTimeTracker.CurrentUser === theTimeTracker.LoggedInUser) {
              theTimeTracker.IsImpersonating = false;
            } else {
              theTimeTracker.IsImpersonating = true;
            }

            $('#' + theTimeTracker.AddTimeBtnID)
              .dxButton()
              .dxButton('instance')
              .option('disabled', theTimeTracker.IsImpersonating);
            $('#' + theTimeTracker.PendingBtnID)
              .dxButton()
              .dxButton('instance')
              .option('disabled', theTimeTracker.IsImpersonating);
            $('#' + theTimeTracker.ManageTimerBtnID)
              .dxButton()
              .dxButton('instance')
              .option('disabled', theTimeTracker.IsImpersonating);

            if ($('#' + theTimeTracker.AgendaGridID).find('div').length > 0) {
              $('#' + theTimeTracker.AgendaAddBtnID)
                .dxButton()
                .dxButton('instance')
                .option('disabled', theTimeTracker.IsImpersonating);
              $('#' + theTimeTracker.AgendaDelBtnID)
                .dxButton()
                .dxButton('instance')
                .option('disabled', theTimeTracker.IsImpersonating);
            }

            theTimeTracker.RefreshTimeTracker(true, true);
            theTimeTracker.RefreshAgendaTab();
          }
        },
        visible: theTimeTracker.IsAdmin
      },
      {
        location: 'after',
        locateInMenu: 'never',
        widget: 'dxButton',
        options: {
          icon: 'fa fa-refresh'
        },
        onClick() {
          theTimeTracker.RefreshTimeTracker(true, true);
        }
      },
      {
        location: 'after',
        locateInMenu: 'auto',
        widget: 'dxButton',
        options: {
          text: '',
          icon: 'fa fa-cog',
          onClick: Debounce(function (e: any) {
            theTimeTracker.GenerateSettingsPopup();
          }, 0.5e3)
        }
      }
    ];

    element
      .append(
        $('<div />')
          .addClass('col-md-6')
          .css('padding', '5px')
          .dxToolbar(<DevExpress.ui.dxToolbarOptions>{
            items: leftItems
          })
      )
      .append(
        $('<div />')
          .addClass('col-md-6')
          .css('padding', '5px')
          .dxToolbar(<DevExpress.ui.dxToolbarOptions>{
            items: rightItems
          })
      );

    return d.promise(d.resolve(element));
  }

  private SetDayString() {
    let theTimeTracker = this;
    let day = theTimeTracker.GetFullDate();
    //let dayString = day.toDate().toLocaleDateString();
    let dayString = Globalize.formatDate(day.toDate(), { date: 'short' });
    enum weekDay {
      'Sun.',
      'Mon.',
      'Tue.',
      'Wed.',
      'Thu.',
      'Fri.',
      'Sat.'
    }

    if (theTimeTracker.DataScope === DataScope.Day) {
      dayString = weekDay[day.weekday()] + ' ' + dayString;
    } else if (theTimeTracker.DataScope === DataScope.Week) {
      dayString = 'Week of ' + dayString;
    }

    theTimeTracker.DateDisplayContainer.text(dayString);
  }

  private SetSelectedDate(date: moment.Moment) {
    let theTimeTracker = this;

    theTimeTracker.SelectedDay = date.date();
    theTimeTracker.SelectedMonth = date.month() + 1;
    theTimeTracker.SelectedYear = date.year();

    theTimeTracker.SetDayString();
  }

  private GetDataPointControlPanel() {
    let theTimeTracker = this;

    let container = $('<div/>').addClass('row');

    theTimeTracker.GetDataPoints().done((dataPointObj: any) => {
      if (
        theTimeTracker.DataScope === DataScope.Week ||
        isNaN(dataPointObj.day.percent) ||
        dataPointObj.day.percent === Infinity
      ) {
        dataPointObj.day.percent = 'N/A';
      }

      let dayBoxTxt: string = 'Day: ' + dataPointObj.day.percent;
      if (typeof dataPointObj.day.percent === 'number') {
        dayBoxTxt += '%'; //  |  ' + dataPointObj.day.time + ' of ' + dataPointObj.day.goal + ' ' + theTimeTracker.MeasuredIn;
      }

      let dayBox = $('<div/>')
        .attr('id', theTimeTracker.DayBoxID)
        .addClass('panel-box')
        .text(dayBoxTxt);
      let weekBox = $('<div/>')
        .attr('id', theTimeTracker.WeekBoxID)
        .addClass('panel-box')
        .text('Week: ' + dataPointObj.week.percent + '%'); //|  ' + dataPointObj.week.time + ' of ' + dataPointObj.week.goal + ' ' + theTimeTracker.MeasuredIn);

      let monthBox = $('<div/>')
        .attr('id', theTimeTracker.MonthBoxID)
        .addClass('panel-box')
        .text('Month: ' + dataPointObj.month.percent + '%'); //  |  ' + dataPointObj.month.time + ' of ' + dataPointObj.month.goal + ' ' + theTimeTracker.MeasuredIn);

      let yearBox = $('<div/>')
        .attr('id', theTimeTracker.YearBoxID)
        .addClass('panel-box')
        .text('Year: ' + dataPointObj.year.percent + '%'); //  |  ' + dataPointObj.year.time + ' of ' + dataPointObj.year.goal + ' ' + theTimeTracker.MeasuredIn);

      let container = $('<div />')
        .addClass('row')
        .append(
          $('<div />')
            .addClass('col-lg-3')
            .append(dayBox)
        )
        .append(
          $('<div />')
            .addClass('col-lg-3')
            .append(weekBox)
        )
        .append(
          $('<div />')
            .addClass('col-lg-3')
            .append(monthBox)
        )
        .append(
          $('<div />')
            .addClass('col-lg-3')
            .append(yearBox)
        )

      if (theTimeTracker.DataPointMode === 'Total') {
        $(container)
          .find('div.panel-box')
          .removeClass('billable-box')
          .addClass('total-box');
      } else if (theTimeTracker.DataPointMode === 'Billable') {
        $(container)
          .find('div.panel-box')
          .removeClass('total-box')
          .addClass('billable-box');
      } else {
        $(container)
          .find('div.panel-box')
          .removeClass('billable-box total-box');
      }

      $('#' + theTimeTracker.SummaryDataPointID).append(container);
    });
  }

  private UpdateDataPointControlPanel() {
    let theTimeTracker = this;

    theTimeTracker.GetDataPoints().done((dataPointObj: any) => {
      if (
        theTimeTracker.DataScope === DataScope.Week ||
        isNaN(dataPointObj.day.percent) ||
        dataPointObj.day.percent === Infinity
      ) {
        dataPointObj.day.percent = 'N/A';
      }

      let dayBoxTxt: string = 'Day: ' + dataPointObj.day.percent;
      if (typeof dataPointObj.day.percent === 'number') {
        dayBoxTxt += '%'; //  |  ' + dataPointObj.day.time + ' of ' + dataPointObj.day.goal + ' ' + theTimeTracker.MeasuredIn;
      }

      $('#' + theTimeTracker.DayBoxID).text(dayBoxTxt);
      $('#' + theTimeTracker.WeekBoxID).text(
        'Week: ' + dataPointObj.week.percent + '%'
      ); //  |  ' + dataPointObj.week.time +
      //' of ' + dataPointObj.week.goal + ' ' + theTimeTracker.MeasuredIn);

      $('#' + theTimeTracker.MonthBoxID).text(
        'Month: ' + dataPointObj.month.percent + '%'
      ); //  |  ' + dataPointObj.month.time +
      //' of ' + dataPointObj.month.goal + ' ' + theTimeTracker.MeasuredIn);

      $('#' + theTimeTracker.YearBoxID).text(
        'Year: ' + dataPointObj.year.percent + '%'
      ); //  |  ' + dataPointObj.year.time +
      //' of ' + dataPointObj.year.goal + ' ' + theTimeTracker.MeasuredIn);

      if (theTimeTracker.DataPointMode === 'Total') {
        $('#' + theTimeTracker.SummaryDataPointID)
          .find('div.panel-box')
          .removeClass('billable-box')
          .addClass('total-box');
      } else if (theTimeTracker.DataPointMode === 'Billable') {
        $('#' + theTimeTracker.SummaryDataPointID)
          .find('div.panel-box')
          .removeClass('total-box')
          .addClass('billable-box');
      } else {
        $('#' + theTimeTracker.SummaryDataPointID)
          .find('div.panel-box')
          .removeClass('billable-box total-box');
      }
    });
  }

  private GetDataPoints() {
    let theTimeTracker = this;
    let d = $.Deferred();

    let dayTime;
    let weekTime;
    let monthTime;
    let yearTime;

    let date = theTimeTracker.GetFullDate();
    let dayString = date.year() + '-' + (date.month() + 1) + '-' + date.date();

    new TDFRequest({
      url:
        theTimeTracker.TimeTrackerUrlStub +
        'summaries?day=' +
        dayString +
        '&userID=' +
        theTimeTracker.CurrentUser,
      type: 'GET'
    })
      .MakeRequest()
      .done((response: Array<any>) => {
        let dayData = response.filter(a => {
          return a.Label === 'Today';
        });

        let weekData = response.filter(a => {
          return a.Label === 'This Week';
        });

        let monthData = response.filter(a => {
          return a.Label === 'This Month';
        });

        let yearData = response.filter(a => {
          return a.Label === 'This Year';
        });

        if (theTimeTracker.DataPointMode === 'Total') {
          dayTime = dayData[0].Billable + dayData[0].NonBillable;
          weekTime = weekData[0].Billable + weekData[0].NonBillable;
          monthTime = monthData[0].Billable + monthData[0].NonBillable;
          yearTime = yearData[0].Billable + yearData[0].NonBillable;
        } else if (theTimeTracker.DataPointMode === 'Billable') {
          dayTime = dayData[0].Billable;
          weekTime = weekData[0].Billable;
          monthTime = monthData[0].Billable;
          yearTime = yearData[0].Billable;
        } else {
          dayTime = dayData[0].NonBillable;
          weekTime = weekData[0].NonBillable;
          monthTime = monthData[0].NonBillable;
          yearTime = yearData[0].NonBillable;
        }

        //Gets percentage for each data point rounded to the nearest 10th.
        let dayPercent = Math.round((dayTime / dayData[0].Goal) * 1000) / 10;

        let weekPercent = Math.round((weekTime / weekData[0].Goal) * 1000) / 10;

        let monthPercent =
          Math.round((monthTime / monthData[0].Goal) * 1000) / 10;

        let yearPercent = Math.round((yearTime / yearData[0].Goal) * 1000) / 10;

        if (theTimeTracker.MeasuredIn === 'Hours') {
          dayTime = Math.round((dayTime / 60) * 10) / 10;
          weekTime = Math.round((weekTime / 60) * 10) / 10;
          monthTime = Math.round((monthTime / 60) * 10) / 10;
          yearTime = Math.round((yearTime / 60) * 10) / 10;

          dayData[0].Goal = Math.round((dayData[0].Goal / 60) * 10) / 10;
          weekData[0].Goal = Math.round((weekData[0].Goal / 60) * 10) / 10;
          monthData[0].Goal = Math.round((monthData[0].Goal / 60) * 10) / 10;
          yearData[0].Goal = Math.round((yearData[0].Goal / 60) * 10) / 10;
        }

        let dayObj = {
          percent: dayPercent,
          time: dayTime,
          goal: dayData[0].Goal
        };

        let weekObj = {
          percent: weekPercent,
          time: weekTime,
          goal: weekData[0].Goal
        };

        let monthObj = {
          percent: monthPercent,
          time: monthTime,
          goal: monthData[0].Goal
        };

        let yearObj = {
          percent: yearPercent,
          time: yearTime,
          goal: yearData[0].Goal
        };

        d.resolve({
          day: dayObj,
          week: weekObj,
          month: monthObj,
          year: yearObj
        });
      });
    return d.promise();
  }

  private GetChartOpts() {
    let theTimeTracker = this;

    let measuredIn = theTimeTracker.MeasuredIn;

    let billableTimeArray = theTimeTracker.TrackedData.Data.map(a => {
      return a.BillableTime;
    });
    let billableTotal = 0;
    $.each(billableTimeArray, (k, v) => {
      billableTotal += v;
    });

    let totalTimeArray = theTimeTracker.TrackedData.Data.map(a => {
      return a.Duration;
    });
    let totalTime = 0;
    $.each(totalTimeArray, (k, v) => {
      totalTime += v;
    });

    if (measuredIn == 'Hours') {
      theTimeTracker.TickInterval = 1;
      theTimeTracker.ChartEndValue = theTimeTracker.LengthOfDay;
      billableTotal = billableTotal / 60;
      totalTime = totalTime / 60;
    } else if (measuredIn == 'Minutes') {
      theTimeTracker.TickInterval = 60;
      theTimeTracker.ChartEndValue = theTimeTracker.LengthOfDay * 60;
    }

    if (theTimeTracker.DataScope === DataScope.Week) {
      theTimeTracker.ChartEndValue *= 5;
    }

    let chartOpts: DevExpress.viz.gauges.dxLinearGaugeOptions = {
      scale: {
        startValue: theTimeTracker.ChartStartValue,
        endValue: theTimeTracker.ChartEndValue,
        tickInterval: theTimeTracker.TickInterval,
        label: {
          customizeText: function (arg) {
            return arg.valueText + ' ' + measuredIn;
          }
        }
      },
      width: '100%',
      subvalueIndicator: {
        offset: 15,
        type: 'rangeBar',
        color: '#5cb85c'
      },
      valueIndicator: {
        offset: 15,
        type: 'rangeBar',
        color: '#656666'
      },
      value: totalTime,
      subvalues: [billableTotal]
      /*tooltip: {
                    enabled: true,
                    customizeTooltip: (e) => {
                        e.subvalue = billableTotal;

                        if (measuredIn === 'Hours') {
                            e.value = e.value * 60;
                            e.subvalue = e.subvalue * 60;
                        }

                        switch (e.type) {
                            case 'value-indicator':
                                e.value = e.value - e.subvalue;

                                return {
                                    text: 'Non-Billable Time: ' + e.value + ' Minutes'
                                };
                            case 'subvalue-indicator':
                                return {
                                    text: 'Billable Time: ' + e.value + ' Minutes'
                                };
                        }
                    }
                }*/
    };

    return chartOpts;
  }

  private GetChart() {
    let theTimeTracker = this;
    let chartOpts: DevExpress.viz.gauges.dxLinearGaugeOptions = theTimeTracker.GetChartOpts();

    theTimeTracker.Chart = $('#' + theTimeTracker.ChartID)
      .dxLinearGauge(chartOpts)
      .dxLinearGauge('instance');
  }

  private CheckBackdated() {
    let theTimeTracker = this;
    let d = $.Deferred();

    let isToday: boolean = theTimeTracker.CheckIsToday();

    if (isToday || theTimeTracker.DataScope === DataScope.Week) {
      return d.promise(d.resolve(false));
    } else {
      confirm(
        'You are trying to add time to a day other than today. Would you like to backdate this note?',
        'Backdate'
      )
        .done(dialogAnswer => {
          d.resolve(dialogAnswer);
        });

      return d.promise();
    }
  }

  private GetTimeItem(selectedItem, quickNoteContainerID) {
    let theTimeTracker = this;

    let theID =
      selectedItem.ItemID || selectedItem['TDF GUID'] || selectedItem.TDFItemID;
    let itemType = selectedItem.ItemType || selectedItem.TDFItemType;

    new TDFRequest({
      url:
        TDFRequest.ApiPath +
        '/quicknotes/ValidNoteParentQuickCheck?itemType=' +
        itemType,
      type: 'GET'
    })
      .MakeRequest()
      .done(valid => {
        if (valid) {
          DisplayInfo(itemTypes.itemActivity).done(function (displayinfo) {
            let dialog = custom({
              message:
                'This item type supports Notes. Which type of item would you like to create?',
              title: 'Add Note?',
              buttons: <Array<DevExpress.ui.dxButtonOptions>>[
                {
                  text: 'Create Note',
                  type: 'default',
                  onClick: e => {
                    return 'note';
                  }
                },
                {
                  text: 'Create ' + displayinfo.DisplayName,
                  type: 'success',
                  onClick: e => {
                    return 'activity';
                  }
                },
                {
                  text: 'Cancel',
                  type: 'danger',
                  onClick: e => {
                    dialog.hide();
                  }
                }
              ]
            });
            dialog.show().done(dialogResult => {
              if (dialogResult === 'note') {
                new TDFRequest({
                  url: TDFRequest.ApiPath + '/quicknotes/notes?itemId=' + theID,
                  type: 'GET'
                })
                  .MakeRequest()
                  .done((response: any) => {
                    // Duration field was messing the note control up by setting its duration field in the background.
                    selectedItem.Duration = 0;

                    theTimeTracker.CheckBackdated().done(backdated => {
                      if (backdated) {
                        selectedItem.NoteDate = theTimeTracker
                          .GetFullDate()
                          .toDate();
                        selectedItem.Backdated = true;
                      } else {
                        selectedItem.NoteDate = moment();
                        selectedItem.Backdated = false;
                      }

                      let quickNotes = new TTQuickNotes(
                        response,
                        $('#' + quickNoteContainerID),
                        selectedItem
                      );

                      quickNotes
                        .generateNotePopup(
                          quickNotes.theData,
                          selectedItem,
                          quickNotes.container
                        )
                        .done(() => {
                          theTimeTracker.RefreshTimeTracker(true, true);
                        });
                    });
                  });
              } else if (dialogResult === 'activity') {
                theTimeTracker.CreateNewLinkedActivity(selectedItem);
              }
            });
          });
        } else {
          theTimeTracker.CreateNewLinkedActivity(selectedItem);
        }
      });
  }

  private CreateNewLinkedActivity(selectedItem) {
    let theTimeTracker = this;
    let theID =
      selectedItem.ItemID || selectedItem['TDF GUID'] || selectedItem.TDFItemID;
    let itemType = selectedItem.ItemType || selectedItem.TDFItemType;

    new TDFRequest({
      url: '/action/getactionsonly',
      type: 'GET',
      data: {
        itemid: theID,
        itemtype: itemType,
        containerType: GridContainerTypes.Unknown
      }
    })
      .MakeRequest()
      .done(response => {
        selectedItem.iMain = response.ActionsModel.iMain;

        let menuAction = new MenuAction(
          {
            Action: menuActions.menuNewActivity,
            AvailableInMobile: true,
            DisplayOptions: ActionDisplayOptions.None,
            Enabled: true,
            ErrorMessageWhenUnavailable: '',
            GroupName: '',
            MenuText: '',
            MenuText2: '',
            RelevantType: itemTypes.itemActivity,
            SHouldAppearInBreadcrumbs: true,
            ShouldAppearInFormMenu: false,
            ShouldAppearInGrid: false,
            SortOrder: 0,
            SupportsMultipleItems: false,
            Visibility: 0
          },
          <any>[selectedItem]
        );
      });
  }

  private BuildPendingTab(
    pendingActivitiesTarget: pendingItemType,
    itemElement: JQuery
  ) {
    let theTimeTracker = this;

    let emailScrollView: DevExpress.ui.dxScrollView;

    let pendingGridContainer = $('<div />').attr(
      'id',
      'pending-grid-' + pendingActivitiesTarget
    );
    let pendingToolbarContainer = $('<div />').attr(
      'id',
      'pending-toolbar-' + pendingActivitiesTarget
    );
    let previewPane = $('<div />').css('margin-top', '10px');

    let toolbar = new ToolbarWithoutViews({
      ToolbarContainerID: 'pending-toolbar-' + pendingActivitiesTarget
    });

    theTimeTracker
      .GetPendingGridOpts(
        pendingActivitiesTarget,
        pendingGridContainer,
        toolbar,
        previewPane
      )
      .done((pendingGrid: GridWithoutViews) => {
        theTimeTracker.GetPendingToolbarExtras(
          toolbar,
          pendingActivitiesTarget,
          pendingGrid
        );

        let outterElement = $('<div />')
          .css('height', 'inherit')
          .append(pendingToolbarContainer)
          .append(pendingGridContainer)
          .append(previewPane);

        itemElement.append(outterElement);

        toolbar.Render();
        pendingGrid.Render();
      });
  }

  private GetPendingGridOpts(
    pendingActivitiesTarget,
    pendingGridContainer,
    toolbar,
    previewPane
  ) {
    let theTimeTracker = this;

    let d: JQueryDeferred<GridWithoutViews> = $.Deferred();

    new TDFRequest({
      url:
        theTimeTracker.TimeTrackerUrlStub +
        'pending-activities?userID=' +
        theTimeTracker.CurrentUser +
        '&includeMode=' +
        pendingActivitiesTarget,
      type: 'GET'
    })
      .MakeRequest()
      .done(response => {
        if (
          pendingActivitiesTarget === pendingItemType.Appointment ||
          pendingActivitiesTarget === pendingItemType.Task
        ) {
          response = response.PendingActivities;
        } else if (pendingActivitiesTarget === pendingItemType.ScreenConnect) {
          response = response.PendingScreenConnects;
        } else if (pendingActivitiesTarget === pendingItemType.PhoneLogging) {
          response = response.PendingPhoneLogs;
        } else {
          response = response.Emails;
        }

        //Fix date formats
        $.each(response, (k, v) => {
          if (
            pendingActivitiesTarget === pendingItemType.Appointment ||
            pendingActivitiesTarget === pendingItemType.Task
          ) {
            let appOrTaskDate = moment(v.Date);
            response[k].Date = appOrTaskDate.toDate().toLocaleDateString();
          } else if (
            pendingActivitiesTarget === pendingItemType.ScreenConnect
          ) {
            let connectDate = moment(v.ConnectedTime);
            response[k].ConnectedTime = connectDate.toDate().toLocaleString();

            let disconnectDate = moment(v.DisconnectedTime);
            response[
              k
            ].DisconnectedTime = disconnectDate.toDate().toLocaleString();
          } else {
            let sentDate = moment(v.SentDate);
            response[k].SentDate = sentDate.toDate().toLocaleDateString();
            response[k].ItemType = itemTypes.itemEmail;
          }
        });

        let pendingGridOpts: DevExpress.ui.dxDataGridOptions = {
          //dataSource: response,
          selection: {
            mode:
              pendingActivitiesTarget === pendingItemType.Email ||
                pendingActivitiesTarget === pendingItemType.PhoneLogging ||
                pendingActivitiesTarget === pendingItemType.ScreenConnect
                ? 'single'
                : 'multiple'
          },
          onRowClick: () => { },
          onSelectionChanged: function (e) {
            let selectedItem = e.selectedRowsData[0];

            if (pendingActivitiesTarget === pendingItemType.Email) {
              let html: string = selectedItem.HtmlBody[0];

              if (!html) {
                html = selectedItem.Body[0];
              }

              let previewContainer = previewPane;
              let temp = $('#previewContent');

              if (!temp.length) {
                temp = $('<div id="previewContent"/>');

                previewContainer.append(temp);

                previewContainer
                  .dxScrollView(<DevExpress.ui.dxScrollViewOptions>{
                    showScrollbar: 'always',
                    direction: 'both',
                    scrollByThumb: true,
                    height: '40%'
                  })
                  .dxScrollView('instance');
              } else {
                temp.empty();
              }

              temp.html(html);
            }

            pendingGrid.DefaultSelectionChanged(e);
          },
          width: '100%',
          height:
            pendingActivitiesTarget === pendingItemType.Email ? '50%' : '90%',
          columns: theTimeTracker.GetPendingGridColumns(pendingActivitiesTarget)
        };

        if (
          pendingActivitiesTarget === pendingItemType.ScreenConnect ||
          pendingActivitiesTarget === pendingItemType.PhoneLogging
        ) {
          pendingGridOpts.onContextMenuPreparing = () => { };

          if (pendingActivitiesTarget === pendingItemType.ScreenConnect) {
            pendingGridOpts.summary = {
              groupItems: [
                {
                  column: 'Duration',
                  summaryType: 'sum'
                }
              ],
              texts: {
                sumOtherColumn: 'Total Duration is {0}'
              }
            };
          }
        }

        let pendingGrid = new GridWithoutViews(
          GetDataResponseObject(response),
          { GridContainer: pendingGridContainer },
          pendingGridOpts,
          '',
          e => {
            if (
              pendingActivitiesTarget !== pendingItemType.ScreenConnect &&
              pendingActivitiesTarget !== pendingItemType.PhoneLogging
            ) {
              toolbar.SelectionChanged(e);
            }
          },
          null,
          'TimeTracker_Pending_' + pendingActivitiesTarget
        );

        d.resolve(pendingGrid);
      });

    return d.promise();
  }

  private GetPendingGridColumns(pendingActivitiesTarget: pendingItemType) {
    let theTimeTracker = this;

    let pendingGridColumns: Array<DevExpress.ui.dxDataGridColumn>;

    if (
      pendingActivitiesTarget === pendingItemType.Appointment ||
      pendingActivitiesTarget === pendingItemType.Task
    ) {
      pendingGridColumns = [
        {
          dataField: 'Date',
          width: 'auto'
        },
        {
          dataField: 'Duration',
          caption: 'Minutes',
          alignment: 'center',
          width: 'auto'
        },
        {
          dataField: 'AccountName',
          width: 'auto'
        },
        {
          dataField: 'Subject',
          minWidth: 200
        }
      ];
    } else if (pendingActivitiesTarget === pendingItemType.ScreenConnect) {
      pendingGridColumns = [
        {
          dataField: 'AccountName',
          width: 'auto',
          groupIndex: 1
        },
        {
          dataField: 'ConnectionName',
          width: 'auto',
          groupIndex: 2
        },
        {
          dataField: 'Duration',
          width: 'auto'
        },
        {
          dataField: 'ConnectedTime',
          width: 'auto'
        },
        {
          dataField: 'DisconnectedTime',
          width: 'auto'
        },
        {
          dataType: 'object'
        }
      ];
    } else if (pendingActivitiesTarget === pendingItemType.PhoneLogging) {
      pendingGridColumns = [
        {
          dataField: 'CallDate',
          width: 'auto'
        },
        {
          dataField: 'AccountName',
          width: 'auto'
        },
        {
          dataField: 'TDFContactName',
          width: 'auto'
        },
        {
          dataField: 'CallType',
          width: 'auto'
        },
        {
          dataField: 'Incoming',
          width: 'auto'
        },
        {
          dataField: 'NumberTo',
          width: 'auto'
        },
        {
          dataField: 'NumberFrom',
          width: 'auto'
        },
        {
          dataField: 'CallStatus',
          width: 'auto'
        },
        {
          dataField: 'Duration',
          width: 'auto'
        },
        {
          dataType: 'object'
        }
      ];
    } else {
      pendingGridColumns = [
        {
          dataField: 'SentDate',
          width: 'auto'
        },
        {
          dataField: 'AccountName',
          width: 'auto'
        },
        {
          dataField: 'Subject',
          minWidth: 200
        },
        {
          dataField: 'From',
          width: 'auto'
        },
        {
          dataField: 'To',
          width: 'auto'
        }
      ];
    }

    return pendingGridColumns;
  }

  private GetPendingToolbarExtras(
    toolbar: ToolbarWithoutViews,
    pendingActivitiesTarget: pendingItemType,
    pendingGrid: GridWithoutViews
  ) {
    let theTimeTracker = this;

    if (
      pendingActivitiesTarget !== pendingItemType.Email &&
      pendingActivitiesTarget !== pendingItemType.ScreenConnect &&
      pendingActivitiesTarget !== pendingItemType.PhoneLogging
    ) {
      let getPendingGridData = (includeMode, viewingDismissed = false) => {
        let d = $.Deferred();

        new TDFRequest({
          url:
            theTimeTracker.TimeTrackerUrlStub +
            'pending-activities?userID=' +
            theTimeTracker.CurrentUser +
            '&includeMode=' +
            includeMode +
            '&getDismissed=' +
            viewingDismissed,
          type: 'GET'
        })
          .MakeRequest()
          .done(pendingResponse => {
            d.resolve(pendingResponse);
          });

        return d.promise();
      };

      toolbar.ExtraItems = [
        {
          location: 'after',
          locateInMenu: 'auto',
          widget: 'dxButton',
          text: '',
          options: <DevExpress.ui.dxButtonOptions>{
            text: 'Dismiss Items',
            elementAttr: {
              id: 'dismiss-retrieve-btn' + pendingActivitiesTarget
            },
            onClick: e => {
              let items = toolbar.SelectedItems.map(a => {
                return { ItemID: a.ItemID, ItemType: a.ItemType };
              });

              let viewingDismissed = $(
                '#toggleDismissed' + pendingActivitiesTarget
              )
                .dxSwitch()
                .dxSwitch('instance')
                .option('value');

              if (viewingDismissed) {
                new TDFRequest({
                  url: theTimeTracker.TimeTrackerUrlStub + 'retrieve-items',
                  type: 'POST',
                  data: items
                })
                  .MakeRequest()
                  .done(dismissResponse => {
                    getPendingGridData(
                      pendingActivitiesTarget,
                      viewingDismissed
                    ).done((response: any) => {
                      pendingGrid.GridObject.option(
                        'dataSource',
                        response.PendingActivities
                      );
                    });
                  });
              } else {
                new TDFRequest({
                  url: theTimeTracker.TimeTrackerUrlStub + 'dismiss-items',
                  type: 'POST',
                  data: items
                })
                  .MakeRequest()
                  .done(dismissResponse => {
                    getPendingGridData(
                      pendingActivitiesTarget,
                      viewingDismissed
                    ).done((response: any) => {
                      pendingGrid.GridObject.option(
                        'dataSource',
                        response.PendingActivities
                      );
                    });
                  });
              }
            }
          }
        },
        {
          location: 'after',
          locateInMenu: 'auto',
          widget: 'dxSwitch',
          text: '',
          options: <DevExpress.ui.dxSwitchOptions>{
            switchedOnText: 'Dismissed',
            switchedOffText: 'Pending',
            elementAttr: {
              id: 'toggleDismissed' + pendingActivitiesTarget
            },
            value: false,
            width: 'auto',
            onValueChanged: e => {
              getPendingGridData(pendingActivitiesTarget, e.value).done(
                (response: any) => {
                  pendingGrid.GridObject.option(
                    'dataSource',
                    response.PendingActivities
                  );

                  if (e.value) {
                    let btn = $(
                      '#dismiss-retrieve-btn' + pendingActivitiesTarget
                    )
                      .dxButton()
                      .dxButton('instance');
                    btn.option('text', 'Retrieve Items');
                  } else {
                    let btn = $(
                      '#dismiss-retrieve-btn' + pendingActivitiesTarget
                    )
                      .dxButton()
                      .dxButton('instance');
                    btn.option('text', 'Dismiss Items');
                  }
                }
              );
            }
          }
        }
      ];
    }
  }

  // Chris's templating example
  RenderTracker2() {
    let theTimeTracker = this;
    let theid = 'someid';
    let TEST = `<div id='${theid}'></div>`;

    //theTimeTracker.Container.append(``);

    let obj = {};
    $.each(obj, (key, value) => {
      $('somecontainer').append(
        `<divclass='col-md-6'><div id='${theid}'></div>${theid}</div>`
      );
    });

    return theTimeTracker;
  }
}

export class Timer {
  public isCounting: boolean = false;
  public item: any;
  public timerID = '';
  public usePopup: boolean;
  public Deferred: JQueryDeferred<{}>;
  private time = 0;
  private dateTime;
  private interval;
  private offset;
  private TimeTrackingUrlStub;
  private TimerDisplayElement: JQuery;
  timerDialog: DevExpress.ui.dxPopup;
  TimerStateChanged: boolean = false;
  ShouldResolveInTimerHide: boolean = true;
  constructor(item, usePopup: boolean = true, dateTime?) {
    let theTimer = this;
    theTimer.item = item;
    theTimer.usePopup = usePopup;
    theTimer.dateTime = dateTime;

    return theTimer;
  }

  Initialize() {
    let theTimer = this;
    theTimer.Deferred = $.Deferred();

    LoadCompany().done(() => {
      theTimer.TimeTrackingUrlStub = TDFRequest.ApiPath + '/time-tracking/';

      new TDFRequest({
        url:
          theTimer.TimeTrackingUrlStub +
          'open-timers?tdfItemID=' +
          theTimer.item.ItemID,
        type: 'GET'
      })
        .MakeRequest()
        .done(infoResponse => {
          if (!infoResponse[0]) {
            theTimer.createTimer().done(() => {
              if (theTimer.usePopup) {
                theTimer.generateTimerPopup();
              } else {
                theTimer.Deferred.resolve();
              }
            });
          } else {
            if (infoResponse[0].CanPause) {
              if (!theTimer.interval) {
                theTimer.interval = setInterval(
                  theTimer.update.bind(theTimer),
                  1e3
                );
              }

              theTimer.isCounting = true;
            }
            theTimer.timerID = infoResponse[0].TimerID;

            theTimer.time =
              infoResponse[0].TotalSeconds + infoResponse[0].RunningSeconds;

            if (theTimer.usePopup) {
              theTimer.generateTimerPopup();
            } else {
              theTimer.Deferred.resolve();
            }

            theTimer.update();
          }
        });
    });

    return theTimer.Deferred.promise();
  }

  generateTimerPopup() {
    let theTimer = this;

    let timerDlgID: string = 'timerDialog';
    if (!$('#' + timerDlgID).length) {
      $('body').append($("<div id='" + timerDlgID + "' />"));
    }

    theTimer.timerDialog = $('#' + timerDlgID)
      .dxPopup({
        title: 'Account: ' + theTimer.item.AccountName,
        onHidden(e) {
          if (theTimer.time === 0) {
            theTimer.delete();
          }
          if (theTimer.ShouldResolveInTimerHide && theTimer.TimerStateChanged) {
            //let thisthing = theTimer.Deferred.progress;
            //debugger
            theTimer.Deferred.resolve();
          }
          if (theTimer.interval) {
            theTimer.Clear();
          }
          e.component.dispose();
        },
        height: 250,
        dragEnabled: true,
        maxWidth: 400,
        showTitle: true,
        toolbarItems: theTimer.getTimerToolbarItems(),
        showCloseButton: true,
        contentTemplate: function (contentElement) {
          contentElement.append(theTimer.getTimerDisplayForm());
        }
      })
      .dxPopup('instance');

    theTimer.timerDialog.show();
  }

  getTimerDisplayForm() {
    let theTimer = this;

    let theForm: DevExpress.ui.dxForm = $('<div />')
      .dxForm({
        items: [
          {
            dataField: 'timerDisplay',
            label: {
              visible: false
            },
            template: (itemData, itemElement) => {
              let timerElement = theTimer.getTimerDisplayElement();

              let content = $('<div />').append(
                $('<div />')
                  .addClass('row')
                  .append(
                    $('<div />')
                      .addClass('col-sm-12')
                      .append(timerElement.css('text-align', 'center'))
                  )
              );

              itemElement.append(content);
            }
          }
        ]
      })
      .dxForm('instance');

    return theForm.element();
  }

  getTimerDisplayElement() {
    let theTimer = this;
    theTimer.TimerDisplayElement = $('<div />')
      .attr('id', 'timer-' + DomSafeID(theTimer.item.ItemID))
      .text('00 : 00 : 00')
      .addClass('e-timerText');

    return theTimer.TimerDisplayElement;
  }

  private getTimerToolbarItems() {
    let theTimer = this;

    let items: Array<DevExpress.ui.dxPopupToolbarItem> = [
      {
        location: 'before',
        toolbar: 'bottom',
        widget: 'dxButton',
        options: theTimer.getTimerStartStopBtnOpts()
      },
      {
        location: 'before',
        toolbar: 'bottom',
        widget: 'dxButton',
        options: theTimer.getTimerResetBtnOpts()
      },
      {
        location: 'after',
        toolbar: 'bottom',
        widget: 'dxButton',
        options: {
          text: 'Add Note',
          type: 'success',
          onClick: function (e) {
            theTimer.timerDialog.hide().done(() => {
              new TDFRequest({
                url:
                  TDFRequest.ApiPath +
                  '/quicknotes/notes?itemId=' +
                  theTimer.item.ItemID,
                type: 'GET'
              })
                .MakeRequest()
                .done((response: any) => {
                  let quickNoteContainerID = 'quickNoteContainer';
                  if (!$('#' + quickNoteContainerID).length) {
                    $('body').append(
                      $('<div id=' + quickNoteContainerID + ' />')
                    );
                  }
                  theTimer.ShouldResolveInTimerHide = false;

                  // Duration field was messing the note control up by setting its duration field in the background.
                  theTimer.item.Duration = 0;

                  if (theTimer.dateTime) {
                    confirm(
                      'You are trying to add time to a day other than today. Would you like to backdate this note?',
                      'Backdate'
                    )
                      .done(dialogAnswer => {
                        if (dialogAnswer) {
                          theTimer.item.NoteDate = theTimer.dateTime.toDate();
                          theTimer.item.Backdated = true;
                        }

                        let quickNotes = new TTQuickNotes(
                          response,
                          $('#' + quickNoteContainerID),
                          theTimer.item
                        );

                        quickNotes
                          .generateNotePopup(
                            quickNotes.theData,
                            theTimer.item,
                            quickNotes.container,
                            false,
                            theTimer
                          )
                          .done(() => {
                            theTimer.Deferred.resolve();
                          });
                      });
                  } else {
                    let quickNotes = new TTQuickNotes(
                      response,
                      $('#' + quickNoteContainerID),
                      theTimer.item
                    );

                    quickNotes
                      .generateNotePopup(
                        quickNotes.theData,
                        theTimer.item,
                        quickNotes.container,
                        false,
                        theTimer
                      )
                      .done(() => {
                        theTimer.Deferred.resolve();
                      });
                  }
                });
            });
          }
        }
      }
    ];

    return items;
  }

  public getTimerStartStopBtnOpts() {
    let theTimer = this;

    let startStop: DevExpress.ui.dxButtonOptions = {
      //text: (theTimer.isCounting) ? "Stop" : "Start",
      icon: theTimer.isCounting ? 'fa fa-stop' : 'fa fa-play',
      elementAttr: {
        id: 'toggleBtn'
      },
      type: 'normal',
      onClick: function (e) {
        if (theTimer.isCounting) {
          theTimer.stop();
          e.component.option('icon', 'fa fa-play');
        } else {
          theTimer.start();
          e.component.option('icon', 'fa fa-stop');
        }
      }
    };

    return startStop;
  }

  public getTimerResetBtnOpts() {
    let theTimer = this;

    let reset: DevExpress.ui.dxButtonOptions = {
      //text: "Reset",
      icon: 'fa fa-repeat',
      type: 'normal',
      onClick: function (e) {
        let dialog = confirm(
          'Are you sure you want to reset the timer?',
          'Reset?'
        );
        dialog.done(dialogResult => {
          if (dialogResult) {
            theTimer.reset();
            $('#toggleBtn')
              .dxButton('instance')
              .option('icon', 'fa fa-play');
          }
        });
      }
    };

    return reset;
  }

  public getTimeInMin() {
    let theTimer = this;
    return Math.ceil(theTimer.time / 60);
  }

  public getTime() {
    let theTimer = this;
    return theTimer.time;
  }

  public Clear() {
    let theTimer = this;
    if (theTimer.interval) {
      clearInterval(theTimer.interval);
      theTimer.interval = null;
    }
  }

  public start() {
    let theTimer = this;
    if (!theTimer.isCounting) {
      if (theTimer.timerID) {
        new TDFRequest({
          url: theTimer.TimeTrackingUrlStub + 'timer-action',
          type: 'POST',
          data: { TimerID: theTimer.timerID, TimerAction: 'StartOrContinue' }
        })
          .MakeRequest()
          .done(response => {
            theTimer.offset = Date.now();
            theTimer.interval = setInterval(
              theTimer.update.bind(theTimer),
              1e3
            );
            theTimer.isCounting = true;
            theTimer.TimerStateChanged = true;
          });
      } else {
        theTimer.createTimer().done(() => {
          new TDFRequest({
            url: theTimer.TimeTrackingUrlStub + 'timer-action',
            type: 'POST',
            data: { TimerID: theTimer.timerID, TimerAction: 'StartOrContinue' }
          })
            .MakeRequest()
            .done(response => {
              theTimer.offset = Date.now();
              theTimer.interval = setInterval(
                theTimer.update.bind(theTimer),
                1e3
              );
              theTimer.isCounting = true;
              theTimer.TimerStateChanged = true;
            });
        });
      }
    }
  }

  public stop() {
    let theTimer = this;
    if (theTimer.isCounting) {
      //let now = Date.now();
      new TDFRequest({
        url: theTimer.TimeTrackingUrlStub + 'timer-action',
        type: 'POST',
        data: {
          TimerID: theTimer.timerID,
          TimerAction: 'PauseTimer',
          CloseTimer: false
        }
      })
        .MakeRequest()
        .done(response => {
          theTimer.Clear();
          theTimer.interval = null;
          theTimer.isCounting = false;
          theTimer.TimerStateChanged = true;
        });
    }
  }

  public reset() {
    let theTimer = this;
    theTimer.time = 0;
    theTimer.Clear();
    theTimer.interval = null;
    theTimer.isCounting = false;

    new TDFRequest(
      {
        url: theTimer.TimeTrackingUrlStub + 'timer-action',
        type: 'POST',
        data: {
          TimerID: theTimer.timerID,
          TimerAction: 'PauseTimer',
          CloseTimer: true
        }
      },
      false
    )
      .MakeRequest()
      .done(() => {
        theTimer.timerID = null;
        theTimer.TimerStateChanged = true;
      });

    let formattedTime = theTimer.timeFormat(theTimer.time);
    theTimer.TimerDisplayElement.text(formattedTime);
  }

  public delete() {
    let theTimer = this;

    new TDFRequest({
      url: theTimer.TimeTrackingUrlStub + 'delete-timer/' + theTimer.timerID,
      type: 'DELETE'
    })
      .MakeRequest()
      .done(response => {
        theTimer.timerID = null;
        theTimer.time = 0;
      });
  }

  private createTimer() {
    let theTimer = this;
    let d = $.Deferred();

    new TDFRequest({
      url: theTimer.TimeTrackingUrlStub + 'new-timer',
      type: 'POST',
      data: { TDFItemID: theTimer.item.ItemID }
    })
      .MakeRequest()
      .done(newResponse => {
        if (newResponse.TimerID) {
          theTimer.timerID = newResponse.TimerID;
          theTimer.time = 0;
          theTimer.interval = null;
        }

        d.resolve();
      });
    return d.promise();
  }

  private update() {
    let theTimer = this;

    if (theTimer.isCounting) {
      theTimer.time = theTimer.time + theTimer.delta();
    }

    let formattedTime = theTimer.timeFormat(theTimer.time);
    if (theTimer.TimerDisplayElement) {
      theTimer.TimerDisplayElement.text(formattedTime);
    }
  }

  private delta() {
    let theTimer = this;
    let now = Date.now();
    if (!theTimer.offset) {
      theTimer.offset = now;
    }
    let timePassed = now - theTimer.offset;
    timePassed = timePassed / 1000; //Math.floor(timePassed / 1000);
    theTimer.offset = now;
    return timePassed;
  }

  private timeFormat(timeInSeconds) {
    let h = Math.floor(timeInSeconds / 3600).toString();
    let m = Math.floor((timeInSeconds % 3600) / 60).toString();
    let s = Math.floor((timeInSeconds % 3600) % 60).toString();

    if (h.length < 2) {
      h = '0' + h;
    }
    if (m.length < 2) {
      m = '0' + m;
    }
    if (s.length < 2) {
      s = '0' + s;
    }

    return h + ' : ' + m + ' : ' + s;
  }
}

export class WorkListGrid {
  CenterType = 999;
  grid: GridWithoutViews;
  toolbar: ToolbarWithoutViews;
  theTimeTracker: TimeTracker;

  constructor(tt: TimeTracker) {
    let theWorkListGrid = this;

    theWorkListGrid.theTimeTracker = tt;

    return theWorkListGrid;
  }

  getToolbar() {
    let theWorkListGrid = this;

    theWorkListGrid.toolbar = new ToolbarWithoutViews({
      ExtraItems: [
        {
          widget: 'dxButton',
          options: <DevExpress.ui.dxButtonOptions>{
            icon: 'clock',
            elementAttr: {
              class: GetDevice().isDevice
                ? ''
                : 'infocenter-toolbar-buttons hidden',
              id: theWorkListGrid.theTimeTracker.ManageTimerBtnID
            },
            text: '',
            onClick: function () {
              // Get id of item selected in the grid
              let data = theWorkListGrid.grid.GridObject.getSelectedRowsData();
              let selected = data[0];

              theWorkListGrid.CreateTimer(selected);
            }
          },
          location: 'before'
        },
        {
          widget: 'dxButton',
          options: <DevExpress.ui.dxButtonOptions>{
            text: 'Add Time',
            elementAttr: { id: theWorkListGrid.theTimeTracker.AddTimeBtnID },
            type: 'success',
            onClick() {
              theWorkListGrid.theTimeTracker.CreateNewTimeItem();
            }
          },
          location: 'before'
        },
        {
          widget: 'dxButton',
          options: <DevExpress.ui.dxButtonOptions>{
            text: 'Pending',
            elementAttr: { id: theWorkListGrid.theTimeTracker.PendingBtnID },
            type: 'normal',
            onClick() {
              theWorkListGrid.theTimeTracker.GeneratePendingPopup();
            }
          },
          location: 'before',
          locateInMenu: GetDevice().isDevice ? 'always' : 'auto'
        }
        //{
        //    location: 'before',
        //    locateInMenu:GetDevice().isDevice ? 'always' : 'auto',
        //    template: function (itemData, itemIndex, itemElement) {
        //        itemElement.append($("<div />")
        //            .addClass("timetracker-timer-count panel-box timer-box")
        //            .text('Current Timers: ' + theWorkListGrid.theTimeTracker.TimerCount));
        //    }
        //}
      ]
    });

    theWorkListGrid.toolbar.Render();
  }

  LoadGridData() {
    let theWorkListGrid = this;

    let workItems = theWorkListGrid.theTimeTracker.SetWorkItems();
    let gr = {
      Data: workItems,
      GridView: {
        Layout: {
          Columns: [
            {
              dataField: 'ItemType',
              caption: '',
              cellTemplate: function (container: JQuery, options: any) {
                container.append(
                  $('<span />').addClass(
                    'tdfitem' + parseInt(options.data.ItemType)
                  )
                );
              },
              allowFiltering: false,
              allowGrouping: false,
              alignment: 'center',
              width: 'auto'
            },
            {
              dataField: 'AccountName',
              caption: 'Account Name',
              visible: true,
              width: 'auto'
            },
            {
              dataField: 'UniqueID',
              visible: true,
              width: 'auto'
            },
            {
              dataField: 'Duration',
              caption: 'Total Minutes',
              alignment: 'center',
              width: 'auto',
              visible: true
            },
            {
              dataField: 'Timer',
              caption: 'Timer',
              allowFiltering: false,
              cellTemplate: function (container, data) {
                let icon = '';
                let timer = data.data.Timers[0];

                if (timer) {
                  if (timer.CanContinue) {
                    icon = 'fa fa-clock-o timer-inactive';
                  } else {
                    icon = 'fa fa-clock-o timer-active';
                  }
                }

                $('<div />')
                  .append(
                    $('<span>')
                      .addClass(icon)
                      .css('cursor', 'pointer')
                      .on(
                        'click',
                        Debounce(
                          () => {
                            theWorkListGrid.CreateTimer(data.data);
                          },
                          1e3,
                          true
                        )
                      )
                  )
                  .appendTo(container);
              },
              width: 'auto',
              visible: true
            },
            {
              dataField: 'Subject',
              caption: 'Description',
              minWidth: 200,
              visible: true
            },
            {
              dataField: 'ItemID',
              visible: false
            }
          ]
        }
      }
    };
    theWorkListGrid.RenderGrid(GetDataResponseObject(gr));
    // theWorkListGrid.grid.GridObject.option("dataSource", workItems);

    $('.timetracker-timer-count').text(
      'Current Timers: ' + theWorkListGrid.theTimeTracker.TimerCount
    );
  }

  RenderGrid(gridresponse) {
    let theWorkListGrid = this;
    let d = $.Deferred();
    let selectionChangedRaised = false;
    let previouslySelectedRow;

    theWorkListGrid.grid = new GridWithoutViews(
      gridresponse,
      { GridContainer: $('#worklistgrid') },
      {
        height: () => {
          let gridHeight = $(window).innerHeight() - 450;

          if (gridHeight < 400) {
            gridHeight = 400;
          }

          return gridHeight + 'px';
        },
        onSelectionChanged: function (selectedItems) {
          selectionChangedRaised = true;

          theWorkListGrid.grid.DefaultSelectionChanged(selectedItems);
        },
        selection: {
          mode: 'single'
        },
        onRowClick: function (e: any) {
          var dataGrid = e.component;
          var keys = dataGrid.getSelectedRowKeys();
          if (!selectionChangedRaised || keys === previouslySelectedRow) {
            dataGrid.clearSelection();
          }
          selectionChangedRaised = false;
          previouslySelectedRow = keys;
        },

        masterDetail: {
          enabled: true,
          template: theWorkListGrid.MasterDetailTemplate.bind(theWorkListGrid)
        },
        summary: {
          totalItems: [
            {
              column: 'Timer',
              summaryType: 'sum',
              customizeText: e => {
                return 'Current Timers: ' + e.value;
              }
            },
            {
              column: 'Duration',
              summaryType: 'sum',
              customizeText: e => {
                return 'Minutes: ' + e.value;
              }
            },
            {
              column: 'Duration',
              summaryType: 'sum',
              customizeText: e => {
                return 'Hours: ' + Math.round((<number>e.value / 60) * 10) / 10;
              }
            }
          ]
        }
      },
      theWorkListGrid,
      e => {
        theWorkListGrid.toolbar.SelectionChanged(e);
      },
      null,
      'TimeTracker'
    );

    theWorkListGrid.grid.Render();
  }

  MasterDetailTemplate(container, options) {
    let theWorkListGrid = this;

    let currentItemData = options.data;
    container.addClass('internal-grid-container');
    $('<div>')
      .text('Individual Item Notes')
      .appendTo(container);
    $('<div>')
      .addClass('internal-grid')
      .dxDataGrid(<DevExpress.ui.dxDataGridOptions>{
        columnAutoWidth: true,
        showBorders: true,
        showColumnLines: true,
        showRowLines: true,
        allowColumnResizing: true,
        columnResizingMode: 'widget',
        columns: [
          {
            caption: 'Open',
            cellTemplate: (container: JQuery, options: any) => {
              container.append(
                $('<span />')
                  .addClass('icon-open')
                  .css('cursor', 'pointer')
                  .on('click', () => {
                    if (!theWorkListGrid.theTimeTracker.IsImpersonating) {
                      if (options.data.Classification === 0) {
                        new TDFRequest({
                          url:
                            TDFRequest.ApiPath +
                            '/quicknotes/notes?itemId=' +
                            options.data.ItemID,
                          type: 'GET'
                        })
                          .MakeRequest()
                          .done((response: any) => {
                            let quickNoteContainerID = 'quickNoteContainer';
                            if (!$('#' + quickNoteContainerID).length) {
                              $('body').append(
                                $('<div id=' + quickNoteContainerID + ' />')
                              );
                            }

                            let quickNotes = new TTQuickNotes(
                              response,
                              $('#' + quickNoteContainerID),
                              currentItemData
                            );

                            let selectedNote = quickNotes.theData.TheNoteList.filter(
                              e => {
                                return e.NoteID === options.data.NoteID;
                              }
                            );

                            quickNotes
                              .generateNotePopup(
                                quickNotes.theData,
                                selectedNote[0],
                                quickNotes.container,
                                true
                              )
                              .done(() => { });
                          });
                      } else if (options.data.Classification === 1) {
                        OpenItemOrURL(
                          {
                            itemid: options.data.NoteID,
                            itemtype: itemTypes.itemActivity
                          },
                          [options.data]
                        );
                      }
                    } else {
                      new Notification({
                        message:
                          'You cannot open notes while impersonating another user.',
                        type: 'error'
                      });
                    }
                  })
              );
            },
            width: 'auto',
            alignment: 'center'
          },
          {
            dataField: 'Classification',
            caption: 'Sub-Item Type',
            cellTemplate: function (container: JQuery, options: any) {
              let type = parseInt(options.data.Classification);
              let itemType;
              if (type) {
                itemType = itemTypes.itemActivity;
              } else {
                itemType = itemTypes.itemNote;
              }
              container.append($('<span />').addClass('tdfitem' + itemType));
            },
            alignment: 'center',
            width: 'auto'
          },
          {
            dataField: 'Duration',
            caption: 'Minutes',
            alignment: 'center',
            width: 'auto'
          },
          {
            dataField: 'Billable',
            caption: 'Billable?',
            width: 'auto'
          },
          {
            dataField: 'NoteText',
            caption: 'Notes',
            minWidth: 200
          }
        ],
        dataSource: currentItemData.Notes
      })
      .appendTo(container);
  }

  CreateTimer(selected) {
    let theWorkListGrid = this;
    let theTimer;

    let isToday: boolean = theWorkListGrid.theTimeTracker.CheckIsToday();

    if (isToday) {
      theTimer = new Timer(selected);
    } else {
      theTimer = new Timer(
        selected,
        true,
        theWorkListGrid.theTimeTracker.GetFullDate()
      );
    }

    theTimer.Initialize().done(() => {
      if (theTimer.ShouldResolveInTimerHide) {
        theWorkListGrid.theTimeTracker.RefreshTimeTracker(false, true);
      } else {
        theWorkListGrid.theTimeTracker.RefreshTimeTracker(true, true);
      }
    });
  }
}
