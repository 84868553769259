import { ItemBase } from "./itembase";
import { itemTypes } from "../../enums/enums";
import { DisplayInfo } from "../../infrastructure/context";

export class Activity extends ItemBase {
  get TypeName() {
    return "Activity";
  }
  get ItemType() {
    return itemTypes.itemActivity;
  }
  set TypeName(name) {
    if (name && this.TypeName !== name) {
      this.TypeName = name;
    }
  }
  get RequiresParentItem(): boolean {
    return true;
  }
  public DisplayName: string;

  constructor(args) {
    super(args);
    const theActivity = this;
    $.each(args, function(key, val) {
      if (theActivity.hasOwnProperty(key)) {
        theActivity[key] = val;
      }
    });
    if (!theActivity.DisplayName) {
      let displayinfo;
      DisplayInfo(theActivity.ItemType).done(function(response) {
        displayinfo = response;
        theActivity.DisplayName = displayinfo.DisplayName;
        theActivity.TypeName = displayinfo.TypeName;
      });
    }
  }
}
