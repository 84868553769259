import { Grid } from "../../../legacy-centers-grids/grid";
import { IGridToolbarWOViewsModel } from "../../../interfaces/interfaces";
import { TDFRequest } from "../../../services/request";
import { Preferences } from "../../../infrastructure/user/preferences";
import { Notification } from "../../dialogs/notification";
import { itemTypes } from "../../../enums/enums";
import { ItemNavigator } from "../../items/itemnavigator";
import { BSIGrids } from "../../../enums/bi/enums";
import { GetPreference } from "../../../infrastructure/user/getpreference";
import {
  GetDevice,
  Debounce,
  GetActionRequestParamsFromRowData,
  OpenItemOrURL,
  RenderActionsPopup,
  RenderViewsPopup,
  LegacyFloatingMenu
} from "../../../util/allutils";
import { DisplayInfo } from "../../../infrastructure/context";
import { createitem } from 'components/items/factory/itemfactory';

export class ToolbarWithoutViews {
  /**
   * The TDF web grid obejct the toolbar should act on
   */
  CurrentGrid: Grid;

  /**
   * Interface for Devexpress toolbar options.
   */
  Options: DevExpress.ui.dxToolbarOptions = {};
  /**
   * The id of the html element that will hold the toolbar.
   */
  ToolbarContainerID: string;
  /*
        A reference to the Devextreme dxToolbar class.
        */
  ToolbarObject: DevExpress.ui.dxToolbar;
  /**
   * Addtional items you would like added to the grid toolbar.
   */
  ExtraItems: any;
  /**
   * Selected rows of an associated grid.
   */
  SelectedItems: Array<any>;
  ContainerItemID: string;
  constructor(args: IGridToolbarWOViewsModel) {
    let theToolbar = this;
    $.each(args, (k, v) => {
      theToolbar[k] = v;
    });
  }

  /**
   * Array of toolbar items used in the datasource of the dxtoolbar
   */
  ToolbarItems() {
    let theToolbar = this;

    let items: DevExpress.ui.dxToolbarItem[] = [
      {
        location: "before",
        locateInMenu: "auto",
        widget: "dxButton",
        visible: !GetDevice().isDevice,
        text: "",
        options: theToolbar.openbutton()
      },
      {
        location: "before",
        locateInMenu: "auto",
        widget: "dxButton",
        text: "",
        visible: !GetDevice().isDevice,
        options: theToolbar.actionbutton()
      },
      {
        location: "before",
        locateInMenu: "auto",
        widget: "dxButton",
        text: "",
        visible: !GetDevice().isDevice,
        options: theToolbar.viewbutton()
      }
    ];
    if (theToolbar.ExtraItems && theToolbar.ExtraItems.length > 0) {
      $.each(theToolbar.ExtraItems, (key, val) => {
        items.push(val);
      });
    }

    return items;
  }

  /**
   * Render the toolbar on the toolbar container id and set the properties for the toolbar controls
   */
  Render() {
    let theToolbar = this;
    if (!theToolbar.Options.dataSource)
      theToolbar.Options.dataSource = theToolbar.ToolbarItems();

    if (theToolbar.ToolbarContainerID) {
      theToolbar.ToolbarObject = $("#" + theToolbar.ToolbarContainerID)
        .css({ margin: "10px 0" })
        .dxToolbar(theToolbar.Options)
        .dxToolbar("instance");
    } else {
      if (
        theToolbar.ToolbarContainerID &&
        $(theToolbar.ToolbarContainerID).length
      ) {
        theToolbar.ToolbarObject = $(theToolbar.ToolbarContainerID)
          .css({ margin: "10px 0" })
          .dxToolbar(theToolbar.Options)
          .dxToolbar("instance");
      } else {
        theToolbar.ToolbarObject = $("<div />")
          .css({ margin: "10px 0" })
          .dxToolbar(theToolbar.Options)
          .dxToolbar("instance");
      }
    }
  }

  private CheckForSelected() {
    let theToolbar = this;
    if ((theToolbar as any).CurrentGrid) {
      let theGrid = (theToolbar as any).CurrentGrid;
      let selected: any = theGrid.GridObject.getSelectedRowsData();
      if (
        selected &&
        selected.length &&
        (!theToolbar.SelectedItems || theToolbar.SelectedItems.length <= 0)
      ) {
        theToolbar.SelectedItems = selected;
      }
    }
  }
  /**
   * get the options for the floating open button
   */
  protected openbutton() {
    let theToolbar = this;
    return {
      icon: "dx-icon icon-open",
      elementAttr: {
        class: "infocenter-toolbar-buttons invisible",
        id: "itemOpen"
      },
      text: "",
      onClick: Debounce(
        () => {
          theToolbar.CheckForSelected();
          if (
            !theToolbar.SelectedItems ||
            theToolbar.SelectedItems.length <= 0
          ) {
            return;
          }
          if (theToolbar.SelectedItems.length === 1) {
            //let rowdata = selected[0];
            //let iMain: ItemMain;
            // let itemInfo = rowdata;
            let requestParams = GetActionRequestParamsFromRowData(
              theToolbar.SelectedItems[0]
            );

            if (requestParams.itemtype === itemTypes.itemMailing) {
              DisplayInfo(requestParams.itemtype).done(info => {
                //TODO:Figure this out when not in namespace TDF
                // new window[info.TypeName](
                //   theToolbar.SelectedItems[0]
                // ).Initialize();
                createitem(requestParams.itemtype, theToolbar.SelectedItems[0], false, true)
              });
            } else {
              OpenItemOrURL(requestParams, theToolbar.SelectedItems);
            }
          } else {
            //AARONS TODO: Check this
            //if (!theToolbar.SelectedItems[0].ItemType) {
            //    theToolbar.SelectedItems[0].ItemType = theGrid.GridToolbar.GridItemType;
            //}

            theToolbar.OpenNavigator();
          }
        },
        250,
        true
      )
    };
  }
  /**
   * get the options for the floating action button
   */
  protected actionbutton() {
    let theToolbar = this;
    return {
      icon: "dx-icon icon-runningMan",
      elementAttr: {
        class: "infocenter-toolbar-buttons invisible",
        id: "itemAction"
      },
      text: "",
      //visible: theToolbar.GridItemType === itemTypes.itemProduct ? false : true,
      onClick(e) {
        theToolbar.CheckForSelected();
        let actionRequestParams = GetActionRequestParamsFromRowData(
          theToolbar.SelectedItems[0]
        );
        let theActions;

        let request = new TDFRequest({
          url: "/action/getactionsonly/",
          type: "GET",
          data: actionRequestParams
        });
        request.MakeRequest().done((response: any) => {
          $.each(response.ActionsModel.ActionList, (key, val: any) => {
            val.text = val.MenuText;
            val.iMain = response.ActionsModel.iMain;
            val.disabled =
              theToolbar.SelectedItems.length > 1
                ? !val.SupportsMultipleItems
                : false;
            val.visible =
              theToolbar.SelectedItems.length > 1
                ? val.SupportsMultipleItems
                : true;

            // TODO: This is a temporary fix to get 2018R1 out. Please remove once action has a web implementation.
            if (val.MenuText === "Launch Web Access Log") {
              val.visible = false;
            }
          });

          //rowdata.Actions = response.ActionsModel.ActionList;       // DO NOT modify the rowdata as it causes the grid to lose track of what is selected

          theActions = response.ActionsModel.ActionList;
          if (
            (actionRequestParams.itemtype === itemTypes.itemContact ||
              actionRequestParams.itemtype === itemTypes.itemLead) &&
            response.ActionsModel.ContactEmail
          ) {
            theActions.ContactEmail = response.ActionsModel.ContactEmail;
          }

          RenderActionsPopup(
            e.element,
            theActions,
            theToolbar.SelectedItems,
            theToolbar.ContainerItemID
          );
        });
      }
    };
  }

  /**
   * get the options for the floating views button
   */
  protected viewbutton() {
    let theToolbar = this;
    return {
      icon: "dx-icon icon-window",
      elementAttr: {
        class: "infocenter-toolbar-buttons invisible",
        id: "itemView"
      },
      text: "",
      //visible: theToolbar.GridItemType === itemTypes.itemProduct ? false : true,
      onClick(e) {
        theToolbar.CheckForSelected();
        if (!theToolbar.SelectedItems || theToolbar.SelectedItems.length <= 0) {
          return;
        }
        let rowdata = theToolbar.SelectedItems[0];
        let itemInfo = rowdata;

        let actionRequestParams = GetActionRequestParamsFromRowData(itemInfo);
        let theViews;
        let request = new TDFRequest({
          url: "/action/getviewsonly/",
          type: "GET",
          data: actionRequestParams
        });
        request.MakeRequest().done((response: any) => {
          $.each(response.ActionsModel.ViewList, (key, val: any) => {
            val.text = val.MenuText;
          });
          theViews = response.ActionsModel.ViewList;
          RenderViewsPopup(e.element, theViews, itemInfo);
        });
      }
    };
  }

  ChangeCardViewOption() {
    let theToolbar = this;
    return {
      location: "after",
      locateInMenu: "always",
      widget: "dxButton",
      showText: "inMenu",
      visible: GetDevice().isDevice,
      options: {
        icon:
          Preferences.GetPreference("UseCardView", "WebGrid", "1") === "1"
            ? "fa fa-table"
            : "fa fa-th-list",
        text:
          Preferences.GetPreference("UseCardView", "WebGrid", "1") === "1"
            ? "Show as grid"
            : "Show as cards",
        //type: "default",
        onClick: function (e) {
          switch (Preferences.GetPreference("UseCardView", "WebGrid", "1")) {
            case "1":
              Preferences.SetPreference(
                "UseCardView",
                "0",
                "WebGrid",
                true
              ).done(() => {
                e.component.option("icon", "fa fa-th-list");
                e.component.option("text", "Show as cards");
                if (theToolbar.CurrentGrid && theToolbar.CurrentGrid.Render) {
                  let id = $(theToolbar.CurrentGrid.GridContainer).attr("id");
                  if (id) {
                    let newContainer = $(`<div id="${id}"/>`);
                    (theToolbar.CurrentGrid.GridContainer as any).replaceWith(
                      newContainer
                    );
                    theToolbar.CurrentGrid.GridContainer = <any>$(`#${id}`);
                  }
                  theToolbar.CurrentGrid.Render();
                }
              });
              break;
            case "0":
              Preferences.SetPreference(
                "UseCardView",
                "1",
                "WebGrid",
                true
              ).done(() => {
                e.component.option("icon", "fa fa-table");
                e.component.option("text", "Show as grid");
                if (theToolbar.CurrentGrid && theToolbar.CurrentGrid.Render) {
                  let id = $(theToolbar.CurrentGrid.GridContainer).attr("id");
                  if (id) {
                    let newContainer = $(`<div id="${id}"/>`);
                    (theToolbar.CurrentGrid.GridContainer as any).replaceWith(
                      newContainer
                    );
                    theToolbar.CurrentGrid.GridContainer = <any>$(`#${id}`);
                  }
                  theToolbar.CurrentGrid.Render();
                }
              });
              break;
          }
          // theToolbar.FullRefreshCurrentGrid(e);
        }
      }
    };
  }

  OpenNavigator() {
    let theToolbar = this;

    let navigatorAllowed: boolean = true;
    let multipleItemTypes: boolean = false;
    let ids = [];

    let itemtype = theToolbar.SelectedItems[0].ItemType; //theGrid.GridToolbar ? theGrid.GridToolbar.GridItemType : selected[0].ItemType;
    // The above line causes an issue for BI Group Summary where the grid data is of type Account, but the Grid Item Type is of type Acct Group Summary.
    //  I think the above line could be changed to use selected[0].ItemType, but at this point in R1, I'm not going to risk causing an issue for something else.
    // So if we are in BI Account Group summary, set the item type to Account so that the navigator knows how to handle it.

    if (!itemtype) {
      itemtype = (theToolbar as any).GridItemType || 0;
    }
    $.each(theToolbar.SelectedItems, function (k, v) {
      if (!v.ItemType) {
        v.ItemType = itemTypes.itemProduct;
      }

      if (v.ItemType === BSIGrids.Acct) {
        v.ItemType = itemTypes.itemAccount;
      }

      if (v.ItemType !== itemTypes.itemProduct) {
        ids.push(v["TDF GUID"]);
      } else {
        ids.push({
          LinkID: v["LinkID"]
        });
      }

      if (
        parseInt(v.ItemType) === itemTypes.itemQuote ||
        parseInt(v.ItemType) === itemTypes.itemExpense
      ) {
        navigatorAllowed = false;
      }
      if (v.ItemType !== itemtype) {
        multipleItemTypes = true;
      }
    });

    if (navigatorAllowed) {
      if (multipleItemTypes) {
        new ItemNavigator(ids, itemTypes.itemUnknown);
      } else {
        new ItemNavigator(ids, parseInt(itemtype));
      }
    } else {
      new Notification({
        message:
          "Multiple items detected: At least one of the selected types does not support the item navigator.",
        type: "warning",
        displayTime: 5000000
      });
    }
  }

  /**
   *
   * @param e
   */
  SelectionChanged(e) {
    let someToolbar = this;
    let allBtnSearchString = GetDevice().isDevice
      ? ".center-float-menu"
      : ".infocenter-toolbar-buttons";
    let viewBtnSearchString = GetDevice().isDevice
      ? "icon-windowCircle center-menu-float-btn"
      : "#itemView";
    let actionBtnSearchString = GetDevice().isDevice
      ? "icon-runningManCircle center-menu-float-btn"
      : "#itemAction";

    someToolbar.SelectedItems = e.selectedRowsData;

    if (someToolbar.SelectedItems && someToolbar.SelectedItems.length > 0) {
      let info = GetActionRequestParamsFromRowData(
        someToolbar.SelectedItems[0]
      );
      if (
        info &&
        info.itemtype
        //AARONS TODO: Check this.
        //&& (theGridWOViews.ObjRef && theGridWOViews.ObjRef.CurrentSelectedItem ?
        //(((theGridWOViews.ObjRef.CurrentSelectedItem.Type === 514) || (theGridWOViews.ObjRef.CurrentSelectedItem.Type === 740)) ? true : info.itemtype === theGridWOViews.ObjRef.CurrentSelectedItem.Type)
        //    : true)
      ) {
        DisplayInfo(info.itemtype).done(function (displayInfo) {
          //TODO:Figure this out when not in namespace TDF
          // if (!window[displayInfo.TypeName]) {
          //   if (!window[displayInfo.DisplayName]) {
          // Commenting out to test for now.
          //     return;
          //   }
          // }

          //if on a phone or mobile show floating buttons
          if (GetDevice().isDevice) {
            if (someToolbar.SelectedItems.length) {
              LegacyFloatingMenu("", someToolbar.SelectedItems);
            }
          } else {
            if (someToolbar.SelectedItems.length === 1) {
              someToolbar.ToolbarObject.element().find(allBtnSearchString).removeClass("invisible");
              someToolbar.ToolbarObject.element().removeClass("invisible");
              if (displayInfo.TypeNum === itemTypes.itemProduct) {
                someToolbar.ToolbarObject.element().find(actionBtnSearchString).addClass("invisible");
                someToolbar.ToolbarObject.element().find(viewBtnSearchString).addClass("invisible");
              }
            } else if (someToolbar.SelectedItems.length > 1) {
              someToolbar.ToolbarObject.element().find(allBtnSearchString).removeClass("invisible");
              someToolbar.ToolbarObject.element().find(viewBtnSearchString).addClass("invisible"); // no views on multiple items
              if (displayInfo.TypeNum === itemTypes.itemProduct) {
                someToolbar.ToolbarObject.element().find(actionBtnSearchString).addClass("invisible");
              }
            } else {
              someToolbar.ToolbarObject.element().find(allBtnSearchString).addClass("invisible");
            }
          }
        });
      }
    } else {
      if (GetDevice().isDevice) {
        LegacyFloatingMenu("", someToolbar.SelectedItems);
      } else {
        let buttons = someToolbar.ToolbarObject.element().find(allBtnSearchString);
        if (buttons.length) {
          buttons.addClass("invisible");
        }
      }
    }
  }
}
