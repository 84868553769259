import * as Globalize from "globalize";

import { TDFRequest } from "../../../services/request";
import { Dialog } from "../../dialogs/dialog";
import { GridToolbar } from "../../grids/gridtoolbars/gridtoolbar";
import { Grid } from "../../../legacy-centers-grids/grid";
import { GridSetup } from "../../../services/grid/helpers/gridsetup";
import {
  DomSafeID,
  BuildBreadcrumbs
} from "../../../util/allutils";
import {
  CurrentUser,
  LoadCompany,
} from "../../../infrastructure/context";
import { Notification } from "../../dialogs/notification";
import { ItemMain } from "../../items/helpers/itemmain";
import { Preferences } from "../../../infrastructure/user/preferences";

export abstract class BI__ItemDetailBase {
  // Item Specific Data
  public abstract TDFContainerType: number;
  public abstract ItemType: number;
  public abstract CustomName: string;
  public abstract DisplayName;
  public abstract ItemHeaderURL: string;
  public abstract HeaderDataCaption: string;
  public Dialogs: Dialog[];
  public CenterType = -1;

  protected ItemID: string = "";
  protected DomSafeID: string = "";
  protected RandomID: string = "";

  // HTML IDs
  protected FormID: string;
  protected ContentID: string;
  protected ContentHeaderID: string;
  protected ContentLinesID: string;
  protected HeaderFormID: string;
  protected GridWrapperContainerID: string;
  protected ToolbarContainerID: string;
  protected GridContainerID: string;
  protected BreadcrumbsID: string;

  // Related objects
  protected DetailForm: Dialog;
  protected ToolbarModel: any;
  protected Toolbar: GridToolbar;
  protected MyGrid: Grid;
  protected GridContainer: JQuery;
  protected RequestModel: GridSetup.BaseGridRequestModel;
  // Detail Data
  protected ItemHeader;
  constructor(itemID: string) {
    const biOrderDetailsForm = this;

    this.ItemID = itemID.toString();
    this.DomSafeID = DomSafeID(this.ItemID);
    this.RandomID = Math.ceil(Math.random() * 999 + 1).toString();
    //TODO: i will probably need to figure this out .... does the webpack globalization plugin handle this??
    //Context.LoadGlobalization();
    LoadCompany();
  }

  protected getCustomDisplayName(defaultName: string) {
    return Preferences.GetCompanyPreference("CustomLabel_" + this.CustomName, "BSISummary", defaultName)
  }

  protected getCustomHeaderCaption(defaultCaption: string) {
    return Preferences.GetCompanyPreference("CustomLabel_" + this.CustomName + "_HeaderDataCaption", "BSISummary", defaultCaption)
  }

  public SetElementIDs() {
    const biItemDetailsForm = this;
    biItemDetailsForm.FormID = biItemDetailsForm.CustomName + "Form_" + biItemDetailsForm.DomSafeID + "_" + biItemDetailsForm.RandomID;
    biItemDetailsForm.ContentID = biItemDetailsForm.CustomName + "Content_" + biItemDetailsForm.DomSafeID + "_" + biItemDetailsForm.RandomID;
    biItemDetailsForm.ContentHeaderID = biItemDetailsForm.CustomName + "ContentHeader_" + biItemDetailsForm.DomSafeID + "_" + biItemDetailsForm.RandomID;
    biItemDetailsForm.ContentLinesID = biItemDetailsForm.CustomName + "ContentLines_" + biItemDetailsForm.DomSafeID + "_" + biItemDetailsForm.RandomID;
    biItemDetailsForm.HeaderFormID = biItemDetailsForm.CustomName + "HeaderForm_" + biItemDetailsForm.DomSafeID + "_" + biItemDetailsForm.RandomID;
    biItemDetailsForm.BreadcrumbsID = biItemDetailsForm.CustomName + "HeaderBreadcrumbs_" + biItemDetailsForm.DomSafeID + "_" + biItemDetailsForm.RandomID;
    biItemDetailsForm.GridWrapperContainerID = biItemDetailsForm.CustomName + "GridWrapper_" + biItemDetailsForm.DomSafeID + "_" + biItemDetailsForm.RandomID;
    biItemDetailsForm.ToolbarContainerID = biItemDetailsForm.CustomName + "Toolsbar_" + biItemDetailsForm.DomSafeID + "_" + biItemDetailsForm.RandomID;
    biItemDetailsForm.GridContainerID = biItemDetailsForm.CustomName + "Grid_" + biItemDetailsForm.DomSafeID + "_" + biItemDetailsForm.RandomID;
  }

  public Initialize() {
    this.SetElementIDs();

    const biItemDetailsForm = this;
    const itemddfd = $.Deferred();

    biItemDetailsForm.Form().done(function () {
      itemddfd.resolve(biItemDetailsForm);
    });

    return itemddfd.promise();
  }

  public Form() {
    const biItemDetailsForm = this;

    const itemddfd = $.Deferred();

    biItemDetailsForm.DetailForm = new Dialog(
      {
        id: biItemDetailsForm.DomSafeID,
        title: biItemDetailsForm.DisplayName,
        size: "size-wide",
        closable: true,
        body: $("<div />")
          .attr("id", biItemDetailsForm.ContentID)
          .append(
            $("<div />")
              .addClass("tdfitemdetails")
              .attr({ id: biItemDetailsForm.ContentHeaderID })
          )
          .append($("<hr>"))
          .append(
            $("<div />")
              .addClass("tdf-item-details-grid")
              .attr({ id: biItemDetailsForm.ContentLinesID })
          ),
        type: "type-primary"
      },
      null,
      false
    );

    biItemDetailsForm.DetailForm.open().done(function (args) {
      // TODO: I commmented out css in TDFDialog.less and Home.less because i dont think these are needed
      // $(BootstrapDialog.dialogs[biItemDetailsForm.DomSafeID].getModalDialog()[0]).addClass("biItem-details-width");
      // $(BootstrapDialog.dialogs[biItemDetailsForm.DomSafeID].getModalDialog()[0]).addClass("biItem-details-height");

      const htmlTop: string =
        "<div id='" +
        biItemDetailsForm.BreadcrumbsID +
        "'></div><div id='" +
        biItemDetailsForm.HeaderFormID +
        "'></div>";
      $("#" + biItemDetailsForm.ContentHeaderID).append(htmlTop);

      const htmlBottom: string =
        "<div id='" +
        biItemDetailsForm.GridWrapperContainerID +
        "'><div id='" +
        biItemDetailsForm.ToolbarContainerID +
        "'></div><div id='" +
        biItemDetailsForm.GridContainerID +
        "'></div></div>";
      $("#" + biItemDetailsForm.ContentLinesID).append(htmlBottom);

      biItemDetailsForm.GridContainer = $(
        "#" + biItemDetailsForm.GridContainerID
      );

      $.when(
        biItemDetailsForm.GetItemHeader(),
        biItemDetailsForm.GetToolbar()
      ).done(function (headerResponse, toolbarResponse) {
        itemddfd.resolve(biItemDetailsForm);
      });
    });

    return itemddfd.promise();
  }

  public GetItemHeader() {
    const biItemDetailsForm = this;
    const dfd = $.Deferred();
    const request = new TDFRequest({
      url: biItemDetailsForm.ItemHeaderURL,
      type: "GET",
      data: {
        ItemID: biItemDetailsForm.ItemID
      }
    });
    request.MakeRequest().done(function (response: any) {
      if (response) {
        biItemDetailsForm.ItemHeader = response;

        const container = $("<div>").addClass("order-details-header table-responsive");
        const table = $("<table>").addClass("table table-striped table-condensed");

        $("#" + biItemDetailsForm.HeaderFormID).dxAccordion({
          dataSource: {
            store: [
              {
                title: biItemDetailsForm.HeaderDataCaption,
                data: biItemDetailsForm.ItemHeader.HeaderData
              }
            ]
          },
          itemTemplate(itemData, itemIndex, itemElement) {
            $.each(biItemDetailsForm.ItemHeader.Columns, function (
              index,
              col: any
            ) {
              if (col.visible === true) {
                const value = itemData.data[itemIndex][col.dataField];
                let formattedValueString =
                  value === null ? "" : value.toString();

                try {
                  switch (col.dataType) {
                    case "date":
                      formattedValueString = Globalize.formatDate(
                        new Date(formattedValueString),
                        { date: "short" }
                      );
                      break;
                    case "number":
                      if (col.format) {
                        switch (col.format.type) {
                          case "currency":
                            formattedValueString = Globalize.formatCurrency(
                              Globalize.parseNumber(formattedValueString, {
                                style: "decimal"
                              }),
                              CurrentUser.Currency
                            );
                            break;
                          case "percent":
                            formattedValueString = Globalize.formatNumber(
                              Globalize.parseNumber(formattedValueString, {
                                style: "decimal"
                              }),
                              {
                                style: "percent",
                                minimumFractionDigits: col.format.precision,
                                maximumFractionDigits: col.format.precision
                              }
                            );
                            break;
                        }
                      }
                      break;
                  }
                } catch (e) {
                  // Log this, but do nothing.  If something failed, the formatted value will just be unformatted.
                  console.error(e);
                }

                table.append(
                  "<tr><td style='width:125px'><strong>" +
                  col.caption +
                  ": </strong></td><td> " +
                  formattedValueString +
                  "</td></tr>"
                );
              }
            });
            container.append(table);
            itemElement.append(container);
          },
          collapsible: true,
          multiple: false,
          onItemRendered(e) {
            (e.component as DevExpress.ui.dxAccordion).collapseItem(
              e.itemIndex
            );
          }
        });

        biItemDetailsForm.CreateBreadCrumbs();
      } else {
        new Notification({
          type: "error",
          shading: true,
          displayTime: 5000,
          message: response.Message || "Something went wrong."
        });
      }
      dfd.resolve(biItemDetailsForm);
    });

    return dfd.promise();
  }

  public CreateBreadCrumbs() {
    const biItemDetailsForm = this;
    const iMain = new ItemMain(biItemDetailsForm.ItemHeader.IMain);
    let small = BuildBreadcrumbs(iMain, biItemDetailsForm.ItemType);
    small.addClass('bi-breadcrumb');
    $("#" + biItemDetailsForm.BreadcrumbsID).append(small);
  }

  public MakeGrid(detailData) {
    const biItemDetailsForm = this;

    const overriddenGridOptions: DevExpress.ui.dxDataGridOptions = {
      elementAttr: { class: "order-detail-grid-width" },
      width: "auto",
      selection: {
        mode: "none"
      },
      onContextMenuPreparing: null,
      onSelectionChanged(e) { },
      paging: {
        enabled: true,
        pageSize: 10
      }
    };
    biItemDetailsForm.MyGrid = new Grid(
      detailData,
      {
        GridContainer: biItemDetailsForm.GridContainer,
        GridToolbar: biItemDetailsForm.Toolbar
      },
      overriddenGridOptions,
      biItemDetailsForm,
      e => {
        biItemDetailsForm.Toolbar.SelectionChanged(e);
      }
    );
    biItemDetailsForm.MyGrid.Render();
    biItemDetailsForm.Toolbar.CurrentGrid = biItemDetailsForm.MyGrid;
  }

  public GetItemLineRequestModel(viewGUID?: string) {
    const biItemDetailsForm = this;

    const requestArgs = new GridSetup.BIRequestArgs();
    requestArgs.GUID = biItemDetailsForm.Toolbar.DefaultView;
    requestArgs.OptionalGroupSummaryOptions = {
      ParentData: biItemDetailsForm.ItemID
    };

    biItemDetailsForm.RequestModel = new GridSetup.BIRequestModel(requestArgs);
    return biItemDetailsForm.RequestModel;
  }

  public GetToolbar() {
    const biItemDetailsForm = this;
    const dfd = $.Deferred();

    // check toolbar data list
    if (biItemDetailsForm.ToolbarModel) {
      dfd.promise(dfd.resolve(biItemDetailsForm));
    } else {
      const request = new TDFRequest({
        url: "/core/user/GetToolbarInfo/",
        type: "GET",
        data: {
          type: biItemDetailsForm.ItemType,
          containertype: biItemDetailsForm.TDFContainerType
        }
      });
      request.MakeRequest().done(function (response) {
        biItemDetailsForm.ToolbarModel = response;
        biItemDetailsForm.ToolbarModel.ToolbarContainerID =
          biItemDetailsForm.ToolbarContainerID;

        biItemDetailsForm.Toolbar = new GridToolbar(
          biItemDetailsForm.ToolbarModel
        );
        biItemDetailsForm.Toolbar.GridDataRequestModel = biItemDetailsForm.GetItemLineRequestModel();
        biItemDetailsForm.Toolbar.GridDataRequestType = "POST";
        biItemDetailsForm.Toolbar.MakeGrid = biItemDetailsForm.MakeGrid.bind(
          biItemDetailsForm
        );
        if (!biItemDetailsForm.Toolbar.CurrentView) {
          biItemDetailsForm.Toolbar.CurrentView =
            biItemDetailsForm.Toolbar.DefaultView;
        }

        biItemDetailsForm.Toolbar.RenderAndLoad();
      });
    }

    return dfd.promise(biItemDetailsForm);
  }
}