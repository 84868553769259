import { BIDataCenter } from "./bidatacenter";
import { CenterType, GridContainerTypes } from "../../enums/enums";
import { ItemListingNameModel } from "../../interfaces/interfaces";
import { TDFDataCenters } from "../../interfaces/datacenters/tdfdatacenters";
import { CurrentUser } from "../../infrastructure/context";
import { DataScopeInitializerItem, DataScopeInitializer, DataScopeSelection, DynamicDataScopeSelectorSettings } from "../../components/dashboards/dynamicdatascopeselector";
import { DataScopeSelectionTypes, DataScopeSelectionMode } from "../../enums/user/datascope/enums";
import { ISummary, EnumBSIPermissionsPrimary } from "../../enums/bi/enums";
import { Preferences } from "infrastructure/user/preferences";
import { SummarySettings } from "services/datacenter/summarysettings";

export class AcctBIDataCenter extends BIDataCenter {
  public static AcctBIDynamicDataScopeSelectorSettings: DynamicDataScopeSelectorSettings = {
    ScopeSelectionType: DataScopeSelectionTypes.AccountBIScope,
    DialogTitle: "Summary Settings",
    AllowDefault: false,
    SelectionMode: DataScopeSelectionMode.Multiple
  };
  get CenterType(): CenterType {
    return CenterType.AccountBi;
  }
  _ContainerType: GridContainerTypes = GridContainerTypes.BSISummary;

  private itemListNameModel: ItemListingNameModel;

  constructor(
    itemID: string,
    itemListNameModel: ItemListingNameModel,
    creationArgs: TDFDataCenters.ICRMDataCenterCreationArgs,
    customName?: string
  ) {
    super(creationArgs, customName);
    let theAcctCenter = this;
    theAcctCenter.itemListNameModel = itemListNameModel;
  }

  protected GetScopeSelectorSettings() {
    let bidc = this;
    //NEEDTODO - make sure this behaves correctly for Account BI
    return AcctBIDataCenter.AcctBIDynamicDataScopeSelectorSettings;
  }

  protected InitializeSummarySettings(): JQueryPromise<any> {
    let bidc = this;
    let dfd: JQueryDeferred<any> = $.Deferred();

    let dataScopeInitializer: DataScopeInitializer = {
      ActiveSelectionLevel: ISummary.SummaryInterfaces.cBSID.EnumType.AcctID,
    };

    if (
      (CurrentUser.BIPermissions.Primary & EnumBSIPermissionsPrimary.SummaryTypeAccount) === EnumBSIPermissionsPrimary.SummaryTypeAccount ||
      (CurrentUser.BIPermissions.Primary & EnumBSIPermissionsPrimary.SummaryTypeAccountOnly) === EnumBSIPermissionsPrimary.SummaryTypeAccountOnly
    ) {
      //let itemList: DataScopeInitializerItem[] = [];
      dataScopeInitializer.SelectedItems = [];
      $.each(bidc.itemListNameModel.ItemListWithNames, function (index, value) {
        dataScopeInitializer.SelectedItems.push({ ItemID: value.ID, Name: value.Name });
      });

      bidc.summarySettings = new DataScopeSelection(bidc.CustomName);
      bidc.summarySettings.InitSettings(dataScopeInitializer);

      return dfd.promise(dfd.resolve());

    } else {
      dataScopeInitializer.ActiveSelectionLevel = ISummary.SummaryInterfaces.cBSID.EnumType.SalesRep

      if (Preferences.GetPreference("HomeType", "BSISummary") === ISummary.SummaryInterfaces.cBSID.EnumType.SalesRep.toString()) {
        dataScopeInitializer.SelectedIds = Preferences.GetPreference("HomeList", "BSISummary").split(',');

        bidc.summarySettings = new DataScopeSelection(bidc.CustomName);
        bidc.summarySettings.InitSettings(dataScopeInitializer);

        return dfd.promise(dfd.resolve());
      }
      else {
        let dis = new SummarySettings();
        dis.AllowableSalespeople().done(salesPeople => {
          dataScopeInitializer.SelectedIds = [];
          salesPeople.forEach(value => {
            dataScopeInitializer.SelectedIds.push(value);
          });

          bidc.summarySettings = new DataScopeSelection(bidc.CustomName);
          bidc.summarySettings.InitSettings(dataScopeInitializer);

          dfd.resolve();
        });

        return dfd.promise();
      }

    }

  }
}
