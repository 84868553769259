import { Dialog } from "../dialogs/dialog";
import { Notification } from "../dialogs/notification";
import { ISummary, BSIGoalType } from "../../enums/bi/enums";
import {
    DataScopeSelectionMode,
    DataScopeSelectionTypes
} from "../../enums/user/datascope/enums";
import { DataScopeSelectorModel } from "../../interfaces/interfaces";
import { SummarySettings } from "../../services/datacenter/summarysettings";
import { DomSafeID, GetDevice, CloneIT } from "../../util/allutils";
import dxDataSource from "devextreme/data/data_source";
import { GetPreference } from "../../infrastructure/user/getpreference";
import { Preferences } from "../../infrastructure/user/preferences";
import { RaiseEvent2 } from "../../infrastructure/events/ui_events";
import { eventNameSpace, EventTypes } from "../../enums/webevents/enums";
import { TDFRequest } from "../../services/request";

export class DynamicDataScopeSelectorSettings {
    public ScopeSelectionType?: DataScopeSelectionTypes;
    DialogTitle: string = "Summary Settings";
    AllowDefault = true;
    SelectionMode: DataScopeSelectionMode = DataScopeSelectionMode.Multiple;
}

//TODO:  Including the Name here helps BI where we often have the name already, but if it gets saved with the Dashboards, it could be wrong.
export interface DataScopeInitializerItem {
    ItemID: string;
    Name: string;
}

export enum FolderTabEnum {
    Unknown = -1,
    Level4 = 4,
    Level3 = 3,
    AcctRoot = 2,
    AcctPkg = 1,
    SalesRep = 5,
    Custom = 6,
    Other = 7,
    AcctId = 8
}

export interface DataScopeInitializer {
    ActiveSelectionLevel?: ISummary.SummaryInterfaces.cBSID.EnumType;
    SelectedItems?: DataScopeInitializerItem[];
    SelectedIds?: string[];
}

// TODO:  This is a re-write of the existing DataScopeSelectionPanel.  It was copied from it, and the old object still exists and is used in several places.  Things need to eventually migrate to this.
export class DataScopeSelection {

    private _summarySettingsService: SummarySettings = new SummarySettings();

    private _ActiveSelectionLevel?: ISummary.SummaryInterfaces.cBSID.EnumType = -1;

    public get ActiveSelectionLevel(): ISummary.SummaryInterfaces.cBSID.EnumType {
        let scopeSelection = this;

        if (this.IsOtherSummary === true) {
            try {
                return <ISummary.SummaryInterfaces.cBSID.EnumType>scopeSelection.otherSummarySettings.otherSummaryType;
            } catch (e) {
                return ISummary.SummaryInterfaces.cBSID.EnumType.InvalidType;
            }
            
        } else {
            return scopeSelection._ActiveSelectionLevel;
        }

    }

    public set ActiveSelectionLevel(level: ISummary.SummaryInterfaces.cBSID.EnumType) {
        let scopeSelection = this;
        scopeSelection._ActiveSelectionLevel = level;
        scopeSelection._ActiveFolderLevel = DataScopeSelection.ConvertScopeLevelToFolderTab(
            scopeSelection.ActiveSelectionLevel
        );
    }

    private _ActiveFolderLevel?= -1;

    public get ActiveFolderLevel() {
        let scopeSelection = this;
        return scopeSelection._ActiveFolderLevel;
    }

    public set ActiveFolderLevel(folderLevel) {
        let scopeSelection = this;
        scopeSelection._ActiveFolderLevel = folderLevel;
        // For Other Tab: this will return Invalid.

        let newSelectionLevel = DataScopeSelection.ConvertFolderTabToScopeLevel(
            scopeSelection.ActiveFolderLevel
        );

        if (newSelectionLevel != -1) {
            scopeSelection._ActiveSelectionLevel = newSelectionLevel
        } else {
            console.log("Keeping ActiveSelectionLevel where it is, because the converted value returned -1.");
        }
    }

    private _SelectedItems?= {};

    public get SelectedItems() {
    let scopeSelection = this;
    return scopeSelection._SelectedItems;
    }

    public get IsSalesmanLevel(): boolean {
    let scopeSelection = this;
    return (
        scopeSelection.ActiveSelectionLevel ===
        ISummary.SummaryInterfaces.cBSID.EnumType.SalesRep
    );
    }

    public otherSummarySettings: {
        otherSummaryType: ISummary.SummaryInterfaces.cBSID.EnumType,
        otherSummaryValue: string
    } = {
        otherSummaryType: ISummary.SummaryInterfaces.cBSID.EnumType.InvalidType,
            otherSummaryValue: ""
        };

    public get IsOtherSummary(): boolean {
        let scopeSelection = this;
        return (scopeSelection.ActiveFolderLevel === 7)
    }

    private EventLimiterName: string = "";

    constructor(eventLimiterName?: string) {
    let scopeSelection = this;
    if (eventLimiterName) {
        scopeSelection.EventLimiterName = eventLimiterName;
    }
    }

    public ReInitSettings(initSettings: DataScopeInitializer) {
    let scopeSelection = this;
    scopeSelection._SelectedItems = {};
    scopeSelection.InitSettings(initSettings);
    }

    public InitSettings(initSettings: DataScopeInitializer, refreshNames: boolean = true) {
    let scopeSelection = this;

        if (initSettings.ActiveSelectionLevel != undefined) {

            switch (initSettings.ActiveSelectionLevel) {
                case ISummary.SummaryInterfaces.cBSID.EnumType.BillTo:
                case ISummary.SummaryInterfaces.cBSID.EnumType.Master:
                //case ISummary.SummaryInterfaces.cBSID.EnumType.SalesRep:
                    scopeSelection.otherSummarySettings.otherSummaryType = initSettings.ActiveSelectionLevel
                    scopeSelection.otherSummarySettings.otherSummaryValue = initSettings.SelectedItems.map((v, i) => { return v.ItemID; }).join(",");
                    break;

            }

        // Note:  Have to check for undefined explictly because 0 is a valid setting that we want to handle.
        scopeSelection.ActiveSelectionLevel = initSettings.ActiveSelectionLevel;
    }


        switch (initSettings.ActiveSelectionLevel) {
            case ISummary.SummaryInterfaces.cBSID.EnumType.BillTo:
            case ISummary.SummaryInterfaces.cBSID.EnumType.Master:
            //case ISummary.SummaryInterfaces.cBSID.EnumType.SalesRep:
                
                break;

            default:

                if (initSettings.SelectedItems && initSettings.SelectedItems.length > 0) {
                    $.each(initSettings.SelectedItems, function (index, item) {
                        scopeSelection.AddSelectedItem(
                            scopeSelection.ActiveFolderLevel,
                            item.ItemID,
                            item.Name
                        );
                    });
                }
                else if (initSettings.SelectedIds && initSettings.SelectedIds.length > 0) // TAB - 05.17.2019 - need to also look up the SelectedIds for backwards compatability from 2019 R1.
                {
                    $.each(initSettings.SelectedIds, function (index, item) {
                        scopeSelection.AddSelectedItem(
                            scopeSelection.ActiveFolderLevel,
                            item,
                            ""
                        );
                    });
                }

                break;

        }

        let currentlySelected = scopeSelection.CurrentlySelectedItemsAsArray();

    let itemsWithoutNames = currentlySelected.filter(function (item) {
        if (item.Name === "") {
        return item;
        }
    }).map((item) => { return item.ItemID });

    if (itemsWithoutNames.length > 0) {
        scopeSelection._summarySettingsService.GetItemListWithNamesRequest(itemsWithoutNames.join(), scopeSelection.IsSalesmanLevel).done(function (response: any) {
        var actvlvl = "Level" + scopeSelection.ActiveFolderLevel;
        if (scopeSelection.SelectedItems[actvlvl]) {
            $.each(scopeSelection.SelectedItems[actvlvl], function (index, item) {
            let resolvedName = response.ItemListWithNames[item.ItemID];
            if (resolvedName) {
                item.Name = resolvedName;
            }
            });
            scopeSelection.RaiseNameChangeEvent();
        }
        });
    }
    else {
        scopeSelection.RaiseNameChangeEvent();
    }
    }

    RaiseNameChangeEvent() {
    let scopeSelection = this;
    RaiseEvent2(EventTypes.BIEventTypes.SummarySelectionNamesUpdated, scopeSelection.EventLimiterName, eventNameSpace.notify, { NewSummarySettings: scopeSelection });
    }

    AddSelectedItem(folderLevel, itemID, name) {
    let scopeSelection = this;

    let thisItemID = "selectedItem_" + DomSafeID(itemID);
    let thisFolderID = itemID;

    if (folderLevel === 5) {
        thisItemID = DataScopeSelection.GetSalesRepItemID(itemID, name);
        thisFolderID = itemID;
    }

    if (!scopeSelection.SelectedItems["Level" + folderLevel]) {
        scopeSelection.SelectedItems["Level" + folderLevel] = {};
    }

    if (!scopeSelection.SelectedItems["Level" + folderLevel][thisItemID]) {
        scopeSelection.SelectedItems["Level" + folderLevel][thisItemID] = {
        ItemID: thisFolderID,
        Name: name,
        Level: folderLevel
        };
    }
    }

    public toJSON(key) {
    let scopeSelection = this;

    let it = {
        ActiveSelectionLevel: scopeSelection.ActiveSelectionLevel,
        ActiveFolderLevel: scopeSelection.ActiveFolderLevel,
        SelectedItems: scopeSelection.SelectedItems
    };

    return it;
    }

    public GetScopeInitializerSettings() {
    let scopeSelection = this;
    let scopeSettings: DataScopeInitializer = {
        ActiveSelectionLevel: scopeSelection.ActiveSelectionLevel,
        SelectedItems: scopeSelection.CurrentlySelectedItemsAsArray(),
        SelectedIds: scopeSelection.CurrentlySelectedItemIDsAsArray()     // TAB - 05.17.2019 - need to also set the SelectedIds for backwards compatability TO 2019 R1 (in the interim that I'm using my dev code to hit a production database)
    };

    return scopeSettings;
    }

    public ApplyClone(myClone) {
    let scopeSelection = this;

    scopeSelection.ActiveSelectionLevel = myClone.ActiveSelectionLevel;
    scopeSelection.ActiveFolderLevel = myClone.ActiveFolderLevel;
    scopeSelection._SelectedItems = myClone.SelectedItems;
    }

    static GetSalesRepItemID(repID, repName) {
    let myRepID = repID.toString().replace("rep_", "");
    let finalRepID = myRepID + "-" + repName;

    finalRepID = finalRepID.split(" ").join("");
    finalRepID = $.trim(finalRepID);
    finalRepID = DomSafeID(finalRepID);

    return finalRepID;
    }

    static ConvertScopeLevelToFolderTab(scopeLevel: ISummary.SummaryInterfaces.cBSID.EnumType) {

        let convertedValue:number = scopeLevel;
        let folderTab: FolderTabEnum = FolderTabEnum.Unknown; //  -1;

    switch (convertedValue) {
        case 8:
            folderTab = FolderTabEnum.Level4; //  4; // Level 4
        break;
        case 7:
            folderTab = FolderTabEnum.Level3; // 3; // Level 3
        break;
        case 6: // Custom
            folderTab = FolderTabEnum.Custom; // 6; // Saved Selections
        break;
        case 3: // SalesRep
            folderTab = FolderTabEnum.SalesRep; // 5;
        break;
        case 2: // AcctRoot
            folderTab = FolderTabEnum.AcctRoot; // 2;
        break;
        case 0: //AcctPkg
            folderTab = FolderTabEnum.AcctPkg; // 1;
            break;
        case ISummary.SummaryInterfaces.cBSID.EnumType.AcctID:
            folderTab = FolderTabEnum.AcctId;
            break;
        case 5: // Master
        //case 1: // AcctID
        case 4: // Bill To
            folderTab = FolderTabEnum.Other; // 7;
        break;
    }
    return folderTab;
    }

    static ConvertFolderTabToScopeLevel(folderTab) {
        let convertedValue = parseInt(folderTab);
        let scopeLevel = -1;

        switch (convertedValue) {
            case 4: // Level 4
            scopeLevel = 8;
            break;
            case 3: // Level 3
            scopeLevel = 7;
            break;
            case 2: // AcctRoot
            scopeLevel = 2;
            break;
            case 1: // AcctPkg
            scopeLevel = 0;
            break;
            case 5: // Sales Rep
            scopeLevel = 3;
            break;
            case 6: // Saved Selections
                //TAB TODO  - not sure of level when using saved selections.
                //RTM: the proper value is set when the user actually clicks on one of the Saved Summaries.
            break;
            case 7: // Other
            // This will be handled when OK is clicked.
            //scopeLevel = 3;     // Other-> SalesRep
            //scopeLevel = 6;     // Other-> CustID
            //scopeLevel = 5;     // Other-> Master
            break;
        }
        return scopeLevel;
    }

    WriteTagCloud(tagCloudElem: JQuery) {
    let scopeSelection = this;

    $.each(scopeSelection.CurrentlySelectedItemsAsArray(), function (
        index,
        item
    ) {
        let tag = $(
        `<span class='ScopeTag badge dialog-info'>${
        item.Name ? item.Name : item.ItemID
        }</span>`
        );
        tagCloudElem.append(tag);
    });
    }

    CurrentlySelectedItemsAsArray(): any[] {

        let scopeSelection = this;
        let currentList: any[] = [];

        if (scopeSelection.IsOtherSummary === true) {
            currentList = scopeSelection.otherSummarySettings.otherSummaryValue.split(",")
                .map((v, i) => {
                    return <DataScopeInitializerItem>{ ItemID: v, Name: v}
                })
            //debugger
        } else {
            var actvlvl = "Level" + scopeSelection.ActiveFolderLevel;
            if (scopeSelection.SelectedItems[actvlvl]) {
                for (var key in scopeSelection.SelectedItems[actvlvl]) {
                    currentList.push(scopeSelection.SelectedItems[actvlvl][key]);
                }
            } else {
                console.log("Expected to find key named " + actvlvl + " within scopeSelection.SelectedItems!")
            }
        }

        return currentList;
    }

    CurrentlySelectedItemIDsAsArray(): string[] {
    let scopeSelection = this;
    let currentList: string[] = [];

        if (scopeSelection.SelectedItems["Level" + scopeSelection.ActiveFolderLevel]) {
            for (var key in scopeSelection.SelectedItems[
                "Level" + scopeSelection.ActiveFolderLevel
            ]) {
                var selectedItem =
                    scopeSelection.SelectedItems[
                    "Level" + scopeSelection.ActiveFolderLevel
                    ][key];
                currentList.push(selectedItem.ItemID);
            }
        } else if (scopeSelection.IsOtherSummary === true) {
            currentList = scopeSelection.otherSummarySettings.otherSummaryValue.split(",");
        }

    return currentList;
    }

    CurrentlySelectedItemIDsAsString(): string {
    let scopeSelection = this;
    let selection: string = "";
    let currentList: string[] = scopeSelection.CurrentlySelectedItemIDsAsArray();

    $.each(currentList, function (index, itemID) {
        if (selection) {
        selection = selection + "," + itemID;
        } else {
        selection = selection + itemID;
        }
    });

    return selection;
    }
    GetGoalSelection(preferenceModule): BSIGoalType {
    let scopeSelection = this;
    let goalType: number = -1;
    let useGoals: boolean = GetPreference("LoadGoals").toLowerCase() === "true";
    if (useGoals) {
        let prefName: string = this.GetGoalPrefName(
        scopeSelection.ActiveSelectionLevel
        );
        goalType = parseInt(GetPreference(prefName, preferenceModule));
    }

    return goalType;
    }
    GetGoalPrefName(selectionScopeLevel: number): string {
    let prefName: string = "";
    switch (selectionScopeLevel) {
        case 8: {
        prefName = "GoalLevel4";
        break;
        }
        case 7: {
        prefName = "GoalLevel3";
        break;
        }
        case 3: {
        prefName = "GoalSalesman";
        break;
        }
        case 2: {
        prefName = "GoalAcctRoot";
        break;
        }
        case 6: // Custom
        case 5: // Master
        case 4: // BillTo
        case 1: {
        prefName = "GoalAcct";
        break;
        }
        case 0: {
        prefName = "GoalAcctPkg";
        break;
        }
    }
    return prefName;
    }
}

export class DynamicDataScopeSelector {
    protected _summarySettingsService: SummarySettings = new SummarySettings();

    // Properties
    private backupScopeSelection = {};
    ScopeSelectorModelLoaded: boolean = false;
    ScopeSelectorModel: DataScopeSelectorModel;
    Buttons: DevExpress.ui.dxPopupToolbarItem[] = [];
    Dialog: Dialog;
    DialogCallBack: Function;

    private CurrentSelection: DataScopeSelection;
    private SelectorSettings: DynamicDataScopeSelectorSettings;


    constructor(
        selection: DataScopeSelection,
        selectorSettings: DynamicDataScopeSelectorSettings
    ) {
        let dynScopeSelector = this;

        dynScopeSelector.CurrentSelection = selection;
        dynScopeSelector.SelectorSettings = selectorSettings;
    }

    BuildPanel(response) {
        var scopeSelector = this;

        var changeTabFunction = function (tabLevel) {
            scopeSelector.ChangeFolderLevel(tabLevel);
        };

        var selectedIndex = 0;
        response.Tabs.forEach(function (item, index) {
            if (item.Level === scopeSelector.CurrentSelection.ActiveFolderLevel) {
                selectedIndex = index;
            }
        });

        var tabs = $("#scope-settings-container")
            .dxTabPanel({
                dataSource: response.Tabs,
                selectedIndex: selectedIndex,
                loop: false,
                animationEnabled: false,
                swipeEnabled: false,
                scrollingEnabled: false,
                scrollByContent: false,

                itemTitleTemplate: function (itemData, itemIndex, itemElement) {
                    var caption = itemData.Name || "**Caption Not Set**";
                    itemElement.append("<b>" + caption + "</b>");
                },
                itemTemplate: function (itemData, itemIndex, itemElement) {
                    var tabContentID = "wrapper_level" + itemData.Level; //"scope-selection-tabs-" + itemData.Level;
                    var tabContent: string;
                    var container = $("<div />")
                        .attr("id", tabContentID)
                        .addClass("tdfitemtab_content");
                    itemElement.append(container);

                    switch (itemData.Level) {
                        case 4:
                        case 3:
                        case 2:
                        case 1:
                            let scopeSelectionID1 = "scope-selection-tab-" + itemData.Level;
                            let scopeScrollerID1 =
                                "scope-selection-tab-scroller-" + itemData.Level;
                            let scopeToolbarID1 = "searchAndButtons_level" + itemData.Level;
                            scopeSelector.CurrentSelection.ActiveFolderLevel = itemData.Level;

                            //#region Regular Folders
                            tabContent = `<div id='helpPop_level${itemData.Level}'></div>
                                                <div id='${scopeToolbarID1}'>
                                                </div>
                                                <hr />
                                                <div id='${scopeScrollerID1}'><div id='${scopeSelectionID1}'>
                                                <div id='thetree_level${
                                itemData.Level
                                }' style='max-height:${$(
                                    window
                                ).height() * 0.5};overflow:hidden;overflow-y:auto;'></div>
                                                </div></div>`;
                            container.append($(tabContent));

                            //#region Initialize Javascript Elements
                            $("#" + scopeToolbarID1)
                                .css("margin-top", "5px")
                                .addClass("container-fluid")
                                .dxToolbar(scopeSelector.GetToolbarItems(itemData.Level))
                                .dxToolbar("instance");

                            var helpPop = function () {
                                scopeSelector.ShowHelpPop();
                            };

                            var infoDotID = "infodot_level" + itemData.Level;
                            var popoverDivID = "helpPop_level" + itemData.Level;
                            var searchTextBoxID = "search-treeview_level" + itemData.Level;

                            $("#" + infoDotID)
                                .unbind()
                                .hover(function () {
                                    helpPop();
                                });

              $("#" + popoverDivID)
                .dxPopover({
                  target: searchTextBoxID,
                  contentTemplate: function (contentElement) {
                    var info = $("<div>")
                      .text(
                        " This search will automatically filter the results that are currently loaded in the tree. " +
                        "If you do not see the results you are looking for, press the [Enter] key to search the entire set. "
                      )
                      .addClass("btn-info")
                      .css({ padding: "5px" });
                    contentElement.append(info);
                  },
                  width: 300
                })
                .dxPopover("instance");

                            $("#thetree_level" + itemData.Level)
                                .dxTreeView({
                                    dataStructure: "tree",
                                    dataSource: new dxDataSource({
                                        load: function (loadOptions) {
                                            return scopeSelector._summarySettingsService.GetFolderChildren(
                                                "",
                                                itemData.Level
                                            );
                                        },
                                        byKey: function (key) {
                                            return scopeSelector._summarySettingsService.GetFolderChildren(
                                                key,
                                                itemData.Level
                                            );
                                        }
                                    }),
                                    onContentReady: function (response) {
                                        scopeSelector.CheckSelectedItemsInTree();
                                    },
                                    expandAllEnabled: true,
                                    parentIdExpr: "FolderParentID",
                                    keyExpr: "FolderID",
                                    displayExpr: "Name",
                                    //virtualModeEnabled: true,
                                    hasItemsExpr: "hasChildren",
                                    onItemClick: function (data) {
                                        scopeSelector.OnTreeItemClick(data);
                                    },
                                    itemTemplate: function (treeItemData) {
                                        var itemID = "item_" + DomSafeID(treeItemData.FolderID);
                                        if (treeItemData.hasChildren && !treeItemData.expanded) {
                                            return $("<div>")
                                                .text(treeItemData.Name)
                                                .attr("id", itemID)
                                                .attr("data-level", treeItemData.Level)
                                                .attr("data-itemid", treeItemData.FolderID)
                                                .attr("data-name", treeItemData.Name)
                                                .append(
                                                    $("<i>")
                                                        .addClass("fa fa-plus expandicon")
                                                        .css("float", "left")
                                                        .css("margin-right", "20px")
                                                );
                                        } else if (!treeItemData.hasChildren) {
                                            return $("<div>")
                                                .text(treeItemData.Name)
                                                .attr("id", itemID)
                                                .attr("data-level", treeItemData.Level)
                                                .attr("data-itemid", treeItemData.FolderID)
                                                .attr("data-name", treeItemData.Name)
                                                .append(
                                                    $("<i>")
                                                        .addClass("fa fa-square-o")
                                                        .css("float", "left")
                                                        .css("margin-right", "20px")
                                                );
                                        } else {
                                            return $("<div>").text(treeItemData.Name);
                                        }
                                    },
                                    searchEnabled: false
                                })
                                .dxTreeView("instance");

                            $("#" + scopeScrollerID1).dxScrollView({
                                height: $(window).height() * 0.5 - 50,
                                showScrollbar: "always",
                                bounceEnabled: false
                            });
                            //#endregion
                            //#endregion
                            break;
                        case 5:
                            scopeSelector.CurrentSelection.ActiveFolderLevel = itemData.Level;
                            // SalesRep
                            let scopeSelectionID5 = "scope-selection-tab-" + itemData.Level;
                            let scopeScrollerID5 =
                                "scope-selection-tab-scroller-" + itemData.Level;
                            let scopeToolbarID5 = "searchAndButtons_level" + itemData.Level;
                            let selectAllBtnID5 = "selectAllBtn" + itemData.Level;
                            let clearSelectionsBtnID = "clearSelectionsBtn" + itemData.Level;

                            //#region SalesRep
                            tabContent = `<div id='${scopeToolbarID5}'>
                                                </div>
                                                <hr />
                                                <div id='${scopeScrollerID5}'><div id='${scopeSelectionID5}'>
                                                <div id='salesrep-list' style='max-height:${$(
                                window
                            ).height() *
                                0.5};overflow:hidden;overflow-y:auto'></div>
                                                </div></div>`;
                            container.append($(tabContent));

                            //#region Initialize Javascript Elements
                            $("#" + scopeToolbarID5)
                                .css("margin-top", "5px")
                                .addClass("container-fluid")
                                .dxToolbar({
                                    items: [
                                        {
                                            locateInMenu: GetDevice().isDevice ? "always" : "auto",
                                            location: "before",
                                            widget: "dxButton",
                                            options: {
                                                attr: { id: selectAllBtnID5 },
                                                icon: "",
                                                hint: "Select All Visible",
                                                text: "Select All Visible",
                                                type: "default",
                                                visible:
                                                    scopeSelector.SelectorSettings.SelectionMode ===
                                                    DataScopeSelectionMode.Multiple,
                                                onClick: function () {
                                                    scopeSelector.selectAllVisibleCheckboxes();
                                                }
                                            }
                                        },
                                        {
                                            locateInMenu: GetDevice().isDevice ? "always" : "auto",
                                            location: "before",
                                            widget: "dxButton",
                                            options: {
                                                attr: { id: clearSelectionsBtnID },
                                                icon: "",
                                                hint: "Clear Selections",
                                                text: "Clear Selections",
                                                type: "default",
                                                visible: true,
                                                onClick: function () {
                                                    scopeSelector.ClearSelections();
                                                }
                                            }
                                        }
                                    ]
                                })
                                .dxToolbar("instance");

                            $("#salesrep-list")
                                .dxList({
                                    dataSource: new dxDataSource({
                                        load: function (loadOptions) {
                                            return scopeSelector._summarySettingsService.GetRepList();
                                        }
                                    }),
                                    itemTemplate: function (itemData) {
                                        var itemID = DataScopeSelection.GetSalesRepItemID(
                                            itemData.SalesPersonID,
                                            itemData.Name
                                        );

                                        return $("<div>")
                                            .text(itemData.SalesPersonID + " - " + itemData.Name)
                                            .attr("id", itemID)
                                            .attr("data-level", itemData.Level)
                                            .attr("data-itemid", itemData.FolderID)
                                            .attr("data-name", itemData.Name);
                                    },
                                    onContentReady: function (response) {
                                        if (
                                            scopeSelector.CurrentSelection.ActiveSelectionLevel == 3
                                        ) {
                                            scopeSelector.CheckSelectedItemsInTree();
                                        }
                                    },
                                    allowItemDeleting: false,
                                    showSelectionControls: true,
                                    selectionMode: "multiple",
                                    onSelectionChanged: function (data) {
                                        if (data.addedItems.length > 0) {
                                            scopeSelector.AddSelectedTag(
                                                5,
                                                data.addedItems[0].SalesPersonID,
                                                data.addedItems[0].Name
                                            );
                                        } else if (data.removedItems.length > 0) {
                                            scopeSelector.RemoveSelectedSalesRepFromTags(
                                                5,
                                                data.removedItems[0].SalesPersonID,
                                                data.removedItems[0].Name
                                            );
                                        }
                                    },
                                    grouped: false
                                })
                                .dxList("instance");

                            $("#" + scopeScrollerID5).dxScrollView({
                                height: $(window).height() * 0.5 - 50,
                                showScrollbar: "always",
                                bounceEnabled: false
                            });
                            //#endregion
                            //#endregion
                            break;
                        case 6:
                            // Saved Selections
                            let scopeScrollerID6 =
                                "scope-selection-tab-scroller-" + itemData.Level;
                            let listID = "savedSelectionsList";
                            tabContent =
                                "<div id='" +
                                scopeScrollerID6 +
                                "'><div id='" +
                                listID +
                                "'></div></div>";

                            container.append($(tabContent));

                            var displaySavedSelections = function (response) {
                                $("#savedSelectionsList")
                                    .dxList({
                                        items: response,
                                        itemTemplate: function (itemData, itemIndex) {
                                            let result = $(
                                                "<span style='margin: 5px'>" + itemData.Name + "</span>"
                                            ).attr({
                                                id: "savedSelection_" + itemData.ID,
                                                name: itemData.Name,
                                                "data-savedSelectionID": itemData.ID
                                            });
                                            return result;
                                        },
                                        showSelectionControls: false,
                                        onItemClick: function (e) {
                                            var savedSelectionID = e.itemData.ID;
                                            scopeSelector.LoadSavedSelection(savedSelectionID);
                                        }
                                    })
                                    .dxList("instance");

                                $("#" + scopeScrollerID6).dxScrollView({
                                    height: $(window).height() * 0.5 - 50,
                                    showScrollbar: "always",
                                    bounceEnabled: false
                                });
                            };

                            scopeSelector._summarySettingsService
                                .GetSavedSelectionList()
                                .done(displaySavedSelections);

                            break;
                        case 7:

                            let formOptions: DevExpress.ui.dxFormOptions = {
                                items: [],
                                formData: scopeSelector.CurrentSelection.otherSummarySettings
                            };

                            let radioChoices: any = [];

                            let prompt: string = "Enter a value";

                            if (response.ShowCustomerId) {
                                radioChoices.push({ text: "Customer Id", value: ISummary.SummaryInterfaces.cBSID.EnumType.BillTo });
                            }

                            if (response.ShowMasterAcctId) {
                                radioChoices.push({ text: "Master Id", value: ISummary.SummaryInterfaces.cBSID.EnumType.Master });
                            }

                            //if (response.ShowSalesRep) {
                            //    radioChoices.push({ text: "Salesman Id", value: ISummary.SummaryInterfaces.cBSID.EnumType.SalesRep });
                            //}

                            let radioOptions: DevExpress.ui.dxRadioGroupOptions = {
                                dataSource: radioChoices,
                                displayExpr: "text",
                                valueExpr: "value"
                            }

                            formOptions.items.push({
                                editorType: 'dxRadioGroup',
                                editorOptions: radioOptions,
                                label: { alignment: "left", location: "top", visible: true, text: "Select a type", showColon: true },
                                dataField: "otherSummaryType"
                            });

                            formOptions.items.push(
                                {
                                    label: {
                                        text: "Enter one or more values, separated by commas.",
                                        showColon: false,
                                        alignment: "left",
                                        location: "top",
                                        visible: true
                                    },
                                    editorType: "dxTextBox",
                                    name: "scope-custid",
                                    dataField: "otherSummaryValue",
                                    editorOptions: <DevExpress.ui.dxTextBoxOptions>{
                                        onInput: (e) => {

                                        }
                                    }
                                }
                            );


                            let otherForm = $("<div id='other-container' />").dxForm(formOptions);

                            container.append(otherForm);


                            // Other
                            //tabContent = "<div id='other-container'>";
                            //if (response.ShowCustomerId) {
                            //  tabContent = tabContent.concat(`<br />
                            //                                                      <label>Customer ID:</label>
                            //                                                      <input type='text' id='scope-custid' oninput='${$(
                            //    this
                            //  )
                            //      .siblings(
                            //        "input"
                            //      )
                            //      .val(
                            //        ""
                            //      )};' /><br />`);
                            //}

                            //if (response.ShowMasterAcctId) {
                            //  tabContent = tabContent.concat(`<br />
                            //                                                      <label>Master ID:</label>
                            //                                                      <input type='text' id='scope-masterid' oninput='${$(
                            //    this
                            //  )
                            //      .siblings(
                            //        "input"
                            //      )
                            //      .val(
                            //        ""
                            //      )};'/><br />`);
                            //}

                            //if (response.ShowSalesRep) {
                            //  tabContent = tabContent.concat(`<br />
                            //                                                      <label>Salesman ID:</label>
                            //                                                      <input type='text' id='scope-salesid' oninput='${$(
                            //    this
                            //  )
                            //      .siblings(
                            //        "input"
                            //      )
                            //      .val(
                            //        ""
                            //      )};' />`);
                            //}
                            //tabContent = tabContent.concat("</div>");


                            //container.append($(tabContent));

                            break;
                        //case 8:
                        //    // Account List tab
                        //    tabContent = "";
                        //    break;
                        default:
                        //Who Knows
                    }
                },
                onSelectionChanged: function (e) {
                    var tabLevel = e.addedItems[0].Level;
                    changeTabFunction(tabLevel);
                }
            })
            .dxTabPanel("instance");

        var selectionElem = $("<div>")
            .attr("id", "selectedScope")
            .css("float", "left");
        var numberElem = $("<span>")
            .attr("id", "num-current-selected")
            .css("float", "left")
            .text("0 Currently Selected");

        let scopeAreaElement = $("#ScopeArea");
        scopeAreaElement.append(numberElem);
        scopeAreaElement.append(selectionElem);

        scopeSelector.ChangeFolderLevel(
            scopeSelector.CurrentSelection.ActiveFolderLevel
        );
    }

    OnTreeItemClick(data) {
        let panel = this;
        if (data.itemData.hasChildren) {
            $(data.event.currentTarget)
                .find(".expandicon")
                .remove();
            var component = data.component;
            var theData = data;
            var scrollPos = $("#thetree_level" + data.itemData.Level).scrollTop();
            var chil = data.component._dataSource._store
                .byKey(data.itemData.FolderID)
                .done(function (response) {
                    var that = component;

                    theData.itemData.items = [];

                    $.each(response, function (key, val) {
                        theData.itemData.items.push(val);
                    });

                    theData.itemData.expanded = true;
                    var currentData = component._dataSource._items;
                    component.option({ items: currentData });
                    panel.CheckSelectedItemsInTree();

                    $("#thetree_level" + theData.itemData.Level).scrollTop(scrollPos);
                });
        } else {
            panel.OnTreeLeafClicked(data);
        }
    }

    // This is for the nodes without children.
    OnTreeLeafClicked(data) {
        let panel = this;

        var itemID = "selectedItem_" + DomSafeID(data.itemData.FolderID);

        var element = $(data.event.currentTarget);

        if (element.find(".fa-check-square-o").length > 0) {
            element
                .find(".fa-check-square-o")
                .removeClass("fa-check-square-o")
                .addClass("fa-square-o");
            data.itemData.selected = false;
            panel.RemoveSelectedTag($("#selectedScope").find("#" + itemID));
        } else {
            if (
                panel.SelectorSettings.SelectionMode == DataScopeSelectionMode.Single
            ) {
                panel.ClearSelections();
            }

            element
                .find(".fa-square-o")
                .removeClass("fa-square-o")
                .addClass("fa-check-square-o");
            data.itemData.selected = true;
            var itemElement = data.itemElement.children(0).children(0);
            //TODO:  Check this
            panel.AddSelectedTag(
                itemElement.data("level"),
                itemElement.data("itemid"),
                itemElement.data("name")
            );
        }
    }

    GetToolbarItems(level) {
        var panel = this;

        var infoDotID = "infodot_level" + level;
        var searchTextBoxID = "search-treeview_level" + level;
        var expandAllBtnID = "expandAllBtn" + level;
        var selectAllBtnID = "selectAllBtn" + level;
        var clearSelectionsBtnID = "clearSelectionsBtn" + level;
        let btns: DevExpress.ui.dxToolbarOptions = {
            items: [
                {
                    locateInMenu: "never",
                    location: "before",
                    widget: "dxTextBox",
                    options: {
                        inputAttr: { id: searchTextBoxID },
                        value: "",
                        mode: "search",
                        placeholder: "Search",
                        width: "200px",
                        showClearButton: true,
                        onValueChanged: function (e) {
                            if (
                                e.jQueryEvent &&
                                e.jQueryEvent.currentTarget.className.indexOf("clear") !== -1
                            ) {
                                //tree.getDataSource
                                var tree = $("#thetree_level" + level).dxTreeView("instance");
                                tree
                                    .option("dataSource")
                                    .load()
                                    .done(function (response) {
                                        tree.option("searchValue", "");
                                    });
                            }
                        },
                        onEnterKey: function (e) {
                            if (e.jQueryEvent.keyCode !== 13) {
                                return;
                            }
                            var tree = $("#thetree_level" + level).dxTreeView("instance");
                            tree.option({ items: [] });
                            //TODO - replace getJSON
                            $.getJSON(TDFRequest.GetApiLink("/DataScope/SearchFoldersByLevel/"), {
                                str: e.jQueryEvent.currentTarget.value,
                                level: level
                            }).done(function (response) {
                                var that = tree;

                                var theData = [];

                                $.each(response, function (key, val) {
                                    theData.push(val);
                                });

                                that.option({ items: theData });
                                panel.CheckSelectedItemsInTree();
                            });
                        },
                        onKeyUp: function (data) {
                            var tree = $("#thetree_level" + level).dxTreeView("instance");
                            tree.option("searchValue", data.jQueryEvent.currentTarget.value);
                        }
                    }
                },
                {
                    locateInMenu: "never",
                    location: "before",
                    template: function () {
                        return $(
                            "<i id='" + infoDotID + "' class='fa fa-question-circle'></i>"
                        );
                    }
                },
                {
                    locateInMenu: GetDevice().isDevice ? "always" : "auto",
                    location: "before",
                    widget: "dxButton",
                    options: {
                        attr: { id: expandAllBtnID },
                        icon: "",
                        hint: "Expand All Nodes",
                        text: "Expand All Nodes",
                        type: "default",
                        visible: true,
                        onClick: function () {
                            panel.ExpandAll();
                        }
                    }
                },
                {
                    locateInMenu: GetDevice().isDevice ? "always" : "auto",
                    location: "before",
                    widget: "dxButton",
                    options: {
                        attr: { id: selectAllBtnID },
                        icon: "",
                        hint: "Select All Visible",
                        text: "Select All Visible",
                        type: "default",
                        visible:
                            panel.SelectorSettings.SelectionMode ===
                            DataScopeSelectionMode.Multiple,
                        onClick: function () {
                            panel.selectAllVisibleCheckboxes();
                        }
                    }
                },
                {
                    locateInMenu: GetDevice().isDevice ? "always" : "auto",
                    location: "before",
                    widget: "dxButton",
                    options: {
                        attr: { id: clearSelectionsBtnID },
                        icon: "",
                        hint: "Clear Selections",
                        text: "Clear Selections",
                        type: "default",
                        visible: true,
                        onClick: function () {
                            panel.ClearSelections();
                        }
                    }
                }
            ]
        };
        return btns;
    }

    GetDataScopeSelectorModel(): JQueryPromise<DataScopeSelectorModel> {
        var scopeSelector = this;
        let dfd: JQueryDeferred<DataScopeSelectorModel> = $.Deferred();
        if (!scopeSelector.ScopeSelectorModelLoaded) {
            scopeSelector._summarySettingsService
                .DataScopeSelectorModelRequest(
                    scopeSelector.SelectorSettings.ScopeSelectionType
                )
                .done(function (response) {
                    scopeSelector.ScopeSelectorModel = response;
                    scopeSelector.ScopeSelectorModelLoaded = true;
                    dfd.resolve(scopeSelector.ScopeSelectorModel);
                });
        } else {
            return dfd.promise(dfd.resolve(scopeSelector.ScopeSelectorModel));
        }

        return dfd.promise();
    }

    // dialogCallBack is used if there are things that the caller needs to do to handle the outcome of this dialog (refresh a grid view, display the new selected values, etc).
    // dialogCallBack is only called from "Okay" or "Use and Set as Default"
    DisplayPanel(dialogCallBack?) {
        var panel = this;

        if (dialogCallBack) {
            panel.DialogCallBack = dialogCallBack;
        }

        panel.AddDialogButtons();
        panel.GetDataScopeSelectorModel().done(function (dataScopeModel) {
            // Save off the original set of selections before letting the user change them.  Then if the user cancels, we can easily restore the set back.
            // Note:  Chose to JSON stringify/parse based on this discussion here - http://stackoverflow.com/questions/122102/what-is-the-most-efficient-way-to-deep-clone-an-object-in-javascript
            panel.backupScopeSelection = CloneIT(panel.CurrentSelection); // JSON.parse(JSON.stringify(panel.CurrentSelection.SelectedItems));

            panel.Dialog = new Dialog({
                size: "size-large",
                title: panel.SelectorSettings.DialogTitle,
                id: "package-selection-dialog",
                body: $(
                    `<div id='ScopeSelectorContentArea'><div id='TabWrapper' style='height:90%'><div id='scope-settings-container' style='padding:8px;' /></div><div id='ScopeArea'/></div>`
                ),
                type: "type-primary",
                buttons: panel.Buttons,
                closable: false
            });

            panel.Dialog.open().done(function (args) {
                panel.BuildPanel(dataScopeModel);
            });

            //TODO: Get the ID of the dialog.

            //var footer = panel.Dialog.getModalFooter();
            //var selectionElem = $("<div>").attr("id", "selectedScope").css("float", "left");
            //var numberElem = $("<span>").attr("id", "num-current-selected").css("float", "left").text("0 Currently Selected");

            //$($(footer).children()[0]).prepend(selectionElem);
            //$($(footer).children()[0]).prepend(numberElem);
        });
    }

    private AddDialogButtons() {
        let panel = this;

        if (!panel.Buttons) {
            panel.Buttons = [];
        }

        panel.Buttons.push({
            toolbar: "bottom",
            location: "after",
            widget: "dxButton",
            options: {
                text: "Cancel",
                icon: "remove",
                type: "danger",
                onClick: function () {
                    //Restore the original set of selections.
                    panel.CurrentSelection.ApplyClone(panel.backupScopeSelection);
                    panel.Buttons = []; // Reset this in case we re-open the dialog.
                    panel.Dialog.close();
                }
            }
        });

        panel.Buttons.push({
            toolbar: "bottom",
            location: "after",
            widget: "dxButton",
            options: {
                text: "OK",
                icon: "todo",
                type: "success",
                id: "btn_apply_dataScope_settings",
                onClick: function () {
                    panel.ApplySettings(false);
                }
            }
        });

        if (panel.SelectorSettings.AllowDefault) {
            panel.Buttons.push({
                toolbar: "bottom",
                location: "after",
                widget: "dxButton",
                options: {
                    text: "Use and Set as Default",
                    type: "default",
                    id: "btn_set_bsi_default",
                    onClick: function () {
                        panel.ApplySettings(true);
                    }
                }
            });
        }
    }

    ApplySettings(setAsDefault: boolean) {
        var panel = this;

        if (
            panel.CurrentSelection.ActiveFolderLevel != 7 &&
            panel.CurrentSelection.CurrentlySelectedItemIDsAsString() == ""
        ) {
            new Notification({
                type: "warning",
                shading: true,
                displayTime: 3000,
                message:
                    "No selection was made. Please make a selection or click Cancel to close the dialog without changing your selection."
            });
        } else {
            // Other tab only.

            if (panel.CurrentSelection.ActiveFolderLevel === 7) {
                panel.CurrentSelection.ActiveSelectionLevel = panel.CurrentSelection.otherSummarySettings.otherSummaryType
            }

            panel.CommitPanelSelections(setAsDefault);
            panel.Buttons = []; // Reset this in case we re-open the dialog.
            if (panel.DialogCallBack) {
                panel.DialogCallBack();
            }
            panel.Dialog.close();
        }
    }

    ChangeFolderLevel(folderLevel) {
        var panel = this;

        //this.CurrentSelection.ActiveSelectionLevel = DataScopeSelection.ConvertFolderTabToScopeLevel(level);
        this.CurrentSelection.ActiveFolderLevel = folderLevel;

        this.ResetSelectionBar();

        if (folderLevel === 4) {
            panel.CheckSelectedItemsInTree();
        }
    }

    UpdateCount() {
        var panel = this;

        var numberSelected = 0;
        if (
            panel.CurrentSelection.SelectedItems[
            "Level" + panel.CurrentSelection.ActiveFolderLevel
            ]
        ) {
            numberSelected = Object.keys(
                panel.CurrentSelection.SelectedItems[
                "Level" + panel.CurrentSelection.ActiveFolderLevel
                ]
            ).length;
        }

        $("#num-current-selected").text(numberSelected + " Currently Selected");
    }

    AddSelectedTag(level, itemID, name) {
        var panel = this;

        panel.CurrentSelection.AddSelectedItem(level, itemID, name);

        var thisItemID = "selectedItem_" + DomSafeID(itemID);
        var onClickMethod = function (e) {
            panel.RemoveSelectedTag(e.target);
        };

        if (level === 5) {
            onClickMethod = function (e) {
                panel.RemoveSelectedSalesRepFromList(e.target);
            };
            thisItemID = DataScopeSelection.GetSalesRepItemID(itemID, name);
        }

        $("#selectedScope").append(
            $(
                "<span class='ScopeTag label label-success' style='margin: 3px'>" +
                name +
                "</span>"
            )
                .attr({
                    id: thisItemID,
                    "data-level": level,
                    "data-itemid": itemID,
                    "data-name": name
                })
                .on("click", onClickMethod)
        );

        this.UpdateCount();
    }

    RemoveSelectedTag(elmnt) {
        var panel = this;

        var level = $(elmnt).data("level");
        var name = $(elmnt).data("name");
        var folderID = $(elmnt).data("itemid");
        var selectedItemID = "selectedItem_" + DomSafeID(folderID);

        $(elmnt).remove();

        var treeElmnt = $(
            $("#thetree_level" + level)
                .dxTreeView("instance")
                .element()
        ).find('div[data-itemid="' + folderID + '"]');
        if (treeElmnt) {
            treeElmnt
                .find(".fa-check-square-o")
                .removeClass("fa-check-square-o")
                .addClass("fa-square-o");
        }

        delete this.CurrentSelection.SelectedItems["Level" + level][selectedItemID];
        this.UpdateCount();
    }

    RemoveSelectedSalesRepFromList(elmnt) {
        var panel = this;

        var level = $(elmnt).data("level");
        var name = $(elmnt).data("name");
        var folderID = $(elmnt).data("itemid");

        $(elmnt).remove();

        var itemID = DataScopeSelection.GetSalesRepItemID(folderID, name);
        if ($("#salesrep-list")) {
            var listElmnt = $("#salesrep-list")
                .dxList("instance")
                .unselectItem(
                    $("#" + itemID)
                        .parent()
                        .parent()
                );
        }
        delete this.CurrentSelection.SelectedItems["Level" + level][itemID];

        this.UpdateCount();
    }

    RemoveSelectedSalesRepFromTags(level, folderID, name) {
        var panel = this;
        var itemID = DataScopeSelection.GetSalesRepItemID(folderID, name);

        $("span#" + itemID).remove();

        delete this.CurrentSelection.SelectedItems["Level" + level][itemID];

        this.UpdateCount();
    }

    CheckSelectedItemsInTree() {
        let panel = this;
        let actvlvl = "Level" + panel.CurrentSelection.ActiveFolderLevel;
        if (panel.CurrentSelection.SelectedItems[actvlvl]) {
            for (let key in panel.CurrentSelection.SelectedItems[actvlvl]) {
                let selectedItem = panel.CurrentSelection.SelectedItems[actvlvl];
                selectedItem = selectedItem[key];
                if (panel.CurrentSelection.ActiveFolderLevel === 5) {
                    var itemID = DataScopeSelection.GetSalesRepItemID(
                        selectedItem.ItemID,
                        selectedItem.Name
                    );
                    if ($("#salesrep-list").hasClass("dx-list")) {
                        var listElmnt = $("#salesrep-list")
                            .dxList("instance")
                            .selectItem(
                                $("#" + itemID)
                                    .parent()
                                    .parent()
                            );
                    }
                } else {
                    let treeElmnt = $(
                        $("#thetree_level" + panel.CurrentSelection.ActiveFolderLevel)
                            .dxTreeView("instance")
                            .element()
                    ).find('div[data-itemid="' + selectedItem.ItemID + '"]');
                    if (treeElmnt) {
                        treeElmnt
                            .find(".fa-square-o")
                            .removeClass("fa-square-o")
                            .addClass("fa-check-square-o");
                    }
                }
            }
        }
    }

    selectAllVisibleCheckboxes() {
        var panel = this;
        var items;
        if (panel.CurrentSelection.ActiveFolderLevel <= 4) {
            items = $(
                "#thetree_level" + panel.CurrentSelection.ActiveFolderLevel
            ).find($(".fa-square-o:visible"));

            for (var index = 0; index < items.length; index++) {
                var entry = items[index];
                $(entry)
                    .removeClass("fa-square-o")
                    .addClass("fa-check-square-o");

                var parent = $(entry).parent();
                var level = parent.data("level");
                var folderID = parent.data("itemid");
                var name = parent.data("name");

                this.AddSelectedTag(level, folderID, name);
            }
        } else if (panel.CurrentSelection.ActiveFolderLevel === 5) {
            if ($("#salesrep-list")) {
                var n = $("#salesrep-list").dxList("instance");
                items = n.option("items");
                for (var i = 0; i < items.length; i++) {
                    n.selectItem(items[i]);
                }
            }
        }
    }

    ExpandAll() {
        var panel = this;
        var expandOnContentReadyFunction = function (response) {
            var n = response.component.option("items");
            for (var i = 0; i < n.length; i++) {
                response.component.expandItem(n[i]);
            }
            panel.CheckSelectedItemsInTree();
        };
        var onItemClickFunction = function (data) {
            if (!data.itemData.hasChildren) {
                panel.OnTreeLeafClicked(data);
            } else {
                panel.CheckSelectedItemsInTree();
            }
        };

        $("#thetree_level" + panel.CurrentSelection.ActiveFolderLevel).remove();

        var newTree = $("<div>").attr(
            "id",
            "thetree_level" + panel.CurrentSelection.ActiveFolderLevel
        ); //.css("max-height", "800px").css("overflow", "hidden").css("overflow-y", "auto");
        var scopeSelectionID =
            "scope-selection-tab-" + panel.CurrentSelection.ActiveFolderLevel;

        $("#" + scopeSelectionID).append(newTree);

        $("#thetree_level" + panel.CurrentSelection.ActiveFolderLevel)
            .dxTreeView({
                dataStructure: "plain",
                dataSource: new dxDataSource({
                    load: function (loadOptions) {
                        return panel._summarySettingsService.GetFolderChildren(
                            "all",
                            panel.CurrentSelection.ActiveFolderLevel
                        );
                    }
                }),
                onContentReady: function (response) {
                    expandOnContentReadyFunction(response);
                },
                expandNodesRecursive: true,
                expandAllEnabled: true,
                rootValue: "0",
                parentIdExpr: "FolderParentID",
                keyExpr: "FolderID",
                displayExpr: "Name",
                virtualModeEnabled: false,
                hasItemsExpr: "hasChildren",
                onItemClick: function (data) {
                    onItemClickFunction(data);
                },
                itemTemplate: function (itemData): string | JQuery {
                    var itemID = "item_" + DomSafeID(itemData.FolderID);
                    if (itemData.hasChildren) {
                        return "item";
                    } else {
                        return $("<div>")
                            .text(itemData.Name)
                            .attr("id", itemID)
                            .attr("data-level", itemData.Level)
                            .attr("data-itemid", itemData.FolderID)
                            .attr("data-name", itemData.Name)
                            .append(
                                $("<i>")
                                    .addClass("fa fa-square-o")
                                    .css("float", "left")
                                    .css("margin-right", "20px")
                            );
                    }
                }
            })
            .dxTreeView("instance");
    }

    ClearSelections() {
        var panel = this;

        if (
            this.CurrentSelection.SelectedItems[
            "Level" + panel.CurrentSelection.ActiveFolderLevel
            ]
        ) {
            for (var key in this.CurrentSelection.SelectedItems[
                "Level" + panel.CurrentSelection.ActiveFolderLevel
            ]) {
                var selectedItem = this.CurrentSelection.SelectedItems[
                    "Level" + panel.CurrentSelection.ActiveFolderLevel
                ][key];
                if (panel.CurrentSelection.ActiveFolderLevel === 5) {
                    var itemID = DataScopeSelection.GetSalesRepItemID(
                        selectedItem.ItemID,
                        selectedItem.Name
                    );
                    if ($("#salesrep-list")) {
                        var listElmnt = $("#salesrep-list")
                            .dxList("instance")
                            .unselectItem(
                                $("#" + itemID)
                                    .parent()
                                    .parent()
                            );
                    }
                } else if (panel.CurrentSelection.ActiveFolderLevel != 6) {
                    var treeItemElmnt = $(
                        $(
                            "#thetree_level" + panel.CurrentSelection.ActiveFolderLevel
                        ).dxTreeView("instance").element
                    ).find('div[data-itemid="' + selectedItem.ItemID + '"]');
                    if (treeItemElmnt) {
                        treeItemElmnt
                            .find(".fa-check-square-o")
                            .removeClass("fa-check-square-o")
                            .addClass("fa-square-o");
                    }
                }
                //TODO:  move this deletion to the other class.
                delete this.CurrentSelection.SelectedItems[
                    "Level" + panel.CurrentSelection.ActiveFolderLevel
                ][key];
            }
        }
        this.ResetSelectionBar();
    }

    ResetSelectionBar() {
        var panel = this;

        $("#selectedScope")
            .find("span")
            .remove();
        if (panel.CurrentSelection.ActiveFolderLevel <= 4) {
            // Saved Selections and Other tab don't use the selected items area.  SalesRep tab gets filled in differently.
            if (
                this.CurrentSelection.SelectedItems[
                "Level" + panel.CurrentSelection.ActiveFolderLevel
                ]
            ) {
                for (var key in this.CurrentSelection.SelectedItems[
                    "Level" + panel.CurrentSelection.ActiveFolderLevel
                ]) {
                    var selectedItem = this.CurrentSelection.SelectedItems[
                        "Level" + panel.CurrentSelection.ActiveFolderLevel
                    ][key];
                    this.AddSelectedTag(
                        selectedItem.Level,
                        selectedItem.ItemID,
                        selectedItem.Name
                    );
                }
            }
        }
        this.UpdateCount();
    }

    ShowHelpPop() {
        var panel = this;

        var helppop = $(
            "#helpPop_level" + panel.CurrentSelection.ActiveFolderLevel
        ).dxPopover("instance");
        helppop.option(
            "target",
            "#search-treeview_level" + panel.CurrentSelection.ActiveFolderLevel
        );
        helppop.show();

        $($("#searchboxhelp_level" + panel.CurrentSelection.ActiveFolderLevel)[0])
            .unbind()
            .hover(
                function () {
                    helppop.show();
                },
                function () {
                    helppop.hide();
                }
            );
    }

    CommitPanelSelections(setAsDefault: boolean) {
        var panel = this;
        // We don't need to do actually commit the selections since they are kept up to date in a separte object.  But, we do need to save the default.  
        //alert("This should be overridden in an inherited class.")
        if (setAsDefault) {
            Preferences.SetPreference("HomeList", panel.CurrentSelection.CurrentlySelectedItemIDsAsString(), "BSISummary");
            Preferences.SetPreference("HomeType", panel.CurrentSelection.ActiveSelectionLevel, "BSISummary");
        }
    }

    LoadSavedSelection(id) {
        let panel = this;
        //alert("This should be overridden in an inherited class.");

        let request: TDFRequest = new TDFRequest({
            url: "/bi/savedselection/",
            type: "GET",
            data: { savedSelectionID: id }
        });

        request.MakeRequest().done(function (response: any) {

            panel.ClearSelections();

            let items: any = response.ItemListWithNames;

            let level = response.Type;

            panel.CurrentSelection.ActiveSelectionLevel = level;
            //panel.CurrentSelection
            panel.CurrentSelection.ActiveFolderLevel = DataScopeSelection.ConvertScopeLevelToFolderTab(level);


            $.each(Object.keys(items), function (index, key) {
                panel.CurrentSelection.AddSelectedItem(panel.CurrentSelection.ActiveFolderLevel, key, items[key]);
            });

            //panel.ApplySettings(false);


        });
    }

}
