import { ToolbarTemplateFactory } from "./toolbartemplatefactory";

export function CustomAddButton(args) {
    let AddButtonOnClick: Function = () => { };
    if (args && args.length) {
        args.map((v, k) => {
            if ($.isPlainObject(v)) {
                Object.keys(v).forEach((val, key) => {
                    if (val.toLowerCase() === "addbuttononclick") {
                        AddButtonOnClick = v[val];
                    }
                });
            }
        });
    }

    return ToolbarTemplateFactory('before', 'never', false, <DevExpress.ui.dxButtonOptions>{
        text: "New",
        hint: "New",
        icon: "dx-icon fa fa-plus",
        onClick: function (e) {
            AddButtonOnClick();
        }
    }, 'dxButton');
}