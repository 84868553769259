import "devextreme/ui/list";
import "devextreme/ui/accordion";
import { itemTypes, CenterType } from "../../enums/enums";
import {
  GetDevice, makeFluid,
} from "../../util/allutils";
import { TDFRequest } from "../../services/request";
import { Dialog } from "../dialogs/dialog";
import {
  IItemInfo,
  IDialogOptions
} from "../../interfaces/interfaces";
import { Search, SearchModel, ISearchType } from "../../legacy-centers-grids/search";
import { RFCenter } from "../../datacenters/recent-favorites";
import { RaiseEvent2, WebEvents } from "../../infrastructure/events/ui_events"
import { eventNameSpace, EventTypes } from "enums/webevents/enums";

export class SearchRecFav {
  get Tabs() {
    const SRF = this;
    const tabs: any = [
      {
        title: "Search",

        template(itemData, index, itemElement) {
          $(itemElement)
            .height('calc(100% - 30px)')
            .append(
              '<div id="' +
              itemData.title.replace(" ", "_") +
              "Toolbar_" +
              SRF.Time +
              '" />'
            )
            .append(
              '<div id="' +
              itemData.title.replace(" ", "_") +
              "Container_" +
              SRF.Time +
              '" />'
            );
          function func() {
            SRF.SearchModel.SearchContainer = "SearchContainer_" + SRF.Time;
            SRF.Search = SRF.GetSearch();
          }
          if (!SRF.SearchModel) {
            SRF.GetDefaultSearchModel().done(model => {
              func();
            })
          } else {
            setTimeout(() => {
              func();
            }, 500);
          }
        }
      },
      {
        title: "Recent",
        template(itemData, index, itemElement) {

          makeFluid(itemElement);

          let timer = setInterval((e) => {
            if ($(itemElement.parent()).find($(itemElement)).length > 0) {
              clearInterval(timer);

              SRF.InitRFCenter(CenterType.Recent, itemElement);

              if (GetDevice().isDevice) {
                itemElement.height('100%');
              }
            }
          }, .25e3);
        }
      },
      {
        title: "Favorites",
        template(itemData, index, itemElement) {
          makeFluid(itemElement);
          let timer = setInterval((e) => {
            if ($(itemElement.parent()).find($(itemElement)).length > 0) {
              clearInterval(timer);

              SRF.InitRFCenter(CenterType.Favorite, itemElement);

              if (GetDevice().isDevice) {
                itemElement.height('100%');
              }
            }
          }, .25e3);
        }
      }
    ];
    if (SRF.extraTab.title) {
      tabs.unshift(SRF.extraTab);
    }
    return tabs;
  }

  public Item: IItemInfo;
  public Search: Search;
  public Recent: RFCenter;
  public Fav: RFCenter;
  public Dialog: Dialog;
  public Time;
  public isDevice: boolean;
  public SRFDeferred: JQueryDeferred<any>;
  /*This is the itemtype of the item we are trying to create or perform an action on*/
  public ChildOrSourceItemType: itemTypes = itemTypes.itemUnknown;
  /*This is the valid types the user will be able to select from in Search Recent or Favorites when creating a new item or performing an action such as linking items*/
  public AllowedTypes: itemTypes[] = [999];
  public PreferredTypeToShow: itemTypes = itemTypes.itemUnknown;
  public multiSelectAllowed: boolean = true;
  public StartingTabIndex: number;
  public extraButton;
  public Container;
  public SearchModel: SearchModel;
  public extraTab = {
    title: null,
    buildContent: null,
    getSelected: null
  };

  //Controls if the OK (submit) button is shown
  private _ReadOnly: boolean = false;
  get ReadOnly(): boolean {
    return this._ReadOnly;
  }
  set ReadOnly(val: boolean) {
    if (val) {
      this._ReadOnly = val;
    }
  }

  // TODO: Refactor to get the selection action overridden depending on what needs to happen... Ex: when using this dialog to select attachments for an email make the selection bring up the attachments for the selected item
  constructor(
    ChildOrSourceType: itemTypes,
    allowedTypes: itemTypes[],
    recFavDdlType: itemTypes,
    multiSelectAllowed: boolean,
    startingTabIndex = 0,
    extraButton = null
  ) {
    const SRF = this;

    SRF.ChildOrSourceItemType = ChildOrSourceType;
    SRF.AllowedTypes = allowedTypes;

    SRF.PreferredTypeToShow = recFavDdlType;
    SRF.multiSelectAllowed = multiSelectAllowed;
    SRF.StartingTabIndex = startingTabIndex;
    SRF.extraButton = extraButton;

    SRF.Time = Date.now();
    SRF.isDevice = GetDevice().isDevice;
    SRF.SRFDeferred = $.Deferred();
  }

  public GetSRFDialog() {
    const SRF = this;

    SRF.SetupContainer();

    const buttons = SRF.GetDialogButtons();

    SRF.Dialog = new Dialog(
      {
        id: `search_${SRF.Time}`,
        body: $("<div />").height('100%').append(SRF.Container),
        type: "type-info",
        closable: true,
        //fullScreen: true,
        size: 'size-large',
        title: SRF.ReadOnly ? "" : "Select Item",
        buttons
      } as IDialogOptions,
      null,
      false,
      false
    );

    SRF.Dialog.open();

    return SRF.SRFDeferred.promise();
  }

  public GetSearch() {
    const SRF = this;
    const search = new Search(SRF.SearchModel);
    search.MultiSelectAllowed = SRF.multiSelectAllowed;
    search.UpdateToolbar();

    // search.SearchFieldSelector = search.SearchForm.getEditor("SearchField") as dxLookup;

    return search;
  }

  public GetDefaultSearchModel(): JQueryPromise<any> {
    let SRF = this;

    let d: JQueryDeferred<any> = $.Deferred();

    const theTypeToSearch = SRF.TypesLimited && SRF.AllowedTypes.length === 1
      ? SRF.AllowedTypes[0]
      : itemTypes.itemUnknown;

    /*Here*/
    const req = new TDFRequest({
      url: "/core/search/GetSearchSetup?" + $.param({ type: theTypeToSearch }),
      type: "GET"
    });
    req.MakeRequest().done(function (data: any) {
      SRF.SearchModel = data;
      if (SRF.TypesLimited) {
        SRF.SearchModel.SearchTypes = $.grep(SRF.SearchModel.SearchTypes, (val) => {
          return $.inArray(val.Type, SRF.AllowedTypes) > -1;
        });

        if (
          SRF.SearchModel.SearchTypes.map(a => {
            return a.Type;
          }).includes(SRF.SearchModel.LastSearch)
        ) {
          SRF.SearchModel.SelectedType = SRF.SearchModel.LastSearch;
        } else {
          SRF.SearchModel.SelectedType = SRF.SearchModel.SearchTypes[0].Type;
        }

      } else {
        SRF.SearchModel.SelectedType = SRF.SearchModel.LastSearch;
      }

      d.resolve(SRF.SearchModel);
    });

    return d.promise();
  }

  /**
   * Returns True if the allowed item types are less than all available item types.
   */
  get TypesLimited(): boolean {
    let SRF = this;
    return !(
      SRF.AllowedTypes.length === 1 &&
      SRF.AllowedTypes[0] === itemTypes.itemAllItems
    );
  }

  public GetSelected() {
    const SRF: SearchRecFav = this;
    let selected;
    let selectedTab;

    // if (!SRF.isDevice) {
    selectedTab = SRF.Container.dxTabPanel("instance").option("selectedItem");
    // } else {
    //   selectedTab = SRF.Container.dxAccordion("instance").option(
    //     "selectedItem"
    //   );
    // }

    if (selectedTab.title === "Search") {
      // if (!SRF.isDevice) {
      selected = SRF.Search.SearchGrid.GridObject.getSelectedRowsData();
      //  }
      // else {
      //    selected = SRF.Search.GridContainer.dxList("instance").option("selectedItems")[0].items;
      // }
    } else if (selectedTab.title === "Recent") {
      selected = SRF.RaiseGetSelectedItemsEvent(CenterType.Recent);

    } else if (selectedTab.title === "Favorites") {
      selected = SRF.RaiseGetSelectedItemsEvent(CenterType.Favorite);

    } else if (selectedTab.title === SRF.extraTab.title) {
      selected = SRF.extraTab.getSelected();
    } else {
      return;
    }

    return selected;
  }

  RaiseGetSelectedItemsEvent(type: CenterType) {
    let SRF = this;

    let d = {
      items: []
    }
    //Raise teh get items event 
    //RaiseEvent(WebEvents.eventType.gridselecteditems, WebEvents.eventNameSpace.request, d);

    RaiseEvent2(EventTypes.CenterEventTypes.gridselecteditems, `${CenterType[type]}_${SRF.Time}`, eventNameSpace.request, d);

    return d.items;

    // add the getitems done listener
    //(e) => {

    //this is the listener
    //d.items
    //d can either be pssed back in or already have the data
    //}



  }

  private GetDialogButtons() {
    const SRF: SearchRecFav = this;

    let buttons = [];

    if (!SRF.ReadOnly) {
      buttons.push({
        widget: "dxButton",
        toolbar: "bottom",
        location: "after",
        options: {
          id: "btnConfirm",
          text: "OK",
          onClick: () => {
            const selected = SRF.GetSelected();
            if (!selected.length) {
              return;
            }
            // send selected item(s) back to origin for correct processing
            SRF.SRFDeferred.resolve(selected);
          },
          type: "success",
          icon: "check"
        }
      }, {
        widget: "dxButton",
        toolbar: "bottom",
        location: "after",
        options: {
          id: "btncancel",
          text: "Cancel",
          icon: "remove",
          onClick() {
            SRF.Dialog.close();
          },
          type: "danger"
        }
      });
    }

    if (SRF.extraButton) {
      buttons.unshift(SRF.extraButton);
    }

    return buttons;
  }

  private InitRFCenter(type: CenterType, itemElement: JQuery) {
    let SRF = this;

    let recFav = new RFCenter(type, `${CenterType[type]}_${SRF.Time}`, $(itemElement))
    recFav.GridOptionOverrides = {
      selection: {
        mode: SRF.multiSelectAllowed ? 'multiple' : 'single',
        showCheckBoxesMode: 'always'
      }
    };

    recFav.AllowedTypesToShow = SRF.AllowedTypes;
    recFav.DefaultItemType = SRF.PreferredTypeToShow;

    recFav.Init();
  }

  private SetupContainer() {
    const SRF: SearchRecFav = this;

    // let tabs: any = [
    //    { "title": "Search" },
    //    { "title": "Recent" },
    //    { "title": "Favorites" }
    // ];

    // if (SRF.extraTab.title) {
    //   // tabs.unshift({ "title": SRF.extraTab.title });
    // }

    // if (!SRF.isDevice) {
    SRF.Container = $("<div />").dxTabPanel({
      dataSource: SRF.Tabs,
      selectedIndex: SRF.StartingTabIndex,
      swipeEnabled: false,
      height: '100%'
    });
    // } else {
    //   SRF.Container = $("<div />").dxAccordion({
    //     dataSource: SRF.Tabs,
    //     selectedIndex: SRF.StartingTabIndex,
    //     collapsible: true,
    //     height: '100%'
    //   });
    // }
  }
}
