import { AwesomeGrid } from "./datacentergrid";
import { CenterType } from "enums/enums";
import { DomSafeID, GetDevice, gethash } from "util/allutils";
import { Preferences } from "infrastructure/user/preferences";
import { IExtendedDataGridColumn } from "interfaces/interfaces";
import { Grid } from "legacy-centers-grids/grid";

export class NotAsAwesomeGrid extends AwesomeGrid {

    protected GetDxOptions(): DevExpress.ui.dxDataGridOptions {
        let theGrid = this;

        let options = super.GetDxOptions()
        options.elementAttr = { "data-gridID": DomSafeID(theGrid.EventLimiterName) };
        options.stateStoring = { enabled: false };
        options.onToolbarPreparing = () => { };

        return options;
    }

    get UseCards(): boolean {
        let theGrid = this;
        if (theGrid._UseCards === null) {
            theGrid._UseCards = GetDevice().isDevice &&
                Preferences.GetPreference(
                    `UseCardView_${theGrid.EventLimiterName}`,
                    "WebGrid",
                    "1"
                ) === "1"
        }

        return theGrid._UseCards
    }

    private _DataSource: DevExpress.data.DataSource;
    get DataSource(): DevExpress.data.DataSource {
        return this._DataSource;
    }
    set DataSource(val: DevExpress.data.DataSource) {
        this._DataSource = val;
    }

    private _DxColumns: IExtendedDataGridColumn[];
    get DxColumns() {
        return this._DxColumns;
    }
    set DxColumns(val: IExtendedDataGridColumn[]) {
        this._DxColumns = val;
    }

    constructor(
        gridcontainer: JQuery,
        center: CenterType,
        eventLimiterName: string,
        dataSource: DevExpress.data.DataSource,
        columns: IExtendedDataGridColumn[]
    ) {
        super(gridcontainer, center, null, eventLimiterName);
        this.DataSource = dataSource;
        this.DxColumns = columns;
    }

    protected SetupStuff() {
        // Do Nothing
    }

    protected getData() {
        let theGrid = this;

        theGrid.DataSource.load().done(data => {
            if (!data || (data.length === 0 && theGrid.Instance)) {
                theGrid.Instance.option("noDataText", "No Data");
            } else {
                theGrid.DoPostLoad();
            }
        })
    }

    protected getLayout(resetting = false): JQueryPromise<any> {
        let theGrid = this;
        let d: JQueryDeferred<any> = $.Deferred();

        theGrid.OverrideDxOptions({ columns: theGrid.DxColumns });
        theGrid.renderGridShell();

        theGrid._ColumnHash = gethash(
            "dataField",
            theGrid.DxColumns
        );

        return d.promise(d.resolve());
    }

    protected DoPostLoad() {
        let theGrid = this;
        let instance = theGrid.Instance;
        let gridel = theGrid._container
            .children()
            .first()
            .detach();
        instance.beginUpdate();
        let parent = theGrid._container;

        if (!instance.option("dataSource")) {
            instance.option("dataSource", theGrid.DataSource);
        }
        parent.append(gridel);
        instance.endUpdate();
    }

    protected CreateIt() {
        let theGrid = this;
        return theGrid.UseCards ?
            theGrid.CardView() :
            $(`<div id='tdf-datagrid${DomSafeID(theGrid.EventLimiterName)}' />`).dxDataGrid(
                theGrid.GetDxOptions()
            );
    }

    protected RefreshGrid(e: JQueryEventObject, data) {
        let theGrid = this;

        if (data) {
            if (data.clear) {
                switch (data.clear) {
                    case "all":
                        (theGrid.Instance as DevExpress.ui.dxDataGrid).clearFilter();
                        (theGrid.Instance as DevExpress.ui.dxDataGrid).clearSorting();
                        break;
                    case "filter":
                        (theGrid.Instance as DevExpress.ui.dxDataGrid).clearFilter();
                        break;
                    case "sort":
                        (theGrid.Instance as DevExpress.ui.dxDataGrid).clearSorting();
                        break;
                }
            }
        }
        theGrid.ReloadData();
    }

    protected ReloadData(doneAction: () => void = $.noop) {
        let theGrid = this;
        theGrid.DataSource.reload().done(() => {
            doneAction();
        });
    }
}