import { DrillHelper } from "../../../datacenters/bidatacenters/drillhelper";
import { BSIGrids } from "../../../enums/bi/enums";
import { AddHandler2, RaiseEvent2 } from "../../../infrastructure/events/ui_events";
import { IGridView } from "../../../interfaces/grids/interfaces";
import { BIGridDataService, GridViewServiceBI } from "../../../services/grid/bigriddataservice";
import { CenterType } from "../../../enums/enums";
import { AwesomeGrid, ViewResponse } from "../datacentergrid";
import { GridSetup } from "../../../services/grid/helpers/gridsetup";
import { DataScopeSelection } from "../../dashboards/dynamicdatascopeselector";
import { eventNameSpace, EventTypes } from "../../../enums/webevents/enums";
import { gethash } from "../../../util/allutils";
import { BiColumnTemplateHandler } from "../../../datacenters/bidatacenters/bi_utils";
import { GetBIPrefModuleFromBISummarySource, GetBISummarySourceFromCenterType } from "../../../datacenters/bidatacenters/bi_utils";

export class BiDataCenterGrid extends AwesomeGrid {

    constructor(gridcontainer: JQuery, center: CenterType, view: IGridView, eventLimiterName: string) {
        super(gridcontainer, center, view, eventLimiterName);
        //RaiseEvent(EventTypes.BIEventTypes.currentsummaryselection, EventNameSpace.request)
    }

    protected SetupStuff() {
        this.viewService = new GridViewServiceBI();
        this.dataService = new BIGridDataService(this.CenterType, this.view);
        //this.dataService.RequestModel.RequestArgs.IncludeGridViewInformation = false;
    }

    init() {
        this.getLayout().done(() => {
            this.getData();
        });
    }

    AddListeners() {
        let grid = this;
        super.AddListeners();
        AddHandler2(EventTypes.BIEventTypes.currentsummaryselection, grid.EventLimiterName, eventNameSpace.notify, this._container, (e, d) => {
            grid.UpdateRequestArgsSummarySettings(d.NewSummarySettings);
        });

        AddHandler2(EventTypes.BIEventTypes.currentgoalselection, grid.EventLimiterName, eventNameSpace.notify, this._container, (e, d) => {
            grid.UpdateRequestArgsGoalSelection(d.NewGoalType);
        });
    }

    ColumnTemplateHandler(col: any) {
        let grid = this;
        BiColumnTemplateHandler(col, grid.CenterType, grid.EventLimiterName, grid.view.ItemType as BSIGrids);
    }

    private UpdateRequestArgsSummarySettings(scopeSettings) {
        let grid = this;
        (grid.dataService as BIGridDataService).RequestArgs.BISummaryType = scopeSettings.ActiveSelectionLevel;
        (grid.dataService as BIGridDataService).RequestArgs.OptionalGroupSummaryOptions.SummarySource = GetBISummarySourceFromCenterType(grid.CenterType);
        grid.dataService.RequestModel.RequestArgs.ContainerID = scopeSettings.CurrentlySelectedItemIDsAsString();
    }

    private UpdateRequestArgsGoalSelection(newGoalType) {
        let grid = this;
        if (newGoalType != -1) {
            ((grid.dataService as BIGridDataService).RequestModel as GridSetup.BIRequestModel).RequestArgs.GoalType = newGoalType;
        } else {
            ((grid.dataService as BIGridDataService).RequestModel as GridSetup.BIRequestModel).RequestArgs.GoalType = -1;
        }
    }

    getData() {
        let grid = this;
        let scopeSettingsDFD = $.Deferred(); //JQueryDeferred<DataScopeSelection> = $.Deferred();

        RaiseEvent2(EventTypes.BIEventTypes.currentsummaryselection, grid.EventLimiterName, eventNameSpace.request, { deferred: scopeSettingsDFD });
        // NEEDTODO:  Need to make these specific to the BI Center they apply to.  So if an Account is opened, or linked contacts/opportunity, etc, then the BI Center for that specific item doesn't conflict here.

        $.when(scopeSettingsDFD).then(function (scopeSettings: DataScopeSelection) {
            (grid.dataService as BIGridDataService).RequestModel.RequestArgs.IncludeGridViewInformation = false;
            let goal: number = scopeSettings.GetGoalSelection(GetBIPrefModuleFromBISummarySource(GetBISummarySourceFromCenterType(grid.CenterType)));
            if (goal != -1) {
                ((grid.dataService as BIGridDataService).RequestModel as GridSetup.BIRequestModel).RequestArgs.GoalType = goal;
            }

            grid.UpdateRequestArgsSummarySettings(scopeSettings);

            grid.dataService.DataSource.load().done(data => {
                if (!data || (data.length === 0 && grid.Instance)) {
                    grid.Instance.option("noDataText", "No Data");
                } else {
                    if (grid.dataService.ResponseFromServer && grid.dataService.ResponseFromServer.GridView) {
                        grid.ViewLayout.GridViewModel = grid.dataService.ResponseFromServer.GridView;
                    }
                    grid.DoPostLoad();
                }
            });
        });
    }

    getLayout(resetting = false): JQueryPromise<any> {
        let grid = this;
        let d = $.Deferred();

        if (grid.view.GUID === "SalesSummary") {
            return d.promise(d.resolve());
        }
        let sendDFD: JQueryDeferred<DataScopeSelection> = $.Deferred();

        RaiseEvent2(EventTypes.BIEventTypes.currentsummaryselection, grid.EventLimiterName, eventNameSpace.request, { deferred: sendDFD });

        sendDFD.done(function (scopeSettings: DataScopeSelection) {
            if (grid.view && grid.view.GUID) {
                (grid.viewService as GridViewServiceBI)
                    .LayoutForViewRequest({
                        viewid: grid.view.GUID,
                        isdrillin: false,
                        summaryType: scopeSettings.ActiveSelectionLevel
                    })
                    .MakeRequest()
                    .done((response: ViewResponse) => {
                        grid.ViewLayout = grid.TryAddDrillCol(response, scopeSettings);
                        grid.dataService.GridViewInformation = response;
                        if (grid.ViewLayout.GridViewModel.Layout.Columns) {
                            grid._ColumnHash = gethash("dataField", grid.ViewLayout.GridViewModel.Layout.Columns);
                        }
                        d.resolve();
                    });
            }
        });

        return d.promise();
    }

    protected GetDxOptions(): DevExpress.ui.dxDataGridOptions {
        let grid = this;
        let options = super.GetDxOptions();

        if (
            !(
                grid.view &&
                (grid.view.ItemType === BSIGrids.Acct ||
                    grid.view.ItemType === BSIGrids.BSIContactAcctGroupSummary ||
                    grid.view.ItemType === BSIGrids.BSIJobAcctGroupSummary ||
                    grid.view.ItemType === BSIGrids.GapAnalysis)
            )
        ) {
            options.selection = { mode: "none" };
            options.onContextMenuPreparing = null;
            options.onSelectionChanged = function (e) {
                //do nothing!
            };
        }

        return options;
    }

    private TryAddDrillCol(
        response: ViewResponse,
        scopeSettings: DataScopeSelection
    ) {
        let grid = this;
        let helper = new DrillHelper(grid.CenterType, grid.EventLimiterName, scopeSettings);

        helper.Initialize().done(() => {
            if (helper.DoesItemTypeAllowDrilldown(grid.view.ItemType)) {
                let drillDownCol: DevExpress.ui.dxDataGridColumn | any = helper.CreateDrilldownColumn(grid.view.ItemType as BSIGrids);
                response.GridViewModel.Layout.Columns.unshift(drillDownCol);
            }
        });
        return response;
    }
}
