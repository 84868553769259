import { DomSafeID } from "../../util/allutils";
import { eventNameSpace, EventTypes } from "../../enums/webevents/enums";

export namespace WebEvents {

  export type EventNameSpaceT = eventNameSpace;
  export const EventNameSpace = Object.assign({}, eventNameSpace);

  export type EventTypeT =
    EventTypes.GeneralEventTypes |
    EventTypes.CenterEventTypes |
    EventTypes.DashboardEventTypes |
    EventTypes.BIEventTypes |
      EventTypes.RFCenterEventTypes |
      EventTypes.GridEventTypes

    export const EventType = Object.assign(
    EventTypes.GeneralEventTypes,
    EventTypes.CenterEventTypes,
    EventTypes.DashboardEventTypes,
    EventTypes.BIEventTypes,
    EventTypes.RFCenterEventTypes,
    EventTypes.GridEventTypes
  );

  export class Event {
    /**
     * Add an event handler to a container;
     * @param type: The type of the event eg("gridrowselect")
     * @param namespace:The namespace of the event eg("notify")
     * @param container :Always expected to be a JQuery object not a html element
     * @param handler : the function to execute when the event is raised
     * @param data: OPTIONAL  No matter what is passeed in here you will need to access it via [**theEventObjectParameter**.data.value] in the handler
     */
    static AddHandler(
      type: EventTypeT,
      namespace: EventNameSpaceT,
      container: JQuery,
      handler: (eventObject: JQueryEventObject, ...args: any[]) => any,
      data = null
    ) {
      Event.AddHandler2(type, "", namespace, container, handler, data);
    }

    /**
     * Add an event handler to a container;
     * @param type: The type of the event eg("gridrowselect")
     * @param namespace:The namespace of the event eg("notify")
     * @param container :Always expected to be a JQuery object not a html element
     * @param handler : the function to execute when the event is raised
     * @param data: OPTIONAL  No matter what is passeed in here you will need to access it via [**theEventObjectParameter**.data.value] in the handler
     */
    static AddHandler2(
      type: EventTypeT,
      limiter: string,
      namespace: EventNameSpaceT,
      container: JQuery,
      handler: (eventObject: JQueryEventObject, ...args: any[]) => any,
      data = null
    ) {
      var className = Event.GetClassName(
        `${EventType[type]}`,
        limiter,
        `${EventNameSpace[namespace]}`
      );
      var eventName = Event.GetEventName(
        `${EventType[type]}`,
        limiter,
        `${EventNameSpace[namespace]}`
      );

      if (!container.hasClass(`${className}`)) {
        container.addClass(`${className}`);
      }
      if (data) {
        data = { value: data };
      }
      container.off(`${eventName}`);
      container.on(`${eventName}`, data, handler);
    }

    static RaiseEvent(type: EventTypeT, namespace: EventNameSpaceT, data = null) {
      Event.RaiseEvent2(type, "", namespace, data);
    }

    static RaiseEvent2(
      type: EventTypeT,
      limiter: string,
      namespace: EventNameSpaceT,
      data = null
    ) {
      var className = Event.GetClassName(
        `${EventType[type]}`,
        limiter,
        `${EventNameSpace[namespace]}`
      );
      var eventName = Event.GetEventName(
        `${EventType[type]}`,
        limiter,
        `${EventNameSpace[namespace]}`
      );

      $(`.${className}`).trigger(`${eventName}`, data);
    }

    static RemoveHandler(
      type: EventTypeT,
      namespace: EventNameSpaceT,
      container: JQuery
    ) {
      Event.RemoveHandler2(type, "", namespace, container);
    }

    static RemoveHandler2(
      type: EventTypeT,
      limiter: string,
      namespace: EventNameSpaceT,
      container: JQuery
    ) {
      var className = Event.GetClassName(
        `${EventType[type]}`,
        limiter,
        `${EventNameSpace[namespace]}`
      );
      var eventName = Event.GetEventName(
        `${EventType[type]}`,
        limiter,
        `${EventNameSpace[namespace]}`
      );

      if (container.hasClass(`${className}`)) {
        container.off(`${eventName}`);
        container.removeClass(`${className}`);
      }
    }

    private static GetClassName(
      type: string,
      limiter: string,
      namespace: string
    ) {
      var restrictor: string = "";
      if (limiter) {
        restrictor = `_${limiter}`;
      }

      return DomSafeID(`${type}${restrictor}_${namespace}`);
    }

    private static GetEventName(
      type: string,
      limiter: string,
      namespace: string
    ) {
      var restrictor: string = "";
      if (limiter) {
        restrictor = `_${limiter}`;
      }
      let part1 = DomSafeID(`${type}${restrictor}`);
      let part2 = DomSafeID(`${namespace}`);

      return `${part1}.${part2}`;
    }
  }

}

export var RaiseEvent = WebEvents.Event.RaiseEvent;
export var RaiseEvent2 = WebEvents.Event.RaiseEvent2;
export var AddHandler = WebEvents.Event.AddHandler;
export var AddHandler2 = WebEvents.Event.AddHandler2;
export var RemoveHandler = WebEvents.Event.RemoveHandler;
export var RemoveHandler2 = WebEvents.Event.RemoveHandler2;