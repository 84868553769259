"use strict";
import { itemTypes } from "../enums/enums";
import { GridSetup } from "../services/grid/helpers/gridsetup";
import { GridToolbar } from "../components/grids/gridtoolbars/gridtoolbar";
import { Grid } from "./grid";
import { GridWithoutViews } from "./gridwithoutviews";
import { IGridToolbarModel } from "../interfaces/interfaces";
import { TDFRequest } from "../services/request";
import { Preferences } from "../infrastructure/user/preferences";
import { ToolbarWithoutViews } from "../components/grids/gridtoolbars/toolbarwithoutviews";
import { Notification } from "../components/dialogs/notification";
import { LoadCompany, DisplayInfo } from "../infrastructure/context";
import { GetDevice, DomSafeID, GetDataResponseObject } from "../util/allutils";

import dxCustomStore from "devextreme/data/custom_store";
import dxDataSource from "devextreme/data/data_source";
import devices from "devextreme/core/devices";

export interface ISearchType {
  Type: itemTypes;
  Name: string;
}

export interface RecentSearch {
  ItemType: itemTypes;
  SearchDate: Date;
  SearchText: string;
}

export interface SearchModel {
  GridToolbar: IGridToolbarModel;
  SearchFields: string[];
  SearchTypes: ISearchType[];
  RecentSearches: RecentSearch[];
  DisableType?: boolean;
  SelectedType?: itemTypes;
  LastSearch?: itemTypes;
  DefaultSearchField?: string;
  SelectedField?: string;
  SearchText?: string;
  SearchContainer?: string;
  SelectionEvent?: Function;
}

export class Search {
  SearchContainer: string = "tdfsearch";
  ToolbarContainer: JQuery;
  GridContainer: JQuery;
  GridContainerID: string = "";
  RequestModel: GridSetup.SearchRequestModel;
  SearchTypeSelector: DevExpress.ui.dxSelectBox;
  SearchFieldSelector: DevExpress.ui.dxLookup;
  SearchButton: DevExpress.ui.dxButton;
  SearchBox: DevExpress.ui.dxTextBox;
  GridToolbar: GridToolbar;
  RecentSearches: RecentSearch[];
  SearchTypes: Array<ISearchType>;
  SearchFields: Array<string>;
  SearchGrid: Grid | GridWithoutViews;
  GridToolbarModel: IGridToolbarModel;
  DisableType: boolean;
  SelectedType: itemTypes;
  DefaultSearchField: string = "";
  SelectedField: string;
  SearchText: string;
  SelectionEvent: Function;
  SearchForm: DevExpress.ui.dxForm;
  MultiSelectAllowed: boolean = true;
  LastSearchResult: any;
  LastSearch: itemTypes;
  constructor(args: SearchModel) {
    let thisSearch = this;

    thisSearch.GridToolbarModel = args.GridToolbar;
    thisSearch.SearchFields = args.SearchFields;
    thisSearch.SearchTypes = args.SearchTypes;
    thisSearch.RecentSearches = args.RecentSearches;
    thisSearch.DisableType = args.DisableType || false;
    if (args.SelectedType) thisSearch.SelectedType = args.SelectedType;
    if (args.LastSearch) thisSearch.LastSearch = args.LastSearch;
    if (args.DefaultSearchField)
      thisSearch.DefaultSearchField = args.DefaultSearchField;
    if (args.SelectedField) thisSearch.SelectedField = args.SelectedField;
    if (args.SearchText) thisSearch.SearchText = args.SearchText;
    if (!thisSearch.SelectedField && thisSearch.DefaultSearchField)
      thisSearch.SelectedField = thisSearch.DefaultSearchField;
    if (args.SearchContainer) {
      thisSearch.SearchContainer = args.SearchContainer;
      $(`#${thisSearch.SearchContainer}`).height('100%');
    }
    if (!thisSearch.SelectedType) {
      thisSearch.GetLastSearch();
    }

    thisSearch.CreateSearchForm();
    thisSearch.SearchGridToolbar();

    if (args.SelectionEvent) thisSearch.SelectionEvent = args.SelectionEvent;
    LoadCompany().done(() => {
      if (thisSearch.GridToolbar) {
        if (!thisSearch.GridToolbar.CurrentView)
          thisSearch.GridToolbar.CurrentView =
            thisSearch.GridToolbar.DefaultView;
      }
    });
  }

  CreateSearchForm() {
    let thisSearch = this;

    thisSearch.SearchForm = $("<div />")
      .css('padding', '5px')
      .dxForm(thisSearch.GetFormOptions())
      .dxForm("instance");

    thisSearch.SearchForm.element().appendTo($(`#${thisSearch.SearchContainer}`));
  }

  GetFormOptions() {
    let thisSearch = this;

    let items = [
      {
        editorType: "dxSelectBox",
        name: "SearchText",
        label: {
          text: "Search For",
          visible: true
        },
        colSpan: GetDevice().isDevice ? 12 : 4,
        // visibleIndex: 0,
        editorOptions: thisSearch.SearchBoxElement()
      } /*
		{
		    itemType: "empty",
		    colSpan: 1,

		},*/,
      {
        editorType: "dxLookup",
        name: "SearchType",
        label: {
          text: "Type",
          visible: true
        },
        colSpan: GetDevice().isDevice ? 12 : 4,
        //   visibleIndex: 1,
        editorOptions: thisSearch.SearchTypeElement()
      } /*
		{
		    itemType: "empty",
		    colSpan: 1,

		},*/,
      {
        editorType: "dxLookup",
        name: "SearchField",
        label: {
          text: "In",
          visible: true
        },
        colSpan: GetDevice().isDevice ? 10 : 3,
        //visibleIndex: 2,
        editorOptions: thisSearch.SearchFieldElement()
      } /*
		{
		    itemType: "empty",
		    colSpan: 1,

		},*/,
      {
        editorType: "dxButton",
        label: {
          text: "",
          visible: false
        },
        colSpan: GetDevice().isDevice ? 2 : 1,
        visible: true,
        //visibleIndex: 3,
        editorOptions: thisSearch.SearchButtonElement()
      } /*
		{
		    itemType: "empty",
		    colSpan: 6,

		},*/
    ];

    let opts: DevExpress.ui.dxFormOptions = {
      colCountByScreen: {
        lg: 12,
        md: 12,
        sm: 12,
        xs: 12
      },
      items: items
    };

    return opts;
  }

  SearchGridToolbar() {
    let thisSearch = this;

    // Add padding here.
    thisSearch.ToolbarContainer = $("<div />")
      .addClass("")
      .attr("id", thisSearch.SearchContainer + "_searchgridtoolbar");

    thisSearch.GridContainerID = thisSearch.SearchContainer + "_searchgrid";
    thisSearch.GridContainer = $("<div />")
      .attr("id", thisSearch.GridContainerID)
      .css("margin-top", 10)
      .height(GetDevice().isDevice ? '70%' : '100%');
    $("#" + thisSearch.SearchContainer).append(
      $("<div />")
        .addClass("row")
        .height('100%')
        .append(
          $("<div />")
            .addClass("col-sm-12")
            .append(thisSearch.ToolbarContainer)
        )
        .append(
          $("<div />")
            .addClass("col-sm-12")
            .height(`calc(100% - ${
              thisSearch.SearchForm.element().height() +
              (GetDevice().isDevice ? 0 : 40)
              }px)`)
            .append(thisSearch.GridContainer)
        )
    );

    //  $("#" + thisSearch.SearchContainer).append($("<div />").dxButton(thisSearch.SearchButtonElement()));

    thisSearch.GridToolbar = new GridToolbar(thisSearch.GridToolbarModel);

    thisSearch.GridToolbar.ToolbarContainerID =
      thisSearch.SearchContainer + "_searchgridtoolbar";
    if (!thisSearch.GridToolbar.CurrentView)
      thisSearch.GridToolbar.CurrentView = thisSearch.GridToolbar.DefaultView;
    thisSearch.GridToolbar.Render();

    // Overrides onViewChanged event to hide the grid.
    thisSearch.GridToolbar.OnViewChanged = e => {
      thisSearch.GridToolbar.CurrentView = e.value;
      if (thisSearch.SearchGrid && thisSearch.SearchGrid.GridObject) {
        thisSearch.GridContainer.hide();
        thisSearch.SearchGrid.GridObject.option("dataSource", "");
      }
    };
    // Overrides the Refresh button to make it work despite overriding onViewChanged.
    thisSearch.GridToolbar.RefreshCurrentGrid = () => {
      thisSearch.GridToolbar.DefaultViewSelectorValueChangedEvent({
        value: thisSearch.GridToolbar.CurrentView,
        component: thisSearch.GridToolbar.ToolbarObject
      });
    };
  }

  SearchTypeElement(): DevExpress.ui.dxLookupOptions {
    let thisSearch = this;
    let options: DevExpress.ui.dxLookupOptions = {
      dataSource: thisSearch.SearchTypes,
      closeOnOutsideClick: true,
      displayExpr: "Name",
      valueExpr: "Type",
      disabled: thisSearch.DisableType,
      width: "auto", //GetDevice().isDevice ? $("#tdfbody").innerWidth() * .3 : $("#tdfbody").innerWidth() * .2,
      value: thisSearch.SelectedType || -1,
      onValueChanged: function (e) {
        thisSearch.UpdateFields(e);
      }
    };

    return options;
  }

  SearchFieldElement(): DevExpress.ui.dxLookupOptions {
    let thisSearch = this;

    let options: DevExpress.ui.dxLookupOptions = {
      items: thisSearch.SearchFields,
      value: thisSearch.SelectedField || "",
      closeOnOutsideClick: true,
      width: "90%", //GetDevice().isDevice ? $("#tdfbody").innerWidth() * .3 : $("#tdfbody").innerWidth() * .2,
      displayExpr: "DisplayName",
      valueExpr: "DetailKey",

      itemTemplate(data) {
        let el = data.Highlight ? `strong class="text-warning"` : `span`;
        return `<${el}>${data.DisplayName}</${el}>`;
      }
    };

    return options;
  }
  RecentSearchData() {
    let thisSearch = this;
    let cso: DevExpress.data.CustomStoreOptions = {
      load(opts) {
        let d = $.Deferred();

        if (opts.searchValue) {
          return d.promise(
            d.resolve(
              thisSearch.RecentSearches.filter(k => {
                return (
                  k[opts.searchExpr as any].indexOf(
                    opts.searchValue.toLowerCase()
                  ) >= 0
                );
              })
            )
          );
        } else {
          return d.promise(d.resolve(thisSearch.RecentSearches));
        }
      },
      byKey(key) {
        let d = $.Deferred();
        if (key) {
          return d.promise(
            d.resolve(
              thisSearch.RecentSearches.filter(x => {
                x.SearchText === key;
              })
            )
          );
        } else {
          return d.promise(d.resolve(thisSearch.RecentSearches));
        }
      },
      key: "SearchText",
      insert(values) {
        let d = $.Deferred();
        thisSearch.RecentSearches.push(values);
        return d.promise(d.resolve());
      }
    };
    let dso: DevExpress.data.DataSourceOptions = {
      store: new dxCustomStore(cso),
      paginate: false
    };
    return new dxDataSource(dso);
  }

  SearchBoxElement(): DevExpress.ui.dxSelectBoxOptions {
    let thisSearch = this;
    let options: DevExpress.ui.dxSelectBoxOptions = {
      dataSource: thisSearch.RecentSearchData(),
      width: "auto", //GetDevice().isDevice ? $("#tdfbody").innerWidth() * .5 : $("#tdfbody").innerWidth() * .2,
      mode: "search",
      showDataBeforeSearch: true,
      minSearchLength: 2,
      searchTimeout: 500,
      searchEnabled: true,
      acceptCustomValue: true,
      displayExpr: "SearchText",
      valueExpr: "SearchText",
      value: thisSearch.SearchText || "",
      onEnterKey: function (e) {
        thisSearch.Search(e);
        thisSearch.GridContainer.show();
      },
      onCustomItemCreating(e) {
        let type: DevExpress.ui.dxLookup = <DevExpress.ui.dxLookup>thisSearch.SearchForm.getEditor(
          "SearchType"
        );
        var newValue = {
          SearchText: e.text,
          ItemType: type.option("value"),
          SearchDate: ""
        },
          items = e.component.option("value"),
          ds = e.component.option("dataSource");
        (ds.store() as DevExpress.data.CustomStore)
          .insert(newValue)
          .done(() => {
            if (
              items &&
              items.length &&
              $.isArray(items) &&
              items.indexOf(newValue) < 0
            ) {
              items.push(newValue);
            } else {
              items = [newValue];
            }
          });
        e.customItem = newValue;
      }
      //onValueChanged: function (e) {
      //    if (e.value) {
      //        //thisSearch.Search(e);
      //    }
      //}
      // dataSource: thisSearch.SearchHistory.byKey(thisSearch.SelectedType||-1)
    };
    return options;
  }

  SearchButtonElement(): DevExpress.ui.dxButtonOptions {
    let thisSearch = this;
    // TODO: Make this use the dxAutoComplete and the dxLocalStore.
    let options: DevExpress.ui.dxButtonOptions = {
      elementAttr: { id: "theSearchButton" },
      onClick: function (e) {
        thisSearch.Search(e);
        thisSearch.GridContainer.show();
      },
      icon: "search"
    };

    return options;
  }

  Search(e?) {
    let thisSearch = this;
    thisSearch.GetRequest();

    if (
      !thisSearch.RequestModel.RequestArgs.GUID ||
      thisSearch.RequestModel.RequestArgs.GUID === "_"
    ) {
      new Notification({
        message: "Please select a valid grid view!",
        displayTime: 3000,
        type: "warning"
      });
      return;
    }
    if (thisSearch.RequestModel.RequestArgs.SearchString) {
      if (thisSearch.RequestModel.RequestArgs.SearchString.length < 2) {
        new Notification({
          message: "Please enter two or more characters!",
          displayTime: 3000,
          type: "warning"
        });
        return;
      }
    } else {
      new Notification({
        message: "Please enter two or more characters!",
        displayTime: 3000,
        type: "warning"
      });
      return;
    }

    let request = new TDFRequest({
      url: thisSearch.RequestModel.Path,
      type: "GET",
      data: thisSearch.RequestModel.RequestArgs
    });
    request.MakeRequest().done(function (response) {
      thisSearch.LastSearchResult = response;
      thisSearch.Render();
      new TDFRequest({
        url: "/core/search/SetLastSearch",
        data: {
          ItemType: thisSearch.RequestModel.RequestArgs.ItemType,
          Text: thisSearch.RequestModel.RequestArgs.SearchString,
          Field: thisSearch.RequestModel.RequestArgs.SearchField
        },
        type: "POST"
      }).MakeRequest();
    });
  }

  Render() {
    let thisSearch = this;
    //if (GetDevice().isDevice && !override) {
    //    thisSearch.SearchList();
    //}
    //else {
    thisSearch.RenderGrid();
    //}
  }

  GetRequest() {
    let thisSearch = this;
    let type: DevExpress.ui.dxLookup = <DevExpress.ui.dxLookup>thisSearch.SearchForm.getEditor(
      "SearchType"
    );
    let fields: DevExpress.ui.dxLookup = <DevExpress.ui.dxLookup>thisSearch.SearchForm.getEditor(
      "SearchField"
    );
    let text: DevExpress.ui.dxSelectBox = <DevExpress.ui.dxSelectBox>thisSearch.SearchForm.getEditor(
      "SearchText"
    );
    if (!thisSearch.GridToolbar.ViewSelector) {
      thisSearch.GridToolbar.ViewSelector = $(
        "#" + thisSearch.GridToolbar.ViewSelectorID
      ).dxLookup("instance");
    }
    let requestArgs = new GridSetup.SearchRequestArgs();
    requestArgs.GUID = thisSearch.GridToolbar.ViewSelector.option("value");
    requestArgs.pageSize = parseInt(
      Preferences.GetPreference("ServerPageSize", "TDFMobile")
    );
    requestArgs.SearchField = fields.option("value");
    requestArgs.SearchString =
      text.option("value").SearchText || text.option("text");
    requestArgs.ItemType = type.option("value");

    thisSearch.RequestModel = new GridSetup.SearchRequestModel(requestArgs);
    thisSearch.UpdateSearchHistory();
    thisSearch.GridToolbar.GridDataRequestModel = thisSearch.RequestModel;
  }

  GetLastSearch() {
    let thisSearch = this;
    let ds: DevExpress.data.DataSource;
    thisSearch.SelectedType = thisSearch.LastSearch;
    //thisSearch.SearchHistory.load().done(function (data: Array<ISearchHistory>) {
    //    let config: DevExpress.data.DataSourceOptions = {
    //        store: data,
    //        sort: [{ field: "TimeStamp", desc: true }]
    //    }
    //    ds = new dxDataSource(config);

    //    ds.load().done(function (searchhist: Array<ISearchHistory>) {

    //        if (searchhist && searchhist.length) {

    //            let type = (<DevExpress.ui.dxLookup>thisSearch.SearchForm.getEditor("SearchType"));
    //            type.option("value",itemTypes[searchhist[0].ItemType]);

    //            let fieldssorted = searchhist[0].History.sort(function (a, b) {
    //                return b.TimeStamp - a.TimeStamp;
    //            });
    //            let field = (<DevExpress.ui.dxLookup>thisSearch.SearchForm.getEditor("SearchField"));
    //            field.option("value", fieldssorted[0].Field);

    //            //  let text = (<DevExpress.ui.dxLookup>thisSearch.SearchForm.getEditor("SearchText"));
    //            //  text.option("value", d[0].Value)
    //        } else {
    //            let type = (<DevExpress.ui.dxLookup>thisSearch.SearchForm.getEditor("SearchType"));

    //            if (!thisSearch.SelectedType) {
    //                type.option("value", 1);
    //                thisSearch.SelectedType = 1;
    //            } else {
    //                type.option("value", thisSearch.SelectedType);
    //            }

    //            let field = (<DevExpress.ui.dxLookup>thisSearch.SearchForm.getEditor("SearchField"));
    //            let items = field.option("items");
    //            /*TODO: Also need to handle Contacts here and set the field to FullName*/
    //            let idx = $.inArray("Company", items);
    //            if (idx >= 0 ) {
    //                field.option("value", items[idx])
    //            } else {
    //                idx = $.inArray("CompanyName", items);
    //                if (idx >= 0) {
    //                    field.option("value", items[idx]);
    //                } else {
    //                    idx = $.inArray("AccountName", items);
    //                    if (idx >= 0) {
    //                        field.option("value", items[idx])
    //                    } else {
    //                        field.option("value", items[0])
    //                    }
    //                }
    //            }

    //        }
    //    }).fail(function (d) {
    //        try {
    //            let type = (<DevExpress.ui.dxLookup>thisSearch.SearchForm.getEditor("SearchType"));
    //            type.option("value", 1);
    //            let field = (<DevExpress.ui.dxLookup>thisSearch.SearchForm.getEditor("SearchField"));
    //            let items = field.option("items");
    //            let idx = $.inArray("Company", items);
    //            if (idx >= 0) {
    //                field.option("value", items[idx])
    //            } else {
    //                idx = $.inArray("CompanyName", items);
    //                if (idx >= 0) {
    //                    field.option("value", items[idx]);
    //                } else {
    //                    idx = $.inArray("AccountName", items);
    //                    if (idx >= 0) {
    //                        field.option("value", items[idx])
    //                    } else {
    //                        field.option("value", items[0])
    //                    }
    //                }
    //            }
    //        } catch (ex) {
    //            console.warn(ex);
    //        }
    //    });
    //}).fail(function (d) {
    //    try {
    //    let type = (<DevExpress.ui.dxLookup>thisSearch.SearchForm.getEditor("SearchType"));
    //    type.option("value", 1);
    //    let field = (<DevExpress.ui.dxLookup>thisSearch.SearchForm.getEditor("SearchField"));
    //    let items = field.option("items");
    //    let idx = $.inArray("Company", items);
    //    if (idx >= 0) {
    //        field.option("value", items[idx])
    //    } else {
    //        idx = $.inArray("CompanyName", items);
    //        if (idx >= 0) {
    //            field.option("value", items[idx]);
    //        } else {
    //            idx = $.inArray("AccountName", items);
    //            if (idx >= 0) {
    //                field.option("value", items[idx])
    //            } else {
    //                field.option("value", items[0])
    //            }
    //        }
    //    }
    //    }
    //    catch (ex) {
    //        console.warn(ex);
    //    }
    //});
  }

  UpdateSearchHistory() {
    let thisSearch = this;

    let type: DevExpress.ui.dxLookup = <DevExpress.ui.dxLookup>thisSearch.SearchForm.getEditor(
      "SearchType"
    );
    let fields: DevExpress.ui.dxLookup = <DevExpress.ui.dxLookup>thisSearch.SearchForm.getEditor(
      "SearchField"
    );
    let text: DevExpress.ui.dxSelectBox = <DevExpress.ui.dxSelectBox>thisSearch.SearchForm.getEditor(
      "SearchText"
    );
    let theText = text.option("value") || text.option("text");

    if (!theText) return;
    let itemtype: string = itemTypes[parseInt(type.option("value"))].toString();

    //thisSearch.SearchHistory.byKey(itemtype).done(function (data: ISearchHistory) {
    //    let histlistitem: IHistory;
    //    let ts = Date.now();
    //    let titem: ITexts = { Text: theText.toString(), TimeStamp: ts };
    //    histlistitem = {
    //        Field: fields.option("value").toString(),
    //        Texts: [titem],
    //        TimeStamp: ts
    //    };

    //    if (!data) {
    //        let newone: ISearchHistory = {
    //            History: [histlistitem],
    //            ItemType: itemtype,
    //            TimeStamp: ts
    //        };
    //        thisSearch.SearchHistory.insert(newone);
    //    } else {
    //        // see if this field is in the history
    //        let field = $.grep(data.History, function (v, k) {
    //            return v.Field === fields.option("value").toString();
    //        });
    //        if (field.length) {

    //            if (field[0].Texts.length) {
    //                // see if this text has been search for for this particular field
    //                let txts = $.grep(field[0].Texts, function (v, k) {
    //                    let theText = text.option("value") || text.option("text");
    //                    return v.Text === theText.toString();
    //                });
    //                if (txts.length) {
    //                    // set this as the most recent search for this type and field
    //                    txts[0].TimeStamp = ts;
    //                } else {
    //                    // this text has not been seached for this field add it to the fields
    //                    field[0].Texts.push(titem);
    //                }
    //            }
    //            thisSearch.SearchHistory.update(itemtype, data)
    //        } else {
    //            //this field has not been searched on add it to the history for this item
    //            data.History.push(histlistitem);

    //        }
    //    }
    //}).fail(function (error) {
    //    let histlistitem: IHistory;
    //    let ts = Date.now();
    //    let titem: ITexts = { Text: text.option("value").toString(), TimeStamp: ts };
    //    histlistitem = {
    //        Field: fields.option("value").toString(),
    //        Texts: [titem],
    //        TimeStamp: ts
    //    };

    //    let newone: ISearchHistory = {
    //        History: [histlistitem],
    //        ItemType: itemtype,
    //        TimeStamp: ts
    //    };
    //    thisSearch.SearchHistory.insert(newone);

    //});
  }

  RefreshCurrentGrid() {
    let thisSearch = this;

    thisSearch.GridToolbar.RefreshCurrentGrid(null);
  }
  get _RequestModel() {
    let thisSearch = this;
    return thisSearch.RequestModel;
  }

  GridOptions() {
    let thisSearch = this;

    let overriddenGridOptions: DevExpress.ui.dxDataGridOptions = {
      columnAutoWidth: true,
      height: '100%',//$(window).innerHeight() - 270,
      showColumnLines: true,
      showRowLines: true,
      selection: {
        mode: thisSearch.MultiSelectAllowed ? "multiple" : "single",
        showCheckBoxesMode: "always"
      },
      onRowClick: function (e) {
        //Override default clicking checkbox
        if (e.rowType !== "data") return;
        let event = e.event as JQueryEventObject;
        if (
          (e.component as DevExpress.ui.dxDataGrid).option("selection.mode") ===
          "multiple"
        ) {
          if (
            !e.isSelected &&
            e.event.target.id.indexOf("drilldownActionButton") === -1
          )
            (e.component as DevExpress.ui.dxDataGrid).selectRows(
              e.key,
              event.ctrlKey
            );
          else (e.component as DevExpress.ui.dxDataGrid).deselectRows(e.key);
        }
      }
    };
    overriddenGridOptions.onContentReady = function (e) {
      let id = DomSafeID(thisSearch.GridContainerID);
      $(window).on("orientationchange", function () {
        if (devices.orientation() === "landscape") {
          $("#" + id).css("width", "100%");
          $("#recentitemcontainer").css("width", "100%");
        }
        // put this outside if, because some reason orientaion change on quote manager doesnt read landscape?
        $("#quotemanagergrid").css("width", "100%");
      });
      if (thisSearch.RequestModel) {
        let btnNextPage = e.element.find(".dx-next-button");
        let btnPrevPage = e.element.find(".dx-prev-button");
        let pageSizes = e.element.find(".dx-page-sizes");
        let grid = e.component as DevExpress.ui.dxDataGrid;
        $(btnNextPage).off("click");
        $(btnNextPage).on("click", function (event) {
          if ($(event.currentTarget).hasClass("dx-button-disable")) return;
          if (grid.pageIndex() === grid.pageCount() - 1) {
            event.stopPropagation();
            thisSearch.RequestModel.RequestArgs.page += 1;
            grid.pageIndex(0);
            thisSearch.RefreshCurrentGrid();
          }
        });
        $(btnPrevPage).off("click");
        $(btnPrevPage).on("click", function (event) {
          if ($(event.currentTarget).hasClass("dx-button-disable")) return;
          if (grid.pageIndex() === 0) {
            if (thisSearch.RequestModel.RequestArgs.page > 1) {
              thisSearch.RequestModel.RequestArgs.page -= 1;
              let newIndex =
                Math.ceil(
                  thisSearch.RequestModel.RequestArgs.pageSize / grid.pageSize()
                ) - 1;
              grid.pageIndex(newIndex);
              thisSearch.RefreshCurrentGrid();
            }
          }
        });
        $(pageSizes).on("dxclick", function (e) {
          Preferences.SetPreference(
            "ClientGridPageSize",
            $(e.target).text(),
            "TDFMobile"
          );
        });
        if ($(btnNextPage).hasClass("dx-button-disable")) {
          if (
            thisSearch.RequestModel.RequestArgs.page <
            thisSearch.SearchGrid.GridDataResponse.Meta.TotalPages
          ) {
            btnNextPage.removeClass("dx-button-disable");
          }
        }
        if ($(btnPrevPage).hasClass("dx-button-disable")) {
          if (thisSearch.RequestModel.RequestArgs.page > 1) {
            btnPrevPage.removeClass("dx-button-disable");
          }
        }
        $(e.element)
          .find(".dx-header-row .dx-datagrid-action")
          .off("dxclick")
          .on("dxclick", function (event) {
            if (Preferences.GetPreference("SortOnServer", "WebGrid")) {
              event.stopPropagation();
              let field;
              let cname = $(this)
                .find(".dx-datagrid-text-content")
                .text();

              let sorts = thisSearch.RequestModel.RequestArgs.Sorts;

              $.each(grid.option("columns"), function (k, v) {
                if (v.caption) {
                  if (cname === v.caption) {
                    field = v.dataField.trim();
                  }
                } else {
                  if (cname === v.dataField) {
                    field = v.dataField.trim();
                  }
                }
              });

              if (sorts && sorts.length) {
                let found = false;

                sorts.forEach((val, idx) => {
                  if (val.Field === field) {
                    found = true;
                    switch (val.Direction) {
                      case "asc":
                        val.Direction = "desc";
                        break;
                      case "desc":
                        val.Direction = "asc";
                        break;
                    }
                  }
                });

                if (!found) {
                  sorts = [{ Field: field, Direction: "asc", Order: 0 }];
                }
              } else {
                sorts = [{ Field: field, Direction: "asc", Order: 0 }];
              }

              thisSearch.RequestModel.RequestArgs.Sorts = sorts;
              thisSearch.RefreshCurrentGrid();
            }
          });
      }
    };
    return overriddenGridOptions;
  }
  RenderGrid() {
    let thisSearch = this;
    //  if (!GetDevice().isDevice) {
    thisSearch.SearchGrid = new Grid(
      thisSearch.LastSearchResult,
      {
        GridContainer: thisSearch.GridContainer,
        GridToolbar: thisSearch.GridToolbar
      },
      thisSearch.GridOptions(),
      thisSearch,
      e => {
        thisSearch.GridToolbar.SelectionChanged(e);
      }
    );

    thisSearch.SearchGrid.Render();

    thisSearch.GridToolbar.CurrentGrid = <Grid>thisSearch.SearchGrid;
    if (thisSearch.SelectionEvent)
      thisSearch.SearchGrid.GridObject.option(
        "onSelectionChanged",
        thisSearch.SelectionEvent
      );
    //   thisSearch.SearchGrid.GridToolbar = thisSearch.GridToolbar;
    //  } else {
    //    let opts: DevExpress.ui.dxAccordionOptions = {
    // //       dataSource: data.Data,
    //       itemTitleTemplate: function () { }

    ///    }
    //     $("#" + data.GridContainer).dxAccordion();

    //  }
  }

  //SearchList() {
  //    let thisSearch = this;
  //    // TODO:Need to get these values to display with the correct formatting and better style.

  //    let iList = [];
  //    let visiList = [];
  //    $.each(thisSearch.LastSearchResult.Data, function (key, dataRow) {
  //        let arr = [];

  //        let visibleCols = $.grep(thisSearch.LastSearchResult.GridView.Layout.Columns, (gridcol: any, k) => {
  //            return gridcol.visible;
  //        });

  //        $.each(visibleCols, (k, col) => {
  //            visiList.push(col.dataField);
  //        });

  //        arr.push(dataRow);

  //        iList.push({
  //            key: dataRow["TDF_Subject"],
  //            items: arr,
  //            type: thisSearch.GridToolbar.GridItemType //,
  //            //right: moment(new Date(val.RecentItemDate).toISOString().replace("Z", "")).format("LL")
  //        })
  //    });

  //    //TODO: Implement paging buttons
  //    thisSearch.GridContainer.dxList({
  //        dataSource:  {
  //            //pageSize: recent.ListPageSize,
  //            paginate: false,
  //            store: iList
  //        },
  //        height: GetDevice().isDevice ? $(window).innerHeight() - $("#tdfnavbar").outerHeight() : "auto",
  //        grouped: true,
  //        collapsibleGroups: true,
  //        pageLoadMode: "nextButton",
  //        showSelectionControls: true,
  //        selectionMode: thisSearch.MultiSelectAllowed ? "multiple" : "single",
  //        groupTemplate: function (data, index, element) {
  //            let classname = data.key ? "" : "text-danger";
  //            let style = data.key ? "" : "text-decoration: underline;";
  //            let result = $("<div />").addClass('recent-favorite-title').append($("<span />").addClass("tdfitem" + parseInt(data.type)))
  //                .append($("<div>").text(data.key || "No Value"));
  //            element.parent().addClass("dx-list-group-collapsed");
  //            return result;
  //        },
  //        onItemClick(e) {
  //            let t = thisSearch;
  //            if (thisSearch.SelectionEvent) {
  //                thisSearch.SelectionEvent({currentSelectedRowKeys : [e.itemData]});
  //            }
  //            else {
  //            t.SearchListItemClick(e);
  //            }

  //        },
  //        itemTemplate: function (data, index, element) {
  //            element.parent().parent().css("height", 0);
  //            let classname = data.Description ? "" : "text-danger";
  //            let style = data.Description ? "" : "text-decoration: underline;";
  //            //let item = new TDF[name]({ ItemID: data["TDF GUID"], ItemType: parseInt(data.ItemType), DisplayName: nameinfo.DisplayName });
  //            let details = $('<div />').addClass('recent-fav-details');

  //            let t = ["ItemType", "TDF GUID", "TDF_AttachmentCount", "Favorite"];

  //            $.each(data, function (key, val) {
  //                if (key == "Favorite" || key == "TDF GUID" || key == "ParentName" || key == "ItemType" || key == "TDF_AttachmentCount" || $.inArray(key, visiList) === -1) {
  //                    //do nothing
  //                } else {
  //                    let reg = new RegExp("\s", "g");
  //                    if ($.inArray(key.toLowerCase().replace(reg, ""), t) === -1) {

  //                        if (key == "Creation" || key == "ModDate" || key == "RecentItemDate") {
  //                            details.append($("<p />").text(key + " :  " + moment(new Date(val).toISOString().replace("Z", "")).format("LL")))
  //                        } else {
  //                            details.append($("<p />").text(key + " :  " + val))
  //                        }
  //                    }
  //                }

  //            });
  //            let result = GetContainerForRecentOrFavorite(data, details);
  //            return result;
  //        }
  //    })
  //}

  SearchListItemClick(e) {
    let recent = this;

    if ($(e.jQueryEvent.target).hasClass("rec-fav-action-buttons")) return;

    $.when(DisplayInfo(parseInt(e.itemData.ItemType))).done(displayInfo => {
      let url = "";
      switch (parseInt(e.itemData.ItemType)) {
        case itemTypes.itemExpense:
          url =
            window.location.origin + "/expense/?itemid=" + e.itemData["TDF GUID"];
          break;
        case itemTypes.itemQuote:
          url =
            window.location.origin +
            "/quote/?itemid=" +
            e.itemData["TDF GUID"];
          break;
      }
      if (url) {
        if (!GetDevice().isDevice) {
          window.open(url, "_blank");
        } else {
          window.location.href = url;
        }
      } else {
        let name: string = displayInfo.TypeName;
        import(`../components/items/${name}`).then(theitem => {
          let item = new theitem[name]({
            ItemID: e.itemData["TDF GUID"],
            ItemType: e.itemData.ItemType,
            DisplayName: e.itemData.DisplayName,
            TypeName: e.itemData.TypeName
          });
          item.Initialize();
        });
      }
    });
  }

  UpdateFields(e) {
    let thisSearch = this;
    let request = new TDFRequest({
      url: "/core/search/search-fields/",
      type: "GET",
      data: { itemtype: e.value }
    });
    request.MakeRequest().done(function (response: any) {
      let fields: DevExpress.ui.dxLookup = <DevExpress.ui.dxLookup>thisSearch.SearchForm.getEditor(
        "SearchField"
      );
      if (fields) {
        fields.option("items", response.Fields);
      }

      if (response.DefaultSearchField) {
        thisSearch.DefaultSearchField = response.DefaultSearchField;
        fields.option("value", thisSearch.DefaultSearchField);
      }
      let text: DevExpress.ui.dxTextBox = <DevExpress.ui.dxTextBox>thisSearch.SearchForm.getEditor(
        "SearchText"
      );
      //try {
      //    thisSearch.SearchHistory.byKey(itemTypes[e.value].toString()).done(function (data: ISearchHistory) {

      //        if (data) {
      //            let fieldssorted = data.History.sort(function (a, b) {
      //                return b.TimeStamp - a.TimeStamp;
      //            });
      //            fields.option("value", fieldssorted[0].Field);
      //            let textsSorted = fieldssorted[0].Texts.sort(function (a, b) {
      //                return b.TimeStamp - a.TimeStamp;

      //            });
      //            let texts = [];
      //            $.each(textsSorted, function (k, v) {
      //                texts.push(v.Text)
      //            });
      //            text.option("items", texts);
      //        }
      //    }).fail(function () {
      //        let items = fields.option("items");
      //        let idx = $.inArray("Company", items);
      //        if (idx >= 0) {

      //            fields.option("value", items[idx])
      //        } else {
      //            idx = $.inArray("CompanyName", items);
      //            if (idx >= 0) {
      //                fields.option("value", items[idx]);
      //            } else {
      //                idx = $.inArray("AccountName", items);
      //                if (idx >= 0) {
      //                    fields.option("value", items[idx])
      //                } else {
      //                    if (thisSearch.DefaultSearchField) {
      //                        fields.option("value", thisSearch.DefaultSearchField);
      //                    } else {
      //                        fields.option("value", items[0]);
      //                    }
      //                }
      //            }
      //        }
      //    });
      //}
      //catch (ex) {

      //}

      thisSearch.UpdateToolbar();
    });
  }

  UpdateToolbar() {
    let thisSearch = this;

    if (thisSearch.SearchForm) {
      thisSearch.SelectedType = (<DevExpress.ui.dxLookup>thisSearch.SearchForm.getEditor(
        "SearchType"
      )).option("value");
    }
    if (thisSearch.SelectedType) {
      let request = new TDFRequest({
        url: "/core/user/GetToolbarInfo/",
        type: "GET",
        data: {
          type: thisSearch.SelectedType,
          containertype: 4
        }
      });
      request.MakeRequest().done(function (response) {
        thisSearch.GridToolbar.GridDataRequestModel = null;
        if (thisSearch.GridToolbar.CurrentGrid) {
          thisSearch.GridToolbar.CurrentGrid.GridObject.option(
            "dataSource",
            []
          );
          thisSearch.GridToolbar.CurrentGrid.GridObject.option("columns", []);
        }
        thisSearch.GridToolbarModel = response;
        let changescope: boolean;
        if (thisSearch.GridToolbar.CurrentScope === "Personal") {
          changescope = !thisSearch.GridToolbarModel.UsePersonalViews;
        } else {
          changescope = thisSearch.GridToolbarModel.UsePersonalViews;
        }
        if (
          thisSearch.GridToolbarModel.GridItemType !==
          thisSearch.GridToolbar.GridItemType
        ) {
          $.each(thisSearch.GridToolbarModel, function (key, val) {
            thisSearch.GridToolbar[key] = val;
          });
          thisSearch.GridToolbar.CurrentView =
            thisSearch.GridToolbarModel.DefaultView;
        }
        if (thisSearch.GridToolbar && thisSearch.GridToolbar.ViewSelector) {
          thisSearch.GridToolbar.ViewSelector.reset();
          thisSearch.GridToolbar.ViewSelector.option(
            "dataSource",
            thisSearch.GridToolbar.GetViewsDataSource()
          );
          thisSearch.GridToolbar.ViewSelector.option("dataSource").reload();
        }
        //if (!thisSearch.GridToolbar.CurrentView) thisSearch.GridToolbar.CurrentView = thisSearch.GridToolbar.DefaultView;

        if (changescope) {
          thisSearch.GridToolbar.UpdateScope();
        } else {
          if (thisSearch.GridToolbar && thisSearch.GridToolbar.ViewSelector) {
            thisSearch.GridToolbar.ViewSelector.option(
              "value",
              thisSearch.GridToolbar.CurrentView
            );
          }
        }

        if (thisSearch.SearchText) {
          thisSearch.Search();
        }
      });
    }
  }
}
interface ISearchHistory {
  ItemType: string;
  TimeStamp: number;
  History: Array<IHistory>;
}

interface IHistory {
  Field: string;
  TimeStamp: number;
  Texts: Array<ITexts>;
}
interface ITexts {
  TimeStamp: number;
  Text: string;
}

export class TimeTrackerSearch extends Search {
  SuggestionsCheckBoxID: string = "SearchSuggestionsCheck";
  SearchToolbar: ToolbarWithoutViews;
  FromMySuggestions: boolean;

  Search(e?) {
    let thisSearch = this;
    thisSearch.GetRequest();

    let searchString = thisSearch.RequestModel.RequestArgs.SearchString;
    let searchType = thisSearch.RequestModel.RequestArgs.ItemType;
    let searchSuggestions = thisSearch.RequestModel.RequestArgs.extraFields;

    if (searchString) {
      if (searchString.length < 2) {
        new Notification({
          message: "Please enter two or more characters!",
          displayTime: 3000,
          type: "warning"
        });
        return;
      }
    } else {
      new Notification({
        message: "Please enter two or more characters!",
        displayTime: 3000,
        type: "warning"
      });
      return;
    }

    let request = new TDFRequest({
      url:
        TDFRequest.ApiPath +
        "/time-tracking/search?query=" +
        searchString +
        "&myStuff=" +
        thisSearch.FromMySuggestions +
        "&itemType=" +
        searchType,
      type: "GET"
    });
    request.MakeRequest().done(function (response) {
      response = {
        Data: response.map(a => {
          return {
            ItemType: a.ItemType,
            AccountName: a.AccountName,
            Roles: a.Roles,
            RecentItemDate: a.RecentItemDate,
            TimesAccessed: a.AccessCount,
            Subject: a.Subject,
            ItemID: a.ItemID,
            UniqueID: a.UniqueID
          };
        })
      };
      thisSearch.LastSearchResult = response;
      thisSearch.Render();
    });
  }

  Render() {
    let thisSearch = this;
    //if (GetDevice().isDevice && !override) {
    //    thisSearch.SearchList();
    //}
    //else {
    thisSearch.RenderGrid();
    //}
  }

  GetFormOptions() {
    let thisSearch = this;

    let items = [
      {
        editorType: "dxSelectBox",
        name: "SearchText",
        label: {
          text: "Search For",
          visible: true
        },
        colSpan: GetDevice().isDevice ? 12 : 4,
        // visibleIndex: 0,
        editorOptions: thisSearch.SearchBoxElement()
      },
      {
        editorType: "dxLookup",
        name: "SearchType",
        label: {
          text: "Type",
          visible: true
        },
        colSpan: GetDevice().isDevice ? 12 : 4,
        //   visibleIndex: 1,
        editorOptions: thisSearch.SearchTypeElement()
      },
      {
        editorType: "dxCheckBox",
        name: "SearchSuggestions",
        label: {
          text: "My Suggestions"
        },
        colSpan: 1,
        editorOptions: {
          value: true,
          elementAttr: {
            id: thisSearch.SuggestionsCheckBoxID
          }
        }
      },
      {
        editorType: "dxButton",
        label: {
          text: "",
          visible: false
        },
        colSpan: GetDevice().isDevice ? 2 : 1,
        visible: true,
        //visibleIndex: 3,
        editorOptions: thisSearch.SearchButtonElement()
      }
    ];

    let opts: DevExpress.ui.dxFormOptions = {
      colCountByScreen: {
        lg: 12,
        md: 12,
        sm: 12,
        xs: 12
      },
      items: items
    };

    return opts;
  }

  RenderGrid() {
    let thisSearch = this;

    thisSearch.SearchGrid = new GridWithoutViews(
      GetDataResponseObject(thisSearch.LastSearchResult.Data),
      {
        GridContainer: thisSearch.GridContainer
      },
      {
        dataSource: thisSearch.LastSearchResult.Data,
        height: $(window).innerHeight() - 310,
        selection: {
          mode: thisSearch.MultiSelectAllowed ? "multiple" : "single",
          showCheckBoxesMode: "always"
        },
        onRowClick: function (e) {
          //Override default clicking checkbox
          if (thisSearch.MultiSelectAllowed) {
            if (!e.isSelected)
              (e.component as DevExpress.ui.dxDataGrid).selectRows(
                e.key,
                e.jQueryEvent.ctrlKey
              );
            else (e.component as DevExpress.ui.dxDataGrid).deselectRows(e.key);
          }
        },
        columns: [
          {
            dataField: "AccountName",
            width: "auto"
          },
          {
            dataField: "UniqueID",
            width: "auto"
          },
          {
            dataField: "Roles",
            width: "auto"
          },
          {
            dataField: "TimesAccessed",
            width: "auto"
          },
          {
            dataField: "RecentItemDate",
            width: "auto"
          },
          {
            dataField: "Subject",
            minWidth: 120
          }
        ]
      },
      thisSearch,
      e => {
        thisSearch.SearchToolbar.SelectionChanged(e);
      },
      null,
      "TimeTracker_Search"
    );

    thisSearch.SearchGrid.Render();

    //thisSearch.GridToolbar.CurrentGrid = <GridWithoutViews>thisSearch.SearchGrid;
    if (thisSearch.SelectionEvent)
      thisSearch.SearchGrid.GridObject.option(
        "onSelectionChanged",
        thisSearch.SelectionEvent
      );
  }

  SearchGridToolbar() {
    let thisSearch = this;
    thisSearch.ToolbarContainer = $("<div />")
      .addClass("")
      .attr("id", thisSearch.SearchContainer + "_searchgridtoolbar");
    thisSearch.GridContainer = $("<div />")
      .attr("id", thisSearch.SearchContainer + "_searchgrid")
      .css("margin-top", 10);
    $("#" + thisSearch.SearchContainer).append(
      $("<div />")
        .addClass("row")
        .css("margin", "0 3px 0px 3px")
        .append(
          $("<div />")
            .addClass("col-sm-12")
            .append(thisSearch.ToolbarContainer)
        )
        .append(
          $("<div />")
            .addClass("col-sm-12")
            .append(thisSearch.GridContainer)
        )
    );

    $("#tdfbody").addClass("nopadding");

    //  $("#" + thisSearch.SearchContainer).append($("<div />").dxButton(thisSearch.SearchButtonElement()));

    thisSearch.SearchToolbar = new ToolbarWithoutViews(
      thisSearch.GridToolbarModel
    );
    //if (thisSearch.GridToolbar) {
    //    thisSearch.GridToolbar.ExtraItems = [{
    //        location: "after",
    //        locateInMenu: "always",
    //        widget: "dxButton",
    //        showText: "inMenu",
    //        visible:GetDevice().isDevice,
    //        options: {
    //            icon: "fa fa-table",
    //            text: "Show as grid",
    //            //type: "default",
    //            onClick: function (e) {
    //                thisSearch.Render(true);
    //            }
    //        },
    //    }];
    //}
    thisSearch.SearchToolbar.ToolbarContainerID =
      thisSearch.SearchContainer + "_searchgridtoolbar";

    thisSearch.SearchToolbar.Render();
  }

  GetRequest() {
    let thisSearch = this;
    let text: DevExpress.ui.dxSelectBox = <DevExpress.ui.dxSelectBox>thisSearch.SearchForm.getEditor(
      "SearchText"
    );
    let type: DevExpress.ui.dxLookup = <DevExpress.ui.dxLookup>thisSearch.SearchForm.getEditor(
      "SearchType"
    );
    let suggestions: DevExpress.ui.dxCheckBox = <DevExpress.ui.dxCheckBox>thisSearch.SearchForm.getEditor(
      "SearchSuggestions"
    );
    let requestArgs = new GridSetup.SearchRequestArgs();
    requestArgs.pageSize = parseInt(
      Preferences.GetPreference("ServerPageSize", "TDFMobile")
    );
    requestArgs.SearchString = text.option("value") || text.option("text");
    requestArgs.ItemType = type.option("value");

    thisSearch.FromMySuggestions = suggestions.option("value");

    thisSearch.RequestModel = new GridSetup.SearchRequestModel(requestArgs);
    //thisSearch.UpdateSearchHistory();
    //thisSearch.GridToolbar.GridDataRequestModel = thisSearch.RequestModel;
  }

  UpdateFields(e) {
    //Do nothing because this version of search does not have this form item.
  }
}
