import { itemTypes } from "../../enums/enums";
import { DisplayInfo } from "../../infrastructure/context";
import { ItemListingNameModel, IControlData } from "../../interfaces/interfaces";
import { TDFRequest } from "../../services/request";
import { DomSafeID } from "../../util/allutils";
import { ContactBIDataCenter } from '../../datacenters/bidatacenters/bi_data_center_contact';
import { ItemBase } from "./itembase";
import { Notification } from '../dialogs/notification';

export class Contact extends ItemBase {
    DisplayName: string;
    get TypeName() {
        return "Contact";
    }
    get ItemType() {
        return itemTypes.itemContact;
    }
    set TypeName(name) {
        if (name && this.TypeName !== name) this.TypeName = name;
    }
    get RequiresParentItem(): boolean {
        return true;
    }
    NameParts: any;
    readonly SaveNameURL = "/item/SaveFullName/";
    //NEEDTODO - Need to add this to the API
    readonly CheckBSContactID_URL = "/BI/CheckBSIDContact/";

    constructor(args) {
        super(args);
        let theContact = this;
        $.each(args, function (key, val) {
            if (theContact.hasOwnProperty(key)) theContact[key] = val;
        });

        if (!theContact.DisplayName) {
            DisplayInfo(theContact.ItemType).done(function (displayinfo) {
                theContact.DisplayName = displayinfo.DisplayName;
                theContact.TypeName = displayinfo.TypeName;
            });
        }
    }
    OnCreate() {
        let theContact = this;
        let d = $.Deferred();
        super.OnCreate().done(() => {
            let timer;
            timer = setInterval(function () {
                let nameEle = $("#btn_Name_" + theContact.DomSafeID);
                if (nameEle.length > 0) {
                    clearInterval(timer);
                    let nameCtrl;
                    for (const controlGroup in theContact.Controls) {
                        if (theContact.Controls.hasOwnProperty(controlGroup)) {
                            const currentCG = theContact.Controls[controlGroup];

                            let nameCtrls = (currentCG as any).filter((a: any) => {
                                let NameCtrlType = 25;
                                return (a as IControlData).Properties.Control_Type === NameCtrlType;
                            });

                            if (nameCtrls.length > 0) {
                                nameCtrl = nameCtrls[0];
                            }
                        }
                    }
                    if (!(<any>nameCtrl).DataValue.ValueName) {
                        let b: DevExpress.ui.dxButton = nameEle.dxButton("instance");
                        b.option("onClick")();
                    }
                }
            }, 100);
            d.resolve();
        });

        return d.promise();
    }

    CheckBSContactID(): JQueryPromise<boolean> {

        //NEEDTODO - Need to add this to the API
        let theContact = this;
        let success: boolean = true;
        let dfd: JQueryDeferred<boolean> = $.Deferred();

        if (theContact.DirtyControls && theContact.DirtyControls.length) {
            let bsContactIDControls = theContact.DirtyControls.filter(function (dirtyContrl, index, array) {
                return dirtyContrl.Properties.TDFField.toUpperCase() === "BSCONTACTID" ? dirtyContrl : null;
            });

            if (bsContactIDControls.length > 0) {

                // Assume only one Contact ID control.
                let bsContactIDCtrl = bsContactIDControls[0];

                let bsCompanyID = "";
                let anyIMain: any = theContact.iMain;
                if (anyIMain.ItemBSICompanyID) {
                    bsCompanyID = anyIMain.ItemBSICompanyID;
                }

                // check if the BSIContact ID is used anywhere else, before actually saving.
                let request = new TDFRequest({
                    url: theContact.CheckBSContactID_URL,
                    data: { bsContactID: bsContactIDCtrl.SaveValue, bsCompanyID: bsCompanyID, contactid: theContact.ItemID },
                    type: "POST"
                });
                request.MakeRequest().done(function (response) {
                    success = true;
                    return dfd.resolve(success);
                }).catch((err) => {

                    success = false;

                    let msg = "The BSContactID you selected is invalid.";

                    try {
                        msg = err.Message;
                    } catch (e) {

                    }

                    new Notification({
                        message: msg,
                        type: "error",
                        displayTime: 5000,
                        shading: true
                    });

                })
            }
            else {
                return dfd.promise(dfd.resolve(success));
            }
        }
        else {
            return dfd.promise(dfd.resolve(success));
        }

        return dfd.promise();
    }

    Save() {
        let theContact = this;
        let dfd = $.Deferred();
        let theSaveMethod = super.Save.bind(theContact);   // I could not call super.Save directly in the deferred function.  


        theContact.CheckBSContactID().done(function (proceedWithSave) {
            if (proceedWithSave) {
                theSaveMethod().done(function (reponse) {
                    if (theContact.NameParts) {
                        let request = new TDFRequest({ url: theContact.SaveNameURL, data: { nameparts: theContact.NameParts, itemid: theContact.ItemID, itemtype: 5 }, type: "POST" });
                        request.MakeRequest().done(function (response) {
                            dfd.resolve();
                        });
                    } else {
                        dfd.resolve();
                    }
                });
            }
        });
        return dfd.promise();
    }

    SaveClose() {
        let theContact = this;
        let theSaveCloseMethod = super.SaveClose.bind(theContact);   // I could not call super.Save directly in the deferred function.  

        theContact.CheckBSContactID().done(function (proceedWithSave) {
            if (proceedWithSave) {
                theSaveCloseMethod();
            }
        });

        return theContact;
    }

    RenderCustomTab(itemData, container, dfd) {
        var theContact = this;
        super.RenderCustomTab(itemData, container, dfd);

        if (itemData.ControlGroupID === "BIContactSummary") {
            theContact.RenderCustomTab_ContactData(itemData, container);
        }
        if (itemData.ControlGroupID === "MarketingIntegrationTab") {
            theContact.RenderCustomTab_MarketingIntegration(itemData, container);
        }
        dfd.resolve();
    }

    RenderCustomTab_ContactData(itemData, container) {
        let theContact = this;
        let customName = DomSafeID(theContact.ItemID);
        let biCenterName = "ContactBi_" + customName;

        // Frame for the Center
        let html = $(`<div id="${biCenterName}">`).height(() => {
            return $(window).innerHeight() - $("#tdffooter").innerHeight() - 175;
        });
        container.append(html);

        let itemListNameModel: ItemListingNameModel = { ItemListWithNames: [] };
        itemListNameModel.ItemListWithNames.push({
            ID: theContact.ItemID,
            Name: theContact.iMain.ItemSubject
        });

        let biCenter = new ContactBIDataCenter(
            itemListNameModel,
            { MainAreaContainer: html },
            customName
        );
        biCenter.InitAndRender();
    }
}