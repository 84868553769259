import { TDFRequest } from "services/request";
import { Dialog } from "components/dialogs/dialog";
import { itemTypes, CenterType, GridContainerTypes } from "enums/enums";
import { EventSummaryDrillDown, OppType } from "./eventsummarydrilldown";
import cssnano = require("cssnano");
import { GetItemTypeDisplayInfo, DisplayInfo } from "infrastructure/context";

export class EventSummary {

    private _Data: EventSummaryData;
    get Data(): JQueryPromise<EventSummaryData> {
        let evSum = this;
        let d: JQueryDeferred<EventSummaryData> = $.Deferred();

        if (evSum._Data) {
            return d.promise(d.resolve(evSum._Data));
        }

        new TDFRequest({
            url: `Item/Event/${evSum.EventID}/EventSummary?eventName=${evSum.EventSubject}`,
            type: 'GET'
        }).MakeRequest().done(response => {
            evSum._Data = response;
            d.resolve(response);
        });

        return d.promise();
    }

    private _FormInstance: DevExpress.ui.dxForm;
    get FormInstance() {
        let evSum = this;
        let d: JQueryDeferred<DevExpress.ui.dxForm> = $.Deferred();

        if (evSum._FormInstance) {
            return d.promise(d.resolve(evSum._FormInstance));
        }

        evSum.Data.done(data => {
            data.NetROI = data.TotalIncome - data.TotalExpenses;

            $.when(
                DisplayInfo(itemTypes.itemAccount),
                DisplayInfo(itemTypes.itemContact),
                DisplayInfo(itemTypes.itemLead),
                DisplayInfo(itemTypes.itemOpportunity)
            ).done((
                accountDisplay, contactDisplay, leadDisplay, oppDisplay
            ) => {

                evSum._FormInstance = $('<div />').dxForm({
                    formData: data,
                    colCount: 2,
                    height: '100%',
                    items: [
                        {
                            itemType: 'group',
                            caption: 'Event Income Summary',
                            colCount: 2,
                            items: [
                                {
                                    dataField: 'TotalIncome',
                                    editorOptions: <DevExpress.ui.dxTextBoxOptions>{
                                        readOnly: true
                                    }
                                }, {
                                    dataField: 'TotalExpenses',
                                    editorOptions: <DevExpress.ui.dxTextBoxOptions>{
                                        readOnly: true
                                    }
                                }, {
                                    dataField: 'ClosedOpps',
                                    label: { text: `Closed ${oppDisplay.DisplayNamePlural}` },
                                    template: (data, element) => {
                                        let closedOpps = data.component.option('formData').ClosedOpps;

                                        return $('<div />')
                                            .text(`${closedOpps[0]} - $${closedOpps[1]}`)
                                            .addClass('badge')
                                            .css({
                                                'text-decoration': 'underline',
                                                'cursor': 'pointer',
                                                'color': '#337ab7'
                                            })
                                            .on('click', e => {
                                                evSum.GetNewBusinessDrillDown(itemTypes.itemOpportunity, OppType.Closed);
                                            });
                                    },
                                    editorOptions: <DevExpress.ui.dxTextBoxOptions>{
                                        readOnly: true
                                    }
                                }, {
                                    dataField: 'NetROI',
                                    label: { text: 'Net ROI' },
                                    editorOptions: <DevExpress.ui.dxTextBoxOptions>{
                                        readOnly: true
                                    }
                                },
                            ]
                        },
                        {
                            itemType: 'group',
                            caption: 'Event Results',
                            colCount: 2,
                            items: [
                                {
                                    dataField: 'NewBusiness.NewAccounts',
                                    label: { text: `New ${accountDisplay.DisplayNamePlural}` },
                                    template: (data, element) => {
                                        return $('<div />')
                                            .text(data.component.option('formData').NewBusiness.NewAccounts)
                                            .addClass('badge')
                                            .css({
                                                'text-decoration': 'underline',
                                                'cursor': 'pointer',
                                                'color': '#337ab7'
                                            })
                                            .on('click', e => {
                                                evSum.GetNewBusinessDrillDown(itemTypes.itemAccount);
                                            });
                                    },
                                    editorOptions: <DevExpress.ui.dxTextBoxOptions>{
                                        readOnly: true,
                                    }
                                }, {
                                    dataField: 'NewBusiness.NewContacts',
                                    label: { text: `New ${contactDisplay.DisplayNamePlural}` },
                                    template: (data, element) => {
                                        return $('<div />')
                                            .text(data.component.option('formData').NewBusiness.NewContacts)
                                            .addClass('badge')
                                            .css({
                                                'text-decoration': 'underline',
                                                'cursor': 'pointer',
                                                'color': '#337ab7'
                                            })
                                            .on('click', e => {
                                                evSum.GetNewBusinessDrillDown(itemTypes.itemContact);
                                            });
                                    },
                                    editorOptions: <DevExpress.ui.dxTextBoxOptions>{
                                        readOnly: true
                                    }
                                }, {
                                    dataField: 'NewBusiness.NewLeads',
                                    label: { text: `New ${leadDisplay.DisplayNamePlural}` },
                                    template: (data, element) => {
                                        return $('<div />')
                                            .text(data.component.option('formData').NewBusiness.NewLeads)
                                            .addClass('badge')
                                            .css({
                                                'text-decoration': 'underline',
                                                'cursor': 'pointer',
                                                'color': '#337ab7'
                                            })
                                            .on('click', e => {
                                                evSum.GetNewBusinessDrillDown(itemTypes.itemLead);
                                            });
                                    },
                                    editorOptions: <DevExpress.ui.dxTextBoxOptions>{
                                        readOnly: true
                                    }
                                }, {
                                    dataField: 'NewBusiness.NewOpportunities',
                                    label: { text: `New ${oppDisplay.DisplayNamePlural}` },
                                    template: (data, element) => {
                                        return $('<div />')
                                            .text(data.component.option('formData').NewBusiness.NewOpportunities)
                                            .addClass('badge')
                                            .css({
                                                'text-decoration': 'underline',
                                                'cursor': 'pointer',
                                                'color': '#337ab7'
                                            })
                                            .on('click', e => {
                                                evSum.GetNewBusinessDrillDown(itemTypes.itemOpportunity);
                                            });
                                    },
                                    editorOptions: <DevExpress.ui.dxTextBoxOptions>{
                                        readOnly: true
                                    }
                                }, {
                                    dataField: 'OpenOpps',
                                    label: { text: `Open ${oppDisplay.DisplayNamePlural}` },
                                    template: (data, element) => {
                                        let openOpps = data.component.option('formData').OpenOpps;

                                        return $('<div />')
                                            .text(`${openOpps[0]} - $${openOpps[1]}`)
                                            .addClass('badge')
                                            .css({
                                                'text-decoration': 'underline',
                                                'cursor': 'pointer',
                                                'color': '#337ab7'
                                            })
                                            .on('click', e => {
                                                evSum.GetNewBusinessDrillDown(itemTypes.itemOpportunity, OppType.Open);
                                            });
                                    },
                                    editorOptions: <DevExpress.ui.dxTextBoxOptions>{
                                        readOnly: true
                                    }
                                }
                            ]
                        }
                    ]
                }).dxForm('instance');

                d.resolve(evSum._FormInstance);

            });
        });

        return d.promise();
    }

    private _GridInstance: DevExpress.ui.dxDataGrid;
    get GridInstance() {
        let evSum = this;
        let d: JQueryDeferred<DevExpress.ui.dxDataGrid> = $.Deferred();

        if (evSum._GridInstance) {
            return d.promise(d.resolve(evSum._GridInstance));
        }

        evSum.Data.done(data => {

            evSum._GridInstance = $('<div />').dxDataGrid({
                dataSource: data.SessionSummary,
                height: '50%',
                columns: [
                    {
                        dataField: 'SessionName',
                        caption: 'Name'
                    }, {
                        dataField: 'NumAttending',
                    }, {
                        dataField: 'SessionFees',
                        caption: 'Fees'
                    }, {
                        dataField: 'HandoutsCost'
                    }, {
                        dataField: 'HandoutsIncome'
                    }, {
                        dataField: 'NetIncome'
                    }
                ],
                showBorders: true,
                showRowLines: true
            }).dxDataGrid('instance');

            d.resolve(evSum._GridInstance);
        });

        return d.promise();
    }

    EventID: string;
    EventSubject: string;

    constructor(eventID: string, subject: string) {
        this.EventID = eventID;
        this.EventSubject = subject;
    }

    Init() {
        let evSum = this;

        let bodyContent = $('<div />').height('100%');

        let dialog = new Dialog({
            title: `Event Summary - ${evSum.EventSubject}`,
            closable: true,
            body: bodyContent
        }, null, true, false);

        let scrollview = $('<div />').height('50%');

        $.when(evSum.FormInstance, evSum.GridInstance).done((form, grid) => {

            scrollview.append(form.element());

            let content = $('<div />').height('100%').append(
                scrollview,
                grid.element()
            )

            scrollview.dxScrollView();

            bodyContent.append(content);

            dialog.open();
        });
    }

    GetNewBusinessDrillDown(itemType: itemTypes, oppType?: OppType) {
        let evSum = this;
        new EventSummaryDrillDown(evSum.EventID, itemType, oppType).Init();
    }

}

interface EventSummaryData {
    TotalIncome: number,
    TotalExpenses: number,
    NetROI: number,
    SessionSummary: SessionSummary[],
    NewBusiness: EventNewBusiness,
    ClosedOpps: number[],
    OpenOpps: number[],
}

interface SessionSummary {
    SessionName: string,
    NumAttending: number,
    SessionsFees: number,
    HandoutsCost: number,
    HandoutsIncome: number,
    NetIncome: number
}

interface EventNewBusiness {
    NewAccounts: number,
    NewContacts: number,
    NewOpportunities: number,
    NewLeads: number
}