import {
  IGridViewOld,
  IGridViewMenuItem,
  IGridToolbarModel
} from "../../../interfaces/interfaces";
import { TDFRequest } from "../../../services/request";
import { Preferences } from "../../../infrastructure/user/preferences";
import { Notification } from "../../dialogs/notification";
import { GridSetup } from "../../../services/grid/helpers/gridsetup";
import { ToolbarWithoutViews } from "./toolbarwithoutviews";
import { GridContainerTypes, itemTypes } from "../../../enums/enums";
import { GetDevice } from "../../../util/allutils";
import dxDataSource from "devextreme/data/data_source";
import dxArrayStore from "devextreme/data/array_store";
import "devextreme/ui/lookup";
import "devextreme/ui/check_box";
import "devextreme/ui/button";
export abstract class BaseToolbar extends ToolbarWithoutViews {
  /**
   * Interface for Devexpress toolbar options.
   */
  //Options: DevExpress.ui.dxToolbarOptions = {};
  /**
   * The id of the html element that will hold the toolbar.
   */
  //ToolbarContainerID: string;
  /**
   * Addtional items you would like added to the grid toolbar.
   */
  //ExtraItems: any;
  /**
   * The list of view id's for the global scope.
   */
  GlobalViews?: Array<IGridViewMenuItem>;
  /**
   * The list of view id's for the personal scope.
   */
  PersonalViews?: Array<IGridViewMenuItem>;
  /**
   * Specifies if the user has chosen to default to their personal views.
   */
  UsePersonalViews: boolean;
  /**
   * The View GUID of the default view for the current scope\type.
   */
  DefaultView: string;
  /**
   * The View GUID of the currently selected view for the scope\type
   */
  CurrentView: string;

  AddViewToolbarItems() {
    return [];
  }

  GridDataRequestType: string = "GET";

  ContainerItemID: string;

  constructor(args: IGridToolbarModel) {
    super(args);
    let theToolbar = this;
    $.each(args, function(key, val) {
      theToolbar[key] = val;
    });
  }
  /*
         * Return the list of Global Grid Views  
         */
  GetGlobalViews(): IGridViewMenuItem[] {
    let theToolbar = this;
    if (theToolbar.GlobalViews && theToolbar.GlobalViews.length > 0) {
      return theToolbar.GlobalViews;
    } else {
      /* TODO: Alert the user there are no views and reset scope?*/
      return;
    }
  }
  /**
   * Return the list of Personal GridViews
   */
  GetPersonalViews(): IGridViewMenuItem[] {
    let theToolbar = this;
    if (theToolbar.PersonalViews && theToolbar.PersonalViews.length > 0) {
      return theToolbar.PersonalViews;
    } else {
      /* TODO: Alert the user there are no views and reset scope? */
      return;
    }
  }

  abstract GetViews();

  Render() {
    super.Render();
  }

  abstract RefreshCurrentGrid(e);
}
export class GridToolbar extends BaseToolbar {
  /**
   * Interface for Devexpress toolbar options.
   */
  //Options: DevExpress.ui.dxToolbarOptions = {};
  /**
   * The DevEpress lookup class that will hold the views for the current container and scope.
   */
  ViewSelector: DevExpress.ui.dxLookup;
  /**
   * The DevExpress button class the will switch the scope of the views shown
   */
  ScopeSelector: DevExpress.ui.dxButton;
  /**
   * The id of the html element that will hold the toolbar.
   */
  //ToolbarContainerID: string;
  /**
   * The Grid Container type e.g.(Search or 4)
   */
  TDFContainerType: GridContainerTypes;
  /**
   * Indicates whether to allow exporting of grid data. Used to show\hide the export button.
   */
  CanExportData: boolean;
  /**
   * Addtional items you would like added to the grid toolbar.
   */
  //ExtraItems: any;
  /**
   * The type of item the grid is currently displaying. e.g.(itemAccounts or 1)
   */
  GridItemType: itemTypes;

  /**
   * The parameters uses to get the data for the grid.
   */
  GridDataRequestModel: GridSetup.BaseGridRequestModel;
  /**
   * The Categories for gridviews to be grouped in.
   */
  CategoryList: string[];
  /**
   * This is a container that will give the user options for the grid.
   */
  ActionSheet: DevExpress.ui.dxActionSheet;
  /**
   * A Devexpress checkbox to set the current view as the default view for the current scope
   */
  MakeDefaultCheck: DevExpress.ui.dxCheckBox;
  /**
   * A Devexpress radio group that allows the user to set the default grid scope for the current grid container type
   */
  GridTypeDefault: DevExpress.ui.dxRadioGroup;
  /**
   * GUID id to uniquely id some elements on the toolbar and grid;
   */
  GridID: string;
  /**
   * Easier way to know what scope the user has selected.
   */
  CurrentScope: "Global" | "Personal" = "Global";
  /**
   * The html id of the popover container for changing default view for scope and default scope for type
   */
  ScopeActionsID: string;
  /**
   * Ths html id of the view lookup
   */
  ViewSelectorID: string;
  /**
   * The html id of the grid refresh button
   */
  RefreshButtonID: string;
  /**
   * The html id of the scope button
   */
  ScopeSelectorID: string;
  /**
            The event to override what happens when a view is changed
            */
  OnViewChanged: any;
  /**
            The event to override to build the grid if nescesary.
            */
  MakeGrid: any;
  /**
            The event to override to build the columns if nescesary.
            */
  MakeColumns: any;
  /**
   * The grid refresh dx button
   */
  RefreshButton: DevExpress.ui.dxButton;
  /**
   * A flag to prevent the default scope\view for scope prefrence from being set if we are just changing the scope
   */
  ShouldChangeDefaultScope: boolean = true;
  /**
   *IF this toolbar is on a Linked item grid then we will need to use the ID of the item we are on for creating some types of items [ex: Opportunity-Contact-Appointment]
   */
  ContainerItemID: string;
  FullScreenID: string;
  constructor(args: any) {
    super(args);
    let theToolbar = this;
    theToolbar.ScopeActionsID = "gridtoolbarscopeactions_" + theToolbar.GridID;
    theToolbar.ViewSelectorID = "viewselector_" + theToolbar.GridID;
    theToolbar.RefreshButtonID = "refreshgrid_" + theToolbar.GridID;
    theToolbar.ScopeSelectorID = "gridscope_" + theToolbar.GridID;
    theToolbar.CurrentScope = theToolbar.UsePersonalViews
      ? "Personal"
      : "Global";

    //if (!$("#" + theToolbar.ScopeActionsID).length) {
    //    $("<div id='" + theToolbar.ScopeActionsID + "' />").appendTo("#tdfbody");
    //}

    //theToolbar.ScopeActions();
  }

  /**
   * Render the dxActionSheet for setting the default scope and view for the current type
   */
  ScopeActions() {
    let theToolbar = this;

    if (!theToolbar.ViewSelector) {
      theToolbar.ViewSelector = $("#" + theToolbar.ViewSelectorID).dxLookup(
        "instance"
      );
    }
    //let opts: DevExpress.ui.dxActionSheetOptions = {
    //   onInitialized
    //}

    if ($("#" + theToolbar.ScopeActionsID).length) {
      $("#" + theToolbar.ScopeActionsID).remove();
    }
    $("<div id='" + theToolbar.ScopeActionsID + "' />").appendTo("#tdfbody");
    theToolbar.ActionSheet = $("#" + theToolbar.ScopeActionsID)
      .dxActionSheet({
        dataSource: [
          {
            template: function(itemData, index, itemElement) {
              //let theView: string = theToolbar.CurrentScope === "Personal" ?
              //    $.grep(theToolbar.GetPersonalViews(), (view, index) => { return view.IsDefault })[0].ViewGUID :
              //    $.grep(theToolbar.GetGlobalViews(), (view, index) => { return view.IsDefault })[0].ViewGUID;

              theToolbar.MakeDefaultCheck = $("<div />")
                .dxCheckBox({
                  value:
                    (theToolbar.ViewSelector.option("value") as any) ===
                    theToolbar.DefaultView, //(theView === (theToolbar.ViewSelector.option("value") as any))
                  text:
                    theToolbar.CurrentScope === "Personal"
                      ? "Personal default view."
                      : " Global default view.",
                  onValueChanged: function(e) {
                    if (theToolbar.ShouldChangeDefaultScope) {
                      if ((e.component as any).Resetting) {
                        delete (e.component as any).Resetting;
                        return;
                      }
                      theToolbar.ChangeDefaultViewForScope(e).done(cancel => {
                        theToolbar.ShouldChangeDefaultScope = true;
                        theToolbar.UpdateCurrentDefaultViewOption();
                        if (cancel) {
                          e.component["Resetting"] = true;
                          e.component.option("value", e.previousValue);
                        }
                      });
                    }
                    theToolbar.ShouldChangeDefaultScope = true;
                  }
                })
                .dxCheckBox("instance");

              theToolbar.MakeDefaultCheck.element().appendTo(itemElement);
            }
          },
          {
            text: "For this grid default to:",
            template: function(itemData, index, itemElement) {
              itemElement.append($("<div />").text(itemData.text));
              theToolbar.GridTypeDefault = theToolbar.GetGridTypeDefaultScopeSelection();
              theToolbar.GridTypeDefault.element().appendTo(itemElement);
            }
          }
        ],
        target: "#" + theToolbar.ScopeSelectorID,
        showTitle: false,
        usePopover: !GetDevice().isDevice,
        showCancelButton: true,
        visible: false
      })
      .dxActionSheet("instance");
  }
  /**
   * get the devextreme datasource for the view selector
   */
  GetViewsDataSource() {
    let theToolbar = this;
    return new dxDataSource({
      store: new dxArrayStore({
        data: theToolbar.GetViews(),
        key: "ViewGUID"
      }),
      sort: "ViewName",
      group: "ViewCategory"
    });
  }
  /**
   * Render the toolbar on the toolbar container id and set the properties for the toolbar controls
   */
  Render() {
    let theToolbar = this;
    let allToolbarItems: Array<DevExpress.ui.dxToolbarOptions> = theToolbar.ToolbarItems();
    $.each(theToolbar.AddViewToolbarItems(), (k: number, v) => {
      allToolbarItems.splice(k + 3, 0, v);
    });

    theToolbar.Options.dataSource = allToolbarItems;
    super.Render();
    //  theToolbar.ToolbarObject = $("#" + theToolbar.ToolbarContainerID).css({"margin":"10px 0"}).dxToolbar(theToolbar.Options).dxToolbar('instance');

    if (!theToolbar.ViewSelector) {
      theToolbar.ViewSelector = $("#" + theToolbar.ViewSelectorID).dxLookup("instance");
    }
    if (!theToolbar.RefreshButton) {
      theToolbar.RefreshButton = $("#" + theToolbar.RefreshButtonID).dxButton("instance");
    }
    if (!theToolbar.ScopeSelector) {
      theToolbar.ScopeSelector = $("#" + theToolbar.ScopeSelectorID).dxButton("instance");
    }
    //if (!theToolbar.ActionSheet) {
    //    theToolbar.ActionSheet = $("#" + theToolbar.ScopeActionsID).dxActionSheet("instance");
    //}

    return theToolbar;
  }

  RenderAndLoad() {
    let theToolbar = this;
    theToolbar.Render();

    if (!theToolbar.ViewSelector.option("value")) {
      theToolbar.ViewSelector.element().addClass("flash");
    } else {
      theToolbar.RefreshCurrentGrid(null);
    }

    return theToolbar;
  }
  /**
   *Get either the personal or global views depending on the selected scope
   */
  GetViews() {
    let theToolbar = this;
    let theViews =
      theToolbar.CurrentScope === "Personal"
        ? theToolbar.GetPersonalViews()
        : theToolbar.GetGlobalViews();
    if (theToolbar.ViewSelector) {
      let theDefaultView = $.grep(theViews, function(val, key) {
        return val.IsDefault;
      });
      if (!theToolbar.CurrentView) {
        theToolbar.CurrentView =
          theDefaultView && theDefaultView[0]
            ? theDefaultView[0].ViewGUID
            : theToolbar.DefaultView;
      }
    }
    let lst: IGridViewOld[] = [];
    $.each(theViews, (key, val) => {
      lst.push({
        ViewName: val.ViewName,
        ViewGUID: val.ViewGUID || "_",
        IsDefault: val.IsDefault,
        ViewCategory: val.ViewCategory || "General",
        ItemType: val.ItemType
      });
    });

    return lst;
  }
  /**
   * Change the icon of the scope button for the selected scope
   */
  UpdateScopeIcon() {
    let theToolbar = this;
    if (!theToolbar.ScopeSelector) {
      theToolbar.ScopeSelector = $("#" + theToolbar.ScopeSelectorID).dxButton(
        "instance"
      );
    }
    if (!theToolbar.ScopeSelector) {
      return;
    }
    theToolbar.ScopeSelector.option(
      "icon",
      theToolbar.CurrentScope === "Personal" ? "home" : "globe"
    );
  }
  /**
   * Add array of view toolbar items used in the datasource of the dxtoolbar
   */
  AddViewToolbarItems() {
    let theToolbar = this;

    let items: any = [
      {
        location: GetDevice().isDevice ? "before" : "after",
        showText: "inMenu",
        locateInMenu: "auto",
        widget: "dxLookup",
        options: theToolbar.ViewSelectorOptions()
      },
      {
        location: "after",
        locateInMenu: GetDevice().isDevice ? "always" : "auto",
        showText: "inMenu",
        widget: "dxButton",
        options: theToolbar.RefreshbtnOptions()
      },
      {
        location: "after",
        locateInMenu: GetDevice().isDevice ? "always" : "auto",
        showText: "inMenu",
        widget: "dxButton",
        options: theToolbar.GridScopebtnOptions()
      },
      {
        location: "after",
        locateInMenu: GetDevice().isDevice ? "always" : "auto",
        showText: "inMenu",
        widget: "dxButton",
        options: theToolbar.GridViewOptionsbtnOptions()
      },
      theToolbar.ChangeCardViewOption()
      //{
      //    location: "after",
      //    locateInMenu: "auto",
      //    visible:$(`#${theToolbar.FullScreenID}`).length?true:false,
      //    showText: "inMenu",
      //    widget: "dxButton",
      //    options: theToolbar.GridFullScreenbtnOptions()
      //}
    ];
    //if (theToolbar.ExtraItems && theToolbar.ExtraItems.length > 0) {
    //    $.each(theToolbar.ExtraItems, (key, val) => {
    //        items.push(val);
    //    });
    //}

    return items;
  }

  FullRefreshCurrentGrid(e) {
    let theToolbar = this;
    if (
      theToolbar.GridDataRequestModel &&
      theToolbar.GridDataRequestModel.RequestArgs
    ) {
      theToolbar.GridDataRequestModel.RequestArgs.filterExpression = "";
      theToolbar.GridDataRequestModel.RequestArgs.Sorts = [];
      theToolbar.GridDataRequestModel.RequestArgs.page = 1;
    }
    theToolbar.RefreshCurrentGrid(e);
  }

  /**
   * Overide this event to handle clicking the refresh button in the grid toolbar
   * @param e
   */
  RefreshCurrentGrid(e) {
    let theToolbar = this;
    let currentValue = theToolbar.ViewSelector.option("value");
    theToolbar.ViewSelector.option("value", "");
    theToolbar.ViewSelector.option("value", currentValue);
  }
  /**
   * Change the scope of the current type             *
   */
  UpdateScope() {
    let theToolbar = this;
    //Switch the current scope.
    theToolbar.CurrentScope =
      theToolbar.CurrentScope === "Global" ? "Personal" : "Global";
    // Since were switching scope clear the current view so it gets set to the default for this scope
    theToolbar.CurrentView = "";
    if (!theToolbar.ViewSelector) {
      theToolbar.ViewSelector = $("#" + theToolbar.ViewSelectorID).dxLookup(
        "instance"
      );
    }
    theToolbar.ShouldChangeDefaultScope = false;
    theToolbar.ViewSelector.reset();
    // theToolbar.ViewSelector.option("dataSource", []);
    theToolbar.ViewSelector.option(
      "dataSource",
      theToolbar.GetViewsDataSource()
    );
    (theToolbar.ViewSelector.option("dataSource") as any).reload();

    theToolbar.ViewSelector.option(
      "value",
      theToolbar.CurrentView || theToolbar.DefaultView || "_"
    );
    theToolbar.UpdateScopeIcon();
    theToolbar.ShouldChangeDefaultScope = true;
  }

  /**
   * If the current view is the default this will check the MakeDefaultCheck box and change the label for the checkbox depending on the scope
   */
  UpdateCurrentDefaultViewOption() {
    let theToolbar = this;
    if (!theToolbar.MakeDefaultCheck) {
      return;
    }
    let isDefault =
      (theToolbar.ViewSelector.option("value") as any) ===
      theToolbar.DefaultView;

    theToolbar.ShouldChangeDefaultScope = false;
    theToolbar.MakeDefaultCheck.option("value", isDefault);

    theToolbar.MakeDefaultCheck.option(
      "text",
      theToolbar.CurrentScope === "Personal"
        ? " Personal default view."
        : " Global default view."
    );

    theToolbar.ShouldChangeDefaultScope = true;
  }

  /**
   * Change the value of the radio buttons for the default scope for the type
   */
  UpdateCurrentDefaultScopeOption() {
    let theToolbar = this;
    let scopes = ["Global Views", "Personal Views"];
    let current =
      theToolbar.CurrentScope === "Personal"
        ? "Personal Views"
        : "Global Views";
    theToolbar.GridTypeDefault.option(
      "value",
      $.grep(scopes, (val, key) => val === current)[0]
    );
  }
  /**
   * render the radio group for the default scope for the type
   */
  private GetGridTypeDefaultScopeSelection() {
    let theToolbar = this;
    let scopes = ["Global Views", "Personal Views"];
    let current = theToolbar.UsePersonalViews
      ? "Personal Views"
      : "Global Views";

    return $("<div id='gridTypeDefault'></div>")
      .dxRadioGroup({
        items: scopes,
        layout: "horizontal",
        value: $.grep(scopes, function(val, key) {
          return val === current;
        })[0],
        itemTemplate: function(itemData, _, itemElement) {
          itemElement
            .parent()
            .addClass(itemData.toLowerCase())
            .text(itemData);
        },
        onValueChanged: function(e) {
          theToolbar.ChangeDefaultScope(e);
        }
      })
      .dxRadioGroup("instance");
  }
  /**
   * set the default scope for the current type
   * @param e
   */
  private ChangeDefaultScope(e) {
    let theToolbar = this;
    let global = e.value !== "Personal Views";
    let request = new TDFRequest({
      url: `${TDFRequest.ApiPath}/core/user/UpdateGridScope`,
      type: "POST",
      data: {
        itemType: theToolbar.GridItemType,
        containerType: theToolbar.TDFContainerType,
        isDesktop: 0,
        useGlobalScope: global
      }
    });
    request.MakeRequest();
  }
  /**
   * set the selected view as the default view for the current type and scope
   * @param e
   */
  private ChangeDefaultViewForScope(e) {
    let theToolbar = this;
    let dfd = $.Deferred();
    // TODO: This does not account for unsetting the default view
    let containerType = theToolbar.TDFContainerType;
    let gridItemType = theToolbar.GridItemType;
    let viewID = theToolbar.ViewSelector.option("value");
    let viewName = theToolbar.ViewSelector.option("text");
    let isGlobalScope = theToolbar.CurrentScope === "Global";

    if (viewID && !e.value) {
      let message: any = {};
      message.text = $("<span />").text(
        "Unset Default View. Please Choose an option."
      );
      message.buttons = $("<div />")
        .append(
          $("<span />")
            .css("margin", "0 5px")
            .append(
              $("<div />").dxButton({
                text: "Clear View",
                icon: "clear",
                type: "danger",
                onClick: function(e: any) {
                  let toast = $($(e.element[0]).parents())
                    .find(
                      ".dx-overlay.dx-widget.dx-visibility-change-handler.dx-toast"
                    )
                    .dxToast("instance");
                  toast.hide();
                  viewID = "";
                  viewName = "No View";
                  Preferences.SetViewAsDefault(
                    containerType,
                    gridItemType,
                    viewID,
                    viewName,
                    isGlobalScope
                  );

                  theToolbar.UpdateLocalDefaultViewSetting(viewID);
                  dfd.resolve(false);
                  return;
                }
              })
            )
        )
        .append(
          $("<span />")
            .css("margin", "0 5px")
            .append(
              $("<div />").dxButton({
                text: "Use Company Default",
                icon: "revert",
                type: "success",
                onClick: function(e: any) {
                  let toast = $($(e.element[0]).parents())
                    .find(
                      ".dx-overlay.dx-widget.dx-visibility-change-handler.dx-toast"
                    )
                    .dxToast("instance");
                  toast.hide();
                  viewID = "{423bd843-4bfe-4cd8-a4d8-c6d36e353376}";
                  viewName = "Company Default";
                  Preferences.SetViewAsDefault(
                    containerType,
                    gridItemType,
                    viewID,
                    viewName,
                    isGlobalScope
                  );

                  theToolbar.UpdateLocalDefaultViewSetting(viewID);
                  dfd.resolve(false);
                  return;
                }
              })
            )
        )
        .append(
          $("<span />")
            .css("margin", "0 5px")
            .append(
              $("<div />").dxButton({
                text: "Cancel",
                icon: "close",
                //type: "info",
                onClick: function(e: any) {
                  let toast = $($(e.element[0]).parents())
                    .find(
                      ".dx-overlay.dx-widget.dx-visibility-change-handler.dx-toast"
                    )
                    .dxToast("instance");
                  toast.hide();
                  dfd.resolve(true);
                  return;
                }
              })
            )
        );

      new Notification({
        type: "warning",
        shading: true,
        displayTime: 2000000,
        message: message
      });
    } else {
      Preferences.SetViewAsDefault(
        containerType,
        gridItemType,
        viewID,
        viewName,
        isGlobalScope
      );

      theToolbar.UpdateLocalDefaultViewSetting(viewID);
      return dfd.promise(dfd.resolve(false));
    }

    // TODO:  Update the default value on the local

    return dfd.promise();
  }

  UpdateLocalDefaultViewSetting(viewID) {
    let theToolbar = this;
    theToolbar.DefaultView = viewID;
    let Views = theToolbar.GetViews();
    let newDefault = Views.filter((v, k) => {
      return v.ViewGUID === viewID;
    });
    let oldDefault = Views.filter((v, k) => {
      return v.IsDefault;
    });
    if (newDefault.length) {
      newDefault[0].IsDefault = true;
    }
    if (oldDefault.length) {
      oldDefault[0].IsDefault = false;
    }
  }

  /**
   * get the options for the views lookup
   */
  protected ViewSelectorOptions(): DevExpress.ui.dxLookupOptions {
    let theToolbar = this;
    //if (!theToolbar.CurrentView) theToolbar.CurrentView = theToolbar.DefaultView;
    return {
      fullScreen: GetDevice().device.phone,
      elementAttr: { id: theToolbar.ViewSelectorID },
      dataSource: theToolbar.GetViewsDataSource(),
      displayExpr: "ViewName",
      valueExpr: "ViewGUID",
      searchEnabled: true,
      grouped: true,
      width: GetDevice().isDevice ? "" : "auto",
      popupWidth: () => {
        return GetDevice().isDevice ? "" : $(window).width() * 0.5;
      },
      value: theToolbar.CurrentView,
      title: "Choose a view",
      showPopupTitle: !GetDevice().device.phone,
      closeOnOutsideClick: true,
      onOpened(data) {
        if (!data.component.option("value")) {
          if (
            data.component.option("items").length === 1 &&
            data.component.option("items")[0].items.length === 1
          ) {
            if (!data.component.option("items")[0].items[0].ViewGUID) {
              theToolbar.ScopeSelector.element().addClass("flash");
            }
          }
        }
      },
      onSelectionChanged: (e: any) => {
        e.component.close();
      },
      onValueChanged: theToolbar.ViewSelectorValueChanged.bind(theToolbar)
    };
  }

  GetGridRequestModel(data) {
    let theToolbar = this;
    let d = $.Deferred();

    if (theToolbar.GridDataRequestModel) {
      theToolbar.GridDataRequestModel.RequestArgs.GUID = data.value;
    }

    if (!data.value) {
      if (
        theToolbar.CurrentGrid &&
        theToolbar.CurrentGrid.GridObject &&
        theToolbar.CurrentGrid.GridObject.option("dataSource") &&
        theToolbar.CurrentGrid.GridObject.option("dataSource").store
      ) {
        if (
          theToolbar.CurrentGrid.GridObject.option("dataSource").store().clear
        ) {
          theToolbar.CurrentGrid.GridObject.option("dataSource")
            .store()
            .clear();
        }
      }
      return d.promise(d.resolve());
    }
    if (theToolbar.GridDataRequestModel) {
      let request = new TDFRequest({
        url: theToolbar.GridDataRequestModel.Path,
        type: theToolbar.GridDataRequestType,
        data: theToolbar.GridDataRequestModel.RequestArgs
      });
      request.MakeRequest().done(response => {
        d.resolve(response);
      });
    }

    return d.promise();
  }

  /**
   * use the overriden viewchanged event if set .. else get the new grid response for the selected grid view
   * @param data
   */
  protected ViewSelectorValueChanged(data: any) {
    const theToolbar = this;

    theToolbar.UpdateCurrentDefaultViewOption();
    if (theToolbar.OnViewChanged) {
      theToolbar.OnViewChanged(data);
    } else {
      theToolbar.DefaultViewSelectorValueChangedEvent(data);
    }
  }

  public DefaultViewSelectorValueChangedEvent(data: any) {
    const theToolbar = this;

    /* TODO: keep the most recent grid data in case the view they switch back to is the one
                they just left so we dont need to go back to the server and get the data again. */
    theToolbar.GetGridRequestModel(data).done(response => {
      if (response) {
        /* TODO: Need to change this to know if need to change columns and such*/
        if (theToolbar.CurrentGrid) {
          let element = theToolbar.CurrentGrid.GridContainer;
          let storageKey =
            "storage_" +
            (element ? ($(element) ? $(element).attr("id") : "") : "");
          sessionStorage.removeItem(storageKey);

          $.each(response, (key, val) => {
            if (theToolbar.CurrentGrid.GridDataResponse.hasOwnProperty(key)) {
              theToolbar.CurrentGrid.GridDataResponse[key] = val;
            }
          });

          let pagingInfo = theToolbar.CurrentGrid.BuildPagingInfo();
          theToolbar.CurrentGrid.Options.pager.infoText = pagingInfo;

          if (theToolbar.MakeColumns) {
            this.CurrentGrid.Options.columns = theToolbar.MakeColumns(response);
            this.CurrentGrid.Summaries();
          } else {
            this.CurrentGrid.Columns();
          }
          this.CurrentGrid.GridObject.option(
            "pager",
            theToolbar.CurrentGrid.Options.pager
          );
          this.CurrentGrid.GridObject.option(
            "columns",
            this.CurrentGrid.Options.columns
          );
          if (theToolbar.CurrentGrid.UseCards) {
            this.CurrentGrid.DataSource = null;
            this.CurrentGrid.GridObject.option(
              "dataSource",
              theToolbar.CurrentGrid.DataSource
            );
          } else {
            this.CurrentGrid.DataSource = null;
            this.CurrentGrid.GridObject.option(
              "dataSource",
              theToolbar.CurrentGrid.DataSource
            );
          }
        } else {
          if (theToolbar.MakeGrid) {
            theToolbar.MakeGrid(response);
          }
        }
      }
    });

    if (!data.value || data.value === "_") {
      let element = data.component.element();
      if (element.hasClass("flash")) element.removeClass("flash");
      setTimeout(() => {
        if (!data.component.option("value")) {
          element.addClass("flash");
        }
      }, 20);
    }
  }

  /**
   * get the options for the grid refresh button
   */

  protected RefreshbtnOptions() {
    let theToolbar = this;
    return {
      text: "Refresh Current Grid",
      elementAttr: { id: theToolbar.RefreshButtonID },
      icon: "fa fa-refresh",
      type: "normal",
      onClick: theToolbar.FullRefreshCurrentGrid.bind(theToolbar)
    };
  }

  /**
   * get the options for the grid scope button
   */
  protected GridScopebtnOptions() {
    let theToolbar = this;
    return {
      text: "Grid Scope",
      elementAttr: { id: theToolbar.ScopeSelectorID },
      icon: theToolbar.CurrentScope === "Personal" ? "home" : "globe",
      type: "normal",
      onClick: theToolbar.UpdateScope.bind(theToolbar)
    };
  }
  /**
   * get the options for the grid options button
   */
  protected GridViewOptionsbtnOptions() {
    let theToolbar = this;
    return {
      text: "View Options",
      elementAttr: { id: theToolbar.ScopeActionsID + "btn" },
      icon: "spindown",
      type: "normal",
      onClick(e) {
        if ($("#" + theToolbar.ScopeActionsID).length) {
          $("#" + theToolbar.ScopeActionsID).remove();
        }
        $("<div id='" + theToolbar.ScopeActionsID + "' />").appendTo(
          "#tdfbody"
        );
        theToolbar.ScopeActions();

        // theToolbar.ActionSheet.option("usePopover", !$("#" + theToolbar.ScopeActionsID).parent().hasClass("dx-toolbar-item-auto-hide"));
        theToolbar.ActionSheet.toggle(
          !theToolbar.ActionSheet.option("visible")
        );
      }
    };
  }
  //protected GridFullScreenbtnOptions() {
  //    let theToolbar = this;
  //    return {
  //        text: "Toggle Fullscreen",
  //        //elementAttr: { "id": theToolbar.ScopeActionsID + "btn" },
  //        icon: "fa fa-arrows-alt",
  //        type: "normal",
  //        onClick(e) {
  //            if (theToolbar.FullScreenID && $(`#${theToolbar.FullScreenID}`).length) {
  //                let el = $(`#${theToolbar.FullScreenID}`)[0]
  //                FullScreen(el);
  //            }
  //        }
  //    };
  //}
}
