import { GetPreference } from "../../infrastructure/user/getpreference";
import {
  PreferenceModuleNames,
  TDFMobilePreferenceNames,
  Preferences
} from "../../infrastructure/user/preferences";
import { DomSafeID, GimmeGUID } from "../../util/allutils";

export class Form {
  private FormArgs: DevExpress.ui.dxFormOptions; //Dialogs.IFormOptions;
  private UseScrollView: boolean;
  private FormElement: JQuery;
  public FormObject: DevExpress.ui.dxForm;

  constructor(
    el: JQuery,
    args: DevExpress.ui.dxFormOptions,
    scrollview = true
  ) {
    let theForm = this;

    theForm.FormElement = el;
    theForm.FormArgs = args;
    theForm.UseScrollView = scrollview;

    if (!theForm.FormArgs.elementAttr) {
      theForm.FormArgs.elementAttr = {};
      //theForm.FormArgs.id = "tdf_Form_" +  DomSafeID( GimmeGUID());
    }

    if (!theForm.FormArgs.elementAttr.id) {
      theForm.FormArgs.elementAttr.id = "tdf_Form_" + DomSafeID(GimmeGUID());
    }
    theForm.FormArgs.elementAttr.class = theForm.FormArgs.elementAttr.class
      ? theForm.FormArgs.elementAttr.class + " tdfForm"
      : "tdfForm";

    theForm.OpenForm();
  }

  private OpenForm() {
    let theForm = this;

    if (theForm.UseScrollView) {
      theForm.FormElement = theForm.FormElement.dxScrollView(
        <DevExpress.ui.dxScrollViewOptions>{ height: "100%" }
      )
        .dxScrollView("instance")
        .content()
        .append($("<div />"));
    }

    let showingHelpText =
      Preferences.GetPreference(
        TDFMobilePreferenceNames.ShowHelpTextAutomatically,
        PreferenceModuleNames.TDFMobile
      ) !== "0";

    let temp;
    if (theForm.FormArgs.onInitialized) {
      temp = theForm.FormArgs.onInitialized;
    }
    theForm.FormArgs.onInitialized = function(e) {
      if (temp) {
        temp(e);
      }

      if (!showingHelpText) {
        let timer;
        timer = window.setInterval(() => {
          let form: JQuery = $(`#${theForm.FormArgs.elementAttr.id}`);
          if (form) {
            clearInterval(timer);
            $(
              `#${theForm.FormArgs.elementAttr.id} .dx-field-item-help-text`
            ).addClass("helpTextHidden");
          }
        }, 10);
      }
    };

    theForm.FormObject = theForm.FormElement.dxForm(theForm.FormArgs).dxForm(
      "instance"
    );
  }
}
