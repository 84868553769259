import { CenterType, GridContainerTypes } from "../../enums/enums";
import { ItemListingNameModel } from "../../interfaces/interfaces";
import { TDFDataCenters } from "../../interfaces/datacenters/tdfdatacenters";
import { AcctBIDataCenter } from "./bi_data_center_acct"
import { BSIGrids } from "enums/bi/enums";

export class VendorBIDataCenter extends AcctBIDataCenter {
    get CenterType(): CenterType {
        return CenterType.VendorBi;
    }

    get ContainerType(): GridContainerTypes {
        return GridContainerTypes.VendorSummary;
    }

    protected get DefaultItem(): BSIGrids {
        return BSIGrids.VendorSalesSummary;
    }

    constructor(
        itemListNameModel: ItemListingNameModel,
        creationArgs: TDFDataCenters.ICRMDataCenterCreationArgs,
        customName?: string
    ) {
        super("", itemListNameModel, creationArgs, customName);
    }
}