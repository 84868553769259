import * as Globalize from "globalize";
import * as moment from "moment";
import { AttachmentViewer } from "../components/controls/attachmentviewer";
import { GridToolbar } from "../components/grids/gridtoolbars/gridtoolbar";
import {
  IGridDataResponse,
  IGridGroupInfo,
  ITDFGridOptions
} from "../interfaces/interfaces";
import { TDFRequest } from "../services/request";
import { DomSafeID } from "../util/allutils";
import { ColumnTemplateHandler, GridWithoutViews } from "./gridwithoutviews";
import { CurrentUser } from "../infrastructure/context";

import dxDataSource from "devextreme/data/data_source";
import dxArrayStore from "devextreme/data/array_store";
export class Grid extends GridWithoutViews {
  GridDataResponse: IGridDataResponse = {};
  GridToolbar: GridToolbar;

  constructor(
    gridresponse: IGridDataResponse,
    gridargs: ITDFGridOptions,
    options?: DevExpress.ui.dxDataGridOptions,
    objRef?: any,
    selectionEvent?: Function,
    colTemplateHandler?: ColumnTemplateHandler
  ) {
    super(
      gridresponse,
      gridargs,
      options,
      objRef,
      selectionEvent,
      colTemplateHandler
    );

    let theGrid = this;

    if (gridresponse) {
      theGrid.GridDataResponse = gridresponse;
      if (gridresponse.Data) {
        theGrid.CleanData(theGrid.GridDataResponse.Data);
      }
    }
    $.each(gridargs, function (k, v: any) {
      theGrid[k] = v;
    });

    theGrid.Options = theGrid.DefaultOptions();
    if (options && !$.isEmptyObject(options)) {
      $.each(options, function (key, val) {
        (theGrid.Options as any)[key] = val;
      });
    }

    if (!options || !options.columns) {
      if (
        gridresponse &&
        gridresponse.GridView &&
        gridresponse.GridView.Layout
      ) {
        theGrid.Columns();
      }
    }

    theGrid.Options.dataSource = new dxDataSource({
      store: new dxArrayStore({
        data: gridresponse ? gridresponse.Data : []
      })
      // TAB:  This caused performance issues with client side delays after the request finished.  Not exactly sure why, but we don't need this right now, so I'm commenting it out, but wanted to leave this note for future reference.
      //pageSize: parseInt(Preferences.GetPreference("ClientGridPageSize", "TDFMobile"))
    }); //args ? args.Data : [];
  }

  CleanData(data: any) {
    let theGrid = this;
    if (theGrid.GridDataResponse && !theGrid.GridDataResponse.GridView) return;
    $.each(data, function (index, row) {
      Object.keys(row).forEach((field, index) => {
        let col: DevExpress.ui.dxDataGridColumn[] = theGrid.getColumn(
          theGrid.GridDataResponse,
          field
        );
        if (col && col.length && col[0].dataType === "date") {
          let valueString = row[field];
          if (valueString) {
            // DO NOT REMOVE OR DO ANYTHING BEFORE HANDLING STRIPPING MIDNIGHT OFF OF THE VALUE.
            // .NET does not handle a date-only data type.  Any date strings are serialized as a DateTime with a timestamp of midnight.
            // However, the browser will handle timezone differences such that an appointment saved in Eastern Time Zone will show as the day before in Central time.
            // (Central time is an hour behind so midnight today in Eastern is 11:00 PM Central.)
            // By removing the midnight timestamp, we are tricking the browser into ignoring the time and it shows the date as it was saved, without shifting it due to the timezone.
            let timestampIndex = valueString.indexOf("T00:00:00");
            if (timestampIndex > -1) {
              row[field] = valueString.substring(0, timestampIndex);
            } else if (moment(new Date(valueString)).isValid()) {
              row[field] = Globalize.formatDate(
                moment.parseZone(valueString).toDate(),
                { datetime: "short" }
              );
            }
          }
        }
      });
    });
  }

  /**
   * get the columns from the data request in a format the dxdata grid can understand.
   */
  Columns() {
    let theGridWOViews = this;

    //Handle the column level item templates
    $.each(theGridWOViews.GridDataResponse.GridView.Layout.Columns, function (
      index,
      col
    ) {
      if (col.itemTemplateType && theGridWOViews.ColumnTemplateHandler) {
        theGridWOViews.ColumnTemplateHandler(col);
      }
      if (col.dataField === "TDF_AttachmentCount") {
        (col as DevExpress.ui.dxDataGridColumn).headerCellTemplate = (
          container,
          info
        ) => {
          container.append(
            $("<span />") /*.css({ "display": "inline-block", width: "100%" })*/
              .addClass("text-center fa fa-paperclip")
          );
        };
        (col as DevExpress.ui.dxDataGridColumn).allowSearch = false;
        (col as DevExpress.ui.dxDataGridColumn).allowHeaderFiltering = true;
        (col as DevExpress.ui.dxDataGridColumn).allowFiltering = false;

        col.caption = "";
        col.cellTemplate = (container: JQuery, options: any) => {
          if (options.data["TDF_AttachmentCount"]) {
            container
              .append(
                $("<span />")
                  .css({ display: "inline-block", width: "100%" })
                  .addClass("text-center fa fa-paperclip")
              )
              .on("click", options.data, e => {
                if (e.data["TDF GUID"] && e.data["ItemType"])
                  new AttachmentViewer(
                    e.data["TDF GUID"],
                    e.data["ItemType"]
                  ).DisplayFiles();
              });
          }
        };
      }

      //Increase width of columns that will not have auto widths, so they display closer to how the saved column would in Desktop.
      if (typeof col.width === "number" && col.width > 0) {
        col.width += 48;
      } else {
        if (typeof col.width === "string" && parseInt(col.width) > 0) {
          col.width = parseInt(col.width) + 48;
        }
      }
    });
    theGridWOViews.Options.columns =
      theGridWOViews.GridDataResponse.GridView.Layout.Columns;
    theGridWOViews.Summaries();
  }

  Summaries() {
    let theGridWOViews = this;
    if (
      theGridWOViews.GridDataResponse.GridView.GroupList &&
      theGridWOViews.GridDataResponse.GridView.GroupList.length
    ) {
      $.each(theGridWOViews.Options.columns, function (
        k: any,
        v: DevExpress.ui.dxDataGridColumn
      ) {
        let group = $.grep(
          theGridWOViews.GridDataResponse.GridView.GroupList,
          function (val: IGridGroupInfo, key) {
            return v.dataField === val.Field;
          }
        );

        if (group.length) {
          // NOTE: a group column may not always be visible in the grid
          //if (v.visible) {
          v.groupIndex = group[0].Order;
          theGridWOViews.Options.summary = {
            groupItems: [
              {
                name: v.dataField,
                showInColumn: v.dataField,
                displayFormat: "Total Count: {0}",
                valueFormat: "decimal",
                //  showInGroupFooter:true,
                //column: v.dataField,
                summaryType: "custom"
              },
              {
                name: v.dataField,
                showInColumn: v.dataField,
                //  showInGroupFooter: true,
                displayFormat: "Currently Showing: {0}",
                valueFormat: "decimal",
                summaryType: "count"
              }
            ],
            calculateCustomSummary: function (options) {
              if (options.name) {
                if (options.summaryProcess === "start") {
                  options.totalValue = 0;
                }
                if (options.summaryProcess === "calculate") {
                  let t = $.grep(
                    theGridWOViews.GridDataResponse.AdditionalSummaryInfo,
                    function (v: any, k) {
                      return (
                        options.value[v.GroupFieldName] === v.GroupNameValue
                      );
                    }
                  );

                  if (t.length && options.totalValue === 0) {
                    options.totalValue = t[0].Count;
                  }
                }
                if (options.summaryProcess === "finalize") {
                }
              }
            }
          };
          // }
        }
      });
    }
    if (theGridWOViews.GridDataResponse.GridView.SummaryMap.Summaries.length) {
      let result = theGridWOViews.GetSummaryInfo(
        theGridWOViews.GridDataResponse.GridView.SummaryMap.Summaries,
        theGridWOViews.Options.columns
      );
      if (theGridWOViews.Options.summary) {
        theGridWOViews.Options.summary.totalItems = result.totalItems;
      } else {
        theGridWOViews.Options["summary"] = {
          totalItems: result.totalItems,
          skipEmptyValues: false
        };
      }
    }
  }
  /**
   * if there is a format string for the column then translate that to a dxDataGrid Column format.
   */
  ColumnFormatting() {
    let theGrid = this;
    $.each(theGrid.Options.columns, function (key, val) {
      let col;
      $.each(theGrid.GridDataResponse.GridView.Formatting, function (
        idx,
        value: any
      ) {
        if (
          value.Field.toLowerCase().replace(/\s/g, "") ===
          val.dataField.toLowerCase().replace(/\s/g, "")
        ) {
          col = value;
        }
      });

      if (col && val.visible) {
        if (col.FormatString)
          switch (col.FormatString) {
            case "c":
            case "c0":
            case "C0":
            case "C2":
            case "C":
              val.dataType = "number";
              val.format = {
                type: "currency",
                precision: 2,
                currency: CurrentUser.Currency
              };

              break;
            case "d":
              val.dataType = "date";
              val.format = { type: "shortDate" };
              break;
            case "g":
            case "G":
              val.dataType = "date";
              val.format = { type: "shortDateShortTime" };
              break;
            case "p":
            case "P":
              val.dataType = "number";
              val.format = {
                type: "percent",
                precision: 2
              };
              break;
          }
      }
    });
  }
  /**
   * Get the toolbar model and render the toolbar for the current grid container and itemtype
   * @param toolbarmodelurl
   * @param toolbarcontainer
   */
  Toolbar(toolbarmodelurl: string, toolbarcontainer: string) {
    let theGrid = this;
    let request = new TDFRequest({ url: toolbarmodelurl, type: "GET" });
    request.MakeRequest().done(function (response) {
      response.GridToolbar.ToolbarContainer = "searchgridtoolbar";
      theGrid.GridToolbar = new GridToolbar(response.GridToolbar);
    });
  }

  /**
   * Render the dxDatagrid
   */
  Render() {
    let theGrid = this;
    super.Render();
    if (
      theGrid.IsBIOrOther &&
      theGrid.GridToolbar &&
      theGrid.GridToolbar.ToolbarObject
    ) {
      theGrid.GridToolbar.ToolbarObject.option(
        `items[${theGrid.GridToolbar.ToolbarObject.option("items").length -
        1}].visible`,
        false
      );
    }
  }

  /**
   * Builds the paging info based on the  data response metadata.
   */
  BuildPagingInfo(): string {
    let theGrid = this;
    let infoText: string = "";

    if (theGrid.GridDataResponse && theGrid.GridDataResponse.Meta) {
      let metaInfo = theGrid.GridDataResponse.Meta;
      let serverPageSize: number = metaInfo.RecordsOnPage;
      let serverPageCount: number =
        metaInfo.TotalPages > 0 ? metaInfo.TotalPages : 1;
      let endRecordCount: number =
        (metaInfo.CurrentPage - 1) * serverPageSize + serverPageSize <
          metaInfo.TotalRecords
          ? (metaInfo.CurrentPage - 1) * serverPageSize + serverPageSize
          : metaInfo.TotalRecords;
      let currentPageRecordInfo: string =
        metaInfo.CurrentPage > 1
          ? (metaInfo.CurrentPage - 1) * serverPageSize +
          1 +
          "-" +
          endRecordCount
          : metaInfo.CurrentPage + "-" + serverPageSize;
      let totalCount: number = metaInfo.TotalRecords; // > 0 ? metaInfo.TotalRecords : results.Data.length;
      infoText =
        metaInfo.TotalRecords >= serverPageSize
          ? "Records: (" +
          currentPageRecordInfo +
          ") Of " +
          totalCount +
          " Total Records "
          : "Total Records " + totalCount;
    }

    return infoText;
  }

  /**
   * Strip the brackets from a View GUID to use as a HTML id
   */
  GetDOMSafeGridViewID() {
    let theGrid = this;
    if (theGrid.GridDataResponse.GridView) {
      return DomSafeID(theGrid.GridDataResponse.GridView.Layout.ViewID);
    } else {
      return "";
    }
  }
}
