import dxDataSource from "devextreme/data/data_source";
import { GridManager } from "../../admin/gridmanager";
import { CenterType, itemTypes, menuActions, tdfModules } from "../../enums/enums";
import { eventNameSpace, EventTypes } from "../../enums/webevents/enums";
import * as Globalize from "globalize";
import {
  RaiseEvent2,
  AddHandler2,
  RemoveHandler2
} from "../../infrastructure/events/ui_events";
import { Preferences } from "../../infrastructure/user/preferences";
import { IGridView } from "../../interfaces/grids/interfaces";
import {
  IExtendedDataGridColumn,
  IGridDataResponse,
  IItemInfo
} from "../../interfaces/interfaces";
import {
  BIGridDataService,
  GridViewServiceBI
} from "../../services/grid/bigriddataservice";
import { GridDataService } from "../../services/grid/griddataservice";
import { GridViewService } from "../../services/grid/gridviewservice";
import { TDFRequest } from "../../services/request";
import {
  AnyOf,
  ColonReplacementGUID,
  Debounce,
  DomSafeID,
  GetActionRequestParamsFromRowData,
  GetContainerForRecentOrFavorite,
  GetDevice,
  gethash,
  GroupActions,
  MenuView,
  NonOf,
  OpenItemOrURL,
  OpenNavigator,
  PeriodReplacementGUID,
  RenderActionsPopup,
  RenderViewsPopup,
  CloneIT
} from "../../util/allutils";
import { FilterBuilderHelper } from "../../util/allutils";
import { cols } from "../../util/allutils";
import { Dialog } from "../dialogs/dialog";
import { Notification } from "../dialogs/notification";
import { ItemMain } from "../items/helpers/itemmain";
import "devextreme/ui/data_grid";
import "devextreme/ui/accordion";
import "devextreme/ui/button";
import "devextreme/ui/toast";
import "devextreme/ui/menu";
import "devextreme/ui/context_menu";
import "devextreme/ui/form";
import "devextreme/ui/scroll_view";
import "devextreme/ui/check_box";
import { DisplayInfo, GetItemTypeDisplayInfo, CurrentUser, IsModuleLicensedAtCompany } from "../../infrastructure/context";
import { MenuAction } from "util/menuactions";
import { GridSetup } from "services/grid/helpers/gridsetup";
import { OpenButton } from "./gridtoolbars/toolbaritems/openbutton";
import { ActionButton } from "./gridtoolbars/toolbaritems/actionbutton";

enum SummaryItemType {
  Sum = 0,
  Min = 1,
  Max = 2,
  Count = 3,
  Average = 4,
  Custom = 5,
  None = 6
}

//TODO: Hide summary section on device to save space

export class AwesomeGrid {
  private _viewService: GridViewService | GridViewServiceBI;
  protected get viewService(): GridViewService | GridViewServiceBI {
    return this._viewService;
  }
  protected set viewService(value: GridViewService | GridViewServiceBI) {
    this._viewService = value;
  }
  private _dataService: GridDataService | BIGridDataService;
  public get dataService(): GridDataService | BIGridDataService {
    return this._dataService;
  }
  public set dataService(value: GridDataService | BIGridDataService) {
    this._dataService = value;
  }
  private _view: IGridView;
  protected get view(): IGridView {
    return this._view;
  }
  protected set view(value: IGridView) {
    this._view = value;
  }
  protected _container: JQuery;
  private _CenterType: CenterType;
  protected EventLimiterName: string = "";

  private _instance: DevExpress.ui.dxDataGrid | DevExpress.ui.dxAccordion | any;
  protected get Instance():
    | DevExpress.ui.dxDataGrid
    | DevExpress.ui.dxAccordion
    | any {
    let grid = this;
    if (grid._instance) return grid._instance;
    try {
      grid.UseCards
        ? (this._instance = this._container
          .children(".dx-scrollview")
          .dxScrollView("instance")
          .content()
          .children(".dx-widget")
          .dxAccordion("instance"))
        : (this._instance = this._container
          .children(".dx-widget")
          .dxDataGrid("instance"));
    } catch (e) {
      console.warn(e);
    }

    return grid._instance;
  }

  protected get CenterType(): CenterType {
    if (typeof this._CenterType !== "undefined") return this._CenterType;
    return null;
  }
  protected set CenterType(val: CenterType) {
    if (typeof val !== "undefined") this._CenterType = val;
  }

  /**
   * Get the column object from the api response matching the key on the datafield or the caption of the column
   * @param response
   * @param key
   */
  protected getColumn(key: string): DevExpress.ui.dxDataGridColumn[] {
    if (
      !this.ViewLayout ||
      !(
        this.ViewLayout.GridViewModel &&
        this.ViewLayout.GridViewModel.Layout.Columns
      )
    )
      return null;
    let col = (this.ViewLayout.GridViewModel.Layout.Columns as any[]).filter(
      (v: IExtendedDataGridColumn, i) => {
        return v.caption && (v.caption === key || v.dataField === key);
      }
    );
    return col;
  }
  /**
   * Get a column base on a property being defined
   * @param key
   */
  protected getColumnThatHasProperty(key: string): IExtendedDataGridColumn[] {
    if (
      !this.ViewLayout ||
      !(
        this.ViewLayout.GridViewModel &&
        this.ViewLayout.GridViewModel.Layout.Columns
      )
    )
      return null;
    let col = (this.ViewLayout.GridViewModel.Layout.Columns as any[]).filter(
      (v: IExtendedDataGridColumn, i) => {
        return typeof v[key] !== "undefined" && v[key] !== "";
      }
    );
    return col;
  }

  /**
   * convert the columns from the data request in a format the dxdata grid can understand.
   */
  protected get DxColumns() {
    let grid = this;

    // safeguard for now
    if (!grid.ViewLayout || !grid.ViewLayout.GridViewModel.Layout.Columns)
      return;

    let colswithtemplate = grid.getColumnThatHasProperty("itemTemplateType");

    if (colswithtemplate.length) {
      colswithtemplate.forEach((val, key) => {
        /*NOTE: need to make sure these are not fixed on devices so they dont take up space*/
        if (GetDevice().isDevice && val.itemTemplateType !== "SubjectCol") {
          val.fixed = false;
          val.visible = false;

          // val.visibleIndex = (val.visibleIndex + 1) +1
          return;
        }
        if (GetDevice().isDevice && val.itemTemplateType === "SubjectCol") {
          val.width =
            val.width > $(window).innerWidth() * 0.7
              ? $(window).innerWidth() * 0.7
              : val.width;
        }
        if (cols[val.itemTemplateType]) {
          val.cellTemplate = cols[val.itemTemplateType]();
          if (
            ["ItemType", "Favorite", "Attachments"].indexOf(val.caption) >= 0
          ) {
            val.allowSorting = false;
            val.headerCellTemplate = function (header, info) {
              header.prev().find(".dx-header-filter")[0].className =
                "fa fa-paperclip";
              header.parent().removeClass("dx-hidden-cell");
              //$('<div>')
              //	.html(`<i class="fa fa-paperclip"></i>`)
              ////	.css('font-size', '16px')
              //	.appendTo(header);
            };
          }
        } else {
          /*Change BiColumns to use above way ^^^*/
          if (grid["ColumnTemplateHandler"]) {
            grid["ColumnTemplateHandler"](val);
          }
        }
      });
    }
    if (
      grid.ViewLayout.GridViewModel.Layout.Columns[
      grid.ViewLayout.GridViewModel.Layout.Columns.length - 1
      ] &&
      grid.ViewLayout.GridViewModel.Layout.Columns[
        grid.ViewLayout.GridViewModel.Layout.Columns.length - 1
      ].dataField === ""
    ) {
      /*
				 * NOTE: In a recent update to devextreme they changed some logic that causes a max width to be set on teh grid if
								 all columns have a width property defined ... even if that value is falsy .. .in order to get a column to fill available space the
								 property for width must not be there at all
				 */
      delete grid.ViewLayout.GridViewModel.Layout.Columns[
        grid.ViewLayout.GridViewModel.Layout.Columns.length - 1
      ].width;
    }

    return grid.ViewLayout.GridViewModel.Layout.Columns;
  }

  protected _ViewLayout: ViewResponse;
  get ViewLayout(): ViewResponse {
    if (typeof this._ViewLayout !== "undefined") return this._ViewLayout;
    return null;
  }
  set ViewLayout(val: ViewResponse) {
    if (val) this._ViewLayout = val;
    this.OverrideDxOptions({ columns: this.DxColumns });
    this.renderGridShell();
  }

  private _dxOptions: DevExpress.ui.dxDataGridOptions;
  protected GetDxOptions(): DevExpress.ui.dxDataGridOptions {
    let theGrid = this;

    if (theGrid._dxOptions) return theGrid._dxOptions;
    let options: DevExpress.ui.dxDataGridOptions = {
      elementAttr: { "data-gridID": theGrid.view ? DomSafeID(theGrid.view.GUID) : "" },
      columnChooser: { enabled: true, allowSearch: true, title: "Add columns" },
      remoteOperations: {
        sorting: true,
        paging: false
      },
      //columns: <DevExpress.ui.dxDataGridColumn[]>theGrid.DxColumns,
      repaintChangesOnly: false,
      onSelectionChanged(e) {
        RaiseEvent2(
          EventTypes.CenterEventTypes.gridrowselect,
          theGrid.EventLimiterName,
          eventNameSpace.complete,
          e
        );
      },
      noDataText: "Fetching Data...",
      allowColumnReordering: true,
      onRowClick: function (e) {
        if (e.rowType !== "data") return;
        let event = e.event as JQueryEventObject;
        if (
          (e.component as DevExpress.ui.dxDataGrid).option("selection.mode") ===
          "multiple"
        ) {
          if (
            !e.isSelected &&
            e.event.target.id.indexOf("drilldownActionButton") === -1
          )
            (e.component as DevExpress.ui.dxDataGrid).selectRows(
              e.key,
              event.ctrlKey
            );
          else (e.component as DevExpress.ui.dxDataGrid).deselectRows(e.key);
        }
      },
      //TODO: add an option to turn these off or back to default if perfomance suffers
      //  scrolling: { mode: "virtual", columnRenderingMode: "virtual", rowRenderingMode: "virtual", showScrollbar: "always", preloadEnabled: false },
      //focusedRowEnabled: true,
      //focusedRowIndex: 0,
      onAdaptiveDetailRowPreparing(e) {
        e.formOptions.items.forEach(v => {
          if (!v.column.cellTemplate)
            v.column.cellTemplate = (el: JQuery, d) => {
              if (!d.value) {
                el.parents()[2].remove();
              } else {
                el.text(d.text ? d.text : d.value);
              }
            };
        });
      },
      onEditorPrepared: function (options: any) {
        if (
          options.parentType === "filterRow" &&
          (options.editorName === "dxTextBox" ||
            options.editorName === "dxNumberBox")
        ) {
          let editor;
          if (options.editorName === "dxTextBox") {
            editor = options.editorElement.dxTextBox("instance");
          } else {
            editor = options.editorElement.dxNumberBox("instance");
          }

          editor.option("onEnterKey", function (e) {
            let op: any[];
            if (
              options.editorElement &&
              options.editorElement.parent()[0] &&
              options.editorElement.parent()[0].previousElementSibling
            )
              op = $.grep(
                $(options.editorElement.parent()[0].previousElementSibling)
                  .dxMenu("instance")
                  .option("items")[0].items,
                function (v: any, k) {
                  return v.selected;
                }
              );
            let operation = "contains";
            if (op && op.length) operation = op[0].name;

            setTimeout(() => {
              let filter = options.defaultCalculateFilterExpression(
                null,
                operation
              );
              if (typeof theGrid._dataService.RequestArgs !== "undefined") {
                //let t = (theGrid.Instance as DevExpress.ui.dxDataGrid).getCombinedFilter();
                FilterBuilderHelper
                  .getFilterText(
                    (theGrid.Instance as DevExpress.ui.dxDataGrid).option(
                      "filterValue"
                    ),
                    [
                      NonOf(theGrid._ColumnHash, theGrid._view),
                      AnyOf(theGrid._ColumnHash, theGrid._view)
                    ],
                    (theGrid.Instance as DevExpress.ui.dxDataGrid)
                      .getVisibleColumns()
                      .filter(x => {
                        return x.dataField;
                      })
                  )
                  .done(filter => {
                    theGrid._dataService.RequestArgs.filterExpression = filter;
                    theGrid.ReloadData();
                  });
              }
            }, 500);
          });
        }
      },
      columnAutoWidth: true,
      showRowLines: true,
      cellHintEnabled: true,
      columnResizingMode: "widget",
      showBorders: true,
      cacheEnabled: true,
      allowColumnResizing: true, //!GetDevice().isDevice
      columnFixing: { enabled: !GetDevice().isDevice },
      grouping: { autoExpandAll: false, contextMenuEnabled: true },
      groupPanel: { visible: true, allowColumnDragging: true },
      filterBuilder: {
        visible: true
      },
      rowAlternationEnabled: true,
      sorting: { mode: "multiple" },
      filterPanel: {
        visible: true
      },
      export: {
        enabled: theGrid.ViewLayout ? theGrid.ViewLayout.CanExport : false,
        allowExportSelectedData: true
      },

      paging: {
        enabled: true,
        pageSize: parseInt(
          Preferences.GetPreference("ClientGridPageSize", "TDFMobile", "100")
        )
      },
      pager: {
        allowedPageSizes: [20, 50, 100, 200],
        showInfo: true,
        showNavigationButtons: true,
        showPageSizeSelector: true,
        visible: true /*infoText: theGrid.BuildPagingInfo*/
      },
      filterRow: {
        visible: true,
        showOperationChooser: true
      },
      //summary: {}
      onContextMenuPreparing(e: any) {
        if (e.row && e.row.rowType === "data") {
          e.event.preventDefault();
          e.event.stopPropagation();

          theGrid.ContextMenu(e.event.target, e.row.data);
          return;
        } else {
          if (e.row && e.row.rowType === "header") {
            if (e.items) {
              let newItems = [
                {
                  text: "Column Chooser",
                  icon: "dx-icon dx-icon-column-chooser",
                  beginGroup: true,
                  onItemClick(ev) {
                    (e.component as DevExpress.ui.dxDataGrid).showColumnChooser();
                  }
                },
                {
                  text: "Hide This Column",

                  onItemClick(ev) {
                    (e.component as DevExpress.ui.dxDataGrid).columnOption(
                      e.column.dataField,
                      "visible",
                      false
                    );
                  }
                },
                {
                  text: "Toggle Group By Box",
                  // icon: "fa fa-eye",
                  beginGroup: true,
                  onItemClick(ev) {
                    (e.component as DevExpress.ui.dxDataGrid).option(
                      "groupPanel.visible",
                      !(e.component as DevExpress.ui.dxDataGrid).option(
                        "groupPanel.visible"
                      )
                    );
                  }
                },
                {
                  text: "Toggle Virtual Scrolling",
                  // icon: "fa fa-toggle-on",
                  //  beginGroup: true,
                  onItemClick(ev) {
                    (e.component as DevExpress.ui.dxDataGrid).option(
                      "scrolling.mode",
                      (e.component as DevExpress.ui.dxDataGrid).option(
                        "scrolling.mode"
                      ) === "virtual"
                        ? "standard"
                        : "virtual"
                    );
                    //  (e.component as DevExpress.ui.dxDataGrid).option("scrolling.rowRenderingMode", (e.component as DevExpress.ui.dxDataGrid).option("scrolling.mode"));
                  }
                },
                {
                  text: "Toggle Filter Panel",
                  // icon: "dx-icon dx-icon-filter",
                  // beginGroup: true,
                  onItemClick(ev) {
                    (e.component as DevExpress.ui.dxDataGrid).option(
                      "filterPanel",
                      {
                        visible: !(e.component as DevExpress.ui.dxDataGrid).option(
                          "filterPanel.visible"
                        )
                      }
                    );
                  }
                },
                {
                  text: "Toggle Summary Panel",
                  // icon: "dx-icon dx-icon-filter",
                  //  beginGroup: true,
                  onItemClick(ev) {
                    //let footer = (e.component as DevExpress.ui.dxDataGrid).element().find(".dx-datagrid-total-footer");

                    theGrid.toggleSummaryPanel();
                  }
                }

                //{
                //    text: "Summary",
                //    // icon: "dx-icon dx-icon-filter",
                //    //  beginGroup: true,
                //    onItemClick(ev) {
                //        let footer = (e.component as DevExpress.ui.dxDataGrid).element().find(".dx-datagrid-total-footer");

                //        if (footer && footer.length) {
                //            footer.hasClass("hidden") ? footer.removeClass("hidden") : footer.addClass("hidden")
                //        }

                //    }
                //},
              ];

              e.items = e.items.concat(newItems);
              //Original Context Menu Button Overrides
              e.items = e.items.map(a => {
                switch (a.text) {
                  case "Clear Sorting":
                    let origClearSort: Function = a.onItemClick;
                    a.onItemClick = (ev) => {
                      origClearSort(ev);

                      let isInArgSorts = false;

                      if (theGrid._dataService.RequestArgs.Sorts) {
                        isInArgSorts = theGrid._dataService.RequestArgs.Sorts.filter((a) => {
                          return a.Field === e.column.dataField;
                        }).length > 0;
                      }

                      if (isInArgSorts) {

                        theGrid._dataService.RequestArgs.Sorts = theGrid._dataService.RequestArgs.Sorts.filter((a) => {
                          return a.Field !== e.column.dataField;
                        });

                        if (theGrid.ViewLayout.GridViewModel.SortList) {
                          let isInViewSorts = theGrid.ViewLayout.GridViewModel.SortList.filter((a) => {
                            return a.Field === e.column.dataField;
                          }).length > 0;

                          if (isInViewSorts) {
                            if (!theGrid._dataService.RequestArgs.IgnoredSorts) {
                              theGrid._dataService.RequestArgs.IgnoredSorts = [e.column.dataField];
                            } else {
                              if (theGrid._dataService.RequestArgs.IgnoredSorts.indexOf(e.column.dataField) === -1) {
                                theGrid._dataService.RequestArgs.IgnoredSorts.push(e.column.dataField);
                              }
                            }
                          }
                        }

                      } else {
                        if (!theGrid._dataService.RequestArgs.IgnoredSorts) {
                          theGrid._dataService.RequestArgs.IgnoredSorts = [e.column.dataField];
                        } else {
                          if (theGrid._dataService.RequestArgs.IgnoredSorts.indexOf(e.column.dataField) === -1) {
                            theGrid._dataService.RequestArgs.IgnoredSorts.push(e.column.dataField);
                          }
                        }
                      }

                      theGrid.ReloadData(() => {
                        theGrid.DoPostLoad();
                      });
                    }
                    break;

                  case "Sort Ascending":
                    a.onItemClick = ev => {
                      theGrid.AddOrUpdateSort([e.column], "asc");
                    };
                    break;

                  case "Sort Descending":
                    a.onItemClick = ev => {
                      theGrid.AddOrUpdateSort([e.column], "desc");
                    };
                    break;
                }

                return a;
              });
            }
          } else {
            if (e.target === "headerPanel") {
              e.items = [
                {
                  text: "Full Expand",
                  icon: "fa fa-expand",

                  beginGroup: true,
                  onItemClick(ev) {
                    (e.component as DevExpress.ui.dxDataGrid).expandAll();
                  }
                },
                {
                  text: "Full Collapse",
                  icon: "fa fa-compress",
                  onItemClick(ev) {
                    (e.component as DevExpress.ui.dxDataGrid).collapseAll();
                  }
                },
                {
                  text: "Clear Grouping",
                  icon: "fa fa-undo",
                  beginGroup: true,
                  onItemClick(ev) {
                    (e.component as DevExpress.ui.dxDataGrid).clearGrouping();
                  }
                },
                {
                  text: "Toggle Group By Box",
                  icon: "fa fa-eye",

                  onItemClick(ev) {
                    (e.component as DevExpress.ui.dxDataGrid).option(
                      "groupPanel.visible",
                      !(e.component as DevExpress.ui.dxDataGrid).option(
                        "groupPanel.visible"
                      )
                    );
                  }
                }
              ];
            }
            if (e.row && e.row.rowType === "group") {
              /*This was a test ......*/
              //e.items.push({
              //  text: "Summary",
              //  icon: "sum",
              //  beginGroup: true,
              //  onItemClick(ev) {
              //    e.component.beginUpdate();
              //    (e.component as DevExpress.ui.dxDataGrid).option("summary.groupItems", [{
              //      column: e.column.dataField,
              //      summaryType: 'count',
              //      showInGroupFooter: true,
              //      valueFormat: "fixedPoint",
              //      displayFormat: "Total: {0}",
              //      showInColumn: "AccountName",
              //      skipEmptyValues: true
              //    }])
              //    e.component.endUpdate();
              //  }
              //})
            }

            if (e.row && e.row.rowType === "totalFooter") {
              /*
                 'billions'
                 'currency'
                 'day'
                 'decimal'
                 'exponential'
                 'fixedPoint'
                 'largeNumber'
                 'longDate'
                 'longTime'
                 'millions'
                 'millisecond'
                 'month'
                 'monthAndDay'
                 'monthAndYear'
                 'percent'
                 'quarter'
                 'quarterAndYear'
                 'shortDate'
                 'shortTime'
                 'thousands'
                 'trillions'
                 'year'
                 'dayOfWeek'
                 'hour'
                 'longDateLongTime'
                 'minute'
                 'second'
                 'shortDateShortTime'
                 * */
              let currentTotalItems = (e.component as DevExpress.ui.dxDataGrid).option(
                "summary.totalItems"
              );
              let summariesForCurrentCol = [];
              let currentSummTypes = [];
              let anySumTypes = ["min", "max", "count", "none"];
              let allSumTypes = ["sum", "avg"].concat(anySumTypes);

              if (currentTotalItems && currentTotalItems.length) {
                currentTotalItems.forEach(sum => {
                  if (sum.column === e.column.dataField) {
                    summariesForCurrentCol.push(sum);
                    currentSummTypes.push(sum.summaryType);
                  }
                });
              }

              if (e.column && e.column.dataType) {
                let summaryToolbarItems = {
                  sum: {
                    text: "Sum",
                    icon: "formula",
                    beginGroup: true,
                    onItemClick(ev) {
                      if (currentSummTypes.indexOf("sum") >= 0) return;
                      e.component.beginUpdate();
                      let sSumm = {
                        column: e.column.dataField,
                        summaryType: "sum",
                        valueFormat: $.isPlainObject(e.column.format)
                          ? e.column.format
                          : typeof e.column.format === "string"
                            ? e.column.format
                            : "fixedPoint",
                        displayFormat: "Sum: {0}",
                        showInColumn: e.column.dataField,
                        skipEmptyValues: true
                      };
                      if (currentTotalItems.length > 0) {
                        (e.component as DevExpress.ui.dxDataGrid).option(
                          "summary.totalItems",
                          (e.component as DevExpress.ui.dxDataGrid)
                            .option("summary.totalItems")
                            .concat(sSumm)
                        );
                      } else {
                        (e.component as DevExpress.ui.dxDataGrid).option(
                          "summary.totalItems",
                          [sSumm]
                        );
                      }
                      e.component.endUpdate();
                    }
                  },
                  avg: {
                    text: "Average",
                    icon: "avg",
                    beginGroup: true,
                    onItemClick(ev) {
                      if (currentSummTypes.indexOf("avg") >= 0) return;
                      e.component.beginUpdate();
                      let aSumm = {
                        column: e.column.dataField,
                        summaryType: "avg",
                        ogFormat:
                          ($.isPlainObject(e.column.format) as any).type ===
                            "currency"
                            ? "Avg: {0:C}"
                            : "",
                        valueFormat: $.isPlainObject(e.column.format)
                          ? e.column.format
                          : typeof e.column.format === "string"
                            ? e.column.format
                            : "fixedPoint",
                        displayFormat: "Avg: {0}",
                        showInColumn: e.column.dataField,
                        skipEmptyValues: true
                      };
                      if (currentTotalItems.length > 0) {
                        (e.component as DevExpress.ui.dxDataGrid).option(
                          "summary.totalItems",
                          (e.component as DevExpress.ui.dxDataGrid)
                            .option("summary.totalItems")
                            .concat(aSumm)
                        );
                      } else {
                        (e.component as DevExpress.ui.dxDataGrid).option(
                          "summary.totalItems",
                          [aSumm]
                        );
                      }
                      e.component.endUpdate();
                    }
                  },
                  min: {
                    text: "Min",
                    icon: "less",
                    beginGroup: true,
                    onItemClick(ev) {
                      if (currentSummTypes.indexOf("min") >= 0) return;
                      e.component.beginUpdate();
                      let minSumm = {
                        column: e.column.dataField,
                        summaryType: "min",
                        valueFormat: $.isPlainObject(e.column.format)
                          ? e.column.format
                          : typeof e.column.format === "string"
                            ? e.column.format
                            : "fixedPoint",
                        displayFormat: "Min: {0}",
                        showInColumn: e.column.dataField,
                        skipEmptyValues: true
                      };
                      if (currentTotalItems.length > 0) {
                        (e.component as DevExpress.ui.dxDataGrid).option(
                          "summary.totalItems",
                          (e.component as DevExpress.ui.dxDataGrid)
                            .option("summary.totalItems")
                            .concat(minSumm)
                        );
                      } else {
                        (e.component as DevExpress.ui.dxDataGrid).option(
                          "summary.totalItems",
                          [minSumm]
                        );
                      }
                      e.component.endUpdate();
                    }
                  },
                  max: {
                    text: "Max",
                    icon: "greater",

                    beginGroup: true,
                    onItemClick(ev) {
                      if (currentSummTypes.indexOf("max") >= 0) return;
                      e.component.beginUpdate();
                      let maxSumm = {
                        column: e.column.dataField,
                        summaryType: "max",
                        valueFormat: $.isPlainObject(e.column.format)
                          ? e.column.format
                          : typeof e.column.format === "string"
                            ? e.column.format
                            : "fixedPoint",
                        displayFormat: "Max: {0}",
                        showInColumn: e.column.dataField,
                        skipEmptyValues: true
                      };
                      if (currentTotalItems.length > 0) {
                        (e.component as DevExpress.ui.dxDataGrid).option(
                          "summary.totalItems",
                          (e.component as DevExpress.ui.dxDataGrid)
                            .option("summary.totalItems")
                            .concat(maxSumm)
                        );
                      } else {
                        (e.component as DevExpress.ui.dxDataGrid).option(
                          "summary.totalItems",
                          [maxSumm]
                        );
                      }
                      e.component.endUpdate();
                    }
                  },
                  count: {
                    text: "Count",
                    icon: "fa fa-hashtag",

                    beginGroup: true,
                    onItemClick(ev) {
                      if (currentSummTypes.indexOf("count") >= 0) return;
                      e.component.beginUpdate();
                      let cSumm = {
                        column: e.column.dataField,
                        summaryType: "count",
                        valueFormat: "fixedPoint", //$.isPlainObject(e.column.format) ? (e.column.format as any).type : typeof e.column.format === "string" ? e.column.format : "fixedPoint",
                        displayFormat: "Count: {0}",
                        showInColumn: e.column.dataField,
                        skipEmptyValues: true
                      };

                      if (currentTotalItems.length > 0) {
                        (e.component as DevExpress.ui.dxDataGrid).option(
                          "summary.totalItems",
                          (e.component as DevExpress.ui.dxDataGrid)
                            .option("summary.totalItems")
                            .concat(cSumm)
                        );
                      } else {
                        (e.component as DevExpress.ui.dxDataGrid).option(
                          "summary.totalItems",
                          [cSumm]
                        );
                      }
                      e.component.endUpdate();
                    }
                  },
                  none: {
                    text: "None",
                    icon: "remove",
                    beginGroup: true,
                    onItemClick(ev) {
                      let newList;
                      if (
                        currentTotalItems &&
                        currentTotalItems.length &&
                        summariesForCurrentCol.length
                      ) {
                        newList = (currentTotalItems as any[]).reduce(
                          (thisList: any[], current) => {
                            if (current.column !== e.column.dataField) {
                              thisList.push(current);
                            }
                            return thisList;
                          },
                          []
                        );
                      } else {
                        return;
                      }
                      e.component.beginUpdate();
                      (e.component as DevExpress.ui.dxDataGrid).option(
                        "summary.totalItems",
                        newList
                      );
                      e.component.endUpdate();
                    }
                  }
                };

                if (e.column.dataType === "number") {
                  e.items = allSumTypes
                    .filter(v => {
                      return currentSummTypes.indexOf(v) < 0;
                    })
                    .map(v => {
                      return summaryToolbarItems[v];
                    });
                } else {
                  if (
                    e.column.dataType !== "boolean" &&
                    e.column.dataType !== "object"
                  ) {
                    e.items = anySumTypes
                      .filter(v => {
                        return currentSummTypes.indexOf(v) < 0;
                      })
                      .map(v => {
                        return summaryToolbarItems[v];
                      });
                  }
                }
              }
            }
          }
        }
      },
      columnHidingEnabled: false,
      headerFilter: { allowSearch: true, visible: true },
      loadPanel: {
        enabled: true,
        text: "Rendering Data"
      },
      //onOptionChanged(e) {
      //  console.log(e);

      //},
      stateStoring: {
        enabled: true,
        type: "custom",
        savingTimeout: 500,
        customSave: function (state) {
          let needsRefreshed = false;
          //when a column is added to the grid from the colomn chooser it is now in the current grids visible fields
          if (theGrid && theGrid.Instance) {
            (theGrid.Instance as DevExpress.ui.dxDataGrid)
              .getVisibleColumns()
              .forEach(v => {
                if (theGrid._ColumnHash[v.dataField]) {
                  // if the current field exists in the column hash as a non visible field then this is the one that was added
                  if (theGrid._ColumnHash[v.dataField].visible !== v.visible && theGrid._dataService) {
                    theGrid._ColumnHash[v.dataField].visible = true;
                    //when this gets sent back in the extra fileds it needs to be the actual field with : or .
                    let thisField = v.dataField
                      .replace(PeriodReplacementGUID, ".")
                      .replace(ColonReplacementGUID, ":");
                    if (theGrid._dataService.RequestArgs.extraFields) {
                      if (
                        $.isArray(theGrid._dataService.RequestArgs.extraFields)
                      ) {
                        //if the field added does not already exist in the extra fields
                        if (
                          !theGrid._dataService.RequestArgs.extraFields.some(
                            field => {
                              return field.dataField === thisField;
                            }
                          )
                        ) {
                          theGrid._dataService.RequestArgs.extraFields.push({
                            dataField: thisField,
                            visibleIndex: v.visibleIndex
                          });
                          needsRefreshed = true;
                        }
                      } else {
                        theGrid._dataService.RequestArgs.extraFields = [
                          { dataField: thisField, visibleIndex: v.visibleIndex }
                        ];
                        needsRefreshed = true;
                      }
                    } else {
                      theGrid._dataService.RequestArgs.extraFields = [
                        { dataField: thisField, visibleIndex: v.visibleIndex }
                      ];
                      needsRefreshed = true;
                    }
                  }
                }
              });
            if (needsRefreshed) theGrid.RefreshGrid(null, null);
          }
        }
      },

      height: "99.9%",
      width: "100%",
      columnMinWidth: parseInt(Preferences.GetCompanyPreference("WebGridMinWidth", "TDFGrid", "48")),
      selection: {
        mode: "multiple",
        showCheckBoxesMode: "always",
        allowSelectAll: true,
        deferred: false
      },
      onContentReady: (e) => {
        $("body")
          .remove(".server-page-menu")
          .append($("<div class='server-page-menu'/>"));

        e.component.option("noDataText", "Loading Data");

        if (
          theGrid._dataService &&
          theGrid._dataService.RequestModel &&
          theGrid._dataService.ResponseFromServer
        ) {
          let pageSizes = e.element.find(".dx-page-sizes");

          $(pageSizes).on("dxclick", function (e) {
            Preferences.SetPreference(
              "ClientGridPageSize",
              $(e.target).text(),
              "TDFMobile"
            );
          });

          theGrid.OverridePagingButtons();

          $(e.element)
            .find(".dx-header-row .dx-datagrid-action")
            .off("dxclick")
            .on("dxclick", function (event) {
              //  if (theCenter.ServerSort) {
              event.stopPropagation();

              let cname = $(this)
                .find(".dx-datagrid-text-content")
                .text();
              if (!cname) {
                return;
              }
              let column = theGrid.getColumn(cname);

              theGrid.AddOrUpdateSort(column);
            });

          if (e.component && e.element) {
            e.element
              .find(".dx-datagrid-filter-row")
              .find("input.dx-texteditor-input")
              .on(
                "focus",
                Debounce(
                  e => {
                    if (
                      Preferences.GetPreference(
                        "ShowGridFilterEnterKeyNotification",
                        "TDFMobile"
                      ) !== "0" &&
                      !window["PressEnterForFilterNotificationDismissed"]
                    ) {
                      let message: any = {};
                      message.text = $("<span />").text(
                        "After typing the desired filter press the enter key to filter the entire set. "
                      );
                      message.buttons = $("<div />")
                        .append(
                          $("<span />")
                            .css("margin", "0 5px")
                            .append(
                              $("<div />").dxButton({
                                text: "Dismiss for now",
                                icon: "close",
                                type: "success",
                                onClick: function (e: any) {
                                  let toast = $($(e.element[0]).parents())
                                    .find(
                                      ".dx-overlay.dx-widget.dx-visibility-change-handler.dx-toast"
                                    )
                                    .dxToast("instance");
                                  toast.hide();
                                  window[
                                    "PressEnterForFilterNotificationDismissed"
                                  ] = true;
                                  return;
                                }
                              })
                            )
                        )
                        .append(
                          $("<span />")
                            .css("margin", "0 5px")
                            .append(
                              $("<div />").dxButton({
                                text: "Dismiss for good",
                                icon: "remove",
                                type: "danger",
                                onClick: function (e: any) {
                                  let toast = $($(e.element[0]).parents())
                                    .find(
                                      ".dx-overlay.dx-widget.dx-visibility-change-handler.dx-toast"
                                    )
                                    .dxToast("instance");
                                  toast.hide();
                                  Preferences.SetPreference(
                                    "ShowGridFilterEnterKeyNotification",
                                    "0",
                                    "TDFMobile"
                                  );
                                }
                              })
                            )
                        );
                      new Notification({
                        type: "info",
                        shading: false,
                        displayTime: 10000,
                        message: message,
                        position: { at: "bottom" },
                        animation: {
                          show: {
                            type: "slide",
                            duration: 500,
                            from: { top: 0 }
                          }
                        }
                      });
                    }
                  },
                  60000,
                  true
                )
              );
          }

          theGrid.SetPageInfoText();
          e.component
            .element()
            .find(".dx-pages")
            .find(".dx-info")
            .attr("title", "Click for explanation.")
            .on("click", () => {
              theGrid.ShowPagingExplanation();
            });
        }
      }
    };

    options.onToolbarPreparing = function (e) {
      e.toolbarOptions.items.push({
        location: "after",
        widget: "dxButton",
        options: <DevExpress.ui.dxButtonOptions>{
          icon: "preferences",
          text: "Manage Grid",
          hint: "Right Click For More Options",
          visible: !(GetDevice().device.deviceType === "phone"),
          elementAttr: { class: "opengridmanager" },
          onClick: function (clickev) {
            new GridManager(theGrid._view, theGrid.Instance, theGrid.EventLimiterName);
          }
        }
      });
      e.toolbarOptions.items.push({
        location: "after",
        widget: "dxButton",
        options: <DevExpress.ui.dxButtonOptions>{
          icon: "save",
          text: "",
          hint: "Save the current grid state.",

          elementAttr: { class: "btngridsavestate" },
          onClick: function (clickev) {
            let gm = new GridManager(
              theGrid._view,
              theGrid.Instance,
              theGrid.EventLimiterName,
              null,
              false
            );
            $.when(gm.ServiceLoaded).done(() => {
              gm.SaveTheState(
                (theGrid.Instance as DevExpress.ui.dxDataGrid).state()
              );
            });

            //let saveIt = (state) => {
            //  //state.columns.forEach((v) => {
            //  //  v.dataField = v.dataField.replace(Util.ColonReplacementGUID, ":").replace(Util.PeriodReplacementGUID, ".")
            //  //});
            //  //if (theGrid.Instance.option("summary").totalItems) {

            //  //  let summaries = theGrid.Instance.option("summary").totalItems;

            //  //  let saveValue = [];
            //  //  summaries.forEach((v, k) => {
            //  //    if (v.summaryType && v.summaryType.search(/custom/i) < 0) {
            //  //      saveValue.push({
            //  //        DisplayFormat: v.displayFormat,
            //  //        SummaryType: v.summaryType.replace("avg", "average"),
            //  //        FieldName: v.column.replace(Util.ColonReplacementGUID, ":").replace(Util.PeriodReplacementGUID, "."),
            //  //        columnFormat: v.valueFormat,
            //  //        Value: null
            //  //      })
            //  //    }
            //  //  });
            //  //  state["TotalSummaries"] = saveValue;

            //  let request = new TDF.TDFRequest({
            //    url: RequestBuilder.GridDesign.BuildURL(theGrid._view.GUID, RequestBuilder.Enums.GridDesign.SaveState),
            //    type: "POST",
            //    data: state
            //  });
            //  request.MakeRequest();
            //}

            //let state = (theGrid.Instance as DevExpress.ui.dxDataGrid).state();
            //if (state.filterValue) {
            //  fbsg.getFilterText(state.filterValue,
            //    [Util.NonOf(theGrid._ColumnHash, theGrid._view), Util.AnyOf(theGrid._ColumnHash, theGrid._view)],
            //    (theGrid.Instance as DevExpress.ui.dxDataGrid).getVisibleColumns().filter((x) => { return x.dataField }), "SQL").done((filterText) => {
            //      filterText = filterText.replace(Util.ColonReplacementGUID, ":").replace(Util.PeriodReplacementGUID, ",");
            //      state.filterValue = filterText;
            //      saveIt(state);
            //    })
            //} else {
            //  state.filterValue = "Delete";
            //  saveIt(state);
            //}
          }
        }
      });

      $(".grid-manager-menu-sections").remove();
      $("#tdfbody").append("<div class='grid-manager-menu-sections'/>");
      let timer;

      timer = setInterval(() => {
        let it = $(".opengridmanager");
        if (it.length) {
          let thatmenu = $(".grid-manager-menu-sections")
            .dxContextMenu({
              target: it.parent(),
              items: GridManager.GetSections(theGrid._view.ItemType).map((v, k) => {
                return /* <DevExpress.ui.dxContextMenuItemTemplate>*/ {
                  text: v.Name,
                  visible: v.visible,
                  onItemClick() {
                    new GridManager(theGrid._view, theGrid.Instance, theGrid.EventLimiterName, v.index);
                  }
                };
              }),
              onItemClick(e) {
                e.itemData.onItemClick();
              }
            })
            .dxContextMenu("instance");
          clearInterval(timer);
        }
      }, 1);
    };
    return options;
  }
  ///**
  // * Use this to set you own [Override] options for the data grid... these must be complete options
  // * **/
  //protected set dxOptions(val: DevExpress.ui.dxDataGridOptions) {
  //  if (val) this._dxOptions = val;
  //}

  /**
   * Make sure the sort isn't supposed to be ignored or is supposed to be overwritten
   * by a local sort on the same field.
   */
  private ShouldOverrideViewSort(col: DevExpress.ui.dxDataGridColumn): boolean {
    let theGrid = this;
    let viewsorts = theGrid.ViewLayout.GridViewModel.SortList;

    return (
      (
        (
          !theGrid._dataService.RequestArgs.IgnoredSorts
          ||
          (
            theGrid._dataService.RequestArgs.IgnoredSorts &&
            theGrid._dataService.RequestArgs.IgnoredSorts.indexOf(col.dataField) === -1
          )
        )
      ) &&
      (viewsorts && viewsorts.map((b) => { return b.Field }).indexOf(col.dataField) !== -1)
    )
  }

  private AddOrUpdateSort(
    column: DevExpress.ui.dxDataGridColumn[],
    direction?: "asc" | "desc"
  ) {
    let theGrid = this;

    let sorts = theGrid._dataService.RequestArgs.Sorts;
    let viewsorts = theGrid.ViewLayout.GridViewModel.SortList;
    if (!viewsorts) {
      viewsorts = [];
    }
    if (sorts && sorts.length) {
      let found = false;
      let newestSort;
      let idxToUpdate: number;

      sorts.forEach((val, idx) => {
        if (val.Field === column[0].dataField) {
          found = true;

          if (direction) {
            val.Direction = direction;
          } else {
            switch (val.Direction) {
              case "asc":
                val.Direction = "desc";
                break;
              case "desc":
                val.Direction = "asc";
                break;
            }
          }
          newestSort = sorts[idx];

          idxToUpdate = idx;

          if (theGrid.ShouldOverrideViewSort(column[0])) {
            //Do nothing

          } else {

            let highestOrder = 0;
            for (let i = 0, length = sorts.length; i < length; i++) {
              if (sorts[i].Order > highestOrder) {
                highestOrder = sorts[i].Order;
              }
            }

            if (newestSort.Order !== highestOrder) {
              newestSort.Order = highestOrder + 1;
            }
          }
          // sorts.push(newestSort);
        }
      });

      if (idxToUpdate > -1 && newestSort) {
        sorts[idxToUpdate] = newestSort;
      }

      if (!found) {
        let highestOrder = 0;

        let orderSetByView = false; //True: Find the next available order value. False: Use the view's sort order for this field.

        if (theGrid.ShouldOverrideViewSort(column[0])) {
          orderSetByView = true;

        } else {
          for (let i = 0, length = sorts.length; i < length; i++) {
            if (sorts[i].Order > highestOrder) {
              highestOrder = sorts[i].Order;
            }
          }
        }

        sorts.push({
          Field: column[0].dataField,
          Direction: direction || "asc",
          Order: orderSetByView ?
            viewsorts.filter((a) => { return a.Field === column[0].dataField })[0].Order :
            highestOrder + 1
        });
      }

    } else {
      let idxOfMatchingField: number;
      let highestOrder: number = 0;
      if (
        (
          (
            theGrid._dataService.RequestArgs.IgnoredSorts &&
            theGrid._dataService.RequestArgs.IgnoredSorts.indexOf(column[0].dataField) === -1
          )
        ) &&
        (viewsorts && viewsorts.map((b) => { return b.Field }).indexOf(column[0].dataField) !== -1)
      ) {
        if (viewsorts) {
          for (let i = 0, length = viewsorts.length; i < length; i++) {
            if (viewsorts[i].Order > highestOrder) {
              highestOrder = viewsorts[i].Order;
            }
          }
        }
      } else {
        idxOfMatchingField = viewsorts.map((a) => { return a.Field }).indexOf(column[0].dataField);
      }

      sorts = [{
        Field: column[0].dataField,
        Direction: direction ?
          direction :
          idxOfMatchingField !== undefined && idxOfMatchingField !== -1 ?
            viewsorts[idxOfMatchingField].Direction === 'asc' ?
              'desc' :
              'asc' :
            "asc",
        Order: idxOfMatchingField !== undefined && idxOfMatchingField !== -1 ?
          viewsorts[idxOfMatchingField].Order : highestOrder + 1
      }];
    }
    theGrid._dataService.RequestArgs.Sorts = sorts;
    theGrid.ReloadData(() => {
      theGrid.DoPostLoad();
    });
  }
  private SetSpecialPagingOps() {
    let theGrid = this;
    let pagesEl = theGrid.Instance.element().find(
      ".dx-datagrid-pager.dx-pager"
    );
    if (document.body.contains(pagesEl[0])) {
      if (
        theGrid._dataService.ResponseFromServer.Meta.TotalPages > 1 &&
        theGrid._dataService.ResponseFromServer.Meta.TotalRecords !== 9999999
      ) {
        let current = theGrid._dataService.ResponseFromServer.Meta.CurrentPage; //1
        // let remaining = theGrid._dataService.ResponseFromServer.Meta.TotalPages - theGrid._dataService.ResponseFromServer.Meta.CurrentPage; //13 of 14
        let items: DevExpress.ui.dxContextMenuItemTemplate[] = [];
        for (
          var i = 1;
          i <= theGrid._dataService.ResponseFromServer.Meta.TotalPages;
          i++
        ) {
          items.push({
            text: `${i}`,
            disabled: i === current
          });
        }
        $(".server-page-menu").dxContextMenu({
          target: pagesEl,
          items: [
            {
              text: "Jump to server page..",
              items: items,
              selectable: false
            }
          ],
          onItemClick(e) {
            if (
              e.itemData &&
              e.itemData.text &&
              e.itemData.text !== "Jump to server page.."
            ) {
              theGrid._dataService.RequestArgs.page = parseInt(e.itemData.text);
              theGrid.Instance.pageIndex(0);
              theGrid.RefreshGrid(null, null);
            }
          }
        });
      }
    }
  }

  private ShowPagingExplanation() {
    let theGrid = this;
    let form = $("<div />").dxForm({
      colCountByScreen: {
        lg: 1,
        md: 1,
        sm: 1,
        xs: 1
      },
      // width: 500,
      alignItemLabelsInAllGroups: true,
      items: [
        <DevExpress.ui.dxFormGroupItem>{
          itemType: 'group',
          caption:
            "Data is returned in pages in order to prevent unusually long load times in the browser. The following is a breakdown of how the data is currently diplayed.",
          items: [
            {
              itemType: "simple",
              label: {
                text: `${theGrid.PageInfoObject.TotalRecords}`
              },
              helpText:
                "This is the number of records that meet the criteria of the gridview. Only a subset of these are downloaded in the browser at any given time.",
              editorType: "dxTextBox",
              editorOptions: <DevExpress.ui.dxTextBoxOptions>{
                readOnly: true,
                value: "The total number of records in the current grid view.",
                stylingMode: "underlined"
              }
            },
            {
              itemType: "simple",
              label: {
                text: theGrid.PageInfoObject.TotalPages
              },
              helpText:
                "Taking the current server page size into account this is the total number of pages that meet the criteria of the gridview.",
              editorType: "dxTextBox",
              editorOptions: <DevExpress.ui.dxTextBoxOptions>{
                readOnly: true,
                value: "The total number of pages on server.",
                stylingMode: "underlined"
              }
            },
            {
              itemType: "simple",
              label: {
                text: theGrid._dataService.ResponseFromServer.Meta.RecordsOnPage
              },
              helpText:
                "This is the total amount of data rows that will be returned to the browser.",
              editorType: "dxTextBox",
              editorOptions: <DevExpress.ui.dxTextBoxOptions>{
                readOnly: true,
                value: "The current server page size.",
                stylingMode: "underlined"
              }
            },
            {
              itemType: "simple",
              label: { text: theGrid.PageInfoObject.AfterFilter },
              editorType: "dxTextBox",
              helpText:
                "Because there is a client side filter the number of visible data rows has been reduced to this amount. Removing the filter will cause all of the currently downloaded rows to be visible.",
              visible:
                typeof theGrid.PageInfoObject.AfterFilter !== "undefined",
              editorOptions: <DevExpress.ui.dxTextBoxOptions>{
                readOnly: true,
                value:
                  "The number of records after the client filter has been applied.",
                stylingMode: "underlined"
              }
            }
          ]
        }
      ]
    });

    new Dialog({
      body: $("<div />")
        .css("padding", "20px")
        .append(form),
      title: "Paging Information",
      closable: true,
      id: "pager-info",
      size: "size-medium",
      showHelpButton: true
    }).open();
  }

  private toggleSummaryPanel() {
    let theGrid = this;
    let sums = (theGrid.Instance as DevExpress.ui.dxDataGrid).option("summary");
    let footer: JQuery;
    if (sums && sums.totalItems && sums.totalItems.length) {
      footer = theGrid.Instance.element().find(".dx-datagrid-total-footer");
      if (footer && footer.length) {
        footer.hasClass("hidden")
          ? footer.removeClass("hidden")
          : footer.addClass("hidden");
      }
    } else {
      (theGrid.Instance as DevExpress.ui.dxDataGrid).option("summary", {
        totalItems: [
          {
            column: "",
            summaryType: "count",
            skipEmptyValues: false,
            customizeText(info) {
              return "";
            }
          }
        ]
      });
    }
  }

  /**
   * Render the context menu that contains the Actions menu , views menu , open button, and other relevant items.
   * @param element
   * @param rowdata
   */
  private ContextMenu(element, rowdata) {
    let theGrid = this;

    //if on a phone or mobile show floating buttons
    let info = GetActionRequestParamsFromRowData(rowdata);
    if (info && info.itemtype) {
      DisplayInfo(info.itemtype).done(function (displayInfo) {
        //TODO: figure this out when not in the namespace TDF
        if (!window[displayInfo.TypeName]) {
          if (!window[displayInfo.DisplayName]) {
            //return;
          }
        }
        let ContactEmail = "";
        let iMain: ItemMain;
        let menu: DevExpress.ui.dxContextMenu;
        if (!$("#tdfcontextmenu").length)
          $("body").append($("<div id='tdfcontextmenu' />"));

        let selected: any[] = theGrid.Instance.getSelectedRowsData();

        let contextItemAlreadySelected: boolean = false;
        $.each(selected, function (index, value) {
          if (value["TDF GUID"] === rowdata["TDF GUID"]) {
            contextItemAlreadySelected = true;
          }
        });
        // If we right clicked on a row that is already selected, then leave the selection alone.
        // If we right clicked anywhere else in the grid, clear the selection and operate on the row that we right clicked on.
        if (!contextItemAlreadySelected) {
          theGrid.Instance.clearSelection();
          selected = [];
          selected.push(rowdata);
        }

        menu = $("#tdfcontextmenu")
          .dxContextMenu(<DevExpress.ui.dxContextMenuOptions>{
            position: { at: "middle center" },
            target: element,
            displayExpr: "MenuText",
            onHidden(e) {
              e.component.dispose();
            },
            onContentReady(e) {
              let timer;
              timer = window.setInterval(() => {
                let container: JQuery = (e.component as any).itemsContainer();
                if (container && container.length) {
                  clearInterval(timer);
                  container.addClass("border-top border-left border-bottom");
                }
              }, 50);
            },
            dataSource: new dxDataSource({
              load: function (options) {
                let dfd = $.Deferred();
                let requestParams;
                // If the selected item is a reminder we want to use the parent's actions instead.
                if (rowdata.ItemType === itemTypes.itemReminder) {
                  requestParams = {
                    itemid: rowdata["ParentId"],
                    itemtype: parseInt(rowdata["LinkItemType"])
                  };
                } else {
                  requestParams = GetActionRequestParamsFromRowData(rowdata);
                }

                let request = new TDFRequest({
                  url: "/action/getactionsforitem/",
                  type: "GET",
                  data: requestParams
                });
                request.MakeRequest().done(function (response: any) {
                  iMain = new ItemMain(response.ActionsModel.iMain);

                  $.each(response.ActionsModel.ActionList, function (
                    key,
                    val: any
                  ) {
                    val.beginGroup = true;

                    if (val.ContactEmail) ContactEmail = val.ContactEmail;

                    if (val.MenuText === "Views") {
                      if (Preferences.GetCompanyPreference("ShowViewsBtn", "TDFMobile", "1") === "1") {
                        if (selected.length > 1 && val.MenuText === "Views") {
                          val.visible = false;
                        }
                      }
                      else {
                        //Hide the Views menu always for now.
                        val.visible = false;
                      }
                    }

                    if (
                      iMain.ItemType === itemTypes.itemProduct &&
                      (val.MenuText === "Actions" || val.MenuText === "Views")
                    ) {
                      val.visible = false;
                    }

                    if (val.items) {
                      // SherpaBI Stuff 
                      // val.items = val.items.filter((v) => { if ((v.Action != menuActions.menuGoToWebsite) && (v.Action != menuActions.menuLinkItem)) { return v; } });

                      $.each(val.items, function (submenuIndex, submenuValue) {
                        submenuValue.disabled =
                          selected.length > 1
                            ? !submenuValue.SupportsMultipleItems
                            : false;
                        submenuValue.visible =
                          selected.length > 1
                            ? submenuValue.SupportsMultipleItems
                            : true;

                        // TODO: This is a temporary fix to get 2018R1 out. Please remove once action has a web implementation.
                        if (submenuValue.MenuText === "Launch Web Access Log") {
                          submenuValue.visible = false;
                        }
                      });
                      let masterActions = GroupActions(
                        val.items,
                        GetDevice().isDevice,
                        true
                      ); // Want it to always group the actions for the right-click menu beacuase the menu doesn't cooperate well for height.

                      val.items = masterActions;
                    }
                  });

                  dfd.resolve(response.ActionsModel.ActionList);
                });
                return dfd.promise();
              }
            }),
            onItemClick: Debounce(function (data: any) {
              let itemInfo = rowdata;

              if (data.itemData.IsView) {
                if (data.itemData.Action) {
                  data.itemData.iteminfo = itemInfo;
                  let views = new MenuView(data.itemData);
                  menu.hide();
                }
              } else {
                if (data.itemData.Action) {
                  let selected: any[] = theGrid.Instance.getSelectedRowsData();

                  if (selected && selected.length === 0) {
                    selected = [];
                    selected.push(rowdata);
                  }

                  if (
                    data.itemData.Action === "Open" /*&& selected.length > 1*/
                  ) {
                    // OpenNavigator(selected, theGrid._view.ItemType);
                    RaiseEvent2(
                      EventTypes.CenterEventTypes.openitem,
                      theGrid.EventLimiterName,
                      eventNameSpace.request,
                      [selected]
                    );
                    return;
                  }

                  if (
                    data.itemData.Action === menuActions.menuNewEmail &&
                    ContactEmail
                  )
                    data.itemData.Email = ContactEmail;
                  let items: Array<IItemInfo | { LinkID: string }> = [];

                  if (
                    (itemInfo["TDF GUID"] ||
                      itemInfo["ItemID"] ||
                      itemInfo["TDFItemID"]) &&
                    (itemInfo["ItemType"] || itemInfo["TDFItemType"])
                  ) {
                    $.each(selected, function (index, rowData) {
                      items.push({
                        ItemId:
                          rowData["TDF GUID"] ||
                          rowData["ItemID"] ||
                          rowData["TDFItemID"],
                        ItemType:
                          parseInt(rowData["ItemType"]) ||
                          parseInt(rowData["TDFItemType"]),
                        iMain: iMain
                      });
                    });
                    /*TODO: Figure out if i need to make a new grid class for linked item grids*/
                    let action = new MenuAction(
                      data.itemData,
                      <IItemInfo[]>items
                    );
                  } else {
                    $.each(selected, function (index, rowData) {
                      items.push({
                        LinkID: rowData["LinkID"]
                      });
                    });
                    /*TODO: Figure out if i need to make a new grid class for linked item grids*/
                    let action = new MenuAction(data.itemData, <{
                      LinkID: string;
                    }[]>items);
                  }

                  menu.hide();
                }
              }
            }, 500),
            // This onItemRendered was causing some context menus to not be able to
            // show their submenus.

            // onItemRendered(e) {
            //   if (
            //     e.itemData.IsView ||
            //     (e.itemData.Action &&
            //       typeof e.itemData.Action === "number" &&
            //       e.itemData.GroupName !== "Other")
            //   ) {
            //     let menu = e.itemElement.parents(".dx-submenu").first();
            //     if (menu && menu.length && !menu.hasClass("dx-scrollview")) {
            //       menu.dxScrollView({ height: $(window).innerHeight() * 0.5 });
            //     }
            //   }
            // }
          })
          .dxContextMenu("instance");

        menu.show();
      });
    }
  }

  private OverridePagingButtons() {
    let grid = this;

    let pagesEl = (grid.Instance as DevExpress.ui.dxDataGrid)
      .element()
      .find(".dx-pages");
    let btnNextPage = pagesEl.find(".dx-next-button");
    let btnPrevPage = pagesEl.find(".dx-prev-button");
    if ($(btnNextPage).hasClass("dx-button-disable")) {
      if (grid._dataService && grid._dataService.GridViewMeta) {
        if (
          grid._dataService.RequestArgs &&
          grid._dataService.GridViewMeta.TotalPages
        ) {
          if (
            grid._dataService.RequestArgs.page <
            grid._dataService.GridViewMeta.TotalPages
          ) {
            btnNextPage.removeClass("dx-button-disable");
          }
        }
      }
    }
    if ($(btnPrevPage).hasClass("dx-button-disable")) {
      if (grid._dataService.RequestArgs.page > 1) {
        btnPrevPage.removeClass("dx-button-disable");
        if (
          grid._dataService.RequestArgs.page ===
          grid._dataService.GridViewMeta.TotalPages
        ) {
          //if this is the last server page there are situations where the pager gets messed up and the previous button does not get enabled properly
          window.setTimeout(() => {
            console.count("checking");
            let btn = (grid.Instance as DevExpress.ui.dxDataGrid)
              .element()
              .find(".dx-prev-button");
            if (
              btn.hasClass("dx-button-disable") &&
              grid._dataService.RequestArgs.page > 1
            ) {
              btn.removeClass("dx-button-disable");
            }
          }, 2000);
        }
      }
    }

    $(btnNextPage).off("click");
    $(btnNextPage).on("click", function (event) {
      if ($(event.currentTarget).hasClass("dx-button-disable")) return;
      if (grid.Instance.pageIndex() === grid.Instance.pageCount() - 1) {
        event.stopPropagation();
        grid._dataService.RequestArgs.page += 1;
        grid.Instance.pageIndex(0);
        grid.ReloadData();
      }
    });

    $(btnPrevPage).off("click");
    $(btnPrevPage).on("click", function (event) {
      if ($(event.currentTarget).hasClass("dx-button-disable")) return;
      if (grid.Instance.pageIndex() === 0) {
        if (grid._dataService.RequestArgs.page > 1) {
          grid._dataService.RequestArgs.page -= 1;
          //theCenter.RemoveGridFromCache(theCenter.CurrentSelectedItem.CurrentView);
          let newIndex =
            Math.ceil(
              grid._dataService.RequestArgs.pageSize / grid.Instance.pageSize()
            ) - 1;
          grid.Instance.pageIndex(newIndex);
          grid.ReloadData();
          //  theCenter.LoadGrid(theCenter.CurrentSelectedItem.CurrentView, e.element, false, theCenter.RequestModel.RequestArgs.page)
        }
      }
    });
  }

  /*
   protected DoPostLoad() {
    let grid = this;
    let instance = grid.Instance;
    let optionstoapplyoninstance = [];

    if (!grid.UseCards) {
      let sorts: ISortList[] = [];
      if (grid.ViewLayout && grid.ViewLayout.GridViewModel) {
        // if (grid._dataService.RequestArgs.Sorts) {
        //   //if there were sorts passed in the request
        //   if (grid.ViewLayout.GridViewModel.SortList) {
        //     // and sorts in the model
        //     grid._dataService.RequestArgs.Sorts.forEach(v => {
        //       //find a possible matching sort in the GridView Model
        //       // this would indicate the user has changed the direction of the saved sort
        //       let vsort = grid.ViewLayout.GridViewModel.SortList.filter(
        //         sort => {
        //           let regex = new RegExp(v.Field, 'i');
        //           return regex.test(sort.Field);
        //         }
        //       );
        //       //if a sort was found in the gridview model
        //       if (vsort && vsort.length) {
        //         let reg = new RegExp(vsort[0].Direction, 'i');
        //         // if the sort direction is the same
        //         if (reg.test(v.Direction)) {
        //           //do nothing the sort is in the same direction as when it was saved ... the saved sort will be concatenated later
        //         } else {
        //           // change the direction of the sort in the model ... this way it does not get added twice when the arrays are concatenated
        //           vsort[0].Direction = v.Direction;
        //         }
        //       } else {
        //         //the sort was not found in the gridview model so add it to the list

        //         sorts.push(v);
        //       }
        //     });
        //     sorts = sorts.concat(grid.ViewLayout.GridViewModel.SortList);

        //     sorts.forEach((v, key) => {
        //       optionstoapplyoninstance.push({
        //         type: 'columnOption',
        //         optionname: v.Field,
        //         args: {
        //           sortIndex: v.Order,
        //           sortOrder: v.Direction.toLowerCase()
        //         }
        //       });

        //       // instance.columnOption(v.Field, {
        //       //   sortIndex: v.Order,
        //       //   sortOrder: v.Direction.toLowerCase()
        //       // });
        //     });
        //   } else {
        //     // there were sorts in the request but none in the model just apply the request sorts
        //     grid._dataService.RequestArgs.Sorts.forEach((v, k) => {
        //       optionstoapplyoninstance.push({
        //         type: 'columnOption',
        //         optionname: v.Field,
        //         args: {
        //           sortIndex: v.Order,
        //           sortOrder: v.Direction.toLowerCase()
        //         }
        //       });
        //       // instance.columnOption(v.Field, {
        //       //   sortIndex: v.Order,
        //       //   sortOrder: v.Direction.toLowerCase()
        //       // });
        //     });
        //   }
        // } else {
        //   // there were no sorts in the request .. see if there are any sorts in the model
        //   if (grid.ViewLayout.GridViewModel.SortList) {
        //     grid.ViewLayout.GridViewModel.SortList.forEach((v, k) => {
        //       optionstoapplyoninstance.push({
        //         type: 'columnOption',
        //         optionname: v.Field,
        //         args: {
        //           sortIndex: v.Order,
        //           sortOrder: v.Direction.toLowerCase()
        //         }
        //       });
        //       // instance.columnOption(v.Field, {
        //       //   sortIndex: v.Order,
        //       //   sortOrder: v.Direction.toLowerCase()
        //       // });
        //     });
        //   }
        // }
        //if there are groups then addem
        if (grid.ViewLayout.GridViewModel.GroupList) {
          grid.ViewLayout.GridViewModel.GroupList.forEach((v, key) => {
            optionstoapplyoninstance.push({
              type: 'columnOption',
              optionname: v.Field,
              args: {
                groupIndex: v.Order,
                showWhenGrouped: false ///*visible:true
              }
            });
            // instance.columnOption(v.Field, {
            //   groupIndex: v.Order,
            //   showWhenGrouped: false ///*visible:true
            // });
          });
        }
      }
    }
    //let infoText = grid.BuildPagingInfo;

    if (!instance.option('dataSource'))
      optionstoapplyoninstance.push({
        type: 'option',
        optionname: 'dataSource',
        args: this._dataService.DataSource
      });
    //instance.option('dataSource', this._dataService.DataSource);
    if (this._dataService.ResponseFromServer.ClientSideFilter) {
      let str = JSON.stringify(
        this._dataService.ResponseFromServer.ClientSideFilter
      );
      str = str
        .replace(/\.(?![0-9])/g, PeriodReplacementGUID)
        .replace(/:(?![0-9])/g, ColonReplacementGUID);
      let fixedFilter = JSON.parse(str);
      optionstoapplyoninstance.push({
        type: 'option',
        optionname: 'filterValue',
        args: fixedFilter
      });
      // instance.option('filterValue', fixedFilter);
    }

    // let gridel = this._container
    //   .children()
    //   .first()
    //   .detach();
    // let parent = this._container;

    // instance.beginUpdate();
    if (!GetDevice().isDevice) {
      this.Summaries(instance);
    }
    optionstoapplyoninstance.forEach(v => {
      instance[v.type](v.optionname, v.args);
    });

    // parent.append(gridel);
    // instance.endUpdate();
  }
  
  
  */

  protected DoPostLoad() {
    let grid = this;
    let instance = grid.Instance;
    let gridel = this._container
      .children()
      .first()
      .detach();
    instance.beginUpdate();
    let parent = this._container;
    if (!GetDevice().isDevice) {
      this.Summaries(instance);
    }
    if (!grid.UseCards) {
      let sorts: ISortList[] = [];
      if (grid.ViewLayout && grid.ViewLayout.GridViewModel) {
        if (grid._dataService.RequestArgs.Sorts.length > 0) {
          let sorts: ISortList[] = CloneIT(grid._dataService.RequestArgs.Sorts);

          //if there were sorts passed in the request
          if (grid.ViewLayout.GridViewModel.SortList) {
            // and sorts in the model
            grid._dataService.RequestArgs.Sorts.forEach(v => {
              //find a possible matching sort in the GridView Model
              // this would indicate the user has changed the direction of the saved sort
              let vsort = grid.ViewLayout.GridViewModel.SortList.filter(
                sort => {
                  let regex = new RegExp(v.Field, "i");
                  return regex.test(sort.Field);
                }
              );
              //if a sort was found in the gridview model
              if (vsort && vsort.length) {
                let reg = new RegExp(vsort[0].Direction, "i");
                // if the sort direction is the same
                if (reg.test(v.Direction)) {
                  //do nothing the sort is in the same direction as when it was saved ... the saved sort will be concatenated later
                } else {
                  // change the direction of the sort in the model ... this way it does not get added twice when the arrays are concatenated
                  vsort[0].Direction = v.Direction;
                }

                vsort[0].Order = v.Order;

              } else {
                //the sort was not found in the gridview model so add it to the list
                sorts.push(v);
              }
            });

            let viewSortList = grid.ViewLayout.GridViewModel.SortList.filter((a) => {
              if (
                (grid._dataService.RequestArgs.IgnoredSorts && grid._dataService.RequestArgs.IgnoredSorts.indexOf(a.Field) !== -1) ||
                grid._dataService.RequestArgs.Sorts.map((b) => { return b.Field }).indexOf(a.Field) !== -1
              ) {

                return false;
              } else {
                return true;
              }
            });

            sorts = sorts.concat(viewSortList);

            sorts.forEach((v, key) => {
              instance.columnOption(v.Field, {
                sortIndex: v.Order,
                sortOrder: v.Direction.toLowerCase()
              });
            });
          } else {
            // there were sorts in the request but none in the model just apply the request sorts
            grid._dataService.RequestArgs.Sorts.forEach((v, k) => {
              instance.columnOption(v.Field, {
                sortIndex: v.Order,
                sortOrder: v.Direction.toLowerCase()
              });
            });
          }
        } else {
          // there were no sorts in the request .. see if there are any sorts in the model
          if (grid.ViewLayout.GridViewModel.SortList) {
            grid.ViewLayout.GridViewModel.SortList.forEach((v, k) => {
              instance.columnOption(v.Field, {
                sortIndex: v.Order,
                sortOrder: v.Direction.toLowerCase()
              });
            });
          }
        }
        //if there are groups then addem
        if (grid.ViewLayout.GridViewModel.GroupList) {
          grid.ViewLayout.GridViewModel.GroupList.forEach((v, key) => {
            instance.columnOption(v.Field, {
              groupIndex: v.Order,
              showWhenGrouped: false /*visible:true */
            });
          });
        }
      }
    }
    //let infoText = grid.BuildPagingInfo;

    if (!instance.option("dataSource"))
      instance.option("dataSource", this._dataService.DataSource);
    parent.append(gridel);
    if (this._dataService.ResponseFromServer.ClientSideFilter) {
      let str = JSON.stringify(
        this._dataService.ResponseFromServer.ClientSideFilter
      );
      str = str
        .replace(/\.(?![0-9])/g, PeriodReplacementGUID)
        .replace(/:(?![0-9])/g, ColonReplacementGUID);
      let fixedFilter = JSON.parse(str);
      instance.option("filterValue", fixedFilter);
    }

    //grid.SetPageInfoText();

    instance.endUpdate();
  }
  /**
   * Builds the paging info based on the  data response metadata.
   */
  private get BuildPagingInfo(): string {
    let theGrid = this;
    let infoText: string = "";

    if (
      theGrid._dataService.ResponseFromServer &&
      theGrid._dataService.ResponseFromServer.Meta
    ) {
      let metaInfo = theGrid._dataService.ResponseFromServer.Meta;
      let currentPageRecordInfo: string =
        theGrid.FirstRecordNumberOfCurrentServerPage +
        "-" +
        theGrid.LastRecordNumberOfCurrentServerPage;
      //let t = theGrid.UseCards ? "" : "Records";

      //if (metaInfo.TotalRecords >= metaInfo.RecordsOnPage) {

      //  t = `${t} (${currentPageRecordInfo}) Of`

      //  if (metaInfo.TotalRecords > 1000000) {
      //    t = `${t}  ~ Many Records`

      //  } else {
      //    t = `${t} ${metaInfo.TotalRecords} Records`
      //  }

      //} else {
      //  t = `Total Records  ${metaInfo.TotalRecords}`
      //}

      infoText =
        metaInfo.TotalRecords >= metaInfo.RecordsOnPage
          ? `${
          theGrid.UseCards ? "" : "Records:"
          } (${currentPageRecordInfo}) Of ${
          metaInfo.TotalRecords > 1000000 ? "~ Many" : metaInfo.TotalRecords
          } Records `
          : `Total Records  ${metaInfo.TotalRecords}`;
    }

    return infoText;
  }

  protected SetPageInfoText() {
    let grid = this;
    let itext = grid.BuildPagingInfo;
    let pageInfo = grid.PageInfoObject;

    if (!grid.UseCards) {
      let count = (grid.Instance as DevExpress.ui.dxDataGrid).totalCount();
      if (
        count &&
        count !==
        grid.LastRecordNumberOfCurrentServerPage -
        grid.FirstRecordNumberOfCurrentServerPage +
        1
      ) {
        pageInfo.AfterFilter = count;
        itext = `Showing ${count} of ${itext}`;
      }
      let pagerText = `${pageInfo.CurrentPageRange.start}-${
        pageInfo.CurrentPageRange.end
        } of ${pageInfo.TotalRecords} Total Records`;
      if (pageInfo.AfterFilter) {
        pagerText = `${pagerText} (Filtered to ${pageInfo.AfterFilter})`;
      }
      grid.Instance.option("pager.infoText", pagerText);
    } else {
      grid.Instance.option("pager.infoText", itext);
    }

    grid.SetSpecialPagingOps();
  }

  private get PageInfoObject(): {
    AfterFilter?: number;
    CurrentPageRange: { start: number; end: number };
    TotalRecords: number;
    TotalPages: number;
  } {
    let grid = this;
    let info = grid.getPageInfoText();
    return info;
  }

  protected getPageInfoText() {
    let grid = this;
    let info: {
      AfterFilter?: number;
      CurrentPageRange: { start: number; end: number };
      TotalRecords: number;
      TotalPages: number;
    } = {
      CurrentPageRange: {
        start: grid.FirstRecordNumberOfCurrentServerPage,
        end: grid.LastRecordNumberOfCurrentServerPage
      },
      TotalRecords: grid._dataService.ResponseFromServer.Meta.TotalRecords,
      TotalPages: grid._dataService.ResponseFromServer.Meta.TotalPages
    };
    if (!grid.UseCards && grid.Instance) {
      let count = (grid.Instance as DevExpress.ui.dxDataGrid).totalCount();
      if (
        count &&
        count !== info.CurrentPageRange.end - info.CurrentPageRange.start + 1
      ) {
        info.AfterFilter = count;
      }
    }
    return info;
  }

  protected _UseCards: boolean;
  get UseCards(): boolean {
    let theGrid = this;
    if (theGrid._UseCards === null || theGrid._UseCards === undefined) {
      theGrid._UseCards = GetDevice().isDevice &&
        theGrid.CenterType !== CenterType.Bi &&
        theGrid.CenterType !== CenterType.AccountBi &&
        theGrid.CenterType !== CenterType.ContactBi &&
        theGrid.CenterType !== CenterType.OppBi &&
        theGrid.CenterType !== CenterType.VendorBi &&
        Preferences.GetPreference(
          `UseCardView_${theGrid.view.GUID}`,
          "WebGrid",
          "1"
        ) === "1"
    }
    //return false;
    /*TODO: Need to get stored proc and table created for grid preferences in order to allow users to be more specific about preferences like this and server\client page size etc*/
    return theGrid._UseCards;

    //return true
  }
  set UseCards(val: boolean) {
    let theGrid = this;
    if (val !== null) theGrid._UseCards = val;
  }

  protected MobileOnlyActionSheet: DevExpress.ui.dxActionSheet;

  constructor(
    gridcontainer: JQuery,
    center: CenterType,
    view: IGridView,
    eventLimiterName: string
  ) {
    this._container = gridcontainer.css({ height: "100%", width: "100%" });
    this.CenterType = center;
    this.view = view;
    this.EventLimiterName = eventLimiterName;

    this.AddListeners();
    this.SetupStuff();
  }

  protected SetupStuff() {
    this.viewService = new GridViewService();
    this.dataService = new GridDataService(this.CenterType, this.view);
    this.dataService.RequestModel.RequestArgs.IncludeGridViewInformation = false;
  }

  private get FirstRecordNumberOfCurrentServerPage(): number {
    let grid = this;
    if (
      grid._dataService.ResponseFromServer &&
      grid._dataService.ResponseFromServer.Meta
    ) {
      let metaInfo = grid._dataService.ResponseFromServer.Meta;
      return metaInfo.CurrentPage > 1
        ? (metaInfo.CurrentPage - 1) * metaInfo.RecordsOnPage + 1
        : metaInfo.CurrentPage;
      // return metaInfo.CurrentPage > 1 ? ((metaInfo.CurrentPage - 1) * metaInfo.RecordsOnPage + 1) + "-" + grid.LastRecordNumberOfCurrentServerPage() : metaInfo.CurrentPage + "-" + metaInfo.RecordsOnPage;
    }
  }

  private get LastRecordNumberOfCurrentServerPage(): number {
    let grid = this;
    if (
      grid._dataService.ResponseFromServer &&
      grid._dataService.ResponseFromServer.Meta
    ) {
      let metaInfo = grid._dataService.ResponseFromServer.Meta;
      return (metaInfo.CurrentPage - 1) * metaInfo.RecordsOnPage +
        metaInfo.RecordsOnPage <
        metaInfo.TotalRecords
        ? (metaInfo.CurrentPage - 1) * metaInfo.RecordsOnPage +
        metaInfo.RecordsOnPage
        : metaInfo.TotalRecords;
    }
  }

  init() {
    this.getLayout(true).done(() => {

      this.getData();
    });

  }

  protected AddListeners() {
    let grid = this;
    AddHandler2(
      EventTypes.CenterEventTypes.gridrefresh,
      grid.EventLimiterName,
      eventNameSpace.request,
      grid._container,
      grid.RefreshGrid.bind(grid)
    );
    AddHandler2(
      EventTypes.CenterEventTypes.openitem,
      grid.EventLimiterName,
      eventNameSpace.request,
      grid._container,
      grid.OnOpenRequested.bind(grid)
    );
    AddHandler2(
      EventTypes.CenterEventTypes.itemviews,
      grid.EventLimiterName,
      eventNameSpace.request,
      grid._container,
      grid.OnItemViewsRequested.bind(grid)
    );
    AddHandler2(
      EventTypes.CenterEventTypes.itemactions,
      grid.EventLimiterName,
      eventNameSpace.request,
      grid._container,
      grid.OnItemActionsRequested.bind(grid)
    );
    AddHandler2(
      EventTypes.CenterEventTypes.gridselecteditems,
      grid.EventLimiterName,
      eventNameSpace.request,
      grid._container,
      grid.OnSelectedItemsRequested.bind(grid)
    );
    AddHandler2(
      EventTypes.CenterEventTypes.viewsaved,
      grid.EventLimiterName,
      eventNameSpace.complete,
      grid._container,
      grid.OnGridViewSaved.bind(grid)
    );
    AddHandler2(
      EventTypes.CenterEventTypes.gridclosed,
      grid.EventLimiterName,
      eventNameSpace.notify,
      grid._container,
      grid.OnGridClosed.bind(grid)
    )
  }

  OverrideDxOptions(optionsToUpdate: DevExpress.ui.dxDataGridOptions) {
    let grid = this;

    let options = grid.GetDxOptions();

    for (const setting in optionsToUpdate) {
      if (optionsToUpdate.hasOwnProperty(setting)) {
        const element = optionsToUpdate[setting];

        options[setting] = element;
      }
    }

    grid._dxOptions = options
  }

  private OnOpenRequested(e: JQueryEventObject, d: any) {
    let grid = this;
    //the grid has the option to deferr selection so assume its deferred .. this will still work if not
    $.when(grid.GetMySelectedItems()).done((data: any[]) => {
      if (d && d.length > 0) {
        if (data.length === 0) {
          data = d;
        }
      }

      if (data.length === 1) {
        let requestParams = GetActionRequestParamsFromRowData(data[0]);
        if (requestParams.itemtype === itemTypes.itemMailing) {
          DisplayInfo(requestParams.itemtype).done(info => {
            import(`../items/${info.TypeName.toLowerCase()}`).then(mod => {
              new mod[info.TypeName](data[0]).Initialize();
            });
          });
        } else {
          OpenItemOrURL(requestParams, data);
        }
      } else {
        if (data.length > 1) {
          OpenNavigator(data, grid._view.ItemType as itemTypes);
        }
      }
    });
  }

  private GetMySelectedItems() {
    let grid = this;
    return grid.UseCards
      ? grid.SelectedCards
      : grid.Instance.getSelectedRowsData();
  }

  private OnItemViewsRequested(e: JQueryEventObject, d: any) {
    let grid = this;
    $.when(grid.GetMySelectedItems()).done((data: any[]) => {
      let actionRequestParams = GetActionRequestParamsFromRowData(data[0]);
      let theViews;
      let request = new TDFRequest({
        url: "/action/getviewsonly/",
        type: "GET",
        data: actionRequestParams
      });
      request.MakeRequest().done((response: any) => {
        $.each(response.ActionsModel.ViewList, (key, val: any) => {
          val.text = val.MenuText;
        });
        theViews = response.ActionsModel.ViewList;

        GetDevice().isDevice;
        RenderViewsPopup(GetDevice().isDevice ? null : d, theViews, data[0]);
      });
    });
  }

  private OnItemActionsRequested(e: JQueryEventObject, d: any) {
    let grid = this;
    $.when(grid.GetMySelectedItems()).done((data: any[]) => {
      let actionRequestParams = GetActionRequestParamsFromRowData(data[0]);

      let theActions;

      let request = new TDFRequest({
        url: "/action/getactionsonly/",
        type: "GET",
        data: actionRequestParams
      });
      request.MakeRequest().done((response: any) => {
        $.each(response.ActionsModel.ActionList, (key, val: any) => {
          val.text = val.MenuText;
          val.iMain = response.ActionsModel.iMain;
          val.disabled = data.length > 1 ? !val.SupportsMultipleItems : false;
          val.visible = data.length > 1 ? val.SupportsMultipleItems : true;

          // TODO: This is a temporary fix to get 2018R1 out. Please remove once action has a web implementation.
          if (val.MenuText === "Launch Web Access Log") {
            val.visible = false;
          }

          if (!IsModuleLicensedAtCompany(tdfModules.TDF_InfoCenter)) {
            if ((val.Action === menuActions.menuNewGeneralItem) || (val.Action === menuActions.menuLinkItem)) {
              val.visible = false;
            }
          }
        });

        //rowdata.Actions = response.ActionsModel.ActionList;       // DO NOT modify the rowdata as it causes the grid to lose track of what is selected

        theActions = response.ActionsModel.ActionList;
        if (
          (actionRequestParams.itemtype === itemTypes.itemContact ||
            actionRequestParams.itemtype === itemTypes.itemLead) &&
          response.ActionsModel.ContactEmail
        ) {
          theActions.ContactEmail = response.ActionsModel.ContactEmail;
        }

        RenderActionsPopup(d, theActions, data);
      });
    });
  }

  private OnSelectedItemsRequested(e: JQueryEventObject, d: any) {
    let grid = this;
    $.when(grid.GetMySelectedItems()).done((data: any[]) => {
      d.items = data;
    });
  }

  private OnGridViewSaved(e: JQueryEventObject, d: IGridView) {
    let grid = this;
    //when a gridview has been saved and removed from the DataCenterTabs we need to go get the new layout info and reload the data
    grid.getLayout().done(() => {
      grid.ReloadData();
    });
  }

  private OnGridClosed(e: JQueryEventObject, d: { id: string }) {
    let grid = this;

    if (!d || grid.view.GUID === d.id) {
      grid.dataService.Abort();
    }
  }

  protected RefreshGrid(e: JQueryEventObject, data) {
    let grid = this;
    grid._dataService.RequestArgs.IncludeGridViewInformation = false;
    if (data) {
      if (data.clear) {
        switch (data.clear) {
          case "all":
            grid._dataService.RequestArgs.filterExpression = "";
            grid._dataService.RequestArgs.Sorts = [];
            (grid.Instance as DevExpress.ui.dxDataGrid).clearFilter();
            (grid.Instance as DevExpress.ui.dxDataGrid).clearSorting();
            break;
          case "filter":
            grid._dataService.RequestArgs.filterExpression = "";
            (grid.Instance as DevExpress.ui.dxDataGrid).clearFilter();
            break;
          case "sort":
            grid._dataService.RequestArgs.Sorts = [];
            (grid.Instance as DevExpress.ui.dxDataGrid).clearSorting();
            break;
        }
      }
    }
    //  else {
    //    if (data.clearView) {
    //      $(`[data-guid='${data.clearView}']`).find(".center-title-close").click();
    //      grid.getLayout().done(() => {
    //        RaiseEvent(EventTypes.CenterEventTypes.found, EventNameSpace.modify, { GUID: data.clearView, reload: true });
    //        grid.ReloadData();

    //      });
    //    } else {

    //    grid.ReloadData();

    //    }
    //  }
    //} else {
    grid.ReloadData();
    //  }
  }

  protected getData() {
    let grid = this;

    if (grid.ViewLayout && grid.ViewLayout.GridViewModel)
      this.dataService.GridViewInformation = this.ViewLayout;

    this.dataService.DataSource.load().done(data => {
      if (!data || (data.length === 0 && this.Instance)) {
        this.Instance.option("noDataText", "No Data");
      } else {
        if (this.dataService.ResponseFromServer.GridView) {
          this.ViewLayout.GridViewModel = this.dataService.ResponseFromServer.GridView;
        }
        grid.DoPostLoad();
      }
    });
  }

  protected ReloadData(doneAction: () => void = $.noop) {
    let theGrid = this;
    theGrid._dataService.DataSource.reload().done((data) => {
      if (!data || (data.length === 0 && this.Instance)) {
        this.Instance.option("noDataText", "No Data");
      }

      theGrid.SetPageInfoText();
      theGrid.OverridePagingButtons();
      doneAction();
    });
  }

  protected _ColumnHash: any;

  protected getLayout(resetting = false): JQueryPromise<any> {
    let grid = this;
    let d = $.Deferred();
    if (grid.view && grid.view.GUID) {
      (grid.viewService as GridViewService)
        .LayoutForViewRequest({ viewid: grid.view.GUID })
        .MakeRequest()
        .done((response: ViewResponse) => {
          grid.ViewLayout = response;
          grid.dataService.GridViewInformation = response;
          if (grid.ViewLayout.GridViewModel.Layout.Columns) {
            grid._ColumnHash = gethash(
              "dataField",
              grid.ViewLayout.GridViewModel.Layout.Columns
            );
          }
          if (resetting) {
            d.resolve();
          }
        });
    }

    if (resetting) {
      return d.promise();
    } else {
      return d.promise(d.resolve());
    }
  }

  protected renderGridShell() {
    let grid = this;
    // try {
    let theGrid = grid.CreateIt();
    if (grid.UseCards) {
      let scrollview = $('<div />').dxScrollView({
        height: "calc(100% - 55px)",
        showScrollbar: "always",
        width: "100%"
      })
        .dxScrollView("instance");

      grid._container.append(
        scrollview.element()
      );

      scrollview
        .content()
        .append(<any>theGrid);
    } else {
      grid._container.append(<any>theGrid);
    }
    //let theGrid = grid.CreateIt()//$("<div />").dxDataGrid(grid.dxOptions);
    //let parent = grid._container.parent().first();
    //let el = grid._container.detach();
    //el.append(theGrid);
    //parent.append(el);
    //} catch (e) {
    //  let t = e;
    //}
  }
  /*
		 * Returns true if on a device && the user has not opted out of card view based on preferences
		 */

  protected CreateIt() {
    let grid = this;
    return grid.UseCards
      ? grid.CardView()
      : $(`<div id='tdf-datagrid${DomSafeID(grid._view.GUID)}' />`).dxDataGrid(
        grid.GetDxOptions()
      );
  }

  private TryGetSubject(response: IGridDataResponse) {
    let subject;
    if (response && response.Data && !response.GridView) {
      if (response.Data.length) {
        if (response.Data[0]) {
          subject = Object.keys(response.Data[0])
            .map(k => {
              let index = $.inArray(k.toLowerCase(), [
                "subject",
                "description",
                "tdf_subject",
                "activity subject"
              ]);
              if (index !== -1) {
                return k;
              }
            })
            .filter(item => {
              return item;
            });
        } else {
          subject = [""];
        }
      }
    } else {
      subject = (response.GridView.Layout.Columns as any[]).filter((v, i) => {
        let found =
          v.UDFInfo &&
          v.UDFInfo.TDFField &&
          v.UDFInfo.TDFField === "TDF_Subject";
        if (!found) {
          found =
            v.caption &&
            (v.caption.toLowerCase() === "subject" ||
              (v.dataField && v.dataField.toLowerCase() === "subject"));
        }
        if (!found) {
          found =
            v.caption &&
            (v.caption.toLowerCase() === "description" ||
              (v.dataField && v.dataField.toLowerCase() === "description"));
        }
        return found;
      });
      if (subject.length > 1) {
        let tempSubject = subject.filter((v, i) => {
          return v.visible;
        });
        if (tempSubject && tempSubject.length) {
          if (response.Data[0][tempSubject[0].dataField]) {
            subject = tempSubject;
          }
        }
      }
    }
    if (!subject) subject = [""];
    return subject[0];
  }

  protected CardView() {
    let grid = this;
    let opts: DevExpress.ui.dxAccordionOptions = {
      //dataSource: theGrid._dataService.,
      collapsible: true,
      multiple: true,
      selectedIndex: -1,
      noDataText: "Fetching Data...",
      //height: "99.9%",
      itemTitleTemplate: function (data, index, element) {
        let subject = grid.TryGetSubject(grid._dataService.ResponseFromServer);
        let key = "No Value";

        if (typeof subject === "object" && subject && data[subject.dataField]) {
          key = data[subject.dataField];
        } else {
          if (typeof subject === "string" && data[subject]) {
            key = data[subject];
          } else {
            if (data["TDF_Subject"]) {
              key = data["TDF_Subject"];
            } else {
              if (
                grid.ViewLayout.GridViewModel &&
                typeof data[
                grid.ViewLayout.GridViewModel.Layout.Columns[0].dataField
                ] === "string"
              ) {
                key =
                  data[
                  grid.ViewLayout.GridViewModel.Layout.Columns[0].dataField
                  ];
              } else {
                let idx = -1;
                Object.keys(data).forEach((x, i) => {
                  if (idx === -1) {
                    if (x.search(/subject/gi) > -1) {
                      idx = i;
                    } else if (x.search(/description/gi) > -1) {
                      idx = i;
                    } else if (x.search(/name/gi) > -1) {
                      idx = i;
                    }
                  }
                });
                if (idx !== -1) {
                  key = data[idx];
                }
              }
            }
          }
        }

        let result;

        result =
          grid.CenterType === CenterType.Recent ||
            grid.CenterType === CenterType.Favorite
            ?
            $(`<div class="container">
                  <div class="row">
								    <div class="col-xs-1">
									<div class="recfaveselect" id='card-select-check${DomSafeID(
              data["TDF GUID"] || data["ItemID"] || data["TDFItemID"]
            )}' data-itemtype="${data.ItemType ||
            data["TDFItemType"]}"data-guid="${data["TDF GUID"] ||
            data[
            "TDFItemID"
            ]}" data-index="${index}" style="vertical-align:middle;display:inline-flex;"></div>
								    </div>
								    <div class="col-xs-1">
									<small class="tdfitem${data.ItemType ||
              data[
              "TDFItemType"
              ]}" style="vertical-align:middle;display:inline-flex;"></small>
								    </div>
								    <div class="col-xs-8" ><small style="white-space:normal;word-wrap:break-word">${key}</small></div>
                  </div>
                  </div>`)
            :
            $(`<div class="container">
            <div class="row">
			    <div class="col-xs-2">
				<span class="recfaveselect" id='card-select-check${DomSafeID(
              data["TDF GUID"] ||
              data["ItemID"] ||
              data["TDFItemID"] ||
              data["LinkID"].toString()
            )}' data-itemtype="${data.ItemType ||
            data["TDFItemType"]}"data-guid="${data["TDF GUID"] ||
            data["LinkID"] ||
            data["TDFItemID"]}" data-index="${index}"></span>
			    </div>
			    <div class="col-xs-8" ><small style="white-space:normal;word-wrap:break-word">${key}</small></div>
      </div>
      </div>`);

        return result;
      },
      onItemTitleClick(e: any) {
        if ($(e.event.target).hasClass("dx-checkbox-icon")) {
          e.event.stopImmediatePropagation();
        }
      },
      onItemHold: (e) => {
        // if (GetDevice().isDevice) {
        let checkbox = e.itemElement.find('[id^=card-select-check]').dxCheckBox('instance');
        checkbox.option('value', true);

        if (!grid.MobileOnlyActionSheet) {
          const sheetID = `mobile-actions-sheet-${grid.EventLimiterName}`
          grid._container.append($('<div />').attr('id', sheetID));

          let openOptions = OpenButton([{ uniquecentername: grid.EventLimiterName }]).options;
          let actionOptions = ActionButton([{ uniquecentername: grid.EventLimiterName }]).options;
          grid.MobileOnlyActionSheet = $(`#${sheetID}`)
            .dxActionSheet({
              items: [
                openOptions,
                actionOptions
              ],
              showTitle: false,
              target: GetDevice().isDevice ? null : e.itemElement,
              usePopover: !GetDevice().isDevice
            }).dxActionSheet('instance');
        }

        if (grid.MobileOnlyActionSheet) {
          grid.MobileOnlyActionSheet.show();
        }
        // }
      },
      onSelectionChanged(e) {
        //possibly use opened accordions as selected instead of checkboxes
      },
      itemTemplate: function (data, index, element) {
        let details = $("<div />")
          .addClass("recent-fav-details")
          .css("border-top", ".5px solid");

        details.append(`<p class="row"></p>`);
        let t = [
          "itemtype",
          "tdfguid",
          "tdf_attachmentCount",
          "favorite",
          "parentname",
          "quoteid"
        ];

        $.each(data, function (key: string, val) {
          let col = grid.getColumn(key);
          let reg = new RegExp("s", "g");
          if (
            col &&
            col.length &&
            col[0].visible &&
            (typeof val !== "undefined" &&
              val !== "" &&
              val !== null &&
              val !== "null")
          ) {
            if ($.inArray(key.toLowerCase().replace(reg, ""), t) === -1) {
              if (col[0].cellTemplate) {
                let span = '<span class="col-xs-6" ></span>';
                let id = Date.now();
                details.append(`<p class="row">
						    <small class="col-xs-4" style="word-break: break-word;">${key}:</small><span id='${id}' class="col-xs-6" ></span>
						   </p>`);

                (col[0].cellTemplate as any)(details.find(`#${id}`), { data });
              } else {
                if (
                  key === "Creation" ||
                  key === "ModDate" ||
                  key === "RecentItemDate"
                ) {
                  details.append(`<p class="row">
						    <small class="col-xs-4" style="word-break: break-word;">${key}:</small><span class="col-xs-6">${val}</span>
						   </p>`);
                  // details.append($("<p />").text(key + " :  " + moment(new Date(val).toISOString().replace("Z", "")).format("LL")))
                } else {
                  details.append(`<p class="row">
						    <small class="col-xs-4" style="word-break: break-word;">${key}:</small><span class="col-xs-6" style="white-space:normal;word-wrap:break-word">${val}</span>
						   </p>`);
                  // details.append($("<p />").text(key + " :  " + val))
                }
              }
            }
          } else {
            if (!grid.ViewLayout.GridViewModel) {
              if ($.inArray(key.toLowerCase().replace(reg, ""), t) === -1) {
                if (
                  key === "Creation" ||
                  key === "ModDate" ||
                  key === "RecentItemDate"
                ) {
                  details.append(`<p class="row">
						    <small class="col-xs-4" style="word-break: break-word;">${key}:</small><span class="col-xs-6">${val}</span>
						   </p>`);
                  // details.append($("<p />").text(key + " :  " + moment(new Date(val).toISOString().replace("Z", "")).format("LL")))
                } else {
                  details.append(`<p class="row">
						    <small class="col-xs-4" style="word-break: break-word;">${key}:</small><span class="col-xs-6" style="white-space:normal;word-wrap:break-word">${val}</span>
						   </p>`);
                  // details.append($("<p />").text(key + " :  " + val))
                }
              }
            }
          }
        });
        let result = GetContainerForRecentOrFavorite(data, details);
        result.append(`<p class="row"></p>`);
        return details;
      },
      onContentReady: function (e) {
        //let id = theGrid.GridContainer[0].id;
        //$(window).on("orientationchange", function () {
        //    $('#' + id).css("width", "100%");
        //    $('#' + id).css("height", "100%");
        //    $('#recentitemcontainer').css("width", "100%");
        //    //  $(`.tdf-dx-card-pager-${theGrid.GridContainer[0].id}`).css("top": $("#${theGrid.GridContainer[0].id} > div").offset().top)
        //});
        grid.CardHeaderCheckbox();

        grid.CardPager(e);
      }
    };

    //if (theGrid._container) {
    //    return theGrid._container.dxAccordion(opts);
    //} else {
    let accordion = $(`<div id='tdf-datagrid${DomSafeID(grid._view.GUID)}' />`)
      .dxAccordion(opts)
      .dxAccordion("instance");
    (accordion as any).getSelectedRowsData = grid.GetMySelectedItems.bind(grid);
    //}
    return accordion.element();
  }

  private PageInfo(ds: any) {
    let theGrid = this;
    let moreServer = theGrid._dataService.GridViewMeta
      ? theGrid._dataService.GridViewMeta.CurrentPage <
      theGrid._dataService.GridViewMeta.TotalPages
      : false;
    let moreClient = !(
      ds.isLastPage() || (ds.pageIndex() + 1) * ds.pageSize() >= ds.totalCount()
    );
    return {
      hasMoreOnServer: moreServer,
      hasMoreOnClient: moreClient
    };
  }

  private CardPager(e) {
    let grid = this;
    let ds: DevExpress.data.DataSource = e.component.option("dataSource");
    if (!ds || !ds.totalCount || !grid) return;

    let id = DomSafeID(grid._view.GUID);

    if (!$(`#tdf-card-page-container-${id}`).length) {
      if (grid._container && grid._container.length) {
        $(
          `<div id='tdf-card-page-container-${id}' style='margin:10px 0 5px 0;'/>`
        ).appendTo(grid._container);
      } else {
        $(
          `<div id='tdf-card-page-container-${id}' style='margin:10px 0 5px 0;'/>`
        ).appendTo("#tdfbodycontent");
      }
    } else {
      $(`#tdf-card-page-container-${id}`).empty();
    }
    if (
      ds.totalCount() >
      parseInt(Preferences.GetPreference("ClientGridPageSize", "TDFMobile"))
    ) {
      let text = grid.BuildPagingInfo;

      if (!$(`.tdf-dx-card-pager-${id} `).length) {
        let pager = `<div class="tdf-dx-card-pager-${id} row">
					    <div class='tdf-dx-card-pager-prev-${id} col-xs-2'></div>
					<div class="col-xs-8">
					    <div  style="white-space:normal;word-wrap:break-word">${text}</div>
					</div>
				    <div class='tdf-dx-card-pager-next-${id}  col-xs-2'></div>
				 </div>`;

        let thePager = $(pager).appendTo(`#tdf-card-page-container-${id}`);
        $(thePager.children()[1]).on("click", () => {
          grid.ShowPagingExplanation();
        });

        $(`.tdf-dx-card-pager-prev-${id}`).dxButton({
          icon: "dx-icon dx-icon-chevronprev",
          height: "40px",
          onClick(e) {
            function shouldEnableNext() {
              let visiblePageSize = ds.pageSize();
              return (
                ds.pageIndex() + 2 * visiblePageSize > ds.items.length ||
                grid._dataService.GridViewMeta.TotalPages >
                grid._dataService.GridViewMeta.CurrentPage
              );
            }
            if (ds.pageIndex() > 0) {
              ds.pageIndex(ds.pageIndex() - 1);
              ds.load().done(() => {
                if (
                  ds.pageIndex() === 0 &&
                  grid._dataService.GridViewMeta.CurrentPage === 1
                ) {
                  e.component.option("disabled", true);
                }
                if (shouldEnableNext()) {
                  $(`.tdf-dx-card-pager-next-${id}`)
                    .dxButton("instance")
                    .option("disabled", false);
                }
              });
            }
          },
          //visible: theGrid.GridResponse.Meta.TotalPages > 1,
          disabled:
            ds.pageIndex() === 0 &&
            (grid._dataService.GridViewMeta
              ? grid._dataService.GridViewMeta.CurrentPage === 1
              : true)
        });
        $(`.tdf-dx-card-pager-next-${id}`).dxButton({
          icon: "dx-icon dx-icon-chevronnext",
          height: "40px",
          onClick(e) {
            function shouldLoadNext() {
              let visiblePageSize = ds.pageSize();
              return ds.pageIndex() + 2 * visiblePageSize > ds.items.length;
            }
            function shouldEnablePrev() {
              return !(
                ds.pageIndex() === 0 &&
                grid._dataService.GridViewMeta.CurrentPage === 1
              );
            }
            if (grid.PageInfo(ds).hasMoreOnClient) {
              ds.pageIndex(1 + ds.pageIndex());
              ds.load().done(d => {
                if (
                  !(
                    grid.PageInfo(ds).hasMoreOnClient ||
                    grid.PageInfo(ds).hasMoreOnServer
                  )
                ) {
                  e.component.option("disabled", true);
                }
                if (shouldEnablePrev()) {
                  $(`.tdf-dx-card-pager-prev-${id}`)
                    .dxButton("instance")
                    .option("disabled", false);
                }
              });
            } else {
              if (grid.CenterType === CenterType.InfoCenter) {
                if (grid.PageInfo(ds).hasMoreOnServer) {
                  $(`#tdf-card-page-container-${id}`).empty();
                  grid._dataService.RequestArgs.page += 1;
                  ds.pageIndex(0);
                  grid.RefreshGrid(null, { clear: "" });
                }
              }
            }
          },
          //   visible: theGrid.GridResponse.Meta.TotalPages > 1,
          disabled: !(
            grid.PageInfo(ds).hasMoreOnClient ||
            grid.PageInfo(ds).hasMoreOnServer
          )
        });
      }
    }
  }

  private _SelectedCards = [];
  private get SelectedCards(): any[] {
    return this._SelectedCards;
  }
  private set SelectedCards(val: any[]) {
    this._SelectedCards = val;
  }

  private CardHeaderCheckbox() {
    let theGrid = this;
    // each time a new page of cards is rendered i clear the selected cards out in order to ensure there is
    // no confusion when navigating from pages that had cards selected
    if (theGrid.SelectedCards) theGrid.SelectedCards = [];
    let opts: DevExpress.ui.dxCheckBoxOptions = {
      onValueChanged(e) {
        if (e.value) {
          let ds: DevExpress.data.DataSource = theGrid.Instance.option(
            "dataSource"
          );
          let index = ds.pageIndex() * ds.pageSize() + e.element.data("index");

          if (theGrid.SelectedCards && theGrid.SelectedCards.length) {
            theGrid.SelectedCards.push(
              theGrid._dataService.ResponseFromServer.Data[index]
            );
          } else {
            theGrid.SelectedCards = [
              theGrid._dataService.ResponseFromServer.Data[index]
            ];
          }
        } else {
          let it = theGrid.SelectedCards.filter((v, i) => {
            if (v["TDF GUID"]) {
              if (v["TDF GUID"] === e.element.data("guid")) {
                theGrid.SelectedCards.splice(i, 1);
              }
            } else {
              if (e.element.data("guid") === "undefined") {
                theGrid.SelectedCards.splice(i, 1);
              }
            }
          });
        }
        RaiseEvent2(
          EventTypes.CenterEventTypes.gridrowselect,
          theGrid.EventLimiterName,
          eventNameSpace.complete,
          { selectedRowsData: theGrid.SelectedCards }
        );
      }
    };
    // i also need to raise the selection event as the user navigated through the pages so the toolbar buttons are hidden appropriately
    RaiseEvent2(
      EventTypes.CenterEventTypes.gridrowselect,
      theGrid.EventLimiterName,
      eventNameSpace.complete,
      { selectedRowsData: theGrid.SelectedCards }
    );
    $(".recfaveselect:not(.dx-checkbox)").dxCheckBox(opts);
  }

  private Summaries(instance) {
    let theGrid = this;
    // safeguard
    if (
      !theGrid.ViewLayout ||
      !(
        theGrid.ViewLayout.GridViewModel.Layout &&
        theGrid.ViewLayout.GridViewModel.Layout.Columns
      )
    )
      return;
    let summary = {
      totalItems: [],
      groupItems: [],
      skipEmptyValues: false
    };

    if (
      theGrid.ViewLayout.GridViewModel.GroupSummaryMap &&
      theGrid.ViewLayout.GridViewModel.GroupSummaryMap.Summaries.length
    ) {
      let sumsg = (theGrid.ViewLayout.GridViewModel.GroupSummaryMap
        .Summaries as {
          DisplayFormat: string;
          FieldName: string;
          Position: string;
          SummaryType: number;
        }[]).map(v => {
          return {
            FieldName: v.FieldName,
            DisplayFormat: v.DisplayFormat,
            showInGroupFooter: v.Position === "Footer",
            SummaryType: SummaryItemType[v.SummaryType].toLowerCase()
          };
        });
      summary.groupItems = theGrid.GetSummaryInfo(
        sumsg as any,
        theGrid.ViewLayout.GridViewModel.Layout.Columns || []
      );
    }
    if (theGrid.ViewLayout.GridViewModel.SummaryMap.Summaries.length) {
      summary.totalItems = theGrid.GetSummaryInfo(
        theGrid.ViewLayout.GridViewModel.SummaryMap.Summaries,
        theGrid.ViewLayout.GridViewModel.Layout.Columns || []
      );
    }

    instance.option("summary", summary);
  }

  //#region Possibly not needed
  /*  if (theGrid.ViewLayout.GridViewModel.GroupList && theGrid.ViewLayout.GridViewModel.GroupList.length) {
						 $.each(theGrid.ViewLayout.GridViewModel.Layout.Columns, function (k: any, v: DevExpress.ui.dxDataGridColumn) {
								 let group = $.grep(theGrid.ViewLayout.GridViewModel.GroupList, function (val, key) {
										 return v.dataField === val.Field;
								 });

								 if (group.length) {
										 // NOTE: a group column may not always be visible in the grid
										 //if (v.visible) {
										 v.groupIndex = group[0].Order;
										 theGrid.dxOptions.summary = {
												 groupItems: [{
														 name: v.dataField,
														 showInColumn: v.dataField,
														 displayFormat: "Total Count: {0}",
														 valueFormat: "decimal",
														 //  showInGroupFooter:true,
														 //column: v.dataField,
														 summaryType: "custom"
												 },
												 {
														 name: v.dataField,
														 showInColumn: v.dataField,
														 //  showInGroupFooter: true,
														 displayFormat: "Currently Showing: {0}",
														 valueFormat: "decimal",
														 summaryType: "count"
												 }
												 ],
												 calculateCustomSummary: function (options) {
														 if (options.name) {
																 if (options.summaryProcess === "start") {
																		 options.totalValue = 0;
																 }
																 if (options.summaryProcess === "calculate") {
																		 let t = $.grep(theGrid._dataService.ResponseFromServer.AdditionalSummaryInfo, function (v: any, k) {
																				 return options.value[v.GroupFieldName] === v.GroupNameValue;
																		 });

																		 if (t.length && options.totalValue === 0) {
																				 options.totalValue = t[0].Count
																		 }
																 }
																 if (options.summaryProcess === "finalize") {

																 }
														 }
												 }
										 }
										 // }
								 }
						 });
				 } */
  //#endregion

  private GetSummaryInfo(
    summaries: Summary[],
    myColumns: Array<IExtendedDataGridColumn>
  ) {
    let grid = this;
    var totalItems = [];

    summaries.forEach((val, key) => {
      val.FieldName = val.FieldName.replace(/:/, ColonReplacementGUID).replace(
        /\./,
        PeriodReplacementGUID
      );
      val.SummaryType = val.SummaryType.toLowerCase().replace("average", "avg");
      let colInfo = myColumns.filter((col, k) => {
        return (
          col &&
          col.dataField &&
          val &&
          val.FieldName &&
          col.dataField.toLowerCase() === val.FieldName.toLowerCase()
        );
      })[0];

      if (colInfo !== undefined) {
        if (typeof colInfo.format !== "undefined") {
          val.ValueFormat = {};

          if (colInfo.format) {
            if ((colInfo.format as any).type)
              val.ValueFormat.type = (colInfo.format as any).type;
            if ((colInfo.format as any).precision)
              val.ValueFormat.precision = (colInfo.format as any).precision;
          } else {
            val.ValueFormat.type = colInfo.format as any;
          }
        }

        if (colInfo.width < 100) {
          colInfo.width = 100;
        }
      }

      var dispFormat = val.DisplayFormat;
      var exp = new RegExp(/\{\d(\:.*)\}/);
      var test = dispFormat.match(exp) || [];
      if (test.length >= 2) {
        dispFormat = dispFormat.replace(test[1], "");
      }

      if (val.SummaryType.search(/sum/i) >= 0) {
        let item = {
          name: val.SummaryType,
          column: val.FieldName,
          ogFormat: val.DisplayFormat,
          displayFormat: dispFormat,
          valueFormat: val.ValueFormat,
          summaryType: val.SummaryType.toLowerCase().replace("average", "avg"),
          sumtype: val.SummaryType
        };
        if (typeof (val as any).showInGroupFooter !== "undefined") {
          (item as any).showInGroupFooter = (val as any).showInGroupFooter;
        }

        totalItems.push(item);
      } else {
        let item = {
          name: val.SummaryType,
          column: val.FieldName,
          ogFormat: val.DisplayFormat,
          displayFormat: dispFormat,
          valueFormat:
            val.SummaryType.search(/count/i) < 0
              ? val.ValueFormat
              : "fixedPoint",
          summaryType: val.SummaryType.toLowerCase().replace("average", "avg"), //"custom",
          sumtype: val.SummaryType
        };

        // if (item.name === "count" && item.valueFormat) {
        //   (item.valueFormat).type = null;
        // }

        if (typeof (val as any).showInGroupFooter !== "undefined") {
          (item as any).showInGroupFooter = (val as any).showInGroupFooter;
        }

        // if (val.Value) {
        //   item.customizeText = e => {
        //     debugger;
        //   };
        // }
        totalItems.push(item);
      }
    });

    if (
      grid._dataService.ResponseFromServer.AdditionalFooterSummaryInfo &&
      grid._dataService.ResponseFromServer.AdditionalFooterSummaryInfo.length >
      0
    ) {
      grid.Instance.option("summary.calculateCustomSummary", options => {
        if (options.summaryProcess === "finalize") {
          try {
            let summ = grid._dataService.ResponseFromServer.AdditionalFooterSummaryInfo.filter(
              k => {
                return k.FieldName === options.name;
              }
            );
            if (summ && summ.length) {
              let col =
                grid._ColumnHash[
                summ[0].FieldName.replace(":", ColonReplacementGUID).replace(
                  ".",
                  PeriodReplacementGUID
                )
                ];
              let format = col.format || { type: "" };
              switch (format.type) {
                case "currency":
                  options.totalValue = Globalize.formatCurrency(
                    typeof summ[0].Value === "string"
                      ? Globalize.parseNumber(summ[0].Value, {
                        style: "decimal"
                      })
                      : summ[0].Value,
                    CurrentUser.Currency,
                    { style: "accounting" }
                  );
                  break;
                case "percent":
                  options.totalValue =
                    "Total: " +
                    Globalize.formatNumber(
                      typeof summ[0].Value === "string"
                        ? Globalize.parseNumber(summ[0].Value, {
                          style: "decimal"
                        })
                        : summ[0].Value,
                      {
                        style: "percent",
                        minimumFractionDigits: (col.format as any).precision,
                        maximumFractionDigits: ((col.format as any) as any)
                          .precision
                      }
                    ); // Globalize.parseNumber(orig.Value, { style: "decimal" })
                  break;
                default:
                  options.totalValue = summ[0].Value;
              }
            }
          } catch (e) {
            console.warn(e);
          }
        }
      });
      if (grid._dataService.ResponseFromServer.Meta.TotalPages > 1) {
        grid._dataService.ResponseFromServer.AdditionalFooterSummaryInfo.forEach(
          v => {
            let dispFormat = `ALL - ${v.DisplayFormat}`;
            let exp = new RegExp(/\{\d(\:.*)\}/);
            let test = dispFormat.match(exp) || [];
            if (test.length >= 2) {
              dispFormat = dispFormat.replace(test[1], "");
            }
            totalItems.push({
              name: v.FieldName,
              column: v.FieldName.replace(":", ColonReplacementGUID).replace(
                ".",
                PeriodReplacementGUID
              ),
              displayFormat: dispFormat,
              // valueFormat: SummaryTypesEnum[v.SummaryType].search(/count/i) < 0 ? v.ValueFormat : "fixedPoint",
              summaryType: "custom", //SummaryTypesEnum[v.SummaryType].toLowerCase().replace("average", "avg"),
              sumtype: v.SummaryType
            });
          }
        );
      }
    }
    return totalItems;
  }
}

export class RecentItemsOrFavoritesGrid extends AwesomeGrid {
  constructor(
    gridcontainer: JQuery,
    center: CenterType,
    view: IGridView,
    eventLimiterName: string,
    itemtype?: itemTypes
  ) {
    super(gridcontainer, center, view, eventLimiterName);

    if (itemtype) {
      this.OnTypeChanged(null, { ItemType: itemtype });
    }
  }

  AddListeners() {
    let rfgrid = this;
    super.AddListeners();
    AddHandler2(EventTypes.RFCenterEventTypes.clear, rfgrid.EventLimiterName, eventNameSpace.request, rfgrid._container, rfgrid.ClearItems.bind(rfgrid));
    AddHandler2(EventTypes.CenterEventTypes.itemtype, rfgrid.EventLimiterName, eventNameSpace.modify, rfgrid._container, rfgrid.OnTypeChanged.bind(rfgrid));
    RemoveHandler2(EventTypes.CenterEventTypes.viewsaved, rfgrid.EventLimiterName, eventNameSpace.complete, rfgrid._container);
  }

  private ClearItems(e: JQueryEventObject, d) {
    let rfgrid = this;
    let data = rfgrid.dataService.DataSource.items();
    GetItemTypeDisplayInfo().done(info => {
      let types = (data.map(k => {
        return k.ItemType;
      }) as any)
        .filter((v, k, self) => {
          return self.indexOf(v) === k
        })
        .map(type => {
          return {
            id: type,
            text: info.filter(i => {
              return i.TypeNum === parseInt(type);
            })[0].DisplayName
          };
        })
        .sort((a, b) => {
          if (a.text < b.text) {
            return -1;
          } else if (a.text > b.text) {
            return 1;
          }
          return 0;
        });
      let theList = $("<div />")
        .dxList({
          items: types,
          keyExpr: "id",
          showSelectionControls: true,
          selectionMode: "all"
        })
        .dxList("instance");
      let dlgSelectType = new Dialog(
        {
          id: "recent-clear-select",
          title: "Select Types",
          closable: true,
          type: "type-warning",
          size: "size-normal",
          buttons: [
            {
              widget: "dxButton",
              location: "after",
              toolbar: "bottom",
              options: {
                text: "OK",
                icon: "check",
                type: 'success',
                onClick(e) {
                  let theTypes = theList.option("selectedItemKeys");
                  if (theTypes.length) {
                    new TDFRequest({
                      url: "/core/user/clear-recent/",
                      data: { itemtypes: theTypes },
                      type: "POST"
                    })
                      .MakeRequest()
                      .done(() => {
                        RaiseEvent2(
                          EventTypes.CenterEventTypes.gridrefresh,
                          rfgrid.EventLimiterName,
                          eventNameSpace.request
                        );
                      });
                    dlgSelectType.close();
                  } else {
                    let opt1: DevExpress.ui.dxButtonOptions = {
                      text: "Yes",
                      icon: "check",
                      type: "success",
                      onClick: function (e: any) {
                        let toast = $($(e.element[0]).parents())
                          .find(
                            ".dx-overlay.dx-widget.dx-visibility-change-handler.dx-toast"
                          )
                          .dxToast("instance");
                        toast.hide();
                        new TDFRequest({
                          url: "/core/user/clear-recent/",
                          type: "POST"
                        })
                          .MakeRequest()
                          .done(() => {
                            RaiseEvent2(
                              EventTypes.CenterEventTypes.gridrefresh,
                              rfgrid.EventLimiterName,
                              eventNameSpace.request
                            );
                          });
                        dlgSelectType.close();
                        return;
                      }
                    };
                    let opt2: DevExpress.ui.dxButtonOptions = {
                      text: "No",
                      icon: "remove",
                      type: "default",
                      onClick: function (e: any) {
                        let toast = $($(e.element[0]).parents())
                          .find(
                            ".dx-overlay.dx-widget.dx-visibility-change-handler.dx-toast"
                          )
                          .dxToast("instance");
                        toast.hide();
                      }
                    };

                    Notification.Confirmation(
                      { type: "warning", location: "middle" },
                      {
                        buttons: [opt1, opt2],
                        notificationText: `Are you sure you want to clear all ${
                          rfgrid.CenterType === CenterType.Recent
                            ? "recent"
                            : "favorite"
                          } items? `,
                        additionalHintText:
                          "You did not select anything, proceeding will clear all items."
                      }
                    );
                  }
                }
              }
            },
            {
              widget: "dxButton",
              location: "after",
              toolbar: "bottom",
              options: {
                text: "Cancel",
                icon: "remove",
                type: 'danger',
                onClick(e) {
                  dlgSelectType.close();
                }
              }
            }
          ],
          body: theList.element()
        },
        null,
        true,
        false
      );
      dlgSelectType.open();
    });
  }

  OnTypeChanged(e: JQueryEventObject, data: { ItemType: itemTypes }) {
    let rfGrid = this;
    if (
      rfGrid.dataService.RequestArgs &&
      (rfGrid.dataService.RequestArgs as GridSetup.RecentRequestArgs)
        .ItemTypeFilter !== data.ItemType
    ) {
      (rfGrid.dataService
        .RequestArgs as GridSetup.RecentRequestArgs).ItemTypeFilter =
        data.ItemType;
      rfGrid.dataService.DataSource.reload();
      // rfGrid.ReloadData();
    }
  }
}

//#region "Interfaces"

interface UDFInfo {
  TDFField: string;
  AcctRootSpecific: boolean;
  Control_ID: string;
  ControlType: number;
  DefaultValue: string;
  FormatString: string;
  IsChild: boolean;
  IsParent: boolean;
  ItemFormTypeSpecific: boolean;
  Locked: boolean;
  PropertyFormat: number;
  RelatedField: string;
  RelatedFieldUDF_ID?: any;
  Required: boolean;
  SystemField?: any;
  SystemTable?: any;
  UDF: string;
  UDF_ID: string;
  UDFFormat: number;
}

export interface ColumnBoolean {
  ID: number;
  Display: string;
}

export interface GridLayout {
  Columns: IExtendedDataGridColumn[];
  ColumnBooleans: ColumnBoolean[];
  ViewID: string;
  Name: string;
}

export interface Summary {
  DisplayFormat: string;
  SummaryType: string;
  FieldName: string;
  Value?: any;
  ValueFormat?: DevExpress.ui.format;
}

export interface SummaryMap {
  Summaries: Summary[];
}

export interface AdditionalSummaryInfo {
  GroupFieldName: string;
  GroupNameValue: string;
  Count: number;
}

export interface GroupList {
  Field: string;
  Order: number;
}

export interface ISortList {
  Field: string;
  Direction: string;
  Order: number;
}

export interface Meta {
  CurrentPage: number;
  TotalPages: number;
  RecordsOnPage: number;
  TotalRecords: number;
  CanExport: boolean;
  TimeInfo: string;
}

export interface GridViewModel {
  Layout: GridLayout;
  SummaryMap: SummaryMap;
  GroupList?: GroupList[];
  SortList?: ISortList[];
  GroupSummaryMap: any;
}

export interface ViewResponse {
  GridViewModel: GridViewModel;
  CanExport: boolean;
}

interface IBaseSummaryItem {
  name?: string;
  column?: string;
  showInColumn?: string;
  summaryType?: "avg" | "count" | "custom" | "max" | "min" | "sum";
  valueFormat?: DevExpress.ui.format;
  precision?: number;
  displayFormat?: string;
  customizeText?: ((
    itemInfo: { value?: string | number | Date; valueText?: string }
  ) => string);
  skipEmptyValues?: boolean;
}

interface ITotalSummaryItem extends IBaseSummaryItem {
  cssClass?: string;
  alignment?: "center" | "left" | "right";
}

interface IGroupSummaryItem extends IBaseSummaryItem {
  showInGroupFooter?: boolean;
  alignByColumn?: boolean;
}
//#endregion
