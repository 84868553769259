import {BI__ItemDetailBase} from "./bi_itemdetailbase"
import { CenterType, GridContainerTypes } from "../../../enums/enums";
import { BSIGrids } from "../../../enums/bi/enums";
import { Preferences } from "../../../infrastructure/user/preferences";

export class BI__ItemDetailPurchaseOrderForm extends BI__ItemDetailBase {
    public readonly TDFContainerType: number = GridContainerTypes.BIDetailFormDialogs;
    public ItemType: number;
    public readonly CustomName: string = "PurchaseOrderDetails";
    public readonly DisplayName = "PurchaseOrder Details";
    public readonly ItemHeaderURL: string = "/bi/BIPurchaseOrderHeaderFormData/";
    public readonly HeaderDataCaption: string = "Purchase Order Header";
  
    constructor(ItemID: string, centerType: CenterType = CenterType.OppBi) {
      super(ItemID);
        const purchaseOrderDetailForm = this;

        this.DisplayName = this.getCustomDisplayName(this.DisplayName);
        this.HeaderDataCaption = this.getCustomHeaderCaption(this.HeaderDataCaption);
  
      switch (centerType) {
        case CenterType.OppBi:
          purchaseOrderDetailForm.ItemType =
            BSIGrids.BIFormDialogPurchaseOrderFromOBI;
          break;
        case CenterType.VendorBi:
          purchaseOrderDetailForm.ItemType =
            BSIGrids.BIFormDialogPurchaseOrderFromVBI;
      }
    }
  }