//import { Preferences } from "../infrastructure/user/preferences";
import { GetPreference } from "./user/getpreference";

export class Debugger {

        public static Enabled: boolean = false;

        public static GetCurrentUserDebuggerModeEnabled(): boolean {
            let currentlyEnabled: boolean = false;

            if (GetPreference(Debugger.PreferenceName, Debugger.ModuleName) != "0") {
                currentlyEnabled = true;
            }

            return (currentlyEnabled);
        }

        public static EnabledCurrentUserDebuggerMode() {
            // TODO: Need to make this use the event framework because of circular refs
            // Preferences.SetPreference(Debugger.PreferenceName, "1", Debugger.ModuleName);
            Debugger.Enabled = true;
            Debugger.Log("Debugging is now enabled for the current user.");
        }

        public static DisableCurrentUserDebuggerMode() {
            Debugger.Log("Debugging will be disabled for the current user.");
            // TODO: Need to make this use the event framework because of circular refs
            // Preferences.SetPreference(Debugger.PreferenceName, "0", Debugger.ModuleName);
            Debugger.Enabled = false;
        }

        public static Log(message?: any, ...optionalParams: any[]): void {
            if (Debugger.Enabled) {
                console.log(message, optionalParams);
            }
        }
        private static PreferenceName = "EnableDebugger";
        private static ModuleName = "TDFMobile";
    }
