export enum DataScopeSelectionTypes {
  BIScope = 0,
  HomeListScope = 1,
  ReportScope = 2,
  CreateWizard = 3,
  AccountBIScope = 4,
  ContactBIScope = 5,
  OppBiScope = 6
}
export enum DataScopeSelectionMode {
  Multiple = 0,
  Single = 1
}
