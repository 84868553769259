import { itemTypes, CenterType, GridContainerTypes } from "enums/enums";
import { Preferences } from "infrastructure/user/preferences";
import { Dialog } from "components/dialogs/dialog";
import { AwesomeToolbar } from "components/grids/gridtoolbars/datacentertoolbar";
import { AwesomeGrid } from "components/grids/datacentergrid";
import { IToolbarItemOverrideOptions } from "interfaces/grids/interfaces";
import { DomSafeID } from "util/allutils";
import { AddHandler2 } from "infrastructure/events/ui_events";
import { eventNameSpace, EventTypes } from "enums/webevents/enums";
import { GridSetup } from "services/grid/helpers/gridsetup";

export class EventSummaryDrillDown {

    private _EventLimiterName: string;
    get EventLimiterName(): string {
        if (this._EventLimiterName) {
            return this._EventLimiterName;
        }

        return `${itemTypes[this.ItemType]}-EventSummary`;
    }
    set EventLimiterName(val: string) {
        this._EventLimiterName = val;
    }

    private _ToolbarContainer: JQuery;
    get ToolbarContainer(): JQuery {
        if (this._ToolbarContainer) {
            return this._ToolbarContainer;
        }

        this._ToolbarContainer = $('<div />').attr(
            'id',
            `${this.EventLimiterName}-Toolbar_${DomSafeID(this.EventID)}`
        );

        return this._ToolbarContainer;
    }
    set ToolbarContainer(val: JQuery) {
        this._ToolbarContainer = val;
    }

    private _GridContainer: JQuery;
    get GridContainer(): JQuery {
        if (this._GridContainer) {
            return this._GridContainer;
        }

        this._GridContainer = $('<div />')
            .attr(
                'id',
                `${this.EventLimiterName}-Grid_${DomSafeID(this.EventID)}`
            );

        return this._GridContainer;
    }
    set GridContainer(val: JQuery) {
        this._GridContainer = val;
    }

    EventID: string;
    ItemType: itemTypes;
    OppType: OppType;

    constructor(EventID: string, ItemType: itemTypes, OppType?: OppType) {
        this.EventID = EventID;
        this.ItemType = ItemType;
        this.OppType = OppType;

        this.AddListeners();
    }

    Init() {
        let evdd = this;

        let bodyContent = $('<div />').height('100%').append(
            evdd.ToolbarContainer,
            evdd.GridContainer
        );

        let ToolbarItems: IToolbarItemOverrideOptions[] = [
            { name: "OpenButton", index: 1, location: "before" },
            { name: "ActionButton", index: 2, location: "before" },
            { name: "ViewLookup", index: 4, location: "after" },
            { name: "RefreshButton", index: 5, location: "after" },
            { name: "OptionsButton", index: 6, location: "after" }
        ];

        if ((Preferences.GetCompanyPreference("ShowViewsBtn", "TDFMobile", "1") === "1")) {
            ToolbarItems.push({ name: "ViewButton", index: 3, location: "before" });
        }

        let dialog = new Dialog({
            title: '',
            closable: true,
            body: bodyContent,
            size: 'size-normal'
        }, null, true, false);

        dialog.open().done(() => {

            new AwesomeToolbar(
                evdd.ToolbarContainer,
                evdd.ItemType,
                GridContainerTypes.Drilldown,
                evdd.EventLimiterName,
                ToolbarItems,
                {
                    ItemType: evdd.ItemType,
                    gridContainerType: GridContainerTypes.Drilldown,
                    UniqueCenterName: evdd.EventLimiterName
                }
            ).init();

        });
    }

    AddListeners() {
        let evdd = this;

        AddHandler2(
            EventTypes.CenterEventTypes.gridviewselect,
            evdd.EventLimiterName,
            eventNameSpace.modify,
            evdd.ToolbarContainer,
            evdd.OnGridViewChanged.bind(evdd)
        );
    }

    OnGridViewChanged(e: JQueryEventObject, viewData) {
        let evdd = this;

        evdd.GridContainer.empty();

        let grid = new AwesomeGrid(
            evdd.GridContainer,
            CenterType.EventDrillDown,
            viewData,
            evdd.EventLimiterName
        );

        (<GridSetup.EventDrillDownRequestArgs>grid.dataService.RequestArgs).EventID = evdd.EventID;

        if (evdd.OppType !== -1) {
            (<GridSetup.EventDrillDownRequestArgs>grid.dataService.RequestArgs).OppType = evdd.OppType;
        }

        grid.init();

        evdd.GridContainer.height('calc(100% - 30px)');
    }
}

export enum OppType {
    Open,
    Closed
}