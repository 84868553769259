export class QuoteDetailControlTypes {
  static Description: DevExpress.ui.dxFormSimpleItem = {
    dataField: "Description", editorType: "dxTextBox",
    label: { text: "Description" }, visible: true, visibleIndex: 0, colSpan: 3
  };
  static ClosedDate: DevExpress.ui.dxFormSimpleItem = {
    dataField: "ClosedDate", editorType: "dxDateBox",
    label: { text: "Closed On" }, visible: true, visibleIndex: 5, colSpan: 1
  };
  static Status: DevExpress.ui.dxFormSimpleItem = {
    dataField: "Status", editorType: "dxSelectBox", label:
      { text: "Status" }, visible: true, visibleIndex: 3, colSpan: 1
  };

  static OrderNumber: DevExpress.ui.dxFormSimpleItem = {
    dataField: "OrderNumber", editorType: "dxTextBox",
    label: { text: "Order #" }, visible: true, visibleIndex: 7, colSpan: 1
  };
  static QuoteID: DevExpress.ui.dxFormSimpleItem = {
    dataField: "QuoteID", editorType: "dxTextBox", label:
      { text: "QuoteID" }, visible: false, visibleIndex: 6, colSpan: 1
  };
  static QuoteDate: DevExpress.ui.dxFormSimpleItem = {
    dataField: "QuoteDate", editorType: "dxDateBox",
    label: { text: "Quote Date" }, visible: true, visibleIndex: 4, colSpan: 1
  };
  static BSIQuoteNo: DevExpress.ui.dxFormSimpleItem = {
    dataField: "BSIQuoteNo", editorType: "dxTextBox",
    label: { text: "BI Quote #" }, visible: true, visibleIndex: 8, colSpan: 1
  };
  static UniqueID: DevExpress.ui.dxFormSimpleItem = {
    dataField: "UniqueID", editorType: "dxTextBox",
    label: { text: "Unique ID" }, visible: true, visibleIndex: 1, colSpan: 1
  };
  static RevisionID: DevExpress.ui.dxFormSimpleItem = {
    dataField: "RevisionID", editorType: "dxTextBox",
    label: { text: "Revision" }, visible: false, visibleIndex: 1, colSpan: 1
  };

  static ActiveRevisionNumber: DevExpress.ui.dxFormSimpleItem = {
    dataField: "ActiveRevisionNumber", editorType: "dxTextBox",
    label: { text: "Revision" }, visible: false, visibleIndex: 1, colSpan: 1
  };
  static RevisionDescription: DevExpress.ui.dxFormSimpleItem = {
    dataField: "RevisionDescription", editorType: "dxTextBox",
    label: { text: "Revision Description" }, visible: false, visibleIndex: 1, colSpan: 1
  };
  static ERPProcessStatus: DevExpress.ui.dxFormSimpleItem = {
    dataField: "ERPProcessStatus", editorType: "dxSelectBox",
    label: { text: "ERPProcess Status" }, visible: false, visibleIndex: 1, colSpan: 1
  };
  static ShipToID: DevExpress.ui.dxFormSimpleItem = {
    dataField: "ShipToID", editorType: "dxDropDownBox",
    label: { text: "ShipTo" }, visible: true, visibleIndex: 9, colSpan: 1
  };
  static Contract: DevExpress.ui.dxFormSimpleItem = {
    dataField: "Contract", editorType: "dxLookup",
    label: { text: "Contract" }, visible: true, visibleIndex: 2, colSpan: 1
  };
  static ActualBSIShipToID: DevExpress.ui.dxFormSimpleItem = {
    dataField: "ActualBSIShipToID",
    editorType: "dxTextBox", label: { text: "Actual BSI ShipTo ID" }, visible: false, visibleIndex: 1, colSpan: 1
  };
  static IsBillOfMaterials: DevExpress.ui.dxFormSimpleItem = {
    dataField: "IsBillOfMaterials",
    editorType: "dxCheckBox", editorOptions: function () { },
    label: { text: "Bill of Materials" }, visible: false, visibleIndex: 9, colSpan: 1
  }
  static ImportedByBISync: DevExpress.ui.dxFormSimpleItem = {
    dataField: "ImportedByBISync",
    editorType: "dxCheckBox", editorOptions: function () { },
    label: { text: "ImportedByBISync" }, visible: false, visibleIndex: 10, colSpan: 1
  }
}