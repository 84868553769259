import { BI__ItemDetailBase } from "./bi_itemdetailbase"
import { CenterType, GridContainerTypes } from "../../../enums/enums";
import { BSIGrids } from "../../../enums/bi/enums";

export class BI__ItemDetailInvoiceForm extends BI__ItemDetailBase {
  public readonly TDFContainerType: number = GridContainerTypes.BIDetailFormDialogs;
  public ItemType: number;
  public readonly CustomName: string = "InvoiceDetails";
  public readonly DisplayName = "Invoice Details";
  public readonly ItemHeaderURL: string = "/bi/BIInvoiceHeaderFormData/";
  public readonly HeaderDataCaption: string = "Invoice Header";

  constructor(ItemID: string, centerType: CenterType = CenterType.Bi) {
    super(ItemID);
    const invoiceDetailForm = this;

    this.DisplayName = this.getCustomDisplayName(this.DisplayName);
    this.HeaderDataCaption = this.getCustomHeaderCaption(this.HeaderDataCaption);

    switch (centerType) {
      case CenterType.Bi:
      case CenterType.AccountBi:
      case CenterType.ContactBi:
      case CenterType.OppBi:
      case CenterType.VendorBi:
      case CenterType.GenericGroupSummary:
      case CenterType.GapAnalysis:
        invoiceDetailForm.ItemType = BSIGrids.BIFormDialogInvoice;
        break;
    }
  }
}