import { itemTypes } from "../../enums/enums";
import * as Globalize from "globalize";
import { ItemBase } from "./itembase";
import { DisplayInfo, CurrentUser } from "../../infrastructure/context";

export class Forecast extends ItemBase {
  DisplayName: string;
  get TypeName() {
    return "Forecast";
  }
  set TypeName(name) {
    if (name && this.TypeName !== name) this.TypeName = name;
  }
  get ItemType() {
    return itemTypes.itemForecast;
  }
  get RequiresParentItem(): boolean {
    return true;
  }
  ForecastProbableValue?: DevExpress.ui.Widget;
  ForecastValue?: DevExpress.ui.Widget;
  ForecastProbability?: DevExpress.ui.Widget;
  constructor(args) {
    super(args);
    let theForecast = this;
    $.each(args, function(key, val) {
      if (theForecast.hasOwnProperty(key)) theForecast[key] = val;
    });

    if (!theForecast.DisplayName) {
      DisplayInfo(theForecast.ItemType).done(function(displayinfo) {
        theForecast.DisplayName = displayinfo.DisplayName;
        theForecast.TypeName = displayinfo.TypeName;
      });
    }
  }
  TryCalculateProbableValue() {
    let theForecast = this;

    if (theForecast.ForecastProbableValue && theForecast.ForecastProbability) {
      let value = Globalize.parseNumber(
        theForecast.ForecastValue.option("value"),
        { style: "decimal" }
      );
      let probability = Globalize.parseNumber(
        theForecast.ForecastProbability.option("value"),
        { style: "percent" }
      );
      if (value && probability) {
        if (probability > 1) probability = probability / 100;
        if (theForecast.ForecastProbableValue)
          theForecast.ForecastProbableValue.option(
            "value",
            Globalize.formatCurrency(value * probability, CurrentUser.Currency)
          );
      }
    }
  }
}
