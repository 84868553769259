import { AwesomeGrid } from "../components/grids/datacentergrid";
import { CenterType, itemTypes } from "../enums/enums";
import { eventNameSpace, EventTypes } from "../enums/webevents/enums";
import { RaiseEvent2, AddHandler2 } from "../infrastructure/events/ui_events";
import { TDFDataCenters } from "../interfaces/datacenters/tdfdatacenters";
import { IGridView } from "../interfaces/grids/interfaces";
import { GetDevice } from "../util/allutils";
import dxDataSource from "devextreme/data/data_source";
import dxCustomStore from "devextreme/data/custom_store";

import { Preferences } from "../infrastructure/user/preferences";
import { CurrentCompany } from "../infrastructure/context";
import { GridSetup } from "services/grid/helpers/gridsetup";
import { BSIGrids } from "enums/bi/enums";

export class DataCenterTabs {
  protected EventLimiterName: string = "";
  protected InDefaultContainer: boolean;

  private _TabsContainer: JQuery;
  get TabsContainer(): JQuery {
    if (this._TabsContainer) return this._TabsContainer;
  }
  set TabsContainer(val: JQuery) {
    if (val) this._TabsContainer = val;
  }

  GridContentCache: { [key: string]: { Element: JQuery, NeedsUpdated: boolean } } = {};

  private _CenterType: CenterType;
  get CenterType(): CenterType {
    if (!(typeof this._CenterType === "undefined" || this._CenterType === null))
      return this._CenterType;
    throw "Center Type not found...";
  }
  set CenterType(val: CenterType) {
    if (!(typeof val === "undefined" || val === null)) this._CenterType = val;
  }

  private _OveriddenGridOptions: DevExpress.ui.dxDataGridOptions;
  get OveriddenGridOptions(): DevExpress.ui.dxDataGridOptions {
    if (this._OveriddenGridOptions) return this._OveriddenGridOptions;

    return null;
  }
  set OveriddenGridOptions(val: DevExpress.ui.dxDataGridOptions) {
    this._OveriddenGridOptions = val;
  }

  private _ExtraGridRequestArgs: GridSetup.GridRequestArgs;
  get ExtraGridRequestArgs(): GridSetup.GridRequestArgs {
    if (this._ExtraGridRequestArgs) return this._ExtraGridRequestArgs;

    return null;
  }
  set ExtraGridRequestArgs(val: GridSetup.GridRequestArgs) {
    this._ExtraGridRequestArgs = val;
  }

  protected _TabItems: TDFDataCenters.DataCenterTabs.TabItem[] = [
    {
      Name: "Welcome",
      text: "Welcome",
      IsGlobalView: true,
      ItemSpecific: 0,
      AutoRefreshInterval: 0,
      ParentView: "",
      ItemType: -1,
      DefaultView: null,
      Category: null,
      GUID: "Welcome",
      visible: true,
      template: this.itemTemplate.bind(this)
    }
  ];

  private _TabPanelInstance: DevExpress.ui.dxTabs;
  /**
   * Get the intance of the extended dxTabs object
   * **/
  protected get TabInstance(): DevExpress.ui.dxTabs {
    if (this._TabPanelInstance) return this._TabPanelInstance;
  }
  /**
   * Set the intance of the extended dxTabs object
   * **/
  protected set TabInstance(val: DevExpress.ui.dxTabs) {
    if (val) this._TabPanelInstance = val;
  }

  /**
   * Gets the custom store the tab panel is using
   */
  get TabStore(): DevExpress.data.CustomStore {
    return this.TabDataSource.store();
  }

  /**
   * Returns the datasource for the tabpanel
   * **/
  protected get TabDataSource(): DevExpress.data.DataSource {
    let dct = this;
    if (dct.TabInstance && dct.TabInstance.getDataSource()) {
      return dct.TabInstance.getDataSource();
    }
    let storeopts: DevExpress.data.CustomStoreOptions = {
      load(opts) {
        let d = $.Deferred();
        return d.promise(d.resolve(dct._TabItems));
      },
      insert(values: IGridView[]) {
        /**
         * Add the view to the store
         * */
        let d = $.Deferred();
        let found = false;
        for (let view of values) {
          if (!view.GUID) {
            view.GUID = `${view.ItemType}-${view.Name}`;
          }

          for (let sourceview of dct._TabItems) {
            found = sourceview.GUID === view.GUID;
            if (found) {
              break;
            }
          }
          if (!found) {
            dct._TabItems.push(<any>{
              DefaultView: view.DefaultView,
              AutoRefreshInterval: view.AutoRefreshInterval,
              Category: view.Category,
              GUID: view.GUID,
              IsGlobalView: view.IsGlobalView,
              ItemSpecific: view.ItemSpecific,
              ItemType: view.ItemType,
              Name: view.Name,
              title: view.Name,
              //tabTemplate: dct.itemTemplate.bind(dct),
              text: view.Name,
              ParentView: view.ParentView,
              template: dct.itemTemplate.bind(dct),
              visible: view.DefaultView
            });
          }

        }
        return d.promise(d.resolve());
      },
      byKey(key) {
        /**
         * Gets a view by the key [GUID]
         * */
        let theDs: DevExpress.data.CustomStore = this;
        let d = $.Deferred();
        let theOne = dct._TabItems.filter((v, k) => {
          if (
            key !== "Welcome" ||
            key !== "No View"
          ) {
            return v.GUID === key;
          }
          return v.Name === key;
        });

        return d.promise(d.resolve(theOne[0]));
      },
      loadMode: "raw", //this tells the store that the data has not been filtered/sorted etc. on the server already
      update(key, values) {
        let theDs: DevExpress.data.CustomStore = this;
        let d = $.Deferred();
        theDs.byKey(key).done(theOne => {
          if (theOne) {
            let keys = Object.keys(values);
            keys.forEach((v, k) => {
              theOne[v] = values[v];
            });
          } else {
            theDs.insert(values);
          }
        });

        return d.promise(d.resolve(dct._TabItems));
      },
      remove(key) {
        let d: JQueryDeferred<void> = $.Deferred();

        let index = -1;
        dct._TabItems.forEach((t, i) => {
          if (t.GUID === key) index = i;
        });

        dct._TabItems.splice(index, 1);

        return d.promise(d.resolve());
      }
    };
    let dsopts: DevExpress.data.DataSourceOptions = {
      store: new dxCustomStore(storeopts),
      paginate: false
    };

    return new dxDataSource(dsopts);
  }
  /**
   * Returns the options for the tabpanel
   * **/
  private get TabOptions(): DevExpress.ui.dxTabsOptions {
    let dct = this;
    return {
      selectedIndex: 0,
      scrollingEnabled: true,
      scrollByContent: true,
      dataSource: dct.TabDataSource,
      showNavButtons: true,
      keyExpr: "GUID",
      onSelectionChanged(e) {
        if (e.addedItems && e.addedItems.length) {
          if (e.addedItems[0].GUID) {
            dct.TabStore.update(e.addedItems[0].GUID, { visible: true }).done(
              () => {
                RaiseEvent2(
                  EventTypes.CenterEventTypes.found,
                  dct.EventLimiterName,
                  eventNameSpace.modify,
                  e.addedItems[0]
                );
                //  UI_Events.Dispatch(UI_Events.GetEventWithData(UI_Events.ViewFound, e.addedItems[0]))
              }
            );
          }
          if (e.removedItems && e.removedItems.length) {
            if (
              e.removedItems[0].Name === "Welcome" ||
              e.removedItems[0].Name === "No View"
            ) {
              dct.TabStore.update(e.removedItems[0].GUID, {
                visible: false
              }).done(() => { });
            }

            if (
              // e.addedItems[0].ItemType !== null &&
              // typeof e.removedItems[0].ItemType !== "undefined" &&
              e.removedItems[0].ItemType !== e.addedItems[0].ItemType// &&
              // e.removedItems[0].ItemType !== null
            ) {
              RaiseEvent2(
                EventTypes.CenterEventTypes.itemtype,
                dct.EventLimiterName,
                eventNameSpace.modify,
                e.addedItems[0]
              );
              // UI_Events.Dispatch(UI_Events.GetEventWithData(UI_Events.DataCenterTypeSelection, { ItemType: e.addedItems[0].ItemType, GUID: e.addedItems[0].GUID }));
            }
          }
          dct.TabDataSource.reload().done(() => {
            dct.RenderContent(e.addedItems[0]);
          });
        }
      },
      onInitialized(e) {
        dct.SubscribeToViewEvents();
      }
    };
  }

  /**
   * Returns the template function to be used for the tab title
   * **/
  get itemTemplate() {
    let dct = this;
    return function (
      data: TDFDataCenters.DataCenterTabs.TabItem,
      index,
      element: JQuery
    ) {
      if (
        data.visible &&
        (
          (data.GUID /*&& data.Name.indexOf("No View") === -1*/) ||
          (data.Name === "Welcome" || data.Name === "No View")
        )
      ) {
        let titleCaption =
          data && data.Name ? data.Name : "View Name Not Found";
        let iconClass =
          data && data.ItemType ? `dx-icon tdfitem${data.ItemType}` : "";

        let thetitlecontainer = `
                                          <div class="center-tab-title" data-GUID="${
          data.GUID
          }" data-tabitemtype="${
          typeof data.ItemType !== "undefined" ? data.ItemType : ""
          }">                                         
					  <span class="center-title-icon"><small class="${iconClass}"></small></span>
					  <span class="center-title-text" title="${titleCaption}"><small>${titleCaption}</small></span>
                                          ${
          data.Name === "Welcome"
            ? ""
            : ' <span class="center-title-close"><i class="dx-icon dx-icon-remove "></i></span>'
          }
					</div>
					`;

        element.append(thetitlecontainer);
        element
          .find(".center-title-close")
          .on(
            "click",
            { tab: data, index: index },
            dct.onTitleCloseClick.bind(dct)
          );
        element.on("click dxclick", e => {
          var t = e;
        });
        if (data.Name === "Welcome") {
          dct.RenderContent(data);
        }
      } else {
        return null;
      }
    };
  }

  private get CurrentItemType() {
    let toolbar_el: HTMLElement = <HTMLElement>document.querySelector(
      "[data-toolbaritemtype]"
    );
    let itemType: itemTypes = null;
    if (
      toolbar_el &&
      toolbar_el.dataset &&
      typeof toolbar_el.dataset.toolbaritemtype !== "undefined"
    ) {
      itemType = parseInt(toolbar_el.dataset.toolbaritemtype);
    }
    return itemType;
  }

  private get CurrentScope() {
    let toolbar_el: HTMLElement = <HTMLElement>document.querySelector(
      "[data-toolbarscope]"
    );
    let scope = "Global";
    if (
      toolbar_el &&
      toolbar_el.dataset &&
      typeof toolbar_el.dataset.toolbarscope !== "undefined"
    ) {
      scope = toolbar_el.dataset.toolbarscope;
    }
    return scope;
  }

  private _dataContainer: JQuery;
  /*
     * the container where the data should load when a specific tab is selected
     */
  get DataContainer(): JQuery {
    if (this._dataContainer) return this._dataContainer;
    return null;
  }
  set DataContainer(val: JQuery) {
    if (val) this._dataContainer = val;
  }

  constructor(
    container: JQuery,
    centertype: CenterType,
    dataContainer: JQuery = null,
    eventLimiterName: string,
    inDefaultContainer: boolean,
    extraGridRequestArgs?: GridSetup.GridRequestArgs
  ) {
    if (
      !container ||
      !container.length ||
      !$.contains(document.documentElement, container[0])
    )
      console.error("Tabs container is either detached from document or nothing...");
    if (typeof centertype === "undefined" || centertype === null)
      console.error("You must pass a Center Type");
    this.TabsContainer = container;

    this.EventLimiterName = eventLimiterName;
    this.DataContainer = dataContainer;
    this.CenterType = centertype;
    this.InDefaultContainer = inDefaultContainer;

    if (extraGridRequestArgs) {
      this.ExtraGridRequestArgs = extraGridRequestArgs;
    }

  }

  Init() {
    this.initTabs();
    this.SubScribeEvents();
  }

  SubScribeEvents() {
    let dct = this;
    AddHandler2(
      EventTypes.CenterEventTypes.size,
      dct.EventLimiterName,
      eventNameSpace.notify,
      dct.TabsContainer,
      dct.SizeChangeNeeded.bind(dct)
    );
    //UI_Events.AddListener(dct.TabsContainer, UI_Events.UpdateSize, dct.SizeChangeNeeded.bind(dct));
  }

  /**
   * Set the height of the data container.
   * @param e
   */
  SizeChangeNeeded(e: JQueryEventObject) {
    let dct = this;

    let height;

    if (dct.InDefaultContainer) {
      // This is based on the knowledge that the datacontainer will be after the tabs controls and nothing else will follow it.
      height = $("#tdffooter").offset().top - dct.DataContainer.offset().top;
    } else {
      // Subtract 25 here to accommodate for toolbar height. (We don't have direct access to it here.)
      let heightOfDataCenterToolbar = 25;
      height = `calc(100% - ${dct.TabsContainer.height() + heightOfDataCenterToolbar}px)`;
      // GetDevice().isDevice
      // ? $("#tdfnavbar").offset().top - dct.DataContainer.offset().top /*- 60*/
      // : $("#tdffooter").offset().top - dct.DataContainer.offset().top;
    }

    dct.DataContainer.css("height", height);
  }

  private SubscribeToViewEvents() {
    let dct = this;
    AddHandler2(
      EventTypes.CenterEventTypes.gridviewselect,
      dct.EventLimiterName,
      eventNameSpace.modify,
      dct.TabsContainer,
      dct.OnGridViewSelectionChanged.bind(dct)
    );
    AddHandler2(
      EventTypes.CenterEventTypes.gridviewsloaded,
      dct.EventLimiterName,
      eventNameSpace.complete,
      dct.TabsContainer,
      dct.OnGridViewsLoaded.bind(dct)
    );
    AddHandler2(
      EventTypes.CenterEventTypes.viewsaved,
      dct.EventLimiterName,
      eventNameSpace.notify,
      dct.TabsContainer,
      dct.OnViewSaved.bind(dct)
    );
  }

  /**
   * When a view has been managed and then saved an event is raised for the notify namespace
   * we first need to remove the grid from the tab control and then fire the event to perform the rest of the actions needed to reload the grid
   * in order to apply the changes
   * @param e
   * @param d
   */
  private OnViewSaved(e: JQueryEventObject, d: IGridView) {
    let dct = this;
    //close the tab
    $(`[data-guid='${d.GUID}']`)
      .find(".center-title-close")
      .click();
    //remove the tab from the content cahce
    if (dct.GridContentCache[d.GUID]) delete dct.GridContentCache[d.GUID];
    //reaise the viewsaved complete event
    RaiseEvent2(
      EventTypes.CenterEventTypes.viewsaved,
      dct.EventLimiterName,
      eventNameSpace.complete,
      d
    );
  }

  /**
   * After the gridviews have been loaded for a particular type insert them into the store
   * --Note this should only be fired after a request to the server has loaded gridviews for a particular type\scope
   * @param e
   */
  private OnGridViewsLoaded(e: JQueryEventObject, data: IGridView[]) {
    let dct = this;
    if (e && e.namespace === "complete" && $.isArray(data)) {
      dct.TabStore.insert(data).done(() => {
        let allTabGuids = data.map(a => { return a.GUID });
        let listToRemove = dct._TabItems.filter(a => {
          if (a.ItemType === data[0].ItemType) {
            return $.inArray(a.GUID, allTabGuids) === -1;
          }
        });

        if (listToRemove.length) {
          listToRemove.forEach((tab, index) => {
            if (dct.GridContentCache[tab.GUID]) delete dct.GridContentCache[tab.GUID];

            dct.TabStore.remove(tab.GUID);

            if (index === listToRemove.length - 1) {
              dct.TabDataSource.reload().done(() => {
                dct.GetNextTabAfterClose();
              });
            }
          });
        } else {
          dct.TabDataSource.reload();
        }

      });
    }
  }

  private IsSelectedTab(view: TDFDataCenters.DataCenterTabs.TabItem) {
    let dct = this;
    let selectedtab: TDFDataCenters.DataCenterTabs.TabItem = dct.TabInstance.option(
      "selectedItem"
    );
    if (selectedtab && view.GUID === selectedtab.GUID) {
      return true;
    }
    return false;
  }

  /**
   * Event that is raised when a view is selected in the view lookup either by the user or progaramtically.
   * @param e
   */
  private OnGridViewSelectionChanged(e: JQueryEventObject, data) {
    let dct = this;
    let selectedViewItem: IGridView = data || null;
    if (!selectedViewItem) {
      console.error("Somehow there seems to be no selection.");
      return;
    }

    dct.TabStore.byKey(selectedViewItem.GUID).done(sourceitem => {
      if (sourceitem) {
        dct.TabStore.update(selectedViewItem.GUID, { visible: true }).done(
          () => {
            dct.TabDataSource.reload().done(() => {
              (dct.TabInstance as any).selectItem(sourceitem);
            });
          }
        );
      } else {

      }
    });
  }

  /**
   * Close the tab and load the welcome tab if applicable
   * @param e
   */
  onTitleCloseClick(e) {
    let dct = this;
    let tab = e.data.tab;
    let tabGUID = tab.GUID;

    RaiseEvent2(
      EventTypes.CenterEventTypes.gridclosed,
      dct.EventLimiterName,
      eventNameSpace.notify,
      { id: tabGUID }
    );
    //see about getting rid of closed tab
    if (dct.GridContentCache[tabGUID]) delete dct.GridContentCache[tabGUID];

    dct.TabStore.update(tab.GUID, { visible: false }).done(() => {
      dct.TabDataSource.reload().done(data => {
        dct.GetNextTabAfterClose();
      });
    });
  }

  private GetNextTabAfterClose() {
    let dct = this;

    if (
      dct.TabInstance.option(`selectedItem`) &&
      dct.TabInstance.option(`selectedItem`).visible
    ) {
      dct.RenderContent(dct.TabInstance.option(`selectedItem`));
    } else {
      if ((dct.TabInstance as any)._getAvailableItems().length) {
        (dct.TabInstance as any)._getAvailableItems()[0].click();
      } else {
        dct.TabStore.update("Welcome", { visible: true }).done(() => {
          dct.TabDataSource.reload().done(data => {
            if (
              (!dct.TabInstance.option(`selectedItem`) ||
                !dct.TabInstance.option(`selectedItem`).visible) &&
              (dct.TabInstance as any)._getAvailableItems().length
            ) {
              (dct.TabInstance as any)._getAvailableItems()[0].click();
            }
          });
        });
      }
    }
  }

  /**
   * set the tabpanel instance
   * */
  private initTabs() {
    let dct = this;
    /*The tab control does not allow the user to click directly on the close tab button so listen for that event here and prevent the user from have to click twice to close */
    dct.TabsContainer.on(
      "focus focusin mousedown dxclick click focus focusin",
      e => {
        if (e.target.className.search(/dx-icon dx-icon-remove/i) > -1) {
          $(e.target).trigger("click");
        }
      }
    );
    dct.TabInstance = dct.TabsContainer.css({
      display: "inline-block",
      border: "none"
    })
      .dxTabs(dct.TabOptions)
      .dxTabs("instance");
  }

  /**
   * Load the content for the currently selected tab\gridview
   * Either create a new grid or get it from the cache.
   *
   * @param data
   */
  RenderContent(data: TDFDataCenters.DataCenterTabs.TabItem) {
    let dct = this;
    /*TODO: Will need to come up with a caching strategy such as fifo or something */
    if (
      data &&
      data.visible &&
      (
        (data.GUID/* && data.Name.indexOf("No View") === -1*/) ||
        (data.Name === "Welcome" || data.Name === "No View")
      )
    ) {

      //TODO:  Should BI skip card view????
      if (data.GUID && GetDevice().isDevice) {
        if (
          Preferences.GetPreference(
            `UseCardView_${data.GUID}`,
            `WebGrid`,
            "1"
          ) === "1" &&
          !dct.DataContainer.hasClass("height-modified")
        ) {
          dct.DataContainer.addClass("height-modified");
          dct.DataContainer.height(dct.DataContainer.height() - 60);
        }
      }

      //this item has already been loaded once
      let id = data.GUID ? data.GUID : "Welcome";
      let toappend = dct.GridContentCache[id] ||
        {
          Element: $(`<div id='visible-grid-${dct.EventLimiterName}' style='height: 100%' />`),
          NeedsUpdated: false
        }
      //detach the container that is currently there
      if (dct.DataContainer.find(`#visible-grid-${dct.EventLimiterName}`).length > 0) {
        dct.DataContainer.find(`#visible-grid-${dct.EventLimiterName}`).detach();
      }
      //append the new container
      dct.DataContainer.append(toappend.Element);
      //if this view is not already in the cache create the grid or the welcome container...
      if (!dct.GridContentCache[id]) {
        let newEl = $("<div/>");
        toappend.Element.append(newEl);
        if (id === "Welcome") {
          newEl.append(dct.Welcome);
        } else if (id === `${data.ItemType}-No View`) {
          newEl.append(dct.NoView(data.ItemType));
        } else {
          // new DataCenterGrid(newEl, dct.CenterType, data.ItemType,data/*{ItemType: data.ItemType,IsDefault: data.DefaultView,ViewGUID: data.GUID,ViewCategory: data.Category,ViewName: data.Name}*/);
          dct.InitGrid(newEl, data);
        }
        RaiseEvent2(EventTypes.CenterEventTypes.found, dct.EventLimiterName, eventNameSpace.notify, data);
        RaiseEvent2(EventTypes.CenterEventTypes.updateButtons, dct.EventLimiterName, eventNameSpace.request, { ItemType: data.ItemType, CenterType: dct.CenterType });
      } else {
        if (dct.GridContentCache[id] && data.GUID) {

          if (dct.GridContentCache[id].NeedsUpdated) {
            dct.GridContentCache[id].NeedsUpdated = false;
            toappend.Element.empty();
            let newEl = $('<div />');
            toappend.Element.append(newEl);

            dct.InitGrid(newEl, data);
          }

          RaiseEvent2(EventTypes.CenterEventTypes.found, dct.EventLimiterName, eventNameSpace.notify, data);
          RaiseEvent2(EventTypes.CenterEventTypes.updateButtons, dct.EventLimiterName, eventNameSpace.request, { ItemType: data.ItemType, CenterType: dct.CenterType });
        }
      }
      // add or replace the container in the content cache
      dct.GridContentCache[id] = toappend;
    } else {
      return null;
    }
  }

  protected InitGrid(gridElem: JQuery, data: TDFDataCenters.DataCenterTabs.TabItem) {
    let dct = this;
    let grid = new AwesomeGrid(gridElem, dct.CenterType, data, dct.EventLimiterName)

    if (dct.OveriddenGridOptions) {
      grid.OverrideDxOptions(dct.OveriddenGridOptions);
    }

    if (dct.ExtraGridRequestArgs) {
      let gridRequest = grid.dataService.RequestArgs;
      for (const prop in dct.ExtraGridRequestArgs) {
        if (dct.ExtraGridRequestArgs.hasOwnProperty(prop)) {
          gridRequest[prop] = dct.ExtraGridRequestArgs[prop];
        }
      }
      grid.dataService.RequestArgs = gridRequest;
    }

    grid.init();
  }

  protected _Welcome: string;
  public get Welcome(): string {
    if (this._Welcome) {
      return this._Welcome;
    }

    this._Welcome = ` <div class="center-centered-content">  
        <object data="/Content/img/customerIcon.svg" style="max-width: 200px"/>
                   <h3>Welcome to the ${CurrentCompany.Branding.Brand_Company} Info Center.</h3>
                   <h5 style="color:#A4A4A4">Select an item from the menu to get started.</h5>
                       ${Preferences.GetPreference("HomeList", "ManagersConsole") ? `` : `<div style="padding-bottom: 100px"></div>           
                       <div id="note-create-home" class="bg-danger">
                           Data will Not load in the Info Center until you have created a <a href="javascript:void(0)" onclick="(function() { window['HomeList'] = window.open(window.location.origin + '/settings'); })()" > Home List </a>.
                               </div>`
      }
               </div>
        </div>`;

    return this._Welcome;
  }

  public set Welcome(val: string) {
    this._Welcome = val;
  }

  public NoView(itemType: itemTypes | BSIGrids): string {
    let NumViewsOfItemType = this._TabItems.filter((a) => {
      return a.ItemType === itemType
    }).length;

    return `<div class="center-centered-content">
                   <h3>${NumViewsOfItemType > 1 ? 'No View is selected' : 'The selected ItemType has no valid views'}.</h3>
                   <h5 style="color:#A4A4A4">${NumViewsOfItemType > 1 ? 'Select another view from the view selector to get started.' : ''}</h5>
               </div>`;
  }
}
