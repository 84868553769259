import { Dialog } from '../dialogs/dialog';
import { itemTypes } from '../../enums/enums';
import { TDFRequest } from '../../services/request';
import { ItemBase } from './itembase';
import { DisplayInfo } from '../../infrastructure/context';

export class Product extends ItemBase {
  ProductForm: DevExpress.ui.dxForm;
  DisplayName: string;
  Product: { LinkID: string };
  Time;

  get TypeName() {
    return 'Product';
  }
  set TypeName(name) {
    if (name && this.TypeName !== name) this.TypeName = name;
  }
  get ItemType() {
    return itemTypes.itemProduct;
  }
  get RequiresParentItem(): boolean {
    return false;
  }

  constructor(args) {
    super(args);
    let theProduct = this;
    $.each(args, function (key, val) {
      if (theProduct.hasOwnProperty(key)) theProduct[key] = val;
    });

    if (!theProduct.DisplayName) {
      DisplayInfo(theProduct.ItemType).done(function (displayinfo) {
        theProduct.DisplayName = displayinfo.DisplayName;
        theProduct.TypeName = displayinfo.TypeName;
      });
    }
  }

  getProductInfo(rowData: { LinkID: string }) {
    let theProduct = this;
    let dfd = $.Deferred();


		// Note that if we open products according to LinkID, that the LinkID of the product must be something other than zero!

    //if ((rowData.ItemCode || rowData.ItemCode === "") &&
    //    (rowData.ItemCodeAlternative || rowData.ItemCodeAlternative === "") &&
    //    (rowData.ProductGroup || rowData.ProductGroup === "") &&
    //    (rowData.WarehouseID || rowData.WarehouseID === "") &&
    //    (rowData.CompanyID || rowData.CompanyID === "")) {
    if (rowData['LinkID'] != undefined) {
      new TDFRequest({
				url: '/item/GetProductData?' + $.param({ id: rowData['LinkID'] }),
        type: 'GET'
      })
        .MakeRequest()
        .done(response => {
          dfd.resolve(response);
        });
    }
    //else {
    //    new Notification({
    //        message: "Item Code, Item Code Alternative, Product Group, Warehouse ID, and Company ID are all required in the view to open Products",
    //        displayTime: 8e3,
    //        type: 'error'
    //    });
    //    dfd.resolve();
    //}

    return dfd.promise();
  }

  Initialize() {
    let theProduct = this;
    let dfd: JQueryDeferred<Product> = $.Deferred();

    if (theProduct.IsNavigatorItem) {
      theProduct.Form();
    } else {
      theProduct.ProductDialog();
    }
    return dfd.promise(<any>dfd.resolve());
  }

  ProductDialog() {
    let theProduct = this;
    theProduct.ItemForm = new Dialog(
      {
        size: 'size-large',
        id: theProduct.DomSafeID,
        title: theProduct.DisplayName,
        closable: true,
        type: 'type-primary',
        body: $('<div />')
          .attr('id', theProduct.ContentID)
          .append(
            $('<div />')
              .addClass('tdfitemtabs')
              .attr({ id: theProduct.UpperTabsID })
          )
      },
      theProduct
    );

    theProduct.ItemForm.open().done(() => {
      theProduct.Form();
    });
  }

  Form() {
    let theProduct = this;

    let d: JQueryDeferred<void> = $.Deferred();

    theProduct.getProductInfo(theProduct.Product).done((data: any) => {
      if (data) {
        let formOptions: DevExpress.ui.dxFormOptions = {
          formData: data[0],
          items: theProduct.GetFormControls(),
          labelLocation: 'top',
          readOnly: true
        };

        if (theProduct.IsNavigatorItem) {
          theProduct.ProductForm = $(
            '#' + theProduct.ContentID + theProduct.Time
          )
            .dxForm(formOptions)
            .dxForm('instance');
        } else {
          theProduct.ProductForm = $('#' + theProduct.ContentID)
            .dxForm(formOptions)
            .dxForm('instance');
        }

        d.resolve();
      }
    });

    return d.promise();
  }

  GetFormControls() {
    let theProduct = this;

    let controls: DevExpress.ui.dxFormGroupItem[] = [
      {
        itemType: 'group',
        caption: 'Product Details',
        colCount: 2,
        items: [
          {
            dataField: 'Description',
            colSpan: 2
          },
          {
            dataField: 'ProductGroup',
            colSpan: 1
          },
          {
            dataField: 'Vendor',
            colSpan: 1
          },
          {
            dataField: 'ItemCode',
            colSpan: 1
          },
          {
            dataField: 'ItemCodeAlternative',
            colSpan: 1
          },
          {
            dataField: 'ResourceURL1',
            label: { text: 'Resource Url' },
            colSpan: 2
          }
        ]
      },
      {
        itemType: 'group',
        caption: 'Pricing Details',
        colCount: 3,
        items: [
          {
            dataField: 'UOM'
          },
          {
            dataField: 'Price'
          },
          {
            dataField: 'Cost'
          },
          {
            dataField: 'ListPrice'
          },
          {
            dataField: 'NetPrice'
          },
          {
            dataField: 'RebateCost'
          }
        ]
      },
      {
        itemType: 'group',
        caption: 'Inventory',
        colCount: 3,
        items: [
          {
            dataField: 'QtyOnHand'
          },
          {
            dataField: 'QtyOnOrder'
          },
          {
            dataField: 'QtyAllocated'
          },
          {
            dataField: 'StockStatus'
          },
          {
            dataField: 'WarehouseID'
          },
          {
            dataField: 'Delivery'
          }
        ]
      }
    ];

    return controls;
  }
}
