import { BaseDataCenter } from "../basedatacenter";
import { TDFDataCenters } from "../../interfaces/datacenters/tdfdatacenters";
import { IRollUpInfo } from "../../interfaces/bi/interfaces";
import { IToolbarItemOverrideOptions } from "../../interfaces/grids/interfaces";
import { CenterType, GridContainerTypes } from "../../enums/enums";
import { ISummary } from "../../enums/bi/enums";
import { AddHandler2, RaiseEvent2 } from "../../infrastructure/events/ui_events";
import { eventNameSpace, EventTypes } from "../../enums/webevents/enums";
import { AwesomeToolbar } from "../../components/grids/gridtoolbars/datacentertoolbar";
import { TDFRequest } from "../../services/request";
import {
  DynamicDataScopeSelectorSettings,
  DataScopeSelection,
  DataScopeInitializer,
  DynamicDataScopeSelector
} from "../../components/dashboards/dynamicdatascopeselector";
import {
  DataScopeSelectionTypes,
  DataScopeSelectionMode
} from "../../enums/user/datascope/enums";
import { LoadUser } from "../../infrastructure/context";
import { Preferences } from "../../infrastructure/user/preferences";
import { BiDataCenterTabs } from "../bidatacentertabs";
import { BSIGrids } from "../../enums/bi/enums";
import { GetPreference } from "../../infrastructure/user/getpreference";
import { BICenterMenuDrawer } from "./bi_center_menu_drawer";
import { GetDevice } from "util/allutils";

/** A object the displays BI data that has and vartical sidebar menu and Tabs that display grids of data with defined gridviews  */
export class BIDataCenter extends BaseDataCenter {
  public static BIDynamicDataScopeSelectorSettings: DynamicDataScopeSelectorSettings = {
    ScopeSelectionType: DataScopeSelectionTypes.BIScope,
    DialogTitle: "Summary Settings",
    AllowDefault: true,
    SelectionMode: DataScopeSelectionMode.Multiple
  };

  private RollupInfoURL: string = "/bi/GetRollupInfo";

  protected MyCreationArgs: TDFDataCenters.ICRMDataCenterCreationArgs;
  get CenterType(): CenterType {
    return CenterType.Bi;
  }

  protected get DefaultItem(): BSIGrids {
    return BSIGrids.SalesSummary;
  }

  protected _ContainerType: GridContainerTypes = GridContainerTypes.BSISummary;
  get ContainerType(): GridContainerTypes {
    return this._ContainerType;
  }

  /**The jquery container that contains the toolbar */
  private _DataToolbarAreaContainer: JQuery;
  get DataToolbarAreaContainer(): JQuery {
    let bidc = this;
    if (bidc._DataToolbarAreaContainer) return bidc._DataToolbarAreaContainer;
    let container: JQuery;
    if (bidc.MyCreationArgs && bidc.MyCreationArgs.DataToolbarAreaContainer) {
      container = $(bidc.MyCreationArgs.DataToolbarAreaContainer);
    }
    if (container && container.length) {
      bidc._DataToolbarAreaContainer = container;
    } else {
      /*Add to MainDataAreaContainer*/
      bidc.DataToolbarAreaContainer = $(
        `<div id='crm-datacenter-datatoolbar-container${Date.now()}' />`
      ).appendTo(bidc.MainDataAreaContainer);
    }

    if (
      !jQuery.contains(document.documentElement, bidc.MainDataAreaContainer[0])
    ) {
      //Element is detached
      throw "Unable to find container => _DataToolbarAreaContainer ";
    }
    return bidc._DataToolbarAreaContainer;
  }
  set DataToolbarAreaContainer(val: JQuery) {
    let bidc = this;
    if (val) bidc._DataToolbarAreaContainer = val;
  }

  /**The jquery container that contains tabs that contain the grids of data */
  private _DataTabsAreaContainer: JQuery;
  get DataTabsAreaContainer(): JQuery {
    let bidc = this;
    if (bidc._DataTabsAreaContainer) return bidc._DataTabsAreaContainer;
    let container: JQuery;
    if (bidc.MyCreationArgs && bidc.MyCreationArgs.DataTabsAreaContainer) {
      container = $(bidc.MyCreationArgs.DataTabsAreaContainer);
    }
    if (container && container.length) {
      bidc.DataTabsAreaContainer = container;
    } else {
      /*Add to MainDataAreaContainer*/
      bidc.DataTabsAreaContainer = $(
        `<div id='crm-datacenter-datatabs-container${Date.now()}'/>`
      ).appendTo(bidc.MainDataAreaContainer);
    }
    if (
      !jQuery.contains(document.documentElement, bidc.MainDataAreaContainer[0])
    ) {
      //Element is detached
      throw "Unable to find container => _DataTabsAreaContainer ";
    }

    return bidc._DataTabsAreaContainer;
  }
  set DataTabsAreaContainer(val: JQuery) {
    let bidc = this;
    if (val) bidc._DataTabsAreaContainer = val;
  }

  /**The jquery container that contains tabs that contain the grids of data */
  private _DataGridAreaContainer: JQuery;
  get DataGridAreaContainer(): JQuery {
    let bidc = this;
    if (bidc._DataGridAreaContainer) return bidc._DataGridAreaContainer;
    let container: JQuery;
    if (bidc.MyCreationArgs && bidc.MyCreationArgs.DataGridAreaContainer) {
      container = $(bidc.MyCreationArgs.DataGridAreaContainer);
    }
    if (container && container.length) {
      bidc.DataGridAreaContainer = container;
    } else {
      /*Add to MainDataAreaContainer*/
      bidc.DataGridAreaContainer = $(
        `<div id='crm-datacenter-datagrid-container${Date.now()}'/>`
      ).appendTo(bidc.MainDataAreaContainer);
    }
    if (
      !jQuery.contains(document.documentElement, bidc.MainDataAreaContainer[0])
    ) {
      //Element is detached
      throw "Unable to find container => _DataTabsAreaContainer ";
    }

    return bidc._DataGridAreaContainer;
  }
  set DataGridAreaContainer(val: JQuery) {
    let bidc = this;
    if (val) bidc._DataGridAreaContainer = val;
  }

  protected summarySettings: DataScopeSelection;
  public get SummarySettings(): DataScopeSelection {
    let bidc = this;
    return bidc.summarySettings;
  }
  /**
   * If creation args are not provided the appropriate containers will be added to tdfbodycontent
   * @param OPTIONAL creationArgs
   */
  constructor(
    creationArgs: TDFDataCenters.ICRMDataCenterCreationArgs = null,
    customName?: string
  ) {
    super(customName);
    let bidc = this;
    bidc.ValidateCreationArgs(creationArgs);
  }

  public InitAndRender() {
    let bidc = this;

    LoadUser().done(function () {
      bidc.InitializeSummarySettings().done(function () {
        bidc.initContainers();
        bidc.RegisterListeners();
        bidc.SubscribeToMenuEvents();
        bidc.initMenu();
        bidc.initTabs();

      });
    });
  }

  protected InitializeSummarySettings(): JQueryPromise<any> {
    let bidc = this;
    let dfd: JQueryDeferred<any> = $.Deferred();

    let dataScopeInitializer: DataScopeInitializer = {
      ActiveSelectionLevel: parseInt(GetPreference("HomeType", "BSISummary")),
      SelectedItems: GetPreference("HomeList", "BSISummary").split(",").map((item) => { return ({ ItemID: item, Name: "" }); })
    };

    bidc.summarySettings = new DataScopeSelection(bidc.CustomName);
    bidc.summarySettings.InitSettings(dataScopeInitializer);

    return dfd.promise(dfd.resolve());
  }

  private RegisterListeners() {
    let bidc = this;

    AddHandler2(EventTypes.BIEventTypes.currentsummaryselection, bidc.CustomName, eventNameSpace.request, bidc.DataToolbarAreaContainer, bidc.OnSummarySettingsRequested.bind(bidc));
    AddHandler2(EventTypes.BIEventTypes.DisplayBISummarySelection, bidc.CustomName, eventNameSpace.request, bidc.DataToolbarAreaContainer, bidc.DisplaySummarySettings.bind(bidc));
    AddHandler2(EventTypes.BIEventTypes.RollUp, bidc.CustomName, eventNameSpace.request, bidc.DataToolbarAreaContainer, bidc.OnRollUp.bind(bidc));
    AddHandler2(EventTypes.BIEventTypes.RollDown, bidc.CustomName, eventNameSpace.request, bidc.DataToolbarAreaContainer, bidc.OnRollDown.bind(bidc));
  }

  private SubscribeToMenuEvents() {
    let bidc = this;
    //NEEDTODO - can this move to the RegisterListeners?
    AddHandler2(
      EventTypes.CenterEventTypes.itemtype,
      bidc.CustomName,
      eventNameSpace.modify,
      bidc.MainAreaContainer,
      bidc.OnMenuSelectionChanged.bind(bidc)
    );

    AddHandler2(
      EventTypes.CenterEventTypes.loaddefaulttoolbar,
      bidc.CustomName,
      eventNameSpace.notify,
      bidc.MainAreaContainer,
      bidc.LoadDefaultToolbar.bind(bidc)
    );
  }

  private OnRollUp(e: JQueryEventObject, data) {
    let bidc = this;

    // 1.  Get rollup (BillTo or Master Account) Info
    bidc.GetRollupInfo().done(function () {
      if (bidc.SummarySettings.ActiveSelectionLevel === ISummary.SummaryInterfaces.cBSID.EnumType.AcctID) {
        // 2.  Update roll-up context for the roll-up
        let dataScopeInitializer: DataScopeInitializer = {
          ActiveSelectionLevel: ISummary.SummaryInterfaces.cBSID.EnumType.BillTo,
          SelectedItems: [{ ItemID: bidc.RollupInfo.CustomerID, Name: bidc.RollupInfo.CustomerName }]
        };

        bidc.SummarySettings.ReInitSettings(dataScopeInitializer);

        //// 3. Set button visibility
        let showRollupBtn = (bidc.CenterType == CenterType.AccountBi) && (bidc.RollupInfo.MasterID !== "");
        RaiseEvent2(EventTypes.BIEventTypes.showHideRollUpButtons, bidc.CustomName, eventNameSpace.request, { ShowRollUpButton: showRollupBtn, ShowRollDownButton: true });
      }
      else if (bidc.SummarySettings.ActiveSelectionLevel === ISummary.SummaryInterfaces.cBSID.EnumType.BillTo) {
        if (bidc.RollupInfo.MasterID !== "") {

          // 2.  Update roll-up context for the roll-up
          let dataScopeInitializer: DataScopeInitializer = {
            ActiveSelectionLevel: ISummary.SummaryInterfaces.cBSID.EnumType.Master,
            SelectedItems: [{ ItemID: bidc.RollupInfo.MasterID, Name: bidc.RollupInfo.MasterName }]
          };

          bidc.SummarySettings.ReInitSettings(dataScopeInitializer);
        }
        else {
          alert("Cannot roll up as there is no master account associated with this account.")
        }
        //// 3. Set button visibility
        RaiseEvent2(EventTypes.BIEventTypes.showHideRollUpButtons, bidc.CustomName, eventNameSpace.request, { ShowRollUpButton: false, ShowRollDownButton: true });
      }

      // 5.  Refresh data
      //theBICenter.RefreshScopeStuff();
      RaiseEvent2(EventTypes.BIEventTypes.currentsummaryselection, bidc.CustomName, eventNameSpace.notify, { NewSummarySettings: bidc.SummarySettings });
    });
  }

  private RollupInfo: IRollUpInfo = undefined;
  private GetRollupInfo(): JQueryPromise<IRollUpInfo> {
    let bidc = this;
    let dfd: JQueryDeferred<IRollUpInfo> = $.Deferred();

    if (bidc.RollupInfo) {
      return (dfd.promise(dfd.resolve(bidc.RollupInfo)));
    }
    else {
      let request = new TDFRequest({
        url: bidc.RollupInfoURL, type: "GET",
        data: {
          biListing: bidc.SummarySettings.CurrentlySelectedItemIDsAsString(),
          biLevel: bidc.SummarySettings.ActiveSelectionLevel,
        }
      });

      request.MakeRequest().done(function (response) {
        bidc.RollupInfo = response;
        return (dfd.resolve(bidc.RollupInfo));
      });
    }

    return (dfd.promise());
  }

  private OnRollDown(e: JQueryEventObject, data) {
    let bidc = this;

    // 1.  Get rollup (BillTo or Master Account) Info
    bidc.GetRollupInfo().done(function () {
      if (bidc.SummarySettings.ActiveSelectionLevel === ISummary.SummaryInterfaces.cBSID.EnumType.BillTo) {
        // 2.  Update roll-up context for the roll-up
        let dataScopeInitializer: DataScopeInitializer = {
          ActiveSelectionLevel: ISummary.SummaryInterfaces.cBSID.EnumType.AcctID,
          SelectedItems: [{ ItemID: bidc.RollupInfo.AccountID, Name: bidc.RollupInfo.AccountName }]
        };

        bidc.SummarySettings.ReInitSettings(dataScopeInitializer);

        //// 3. Set button visibility
        RaiseEvent2(EventTypes.BIEventTypes.showHideRollUpButtons, bidc.CustomName, eventNameSpace.request, { ShowRollUpButton: true, ShowRollDownButton: false });
      }
      else if (bidc.SummarySettings.ActiveSelectionLevel === ISummary.SummaryInterfaces.cBSID.EnumType.Master) {

        // 2.  Update roll-up context for the roll-up
        let dataScopeInitializer: DataScopeInitializer = {
          ActiveSelectionLevel: ISummary.SummaryInterfaces.cBSID.EnumType.BillTo,
          SelectedItems: [{ ItemID: bidc.RollupInfo.CustomerID, Name: bidc.RollupInfo.CustomerName }]
        };

        bidc.SummarySettings.ReInitSettings(dataScopeInitializer);

        //// 3. Set button visibility
        let showRollupBtn = (bidc.CenterType == CenterType.AccountBi) && (bidc.RollupInfo.MasterID !== "");
        RaiseEvent2(EventTypes.BIEventTypes.showHideRollUpButtons, bidc.CustomName, eventNameSpace.request, { ShowRollUpButton: showRollupBtn, ShowRollDownButton: true });
      }

      // 5.  Refresh data
      //theBICenter.RefreshScopeStuff();
      RaiseEvent2(EventTypes.BIEventTypes.currentsummaryselection, bidc.CustomName, eventNameSpace.notify, { NewSummarySettings: bidc.SummarySettings });
    });
  }

  protected GetScopeSelectorSettings() {
    let bidc = this;
    return BIDataCenter.BIDynamicDataScopeSelectorSettings;
  }

  DisplaySummarySettings(e: JQueryEventObject, data) {
    let bidc = this;
    let callBack = function () {
      RaiseEvent2(EventTypes.BIEventTypes.currentsummaryselection, bidc.CustomName, eventNameSpace.notify, { NewSummarySettings: bidc.SummarySettings });
      bidc.RollupInfo = undefined;
    };

    //NEEDTODO - this needs to limit what options are allowed based on BI Type -> Account BI doesn't let you pick from Level4, Level3, etc.  Summary Setttings from Account BI also does not let you set default.
    let sumSettingSelector = new DynamicDataScopeSelector(
      bidc.SummarySettings,
      bidc.GetScopeSelectorSettings()
    );

    sumSettingSelector.DisplayPanel(callBack);
  }

  OnSummarySettingsRequested(e: JQueryEventObject, data) {
    let bidc = this;
    data.deferred.resolve(bidc.SummarySettings);
  }

  OnMenuSelectionChanged(e: JQueryEventObject, data) {
    let bidc = this;

    if (bidc.DefaultToolbarLoaded) {
      bidc.DefaultToolbarLoaded = false;

      bidc.DataToolbarAreaContainer.empty();
    }

    bidc.initToolbar(data);

  }

  GetToolbarItemArgs(data) {
    let bidc = this;

    let args: any = {
      gridContainerType: bidc.ContainerType,
      CenterType: bidc.CenterType,
      UniqueCenterName: bidc.CustomName
    }

    if (!bidc.DefaultToolbarLoaded) {
      args.ItemType = data.ItemType;
    }

    return args;
  }

  protected DefaultToolbarLoaded: boolean;

  protected get ToolbarItems() {
    let bidc = this;

    let toolbarItems: IToolbarItemOverrideOptions[] = [
      { name: "MenuButton", index: 0, location: "before" },
      { name: "BIDataCenterSummarySelector", index: 4, location: "before" },
      { name: "BIDCTagCloud", index: 5, location: "before", visible: !GetDevice().isDevice },
    ];

    if (!bidc.DefaultToolbarLoaded) {

      toolbarItems = toolbarItems.concat([
        { name: "OpenButton", index: 1, location: "before" },
        { name: "ActionButton", index: 2, location: "before" },
        { name: "ViewButton", index: 3, location: "before" },
        { name: "BIViewLookup", index: 6, location: "after", locateInMenu: 'never' },
        { name: "RefreshButton", index: 7, location: "after", locateInMenu: 'auto' },
        { name: "RollDownButton", index: 9, location: "after", visible: bidc.CenterType == CenterType.AccountBi, locateInMenu: 'auto' }, // NEEDTODO:  Move this to the Acct BI Center?  
        { name: "RollUpButton", index: 10, location: "after", visible: false, locateInMenu: 'auto' }, // NEEDTODO:  Move this to the Acct BI Center?  
        { name: "OptionsButton", index: 11, location: "after", locateInMenu: 'auto' },
      ]);

      if (Preferences.GetCompanyPreference("UseGoals", "BSISummary", "1") === "1") {
        toolbarItems.push({ name: "BIGoalSelector", index: 8, location: "after", locateInMenu: 'auto' });
      }
    }

    return toolbarItems
  }

  LoadDefaultToolbar() {
    let bidc = this;

    bidc.DefaultToolbarLoaded = true;

    bidc.initToolbar();
  }

  private initToolbar(data?) {
    let bidc = this;

    if (bidc.DataToolbarAreaContainer.children().length === 0) {
      bidc.MenuAreaContainer.hide();

      new AwesomeToolbar(
        bidc.DataToolbarAreaContainer,
        data && data.ItemType ? data.ItemType : null,
        bidc.ContainerType,
        bidc.CustomName,
        bidc.ToolbarItems,
        bidc.GetToolbarItemArgs(data)
      ).init();
    }
  }

  protected initContainers() {
    super.initContainers();
    let bidc = this;
    let tdc = bidc;
    if (!bidc.DataToolbarAreaContainer.length)
      throw "Error toolbar container missing";
    if (!bidc.DataTabsAreaContainer.length)
      throw "Error tabs container missing";
    if (!bidc.DataGridAreaContainer.length)
      throw "Error grid container missing";
  }

  protected initMenu() {
    let bidc = this;
    new BICenterMenuDrawer(
      bidc.MenuAreaContainer,
      bidc.CenterType,
      bidc.CustomName,
      bidc.MainAreaContainer,
      bidc.InDefaultContainer,
      bidc.DefaultItem
    ).Init();
  }

  initTabs() {
    let bidc = this;
    new BiDataCenterTabs(
      bidc.DataTabsAreaContainer,
      bidc.CenterType,
      bidc.DataGridAreaContainer,
      bidc.CustomName,
      bidc.InDefaultContainer,
    ).Init();
  }

  protected ValidateCreationArgs(
    args: TDFDataCenters.ICRMDataCenterCreationArgs
  ) {
    super.ValidateCreationArgs(args);
    let bidc = this;
    // if (args) {
    //   if (!args.DataToolbarAreaContainer)
    //     //throw "DataToolbarAreaContainer_ID must begin with hashtag";
    //   if (!args.DataTabsAreaContainer)
    //     // throw "DataTabsAreaContainer_ID must begin with hashtag";
    //   if (!args.DataGridAreaContainer)
    //     // throw "DataGridsAreaContainer_ID must begin with hashtag";
    // }
    bidc.MyCreationArgs = args;
  }
}
