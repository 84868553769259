import { Notification } from "../../components/dialogs/notification";
import { itemTypes } from "../../enums/enums";
import { TDFRequest } from "../../services/request";
import dxDataSource from "devextreme/data/data_source";
import dxArrayStore from "devextreme/data/array_store";
import notify from "devextreme/ui/notify";
import { AlertService } from "../../services/tiles/alertservice";
import {
  ReloadUser,
  LoadUser,
  CurrentUser,
  CurrentCompany
} from "../context";
import { HomeListSelection } from "../../components/controls/homelistselection";
import {
  UserPreferenceDefaults,
  CompanyPreferenceDefaults
} from "./preferencedefaults";
export namespace PreferenceModuleNames {
  export const TDFMobile: string = "TDFMobile";
}
export namespace TDFMobilePreferenceNames {
  export const ShowHelpTextAutomatically: string = "ShowHelpTextAutomatically";
}

export interface Pref {
  PropertyName: string,
  PropertyValue: any,
  ModuleName?: string,
  Exists?: boolean
};

declare var HomeListScopeSelectorPanel: any;
export class Preferences {
  static AlertsLoaded: boolean = false;
  static GridDefaultsLoaded: boolean = false;
  static HomeListingLoaded: boolean = false;
  static HomePageLayoutLoaded: boolean = false;
  static load() {
    $.when(LoadUser()).done(function () {
      Preferences.SetScreenValues();
    });

    if (window.opener && window["SectionToLoad"]) {
      Preferences[window["SectionToLoad"]](
        "#gridDefaults",
        window["CurrentGrid"]
      );
    }
  }

  static save(displayResult: boolean = false) {
    try {
      let homeListingID = "scope-settings-container";

      // Some defaults.
      let showpastdue = 1,
        DailySummaryTasksToDisplay = 0,
        DailySummaryItemSource = 1,
        DailySummaryItemOrder = "3,2,12,10",
        includeBIData = 0,
        highlightSearch = 0,
        serverPageSize = 1000,
        id;

      if (arguments.length === 1) id = arguments[0];

      let frm = document.forms["form_user_prefs"];
      if (frm) {
        // Daily Summary Options
        if (frm["ShowPastDueTasks"]) {
          showpastdue = frm["ShowPastDueTasks"].checked ? 1 : 0;
        }
        if (frm["selTasksToDisplay"]) {
          DailySummaryTasksToDisplay = parseInt(frm["selTasksToDisplay"].value);
          if (DailySummaryTasksToDisplay === 3) {
            //TODO: Will need to change this to the bootstrap dialog if can get it working every where!
            let ok = confirm(
              "Including all tasks in Home Account Packages could cause tasks to load very slowly. Would you like to continue saving these settings?"
            );
            if (!ok) {
              return;
            }
          }
        }
        if (frm["daily-nav-source"]) {
          DailySummaryItemSource = parseInt(frm["daily-nav-source"].value);
        }
        DailySummaryItemOrder = "";

        let dailySummaryItems = $("select[id*='sel_daily_']");
        $(dailySummaryItems).each(function (index, obj) {
          if (parseInt(<string>$(obj).val()) !== 0) {
            DailySummaryItemOrder +=
              $(obj)
                .val()
                .toString() + ",";
          }
        });

        // Grid Preferences
        includeBIData = $("#chk_IncludeBIData").is(":checked") ? 1 : 0;
        serverPageSize = <number>$("#server-pages").val();
      }

      let preferencesToSave = [];
      preferencesToSave.push({
        ModuleName: "TDFMobile",
        PropertyName: "ShowPastDueTasks",
        PropertyValue: showpastdue
      });
      preferencesToSave.push({
        ModuleName: "TDFMobile",
        PropertyName: "DailySummaryTasksToDisplay",
        PropertyValue: DailySummaryTasksToDisplay
      });
      preferencesToSave.push({
        ModuleName: "TDFMobile",
        PropertyName: "DailySummaryItemSource",
        PropertyValue: DailySummaryItemSource
      });
      preferencesToSave.push({
        ModuleName: "TDFMobile",
        PropertyName: "DailySummaryItemOrder",
        PropertyValue: DailySummaryItemOrder
      });
      preferencesToSave.push({
        ModuleName: "TDFMobile",
        PropertyName: "IncludeBSIData",
        PropertyValue: includeBIData // WHAT IS THIS???
      });
      preferencesToSave.push({
        ModuleName: "TDFMobile",
        PropertyName: "ServerPageSize",
        PropertyValue: serverPageSize
      });

      let chooser = document.getElementById(homeListingID); // Home List Selector might not be present, so only add home list settings if it is there.
      if (chooser) {
        preferencesToSave.push({
          ModuleName: "ManagersConsole",
          PropertyName: "HomeList",
          PropertyValue: HomeListScopeSelectorPanel.CurrentlySelectedItemsAsString()
        });
        preferencesToSave.push({
          ModuleName: "ManagersConsole",
          PropertyName: "HomeType",
          PropertyValue: HomeListScopeSelectorPanel.ActiveSelectionLevel
        });
      }

      let request = new TDFRequest({
        url: `/core/user/SaveUserPreferences`,
        type: "POST",
        data: { options: preferencesToSave, forceReload: false }
      });
      request.MakeRequest().done(function (result) {
        if (displayResult) {
          new Notification({
            type: "info",
            shading: true,
            message: result.Message
          });
        }
        ReloadUser();
      });
    } catch (e) {
      console.error(e.message, e.stack);
    }
  }

  static SetScreenValues() {
    let settings = this;
    // Daily Summary Options
    if (
      parseInt(settings.GetPreference("ShowPastDueTasks", "TDFMobile")) ==
      Number(true)
    ) {
      $("#chk_ShowPastDueTasks").prop("checked", true);
    }

    if (
      parseInt(
        settings.GetPreference("DailySummaryTasksToDisplay", "TDFMobile")
      ) <= 3
    ) {
      $("#selTasksToDisplay").val(
        Preferences.GetPreference("DailySummaryTasksToDisplay", "TDFMobile")
      );
    }

    $("#daily-nav-source").val(
      settings.GetPreference("DailySummaryItemSource", "TDFMobile")
    );

    // Grid Preferences
    if (
      parseInt(settings.GetPreference("IncludeBSIData", "TDFMobile")) ==
      Number(true)
    ) {
      $("#chk_IncludeBIData").prop("checked", true);
    }
    $("#server-pages").val(
      settings.GetPreference("ServerPageSize", "TDFMobile")
    );
  }

  static LoadAvailableAlerts(includeTitle?: boolean) {

    let that = this;

    let callBack = function (response) {
      let alertInfo = {};
      alertInfo = response;
      let content = "";
      if (includeTitle) {
        content =
          content +
          "<h1 id='AlertsListHeader' class='alert-title'>Mobile Alerts</h1>";
      }
      content = content + "<div id='alerts' class='col-sm-12' > </div>";

      let wrapperElement = $("<div />")
        .attr("id", "alertWrapper")
        .addClass("available-alert")
        .html(content); //new Element("div").WithID("alertWrapper").WithClass("available-alert").WithContent(content).Item();

      let container = $("#alertsList");
      $(container).append(wrapperElement);

      let alertList = new dxDataSource({
        store: alertInfo,
        searchExpr: () => {
          return ["Description"];
        },
        paginate: false
      });

      let alertsList = $("#alerts")
        .dxList({
          dataSource: alertList,
          itemTemplate: function (itemData, itemIndex) {
            if (itemData.Show) {
              this.selectItem(itemIndex);
            }
            let itemID =
              "alert_" +
              itemData.MailingID.replace(RegExp("[()[]{}'\"/?]", "g"), "");
            $("#searchTextBox").dxTextBox({
              placeholder: "Search",
              mode: "search",
              valueChangeEvent: "keyup",
              onValueChanged: function (e) {
                alertList.searchValue(e.value);
                alertList.load();
              }
            });
            return $("<div>")
              .addClass("wordwrap")
              .text(itemData.Description)
              .attr("id", itemID);
          },
          onContentReady: function (response) {
            Preferences.AlertsLoaded = true;
          },
          showSelectionControls: true,
          selectionMode: "multiple",
          onSelectionChanged: function (data) {
            if (Preferences.AlertsLoaded) {
              data.addedItems.forEach(function (item, index) {
                Preferences.UpdateAlertEnrollment(
                  item.MailingID,
                  item.ID,
                  null,
                  1
                );
              });
              data.removedItems.forEach(function (item, index) {
                Preferences.UpdateAlertEnrollment(
                  item.MailingID,
                  item.ID,
                  null,
                  0
                );
              });
            }
          }
        })
        .dxList("instance");
    };

    AlertService.AvailableAlertList().done(callBack);
  }

  static ChangeGridScope(itemData, isDesktop, gridSelectorElement) {
    let useGlobalScope = true;
    useGlobalScope = isDesktop
      ? itemData.Item.ScopeDesktop !== 0
      : itemData.Item.ScopeWeb !== 0;

    //let callback =;

    let data = {
      itemType: itemData.RelatedItemType,
      containerType: itemData.ContainerTypeID,
      isDesktop: isDesktop,
      useGlobalScope: useGlobalScope
    };

    let myRequest = new TDFRequest({
      url: `/core/user/UpdateGridScope`,
      type: "POST",
      data: data
    });
    myRequest.MakeRequest().done(function (response) {
      if (response && response.Valid == true) {
        if (!useGlobalScope) {
          if (isDesktop) {
            itemData.Item.ScopeDesktop = 1;
          } else {
            itemData.Item.ScopeWeb = 1;
          }
          $(gridSelectorElement).children()[0].className = $(
            gridSelectorElement
          )
            .children()[0]
            .className.replace("dx-icon-globe", "dx-icon-home");
        } else {
          if (isDesktop) {
            itemData.Item.ScopeDesktop = 0;
          } else {
            itemData.Item.ScopeWeb = 0;
          }
          $(gridSelectorElement).children()[0].className = $(
            gridSelectorElement
          )
            .children()[0]
            .className.replace("dx-icon-home", "dx-icon-globe");
        }
      }
    });
  }

  static SetViewAsDefault(containerType, itemType, viewID, viewName, isGlobal) {
    let data = {
      containerType: containerType,
      itemType: itemType,
      viewID: viewID,
      isGlobal: isGlobal
    };

    let myRequest = new TDFRequest({
      url: `/core/user/SetViewAsDefault`,
      type: "POST",
      data: data
    });
    myRequest.MakeRequest().done(function (response) {
      var scope = isGlobal ? "global" : "personal";
      var message =
        "Your default " + scope + " view was set to '" + viewName + "'.";
      var type = "success";

      notify(message, type, 2000);
    });
  }

  static GetGridItemString(item) {
    let itemContent = "";
    let className = "global";
    let globalSelected = "selected";
    let personalSelected = "";
    if (item.Global == 0) {
      className = "personal";
      globalSelected = "";
      personalSelected = "selected";
    }

    itemContent = itemContent + "<div class='row'>";
    itemContent = itemContent + "<div class='col-sm-6'>";
    itemContent =
      itemContent + "<p style='text-align:right'>" + item.ItemName + "</p>";
    itemContent = itemContent + "</div>";
    itemContent = itemContent + "<div class='col-sm-4'>";
    itemContent =
      itemContent +
      "<select class='" +
      className +
      " form-control' name='GridScope" +
      item.ItemName +
      "' onchange=\"Preferences.ChangeGridScope('" +
      item.ItemName +
      "', '" +
      item.ItemType +
      "', this)\">";
    itemContent =
      itemContent + "<option " + globalSelected + " value='1'>Global</option>";
    itemContent =
      itemContent +
      "<option value='0' " +
      personalSelected +
      ">Personal</option>";
    itemContent = itemContent + "</select></div>";
    itemContent = itemContent + "</div>";

    return itemContent;
  }

  static LoadGridDefaults(
    placeholderElement,
    ItemType = itemTypes.itemAccount
  ) {
    let that = this;
    let gridTypes = [];
    // defaultItemID = defaultItemID || window["CurrentGrid"] || 1;
    $("#grid-prefs").toggle();

    let myRequest = new TDFRequest({
      url: "/Settings/GetGridTypes",
      type: "POST",
      data: {}
    });
    myRequest.MakeRequest().done(function (response) {
      gridTypes = response.Itemlisting;

      //1.  Empty placeholder
      $(placeholderElement).empty();

      let content = "<div><h4>Manage Default View Scopes:</h4></div>";
      $(placeholderElement).append(content);

      let myLoadGridTypeScopes = function (
        relatedItemType,
        itemName,
        category,
        placeholderScopeElement
      ) {
        that.LoadGridTypeContainerScopes(
          relatedItemType,
          itemName,
          category,
          placeholderScopeElement
        );
      };

      $(placeholderElement).append("<div>Select Grid Type:</div>");
      $(placeholderElement).append("<div id='sel_grid_type'></div>");
      $(placeholderElement).append("<div id='grid_scopes'></div>");

      // 3. Create Select Element
      let lookup = $("#sel_grid_type")
        .dxLookup({
          dataSource: new dxDataSource({
            store: new dxArrayStore({
              data: gridTypes,
              key: "RelatedItemType"
            }),

            searchExpr: () => {
              return ["ItemName"];
            },
            group: "Category"
          }),
          grouped: true,
          closeOnOutsideClick: true,
          displayExpr: "ItemName",
          valueExpr: "RelatedItemType",
          value: ItemType,
          onItemClick: function (selectedItem) {
            let relatedItemType = selectedItem.itemData.RelatedItemType;
            let itemName = selectedItem.itemData.ItemName;
            let category = selectedItem.itemData.Category;
            let scopeElement = $("#grid_scopes");

            myLoadGridTypeScopes(
              relatedItemType,
              itemName,
              category,
              scopeElement
            );
          }
        })
        .dxLookup("instance");

      lookup
        .option("dataSource")
        .store()
        .byKey(ItemType)
        .done(function (val) {
          let clf = lookup.option("onItemClick");
          clf({ itemData: val });
        });

      Preferences.GridDefaultsLoaded = true;
    });
  }

  static LoadGridTypeContainerScopes(
    relatedItemType,
    itemName,
    category,
    placeholderScopeElement
  ) {
    let that = this;
    let containersAndScopes = [];

    let data = {
      itemType: relatedItemType
    };

    let gridTypeScopeRequest = new TDFRequest({
      url: "/Settings/GetGridTypeContainerScopes",
      type: "POST",
      data: data
    });
    gridTypeScopeRequest.MakeRequest().done(function (response) {
      containersAndScopes = response;

      //1.  Empty placeholder
      $(placeholderScopeElement).empty();

      //2.  Create table
      $(placeholderScopeElement).append(
        "<div class='table table-condensed' id='containerScopeGrid'></div>"
      );
      $("#containerScopeGrid").append($("<tr id='containerHeaders'></tr>"));
      $("#containerHeaders").append("<td>Container</td>");
      $("#containerHeaders").append("<td class='center'>Desktop</td>");
      $("#containerHeaders").append("<td class='center'>Web</td>");

      //3.  Loop through containers and add rows for each container type
      $.each(containersAndScopes, function (key, item) {
        let desktopClassName = "dx-icon dx-icon-globe";
        let webClassName = "dx-icon dx-icon-globe";

        if (item.ScopeDesktop == 1) {
          desktopClassName = "dx-icon dx-icon-home";
        }
        if (item.ScopeWeb == 1) {
          webClassName = "dx-icon dx-icon-home";
        }

        let rowID = "containerScope_" + item.ContainerTypeID;
        if ($("#" + rowID)[0] == undefined) {
          $("#containerScopeGrid").append("<tr id='" + rowID + "'></tr>");
          $("#" + rowID).append("<td>" + item.ContainerType + "</td>");

          $("#" + rowID).append(
            "<td class='center' style='padding:5px' id='scopeDsk_" +
            item.ContainerTypeID +
            "'></td>"
          );
          $("#scopeDsk_" + item.ContainerTypeID).append(
            $("<button />")
              .attr({
                id: "dskScopeBtn_" + item.ContainerTypeID,
                style: "",
                value: item.ScopeDesktop,
                title: "Scope (Global / Personal)",
                type: "button"
                //"ContainerTypeID": item.ContainerTypeID,
                //"Scope": item.ScopeDesktop,
                //"RelatedItemType": relatedItemType
              })
              .addClass("btn btn-info")
          );

          $("#dskScopeBtn_" + item.ContainerTypeID).on(
            "click",
            {
              ContainerTypeID: item.ContainerTypeID,
              Item: item,
              RelatedItemType: relatedItemType
            },
            function (e) {
              Preferences.ChangeGridScope(e.data, 1, this);
            }
          );
          $("#dskScopeBtn_" + item.ContainerTypeID).append(
            $("<span />")
              .attr("id", "scope-img_" + item.ContainerTypeID)
              .addClass(desktopClassName)
          );

          $("#" + rowID).append(
            "<td class='center' style='padding:5px' id='scopeWeb_" +
            item.ContainerTypeID +
            "'></td>"
          );
          $("#scopeWeb_" + item.ContainerTypeID).append(
            $("<button />")
              .attr({
                id: "webScopeBtn_" + item.ContainerTypeID,
                style: "",
                value: item.ScopeWeb,
                title: "Scope (Global / Personal)",
                type: "button"
                //"ContainerTypeID": item.ContainerTypeID,
                //"Scope": item.ScopeWeb,
                //"RelatedItemType": relatedItemType
              })
              .addClass("btn btn-info")
          );
          $("#webScopeBtn_" + item.ContainerTypeID).on(
            "click",
            {
              ContainerTypeID: item.ContainerTypeID,
              Item: item,
              RelatedItemType: relatedItemType
            },
            function (e) {
              Preferences.ChangeGridScope(e.data, 0, this);
            }
          );
          $("#webScopeBtn_" + item.ContainerTypeID).append(
            $("<span />")
              .attr("id", "scope-img_" + item.ContainerTypeID)
              .addClass(webClassName)
          );
        }
      });
    });
  }

  static UpdateAlertEnrollment(mailingid, alertid, elementID, iEnroll) {
    let callback = function (response) {
      if (elementID && $("#" + elementID) && iEnroll == 0) {
        //TODO:  TAB - This doesn't work right. the other panels in the accordian stop opening and closing correctly.  I logged ticket #T390794 with dev express support to ask for help.
        // TODO: CLM I'm pretty sure you need to update the datasource of the dxAccordian and refresh it,but i have not looked into it to see how the DS is set
        $($("#" + elementID).parent()).remove();
        // Util.refreshScroller();
      }
    };

    let enrollInfo = {
      MailingID: mailingid,
      ID: alertid,
      Add: iEnroll
    };

    let myRequest = new TDFRequest({
      url: "/Alert/Enroll",
      type: "POST",
      data: enrollInfo
    });
    myRequest.MakeRequest().done(callback);
  }

  static LoadHomeList() {
    if (!Preferences.HomeListingLoaded) {
      HomeListScopeSelectorPanel = new HomeListSelection();
      HomeListScopeSelectorPanel.DisplayPanel();

      Preferences.HomeListingLoaded = true;
    }
  }

  // Note:  Use TDF.UserPreferenceDefaults class to set up default values that will get returned by this method.
  static GetPreference(
    propertyname: string,
    modulename?: string,
    _default: string = ""
  ): string {
    let defaultValueName = "";

    if (modulename) {
      if (modulename.toLowerCase() === "infocenter") {
        modulename = "ManagersConsole";
      }

      defaultValueName = defaultValueName + modulename + "_";
    }
    defaultValueName = defaultValueName + propertyname;
    let defaultValue = UserPreferenceDefaults[defaultValueName] || _default;

    if (!CurrentUser || !CurrentUser.Preferences) return _default;
    let pref = $.grep(CurrentUser.Preferences.PrefObj, function (val: any, idx) {
      if (!modulename) {
        return val.PropertyName === propertyname;
      } else {
        return (
          val.ModuleName === modulename && val.PropertyName === propertyname
        );
      }
    });
    if (pref[0]) {
      return pref[0].PropertyValue || defaultValue || _default;
    } else {
      return defaultValue || _default;
    }
  }

  //static SetGlobalPreference(
  //  propertyName: string,
  //  value: any,
  //  moduleName?: string,
  //  reload = false
  //) {
  //  let dfd = $.Deferred();

  //  let foundProperty = false;

  //  if (moduleName.toLowerCase() === "infocenter") {
  //    moduleName = "ManagersConsole";
  //  }

  //  $.each(CurrentCompany.Preferences.PrefObj, function (key, val) {
  //    if (!moduleName) {
  //      if (val.PropertyName === propertyName) {
  //        val.PropertyValue = value;
  //        foundProperty = true;
  //      }
  //    } else {
  //      if (
  //        val.PropertyName === propertyName &&
  //        val.ModuleName === moduleName
  //      ) {
  //        val.PropertyValue = value;
  //        foundProperty = true;
  //      }
  //    }
  //  });

  //  if (!foundProperty) {
  //    let newProperty: any = {};
  //    newProperty.ModuleName = moduleName;
  //    newProperty.PropertyName = propertyName;
  //    newProperty.PropertyValue = value;

  //    CurrentCompany.Preferences.PrefObj.push(newProperty);
  //  }

  //  sessionStorage.setItem("CurrentCompany", JSON.stringify(CurrentCompany));

  //  // Send new value back to server
  //  let preferencesToSave = [];
  //  preferencesToSave.push({
  //    ModuleName: moduleName,
  //    PropertyName: propertyName,
  //    PropertyValue: value
  //  });

  //  let request = new TDFRequest({
  //    url: "/SettingsUpdater/SaveGlobalPreferences",
  //    type: "POST",
  //    data: { options: preferencesToSave, forceReload: reload }
  //  });
  //  request.ShouldShowSpinner = false;
  //  request.MakeRequest().done(function (result) {
  //    ReloadCompany().done(() => {
  //      dfd.resolve();
  //    });
  //  });

  //  return dfd.promise();
  //}

  static SetPreference(
    propertyname: string,
    value: any,
    modulename?: string,
    reload = false
  ) {
    let dfd = $.Deferred();

    let foundProperty = false;

    if (modulename.toLowerCase() === "infocenter") {
      modulename = "ManagersConsole";
    }

    $.each(CurrentUser.Preferences.PrefObj, function (key, val) {
      if (!modulename) {
        if (val.PropertyName === propertyname) {
          val.PropertyValue = value;
          foundProperty = true;
        }
      } else {
        if (
          val.PropertyName === propertyname &&
          val.ModuleName === modulename
        ) {
          val.PropertyValue = value;
          foundProperty = true;
        }
      }
    });

    if (!foundProperty) {
      let newProperty: any = {};
      newProperty.ModuleName = modulename;
      newProperty.PropertyName = propertyname;
      newProperty.PropertyValue = value;

      CurrentUser.Preferences.PrefObj.push(newProperty);
    }

    // Send values to server. Do not assume success.

    let preferencesToSave = [];
    preferencesToSave.push({
      ModuleName: modulename,
      PropertyName: propertyname,
      PropertyValue: value
    });

    let request = new TDFRequest({
      url: `/core/user/SaveUserPreferences`,
      type: "POST",
      data: preferencesToSave
    });

    request.ShouldShowSpinner = false;
    request.MakeRequest().done(function (result) {
      ReloadUser().done(() => {
        dfd.resolve();
      });
    });

    return dfd.promise();
  }

  static SetPreferences(prefArray: Pref[], reload = false) {

    let d = $.Deferred();

    $.each(CurrentUser.Preferences.PrefObj, (k, v) => {
      $.each(prefArray, (k1, v1) => {
        if (!v.ModuleName) {
          if (v1.PropertyName === v.PropertyName) {
            v1.Exists = true;
          }
        } else {
          if (v1.PropertyName === v.PropertyName && v1.ModuleName === v.ModuleName) {
            v1.Exists = true;
          }
        }
      });
    });

    $.each(prefArray, (k, v) => {
      if (!v.Exists) {
        CurrentUser.Preferences.PrefObj.push({
          ModuleName: v.ModuleName,
          PropertyName: v.PropertyName,
          PropertyValue: v.PropertyValue
        });
      }
    });

    localStorage.setItem('CurrentUser', JSON.stringify(CurrentUser));

    let request = new TDFRequest({
      url: `/core/user/SaveUserPreferences`,
      type: "POST",
      data: prefArray
    });
    request.ShouldShowSpinner = false;
    request.MakeRequest().done(function (result) {
      ReloadUser().done(() => {
        d.resolve();
      });
    });

    return d.promise();
  }

  // Note:  Use TDF.CompanyPreferenceDefaults class to set up default values that will get returned by this method.
  static GetCompanyPreference(
    propertyname: string,
    modulename?: string,
    _default: string = ""
  ) {
    let defaultValueName = "";

    if (modulename) {
      defaultValueName = defaultValueName + modulename + "_";
    }
    defaultValueName = defaultValueName + propertyname;
    let defaultValue = CompanyPreferenceDefaults[defaultValueName] || _default;

    if (!CurrentCompany || !CurrentCompany.Preferences) return _default;
    let pref = $.grep(CurrentCompany.Preferences.PrefObj, function (
      val: any,
      idx
    ) {
      if (!modulename) {
        return val.PropertyName === propertyname;
      } else {
        return (
          val.ModuleName === modulename && val.PropertyName === propertyname
        );
      }
    });
    if (pref[0]) {
      return pref[0].PropertyValue || defaultValue || _default;
    } else {
      return defaultValue || _default;
    }
  }
}
