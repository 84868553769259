import { GetPreference } from "../../infrastructure/user/getpreference";
import { Preferences } from "../../infrastructure/user/preferences";
import { GetDevice } from "../../util/allutils";

// Slide Menu for InfoCenter and BI
export class StickyMenu {
  ParentID: string = "";
  MenuDivID: string = "";
  MainAreaDivID: string = "";
  ButtonDivID: string = "";
  HeaderText: string = "";
  ItemsMenu: JQuery;
  PinPreferenceModule: string;
  PinPreferenceProperty: string;

  constructor(menuDivID: string, mainAreaDivID: string, buttonDivID: string) {
    let stickyMenu = this;
    stickyMenu.MenuDivID = menuDivID;
    stickyMenu.MainAreaDivID = mainAreaDivID;
    stickyMenu.ButtonDivID = buttonDivID;

    stickyMenu.ParentID = $($("#" + stickyMenu.MenuDivID).parent())[0].id;
  }

  toggleMenu() {
    //e.preventDefault();
    let stickyMenu = this;

    var $body = $("#" + stickyMenu.ParentID),
      $page = $("#" + stickyMenu.ParentID + " .slide-main-cell"),
      $menu = $("#" + stickyMenu.ParentID + " .slide-menu-cell"),
      /* Cross browser support for CSS "transition end" event */
      transitionEnd =
        "transitionend webkitTransitionEnd otransitionend MSTransitionEnd";

    /* When the toggle menu link is clicked, animation starts */
    $body.addClass("animating");

    /***
     * Determine the direction of the animation and
     * add the correct direction class depending
     * on whether the menu was already visible.
     */
    if ($body.hasClass("menu-visible")) {
      $body.addClass("left");
    } else {
      $body.addClass("right");
    }

    /***
     * When the animation (technically a CSS transition)
     * has finished, remove all animating classes and
     * either add or remove the "menu-visible" class
     * depending whether it was visible or not previously.
     */
    $page.on(transitionEnd, function() {
      $body.removeClass("animating left right").toggleClass("menu-visible");

      $page.off(transitionEnd);

      if ($body.hasClass("menu-visible")) {
        $("#" + stickyMenu.ParentID + " #expandMenuButton").text(
          "Collapse Menu"
        );
      } else {
        $("#" + stickyMenu.ParentID + " #expandMenuButton").text("Expand Menu");
      }
    });
  }

  BuildMenuStructure() {
    let stickyMenu = this;

    let stickyMenuHTML =
      "<nav id='sidebarnav' class='sidebar' role= 'navigation' >" +
      "<div style='height:100%' >" +
      "<ul id='sideBarMenu' class='sideNav nav navbar' > " +
      "<li class='slide-header'>" +
      "<table width='100%'>" +
      "<tr>" +
      "<td style='width:30px'>" +
      "<span id='mcMenuExpander' class='dx-icon dx-icon-menu mcMenuForward' aria-hidden='true'></span>" +
      "</td>" +
      "<td style='text-align:center;'>" +
      "<span id='slide-menu-header'>" +
      stickyMenu.HeaderText +
      "</span>" +
      "</td>" +
      "<td style='width:30px'>" +
      "<span id='stickyPin' class='fa fa-thumb-tack hidden-xs slide-menu-sticky-btn' aria-hidden='true'></span>" +
      "</td>" +
      "</tr>" +
      "</table>" +
      "</li>" +
      "<li id='itemsMenu' class='slide-body'>" +
      "</li>" +
      "</ul>" +
      "</div>" +
      "</nav>";

    let rootMenuDiv = $(
      "#" + stickyMenu.ParentID + " #" + stickyMenu.MenuDivID
    );
    rootMenuDiv.addClass("slide-menu-cell");
    rootMenuDiv.append($(stickyMenuHTML));

    stickyMenu.ItemsMenu = $("#" + stickyMenu.ParentID + " #itemsMenu");

    let mainDivArea = $(
      "#" + stickyMenu.ParentID + " #" + stickyMenu.MainAreaDivID
    );
    mainDivArea.addClass("slide-main-cell");

    // On Click for push pin button
    $("#" + stickyMenu.ParentID + " #stickyPin").on("click", function() {
      if (!$(this).hasClass("stickyPinOn")) {
        stickyMenu.PinOpen();
      } else {
        stickyMenu.UnPin();
      }
    });

    if (!stickyMenu.KeepOpenPref() || stickyMenu.OnPhone()) {
      $("#" + stickyMenu.ParentID + " #stickyPin").removeClass("stickyPinOn");
    } else if (stickyMenu.KeepOpenPref() && !stickyMenu.OnPhone()) {
      $("#" + stickyMenu.ParentID + " #stickyPin").addClass("stickyPinOn");
      stickyMenu.HideSlideMenu();
    }

    /***
     * Run this code when the #toggle-menu link has been tapped
     * or clicked
     */
    $("#" + stickyMenu.ParentID + " .mcMenuForward").on("click", function() {
      stickyMenu.toggleMenu();
    });
  }

  PinOpen() {
    let stickyMenu = this;

    $("#" + stickyMenu.ParentID + " #keepMenuOpenBtn").text(
      "Remove Sticky Menu"
    );
    $("#" + stickyMenu.ParentID + " #stickyPin").addClass("stickyPinOn");
    Preferences.SetPreference(
      stickyMenu.PinPreferenceProperty,
      1,
      stickyMenu.PinPreferenceModule
    );

    stickyMenu.HideSlideMenu();
  }

  UnPin() {
    let stickyMenu = this;

    Preferences.SetPreference(
      stickyMenu.PinPreferenceProperty,
      0,
      stickyMenu.PinPreferenceModule
    );
    $("#" + stickyMenu.ParentID + " #stickyPin").removeClass("stickyPinOn");
    $("#" + stickyMenu.ParentID + " #keepMenuOpenBtn").text("Keep Menu Open");
    $("#" + stickyMenu.ParentID + " #expandMenuButton").text("Expand Menu");
    stickyMenu.ShowSlideMenu();
  }

  BuildButtonStructure() {
    let stickyMenu = this;

    let keepOpenBtnText: string = "Remove Sticky Menu";
    if (!stickyMenu.KeepOpenPref() && !stickyMenu.OnPhone()) {
      keepOpenBtnText = "Keep Menu Open";
    }

    let html: string =
      "<button style='margin-top:20px' id='expandMenuButton' class='btn btn-warning expandMenuButtonCenter' >Expand Menu</button>" +
      "<br />" +
      "<button style='margin-top:20px' id='keepMenuOpenBtn' class='btn btn-info btn-xs slide-menu-sticky-btn hidden-xs'>" +
      keepOpenBtnText +
      "</button>";

    $("#" + stickyMenu.ParentID + " #" + stickyMenu.ButtonDivID).append(html);

    $("#" + stickyMenu.ParentID + " #expandMenuButton").on("click", function() {
      stickyMenu.toggleMenu();
    });

    // On Click for "keepMenuOpenBtn"
    $("#" + stickyMenu.ParentID + " #keepMenuOpenBtn").on("click", function() {
      if ($(this).text() === "Keep Menu Open") {
        stickyMenu.PinOpen();
      } else {
        stickyMenu.UnPin();
      }
    });

    if (stickyMenu.KeepOpenPref()) {
      $("#" + stickyMenu.ParentID + " #expandMenuButton").hide();
    }
  }

  HideSlideMenu() {
    let stickyMenu = this;

    //hide menu btns
    $("#" + stickyMenu.ParentID + " .mcMenuForward").hide();
    $("#" + stickyMenu.ParentID + " #expandMenuButton").hide();

    //position screen
    $("#" + stickyMenu.ParentID + " .slide-main-cell")
      .css("width", "100%")
      .css("width", "-=196px");

    let timer;
    timer = setInterval(function() {
      if ($("#" + stickyMenu.ParentID + " .center-menu-text").length) {
        clearInterval(timer);
      }
    }, 100);

    if (!$("#" + stickyMenu.ParentID).hasClass("menu-visible")) {
      $("#" + stickyMenu.ParentID).toggleClass("menu-visible");
    }

    $("#" + stickyMenu.ParentID + " .McMenuIcons").off("click");
  }

  ShowSlideMenu() {
    let stickyMenu = this;

    //show menu btns
    $("#" + stickyMenu.ParentID + " .mcMenuForward").show();
    $("#" + stickyMenu.ParentID + " #expandMenuButton").show();

    //position screen
    $("#" + stickyMenu.ParentID + " .slide-main-cell")
      .css("width", "100%")
      .css("width", "-=30px");

    $("#" + stickyMenu.ParentID + " .McMenuIcons").on(
      "click",
      stickyMenu.McMenuIconClick.bind(stickyMenu)
    ); // function () {
    //stickyMenu.McMenuIconClick();
    //});

    if ($("#" + stickyMenu.ParentID).hasClass("menu-visible")) {
      $("#" + stickyMenu.ParentID).toggleClass("menu-visible");
    }
  }

  CollapseMenu() {
    let stickyMenu = this;
    var $body = $("#" + stickyMenu.ParentID),
      $page = $("#" + stickyMenu.ParentID + " .slide-main-cell"),
      $menu = $("#" + stickyMenu.ParentID + " .slide-menu-cell"),
      /* Cross browser support for CSS "transition end" event */
      transitionEnd =
        "transitionend webkitTransitionEnd otransitionend MSTransitionEnd";

    /* When the toggle menu link is clicked, animation starts */
    $body.addClass("animating");

    /***
     * Collapse menu by adding left class
     */
    $body.addClass("left");

    $page.on(
      "webkitTransitionEnd otransitionend oTransitionEnd msTransitionEnd transitionend",
      function() {
        $body.removeClass("animating left right");

        if ($body.hasClass("menu-visible")) {
          $body.removeClass("menu-visible");
        }

        $page.off(transitionEnd);

        if ($body.hasClass("menu-visible")) {
          $("#" + stickyMenu.ParentID + " #expandMenuButton").text(
            "Collapse Menu"
          );
        } else {
          $("#" + stickyMenu.ParentID + " #expandMenuButton").text(
            "Expand Menu"
          );
        }
      }
    );
  }

  McMenuIconClick() {
    let stickyMenu = this;

    if ($("#" + stickyMenu.ParentID).hasClass("menu-visible")) {
      stickyMenu.CollapseMenu();
    }
  }

  KeepOpenPref(): boolean {
    let stickyMenu = this;
    var pref: number;
    var keepOpen: boolean = false;

    pref = parseInt(
      Preferences.GetPreference(
        stickyMenu.PinPreferenceProperty,
        stickyMenu.PinPreferenceModule
      )
    );

    if (pref) {
      keepOpen = true;
    }

    return keepOpen;
  }

  OnPhone(): boolean {
    return GetDevice().device.deviceType === "phone";
  }

  InitializeStickySettingsForMenuItems() {
    let stickyMenu = this;

    // If preference is off and we are not on mobile device
    if (!stickyMenu.KeepOpenPref()) {
      $("#" + stickyMenu.ParentID + " .McMenuIcons").on(
        "click",
        stickyMenu.McMenuIconClick.bind(stickyMenu)
      );
    } else {
      $("#" + stickyMenu.ParentID + " .McMenuIcons").off("click");
    }
  }
}
