import { Notification } from '../dialogs/notification';

import { TDFRequest } from '../../services/request';
import { ItemBase } from './itembase';
import { Download } from '../../util/allutils';
import { itemTypes } from '../../enums/enums';
import { DisplayInfo } from '../../infrastructure/context';
import Cookies = require('js-cookie');
import * as AttachmentViewer from "../controls/attachmentviewer";

export class Email extends ItemBase {
  DisplayName: string;
  get TypeName() {
    return 'Email';
  }
  set TypeName(name) {
    if (name && this.TypeName !== name) this.TypeName = name;
  }
  get ItemType() {
    return itemTypes.itemEmail;
  }
  get RequiresParentItem(): boolean {
    return true;
  }
  EmailData: IEmailData;
      Attachments: [];
  NoHtml: boolean = false; //This is true only if the email does not have an HtmlBody field.
  constructor(args) {
    super(args);
    let theEmail = this;
    $.each(args, function(key, val) {
      if (theEmail.hasOwnProperty(key)) theEmail[key] = val;
    });

    if (!theEmail.DisplayName) {
      DisplayInfo(theEmail.ItemType).done(function(displayinfo) {
        theEmail.DisplayName = displayinfo.DisplayName;
        theEmail.TypeName = displayinfo.TypeName;
      });
    }
  }

  GetControlGroups() {
    let theEmail = this;
    let dfd = $.Deferred();
    let rq1 = new TDFRequest({
      url: '/item/getcontrolgroups/',
      type: 'GET',
      data: { itemtype: theEmail.ItemType, itemid: theEmail.ItemID }
    });

    let rq2 = new TDFRequest({
      url: '/item/getemail/',
      data: { itemid: theEmail.ItemID }
    });

    $.when(rq1.MakeRequest(), rq2.MakeRequest()).done(function(
      r1: any,
      r2: any
    ) {
      if (true) {
        theEmail.EmailData = r2[0];
      }

      r1.controlgroups.unshift({
        BackColor: '',
        ContolSet: 0,
        ControlGroupID: 'Email_Summary_Group',
        ControlGroupOrder: 0,
        CustomControlEnabled: false,
        DefaultTab: true,
        DesignMode: { CanUserEditTabType: true },
        EditDisplayName:
          'Profile - Summary (Key Information on Account Creation)',
        FrameCaption: 'Email Details',
        GroupName: 'Main',
        IsCustomTab: false,
        ItemType: itemTypes.itemEmail,
        MaxRows: 5,
        Name: 'Email_Summary',
        TDFGridEnabled: false,
        TabName: '',
        UDFEnabled: true,
        Visible: true
      });

      //only showing the email details tab for now , still getting the other controls for future use
      if (true) {
        theEmail.ControlGroups = [
          {
            BackColor: '',
            ContolSet: 0,
            ControlGroupID: 'Email_Summary_Group',
            ControlGroupOrder: 0,
            CustomControlEnabled: false,
            DefaultTab: true,
            DesignMode: { CanUserEditTabType: true },
            EditDisplayName:
              'Profile - Summary (Key Information on Account Creation)',
            FrameCaption: 'Email Details',
            GroupName: 'Main',
            IsCustomTab: false,
            ItemType: itemTypes.itemEmail,
            MaxRows: 5,
            Name: 'Email_Summary',
            TDFGridEnabled: false,
            TabName: '',
            UDFEnabled: true,
            Visible: true
          }
        ]; // r1.Data.controlgroups;
        theEmail.FormTypeInfo = r1.FormTypeInfo;
        theEmail.EnableDelete = r1.EnableDelete;
        theEmail.iMain = r1.imain;
        if (
          theEmail.ItemType === itemTypes.itemTask &&
          typeof r1.EnableReassign !== 'undefined'
        ) {
          theEmail.EnableReassign = r1.EnableReassign;
        }
        theEmail.CanEdit = r1.EnableEdit;
        dfd.resolve(theEmail);
      } else {
        new Notification({
          type: 'error',
          shading: true,
          displayTime: 5000,
          message: r1.Message || 'Something went wrong.'
        });
      }
    });

    return dfd.promise(theEmail);
  }

  ToolbarItems() {

		let theEmail = this;

		var url = TDFRequest.GetApiLink(`/attachment/getemail/${theEmail.ItemID}`);

    theEmail.DefaultToolbarButtons = [
      {
        location: 'before',
        locateInMenu: 'auto',
        showText: 'always',
        html: `<a download href="${url}">
                            <div class="dx-button dx-button-default dx-widget dx-button-has-icon dx-button-has-text" role="button" aria-label="Reply" title="Open this Message in Email Client" tabindex="0">
                                <div class="dx-button-content">
                                    <i class="dx-icon fa fa"></i>
                                    <span class="dx-button-text">Reply</span>
                                </div>
                            </div>
                            </a>`

      },

      {
        location: 'before',
        locateInMenu: 'auto',
        showText: 'always',
        html: `<a download href="${url}">
                            <div class="dx-button dx-button-default dx-widget dx-button-has-icon dx-button-has-text" role="button" aria-label="Forward" title="Open this Message in Email Client" tabindex="0">
                                <div class="dx-button-content">
                                    <i class="dx-icon fa fa-share"></i>
                                    <span class="dx-button-text">Forward</span>
                                </div>
                            </div>
                            </a>`
 
      },
      {
        location: 'before',
        locateInMenu: 'auto',
        showText: 'always',
        options: {
          icon: 'fa fa-print',
          hint: 'Print',
          text: 'Print',
          visible: false,
          onClick: function() {}
        },
        widget: 'dxButton'
      },
      {
        location: 'before',
        locateInMenu: 'auto',
        showText: 'always',
        html: `<a download href="${url}">
                            <div class="dx-button dx-button-default dx-widget dx-button-has-icon dx-button-has-text" role="button" aria-label="Open Message" title="Open this Message in Email Client" tabindex="0">
                                <div class="dx-button-content">
                                    <i class="dx-icon dx-icon-folder"></i>
                                    <span class="dx-button-text">Open Message</span>
                                </div>
                            </div>
                            </a>`

        },

        {

            widget: "dxButton",
            options: {
                onClick: function () {
                    let fileViewer =  new AttachmentViewer.AttachmentViewer(theEmail.ItemID, itemTypes.itemEmail, null, theEmail.Attachments);
                    fileViewer.AllowAddNewFile = false;
                    fileViewer.AllowAddImage = false;
                    fileViewer.DisplayFiles();
                },
                text: `Attachments (${theEmail.EmailData.AttachmentCount})`
            },

            location: "before", locateInMenu: "auto", showText: "always"

        }

    ];
    return theEmail.DefaultToolbarButtons;
  }

  RenderControls(itemData, container) {
    let theEmail = this;
    let dfd = $.Deferred();

    if (!itemData.IsCustomTab) {
      let id = itemData.ControlGroupID;
      let group = container;
      if (theEmail.Controls && theEmail.Controls[id]) {
        if (id === 'Email_Summary_Group') {
          theEmail.RenderEmailControls(theEmail.Controls[id], container, dfd);
        }
        //    else {
        //        theEmail.GetControlElements(theEmail.Controls[id], container, dfd)
        //    }
      }
      //else {
      //    theEmail.GetControls(id).done(function () {
      //        theEmail.GetControlElements(theEmail.Controls[id], container, dfd)
      //    });
      //}
    }
    //else {
    //    theEmail.RenderCustomTab(itemData, container, dfd);
    //}

    return dfd.promise();
  }

  RenderEmailControls(id, container, dfd) {
    let theEmail = this;

    let formItems: DevExpress.ui.dxFormSimpleItem[] = [
      {
        dataField: 'FromAddress',
        colSpan: 1,
        label: { text: 'From' },
        editorType: 'dxTextBox',
        editorOptions: { disabled: true }
      },
      {
        dataField: 'SentDate',
        colSpan: 1,
        label: { text: 'Sent Date' },
        editorType: 'dxTextBox',
        editorOptions: { disabled: true }
      },
      {
        dataField: 'ToAddress',
        colSpan: 1,
        label: { text: 'To' },
        editorType: 'dxTextBox',
        editorOptions: { disabled: true }
      },
      {
        dataField: 'Priority',
        colSpan: 1,
        label: { text: 'Priority' },
        editorType: 'dxTextBox',
        editorOptions: { disabled: true }
      },
      {
        dataField: 'Subject',
        colSpan: 1,
        label: { text: 'Subject' },
        editorType: 'dxTextBox',
        editorOptions: { disabled: true }
      },
      {
        dataField: 'Sensitivity',
        colSpan: 1,
        label: { text: 'Sensitivity' },
        editorType: 'dxTextBox',
        editorOptions: { disabled: true }
      },
      {
        dataField: 'CC',
        colSpan: 1,
        label: { text: 'CC' },
        editorType: 'dxTextBox',
        editorOptions: { disabled: true }
      },
      {
        dataField: 'ThreadTopic',
        colSpan: 1,
        label: { text: 'Thread Topic' },
        editorType: 'dxTextBox',
        editorOptions: { disabled: true }
      },
      {
        dataField: 'BCC',
        colSpan: 1,
        label: { text: 'BCC' },
        editorType: 'dxTextBox',
        editorOptions: { disabled: true }
      },
      {
        dataField: 'ThreadIndex',
        colSpan: 1,
        label: { text: 'Thread Index' },
        editorType: 'dxTextBox',
        editorOptions: { disabled: true }
      },
      {
        dataField: 'Body',
        visible: false,
        label: { text: 'TBody' },
        editorType: 'dxTextArea',
        editorOptions: { disabled: true }
      },
      {
        dataField: 'HtmlBody',
        visible: false,
        colSpan: 2,
        label: { text: 'Body' },
        editorType: 'dxTextArea',
        editorOptions: { disabled: true }
      },
      {
        dataField: 'EmailID',
        visible: false,
        label: { text: '' },
        editorType: 'dxTextBox',
        editorOptions: { disabled: true }
      }
    ];
    let opts: DevExpress.ui.dxFormOptions = {
      colCountByScreen: {
        lg: 2,
        md: 2,
        sm: 2,
        xs: 1
      },
      formData: theEmail.EmailData,
      items: formItems
    };

    let bodySection = $('<div />').attr({
      id: 'email-body-html' + theEmail.DomSafeID,
      style: 'margin-top:15px;'
    });

    if (theEmail.EmailData.HtmlBody) {
      theEmail.NoHtml = false; // Just to make sure it stays false
      bodySection.html(theEmail.EmailData.HtmlBody);
    } else {
      theEmail.NoHtml = true;
      bodySection.text(theEmail.EmailData.Body);
    }

    container.append($('<div />').dxForm(opts)).append(bodySection);
          try {

            console.time("Image stuff");
            
            // try to translate any cid:____ style urls to a SingleFileFromEmail style URL.
            let theBody = document.getElementById("email-body-html" + theEmail.DomSafeID);
            if (theBody) {
              let imgs = theBody.getElementsByTagName("img");

                let tokn = Cookies.get("access");

                for (var i = 0; i < imgs.length; i++) {
                if (imgs[i].src.startsWith("cid:", 0)) {
                  let newSrc = imgs[i].src.replace("cid:", "");
                  if (newSrc.indexOf("@") != -1) {
                    newSrc = newSrc.split("@")[0];
                  }
                    imgs[i].src = TDFRequest.GetApiLink(`/Attachment/SingleFileFromEmail/${theEmail.ItemID}`, { fileName: newSrc } );
                }
              }
            }
          } catch (e) {
            console.log(e);
          } finally {
            console.timeEnd("Image stuff");
          }
    //$("#email-body-html" + theEmail.DomSafeID)
  }

  OpenMessage() {
    let theEmail = this;

    //let theFile =
    //    "From:" + theEmail.EmailData.FromAddress + "\n"
    //    + "To:" + theEmail.EmailData.ToAddress + "\n"
    //    + "Cc:" + theEmail.EmailData.CC + "\n"
    //    + "Bcc:" + theEmail.EmailData.BCC + "\n"
    //    + "Subject:" + theEmail.EmailData.Subject + "\n"
    //    + "Thread-Topic:" + theEmail.EmailData.ThreadIndex + "\n"
    //    + "Thread-Index:" + theEmail.EmailData.ThreadTopic + "\n"
    //    + "X-Unsent: 0\n"
    //    + "Content-Type: text/html\n"
    //    + (theEmail.NoHtml ? theEmail.EmailData.Body : theEmail.EmailData.HtmlBody) + "";

    $('<a/>')
      .addClass('hidden')
      .attr({
        download: 'download',
          href: TDFRequest.GetApiLink(`/attachment/getemail/${theEmail.ItemID}`)
      })
      .appendTo('body')
      .click();

    //Download(theFile, theEmail.EmailData.Subject+".eml", "text/eml");
  }

  Reply() {
    let theEmail = this;

    let theFile =
      'To:' +
      theEmail.EmailData.FromAddress +
      '\n' +
      // + "Cc:" + theEmail.EmailData.CC + "\n"
      //+ "Bcc:" + theEmail.EmailData.BCC + "\n"
      'Subject:' +
      theEmail.EmailData.Subject +
      '\n' +
      'Thread-Topic:' +
      theEmail.EmailData.ThreadIndex +
      '\n' +
      'Thread-Index:' +
      theEmail.EmailData.ThreadTopic +
      '\n' +
      'X-Unsent: 0\n' +
      'Content-Type: text/html\n' +
      (theEmail.NoHtml
        ? theEmail.EmailData.Body
        : theEmail.EmailData.HtmlBody) +
      '';

    Download(theFile, theEmail.EmailData.Subject + '.eml', 'text/eml');
  }

  ReplyAll() {
    let theEmail = this;

    let theFile =
      //"From:" + theEmail.EmailData.FromAddress + "\n"
      'To:' +
      theEmail.EmailData.FromAddress +
      '\n' +
      'Cc:' +
      theEmail.EmailData.CC +
      '\n' +
      'Bcc:' +
      theEmail.EmailData.BCC +
      '\n' +
      'Subject:' +
      theEmail.EmailData.Subject +
      '\n' +
      'Thread-Topic:' +
      theEmail.EmailData.ThreadIndex +
      '\n' +
      'Thread-Index:' +
      theEmail.EmailData.ThreadTopic +
      '\n' +
      'X-Unsent: 0\n' +
      'Content-Type: text/html\n' +
      (theEmail.NoHtml
        ? theEmail.EmailData.Body
        : theEmail.EmailData.HtmlBody) +
      '';

    Download(theFile, theEmail.EmailData.Subject + '.eml', 'text/eml');
  }

  Forward() {
    let theEmail = this;

    let theFile =
      //"From:" + theEmail.EmailData.FromAddress + "\n"
      //"To:" + theEmail.EmailData.ToAddress + "\n"
      //+ "Cc:" + theEmail.EmailData.CC + "\n"
      //+ "Bcc:" + theEmail.EmailData.BCC + "\n"
      'Subject:' +
      theEmail.EmailData.Subject +
      '\n' +
      'Thread-Topic:' +
      theEmail.EmailData.ThreadIndex +
      '\n' +
      'Thread-Index:' +
      theEmail.EmailData.ThreadTopic +
      '\n' +
      'X-Unsent: 0\n' +
      'Content-Type: text/html\n' +
      (theEmail.NoHtml
        ? theEmail.EmailData.Body
        : theEmail.EmailData.HtmlBody) +
      '';

    Download(theFile, theEmail.EmailData.Subject + '.eml', 'text/eml');
  }
}

enum SensitivityType {
  Normal,
  Personal,
  Private,
  Confidential
}

export interface IEmailData {
  BCC: string;
  Body: string;
  CC: string;
  EmailID: string;
  FromAddress: string;
  HtmlBody: string;
  Priority: number;
  Sensitivity: number;
  SentDate: string; //"2017-04-28T12:38:47-04:00"
  Subject: string;
  ThreadIndex: string;
  ThreadTopic: string;
  ToAddress: string;
  AttachmentCount: number;
}
