import { ToolbarTemplateFactory } from "./toolbartemplatefactory";
import { Debounce } from "util/allutils";

export function CustomOpenButton(args) {
    let OpenButtonOnClick: Function = () => { };
    if (args && args.length) {
        args.map((v, k) => {
            if ($.isPlainObject(v)) {
                Object.keys(v).forEach((val, key) => {
                    if (val.toLowerCase() === "openbuttononclick") {
                        OpenButtonOnClick = v[val];
                    }
                });
            }
        });
    }

    return ToolbarTemplateFactory('before', 'never', false, <DevExpress.ui.dxButtonOptions>{
        text: "Open",
        hint: "Open",
        icon: "dx-icon icon-open",
        onClick: Debounce(() => {
            OpenButtonOnClick();
        }, 250)
    }, 'dxButton');
}