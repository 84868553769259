export enum EnumQuoteStatus {

    Closed = 0,
    Lost = 1,
    Open = 2,
    Inactive = 3,
    OrderProcessed = 4,
    ManualEntry = 5,
    ProcessedToERPOrderSuccessfully = 9998,
    ProcessedToERPQuoteSuccessfully = 9999,
    ProcessedToERPPriceContractSuccessfully = 10000,
    ImportedByBISync = 10001,

}

export enum HidableQuoteHeaderFieldsEnum {
    ShipTo = 1,
    OrderNumber = 2,
    BIQuoteNumber = 3,
    ClosedOn = 4
}

export enum IncludeInLeaseEnum {
    // <System.ComponentModel.Description("Exclude") >
    Exclude = 0,
    //      < System.ComponentModel.Description("Include in Calculation") >
    IncludeInCalculation = 1,
    //          < System.ComponentModel.Description("Include in Monthly Total") >
    IncludeAsMonthlyLump = 2
}

export enum FinancePlanTypes {
    Unknown = 0,
    Lease = 1,
    Purchase = 2,
    Rental = 3
}

export enum CopyQuoteOptionsEnum {
    CopySameParent = 0,
    CopyOtherExistingParent = 1,
    CopyNewParent = 2
}

export enum EnumDocumentTypes {
    Doc = 0,
    DocX = 1,
    PDF = 2
}

export enum Item_Quotes_Fields {
    BaseTaxRate,
    SurTaxRate,
    SurTaxRateMaximum,
    Deposit,
    GlobalDiscountRate,
    GlobalDiscountAmount,
}

export enum EditSellCostOptionEnum {
    None = -1,
    MaintainMargin = 0,
    ApplySameDiscounttoPrice = 1
}

export enum EnumSellPriceMethods {

    NoMethodSelected = -1,
    PriceLessDiscount = 0,
    ManualEditSellPrice = 1,
    CostMarkUp = 2,
    DesiredGrossProfitPercent = 3,
    /*
    Note - this value should not appear in any user-visible menus.
    It is a value potentially pushed from the Quote-to-ERP process indicating
    that the SellPrice was overridden by the ERP
    */
    OverrideFromERP = 4,
    /*
    *Indicates that the price of this item has been influenced by a discount that was applied to the SellCost.
    */
    CostOverride = 5,
    SPA_Green = 100,
    SPA_Yellow = 101,
    SPA_Red = 102,
    SPA_Green_Manual = 103,
    SPA_Yellow_Manual = 104,
    SPA_Red_Manual = 105


}
export enum EnumERPProcessStatus {
    Normal = 0,
    Processed = 1,//cant change line items or process to erp again ... can close quote 
    ProcessedWithErrors = 2,
    Pending = 3,
}

export enum EnumDocumentCreationParentSearchOptions {
    /*
    * select among child Contacts of the Quote's Parent Account.")
    */
    SelectChildContactOfParentAccount = 0,
    /*  
     * select an arbitrary Account or Contact
       */
    SelectArbitraryAccount = 1,
    /*  
    * bypass the Parent Search and use the Parent Account
    */
    BypassSearchAndUseParentAccount = 2,
    /*
    * bypass the Parent Search and use the Parent Contact 
    */
    BypassSearchAndUseParentContact = 3
}


export enum EnumQuoteDocumentExportOptions {
    ExportCurrentGrid = 1,
    ExportKitLines = 2,
    SelectedLinesOnly = 4,
    ExpandGroups = 8,
    All = 7

}

export enum EnumQuoteDocName {
    QuoteDescription = 0,
    TemplateName = 1
}
export enum EnumQuoteToOrderActions {
    SendToQuote = 0,
    SendToOrder = 1,
    SendToContract = 2,
    UpdateQuoteFromERP = 3
}