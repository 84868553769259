import { ItemBase } from "./itembase";
import { itemTypes } from "../../enums/enums";
import { DisplayInfo } from "../../infrastructure/context";

export class ConTRact extends ItemBase {
  DisplayName: string;
  get TypeName() {
    return "ConTRact";
  }
  set TypeName(name) {
    if (name && this.TypeName !== name) this.TypeName = name;
  }
  get ItemType() {
    return itemTypes.itemContract;
  }
  get RequiresParentItem(): boolean {
    return true;
  }
  constructor(args) {
    super(args);
    let theContract = this;
    $.each(args, function(key, val) {
      if (theContract.hasOwnProperty(key)) theContract[key] = val;
    });

    if (!theContract.DisplayName) {
      DisplayInfo(theContract.ItemType).done(function(displayinfo) {
        theContract.DisplayName = displayinfo.DisplayName;
        theContract.TypeName = displayinfo.TypeName;
      });
    }
  }
}
