import { BaseDataCenter } from "./basedatacenter";
import { TDFDataCenters } from "../interfaces/datacenters/tdfdatacenters";
import { CenterType, GridContainerTypes } from "../enums/enums";
import { AddHandler2 } from "../infrastructure/events/ui_events";
import { AwesomeToolbar } from "../components/grids/gridtoolbars/datacentertoolbar";
import { DataCenterMenuDrawer } from "./datacentermenudrawer";
import { DataCenterTabs } from "./datacentertabs";
import { eventNameSpace, EventTypes } from "../enums/webevents/enums";
import { IToolbarItemOverrideOptions } from "../interfaces/grids/interfaces";
import { Preferences } from "../infrastructure/user/preferences";

/** A object the displays CRM data that has and vartical sidebar menu and Tabs that display grids of data with defined gridviews  */
export class CRMDataCenter extends BaseDataCenter {
  protected MyCreationArgs: TDFDataCenters.ICRMDataCenterCreationArgs;

  _CenterType: CenterType = CenterType.InfoCenter;
  protected get GridContainerType(): GridContainerTypes {
    return GridContainerTypes.InfoCenter;
  }

  protected DefaultToolbarLoaded: boolean;

  /**The jquery container that contains the toolbar */
  private _DataToolbarAreaContainer: JQuery;
  get DataToolbarAreaContainer(): JQuery {
    let crmdc = this;
    if (crmdc._DataToolbarAreaContainer) return crmdc._DataToolbarAreaContainer;
    let container: JQuery;
    if (crmdc.MyCreationArgs && crmdc.MyCreationArgs.DataToolbarAreaContainer) {
      container = $(crmdc.MyCreationArgs.DataToolbarAreaContainer);
    }
    if (container && container.length) {
      crmdc._DataToolbarAreaContainer = container;
    } else {
      /*Add to MainDataAreaContainer*/
      crmdc.DataToolbarAreaContainer = $(
        `<div id='crm-datacenter-datatoolbar-container${Date.now()}' />`
      ).appendTo(crmdc.MainDataAreaContainer);
    }

    if (
      !jQuery.contains(document.documentElement, crmdc.MainDataAreaContainer[0])
    ) {
      //Element is detached
      throw "Unable to find container => _DataToolbarAreaContainer ";
    }
    return crmdc._DataToolbarAreaContainer;
  }
  set DataToolbarAreaContainer(val: JQuery) {
    let crmdc = this;
    if (val) crmdc._DataToolbarAreaContainer = val;
  }

  /**The jquery container that contains tabs that contain the grids of data */
  private _DataTabsAreaContainer: JQuery;
  get DataTabsAreaContainer(): JQuery {
    let crmdc = this;
    if (crmdc._DataTabsAreaContainer) return crmdc._DataTabsAreaContainer;
    let container: JQuery;
    if (crmdc.MyCreationArgs && crmdc.MyCreationArgs.DataTabsAreaContainer) {
      container = $(crmdc.MyCreationArgs.DataTabsAreaContainer);
    }
    if (container && container.length) {
      crmdc.DataTabsAreaContainer = container;
    } else {
      /*Add to MainDataAreaContainer*/
      crmdc.DataTabsAreaContainer = $(
        `<div id='crm-datacenter-datatabs-container${Date.now()}'/>`
      ).appendTo(crmdc.MainDataAreaContainer);
    }
    if (
      !jQuery.contains(document.documentElement, crmdc.MainDataAreaContainer[0])
    ) {
      //Element is detached
      throw "Unable to find container => _DataTabsAreaContainer ";
    }

    return crmdc._DataTabsAreaContainer;
  }
  set DataTabsAreaContainer(val: JQuery) {
    let crmdc = this;
    if (val) crmdc._DataTabsAreaContainer = val;
  }

  /**The jquery container that contains tabs that contain the grids of data */
  private _DataGridAreaContainer: JQuery;
  get DataGridAreaContainer(): JQuery {
    let crmdc = this;
    if (crmdc._DataGridAreaContainer) return crmdc._DataGridAreaContainer;
    let container: JQuery;
    if (crmdc.MyCreationArgs && crmdc.MyCreationArgs.DataGridAreaContainer) {
      container = $(crmdc.MyCreationArgs.DataGridAreaContainer);
    }
    if (container && container.length) {
      crmdc.DataGridAreaContainer = container;
    } else {
      /*Add to MainDataAreaContainer*/
      crmdc.DataGridAreaContainer = $(
        `<div id='crm-datacenter-datagrid-container${Date.now()}'/>`
      )
        .css('overflow', 'hidden') // Handles the welcome page. Makes sure it doesn't create a bunch of blank space at the bottom of the page
        .appendTo(crmdc.MainDataAreaContainer);
    }
    if (
      !jQuery.contains(document.documentElement, crmdc.MainDataAreaContainer[0])
    ) {
      //Element is detached
      throw "Unable to find container => _DataGridAreaContainer ";
    }

    return crmdc._DataGridAreaContainer;
  }
  set DataGridAreaContainer(val: JQuery) {
    let crmdc = this;
    if (val) crmdc._DataGridAreaContainer = val;
  }

  protected get ToolbarItems(): IToolbarItemOverrideOptions[] {
    let ToolbarItems: IToolbarItemOverrideOptions[];

    if (this.DefaultToolbarLoaded) {
      ToolbarItems = [
        { name: "MenuButton", index: 0, location: "before" }
      ]
    } else {
      ToolbarItems = [
        { name: "MenuButton", index: 0, location: "before" },
        { name: "OpenButton", index: 1, location: "before" },
        { name: "ActionButton", index: 2, location: "before" },
        { name: "ViewLookup", index: 4, location: "after" },
        { name: "RefreshButton", index: 5, location: "after" },
        { name: "OptionsButton", index: 6, location: "after" }
      ];

      if ((Preferences.GetCompanyPreference("ShowViewsBtn", "TDFMobile", "1") === "1")) {
        ToolbarItems.push({ name: "ViewButton", index: 3, location: "before" });
      }
    }

    return ToolbarItems;
  }

  /**
   * If creation args are not provided the appropriate containers will be added to tdfbodycontent
   * @param OPTIONAL creationArgs
   */
  constructor(creationArgs: TDFDataCenters.ICRMDataCenterCreationArgs = null, EventLimiterName: string = 'infocenter') {
    super(EventLimiterName);
    let crmdc = this;
    crmdc.ValidateCreationArgs(creationArgs);
    crmdc.initContainers();
    crmdc.initMenu();
    crmdc.initTabs();
    crmdc.SubscribeToMenuEvents();
  }

  private SubscribeToMenuEvents() {
    let crmdc = this;
    AddHandler2(
      EventTypes.CenterEventTypes.itemtype,
      crmdc.CustomName,
      eventNameSpace.modify,
      crmdc.MainAreaContainer,
      crmdc.OnMenuSelectionChanged.bind(crmdc)
    );
    AddHandler2(
      EventTypes.CenterEventTypes.loaddefaulttoolbar,
      crmdc.CustomName,
      eventNameSpace.notify,
      crmdc.MainAreaContainer,
      crmdc.LoadDefaultToolbar.bind(crmdc)
    );
  }

  OnMenuSelectionChanged(e: JQueryEventObject, data) {
    let crmdc = this;

    if (crmdc.DefaultToolbarLoaded) {
      crmdc.DefaultToolbarLoaded = false;

      crmdc.DataToolbarAreaContainer.empty();
    }

    crmdc.initToolbar(data);
  }

  protected LoadDefaultToolbar(e: JQueryEventObject) {
    let crmdc = this;

    crmdc.DefaultToolbarLoaded = true;

    crmdc.initToolbar();
  }

  protected initToolbar(data?) {
    let crmdc = this;

    if (crmdc.DataToolbarAreaContainer.children().length === 0) {
      crmdc.MenuAreaContainer.hide();

      new AwesomeToolbar(
        crmdc.DataToolbarAreaContainer,
        data && data.ItemType ? data.ItemType : null,
        crmdc.GridContainerType,
        crmdc.CustomName,
        crmdc.ToolbarItems,
        crmdc.GetToolbarItemArgs(data)
      ).init();
    }
  }

  protected initContainers() {
    super.initContainers();
    let crmdc = this;
    if (!crmdc.DataToolbarAreaContainer.length)
      throw "Error toolbar container missing";
    if (!crmdc.DataTabsAreaContainer.length)
      throw "Error tabs container missing";
    if (!crmdc.DataGridAreaContainer.length)
      throw "Error grid container missing";
  }

  protected initMenu() {
    let crmdc = this;
    new DataCenterMenuDrawer(
      crmdc.MenuAreaContainer,
      crmdc.CenterType,
      crmdc.CustomName,
      crmdc.MainAreaContainer,
      crmdc.InDefaultContainer
    ).Init();
  }

  initTabs() {
    let crmdc = this;
    new DataCenterTabs(
      crmdc.DataTabsAreaContainer,
      crmdc.CenterType,
      crmdc.DataGridAreaContainer,
      crmdc.CustomName,
      crmdc.InDefaultContainer
    ).Init();
  }

  protected ValidateCreationArgs(
    args: TDFDataCenters.ICRMDataCenterCreationArgs
  ) {
    super.ValidateCreationArgs(args);
    let crmdc = this;
    // if (args) {
    //   if (!args.DataToolbarAreaContainer.startsWith("#", 0))
    //     throw "DataToolbarAreaContainer_ID must begin with hashtag";
    //   if (!args.DataTabsAreaContainer.startsWith("#", 0))
    //     throw "DataTabsAreaContainer_ID must begin with hashtag";
    //   if (!args.DataGridAreaContainer.startsWith("#", 0))
    //     throw "DataGridsAreaContainer_ID must begin with hashtag";
    // }
    crmdc.MyCreationArgs = args;
  }

  protected GetToolbarItemArgs(data?) {
    let ToolbarItemArgs: any;

    if (this.DefaultToolbarLoaded) {
      ToolbarItemArgs = {
        gridContainerType: this.GridContainerType,
        UniqueCenterName: this.CustomName
      }
    } else {
      ToolbarItemArgs = {
        ItemType: data.ItemType,
        gridContainerType: this.GridContainerType,
        UniqueCenterName: this.CustomName
      }
    }
    return ToolbarItemArgs;
  }
}
