import { enumscope } from "../../../../enums/enums";
import { AddHandler, RaiseEvent } from "../../../../infrastructure/events/ui_events";
import { ToolbarTemplateFactory } from "./toolbartemplatefactory";
import { eventNameSpace, EventTypes } from "../../../../enums/webevents/enums";

export function ScopeButton(initialscope, config?) {
  //TODO: Add Config paramter and respect it
  return ToolbarTemplateFactory(
    config && config.location ? config.location : "before",
    config && config.locateInMenu ? config.locateInMenu : "auto",
    config && typeof config.disabled !== "undefined" ? config.disabled : false,
    config && config.options
      ? config.options
      : <DevExpress.ui.dxButtonOptions>{
        text: "Toggle Scope",
        hint:
          "Right click to change whether to default to personal or global views",
        icon:
          !initialscope ||
            initialscope === -1 ||
            initialscope === enumscope.global
            ? "globe"
            : "home",
        onClick(e) {
          let scope = -1;
          switch (e.component.option("icon")) {
            case "globe":
              e.component.option("icon", "home");
              scope = enumscope.local;
              break;
            case "home":
              scope = enumscope.global;
              e.component.option("icon", "globe");
          }
          RaiseEvent(
            EventTypes.CenterEventTypes.gridscope,
            eventNameSpace.modify,
            scope
          );
          // UI_Events.Dispatch(UI_Events.GetEventWithData(UI_Events.GridScopeChanged, toolbar.Scope))
        },
        onInitialized(e) {
          $("#scope-context").remove();
          $("#tdfbody").append($("<div id='scope-context' />"));
          $("#scope-context").dxContextMenu(
            <DevExpress.ui.dxContextMenuOptions>{
              target: e.element,
              items: [
                {
                  html: `<div style="width:auto;">Set as Default ( Personal <span class="dx-icon dx-icon-home text-warning"style="font-size:small;vertical-align: baseline;"></span> / Global <span class="dx-icon dx-icon-globe text-info" style="font-size:small;vertical-align: baseline;"></span> )</div>`
                }
              ],
              hint: "Set Default",
              onItemClick(ev) {
                RaiseEvent(
                  EventTypes.CenterEventTypes.scopeforitemtype,
                  eventNameSpace.request,
                  e.component.option("icon") === "globe" ? true : false
                );
              }
            }
          );
        },
        onContentReady(e) {
          AddHandler(
            EventTypes.CenterEventTypes.gridscope,
            eventNameSpace.modify,
            e.element,
            (ev, data) => {
              e.component.option(
                "icon",
                data === enumscope.global ? "globe" : "home"
              );
            }
          );
        }
      },
    config && config.widget ? config.widget : "dxButton"
  );
}
