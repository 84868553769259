import dxCustomStore from 'devextreme/data/custom_store';
import dxDataSource from 'devextreme/data/data_source';
import { CenterType } from '../../enums/enums';
import { GetPreference } from '../../infrastructure/user/getpreference';
import { IGridView } from '../../interfaces/grids/interfaces';
import {
  IGridDataResponse,
  IGridDataResponseMeta,
  IGridViewDefinition
} from '../../interfaces/interfaces';
import { TDFRequest } from '../request';
import { GridSetup } from './helpers/gridsetup';
import { ViewResponse } from '../../components/grids/datacentergrid';
export class GridDataService {
  constructor(center: CenterType, view: IGridView) {
    this.CenterType = center;
    this.GridView = view;
  }
  protected RequestType: string = 'POST';

  private Request: TDFRequest;

  private _GridView: IGridView;
  get GridView(): IGridView {
    if (this._GridView) return this._GridView;
    return null;
  }
  set GridView(val: IGridView) {
    if (val) this._GridView = val;
  }

  private _CenterType: CenterType;
  get CenterType(): CenterType {
    if (typeof this._CenterType !== 'undefined') return this._CenterType;
    return null;
  }
  set CenterType(val: CenterType) {
    if (typeof val !== 'undefined') this._CenterType = val;
  }

  private _GridViewInformation: ViewResponse;
  get GridViewInformation(): ViewResponse {
    if (this._GridViewInformation) return this._GridViewInformation;
    return null;
  }
  set GridViewInformation(val: ViewResponse) {
    if (val) this._GridViewInformation = val;
  }

  private _GridViewMeta: IGridDataResponseMeta;
  get GridViewMeta(): IGridDataResponseMeta {
    if (this._GridViewMeta) return this._GridViewMeta;
    return null;
  }
  set GridViewMeta(val: IGridDataResponseMeta) {
    if (val) this._GridViewMeta = val;
  }

  get HasDateColumn(): boolean {
    let theGrid = this;
    if (
      theGrid.GridViewInformation.GridViewModel.Layout.Columns &&
      theGrid.GridViewInformation.GridViewModel.Layout.Columns.length > 0
    ) {
      return theGrid.GridViewInformation.GridViewModel.Layout.Columns.some(
        (val, index) => {
          return ['date', 'dateTime'].indexOf(val.dataType) > -1;
        }
      );
    }
    return false;
  }

  /**
   * Tries to remove timezone information from the data
   * @param data
   */
  CleanDates() {
    let dataService = this;
    //if (dataService.ResponseFromServer && !dataService.ResponseFromServer.GridView) return;
    let datecols = dataService.GridViewInformation.GridViewModel.Layout.Columns.filter(
      (v, k) => {
        return ['date', 'dateTime'].indexOf(v.dataType) > -1 && v.visible;
      }
    );
    var length;
    var len;
    for (var index = 0; (length = datecols.length), index < length; index++) {
      let thisone = datecols[index].dataField;
      for (
        var i = 0, len = dataService.ResponseFromServer.Data.length;
        i < len;
        i++
      ) {
        let row = dataService.ResponseFromServer.Data[i];
        if (row[thisone]) {
          let timestampIndex = row[thisone].indexOf('T00:00:00');
          if (timestampIndex > -1) {
            row[thisone] = row[thisone].substring(0, timestampIndex);
          }
          //else {
          //    if (moment(new Date(row[thisone])).isValid()) {
          //        row[thisone] = Globalize.formatDate(moment.parseZone(row[thisone]).toDate(), { datetime: "short" });
          //    }
          //}
        }
      }
    }
  }

  // Aborts the request to get the grid data.
  Abort() {
    if (this.Request) {
      this.Request.AbortRequest();
    }
  }

  private _ResponseFromServer: IGridDataResponse;
  get ResponseFromServer(): IGridDataResponse {
    if (this._ResponseFromServer) return this._ResponseFromServer;
    return null;
  }
  set ResponseFromServer(response: IGridDataResponse) {
    let dataService = this;
    if (response) this._ResponseFromServer = response;
    if (dataService.GridViewInformation) {
      dataService.GridViewInformation.GridViewModel =
        response.GridView || dataService.GridViewInformation.GridViewModel;
    }
    if ((response && response.Meta) || dataService.GridViewMeta) {
      dataService.GridViewMeta = response.Meta || dataService.GridViewMeta;
    }

    //if (dataService.GridViewInformation && dataService.GridViewInformation.Layout && (!dataService.dxOptions || !dataService.dxOptions.columns)) {
    //    dataService.setDxColumns()
    //}

    //if (dataService.GridViewMeta) {
    //    if (typeof dataService.GridViewMeta.CanExport !== "undefined") {
    //        dataService.GridInstance.option("export", {
    //            enabled: dataService.GridViewMeta.CanExport,
    //            allowExportSelectedData: true,
    //        });
    //    }
    //    if (typeof dataService.GridViewMeta.RecordsOnPage !== "undefined") {
    //        dataService.GridInstance.option("pager", { infoText: dataService.PagingInfo })
    //    }
    //}
  }

  protected _RequestModel: GridSetup.BaseGridRequestModel;
  get RequestModel() {
    let dataService = this;
    if (dataService._RequestModel) return dataService._RequestModel;
    dataService.RequestModel = GridSetup.GridRequestFactory(
      dataService.CenterType,
      dataService.RequestArgs
    );

    return dataService._RequestModel;
  }
  set RequestModel(model) {
    let dataService = this;
    if (!model.RequestArgs.GUID && dataService.GridView)
      model.RequestArgs.GUID = dataService.GridView.GUID;
    if (!model.RequestArgs.ItemType && dataService.GridView)
      model.RequestArgs.ItemType = dataService.GridView.ItemType;
    dataService._RequestModel = model;
  }

  protected _RequestArgs: GridSetup.GridRequestArgs;
  get RequestArgs() {
    let dataService = this;
    if (dataService._RequestArgs) return dataService._RequestArgs;
    dataService.RequestArgs = GridSetup.GridRequestArgsFactory(
      dataService.CenterType
    );
    return dataService._RequestArgs;
  }
  set RequestArgs(args) {
    let dataService = this;
    dataService._RequestArgs = args;
  }
  /*
   *   Actual request to server happens here
   */
  private get store(): DevExpress.data.CustomStore {
    let dataService = this;
    let storeOptions: DevExpress.data.CustomStoreOptions = {
      loadMode: 'raw',

      load(options) {
        let d = $.Deferred();
        let requestmodel = dataService.RequestModel;

        if (requestmodel.Path && dataService.RequestModel.RequestArgs.GUID) {
          let data: any = requestmodel.RequestArgs;
          if (dataService.RequestType === 'GET' && !window.fetch) {
            /*if fetch is not available (Internet Explorer for example) then the request model will be serialized with incorrect variable names the include an underscore in them */
            Object.keys(requestmodel.RequestArgs).forEach(k => {
              data[k.replace('_', '')] = requestmodel.RequestArgs[k];
            });
          }

          dataService.Request = new TDFRequest({
            url: requestmodel.Path,
            type: dataService.RequestType,
            data: data
          });

          dataService.Request.MakeRequest().done(response => {
            //cache the response
            dataService.ResponseFromServer = response;
            if (
              dataService.ResponseFromServer.Data &&
              dataService.GridViewInformation &&
              dataService.HasDateColumn
            ) {
              dataService.CleanDates();
            }

            d.resolve(dataService.ResponseFromServer.Data, {
              totalCount: dataService.ResponseFromServer.TotalRecords
            });
          });
        } else {
          return d.promise(d.resolve([]));
        }
        return d.promise();
      },

      byKey(key) {
        let d = $.Deferred();
        return d.promise(d.resolve(dataService.ResponseFromServer[key]));
      }
    };

    return new dxCustomStore(storeOptions);
  }

  protected _GridDataSource: DevExpress.data.DataSource;
  get DataSource(): DevExpress.data.DataSource {
    let dataService = this;
    /*TODO: Need to get areas where grid data is loaded outside of the grid to give this a request model somehow*/
    if (dataService._GridDataSource) return dataService._GridDataSource;
    let options: DevExpress.data.DataSourceOptions = {
      store: dataService.store,

      pageSize: parseInt(GetPreference('ClientGridPageSize', 'TDFMobile')),
      requireTotalCount: true,
      paginate: true
      //map(data:IGridDataResponse) {
      //    return data.Data
      //}
    };
    dataService.DataSource = new dxDataSource(options);

    return dataService._GridDataSource;
  }
  set DataSource(val: DevExpress.data.DataSource) {
    if (typeof val !== 'undefined') this._GridDataSource = val;
  }
}
