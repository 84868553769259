import { IItemInfo } from "../interfaces/interfaces";
import { TDFRequest } from "../services/request";
import { SearchRecFav } from "../components/controls/searchrecfav";

export function GetLinkPrompt(singleItem: IItemInfo, relevantType) {
  let SRF: SearchRecFav = new SearchRecFav(
    relevantType,
    [relevantType],
    relevantType,
    true
  );

  //let isDevice = GetDevice().isDevice;
  //let time = SRF.Time;

  SRF.GetSRFDialog().done(selected => {
    if (!selected.length) return;
    let childList = [];
    $.each(selected, function(k, v) {
      childList.push(v["TDF GUID"]);
    });

    /*Here*/
    new TDFRequest({
      url: "/core/user/GetItemTypeFromID/",
      data: { itemid: childList[0] }
    })
      .MakeRequest()
      .done(function(response1: any) {
        let request;
        if (relevantType !== 999) {
          /*Here*/
          request = new TDFRequest({
            url: "/item/AddItemLink/",
            data: {
              parentID: singleItem.ItemId,
              parentType: singleItem.ItemType,
              children: childList,
              childrentype: relevantType
            },
            type: "POST"
          });
        } else {
          /*Here*/
          request = new TDFRequest({
            url: "/item/AddItemLink/",
            data: {
              parentID: childList[0],
              parentType: response1,
              children: singleItem.ItemId,
              childrentype: singleItem.ItemType
            },
            type: "POST"
          });
        }

        request.MakeRequest().done(function(response2: any) {
          let message = "Items were linked.";
          // new Notification({ message: message, type: response2.Valid ? "success" : "error", shading: !response2.Valid });
          SRF.Dialog.close();
        });
      });
  });
}

//export class LinkItems {
//       item: IItemInfo;
//       //search: Search;
//      // recent: Recent;
//      // fav: Favorites;
//      // SelectedItems: any;
//       //DFDS: JQueryDeferred<any>[];

//       constructor(args: any) {
//           let linkItems = this;
//           linkItems.item = args;

//       }

//   }
