import { SearchRecFav } from "./searchrecfav";
import { TimeTrackerSearch } from "../../legacy-centers-grids/search";

export class TimeTrackerSearchRecFav extends SearchRecFav {
  public GetSearch() {
    const TTSRF = this;
    const Search: TimeTrackerSearch = new TimeTrackerSearch(TTSRF.SearchModel);
    Search.MultiSelectAllowed = TTSRF.multiSelectAllowed;
    Search.GridToolbarModel;
    return Search;
  }
}
