import { Dialog } from '../components/dialogs/dialog';
import 'devextreme/ui/toolbar';
import 'devextreme/ui/form';
import 'devextreme/ui/data_grid';
import 'devextreme/ui/check_box';
import 'devextreme/ui/form';
import { confirm } from 'devextreme/ui/dialog';
import { IDisplayInfo } from '../interfaces/interfaces';
import {
  combinerOptions,
  ICombineItemsCommand,
  IContactGridItem,
  IFetchContacts,
  ILongJob,
  SourceGridItem
} from '../interfaces/admin/interfaces';
import { ItemCombinerDataService } from '../services/admin/itemcombinerdataservice';
import { LongJobManager } from './longjobmanager';
import { DomSafeID, GimmeGUID, GetStore } from '../util/allutils';
import { SearchRecFav } from '../components/controls/searchrecfav';
import { itemTypes } from '../enums/enums';
import { LoadCompany, GetItemTypeDisplayInfo } from '../infrastructure/context';
export class ItemCombiner {
  private dataService: ItemCombinerDataService;

  private options: combinerOptions;

  private displayInfo: IDisplayInfo[];

  private relevantItemType: itemTypes = itemTypes.itemContact;

  constructor(options?: combinerOptions) {
    let that = this;
    that.options = options;
    that.dataService = new ItemCombinerDataService();

    if (!that.options) {
      that.relevantItemType = itemTypes.itemAccount;
    } else {
      that.relevantItemType = that.options.sourceItems.itemtype;
    }

    LoadCompany().done(e => {
      GetItemTypeDisplayInfo().done(info => {
        that.displayInfo = info;
        that.ShowDialog();
      });
    });
  }

  private ShowDialog() {
    let that = this;
    let formData = {};

    let advancedMode: boolean = false;

    let sourceGridData: SourceGridItem[];
    let targetGridData: SourceGridItem[];

    let pluralCaption: string = "Items";
    let singularCaption: string = "Item";

    let contactGridOptions: DevExpress.ui.dxDataGridOptions;

    let contactsAtSource: IContactGridItem[] = [];

    let contactsAtTarget: IContactGridItem[] = [];

    function refreshContactsGrid(
      accounts: SourceGridItem[],
      targetAccount: string
    ) {
      if (that.relevantItemType != itemTypes.itemAccount) {
        //console.log("Contacts grid not relevant");
      }

      if (accounts && accounts.length > 0) {
        let model: IFetchContacts = {
          SourceAccounts: accounts.map((val, index) => {
            return val.ItemId;
          }),
          TargetAccount: targetAccount
        };

        that.dataService
          .GetContactsAtAccounts(model)
          .MakeRequest()
          .done(e => {
            let contactGrid = $(
              `#${contactGridOptions.elementAttr.id}`
            ).dxDataGrid("instance");
            if (contactGrid) {
              contactsAtTarget = e.ContactsAtTargetAccount;
              contactsAtTarget.unshift({
                contactid: "[Move Contact]",
                Name: "[Move Contact]",
                Action: ""
              });

              contactsAtSource = e.ContactsAtSourceAccounts;

              contactGrid.option("dataSource", contactsAtSource);
            } else {
              console.log("Didn't find contactGrid!");
            }
          });
      }
    }

    contactGridOptions = {
      visible: true,
      elementAttr: { id: DomSafeID(GimmeGUID()) },
      paging: { enabled: true, pageSize: 10 },
      noDataText:
        "No data - select at least one source account and one target account.",
      editing: { mode: "cell", allowUpdating: true },
      columns: [
        { dataField: "Name", allowEditing: false, caption: "Contact Name" },
        {
          dataField: "Action",
          lookup: {
            displayExpr: "Name",
            valueExpr: "contactid",
            dataSource: {
              store: GetStore(() => {
                return contactsAtTarget;
              }, "contactid")
            }
          }
        }
      ]
    };

    try {
      pluralCaption = that.displayInfo.filter((val, index) => {
        return val.TypeNum == that.relevantItemType;
      })[0].DisplayNamePlural;
      singularCaption = that.displayInfo.filter((val, index) => {
        return val.TypeNum == that.relevantItemType;
      })[0].DisplayName;
    } catch (ex) { }

    let formOptions: DevExpress.ui.dxFormOptions = {
      formData: formData,
      items: [
        {
          itemType: "simple",
          template: e => {
            return $("<div />").dxToolbar({
              items: [
                <DevExpress.ui.dxPopupToolbarItem>{
                  widget: "dxButton",
                  location: "before",
                  options: {
                    text: "Source Items",
                    icon: "fa fa-plus",
                    onClick: e => {
                      let srf: SearchRecFav = new SearchRecFav(
                        that.relevantItemType,
                        [that.relevantItemType],
                        itemTypes.itemUnknown,
                        true
                      );

                      srf.GetSRFDialog().done(selected => {
                        try {
                          let z = (<Array<any>>selected).map((val, index) => {
                            let m: SourceGridItem = {
                              AccountName: "Unknown",
                              AcctPkg: "Unknown",
                              ItemId: val["TDF GUID"],
                              ItemName: "Unknown"
                            };

                            if (val.AccountName)
                              m.AccountName = val.AccountName;
                            if (val.AcctPkg) m.AcctPkg = val.AcctPkg;
                            if (val.TDF_Subject) m.ItemName = val.TDF_Subject;

                            return m;
                          });

                          let grid: DevExpress.ui.dxDataGrid = form.getEditor(
                            "SourceGrid"
                          );

                          let currentData: SourceGridItem[] = grid.option(
                            "dataSource"
                          );

                          currentData = currentData.concat(z);

                          grid.option("dataSource", currentData);
                        } catch (e) {
                          alert(e.message);
                        } finally {
                          srf.Dialog.close();
                        }
                      });
                    }
                  }
                },

                <DevExpress.ui.dxPopupToolbarItem>{
                  widget: "dxButton",
                  location: "before",
                  options: {
                    text: "Target Item",
                    icon: "fa fa-bullseye",
                    onClick: e => {
                      let srf: SearchRecFav = new SearchRecFav(
                        that.relevantItemType,
                        [that.relevantItemType],
                        itemTypes.itemUnknown,
                        false
                      );

                      srf.GetSRFDialog().done(selected => {
                        try {
                          let z = (<Array<any>>selected).map((val, index) => {
                            let m: SourceGridItem = {
                              AccountName: "Unknown",
                              AcctPkg: "Unknown",
                              ItemId: val["TDF GUID"],
                              ItemName: "Unknown"
                            };

                            if (val.AccountName)
                              m.AccountName = val.AccountName;
                            if (val.AcctPkg) m.AcctPkg = val.AcctPkg;
                            if (val.TDF_Subject) m.ItemName = val.TDF_Subject;

                            return m;
                          });

                          let grid: DevExpress.ui.dxDataGrid = form.getEditor(
                            "TargetGrid"
                          );
                          grid.option("dataSource", z);

                          // remove any instance of the clicked item from the source contact grid.
                          sourceGridData = sourceGridData.filter(
                            (item, index) => {
                              return item.ItemId != z[0].ItemId;
                            }
                          );

                          grid = form.getEditor("SourceGrid");
                          grid.option("dataSource", sourceGridData);

                          if (that.relevantItemType === itemTypes.itemAccount) {
                            refreshContactsGrid(sourceGridData, z[0].ItemId);
                          }
                        } catch (e) {
                          alert(e.message);
                        } finally {
                          srf.Dialog.close();
                        }
                      });
                    } // end function
                  }
                }
              ]
            });
          }
        },
        //dxForm no longer officially supports the datagrid as an editor type... but it will still work
        <any>{
          itemType: "simple",
          editorType: "dxDataGrid",
          dataField: "SourceGrid",
          label: { text: `Source ${pluralCaption}`, location: "top" },
          editorOptions: <DevExpress.ui.dxDataGridOptions>{
            editing: {
              allowDeleting: true,
              useIcons: true,
              texts: {
                confirmDeleteMessage: ""
              }
            },

            columns: [
              { dataField: "ItemId", visible: false },
              { dataField: "ItemName", caption: "Name" },
              "AcctPkg",
              "AccountName",
              <DevExpress.ui.dxDataGridColumn>{
                dataField: "UseAsTarget",
                caption: " ",
                width: 32,
                cellTemplate: (el, info) => {
                  let bullseye = $(
                    "<div title='Use as target' class='fa fa-bullseye fa-2x' style='text-align:center;cursor:pointer' />"
                  );
                  bullseye.on("click", e => {
                    let row: SourceGridItem = info.key;
                    targetGridData = [row];

                    let grid: DevExpress.ui.dxDataGrid = form.getEditor(
                      "TargetGrid"
                    );
                    grid.option("dataSource", targetGridData);

                    // remove any instance of the clicked item from the source contact grid.
                    sourceGridData = sourceGridData.filter((item, index) => {
                      return item.ItemId != row.ItemId;
                    });

                    grid = form.getEditor("SourceGrid");
                    grid.option("dataSource", sourceGridData);

                    if (that.relevantItemType == itemTypes.itemAccount) {
                      refreshContactsGrid(
                        sourceGridData,
                        targetGridData[0].ItemId
                      );
                    }
                  });
                  return bullseye;
                }
              }
            ]
          }
        },

        {
          itemType: "simple",
          template: $("<hr />")
        },

        {
          itemType: "simple",
          editorType: "dxDataGrid",
          dataField: "TargetGrid",
          label: {
            text: `Target ${singularCaption}`,
            location: "top"
          },
          editorOptions: {
            columns: [
              { dataField: "ItemId", visible: false },
              { dataField: "ItemName", caption: "Name" },
              "AcctPkg",
              "AccountName"
            ]
          }
        },

        {
          itemType: "simple",
          editorType: "dxRadioGroup",
          dataField: "CombineOption",
          label: { text: "Combine Options", location: "top" },
          alignment: "center",
          editorOptions: <DevExpress.ui.dxRadioGroupOptions>{
            layout: "horizontal",
            value: "KeepDestinationValue",
            dataSource: [
              {
                text: "Keep destination values",
                value: "KeepDestinationValue"
              },
              {
                text: "Update empty destination values",
                value: "ReplaceEmptyDestinationValue"
              }
            ]
          }
        },

        {
          itemType: "simple",
          template: e => {
            return "<hr />";
          }
        },

        {
          visible: that.relevantItemType == itemTypes.itemAccount,

          itemType: "simple",
          editorType: "dxDataGrid",
          dataField: "ContactGrid",
          label: { text: "Advanced Contact Merge Options", location: "top" },

          template: e => {
            let box = $("<div />").dxCheckBox({
              elementAttr: { style: "padding-top: 7px; padding-bottom: 7px;" },
              text: "Show advanced options?",
              onValueChanged: e => {
                advancedMode = e.value;
                if (e.value === true) {
                  $(`#${contactGridOptions.elementAttr.id}`).show();
                } else {
                  $(`#${contactGridOptions.elementAttr.id}`).hide();
                }
              }
            });

            let content = $("<div />")
              .append(box)
              .append($("<div />").dxDataGrid(contactGridOptions));

            return content;
          }

          //editorOptions: contactGridOptions
        },

        // {
        //   itemType: "button",
        //   alignment: "center",

        //   buttonOptions: {
        //     text: "Combine",
        //     icon: "fa fa-compress fa3x",

        //     onClick: e => {
        //       if (formData["CombineOption"]) {
        //         let source = (form.getEditor(
        //           "SourceGrid"
        //         ) as DevExpress.ui.dxDataGrid).option(
        //           "dataSource"
        //         ) as SourceGridItem[];
        //         let target = (form.getEditor(
        //           "TargetGrid"
        //         ) as DevExpress.ui.dxDataGrid).option(
        //           "dataSource"
        //         ) as SourceGridItem[];

        //         if (!source || !target) {
        //           return;
        //         }

        //         if (source.length == 0 || target.length == 0) {
        //           return;
        //         }

        //         DevExpress.ui.dialog
        //           .confirm("Are you sure you want to combine the items?", "")
        //           .done(e => {
        //             if (e) {
        //               let source = (form.getEditor(
        //                 "SourceGrid"
        //               ) as DevExpress.ui.dxDataGrid).option(
        //                 "dataSource"
        //               ) as SourceGridItem[];
        //               let target = (form.getEditor(
        //                 "TargetGrid"
        //               ) as DevExpress.ui.dxDataGrid).option(
        //                 "dataSource"
        //               ) as SourceGridItem[];
        //               let combineOption = (form.getEditor(
        //                 "CombineOption"
        //               ) as DevExpress.ui.dxRadioGroup).option("value");

        //               let command: ICombineItemsCommand = {
        //                 AdvancedContactMerge: advancedMode,

        //                 CombineOption:
        //                   combineOption == "KeepDestinationValue"
        //                     ? "KeepDestinationValue"
        //                     : "ReplaceEmptyDestinationValue",

        //                 SourceItems: {
        //                   itemtype: that.relevantItemType,
        //                   guids: source.map((val, index) => {
        //                     return val.ItemId;
        //                   })
        //                 },

        //                 TargetItem: {
        //                   itemtype: that.relevantItemType,
        //                   guids: [target[0].ItemId]
        //                 },

        //                 ContactMergeSettings: contactsAtSource.map(
        //                   (val, index) => {
        //                     return {
        //                       SourceID: val.contactid,
        //                       TargetID: val.Action
        //                     };
        //                   }
        //                 )
        //               };

        //               that.dataService
        //                 .InitiateCombine(command)
        //                 .MakeRequest()
        //                 .done((e: ILongJob) => {
        //                   let jobMan = new LongJobManager();
        //                   jobMan.showJobProgress(
        //                     e,
        //                     `Combining ${pluralCaption}...`
        //                   );
        //                 });
        //             }
        //           });
        //       }
        //     }
        //   }
        // }
      ]
    };

    let form: DevExpress.ui.dxForm = $("<div />")
      .dxForm(formOptions)
      .dxForm("instance");

    let dlg = new Dialog({
      body: form.element(),
      type: "type-primary",
      size: "size-normal",
      title: "Combine Items",
      closable: true,
      modal: "no",
      buttons: [{
        widget: <any>'dxButton',
        toolbar: "bottom",
        location: 'after',
        options: {
          text: "Combine",
          icon: "fa fa-compress fa3x",
          type: 'success',
          onClick: (e) => {
            if (formData['CombineOption']) {

              let source = ((form.getEditor("SourceGrid") as DevExpress.ui.dxDataGrid).option("dataSource") as SourceGridItem[]);
              let target = ((form.getEditor("TargetGrid") as DevExpress.ui.dxDataGrid).option("dataSource") as SourceGridItem[]);

              if (!source || !target) {
                return;
              }

              if (source.length == 0 || target.length == 0) {
                return;
              }

              confirm("Are you sure you want to combine the items?", "").done(
                (e) => {
                  if (e) {

                    let source = ((form.getEditor("SourceGrid") as DevExpress.ui.dxDataGrid).option("dataSource") as SourceGridItem[]);
                    let target = ((form.getEditor("TargetGrid") as DevExpress.ui.dxDataGrid).option("dataSource") as SourceGridItem[]);
                    let combineOption = (form.getEditor("CombineOption") as DevExpress.ui.dxRadioGroup).option("value");

                    let command: ICombineItemsCommand = {

                      AdvancedContactMerge: advancedMode,

                      CombineOption: (combineOption == "KeepDestinationValue") ? "KeepDestinationValue" : "ReplaceEmptyDestinationValue",

                      SourceItems: {
                        itemtype: that.relevantItemType,
                        guids: source.map((val, index) => { return val.ItemId })
                      },

                      TargetItem: {
                        itemtype: that.relevantItemType,
                        guids: [target[0].ItemId]
                      },

                      ContactMergeSettings: contactsAtSource.map((val, index) => {
                        return { SourceID: val.contactid, TargetID: val.Action }
                      })
                    }

                    that.dataService.InitiateCombine(command).MakeRequest().done((e: ILongJob) => {
                      let jobMan = new LongJobManager();
                      jobMan.showJobProgress(e, `Combining ${pluralCaption}...`);
                    });

                  }
                }
              );
            }
          }
        }
      }, {
        widget: 'dxButton',
        toolbar: 'bottom',
        location: 'after',
        options: {
          text: 'Cancel',
          icon: 'remove',
          type: 'danger',
          onClick: (e) => {
            dlg.close();
          }
        }
      }]
    });

    dlg.open().done(e => {
      $("#" + contactGridOptions.elementAttr.id).hide();

      if (that.options.sourceItems) {
        that.dataService
          .GetItemInfo(that.options.sourceItems)
          .MakeRequest()
          .done((e: SourceGridItem[]) => {
            sourceGridData = e;
            (form.getEditor("SourceGrid") as DevExpress.ui.dxDataGrid).option(
              "dataSource",
              sourceGridData
            );
          });
      }

      if (that.options.targetItem) {
        that.dataService
          .GetItemInfo(that.options.targetItem)
          .MakeRequest()
          .done((e: SourceGridItem[]) => {
            targetGridData = e;
            (form.getEditor("TargetGrid") as DevExpress.ui.dxDataGrid).option(
              "dataSource",
              targetGridData
            );
          });
      }
    });
  }
}

export interface combinerOptions {
  targetItem?: itemDescriptor;
  sourceItems?: itemDescriptor;
}

export interface itemDescriptor {
  itemtype: itemTypes;
  guids: string[];
}

export interface SourceGridItem {
  ItemId: string;
  ItemName: string;
  AcctPkg: string;
  AccountName: string;
}

export interface ILongJob {
  JobId: string;
  InfoURL: string;
  CancelURL: string;
}

export interface ILongJobStatusInfo {
  JobId: string;
  StepsCompleted: number;
  TotalSteps: number;
  ElapsedMilliseconds: number;
  Description: string;
  IsComplete: boolean;
  Result: any;
}

export interface IFetchContacts {
  SourceAccounts: string[];
  TargetAccount: string;
}

export interface IContactGridItem {
  contactid: string;
  Name: string;
  Action: string;
}

export interface ICombineItemsCommand {
  TargetItem: itemDescriptor;
  SourceItems: itemDescriptor;
  CombineOption: "KeepDestinationValue" | "ReplaceEmptyDestinationValue";
  AdvancedContactMerge: boolean;
  ContactMergeSettings: { SourceID: string; TargetID: string }[];
}
