import { itemTypes } from "../../enums/enums";
import { ItemBase } from "./itembase";
import { DisplayInfo } from "../../infrastructure/context";

export class Note extends ItemBase {
  DisplayName: string;
  get ItemType() {
    return itemTypes.itemNote;
  }
  get TypeName() {
    return "Note";
  }
  set TypeName(name) {
    if (name && this.TypeName !== name) this.TypeName = name;
  }
  get RequiresParentItem(): boolean {
    return true;
  }
  constructor(args) {
    super(args);
    let theNote = this;
    $.each(args, function(key, val) {
      if (theNote.hasOwnProperty(key)) theNote[key] = val;
    });
    if (!theNote.DisplayName) {
      DisplayInfo(theNote.ItemType).done(function(displayinfo) {
        theNote.DisplayName = displayinfo.DisplayName;
        theNote.TypeName = displayinfo.TypeName;
      });
    }
  }
}
