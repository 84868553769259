import "devextreme/viz/range_selector";
import { GridContainerTypes, itemTypes, CenterType } from "../../enums/enums";

import { GetPreference } from "../../infrastructure/user/getpreference";
import { Grid } from "../../legacy-centers-grids/grid";
import * as moment from "moment";
import { GridSetup } from "../../services/grid/helpers/gridsetup";
import { TDFRequest } from "../../services/request";
import { GetDevice } from "../../util/allutils";
import "devextreme/ui/popover";
import "devextreme/ui/date_box";
import "devextreme/ui/number_box";
import { BaseDataCenter } from "../../datacenters/basedatacenter";
import { GridToolbar } from "../grids/gridtoolbars/gridtoolbar";
import { LoadCompany } from "../../infrastructure/context";
import { Preferences } from "../../infrastructure/user/preferences";
export class QuoteManager {
  GridToolbar: GridToolbar;
  GridToolbarContainer: string = "quotemanagertoolbar";
  GridContainer: string = "quotemanagergrid";
  QuoteManagerContainer: string = "quotemanager";
  myGrid: Grid;
  QuoteGridRange: DevExpress.viz.dxRangeSelector;
  FilterSheet: DevExpress.ui.dxPopover;
  MyQuotes: DevExpress.ui.dxCheckBox;
  SelectedParent: DevExpress.ui.dxCheckBox;
  KeyFilter: DevExpress.ui.dxTextBox;
  GridDateFilter = {
    startValue: moment(
      moment()
        .startOf("year")
        .add(-1, "years")
    ).startOf("year"), //new Date(2011, 1, 1),
    endValue: moment().endOf("year")
  };
  RequestModel: GridSetup.QuoteManagerRequestModel;
  constructor(args: any) {
    let manager = this;
    $.each(args, function(key, val) {
      manager[key] = val;
    });
    $("#tdfbodycontent")
      .append($("<div />").attr("id", manager.QuoteManagerContainer))
      .append($("<div />").attr("id", "tdfcontextmenu"));

    $.when(LoadCompany()).done(function() {
      manager.Init();
    });

    if (!$("#quotegridfiltersettings").length) {
      $("<div id='quotegridfiltersettings' />").appendTo("#tdfbody");
    }
    manager.FilterSettings();
  }

  Init() {
    let manager = this;
    $("#" + manager.QuoteManagerContainer)
      .append(
        $("<div />")
          .attr("id", manager.GridToolbarContainer)
          .css("min-height", "70px")
      )
      .append($("<div />").attr("id", manager.GridContainer));
    // $("#" + manager.GridToolbarContainer).append($("<hr>"))
    let request = new TDFRequest({
      url: "/core/user/GetToolbarInfo/",
      data: {
        type: itemTypes.itemQuote,
        containertype: GridContainerTypes.QuoteManager
      },
      type: "GET"
    });
    request.MakeRequest().done(function(response) {
      manager.GridToolbar = new GridToolbar(response);
      manager.GridToolbar.ToolbarContainerID = manager.GridToolbarContainer;

      manager.GetGridRequestModel();
      manager.GridToolbar.ExtraItems = manager.ToolbarFilters();
      if (!manager.GridToolbar.CurrentView)
        manager.GridToolbar.CurrentView = manager.GridToolbar.DefaultView;
      manager.GridToolbar.Render();
      manager.GetData();
    });
  }

  GetGridRequestModel(viewGUID?: string) {
    let manager = this;

    let requestArgs = new GridSetup.QuoteManagerRequestArgs();
    requestArgs.GUID = viewGUID || manager.GridToolbar.DefaultView;
    requestArgs.ContainerID = ""; // Preferences.GetPreference("HomeList", "ManagersConsole"),

    (requestArgs.FilterStartDate = manager.GridDateFilter.startValue.format(
      "YYYY/MM/DD"
    )),
      (requestArgs.FilterEndDate = manager.GridDateFilter.endValue.format(
        "YYYY/MM/DD"
      )),
      (requestArgs.MyQuotesOnly = false),
      (requestArgs.ParentID = ""),
      (requestArgs.SelectedParent = false),
      (requestArgs.TDFKeyFilter = "");

    manager.RequestModel = new GridSetup.QuoteManagerRequestModel(requestArgs);
    manager.GridToolbar.GridDataRequestModel = manager.RequestModel;
  }

  GetData() {
    let manager = this;

    let request = new TDFRequest({
      url: manager.RequestModel.Path,
      data: manager.RequestModel.RequestArgs,
      type: "GET"
    });
    request.spinnerContainerID = manager.GridContainer;

    if (manager.GridToolbar.ViewSelector) {
      if (!manager.GridToolbar.ViewSelector.option("value")) {
        manager.GridToolbar.ViewSelector.option(
          "value",
          manager.RequestModel.RequestArgs.GUID
        );
      }
    }
    request.MakeRequest().done(function(response) {
      if (manager.myGrid && manager.myGrid.GridObject) {
        manager.myGrid.GridObject.option("dataSource", response);
        //  return recentdfd.promise(recentdfd.resolve(manager));
      } else {
        manager.myGrid = new Grid(
          response,
          {
            GridContainer: $("#" + manager.GridContainer),
            GridToolbar: manager.GridToolbar
          },
          manager.GridOptions(),
          manager,
          e => {
            manager.GridToolbar.SelectionChanged(e);
          }
        );
        //$.each(manager.myGrid.Options.columns, function (k, v:DevExpress.ui.dxDataGridColumn) {
        //    //if (v.dataField.toLowerCase() === "status") {
        //    //    v.calculateDisplayValue = function () {
        //    //        //$.get(theQuote.BaseQuotePath + "GetStatusOptions/", function (response: any) {
        //    //        //    if (true) {
        //    //        //        d.resolve(response);
        //    //        //    }
        //    //        //});
        //    //    }
        //    //}
        //});
        manager.myGrid.Render();
        manager.GridToolbar.CurrentGrid = manager.myGrid;
      }
    });
  }

  GridOptions() {
    let manager = this;
    let options: DevExpress.ui.dxDataGridOptions = {};
    options.showColumnLines = true;
    options.showRowLines = true;
    options.rowAlternationEnabled = true;
    options.showBorders = true;
    options.scrolling = {
      // showScrollbar: 'always'
      // mode: "virtual"
    };

    options.pager = {
      showPageSizeSelector: true,
      visible: true
    };
    options.editing = {
      allowUpdating: false,
      allowAdding: false,
      allowDeleting: false,
      mode: "cell"
    };
    options.columnChooser = {
      enabled: true,
      allowSearch: true,
      height: GetDevice().isDevice ? 260 : 500
    };

    options.columnAutoWidth = true;
    options.allowColumnResizing = true;
    options.height = GetDevice().isDevice
      ? $(window).innerHeight()
      : $(window).innerHeight() - 150;
    return options;
  }

  FilterSettings() {
    let manager = this;
    manager.FilterSheet = $("#quotegridfiltersettings")
      .dxPopover({
        // dataSource: [{
        contentTemplate: function(itemElement) {
          if (!manager.QuoteGridRange) {
            manager.QuoteGridRange = $("#quotedaterange").dxRangeSelector(
              "instance"
            );
          }

          $("<div />")
            .append($("<label />").text("Scale Start"))
            .append(
              $("<div />").dxDateBox({
                value: manager.QuoteGridRange.option("scale.startValue"),
                pickerType: GetDevice().isDevice ? "native" : "calendar",
                onValueChanged: function(e) {
                  manager.QuoteGridRange.option("scale.startValue", e.value);
                }
              })
            )
            .appendTo(itemElement);

          $("<div />")
            .append($("<label />").text("Scale End"))
            .append(
              $("<div />").dxDateBox({
                value: manager.QuoteGridRange.option("scale.endValue"),
                pickerType: GetDevice().isDevice ? "native" : "calendar",
                onValueChanged: function(e) {
                  manager.QuoteGridRange.option("scale.endValue", e.value);
                }
              })
            )
            .appendTo(itemElement);

          $("<div />")
            .append($("<label />").text("Max Range in (Years)"))
            .append(
              $("<div />").dxNumberBox({
                value: 1,
                min: 0.5,
                max: 10,
                step: 0.5,
                onValueChanged: function(e) {
                  manager.QuoteGridRange.option("scale.maxRange", {
                    years: e.value
                  });
                }
              })
            )
            .appendTo(itemElement);
        },

        target: "#quotedaterange",
        showTitle: false,
        width: 300,
        // usePopover: true,// !isMobile().IsMobile,
        // showCancelButton: true,
        visible: false
      })
      .dxPopover("instance");
  }
  ToolbarFilters() {
    let manager = this;

    return [
      {
        location: "before",
        locateInMenu: "never",
        template: function(
          itemData: any,
          itemIndex: number,
          itemElement: JQuery
        ) {
          let opts: DevExpress.viz.rangeSelector.dxRangeSelectorOptions = {
            elementAttr: { id: "quotedaterange" },

            margin: {
              top: 5
            },
            size: {
              height: 75
            },
            scale: {
              startValue: manager.GridDateFilter.startValue.toDate(), //moment().year(2015).startOf("year").toDate(),//new Date(2011, 1, 1),
              endValue: manager.GridDateFilter.endValue.toDate(),
              minorTickInterval: "week",
              tickInterval: "month", //{ years: 1 },
              minRange: "week",
              maxRange: { years: 1 },
              minorTick: { visible: true },
              label: { format: "monthAndYear" }
            },
            sliderMarker: {
              color: "#f0ad4e",
              format: "shortDate"
            },
            value: [
              moment(
                moment()
                  .startOf("year")
                  .add(-1, "years")
              )
                .startOf("year")
                .toDate(),
              moment().toDate()
            ],
            title: {
              horizontalAlignment: "left",
              margin: {
                bottom: -10
              },
              font: {
                size: "12px"
              },
              text: "" //"Select a date range"
            },
            onValueChanged: function(e) {
              manager.RequestModel.RequestArgs.FilterStartDate = moment(
                e.value[0]
              ).format("YYYY/MM/DD");
              manager.RequestModel.RequestArgs.FilterEndDate = moment(
                e.value[1]
              ).format("YYYY/MM/DD");
              manager.GetData();
            }
          };
          let daterange = $("<div />")
            .dxRangeSelector(opts)
            .dxRangeSelector("instance");
          $(daterange.element().children()[0]).addClass("img-responsive");
          itemElement.append(daterange.element());
        }
      },
      {
        location: "before",
        locateInMenu: "auto",
        showText: "inMenu",
        widget: "dxButton",
        options: {
          icon: "fa fa-cog",
          elementAttr: { id: "quotegridfilterset" },
          text: "Quote Manager Grid Filter Settings",
          onClick: function(e) {
            manager.FilterSheet.toggle(!manager.FilterSheet.option("visible"));
          }
        }
      },
      {
        location: "center",
        locateInMenu: "auto",
        showText: "inMenu",
        widget: "dxTextBox",
        options: {
          placeholder: "TDFKey Filter",
          valueChangedEvent: "blur",
          onValueChanged: function(e) {
            manager.RequestModel.RequestArgs.TDFKeyFilter = e.value;
            manager.GetData();
          }
        }
      },
      {
        location: "center",
        locateInMenu: "auto",
        showText: "inMenu",
        widget: "dxCheckBox",

        options: {
          text: "My Quotes",
          value:
            Preferences.GetPreference("MyQuotesOnly", "QuoteManager") === "1",
          onValueChanged: function(e) {
            manager.RequestModel.RequestArgs.MyQuotesOnly = e.value;
            manager.GetData();
          }
        }
      },
      {
        location: "center",
        locateInMenu: "auto",
        showText: "inMenu",
        widget: "dxCheckBox",

        options: {
          text: "Selected Parent",
          value: false,
          onValueChanged: function(e) {
            manager.RequestModel.RequestArgs.SelectedParent = e.value;
            manager.RequestModel.RequestArgs.ParentID = "";
            if (
              manager.RequestModel.RequestArgs.SelectedParent &&
              manager.myGrid.GridObject.getSelectedRowsData().length
            ) {
              manager.RequestModel.RequestArgs.ParentID = manager.myGrid.GridObject.getSelectedRowsData()[0][
                "ParentItemID"
              ];
              if (manager.RequestModel.RequestArgs.ParentID) manager.GetData();
            }
            if (e.previousValue) manager.GetData();
          }
        }
      }
    ];
  }
}
