import { TDFRequest } from "../services/request";

// can now say "import {actions} from api"
// put new classes into new files, then they can be imported easily to other modules.
// don't actually declare the class - the name of the file "is" the class.
// declaring it as "const" is sort of like window.actions = {}, except that it is readonly.
// an interface would NOT be declared const; only "export interface IWhatever { }"
export const actions = {
     changeAcctPkg: (e:ItemListWithPkgID) => { 
        return new TDFRequest({url:"/action/ChangeAccountPackage", type: "POST", data:e});
     }
}

// declaring as default, "import whatevernameyouwant from api"
export default {

  getTypeFromId: (e) => {
      return new TDFRequest({url:"/user/getTypeFromId", type: "GET", data: e});
  }
}

// Specifying export is what makes it importable. (makes it public)
 export interface ItemListWithPkgID {
    NewPkgID: string,
    ItemList: {ItemID: string, ItemType: number}[]
}
