import { TDFRequest } from "services/request";
import { FilterTarget } from "enums/rulesmanager/enums";
import { itemTypes } from "enums/enums";
import { DateRangeSetupOptions } from "interfaces/grids/daterangesetupoptions";

export class RulesRelativeDateFilterDataService {

    static LoadedFilterOptions: { [itemType: number]: DateRangeSetupOptions };
    private get _routePrefix(): string {
        return 'RulesManager/DateFilterSetup';
    }
    private get _route(): string {
        return `${TDFRequest.ApiPath}/${this._routePrefix}`;
    }

    GetSettings(filterTarget: FilterTarget, itemType: itemTypes) {
        let service = this;

        let d: JQueryDeferred<DateRangeSetupOptions> = $.Deferred();
        if (RulesRelativeDateFilterDataService.LoadedFilterOptions && RulesRelativeDateFilterDataService.LoadedFilterOptions[itemType]) {
            return d.promise(d.resolve(RulesRelativeDateFilterDataService.LoadedFilterOptions[itemType]));
        }

        new TDFRequest({
            url: `${service._route}?filterTarget=${filterTarget}&itemType=${itemType}`,
            type: 'GET'
        }).MakeRequest().done((response: DateRangeSetupOptions) => {
            if (typeof RulesRelativeDateFilterDataService.LoadedFilterOptions !== "undefined") {
                RulesRelativeDateFilterDataService.LoadedFilterOptions[itemType] = response;
            } else {
                RulesRelativeDateFilterDataService.LoadedFilterOptions = { [itemType]: response };

            }
            d.resolve(RulesRelativeDateFilterDataService.LoadedFilterOptions[itemType]);
        })

        return d.promise();

    }
}