import 'devextreme/ui/button';
import * as moment from 'moment';
import { itemTypes } from '../../enums/enums';
import { Preferences } from '../../infrastructure/user/preferences';
import { NewNoteData, NoteData } from '../../interfaces/quicknotes/interfaces';
import { TDFRequest } from '../../services/request';
import { Notification } from '../dialogs/notification';
import { Timer } from '../timetracker/timetracker';
import { AttachmentViewer } from './attachmentviewer';
import { GetDevice, DomSafeID } from '../../util/allutils';
import "bootstrap/js/src/collapse"
import 'devextreme/ui/popup';
import 'devextreme/ui/data_grid';
import { confirm } from 'devextreme/ui/dialog';
import notify from "devextreme/ui/notify";
import { CurrentUser } from '../../infrastructure/context';

export class QuickNotes {
  container: JQuery;
  theData;
  itemId: string;
  multipleIds: Array<string>; // Used to create copies of the note for multiple items at the same time.
  iBase;
  noteFormID: string;
  Dialog: DevExpress.ui.dxPopup;

  constructor(data, container, iBase) {
    let theQuickNoteModule = this;

    theQuickNoteModule.container = container;
    theQuickNoteModule.theData = data;
    theQuickNoteModule.itemId = iBase.ItemID || iBase.ItemId || iBase['TDF GUID'] || iBase.TDFItemID;
    theQuickNoteModule.iBase = iBase;

    return theQuickNoteModule;
  }

  printDiv(divName, outerContainer) {
    let theQuickNoteModule = this;

    let printContents = $('#' + divName).html();

    let css =
      '<style>.item-quicknote-contain {padding: 20px;border: 1px solid black;margin: 20px;' +
      'border-radius: 10px; max - width:800px; margin-right:auto; margin-left:auto;' +
      '}.item-quicknote-header{border-bottom:1px solid black; font-weight:800; } input {display:none;} </style>';

    let pageHeader = '<div><Strong>Subject: </strong>' + theQuickNoteModule.iBase.iMain.ItemSubject + '</div><hr>';

    printContents = css + pageHeader + printContents;
    //+ "<script>(function() {window.print()})();</script>"
    var w = window.open();

    $(w.document.body).html(printContents);
  }

  refreshQuickNotes(outerContainer) {
    let theQuickNoteModule = this;
    new TDFRequest({
      url: TDFRequest.ApiPath + '/quicknotes/notes?itemId=' + theQuickNoteModule.itemId,
      type: 'GET'
    }).MakeRequest()
      .done((response: any) => {
        theQuickNoteModule.theData = response;
        outerContainer.empty();
        theQuickNoteModule.RenderQuickNoteTab();
      });
  }

  generateManualBookmarksPopup(data, templateText, theForm) {
    let theQuickNoteModule = this;
    let bookmarkEditGrid: DevExpress.ui.dxDataGrid;
    let bookmarkDetailDialog: DevExpress.ui.dxPopup;

    if (!$('#bookmarkDetailDialog').length) {
      theQuickNoteModule.container.append($("<div id='bookmarkDetailDialog' />"));
    }

    bookmarkDetailDialog = $('#bookmarkDetailDialog')
      .dxPopup({
        title: 'Enter Bookmark Text',
        height: 500,
        dragEnabled: true,
        maxWidth: 600,
        fullScreen: GetDevice().isDevice,
        showTitle: true,
        showCloseButton: true,
        toolbarItems: [
          {
            location: 'after',
            toolbar: 'bottom',
            widget: 'dxButton',

            options: {
              text: 'OK',
              type: 'success',
              icon: 'check',
              elementAttr: { id: 'submitBookmarkBtn' },
              onClick: function (e) {
                let saveAll = function () {
                  let validationCompleteFlag = false;

                  function myReplace(str, replaceWhat, replaceTo) {
                    str = str.split(replaceWhat).join(replaceTo);
                    return str;
                  }
                  let datasource = bookmarkEditGrid.getDataSource();
                  let bookmarks = datasource.items();

                  //check if all fields are accounted for
                  $.each(bookmarks, function (i, val) {
                    if (val.Text == null || val.Text == '') {
                      validationCompleteFlag = true;
                      return false;
                    }
                  });
                  if (validationCompleteFlag) {
                    new Notification({ message: 'Please complete form.', type: 'warning', displayTime: 4000 });
                  }

                  if (!validationCompleteFlag) {
                    $.each(bookmarks, function (index, val) {
                      templateText = myReplace(templateText, val.Bookmark, val.Text);
                    });

                    (theForm.getEditor('NoteText') as any).reset();
                    (theForm.getEditor('NoteText') as any).option('value', templateText);

                    bookmarkDetailDialog.hide();
                  }
                };

                if (bookmarkEditGrid.hasEditData()) {
                  let result = confirm('Save your changes made to form?', 'Confirm changes');
                  result.done(function (dialogResult) {
                    if (dialogResult) {
                      bookmarkEditGrid.saveEditData().done(saveAll);
                      //notify("Assists have been saved.", "success", 2000);
                    } else {
                      bookmarkEditGrid.cancelEditData();
                    }
                  });
                } else {
                  saveAll();
                }
              }
            }
          }, {
            location: 'after',
            toolbar: 'bottom',
            widget: 'dxButton',
            options: {
              text: 'Cancel',
              type: 'danger',
              icon: 'remove',
              onClick: (e) => {
                bookmarkDetailDialog.hide();
              }
            }
          }
        ],
        contentTemplate: function (contentElement) {
          let editData = [];

          $.each(data, function (index, value) {
            editData.push({ Bookmark: value, Text: '' });
          });

          function logEvent(eventName) {
            var logList = $('#events ul'),
              newItem = $('<li>', { text: eventName });

            logList.prepend(newItem);
          }

          let opts: DevExpress.ui.dxDataGridOptions = {
            dataSource: editData,
            paging: {
              enabled: false
            },
            editing: {
              mode: 'batch',
              allowUpdating: true
            },
            columns: [
              {
                dataField: 'Bookmark',
                allowEditing: false
              },
              {
                dataField: 'Text',
                validationRules: [{ type: 'required' }]
              }
            ],
            onEditingStart: function (e) {
              logEvent('EditingStart');
            },
            onInitNewRow: function (e) {
              logEvent('InitNewRow');
            },
            onRowInserting: function (e) {
              logEvent('RowInserting');
            },
            onRowInserted: function (e) {
              logEvent('RowInserted');
            },
            onRowUpdating: function (e) {
              logEvent('RowUpdating');
            },
            onRowUpdated: function (e) {
              logEvent('RowUpdated');
            }
          };

          bookmarkEditGrid = $('<div >')
            .dxDataGrid(opts)
            .dxDataGrid('instance');
          contentElement.append(bookmarkEditGrid.element());
        }
      })
      .dxPopup('instance');

    bookmarkDetailDialog.show().done(() => {
      let el = bookmarkEditGrid.getCellElement(0, 'Text');
      bookmarkEditGrid.focus(el);
      bookmarkEditGrid.editCell(0, 'Text');
    });
  }

  generateUserAssistPopup(assistData, theForm, itemData) {
    let theQuickNoteModule = this;
    let assistDialog: DevExpress.ui.dxPopup;
    let assistGrid: DevExpress.ui.dxDataGrid;

    if (!$('#assistDialog').length) {
      theQuickNoteModule.container.append($("<div id='assistDialog' />"));
    }

    let myDuration = theForm.option('formData').Duration;
    let myBillable = theForm.option('formData').Billable;
    let myReasonCode = theForm.option('formData').UnbilledReasonCode;

    let modifiedUserData = [];

    $.each(assistData, function (index, value) {
      modifiedUserData.push({
        UserID: value.SearchKey,
        Name: value.Name,
        Billable: myBillable,
        Duration: myDuration != null && myDuration > 0 ? myDuration : itemData.NewNoteOptions.ForceNonZeroDuration ? 1 : 0,
        ReasonCode: myBillable ? $.grep(itemData.NewNoteOptions.billableMenuValues, (v: any, k) => { return v.IsBillable; })[0].ReasonCode : myReasonCode
      });
    });

    assistDialog = $('#assistDialog')
      .dxPopup({
        title: 'Choose Assists',
        height: 600,
        dragEnabled: true,
        maxWidth: 800,
        fullScreen: GetDevice().isDevice,
        showTitle: true,
        showCloseButton: true,
        toolbarItems: [
          {
            location: 'after',
            toolbar: 'bottom',
            widget: 'dxButton',
            options: {
              text: 'OK',
              type: 'success',
              icon: 'check',
              elementAttr: { id: 'selectAssistBtn' },
              onClick: function (e) {
                $('.assist-list').empty();

                let saveAll = function () {
                  let validationCompleteFlag = false;
                  let validationNonZeroFlag = false;
                  let validationSelectedAssistFlag = false;
                  let data = assistGrid.getSelectedRowsData();
                  let assistString = '';
                  //let objdata = data.items();

                  if (data.length < 1) {
                    validationSelectedAssistFlag = true;
                    new Notification({ message: 'Please select at least one assistant or exit the prompt if there were none.', type: 'warning', displayTime: 4000 });
                  }

                  //check for zero if non zero duration is enforced
                  if (itemData.NewNoteOptions.ForceNonZeroDuration) {
                    //check if all fields are accounted for
                    $.each(data, function (i, val) {
                      if (val.Duration == 0) {
                        validationNonZeroFlag = true;
                        return false;
                      }
                    });
                    if (validationNonZeroFlag) {
                      new Notification({
                        message: 'Cannot enter a minute value of zero.',
                        type: 'warning',
                        displayTime: 4000
                      });
                    }
                  }

                  if (itemData.ItemType == itemTypes.itemSupport) {
                    //check if all fields are accounted for
                    $.each(data, function (i, val) {
                      if (val.ReasonCode == null) {
                        validationCompleteFlag = true;
                        return false;
                      }
                    });
                    if (validationCompleteFlag) {
                      new Notification({ message: 'Please complete form.', type: 'warning', displayTime: 4000 });
                    }
                  }

                  if (!validationCompleteFlag && !validationNonZeroFlag && !validationSelectedAssistFlag) {
                    $.each(data, function (index, value) {
                      if (value.ReasonCode !== '') {
                        value.Billable = false;
                      } else {
                        value.Billable = true;
                      }

                      assistString = assistString + value.Name + ' | ';
                      delete value.Name;
                    });

                    // Get rid of last pipe
                    assistString = assistString.substring(0, assistString.length - 2);

                    //console.log(theForm.option("formData"));
                    theForm.option('formData').UsersProvidingAssistance = data;

                    $('.assist-list').html('Users Assisting: ' + assistString);

                    $('#clearAssistBtn').dxButton('instance').option('disabled', false);

                    assistDialog.hide();
                  }
                };

                if (assistGrid.hasEditData()) {
                  let result = confirm('Save your changes made to form?', 'Confirm changes');
                  result.done(function (dialogResult) {
                    if (dialogResult) {
                      assistGrid.saveEditData().done(saveAll);
                      //notify("Assists have been saved.", "success", 2000);
                    } else {
                      assistGrid.cancelEditData();
                    }
                  });
                } else {
                  saveAll();
                }
              }
            }
          }, {
            location: 'after',
            toolbar: 'bottom',
            widget: 'dxButton',
            options: {
              text: 'Cancel',
              type: 'danger',
              icon: 'remove',
              onClick: (e) => {
                assistDialog.hide();
              }
            }
          }
        ],
        contentTemplate: function (contentElement) {
          let opts: DevExpress.ui.dxDataGridOptions = {
            selection: {
              mode: 'multiple',
              showCheckBoxesMode: 'always'
            },
            editing: {
              mode: 'batch',
              allowUpdating: true
            },
            searchPanel: {
              visible: true
            },
            hoverStateEnabled: true,
            onSelectionChanged: function (e) {
              $('#selectAssistBtn')
                .dxButton('instance')
                .option('disabled', false);
            },
            width: '100%',
            height: 500,
            columns: [
              {
                dataField: 'Name',
                dataType: 'string',
                allowEditing: false
              },
              {
                dataField: 'ReasonCode',
                caption: 'Billable',
                width: 125,
                visible: itemData.ItemType == itemTypes.itemSupport ? true : false,
                lookup: {
                  dataSource: itemData.NewNoteOptions.billableMenuValues,
                  displayExpr: 'DisplayValue',
                  valueExpr: 'ReasonCode'
                }
              },
              {
                dataField: 'Duration',
                caption: 'Minutes',
                dataType: 'number',
                width: 'auto',
                validationRules: [
                  { type: 'required' },
                  {
                    type: 'pattern',
                    pattern: '^[0-9]*$',
                    message: 'Use digits.'
                  }
                ]
              },
              {
                dataField: 'Service',
                editCellTemplate: function (cellElement, cellInfo) {
                  var div = document.createElement('div');
                  cellElement.get(0).appendChild(div);
                  new TDFRequest({
                    url: TDFRequest.ApiPath + '/QuickNotes/GetServicesForAssistant?userID=' + cellInfo.data.UserID + '&ItemType=' + itemData.ItemType,
                    type: 'GET'
                  }).MakeRequest()
                    .done(serviceTypes => {
                      cellInfo.setValue(serviceTypes.default[0].Display);
                      cellInfo.data.ServiceID = serviceTypes.default[0].ID;

                      $(div).dxLookup({
                        dataSource: serviceTypes.services,
                        //valueExpr: 'ID',
                        displayExpr: 'Display',
                        onValueChanged: function (e) {
                          cellInfo.setValue(e.value.Display);
                          cellInfo.data.ServiceID = e.value.ID;
                        }
                      });
                    });
                }
              }
            ],
            dataSource: modifiedUserData
          };

          assistGrid = $('<div >').dxDataGrid(opts).dxDataGrid('instance');
          contentElement.append(assistGrid.element());
        }
      })
      .dxPopup('instance');

    assistDialog.show();
  }

  generateTemplatePopup(templateData, theForm) {
    let theQuickNoteModule = this;
    let templateDialog: DevExpress.ui.dxPopup;

    let templateGrid: DevExpress.ui.dxDataGrid;

    if (!$('#templateDialog').length) {
      theQuickNoteModule.container.append($("<div id='templateDialog' />"));
    }

    templateDialog = $('#templateDialog')
      .dxPopup({
        title: 'Select Template',
        height: 500,
        dragEnabled: true,
        maxWidth: 600,
        fullScreen: GetDevice().isDevice,
        showTitle: true,
        showCloseButton: true,
        toolbarItems: [
          {
            location: 'after',
            toolbar: 'bottom',
            widget: 'dxButton',

            options: {
              text: 'Cancel',
              type: 'danger',
              onClick: function (e) {
                templateDialog.hide();
              }
            }
          }
        ],
        contentTemplate: function (contentElement) {
          let opts: DevExpress.ui.dxDataGridOptions = {
            selection: { mode: 'single' },
            hoverStateEnabled: true,
            searchPanel: {
              visible: true
            },
            onSelectionChanged: function (e) {
              let templateID = e.selectedRowsData[0].TemplateID;
              let template = '';

              new TDFRequest({
                url: TDFRequest.ApiPath + '/QuickNotes/' + theQuickNoteModule.itemId + '/' + templateID + '/GetPopulatedTemplate?multiItem=' + (theQuickNoteModule.multipleIds.length > 1).toString(),
                type: 'GET'
              }).MakeRequest()
                .done(response => {
                  template = response.Text;

                  if ($.isArray(response.ManualBookmarks) && response.ManualBookmarks.length != 0) {
                    //handler either not an array or empty array
                    theQuickNoteModule.generateManualBookmarksPopup(response.ManualBookmarks, template, theForm);
                  } else {
                    (theForm.getEditor('NoteText') as any).reset();
                    (theForm.getEditor('NoteText') as any).option('value', template);
                  }

                  templateDialog.hide();
                });
            },
            width: '100%',
            height: 400,
            columns: [
              {
                dataField: 'TemplateTitle',
                caption: 'Name'
              },
              {
                dataField: 'TemplateID',
                visible: false
              },
              {
                dataField: 'TemplateContent',
                visible: false
              }
            ],
            dataSource: templateData
          };

          templateGrid = $('<div >').dxDataGrid(opts).dxDataGrid('instance');
          contentElement.append(templateGrid.element());
        }
      })
      .dxPopup('instance');

    templateDialog.show();
  }

  generate2017ChargePopup(response, outerContainer, itemData, onRefundTab1, forSingleNote: boolean = true) {
    let theQuickNoteModule = this;
    let chargeDialog: DevExpress.ui.dxPopup;
    let d = $.Deferred();

    let onRefundTab = 0;

    let route = forSingleNote ? response.NoteID + '/GetPendingChargesForNote' : onRefundTab + '/GetPendingChargesForItem';
    let chargeGrid: DevExpress.ui.dxDataGrid;

    if (!$('#chargeDialog').length) {
      theQuickNoteModule.container.append($("<div id='chargeDialog' />"));
    }

    let buildGrid = function (tab, noteChargeData) {
      let theGrid: DevExpress.ui.dxDataGrid;

      if (!$.isArray(noteChargeData) || !noteChargeData.length) {
        //handler either not an array or empty array
        let data = [];
        data.push(noteChargeData);
        noteChargeData = data;
      }

      function logEvent(eventName) {
        var logList = $('#events ul'),
          newItem = $('<li>', { text: eventName });

        logList.prepend(newItem);
      }

      let opts: DevExpress.ui.dxDataGridOptions = {
        dataSource: noteChargeData,
        selection: {
          mode: noteChargeData.length > 1 ? 'multiple' : 'single'
        },
        editing: {
          mode: 'batch',
          allowUpdating: true
        },
        paging: {
          pageSize: 10
        },
        pager: {
          showPageSizeSelector: true,
          allowedPageSizes: [5, 10, 20],
          showInfo: true
        },
        columns: [
          {
            dataField: 'NoteDate',
            caption: 'Date',
            visible: true,
            dataType: 'date',
            allowEditing: false,
            format: 'MM/dd/yyyy'
          },
          {
            dataField: 'AuthorName',
            caption: 'Author',
            visible: true,
            allowEditing: false
          },
          {
            dataField: 'Duration',
            caption: 'Minutes',
            visible: true,
            dataType: 'number',
            allowEditing: false
          },
          {
            dataField: 'MaxCredits',
            caption: 'Expected',
            visible: true,
            dataType: 'number',
            allowEditing: false
          },
          {
            dataField: 'TotalAssociatedCharges',
            caption: !tab ? 'Charged to Date' : 'To Reverse',
            visible: true,
            dataType: 'number',
            allowEditing: !tab ? false : true,
            cssClass: !tab ? '' : 'support-note-edit-charge',
            validationRules: [
              {
                type: 'custom',
                validationCallback: function (options) {
                  var key = options.validator.option('validationGroup').key;
                  var rowIndex = chargeGrid.getRowIndexByKey(key);
                  if (options.value > key.MaxCredits || options.value < 0) {
                    return false;
                  } else {
                    return true;
                  }
                },
                message:
                  'Refund cannot be more than Expected, and greater than zero.'
              }
            ]
          },
          {
            dataField: 'Remaining',
            caption: !tab ? 'To Charge' : 'Uncharged',
            visible: true,
            dataType: 'number',
            allowEditing: !tab ? true : false,
            cssClass: !tab ? 'support-note-edit-charge' : '',
            validationRules: [
              {
                type: 'custom',
                validationCallback: function (options) {
                  var key = options.validator.option('validationGroup').key;
                  var rowIndex = chargeGrid.getRowIndexByKey(key);
                  if (options.value > key.MaxCredits || options.value < 0) {
                    return false;
                  } else {
                    return true;
                  }
                },
                message:
                  'Charge cannot be more than Expected, and greater than zero.'
              }
            ]
          }
        ],
        onEditingStart: function (e) {
          logEvent('EditingStart');
        },
        onInitNewRow: function (e) {
          logEvent('InitNewRow');
        },
        onRowInserting: function (e) {
          logEvent('RowInserting');
        },
        onRowInserted: function (e) {
          logEvent('RowInserted');
        },
        onRowUpdating: function (e) {
          logEvent('RowUpdating');
        },
        onRowUpdated: function (e) {
          logEvent('RowUpdated');
        }
      };

      theGrid = $('<div >').dxDataGrid(opts).dxDataGrid('instance');

      return theGrid;
    };

    let gridContainer = $('<div />').addClass('quicknotes-charge-grid-contain');

    // hide or disable reverse charge here
    let tabs = [
      {
        id: 0,
        text: 'Apply Charge',
        content: 'Apply Charge content'
      },
      {
        id: 1,
        text: 'Reverse Charge',
        content: 'Reverse Charge content'
      }
    ];

    let tabsInstance = $('<div />')
      .dxTabs({
        dataSource: tabs,
        selectedIndex: 0,
        disabled: forSingleNote ? true : !itemData.NewNoteOptions.UserCreditSettings.CanReverseCharge,
        onItemClick: function (e) {
          onRefundTab = e.itemData.id;
          let btnRoute = onRefundTab + '/GetPendingChargesForItem';
          new TDFRequest({
            url: TDFRequest.ApiPath + '/' + 'quicknotes/' + theQuickNoteModule.itemId + '/' + btnRoute
          }).MakeRequest()
            .done(noteChargeData => {
              chargeGrid = buildGrid(onRefundTab, noteChargeData);
              gridContainer.empty();
              gridContainer.append(chargeGrid.element());
            });
        }
      })
      .dxTabs('instance');

    chargeDialog = $('#chargeDialog')
      .dxPopup({
        title: 'Select notes charges should apply.',
        height: 500,
        dragEnabled: true,
        maxWidth: 600,
        fullScreen: GetDevice().isDevice,
        showTitle: true,
        showCloseButton: true,
        toolbarItems: [
          {
            location: 'after',
            toolbar: 'bottom',
            widget: 'dxButton',
            options: {
              text: 'Apply',
              type: 'success',
              icon: 'check',
              onClick: function (e) {
                let saveAll = function () {
                  let selectedTab = tabsInstance.option('selectedIndex');
                  let dataSource;
                  let data;

                  if (forSingleNote) {
                    dataSource = chargeGrid.getDataSource();
                    data = dataSource.items();
                  } else {
                    dataSource = chargeGrid.getSelectedRowsData();
                    data = dataSource;
                  }

                  let linkedNotes = [];

                  $.each(data, function (index, val) {
                    linkedNotes.push({
                      NoteID: val.NoteID,
                      ChargeAmount: selectedTab
                        ? val.TotalAssociatedCharges * -1
                        : val.Remaining
                    });
                  });

                  //have to make object for api routing purposes.
                  let linkedNotesObj = {
                    data: linkedNotes
                  };

                  new TDFRequest({
                    url: TDFRequest.ApiPath + '/' + 'QuickNotes/' + theQuickNoteModule.itemId + '/PerformCharge',
                    type: 'POST',
                    data: linkedNotesObj
                  }).MakeRequest()
                    .done(noteChargeData => {
                      if (!$.isArray(noteChargeData) || !noteChargeData.length) {
                        //handler either not an array or empty array
                        new Notification({ message: 'Error, no charges applied.', type: 'warning', displayTime: 3000 });
                      } else {
                        new Notification({ message: 'Charges Applied', type: 'success', displayTime: 3000 });
                        d.resolve();
                      }
                    });

                  setTimeout(() => {
                    theQuickNoteModule.refreshQuickNotes(outerContainer);
                  }, 800);

                  chargeDialog.hide();
                };

                if (chargeGrid.hasEditData()) {
                  let result = confirm('Save your changes made to form?', 'Confirm changes');
                  result.done(function (dialogResult) {
                    if (dialogResult) {
                      chargeGrid.saveEditData();
                    } else {
                      chargeGrid.cancelEditData();
                    }
                  });
                } else {
                  saveAll();
                }
              }
            }
          },
          {
            location: 'after',
            toolbar: 'bottom',
            widget: 'dxButton',

            options: {
              text: 'Cancel',
              type: 'danger',
              icon: 'remove',
              onClick: function (e) {
                setTimeout(() => {
                  theQuickNoteModule.refreshQuickNotes(outerContainer);
                }, 800);

                chargeDialog.hide();
              }
            }
          }
        ],
        contentTemplate: function (contentElement) {
          new TDFRequest({
            url: TDFRequest.ApiPath + '/' + 'quicknotes/' + theQuickNoteModule.itemId + '/' + route
          }).MakeRequest()
            .done(noteChargeData => {
              chargeGrid = buildGrid(onRefundTab, noteChargeData);

              contentElement.append(tabsInstance.element());
              gridContainer.append(chargeGrid.element());

              contentElement.append(gridContainer);
            });
        }
      }).dxPopup('instance');

    chargeDialog.show().done(() => { });

    return d.promise();
  }

  generatePreviewPoupup(response, outerContainer, newNoteData, editMode, addNoteDialog) {
    let theQuickNoteModule = this;
    let d = $.Deferred();
    let previewDialog: DevExpress.ui.dxPopup;

    if (!$('#previewDialog').length) {
      theQuickNoteModule.container.append($("<div id='previewDialog' />"));
    }
    previewDialog = $('#previewDialog')
      .dxPopup({
        title: 'Preview Charge',
        height: 200,
        dragEnabled: true,
        maxWidth: 300,
        showTitle: true,
        showCloseButton: true,
        toolbarItems: [
          {
            location: 'after',
            toolbar: 'bottom',
            widget: 'dxButton',
            options: {
              text: 'OK',
              type: 'success',
              icon: 'check',
              onClick: function (e) {
                if (theQuickNoteModule.multipleIds) {
                  new TDFRequest({
                    url: TDFRequest.ApiPath + '/quicknotes/0/AddNoteToItemList',
                    type: 'POST',
                    data: {
                      itemIdList: theQuickNoteModule.multipleIds,
                      note: newNoteData
                    }
                  }).MakeRequest().done(response => {
                    if (true) {
                      let msg = 'Note(s) Created';
                      if (editMode === true) {
                        msg = 'Edit Successful';
                      }

                      new Notification({ message: msg, type: 'success', displayTime: 5000 });

                      d.resolve;
                      $(addNoteDialog).dxPopup('instance').hide();

                      setTimeout(() => {
                        theQuickNoteModule.refreshQuickNotes(outerContainer);
                      }, 500);
                    }
                  });
                } else {
                  new TDFRequest(
                    {
                      url: TDFRequest.ApiPath + '/quicknotes/0/AddorEditNote',
                      type: 'POST',
                      data: newNoteData
                    },
                    false
                  )
                    .MakeRequest()
                    .done(response => {
                      if (response.Valid === true) {
                        //note added
                        let msg = 'Note Created';
                        if (editMode === true) {
                          msg = 'Edit Successful';
                        }
                        new Notification({ message: msg, type: 'success', displayTime: 5000 });

                        d.resolve();
                      } else {
                        //there was an error
                        new Notification({ message: response.Message, type: 'error', displayTime: 5000 });
                      }
                    });

                  previewDialog.hide();
                  $(addNoteDialog).dxPopup('instance').hide();
                  setTimeout(() => {
                    theQuickNoteModule.refreshQuickNotes(outerContainer);
                  }, 500);
                }
              }
            }
          }, {
            location: 'after',
            toolbar: 'bottom',
            widget: 'dxButton',

            options: {
              text: 'Cancel',
              type: 'danger',
              icon: 'remove',
              onClick: function (e) {
                previewDialog.hide();
              }
            }
          },
        ],
        contentTemplate: function (contentElement) {
          let theData = response.Response.TheNoteResponse[0];

          if (theQuickNoteModule.multipleIds) {
            theData.AutoCharge = theData.AutoCharge * theQuickNoteModule.multipleIds.length;
          }

          let info = '<p><strong>Add note and charge ' + theData.AutoCharge + ' credits?';
          let infoDiv = $('<div />').html(info);
          contentElement.append(infoDiv);
        }
      })
      .dxPopup('instance');

    previewDialog.show();
    return d.promise();
  }

  generateNoteSettingsPopup(outerContainer) {
    let theQuickNoteModule = this;
    let settingsDialog: DevExpress.ui.dxPopup;

    if (!$('#previewDialog').length) {
      theQuickNoteModule.container.append($("<div id='settingsDialog' />"));
    }

    let formData = theQuickNoteModule.getNoteSettingsFormData();

    let formFields: DevExpress.ui.dxFormSimpleItem[] = theQuickNoteModule.getNoteSettingsFormFields();

    let settingsForm: DevExpress.ui.dxForm = $('<div />')
      .dxForm(<DevExpress.ui.dxFormOptions>{
        formData: formData,
        items: formFields,
        colCount: 2
      })
      .dxForm('instance');

    settingsDialog = $('#settingsDialog')
      .dxPopup({
        title: 'Settings',
        height: 600,
        dragEnabled: true,
        maxWidth: 600,
        showTitle: true,
        showCloseButton: true,
        toolbarItems: [
          {
            location: 'after',
            toolbar: 'bottom',
            widget: 'dxButton',
            options: {
              text: 'Save',
              type: 'success',
              icon: 'save',
              onClick: function (e) {
                let settingsValues = settingsForm.option('formData');

                Preferences.SetPreference('ShowChargeNotes', Number(settingsValues.ChargeNotes), 'TDFSupport');

                settingsDialog.hide();
                setTimeout(() => {
                  theQuickNoteModule.refreshQuickNotes(outerContainer);
                }, 800);
              }
            }
          }, {
            location: 'after',
            toolbar: 'bottom',
            widget: 'dxButton',

            options: {
              text: 'Cancel',
              type: 'danger',
              icon: 'remove',
              onClick: function (e) {
                settingsDialog.hide();
              }
            }
          },
        ],
        contentTemplate: function (contentElement) {
          contentElement.append(settingsForm.element());
        }
      })
      .dxPopup('instance');

    settingsDialog.show();
  }

  getNoteSettingsFormFields() {
    let theQuickNoteModule = this;

    let items: DevExpress.ui.dxFormSimpleItem[] = [
      {
        dataField: 'ChargeNotes',
        label: {
          text: 'Display Charge Notes'
        },
        editorOptions: <DevExpress.ui.dxCheckBoxOptions>{},
        editorType: 'dxCheckBox'
      }
    ];

    return items;
  }

  getNoteSettingsFormData() {
    let theQuickNoteModule = this;

    let formData = {
      ChargeNotes: Number(Preferences.GetPreference('ShowChargeNotes', 'TDFSupport'))
    };

    return formData;
  }

  generateDeliveryQueuePoupup(selectedNote, data) {
    let theQuickNoteModule = this;
    let deliveryQueueDialog: DevExpress.ui.dxPopup;

    if (!$('#deliveryQueueDialog').length) {
      theQuickNoteModule.container.append($("<div id='deliveryQueueDialog' />"));
    }

    deliveryQueueDialog = $('#deliveryQueueDialog')
      .dxPopup({
        title: 'Note will be delivered to: ',
        height: 300,
        dragEnabled: true,
        maxWidth: 300,
        showTitle: true,
        showCloseButton: true,
        toolbarItems: [
          {
            location: 'after',
            toolbar: 'bottom',
            widget: 'dxButton',
            options: {
              text: 'Refresh',
              type: 'default',
              onClick: function (e) {
                new TDFRequest({
                  url: TDFRequest.ApiPath + '/QuickNotes/' + selectedNote.NoteID + '/QueueStatus'
                }).MakeRequest()
                  .done(queueList => {
                    $('#quicknote-delivery-queue').empty();
                    $('#quicknote-delivery-queue').hide();
                    setTimeout(() => {
                      $('#quicknote-delivery-queue').append(
                        queueList.join('<br />')
                      );
                      $('#quicknote-delivery-queue').slideDown();
                    }, 500);
                  });
              }
            }
          }, {
            location: 'after',
            toolbar: 'bottom',
            widget: 'dxButton',

            options: {
              text: 'OK',
              type: 'success',
              icon: 'check',
              onClick: function (e) {
                deliveryQueueDialog.hide();
              }
            }
          },
        ],
        contentTemplate: function (contentElement) {
          contentElement.append(
            $('<div />')
              .html(data.join('<br />'))
              .attr('id', 'quicknote-delivery-queue')
          );
        }
      }).dxPopup('instance');

    deliveryQueueDialog.show();
  }

  SupportID(type): DevExpress.ui.dxFormSimpleItem {
    let tqm = this;
    return {
      visible: type === itemTypes.itemSupport,
      editorType: 'dxTextBox',
      editorOptions: {
        value: tqm.itemId
      }
    };
  }

  generateNotePopup(itemData, formData, outerContainer, editMode: boolean = false, myTimer?: Timer) {
    let theQuickNoteModule = this;
    let tqm = this;
    let d = $.Deferred();

    let firstOfTheMonth = moment().startOf('month');
    let selectedDate = moment(formData.NoteDate);
    if (selectedDate.isBefore(firstOfTheMonth)) {
      new Notification({
        message: 'Notes cannot be added to past periods.',
        type: 'error'
      });
      return d.promise(d.resolve);
    }

    let formOptions: DevExpress.ui.dxFormOptions = {
      colCountByScreen: {
        xs: 6,
        sm: 6,
        md: 12,
        lg: 12
      },
      formData: formData
    };

    if (!myTimer) {
      myTimer = new Timer({ ItemID: theQuickNoteModule.itemId }, false);
    } else {
      myTimer.usePopup = false;
    }
    myTimer.Initialize().done(() => {
      formOptions.items = theQuickNoteModule.getNotePopupFormItems(
        itemData,
        formData,
        myTimer,
        editMode
      );
      theQuickNoteModule.noteFormID = 'addQuickNoteForm' + DomSafeID(theQuickNoteModule.itemId);

      let theForm = $('<div />').attr('id', theQuickNoteModule.noteFormID).dxForm(formOptions).dxForm('instance');
      let addNoteDialog = $('<div />').attr('id', '#addNote').appendTo(theQuickNoteModule.container);

      theQuickNoteModule.Dialog = addNoteDialog
        .dxPopup(<DevExpress.ui.dxPopupOptions>{
          title: editMode === false ? 'Add Note' : 'Edit Note',
          height: 650,
          dragEnabled: true,
          fullScreen: GetDevice().isDevice,
          showTitle: true,
          showCloseButton: true,
          onHidden: e => {
            if (myTimer) {
              myTimer.Clear();

              if (myTimer.getTime() === 0) {
                myTimer.delete();
              }
            }

            theQuickNoteModule.Dialog.dispose();

            myTimer.Deferred.resolve();
            //e.element.remove();
          },
          contentTemplate: function (contentElement) {
            if (editMode === false) {
              if (itemData.ItemType == itemTypes.itemSupport) {
                let defaultPrivate = 1;
                if (itemData.NewNoteOptions.defaultPublic === true) {
                  defaultPrivate = 0;
                }

                (theForm.getEditor('IsPrivate') as any).option('value', defaultPrivate);

                if (itemData.NewNoteOptions.ServiceOptions.AvailableServices.length) {
                  (theForm.getEditor('ServiceNatureID') as any).option('value', itemData.NewNoteOptions.ServiceOptions.AvailableServices[0].ID);
                }
              }
            } else {
              new TDFRequest({
                url: TDFRequest.ApiPath + '/quicknotes/GetEditRightsForNote?noteId=' + formData.NoteID + '&authorId=' + formData.AuthorID
              }).MakeRequest()
                .done(rightData => {
                  if (rightData.AllowEdit) {
                    //enable all disabled fields
                    if (itemData.ItemType == itemTypes.itemSupport) {
                      (theForm.getEditor('ServiceNatureID') as any).option('disabled', false);
                      (theForm.getEditor('ChannelID') as any).option('disabled', false);
                      (theForm.getEditor('Billable') as any).option('disabled', false);
                    }

                    (theForm.getEditor('IsPrivate') as any).option('disabled', false);
                    (theForm.getEditor('Duration') as any).option('disabled', false);
                  }

                  if (rightData.AllowEditNoteText) {
                    (theForm.getEditor('NoteText') as any).option('disabled', false);
                  }
                });
            }
            new TDFRequest({
              url: TDFRequest.ApiPath + '/quicknotes/GetAutoRecipientMsg?itemID=' + theQuickNoteModule.itemId + '&itemType=' + itemData.ItemType,
              type: 'GET'
            }).MakeRequest()
              .done(recipientMsg => {
                let alertBar: DevExpress.ui.dxToolbar;
                if (recipientMsg.length > 0) {
                  alertBar = $('<div />')
                    .addClass('quicknote-auto-recipient-msg col-sm-12')
                    .dxToolbar(<DevExpress.ui.dxToolbarOptions>{
                      items: [
                        {
                          text: recipientMsg
                        }
                      ]
                    })
                    .dxToolbar('instance');

                  if (!theQuickNoteModule.multipleIds) {
                    contentElement.append(alertBar.element());
                  }
                }

                contentElement.append(theForm.element());
              });
          },
          toolbarItems: [
            {
              location: 'after',
              toolbar: GetDevice().isDevice ? 'top' : 'bottom',
              widget: 'dxButton',
              options: {
                icon: GetDevice().isDevice ? 'fa fa-user-times' : null,
                text: GetDevice().isDevice ? null : 'Clear Assists',
                type: 'warning',
                disabled: true,
                elementAttr: { id: 'clearAssistBtn' },
                onClick: function (e) {
                  let btn = this;

                  var result = confirm('Are you sure you want to clear assists?', 'Confirm changes');
                  result.done(function (dialogResult) {
                    if (dialogResult) {
                      theForm.option('formData').UsersProvidingAssistance = [];
                      $('.assist-list').empty();

                      btn.option('disabled', true);
                      notify('Assists have been deleted.', 'success', 2000);
                    }
                  });
                }
              }
            },
            {
              location: 'after',
              toolbar: 'top',
              widget: 'dxButton',
              options: {
                type: 'default',
                hint: 'Add Template',
                icon: 'fa fa-sticky-note',
                onClick: function (e) {
                  new TDFRequest({
                    url: TDFRequest.ApiPath + '/quicknotes/GetNoteTemplates?itemId=' + theQuickNoteModule.itemId
                  }).MakeRequest()
                    .done(templateData => {
                      if (!$.isArray(templateData) || !templateData.length) {
                        //handler either not an array or empty array
                        new Notification({ message: 'No templates set up yet.', type: 'warning', displayTime: 5000 });
                      } else {
                        theQuickNoteModule.generateTemplatePopup(templateData, theForm);
                      }
                    });
                }
              }
            },
            {
              location: 'after',
              toolbar: GetDevice().isDevice ? 'top' : 'bottom',
              widget: 'dxButton',
              options: {
                text: 'Save',
                type: 'success',
                icon: 'save',
                onClick: function (e) {
                  let form = $('#addQuickNoteForm' + DomSafeID(theQuickNoteModule.itemId)).dxForm('instance');

                  let newNoteData = form.option('formData');
                  newNoteData.SupportID = theQuickNoteModule.itemId;

                  if (newNoteData.Duration < 1 && itemData.NewNoteOptions.ForceNonZeroDuration && !tqm.multipleIds) {
                    new Notification({ message: 'You cannot enter 0 for minutes.', type: 'error', displayTime: 5000 });
                  } else if (tqm.multipleIds && newNoteData.UnbilledReasonCode === '') {
                    new Notification({ message: 'Notes created for multiple items cannot be billable.', type: 'error', displayTime: 5000 });
                  } else {
                    //check if there is unbilled reason code and set billable to correct value
                    if (newNoteData.UnbilledReasonCode !== '' || itemData.ItemType != itemTypes.itemSupport) {
                      newNoteData.Billable = false;
                    } else {
                      newNoteData.Billable = true;
                    }
                    theQuickNoteModule.saveNote(newNoteData, itemData, d, myTimer, editMode, addNoteDialog, outerContainer);
                  }
                }
              }
            }, {
              location: 'after',
              toolbar: 'bottom',
              widget: 'dxButton',
              options: {
                text: 'Cancel',
                type: 'danger',
                icon: 'remove',
                onClick: (e) => {
                  theQuickNoteModule.Dialog.hide();
                }
              }
            }, {
              location: 'before',
              toolbar: 'bottom',
              template: function () {
                return $("<div class='assist-list'></div>");
              }
            }
          ]
        })
        .dxPopup('instance');
      theQuickNoteModule.Dialog.show().done(() => {
        d.resolve();
      });
    });
    return d.promise();
  }

  saveNote(newNoteData, itemData, deferred, myTimer, editMode, addNoteDialog, outerContainer) {
    let theQuickNoteModule = this;

    if (theQuickNoteModule.multipleIds) {
      new TDFRequest({
        url: TDFRequest.ApiPath + '/quicknotes/1/AddNoteToItemList',
        type: 'POST',
        data: {
          itemIdList: theQuickNoteModule.multipleIds,
          note: newNoteData
        }
      }).MakeRequest()
        .done(response => {
          if (true) {
            let msg = 'Note(s) Created';
            if (editMode === true) {
              msg = 'Edit Successful';
            }

            new Notification({ message: msg, type: 'success', displayTime: 5000 });
            if (myTimer) {
              myTimer.reset();
            }
            deferred.resolve();

            $(addNoteDialog).dxPopup('instance').hide();

            setTimeout(() => {
              theQuickNoteModule.refreshQuickNotes(outerContainer);
            }, 500);
          } else {
            if (response.Response.PreviewOnly) {
              theQuickNoteModule.generatePreviewPoupup(response, outerContainer, newNoteData, editMode, addNoteDialog).done(() => {
                if (myTimer) {
                  myTimer.reset();
                }
                deferred.resolve();
              });
            } else {
              //there was an error
              new Notification({ message: response.Message, type: 'error', displayTime: 5000 });
            }
          }
        });
    } else {
      //Call to api to save note
      new TDFRequest(
        {
          url: TDFRequest.ApiPath + '/quicknotes/1/AddorEditNote',
          type: 'POST',
          data: newNoteData
        },
        false
      ).MakeRequest()
        .done(response => {
          if (response.Valid === true) {
            if (response.Response.PromptUser) {
              theQuickNoteModule.generate2017ChargePopup(response, outerContainer, itemData, false, true)
                .done(() => {
                  if (myTimer) {
                    myTimer.reset();
                  }
                  deferred.resolve();
                });
            } else {
              //note added
              let msg = 'Note Created';
              if (editMode === true) {
                msg = 'Edit Successful';
              }

              new Notification({ message: msg, type: 'success', displayTime: 5000 });
              if (myTimer) {
                myTimer.reset();
              }
              deferred.resolve();

              $(addNoteDialog).dxPopup('instance').hide();

              setTimeout(() => {
                theQuickNoteModule.refreshQuickNotes(outerContainer);
              }, 500);
            }
          } else {
            if (response.Response.PreviewOnly) {
              theQuickNoteModule.generatePreviewPoupup(response, outerContainer, newNoteData, editMode, addNoteDialog).done(() => {
                if (myTimer) {
                  myTimer.reset();
                }
                deferred.resolve();
              });
            } else {
              //there was an error
              new Notification({
                message: response.Message,
                type: 'error',
                displayTime: 5000
              });
            }
          }
        });
    }
  }

  getNotePopupFormItems(itemData, formData, myTimer: Timer, editMode: boolean) {
    let tqm = this;
    let items: any; //Array<DevExpress.ui.dxFormSimpleItem | DevExpress.ui.dxFormGroupItem>;

    let theItems = ['SupportID', 'NoteID', 'ChannelID', 'IsExternal'].map(k => {
      let theOpts;
      if (tqm[k] && tqm[k](itemData.ItemType)) {
        theOpts = tqm[k](itemData.ItemType);
        theOpts.dataField = k;
      } else {
        return {
          dataField: k,
          visible: false,
          editorType: 'dxTextArea',
          editorOptions: {
            value: ''
          },
          colSpan: 6
        };
      }
      return theOpts;
    });

    items = [
      {
        dataField: 'Locked',
        visible: false,
        editorType: 'dxTextArea',
        editorOptions: {
          value: ''
        },
        colSpan: 6
      },
      {
        dataField: 'NoteDate',
        visible: false,
        editorType: 'dxTextArea',
        editorOptions: {
          value: ''
        },
        colSpan: 6
      },
      {
        dataField: 'OriginalChannelName',
        visible: false,
        editorType: 'dxTextArea',
        editorOptions: {
          value: ''
        },
        colSpan: 6
      },
      {
        dataField: 'OverrideAuthorID',
        visible: false,
        editorType: 'dxTextArea',
        editorOptions: {
          value: ''
        },
        colSpan: 6
      },
      {
        dataField: 'ParentNoteID',
        visible: false,
        editorType: 'dxTextArea',
        editorOptions: {
          value: ''
        },
        colSpan: 6
      },
      {
        dataField: 'RevisionLinkID',
        visible: false,
        editorType: 'dxTextArea',
        editorOptions: {
          value: ''
        },
        colSpan: 6
      },
      {
        dataField: 'UnbilledReasonCode',
        visible: false,
        editorType: 'dxTextArea',
        editorOptions: {
          value: ''
        },
        colSpan: 6
      },
      {
        dataField: 'UsersProvidingAssistance',
        visible: false,
        editorType: 'dxSelectBox',
        editorOptions: {
          value: ''
        },
        colSpan: 6
      },
      {
        dataField: 'NoteText',
        colSpan: GetDevice().isDevice ? 6 : 12,
        label: {
          text: '',
          visible: false
        },
        editorOptions: {
          placeholder: '',
          height: 150,
          disabled: editMode
        },
        editorType: 'dxTextArea'
      },
      {
        editorOptions: {
          type: 'normal',
          text: 'Edit Users who Assisted',
          icon: '',
          visible: itemData.NewNoteOptions.AllowUserAssists ? true : false,
          onClick(e) {
            let theForm: DevExpress.ui.dxForm = $('#' + tqm.noteFormID).dxForm(
              'instance'
            );
            theForm.option('formData').UsersProvidingAssistance = [];
            let assistData = '';

            //request to get assist data
            new TDFRequest({
              url: TDFRequest.ApiPath + '/quicknotes/GetUserAssists'
            }).MakeRequest().done(assistData => {
              if (!$.isArray(assistData) || !assistData.length) {
                //handler either not an array or empty array
                new Notification({ message: 'No users available to assist.', type: 'warning', displayTime: 5000 });
              } else {
                tqm.generateUserAssistPopup(assistData, theForm, itemData);
              }
            });
          }
        },
        editorType: 'dxButton',
        colSpan: 6
      },
      {
        dataField: 'ServiceNatureID',
        label: {
          text: 'Service'
        },
        visible: itemData.ItemType === itemTypes.itemSupport,
        editorOptions: {
          placeholder: '',
          dataSource: itemData.NewNoteOptions.ServiceOptions.AvailableServices,
          valueExpr: 'ID',
          displayExpr: 'Description',
          value: editMode === false ? null : formData.ServiceNatureID,
          disabled: editMode
        },
        editorType: 'dxSelectBox',
        colSpan: 6
      },
      {
        itemType: 'empty',
        colSpan: 6,
        visible: itemData.ItemType === itemTypes.itemSupport
      },
      {
        dataField: 'IsPrivate',
        label: {
          text: 'Privacy'
        },
        editorOptions: {
          placeholder: '',
          dataSource: [
            { ID: 1, Description: 'Private' },
            { ID: 0, Description: 'Public' }
          ],
          valueExpr: 'ID',
          displayExpr: 'Description',
          value: editMode === false ? null : formData.IsPrivate,
          disabled: editMode
        },
        editorType: 'dxSelectBox',
        colSpan: 6
      },
      {
        itemType: 'empty',
        colSpan: 6,
        visible: itemData.ItemType === itemTypes.itemSupport
      },
      {
        dataField: 'ChannelID',
        label: {
          text: 'Touch Type'
        },
        visible: itemData.ItemType === itemTypes.itemSupport,
        editorOptions: {
          placeholder: '',
          dataSource: itemData.NewNoteOptions.TouchTypeOptions,
          valueExpr: 'ChannelID',
          displayExpr: 'ChannelName',
          value: editMode === false ? null : formData.ChannelID,
          disabled: editMode
        },
        editorType: 'dxSelectBox',
        colSpan: 6
      },
      {
        itemType: 'empty',
        visible: !GetDevice().isDevice,
        colSpan: 1
      },
      {
        dataField: 'Timers',
        label: {
          text: 'Timer'
        },
        template: (data, itemElement) => {
          itemElement.append(myTimer.getTimerDisplayElement().css('margin-top', '0px'));
        },
        colSpan: GetDevice().isDevice ? 3 : 2
      },
      {
        colSpan: 3,
        template: (data, itemElement) => {
          let ApplyMinutes = () => {
            let theForm: DevExpress.ui.dxForm = $('#' + tqm.noteFormID).dxForm('instance');
            theForm.updateData('Duration', myTimer.getTimeInMin());
          };

          let toggleBtn = $('<div />').dxButton(myTimer.getTimerStartStopBtnOpts()).dxButton('instance');
          // toggleBtn = toggleBtn.dxButton('instance')
          let resetBtn = $('<div />').dxButton(myTimer.getTimerResetBtnOpts()).dxButton('instance');
          let applyBtn = $('<div />').dxButton({
            text: 'Apply',
            onClick: e => {
              myTimer.stop();
              $('#toggleBtn')
                .dxButton('instance')
                .option('icon', 'fa fa-play');
              ApplyMinutes();
            }
          }).dxButton('instance');

          let content = $('<div />').css('margin-left', '-30px').css('margin-right', '-30px')
            .append($('<div />')
              .append(
                toggleBtn
                  .element()
                  .addClass('pull-left')
                  .on('click', e => {
                    ApplyMinutes();
                  })
              )
              .append(resetBtn.element().addClass('pull-left'))
            )
            .append(
              $('<div />').append(applyBtn.element().css('margin-left', '5px'))
            );

          itemElement.append(content);
          $('#toggleBtn').on('click', e => {
            ApplyMinutes();
          });
        }
      },
      {
        dataField: 'Duration',

        label: {
          text: 'Minutes'
        },
        editorOptions: {
          placeholder: '',
          dataType: 'number',
          value: editMode === false || tqm.multipleIds ? 0 : formData.Duration,
          disabled: editMode || tqm.multipleIds,
          invalidValueMessage: 'Value must be a number greater than zero.',
          min: 0
        },
        editorType: 'dxNumberBox',
        colSpan: 6
      },
      {
        itemType: 'empty',
        colSpan: 6,
        visible: itemData.ItemType === itemTypes.itemSupport
      },
      {
        dataField: 'Billable',
        visible: itemData.ItemType === itemTypes.itemSupport,
        label: {
          text: 'Billable'
        },
        editorOptions: {
          placeholder: '',
          dataSource: itemData.NewNoteOptions.billableMenuValues,
          valueExpr: 'ReasonCode',
          displayExpr: 'DisplayValue',
          value:
            editMode === false
              ? itemData.NewNoteOptions.defaultBillable === '1'
                ? $.grep(
                  itemData.NewNoteOptions.billableMenuValues,
                  (v: any, k) => {
                    return v.IsBillable;
                  }
                )[0].ReasonCode
                : ''
              : formData.Billable === 'No'
                ? formData.UnbilledReasonCode
                : '',
          onValueChanged(e) {
            if (e.value) {
              formData['UnbilledReasonCode'] = e.value;
              formData['Billable'] = false;
            } else {
              formData['Billable'] = true;
            }
          },
          disabled: editMode
        },
        editorType: 'dxSelectBox',
        colSpan: 6
      }
    ];

    return items;
  }

  generateAttachmentGridPopup(attachmentData) {
    let theQuickNoteModule = this;
    let attachmentDialog: DevExpress.ui.dxPopup;

    let attachmentGrid: DevExpress.ui.dxDataGrid;

    if (!$('#attachmentDialog').length) {
      theQuickNoteModule.container.append($("<div id='attachmentDialog' />"));
    }

    attachmentDialog = $('#attachmentDialog')
      .dxPopup({
        title: 'Attachments / Files',
        height: 500,
        dragEnabled: true,
        maxWidth: 600,
        fullScreen: GetDevice().isDevice,
        showTitle: true,
        showCloseButton: true,
        toolbarItems: [
          {
            location: 'after',
            toolbar: 'bottom',
            widget: 'dxButton',

            options: {
              text: 'Cancel',
              type: 'danger',
              onClick: function (e) {
                // close the pop-up
                attachmentDialog.hide();
              }
            }

            //TODO: Delete attachment button and functionality
          }
        ],
        contentTemplate: function (contentElement) {
          let fakeReq = new TDFRequest({ url: 'fake' });
          fakeReq.GetToken().done(authToken => {

            let opts: DevExpress.ui.dxDataGridOptions = {
              selection: { mode: 'single' },
              hoverStateEnabled: true,
              onSelectionChanged: function (e) {
                let noteData = e.selectedRowsData[0];
              },
              width: '100%',
              height: 400,
              columns: [
                {
                  dataField: 'FileName',
                  caption: 'File Name',
                  cellTemplate(el, data) {
                    el.append(
                      $('<a />')
                        .text(data.data.FileName)
                        .attr({
                          download: 'download',
                          href:
                            TDFRequest.ApiPath +
                            '/quicknotes/downloadfile/?fileid=' +
                            data.data.FileID +
                            '&access_token=' +
                            authToken
                        })
                    );
                  }
                },
                {
                  dataField: 'Extension',
                  dataType: 'string',
                  width: 60
                },
                {
                  dataField: 'FileSize',
                  dataType: 'string',
                  width: 60
                },
                {
                  dataField: 'Creation',
                  caption: 'Creation',
                  dataType: 'date',
                  width: 80
                }
              ],
              dataSource: attachmentData
            };

            attachmentGrid = $('<div >')
              .dxDataGrid(opts)
              .dxDataGrid('instance');
            contentElement.append(attachmentGrid.element());
          });
        }
      })
      .dxPopup('instance');

    attachmentDialog.show();
  }

  generateCreditLedgerPopup(creditData) {
    let theQuickNoteModule = this;
    let creditLedgerDialog: DevExpress.ui.dxPopup;

    let creditGrid: DevExpress.ui.dxDataGrid;

    if (!$('#creditLedgerDialog').length) {
      theQuickNoteModule.container.append($("<div id='creditLedgerDialog' />"));
    }

    creditLedgerDialog = $('#creditLedgerDialog')
      .dxPopup({
        title: 'Credit Ledger',
        height: 500,
        dragEnabled: true,
        maxWidth: 800,
        fullScreen: GetDevice().isDevice,
        showTitle: true,
        showCloseButton: true,
        toolbarItems: [
          {
            location: 'after',
            toolbar: 'bottom',
            widget: 'dxButton',
            options: {
              text: 'Cancel',
              type: 'danger',
              onClick: function (e) {
                creditLedgerDialog.hide();
              }
            }
          }
        ],
        contentTemplate: function (contentElement) {
          let opts: DevExpress.ui.dxDataGridOptions = {
            selection: { mode: 'single' },
            hoverStateEnabled: true,
            export: {
              enabled: true
            },
            width: '100%',
            height: 400,
            searchPanel: { visible: true },
            filterRow: { visible: true },
            columns: [
              {
                dataField: 'CreditsApplied',
                dataType: 'number',
                caption: 'Applied',
                width: 60,
                format: { precision: 2 }
              },
              {
                dataField: 'CreditsDeleted',
                dataType: 'number',
                caption: 'Removed',
                cssClass: 'red-text',
                width: 60,
                format: { precision: 2 }
              },
              {
                dataField: 'NatureName',
                caption: 'Service'
              },
              {
                dataField: 'CreditsAppliedByName',
                caption: 'Applied By'
              },
              {
                dataField: 'MinutesPerCredit',
                dataType: 'number',
                caption: 'Rate',
                width: 60,
                format: { precision: 2 }
              },
              {
                dataField: 'ChargeDate',
                dataType: 'date',
                caption: 'Charge Date'
              },
              {
                dataField: 'Work By',
                caption: 'Work By'
              }
            ],
            dataSource: creditData.LedgerData
          };
          creditGrid = $('<div >')
            .dxDataGrid(opts)
            .dxDataGrid('instance');
          contentElement.append(creditGrid.element());
        }
      })
      .dxPopup('instance');

    creditLedgerDialog.show();
  }

  generateShareNotePopup(shareData, selectedNote, itemData) {
    let theQuickNoteModule = this;
    let shareDialog: DevExpress.ui.dxPopup;

    let shareInternalGrid: DevExpress.ui.dxDataGrid;
    let shareExternalGrid: DevExpress.ui.dxDataGrid;

    if (!$('#shareDialog').length) {
      theQuickNoteModule.container.append($("<div id='shareDialog' />"));
    }

    shareDialog = $('#shareDialog')
      .dxPopup({
        title: 'Send Note',
        dragEnabled: true,
        maxWidth: 800,
        fullScreen: GetDevice().isDevice,
        showTitle: true,
        showCloseButton: true,
        toolbarItems: [
          {
            location: 'after',
            toolbar: 'bottom',
            widget: 'dxButton',
            options: {
              text: 'Cancel',
              type: 'danger',
              onClick: function (e) {
                shareDialog.hide();
              }
            }
          },
          {
            location: 'after',
            toolbar: 'bottom',
            widget: 'dxButton',
            options: {
              text: 'Send',
              type: 'success',
              onClick: function (e) {
                let noteId = selectedNote.NoteID;
                let recipientListing = [];

                if ($.isArray(shareInternalGrid.getSelectedRowsData()) || shareInternalGrid.getSelectedRowsData().length) {
                  let internaldata = shareInternalGrid.getSelectedRowsData();
                  $.each(internaldata, function (index, value) {
                    recipientListing.push(value.SearchKey);
                  });
                }

                if ($.isArray(shareExternalGrid.getSelectedRowsData()) || shareExternalGrid.getSelectedRowsData().length) {
                  let externaldata = shareExternalGrid.getSelectedRowsData();
                  $.each(externaldata, function (index, value) {
                    recipientListing.push(value['TDF GUID']);
                  });
                }

                new TDFRequest({
                  url: TDFRequest.ApiPath + '/QuickNotes/' + theQuickNoteModule.itemId + '/' + selectedNote.NoteID + '/Send',
                  type: 'POST',
                  data: recipientListing
                }).MakeRequest()
                  .done(data => {
                    if (data.Valid) {
                      new Notification({ message: data.Message, type: 'success', displayTime: 5000 });
                    } else {
                      new Notification({ message: 'There was an error. ' + data.Message, type: 'warning', displayTime: 5000 });
                    }
                  });

                shareDialog.hide();
              }
            }
          }
        ],
        contentTemplate: function (contentElement) {
          let getGridOpts = function (shareData, isInternal, itemType) {
            let opts: DevExpress.ui.dxDataGridOptions = {
              selection: { mode: 'multiple' },
              hoverStateEnabled: true,
              paging: {
                enabled: false
              },
              width: '100%',
              filterRow: { visible: true },
              onRowPrepared: function (e: any) {
                if (e.rowType == 'data') {
                  if (!isInternal) {
                    if (e.data.IsParentContact != null) {
                      e.rowElement.addClass('quicknote-is-parent-contact');
                    }
                  }
                  //show only ppl who have role on support request, also put a checkbox on ui to remove this restriction!!!! next step
                  if (e.data['TDFRoleOnRequest_043A3EE0-1009-4FDE-8F52-CB2B56F9D4D6'] == '') {
                    e.rowElement.addClass('quicknote-is-not-role role-hide');
                  }
                }
              },
              columns: [
                {
                  dataField: isInternal ? 'Name' : 'TDF_Subject',
                  caption: 'Name'
                },
                {
                  dataField: isInternal ? 'Email' : 'TDF_Email',
                  caption: 'Email'
                },
                {
                  dataField: isInternal ? 'Title' : 'TDF_JobTitle',
                  caption: 'Title'
                }
              ],
              dataSource: isInternal
                ? shareData.InternalContacts
                : shareData.ExternalContacts
            };
            return opts;
          };

          //button of show all contacts
          let btnopts: DevExpress.ui.dxButtonOptions = {
            text: '',
            icon: 'group',
            type: 'normal',
            hint: 'Toggle Contact Role Visibility',
            onClick: function (e: any) {
              if ($('.quicknote-is-not-role').hasClass('role-hide')) {
                $('.quicknote-is-not-role').removeClass('role-hide');
              } else {
                $('.quicknote-is-not-role').addClass('role-hide');
              }
            }
          };
          let showAllContacts = $('<div >').dxButton(btnopts).dxButton('instance');

          let delQueBtnopts: DevExpress.ui.dxButtonOptions = {
            text: 'Delivery Queue',
            type: 'normal',
            hint: '',
            onClick: function (e: any) {
              //check delivery queue and display names if any:
              new TDFRequest({
                url: TDFRequest.ApiPath + '/QuickNotes/' + selectedNote.NoteID + '/QueueStatus'
              }).MakeRequest()
                .done(queueList => {
                  theQuickNoteModule.generateDeliveryQueuePoupup(selectedNote, queueList);
                });
            }
          };
          let delQueBtn = $('<div >').dxButton(delQueBtnopts).dxButton('instance');

          let myContent = $("<div id='myScrollView'></div>");
          myContent.append($(showAllContacts.element()).css('float', 'right'));
          myContent.append($(delQueBtn.element()).css('float', 'right'));

          shareInternalGrid = $('<div >').dxDataGrid(getGridOpts(shareData, true, itemData.ItemType)).dxDataGrid('instance');
          shareExternalGrid = $('<div >').dxDataGrid(getGridOpts(shareData, false, itemData.ItemType)).dxDataGrid('instance');
          myContent.append($('<div />').addClass('share-note-header').text('Internal Contacts'));
          myContent.append(shareInternalGrid.element());

          if (!selectedNote.IsPrivate && itemData.ItemType == 9) {
            myContent.append($('<div />').addClass('share-note-header').text('External Contacts'));
            myContent.append(shareExternalGrid.element());
          }
          contentElement.append(myContent);
          let svInstance = $('#myScrollView').dxScrollView({ height: '99%' }).dxScrollView('instance');
        }
      })
      .dxPopup('instance');

    shareDialog.show();
  }

  RenderQuickNoteTab() {
    let theQuickNoteModule = this;
    let itemData = theQuickNoteModule.theData;
    let outerContainer = theQuickNoteModule.container;

    let fruits = [];
    let theGrid: DevExpress.ui.dxDataGrid;

    function convertCredit(x) {
      let n = Number(x.replace(/[^0-9\.]+/g, ''));
      let s = n.toFixed(2);
      let y = s.toString();
      return y + ' credits remaining';
    }
    $(theQuickNoteModule.container).append(
      $('<div />').addClass('row').css('margin-bottom', '10px').append(
        $('<div />').addClass('col-md-12 quick-no-margin')
          .append($('<div />').attr('id', 'quicknotes-toolbar').dxToolbar({
            items: [
              {
                widget: 'dxButton',
                locateInMenu: 'auto',
                showText: 'inMenu',
                options: {
                  hint: 'Refresh Notes',
                  type: 'normal',
                  text: 'Refresh',
                  icon: 'refresh',
                  onClick: function (e) {
                    theQuickNoteModule.refreshQuickNotes(outerContainer);
                  }
                },
                location: 'before'
              },
              {
                widget: 'dxButton',
                locateInMenu: 'auto',
                showText: 'inMenu',
                options: {
                  type: 'normal',
                  text: 'Add Note',
                  icon: 'add',
                  elementAttr: { style: 'background:#A4FF5C;' },
                  hint: 'Add a Note to this item.',
                  onInitialized: function (data) {
                    $(data.element).css({
                      'border-width': ' 0 0 0 1px',
                      'border-radius': '0px',
                      padding: 0
                    });
                  },
                  onClick: function (data) {
                    let newNoteFormData: NewNoteData = new NewQuickNote({
                      SupportID: theQuickNoteModule.itemId
                    });
                    theQuickNoteModule.generateNotePopup(itemData, newNoteFormData, outerContainer, false);
                  }
                },
                location: 'before'
              },
              {
                widget: 'dxButton',
                locateInMenu: 'auto',
                showText: 'inMenu',
                options: {
                  type: 'normal',
                  hint: 'Edit Selected Note',
                  text: 'Edit',
                  icon: 'edit',
                  disabled: true,
                  onClick(e) {
                    let data = theGrid.getSelectedRowsData();

                    let selectedNote = data[0];
                    let noteFormData: NoteData = new QuickNote({
                      AuthorID: selectedNote.AuthorID,
                      SupportID: theQuickNoteModule.itemId,
                      NoteID: selectedNote.NoteID,
                      Billable: selectedNote.Billable,
                      Duration: selectedNote.Duration,
                      ChannelID: selectedNote.ChannelID,
                      IsPrivate: selectedNote.IsPrivate,
                      Locked: selectedNote.Locked,
                      NoteDate: selectedNote.NoteDate,
                      NoteText: selectedNote.NoteText,
                      OriginalChannelName: selectedNote.OriginalChannelName,
                      ParentNoteID: selectedNote.ParentNoteID,
                      ServiceNatureID: selectedNote.ServiceNatureID,
                      UnbilledReasonCode: selectedNote.UnbilledReasonCode
                    });

                    theQuickNoteModule.generateNotePopup(theQuickNoteModule.theData, noteFormData, outerContainer, true);
                  }
                },
                location: 'before',
                elementAttr: { id: 'editNoteBtn' }
              },
              {
                widget: 'dxButton',
                locateInMenu: 'auto',
                showText: 'inMenu',
                visible: GetDevice().isDevice ? false : true,
                options: {
                  type: 'danger',
                  hint: 'Delete Selected Note',
                  text: 'Delete Note',
                  icon: 'clear',
                  disabled: true, //itemData.NewNoteOptions.AllowNoteDeletion === false ? true : false,
                  onClick(e) {
                    let data = theGrid.getSelectedRowsData();
                    let selectedNote = data[0];

                    let info = {
                      itemId: theQuickNoteModule.itemId,
                      noteId: selectedNote.NoteID
                    };

                    new TDFRequest({
                      url: TDFRequest.ApiPath + '/quicknotes/RemoveQuickNote',
                      type: 'POST',
                      data: info
                    }).MakeRequest()
                      .done(deleteData => {
                        theQuickNoteModule.refreshQuickNotes(outerContainer);
                        new Notification({ message: 'Note Deleted', type: 'success', displayTime: 5000 });
                      });
                  }
                },
                location: 'before'
              },
              {
                widget: 'dxButton',
                locateInMenu: 'auto',
                showText: 'inMenu',
                visible: true,
                options: {
                  type: 'normal',
                  hint: 'View All Files Attached to Notes',
                  text: 'Files',
                  icon: 'box',
                  onClick(e) {
                    new TDFRequest({
                      url: TDFRequest.ApiPath + '/quicknotes/GetAttachmentList?itemID=' + theQuickNoteModule.itemId
                    }).MakeRequest()
                      .done(attachmentData => {
                        if (!$.isArray(attachmentData) || !attachmentData.length) {
                          //handler either not an array or empty array
                          new Notification({ message: 'No attachments associated with this support request', type: 'success', displayTime: 5000 });
                        } else {
                          theQuickNoteModule.generateAttachmentGridPopup(attachmentData);
                        }
                      });
                  }
                },
                location: 'before'
              },
              {
                widget: 'dxButton',
                locateInMenu: 'auto',
                showText: 'inMenu',
                visible: true,
                options: {
                  type: 'normal',
                  hint: 'Attach File to current note',
                  text: 'Attach File',
                  icon: 'fa fa-paperclip',
                  disabled: true,
                  onClick(e) {
                    let data = theGrid.getSelectedRowsData();
                    let selectedNote = data[0];
                    let attachments = new AttachmentViewer(theQuickNoteModule.itemId, itemData.ItemType, selectedNote.NoteID);
                    attachments.DisplayFiles();
                  }
                },
                elementAttr: { id: 'attach-file-to-note-btn' },
                location: 'before'
              },
              {
                widget: 'dxButton',
                locateInMenu: 'auto',
                showText: 'inMenu',
                visible: itemData.ItemType == itemTypes.itemSupport ? true : false,
                options: {
                  type: 'normal',
                  text: 'Charge',
                  hint: 'Show Pending Charges',
                  icon: 'fa fa-usd',
                  onClick(e) {
                    theQuickNoteModule.generate2017ChargePopup('', outerContainer, itemData, false, false);
                  }
                },
                location: 'before'
              },
              {
                widget: 'dxButton',
                locateInMenu: 'auto',
                showText: 'inMenu',
                visible: !GetDevice().isDevice,
                options: {
                  text: 'Print',
                  hint: 'Print Notes',
                  icon: 'fa fa-print',
                  onClick(e) {
                    $('#quicknote-printable-area .collapse').collapse('show');
                    $('.remaining-content').css('display', 'inline');
                    $("p[id^='morelink']").html('');
                    theQuickNoteModule.printDiv('quicknote-printable-area', outerContainer);
                    theQuickNoteModule.refreshQuickNotes(outerContainer);
                  }
                },
                location: 'before'
              },
              {
                widget: 'dxButton',
                locateInMenu: 'auto',
                showText: 'inMenu',
                visible: true,
                options: {
                  //type: 'back',
                  text: 'Privacy',
                  hint: 'Set Privacy',
                  icon: 'fa fa-lock',
                  disabled: true,
                  onClick(e) {
                    let data = theGrid.getSelectedRowsData();
                    let selectedNote = data[0];
                    let privacy = false;

                    privacy = !selectedNote.IsPrivate;

                    new TDFRequest({
                      url: TDFRequest.ApiPath + '/QuickNotes/' + theQuickNoteModule.itemId + '/' + selectedNote.NoteID + '/SetNotePrivacy',
                      type: 'POST',
                      data: privacy.toString()
                    }).MakeRequest()
                      .done(data => {
                        new Notification({ message: data.Message, type: 'success', displayTime: 5000 });
                      });
                    setTimeout(() => {
                      theQuickNoteModule.refreshQuickNotes(outerContainer);
                    }, 500);
                  }
                },
                elementAttr: { id: 'privacyBtn' },
                location: 'before'
              },
              {
                widget: 'dxButton',
                locateInMenu: 'auto',
                showText: 'inMenu',
                visible: true,
                options: {
                  text: 'Send',
                  hint: 'Send Note',
                  icon: 'fa fa-share-alt',
                  disabled: true,
                  onClick(e) {
                    let data = theGrid.getSelectedRowsData();
                    let selectedNote = data[0];

                    new TDFRequest({
                      url: TDFRequest.ApiPath + '/QuickNotes/' + theQuickNoteModule.itemId + '/' + selectedNote.NoteID + '/getSendInfo'
                    }).MakeRequest().done(sendInfo => {
                      let shareData = sendInfo.SendData;
                      theQuickNoteModule.generateShareNotePopup(
                        shareData,
                        selectedNote,
                        itemData
                      );
                    });
                  }
                },
                elementAttr: { id: 'share-note-btn' },
                location: 'before'
              },
              {
                widget: 'dxButton',
                locateInMenu: 'auto',
                showText: 'inMenu',
                visible: GetDevice().isDevice ? false : true,
                options: {
                  text: 'Settings',
                  hint: 'Note Settings',
                  icon: 'fa fa-cog',
                  onClick(e) {
                    theQuickNoteModule.generateNoteSettingsPopup(outerContainer);
                  }
                },
                elementAttr: { id: 'settings-note-btn' },
                location: 'after'
              },
              {
                widget: 'dxButton',
                locateInMenu: 'auto',
                type: 'normal',
                showText: 'always',
                visible: itemData.ItemType == itemTypes.itemSupport ? true : false,
                options: {
                  type: 'default',
                  hint: 'Show Credit Ledger',
                  text: GetDevice().isDevice ? 'C' : itemData.NewNoteOptions.RemainingCreditsBalance.Valid && itemData.NewNoteOptions.UserCreditSettings.ShowCreditBalance ? convertCredit(itemData.NewNoteOptions.RemainingCreditsBalance.Message) : 'Credit Info',
                  onClick(e) {
                    new TDFRequest({
                      url: TDFRequest.ApiPath + '/quicknotes/GetCreditLedger?itemID=' + theQuickNoteModule.itemId
                    }).MakeRequest()
                      .done(creditData => {
                        if (!$.isArray(creditData.LedgerData) || !creditData.LedgerData.length) {
                          //handler either not an array or empty array
                          new Notification({ message: 'No credits associated with this support request', type: 'success', displayTime: 5000 });
                        } else {
                          theQuickNoteModule.generateCreditLedgerPopup(creditData);
                        }
                      });
                  }
                },
                location: 'after'
              },
              {
                widget: 'dxButton',
                locateInMenu: 'auto',
                showText: 'inMenu',
                type: 'normal',
                options: {
                  hint: 'Collapse All',
                  type: 'default',
                  text: 'Collapse All',
                  icon: 'chevronup',
                  onClick(e) {
                    $('#quicknote-printable-area .collapse').collapse('hide');
                  }
                },
                location: 'after'
              },
              {
                widget: 'dxButton',
                locateInMenu: 'auto',
                showText: 'inMenu',
                type: 'normal',
                options: {
                  hint: 'Expand All',
                  type: 'default',
                  text: 'Expand All',
                  icon: 'chevrondown',
                  onClick(e) {
                    $('#quicknote-printable-area .collapse').collapse('show');
                  }
                },
                location: 'after'
              }
            ]
          })
          )
      )
    );
    let moretext = '*****SELECT NOTE TO SEE MORE.*****';
    let lesstext = '*****CLICK NOTE TO SEE LESS.*****';
    let opts: DevExpress.ui.dxDataGridOptions = {
      height: GetDevice().isDevice ? '450px' : '600px',
      showColumnHeaders: false,
      showColumnLines: false,
      showRowLines: false,
      showBorders: false,
      paging: { enabled: false },
      searchPanel: {
        visible: true
      },
      selection: { mode: 'single' },
      hoverStateEnabled: true,
      onRowClick: function (e) {
        let noteData = e.data;
        if ($('#morelink' + DomSafeID(noteData.NoteID)).hasClass('less')) {
          $('#morelink' + DomSafeID(noteData.NoteID)).removeClass('less');
          $('#morelink' + DomSafeID(noteData.NoteID)).html(moretext);
        } else {
          $('#morelink' + DomSafeID(noteData.NoteID)).addClass('less');
          $('#morelink' + DomSafeID(noteData.NoteID)).html(lesstext);
        }
        $('#morelink' + DomSafeID(noteData.NoteID)).parent().prev().toggle();
        $('#morelink' + DomSafeID(noteData.NoteID)).prev().toggle();
      },
      onSelectionChanged: function (e) {
        let noteData = e.selectedRowsData[0];

        new TDFRequest({
          url: TDFRequest.ApiPath + '/quicknotes/GetEditRightsForNote?noteId=' + noteData.NoteID + '&authorId=' + noteData.AuthorID
        }).MakeRequest()
          .done(rightData => {
            let toolbar = $('#quicknotes-toolbar');
            let editButton = toolbar.find(".dx-button[aria-label='Edit']").dxButton('instance');
            let privacyBtn = toolbar.find(".dx-button[aria-label='Privacy']").dxButton('instance');

            let attachFileBtn = toolbar.find(".dx-button[aria-label='Attach File']").dxButton('instance');
            attachFileBtn.option('disabled', false);

            let emailButton = toolbar.find(".dx-button[aria-label='Send']").dxButton('instance');
            emailButton.option('disabled', false);

            let deleteBtn = toolbar.find(".dx-button[aria-label='Delete Note']").dxButton('instance');
            deleteBtn.option('disabled', false);

            if (rightData.AllowEdit || rightData.AllowEditNoteText) {
              editButton.option('disabled', false);

              let userId = CurrentUser.ID;

              if (itemData.NewNoteOptions.UserCreditSettings.CanAlterNotePrivacy) {
                privacyBtn.option('disabled', false);
              } else {
                if (noteData.AuthorID == userId) {
                  privacyBtn.option('disabled', false);
                }
              }
            } else {
              editButton.option('disabled', true);
              privacyBtn.option('disabled', true);
            }

            if (!rightData.AllowAddFiles) {
              attachFileBtn.option('disabled', true);
            }

            if (!rightData.AllowDeleteNote) {
              deleteBtn.option('disabled', true);
            }
          });
      },
      width: '100%',
      columns: [
        {
          dataField: 'NoteID',
          cellTemplate(el, data) {
            let iconPublic = 'icon-tdf';
            let iconPrivate = 'fa fa-lock';

            let iconPublicPref = Preferences.GetPreference('publicNoteIcon', 'QuickNotes');
            let iconPrivatePref = Preferences.GetPreference('privateNoteIcon', 'QuickNotes');

            if (iconPublicPref == 'Support') {
              iconPublic = 'icon-utilities';
            }
            if (iconPrivatePref == 'Private-Investigator') {
              iconPrivate = 'icon-tdfprivatenote';
            }

            let val = data.data;
            let iconClass = val.IsExternal == 0 ? val.IsPrivate && itemData.ItemType == itemTypes.itemSupport ? iconPrivate : iconPublic : 'icon-user';
            let headers = itemData.HeaderFields;
            let originalDate = val.NoteDate;
            let headerClass = '';
            let visibleHeaders = '';
            let attachmentHtml = '';
            let attachmentList = val.Attachments;
            let attachmentIdList = val.AttachmentIDs;
            let showChar = GetDevice().isDevice ? 300 : 1000;
            let ellipsestext = '...';
            let noteText = val.NoteText;
            let isPrivate = val.IsPrivate && itemData.ItemType == itemTypes.itemSupport ? 'private-note' : 'public-note';
            let chargeButtonArea = $('<div />');

            // Shorten Text with "More"
            if (noteText.length > showChar) {
              let show_content = noteText.substr(0, showChar);
              let hide_content = noteText.substr(showChar, noteText.length - showChar);
              let html = show_content + '<span class="moreelipses">' + ellipsestext + '</span><span class="remaining-content"><span>' +
                hide_content + '</span>&nbsp;&nbsp;<p id="morelink' + DomSafeID(val.NoteID) + '">' + moretext + '</p></span>';
              noteText = html;
            }

            //Organize send history
            let sendHistoryHtml = val.SendHistory != '' && val.SendHistory != null ? 'Send History: ' + val.SendHistory : '';

            // Set icon if a charge
            if (val.ChargeUnits > 0 || val.ChargeUnits < 0) {
              iconClass = 'fa fa-usd';
            }

            // Organize Date to be shown without time
            let datestring = new Date(val.NoteDate);
            val.NoteDate = datestring.toDateString();
            val.Billable = val.Billable == 1 ? 'Yes' : 'No';

            //Organize Headers
            for (let i = 0; i < headers.length; i++) {
              $.each(val, function (index, value) {
                if (headers[i] === index) {
                  if (value !== '' && value !== null) {
                    if (index === 'AuthorName') {
                      index = 'Name';
                    }
                    if (index === 'NoteDate') {
                      index = 'Date';
                    }
                    if (i != 0 && i != 1) {
                      headerClass = 'hidden-sm hidden-xs';
                    }
                    visibleHeaders = visibleHeaders + "<span class='" + headerClass + "'><span class='hidden-sm hidden-xs'>" + index.toString() + ': </span>' + value + ' / </span>';
                  }
                }
              });
            }

            // Set up Expected Charge Button
            if (val.ExpectedCharge > 0) {
              let opts: DevExpress.ui.dxButtonOptions = {
                text: 'Charge: ' + val.ExpectedCharge,
                type: 'normal',
                disabled: true,
                onClick: function (e: any) { }
              };

              let chargeButton = $('<div >').dxButton(opts).dxButton('instance');
              chargeButtonArea.append(chargeButton.element());
            }


            //set date back to include time for sorting purposes
            val.NoteDate = originalDate;

            // Put it all together my son
            function CreateNoteItem() {
              let noteItem = $('<div />').addClass('item-quicknote-contain ' + isPrivate).attr('id', 'quicknote' + DomSafeID(val.NoteID))
                .append($('<div />').addClass('item-quicknote-header').html(visibleHeaders)
                  .append($('<a />').addClass('pull-right btn btn-xs btn-primary').attr('href', '#notebody' + DomSafeID(val.NoteID)).attr('data-toggle', 'collapse')
                    .append($('<span />').addClass('dx-icon dx-icon-chevronup note-collapse').on('click', function () {
                      if ($(this).hasClass('dx-icon-chevronup')) {
                        $(this).removeClass('dx-icon-chevronup');
                        $(this).addClass('dx-icon-chevronup');
                      } else {
                        $(this).removeClass('dx-icon-chevrondown');
                        $(this).addClass('dx-icon-chevrondown');
                      }
                    })
                    )
                  )
                )
                .append($('<div />').attr('id', 'notebody' + DomSafeID(val.NoteID)).addClass('collapse show')
                  .append($('<table />').css('table-layout', 'fixed').addClass('quick-note-wrap')
                    .append($('<tr />').addClass('item-quicknote-body')
                      .append($('<td />').addClass('item-quick-icon-box').css('word-wrap', 'break-word')
                        .append($('<span />').addClass(iconClass))
                      ).append($('<td />').addClass('item-quicknote-textarea').html(noteText))
                    )
                  ).append($('<div />').addClass('item-quicknote-footer')
                    .append($('<div />').addClass('row')
                      .append($('<div />').addClass('col-md-6 note-send-history').html('<small>' + sendHistoryHtml + '</small>')
                      ).append($('<div />').addClass('col-md-6 note-attach-section').html(attachmentHtml)
                      )
                    )
                  )
                );
              el.append(noteItem);
            }

            //Format attachments
            if (attachmentList !== null) {
              let fakeReq = new TDFRequest({ url: 'fake' });
              fakeReq.GetToken().done(authToken => {
                let attachmentListArray = attachmentList.split('|');
                let attachmentIdListArray = attachmentIdList.split('|');
                for (let x = 0; x < attachmentListArray.length; x++) {
                  attachmentHtml =
                    attachmentHtml + "<a download='download' href='" + TDFRequest.ApiPath + '/quicknotes/downloadfile/?fileid=' +
                    attachmentIdListArray[x].trim() + '&access_token=' + authToken + "'>" + attachmentListArray[x] + '</a> | ';
                }

                CreateNoteItem();
              });
            } else {
              CreateNoteItem();
            }
          }
        },
        {
          dataField: 'NoteText',
          visible: false
        },
        {
          dataField: 'AuthorName',
          visible: false
        }
      ],
      dataSource: itemData.TheNoteList
    };

    theGrid = $("<div id='quicknote-printable-area'>").dxDataGrid(opts).dxDataGrid('instance');
    theQuickNoteModule.container.append(theGrid.element());
  }
}

class UsersProvidingAssistance {
  Billable: boolean = true;
  Duration: number = 0;
  ReasonCode: string = '';
  UserID: string = '';
}

class NewQuickNote implements NewNoteData {
  NoteID: string = '';
  NoteText: string = '';
  SupportID: string = '';
  Billable: number = 0;
  Duration: number = 0;
  IsPrivate: number = 0;
  ChannelID: string = '';
  ServiceNatureID: string = '';
  UnbilledReasonCode: string = '';
  UsersProvidingAssistance: any = [];

  constructor(args?: any) {
    let theNote = this;
    $.each(args, (k, v) => {
      if (theNote.hasOwnProperty(k)) {
        theNote[k] = v;
      }
    });
  }
}
class QuickNote implements NoteData {
  ParentNoteID: string = '';
  AuthorID: string = '';
  AuthorName: string = '';
  Locked: boolean = false;
  IsExternal: boolean = false;
  NoteID: string = '';
  NoteText: string = '';
  SupportID: string = '';
  Billable: string = '';
  Duration: number = 0;
  NoteDate: string = '';
  IsPrivate: boolean = true;
  ChannelID: string = '';
  OriginalChannelName: string = '';
  ServiceNatureID: string = '';
  UnbilledReasonCode: string = '';
  RevisionLinkID: string = '';
  OverrideAuthorID: string = '';
  UsersProvidingAssistance: any = [];

  constructor(args?: any) {
    let theNote = this;
    $.each(args, (k, v) => {
      if (theNote.hasOwnProperty(k)) {
        theNote[k] = v;
      }
    });
  }
}