import { AddHandler2, RaiseEvent2 } from "../../../infrastructure/events/ui_events";
import { IGridView, IToolbarItemOverrideOptions } from "../../../interfaces/grids/interfaces";
import { eventNameSpace, EventTypes } from "../../../enums/webevents/enums";
import { DomSafeID, GetDevice } from "../../../util/allutils";
import dxDataSource from "devextreme/data/data_source";
import dxCustomStore from "devextreme/data/custom_store";
import "devextreme/ui/toolbar";
import "devextreme/ui/accordion";
import { CenterType, GridContainerTypes, itemTypes, enumscope } from "../../../enums/enums";
import { Preferences } from "../../../infrastructure/user/preferences";
import { BSIGrids, ISummary } from "enums/bi/enums";

export class AwesomeToolbar {
  private _container: JQuery;
  private ToolbarItemArgs: any[];
  private EventLimiterName: string = "";

  private _ToolbarItems: IToolbarItemOverrideOptions[];
  get ToolbarItems(): IToolbarItemOverrideOptions[] {
    if (this._ToolbarItems) return this._ToolbarItems;
    return null;
  }
  set ToolbarItems(val: IToolbarItemOverrideOptions[]) {
    if (val && $.isArray(val)) this._ToolbarItems = val;
  }

  protected _Items() {
    return this.ToolbarItems;
  }

  protected get _toolbarOptions(): DevExpress.ui.dxToolbarOptions {
    let toolbar = this;
    return {
      dataSource: new dxDataSource({
        store: new dxCustomStore({
          load() {
            let d = $.Deferred();
            toolbar.ToolbarItems = toolbar.ToolbarItems.sort((a, b) => {
              return a.index >= b.index ? 1 : 0;
            });
            let theseItems = [];

            toolbar.ToolbarItems.forEach(item => {
              import(`./toolbaritems/${item.name.toLowerCase()}`).then(mod => {
                let tmod = mod[item.name];
                theseItems.push(tmod(toolbar.ToolbarItemArgs, item));
                if (theseItems.length === toolbar.ToolbarItems.length) {
                  d.resolve(theseItems);
                }
              });
            });
            return d.promise();
          }
        })
      }),
      onInitialized(e) {
        let timer;

        timer = setInterval(() => {
          if (e.element.outerHeight()) {
            clearInterval(timer);
            RaiseEvent2(
              EventTypes.CenterEventTypes.size,
              toolbar.EventLimiterName,
              eventNameSpace.notify,
              e
            );
          }
        }, 50);
      }
      //height:45
      //itemTemplate(d, i, e) {
      //    var t = d;
      //}
    };
  }
  get instance(): DevExpress.ui.dxToolbar {
    return $(this._container.children()[0]).dxToolbar("instance");
  }

  private _GridContainerType: GridContainerTypes;
  get GridContainerType(): GridContainerTypes {
    if (this._GridContainerType) return this._GridContainerType;
    return null;
  }
  set GridContainerType(val: GridContainerTypes) {
    if (val) this._GridContainerType = val;
  }

  private _ItemType: itemTypes;
  get ItemType(): itemTypes {
    if (this._ItemType) return this._ItemType;
    return null;
  }
  set ItemType(val: itemTypes) {
    if (val) this._ItemType = val;
  }

  private _Scope: enumscope;
  get Scope(): enumscope {
    if (typeof this._Scope !== "undefined") return this._Scope;
    return -1;
  }
  set Scope(val: enumscope) {
    this._Scope = val;
  }

  constructor(
    container: JQuery,
    itemType?: itemTypes,
    gridContainerType?: GridContainerTypes,
    eventLimiterName?: string,
    items?: IToolbarItemOverrideOptions[],
    ...itemArgs
  ) {
    if (typeof itemType !== "undefined") this.ItemType = itemType;
    if (typeof gridContainerType !== "undefined")
      this.GridContainerType = gridContainerType;
    if (typeof eventLimiterName !== "undefined")
      this.EventLimiterName = eventLimiterName;
    if (itemArgs) this.ToolbarItemArgs = itemArgs;
    this.ToolbarItems = this.SortToolbarItemArray(items);
    this._container = container;
    this.AddListeners();
  }

  AddListeners() {
    let toolbar = this;
    AddHandler2(EventTypes.CenterEventTypes.itemtype, toolbar.EventLimiterName, eventNameSpace.modify, toolbar._container, toolbar.OnTypeChanged.bind(toolbar));
    AddHandler2(EventTypes.CenterEventTypes.gridscope, toolbar.EventLimiterName, eventNameSpace.modify, toolbar._container, toolbar.OnScopeChange.bind(toolbar));
    AddHandler2(EventTypes.CenterEventTypes.gridrowselect, toolbar.EventLimiterName, eventNameSpace.complete, toolbar._container, toolbar.OnGridSelectionChanged.bind(toolbar));
    AddHandler2(EventTypes.CenterEventTypes.found, toolbar.EventLimiterName, eventNameSpace.notify, toolbar._container, toolbar.OnGridLoaded.bind(toolbar));
    AddHandler2(EventTypes.CenterEventTypes.updateButtons, toolbar.EventLimiterName, eventNameSpace.request, toolbar._container, toolbar.UpdateBIButtons.bind(toolbar));
    AddHandler2(EventTypes.BIEventTypes.showHideRollUpButtons, toolbar.EventLimiterName, eventNameSpace.request, toolbar._container, toolbar.ShowHideRollUpButton.bind(toolbar));
  }

  ShowHideRollUpButton(e: JQueryEventObject, data) {
    let toolbar = this;
    // Note:  This was thrown in at the last minute.  Please excuse the hackiness.

    if (data.ShowRollUpButton) {
      toolbar.ShowOrHideToolbarItem("RollUpButton", true);
    }
    else {
      toolbar.ShowOrHideToolbarItem("RollUpButton", false);
    }

    if (data.ShowRollDownButton) {
      toolbar.ShowOrHideToolbarItem("RollDownButton", true);
    }
    else {
      toolbar.ShowOrHideToolbarItem("RollDownButton", false);
    }
  }

  UpdateBIButtons(e: JQueryEventObject, data) {
    let toolbar = this;
    // Note:  This was thrown in at the last minute.  Please excuse the hackiness.
    let itemType: BSIGrids = data.ItemType;
    let centerType: CenterType = data.CenterType;

    if (
      (itemType === null) || // This is for Welcome tabs
      (itemType === BSIGrids.SalesSummary) ||
      (itemType === BSIGrids.GeneralSummary) ||
      (itemType === BSIGrids.VendorSalesSummary) ||
      (itemType === BSIGrids.VendorGeneralSummary) ||
      (itemType === BSIGrids.JobSalesSummary) ||
      (itemType === BSIGrids.JobGeneralSummary)
    ) {
      toolbar.ShowOrHideToolbarItem("BIViewLookup", false);
    } else {
      toolbar.ShowOrHideToolbarItem("BIViewLookup", true);
    }

    if (
      (centerType === CenterType.AccountBi) ||
      (centerType === CenterType.OppBi) ||
      (centerType === CenterType.ContactBi) ||
      (centerType === CenterType.VendorBi)
    ) {
      toolbar.ShowOrHideToolbarItem("BIDataCenterSummarySelector", false);
    }
    else {
      toolbar.ShowOrHideToolbarItem("BIDataCenterSummarySelector", true);
    }
  }

  ShowOrHideToolbarItem(name: string, visible: boolean) {
    let toolbar = this;
    toolbar.ToolbarItems.forEach((v, k) => {
      if (v.name == name) {
        toolbar.instance.option(`items[${k}].visible`, visible);
      }
    });
  }

  OnScopeChange(e: JQueryEventObject, data) {
    let toolbar = this;
    if (data === toolbar.Scope) return;
    toolbar.Scope = data;
    //$(`#${toolbar.scopeID}`).dxButton("instance").option("icon", toolbar.Scope ===  enumscope.global ? "globe" : "home");
  }

  OnTypeChanged(e: JQueryEventObject, data) {
    let toolbar = this;
    toolbar.ItemType = data.ItemType;
  }

  ActionItemCache: any[];

  OnGridSelectionChanged(e: JQueryEventObject, data) {
    let toolbar = this;
    if (data && data.selectedRowsData) {
      toolbar.ActionItemCache = data.selectedRowsData;
    }

    toolbar.HandleButtonVisibilityChanges();
  }

  init() {
    this._container.append(
      $("<div style='margin:5px 0;' />").dxToolbar(this._toolbarOptions)
    );
  }

  protected HandleButtonVisibilityChanges() {
    let toolbar = this;
    // 1.  Deal with Open/Actions/Views Items
    if (toolbar.ActionItemCache && toolbar.ActionItemCache.length > 0) {
      toolbar.setActionItemVisiblility(true, toolbar.ActionItemCache.length);
    } else {
      toolbar.setActionItemVisiblility(false);
    }
  }

  OnGridLoaded(e: JQueryEventObject, data: IGridView) {
    let toolbar = this;
    let gridel = $(`#tdf-datagrid${DomSafeID(data.GUID)}`);
    if (gridel.length > 0) {

      let data: { items?: any[] } = {};

      RaiseEvent2(
        EventTypes.CenterEventTypes.gridrowselect,
        toolbar.EventLimiterName,
        eventNameSpace.request,
        data
      )

      toolbar.ActionItemCache = data.items;
      toolbar.HandleButtonVisibilityChanges();
    } else {
      toolbar.ActionItemCache = undefined;
      toolbar.HandleButtonVisibilityChanges();
      //toolbar.setActionItemVisiblility(false);
    }
  }

  /**
   * Note .. this is absolutely dependent on the buttons being in the following order 1.menu 2.Open , 3.Action , 4.Views
   * @param visible make visible or hide
   * @param length the length of selected items
   */
  setActionItemVisiblility(visible, length = 1) {
    let toolbar = this;
    let itemslength = toolbar.instance.option("items").length;
    toolbar.ToolbarItems.forEach((v, k) => {
      if (
        $.inArray(v.name, ["OpenButton", "ActionButton", "ViewButton"]) >= 0
      ) {
        switch (v.name) {
          case "OpenButton":
            toolbar.instance.option(`items[${k}].visible`, visible);
            break;
          case "ActionButton":
            toolbar.instance.option(`items[${k}].visible`, visible);
            break;
          case "ViewButton":
            if ((Preferences.GetCompanyPreference("ShowViewsBtn", "TDFMobile", "1") === "1")) {
              toolbar.instance.option(`items[${k}].visible`, visible && length === 1 ? visible : false);
            }
            break;
        }
      }
    });
  }

  SortToolbarItemArray(items: IToolbarItemOverrideOptions[]): IToolbarItemOverrideOptions[] {
    items.sort(function (a, b) {
      if ((a.location === "before") && b.location === "after") {
        return -1;
      }
      else if ((a.location === "after") && (b.location === "before")) {
        return 1;
      }
      else if (a.location === b.location) {
        if (a.index == b.index) {
          return 0;
        }
        else if (a.index < b.index) {
          return -1;
        }
        // else.
        return 1;
      }
    });
    return items;
  }
}
