import { TDFRequest } from '../../services/request';
import { itemTypes, GridContainerTypes, enumscope } from '../../enums/enums';

export class GridViewService {
  protected get _routePrefix(): string {
    return `Grid/Views`;
  }
  protected get _route(): string {
    return `${TDFRequest.ApiPath}/${this._routePrefix}`;
  }
  public ViewsForMenuRequest(
    itemType: itemTypes,
    containerType: GridContainerTypes,
    scope: enumscope
  ): TDFRequest {
    return new TDFRequest({
      url: `${
        this._route
      }/${itemType}/${containerType}/for-menu?scope=${scope}`,
      type: 'GET'
    });
  }
  public LayoutForViewRequest(options: { viewid: string }): TDFRequest {
    return new TDFRequest({
      url: `${this._route}/${options.viewid}/layout`,
      type: 'GET'
    });
  }
}
