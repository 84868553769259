import { RaiseEvent2 } from "../../../../infrastructure/events/ui_events";
import { eventNameSpace, EventTypes } from "../../../../enums/webevents/enums";
import { ToolbarTemplateFactory } from "./toolbartemplatefactory";
import { Debounce } from "../../../../util/allutils";

export function MenuButton(args) {
  let centerName: string = "";
  if (args && args.length) {
    args.map((v, k) => {
      if ($.isPlainObject(v)) {
        Object.keys(v).forEach((val, key) => {
          if (val.toLowerCase() === "uniquecentername") {
            centerName = v[val];
          }
        });
      }
    });
  }
  //TODO: Add Config paramter and respect it
  return ToolbarTemplateFactory(
    "before",
    "never",
    false,
    <DevExpress.ui.dxButtonOptions>{
      icon: "arrowright",
      text: "Show Menu",
      elementAttr: { class: 'center-menu-button' },
      onClick: Debounce(
        () => {
          RaiseEvent2(
            EventTypes.CenterEventTypes.opendatacentermenu,
            centerName,
            eventNameSpace.request
          );
        },
        250,
        true
      ),
      onInitialized: e => {

        e.element.mouseenter(
          Debounce(
            () => {
              RaiseEvent2(
                EventTypes.CenterEventTypes.opendatacentermenu,
                centerName,
                eventNameSpace.request
              );
            },
            100,
            false
          )
        );
      }
    },
    "dxButton",
    null,
    true
  );
}
