import 'devextreme/ui/popup';
import 'devextreme/ui/form';
import { TDFRequest } from '../../services/request';
import { Notification } from '../dialogs/notification';

export function GetForgotPasswordForm() {
    if ($('#tdfforgotpassword').length === 0) {
        $('<div id="tdfforgotpassword" />').appendTo('body');
    }

    const dlg = $('#tdfforgotpassword').dxPopup({
        contentTemplate: (el) => {
            el.append($('<div id="forgotpasswordform" />'));
        },
        title: 'Reset Password',
        closeOnOutsideClick: false,
        showCloseButton: true,
        maxHeight: '50%',
        minHeight: '30%',
        maxWidth: '50%',
        minWidth: '30%',
        position: 'center',
    }).dxPopup('instance');

    dlg.show().done(() => {
        const form: DevExpress.ui.dxForm = $('#forgotpasswordform').dxForm({
            items: [
                {
                    template: (data, el) => {
                        el.append($('<p>').text('After clicking submit you will receive an email containing a temporary password.'));
                    }
                }, {
                    dataField: 'UserName',
                    label: { text: '', visible: false },
                    editorOptions: {
                        validationMessageMode: 'always',
                        placeholder: 'User Name',
                    }
                }, {
                    dataField: 'EmailAddress',
                    label: { text: '', visible: false },
                    editorOptions: {
                        validationMessageMode: 'always',
                        placeholder: 'Email Address',
                    }
                }, {
                    itemType: 'button',
                    buttonOptions: {
                        text: 'Reset Password',
                        onClick: () => {
                            let formData = form.option('formData');

                            let request = new TDFRequest({
                                url: '/core/user/ForgotPassword',
                                type: 'POST',
                                data: { username: formData.UserName, email: formData.EmailAddress }
                            });

                            request.MakeRequest().done((response) => {
                                new Notification({
                                    message: response,
                                    type: 'success'
                                });

                                dlg.hide();
                            });
                        }
                    }
                }
            ]
        }).dxForm('instance');
    });
}