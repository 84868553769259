
import { CompanyPreferenceDefaults, UserPreferenceDefaults } from "./preferencedefaults";

      // Note:  Use TDF.UserPreferenceDefaults class to set up default values that will get returned by this method.

export function GetPreference(propertyname: string, modulename?: string, _default: string = ""): string {
    let defaultValueName = "";

    if (modulename) {
        if (modulename.toLowerCase() === "infocenter") {
            modulename = "ManagersConsole";
        }

        defaultValueName = defaultValueName + modulename + "_";
    }
    defaultValueName = defaultValueName + propertyname;
    const defaultValue = UserPreferenceDefaults[defaultValueName] || _default;
        const CurrentUser = JSON.parse(localStorage.getItem("CurrentUser"));
    if (!CurrentUser || !CurrentUser.Preferences) { return _default; }
    const pref = $.grep(CurrentUser.Preferences.PrefObj, function(val: any, idx) {
        if (!modulename) {
            return val.PropertyName === propertyname;
        } else {
            return val.ModuleName === modulename && val.PropertyName === propertyname;
        }
    });
    if (pref[0]) {
        return pref[0].PropertyValue || defaultValue || _default;
    } else {
        return defaultValue || _default;
    }
}

   // Note:  Use TDF.CompanyPreferenceDefaults class to set up default values that will get returned by this method.
export function GetCompanyPreference(propertyname: string, modulename?: string,  _default: string = "") {
    let defaultValueName = "";

    if (modulename) {
        defaultValueName = defaultValueName + modulename + "_";
    }
    defaultValueName = defaultValueName + propertyname;
    const defaultValue = CompanyPreferenceDefaults[defaultValueName] || _default;
    const CurrentCompany =JSON.parse(sessionStorage.getItem("CurrentCompany"));
    if (!CurrentCompany || !CurrentCompany.Preferences) { return _default; }
    const pref = $.grep(CurrentCompany.Preferences.PrefObj, function(val: any, idx) {
        if (!modulename) {
            return val.PropertyName === propertyname;
        } else {
            return val.ModuleName === modulename && val.PropertyName === propertyname;
        }
    });
    if (pref[0]) {
        return pref[0].PropertyValue || defaultValue || _default;
    } else {
        return defaultValue || _default;
    }
}
