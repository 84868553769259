import "devextreme/ui/list";
import "devextreme/ui/popover";
import "devextreme/ui/scroll_view";
import "devextreme/ui/tab_panel";
import "devextreme/ui/toolbar";
import "devextreme/ui/tree_view";
import { Dialog } from "../../components/dialogs/dialog";
import { Notification } from "../../components/dialogs/notification";
import { ISummary } from "../../enums/bi/enums";
import {
  DataScopeSelectionMode,
  DataScopeSelectionTypes
} from "../../enums/user/datascope/enums";
import { DataScopeSelectorModel } from "../../interfaces/interfaces";
import { SummarySettings } from "../../services/datacenter/summarysettings";
import { DomSafeID, GetDevice } from "../../util/allutils";
import dxDataSource from "devextreme/data/data_source";
import { TDFRequest } from "../../services/request";

export abstract class DataScopeSelectionPanel {
  protected _summarySettingsService: SummarySettings = new SummarySettings();

  //Must Override
  abstract ScopeSelectionType: DataScopeSelectionTypes;
  abstract DialogTitle;

  // Properties
  ActiveSelectionLevel: ISummary.SummaryInterfaces.cBSID.EnumType = -1;
  ActiveFolderLevel = -1;
  SelectedItems = {};
  backupSelectedItems = {};
  SelectionMode: DataScopeSelectionMode = DataScopeSelectionMode.Multiple;
  AllowDefault = true;
  ScopeSelectorModelLoaded: boolean = false;
  ScopeSelectorModel: DataScopeSelectorModel;
  Buttons: DevExpress.ui.dxPopupToolbarItem[] = []; //Dialogs.IDialogButton[] = [];
  Dialog: Dialog;

  constructor() {
    let scopeSelector = this;
  }

  BuildPanel(response) {
    var scopeSelector = this;

    var changeTabFunction = function (tabLevel) {
      scopeSelector.ChangeFolderLevel(tabLevel);
    };

    // if (!GetDevice().isDevice) {
    var selectedIndex = 0;
    response.Tabs.forEach(function (item, index) {
      if (item.Level === scopeSelector.ActiveFolderLevel) {
        selectedIndex = index;
      }
    });

    var tabs = $("#scope-settings-container")
      .dxTabPanel({
        dataSource: response.Tabs,
        selectedIndex: selectedIndex,
        loop: false,
        animationEnabled: false,
        swipeEnabled: false,
        scrollingEnabled: false,
        scrollByContent: false,

        itemTitleTemplate: function (itemData, itemIndex, itemElement) {
          var caption = itemData.Name || "**Caption Not Set**";
          itemElement.append("<b>" + caption + "</b>");
        },
        itemTemplate: function (itemData, itemIndex, itemElement) {
          var tabContentID = "wrapper_level" + itemData.Level; //"scope-selection-tabs-" + itemData.Level;
          var tabContent: string;
          var container = $("<div />")
            .attr("id", tabContentID)
            .addClass("tdfitemtab_content");
          itemElement.append(container);

          switch (itemData.Level) {
            case 4:
            case 3:
            case 2:
            case 1:
              let scopeSelectionID1 = "scope-selection-tab-" + itemData.Level;
              let scopeScrollerID1 =
                "scope-selection-tab-scroller-" + itemData.Level;
              let scopeToolbarID1 = "searchAndButtons_level" + itemData.Level;
              scopeSelector.ActiveFolderLevel = itemData.Level;

              //#region Regular Folders
              tabContent = `<div id='helpPop_level${itemData.Level}'></div>
                                              <div id='${scopeToolbarID1}'>
                                              </div>
                                              <hr />
                                              <div id='${scopeScrollerID1}'><div id='${scopeSelectionID1}'>
                                              <div id='thetree_level${
                itemData.Level
                }' style='max-height:${$(
                  window
                ).height() * 0.5};overflow:hidden;overflow-y:auto;'></div>
                                              </div></div>`;
              container.append($(tabContent));

              //#region Initialize Javascript Elements
              $("#" + scopeToolbarID1)
                .css("margin-top", "5px")
                .addClass("container-fluid")
                .dxToolbar(scopeSelector.GetToolbarItems(itemData.Level))
                .dxToolbar("instance");

              var helpPop = function () {
                scopeSelector.ShowHelpPop();
              };

              var infoDotID = "infodot_level" + itemData.Level;
              var popoverDivID = "helpPop_level" + itemData.Level;
              var searchTextBoxID = "search-treeview_level" + itemData.Level;

              $("#" + infoDotID)
                .unbind()
                .hover(function () {
                  helpPop();
                });

              $("#" + popoverDivID)
                .dxPopover({
                  target: searchTextBoxID,
                  //   position: { my: "top" },
                  onShowing: function (data) {
                    //  dxOverlay.baseZIndex(50000);
                  },
                  onInitialized: function (data) {
                    //   dxOverlay.baseZIndex(50000);
                  },
                  contentTemplate: function (contentElement) {
                    var info = $("<div>")
                      .text(
                        " This search will automatically filter the results that are currently loaded in the tree. " +
                        "If you do not see the results you are looking for, press the [Enter] key to search the entire set. "
                      )
                      .addClass("btn-info")
                      .css({ padding: "5px" });
                    contentElement.append(info);
                  },
                  width: 300
                })
                .dxPopover("instance");

              $("#thetree_level" + itemData.Level)
                .dxTreeView({
                  dataStructure: "tree",
                  dataSource: new dxDataSource({
                    load: function (loadOptions) {
                      return scopeSelector._summarySettingsService.GetFolderChildren(
                        "",
                        itemData.Level
                      );
                    },
                    byKey: function (key) {
                      return scopeSelector._summarySettingsService.GetFolderChildren(
                        key,
                        itemData.Level
                      );
                    }
                  }),
                  onContentReady: function (response) {
                    scopeSelector.CheckSelectedItemsInTree();
                  },
                  expandAllEnabled: true,
                  parentIdExpr: "FolderParentID",
                  keyExpr: "FolderID",
                  displayExpr: "Name",
                  //virtualModeEnabled: true,
                  hasItemsExpr: "hasChildren",
                  onItemClick: function (data) {
                    scopeSelector.OnTreeItemClick(data);
                  },
                  itemTemplate: function (treeItemData) {
                    var itemID = "item_" + DomSafeID(treeItemData.FolderID);
                    if (treeItemData.hasChildren && !treeItemData.expanded) {
                      return $("<div>")
                        .text(treeItemData.Name)
                        .attr("id", itemID)
                        .attr("data-level", treeItemData.Level)
                        .attr("data-folderid", treeItemData.FolderID)
                        .attr("data-name", treeItemData.Name)
                        .append(
                          $("<i>")
                            .addClass("fa fa-plus expandicon")
                            .css("float", "left")
                            .css("margin-right", "20px")
                        );
                    } else if (!treeItemData.hasChildren) {
                      return $("<div>")
                        .text(treeItemData.Name)
                        .attr("id", itemID)
                        .attr("data-level", treeItemData.Level)
                        .attr("data-folderid", treeItemData.FolderID)
                        .attr("data-name", treeItemData.Name)
                        .append(
                          $("<i>")
                            .addClass("fa fa-square-o")
                            .css("float", "left")
                            .css("margin-right", "20px")
                        );
                    } else {
                      return $("<div>").text(treeItemData.Name);
                    }
                  }
                })
                .dxTreeView("instance");

              $("#" + scopeScrollerID1).dxScrollView({
                height: $(window).height() * 0.5 - 50,
                showScrollbar: "always",
                bounceEnabled: false
              });
              //#endregion
              //#endregion
              break;
            case 5:
              scopeSelector.ActiveFolderLevel = itemData.Level;
              // SalesRep
              let scopeSelectionID5 = "scope-selection-tab-" + itemData.Level;
              let scopeScrollerID5 =
                "scope-selection-tab-scroller-" + itemData.Level;
              let scopeToolbarID5 = "searchAndButtons_level" + itemData.Level;
              let selectAllBtnID5 = "selectAllBtn" + itemData.Level;
              let clearSelectionsBtnID = "clearSelectionsBtn" + itemData.Level;

              //#region SalesRep
              tabContent = `<div id='${scopeToolbarID5}'>
                                              </div>
                                              <hr />
                                              <div id='${scopeScrollerID5}'><div id='${scopeSelectionID5}'>
                                              <div id='salesrep-list' style='max-height:${$(
                window
              ).height() *
                0.5};overflow:hidden;overflow-y:auto'></div>
                                              </div></div>`;
              container.append($(tabContent));

              //#region Initialize Javascript Elements
              $("#" + scopeToolbarID5)
                .css("margin-top", "5px")
                .addClass("container-fluid")
                .dxToolbar({
                  items: [
                    {
                      locateInMenu: GetDevice().isDevice ? "always" : "auto",
                      location: "before",
                      widget: "dxButton",
                      options: {
                        attr: { id: selectAllBtnID5 },
                        icon: "",
                        hint: "Select All Visible",
                        text: "Select All Visible",
                        type: "default",
                        visible:
                          scopeSelector.SelectionMode ===
                          DataScopeSelectionMode.Multiple,
                        onClick: function () {
                          scopeSelector.selectAllVisibleCheckboxes();
                        }
                      }
                    },
                    {
                      locateInMenu: GetDevice().isDevice ? "always" : "auto",
                      location: "before",
                      widget: "dxButton",
                      options: {
                        attr: { id: clearSelectionsBtnID },
                        icon: "",
                        hint: "Clear Selections",
                        text: "Clear Selections",
                        type: "default",
                        visible: true,
                        onClick: function () {
                          scopeSelector.ClearSelections();
                        }
                      }
                    }
                  ]
                })
                .dxToolbar("instance");

              $("#salesrep-list")
                .dxList({
                  dataSource: new dxDataSource({
                    load: function (loadOptions) {
                      return scopeSelector._summarySettingsService.GetRepList();
                    }
                  }),
                  itemTemplate: function (itemData) {
                    var itemID = scopeSelector.GetSalesRepItemID(
                      itemData.SalesPersonID,
                      itemData.Name
                    );

                    return $("<div>")
                      .text(itemData.SalesPersonID + " - " + itemData.Name)
                      .attr("id", itemID)
                      .attr("data-level", itemData.Level)
                      .attr("data-folderid", itemData.FolderID)
                      .attr("data-name", itemData.Name);
                  },
                  onContentReady: function (response) {
                    if (scopeSelector.ActiveSelectionLevel == 3) {
                      scopeSelector.CheckSelectedItemsInTree();
                    }
                  },
                  allowItemDeleting: false,
                  showSelectionControls: true,
                  selectionMode: "multiple",
                  onSelectionChanged: function (data) {
                    if (data.addedItems.length > 0) {
                      scopeSelector.AddSelectedTag(
                        5,
                        data.addedItems[0].SalesPersonID,
                        data.addedItems[0].Name
                      );
                    } else if (data.removedItems.length > 0) {
                      scopeSelector.RemoveSelectedSalesRepFromTags(
                        5,
                        data.removedItems[0].SalesPersonID,
                        data.removedItems[0].Name
                      );
                    }
                  },
                  grouped: false
                })
                .dxList("instance");

              $("#" + scopeScrollerID5).dxScrollView({
                height: $(window).height() * 0.5 - 50,
                showScrollbar: "always",
                bounceEnabled: false
              });
              //#endregion
              //#endregion
              break;
            case 6:
              // Saved Selections
              let scopeScrollerID6 =
                "scope-selection-tab-scroller-" + itemData.Level;
              let listID = "savedSelectionsList";
              tabContent =
                "<div id='" +
                scopeScrollerID6 +
                "'><div id='" +
                listID +
                "'></div></div>";

              container.append($(tabContent));

              var displaySavedSelections = function (response) {
                $("#savedSelectionsList")
                  .dxList({
                    items: response,
                    itemTemplate: function (itemData, itemIndex) {
                      let result = $(
                        "<span style='margin: 5px'>" + itemData.Name + "</span>"
                      ).attr({
                        id: "savedSelection_" + itemData.ID,
                        name: itemData.Name,
                        "data-savedSelectionID": itemData.ID
                      });
                      return result;
                    },
                    showSelectionControls: false,
                    onItemClick: function (e) {
                      var savedSelectionID = e.itemData.ID;
                      scopeSelector.LoadSavedSelection(savedSelectionID);
                    }
                  })
                  .dxList("instance");

                $("#" + scopeScrollerID6).dxScrollView({
                  height: $(window).height() * 0.5 - 50,
                  showScrollbar: "always",
                  bounceEnabled: false
                });
              };

              scopeSelector._summarySettingsService
                .GetSavedSelectionList()
                .done(displaySavedSelections);

              break;
            case 7:
              // Other
              tabContent = "<div id='other-container'>";
              if (response.ShowCustomerId) {
                tabContent = tabContent.concat(`<br />
                                                                    <label>Customer ID:</label>
                                                                    <input type='text' id='scope-custid' oninput='${$(
                  this
                )
                    .siblings(
                      "input"
                    )
                    .val(
                      ""
                    )};' /><br />`);
              }
              if (response.ShowMasterAcctId) {
                tabContent = tabContent.concat(`<br />
                                                                    <label>Master ID:</label>
                                                                    <input type='text' id='scope-masterid' oninput='${$(
                  this
                )
                    .siblings(
                      "input"
                    )
                    .val(
                      ""
                    )};'/><br />`);
              }

              if (response.ShowSalesRep) {
                tabContent = tabContent.concat(`<br />
                                                                    <label>Salesman ID:</label>
                                                                    <input type='text' id='scope-salesid' oninput='${$(
                  this
                )
                    .siblings(
                      "input"
                    )
                    .val(
                      ""
                    )};' />`);
              }
              tabContent = tabContent.concat("</div>");
              container.append($(tabContent));
              break;
            //case 8:
            //    // Account List tab
            //    tabContent = "";
            //    break;
            default:
            //Who Knows
          }
        },
        onSelectionChanged: function (e) {
          var tabLevel = e.addedItems[0].Level;
          changeTabFunction(tabLevel);
        }
      })
      .dxTabPanel("instance");

    scopeSelector.ChangeFolderLevel(scopeSelector.ActiveFolderLevel);
    //   } else {
    ///       alert("not done");
    //  }
  }

  OnTreeItemClick(data) {
    let panel = this;
    if (data.itemData.hasChildren) {
      $(data.jQueryEvent.currentTarget)
        .find(".expandicon")
        .remove();
      var component = data.component;
      var theData = data;
      var scrollPos = $("#thetree_level" + data.itemData.Level).scrollTop();
      var chil = data.component._dataSource._store
        .byKey(data.itemData.FolderID)
        .done(function (response) {
          var that = component;

          theData.itemData.items = [];

          $.each(response, function (key, val) {
            theData.itemData.items.push(val);
          });

          theData.itemData.expanded = true;
          var currentData = component._dataSource._items;
          component.option({ items: currentData });
          panel.CheckSelectedItemsInTree();

          $("#thetree_level" + theData.itemData.Level).scrollTop(scrollPos);
        });
    } else {
      panel.OnTreeLeafClicked(data);
    }
  }

  // This is for the nodes without children.
  OnTreeLeafClicked(data) {
    let panel = this;

    var itemID = "selectedItem_" + DomSafeID(data.itemData.FolderID);

    var element = $(data.jQueryEvent.currentTarget);

    if (element.find(".fa-check-square-o").length > 0) {
      element
        .find(".fa-check-square-o")
        .removeClass("fa-check-square-o")
        .addClass("fa-square-o");
      data.itemData.selected = false;
      panel.RemoveSelectedTag($("#selectedScope").find("#" + itemID));
    } else {
      if (panel.SelectionMode == DataScopeSelectionMode.Single) {
        panel.ClearSelections();
      }

      element
        .find(".fa-square-o")
        .removeClass("fa-square-o")
        .addClass("fa-check-square-o");
      data.itemData.selected = true;
      var itemElement = data.itemElement.children(0).children(0);
      panel.AddSelectedTag(
        itemElement.data("level"),
        itemElement.data("folderid"),
        itemElement.data("name")
      );
    }
  }

  GetToolbarItems(level) {
    var panel = this;

    var infoDotID = "infodot_level" + level;
    var searchTextBoxID = "search-treeview_level" + level;
    var expandAllBtnID = "expandAllBtn" + level;
    var selectAllBtnID = "selectAllBtn" + level;
    var clearSelectionsBtnID = "clearSelectionsBtn" + level;
    let btns: DevExpress.ui.dxToolbarOptions = {
      items: [
        {
          locateInMenu: "never",
          location: "before",
          widget: "dxTextBox",
          options: {
            inputAttr: { id: searchTextBoxID },
            value: "",
            mode: "search",
            placeholder: "Search",
            width: "200px",
            showClearButton: true,
            onValueChanged: function (e) {
              if (
                e.jQueryEvent &&
                e.jQueryEvent.currentTarget.className.indexOf("clear") !== -1
              ) {
                //tree.getDataSource
                var tree = $("#thetree_level" + level).dxTreeView("instance");
                tree
                  .option("dataSource")
                  .load()
                  .done(function (response) {
                    tree.option("searchValue", "");
                  });
              }
            },
            onEnterKey: function (e) {
              if (e.jQueryEvent.keyCode !== 13) {
                return;
              }
              var tree = $("#thetree_level" + level).dxTreeView("instance");
              tree.option({ items: [] });
              //TODO - replace getJSON
              $.getJSON( TDFRequest.GetApiLink("/core/user/SearchFoldersByLevel/"), {
                str: e.jQueryEvent.currentTarget.value,
                level: level
              }).done(function (response) {
                var that = tree;

                var theData = [];

                $.each(response, function (key, val) {
                  theData.push(val);
                });

                that.option({ items: theData });
                panel.CheckSelectedItemsInTree();
              });
            },
            onKeyUp: function (data) {
              var tree = $("#thetree_level" + level).dxTreeView("instance");
              tree.option("searchValue", data.jQueryEvent.currentTarget.value);
            }
          }
        },
        {
          locateInMenu: "never",
          location: "before",
          template: function () {
            return $(
              "<i id='" + infoDotID + "' class='fa fa-question-circle'></i>"
            );
          }
        },
        {
          locateInMenu: GetDevice().isDevice ? "always" : "auto",
          location: "before",
          widget: "dxButton",
          options: {
            attr: { id: expandAllBtnID },
            icon: "",
            hint: "Expand All Nodes",
            text: "Expand All Nodes",
            type: "default",
            visible: true,
            onClick: function () {
              panel.ExpandAll();
            }
          }
        },
        {
          locateInMenu: GetDevice().isDevice ? "always" : "auto",
          location: "before",
          widget: "dxButton",
          options: {
            attr: { id: selectAllBtnID },
            icon: "",
            hint: "Select All Visible",
            text: "Select All Visible",
            type: "default",
            visible: panel.SelectionMode === DataScopeSelectionMode.Multiple,
            onClick: function () {
              panel.selectAllVisibleCheckboxes();
            }
          }
        },
        {
          locateInMenu: GetDevice().isDevice ? "always" : "auto",
          location: "before",
          widget: "dxButton",
          options: {
            attr: { id: clearSelectionsBtnID },
            icon: "",
            hint: "Clear Selections",
            text: "Clear Selections",
            type: "default",
            visible: true,
            onClick: function () {
              panel.ClearSelections();
            }
          }
        }
      ]
    };
    return btns;
  }

  GetDataScopeSelectorModel(): JQueryPromise<DataScopeSelectorModel> {
    var scopeSelector = this;
    let dfd: JQueryDeferred<DataScopeSelectorModel> = $.Deferred();
    if (!scopeSelector.ScopeSelectorModelLoaded) {
      scopeSelector._summarySettingsService
        .DataScopeSelectorModelRequest(scopeSelector.ScopeSelectionType)
        .done(function (response) {
          scopeSelector.ScopeSelectorModel = response;
          scopeSelector.ScopeSelectorModelLoaded = true;
          dfd.resolve(scopeSelector.ScopeSelectorModel);
        });
    } else {
      return dfd.promise(dfd.resolve(scopeSelector.ScopeSelectorModel));
    }

    return dfd.promise();
  }

  DialogCallBack: Function;

  // dialogCallBack is used if there are things that the caller needs to do to handle the outcome of this dialog (refresh a grid view, display the new selected values, etc).
  // dialogCallBack is only called from "Okay" or "Use and Set as Default"
  DisplayPanel(dialogCallBack?) {
    var panel = this;

    if (dialogCallBack) {
      panel.DialogCallBack = dialogCallBack;
    }

    panel.AddDialogButtons();
    $.when<any>(
      panel.GetInitialSelectedItemList(),
      panel.GetDataScopeSelectorModel()
    ).done(function (response1, response2) {
      // Save off the original set of selections before letting the user change them.  Then if the user cancels, we can easily restore the set back.
      // Note:  Chose to JSON stringify/parse based on this discussion here - http://stackoverflow.com/questions/122102/what-is-the-most-efficient-way-to-deep-clone-an-object-in-javascript
      panel.backupSelectedItems = JSON.parse(
        JSON.stringify(panel.SelectedItems)
      );

      panel.Dialog = new Dialog({
        size: "size-large",
        title: panel.DialogTitle,
        id: "package-selection-dialog",
        body: $("<div />")
          .attr("id", "scope-settings-container")
          .attr("style", "padding:8px;")
          .addClass("")
          .append(
            $("<div />")
              .attr("id", "")
              .addClass("tdfitemtabs")
          ),
        type: "type-primary",
        buttons: panel.Buttons,
        closable: false
      });

      panel.Dialog.open().done(function (args) {
        panel.BuildPanel(response2);
      });

      //TODO: Get the ID of the dialog.
      var header = panel.Dialog.getModalHeader();
      var closeBtn = header.find(".bootstrap-dialog-close-button");
      $(closeBtn).on("click", function () {
        panel.SelectedItems = JSON.parse(
          JSON.stringify(panel.backupSelectedItems)
        );
      });

      var footer = panel.Dialog.getModalFooter();
      var selectionElem = $("<div>")
        .attr("id", "selectedScope")
        .css("float", "left");
      var numberElem = $("<span>")
        .attr("id", "num-current-selected")
        .css("float", "left")
        .text("0 Currently Selected");

      $($(footer).children()[0]).prepend(selectionElem);
      $($(footer).children()[0]).prepend(numberElem);
    });
  }

  private AddDialogButtons() {
    let panel = this;

    if (!panel.Buttons) {
      panel.Buttons = [];
    }

    if (panel.AllowDefault) {
      panel.Buttons.push({
        toolbar: "bottom",
        location: "after",
        widget: "dxButton",
        options: {
          text: "Use and Set as Default",
          type: "default",
          id: "btn_set_bsi_default",
          onClick: function () {
            panel.ApplySettings(true);
          }
        }
      });
    }

    panel.Buttons.push({
      toolbar: "bottom",
      location: "after",
      widget: "dxButton",
      options: {
        text: "OK",
        icon: "todo",
        type: "success",
        id: "btn_apply_dataScope_settings",
        onClick: function () {
          panel.ApplySettings(false);
        }
      }
    });

    panel.Buttons.push({
      toolbar: "bottom",
      location: "after",
      widget: "dxButton",
      options: {
        text: "Cancel",
        icon: "remove",
        type: "danger",
        onClick: function () {
          //Restore the original set of selections.
          panel.SelectedItems = JSON.parse(
            JSON.stringify(panel.backupSelectedItems)
          );
          panel.Buttons = []; // Reset this in case we re-open the dialog.
          panel.Dialog.close();
        }
      }
    });

  }

  ApplySettings(setAsDefault: boolean) {
    var panel = this;

    if (
      panel.ActiveFolderLevel != 7 &&
      panel.CurrentlySelectedItemsAsString() == ""
    ) {
      new Notification({
        type: "warning",
        shading: true,
        displayTime: 3000,
        message:
          "No selection was made.  Please make a selection or click Cancel to close the dialog without changing your selection."
      });
    } else {
      panel.CommitPanelSelections(setAsDefault);
      panel.Buttons = []; // Reset this in case we re-open the dialog.
      if (panel.DialogCallBack) {
        panel.DialogCallBack();
      }
      panel.Dialog.close();
    }
  }

  ConvertScopeLevelToFolderTab(scopeLevel) {
    var panel = this;

    var convertedValue = parseInt(scopeLevel);
    var folderTab = -1;

    switch (convertedValue) {
      case 8:
        folderTab = 4; // Level 4
        break;
      case 7:
        folderTab = 3; // Level 3
        break;
      case 6: // Custom
        folderTab = 6; // Saved Selections
        break;
      case 3: // SalesRep
        folderTab = 5;
        break;
      case 2: // AcctRoot
        folderTab = 2;
        break;
      case 0: //AcctPkg
        folderTab = 1;
        break;
      case 5: // Master
      case 1: // AcctID
      case 4: // Bill To
        folderTab = 7;
        break;
    }
    return folderTab;
  }

  ConvertFolderTabToScopeLevel(folderTab) {
    var panel = this;

    var convertedValue = parseInt(folderTab);
    var scopeLevel = -1;

    switch (convertedValue) {
      case 4: // Level 4
        scopeLevel = 8;
        break;
      case 3: // Level 3
        scopeLevel = 7;
        break;
      case 2: // AcctRoot
        scopeLevel = 2;
        break;
      case 1: // AcctPkg
        scopeLevel = 0;
        break;
      case 5: // Sales Rep
        scopeLevel = 3;
        break;
      case 6: // Saved Selections
        //TAB TODO  - not sure of level when using saved selections.
        break;
      case 7: // Other
        // This will be handled when OK is clicked.
        //scopeLevel = 3;     // Other-> SalesRep
        //scopeLevel = 6;     // Other-> CustID
        //scopeLevel = 5;     // Other-> Master
        break;
    }
    return scopeLevel;
  }

  ChangeFolderLevel(level) {
    var panel = this;

    this.ActiveSelectionLevel = this.ConvertFolderTabToScopeLevel(level);
    this.ActiveFolderLevel = level;

    this.ResetSelectionBar();

    if (level === 4) {
      panel.CheckSelectedItemsInTree();
    }
  }

  UpdateCount() {
    var panel = this;

    var numberSelected = 0;
    if (this.SelectedItems["level" + panel.ActiveFolderLevel]) {
      numberSelected = Object.keys(
        this.SelectedItems["level" + panel.ActiveFolderLevel]
      ).length;
    }

    $("#num-current-selected").text(numberSelected + " Currently Selected");
  }

  AddSelectedTag(level, itemID, name) {
    var panel = this;

    panel.AddSelectedItem(level, itemID, name);

    var thisItemID = "selectedItem_" + DomSafeID(itemID);
    var onClickMethod = function (e) {
      panel.RemoveSelectedTag(e.target);
    };

    if (level === 5) {
      onClickMethod = function (e) {
        panel.RemoveSelectedSalesRepFromList(e.target);
      };
      thisItemID = panel.GetSalesRepItemID(itemID, name);
    }

    $("#selectedScope").append(
      $(
        "<span class='label label-success' style='margin: 5px'>" +
        name +
        "</span>"
      )
        .attr({
          id: thisItemID,
          "data-level": level,
          "data-folderid": itemID,
          "data-name": name
        })
        .on("click", onClickMethod)
    );

    this.UpdateCount();
  }

  AddSelectedItem(level, itemID, name) {
    var panel = this;

    var thisItemID = "selectedItem_" + DomSafeID(itemID);
    var thisFolderID = itemID;

    if (level === 5) {
      thisItemID = this.GetSalesRepItemID(itemID, name);
      thisFolderID = itemID;
    }

    if (!this.SelectedItems["level" + level]) {
      this.SelectedItems["level" + level] = {};
    }

    if (!this.SelectedItems["level" + level][thisItemID]) {
      this.SelectedItems["level" + level][thisItemID] = {
        folderid: thisFolderID,
        name: name,
        level: level
      };
    }
  }

  RemoveSelectedTag(elmnt) {
    var panel = this;

    var level = $(elmnt).data("level");
    var name = $(elmnt).data("name");
    var folderID = $(elmnt).data("folderid");
    var selectedItemID = "selectedItem_" + DomSafeID(folderID);

    $(elmnt).remove();

    var treeElmnt = $(
      $("#thetree_level" + level)
        .dxTreeView("instance")
        .element()
    ).find('div[data-folderid="' + folderID + '"]');
    if (treeElmnt) {
      treeElmnt
        .find(".fa-check-square-o")
        .removeClass("fa-check-square-o")
        .addClass("fa-square-o");
    }

    delete this.SelectedItems["level" + level][selectedItemID];
    this.UpdateCount();
  }

  RemoveSelectedSalesRepFromList(elmnt) {
    var panel = this;

    var level = $(elmnt).data("level");
    var name = $(elmnt).data("name");
    var folderID = $(elmnt).data("folderid");

    $(elmnt).remove();

    var itemID = this.GetSalesRepItemID(folderID, name);
    if ($("#salesrep-list")) {
      var listElmnt = $("#salesrep-list")
        .dxList("instance")
        .unselectItem(
          $("#" + itemID)
            .parent()
            .parent()
        );
    }
    delete this.SelectedItems["level" + level][itemID];

    this.UpdateCount();
  }

  RemoveSelectedSalesRepFromTags(level, folderID, name) {
    var panel = this;
    var itemID = this.GetSalesRepItemID(folderID, name);

    $("span#" + itemID).remove();

    delete this.SelectedItems["level" + level][itemID];

    this.UpdateCount();
  }

  CheckSelectedItemsInTree() {
    var panel = this;
    var actvlvl = "level" + panel.ActiveFolderLevel;
    if (panel.SelectedItems[actvlvl]) {
      for (var key in panel.SelectedItems[actvlvl]) {
        var selectedItem = panel.SelectedItems[actvlvl];
        selectedItem = selectedItem[key];
        if (panel.ActiveFolderLevel === 5) {
          var itemID = panel.GetSalesRepItemID(
            selectedItem.folderid,
            selectedItem.name
          );
          if ($("#salesrep-list").hasClass("dx-list")) {
            var listElmnt = $("#salesrep-list")
              .dxList("instance")
              .selectItem(
                $("#" + itemID)
                  .parent()
                  .parent()
              );
          }
        } else {
          var treeElmnt = $(
            $("#thetree_level" + panel.ActiveFolderLevel)
              .dxTreeView("instance")
              .element()
          ).find('div[data-folderid="' + selectedItem.folderid + '"]');
          if (treeElmnt) {
            treeElmnt
              .find(".fa-square-o")
              .removeClass("fa-square-o")
              .addClass("fa-check-square-o");
          }
        }
      }
    }
  }

  GetInitialSelectedItemList(): JQueryPromise<any[]> {
    let scopeSelector = this;
    let initialList = [];

    let dfd: JQueryDeferred<any[]> = $.Deferred();
    return dfd.promise(dfd.resolve(initialList));
  }

  CurrentlySelectedItemsAsArray(): any[] {
    let panel = this;
    let currentList: any[] = [];

    var actvlvl = "level" + panel.ActiveFolderLevel;
    if (panel.SelectedItems[actvlvl]) {
      for (var key in panel.SelectedItems[actvlvl]) {
        currentList.push(panel.SelectedItems[actvlvl][key]);
      }
    }

    return currentList;
  }

  GetSalesRepItemID(repID, repName) {
    var panel = this;

    repID = repID.toString().replace("rep_", "");
    var finalRepID = repID + "-" + repName;

    finalRepID = finalRepID.split(" ").join("");
    finalRepID = $.trim(finalRepID);
    finalRepID = DomSafeID(finalRepID);

    return finalRepID;
  }

  CurrentlySelectedItemsAsString(): string {
    var panel = this;
    var selection = "";

    if (this.SelectedItems["level" + this.ActiveFolderLevel]) {
      for (var key in this.SelectedItems["level" + this.ActiveFolderLevel]) {
        var selectedItem = this.SelectedItems["level" + this.ActiveFolderLevel][
          key
        ];

        if (selection) {
          selection = selection + "," + selectedItem.folderid;
        } else {
          selection = selection + selectedItem.folderid;
        }
      }
    }
    return selection;
  }

  selectAllVisibleCheckboxes() {
    var panel = this;
    var items;
    if (panel.ActiveFolderLevel <= 4) {
      items = $("#thetree_level" + panel.ActiveFolderLevel).find(
        $(".fa-square-o:visible")
      );

      for (var index = 0; index < items.length; index++) {
        var entry = items[index];
        $(entry)
          .removeClass("fa-square-o")
          .addClass("fa-check-square-o");

        var parent = $(entry).parent();
        var level = parent.data("level");
        var folderID = parent.data("folderid");
        var name = parent.data("name");

        this.AddSelectedTag(level, folderID, name);
      }
    } else if (panel.ActiveFolderLevel === 5) {
      if ($("#salesrep-list")) {
        var n = $("#salesrep-list").dxList("instance");
        items = n.option("items");
        for (var i = 0; i < items.length; i++) {
          n.selectItem(items[i]);
        }
      }
    }
  }

  ExpandAll() {
    var panel = this;
    var expandOnContentReadyFunction = function (response) {
      var n = response.component.option("items");
      for (var i = 0; i < n.length; i++) {
        response.component.expandItem(n[i]);
      }
      panel.CheckSelectedItemsInTree();
    };
    var onItemClickFunction = function (data) {
      if (!data.itemData.hasChildren) {
        panel.OnTreeLeafClicked(data);
      } else {
        panel.CheckSelectedItemsInTree();
      }
    };

    $("#thetree_level" + panel.ActiveFolderLevel).remove();

    var newTree = $("<div>").attr(
      "id",
      "thetree_level" + panel.ActiveFolderLevel
    ); //.css("max-height", "800px").css("overflow", "hidden").css("overflow-y", "auto");
    var scopeSelectionID = "scope-selection-tab-" + panel.ActiveFolderLevel;

    $("#" + scopeSelectionID).append(newTree);

    $("#thetree_level" + panel.ActiveFolderLevel)
      .dxTreeView({
        dataStructure: "plain",
        dataSource: new dxDataSource({
          load: function (loadOptions) {
            return panel._summarySettingsService.GetFolderChildren(
              "all",
              panel.ActiveFolderLevel
            );
          }
        }),
        onContentReady: function (response) {
          expandOnContentReadyFunction(response);
        },
        expandNodesRecursive: true,
        expandAllEnabled: true,
        rootValue: "0",
        parentIdExpr: "FolderParentID",
        keyExpr: "FolderID",
        displayExpr: "Name",
        virtualModeEnabled: false,
        hasItemsExpr: "hasChildren",
        onItemClick: function (data) {
          onItemClickFunction(data);
        },
        itemTemplate: function (itemData): string | JQuery {
          var itemID = "item_" + DomSafeID(itemData.FolderID);
          if (itemData.hasChildren) {
            return "item";
          } else {
            return $("<div>")
              .text(itemData.Name)
              .attr("id", itemID)
              .attr("data-level", itemData.Level)
              .attr("data-folderid", itemData.FolderID)
              .attr("data-name", itemData.Name)
              .append(
                $("<i>")
                  .addClass("fa fa-square-o")
                  .css("float", "left")
                  .css("margin-right", "20px")
              );
          }
        }
      })
      .dxTreeView("instance");
  }

  ClearSelections() {
    var panel = this;

    if (this.SelectedItems["level" + panel.ActiveFolderLevel]) {
      for (var key in this.SelectedItems["level" + panel.ActiveFolderLevel]) {
        var selectedItem = this.SelectedItems[
          "level" + panel.ActiveFolderLevel
        ][key];
        if (panel.ActiveFolderLevel === 5) {
          var itemID = this.GetSalesRepItemID(
            selectedItem.folderid,
            selectedItem.name
          );
          if ($("#salesrep-list")) {
            var listElmnt = $("#salesrep-list")
              .dxList("instance")
              .unselectItem(
                $("#" + itemID)
                  .parent()
                  .parent()
              );
          }
        } else if (panel.ActiveFolderLevel != 6) {
          var treeItemElmnt = $(
            $("#thetree_level" + panel.ActiveFolderLevel).dxTreeView("instance")
              .element()
          ).find('div[data-folderid="' + selectedItem.folderid + '"]');
          if (treeItemElmnt) {
            treeItemElmnt
              .find(".fa-check-square-o")
              .removeClass("fa-check-square-o")
              .addClass("fa-square-o");
          }
        }
        delete this.SelectedItems["level" + panel.ActiveFolderLevel][key];
      }
    }
    this.ResetSelectionBar();
  }

  ResetSelectionBar() {
    var panel = this;

    $("#selectedScope")
      .find("span")
      .remove();
    if (panel.ActiveFolderLevel <= 4) {
      // Saved Selections and Other tab don't use the selected items area.  SalesRep tab gets filled in differently.
      if (this.SelectedItems["level" + panel.ActiveFolderLevel]) {
        for (var key in this.SelectedItems["level" + panel.ActiveFolderLevel]) {
          var selectedItem = this.SelectedItems[
            "level" + panel.ActiveFolderLevel
          ][key];
          this.AddSelectedTag(
            selectedItem.level,
            selectedItem.folderid,
            selectedItem.name
          );
        }
      }
    }
    this.UpdateCount();
  }

  ShowHelpPop() {
    var panel = this;

    var helppop = $("#helpPop_level" + panel.ActiveFolderLevel).dxPopover(
      "instance"
    );
    helppop.option(
      "target",
      "#search-treeview_level" + panel.ActiveFolderLevel
    );
    helppop.show();

    $($("#searchboxhelp_level" + panel.ActiveFolderLevel)[0])
      .unbind()
      .hover(
        function () {
          helppop.show();
        },
        function () {
          helppop.hide();
        }
      );
  }

  CommitPanelSelections(setAsDefault: boolean) {
    var panel = this;
    alert("This should be overridden in an inherited class.");
  }

  LoadSavedSelection(id) {
    var panel = this;
    alert("This should be overridden in an inherited class.");
  }
}
