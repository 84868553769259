"use strict";
import * as Globalize from "globalize";
import { custom, confirm } from "devextreme/ui/dialog";
import * as moment from "moment";
import { itemTypes } from "../../enums/enums";
import {
  CopyQuoteOptionsEnum,
  EnumDocumentCreationParentSearchOptions,
  EnumDocumentTypes,
  EnumERPProcessStatus,
  EnumQuoteDocName,
  EnumQuoteDocumentExportOptions,
  EnumQuoteStatus,
  EnumQuoteToOrderActions,
  EnumSellPriceMethods,
  HidableQuoteHeaderFieldsEnum,
  IncludeInLeaseEnum,
  Item_Quotes_Fields
} from "../../enums/quotes/enums";

import {
  GetCompanyPreference,
  GetPreference
} from "../../infrastructure/user/getpreference";
import { Preferences } from "../../infrastructure/user/preferences";
import { IDialogOptions } from "../../interfaces/interfaces";
import {
  IAdvancedQuoteDocumentNamingOptions,
  IDataProduct,
  IDataProductWrapper,
  IExportOptions,
  IExtendedTotal,
  IFinancePlan,
  ILineItemExportOptions,
  IQuoteDataProduct,
  IQuoteDefaultDraftCategory,
  IQuoteDetails,
  IQuoteDocumentAppearanceOptions,
  IQuoteDocumentOptions,
  IQuoteERPOptions,
  IQuoteLineEditSettings,
  IQuoteLineField,
  IQuoteOptions,
  ISegment,
  ISelectedPlan,
  ISetFinancePlanOptions,
  ITemplateSetupOptions
} from "../../interfaces/quotes/interfaces";
import { Grid } from "../../legacy-centers-grids/grid";
import { PackageSelection } from "../controls/packageselection";
import { Dialog } from "../dialogs/dialog";
import { Notification } from "../dialogs/notification";
import { EmailGenerator } from "./emailgenerator";
import { QuoteDetailControlTypes } from "./helpers/quotedetailcontroltypes";
import { QuoteResultForm } from "./helpers/quoteresultform";
import { ItemBase } from "./itembase";
import {
  GetDevice,
  DomSafeID,
  GetDataResponseObject,
  CloneIT,
  GimmeGUID
} from "../../util/allutils";
import dxDataSource from "devextreme/data/data_source";
import dxCustomStore from "devextreme/data/custom_store";
import notify from "devextreme/ui/notify";
import {
  DisplayInfo,
  LoadCompany,
  CurrentUser
} from "../../infrastructure/context";
import { SearchRecFav } from "../controls/searchrecfav";
import { Search } from "../../legacy-centers-grids/search";
import { EditGrid } from "../../legacy-centers-grids/editgrid";
import * as QuoteDataService from '../../services/quote/quotedataservice';
import { ValidParents } from 'services/item/itemdataservice';
import 'devextreme/ui/form';
import 'devextreme/ui/radio_group';
import 'devextreme/ui/text_area';
import 'devextreme/ui/tag_box';
import 'devextreme/ui/switch';
import 'devextreme/ui/slider';
import 'devextreme/ui/data_grid';
import 'devextreme/ui/lookup';




import { TDFRequest } from 'services/request';
import { AddNewLineItemOptionsFromJSON } from "../../apis/tsfetch";

export class Quote extends ItemBase {
  DisplayName: string;
  CenterType = -1;
  get ItemType() {
    return itemTypes.itemQuote;
  }
  get TypeName() {
    return "Quote";
  }
  set TypeName(name) {
    if (name && this.TypeName !== name) this.TypeName = name;
  }
  get RequiresParentItem(): boolean {
    return true;
  }
  readonly QuoteContainerID: string = "tdfquote";
  public readonly BaseQuotePath: string = "/Quote/";
  QuoteDetails: IQuoteDetails;
  DetailsDirty: boolean;
  DirtyDetails: any = {};
  QuoteOptions: IQuoteOptions;
  ERPOption: IQuoteERPOptions;
  constProductSearchViewGUID: string;
  SelectedPlan: ISelectedPlan;
  Contracts: Array<{ Value: string; Display: string }>;
  EnableSPA: boolean = false;
  get LockedForReal() {
    let theQuote = this;

    if (theQuote.QuoteDetails) {
      return (
        theQuote.QuoteDetails.ERPProcessStatus !==
        EnumERPProcessStatus.Normal &&
        theQuote.QuoteDetails.ERPProcessStatus !==
        EnumERPProcessStatus.Processed
      );
    }
    return false;
  }

  LinesLocked() {
    let theQuote = this;
    let t = [
      EnumQuoteStatus.ImportedByBISync,
      EnumQuoteStatus.ProcessedToERPOrderSuccessfully,
      EnumQuoteStatus.ProcessedToERPPriceContractSuccessfully,
      EnumQuoteStatus.ProcessedToERPQuoteSuccessfully,
      EnumQuoteStatus.Closed
    ];
    let LinesLocked = $.inArray(theQuote.QuoteDetails.Status, t) > -1;
    return LinesLocked;
  }
  LineItemHelper: QuoteLineHelper;
  ExtendedTotalHelper: QuoteTotalsHelper;
  FinanceHelper: QuoteFinanceHelper;
  ProductSearchHelper: ProductSearchHelper;
  SegmentsDefined: boolean;
  SegmentHelper: QuoteSegmentHelper;
  PointbookHelper: PointbookHelper;
  StatusOptions: any;
  PointBooksEnabled: boolean;
  NewQuoteParentSearchDialog: Dialog;
  QuickStatus: boolean;
  constructor(args) {
    super(args);
    let theQuote = this;
    $.each(args, function (key, val) {
      if (theQuote.hasOwnProperty(key)) theQuote[key] = val;
    });

    if (!theQuote.DisplayName) {
      DisplayInfo(theQuote.ItemType).done(function (displayinfo) {
        theQuote.DisplayName = displayinfo.DisplayName;
        theQuote.TypeName = displayinfo.TypeName;
      });
    }
    if (theQuote.ItemID) {
      QuoteDataService.QuickStatus(theQuote.ItemID)
        .done(enabled => {
          theQuote.QuickStatus =
            enabled.toLowerCase() === "true" ? true : false;
        });

      theQuote.Initialize();
    }
  }

  Refresh() {
    let theQuote = this;
    window.location.reload();
    return theQuote;
  }
  //#region Quote Methods\Functions

  Create() {
    let theQuote = this;
    let d = $.Deferred();
    ValidParents(itemTypes.itemQuote)
      .done(function (response: any) {
        let SRF: SearchRecFav = new SearchRecFav(
          itemTypes.itemQuote,
          response,
          itemTypes.itemUnknown,
          false
        );
        SRF.GetSRFDialog().done(selected => {
          theQuote.CreateNewParentSelection(selected);
          d.resolve();
        });
      });

    return d.promise();
  }

  CreateNewParentSelection(data) {
    let theQuote = this;
    let theWindow;
    let selectedRow = data[0];
    let parentid = selectedRow["TDF GUID"];
    let tempDescription =
      selectedRow["Company"] ||
      selectedRow["Subject"] ||
      selectedRow["CompanyName"] ||
      selectedRow["AccountName"] ||
      selectedRow["Description"] ||
      "";

    let Description = new Dialog({
      id: "newquoteDescription",
      body: $("<div id='getdesccontent'/>")
        .append($("<label/>").text("Please Enter A Description"))
        .append(
          $("<div />").attr('id', 'descriptionfornewquote').dxTextArea({
            //3/5/2010 4:35:12 PM - Abec Inc -
            value:
              Globalize.formatDate(new Date(), { datetime: "short" }) +
              "-" +
              tempDescription
          }).dxTextArea('instance').element()
        ),
      size: "size-normal",
      type: "type-warning",
      closable: true,
      title: "New Quote Description",
      buttons: [
        {
          widget: "dxButton",
          toolbar: "bottom",
          location: "after",
          options: {
            id: "",
            type: "success",
            onClick: function () {
              if (!GetDevice().isDevice) {
              }

              QuoteDataService.NewQuoteWithParent(parentid, $("#descriptionfornewquote").dxTextArea("instance").option("value"))
                .done(function (response: any) {
                  if (true) {
                    if (GetDevice().isDevice) {
                      window.location.href =
                        window.location.origin +
                        "/quote/?itemid=" +
                        response;
                    } else {
                      theWindow = window.open();
                      theWindow.location =
                        window.location.origin +
                        "/quote/?itemid=" +
                        response;
                    }
                  } else {
                  }
                });
              Description.close();
            },
            icon: "check",
            text: "OK"
          }
        }
      ]
    });
    Description.open();
  }

  ConfirmERP() {
    let theQuote = this;
    let d = $.Deferred();
    let message: any = {};
    message.text = $("<span />").text(`
    Once ERP processing has begun, the quote will be locked. Upon successful or failed processing, 
    the quote will remain locked in Tour De Force. Are you sure you want to process this quote to the ERP system?`);
    message.buttons = $("<div />")
      .append(
        $("<span />")
          .css("margin", "0 5px")
          .append(
            $("<div />").dxButton({
              text: "Yes",
              icon: "check",
              type: "success",
              onClick: function (e: any) {
                let toast = $($(e.element[0]).parents())
                  .find(
                    ".dx-overlay.dx-widget.dx-visibility-change-handler.dx-toast"
                  )
                  .dxToast("instance");
                toast.hide();
                d.resolve(true);
                return;
              }
            })
          )
      )
      .append(
        $("<span />")
          .css("margin", "0 5px")
          .append(
            $("<div />").dxButton({
              text: "No",
              icon: "revert",
              type: "default",
              onClick: function (e: any) {
                let toast = $($(e.element[0]).parents())
                  .find(
                    ".dx-overlay.dx-widget.dx-visibility-change-handler.dx-toast"
                  )
                  .dxToast("instance");
                toast.hide();
                d.resolve(false);
              }
            })
          )
      );
    new Notification({
      type: "warning",
      shading: true,
      displayTime: 2000000,
      message: message
    });
    return d.promise();
  }

  /**
   * @Overrides Form()
   */
  Form() {
    let theQuote = this;

    let d: JQueryDeferred<void> = $.Deferred();

    // Create and add the container to the body.
    let quotecontainer = $("<div />").attr("id", theQuote.QuoteContainerID);

    quotecontainer.css("margin-top", "8px");

    $("#tdfbodycontent").append(quotecontainer);
    /*
				Add the appropriate containers
			*/
    quotecontainer
      .append($("<div id=" + theQuote.ToolbarID + "></div>"))
      .append(
        $("<div />")
          .addClass("tdfitemtabs")
          .attr({ id: theQuote.UpperTabsID })
      )
      .append(
        $("<div />")
          .addClass("tdfitemtabs")
          .attr({ id: theQuote.LowerTabsID })
      )
      .append("<div id='erpwarn' class='alert alert-danger hidden'></div>");

    theQuote.GetQuoteOptions().done(() => {
      /*
				Get the quote specific and non screen designable section. 
				*/
      theQuote.GetQuoteDetails().done(() => {
        /*
		Get the list of normal toolbar items and the list for the quote toolbar
		then remove default items that are not used with quotes and add the quote specific ones.
		*/
        let t = theQuote.ToolbarItems();
        let temp = theQuote.QuoteToolBarItems();
        $.each(t, function (k, v: any) {
          if (
            v &&
            v.options &&
            v.options.text &&
            (v.options.text.toLowerCase().indexOf("save & copy") < 0 &&
              v.options.text.toLowerCase().indexOf("save & close") < 0 &&
              v.options.text.toLowerCase().indexOf("close") < 0 &&
              v.options.text.toLowerCase().indexOf("links") < 0)
          ) {
            temp.push(v);
          } else {
            if (!v.options.text && v.options.icon.indexOf("ellipsis") > -1) {
              temp.push(v);
            }
          }
        });

        theQuote.Toolbar = $("#" + theQuote.ToolbarID)
          .addClass("container-fluid item-toolbar")
          .dxToolbar({
            items: temp
          }).dxToolbar("instance");

        d.resolve();

      });
    });
    return d.promise();
  }
  /**
   * Gets the quote specific toolbar items.
   */
  QuoteToolBarItems() {
    let theQuote = this;

    let revHelper = new QuoteRevisionHelper(theQuote.ItemID);

    return <DevExpress.ui.dxToolbarItemTemplate[]>[

      revHelper.GetButton(theQuote),

      {
        locateInMenu: GetDevice().isDevice ? "always" : "auto",
        location: "after",
        showText: "inMenu",
        options: {
          icon: "email",
          elementAttr: { id: "btnemailquote" },
          hint: "Email the quote",
          text: "Email",
          disabled: !theQuote.QuickStatus && theQuote.LockedForReal,
          visible: true,
          onClick: function (e) {
            let draftrequest = QuoteDataService.EmailDraftsRequest(itemTypes.itemQuote);
            let contactrequest = QuoteDataService.ParentContactsRequest(theQuote.ItemID, itemTypes.itemQuote);
            let contactid: string = "";
            let draftid: string = "";
            let draftselection: Dialog;
            let contactselection: Dialog;

            $.when(
              draftrequest.MakeRequest(),
              contactrequest.MakeRequest()
            ).done(
              (draftresponse: any, contactresponse: any) => {
                if (true) {
                  draftselection = new Dialog({
                    title: "Select a template",
                    id: `email-draft-selection-${theQuote.DomSafeID}`,
                    closable: true,
                    body: $("<div />").dxDataGrid({
                      dataSource: draftresponse,
                      columns: [{ dataField: "Subject", caption: "Draft" }],
                      selection: { mode: "single" },
                      onSelectionChanged(e) {
                        if (
                          e.selectedRowKeys.length &&
                          e.selectedRowKeys[0].DraftID
                        ) {
                          draftid = e.selectedRowKeys[0].DraftID;
                          draftselection.close();
                          contactselection.open();
                        }
                      }
                    }),

                    size: "size-normal"
                  });

                  draftselection.open();
                } else {
                  new Notification({
                    message: draftresponse.Message,
                    type: "error",
                    displayTime: 5000
                  });
                }

                if (true) {
                  contactselection = new Dialog({
                    title: "Select a contact",
                    id: `email-contact-selection-${theQuote.DomSafeID}`,
                    closable: true,
                    body: $("<div />").dxDataGrid({
                      dataSource: contactresponse.Contacts,
                      selection: { mode: "single" },
                      columns: [
                        { dataField: "TDF_Subject", caption: "Name" },
                        { dataField: "TDF_Email", caption: "Email" }
                      ],
                      onSelectionChanged(e) {
                        if (
                          e.selectedRowKeys.length &&
                          e.selectedRowKeys[0]["TDF GUID"]
                        ) {
                          contactid = e.selectedRowKeys[0]["TDF GUID"];
                          contactselection.close();
                          QuoteDataService.EmailBodyForQuote(theQuote.ItemID, contactid, draftid)
                            .done((response: any) => {
                              new EmailGenerator(
                                {
                                  ItemId: theQuote.ItemID,
                                  ItemType: theQuote.ItemType,
                                  iMain: theQuote.iMain
                                },
                                response.Subject,
                                response.Body,
                                e.selectedRowKeys[0]["TDF_Email"] ||
                                response.Address,
                                false
                              );
                            });
                        }
                      }
                    }),
                    buttons: [
                      {
                        widget: "dxButton",
                        toolbar: "bottom",
                        location: "after",
                        options: {
                          id: "template-searchfor-contact",
                          text: "Search for a contact",
                          onClick() {
                            contactselection.close();
                            //  QuoteDataService.SearchSetup(itemTypes.itemContact)
                            //   .done(function (searchsetup) {
                            //     let searchforcontactdialog = new Dialog({
                            //       id: "template-searchfor-contact-dialog",
                            //       body: $(
                            //         "<div id='template-searchfor-contact-container'/>"
                            //       ).append($("<div id='tdfcontextmenu'/>")),
                            //       type: "type-info",
                            //       closable: true,
                            //       title: "Search"
                            //     });
                            //     searchforcontactdialog.open().done(function () {
                            //       searchsetup.Data.SearchTypes = $.grep(
                            //         searchsetup.Data.SearchTypes,
                            //         function (val: any) {
                            //           return $.inArray(val.Type, [5]) > -1;
                            //         }
                            //       );
                            //       //searchsetup.Data.SearchTypes = types;
                            //       searchsetup.Data.SearchContainer =
                            //         "template-searchfor-contact-container";
                            //       searchsetup.Data.SelectedType = 5;
                            //       searchsetup.Data.DisableType = true;
                            //       let search = new Search(searchsetup.Data);
                            //       search.SelectionEvent = function (data) {
                            //         searchforcontactdialog.close();
                            //         contactid =
                            //           data.currentSelectedRowKeys[0]["TDF GUID"];

                            //         QuoteDataService.EmailBodyForQuote(theQuote, contactid, draftid)
                            //           .done((response: any) => {
                            //             new EmailGenerator(
                            //               {
                            //                 ItemId: theQuote.ItemID,
                            //                 ItemType: theQuote.ItemType,
                            //                 iMain: theQuote.iMain
                            //               },
                            //               response.Subject,
                            //               response.Body,
                            //               data.currentSelectedRowKeys[0][
                            //               "EmailAddress1"
                            //               ] || response.Address,
                            //               false
                            //             );
                            //           });

                            //         //new TDFRequest({ url: theQuote.BaseQuotePath + "emailQuote/", data: { contactid: contactid, quoteid: theQuote.ItemID, draftid: draftid } })
                            //         //    .MakeRequest().done((response: any) => {
                            //         //        if (true) {
                            //         //            new Notification({ message: response.Message, type: "success", displayTime: 5000 });
                            //         //        } else {
                            //         //            new Notification({ message: response.Message, type: "error", displayTime: 5000 });
                            //         //        }
                            //         //    });
                            //       };
                            //     });
                            //   });
                          },
                          icon: "search"
                        }
                      }
                    ],
                    size: "size-normal"
                  });
                } else {
                  new Notification({
                    message: contactresponse.Message,
                    type: "error",
                    displayTime: 5000
                  });
                }
              }
            );
          }
        },
        widget: "dxButton"
      },

      {
        locateInMenu: GetDevice().isDevice ? "always" : "auto",
        location: "after",
        showText: "inMenu",
        options: {
          icon: "q icon-erp",
          elementAttr: { id: "btnerp" },
          hint: "ERP Sync Options",
          text: "ERP Sync",
          disabled:
            !theQuote.QuickStatus ||
            (theQuote.LockedForReal && !theQuote.QuickStatus),
          visible: true,
          onClick: function (e) {
            let items = [
              {
                text: "Send to Quote",
                disabled: !theQuote.ERPOption.QuoteToQuoteSyncEnabled,
                onClick: function (e) {
                  $.when(theQuote.ConfirmERP()).done(proceed => {
                    if (proceed) {
                      QuoteDataService.ProcessERP(theQuote.ItemID, EnumQuoteToOrderActions.SendToQuote)
                        .done(function (response: any) {
                          if (true) {
                            new Notification({
                              message: response.Message,
                              type: "success"
                            });
                            theQuote.QuoteDetails = null;
                            theQuote.CheckERPStatus();
                          } else {
                            new Notification({
                              message: response.Message,
                              type: "error",
                              displayTime: 10000
                            });
                          }
                        });
                    }
                  });
                }
              },
              {
                text: "Send to Order",
                disabled: !theQuote.ERPOption.QuoteToOrderSyncEnabled,
                onClick: function (e) {
                  $.when(theQuote.ConfirmERP()).done(proceed => {
                    if (proceed) {
                      // new TDFRequest({
                      //   url: theQuote.BaseQuotePath + "processERP/",
                      //   data: {
                      //     quoteid: theQuote.ItemID,
                      //     action: EnumQuoteToOrderActions.SendToOrder
                      //   },
                      //   type: "POST"
                      // })
                      //   .MakeRequest()
                      QuoteDataService.ProcessERP(theQuote.ItemID, EnumQuoteToOrderActions.SendToOrder)
                        .done(function (response: any) {
                          if (true) {
                            new Notification({
                              message: response.Message,
                              type: "success"
                            });
                            theQuote.QuoteDetails = null;
                            theQuote.CheckERPStatus();
                          } else {
                            new Notification({
                              message: response.Message,
                              type: "error",
                              displayTime: 10000
                            });
                          }
                        });
                    }
                  });
                }
              },
              {
                text: "Update Quote from ERP",
                disabled: !theQuote.ERPOption.UpdateQuoteFromERPEnabled,
                onClick: function (e) {
                  $.when(theQuote.ConfirmERP()).done(proceed => {
                    if (proceed) {
                      // new TDFRequest({
                      //   url: theQuote.BaseQuotePath + "processERP/",
                      //   data: {
                      //     quoteid: theQuote.ItemID,
                      //     action: EnumQuoteToOrderActions.UpdateQuoteFromERP
                      //   },
                      //   type: "POST"
                      // })
                      //   .MakeRequest()
                      QuoteDataService.ProcessERP(theQuote.ItemID, EnumQuoteToOrderActions.UpdateQuoteFromERP)
                        .done(function (response: any) {
                          if (true) {
                            new Notification({
                              message: response.Message,
                              type: "success"
                            });
                            theQuote.QuoteDetails = null;
                            theQuote.CheckERPStatus();
                          } else {
                            new Notification({
                              message: response.Message,
                              type: "error",
                              displayTime: 10000
                            });
                          }
                        });
                    }
                  });
                }
              }
            ];

            if (!$("#erpactionsheet").length) {
              let act = $("<div id='erpactionsheet'/>")
                .dxActionSheet({
                  dataSource: items,
                  target: e.element,
                  usePopover: true,
                  visible: false,
                  showCancelButton: true,
                  showTitle: false
                })
                .dxActionSheet("instance");

              $(e.element).append(act.element());
              act.toggle(!act.option("visible"));
            } else {
              let act = $("#erpactionsheet").dxActionSheet("instance");
              act.toggle(!act.option("visible"));
            }
          }
        },
        widget: "dxButton"
      },

      {
        locateInMenu: GetDevice().isDevice ? "always" : "auto",
        location: "after",
        showText: "inMenu",
        options: {
          icon: "q icon-newfile",
          elementAttr: { id: "btnnewdoc" },
          hint: "Document Generation",
          text: "Document",
          visible: true,
          disabled: !theQuote.QuickStatus && theQuote.LockedForReal,
          onClick: function (e) {
            new QuoteDocumentHelper(theQuote);
          }
        },
        widget: "dxButton"
      },
      {
        locateInMenu: GetDevice().isDevice ? "always" : "auto",
        location: "after",
        showText: "always",
        options: {
          icon: "info",
          elementAttr: { id: "audit" },
          hint: "View Audit Trail",
          text: "Audit Trail",
          visible: true,
          disabled: false, // !theQuote.QuickStatus && theQuote.LockedForReal,
          onClick: function (e) {
            theQuote.GetAuditTrail();
          }
        },
        widget: "dxButton"
      },
      {
        locateInMenu: GetDevice().isDevice ? "always" : "auto",
        location: "after",
        showText: "always",
        options: {
          icon: "fa fa-clone",
          elementAttr: { id: "btncopy" },
          hint: "Save and Create a Copy of this Quote",
          text: "Copy",
          visible: true,
          onClick: function (e) {

            // User click Copy button.

            let copyAndForward = (options: QuoteDataService.QuoteCopyOptions) => {
              QuoteDataService.CopyQuote(theQuote.ItemID, options)
                .done(function (response: any) {
                  window.location.href =
                    window.location.origin +
                    "/quote/?itemid=" +
                    response;
                });
            }

            let getCopyOptions = (copyOption: QuoteDataService.QuoteCopyOptions) => {

              let Description = new Dialog({
                id: "newquoteDescription",
                closable: true,
                body: $("<div id='getdesccontent'/>")
                  .append(
                    $("<label/>").text("Please enter a description.")
                  )
                  .append(
                    $("<div />").dxTextArea({
                      inputAttr: { id: "descriptionfornewquote" },
                      value:
                        "Copy of (" +
                        theQuote.QuoteDetails.Description +
                        ") " +
                        Globalize.formatDate(new Date(), {
                          datetime: "short"
                        })
                    })
                  ),
                size: "size-normal",
                type: "type-warning",
                title: "New Quote Description",
                buttons: [
                  {
                    widget: "dxButton",
                    options: {
                      id: "",
                      type: "success",
                      onClick: function () {
                        copyOption.newQuoteDescription = $("#descriptionfornewquote").val().toString()
                        copyAndForward(copyOption);
                        Description.close();
                      },
                      icon: "check",
                      text: "OK"
                    }
                  }
                ]
              });
              Description.open();
              return;

            }

            let copyUnderCurrent = () => {

              let options: QuoteDataService.QuoteCopyOptions = {
                copyOption: CopyQuoteOptionsEnum.CopySameParent,
                newQuoteDescription: ""
              }

              getCopyOptions(options)
            }

            let copyUnderExisting = () => {
              // what?
              ValidParents(itemTypes.itemQuote)
                .done(function (response: itemTypes[]) {

                  let srf = new SearchRecFav(itemTypes.itemAccount, response, itemTypes.itemAccount, false);
                  srf.GetSRFDialog().done((selected) => {

                    let row: any = <any[]>selected[0];

                    let options: QuoteDataService.QuoteCopyOptions = {
                      copyOption: CopyQuoteOptionsEnum.CopyOtherExistingParent,
                      newQuoteDescription: "",
                      newQuoteParentID: row["TDF GUID"],
                      newQuoteParentType: <itemTypes>row.ItemType
                    }

                    getCopyOptions(options);

                  });


                });
            }

            let copyUnderNewAcct = () => {

              let selector = new PackageSelection();
              selector.DisplayPanel();
              selector.OnCommit = function (response) {

                // TODO: Refactor this a bit so that it since it shares a big chunk of code with copyUnderCurrent.

                let folder = response["level" + selector.ActiveFolderLevel];
                let str = Object.keys(folder)[0];
                folder = folder[str];

                let Description = new Dialog({
                  hideTitle: true,
                  id: "newquoteDescription",
                  body: $("<div id='getdesccontent'/>")
                    .append(
                      $("<label/>").text("Please enter a description.")
                    )
                    .append(
                      $("<div />").dxTextArea({
                        //3/5/2010 4:35:12 PM - Abec Inc -
                        inputAttr: { id: "descriptionfornewquote" },
                        value:
                          "Copy of (" +
                          theQuote.QuoteDetails.Description +
                          ") " +
                          Globalize.formatDate(new Date(), {
                            datetime: "short"
                          })
                      })
                    )
                    .append(
                      $("<label/>").text(
                        "Please enter a name for the new Account."
                      ).css("margin-top", "10px")
                    )
                    .append(
                      $("<div />").dxTextBox({
                        //3/5/2010 4:35:12 PM - Abec Inc -
                        inputAttr: { id: "namefornewaccount" },
                        value:
                          " (Account Created for new QuoteCopy) " +
                          Globalize.formatDate(new Date(), {
                            datetime: "short"
                          })
                      })
                    ),
                  size: "size-normal",
                  type: "type-warning",
                  closable: true,
                  title: "New Quote Description",
                  buttons: [
                    {
                      widget: "dxButton",
                      toolbar: "bottom",
                      location: "after",
                      text: "Cancel",
                      options: {
                        onClick: (e) => {
                          Description.close();
                        }
                      }
                    },
                    {
                      widget: "dxButton",
                      toolbar: "bottom",
                      location: "after",
                      options: <DevExpress.ui.dxButtonOptions>{
                        id: "",
                        type: "success",
                        onClick: function (e) {

                          let options: QuoteDataService.QuoteCopyOptions = {
                            copyOption: CopyQuoteOptionsEnum.CopyNewParent,
                            newQuoteDescription: $("#descriptionfornewquote").val().toString(),
                            newParentAccountName: $("#namefornewaccount").val().toString(),
                            newQuoteNewParentAccountPackage: folder.folderid,
                            newQuoteParentType: itemTypes.itemAccount
                          }

                          copyAndForward(options);

                          Description.close();
                        },
                        icon: "check",
                        text: "OK"
                      }
                    }
                  ]
                });
                Description.open();
              };

              return;

            }

            let prompt = custom({
              showTitle: true,
              title: "Copy Quote:",
              dragEnabled: false,
              message: `Under what parent should the ${theQuote.DisplayName} be copied?`,
              buttons: [
                {
                  text: `Same Parent`,
                  onClick: (e) => {
                    copyUnderCurrent();
                  }

                },
                {
                  text: "Different Parent",
                  onClick: (e) => {
                    copyUnderExisting();
                  }
                },
                {
                  text: "New Account",
                  onClick: (e) => {
                    copyUnderNewAcct();
                  }

                }
              ]
            });

            prompt.show();

          }
        },
        widget: "dxButton"
      }
    ];
  }

  private GetAuditTrail() {
    let theQuote = this;
    QuoteDataService.QuoteAuditTrail(theQuote.ItemID)
      .done(function (response: any) {
        if (response) {
          // if (response.length) {
          new Dialog(
            {
              title: "Audit Info",
              id: "quote-audit",
              closable: true,
              size: "size-large",
              type: "type-warning",
              body: $("<div/>").dxDataGrid({
                dataSource: response,
                noDataText: "No Audit Information Found",
                columns: [
                  { dataField: "AdditionalDetails", visible: false },
                  { dataField: "ID", visible: false },
                  {
                    dataField: "Status",
                    visible: true,
                    visibleIndex: 0,
                    cellTemplate(el, info) {
                      info.value === "Successful"
                        ? el.addClass("success").text(info.value)
                        : el.addClass("danger").text(info.value);
                    }
                  },
                  {
                    dataField: "StatusDetails",
                    visible: false,
                    visibleIndex: 2
                  },
                  {
                    dataField: "StatusTime",
                    visible: true,
                    dataType: "date",
                    visibleIndex: 1,
                    caption: "Date"
                  }
                ],
                masterDetail: {
                  enabled: true,
                  template(el, info) {
                    $("<pre style='color:inherit;background-color:inherit;'/>")
                      .text(info.data["StatusDetails"])
                      .appendTo(el);
                  }
                }
              })
            },
            null,
            true,
            true
          ).open();
          //}
        } else {
          new Notification({ message: response.Message, type: "error" });
        }
      });
  }

  /**
   * Gets the quote specific details section.
   */
  GetQuoteDetails() {
    let theQuote = this;
    let d = $.Deferred();
    if (theQuote.QuoteDetails) {
      return d.promise(d.resolve);
    }
    QuoteDataService.QuoteDetails(theQuote.ItemID)
      .done(function (response: any) {
        theQuote.QuoteDetails = response.Details.Details;
        if (
          theQuote.QuoteOptions.QuoteContractOptions.UseContractIntegration &&
          response.Details.Contracts
        ) {
          theQuote.Contracts = response.Details.Contracts;
        }
        theQuote.CheckERPStatus();
        theQuote.StatusOptions = theQuote.GetStatusOptions(
          response.StatusOptions
        );
        theQuote.RenderControlGroups().done(() => {
          theQuote.RenderDetailsSection();
        });
        d.resolve();
      });
    return d.promise();
  }

  private GetStatusOptions(options): any {
    let theQuote = this;
    switch (theQuote.QuoteDetails.Status) {
      case EnumQuoteStatus.Inactive:
      case EnumQuoteStatus.Lost:
      case EnumQuoteStatus.ManualEntry:
      case EnumQuoteStatus.Closed:
      case EnumQuoteStatus.Open:
      case EnumQuoteStatus.OrderProcessed:
        if (theQuote.QuoteDetails.ImportedByBISync) {
          options.push({
            ItemKey: EnumQuoteStatus.ImportedByBISync,
            ItemText: "ImportedByBISync"
          });
        }
        return options;
      case EnumQuoteStatus.ImportedByBISync:
        options.push({
          ItemKey: EnumQuoteStatus.ImportedByBISync,
          ItemText: "ImportedByBISync"
        });
        return options;
      case EnumQuoteStatus.ProcessedToERPOrderSuccessfully:
      case EnumQuoteStatus.ProcessedToERPPriceContractSuccessfully:
      case EnumQuoteStatus.ProcessedToERPQuoteSuccessfully:
        let display;
        switch (theQuote.QuoteDetails.Status) {
          case EnumQuoteStatus.ProcessedToERPOrderSuccessfully:
            display = "Order";
            break;
          case EnumQuoteStatus.ProcessedToERPQuoteSuccessfully:
            display = "Quote";
            break;
          case EnumQuoteStatus.ProcessedToERPPriceContractSuccessfully:
            display = "Contract";
            break;
        }
        return [
          {
            ItemKey: theQuote.QuoteDetails.Status,
            ItemText: `Processed to ERP (${display})`
          }
        ];
    }

    return options;
  }

  GetLockedMessage() {
    let theQuote = this;
    let message = "";
    let refresh = "  .....    Refresh <kbd>F5</kbd> to update. ";
    switch (theQuote.QuoteDetails.ERPProcessStatus) {
      case EnumERPProcessStatus.Processed:
        message = `The Quote has been successfully processed to the ERP System.`;
        break;
      case EnumERPProcessStatus.ProcessedWithErrors:
        message = `Errors were encountered while processing the Quote to the ERP System. ${refresh}`;
        break;
      case EnumERPProcessStatus.Pending:
        message = `ERP Processing for this Quote is pending. ${refresh}`;
        break; // message = "This Quote is locked."
    }

    if (
      !message &&
      theQuote.QuoteDetails.Status === EnumQuoteStatus.ImportedByBISync
    ) {
      message = "This quote was imported by BI Quote Sync and is locked.";
    }

    return message;
  }

  CheckERPStatus() {
    let theQuote = this;

    let message = theQuote.GetLockedMessage();

    if (
      theQuote.QuoteDetails.ERPProcessStatus === EnumERPProcessStatus.Processed
    ) {
      $("#erpwarn")
        .removeClass("hidden")
        .removeClass("alert-danger")
        .addClass("alert-success")
        .append(
          `<a href="javaScript:void(0)" id="audit">Quote Locked:</a>  <span>${message}</span>`
        );
      $("#audit").on("click", () => {
        theQuote.GetAuditTrail();
      });
    } else {
      if (message) {
        $("#erpwarn")
          .removeClass("hidden")
          .append(
            `<a href="javaScript:void(0)" id="audit">Quote Locked:</a> <span>${message}</span>`
          );
        $("#audit").on("click", () => {
          theQuote.GetAuditTrail();
        });
      } else {
        if (!$("#erpwarn").hasClass("hidden")) {
          $("#erpwarn").addClass("hidden");
        }
      }
    }
  }

  /**
   * Render the quote specific details section.
   */
  RenderDetailsSection() {
    let theQuote = this;

    let container = $(
      "#" + theQuote.TypeName + "-Quote_Detail-" + DomSafeID(theQuote.ItemID)
    );

    let form: DevExpress.ui.dxForm;
    let opts: DevExpress.ui.dxFormOptions = {
      formData: theQuote.QuoteDetails,
      items: [],
      colCountByScreen: {
        lg: 4,
        md: 3,
        sm: 2,
        xs: 1
      }
    };
    let item: DevExpress.ui.dxFormSimpleItem;

    $.each(theQuote.QuoteDetails, function (key: string, val: any) {

      item = QuoteDetailControlTypes[key];

      if (!item) {
        // Note every property is guaranteed
        console.log(`No entry in QuoteDetailControlTypes for key ${key}. Add one if needed.`);
        return;
      }

      item.dataField = key;

      if (key === "ClosedDate") {
        let temp = new Date(val);

        if (Math.abs(moment(temp).diff(moment(), "years")) > 100) {
          theQuote.QuoteDetails[key] = null;
          item.helpText = "This date has not been set.";
          item.editorOptions = {
            disabled: true
          };
        }
      }

      if (!item.editorOptions) {
        item.editorOptions = {
          disabled:
            theQuote.QuoteDetails.Status === 0 ||
            key === "UniqueID" ||
            (!theQuote.QuickStatus && theQuote.LockedForReal),
          valueChangeEvent: "blur",
          onValueChanged: function (e) {
            if (key === "ShipToID") return;
            e.field = key;
            if (key.toLowerCase().trim() === "status") {
              theQuote.ChangeQuoteStatus(e);
            } else {
              theQuote.SetDirty(e);
            }
          }
        };
      }
      if (item.dataField === "Contract") {
        item.editorOptions["dataSource"] = theQuote.Contracts;
        item.editorOptions["displayExpr"] = "Display";
        item.editorOptions["valueExpr"] = "Value";
        item.visible =
          theQuote.QuoteOptions.QuoteContractOptions.UseContractIntegration;
        item.editorOptions["onValueChanged"] = event => {
          let message: any = {};
          if (window["ISTDFResetValue"]) {
            delete window["ISTDFResetValue"];
            return;
          }
          let Contractid = event.value;
          message.text = $("<span />").text(
            "Changing the Contract will affect the contract pricing on the quote lines, and requires a pricing update call.Do you wish to perform the pricing update now, or Cancel and perform it later ? "
          );
          message.buttons = $("<div />")
            .append(
              $("<span />")
                .css("margin", "0 5px")
                .append(
                  $("<div />").dxButton({
                    text: "Ok",
                    icon: "check",
                    type: "success",
                    onClick: function (e: any) {
                      let toast = $($(e.element[0]).parents())
                        .find(
                          ".dx-overlay.dx-widget.dx-visibility-change-handler.dx-toast"
                        )
                        .dxToast("instance");
                      toast.hide();
                      theQuote.ChangeQuoteContract(Contractid);
                      return;
                    }
                  })
                )
            )
            .append(
              $("<span />")
                .css("margin", "0 5px")
                .append(
                  $("<div />").dxButton({
                    text: "Cancel",
                    icon: "revert",
                    type: "danger",
                    onClick: function (e: any) {
                      let toast = $($(e.element[0]).parents())
                        .find(
                          ".dx-overlay.dx-widget.dx-visibility-change-handler.dx-toast"
                        )
                        .dxToast("instance");
                      toast.hide();
                      window["ISTDFResetValue"] = true;
                      event.component.option("value", event.previousValue);
                      return;
                    }
                  })
                )
            );
          new Notification({
            type: "warning",
            shading: true,
            displayTime: 2000000,
            message: message
          });
        };
      }

      if (item.dataField === "Status") {
        item.editorOptions["dataSource"] = theQuote.StatusOptions;
        item.editorOptions["displayExpr"] = "ItemText";
        item.editorOptions["valueExpr"] = "ItemKey";
      }

      if (key === "ShipToID" && theQuote.QuoteOptions.PossibleShipTos.length) {
        item.editorOptions["dataSource"] =
          theQuote.QuoteOptions.PossibleShipTos;
        item.editorOptions["displayExpr"] = function (item) {
          return (
            item &&
            item.Name + " " + item.BusinessCity + " <" + item.BSIShipToID + ">"
          );
        };
        item.editorOptions["valueExpr"] = "BSIShipToID";

        item.editorOptions["contentTemplate"] = function (e) {
          let value = e.component.option("value");
          let $dataGrid = $("<div>").dxDataGrid({
            dataSource: e.component.option("dataSource"),
            columns: [
              { dataField: "BSIShipToID" },
              { dataField: "BusinessCity" },
              { dataField: "BusinessState" },
              { dataField: "Name" }
            ],
            hoverStateEnabled: true,
            paging: { enabled: true, pageSize: 10 },
            filterRow: { visible: true },
            scrolling: { mode: "infinite" },
            height: 265,
            selection: { mode: "single" },
            selectedRowKeys: $.grep(
              theQuote.QuoteOptions.PossibleShipTos,
              (v: any, k) => {
                return v.BSIShipToID === value;
              }
            ),
            onSelectionChanged: function (selectedItems) {
              if (selectedItems.selectedRowKeys.length) {
                e.component.option(
                  "value",
                  selectedItems.selectedRowKeys[0].BSIShipToID
                );
                theQuote.SetDirty({
                  field: "ShipToID",
                  value: selectedItems.selectedRowKeys[0].BSIShipToID
                });
              }
            }
          });

          return $dataGrid;
        };
      }
      $.each(
        theQuote.QuoteOptions.GeneralOptions.HiddenQuoteHeaderFields,
        (i, v) => {
          //TODO: need to figure out how to use the enum for hidden quote header fields because currently
          //BIQuoteNo can be hidden but it is mismatched, the datafield is BSIQuoteNo but the hidden header value comes back as BIQuoteNumber
          if (
            v.ID === HidableQuoteHeaderFieldsEnum.BIQuoteNumber &&
            QuoteDetailControlTypes[key] === QuoteDetailControlTypes.BSIQuoteNo
          ) {
            item.visible = false;
          }
          if (
            v.ID === HidableQuoteHeaderFieldsEnum.ClosedOn &&
            QuoteDetailControlTypes[key] === QuoteDetailControlTypes.ClosedDate
          ) {
            item.visible = false;
          }
          if (
            v.ID === HidableQuoteHeaderFieldsEnum.OrderNumber &&
            QuoteDetailControlTypes[key] === QuoteDetailControlTypes.OrderNumber
          ) {
            item.visible = false;
          }
          if (
            v.ID === HidableQuoteHeaderFieldsEnum.ShipTo &&
            QuoteDetailControlTypes[key] === QuoteDetailControlTypes.ShipToID
          ) {
            item.visible = false;
          }
        }
      );

      opts.items.push(item);
    });
    let div = $("<div />").appendTo(container);
    form = div.dxForm(opts).dxForm("instance");

    // Revision Info:

    if (theQuote.QuoteDetails.IsPreviousRevision) {

      let header = $(`#${this.HeaderID}`)
      if (header.length == 1) {

        let warning = $("<div class='badge-warning' />").
          html(`This is a previous revision - <a href='?itemid=${theQuote.QuoteDetails.MostRecentRevision}'>click here</a> to jump to the latest revision.`).
          appendTo(header);
      }

    }

    //container.append(form.element())
  }

  ChangeQuoteContract(contractid) {
    let theQuote = this;
    QuoteDataService.ChangeContract(theQuote.ItemID, contractid)
      .done(response => {

        $.when(theQuote.ConfirmERP()).done(proceed => {
          if (proceed) {

            QuoteDataService.ProcessERP(theQuote.ItemID, EnumQuoteToOrderActions.UpdateQuoteFromERP)
              .done(function (response: any) {

                new Notification({
                  message: response.Message,
                  type: "success"
                });
                theQuote.QuoteDetails = null;
                theQuote.CheckERPStatus();

              }).catch((err) => {

                new Notification({
                  message: err.Message,
                  type: "error",
                  displayTime: 10000
                });

              });
          }
        });

      });
  }

  /**
   * Changes the status of the quote and closes the quote if needed.
   * @param e {Event} e.value will be the new status of the quote.
   */
  ChangeQuoteStatus(e) {
    let theQuote = this;
    if (e.value === 0) {
      let valchange = e.component.option("onValueChanged");
      e.component.option("onValueChanged", null);
      theQuote.CloseQuote().done(closed => {
        if (closed) {
          window.location.reload();
        } else {
          e.component.option("value", e.previousValue);
          e.component.option("onValueChanged", valchange);
        }
      });
    } else {
      theQuote.SetDirty(e);
    }
  }

  /**
   * Set the quote status to closed.
   */
  CloseQuote() {
    let theQuote = this;
    let d = $.Deferred();
    let message: any = {};
    message.text = $("<span />").text(
      "Closing this Quote will cause it to become locked, preventing any future changes. Are you sure you want to lock this Quote?"
    );
    message.buttons = $("<div />")
      .append(
        $("<span />")
          .css("margin", "0 5px")
          .append(
            $("<div />").dxButton({
              text: "Yes",
              icon: "save",
              type: "success",
              onClick: function (e: any) {
                let toast = $($(e.element[0]).parents())
                  .find(
                    ".dx-overlay.dx-widget.dx-visibility-change-handler.dx-toast"
                  )
                  .dxToast("instance");
                toast.hide();
                QuoteDataService.CloseQuote(theQuote.ItemID)
                  .done(function () {
                    d.resolve(true);
                  });
                return;
              }
            })
          )
      )
      .append(
        $("<span />")
          .css("margin", "0 5px")
          .append(
            $("<div />").dxButton({
              text: "Cancel",
              icon: "revert",
              type: "danger",
              onClick: function (e: any) {
                let toast = $($(e.element[0]).parents())
                  .find(
                    ".dx-overlay.dx-widget.dx-visibility-change-handler.dx-toast"
                  )
                  .dxToast("instance");
                toast.hide();
                d.resolve(false);
                return;
              }
            })
          )
      );
    new Notification({
      type: "warning",
      shading: true,
      displayTime: 2000000,
      message: message
    });
    return d.promise();
  }

  /**
   * We need to override the base's set dirty function because of the quote specific details section.
   * @Overrides SetDirty()
   * @param control
   */
  SetDirty(control) {
    let theQuote = this;
    if (theQuote.QuoteDetails) {
      if (theQuote.QuoteDetails.Status !== 0) {
        if (control.hasOwnProperty("DataValue")) {
          super.SetDirty(control);
        } else {
          if (theQuote.QuoteDetails.hasOwnProperty(control.field)) {
            theQuote.DirtyDetails[control.field] = control.value;
            theQuote.DetailsDirty = true;

            theQuote.ChangeState();
          }
        }
      } else {
        new Notification({
          message: "This Quote is currently locked.",
          displayTime: 2000,
          type: "warning"
        });
      }
    } else {
      if (
        control.hasOwnProperty("DataValue") &&
        window.location.href.indexOf("quotenew") > -1
      ) {
        super.SetDirty(control);
      }
    }

    return theQuote;
  }

  /**
   * We need to override the Save method because of the quote specific detials section.
   * @Overrides Save()
   */
  Save() {
    let theQuote = this;
    let dfd = $.Deferred();
    if (theQuote.DirtyControls && theQuote.DirtyControls.length) {
      super.Save().done(function (response) {
        dfd.resolve(theQuote);
      });
    }
    if (theQuote.DetailsDirty) {
      theQuote.SaveDetails();
    }

    if (!theQuote.Dirty && !theQuote.DetailsDirty) {
      new Notification({
        message: "No values to save.",
        type: "warning",
        displayTime: 1500,
        shading: false,
        position: { my: "bottom" }
      });
    }
    return dfd.promise(theQuote);
  }

  /**
   * Saves the quote Specific Detials section.
   */
  SaveDetails() {
    let theQuote = this;
    /*     new TDFRequest({
      url: theQuote.BaseQuotePath + theQuote.SaveDetailsPath,
      data: { details: theQuote.DirtyDetails, quoteid: theQuote.ItemID },
      type: "POST",
      contentType: "application/json"
    }).MakeRequest() */
    QuoteDataService.SaveQuoteDetails(theQuote.ItemID, theQuote.DirtyDetails)
      .done(function (response) {
        new Notification({
          message: "Success ",
          type: "success",
          displayTime: 1500,
          shading: false,
          position: { my: "top" }
        });
        theQuote.DetailsDirty = false;
        theQuote.DirtyDetails = {};
        theQuote.ChangeState();
        theQuote.CheckERPStatus();
      });
    return theQuote;
  }

  /**
   * Get the initial options for the quote
   * Sets the quotes QuoteOptions, ERPOption, SegementsDefined, and PointBooksEnabled
   */
  GetQuoteOptions() {
    let theQuote = this;
    let d = $.Deferred();
    // new TDFRequest({
    //   url: theQuote.BaseQuotePath + theQuote.QuoteOptionsPath,
    //   data: { quoteid: theQuote.ItemID },
    //   type: "GET"
    // })
    //   .MakeRequest()
    QuoteDataService.QuoteOptions(theQuote.ItemID)
      .done(function (response) {
        theQuote.EnableSPA = response.EnableSPA;
        theQuote.QuoteOptions = response.QuoteOptions;
        theQuote.QuoteOptions.PossibleShipTos = response.PossibleShipTos;
        theQuote.ERPOption = response.ERPOption;
        theQuote.SegmentsDefined = response.SegmentsDefined;
        theQuote.PointBooksEnabled = response.PointBooks;

        d.resolve();
      })
      .then(function () {
        if (theQuote.PointBooksEnabled) {
          theQuote.PointbookHelper = new PointbookHelper(theQuote);
        }
        theQuote.LineItemHelper = new QuoteLineHelper(theQuote);
        theQuote.SegmentHelper = new QuoteSegmentHelper(
          theQuote
        ).RenderSegments();
        theQuote.ExtendedTotalHelper = new QuoteTotalsHelper(
          theQuote
        ).RenderExtPrev();
      });
    return d.promise();
  }

  /**
   * Change the color of the toolbar to indicate to the user that there are unsaved values.
   */
  ChangeState() {
    let theQuote = this;
    if (theQuote.Dirty || theQuote.DetailsDirty) {
      $("#" + theQuote.ToolbarID).addClass("tdfdirty");
      window["TDFDirty"] = true;
    } else {
      $("#" + theQuote.ToolbarID).removeClass("tdfdirty");
    }
    return theQuote;
  }

  /**
   * Render the product search for the current quote line grid\segment.
   * @param e
   */
  RenderProductSearch(e) {
    let theQuote = this;
    theQuote.ProductSearchHelper = new ProductSearchHelper(theQuote);

    let d = $.Deferred();
    theQuote.ProductSearchHelper.RenderProductSearch(e);

    return d.promise();
  }

  RefreshLinesAndTotals() {
    let theQuote = this;
		if (true) { // (theQuote.SegmentsDefined) {

			theQuote.ClearLineAndTotalData();
      theQuote.SegmentHelper.RenderSegments();
      theQuote.ExtendedTotalHelper.GetExtendedTotal().done(function () {
        theQuote.ExtendedTotalHelper.RenderExtPrev();
			});

    } else {
      theQuote.ClearLineAndTotalData();

			theQuote.LineItemHelper.GetQuoteProducts(true).done(function () {
				theQuote.ExtendedTotalHelper.GetExtendedTotal().done(function () {
					theQuote.ExtendedTotalHelper.RenderExtPrev();
				});
			});
      
    }
  }

  private ClearLineAndTotalData() {
    let theQuote = this;
    theQuote.LineItemHelper.QuoteProducts = null;
    theQuote.ExtendedTotalHelper.ExtendedTotalData = null;
    theQuote.SegmentHelper.Segments = null;
  }

  //#endregion

  public ShouldRenderLinkedItemsTab() {
    return false;
  }



  public CanSave(): boolean {
    // note override.
    let theQuote = this;
    return (super.CanSave() && (theQuote.QuoteDetails.IsPreviousRevision === false))

  }


  //#region Events
  /**
   * Respond to changes in properties that would affect the Quote's totals
   * @param eventArgs the arguments passed to the original event handler.
   * @param theQuote the object assigned to the value of this might not be the quote... always pass it here to be sure.
   */
  TotalsAffected(eventArgs: any, theQuote: Quote) { }

  //#endregion
}


export class QuoteSegmentHelper {
  Segments: Array<ISegment>;
  private _Quote: Quote;
  SegmentObj: DevExpress.ui.dxAccordion;
  private readonly QuoteSegmentContainerID: string = "tdfquotesegmentcontainer";

  constructor(quote: Quote) {
    let segmenthelper = this;
    segmenthelper._Quote = quote;
  }

  RenderSegments() {
    let segmenthelper = this;

    $("#" + segmenthelper._Quote.QuoteContainerID).append(
      $("<div id='" + segmenthelper.QuoteSegmentContainerID + "' />")
    );
    let dataSource: any = [];

    let options: DevExpress.ui.dxAccordionOptions = {
      selectedIndex: -1,
      collapsible: true,
      multiple: true,
      keyExpr: "title",
      onItemTitleClick(e) {
        let currentStickySetting = GetPreference(
          "Web_Quote_Sticky_Sections",
          "Web_Quote"
        );
        let sectionspref: string[];
        let currentTitle: string = e.itemData.title;
        if (currentStickySetting) {
          if (currentStickySetting.search(currentTitle) > -1) {
            // remove current
            currentStickySetting = currentStickySetting.replace(
              currentTitle,
              ""
            );
            sectionspref = currentStickySetting.split(",").filter(v => {
              return v;
            });
          } else {
            //add current
            sectionspref = currentStickySetting.split(",").filter(v => {
              return v;
            });
            sectionspref.push(currentTitle);
          }

          Preferences.SetPreference(
            "Web_Quote_Sticky_Sections",
            sectionspref.join(","),
            "Web_Quote"
          );
        } else {
          Preferences.SetPreference(
            "Web_Quote_Sticky_Sections",
            currentTitle,
            "Web_Quote"
          );
        }
      },
      itemTitleTemplate: function (data, index, element: JQuery) {
        if (data.action === "GetExtendedTotal") {
          if (segmenthelper._Quote.ExtendedTotalHelper.ExtendedTotalData) {
            let extprev = $("<div />").addClass("quoteExtTotalPreview");
            element.append(extprev);
            segmenthelper._Quote.ExtendedTotalHelper.RenderExtPrev();
          }
        } else {
          element.append($("<label />").text(data.title));
        }
      }
    };
    if (segmenthelper._Quote.SegmentsDefined) {

      QuoteDataService.ProductSegments()
        .done(function (r2) {
          if (r2 && r2.length) {
            segmenthelper.Segments = r2;


            //segmenthelper.Segments.sort(function (a, b) {
            //  if (a.Title < b.Title) return 1;
            //  if (a.Title > b.Title) return -1;
            //  return 0;
            //});
            $.each(segmenthelper.Segments, function (k, v) {
              dataSource.push({
                title: v.Title,
                action: "LoadSegment",
                SegmentID: v.SegmentID,
                container: "segmentcontainer-_-" + v.Title.replace(' ', ''),
                content: "segmentcontent-_-" + v.Title.replace(' ', ''),
                toolbar: "segmentcontainer-_-" + v.Title.replace(' ', ''),
                template: function (data, index, element) {
                  element.append(
                    $("<div id='" + data.container + "'/>").append(
                      $("<div />")
                        .attr({ id: data.content })
                        .append($("<div />").attr("id", data.toolbar))
                    )
                  );

                  segmenthelper.LoadSegment(
                    data.SegmentID,
                    data.container,
                    data.content,
                    data.toolbar
                  );
                }
              });
              if (
                Preferences.GetPreference(
                  "Web_Quote_Sticky_Sections",
                  "Web_Quote"
                ).indexOf(v.Title) > -1
              ) {
                if (!options.selectedItemKeys) {
                  options.selectedItemKeys = [v.Title];
                } else {
                  options.selectedItemKeys.push(v.Title);
                }
              }
            });

            options.dataSource = dataSource;
            segmenthelper.SegmentObj = $(
              "#" + segmenthelper.QuoteSegmentContainerID
            )
              .dxAccordion(options)
              .dxAccordion("instance");
          }
        });
    } else {
      dataSource.unshift({
        title: "Line Items",
        action: "LoadProductGrid",
        container: segmenthelper._Quote.LineItemHelper.QuoteLineGridContainer,
        content: segmenthelper._Quote.LineItemHelper.QuoteLineGridID,
        toolbar: segmenthelper._Quote.LineItemHelper.QuoteLineGridToolbarID,
        template: function (data, index, element) {
          element.append(
            $("<div id='" + data.container + "'/>").append(
              $("<div />")
                .attr({ id: data.content })
                .append($("<div />").attr("id", data.toolbar))
            )
          );
          segmenthelper._Quote.LineItemHelper.GetSectionData(data.action);
        }
      });

      if (
        Preferences.GetPreference(
          "Web_Quote_Sticky_Sections",
          "Web_Quote"
        ).indexOf("Line Items") > -1
      ) {
        if (!options.selectedItemKeys) {
          options.selectedItemKeys = ["Line Items"];
        } else {
          options.selectedItemKeys.push("Line Items");
        }
      }
      options.dataSource = dataSource;
      segmenthelper.SegmentObj = $("#" + segmenthelper.QuoteSegmentContainerID)
        .dxAccordion(options)
        .dxAccordion("instance");
    }

    return segmenthelper;
  }

  LoadSegment(id, container, content, toolbar) {
    let segmenthelper = this;
    $.when(
      segmenthelper._Quote.LineItemHelper.GetQuoteLineEditSettings(),
      segmenthelper._Quote.LineItemHelper.GetGlobalQuoteFields(),
      segmenthelper._Quote.LineItemHelper.GetQuoteSegementProducts()
    ).done(function () {
      segmenthelper._Quote.LineItemHelper.Columns = segmenthelper._Quote.LineItemHelper.TransformProductColumns(
        segmenthelper._Quote.LineItemHelper.GlobalLineFields
      );
      let seg = $.grep(
        segmenthelper._Quote.LineItemHelper.QuoteProducts,
        function (v: any, k) {
          return v.SegmentID === id;
        }
      );

      let options = segmenthelper._Quote.LineItemHelper.GetGridOptions(id);
      options.columns = segmenthelper._Quote.LineItemHelper.Columns;
      options.columnAutoWidth = true;
      options.height = GetDevice().isDevice
        ? $(window).innerHeight() * 0.8
        : $(window).innerHeight() * 0.5;

      $.each(seg[0].Products, (index, line) => {
        $.each(line, (idx, field) => {
          if (typeof field === "object") {
            $.each(field, (name, val) => {
              if (!line[name]) line[name] = val;
            });
          }
        });
      });

      segmenthelper._Quote.LineItemHelper.LineItemGrid = new EditGrid(
        {
          Data: seg[0].Products
        },
        { GridContainer: $("#" + content) },
        options,
        segmenthelper._Quote
      );

      if (segmenthelper._Quote.QuoteDetails.IsPreviousRevision === true) {
        segmenthelper._Quote.LineItemHelper.LineItemGrid.Options.editing.allowDeleting = false;
        segmenthelper._Quote.LineItemHelper.LineItemGrid.Options.editing.allowUpdating = false;
      }

      segmenthelper._Quote.LineItemHelper.LineItemGrid.Render();
    });
  }
}

export class QuoteLineHelper {
  //quoteid // String, kititemcode // String, kitdescription // String, selected // List(Of Product), linecount // Integer

  /* private*/ readonly LineItemSearchOptionsPath: string = "GetProductSearchOptions/";
  /*        */
  /* private*/ readonly QuoteLineGridID: string = "tdfquotegrid";
  /* private*/ readonly QuoteLineGridContainer: string = "tdfquotegridcontainer";
  /* private*/ readonly QuoteLineGridToolbarID: string = "tdfquotegridtoolbar";

  LineItemGrid: EditGrid;
  GlobalLineFields: Array<IQuoteLineField>;
  UserLineFields: Array<IQuoteLineField>;
  SellCostCalcForm: DevExpress.ui.dxForm;
  SellPriceCalcForm: DevExpress.ui.dxForm;
  GlobalLineScope: boolean = true;
  QuoteProducts: any;
  Columns: Array<DevExpress.ui.dxDataGridColumn>;
  QuoteLineEditFieldSettings: IQuoteLineEditSettings;
  private _Quote: Quote;
  constructor(quote: Quote) {
    let linehelper = this;
    linehelper._Quote = quote;
    LoadCompany();
  }

  /**
   * Get the company defined LineFields
   */
  GetGlobalQuoteFields() {
    let linehelper = this;
    let d = $.Deferred();
    if (!linehelper.GlobalLineFields) {
      QuoteDataService.GlobalLineFields()
        .done(function (response: IQuoteLineField[]) {
          linehelper.GlobalLineFields = response;
          d.resolve();
        });
    } else {
      return d.promise(d.resolve());
    }
    return d.promise();
  }

  /**
   * Get the user defined line fields.
   */
  GetUserQuoteFields() {
    let linehelper = this;
    let d = $.Deferred();
    if (!linehelper.UserLineFields) {
      QuoteDataService.UserQuoteFields()
        .done(function (response: IQuoteLineField[]) {
          linehelper.UserLineFields = response;
          d.resolve();
        });
    } else {
      return d.promise(d.resolve());
    }
    return d.promise();
  }

  /**
   * Change the scope of the grid to either Global or Personal.
   */
  ChangeLineItemScope() {
    let linehelper = this;

    if (linehelper.GlobalLineScope) {
      linehelper.GetUserQuoteFields().done(function () {
        linehelper.Columns = linehelper.TransformProductColumns(
          linehelper.UserLineFields
        );
        linehelper.LineItemGrid.GridObject.option(
          "columns",
          linehelper.Columns
        );
        linehelper.LineItemGrid.GridObject.refresh();
        linehelper.GlobalLineScope = false;
      });
    } else {
      linehelper.GetGlobalQuoteFields().done(function () {
        linehelper.Columns = linehelper.TransformProductColumns(
          linehelper.GlobalLineFields
        );
        linehelper.LineItemGrid.GridObject.option(
          "columns",
          linehelper.Columns
        );
        linehelper.LineItemGrid.GridObject.refresh();
        linehelper.GlobalLineScope = true;
      });
    }
  }

  /**
   * Get the lines associated with this quote.
   */
  GetQuoteProducts(force = false) {
    let linehelper = this;
    let d = $.Deferred();
    if (
      !linehelper.QuoteProducts ||
      !linehelper.QuoteProducts.length ||
      force
    ) {
      QuoteDataService.LinesForQuote(linehelper._Quote.ItemID)

        .done(function (response: IDataProduct[]) {
          if (response && typeof response === "object") {
            if (response) {
              linehelper.QuoteProducts = response;
              return d.resolve();
            }
          } else {
            if (response && typeof response === 'string') {
              new Notification({
                message: response,
                type: "error",
                displayTime: 5000,
                closeOnClick: true
              });
            } else {
              new Notification({
                message: "Unkown Error",
                type: "error",
                displayTime: 5000,
                closeOnClick: true
              });
            }
          }
        });
    } else {
      return d.promise(d.resolve());
    }
    return d.promise();
  }

  GetQuoteSegementProducts(force = false) {
    let linehelper = this;
    let d = $.Deferred();
    if (
      !linehelper.QuoteProducts ||
      !linehelper.QuoteProducts.length ||
      force
    ) {
      QuoteDataService.SegmentLinesForQuote(linehelper._Quote.ItemID)

        .done(function (response: IDataProduct[]) {
          if (response && typeof response === "object") {
            if (response) {
              linehelper.QuoteProducts = response;
              return d.resolve();
            }
          } else {
            if (response && typeof response === 'string') {
              new Notification({
                message: response,
                type: "error",
                displayTime: 5000,
                closeOnClick: true
              });
            } else {
              new Notification({
                message: "Unkown Error",
                type: "error",
                displayTime: 5000,
                closeOnClick: true
              });
            }
          }
        });
    } else {
      return d.promise(d.resolve());
    }
    return d.promise();
  }
  /**
   * Get the quote line edit settings, the global line fields, and the lines for this quote and call the render function for the grid.
   */
  LoadProductGrid() {
    let linehelper = this;
    let d = $.Deferred();
    ///if (linehelper.GlobalLineFields) {
    //     return d.resolve(linehelper);
    //  }
    $.when(
      linehelper.GetQuoteLineEditSettings(),
      linehelper.GetGlobalQuoteFields(),
      linehelper.GetQuoteProducts()
    ).done(function () {
      linehelper.Columns = linehelper.TransformProductColumns(
        linehelper.GlobalLineFields
      );
      linehelper.RenderQuoteLineGrid();
      d.resolve();
    });
    return d.promise(linehelper);
  }

  /**
   * Get the grid editing settings.
   */
  GetQuoteLineEditSettings() {
    let linehelper = this;
    let d = $.Deferred();
    if (!linehelper.QuoteLineEditFieldSettings) {
      //TODO: Is this something that could be cached in session storage
      QuoteDataService.QuoteLineEditSettings()
        .done(function (response: any | any) {
          if (true) {
            linehelper.QuoteLineEditFieldSettings = response;
          } else {
            new Notification({
              message: response.Message,
              type: "error",
              displayTime: 5000,
              shading: true,
              position: { my: "center" }
            });
          }

          d.resolve(linehelper);
        });
    } else {
      return d.promise(d.resolve(linehelper));
    }
    return d.promise(linehelper);
  }

  /**
   * Render the grid.
   */
  RenderQuoteLineGrid() {
    let linehelper = this;

    $.each(linehelper.QuoteProducts, (index, line) => {
      $.each(line, (idx, field) => {
        if (typeof field === "object") {
          $.each(field, (name, val) => {
            if (!line[name]) line[name] = val;
          });
        }
      });
    });

    let t = {
      Data: linehelper.QuoteProducts
    };
    let options = linehelper.GetGridOptions();
    options.columns = linehelper.Columns;
    options.columnAutoWidth = true;
    options.showColumnLines = true;
    options.height = GetDevice().isDevice
      ? $(window).innerHeight() * 0.8
      : $(window).innerHeight() * 0.5;
    linehelper.LineItemGrid = new EditGrid(
      t,
      { GridContainer: $("#" + linehelper.QuoteLineGridID) },
      options,
      linehelper._Quote
    );
    linehelper.LineItemGrid.Render();
    $("#" + linehelper.QuoteLineGridContainer).addClass("tdfloaded");
  }

  /**
   * Take the fields given from the server and transform them so the web grid and understand. Also sets the editability and editors for colums.
   * @param Fields
   */
  TransformProductColumns(Fields) {
    let linehelper = this;
    let columns: Array<DevExpress.ui.dxDataGridColumn> = [];
    $.each(Fields, function (key, val: IQuoteLineField) {
      let t = $.grep(linehelper.QuoteLineEditFieldSettings.Fields, function (
        field: any
      ) {
        return field.DisplayName === val.Key;
      });
      let editset = t[0];

      let col: DevExpress.ui.dxDataGridColumn = {
        caption: val.Caption,
        dataField: val.Key,
        allowExporting: val.IncludeInExport === 1,
        visibleIndex: val.Position,
        visible: Boolean(val.Visible),
        allowEditing: editset ? editset.Editable : false,
        formItem: {
          visible: editset ? editset.Editable : false,
          colSpan: 2
        }
      };
      (<any>col).EditIfCustom = false;
      if (val.Key.toLowerCase().replace(/\s/g, "") === "discount") {
        col.allowEditing = false;
      }

      if (val.Key.toLowerCase().replace(/\s/g, "") === "sellpricecalculation") {
        col.editCellTemplate = function (cellElement, cellInfo) {
          linehelper.CalcSellPrice(cellElement, cellInfo);
        };

        col.cellTemplate = function (element, cellInfo) {
          let display = linehelper.SellPriceDisplayValue(
            cellInfo.data.SellPriceCalculation
          );
          element.append(
            $("<div />")
              .append($("<i/>").addClass("pull-left " + display.icon))
              .append($("<span />").text(display.text))
          );
        };

        //col.calculateDisplayValue = function (data) {

        //    return theQuote.SellPriceDisplayValue(data.SellPriceCalculation);
        //}
      }
      if (
        val.Key.toLowerCase().replace(/\s/g, "") === "sellcost" ||
        val.Key.toLowerCase().replace(/\s/g, "") === "sellcostdiscount"
      ) {
        col.allowEditing = true;
        col.editCellTemplate = function (cellElement, cellInfo) {
          linehelper.CalcSellCost(cellElement, cellInfo);
        };
      }

      if (!linehelper._Quote.QuoteOptions.UserOptions.CanUserViewCostData) {
        let costFields = ["cost", "sellcost", "sellcostdiscount", "extendedsellcost", "grossprofit", "grossprofitpercent"]
        if ($.inArray(val.Key.toLowerCase().replace(/\s/g, ""), costFields) > -1) {
          col.calculateDisplayValue = function (cellInfo) {
            if (!cellInfo.CustomLine) {
              col.allowEditing = false;
              return "N/A"
            }
            else
              return cellInfo[val.Key]
          }
        }
      }

      if (editset && editset.Format) {
        col.format = Grid.GetColumnFormatFromUDFFormat(editset.Format, col);
        if (col.format && col.format.type === "currency") {
          col.format.precision = linehelper._Quote.QuoteOptions.GeneralOptions.CurrencyPrecision
        }
      }
      if (editset) {
        if (
          $.inArray(
            editset.LineItemKey,
            linehelper.QuoteLineEditFieldSettings.OnlyEditableForCustomProducts
          ) > -1
        ) {
          (<any>col).EditIfCustom = true;
        }
      }

      columns.push(col);
    });
    return columns;
  }

  /**
   * Create the Sell Cost Editing form.
   * @param cellElement
   * @param cellInfo
   */
  CalcSellCost(cellElement, cellInfo) {
    let linehelper = this;
    if (cellInfo.component.hasEditData()) {
      new Notification({
        message: "Please save current changes before editing this value.",
        type: "warning",
        displayTime: 3500
      });
    } else {
      if (!$("#sellCostCalc").length) {
        let div = $("<div id='sellCostCalc' />").appendTo("body");
      }

      let pop = $("#sellCostCalc")
        .dxPopup({
          // target: cellElement,
          width: 350,
          height: "auto",
          visible: true,
          showTitle: true,
          title: "Edit Sell Cost",
          showCloseButton: false,
          toolbarItems: [
            {
              toolbar: "bottom",
              location: "after",
              widget: "dxButton",
              options: {
                text: "OK",
                onClick: function () {
                  let products = [];
                  //TODO: Get this down to only passing the product id's if possible
                  if ($("#calcCostAll").length) {
                    if (
                      $("#calcCostAll")
                        .dxCheckBox("instance")
                        .option("value")
                    ) {
                      products = cellInfo.component.getSelectedRowsData();
                    } else {
                      products.push(cellInfo.data);
                    }
                  } else {
                    products.push(cellInfo.data);
                  }
                  let method = (<DevExpress.ui.dxRadioGroup>linehelper.SellCostCalcForm.getEditor(
                    "SellCostOption"
                  )).option("value");
                  let amount =
                    (<DevExpress.ui.dxNumberBox>linehelper.SellCostCalcForm.getEditor(
                      "Discount"
                    )).option("value") / 100;
                  let data = {
                    amount: amount,
                    products: products,
                    method: method || -1,
                    quoteid: linehelper._Quote.ItemID
                  };

                  // new TDFRequest({
                  //   url: linehelper._Quote.BaseQuotePath +
                  //     "UpdateQuoteLineSellCost/",
                  //   data: data,
                  //   type: "POST"
                  // });
                  ;
                  linehelper.PerformUpdate(QuoteDataService.GetUpdateSellCostRequest(linehelper._Quote.ItemID, data));

                  pop.option("visible", false);
                }
              }
            },
            {
              toolbar: "bottom",
              location: "after",
              widget: "dxButton",
              options: {
                text: "Cancel",
                onClick: function () {
                  pop.option("visible", false);
                }
              }
            }
          ],
          contentTemplate: function (element) {
            let opts: DevExpress.ui.dxFormOptions = {
              labelLocation: "top"
            };

            let items: DevExpress.ui.dxFormSimpleItem[] = [
              {
                editorType: "dxTextBox",
                name: "cost",
                label: { text: "Cost" },
                editorOptions: {
                  disabled: true,
                  focusStateEnabled: false,
                  value: cellInfo.data["Cost"]
                }
              },
              {
                editorType: "dxNumberBox",
                name: "Discount",
                label: { text: "Sell Cost Discount %" },
                editorOptions: {
                  min: 0,
                  max: 100,
                  step: 0.5,
                  showClearButton: true,
                  onValueChanged: function (e) {
                    let discountpercent = e.value / 100; //e.value < 1 ? e.value / 100 : e.value / 100  ;
                    let cost = <DevExpress.ui.dxTextBox>linehelper.SellCostCalcForm.getEditor(
                      "cost"
                    );
                    let costval = cost.option("value");
                    let sellcost = <DevExpress.ui.dxNumberBox>linehelper.SellCostCalcForm.getEditor(
                      "sellcost"
                    );
                    sellcost.option(
                      "value",
                      costval - costval * discountpercent
                    );
                  },
                  value:
                    cellInfo.data["SellCostDiscount"] < 1
                      ? cellInfo.data["SellCostDiscount"] * 100
                      : cellInfo.data["SellCostDiscount"],
                  disabled: false
                }
              },
              {
                editorType: "dxTextBox",
                name: "sellcost",
                label: { text: "Sell Cost" },
                editorOptions: {
                  disabled: true,
                  focusStateEnabled: false,
                  value: cellInfo.data["SellCost"]
                }
              },
              {
                editorType: "dxRadioGroup",
                name: "SellCostOption",
                label: { text: "" },
                editorOptions: {
                  dataSource: [
                    { text: "Maintain GP % on Sell Price", value: 0 },
                    { text: "Apply same discount to Sell Price", value: 1 }
                  ],
                  displayExpr: "text",
                  valueExpr: "value",

                  disabled: false
                }
              }
            ];
            linehelper.SellCostCalcForm = $("<div />")
              .dxForm({
                labelLocation: "top",
                items: items
              })
              .dxForm("instance");
            element.append(linehelper.SellCostCalcForm.element());

            if (cellInfo.component.getSelectedRowsData().length) {
              element.prepend(
                $("<div id='calcCostAll' />").dxCheckBox({
                  text: "Update For Selected Lines",
                  elementAttr: { style: "margin: 5px 0;" }
                })
              );
            }
          }
        })
        .dxPopup("instance");
    }
  }

  protected PerformUpdate(request/* : TDFRequest */) {
    let linehelper = this;
    request.MakeRequest().done(function (response: any) {
      if (true) {
        linehelper._Quote.RefreshLinesAndTotals();
      }
    });
  }

  /**
   * Create the Sell Price Editing form.
   * @param cellElement
   * @param cellInfo
   */
  CalcSellPrice(cellElement, cellInfo) {
    let linehelper = this;

    if (cellInfo.component.hasEditData()) {
      new Notification({
        message: "Please save current changes before editing this value.",
        type: "warning",
        displayTime: 3500
      });
    } else {
      if (!$("#sellPriceCalc").length) {
        let div = $("<div id='sellPriceCalc' />").appendTo("body");
      }
      //  $(cellElement.get(0)).append(div);
      let data = {
        SellPriceCalculation: cellInfo.data.SellPriceCalculation,
        Price: cellInfo.data.Price,
        SellCost: cellInfo.data.SellCost,
        Discount: cellInfo.data.Discount,
        CostMultiplier: cellInfo.data.CostMultiplier,
        DesiredGrossProfitPercent: cellInfo.data.DesiredGrossProfitPercent,
        SellPrice: cellInfo.data.SellPrice
      };
      let pop = $("#sellPriceCalc")
        .dxPopup({
          //target: cellElement,
          width: 350,
          height: "auto",
          visible: true,
          showTitle: true,
          title: "Calculate Sell Price",
          showCloseButton: false,
          onOptionChanged: function (e) { },
          toolbarItems: [
            {
              toolbar: "bottom",
              location: "after",
              widget: "dxButton",
              options: {
                text: "OK",
                onClick: function (e) {
                  let products = [];

                  //TODO: Get this down to only passing the product id's if possible
                  if ($("#calcPriceAll").length) {
                    if (
                      $("#calcPriceAll")
                        .dxCheckBox("instance")
                        .option("value")
                    ) {
                      products = cellInfo.component.getSelectedRowsData();
                    } else {
                      products.push(cellInfo.data);
                    }
                  } else {
                    products.push(cellInfo.data);
                  }
                  let method: EnumSellPriceMethods = (<DevExpress.ui.dxRadioGroup>linehelper.SellPriceCalcForm.getEditor(
                    "SellPriceCalculation"
                  )).option("value");
                  let amount;
                  switch (method) {
                    case EnumSellPriceMethods.PriceLessDiscount:
                      amount = (<DevExpress.ui.dxNumberBox>linehelper.SellPriceCalcForm.getEditor(
                        "Discount"
                      )).option("value");
                      break;
                    case EnumSellPriceMethods.CostMarkUp:
                      amount = (<DevExpress.ui.dxNumberBox>linehelper.SellPriceCalcForm.getEditor(
                        "CostMultiplier"
                      )).option("value");

                      break;
                    case EnumSellPriceMethods.DesiredGrossProfitPercent:
                      amount = (<DevExpress.ui.dxNumberBox>linehelper.SellPriceCalcForm.getEditor(
                        "DesiredGrossProfitPercent"
                      )).option("value");
                      break;
                    case EnumSellPriceMethods.ManualEditSellPrice:
                      amount = (<DevExpress.ui.dxTextBox>linehelper.SellPriceCalcForm.getEditor(
                        "SellPrice"
                      )).option("value");
                  }
                  let data = {
                    amount: amount,
                    products: products,
                    method: method,
                    quoteid: linehelper._Quote.ItemID
                  };

                  // new TDFRequest({
                  //   url: linehelper._Quote.BaseQuotePath +
                  //     "UpdateQuoteLineSellPrice/",
                  //   data: data,
                  //   type: "POST"
                  // });

                  linehelper.PerformUpdate(QuoteDataService.GetUpdateSellPriceRequest(linehelper._Quote.ItemID, data));
                  pop.option("visible", false);
                }
              }
            },
            {
              toolbar: "bottom",
              location: "after",
              widget: "dxButton",
              options: {
                text: "Cancel",
                onClick: function () {
                  pop.option("visible", false);
                }
              }
            }
          ],
          contentTemplate: function (element) {
            let methods = [];
            $.each(EnumSellPriceMethods, function (key, val) {
              if (typeof val === "string") {
                if (parseInt(key) !== 4 && parseInt(key) !== 5)
                  if (
                    $.inArray(parseInt(key), [100, 101, 102, 103, 104, 105]) >
                    -1
                  ) {
                    methods.push({
                      key: parseInt(key),
                      val: linehelper.SellPriceCalcDisplayValue(parseInt(key)),
                      visible: false
                    });
                  } else {
                    methods.push({
                      key: parseInt(key),
                      val: linehelper.SellPriceCalcDisplayValue(parseInt(key))
                    });
                  }
              }
            });
            let items: DevExpress.ui.dxFormSimpleItem[] = [
              {
                editorType: "dxSelectBox",
                dataField: "SellPriceCalculation",
                name: "SellPriceCalculation",
                label: { text: "Sell Price Calculation Method" },
                editorOptions: {
                  items: methods,
                  displayExpr: "val",
                  valueExpr: "key",
                  disabled: false,
                  //  value: cellInfo.value,
                  onValueChanged: function (e) {
                    //cellInfo.data.SellPriceCalculation = e.value;
                    if (linehelper.SellPriceCalcForm) {
                      linehelper.SellPriceCalcForm.itemOption(
                        "Price",
                        "visible",
                        false
                      );
                      linehelper.SellPriceCalcForm.itemOption(
                        "Discount",
                        "visible",
                        false
                      );
                      linehelper.SellPriceCalcForm.itemOption(
                        "SellPrice",
                        "visible",
                        false
                      );
                      linehelper.SellPriceCalcForm.itemOption(
                        "SellCost",
                        "visible",
                        false
                      );
                      linehelper.SellPriceCalcForm.itemOption(
                        "CostMultiplier",
                        "visible",
                        false
                      );
                      linehelper.SellPriceCalcForm.itemOption(
                        "DesiredGrossProfitPercent",
                        "visible",
                        false
                      );
                      switch (e.value) {
                        case -1:
                          linehelper.SellPriceCalcForm.itemOption(
                            "Price",
                            "visible",
                            false
                          );
                          linehelper.SellPriceCalcForm.itemOption(
                            "Discount",
                            "visible",
                            false
                          );
                          linehelper.SellPriceCalcForm.itemOption(
                            "SellPrice",
                            "visible",
                            false
                          );
                          linehelper.SellPriceCalcForm.itemOption(
                            "SellCost",
                            "visible",
                            false
                          );
                          linehelper.SellPriceCalcForm.itemOption(
                            "CostMultiplier",
                            "visible",
                            false
                          );
                          linehelper.SellPriceCalcForm.itemOption(
                            "DesiredGrossProfitPercent",
                            "visible",
                            false
                          );
                          break;
                        case 0:
                          linehelper.SellPriceCalcForm.itemOption(
                            "Price",
                            "visible",
                            true
                          );
                          linehelper.SellPriceCalcForm.itemOption(
                            "Discount",
                            "visible",
                            true
                          );
                          linehelper.SellPriceCalcForm.itemOption(
                            "SellPrice",
                            "visible",
                            true
                          );
                          break;
                        case 1:
                          linehelper.SellPriceCalcForm.itemOption(
                            "SellPrice",
                            "visible",
                            true
                          );
                          (<DevExpress.ui.dxTextBox>linehelper.SellPriceCalcForm.getEditor(
                            "SellPrice"
                          )).option("disabled", false);
                          break;
                        case 2:
                          linehelper.SellPriceCalcForm.itemOption(
                            "SellCost",
                            "visible",
                            true
                          );
                          linehelper.SellPriceCalcForm.itemOption(
                            "CostMultiplier",
                            "visible",
                            true
                          );
                          linehelper.SellPriceCalcForm.itemOption(
                            "SellPrice",
                            "visible",
                            true
                          );
                          break;
                        case 3:
                          linehelper.SellPriceCalcForm.itemOption(
                            "SellCost",
                            "visible",
                            true
                          );
                          linehelper.SellPriceCalcForm.itemOption(
                            "DesiredGrossProfitPercent",
                            "visible",
                            true
                          );
                          linehelper.SellPriceCalcForm.itemOption(
                            "SellPrice",
                            "visible",
                            true
                          );
                          break;
                      }
                    }
                    //  cellInfo.data.SellPriceCalculation = e.value;
                  }
                }
              },
              {
                editorType: "dxTextBox",
                dataField: "Price",
                name: "Price",
                label: { text: "Price" },
                editorOptions: {
                  disabled: true
                },

                visible:
                  cellInfo.value === EnumSellPriceMethods.PriceLessDiscount
              },
              {
                editorType: "dxTextBox",
                dataField: "SellCost",
                name: "SellCost",
                label: { text: "Sell Cost" },
                editorOptions: {
                  disabled: true
                },
                visible: cellInfo.value === EnumSellPriceMethods.CostMarkUp
              },
              {
                editorType: "dxNumberBox",
                name: "Discount",
                dataField: "Discount",
                label: { text: "Discount" },
                editorOptions: {
                  min: 0,
                  max: 100,
                  step: 0.5,
                  showClearButton: true,
                  value: data.Discount,
                  onValueChanged: function (e) {
                    let val = cellInfo.data.Price * (1 - e.value / 100);
                    (<DevExpress.ui.dxTextBox>linehelper.SellPriceCalcForm.getEditor(
                      "SellPrice"
                    )).option(
                      "value",
                      Globalize.formatCurrency(val, CurrentUser.Currency)
                    );
                  }
                  //disabled: cellInfo.value === EnumSellPriceMethods.NoMethodSelected ? true : false,
                },
                visible:
                  cellInfo.value === EnumSellPriceMethods.PriceLessDiscount
              },
              {
                editorType: "dxNumberBox",
                name: "CostMultiplier",
                dataField: "CostMultiplier",
                label: {
                  text: "Cost Multiplier (Ex: Enter 120 to apply 1.2 mark-up)"
                },
                editorOptions: {
                  min: 0,
                  max: 10000,
                  step: 0.5,
                  showClearButton: true,
                  value: data.CostMultiplier,
                  onValueChanged: function (e) {
                    let val = cellInfo.data.Cost * (e.value / 100);
                    (<DevExpress.ui.dxTextBox>linehelper.SellPriceCalcForm.getEditor(
                      "SellPrice"
                    )).option(
                      "value",
                      Globalize.formatCurrency(val, CurrentUser.Currency)
                    );
                  }
                },
                visible: cellInfo.value === EnumSellPriceMethods.CostMarkUp
              },
              {
                editorType: "dxNumberBox",
                name: "DesiredGrossProfitPercent",
                dataField: "DesiredGrossProfitPercent",
                label: {
                  text: "Desired GP% (Ex: Enter 10 for 10% Gross Profit)"
                },
                editorOptions: {
                  min: 0,
                  max: 100,
                  step: 0.5,
                  showClearButton: true,
                  value: data.DesiredGrossProfitPercent,
                  onValueChanged: function (e) {
                    let val = cellInfo.data.Cost / (1 - e.value / 100);
                    (<DevExpress.ui.dxTextBox>linehelper.SellPriceCalcForm.getEditor(
                      "SellPrice"
                    )).option(
                      "value",
                      Globalize.formatCurrency(val, CurrentUser.Currency)
                    );
                  }
                },
                visible:
                  cellInfo.value ===
                  EnumSellPriceMethods.DesiredGrossProfitPercent
              },
              {
                editorType: "dxTextBox",
                name: "SellPrice",
                dataField: "SellPrice",
                label: { text: "Sell Price" },
                editorOptions: {
                  disabled:
                    cellInfo.value !== EnumSellPriceMethods.ManualEditSellPrice
                },
                visible: $.inArray(cellInfo.value, [0, 1, 2, 3]) > -1
              }
            ];

            linehelper.SellPriceCalcForm = $("<div />")
              .dxForm({
                labelLocation: "top",
                formData: data,
                items: items
              })
              .dxForm("instance");

            element.append(linehelper.SellPriceCalcForm.element());

            if (cellInfo.component.getSelectedRowsData().length) {
              element.prepend(
                $("<div id='calcPriceAll' />").dxCheckBox({
                  text: "Update For Selected Lines",
                  elementAttr: { style: "margin: 10px 0;" }
                })
              );
            }
          }
        })
        .dxPopup("instance");
    }
  }

  /**
   * Get the display text for the types of sell price methods.
   * @param value
   */
  SellPriceCalcDisplayValue(value) {
    let linehelper = this;

    // let method: EnumSellPriceMethods = parseInt(EnumSellPriceMethods[value]);

    switch (value) {
      case EnumSellPriceMethods.CostMarkUp:
        return "Cost Mark-up";
      case EnumSellPriceMethods.DesiredGrossProfitPercent:
        return "Desired Gross Profit Percent";
      case EnumSellPriceMethods.ManualEditSellPrice:
        return "Manual Edit Sell Price";
      case EnumSellPriceMethods.NoMethodSelected:
        return "No Method Selected";
      case EnumSellPriceMethods.PriceLessDiscount:
        return "Price Less Discount";
      case EnumSellPriceMethods.SPA_Green:
        return "SPA-Green";
      case EnumSellPriceMethods.SPA_Green_Manual:
        return "SPA-Green-Manual";
      case EnumSellPriceMethods.SPA_Red:
        return "SPA-Red";
      case EnumSellPriceMethods.SPA_Red_Manual:
        return "SPA-Red-Manual";
      case EnumSellPriceMethods.SPA_Yellow:
        return "SPA-Yellow";
      case EnumSellPriceMethods.SPA_Yellow_Manual:
        return "SPA-Yellow-Manual";
      default:
        return "";
    }
  }

  /**
   * Get the text and icon for the current Sell Price Method for the current line.
   * @param value
   */
  SellPriceDisplayValue(value) {
    let linehelper = this;

    // let method: EnumSellPriceMethods = parseInt(EnumSellPriceMethods[value]);

    switch (value) {
      case EnumSellPriceMethods.CostMarkUp:
        return { icon: "dx-icon dx-icon-arrowup", text: "Cost" };
      case EnumSellPriceMethods.CostOverride:
        return { icon: "dx-icon dx-icon-clear", text: "Cost Override" };
      case EnumSellPriceMethods.DesiredGrossProfitPercent:
        return { icon: "dx-icon dx-icon-favorites", text: "Margin %" };
      case EnumSellPriceMethods.ManualEditSellPrice:
        return { icon: "dx-icon dx-icon-edit", text: "Manual" };
      case EnumSellPriceMethods.NoMethodSelected:
        return { icon: "dx-icon dx-icon-info", text: "None" };
      case EnumSellPriceMethods.PriceLessDiscount:
        return { icon: "dx-icon dx-icon-arrowdown", text: "Price" };
      case EnumSellPriceMethods.SPA_Green:
        return {
          icon: "dx-icon icon-SPA-Price-Bands-notag-logo3 spa-green",
          text: "SPA-Green"
        };
      case EnumSellPriceMethods.SPA_Green_Manual:
        return {
          icon: "dx-icon icon-SPA-Price-Bands-notag-logo3 spa-green",
          text: "SPA-Green-Manual"
        };
      case EnumSellPriceMethods.SPA_Red:
        return {
          icon: "dx-icon icon-SPA-Price-Bands-notag-logo3 spa-red",
          text: "SPA-Red"
        };
      case EnumSellPriceMethods.SPA_Red_Manual:
        return {
          icon: "dx-icon icon-SPA-Price-Bands-notag-logo3 spa-red",
          text: "SPA-Red-Manual"
        };
      case EnumSellPriceMethods.SPA_Yellow:
        return {
          icon: "dx-icon icon-SPA-Price-Bands-notag-logo3 spa-yellow",
          text: "SPA-Yellow"
        };
      case EnumSellPriceMethods.SPA_Yellow_Manual:
        return {
          icon: "dx-icon icon-SPA-Price-Bands-notag-logo3 spa-yellow",
          text: "SPA-Yellow-Manual"
        };

      default:
        return { icon: "", text: "" };
    }
  }

  /**
   * Calls the correct method based on the section that was clicked.
   * @param action
   */
  GetSectionData(action) {
    let linehelper = this;
    if (typeof linehelper[action] === "function") {
      linehelper[action]();
    } else {
      if (typeof linehelper._Quote[action] === "function") {
        linehelper._Quote[action]();
      }
    }
  }

  /**
   * Get the options for the grid that the web grid can understand.
   * @param segmentID
   */
  GetGridOptions(segmentID?) {
    let linehelper = this;
    let opts: DevExpress.ui.dxDataGridOptions = {};
    let LinesLocked = linehelper._Quote.LinesLocked();

    opts.showColumnLines = true;
    opts.showRowLines = true;
    opts.editing = {
      mode: "batch",
      allowUpdating:
        !LinesLocked &&
        linehelper._Quote.QuoteDetails.ERPProcessStatus ===
        EnumERPProcessStatus.Normal,
      allowAdding: false,
      allowDeleting:
        !LinesLocked &&
        linehelper._Quote.QuoteDetails.ERPProcessStatus ===
        EnumERPProcessStatus.Normal,

      texts: {
        addRow: "Add Custom Product",
        cancelAllChanges: "Cancel Current Edits",
        confirmDeleteMessage: "Delete Product From Quote?",
        deleteRow: "Remove",
        saveRowChanges: "Save"
      },
      form: {
        colCountByScreen: {
          lg: 4,
          md: 3,
          sm: 2,
          xs: 1
        },
        labelLocation: "top",
        width: $(window).innerWidth() * 0.8
      }
    };

    opts.onEditorPreparing = function (e) {
      if (e.row) {
        if (e.row.rowType === "data") {
          if (
            !e.row.data.CustomLine &&
            (<any>e).EditIfCustom &&
            !e.row.key.hasOwnProperty("__DX_INSERT_INDEX__")
          ) {
            new Notification({
              message: "This field is only editable for custom products.",
              displayTime: 3000,
              shading: false,
              type: "warning",
              position: { my: "center" }
            });
            e.cancel = true;
          }
          if (e.dataField === "Quantity") {
            e.editorOptions.onValueChanged = ev => {
              e.setValue(ev.value);
              let id = e.row.data["QuoteProductID"];
              let grid = e.component as DevExpress.ui.dxDataGrid;
              let accessories = grid
                .getDataSource()
                .items()
                .filter((v, k) => {
                  return v.IsAccessoryTo === id;
                });
              if (accessories && accessories.length > 0) {
                accessories.forEach((v, k) => {
                  grid.getRowIndexByKey(v);
                  grid.cellValue(
                    grid.getRowIndexByKey(v),
                    "Quantity",
                    ev.value
                  );
                });
              }
            };
          }

          if (e.row.isEditing) window["TDFDirty"] = true;
        }
      }
    };
    opts.onSelectionChanged = function (e) {
      if (e.selectedRowsData.length > 1) {
        //TODO: ENable\reveal multiple buttons
      } else {
      }
    };
    opts.masterDetail = {
      enabled: true,
      template: function (container: JQuery, info) {
        if (!info.data.Components.length) {
          container.append("<div />").text("No Components to show.");
        } else {
          let options = linehelper.GetGridOptions();
          options.columns = linehelper.Columns;
          options.columnAutoWidth = true;
          options.columnMinWidth = 100;
          options.showColumnLines = true;
          options.groupPanel = { visible: false };
          options.searchPanel = {
            visible: false
          };
          options.onContentReady = e => {
            (e.component as DevExpress.ui.dxDataGrid).updateDimensions();
          };
          options.export = { enabled: false };
          options.onToolbarPreparing = $.noop;
          options.height = "auto";
          options.width = "auto";
          let gridcontainer = $("<div/>");
          container.append(gridcontainer);
          linehelper.LineItemGrid = new EditGrid(
            GetDataResponseObject({ Data: info.data.Components }),
            { GridContainer: gridcontainer },
            options,
            linehelper._Quote
          );

          linehelper.LineItemGrid.Render();
        }
      }
    };

    opts.onRowRemoved = function (e: any) {
      // new TDFRequest({
      //   url: linehelper._Quote.BaseQuotePath + linehelper.RemoveLineItemsPath,
      //   data: { id: e.data.QuoteProductID },
      //   type: "POST"
      // });

      linehelper.HoldBatchRequests(QuoteDataService.GetRemoveLineRequest(e.data.QuoteProductID));
    };

    opts.onRowUpdating = function (e: any) {
      let values = [];
      $.each(e.newData, function (key, val) {
        let d = {
          Field: key,
          Value: val ? val : "!set"
        };
        values.push(d);
      });

      let prodid = e.oldData.QuoteProductID;
      // new TDFRequest({
      //   url: linehelper._Quote.BaseQuotePath + linehelper.LineItemEditPath,
      //   data: {
      //     quoteid: linehelper._Quote.ItemID,
      //     quoteproductid: prodid,
      //     values: values
      //   },
      //   type: "POST"
      // });

      linehelper.HoldBatchRequests(QuoteDataService.GetEditLineRequest(linehelper._Quote.ItemID, prodid, values));

      //    request.MakeRequest().done(function (response: any) {
      //      console.log("Done" + prodid);
      //	if (true) {
      //		window["TDFDirty"] = false;
      //	} else {
      //		new Notification({
      //			message: response.Message, type: "error", displayTime: 1000, shading: false, position: { my: "top" },
      //			animation: {
      //				show:
      //				{
      //					type: 'pop',
      //					duration: 200,
      //					from: {
      //						top: $(window).height()
      //					}
      //				}, hide: {
      //					type: 'slide',
      //					duration: 200,
      //					to: {
      //						top: $(window).height()
      //					}
      //				}
      //			}
      //		})

      //	}

      //})
    };
    opts.onRowUpdated = e => {
      //		linehelper._Quote.RefreshLinesAndTotals();
    };

    let theQuoteDetails = linehelper._Quote.QuoteDetails;

    let lineItemActionsLocked: boolean = (theQuoteDetails.Status === 0 ||
      theQuoteDetails.ERPProcessStatus !== 0 || theQuoteDetails.IsPreviousRevision === true);

    opts.onToolbarPreparing = function (e: any) {
      let gridobj: DevExpress.ui.dxDataGrid = e.component;
      let btnaddCustom = {
        disabled:
          linehelper._Quote.QuoteDetails.Status === 0 ||
          linehelper._Quote.QuoteDetails.ERPProcessStatus !== 0,
        locateInMenu: "auto",
        location: "after",
        name: "addCustomProductButton",
        options: {
          disabled: lineItemActionsLocked,
          elementAttr: segmentID ? { segmentID: segmentID } : "",
          hint: "Add a custom product to this quote.",
          icon: "fa fa-plus",
          onClick: e => {
            linehelper.AddCustomProduct(gridobj);
          },
          onInitialized: function (e) { },
          text: " Add Custom Product "
        },
        showText: "inMenu",
        widget: "dxButton"
      };
      let btnaddMultiple = {
        disabled: lineItemActionsLocked,
        locateInMenu: "auto",
        location: "after",
        name: "addMultipleProductsButton",
        options: {
          disabled:
            linehelper._Quote.QuoteDetails.Status === 0 ||
            linehelper._Quote.QuoteDetails.ERPProcessStatus !== 0,
          elementAttr: segmentID ? { segmentID: segmentID } : "",
          hint: "Search for products to add",
          icon: "fa fa-plus-square",
          onClick: e => {
            linehelper._Quote.RenderProductSearch(e);
          },
          onInitialized: function (e) { },
          text: " Add Products "
        },
        showText: "inMenu",
        widget: "dxButton"
      };
      let btnChangeScope = {
        disabled: false,
        locateInMenu: "auto",
        location: "after",
        name: "changelinescope",
        options: {
          disabled: false,
          hint: "Change QuoteLine Fields Scope",
          icon: "globe",
          onClick: function (e) {
            let icon = linehelper.GlobalLineScope ? "user" : "globe";
            e.component.option("icon", icon);

            linehelper.ChangeLineItemScope();
          }, // theQuote.RenderProductSearch.bind(theQuote),
          onInitialized: function (e) { },
          text: "Change Scope "
        },
        showText: "inMenu",
        widget: "dxButton"
      };
      let btnSendToKit = {
        disabled: false,
        locateInMenu: "auto",
        location: "after",
        name: "senditemstokit",
        options: {
          disabled: lineItemActionsLocked,
          hint: "Send Select Line Items to a New Kit",
          icon: "box",
          onClick: function (e) {
            if (gridobj.getSelectedRowsData().length > 1) {
              //let FormData: ["kititemcode", "kitdescription"];
              let items = [
                {
                  editorType: "dxTextBox",
                  colSpan: 2,
                  label: { text: "ItemCode" },
                  name: "ItemCode"
                },
                {
                  editorType: "dxTextArea",
                  colSpan: 2,

                  label: { text: "Description" },
                  name: "Description"
                }
              ];
              let form = $("<div />")
                .dxForm({ items: items })
                .dxForm("instance");

              let dialog = new Dialog({
                body: form.element(),
                size: "size-normal",
                closable: true,
                title: "Create Kit",
                buttons: [
                  {
                    widget: "dxButton",
                    toolbar: "bottom",
                    location: "after",
                    options: {
                      id: "okgo",
                      type: "success",
                      onClick: function (dlg) {
                        let itemCode = form
                          .getEditor("ItemCode")
                          .option("value");
                        let description = form
                          .getEditor("Description")
                          .option("value");
                        if (itemCode) {
                          // new TDFRequest({
                          //   url:
                          //     linehelper._Quote.BaseQuotePath + "/SendToKit/",
                          //   data: {
                          //     quoteid: linehelper._Quote.ItemID,
                          //     kititemcode: itemCode,
                          //     kitdescription: description,
                          //     selected: gridobj.getSelectedRowsData(),
                          //     linecount: gridobj.getVisibleRows().length
                          //   },
                          //   type: "POST"
                          // })
                          //   .MakeRequest()
                          QuoteDataService.SendToKit({
                            quoteid: linehelper._Quote.ItemID,
                            kititemcode: itemCode,
                            kitdescription: description,
                            selected: gridobj.getSelectedRowsData(),
                            linecount: gridobj.getVisibleRows().length
                          })
                            .done(function (response: any) {
                              response.Valid
                                ? (linehelper.QuoteProducts = null)
                                : new Notification({
                                  message: response.Message,
                                  type: "error"
                                });

                              linehelper._Quote.RefreshLinesAndTotals();
                            });

                          dialog.close();
                        } else {
                          new Notification({
                            message: "Please enter an ItemCode for the Kit"
                          });
                        }
                      },
                      autospin: true,
                      hotkey: 13,
                      icon: "check",
                      label: "Ok"
                    }
                  },
                  {
                    widget: "dxButton",
                    toolbar: "bottom",
                    location: "after",
                    options: {
                      id: "cancel",
                      action: function () {
                        dialog.close();
                      },
                      icon: "remove",
                      text: "Cancel"
                    }
                  }
                ]
              });
              dialog.open();
              //  quoteid As String, kititemcode As String, kitdescription As String, selected As List(Of Product), linecount As Integer
            }
          },

          text: "Send To Kit "
        },
        showText: "inMenu",
        widget: "dxButton"
      };
      let btnDeleteMultiple = {
        disabled: lineItemActionsLocked,
        locateInMenu: "auto",
        location: "after",
        name: "deleteMultipleProductsButton",
        options: {
          disabled:
            linehelper._Quote.QuoteDetails.Status === 0 ||
            linehelper._Quote.QuoteDetails.ERPProcessStatus !== 0,
          elementAttr: segmentID ? { segmentID: segmentID } : "",
          hint: "Remove Selected Products.",
          icon: "remove",
          onClick: e => {
            if (gridobj && gridobj.getSelectedRowsData().length) {
              let selected = gridobj
                .getSelectedRowsData()
                .map(k => k["QuoteProductID"])
                .filter(item => {
                  return item;
                });
              // new TDFRequest({
              //   url: `${
              //     linehelper._Quote.BaseQuotePath
              //     }/RemoveMultipleProducts`,
              //   data: { ids: selected },
              //   type: "POST"
              // })
              //   .MakeRequest()
              QuoteDataService.RemoveMultipleLines(selected)
                .done((response: any) => {
                  new Notification({
                    message: response.Message,
                    type: response.Valid ? "success" : "error",
                    displayTime: response.Valid ? 3000 : 5000,
                    shading: !response.Valid
                  });

                  if (true) {
                    linehelper._Quote.RefreshLinesAndTotals();
                  }
                });
            }
          },
          onInitialized: function (e) { },
          text: " Remove Products "
        },
        showText: "inMenu",
        widget: "dxButton"
      };
      let btnSPA = {
        disabled: false,
        locateInMenu: "auto",
        location: "after",
        name: "sendlinetospa",
        options: {
          disabled: false,
          hint:
            "Send the selected product to SPA to access pricing information.",
          //icon:SPAICON,
          icon: "dx-icon icon-SPA-Price-Bands-notag-logo3",
          onClick: function (e) {
            let selected = gridobj.getSelectedRowsData();
            if (selected.length) {
              let custom = selected.filter((v, k) => {
                return v.CustomProduct || v.CustomLine;
              });
              if (custom.length) {
                new Notification({
                  message: "Custom products and kits are not supported.",
                  type: "error"
                });
              } else {
                let helper = new SPAHelper(linehelper._Quote, selected);
              }
            } else {
              new Notification({
                message: "At least one product must be selected.",
                type: "warning"
              });
            }
          },

          text: "SPA Options"
        },
        showText: "inMenu",
        widget: "dxButton"
      };

      (<DevExpress.ui.dxToolbarOptions>e.toolbarOptions).onOptionChanged = e => {
        try {
          if (e.fullName.search(/disabled/i) > -1) {
            let itemoptionsstring = e.fullName.replace(".disabled", "");
            if (itemoptionsstring) {
              let options = e.component.option(itemoptionsstring);
              //this will be triggered when the grid has been edited ... !e.value means the option that disables the save button is being set to false (it is being enabled)
              if (options && options.text === "Save changes" && !e.value) {
                e.component.element().addClass("tdfdirty");
              } else {
                if (options && options.text === "Save changes" && e.value) {
                  if (!linehelper.LineItemGrid.GridObject.hasEditData()) {
                    e.component.element().removeClass("tdfdirty");
                  }
                }
              }
              (options as DevExpress.ui.dxButtonOptions).onClick = e => {
                if (gridobj.hasEditData()) {
                  gridobj.saveEditData().done(e => {
                    if (
                      linehelper._saverequests &&
                      linehelper._saverequests.length
                    ) {
                      if (
                        GetCompanyPreference(
                          "UseSpecialSaveLoop",
                          "QuoteLines",
                          "0"
                        ) === "1"
                      ) {
                        let count = 0;
                        let callit = next => {
                          if (linehelper._saverequests[count]) {
                            linehelper._saverequests[count]
                              .MakeRequest()
                              .done(() => {
                                count += 1;
                                callit(count);
                              });
                          }
                          if (count === linehelper._saverequests.length) {
                            linehelper._saverequests = [];
                            linehelper._Quote.RefreshLinesAndTotals();
                            window["TDFDirty"] = false;
                          }
                        };
                        callit(count);
                      } else {
                        let dfds = [];
                        linehelper._saverequests.forEach(v => {
                          dfds.push(v.MakeRequest());
                        });

                        $.when.apply($, dfds).done(e => {
                          linehelper._saverequests = [];
                          linehelper._Quote.RefreshLinesAndTotals();
                          window["TDFDirty"] = false;
                        });
                      }
                    }
                  });
                }
              };
            }
          }
        } catch (e) {
          console.warn("Unable to find correct button or option.");
        }
      };
      if (linehelper._Quote.QuoteOptions.GeneralOptions.EnableKitCreation) {
        e.toolbarOptions.items.unshift(btnSendToKit);
      }
      if (
        linehelper._Quote.QuoteDetails.Status === 0 ||
        linehelper._Quote.QuoteDetails.ERPProcessStatus !== 0
      ) {
        e.toolbarOptions.items.unshift(btnSendToKit);
      }
      e.toolbarOptions.items.unshift(btnDeleteMultiple);
      e.toolbarOptions.items.unshift(btnChangeScope);
      e.toolbarOptions.items.unshift(btnaddCustom);
      e.toolbarOptions.items.unshift(btnaddMultiple);
      //TODO: should this default to false ????
      if (linehelper._Quote.EnableSPA) {
        e.toolbarOptions.items.unshift(btnSPA);
      }
    };
    opts.onExporting = function (e: any) {
      $.each(e.component.getVisibleColumns(), function (key, val) {
        val.visible = val.allowExport ? "" : false;
      });
    };
    opts.onContentReady = function (e) {
      (e.component as DevExpress.ui.dxDataGrid).columnOption(
        "command:edit",
        "visibleIndex",
        -3
      );
      $(window).on("orientationchange", function () {
        e.element.css("width", "100%");
      });
    };

    return opts;
  }
  private _saverequests: any /* TDFRequest[] */;
  HoldBatchRequests(req/* : TDFRequest */) {
    let lineHelper = this;

    if (!lineHelper._saverequests) {
      lineHelper._saverequests = [req];
    } else {
      lineHelper._saverequests.push(req);
    }
  }

  /**
   * Add a custom product to the current quote.
   */
  AddCustomProduct(gridobj?: DevExpress.ui.dxDataGrid) {
    let linehelper = this;
    let position = linehelper.QuoteProducts
      ? linehelper.QuoteProducts.length
      : 0;
    // new TDFRequest({
    //   url: linehelper._Quote.BaseQuotePath + linehelper.AddCustomLinePath,
    //   data: { quoteid: linehelper._Quote.ItemID, position: position },
    //   type: "POST"
    // }).MakeRequest()
    QuoteDataService.AddCustomline(linehelper._Quote.ItemID, position)
      .done(function (response: any) {
        // if (linehelper._Quote.SegmentsDefined) {
        if (linehelper._Quote.Dirty || linehelper._Quote.DetailsDirty) {
          new Notification({
            message:
              "The custom product has been added, but there are unsaved changes. Please save the changes and then refresh. ",
            type: "warning",
            displayTime: 5000
          });
        } else {
          //window.location.reload();
					linehelper._Quote.RefreshLinesAndTotals();
        }
      });
  }
}

class SPAHelper {
  // PriceBandRequest: { PurchaseScenarioID: number | null; OverrideContractPrice: boolean | null; ProvideBestPrice: boolean | null; QuoteProductID: string }
  PriceBandRequests: {
    PurchaseScenarioID: number | null;
    OverrideContractPrice: boolean | null;
    ProvideBestPrice: boolean | null;
    QuoteProductID: string;
  }[] = [];
  QuoteProducts: IQuoteDataProduct[];
  _Quote: Quote;
  SelectedColor: string;
  SelectedPrice: number;
  ImageField: string;
  constructor(theQuote: Quote, products: IQuoteDataProduct[]) {
    let spaHelper = this;
    spaHelper._Quote = theQuote;
    spaHelper.QuoteProducts = products;

    let productindex = 0;
    spaHelper.ImageField = GetCompanyPreference(
      "ProductImageField",
      "SPAIntegration"
    );
    spaHelper.GetSPAAccessLevel().done((accesslevels: any) => {
      let ds = [];
      let d = $.Deferred();
      let temp = function (index) {
        let changedVals;
        if (
          Object.keys(accesslevels.Rights)
            .map(k => (k !== "BeltLevel" ? accesslevels.Rights[k] : false))
            .indexOf(true) > -1
        ) {
          if (index === spaHelper.QuoteProducts.length) return d.resolve(false);
          let form = spaHelper.GetSettingsForm(changedVals, accesslevels);
          spaHelper
            .GetRequestParameters(
              form,
              changedVals,
              spaHelper.QuoteProducts[index]
            )
            .done(
              (requestparams: {
                PurchaseScenarioID: number | null;
                OverrideContractPrice: boolean | null;
                ProvideBestPrice: boolean | null;
                QuoteProductID: string;
              }) => {
                if (requestparams) {
                  spaHelper.GetTDF_SPADataSingle(requestparams).done(() => {
                    temp((index += 1));
                  });
                } else {
                  temp((index += 1));
                }
                // spaHelper.PriceBandRequests.push(requestparams);
              }
            );
        } else {
          if (index === spaHelper.QuoteProducts.length) return d.resolve(true);
          spaHelper.PriceBandRequests.push({
            PurchaseScenarioID: null,
            OverrideContractPrice: null,
            ProvideBestPrice: null,
            QuoteProductID: spaHelper.QuoteProducts[index].QuoteProductID
          });
          temp((index += 1));
        }
        return d.promise();
      };
      $.when(temp(productindex)).done(go => {
        if (go) {
          spaHelper.GetSPA_TDFData();
        } else {
          spaHelper._Quote.RefreshLinesAndTotals();
        }
      });
    });
  }

  GetSPAAccessLevel() {
    let spaHelper = this;

    let d = $.Deferred();
    /* new TDFRequest({
      url: `${TDFRequest.ApiPath}/SPA/Client/UserAccessLevel`,
      type: "GET"
    })
      .MakeRequest() */
    QuoteDataService.GetStrategicPricingAccessLevel()
      .done(response => {
        d.resolve(response);
      });

    return d.promise();
  }

  GetTDF_SPADataSingle(requestParams) {
    let spaHelper = this;
    let isManual: boolean = false;
    let d = $.Deferred();
    /*   let actualurl = `${TDFRequest.ApiPath}/SPA/Client/CreatePriceBand`;
      // let testurl = `${TDFRequest.ApiPath}/SPA/Client/test-scenario/`;
      let reqmeta: TDFRequest = new TDFRequest({
        url: `${TDFRequest.ApiPath}/SPA/Client/ProductMeta/?${$.param({
          id: requestParams.QuoteProductID
        })}`,
        type: "GET"
      });
      let reqspa: TDFRequest = new TDFRequest({
        url:'/SPA/Client/CreatePriceBand',// actualurl, //`${testurl}?id=${Math.floor(Math.random() * 6) + 1}`,
        data: requestParams,
        type: "POST"
      });
  
   */
    $.when(QuoteDataService.StrategicPricingProductMeta(requestParams), QuoteDataService.CreatePriceBand(requestParams)).done((r1, r2) => {
      spaHelper.CreateSpaForm(r1, r2).done(() => {
        d.resolve();
      });
    });
    return d.promise();
  }

  GetSPA_TDFData() {
    let spaHelper = this;
    let isManual: boolean = false;
    /*  let actualurl = `${TDFRequest.ApiPath}/SPA/Client/CreatePriceBand`;
     // let testurl = `${TDFRequest.ApiPath}/SPA/Client/test-scenario/`;
 
     let reqmeta: TDFRequest[] = [];
     let reqspa: TDFRequest[] = [];
 
     spaHelper.PriceBandRequests.forEach((v, k) => {
       reqmeta.push(
         new TDFRequest({
           url: `${TDFRequest.ApiPath}/SPA/Client/ProductMeta/?${$.param({
             id: v.QuoteProductID
           })}`,
           type: "GET"
         })
       );
       reqspa.push(
         new TDFRequest({
           url: actualurl, //`${testurl}?id=${Math.floor(Math.random() * 6) + 1}`,
           data: v,
           type: "POST"
         })
       );
     }); */


    let promises = [];

    spaHelper.PriceBandRequests.forEach((v, k) => {
      promises.push(QuoteDataService.StrategicPricingProductMeta(v));
      promises.push(QuoteDataService.CreatePriceBand(v));
    });


    /*  for (let i = 0; i < reqmeta.length; i++) {
       promises.push(reqmeta[i].MakeRequest());
       promises.push(reqspa[i].MakeRequest());
     } */


    let responses: { tdfresponse: any; sparesponse: any }[] = [];
    $.when.apply($, promises).done(function () {
      if (arguments && arguments.length) {
        for (let i = 0; i < arguments.length; i++) {
          if (arguments[i].tdf && arguments[i + 1].SPAResponse) {
            responses.push({
              tdfresponse: arguments[i],
              sparesponse: arguments[i + 1]
            });
            i++;
          }
        }
        let responseIndex = 0;
        let temp = function (index) {
          let d = $.Deferred();
          if (index === responses.length) return d.resolve();
          spaHelper
            .CreateSpaForm(
              responses[index].tdfresponse,
              responses[index].sparesponse
            )
            .done(() => {
              temp((index += 1));
            });
          return d.promise();
        };

        $.when(temp(responseIndex)).done(() => {
          spaHelper._Quote.RefreshLinesAndTotals();
        });
      }
    });
  }

  private GetRequestParameters(
    form: DevExpress.ui.dxForm,
    changedVals: any,
    product: IQuoteDataProduct
  ) {
    let spaHelper = this;
    let d: JQueryDeferred<{
      PurchaseScenarioID: number | null;
      OverrideContractPrice: boolean | null;
      ProvideBestPrice: boolean | null;
      QuoteProductID: string;
    }> = $.Deferred();
    let opts: DevExpress.ui.dxPopupOptions = {
      contentTemplate() {
        return form.element();
      },
      title: "Advanced Options",
      showCloseButton: true,
      height: "auto",
      toolbarItems: [
        {
          widget: "dxButton",
          toolbar: "bottom",
          location: "after",
          options: {
            type: "success",
            onClick: function (dlg) {
              let theform = form.option("formData");
              let theseRequestParams = {
                PurchaseScenarioID:
                  theform.ui &&
                    typeof theform.ui["PurchaseScenario"] === "number"
                    ? theform.ui["PurchaseScenario"]
                    : null,
                OverrideContractPrice:
                  theform.Rights &&
                    typeof theform.Rights["OverrideContractPrice"] !== "undefined"
                    ? theform.Rights["OverrideContractPrice"]
                    : null,
                ProvideBestPrice:
                  theform.Rights &&
                    typeof theform.Rights["ProvideBestPrice"] !== "undefined"
                    ? theform.Rights["ProvideBestPrice"]
                    : null,
                QuoteProductID: product.QuoteProductID
              };
              theDialog.hide().done(() => {
                d.resolve(theseRequestParams);
              });
            },
            icon: "check",
            text: "Submit"
          }
        },
        {
          widget: "dxButton",
          toolbar: "bottom",
          location: "after",
          options: {
            id: "defaults",
            onClick: function () {
              theDialog.hide().done(() => {
                d.resolve({
                  PurchaseScenarioID: null,
                  OverrideContractPrice: null,
                  ProvideBestPrice: null,
                  QuoteProductID: product.QuoteProductID
                });
              });
            },
            icon: "fa fa-undo",
            text: "Use Defaults"
          }
        },
        {
          widget: "dxButton",
          toolbar: "bottom",
          location: "after",
          options: {
            id: "cancel",
            onClick: function () {
              theDialog.hide().done(() => {
                d.resolve(null);
              });
            },
            icon: "remove",
            text: "Cancel"
          }
        }
      ]
    };
    if ($("#spaform").length) {
      $("#spaform").remove();
    }
    $("<div id='spaform'/>").appendTo("body");
    let theDialog = $("#spaform")
      .dxPopup(opts)
      .dxPopup("instance");
    theDialog.show();

    return d.promise();
  }

  private GetSettingsForm(changedVals: any, data: any) {
    let spaHelper = this;
    let theItems;
    ({ theItems, changedVals } = spaHelper.FormItems(
      theItems,
      data,
      changedVals
    ));
    let form = $("<div />")
      .dxForm({ formData: {}, items: theItems, colCount: 2 })
      .dxForm("instance");
    return form;
  }

  private FormItems(theItems: any, data: any, changedVals: any) {
    theItems = Object.keys(data.Rights)
      .map(
        k =>
          data.ui[k] && k !== "BeltLevel"
            ? {
              dataField: `ui.${k}`,
              label: {
                text: k.replace(/([A-Z])/g, " $1").trim()
              },
              editorType: data.ui[k].length > 1 ? "dxSelectBox" : "dxTextBox",
              editorOptions: {
                displayExpr: "Display",
                valueExpr: "ID",
                dataSource: data.ui[k]
              },
              colSpan: 2
            }
            : {
              dataField: `Rights.${k}`,
              label: {
                text: k.replace(/([A-Z])/g, " $1").trim()
              },
              editorType: "dxSwitch",
              editorOptions: {
                value: data.Rights[k] || false
                //onValueChanged(e) {
                //    if (!changedVals) {
                //        changedVals = {
                //            "": ""
                //        };
                //        changedVals[k] = e.value;
                //    }
                //    else {

                //        changedVals[k] = e.value;
                //    }
                //},
              },
              colSpan: 2,
              visible: data.Rights[k]
            }
      )
      .filter(item => {
        return item;
      });
    return { theItems, changedVals };
  }

  CreateSpaForm(tdfdata, spadata) {
    let spaHelper = this;
    let multi = $.Deferred();
    let isManual;

    let responseFromSPA = spadata.SPAResponse;

    tdfdata.CustomerID = spaHelper._Quote.iMain["ItemBSICompanyID"];
    let theProduct = spaHelper.QuoteProducts.filter((v, k) => {
      return v.QuoteProductID === tdfdata.quoteProductID;
    })[0];

    let dlgspa: DevExpress.ui.dxPopup;
    let opts: DevExpress.ui.dxPopupOptions = {
      contentTemplate() {
        return $("<div id='placeholder'/>")
          .css("height", "auto")
          .text("Loading...");
      },
      titleTemplate(el) {
        $(`<span class="icon-SPA-Price-Bands-logo2"></span>`).appendTo(el);
      },
      fullScreen: true,
      showCloseButton: true,
      toolbarItems: [
        {
          widget: "dxButton",
          toolbar: "bottom",
          location: "after",
          options: {
            onClick: function () {
              dlgspa.hide().done(() => {
                multi.resolve();
              });
            },

            icon: "remove",
            text: "Cancel"
          }
        },
        {
          widget: "dxButton",
          toolbar: "bottom",
          location: "after",
          options: {
            type: "success",
            onClick: function () {
              /* new TDFRequest({
                url: `${TDFRequest.ApiPath}/spa/client/updateprice`,
                type: "POST",
                data: {
                  QuoteProductID: tdfdata.quoteProductID,
                  Color: spaHelper.SelectedColor,
                  isManual: isManual,
                  Price: spaHelper.SelectedPrice,
                  GreenPrice: responseFromSPA.PriceBand.Green,
                  RedPrice: responseFromSPA.PriceBand.Red,
                  YellowPrice: responseFromSPA.PriceBand.Yellow
                }
              })
                .MakeRequest() */
              QuoteDataService.UpdateStrategicPrice(tdfdata, spaHelper, isManual, responseFromSPA)
                .done(response => {
                  if (response.responseText) {
                    new Notification({
                      message: JSON.parse(response.responseText).Message,
                      displayTime: 10000,
                      closeOnClick: true,
                      type: "error",
                      shading: true
                    });
                  } else {
                    dlgspa.hide().done(() => {
                      multi.resolve();
                      //spaHelper._Quote.RefreshLinesAndTotals();
                    });
                  }
                });
            },

            icon: "check",
            text: "Save"
          }
        }
      ]
    };
    if ($("#spadiag").length) {
      $("#spadiag").remove();
    }

    $("<div id='spadiag'/>").appendTo("body");
    dlgspa = $("#spadiag")
      .dxPopup(opts)
      .dxPopup("instance");

    dlgspa.show().done(() => {
      let sections = [
        {
          title: "Product Image",
          size: 2,
          contentID: "tdf-product-img-container",
          colr: "success",
          url: theProduct[spaHelper.ImageField]
        },
        {
          title: "Product Information",
          size: 6,
          contentID: "tdf-product-container",
          colr: "warning"
        },
        {
          title: "SPA Price Bands",
          size: 4,
          contentID: "spa-band-container-test",
          colr: "danger"
        }
      ];

      let cont = $(
        "<div class='container-fluid' style='height:100%;overflow:hidden;overflow-y:auto;' />"
      );

      let thecontainer = $("<div class='row' />").appendTo(cont);

      sections.forEach(section => {
        thecontainer.append(spaHelper.Template(section));
      });

      $("#placeholder").replaceWith(cont);

      $(
        '<div class="fa fa-eye" data-toggle="collapse" data-target="#tdf-product-img-container" title="Toggle Product Image" style="cursor:pointer;"></div>'
      ).insertBefore(cont);

      let LastTransInPast12 = !moment(
        new Date(tdfdata.LastTransactionDate)
      ).isBefore(moment(new Date()).add("months", -12));
      let LastTransDate = LastTransInPast12
        ? Globalize.formatDate(new Date(tdfdata.LastTransactionDate), {
          date: "short"
        })
        : "N/A";
      $("#last-trans").append(`<small><label> Last Transaction </label></small>
                                                <div><label>Date:</label> ${LastTransDate}</div>
                                                <div><label>Price:</label> ${
        LastTransInPast12
          ? Globalize.formatCurrency(
            tdfdata.LastTransactionPrice,
            CurrentUser.Currency
          )
          : "N/A"
        }</div>`);

      $("#spa-band-container-test").dxRadioGroup(
        spaHelper.GetBandRadGroupOptions(responseFromSPA)
      );
      spaHelper.RenderTDFSection(tdfdata.tdf, theProduct);
      spaHelper.AdditionalSection(responseFromSPA);

      $("#spa-band-container-test").on("change", e => {
        let id = e.target.id;
        let isMargin = id === "spa-manual-gm";
        isManual = true;
        spaHelper.SelectedColor = "Manual";
        let val = parseFloat(<string>$(e.target).val());
        spaHelper.SelectedPrice = val;
        if (isMargin && val > 100) {
          notify("Please Enter a value between 1 & 100", "error");
        } else {
          if (isMargin && val > 1) {
            val = val / 100;
            spaHelper.SelectedPrice = val;
          }

          //calls for break even and price - from margin
          // CalcBreakEven(ByVal price As Decimal, ByVal greenPrice As Decimal, ByVal systemCost As Decimal)
          if (typeof val === "number") {
            /* new TDFRequest({
              url: `${
                TDFRequest.ApiPath
                }/spa/client/CalculateFactors/?userValue=${val}&ismargin=${isMargin}&greenprice=${
                responseFromSPA.PriceBand.Green
                }&systemcost=${theProduct.Cost}`
            })
              .MakeRequest() */

            QuoteDataService.CalcStrategicFacotrs(val, isMargin, responseFromSPA, theProduct)
              .done(response => {
                let theContainer = $(`#${e.target.id}-result`);
                theContainer.removeClass("hidden").empty();
                spaHelper.SelectedPrice = response.Price;
                theContainer.append(
                  $("<div/>").text(
                    `${Globalize.formatCurrency(
                      spaHelper.SelectedPrice,
                      CurrentUser.Currency
                    )}`
                  )
                );
                if (
                  GetCompanyPreference("ShowCommissionValues") ===
                  "1"
                ) {
                  theContainer.append(
                    $("<div/>").text(
                      `${Globalize.formatCurrency(
                        response.CommissionFactor,
                        CurrentUser.Currency
                      )} Commission`
                    )
                  );
                }
                theContainer.append(
                  $("<div/>").text(
                    `${Globalize.formatNumber(response.BreakEven / 100, {
                      round: "round",
                      style: "percent",
                      maximumFractionDigits: 2,
                      minimumFractionDigits: 2
                    })} B\E Volume`
                  )
                );
              });
          }
        }
      });
    });

    return multi.promise();
  }

  AdditionalSection(data) {
    let spaHelper = this;
    let template = `<div class='row'>
   <div class='col-sm-6'>
        <div class="form-group">
            <strong class="">Customer Segment: </strong>
            <div id="spa-CustomerSegment" class="" ></div>
        </div>
        <div class="form-group">
            <strong class=" ">Customer Haggle Index:</strong>
            <div id="spa-CustomerHaggleIndex" ></div>           
        </div>
        <div class="form-group">
            <strong class=" ">Price Environment Score:</strong>
            <div id="spa-PriceEnvironmentScore" ></div>
        </div>      
    </div>
    <div class='col-sm-6'>
          <div class="form-group" >
            <strong class=" ">Core Status:</strong>
            <div id="spa-CoreStatus"></div>
        </div>
        <div class="form-group">
            <strong class=" ">Visibility: </strong>
            <div id="spa-Visibility" ></div>       
        </div>
    </div></div>`;
    $("#spa-additional-info").append(template);
    Object.keys(data).map(k => {
      $(`#spa-${k}`).text(data[k]);
    });
  }

  private RenderTDFSection(tdfproductmeta, theProduct) {
    let spaHelper = this;
    //trying to get away from including this html in here but for show and tell time this is the best way to make changes quickly
    let template = spaHelper.ProductInfoTemplate(theProduct);
    $("#tdf-product-container").append(template);
    //if (Preferences.GetCompanyPreference("ShowSystemPrice") === "1") {
    //    metaValue = Globalize.formatCurrency(spaHelper.QuoteProducts[0][k] || spaHelper.QuoteProducts[0]["SellPrice"],CurrentUser.Currency);
    //}
    Object.keys(tdfproductmeta)
      .sort()
      .map(k => {
        let metaValue;
        if (k === "ListPrice") {
          metaValue = Globalize.formatCurrency(
            theProduct[k] || theProduct["SellPrice"],
            CurrentUser.Currency
          );
        } else {
          metaValue =
            tdfproductmeta[k] ||
            theProduct[spaHelper.TranslateFields(k)] ||
            null;
        }
        if (k === "UOM" && !metaValue) {
          metaValue = "EA";
        }
        $(`#tdf-${k}`).text(metaValue);
        //if ($.inArray(k, ["LastTransactionDate", "LastTransactionPrice"]) < 0 && metaValue) {
        //    $(`<div id="tdf-${k}-container"/>`).addClass("form-group").css("border-color", "transparent").append(
        //        $("<div />").addClass("card-block ").append(
        //            $(`<label id="tdf-${k}-label"/>`).addClass(k).text($.inArray(k, ["UOM", "SKU", "VendorID"]) > -1 ? k : k.replace(/([A-Z])/g, ' $1').trim())).append(
        //            $(`<div id="tdf-${k}-value"/>`).addClass("card-text text-nowrap").text(metaValue)))
        //        .appendTo("#tdf-product-container")
        //}
      });
  }
  private ProductInfoTemplate(theproduct) {
    let spaHelper = this;
    return `
<div class="form-group">
                          <div class='${theproduct.ListPrice ? "" : ""}'>
                            <strong class="tdf-spa-label" style='font-size:larger;display:table-cell;width:8em;;'>List Price: </strong>
                            <span class="tdf-spa-value " style='font-size:larger;display:table-cell;width:10em;text-align:left;' id="tdf-ListPrice" class=""></span>    </div>
  <div class='${
      GetCompanyPreference("ShowSystemCost") === "1" ? "" : "hidden"
      }'>
                            <strong class="tdf-spa-label" style='font-size:larger;display:table-cell;width:8em;'>System Cost: </strong>
                            <span class="tdf-spa-value " id="tdf-SystemCost" style='font-size:larger;display:table-cell;width:10em;text-align:left;'> ${Globalize.formatCurrency(
        theproduct.Cost,
        CurrentUser.Currency
      )}</span>  
  </div>
  <div class='${
      GetCompanyPreference("ShowSystemPrice") === "1" ? "" : "hidden"
      }'>
                            <strong class="tdf-spa-label" style='font-size:larger;display:table-cell;width:8em;'>System Price: </strong>
                            <span class="tdf-spa-value " id="tdf-SystemPrice" style='font-size:larger;display:table-cell;width:10em;text-align:left;'> ${Globalize.formatCurrency(
        theproduct.Price,
        CurrentUser.Currency
      )}</span>
  </div>
</div>
<div class="row">
<div class='col-sm-6'>  
    <div class="form-group">
        <strong>Product: </strong>
        <div>
          <div class="tdf-spa-value" id="tdf-SKU"> </div>
          <div class="tdf-spa-value" id="tdf-ProductName"></div>
        </div>
      </div>
  </div>
  <div class='col-sm-6'>  
      <div class="form-group">
          <strong>Customer:</strong>
          <div id="tdf-CustomerID" class="tdf-spa-value"> </div>
          <div id="tdf-CustomerName" class="tdf-spa-value"> </div>
        </div>
    </div>
</div>
<div class="row">
<div class='col-sm-6'>  
    <div class="form-group">
        <strong> Vendor </strong>
        <div id="tdf-VendorID" class="tdf-spa-value"></div>
        <div id="tdf-Vendor" class="tdf-spa-value"></div>
      </div>
  
  </div>
  <div class='col-sm-6'>  
      <div class="form-group">
          <strong>Qty on Hand:</strong>
          <div class="tdf-spa-value" id="tdf-QtyOnHand"></div>
        </div>
    </div>
</div>
<div class="row">
<div class='col-sm-6'>        
    <div class="form-group">
        <strong>Family: </strong>
        <div class="tdf-spa-value" id="tdf-ProductFamily"></div> 
        <div class="tdf-spa-value" id="tdf-ProductFamilyName"></div>
      </div> 
  </div>
  <div class='col-sm-6'>         
    <div class="form-group">
        <strong>UOM:</strong>
        <div>
          <div class="tdf-spa-value" id="tdf-UOM"> </div>
        </div>
      </div>
    </div>
                         </div> `;
  }

  private GetBandRadGroupOptions(responseFromSPA: any) {
    let spaHelper = this;
    let items = ["Green", "Yellow", "Red", "Manual"];

    let options: DevExpress.ui.dxRadioGroupOptions = {
      items: items,
      layout: "vertical",

      itemTemplate: (data, index, element) => {
        let thisone = data;

        if (data === "Manual") {
          element.append(
            $("<div id='spa-manual' />").append(`
                        <ul class="nav nav-tabs card-header-tabs">
                          <li class="nav-item active">
                            <a class="nav-link " href="#man-price" data-toggle="tab">Manual Price</a>
                          </li>     
                          <li class="nav-item">
                            <a class="nav-link disabled" href="#man-gm" data-toggle="tab">Target GM%</a>
                          </li>
                        </ul>
                      </div>
                    <div class="tab-content">
                        <div role="tabpanel" class="tab-pane active panel panel-body" id="man-price">
                            <div class="input-group">
                                <div class="input-group-addon">$</div>
                            <input class="form-control" id="spa-manual-price"/> 
                                </div>
                          <div class="hidden text-white" id="spa-manual-price-result">

                           </div>
                        </div>
                    <div role="tabpanel" class="tab-pane panel  panel-body" id="man-gm">
                            <div class="input-group">
                                <div class="input-group-addon">%</div>
                           <input class="form-control" id="spa-manual-gm"/> 
                            </div>
                          <div class="hidden text-white" id="spa-manual-gm-result">
                           
                    </div>`)
          );
        } else {
          let container = $(`<div id='spa-${data.toLowerCase()}-contianer'/>`)
            .addClass("card-block text-white")
            .append($(`<div id="spa-${data.toLowerCase()}"/>`));
          Object.keys(responseFromSPA).map(k => {
            if (k.indexOf("Band") > -1) {
              Object.keys(responseFromSPA[k]).map(key => {
                if (key === data) {
                  let el;
                  if (k.indexOf("Commission") > -1) {
                    if (
                      GetCompanyPreference(
                        "ShowCommissionValues"
                      ) === "1"
                    ) {
                      el = $(
                        `<div id="${key.toLowerCase()}-${k
                          .toLowerCase()
                          .replace("band", "")}" class="card-subtitle"/>`
                      ).text(
                        Globalize.formatCurrency(
                          responseFromSPA[k][key],
                          CurrentUser.Currency
                        ) + " Commission"
                      );
                      container.append(el);
                    }
                  } else {
                    if (k.indexOf("Break") > -1) {
                      el = $(
                        `<div id="${key.toLowerCase()}-${k
                          .toLowerCase()
                          .replace("band", "")}"class="card-subtitle"/>`
                      ).text(
                        Globalize.formatNumber(responseFromSPA[k][key] / 100, {
                          style: "percent"
                        }) + " B/E Volume"
                      );
                      container.append(el);
                    } else {
                      el = $(
                        `<h3 id="${key.toLowerCase()}-${k
                          .toLowerCase()
                          .replace("band", "")}"class="card-title"/>`
                      ).text(
                        Globalize.formatCurrency(
                          responseFromSPA[k][key],
                          CurrentUser.Currency
                        )
                      );
                      container.append(el);
                    }
                  }
                  element.parent().addClass(`spa-radio-${key.toLowerCase()}`);
                } // $(`#spa-${key.toLowerCase()}`).append(el)

                element.append(container);
              });
            }
          });
        }
      },
      onValueChanged(e) {
        if (e.value !== "Manual") {
          spaHelper.SelectedColor = e.value;
          spaHelper.SelectedPrice = parseFloat(
            $(e.event.currentTarget)
              .find("#" + e.value.toLowerCase() + "-price")
              .html()
              .replace("$", "")
          );
        }
      }
    };
    return options;
  }

  Template(info): string {
    let thistemplate;

    if (info.contentID === "tdf-product-container") {
      let child = {
        title: "Additional Information",
        size: 8,
        contentID: "spa-additional-info",
        colr: "info"
      };

      thistemplate = `<div  class="col-sm-${info.size}">
                                        <div class="bs-callout bs-callout-${
        info.colr
        } ">
                                            <h4 class="card-title">${
        info.title
        }</h4> 
                                          <div class="spa-block spa-block-${
        info.colr
        }">                                                    
                                             <div id="${
        info.contentID
        }" class="highlight "></div>                                          
                                          </div>
                                        </div>
                                        <div class="bs-callout bs-callout-${
        child.colr
        } ">
                                            <h4 class="card-title">${
        child.title
        }</h4> 
                                          <div class="spa-block spa-block-${
        child.colr
        }">                                                    
                                             <div id="${
        child.contentID
        }" class="highlight "></div>                                          
                                          </div>
                                        </div>
                                      </div>`;
    } else {
      if (info.contentID === "tdf-product-img-container") {
        let visibility = "collapse";
        if (info.url) {
          visibility = "in";
        }
        thistemplate = `<div id="${info.contentID}" class="col-sm-${
          info.size
          } ${visibility}">
                                        <div class="bs-callout bs-callout-${
          info.colr
          } ">
                                            <h4 class="card-title">${
          info.title
          }</h4> 
                                          <div class="spa-block spa-block-${
          info.colr
          }">
                                                  <img class="cust-logo" src="${info.url ||
          "no url"}" alt="Image Not Found"/>
                                                                                      
                                          </div>
                                        </div>
                                      </div>`;
      } else {
        thistemplate = `<div  class="col-sm-${info.size}">
                                        <div class="bs-callout bs-callout-${
          info.colr
          } ">
                                            <h4 class="card-title">${
          info.title
          }</h4> 
                                          <div class="spa-block spa-block-${
          info.colr
          }">
                                                    <div id='last-trans'></div>
                                             <div id="${
          info.contentID
          }" class="highlight "></div>                                          
                                          </div>
                                        </div>
                                      </div>`;
      }
    }
    //if (info.title === "SPA Price Bands") {
    //    thistemplate = `<div id="${info.contentID}" />`
    //}

    return thistemplate;
  }

  TranslateFields(name): string {
    switch (name) {
      case "SKU":
        return "ItemCode";
      case "ProductName":
        return "AssociatedProductName";
      case "ProductFamily":
        return "ProductGroup";
      case "CustomerName":
        return "CompanyID";
      case "VendorID":
        return "Vendor";
      case "Vendor":
        return "CompanyID";
    }
    return name;
  }
}

export class PointbookHelper {
  private _Quote: Quote;
  AvailablePointbooks: any;
  Summary: any;
  PointSlider: DevExpress.ui.dxSlider;
  PointSelector: DevExpress.ui.dxSelectBox;
  SetpointsButton: DevExpress.ui.dxButton;
  SegmentPoints: DevExpress.ui.dxDataGrid;
  CategoryPoints: DevExpress.ui.dxDataGrid;
  PointSelectionForm: DevExpress.ui.dxForm;
  PointSetup: any;

  constructor(quote: Quote) {
    let helper = this;
    helper._Quote = quote;
    QuoteDataService.PointsSetup()
      .done(function (response: any) {
        if (true) {
          helper.PointSetup = response;
        }
      });
  }

  GetAvailablePointbooks() {
    let helper = this;
    let d = $.Deferred();
    if (!helper.AvailablePointbooks) {
      QuoteDataService.PontBookForQuote(helper._Quote.ItemID)
        .done(function (response: any) {
          helper.AvailablePointbooks = response;
          d.resolve();
        });
    } else {
      d.resolve();
    }
    return d.promise();
  }

  GetSummary() {
    let helper = this;
    let d = $.Deferred();
    if (!helper.AvailablePointbooks) {
      QuoteDataService.QuoteSummary(helper._Quote.ItemID)
        .done(function (response: any) {
          helper.Summary = response;
          d.resolve();
        });
    } else {
      d.resolve();
    }
    return d.promise();
  }

  RenderPointSelection() {
    let helper = this;
    let currentPoints = 0;
    $.each(helper.Summary.SegmentPoints, function (key, val) {
      currentPoints += val.Value;
    });
    let opts: DevExpress.ui.dxPopupOptions = {
      visible: false,
      showCloseButton: true,
      fullScreen: GetDevice().isDevice,
      title: "Use the slider to adjust your desired points.",
      toolbarItems: [
        {
          toolbar: "bottom",
          location: "after",
          widget: "dxButton",
          options: {
            text: "Set Points to " + currentPoints,
            type: "success",
            icon: "todo",
            elementAttr: { id: "setpoints" },
            onClick: function (e) {
              let value = helper.PointSelector.option("value");
              let mode = $.grep(
                helper.PointSetup.AllowableUpdateModes,
                function (val: any) {
                  return val.ID === value;
                }
              )[0];
              /* 
                            new TDFRequest({
                              url: helper._Quote.BaseQuotePath + "TrySetPointsForQuote/",
                              data: {
                                quoteid: helper._Quote.ItemID,
                                desiredPoints:
                                  helper.PointSlider.option("value") || currentPoints,
                                preview: true,
                                updateMode: mode
                              },
                              type: "POST"
                            })
                              .MakeRequest() */
              QuoteDataService.TrySetPointsForQuote(helper._Quote.ItemID, helper.PointSlider.option("value") || currentPoints, mode)
                .done(function (response: any) {
                  if (true) {
                    new Notification({
                      message: response,
                      type: "info",
                      displayTime: 5000,
                      shading: true,
                      position: { my: "center" }
                    });
                    window.location.reload();
                    //helper._Quote.Refresh()
                  } else {
                    new Notification({
                      message: response.Message,
                      type: "error",
                      displayTime: 5000,
                      shading: true,
                      position: { my: "center" }
                    });
                  }
                });
            }
          }
        }
      ],
      contentTemplate: function (element) {
        element.append(helper.SelectionForm());
        $(element).dxScrollView({ height: $(window).innerHeight() * 0.2 });
      }
    };

    if (!$("#pointselection").length) {
      $("#tdfbody").append($("<div id='pointselection'/>"));
      $("#pointselection")
        .dxPopup(opts)
        .dxPopup("instance")
        .show()
        .done(function () {
          helper.CategoryPoints = <DevExpress.ui.dxDataGrid>helper.PointSelectionForm.getEditor(
            "categorypoints"
          );
          helper.PointSelector = <DevExpress.ui.dxSelectBox>helper.PointSelectionForm.getEditor(
            "pointselector"
          );
          helper.PointSlider = <DevExpress.ui.dxSlider>helper.PointSelectionForm.getEditor(
            "pointslider"
          );
          helper.SegmentPoints = <DevExpress.ui.dxDataGrid>helper.PointSelectionForm.getEditor(
            "segmentpoints"
          );
          helper.SetpointsButton = $("#setpoints").dxButton("instance");
        });
    } else {
      $("#pointselection")
        .dxPopup("instance")
        .toggle(
          !$("#pointselection")
            .dxPopup("instance")
            .option("visible")
        );
    }
  }

  SelectionForm() {
    let helper = this;

    let opts: DevExpress.ui.dxFormOptions = {
      items: helper.PointSelectionFormItems(),
      colCountByScreen: {
        lg: 2,
        md: 2,
        sm: 2,
        xs: 1
      }
    };

    helper.PointSelectionForm = $("<div />")
      .dxForm(opts)
      .dxForm("instance");
    return helper.PointSelectionForm.element();
  }

  PointSelectionFormItems() {
    let helper = this;
    let currentPoints = 0;
    $.each(helper.Summary.SegmentPoints, function (key, val) {
      currentPoints += val.Value;
    });
    let items = [
      {
        editorType: "dxSlider",
        colSpan: 2,
        editorOptions: {
          min: helper.Summary.MinPoints,
          max: helper.Summary.MaxPoints,
          value: currentPoints,
          step: 0.1,
          label: {
            visible: true
          },
          onValueChanged: function (e) {
            if (!helper.PointSlider) {
              helper.PointSlider = e.component;
            }
            helper.SetpointsButton.option("text", "Set Points to " + e.value);
          }
        },
        label: { visible: false },
        name: "pointslider"
      },
      {
        editorType: "dxSelectBox",
        colSpan: 2,
        editorOptions: {
          dataSource: helper.PointSetup.AllowableUpdateModes,
          displayExpr: "Description",
          valueExpr: "ID",
          value: helper.Summary.LastUpdateMode
          //value: helper.Summary.LastUpdateMode ? $.grep(helper.PointSetup.AllowableUpdateModes, function (val: any) { return val.ID === helper.Summary.LastUpdateMode })[0] :
          //    $.grep(helper.PointSetup.AllowableUpdateModes, function (val: any) { return val.ID === "STANDARD"})[0]
        },
        label: { visible: false },
        name: "pointselector"
      },

      {
        editorType: "dxDataGrid",
        colSpan: 1,
        editorOptions: {
          dataSource: helper.Summary.SegmentPoints,
          columns: [
            {
              caption: "Segment",
              dataField: "Key"
            },
            {
              caption: "# Points",
              dataField: "Value"
            }
          ]
        },
        label: { visible: false },
        name: "segmentpoints"
      },
      {
        editorType: "dxDataGrid",
        colSpan: 1,
        editorOptions: {
          dataSource: helper.Summary.CategoryPoints,
          columns: [
            {
              caption: "Category",
              dataField: "Key"
            },
            {
              caption: "# Points",
              dataField: "Value"
            }
          ]
        },
        label: { visible: false },
        name: "categorypoints"
      }
    ];
    return items;
  }
}

export class QuoteFinanceHelper {
  private _Quote: Quote;
  Plans: Array<IFinancePlan>;
  SetPlanOptions: ISetFinancePlanOptions;
  SelectionDialog: Dialog;
  SelectionForm: DevExpress.ui.dxForm;

  PlanSelector: DevExpress.ui.dxSelectBox;
  IncludedSegmentGrid: DevExpress.ui.dxDataGrid;
  Terms: DevExpress.ui.dxSelectBox;
  Factor: DevExpress.ui.dxTextBox;
  Monthly: DevExpress.ui.dxTextBox;
  Preview: DevExpress.ui.dxDataGrid;
  AmountFinanced: DevExpress.ui.dxTextBox;
  constructor(quote: Quote) {
    let helper = this;
    helper._Quote = quote;
  }
  GetFinancePlans() {
    let helper = this;
    if (!helper.Plans) {
      /* new TDFRequest({
        url:
          helper._Quote.BaseQuotePath +
          "GetFinancePlans/?" +
          $.param({ quoteid: helper._Quote.ItemID })
      }).MakeRequest() */
      QuoteDataService.FinancePlans(helper._Quote.ItemID)
        .done(function (response: any) {
          if (true) {
            helper.Plans = response.Plans;
            helper.SetPlanOptions = response.SetFinancePlanOptions;
            helper.RenderFinanceSelection();
          } else {
          }
        });
    } else {
      helper.RenderFinanceSelection();
    }
  }

  RenderFinanceSelection() {
    let helper = this;
    /* new TDFRequest({
      url:
        helper._Quote.BaseQuotePath +
        "GetFinancePlanForQuote/?" +
        $.param({ quoteid: helper._Quote.ItemID })
    }).MakeRequest() */

    QuoteDataService.FinancePlanForQuote(helper._Quote.ItemID)
      .done(function (response: ISelectedPlan) {
        if (true) {
          helper._Quote.SelectedPlan = response
            ? response
            : null;
        }

        helper.SelectionForm = $("<div />")
          .css("padding", "20px")
          .dxForm(<DevExpress.ui.dxFormOptions>{
            formData: helper._Quote.SelectedPlan,
            items: helper.FinanceFormItems(),
            labelLocation: "top",
            colCount: 1
          }).dxForm("instance");

        helper.SelectionDialog = new Dialog({
          title: 'Financing Options',
          closable: true,
          fullScreen: GetDevice().isDevice,
          buttons: [
            {
              toolbar: "bottom",
              location: "before",
              widget: "dxButton",
              options: {
                text: "Clear",
                type: "default",
                icon: 'trash',
                onClick: function (e) {
                  $.when(helper.ConfirmClearFinanceData()).done(function (clear) {
                    if (clear) {
                      /* new TDFRequest({
                        url:
                          helper._Quote.BaseQuotePath +
                          "ClearFinancePlanForQuote/",
                        data: { quoteid: helper._Quote.ItemID }
                      }).MakeRequest() */
                      QuoteDataService.ClearFinancePlan(helper._Quote.ItemID)
                        .done(function (response: any) {
                          if (true) {
                            $("#btnFinance")
                              .dxButton("instance")
                              .option("text", "Financing");
                            helper.SelectionDialog.close();
                            helper.ClearForm();
                          }
                        });
                    }
                  });
                }
              }
            },
            {
              toolbar: "bottom",
              location: "after",
              widget: "dxButton",
              options: {
                text: "OK",
                type: "success",
                icon: 'check',
                onClick: function (e) {
                  QuoteDataService.ApplyFinancePlan(helper._Quote.ItemID, helper.SetPlanOptions)
                    .done(function (response: any) {
                      if (true) {
                        $("#btnFinance")
                          .dxButton("instance")
                          .option("text", helper.SetPlanOptions.PlanName);
                        helper.SelectionDialog.close();
                      }
                    });
                }
              }
            },
            {
              toolbar: "bottom",
              location: "after",
              widget: "dxButton",
              options: {
                text: "Cancel",
                type: "danger",
                icon: 'remove',
                onClick: function () {
                  helper.SelectionDialog.close();
                  helper.ClearForm();
                }
              }
            }
          ],
          body: helper.SelectionForm.element()
        });

        helper.SetFinancePlanFormEditors();

        helper.SelectionDialog.open().done(function () {
          helper.SetFinancePlanFormEditors();

          if (helper._Quote.SelectedPlan.PlanOptions.PlanID) {
            helper.PlanSelector.option(
              "value",
              helper._Quote.SelectedPlan.PlanOptions.PlanID
            );
            let theplan = $.grep(helper.Plans, function (val) {
              return val.ID === helper._Quote.SelectedPlan.PlanOptions.PlanID;
            });
            let theterms = theplan[0].AvailableTerms;
            if (theterms) {
              helper.Terms.option(
                "value",
                $.grep(theterms, function (val: any) {
                  return (
                    helper._Quote.SelectedPlan.PlanOptions.Terms ===
                    val.TermInMonths
                  );
                })[0]
              );
            }
          } else {
            helper.PlanSelector.open();
            helper.PlanSelector.element().addClass("flash");
          }
        });
      });
  }

  SetFinancePlanFormEditors() {
    let helper = this;

    helper.PlanSelector = <DevExpress.ui.dxSelectBox>helper.SelectionForm.getEditor(
      "Plans"
    );
    helper.Factor = <DevExpress.ui.dxTextBox>helper.SelectionForm.getEditor(
      "Factor"
    );
    helper.Monthly = <DevExpress.ui.dxTextBox>helper.SelectionForm.getEditor(
      "Monthly"
    );
    helper.Preview = <DevExpress.ui.dxDataGrid>helper.SelectionForm.getEditor(
      "Preview"
    );
    helper.Terms = <DevExpress.ui.dxSelectBox>helper.SelectionForm.getEditor(
      "Terms"
    );
    helper.AmountFinanced = <DevExpress.ui.dxTextBox>helper.SelectionForm.getEditor(
      "TotalFinanced"
    );
    helper.IncludedSegmentGrid = <DevExpress.ui.dxDataGrid>helper.SelectionForm.getEditor(
      "ItemsToInclude"
    );
  }

  ClearForm() {
    let helper = this;
    helper.PlanSelector.option("value", "");
    helper.Factor.option("value", "");
    helper.Monthly.option("value", "");
    helper.Preview.option("dataSource", []);
    helper.Terms.option("value", "");
    helper.AmountFinanced.option("value", "");
    helper.IncludedSegmentGrid.option("dataSource", []);
  }

  FinanceFormItems(): DevExpress.ui.dxFormGroupItem[] {
    let helper = this;
    let selected = [];
    if (!helper.SetPlanOptions.IncludeSegments) {
      helper.SetPlanOptions.IncludeSegments = [];
    }

    let segmentDS = [];
    $.each(helper._Quote.SegmentHelper.Segments, function (k, v: any) {
      let seg = $.grep(
        helper._Quote.SelectedPlan.PlanOptions.IncludeSegments,
        function (val: any) {
          return val.SegmentID === v.SegmentID;
        }
      );

      if (seg.length) {
        v.IncludeMode = (<any>seg[0]).IncludeMode;
      } else {
        v.IncludeMode = v.DefaultIncludeInLease;
      }
      segmentDS.push(v);
    });

    //$.each(helper._Quote.SelectedPlan.PlanOptions.IncludeSegments, function (k, v: any) {

    //    let seg = $.grep(helper._Quote.SegmentHelper.Segments, function (val) {
    //        return val.SegmentID === v.SegmentID;
    //    });
    //    if (seg.length) {
    //        v.IncludeMode = (<any>seg[0]).IncludeMode
    //    }

    //    segmentDS.push(v)

    //});
    //$.each(helper._Quote.SegmentHelper.Segments, function (key, val) {

    //    if (val.DefaultIncludeInLease === 1) {
    //        selected.push(val.SegmentID);
    //        helper.SetPlanOptions.IncludeSegments.push(val)
    //    }
    //});

    let IncludeOptions = [];
    $.each(IncludeInLeaseEnum, function (k, v) {
      if (parseInt(k) || parseInt(k) === 0) {
        IncludeOptions.push({
          Value: k,
          Text:
            parseInt(k) === IncludeInLeaseEnum.Exclude
              ? v
              : parseInt(k) === IncludeInLeaseEnum.IncludeInCalculation
                ? "Include in lease calculation"
                : "Add to monthly total"
        });
      }
    });

    return <DevExpress.ui.dxFormGroupItem[]>[
      {
        itemType: "group",
        colCount: 2,
        caption: "Select which items to include in the lease calculation",
        items: [
          {
            name: "ItemsToInclude",
            editorType: "dxDataGrid",
            colSpan: 2,
            editorOptions: {
              editing: {
                mode: "cell",
                allowUpdating: true,
                allowAdding: false,
                allowDeleting: false
              },
              dataSource: {
                store: {
                  data: segmentDS, //helper._Quote.SegmentHelper.Segments,
                  type: "array",
                  key: "SegmentID"
                }
              },
              onSelectionChanged: function (data) {
                helper.SetPlanOptions.IncludeSegments = data.selectedRowsData;
              },
              onRowUpdated: function (e) {
                let total = helper._Quote.SelectedPlan.GrandTotal; //helper.AmountFinanced.option("value").toString().replace("$", "");
                //  total = Globalize.parseNumber(total, { style: "decimal" });
                let segment;
                switch (parseInt(e.data.IncludeMode)) {
                  case IncludeInLeaseEnum.Exclude:
                    segment = $.grep(
                      helper._Quote.SelectedPlan.SegmentSubtotals,
                      function (key: any) {
                        return key.Key === e.key;
                      }
                    );
                    total -= segment[0].Value;
                    helper.AmountFinanced.option(
                      "value",
                      Globalize.formatCurrency(total, CurrentUser.Currency)
                    );
                    helper.UpdatePlanUI();

                    break;
                  case IncludeInLeaseEnum.IncludeInCalculation:
                    segment = $.grep(
                      helper._Quote.SelectedPlan.SegmentSubtotals,
                      function (key: any) {
                        return key.Key === e.key;
                      }
                    );
                    total += segment[0].Value;
                    helper.AmountFinanced.option(
                      "value",
                      Globalize.formatCurrency(total, CurrentUser.Currency)
                    );
                    helper.UpdatePlanUI();

                    break;
                  case IncludeInLeaseEnum.IncludeAsMonthlyLump:
                    segment = $.grep(
                      helper._Quote.SelectedPlan.SegmentSubtotals,
                      function (key: any) {
                        return key.Key === e.key;
                      }
                    );
                    total -= segment[0].Value;
                    helper.AmountFinanced.option(
                      "value",
                      Globalize.formatCurrency(total, CurrentUser.Currency)
                    );
                    helper.UpdatePlanUI(segment[0].Value);
                    break;
                }
              },
              selectedRowKeys: selected,
              selection: { mode: "multiple", showCheckBoxesMode: "never" },
              showColumnHeaders: false,
              columns: [
                {
                  dataField: "IncludeMode",
                  caption: "",
                  width: 175,
                  lookup: {
                    dataSource: IncludeOptions,
                    displayExpr: "Text",
                    valueExpr: "Value"
                  },
                  allowEditing: true
                },
                {
                  dataField: "Title",
                  allowEditing: false
                }
              ]
            }
          },
          {
            name: "TotalFinanced",
            label: { text: "Financed Amount" },
            editorType: "dxTextBox",
            colSpan: 1,
            editorOptions: {
              disabled: true,
              elementAttr: { style: "opacity:.8" },
              value: Globalize.formatCurrency(
                helper._Quote.SelectedPlan.GrandTotal,
                CurrentUser.Currency
              )
            }
          }
        ]
      },
      {
        itemType: "group",
        caption: "Select a Finance Program",
        colCount: 2,
        items: [
          {
            name: "Plans",
            editorType: "dxSelectBox",
            colSpan: 2,
            editorOptions: {
              items: helper.Plans,
              displayExpr: "Name",
              valueExpr: "ID",
              onValueChanged: function (e) {
                if (!e.value) return;
                let selectedplan = $.grep(helper.Plans, function (val) {
                  return val.ID === e.value;
                });

                helper.SetPlanOptions.PlanID = selectedplan[0].ID;
                helper.SetPlanOptions.PlanName = selectedplan[0].Name;
                helper.Terms.option(
                  "dataSource",
                  selectedplan[0].AvailableTerms
                );
                setTimeout(function () {
                  if (!helper.Terms.option("value")) {
                    helper.Terms.element().addClass("flash");
                    helper.Terms.open();
                  }
                }, 100);
              }
            }
          },
          {
            name: "Terms",
            label: { text: "No. of Months" },
            editorType: "dxSelectBox",
            colSpan: 1,
            editorOptions: {
              items: [],
              displayExpr: "TermInMonths",
              // valueExpr: "TermInMonths",
              onValueChanged: function (e) {
                helper.Factor.option("value", e.value.MoneyRate);
                let addToMonthly = 0;
                $.each(segmentDS, function (k, v) {
                  if (
                    v.IncludeMode === IncludeInLeaseEnum.IncludeAsMonthlyLump
                  ) {
                    let segment = $.grep(
                      helper._Quote.SelectedPlan.SegmentSubtotals,
                      function (key: any) {
                        return key.Key === v.SegmentID;
                      }
                    );
                    if (segment.length) {
                      addToMonthly = segment[0].Value;
                    }
                  }
                });
                helper.UpdatePlanUI(addToMonthly);
              }
            }
          },
          {
            name: "Factor",
            label: { text: "$ Factor" },
            editorType: "dxTextBox",
            colSpan: 1,
            editorOptions: {
              disabled: true,
              elementAttr: { style: "opacity:.8" }
            }
          }
        ]
      },
      {
        itemType: "group",
        caption: "Monthly Payment",
        colCount: 2,
        items: [
          {
            label: { text: "Amount" },
            name: "Monthly",
            editorType: "dxTextBox",
            colSpan: 1,
            editorOptions: {
              disabled: true,
              elementAttr: { style: "opacity:.8" }
            }
          },
          {
            name: "Preview",
            label: { text: "Preview" },
            editorType: "dxDataGrid",
            colSpan: 1,
            editorOptions: {
              dataSource: [],
              selection: { mode: "multiple", showCheckBoxesMode: "never" },
              showColumnHeaders: true,
              onRowPrepared: function (info) {
                let total = helper.AmountFinanced.option("value")
                  .toString()
                  .replace("$", "");
                if (
                  info.rowType === "data" &&
                  info.data["Total Value"] <
                  Globalize.parseNumber(total, { style: "decimal" })
                ) {
                  info.rowElement.addClass("bg-danger").attr({
                    title: "The terms WILL NOT cover the total amount financed."
                  });
                  if (helper.Monthly.option("value") === info.cells[1].text) {
                    info.rowElement.addClass("dangerflash");
                  }
                }
                if (
                  info.rowType === "data" &&
                  info.data["Total Value"] >=
                  Globalize.parseNumber(total, { style: "decimal" })
                ) {
                  info.rowElement.addClass("bg-success").attr({
                    title: "The terms WILL cover the total amount financed."
                  });
                  if (helper.Monthly.option("value") === info.cells[1].text) {
                    info.rowElement.addClass("successflash");
                  }
                }
              },
              columns: [
                {
                  dataField: "Months",
                  dataType: "number"
                },
                {
                  dataField: "Payment",
                  dataType: "number",
                  format: {
                    type: "currency",
                    precision: 2
                  }
                },
                {
                  dataField: "Total Value",
                  dataType: "number",
                  format: {
                    type: "currency",
                    precision: 2
                  }
                }
              ]
            }
          }
        ]
      }
    ];
  }

  UpdatePlanUI(addToMonthly?) {
    let helper = this;

    let terms = helper.Terms.option("value");

    let previewdata = [];
    let total = helper.AmountFinanced.option("value")
      .toString()
      .replace("$", "");

    total = Globalize.parseNumber(total, { style: "decimal" });
    let selectedplan = $.grep(helper.Plans, function (val) {
      return val.ID === helper.PlanSelector.option("value");
    });
    if (!selectedplan.length) return;
    $.each(selectedplan[0].AvailableTerms, function (k, v) {
      let includedsegments = helper.IncludedSegmentGrid.option("items");

      let payment = v.MoneyRate * total;
      previewdata.push({
        Months: v.TermInMonths,
        Payment: payment,
        "Total Value": payment * v.TermInMonths
      });
    });
    helper.Preview.option("dataSource", previewdata);
    helper.Preview.refresh();

    let selectedMonthlyTotal = terms.MoneyRate * total;
    if (addToMonthly) {
      selectedMonthlyTotal += addToMonthly;
    }

    helper.Monthly.option(
      "value",
      Globalize.formatCurrency(selectedMonthlyTotal, CurrentUser.Currency)
    );
    if (selectedMonthlyTotal * terms.TermInMonths < total) {
      helper.Monthly.element()
        .find(".dx-texteditor-input")
        .addClass("bg-danger");
    } else {
      helper.Monthly.element()
        .find(".dx-texteditor-input")
        .removeClass("bg-danger")
        .addClass("bg-success");
    }

    helper.SetPlanOptions.CurrentMonthlyPayment = selectedMonthlyTotal;
    helper.SetPlanOptions.Rate = terms.MoneyRate;
    helper.SetPlanOptions.Terms = terms.TermInMonths;
    // TODO: Need to find out how to know if i should allow the user to override the rate and make it happen
    helper.SetPlanOptions.IsRateOverridden = false;
  }

  ConfirmClearFinanceData() {
    let helper = this;
    let d = $.Deferred();
    let message: any = {};
    message.text = $("<span />").text(
      "Are you sure you want to clear all financing data for this item?"
    );
    message.buttons = $("<div />")
      .append(
        $("<span />")
          .css("margin", "0 5px")
          .append(
            $("<div />").dxButton({
              text: "Yes",
              icon: "save",
              type: "success",
              onClick: function (e: any) {
                let toast = $($(e.element[0]).parents())
                  .find(
                    ".dx-overlay.dx-widget.dx-visibility-change-handler.dx-toast"
                  )
                  .dxToast("instance");
                toast.hide();

                d.resolve(true);
              }
            })
          )
      )
      .append(
        $("<span />")
          .css("margin", "0 5px")
          .append(
            $("<div />").dxButton({
              text: "No",
              icon: "check",
              type: "default",
              onClick: function (e: any) {
                let toast = $($(e.element[0]).parents())
                  .find(
                    ".dx-overlay.dx-widget.dx-visibility-change-handler.dx-toast"
                  )
                  .dxToast("instance");
                toast.hide();
                d.resolve(false);
              }
            })
          )
      );
    new Notification({
      type: "warning",
      shading: true,
      displayTime: 2000000,
      message: message
    });

    return d.promise();
  }
}

export class QuoteTotalsHelper {
  private _Quote: Quote;
  private readonly GetExtendedTotalPath: string = "GetExtendedTotal/";
  private readonly ExtendedTotalContainer: string = "tdfquoteextendedtotalcontainer";
  private readonly UpdateFreightPath: string = "UpdateFreight/";
  private readonly UpdateQuotePropertyPath: string = "UpdateQuoteProperty/";

  private isQuoteLocked(): boolean {
    // Other reasons these editors should be locked?
    return (this._Quote.QuoteDetails.IsPreviousRevision === true)
  }

  PreviewItems = [
    { field: "SubTotalTotal", text: "Sub Total:", css: "hidden d-sm-inline" },
    {
      field: "AdditionalDiscountTotal",
      text: "Add. Discount:",
      css: "hidden d-sm-inline"
    },
    {
      field: "SubTotalAfterDiscountTotal",
      text: "Sub Total w/Discount:",
      css: ""
    },
    { field: "BaseTaxTotal", text: "Tax:", css: "" },
    { field: "SurTaxTotal", text: "Sur Tax", css: "" },
    { field: "FreightTotal", text: "Freight:", css: "hidden d-sm-inline" },
    { field: "DepositTotal", text: "Deposit:", css: "hidden d-sm-inline" },
    { field: "TotalMinusDepositTotal", text: "Due:", css: "" }
  ];

  TDFFooter: JQuery;
  TotalsPreview: JQuery;
  TotalsPreviewFooter: JQuery;
  ExtendedTotalPopup: JQuery;

  FinancePlanButton: DevExpress.ui.dxButton;
  PointsButton: DevExpress.ui.dxButton;
  PopupEditButton: DevExpress.ui.dxButton;
  // EditButton: DevExpress.ui.dxButton;
  ExtendedTotalEditForm: DevExpress.ui.dxForm;
  FinanceHelper: QuoteFinanceHelper;
  PointbookHelper: PointbookHelper;
  ExtendedTotalData: IExtendedTotal;
  constructor(quote: Quote) {
    let helper = this;
    helper._Quote = quote;
    helper.TDFFooter = GetDevice().isDevice ? $('#tdfnavbar') : $("#tdffooter");
    helper.TotalsPreview = $("<div />").addClass("text-right quote-footer");
    helper.TotalsPreviewFooter = $("<span />").appendTo(helper.TotalsPreview);
    helper.TDFFooter.prepend(helper.TotalsPreview);
    helper.FinanceHelper = new QuoteFinanceHelper(quote);
    helper.FinancePlanButton = $("<div />")
      .dxButton({
        height: 30,
        text: "Financing",
        icon: "money",
        type: "success",
        elementAttr: {
          style: "margin:0 5px; font-size:12px",
          id: "btnFinance"
        },
        onClick: function () {
          helper.FinanceHelper.GetFinancePlans();
        }
      })
      .dxButton("instance");
    helper.PopupEditButton = $("<div />")
      .dxButton({
        height: 30,
        icon: "edit",
        type: "success",
        elementAttr: { style: "margin:0 5px; font-size:12px" },
        onClick: function () {
          helper.RenderExtendedTotal(true);
        }
      })
      .dxButton("instance");
    //helper.EditButton = $("<div />").dxButton({
    //    height: 30,
    //    icon: "spindown", elementAttr: { style: "margin:0 5px; font-size:12px", class: "pull-left" }, onClick: function () { helper.RenderExtendedTotal(false) }
    //}).dxButton("instance");

    if (helper._Quote.PointBooksEnabled) {
      helper.PointbookHelper = helper._Quote.PointbookHelper;

      helper.PointsButton = $("<div />")
        .dxButton({
          height: 30,
          text: "PointBook",
          icon: "tips",
          type: "default",
          elementAttr: {
            style: "margin:0 5px; font-size:12px",
            id: "btnPoints"
          },
          onClick: function () {
            $.when(
              helper.PointbookHelper.GetAvailablePointbooks(),
              helper.PointbookHelper.GetSummary()
            ).done(function () {
              helper.PointbookHelper.RenderPointSelection();
            });
          }
        })
        .dxButton("instance");
    }

    //helper.TotalsPreviewFooter.append(helper.PointsButton.element(),helper.FinancePlanButton.element(), helper.PopupEditButton.element(), helper.EditButton.element());
    helper.ExtendedTotalPopup = $("<div id='extpop' />").appendTo("#tdfbody");

    //    $("#tdfbody").dxScrollView("instance").option("height", $(window).innerHeight() - ($("#tdfnavbar").innerHeight() + $("#tdffooter").innerHeight()));

    // let mainscroll = $("#tdfbody").dxScrollView("instance");
    //  mainscroll.option("height", mainscroll.option("height") - 30);
  }

  RenderExtPrev() {
    let helper = this;
    let container = $("#totalspreview");
    if (!container.length) {
      container = $("<span id='totalspreview' />").prependTo(
        helper.TotalsPreview
      );
    }
    /* new TDFRequest({
      url:
        helper._Quote.BaseQuotePath +
        "GetPlanButtonDisplayText/?" +
        $.param({ quoteid: helper._Quote.ItemID })
    }) */


    if (!GetDevice().isDevice) {
      helper.TotalsPreviewFooter.append(
        helper.PointsButton ? helper.PointsButton.element() : "",
        helper._Quote.QuoteOptions.FinanceOptions.EnableFinancingOptions
          ? helper.FinancePlanButton.element()
          : "",
        helper.PopupEditButton.element()
      );

      $.when(
        helper.GetExtendedTotal(),
        helper._Quote.QuoteOptions.FinanceOptions.EnableFinancingOptions
          ? QuoteDataService.PlanButtonDisplay(helper._Quote.ItemID)
          : $.noop()
      ).done(function (Quote, textResponse: any) {
        if (true) {
          helper.FinancePlanButton.option("text", textResponse);
        }
        $("#totalspreview").empty();

        $.each(helper.PreviewItems, function (k, v) {
          container.append(
            $("<span />")
              .addClass(v.css)
              .append($("<label />").text(v.text))
              .append(
                $("<span />")
                  .addClass("money")
                  .text(
                    Globalize.formatCurrency(
                      Globalize.parseNumber(
                        helper.ExtendedTotalData.Results[v.field].replace(
                          "$",
                          ""
                        ),
                        { style: "decimal" }
                      ),
                      CurrentUser.Currency
                    )
                  )
              )
          );
        });
      });
      $("#tdfquote").css("margin-bottom", helper.TDFFooter.innerHeight() + 5);
    } else {
      helper.TotalsPreviewFooter.append(
        helper.PointsButton ? helper.PointsButton.element() : "",
        helper.FinancePlanButton.element(),
        helper.PopupEditButton.element()
      );

      $.when(helper.GetExtendedTotal(), helper._Quote.QuoteOptions.FinanceOptions.EnableFinancingOptions
        ? QuoteDataService.PlanButtonDisplay(helper._Quote.ItemID)
        : $.noop()).done(function (
          Quote,
          textresponse: any
        ) {
          if (true) {
            helper.FinancePlanButton.option("text", "Plan...");
            helper.FinancePlanButton.element().attr("title", textresponse);
          }

          if (helper.PointsButton)
            $(helper.PointsButton.element().children()[0]).css({
              padding: "5px 10px"
            });
          if (helper.FinancePlanButton)
            $(helper.FinancePlanButton.element().children()[0]).css({
              padding: "5px 10px"
            });

          if (helper.PopupEditButton)
            $(helper.PopupEditButton.element().children()[0]).css({
              padding: "5px 10px"
            });

          // helper.TotalsPreviewFooter.append(helper.PointsButton.element(), helper.FinancePlanButton.element(), helper.PopupEditButton.element());
        });

      $("#tdfquote").css("margin-top", helper.TDFFooter.innerHeight() + 15);
      $("#tdfquote").css("margin-bottom", $("#tdfnavbar").innerHeight() + 15);
    }

    return helper;
  }

  GetExtendedTotal() {
    let helper = this;

    let d = $.Deferred();
    if (helper.ExtendedTotalData) {
      return d.promise(d.resolve(helper._Quote));
    }
    // let request = new TDFRequest({
    //   url: helper._Quote.BaseQuotePath + helper.GetExtendedTotalPath,
    //   data: { quoteid: helper._Quote.ItemID },
    //   type: "GET"
    // });
    // request.spinnerContainerID = helper.ExtendedTotalContainer;

    QuoteDataService.GetExtendedTotal(helper._Quote.ItemID)
      .done(function (response: IExtendedTotal) {
        if (response && typeof response === 'object') {
          if (response) {
            helper.ExtendedTotalData = response;
            d.resolve(helper._Quote);
          }
        } else {
          if (response && typeof response === 'string') {
            new Notification({
              message: response,
              type: "error",
              displayTime: 5000,
              closeOnClick: true
            });
          } else {
            new Notification({
              message: "Unkown Error",
              type: "error",
              displayTime: 5000,
              closeOnClick: true
            });
          }

          d.resolve(helper._Quote);
        }
        helper.ExtendedTotalData = response;
      });

    return d.promise();
  }

  ConvertExtendedValues() {
    let helper = this;

    $.each(helper.ExtendedTotalData, function (key: string, val) {
      $.each(val, function (key1, val1: any) {
        let currency = $.inArray(key1, QuoteResultForm.CurrencyFields);
        let percent = $.inArray(key1, QuoteResultForm.PercentageFields);
        if (currency >= 0) {
          val1 = val1 === null ? 0 : val1;
          helper.ExtendedTotalData[key][key1] =
            typeof val1 === "string"
              ? Globalize.formatCurrency(
                Globalize.parseNumber(val1.replace("$", ""), {
                  style: "decimal"
                }),
                CurrentUser.Currency
              )
              : Globalize.formatCurrency(val1, CurrentUser.Currency);
        }
        if (percent >= 0) {
          val1 = val1 === null ? 0 : val1;
          helper.ExtendedTotalData[key][key1] =
            typeof val1 === "string"
              ? parseInt(val1.replace("%", "")) < 1
                ? parseInt(val1.replace("%", "")) * 100
                : parseInt(val1.replace("%", ""))
              : val1 < 1
                ? val1 * 100
                : val1;
        }
      });
    });
  }

  RenderExtendedTotal(popover) {
    let helper = this;

    helper.ConvertExtendedValues();

    let opts: DevExpress.ui.dxFormOptions = {
      formData: helper.ExtendedTotalData,
      items: [],
      disabled: helper._Quote.QuoteDetails.Status === 0,
      labelLocation: "left",
      onFieldDataChanged: function (e: any) { }
    };
    let item: DevExpress.ui.dxFormGroupItem;
    $.each(QuoteResultForm.groupkeys, (key, val) => {
      item = QuoteResultForm[val];

      $.each(item.items, function (key, formitem: any) {
        if (formitem.ChangeAction) {
          let field = formitem.dataField;

          formitem.editorOptions.onValueChanged = function (e) {
            if (!e.jQueryEvent) return;
            let changed: boolean = false;
            let oldVal = parseInt(e.previousValue);
            let newVal = parseInt(e.value);
            changed = oldVal !== newVal;

            changed
              ? helper[formitem.ChangeAction](e, field, formitem.IsRateField)
              : $.noop;
          };
        }
        if (formitem.dataField === "Results.SubTotalTotal") {
          formitem.editorOptions.onFocusIn = function (e) {
            let pop;

            if (!$("#subtotaledit").length) {
              pop = $("<div id='subtotaledit'/>").appendTo("#tdfbody");
            }
            let popup = $("#subtotaledit")
              .dxPopup({
                contentTemplate: function (element: JQuery) {
                  let newtotaledit = $("<div />")
                    .dxTextBox({
                      value: e.component.option("value"),
                      onValueChanged: function (e) {
                        // new TDFRequest({
                        //   url:
                        //     helper._Quote.BaseQuotePath +
                        //     "UpdateLineItemsToHitTarget/",
                        //   data: {
                        //     quoteid: helper._Quote.ItemID,
                        //     amount: e.value
                        //   }
                        // })
                        //   .MakeRequest()
                        QuoteDataService.UpdateLinesToHitTarget(helper._Quote.ItemID, e)
                          .done(function (response: any) {
                            if (true) {
                              if (response) {
                                window.location.reload();
                                //  helper.ExtendedTotalData = response.ExtendedTotalData;
                                // helper.UpdateData();
                                // helper._Quote.QuoteProducts = response.QuoteProducts;
                                //helper._Quote.;
                              } else {
                                new Notification({
                                  message: response.Message,
                                  displayTime: 4000,
                                  type: "warning"
                                });
                              }
                            } else {
                              new Notification({
                                message: response.Message,
                                displayTime: 4000,
                                type: "error"
                              });
                            }
                          });
                      }
                    })
                    .dxTextBox("instance");
                  newtotaledit.element().appendTo(element);
                },
                title: "Enter a target sub-total",
                height: 200,
                width:
                  $(window).innerWidth() > 500 ? 500 : $(window).innerWidth(),
                toolbarItems: [
                  {
                    location: "after",
                    toolbar: "bottom",
                    widget: "dxButton",
                    options: {
                      text: "OK",
                      onClick: function (e) {
                        popup.hide();
                      }
                    }
                  }
                ]
              })
              .dxPopup("instance");

            popup.show();
          };
        }
        if (
          formitem.editorOptions &&
          !formitem.editorOptions.hasOwnProperty("disabled")
        ) {
          formitem.editorOptions.disabled =
            helper._Quote.QuoteDetails.Status === 0;
        } else {
          if (formitem.editorOptions && formitem.editorOptions.disabled) {
            formitem.editorOptions.elementAttr = {
              class: "quoteExtTotalDisable"
            };
          }
        }
      });

      if (item) {
        opts.items.push(item);
      }
    });
    if (popover) {
      let optspop: DevExpress.ui.dxPopupOptions = {
        title: "Totals",
        showCloseButton: true,
        fullScreen: GetDevice().isDevice,
        contentTemplate: function (element) {
          element
            .append(
              $("<div />")
                .addClass("alert alert-warning")
                .text("Values in this section are saved automatically! ")
            )
            .append($("<div id='" + helper.ExtendedTotalContainer + "' />"));
          $(element).dxScrollView({ height: $(window).innerHeight() * 0.5 });
        }
      };
      $("#extpop")
        .dxPopup(optspop)
        .dxPopup("instance")
        .show();
    } else {
      $("#tdfquote")
        .append(
          $("<div />")
            .addClass("alert alert-warning")
            .text("Values in this section are saved automatically! ")
        )
        .append($("<div id='" + helper.ExtendedTotalContainer + "' />"));
      // TODO: Get the heights correct.
      // $("#tdfbody").dxScrollView("instance").option("height", $(window).innerHeight() - $("#tdfnavbar").innerHeight() - $("#tdffooter").innerHeight())
    }

    helper.ExtendedTotalEditForm = $("#" + helper.ExtendedTotalContainer)
      .dxForm(opts)
      .dxForm("instance");

    //new Notification({
    //    message: "Values in this section are saved automatically! ",
    //    type: "warning",
    //    displayTime: 5000,
    //    shading: false,
    //    position: "center"
    //});
  }

  QuotePropertyChanged(e, dataField, isRateField) {

    let helper = this;

    if (helper.isQuoteLocked() === true) {
      return;
    }

    let reg = new RegExp("UserProperties.|Results.|Global");
    let field = Item_Quotes_Fields[dataField.replace(reg, "")];
    if (typeof field !== "undefined") {
      helper.UpdateQuoteProperty(field, e.value, isRateField);
    }
  }

  UpdateQuoteProperty(thefield, thevalue, isRateField) {
    let helper = this;
    /* new TDFRequest({
      url: helper._Quote.BaseQuotePath + helper.UpdateQuotePropertyPath,
      data: {
        quoteid: helper._Quote.ItemID,
        field: thefield,
        value: thevalue,
        israte: isRateField
      },
      type: "POST"
    }).MakeRequest() */
    QuoteDataService.UpdateQuoteProperty(helper._Quote.ItemID, thefield, thevalue, isRateField)
      .done(function (response: any) {
        if (true) {
          helper.ExtendedTotalData = response;
          helper.UpdateData();
        }
      });
  }

  UpdateData() {
    let helper = this;

    if (helper.isQuoteLocked() === true) {
      return;
    }

    helper.ConvertExtendedValues();
    helper.ExtendedTotalEditForm.updateData(helper.ExtendedTotalData);
    helper.RenderExtPrev();
  }

  UpdateFreight() {
    let helper = this;

    if (helper.isQuoteLocked() === true) return;

    let inbound = helper.ExtendedTotalEditForm.option("formData").UserProperties
      .FreightInbound;
    let outbound = helper.ExtendedTotalEditForm.option("formData")
      .UserProperties.FreightOutbound;
    /* new TDFRequest({
      url: helper._Quote.BaseQuotePath + helper.UpdateFreightPath,
      data: {
        quoteid: helper._Quote.ItemID,
        freightinbound: inbound,
        freightoutbound: outbound
      },
      type: "POST"
    }).MakeRequest() */

    QuoteDataService.UpdateFreight(helper._Quote.ItemID, inbound, outbound)
      .done(function (response: any) {
        if (true) {
          helper.ExtendedTotalEditForm.updateData(response);
        }
      });
  }
}

class QuoteDocumentHelper implements IQuoteDocumentOptions {
  readonly LinkDocPath: string = "AttachDocumentToQuote/";
  readonly PreviewTemplatePath: string = "TemplatePreview/";

  AdvancedDocumentNamingOptions: IAdvancedQuoteDocumentNamingOptions;
  AllowPDFCreationOnly: boolean;
  AppearanceOptions: IQuoteDocumentAppearanceOptions;
  DefaultDraftCategory: IQuoteDefaultDraftCategory;
  DocumentCreationParentSearchOption: EnumDocumentCreationParentSearchOptions;
  GlobalDocumentExportOptions: EnumQuoteDocumentExportOptions;
  QuoteDocName: EnumQuoteDocName;
  ReadonlyDocumentCreationParentSearchOption: string;
  private _Quote: Quote;
  DocumentParentID: string;
  SelectedTemplateOptions: ILineItemExportOptions;
  DocSetupForm: DevExpress.ui.dxForm;
  TemplateSetupDialog: Dialog;
  ParentSearchDialog: Dialog;
  constructor(quote: Quote) {
    let dochelper: QuoteDocumentHelper = this;
    if (quote) {
      dochelper._Quote = quote;
    }
    $.each(dochelper._Quote.QuoteOptions.DocumentOptions, function (k, v) {
      (dochelper as any)[k] = v;
    });
    dochelper.GetDocumentParent();

    // if (!TDFRequest.ApiPath) {
    LoadCompany();
    // }
  }

  GetDocumentParent() {
    let dochelper = this;
    switch (dochelper.DocumentCreationParentSearchOption) {
      case EnumDocumentCreationParentSearchOptions.SelectChildContactOfParentAccount:
        dochelper.GetSearch(5);
        break;
      case EnumDocumentCreationParentSearchOptions.SelectArbitraryAccount:
        dochelper.GetSearch(1);
        break;
      case EnumDocumentCreationParentSearchOptions.BypassSearchAndUseParentAccount:
        dochelper.DocumentParentID = dochelper._Quote.iMain.ItemParentAccountId;
        if (dochelper.DocumentParentID) {
          dochelper.TemplateSetup();
        } else {
          dochelper.GetSearch(1);
        }
        break;
      case EnumDocumentCreationParentSearchOptions.BypassSearchAndUseParentContact:
        let parentContact = $.grep(
          dochelper._Quote.iMain.ItemParents,
          (val: any, key) => {
            return val.ParentType === itemTypes.itemContact;
          }
        );
        if (parentContact.length) {
          dochelper.DocumentParentID = parentContact[0].ParentId;
        }

        if (dochelper.DocumentParentID) {
          dochelper.TemplateSetup();
        } else {
          dochelper.GetSearch(5);
        }
        break;
    }
  }

  GetSearch(parenttype: itemTypes) {
    let dochelper = this;
    dochelper.ParentSearchDialog = new Dialog({
      id: "docgenparentsearch",
      body: $("<div id='tdfsearch'/>").append($("<div id='tdfcontextmenu'/>")),
      type: "type-info",
      closable: true,
      title: "Search"
    });
    dochelper.ParentSearchDialog.open().done(function () {
      // new TDFRequest({
      //   url: "/search/GetSearchSetup/?" + $.param({ type: parenttype }),
      //   type: "GET"
      // })
      // .MakeRequest()
      QuoteDataService.SearchSetup(parenttype)
        .done(function (response: any) {
          if (true) {
            response.SelectedType = parenttype;
            response.SelectedField = "CompanyName"; // ||response.DefaultSearchField;
            response.SearchText =
              parenttype === itemTypes.itemAccount
                ? dochelper._Quote.iMain.PrimaryParent.Parent
                : "";

            if (!response.SearchText) {
              let accnt = $.grep(dochelper._Quote.iMain.ItemParents, function (
                v: any
              ) {
                return v.ParentType === itemTypes.itemAccount;
              });
              if (accnt.length) {
                response.SearchText = accnt[0].Parent;
              }
            }
            response.DisableType = true;
            response.GridToolbar.CurrentView =
              response.GridToolbar.DefaultView;

            let search = new Search(response);
            search.Search();

            search.SelectionEvent = function (data) {
              dochelper.DocumentParentID =
                data.currentSelectedRowKeys[0]["TDF GUID"];

              // TODO: get some error handling in and alternative actions
              if (dochelper.DocumentParentID) {
                dochelper.TemplateSetup();
              }
            };
          }
        });
    });
  }

  TemplateSetup() {
    let dochelper = this;
    /* 
        new TDFRequest({
          url: "/QuoteManager/" + dochelper.DocumentTemplateSetupPath,
          data: { quoteid: dochelper._Quote.ItemID },
          type: "GET"
        }).MakeRequest()
         */
    QuoteDataService.DocSetup(dochelper._Quote.ItemID)
      .done(function (response: any) {
        if (true) {
          dochelper.SetupDialog(response);
        }
      });
  }

  SetupDialog(data) {
    let dochelper = this;

    let buttons = [
      {
        widget: "dxButton",
        toolbar: "bottom",
        location: "after",
        options: {
          icon: "save",
          type: "success",
          text: "Save",

          onClick: function () {
            // dialog.close();
            if (dochelper.DocSetupForm) {
              if (dochelper.GetSetupFormValues()) {
                dochelper.GenerateAndLinkDocument().done(() => {
                  dochelper.TemplateSetupDialog.close();
                  dochelper.ParentSearchDialog.close();
                });
              }
            }
          }
        }
      }
      //    {
      //        visible:false,
      //        widget: "dxButton",
      //        options: {

      //        icon: "",
      //        type: "success",
      //        text: "Preview",
      //         toolbar: "bottom",

      //        onClick: function () {
      //            // dialog.close();
      //            if (dochelper.DocSetupForm) {
      //                if (dochelper.GetSetupFormValues()) {

      //                }
      //            }
      //        }
      //    }
      //},
    ];
    dochelper.TemplateSetupDialog = new Dialog(<IDialogOptions>{
      id: "doctemplatesetup",
      body: $("<div />").append(dochelper.SetupForm(data).element()),
      type: "type-info",
      closable: true,
      title: "Document Setup",
      size: "size-normal",
      buttons: buttons
    });

    dochelper.TemplateSetupDialog.open().done(function () {
      dochelper.TemplateSetupDialog.enableButtons(false);

      //dochelper.ParentSearchDialog.close();
    });
  }

  GetSetupFormValues() {
    let dochelper = this;

    let validate: any = dochelper.DocSetupForm.validate();
    if (validate.isValid) {
      let optional = <DevExpress.ui.dxTagBox>dochelper.DocSetupForm.getEditor(
        "Optional"
      );
      let optsselcted = optional.option("value");

      dochelper.SelectedTemplateOptions = {
        TemplateID: parseInt(
          (<DevExpress.ui.dxSelectBox>dochelper.DocSetupForm.getEditor(
            "TemplateID"
          )).option("value")
        ),
        LayoutInfo: null,
        FileType: parseInt(
          (<DevExpress.ui.dxSelectBox>dochelper.DocSetupForm.getEditor(
            "FileType"
          )).option("value")
        ),
        QuoteID: dochelper._Quote.ItemID,
        Parent: dochelper.DocumentParentID,
        ExplodeKits: true,
        UsingPersonalLineItemView: !dochelper._Quote.LineItemHelper
          .GlobalLineScope, // TODO: and selected line items only????
        IncludeSelectedLineItemsOnly:
          $.inArray(
            EnumQuoteDocumentExportOptions.SelectedLinesOnly,
            optsselcted
          ) > -1,
        CurrencyPrecision:
          dochelper._Quote.QuoteOptions.GeneralOptions.CurrencyPrecision || 2,
        FileName: (<DevExpress.ui.dxSelectBox>dochelper.DocSetupForm.getEditor(
          "FileName"
        )).option("value")
      };
      return true;
    } else {
      return false;
    }

    //dialog.close();
    // TODO: Now create and link the doc to the quote...
  }

  SetupForm(data: ITemplateSetupOptions) {
    let dochelper = this;
    let optional = [];

    $.each(data.ExportOptions, function (key, val) {
      let t = {};
      $.each(val, function (k, v) {
        t[k] = v;
      });
      t["disabled"] = !val.EnableByDefault;
      optional.push(t);
    });
    let formitems: DevExpress.ui.dxFormSimpleItem[] = [
      {
        editorType: "dxSelectBox",
        name: "TemplateID",
        isRequired: true,
        label: {
          text: "Available Templates"
        },
        editorOptions: <DevExpress.ui.dxSelectBoxOptions>{
          items: data.AvailableTemplates,
          displayExpr: "MenuDisplayName",
          valueExpr: "TemplateID",
          onOpened: (e) => {
            $('.dx-dropdowneditor-overlay').css('z-index', e.element.css('z-index'))
          },
          onValueChanged: function (e) {
            dochelper.GetDefaultDocName(e.value).done(function (name) {
              (<DevExpress.ui.dxTextBox>dochelper.DocSetupForm.getEditor(
                "FileName"
              )).option("value", name);
            });
          }
        }
      },
      {
        editorType: "dxTextBox",
        name: "FileName",
        isRequired: true,
        label: {
          text: "File Name"
        },
        editorOptions: {
          //  value: dochelper.GetDefaultDocName(),
          //onValueChanged: function (e) {
          //    // dochelper.SelectedTemplateOptions.FileName = e.value;
          //}
        }
      },

      {
        editorType: "dxTagBox",
        name: "Optional",
        label: {
          text: "Optional Settings"
        },
        editorOptions: {
          items: optional,
          displayExpr: "Description",
          valueExpr: "Value",
          value: $.grep(data.ExportOptions, function (v: IExportOptions) {
            return v.CheckedByDefault;
          })[0],
          onValueChanged: function (e) {
            // if the option to export the current grid is selected ...(it value is 1) the enable the child option
            if ($.inArray(1, e.value) > -1) {
              let opt = $.grep(optional, function (v: IExportOptions | any) {
                return v.Value === 8;
              });
              if (opt.length) {
                opt[0].disabled = false;
              }
            }

            e.component.repaint();

            // TODO: What do i need to send, list of the values selected ?
            // dochelper.SelectedTemplateOptions.OptionalSettings = e.value;
          }
        }
      },
      {
        editorType: "dxRadioGroup",
        name: "FileType",
        isRequired: true,
        label: {
          text: "File Type"
        },
        editorOptions: {
          items: data.DocumentTypes,
          layout: "horizontal",
          displayExpr: "Value",
          valueExpr: "Key",
          //having this set when there is only one value will result in the inability to save or preview the doc
          //  value: dochelper.AllowPDFCreationOnly ? 2 : null,
          onValueChanged: function (e) {
            //let validate: any = dochelper.DocSetupForm.validate()
            if (dochelper.GetSetupFormValues()) {
              //  dochelper.TemplateSetupDialog.enableButtons(true);

              dochelper.GenerateDocumentPreview();
            }
          }
        }
      }
    ];

    dochelper.DocSetupForm = $("<div />")
      .dxForm({ items: formitems })
      .dxForm("instance");
    return dochelper.DocSetupForm;
  }

  GetDefaultDocName(templateid) {
    let dochelper = this;
    let d = $.Deferred();
    /* new TDFRequest({
      url:
        dochelper._Quote.BaseQuotePath +
        "GetQuoteDocumentName/?" +
        $.param({ templateid: templateid, quoteid: dochelper._Quote.ItemID })
    }).MakeRequest() */
    QuoteDataService.QuoteDocName(dochelper._Quote.ItemID, templateid)
      .done(function (response: any) {
        if (true) {
          d.resolve(response);
        } else {
        }
      });
    return d.promise();
  }

  GenerateAndLinkDocument() {
    let dochelper = this;
    let d: JQueryDeferred<void> = $.Deferred();
    /* new TDFRequest({
      url:
        dochelper._Quote.BaseQuotePath +
        dochelper.GenerateAndLinkDocumentPath +
        "?" +
        $.param(dochelper.SelectedTemplateOptions)
    }).MakeRequest() */
    QuoteDataService.GenerateAndLink(dochelper.SelectedTemplateOptions)
      .done(function (response: any) {
        if (true) {
          new Notification({
            message: "Success: The document has been created.",
            type: "success",
            displayTime: 5000
          });

          d.resolve();
        } else {
          new Notification({
            message: response.Message,
            type: "error",
            displayTime: 5000
          });
        }
      });

    return d.promise();
  }

  GenerateDocumentPreview() {
    let dochelper = this;

    let d: JQueryDeferred<void> = $.Deferred();

    //let footer = dochelper.TemplateSetupDialog.getModalFooter();
    let modalBody = dochelper.TemplateSetupDialog.getModalBody();
    //let buttons = footer.find(".bootstrap-dialog-footer-buttons");
    if (
      $(
        "#previewtemplate" +
        EnumDocumentTypes[dochelper.SelectedTemplateOptions.FileType]
      ).length
    ) {
      return;
    }

    let fakeReq = new TDFRequest({ url: 'fake' });
    fakeReq.GetToken().done(authToken => {

      let link = $("<a/>")
        .addClass("dx-button dx-button-info dx-button-mode-contained")
        .attr({
          download: "true",
          type: "application/octet-stream",
          id:
            "previewtemplate" +
            EnumDocumentTypes[dochelper.SelectedTemplateOptions.FileType],
          href: `${TDFRequest.ApiPath}/${
            dochelper._Quote.BaseQuotePath
            }${
            dochelper.PreviewTemplatePath
            }/?${
            $.param(dochelper.SelectedTemplateOptions)
            }&access_token=${authToken}`
          // dochelper._Quote.BaseQuotePath +
          // dochelper.PreviewTemplatePath +
          // "?" +
          // $.param(dochelper.SelectedTemplateOptions)
        })
        .text(
          "Preview " +
          EnumDocumentTypes[dochelper.SelectedTemplateOptions.FileType]
        )
        .css({ color: "#fff" })
        .append(
          $("<span />")
            .addClass("fa fa-eye")
            .css({ padding: "3px", color: "#fff" })
        )
        .on("click", function () {
          if ($("#uploadarea").length) return;

          let fileUploadOptions: DevExpress.ui.dxFileUploaderOptions = {
            accept: "/*",
            allowCanceling: true,
            elementAttr: { id: "uploadarea" },
            disabled: false,
            labelText: "or drop here after previewing.",
            multiple: true,
            uploadButtonText: "Upload",
            uploadUrl:
              dochelper._Quote.BaseQuotePath +
              dochelper.LinkDocPath +
              "?" +
              $.param({ quoteid: dochelper._Quote.ItemID }),
            visible: true,
            // selectButtonText: btnText,
            onUploaded: function () {
              dochelper.TemplateSetupDialog.close();
              new Notification({
                message: "Success: The document has been created.",
                type: "success",
                displayTime: 5000
              });
            }
          };
          modalBody.append($("<div />").dxFileUploader(fileUploadOptions));
          new Notification({
            message:
              "Note: The document has been created but has not been linked to this quote. Please continue to upload the file when you are ready.",
            type: "warning",
            displayTime: 15000
          });
        });

      modalBody.append(link);
    });

    return d.promise();
  }
}

export class ProductSearchHelper {
  private _Quote: Quote;
  ProductSearchForm: DevExpress.ui.dxForm;
  SearchDialog: Dialog;
  SearchSegment: ISegment;
  ProductsToAdd: Array<IDataProductWrapper> = [];
  RecentSearches: any[];
  constructor(quote: Quote) {
    let helper = this;
    helper._Quote = quote;
  }

  GetSelectedProductColumns() {
    let helper = this;
    /*
			 * I could just use the GetProductSearchColumns , but maybe i should anticipate
			 * needing to do something different with these.
			 */
    let cols: Array<DevExpress.ui.dxDataGridColumn> = [
      { dataField: "Ave_Turns", visible: false, allowEditing: false },
      {
        dataField: "AverageCost",
        visible: false,
        dataType: "number",
        format: {
          type: "currency",
          precision:
            helper._Quote.QuoteOptions.GeneralOptions.CurrencyPrecision,
          currency: CurrentUser.Currency
        }
      },
      {
        dataField: "Accessories",
        caption: "# Accessories",
        cellTemplate(element, data) {
          element
            .append($("<span/>").text(data.text))
            .addClass("btn btn-warning");
        },
        editCellTemplate: function (element, rowinfo) {
          let selectedproduct: IDataProduct = rowinfo.data;
          /* 
                    new TDFRequest({
                      url: helper._Quote.BaseQuotePath + "/GetAccesoriesForPoduct/",
                      data: {
                        companyID: selectedproduct.CompanyID, //selectedproductrow.data["CompanyID"],
                        itemcode: selectedproduct.ItemCode, //rowinfo.data["ItemCode"],
                        warehouseid: selectedproduct.WarehouseId // rowinfo.data["WarehouseId"]
                      }
                    })
                      .MakeRequest() */
          QuoteDataService.ProductAccessories(selectedproduct)
            .done(function (response: any) {
              let tdata: Array<any> =
                response.Required && response.Required.length
                  ? response.Required
                  : response.Optional && response.Optional.length
                    ? response.Optional
                    : [];
              let accessorycols: DevExpress.ui.dxDataGridColumn[] =
                tdata && tdata.length > 0
                  ? Object.keys(tdata[0]).map((v, k) => {
                    return { dataField: v, visible: true };
                  })
                  : [];

              function common(opts): DevExpress.ui.dxDataGridOptions {
                opts.columns = accessorycols;
                opts.width = "100%";
                opts.height = "100%";
                opts.columnResizingMode = "widget";
                opts.allowColumnResizing = true;
                opts.columnMinWidth = 75;
                opts.showBorders = true;
                opts.showRowLines = true;
                return opts;
              }
              if (true) {
                let selectedProductWrapper: IDataProductWrapper = {};
                selectedProductWrapper.IncludeInSummary =
                  selectedproduct.IncludeInSummary;
                selectedProductWrapper.Product = selectedproduct;
                selectedProductWrapper.GUID = selectedproduct.TempGUID; //rowinfo.data["TempGUID"];
                selectedProductWrapper.ItemCode = selectedproduct.ItemCode;
                selectedProductWrapper.ItemCodeAlternative =
                  selectedproduct.ItemCodeAlternative;
                selectedProductWrapper.ProductGroup =
                  selectedproduct.ProductGroup;
                selectedProductWrapper.Vendor = selectedproduct.Vendor;
                helper.ProductsToAdd.push(selectedProductWrapper);

                let optionalAccessoriesGrid = $("<div/>")
                  .dxDataGrid(
                    common({
                      dataSource: response.Optional,
                      editing: {
                        allowDeleting: true,
                        texts: { confirmDeleteMessage: "", deleteRow: "" }
                      },
                      selection: {
                        mode: "single"
                      },
                      onSelectionChanged: function (e) {
                        if (e.selectedRowsData.length > 0) {
                          let t = e.selectedRowsData[0];
                          let ds: Array<any> = selectedAccessoriesGrid.option(
                            "dataSource"
                          );
                          ds.push(t);
                          selectedAccessoriesGrid.refresh();
                          (<DevExpress.ui.dxDataGrid>e.component).clearSelection();
                        } else {
                          (<DevExpress.ui.dxDataGrid>e.component).deleteRow(
                            (<DevExpress.ui.dxDataGrid>e.component).getRowIndexByKey(
                              e.currentDeselectedRowKeys[0]
                            )
                          );
                        }
                      }
                    })
                  )
                  .dxDataGrid("instance");

                let selectedAccessoriesGrid = $("<div/>")
                  .dxDataGrid(
                    common({
                      dataSource: response.Required,
                      editing: {
                        allowDeleting: true,
                        texts: {
                          confirmDeleteMessage: "Cancel adding this accessory?",
                          deleteRow: "Remove"
                        }
                      },
                      onRowRemoving(e) {
                        if (e.data.IsRequired) {
                          e.cancel = true;
                          new Notification({
                            message: "Required Accessories can not be removed.",
                            type: "error"
                          });
                        } else {
                          optionalAccessoriesGrid.option(
                            "dataSource",
                            optionalAccessoriesGrid
                              .getDataSource()
                              .items()
                              .concat([e.data])
                          );
                        }
                      }
                    })
                  )
                  .dxDataGrid("instance");

                let AccessorySelectionDialog = new Dialog({
                  body: $("<div/>")
                    .addClass("row")
                    .append(
                      $("<div />")
                        .addClass("col-md-6")
                        .append($("<h3 />").text("All Available Accessories"))
                        .append(optionalAccessoriesGrid.element())
                    )
                    .append(
                      $("<div />")
                        .addClass("col-md-6")
                        .append($("<h3 />").text("Selected Accessories"))
                        .append(selectedAccessoriesGrid.element())
                    ),
                  buttons: [
                    {
                      widget: "dxButton",
                      location: "after",
                      toolbar: "bottom",
                      options: {
                        text: "Add Accessories",

                        type: "success",

                        onClick: function () {
                          let selectedAccessories: any[] = selectedAccessoriesGrid.option(
                            "dataSource"
                          );

                          $.each(selectedAccessories, (k, v) => {
                            let wrapper: IDataProductWrapper = {};
                            wrapper.Product = v;
                            if (!v.CompanyID) {
                              v.CompanyID = selectedproduct.CompanyID;
                              if (v.companyID) {
                                delete v.companyID;
                              }
                            }
                            wrapper.IsAccessoryTo = selectedproduct.TempGUID;
                            wrapper.ItemCode = wrapper.Product.ItemCode;
                            wrapper.ItemCodeAlternative =
                              wrapper.Product.ItemCodeAlternative;
                            wrapper.ProductGroup = wrapper.Product.ProductGroup;
                            wrapper.Vendor = wrapper.Product.Vendor;
                            wrapper.IncludeInSummary =
                              wrapper.Product["IncludeInSummary"];
                            helper.ProductsToAdd.push(wrapper);
                          });
                          //  helper.SearchDialog.setData("Accessories", selectedAccessoriesGrid.option("dataSource"));

                          let foot: JQuery = helper.SearchDialog.getModalFooter();
                          if ($("#numaccessories").length > 0) {
                            $("#numaccessories").text(
                              selectedAccessories.length +
                              parseInt($("#numaccessories").text())
                            );
                          } else {
                            $($(foot.children()[0]).children()[0]).append(
                              $("<span />")
                                .addClass("pull-left")
                                .append(
                                  $("<span id='numaccessories' />").text(
                                    selectedAccessories.length
                                  )
                                )
                                .append(
                                  $("<span />").text(" Additional Accessories ")
                                )
                            );
                          }
                          if (selectedAccessories) {
                            if (!(selectedAccessories.length > 0)) {
                              new Notification({
                                message: "No Accessories Selected",
                                type: "warning"
                              });
                            }
                          }
                          AccessorySelectionDialog.close();
                        },

                        icon: "check"
                      }
                    }
                  ],
                  title: "Select Accessories to Include With this Product",
                  closable: true
                });
                AccessorySelectionDialog.open();
              }
            });
        },
        visibleIndex: 0
      },
      { dataField: "CompanyID", visible: false },
      {
        dataField: "Cost",
        visible: false,
        visibleIndex: 3,
        dataType: "number",
        format: {
          type: "currency",
          precision:
            helper._Quote.QuoteOptions.GeneralOptions.CurrencyPrecision,
          currency: CurrentUser.Currency
        }
      },
      { dataField: "CustomProduct", visible: false, dataType: "boolean" },
      { dataField: "Delivery", visible: false },
      { dataField: "Description", visibleIndex: 3, allowEditing: false },
      { dataField: "IncludeInSummary", visible: false },
      {
        dataField: "Number of Lines",
        visibleIndex: 0,
        visible: true,
        dataType: "number"
      },
      { dataField: "ItemCode", visibleIndex: 1, allowEditing: false },
      {
        dataField: "ItemCodeAlternative",
        visibleIndex: 2,
        allowEditing: false
      },
      { dataField: "LikeProducts", visible: false },
      { dataField: "Link", visible: false },
      {
        dataField: "ListPrice",
        visible: false,
        visibleIndex: 4,
        dataType: "number",
        format: {
          type: "currency",
          precision:
            helper._Quote.QuoteOptions.GeneralOptions.CurrencyPrecision,
          currency: CurrentUser.Currency
        }
      },
      { dataField: "MinimumMargin", visible: false },
      {
        dataField: "NetPrice",
        visible: false,
        dataType: "number",
        format: {
          type: "currency",
          precision:
            helper._Quote.QuoteOptions.GeneralOptions.CurrencyPrecision,
          currency: CurrentUser.Currency
        }
      },
      { dataField: "OptionalProduct", visible: false },
      {
        dataField: "Price",
        allowEditing: false,
        dataType: "number",
        format: {
          type: "currency",
          precision:
            helper._Quote.QuoteOptions.GeneralOptions.CurrencyPrecision,
          currency: CurrentUser.Currency
        }
      },
      { dataField: "ProductGroup", allowEditing: false },
      { dataField: "QtyAllocated", dataType: "number", allowEditing: false },
      { dataField: "QtyOnHand", dataType: "number", allowEditing: false },
      { dataField: "QtyOnOrder", dataType: "number", allowEditing: false },
      {
        dataField: "RebateCost",
        visible: false,
        dataType: "number",
        format: {
          type: "currency",
          precision:
            helper._Quote.QuoteOptions.GeneralOptions.CurrencyPrecision,
          currency: CurrentUser.Currency
        }
      },
      {
        dataField: "ReplacementCost",
        visible: false,
        dataType: "number",
        format: {
          type: "currency",
          precision:
            helper._Quote.QuoteOptions.GeneralOptions.CurrencyPrecision,
          currency: CurrentUser.Currency
        }
      },
      { dataField: "ResourceURL1", visible: false },
      { dataField: "ResourceURL2", visible: false },
      { dataField: "ResourceURL3", visible: false },
      { dataField: "StockStatus", visible: false },
      { dataField: "UOM", visible: false },
      { dataField: "User1", visible: false },
      { dataField: "User2", visible: false },
      { dataField: "User3", visible: false },
      { dataField: "User4", visible: false },
      { dataField: "User5", visible: false },
      { dataField: "User6", visible: false },
      { dataField: "User7", visible: false },
      { dataField: "User8", visible: false },
      { dataField: "User9", visible: false },
      { dataField: "User10", visible: false },
      { dataField: "User11", visible: false },
      {
        dataField: "UserCurrency1",
        visible: false,
        dataType: "number",
        format: {
          type: "currency",
          precision:
            helper._Quote.QuoteOptions.GeneralOptions.CurrencyPrecision,
          currency: CurrentUser.Currency
        }
      },
      {
        dataField: "UserCurrency2",
        visible: false,
        dataType: "number",
        format: {
          type: "currency",
          precision:
            helper._Quote.QuoteOptions.GeneralOptions.CurrencyPrecision,
          currency: CurrentUser.Currency
        }
      },
      {
        dataField: "UserCurrency3",
        visible: false,
        dataType: "number",
        format: {
          type: "currency",
          precision:
            helper._Quote.QuoteOptions.GeneralOptions.CurrencyPrecision,
          currency: CurrentUser.Currency
        }
      },
      {
        dataField: "UserCurrency4",
        visible: false,
        dataType: "number",
        format: {
          type: "currency",
          precision:
            helper._Quote.QuoteOptions.GeneralOptions.CurrencyPrecision,
          currency: CurrentUser.Currency
        }
      },
      {
        dataField: "UserCurrency5",
        visible: false,
        dataType: "number",
        format: {
          type: "currency",
          precision:
            helper._Quote.QuoteOptions.GeneralOptions.CurrencyPrecision,
          currency: CurrentUser.Currency
        }
      },
      {
        dataField: "UserDate1",
        visible: false,
        dataType: "date",
        format: "shortDate"
      },
      {
        dataField: "UserDate2",
        visible: false,
        dataType: "date",
        format: "shortDate"
      },
      {
        dataField: "UserDate3",
        visible: false,
        dataType: "date",
        format: "shortDate"
      },
      {
        dataField: "UserDate4",
        visible: false,
        dataType: "date",
        format: "shortDate"
      },
      {
        dataField: "UserDate5",
        visible: false,
        dataType: "date",
        format: "shortDate"
      },
      { dataField: "UserNumeric1", visible: false, dataType: "number" },
      { dataField: "UserNumeric2", visible: false, dataType: "number" },
      { dataField: "UserNumeric3", visible: false, dataType: "number" },
      { dataField: "UserNumeric4", visible: false, dataType: "number" },
      { dataField: "UserNumeric5", visible: false, dataType: "number" },
      { dataField: "Vendor", visible: false },
      { dataField: "WarehouseId", visible: false }
    ];

    $.each(cols, function (key, val) {
      $.each(helper._Quote.QuoteOptions.DataProductSearchCaptions, function (
        k,
        v
      ) {
        if (k === val.dataField) {
          v && v !== val.dataField ? (val.caption = v) : (val.visible = false);
        }
      });
    });
    return cols;
  }

  GetProductSearchColumns() {
    let helper = this;

    let cols: Array<DevExpress.ui.dxDataGridColumn> = [
      { dataField: "Ave_Turns" },
      {
        dataField: "Accessories",
        caption: "# Accessories",
        editCellTemplate: function (element, data) {
          /*    new TDFRequest({
               url: helper._Quote.BaseQuotePath + "/GetAccesoriesForPoduct/",
               data: {
                 companyID: data.data["CompanyID"],
                 itemcode: data.data["ItemCode"],
                 warehouseid: data.data["WarehouseId"]
               }
             })
               .MakeRequest() */

          QuoteDataService.ProductAccessories({
            companyID: data.data["CompanyID"],
            itemcode: data.data["ItemCode"],
            warehouseid: data.data["WarehouseId"]
          })
            .done(function (response: any) {
              if (true) {
                // debugger
              }
            });
        },
        visibleIndex: 0
      },
      {
        dataField: "AverageCost",
        dataType: "number",
        format: {
          type: "currency",
          precision:
            helper._Quote.QuoteOptions.GeneralOptions.CurrencyPrecision,
          currency: CurrentUser.Currency
        }
      },
      { dataField: "CompanyID" },
      {
        dataField: "Cost",
        visibleIndex: 3,
        dataType: "number",
        format: {
          type: "currency",
          precision:
            helper._Quote.QuoteOptions.GeneralOptions.CurrencyPrecision,
          currency: CurrentUser.Currency
        }
      },
      { dataField: "CustomProduct", dataType: "boolean" },
      { dataField: "Delivery" },
      { dataField: "Description", visibleIndex: 2 },
      { dataField: "IncludeInSummary" },
      { dataField: "ItemCode", visibleIndex: 0 },
      { dataField: "ItemCodeAlternative", visibleIndex: 1 },
      { dataField: "LikeProducts" },
      { dataField: "Link", visible: false },
      {
        dataField: "ListPrice",
        visibleIndex: 4,
        dataType: "number",
        format: {
          type: "currency",
          precision:
            helper._Quote.QuoteOptions.GeneralOptions.CurrencyPrecision,
          currency: CurrentUser.Currency
        }
      },
      { dataField: "MinimumMargin" },
      {
        dataField: "NetPrice",
        dataType: "number",
        format: {
          type: "currency",
          precision:
            helper._Quote.QuoteOptions.GeneralOptions.CurrencyPrecision,
          currency: CurrentUser.Currency
        }
      },
      { dataField: "OptionalProduct" },
      {
        dataField: "Price",
        dataType: "number",
        format: {
          type: "currency",
          precision:
            helper._Quote.QuoteOptions.GeneralOptions.CurrencyPrecision,
          currency: CurrentUser.Currency
        }
      },
      { dataField: "ProductGroup" },
      { dataField: "QtyAllocated", dataType: "number" },
      { dataField: "QtyOnHand", dataType: "number" },
      { dataField: "QtyOnOrder", dataType: "number" },
      {
        dataField: "RebateCost",
        dataType: "number",
        format: {
          type: "currency",
          precision:
            helper._Quote.QuoteOptions.GeneralOptions.CurrencyPrecision,
          currency: CurrentUser.Currency
        }
      },
      {
        dataField: "ReplacementCost",
        dataType: "number",
        format: {
          type: "currency",
          precision:
            helper._Quote.QuoteOptions.GeneralOptions.CurrencyPrecision,
          currency: CurrentUser.Currency
        }
      },
      { dataField: "ResourceURL1" },
      { dataField: "ResourceURL2" },
      { dataField: "ResourceURL3" },
      { dataField: "StockStatus" },
      { dataField: "UOM" },
      { dataField: "User1" },
      { dataField: "User2" },
      { dataField: "User3" },
      { dataField: "User4" },
      { dataField: "User5" },
      { dataField: "User6" },
      { dataField: "User7" },
      { dataField: "User8" },
      { dataField: "User9" },
      { dataField: "User10" },
      { dataField: "User11" },
      {
        dataField: "UserCurrency1",
        dataType: "number",
        format: {
          type: "currency",
          precision:
            helper._Quote.QuoteOptions.GeneralOptions.CurrencyPrecision,
          currency: CurrentUser.Currency
        }
      },
      {
        dataField: "UserCurrency2",
        dataType: "number",
        format: {
          type: "currency",
          precision:
            helper._Quote.QuoteOptions.GeneralOptions.CurrencyPrecision,
          currency: CurrentUser.Currency
        }
      },
      {
        dataField: "UserCurrency3",
        dataType: "number",
        format: {
          type: "currency",
          precision:
            helper._Quote.QuoteOptions.GeneralOptions.CurrencyPrecision,
          currency: CurrentUser.Currency
        }
      },
      {
        dataField: "UserCurrency4",
        dataType: "number",
        format: {
          type: "currency",
          precision:
            helper._Quote.QuoteOptions.GeneralOptions.CurrencyPrecision,
          currency: CurrentUser.Currency
        }
      },
      {
        dataField: "UserCurrency5",
        dataType: "number",
        format: {
          type: "currency",
          precision:
            helper._Quote.QuoteOptions.GeneralOptions.CurrencyPrecision,
          currency: CurrentUser.Currency
        }
      },
      { dataField: "UserDate1", dataType: "date", format: "shortDate" },
      { dataField: "UserDate2", dataType: "date", format: "shortDate" },
      { dataField: "UserDate3", dataType: "date", format: "shortDate" },
      { dataField: "UserDate4", dataType: "date", format: "shortDate" },
      { dataField: "UserDate5", dataType: "date", format: "shortDate" },
      { dataField: "UserNumeric1", dataType: "number" },
      { dataField: "UserNumeric2", dataType: "number" },
      { dataField: "UserNumeric3", dataType: "number" },
      { dataField: "UserNumeric4", dataType: "number" },
      { dataField: "UserNumeric5", dataType: "number" },
      { dataField: "Vendor" },
      { dataField: "WarehouseId" }
    ];

    $.each(cols, function (key, val) {
      $.each(helper._Quote.QuoteOptions.DataProductSearchCaptions, function (
        k,
        v
      ) {
        if (k === val.dataField) {
          v && v !== val.dataField ? (val.caption = v) : (val.visible = false);
        }
      });
    });
    return cols;
  }

  GetProductSearchGridOptions() {
    let helper = this;
    let gridopts: DevExpress.ui.dxDataGridOptions = {
      //columns: helper.GetProductSearchColumns(),
      columnAutoWidth: true,
      elementAttr: { class: "productSearchGrids hidden" },
      pager: {
        visible: true,
        allowedPageSizes: [10, 20, 30, 50, 100],
        showInfo: true,
        showNavigationButtons: true
      },
      searchPanel: { visible: true, highlightSearchText: true },
      onRowClick: function (e) {
        if (
          !e.isSelected &&
          e.event.target.id.indexOf("drilldownActionButton") === -1
        )
          (e.component as DevExpress.ui.dxDataGrid).selectRows(
            e.key,
            e.jQueryEvent.ctrlKey
          );
        else (e.component as DevExpress.ui.dxDataGrid).deselectRows(e.key);
      },
      showBorders: true,
      showColumnLines: true,
      filterRow: {
        visible: true,
        applyFilter: "auto"
      },
      sorting: {
        mode: "multiple"
      },
      // headerFilter: { visible: true },
      selection: {
        mode: "multiple",
        allowSelectAll: false,
        showCheckBoxesMode: "always"
      },
      allowColumnResizing: true,
      columnResizingMode: "widget",
      columnChooser: { enabled: true, allowSearch: true },
      // height: 400,
      onSelectionChanged: function (data: any) {
        if (data.currentSelectedRowKeys.length) {
          let thisCopy = CloneIT(data.currentSelectedRowKeys[0]);
          thisCopy["Number of Lines"] = 1;
          thisCopy["TempGUID"] = GimmeGUID();
          /* new TDFRequest({
            url: helper._Quote.BaseQuotePath + "/GetAccesoriesCountForPoduct/",
            data: {
              companyID: thisCopy["CompanyID"],
              itemcode: thisCopy["ItemCode"],
              warehouseid: thisCopy["WarehouseId"]
            }
          })
            .MakeRequest() */

          QuoteDataService.ProductAccessoryCount(thisCopy)
            .done(function (response) {
              let selecteditems: DevExpress.ui.dxDataGrid = <DevExpress.ui.dxDataGrid>helper.ProductSearchForm.getEditor(
                "SelectedProducts"
              );
              let ds = selecteditems.option("dataSource");

              thisCopy["Accessories"] = response;
              if (!ds) {
                selecteditems.option("dataSource", [thisCopy]);
              } else {
                ds.push(thisCopy);
              }

              selecteditems.refresh();
              data.component.clearSelection();
              (helper.SearchDialog.Instance.content()
                .find(".dx-scrollview")
                .dxScrollView(
                  "instance"
                ) as DevExpress.ui.dxScrollView).scrollTo(
                  selecteditems.element().offset().top
                );
            });
        }
      },
      onContentReady(e) {



        e.component.option(
          "width",
          e.component
            .element()
            .parent()
            .innerWidth() - 20
        );
      }
    };
    return gridopts;
  }

  GetProductSearchForm(): JQueryPromise<DevExpress.ui.dxForm | any> {
    let helper = this;
    let dfd = $.Deferred();

    let opts: DevExpress.ui.dxDataGridOptions = {
      columnAutoWidth: true,
      elementAttr: { class: "productSearchGrids hidden" },
      pager: {
        visible: true,
        allowedPageSizes: [10, 20, 30, 50, 100],
        showInfo: true,
        showNavigationButtons: true
      },
      showBorders: false,
      showColumnLines: false,
      editing: {
        allowDeleting: true,
        allowUpdating: true,
        mode: "cell",
        texts: {
          deleteRow: "Remove",
          confirmDeleteMessage: "",
          saveAllChanges: "Set number of lines for these products?",
          saveRowChanges: "Set # of lines?"
        }
      },
      sorting: {
        mode: "multiple"
      },
      filterRow: {
        visible: true,
        applyFilter: "auto"
      },
      columns: helper.GetSelectedProductColumns(),
      width: "100%",
      //height: 200,
      noDataText: "Nothing  selected"
    };
    QuoteDataService.LineItemSearchOptions()

      .done(function (response) {
        let formItems = <any[]>[
          {
            itemType: "simple",
            editorType: "dxSelectBox",
            dataField: "SearchString",
            label: {
              text: "Search For",
              visible: true
            },
            colSpan: 5,
            editorOptions: {
              mode: "search",
              acceptCustomValue: true,
              searchEnabled: true,
              showDataBeforeSearch: true,
              minSearchLength: 2,
              searchTimeout: 500,
              displayExpr: "SearchValue",
              ValueExpr: "SearchValue",
              dataSource: new dxDataSource({
                store: new dxCustomStore({
                  load(opts) {
                    let d = $.Deferred();
                    if (!helper.RecentSearches) {
                      /* 
                                          new TDFRequest({
                                            url: `${
                                              helper._Quote.BaseQuotePath
                                              }/GetRecentProductSearches`
                                          })
                                            .MakeRequest() */
                      QuoteDataService.RecentProductSearches()
                        .done(data => {
                          helper.RecentSearches = data;
                          if (opts.searchValue) {
                            d.resolve(
                              helper.RecentSearches.filter(k => {
                                return (
                                  k[opts.searchExpr as any].indexOf(
                                    opts.searchValue.toLowerCase()
                                  ) >= 0
                                );
                              })
                            );
                          } else {
                            d.resolve(helper.RecentSearches);
                          }
                        });
                    } else {
                      if (opts.searchValue) {
                        d.resolve(
                          helper.RecentSearches.filter(k => {
                            return (
                              k[opts.searchExpr as any].indexOf(
                                opts.searchValue.toLowerCase()
                              ) >= 0
                            );
                          })
                        );
                      } else {
                        d.resolve(helper.RecentSearches);
                      }
                    }
                    return d.promise();
                  },
                  byKey(key) {
                    let d = $.Deferred();
                    if (key) {
                      return d.promise(
                        d.resolve(
                          helper.RecentSearches.filter(x => {
                            x.SearchValue === key;
                          })
                        )
                      );
                    } else {
                      return d.promise(d.resolve(helper.RecentSearches));
                    }
                  },
                  key: "SearchValue",
                  insert(values) {
                    let d = $.Deferred();
                    helper.RecentSearches.push(values);
                    return d.promise(d.resolve());
                  }
                }),
                // data: localStorage.getItem("QuoteProductSearchString") ? localStorage.getItem("QuoteProductSearchString").split(",") : [],
                paginate: false
              }),
              onCustomItemCreating(e) {
                var newValue = { SearchValue: e.text },
                  items = e.component.option("value"),
                  ds = e.component.option("dataSource");
                (ds.store() as DevExpress.data.CustomStore)
                  .insert(newValue)
                  .done(() => {
                    if (
                      items &&
                      items.length &&
                      $.isArray(items) &&
                      items.indexOf(newValue) < 0
                    ) {
                      items.push(newValue);
                    } else {
                      items = [newValue];
                    }
                  });
                return newValue;
              }
              //onEnterKey: function (e) {
              //    e.component.option("value", e.component.option("text"))
              //    helper.PerformSearch();
              //}
            }
          },
          {
            itemType: "simple",
            editorType: "dxLookup",
            dataField: "SearchField",
            label: {
              text: "In",
              visible: true
            },

            colSpan: 5,
            editorOptions: {
              onValueChanged: function (e) {
                helper.PerformSearch();
              },

              displayExpr: "ItemText",
              valueExpr: "ItemText",
              dataSource: response,
              value: localStorage.getItem("productLastSearch") || "All Fields"
            }
          },
          {
            //editorType: "dxButton",
            itemType: "button",
            colSpan: 2,
            visible: true,
            buttonOptions: {
              text: "Search",
              icon: "dx-icon dx-icon-search myclass",
              elementAttr: { style: "margin-top:21px" },
              onClick: function (e) {
                helper.PerformSearch();
              }
            }
          },

          {
            itemType: "simple",
            editorType: "dxDataGrid",
            name: "Search Results",

            label: {
              text: "Results",
              visible: true
            },
            colSpan: 12,
            editorOptions: <any>helper.GetProductSearchGridOptions()
          },
          {
            itemType: "empty",
            colSpan: 12
          },
          {
            itemType: "simple",
            editorType: "dxDataGrid",
            name: "SelectedProducts",
            label: {
              text: "Preview",
              visible: true
            },
            colSpan: 12,
            editorOptions: opts
          }
        ];
        let formopts: DevExpress.ui.dxFormOptions = {
          colCount: 12,
          items: formItems,
          labelLocation: "top",
          width: "100%"
        };
        let formData = {
          SearchField: localStorage.getItem("productLastSearch") || "All Fields"
        };
        helper.ProductSearchForm = $("<div />")
          .addClass("product-search-form")
          .dxForm(formopts)
          .dxForm("instance");
        // helper.ProductSearchForm.option(formopts);
        dfd.resolve(helper.ProductSearchForm);
      });
    return dfd.promise();
  }

  private PerformSearch() {
    let helper = this;
    let grid: DevExpress.ui.dxDataGrid = helper.ProductSearchForm.getEditor(
      "Search Results"
    );
    let params = helper.ProductSearchForm.option("formData");
    if (typeof params.SearchString === "string") {
      helper.RecentSearches.push(params.SearchString);
    } else {
      if (typeof params.SearchString === "object") {
        if (params.SearchString.hasOwnProperty("SearchValue")) {
          params.SearchString = params.SearchString["SearchValue"];
        }
      }
    }
    localStorage.setItem(
      "productLastSearch",
      params.SearchField ? params.SearchField : "All Fields"
    );

    if (!params.SearchField) {
      params.SearchField = "All Fields";
    }
    if (!params.SearchString) {
      new Notification({
        message: "Please enter something to search for.",
        type: "warning",
        displayTime: 5000
      });
      let box: any = helper.ProductSearchForm.getEditor("SearchString");
      box
        .element()
        .find(".dx-texteditor-input")
        .addClass("flash");
      box.open();
      return;
    } else {
      let searches: string[] = localStorage.getItem("QuoteProductSearchString")
        ? localStorage.getItem("QuoteProductSearchString").split(",")
        : [];
      if (searches && searches.length) {
        if (searches.indexOf(params.SearchString) < 0) {
          searches.push(params.SearchString);
        }
      } else {
        searches = [params.SearchString];
      }
      localStorage.setItem("QuoteProductSearchString", searches.join(","));
    }
    let warehouserestrict = $("#limitwarehouse");
    if (warehouserestrict.length) {
      params.restrictWarehouse = warehouserestrict
        .dxCheckBox("instance")
        .option("value");
    }
    if (params.restrictWarehouse) {
      params.warehouseIDs = $("#warehouses")
        .dxTagBox("instance")
        .option("value")
        .join("|");
    }
    /*If there are different segments then the segmentID will be added to the dialog's data property ... we need to limit the search results to the segment */
    if (helper._Quote.SegmentsDefined) {
      params["segmentID"] = helper.SearchDialog.getData("segmentID");
    }
    /* new TDFRequest({
      url:
        helper._Quote.BaseQuotePath +
        helper._Quote.LineItemHelper.SearchLineItemsPath,
      data: params,
      type: "GET"
    }).MakeRequest() */


    QuoteDataService.SearchLines(params)
      .done(function (response: any) {
        if (true) {
          grid.beginUpdate();
          if (response.columns && response.columns.length > 0) {
            let cols: DevExpress.ui.dxDataGridColumn[] = [];
            let fields = [];
            if (response.products && response.products.length > 0) {
              Object.keys(response.products[0]).forEach((v, k) => {
                fields.push(v);
              });
            }
            //    (grid as DevExpress.ui.dxDataGrid).option("columns").forEach((v: DevExpress.ui.dxDataGridColumn, k) => {
            (response.columns as Array<{
              Visible: boolean;
              VisibleIndex: number;
              Width: number;
              Name: string;
            }>).forEach((info, idx) => {
              let col: DevExpress.ui.dxDataGridColumn = {
                visible: info.Visible,
                visibleIndex: info.VisibleIndex,
                width: info.Width || 60,
                dataField: info.Name
              };
              if (info.Name === "Link") {
                col.visible = false;
              }
              if (helper.CurrencyCols.indexOf(info.Name) > -1) {
                col.format = {
                  type: "currency",
                  precision:
                    helper._Quote.QuoteOptions.GeneralOptions.CurrencyPrecision,
                  currency: CurrentUser.Currency
                };
                col.dataType = "number";
              }
              if (helper.NumberCols.indexOf(info.Name) > -1) {
                col.dataType = "number";
              }
              if (helper.DateCols.indexOf(info.Name) > -1) {
                col.format = "shortDate";
                col.dataType = "date";
              }

              if (
                helper._Quote.QuoteOptions.DataProductSearchCaptions[info.Name]
              ) {
                col.caption =
                  helper._Quote.QuoteOptions.DataProductSearchCaptions[info.Name];
              }
              cols.push(col);
              fields.splice(fields.indexOf(col.dataField), 1);
            });

            fields.forEach((v, k) => {
              cols.push({ dataField: v, visible: false });
            });
            if (cols.length > 0) {
              cols.push({
                dataField: "",
                visible: true,
                caption: ""
              });
              grid.option("columns", cols);
            }
            //  })
          }
          grid.option("dataSource", response.products);
          grid.endUpdate();
        }
      });

    $(".productSearchGrids").removeClass("hidden");
  }
  CurrencyCols = [
    "Cost",
    "ListPrice",
    "NetPrice",
    "Price",
    "RebateCost",
    "ReplacementCost",
    "UserCurrency1",
    "UserCurrency2",
    "UserCurrency3",
    "UserCurrency4",
    "UserCurrency5"
  ];
  DateCols = ["UserDate1", "UserDate2", "UserDate3", "UserDate4", "UserDate5"];
  NumberCols = [
    "UserNumeric1",
    "UserNumeric2",
    "UserNumeric3",
    "UserNumeric4",
    "UserNumeric5"
  ];
  RenderProductSearch(e) {
    let helper = this;

    $.when(helper.GetProductSearchForm()).done(function (searchForm) {
      let it = $("<div/>").append(searchForm.element());
      helper.SearchDialog = new Dialog(
        {
          id: "productsearch",
          type: "type-default",
          body: it,
          closable: true,
          title: "Product Search",
          buttons: [
            {
              widget: "dxButton",
              location: "after",
              toolbar: "bottom",
              options: {
                text: "Cancel",
                icon: "remove",
                type: "danger",

                onClick: function () {
                  helper.SearchDialog.close();
                }
              }
            },
            {
              widget: "dxButton",
              location: "after",
              toolbar: "bottom",
              options: {
                text: "Add Selected",
                icon: "check",
                type: "success",
                onClick() {
                  let selectedgrid: DevExpress.ui.dxDataGrid = <DevExpress.ui.dxDataGrid>helper.ProductSearchForm.getEditor(
                    "SelectedProducts"
                  );
                  let products = selectedgrid.option("dataSource");

                  $.each(products, function (key, val: IDataProduct) {
                    $.each(val, function (k, v) {
                      v = v === null ? "" : v;
                    });

                    if (helper.ProductsToAdd.length) {
                      $.each(
                        helper.ProductsToAdd,
                        (index, prod: IDataProductWrapper) => {
                          let wrap: IDataProductWrapper = {};
                          if (
                            prod.Product.TempGUID !== val.TempGUID &&
                            !prod.IsAccessoryTo
                          ) {
                            wrap.Product = val;
                            wrap.GUID = wrap.Product.TempGUID;
                            wrap.ItemCode = wrap.Product.ItemCode;
                            wrap.IncludeInSummary =
                              wrap.Product["IncludeInSummary"] ||
                              helper.SearchSegment.DefaultIncludeInSummary;
                            wrap.ItemCodeAlternative =
                              wrap.Product.ItemCodeAlternative;
                            wrap.ProductGroup = wrap.Product.ProductGroup;
                            wrap.Vendor = wrap.Product.Vendor;
                            let alreadyadded = helper.ProductsToAdd.filter(
                              (v, k) => {
                                return v.GUID === wrap.GUID;
                              }
                            );
                            if (alreadyadded.length === 0)
                              helper.ProductsToAdd.push(wrap);
                          }
                        }
                      );
                    } else {
                      let wrap: IDataProductWrapper = {};
                      wrap.Product = val;
                      wrap.GUID = wrap.Product.TempGUID;
                      wrap.ItemCode = wrap.Product.ItemCode;
                      wrap.ItemCodeAlternative =
                        wrap.Product.ItemCodeAlternative;
                      wrap.IncludeInSummary =
                        wrap.Product["IncludeInSummary"] ||
                        helper.SearchSegment.DefaultIncludeInSummary;
                      wrap.ProductGroup = wrap.Product.ProductGroup;
                      wrap.Vendor = wrap.Product.Vendor;
                      helper.ProductsToAdd.push(wrap);
                    }
                  });
                  /* 
                                    new TDFRequest({
                                      url:
                                        helper._Quote.BaseQuotePath +
                                        helper._Quote.LineItemHelper.AddLinesPath,
                                      data: {
                                        quoteid: helper._Quote.ItemID,
                                        products: helper.ProductsToAdd,
                                        options: {
                                          ExpectedProductSegmentID: helper._Quote.SegmentsDefined
                                            ? helper.SearchSegment.SegmentID
                                            : ""
                                        }
                                      },
                                      type: "POST"
                                    }).MakeRequest() */
                  QuoteDataService.AddLines(helper._Quote.ItemID, {
                    products: helper.ProductsToAdd,
                    options: {
                      ExpectedProductSegmentID: helper._Quote.SegmentsDefined
                        ? helper.SearchSegment.SegmentID
                        : ""
                    }
                  })
                    .done(function (response: any) {
                      new Notification({
                        message: response.AddedItems && response.AddedItems.length
                          ? `${response.AddedItems.length} Products added`
                          : response.Message || "Unknown error",
                        type: response.Errors.length === 0 ? "success" : "error",
                        displayTime: response.Errors.length === 0 ? 3000 : 5000,
                        shading: !(response.Errors.length === 0),
                        position: { my: "center" }
                      });
                      if (true) {
                        helper.SearchDialog.close();
                        helper._Quote.RefreshLinesAndTotals();
                      }
                    });
                }
              }
            }
          ]
        },
        null,
        true,
        true
      );

      helper.SearchDialog.setData("segmentID", $(e.element).attr("segmentID"));
      // helper.SearchDialog.Instance.setButtons();
      helper.SearchDialog.open().done(function () {
        let body = it; //helper.SearchDialog.getModalBody();
        let height = $(window).innerHeight() - 200;
        //$(body.children()[0]).css({ "height": height + "px" });
        //$(body).dxScrollView({
        //    //height: height - 120,
        //    showScrollbar: "always",
        //    bounceEnabled: false,
        //});
        if (
          helper._Quote.QuoteOptions.UserOptions.DefaultWarehouseID &&
          helper._Quote.QuoteOptions.UserOptions.DefaultWarehouseID.length &&
          helper._Quote.QuoteOptions.UserOptions.DefaultWarehouseID.filter(
            function (item) {
              return item !== "<DocumentElement />";
            }
          ).length > 0
        ) {
          let defaultwarehousoptions = $("<div />").prependTo(body);
          let limitflag = $("<div />").appendTo(defaultwarehousoptions);
          let chkopts: DevExpress.ui.dxCheckBoxOptions = {
            text: "Limit to warehouse:",
            value:
              helper._Quote.QuoteOptions.UserOptions.DefaultWarehouseID &&
              helper._Quote.QuoteOptions.UserOptions.DefaultWarehouseID.length >
              0 &&
              helper._Quote.QuoteOptions.UserOptions.DefaultWarehouseID.filter(
                function (item) {
                  return item !== "<DocumentElement />";
                }
              ).length > 0,
            elementAttr: { id: "limitwarehouse" }
          };
          limitflag.dxCheckBox(chkopts);
          let warehouses = [];
          $.each(
            helper._Quote.QuoteOptions.UserOptions.DefaultWarehouseID,
            (k, v) => {
              if (v !== "<DocumentElement />") warehouses.push({ ID: v });
            }
          );

          let temp = $("<div >").appendTo(defaultwarehousoptions);

          temp.dxTagBox({
            dataSource: warehouses,
            valueExpr: "ID",
            displayExpr: "ID",
            value: helper._Quote.QuoteOptions.UserOptions.DefaultWarehouseID,
            elementAttr: { id: "warehouses" }
          });
        }
        if (helper._Quote.SegmentsDefined) {
          helper.SearchSegment = $.grep(
            helper._Quote.SegmentHelper.Segments,
            function (val) {
              return val.SegmentID === $(e.element).attr("segmentID");
            }
          )[0];
          //let header = dlg.Instance.getModalHeader();
          //$(header).find(".bootstrap-dialog-header").append($("<span />").addClass(" alert-danger").css("margin-left", "3em").text("**Note: Results will be limited to " + seg[0].Title));
          $(body).prepend(
            $("<div />")
              .addClass("alert alert-warning")
              .text(
                "**Note: Results will be limited to " +
                helper.SearchSegment.Title
              )
          );
        }

        let sText: DevExpress.ui.dxTextBox = <DevExpress.ui.dxTextBox>helper.ProductSearchForm.getEditor(
          "SearchString"
        );
        let sField: DevExpress.ui.dxLookup = <DevExpress.ui.dxLookup>helper.ProductSearchForm.getEditor(
          "SearchText"
        );
        sText.focus();
      });
    });
  }
}

export class QuoteRevisionHelper {

  private QuoteID: string;

  constructor(quoteId: string) {
    this.QuoteID = quoteId;
  }

  public CreateRevision() {

    let that = this;

    let RevisionName = new Dialog({
      id: "new-quote-revision",
      body: $("<div id='get-revision-content'/>").
        append($("<label/>").
          text("Please enter a revision title. Leave blank to re-use the Quote's current title.")).
        append($("<div />").dxTextArea({
          inputAttr: { id: "name-for-new-quote-revision" },
          value: ""
        })),
      size: "size-normal",
      type: "type-warning",
      closable: true,
      title: "Name the revision",
      buttons: [{
        widget: "dxButton", toolbar: "bottom", location: "after", options: {
          id: "",
          type: "success",
          onClick: function () {

            let revTitle = $("#name-for-new-quote-revision").val();
            RevisionName.close();

            QuoteDataService.CreateRevision(that.QuoteID, revTitle.toString()).
              done((data: QuoteDataService.ICreateQuoteRevisionResponse) => {

                try {
                  window.location.assign("?itemid=" + data.Revisions[0].RevisionID);
                } catch (e) {
                  console.log(e.message);
                }

              });

          },
          icon: "check",
          text: "OK"
        }
      }]
    });
    RevisionName.open();

  }

  public ViewRevisions() {

    let that = this;

    QuoteDataService.GetRevisions(that.QuoteID).done((response) => {
      let revGrid = $("<div />").dxDataGrid({
        dataSource: response,
        // When deleting revisions is allowed uncomment below 😎 
        // editing: {allowDeleting:theQuote.EnableDelete},
        columns: [
          {
            dataField: "DisplayValue",
            caption: "ID",
            width: 100,
          },
          {
            dataField: "RevisionDate",
            visible: false
          },
          {
            dataField: "RevisionID",
            visible: false
          },
          {
            dataField: "RevisionNumber",
            caption: "Rev #",
            visible: true,
            sortOrder: "desc",
            width: 100,
          },
          {
            dataField: "RevisionTitle",
            caption: "Name"
          }
        ],
        hint: "Click to go to this revision.",
        rowAlternationEnabled: false,
        hoverStateEnabled: true,
        //onRowRemoved(e) {
        //    QuoteDataService.DeleteRevision(e.data.RevisionID).done(() => {
        //        if (window.location.href.indexOf(e.data.RevisionID) >= 0) {
        //            window.location.assign(window.location.href.replace(`?revisionid=${e.data.RevisionID}`, ""));
        //        }
        //    });
        //},
        selection: {
          mode: "single",
          allowSelectAll: false,
          showCheckBoxesMode: "none"
        },
        onRowClick(e) {
          window.location.assign(`?itemid=${e.data.RevisionID}`)
        }

      })
      new Dialog({ id: "dlg-view-revisions", body: revGrid, size: "size-normal", type: "type-warning", closable: true, title: "Revisions" }).open();
    });

  }

  public GetButton(theQuote: Quote): DevExpress.ui.dxToolbarItemTemplate {

    let that = this;

    return <DevExpress.ui.dxToolbarItemTemplate>{
      locateInMenu: GetDevice().isDevice ? 'always' : 'auto',
      location: "after",
      showText: "always",

      options: <DevExpress.ui.dxButtonOptions>{
        icon: "",
        elementAttr: { id: "btnnewrevision" },
        hint: "Create or view revisions",
        text: `Revisions (${theQuote.QuoteDetails.ActiveRevisionNumber})`,
        visible: theQuote.QuoteOptions.GeneralOptions.EnableQuoteRevisions,
        onClick: function (e) {

          let divSheet = $("#sheet_revisions")

          if (divSheet.length === 0) {
            divSheet = $("<div />").attr({ id: "sheet_revisions" }).appendTo($("#tdfbodycontent"));
          }

          let sheet = divSheet.dxActionSheet({
            showCancelButton: true,
            target: e.element,
            usePopover: true,
            width: "250px",

            dataSource: [
              { text: "Create Revision", disabled: (theQuote.QuoteDetails.IsPreviousRevision === true) },
              { text: "View Previous Revisions", disabled: false }
            ],
            onItemClick: (e) => {
              switch (e.itemData.text) {
                case "Create Revision":
                  that.CreateRevision()
                  break;
                case "View Previous Revisions":
                  that.ViewRevisions();
                  //console.log("View Revisions");
                  break;
              }
            }
          });

          //$("#tdfbodycontent").append(sheet);

          sheet.dxActionSheet("instance").option("visible", true);

        }


      },
      widget: "dxButton"
    }


  }



}




