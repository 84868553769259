import * as Globalize from 'globalize';
import { RaiseEvent } from "../../infrastructure/events/ui_events";
import { eventNameSpace, EventTypes } from "../../enums/webevents/enums";

import { IDateRangeSaveOptions } from "../../interfaces/admin/gridmanagement/interfaces";
import { DateRangeSetupOptions } from "../../interfaces/grids/daterangesetupoptions";
import { RelativeDateFilterDataService } from "../../services/grid/relativedatefilter/relativedatefilterdataservice";
import { Form } from "./form";
import { RulesRelativeDateFilterDataService } from 'services/grid/relativedatefilter/rulesrelativedatefilterdataservice';

enum DateFilterCombinerEnum {
	And = 0,
	Or = 1,
	NotAnd = 2,
	NotOr = 3,
	Custom = 4,

}

enum EnumRangeType {
	Fixed,
	Relative,
	Predefined,
	RelativeOpenEnded
}

enum EnumRangeUnits {
	Day,
	Week,
	Month
}

enum EnumOpenEnded {
	AllFuture,
	AllPast
}

enum EnumQuickRanges {
	ThisPeriod = 0,
	ThisQuarter = 1,
	ThisYear = 2,
	LastPeriod = 3,
	LastQuarter = 4,
	LastYear = 5,
	NextPeriod = 6,
	NextQuarter = 7,
	NextYear = 8,
	Today = 9,
	ThisWeek = 10,
	LastWeek = 11,
	NextWeek = 12,
	Yesterday = 13,
	TwoYearsAgo = 14,
	BlankOrNull = 15,
	LastYTD = 16,
	LastYMTD = 17,
	LastYQTD = 18,
	LastYearDay = 19,
	TwoYTD = 20,
	TwoMTD = 21,
	TwoQTD = 22,
	TwoDay = 23,
	Tomorrow = 24,
	BSIMostRecent = 25
}

interface AvailableOption {
	ID: number;
	Display: string;
}

interface AvailableUnit {
	ID: number;
	Display: string;
}

interface AvailableOpenEndValue {
	ID: number;
	Display: string;
}

interface AvailablePredefinedRange {
	ID: number;
	Display: string;
}

export interface IDateFilter {
	/** The date filter string
	 * ex:"[Field] Between m/d/yyy and m/d/yyy"
	 * */
	FilterDetails: string;
	/** I think this will always be an empty string */
	DateTable: string;
	/** The field */
	DateField: string;
	/**not sure yet */
	RecursAnnually: boolean;
	/** EnumRangeType as a string
	 * Ex:Fixed*/
	RangeType: EnumRangeType;

	FixedDateRangeStart: Date;
	FixedDateRangeEnd: Date;
	/**EnumRangeUnits as string
	 * ex: Day*/
	RelativeMeasurementUnit: EnumRangeUnits;
	RelativeDateRangeStart: number;
	RelativeDateRangeEnd: number;
	/**EnumOpenEnded as string
	 * ex: AllFuture*/
	RelativeOpenEnded: EnumOpenEnded;
	/** EnumQuickRange as string
	 * ex: ThisPeriod
	 * */
	QuickRange: EnumQuickRanges;
}

export interface Setup {
	AvailableDateFields: string[];
	AvailableOptions: AvailableOption[];
	AvailableUnits: AvailableUnit[];
	AvailableOpenEndValues: AvailableOpenEndValue[];
	AvailablePredefinedRanges: AvailablePredefinedRange[];
}

/**
* Relative date filter control ... this is starting from grid managment let Chris know if there is anything you may need this to do if you are unsure of how to do it
 * */
export class RelativeDateFilterControl implements IDateFilter {
	FilterDetails: string;
	DateTable: string = "";
	DateField: string = "";
	RecursAnnually: boolean = false;
	RangeType: EnumRangeType;
	FixedDateRangeStart: Date;
	FixedDateRangeEnd: Date;
	RelativeMeasurementUnit: EnumRangeUnits;
	RelativeDateRangeStart: number;
	RelativeDateRangeEnd: number;
	RelativeOpenEnded: EnumOpenEnded;
	QuickRange: EnumQuickRanges;

	protected _dataService: RelativeDateFilterDataService | RulesRelativeDateFilterDataService;
	protected _rangeOpts: DateRangeSetupOptions;
	private _activeTab: string = "Fixed";
	private _viewid: string;
	private _formContainer: JQuery;
	private _editorForm: Form;
	private _operationChooser: DevExpress.ui.dxSelectBox;

	public get OperationChooser(): DevExpress.ui.dxSelectBox {
		if (this._operationChooser) return this._operationChooser;
		this._operationChooser = $("<div />").dxSelectBox(this._operationSelectorOptions).dxSelectBox("instance");
		return this._operationChooser;
	}

	get _operationSelectorOptions(): DevExpress.ui.dxSelectBoxOptions {
		return {
			items: ["And", "Or", "NotAnd", "NotOr", "Custom"],
			value: "And",
		};
	}

	private get _selectedFilterField(): string {
		return this._editorForm.FormObject.getEditor("Date Field").option("value");
	}

	private get _combineType(): string {
		return this.OperationChooser.option("value");
	}

	private get _formOptions(): DevExpress.ui.dxFormOptions {
		let rdf = this;
		return {
			colCount: 1,
			items: rdf._formItems,

		}
	}

	constructor(viewid: string, el?: JQuery) {
		this._viewid = viewid;
		this._formContainer = el;
		this._dataService = new RelativeDateFilterDataService();
	}

	GetSetupOptions() {
		let rdf = this;
		let d = $.Deferred();
		(<RelativeDateFilterDataService>rdf._dataService).SettingsForView(rdf._viewid).done((response) => {
			this._rangeOpts = response;
			d.resolve();
		});
		return d.promise();
	}

	get DropDownBoxOptions(): DevExpress.ui.dxDropDownBoxOptions {
		let rdf = this;
		return {

			dropDownOptions: {
				height: "300px",
			},
			contentTemplate(data, el) {
				let content = $("<div />");
				el.append(content);
				rdf.GetSetupOptions().done(() => {

					rdf._editorForm = new Form(content, rdf._formOptions)

				})
			}
		}
	}

	private get _formItems() {
		let rdf = this;
		return [
			<DevExpress.ui.dxFormSimpleItem>{
				itemType: "simple",
				dataField: "Date Field",
				editorType: "dxSelectBox",
				editorOptions: <DevExpress.ui.dxSelectBoxOptions>{
					items: this._rangeOpts.Setup.AvailableDateFields,
				}
			},
			<DevExpress.ui.dxFormTabbedItem>{
				itemType: "tabbed",
				tabPanelOptions: {
					// height: 300,
					onInitialized(e) {
						rdf._activeTab = "Fixed";
					},
					onSelectionChanged(e) {
						rdf._activeTab = e.addedItems[0].title;
					},
				},
				tabs: rdf._formTabs
			},
			<DevExpress.ui.dxFormButtonItem>{
				itemType: "button",
				buttonOptions: {
					icon: "save",
					onClick(e) {
						if (!rdf._activeTab)
							return;
						rdf[`Set${rdf._activeTab.replace("-", "")}DateFilterVal`]();
					}
				}
			}
		];
	}
	SetDateFilterVal(filterSaveOptions: IDateRangeSaveOptions) {
		let rdf = this;
		let toSave: IDateRangeSaveOptions;

		toSave = {
			CombinePattern: filterSaveOptions.CombinePattern,
			CombineType: filterSaveOptions.CombineType,
			DateFilters: []
		};
		let toRemove = [];
		rdf._rangeOpts.DateFilters.forEach((val, k) => {
			filterSaveOptions.DateFilters.forEach((v, key) => {
				if (v.DateField === val.DateField) {
					toRemove.push(k)
				}
			});
		});
		if (toRemove.length) {
			toRemove.forEach(i => {
				rdf._rangeOpts.DateFilters.splice(i, 1);
			})
		}
		toSave.DateFilters = filterSaveOptions.DateFilters.concat(rdf._rangeOpts.DateFilters);
		rdf._rangeOpts.DateFilters = toSave.DateFilters;

		RaiseEvent(EventTypes.CenterEventTypes.relativedatefilter, eventNameSpace.notify, toSave);

	}


	private SetFixedDateFilterVal() {
		let rdf = this;
		let start = rdf._editorForm.FormObject.getEditor("FixedStartDate").option("value");
		let end = rdf._editorForm.FormObject.getEditor("FixedEndDate").option("value");

		let dfil: IDateFilter = {
			QuickRange: 0,
			DateField: rdf._selectedFilterField,
			DateTable: "",
			FilterDetails: `${rdf._selectedFilterField} BETWEEN ${Globalize.formatDate(start, { date: "short" })} AND ${Globalize.formatDate(end, { date: "short" })}`,
			FixedDateRangeStart: start,
			FixedDateRangeEnd: end,
			RangeType: EnumRangeType.Fixed,
			RecursAnnually: false,
			RelativeDateRangeEnd: 0,
			RelativeDateRangeStart: 0,
			RelativeMeasurementUnit: 0,
			RelativeOpenEnded: 0,
		}

		rdf.getString(dfil);
		let filterSaveOptions: IDateRangeSaveOptions = {
			DateFilters: [dfil],
			CombinePattern: "",
			CombineType: rdf._combineType
		}
		rdf.SetDateFilterVal(filterSaveOptions);

	}


	private SetRelativeDateFilterVal() {
		let rdf = this;

		let start = rdf._editorForm.FormObject.getEditor("RelativeStartDate").option("value");
		let end = rdf._editorForm.FormObject.getEditor("RelativeEndDate").option("value");
		let unit = rdf._editorForm.FormObject.getEditor("RelativeUnit").option("value");



		let text = `${rdf._selectedFilterField} BETWEEN `;

		if (start === 0) {
			text = text.concat("Today")
		} else {
			text = text.concat(`${Math.abs(start)} ${EnumRangeUnits[unit]}${Math.abs(start) > 1 ? "s" : ""} ${start < 0 ? "ago" : "from now"} `)
		}
		text = text.concat("AND ");

		if (end === 0) {
			text = text.concat("Today")
		} else {
			text = text.concat(`${Math.abs(end)} ${EnumRangeUnits[unit]}${Math.abs(end) > 1 ? "s" : ""} ${end < 0 ? "ago" : "from now"} `)
		}

		let dfil: IDateFilter = {
			DateField: rdf._selectedFilterField,
			DateTable: "",
			FilterDetails: text,//`${rdf._selectedFilterField} Between ${start === 0 ?"Today" : `${start} ${unit} from now`} and  `,
			FixedDateRangeEnd: new Date(1900, 1, 1),
			FixedDateRangeStart: new Date(1900, 1, 1),
			RangeType: EnumRangeType.Relative,
			QuickRange: 0,
			RecursAnnually: false,
			RelativeDateRangeEnd: end,
			RelativeDateRangeStart: start,
			RelativeMeasurementUnit: unit,
			RelativeOpenEnded: 0

		}

		let filterSaveOptions: IDateRangeSaveOptions = {
			DateFilters: [dfil],
			CombinePattern: "",
			CombineType: rdf._combineType
		}
		rdf.SetDateFilterVal(filterSaveOptions);
	}
	private SetOpenEndedDateFilterVal() {
		let rdf = this;
		let start = rdf._editorForm.FormObject.getEditor("Open-EndedStartDate").option("value");
		let openend = rdf._editorForm.FormObject.getEditor("OpenEnd").option("value");
		let unit = rdf._editorForm.FormObject.getEditor("Open-EndedUnit").option("value");

		let posttext = `${start === 0 ? "Today" : `${Math.abs(start)} ${EnumRangeUnits[unit]}(s)`} ${start < 0 ? "ago" : "from now"}`;


		let dfil: IDateFilter = {
			RangeType: EnumRangeType.RelativeOpenEnded,
			RelativeOpenEnded: openend,
			DateField: rdf._selectedFilterField,
			DateTable: "",
			FilterDetails: `${rdf._selectedFilterField} ${openend === "AllFuture" ? ">=" : "<="} ${posttext} `,
			RecursAnnually: false,
			RelativeDateRangeEnd: 0,
			RelativeDateRangeStart: start,
			RelativeMeasurementUnit: unit,
			FixedDateRangeEnd: new Date(1900, 1, 1),
			FixedDateRangeStart: new Date(1900, 1, 1),
			QuickRange: 0
		}

		let filterSaveOptions: IDateRangeSaveOptions = {
			DateFilters: [dfil],
			CombinePattern: "",
			CombineType: rdf._combineType
		}
		rdf.SetDateFilterVal(filterSaveOptions)
	}
	private SetPreDefinedDateFilterVal() {
		let rdf = this;

		let dfil: IDateFilter = {
			DateField: rdf._selectedFilterField,
			DateTable: "",
			FilterDetails: `${rdf._selectedFilterField} IN ${EnumQuickRanges[rdf._editorForm.FormObject.getEditor("Pre-Defined").option("value")]}`,
			FixedDateRangeStart: new Date(1900, 1, 1),
			FixedDateRangeEnd: new Date(1900, 1, 1),
			QuickRange: rdf._editorForm.FormObject.getEditor("Pre-Defined").option("value"),
			RangeType: EnumRangeType.Predefined,
			RecursAnnually: false,
			RelativeDateRangeEnd: 0,
			RelativeDateRangeStart: 0,
			RelativeMeasurementUnit: 0,
			RelativeOpenEnded: 0,
		}

		let filterSaveOptions: IDateRangeSaveOptions = {
			DateFilters: [dfil],
			CombinePattern: "",
			CombineType: rdf._combineType
		}
		rdf.SetDateFilterVal(filterSaveOptions)
	}

	private get _formTabs(): any {
		return [
			{
				title: "Fixed",
				items: [
					{
						itemType: "simple",
						dataField: "FixedStartDate",
						label: { text: "Start Date" },
						editorType: "dxDateBox"
					},
					{
						itemType: "simple",
						dataField: "FixedEndDate",
						label: { text: "End Date" },
						editorType: "dxDateBox"
					}
				]
			},
			{
				title: "Relative",
				items: [
					{
						itemType: "simple",
						dataField: "RelativeStartDate",
						label: { text: "Start Date" },
						editorType: "dxNumberBox",
						editorOptions: <DevExpress.ui.dxNumberBoxOptions>{
							value: 0,
							showSpinButtons: true,
						}
					},
					{
						itemType: "simple",
						dataField: "RelativeEndDate",
						label: { text: "End Date" },
						editorType: "dxNumberBox",
						editorOptions: <DevExpress.ui.dxNumberBoxOptions>{
							value: 0,
							showSpinButtons: true,
						}
					},
					{
						itemType: "simple",
						dataField: "RelativeUnit",
						label: { text: "Unit" },
						editorType: "dxSelectBox",
						editorOptions: <DevExpress.ui.dxSelectBoxOptions>{
							valueExpr: "ID",
							displayExpr: "Display",
							items: this._rangeOpts.Setup.AvailableUnits,

						}
					},
				]
			},
			{
				title: "Open-Ended",
				items: [
					{
						itemType: "simple",
						dataField: "OpenEnd",
						editorType: "dxSelectBox",
						editorOptions: <DevExpress.ui.dxSelectBoxOptions>{
							valueExpr: "ID",
							displayExpr: "Display",
							items: this._rangeOpts.Setup.AvailableOpenEndValues.map((v) => {
								return {
									ID: v.ID,
									Display: v.Display
								};
							})
						}
					},
					{
						itemType: "simple",
						dataField: "Open-EndedStartDate",
						label: { text: "Start Date" },
						editorType: "dxNumberBox",
						editorOptions: <DevExpress.ui.dxNumberBoxOptions>{
							value: 0,
							showSpinButtons: true,
						}
					},
					{
						itemType: "simple",
						dataField: "Open-EndedUnit",
						label: { text: "Unit" },
						editorType: "dxSelectBox",
						editorOptions: <DevExpress.ui.dxSelectBoxOptions>{
							valueExpr: "ID",
							displayExpr: "Display",
							items: this._rangeOpts.Setup.AvailableUnits
						}
					},
				]
			},
			{
				title: "Pre-Defined",
				items: [
					{
						itemType: "simple",
						dataField: "Pre-Defined",
						editorType: "dxSelectBox",
						editorOptions: <DevExpress.ui.dxSelectBoxOptions>{
							valueExpr: "ID",
							displayExpr: "Display",
							items: this._rangeOpts.Setup.AvailablePredefinedRanges.map((v) => {
								return { ID: v.ID, Display: v.Display };
							})
						}
					},
				]
			}
		];
	}

	private _dropDownBox: DevExpress.ui.dxDropDownBox;

	public get DropDownBox(): DevExpress.ui.dxDropDownBox {
		if (this._dropDownBox) return this._dropDownBox;
		this._dropDownBox = $("<div />").dxDropDownBox(this.DropDownBoxOptions).dxDropDownBox("instance");
		return this._dropDownBox;

	}

	public toJSON(key) {

	}

	public getString(filter: IDateFilter) {
		let text = ``;
		switch (filter.RangeType) {
			case EnumRangeType.Fixed:


				break;
			case EnumRangeType.Predefined:
				break;
			case EnumRangeType.Relative:
				break;
			case EnumRangeType.RelativeOpenEnded:
				break;
		}

	}

}




