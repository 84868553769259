import dxArrayStore from "devextreme/data/array_store";
import dxCustomStore from "devextreme/data/custom_store";
import dxDataSource from "devextreme/data/data_source";
import { GridManager } from "../../../../admin/gridmanager";
import {
  enumscope,
  GridContainerTypes,
  itemTypes
} from "../../../../enums/enums";
import { eventNameSpace, EventTypes } from "../../../../enums/webevents/enums";
import { RaiseEvent2, AddHandler2 } from "../../../../infrastructure/events/ui_events";
import { Preferences } from "../../../../infrastructure/user/preferences";
import { TDFDataCenters } from "../../../../interfaces/datacenters/tdfdatacenters";
import {
  IGridContainer_ItemType_Scope_Descriptor,
  IGridView,
  IToolbarItemOverrideOptions
} from "../../../../interfaces/grids/interfaces";

import { GridManagementDataService } from "../../../../services/admin/gridmanagement/gridmanagementdataservice";
import { GridViewService } from "../../../../services/grid/gridviewservice";
import { TDFRequest } from "../../../../services/request";
import { GetDevice } from "../../../../util/allutils";
import { Dialog } from "../../../dialogs/dialog";
import { Notification } from "../../../dialogs/notification";
import { ToolbarTemplateFactory } from "./toolbartemplatefactory";
import "devextreme/ui/drop_down_box";
import "devextreme/ui/tabs";
import "devextreme/ui/list";
import "devextreme/ui/context_menu";
import "devextreme/ui/form";
import "devextreme/ui/number_box";
import { confirm } from  "devextreme/ui/dialog";
import { IDisplayInfo } from "../../../../interfaces/interfaces";
import { LoadCompany, DisplayInfo } from "../../../../infrastructure/context";
export interface RecentView {
  ViewName: string;
  ViewGUID: string;
  GlobalView: boolean;
}

export interface ViewsResponse {
  AllViews: IGridView[];
  recentViews: RecentView[];
}

export class AwesomeViewLookup {
  private SetViewAsDefaultURL = "/core/user/SetViewAsDefault";
	private UpdateGridScopeURL = "/core/user/UpdateGridScope";

  GridContainerType: GridContainerTypes;
  protected EventLimiterName: string = "";
  /*
		 * This is used to set the value of the lookup when a gridview has been selected in another object and the gridviews are to be reloaded such as when the item type or scope has changed.
		 * **/
  GuidToUse = null;
  private _ItemType: itemTypes;
  get ItemType(): itemTypes {
    if (this._ItemType) return this._ItemType;
    return null;
  }
  set ItemType(val: itemTypes) {
    let avs = this;
    if (val) this._ItemType = val;
  }

  get Instance(): DevExpress.ui.dxDropDownBox {
    return this._container.dxDropDownBox("instance");
  }

  private _Scope: enumscope;
  get Scope(): enumscope {
    if (typeof this._Scope !== "undefined") return this._Scope;
    return -1;
  }
  set Scope(val: enumscope) {
    this._Scope = val;
  }
  _container: JQuery;
  protected _allViews: IGridView[] = [];

  protected _DataService: GridViewService;
  get DataService(): GridViewService {
    if (this._DataService) return this._DataService;
    this.DataService = new GridViewService();
    return this._DataService;
  }
  set DataService(val: GridViewService) {
    if (val) this._DataService = val;
  }

  private _recentViews: RecentView[];

  get recentViews(): RecentView[] {
    return this._recentViews;
  }
  set recentViews(val: RecentView[]) {
    this._recentViews = val;
  }

  private inScope(isGlobalView: boolean) {
    let avs = this;
    return avs.Scope === -1
      ? true
      : avs.Scope === enumscope.global && isGlobalView
        ? true
        : avs.Scope === enumscope.local && !isGlobalView;
  }
  private initialItem: any;

  private _ViewsDataSource: DevExpress.data.DataSource;
  get ViewsDataSource(): DevExpress.data.DataSource {
    let avs = this;
    if (this._ViewsDataSource) return this._ViewsDataSource;

    let StoreOptions: DevExpress.data.CustomStoreOptions = {
      loadMode: "raw",
      load: (opts): JQueryPromise<IGridView[]> => {
        let d: JQueryDeferred<IGridView[]> = $.Deferred();
        let scopeChange: boolean = true;

        if (avs._allViews.length > 0) {
          if (
            avs._allViews.some((v, k) => {
              if (v.ItemType === avs.ItemType) {
                scopeChange = avs.inScope(v.IsGlobalView);
              }
              return v.ItemType === avs.ItemType && avs.inScope(v.IsGlobalView);
            })
          ) {
            return d.promise(d.resolve(avs._allViews));
          }
        }
        if (avs.ItemType && avs.ItemType !== -1 && avs.GridContainerType) {
          LoadCompany().done(() => {
            avs.GetViewsForMenu(scopeChange)
              .done((views: ViewsResponse) => {
                avs._allViews = avs._allViews.concat(views.AllViews);
                avs.recentViews = views.recentViews;
                avs._allViews = (avs._allViews as any).concat(
                  avs.recentViews.map(v => {
                    // (v as any)["recent"] = true;
                    return {
                      recent: true,
                      GUID: v.ViewGUID,
                      Name: v.ViewName,
                      IsGlobalView: v.GlobalView,
                      Type: avs.ItemType
                    };
                  })
                );
                if (views && views.AllViews.length) {
                  if (avs.Scope === -1 || !avs.inScope(views.AllViews[0].IsGlobalView)) {
                    avs.Scope = views.AllViews[0].IsGlobalView ? enumscope.global : enumscope.local;
                    RaiseEvent2(EventTypes.CenterEventTypes.gridscope, avs.EventLimiterName, eventNameSpace.modify, avs.Scope);
                    //  avs.RelaodViews();
                  }
                }

                RaiseEvent2(EventTypes.CenterEventTypes.gridviewsloaded, avs.EventLimiterName, eventNameSpace.complete, [views.AllViews]);
                d.resolve(avs._allViews);
              });
          });
        } else {
          return d.promise(d.resolve(avs._allViews));
        }
        return d.promise();
      },
      byKey(key) {
        let d = $.Deferred();
        return d.promise(
          d.resolve(
            avs._allViews.filter(v => {
              return v.GUID === key;
            })[0]
          )
        );
      },
      key: "GUID"
    };

    let dsopts: DevExpress.data.DataSourceOptions = {
      store: new dxCustomStore(StoreOptions),
      paginate: false,
      sort: "Name",
      group: "Category",
      filter: ["ItemType", "=", avs.ItemType]
      //filter: (e: IGridView) => {
      //    return e.ItemType === avs.ItemType && avs.inScope(e.IsGlobalView);
      //},
    };

    avs.ViewsDataSource = new dxDataSource(dsopts);
    return avs._ViewsDataSource;
  }
  set ViewsDataSource(val: DevExpress.data.DataSource) {
    this._ViewsDataSource = val;
  }

  protected GetViewsForMenu(scopeChange: boolean): JQueryPromise<ViewsResponse> {
    let avs = this;
    let d = $.Deferred();
    avs.DataService.ViewsForMenuRequest(
      avs.ItemType,
      avs.GridContainerType,
      scopeChange ? -1 : avs.Scope
    ).MakeRequest().done(views => {
      d.resolve(views);
    })

    return d.promise();
  }

  private _tabs: DevExpress.ui.dxTabs;
  get tabs(): DevExpress.ui.dxTabs {
    let avs = this;
    if (avs._tabs) return avs._tabs;
    let tabs: DevExpress.ui.dxTabsItemTemplate[] = [
      { text: "Global Views", icon: "globe" },
      { text: "Personal Views", icon: "home" },
      { text: "Recent Views", icon: "clock" }
    ];
    avs.tabs = $("<div/>")
      .dxTabs({
        dataSource: tabs,
        selectedIndex: 0,
        onSelectionChanged(e) {
          if (e.component.option("selectedIndex") === 2) {
            if (avs.recentViews && avs.recentViews.length) {
              avs.ViewsDataSource.filter([
                ["Type", "=", avs.ItemType],
                "and",
                ["recent", "=", true]
              ]);
            }
          } else {
            // avs.Scope = e.component.option("selectedIndex");
            avs.ViewsDataSource.filter([
              ["ItemType", "=", avs.ItemType],
              "and",
              [
                "IsGlobalView",
                "=",
                e.component.option("selectedIndex") === 0 ? true : false
              ]
            ]);
          }
          avs.ReloadViews(false);
        }
      })
      .dxTabs("instance");
    return this._tabs;
  }
  set tabs(val: DevExpress.ui.dxTabs) {
    this._tabs = val;
  }

  private _list: DevExpress.ui.dxList;
  get list(): DevExpress.ui.dxList {
    let avs = this;
    if (avs._list) return avs._list;
    avs.list = $("<div />")
      .dxList({
        dataSource: avs.ViewsDataSource,
        keyExpr: "GUID",
        grouped: true,
        searchMode: "contains",
        searchExpr(e) {
          return e.Name;
        },

        searchEnabled: true,
        selectionMode: "single",
        selectedItem: avs.initialItem || null,
        collapsibleGroups: true,
        menuItems: [
          
					<DevExpress.ui.dxContextMenuItem>{

						text: "Delete this view.",

						action: (e, itemData) => {

							let realItemData: any = <any>e["itemData"];

							confirm("Are you sure you want to delete this Grid View?", "").done((e) => {
								if (e === true) {
									let ds = new GridManagementDataService(realItemData, avs.EventLimiterName);
									ds.DeleleGridViewRequest.MakeRequest().done(res => {
										if (res.Valid) {
											avs.OnRefeshViewsRequested(null, null, true);
										} else {
											new Notification({
												message: `${
													res.Message
													} You must have Global Grid Manager Rights`,
												type: "error",
												displayTime: 3e3
											});
										}
									});
								}
							});

						}
					}
        ],
        height: "95%",
        menuMode: "context",
        itemTemplate(d: IGridView, i, e) {
          e.attr({ title: "Choose this view or right click for options." });
          let isRecent =
            typeof (d as any).recent !== "undefined" && (d as any).recent;
          e.css("display", "inline-block;").append(
            $(`<div style='float:left' >`)
              .css({
                'float': 'left',
                'overflow-x': 'hidden',
                'text-overflow': 'ellipsis',
                'max-width': 'calc(100% - 30px)'
              })
              .append(
                `${isRecent
                  ? `<span class="${
                  d.IsGlobalView
                    ? "fa fa-globe text-info"
                    : "fa fa-home text-warning"
                  }" style="margin-right:10px;"  /> ${
                  d.Name
                  }`
                  : d.Name
                } </div>`
              )
          );

          if (isRecent) {
            avs.ViewsDataSource.store()
              .byKey(d.GUID)
              .done(v => {
                if (v) {
                  avs.AddMakeDefaultCheckbox(v, e);
                }
              });
          } else {
            avs.AddMakeDefaultCheckbox(d, e);
          }
        },
        onSelectionChanged(e) {
          if (e.addedItems && e.addedItems.length > 0) {
            avs.Instance.option("value", e.addedItems[0].GUID);
            avs.Instance.close();
          }
        }
      })
      .dxList("instance");
    return this._list;
  }
  set list(val: DevExpress.ui.dxList) {
    this._list = val;
  }

  private AddMakeDefaultCheckbox(d: IGridView, e: DevExpress.core.dxElement) {
    let avs = this;
    GetDevice().isDevice
      ? ""
      : e.append(
        $("<div style='float:right; margin-right:10px;' />").append(
          $("<small />").dxCheckBox(<DevExpress.ui.dxCheckBoxOptions>{
            text: "",
            value: d.DefaultView,
            hint: d.DefaultView
              ? `This view is the default ${
              d.IsGlobalView ? "Global" : "Personal"
              } for this type.`
              : `Choose this view and set as the ${
              d.IsGlobalView ? "Global" : "Personal"
              } default for this type.`,
            disabled: d.DefaultView,
            hoverStateEnabled: true,
            onValueChanged(e) {
              avs.UpdateDefaultView(d);
            }
          })
        )
      );
  }

  private UpdateDefaultView(d: IGridView) {
    let avs = this;
    avs.ViewsDataSource.store()
      .byKey(d.GUID)
      .done(v => {
        $.when(
          new TDFRequest({
            url: `${TDFRequest.ApiPath}${avs.SetViewAsDefaultURL}`,
            type: "POST",
            data: {
              containerType: avs.GridContainerType,
              itemType: v.ItemType,
              viewid: d.GUID !== `${v.ItemType}-${d.Name}` ? d.GUID : '',
              isGlobal: v.IsGlobalView
            }
          }).MakeRequest(),
          new TDFRequest({
            url: `${TDFRequest.ApiPath}${avs.UpdateGridScopeURL}`,
            type: "POST",
            data: {
              itemType: avs.ItemType,
              containerType: avs.GridContainerType,
              isDesktop: 0,
              useGlobalScope: v.IsGlobalView
            }
          }).MakeRequest()
        ).done(() => {
          avs._allViews.forEach(view => {
            if (view.DefaultView) {
              view.DefaultView = false;
            }
            if (view.GUID === v.GUID) {
              view.DefaultView = true;
            }
          });
          avs.ReloadViews(true);
        });
      });
  }

  get Options(): DevExpress.ui.dxDropDownBoxOptions {
    let avs = this;
    return {
      displayExpr: "Name",
      valueExpr: "GUID",
      dataSource: avs.ViewsDataSource,
      hint: "Right click for options.",
      width: GetDevice().isDevice ? '150px' : '250px',
      dropDownOptions: {
        fullScreen: GetDevice().isDevice,
        width: GetDevice().isDevice
          ? $(window).innerWidth()
          : $(window).innerWidth() * 0.4,
        //	height: window.innerHeight > 800 ? 800 : window.innerHeight,
        resizeEnabled: true,
        onShown(e) {
          avs.ViewsDataSource.store()
            .byKey(avs.Instance.option("value"))
            .done(v => {
              if (
                v &&
                (avs.tabs as any)._itemElements() &&
                (avs.tabs as any)._itemElements().length
              ) {
                (avs.tabs as any)
                  ._itemElements()
                  .removeClass("dx-state-focused");
                (avs.tabs as any).selectItem(
                  v.IsGlobalView
                    ? avs.tabs.option("items[0]")
                    : avs.tabs.option("items[1]")
                );
                avs.list.unselectAll();
              }
            });
        },
        showCloseButton: true,
        title: "Available Grid Views",
        showTitle: true
      },
      contentTemplate(data, el: JQuery) {
        avs.ViewsDataSource.store()
          .byKey(avs.Instance.option("value"))
          .done(v => {
            if (!avs.initialItem) {
              avs.initialItem = v;
            }

            el.append(avs.tabs.element()).append(avs.list.element());
          });
      },
      onValueChanged(lookupchangedevent) {
        if (lookupchangedevent.value !== lookupchangedevent.previousValue) {
          lookupchangedevent.component
            .option("dataSource")
            .store()
            .byKey(lookupchangedevent.value)
            .done(k => {
              if (k || lookupchangedevent.value === 'Welcome') {
                RaiseEvent2(
                  EventTypes.CenterEventTypes.gridviewselect,
                  avs.EventLimiterName,
                  eventNameSpace.modify,
                  k || { GUID: lookupchangedevent.value }
                );
              }
            });
        }
      },
      onInitialized(e) {
        $("#view-set-default").remove();
        $("#tdfbody").append($("<div id='view-set-default' />"));
        $("#view-set-default").dxContextMenu(
          <DevExpress.ui.dxContextMenuOptions>{
            target: e.element,
            items: [{ text: "Set Current View as Default" }],
            hint:
              "Set the current view as your default for this scope and type.",
            onItemClick(ev) {
              avs.ViewsDataSource.store()
                .byKey(avs.Instance.option("value"))
                .done(v => {
                  new TDFRequest({
                    url: `${TDFRequest.ApiPath}${avs.SetViewAsDefaultURL}`,
                    type: "POST",
                    data: {
                      containerType: avs.GridContainerType,
                      itemType: v.ItemType,
                      viewid: e.component.option("value"),
                      isGlobal: v.IsGlobalView
                    }
                  }).MakeRequest();

                  new TDFRequest({
                    url: `${TDFRequest.ApiPath}${avs.UpdateGridScopeURL}`,
                    type: "POST",
                    data: {
                      itemType: avs.ItemType,
                      containerType: avs.GridContainerType,
                      isDesktop: 0,
                      useGlobalScope: v.IsGlobalView
                    }
                  }).MakeRequest();
                });
            }
          }
        );
      }
    };
  }

  constructor(container, gridcontainertype, itemtype, eventLimiterName, viewGuid?: string) {
    this._container = container;
    this.EventLimiterName = eventLimiterName;
    this.GridContainerType = gridcontainertype;
    this.ItemType = itemtype;

    if (viewGuid) {
      this.initialItem = viewGuid;
    }

    this.AddListeners();
  }

  init() {
    this._container.dxDropDownBox(this.Options);
    this.ReloadViews();
  }

  AddListeners() {
    let avs = this;
    AddHandler2(EventTypes.CenterEventTypes.itemtype, avs.EventLimiterName, eventNameSpace.modify, avs._container, avs.TypeChanged.bind(avs));
    AddHandler2(EventTypes.CenterEventTypes.gridscope, avs.EventLimiterName, eventNameSpace.modify, avs._container, avs.ChangeScope.bind(avs));
    AddHandler2(EventTypes.CenterEventTypes.found, avs.EventLimiterName, eventNameSpace.modify, avs._container, avs.OnViewFound.bind(avs));
    AddHandler2(EventTypes.CenterEventTypes.notfound, avs.EventLimiterName, eventNameSpace.modify, avs._container, avs.OnViewNotFound.bind(avs));
    AddHandler2(EventTypes.CenterEventTypes.scopeforitemtype, avs.EventLimiterName, eventNameSpace.request, avs._container, avs.OnSetDefaultScopeForType.bind(avs));
    AddHandler2(EventTypes.CenterEventTypes.optionsforgridtype, avs.EventLimiterName, eventNameSpace.request, avs._container, avs.OnGridItemTypeOptionsRequested.bind(avs));
    AddHandler2(EventTypes.CenterEventTypes.refreshviews, avs.EventLimiterName, eventNameSpace.request, avs._container, avs.OnRefeshViewsRequested.bind(avs));
    AddHandler2(EventTypes.CenterEventTypes.viewsaved, avs.EventLimiterName, eventNameSpace.complete, avs._container, avs.OnViewSaved.bind(avs));
  }
  /**
   * this event is raised from the DataCenterTabs after the view has been closed and removed from the content cache
   * @param e
   * @param data
   */
  private OnViewSaved(e: JQueryEventObject, data: IGridView) {
    let avs = this;
    avs.Instance.option("value", "");
    avs.Instance.option("value", data.GUID);
  }

  OnRefeshViewsRequested(e: JQueryEventObject, data, checkDefault = false) {
    let avs = this;
    avs._allViews = [];
    avs.ReloadViews(checkDefault).done(() => {
      avs.Instance.option("dataSource").reload().done(() => {
        if (data) {
          avs.Instance.option("value", data);
        }
      });
    });
  }

  OnSetDefaultScopeForType(e: JQueryEventObject, data) {
    let avs = this;
    new TDFRequest({
      url: `${TDFRequest.ApiPath}${avs.UpdateGridScopeURL}`,
      type: "POST",
      data: {
        itemType: avs.ItemType,
        containerType: avs.GridContainerType,
        isDesktop: 0,
        useGlobalScope: data
      }
    }).MakeRequest().done(response => { });
  }

  OnViewFound(e: JQueryEventObject, data) {
    let avs = this;
    if (avs.Instance.option("value") === data.GUID) return;
    avs.Instance.option("value", data.GUID);
  }

  OnViewNotFound(e: JQueryEventObject, data) {
    let avs = this;
    avs.Instance.option("value", data.GUID);
  }

  TypeChanged(e: JQueryEventObject, data) {
    let avs = this;
    if (avs.ItemType === data.ItemType) return;

    if (avs.ItemType === itemTypes.itemRecentItem || avs.ItemType === itemTypes.itemFavorite) {
      /*NOTE: Do nothing here because recent items and favorites itemtype will not change but the itemtype filter will change
				 for now this is the only way i can think to handle this
				 */
    } else {
      //  avs.Instance.option("selectedItem", null)
      if (data.GUID) {
        avs.GuidToUse = data.GUID;
      } else {
        avs.GuidToUse = null;
      }
      avs.ItemType = data.ItemType;
      avs.ViewsDataSource.filter(["ItemType", "=", avs.ItemType]);
      avs.ReloadViews();
      if (typeof data.UsesToolbar !== "undefined") {
        data.UsesToolbar
          ? avs.Instance.option("disabled", false)
          : avs.Instance.option("disabled", true);
      } else {
        if (data.GUID) {
          let spectabs = [
            "Welcome",
            "SalesSummary",
            "GeneralSummary",
            ""
          ];
          $.inArray(data.GUID, spectabs) >= 0
            ? avs.Instance.option("disabled", true)
            : avs.Instance.option("disabled", false);
          //data.GUID === "SalesSummary" || data.GUID === "                                           GenSummary" ? avs.Instance.option("disabled", true) : avs.Instance.option("disabled", false);
        }
      }
    }
  }

  private ChangeScope(e: JQueryEventObject, data) {
    let avs = this;
    if (data === avs.Scope) return;
    avs.Scope = data;
    this.ReloadViews();
  }

  /**
   * The event handler for when a user clicks the grid settings button.
   * @param e
   * @param data
   */
  OnGridItemTypeOptionsRequested(e: JQueryEventObject, data) {
    let avs = this;
    let myRequest = new TDFRequest({ url: "/Settings/GetGridTypes" });
    let theType = avs.ItemType;
    let ViewScopesForm: DevExpress.ui.dxForm;
    myRequest.MakeRequest().done(function (response) {
      let gridTypes: {
        Category: string;
        ItemName: string;
        RelatedItemType: itemTypes;
      }[] =
        response.Itemlisting;
      $.when(
        new TDFRequest({
          url: "/Settings/GetGridTypeContainerScopes",
          type: "POST",
          data: { ItemType: avs.ItemType }
        }).MakeRequest(),
        DisplayInfo(avs.ItemType)
      ).done(
        (
          re: IGridContainer_ItemType_Scope_Descriptor[],
          info: IDisplayInfo
        ) => {
          let scopeformOptions = (): DevExpress.ui.dxFormOptions => {
            return {
              //  formData: data,
              colCount: 6,
              colCountByScreen: { lg: 6, sm: 6, md: 6, xs: 6 },
              items: <Array<DevExpress.ui.dxFormGroupItem>>[
                {
                  itemType: "group",
                  caption: "Manage Default View Types",
                  //label: "Manage Default View Types",
                  items: [
                    {
                      itemType: "simple",
                      helpText:
                        "You can manage the default view types for Container & ItemTypes here. Select an Item Type from the menu above.",
                      editorType: "dxLookup",

                      editorOptions: <DevExpress.ui.dxLookupOptions>{
                        dataSource: new dxDataSource({
                          store: new dxArrayStore({
                            data: gridTypes,
                            key: "RelatedItemType"
                          }),

                          searchExpr: () => {
                            return ["ItemName"];
                          },
                          group: "Category"
                        }),
                        grouped: true,
                        closeOnOutsideClick: true,
                        displayExpr: "ItemName",

                        valueExpr: "RelatedItemType",
                        cleanSearchOnOpening: true,

                        onOpened(e) {
                          (e.component as any)._$list
                            .dxScrollView("instance")
                            .scrollToElement(
                              e.component.option("selectedItem")
                            );
                        },
                        // value: theType,
                        onItemClick(e) {
                          $.when(
                            new TDFRequest({
                              url: "/Settings/GetGridTypeContainerScopes",
                              type: "POST",
                              data: { ItemType: e.itemData.RelatedItemType }
                            }).MakeRequest(),
                            DisplayInfo(e.itemData.RelatedItemType)
                          ).done(
                            (
                              re: IGridContainer_ItemType_Scope_Descriptor[],
                              info: IDisplayInfo
                            ) => {
                              let all = ViewScopesForm.option("items");
                              let t = all[0].items.splice(
                                4,
                                all[0].items.length - 1
                              );
                              all[0].items = all[0].items.concat(
                                avs.GetItems(re, info)
                              );
                              ViewScopesForm.repaint();
                              e.component.option("value", info.TypeNum);
                            }
                          );
                        },
                        onInitialized(e) {
                          e.component.option("value", theType);
                        }
                      },
                      colSpan: 6
                    },
                    {
                      itemType: "simple",
                      label: { text: "Container" },
                      colSpan: 2,
                      template(d, e) {
                        return $(
                          `<span style='text-decoration:underline;'>Container</span>`
                        );
                      }
                    },
                    {
                      itemType: "simple",
                      label: { text: "Desktop" },
                      colSpan: 2,
                      template(d, e) {
                        return $(
                          `<i class="fa fa-desktop" ></i><span  style='text-decoration:underline;margin-left:5px;'>Desktop</span>`
                        );
                      }
                    },
                    {
                      itemType: "simple",
                      label: { text: "Web" },
                      colSpan: 2,
                      template(d, e) {
                        return $(
                          `<i class="fa fa-chrome"></i><span  style='text-decoration:underline;margin-left:5px;'>Web</span>`
                        );
                      }
                    },
                    ...avs.GetItems(re, info)
                  ],
                  colCount: 6,
                  colSpan: 6
                }
              ]
            };
          };
          let defaultViewFormOptions = (): DevExpress.ui.dxFormOptions => {
            return {
              items: [
                {
                  itemType: "group",
                  caption: "Default View For this type.",
                  //label: "Default View For Scope",
                  items: [
                    {
                      itemType: "simple",
                      helpText: "Set the Default View.",
                      editorType: "dxLookup",

                      editorOptions: <DevExpress.ui.dxLookupOptions>{
                        dataSource: avs.ViewsDataSource,
                        grouped: true,
                        closeOnOutsideClick: true,
                        displayExpr: "Name",

                        valueExpr: "GUID",
                        cleanSearchOnOpening: true,

                        onOpened(e) {
                          (e.component as any)._$list
                            .dxScrollView("instance")
                            .scrollToElement(
                              e.component.option("selectedItem")
                            );
                        },
                        // value: theType,
                        onItemClick(e) {
                          new TDFRequest({
                            url: `${TDFRequest.ApiPath}${
                              avs.SetViewAsDefaultURL
                              }`,
                            type: "POST",
                            data: {
                              containerType: avs.GridContainerType,
                              itemType: avs.ItemType,
                              viewid: e.itemData.GUID,
                              isGlobal:
                                avs.Scope === enumscope.global ? true : false
                            }
                          }).MakeRequest();
                          new TDFRequest({
                            url: `${TDFRequest.ApiPath}${
                              avs.UpdateGridScopeURL
                              }`,
                            type: "POST",
                            data: {
                              itemType: avs.ItemType,
                              containerType: avs.GridContainerType,
                              isDesktop: 0,
                              useGlobalScope: e.itemData.IsGlobalView
                            }
                          }).MakeRequest();
                        }
                      },
                      colSpan: 6
                    }
                  ],
                  colCount: 6,
                  colSpan: 6
                }
              ]
            };
          };
          let sortingFilteringFormOptions = (): DevExpress.ui.dxFormOptions => {
            return {
              colCountByScreen: { lg: 12, sm: 12, md: 6, xs: 6 },
              items: [
                // Removed these groups for now as they weren't updating anything and weren't being respected.
                // {
                //   itemType: "group",
                //   caption: "Sorting Options",
                //   //label: "Sorting Options",
                //   items: [
                //     {
                //       itemType: "simple",
                //       editorType: "dxCheckBox",
                //       editorOptions: <DevExpress.ui.dxCheckBoxOptions>{
                //         value: true,
                //         text: "Sort Data on Server"
                //       },
                //       helpText:
                //         "When this is checked clicking on the column header will sort on the entire dataset. Else, only current data loaded will be sorted",
                //       colSpan: 6
                //     }
                //   ],
                //   colCount: 6,
                //   colSpan: 6
                // },
                // {
                //   itemType: "group",
                //   caption: "Filtering Options",
                //   //label: "Filtering Options",
                //   items: [
                //     {
                //       itemType: "simple",
                //       editorType: "dxCheckBox",
                //       editorOptions: <DevExpress.ui.dxCheckBoxOptions>{
                //         value: true,
                //         text: "Filter Data on Server"
                //       },
                //       helpText:
                //         "When this is checked entering text in the filter row will filter the entire dataset. Else, only current data loaded will be filtered",
                //       colSpan: 6
                //     }
                //   ],
                //   colCount: 6,
                //   colSpan: 6
                // },
                {
                  itemType: "group",
                  caption: "Page Options",
                  items: [
                    {
                      itemType: 'simple',
                      editorType: 'dxNumberBox',
                      label: { text: "Page Size to Retrieve from Server" },
                      editorOptions: <DevExpress.ui.dxNumberBoxOptions>{
                        value: parseInt(Preferences.GetPreference(
                          'ServerPageSize',
                          'TDFMobile',
                          '1000'
                        )),
                        onValueChanged(e) {
                          Preferences.SetPreference(
                            'ServerPageSize',
                            e.value,
                            'TDFMobile'
                          );
                        }
                      },
                      helpText:
                        "This determines how many items are loaded on the server when a grid is loaded. Use Caution! Large page sizes can cause performance issues.",
                      colSpan: 6
                    }
                  ],
                  colCount: 6,
                  colSpan: 6
                }
              ]
            };
          };
          let DisplayFormOptions = (checked): DevExpress.ui.dxFormOptions => {
            return {
              colCountByScreen: { lg: 12, sm: 12, md: 6, xs: 6 },
              items: [
                {
                  itemType: "group",
                  caption: "Card View Options",
                  items: [
                    {
                      itemType: "simple",
                      editorType: "dxCheckBox",
                      editorOptions: <DevExpress.ui.dxCheckBoxOptions>{
                        value: checked,
                        text: "Use Card View for this Grid View",
                        onValueChanged(e) {
                          Preferences.SetPreference(
                            `UseCardView_${avs.Instance.option("value")}`,
                            e.value ? "1" : "0",
                            `WebGrid`
                          );
                        }
                      },
                      helpText:
                        "When enabled the grid will use a collapsable format for easier navigation and to save space on devices.",
                      colSpan: 12
                    },
                  ],
                  colCount: 12,
                  colSpan: 6
                }, {
                  itemType: 'group',
                  caption: 'Recent and Favorites Options',
                  visible: avs.ItemType === itemTypes.itemRecentItem || avs.ItemType === itemTypes.itemFavorite,
                  items: [
                    {
                      itemType: 'simple',
                      editorType: 'dxCheckBox',
                      editorOptions: <DevExpress.ui.dxCheckBoxOptions>{
                        value: Preferences.GetPreference(
                          'CompactView_FromNav',
                          'SearchRecFav',
                          '0'
                        ) === '1',
                        text: 'Compact View from Navigation Bar',
                        onValueChanged(e) {
                          Preferences.SetPreference(
                            'CompactView_FromNav',
                            e.value ? "1" : "0",
                            'SearchRecFav'
                          )
                        }
                      },
                      helpText:
                        "When enabled clicking the Recent or Favorites buttons will show a small version of the grid in Card View. (Does not currently include Search)",
                      colSpan: 12
                    }
                  ],
                  colCount: 12,
                  colSpan: 6
                }
              ]
            };
					};

          let TabOptions = (): DevExpress.ui.dxTabPanelOptions => {
            return {
              items: <DevExpress.ui.dxTabPanelItemTemplate[]>[
                { title: "Default Types" },
                { title: "Default View" },
                { title: "Server Options" },
								{ title: "Display Options" }
              ],
              itemTemplate: (d, i, e) => {

                if (!e.hasClass("container-fluid")) {
                  e.addClass("container-fluid").css("padding-top", "10px");
                }

                let scroll: DevExpress.ui.dxScrollView = $("<div />").dxScrollView({ height: "100%" }).dxScrollView("instance");

                switch (d.title) {
                  case "Default Types":
                    ViewScopesForm = $("<div />").dxForm(scopeformOptions()).dxForm("instance");
                    e.append(scroll.element());
                    scroll.content().append(ViewScopesForm.element());

                    break;
                  case "Default View":
                    e.append($("<div />").dxForm(defaultViewFormOptions()));
                    break;
                  case "Server Options":
                    e.append(
                      $("<div />").dxForm(sortingFilteringFormOptions())
                    );
                    break;
                  case "Display Options":
                    e.append(
                      $("<div />").dxForm(
                        DisplayFormOptions(
                          Preferences.GetPreference(
                            `UseCardView_${avs.Instance.option("value")}`,
                            `WebGrid`,
                            "1"
                          ) === "1"
                        )
                      )
                    );
										break;
                }
              },
            } || {};
          };

          let dialog = new Dialog(
            {
              size: "size-large",
              title: "Edit Grid Options",
              closable: true,
              body: $("<div id='grid-opts-tabpanel' />").dxTabPanel(
                TabOptions()
              ), //$("<div id='grid-opts-form'/>"),
              type: "type-primary",
              showHelpButton: true
            },
            null,
            true,
            false
          );

          dialog.open().done(function () {
            let body = dialog.getModalBody();
            $("#grid-opts-tabpanel")
              .dxTabPanel("instance")
              .option("height", body.height() - 20);
            ViewScopesForm.option("height", body.height() - 20);
          });
        }
      );
    });
  }

  protected ReloadViews(checkDefault = true) {
    let avs = this;
    let d = $.Deferred();

    avs.ViewsDataSource.reload().done(
      (
        data: { key: string; items: TDFDataCenters.DataCenterTabs.TabItem[] }[]
      ) => {
        if (data.length > 0 && checkDefault) {
          let defv: TDFDataCenters.DataCenterTabs.TabItem;
          let length;
          for (var i = 0; (length = data.length), i < length; i++) {
            data[i].items.some((view, index) => {
              if (avs.GuidToUse) {
                if (view.GUID === avs.GuidToUse) {
                  defv = view;
                }
                return view.GUID === avs.GuidToUse;
              } else {
                if (view.DefaultView) {
                  defv = view;
                  return true;
                }
                return view.DefaultView;
              }
            });
            if (defv) {
              avs.GuidToUse = null;
              if (avs.Instance.option("value") !== defv.GUID) {
                avs.Instance.option("value", defv.GUID);
                //UI_Events.Dispatch(UI_Events.GetEventWithData(UI_Events.GridScopeChanged, defv.IsGlobalView ? enumscope.global : enumscope.local));
              }
              break;
            }
          }
          d.resolve();
        } else {
          d.resolve();
        }
      }
    );
    return d.promise();
  }

  private GetItems(
    re: IGridContainer_ItemType_Scope_Descriptor[],
    info: IDisplayInfo
  ) {
    let avs = this;
    let items: DevExpress.ui.dxFormSimpleItem[] = [];

    re.forEach((val, key) => {
      items.push({
        itemType: "simple",
        /*helpText: `${info.DisplayName} grids on ${val.ContainerType} default to (personal/global).`,*/ template(
          d,
          e
        ) {
          return $(`<strong >${val.ContainerType}</strong>`);
        },
        colSpan: 2
      });
      items.push(<DevExpress.ui.dxFormButtonItem>{
        itemType: "button",
        // editorType: "dxButton",
        helpText: `Within Desktop, ${info.DisplayName} grids on ${
          val.ContainerType
          } default to (personal/global).`,
        buttonOptions: {
          elementAttr: {
            class: `${val.ContainerType}--Desktop--${val.ScopeDesktop}`
          },

          icon: val.ScopeDesktop === enumscope.local ? "home" : "globe",
          //TODO: Figure out how to override this
          //type: val.ScopeDesktop === enumscope.local ? "warning" : "info",
          onClick(e) {
            e.component.option(
              "icon",
              val.ScopeDesktop === enumscope.local ? "globe" : "home"
            );
            e.component.option(
              "type",
              val.ScopeDesktop === enumscope.local ? "info" : "warning"
            );
            val.ScopeDesktop =
              val.ScopeDesktop === enumscope.local
                ? enumscope.global
                : enumscope.local;
            avs.SetDefaultScopeForItemTypeByContainerType(
              val,
              true,
              info.TypeNum
            );
          }
        },
        colSpan: 2
      });
      items.push(<DevExpress.ui.dxFormButtonItem>{
        itemType: "button",

        helpText: `Within Web, ${info.DisplayName} grids on ${
          val.ContainerType
          } default to (personal/global).`,
        buttonOptions: <DevExpress.ui.dxButtonOptions>{
          elementAttr: { class: `${val.ContainerType}--Web--${val.ScopeWeb}` },
          icon: val.ScopeWeb === enumscope.local ? "home" : "globe",
          //TODO: Figure out how to override this
          //	type: val.ScopeWeb === enumscope.local ? "warning" : "info",
          onClick(e) {
            e.component.option(
              "icon",
              val.ScopeWeb === enumscope.local ? "globe" : "home"
            );
            e.component.option(
              "type",
              val.ScopeWeb === enumscope.local ? "info" : "warning"
            );
            val.ScopeWeb =
              val.ScopeWeb === enumscope.local
                ? enumscope.global
                : enumscope.local;
            avs.SetDefaultScopeForItemTypeByContainerType(
              val,
              false,
              info.TypeNum
            );
          }
        },
        colSpan: 2
      });
    });

    return items;
  }

  SetDefaultScopeForItemTypeByContainerType(
    val: IGridContainer_ItemType_Scope_Descriptor,
    isDesktop: boolean,
    itemtype: itemTypes
  ) {
    let avs = this;
    let data = {
      itemType: itemtype,
      containerType: val.ContainerTypeID,
      isDesktop: isDesktop,
      useGlobalScope: isDesktop
        ? val.ScopeDesktop === enumscope.global
        : val.ScopeWeb === enumscope.global
    };

    let myRequest = new TDFRequest({
      url: `${TDFRequest.ApiPath}${avs.UpdateGridScopeURL}`,
      type: "POST",
      data: data
    });
    myRequest.MakeRequest().done(function (response) {
      if (response && response.Valid == true) {
      }
    });
  }
}

export function ViewLookup(args, config?: IToolbarItemOverrideOptions) {
  let ItemType = itemTypes.itemUnknown;
  let gridContainerType = GridContainerTypes.Unknown;
  let centerName: string = "";
  let viewGuid: string = "";
  if (args && args.length) {
    args.map((v, k) => {
      if ($.isPlainObject(v)) {
        Object.keys(v).forEach((val, key) => {
          if (val.toLowerCase() === "gridcontainertype") {
            gridContainerType = v[val];
          } else if (val.toLowerCase() === "itemtype") {
            ItemType = v[val];
          } else if (val.toLowerCase() === "uniquecentername") {
            centerName = v[val];
          } else if (val.toLowerCase() === "viewguid") {
            viewGuid = v[val];
          }
        });
      }
    });
  }

  return ToolbarTemplateFactory(
    config && config.location ? config.location : "after",
    config && config.locateInMenu ? config.locateInMenu : "never",
    false,
    null,
    "",
    (data, index, element) => {
      let timer;
      let div = $("<div />")
        .width(GetDevice().isDevice ? '150px' : '250px')
        .appendTo(element);
      let selector;
      timer = setInterval(function () {
        if (document.body.contains(element[0])) {
          clearInterval(timer);
          selector = new AwesomeViewLookup(
            div,
            gridContainerType,
            ItemType,
            centerName,
            viewGuid
          );
          selector.init();
        }
      });
    }
  );
}