import { BaseDataCenter } from "../basedatacenter";
import { CenterType, itemTypes } from "enums/enums";
import { TDFDataCenters } from "../../interfaces/datacenters/tdfdatacenters";
import { EventLocation } from "interfaces/items/interfaces";
import { TDFRequest } from "services/request";
import { SearchRecFav } from "components/controls/searchrecfav";
import { createitem } from "components/items/factory/itemfactory";
import { DomSafeID } from "util/allutils";

export abstract class EventCenter extends BaseDataCenter {
    protected MyCreationArgs: TDFDataCenters.ICRMDataCenterCreationArgs;

    /**The jquery container that contains the toolbar */
    private _DataToolbarAreaContainer: JQuery;
    get DataToolbarAreaContainer(): JQuery {
        let evtdc = this;
        if (evtdc._DataToolbarAreaContainer) return evtdc._DataToolbarAreaContainer;
        let container: JQuery;
        if (evtdc.MyCreationArgs && evtdc.MyCreationArgs.DataToolbarAreaContainer) {
            container = $(evtdc.MyCreationArgs.DataToolbarAreaContainer);
        }
        if (container && container.length) {
            evtdc._DataToolbarAreaContainer = container;
        } else {
            /*Add to MainDataAreaContainer*/
            evtdc.DataToolbarAreaContainer = $(
                `<div id='event-datacenter-datatoolbar-container${Date.now()}' />`
            ).appendTo(evtdc.MainDataAreaContainer);
        }

        if (
            !jQuery.contains(document.documentElement, evtdc.MainDataAreaContainer[0])
        ) {
            //Element is detached
            throw "Unable to find container => _DataToolbarAreaContainer ";
        }
        return evtdc._DataToolbarAreaContainer;
    }
    set DataToolbarAreaContainer(val: JQuery) {
        let evtdc = this;
        if (val) evtdc._DataToolbarAreaContainer = val;
    }

    /**The jquery container that contains tabs that contain the grids of data */
    private _DataTabsAreaContainer: JQuery;
    get DataTabsAreaContainer(): JQuery {
        let evtdc = this;
        if (evtdc._DataTabsAreaContainer) return evtdc._DataTabsAreaContainer;
        let container: JQuery;
        if (evtdc.MyCreationArgs && evtdc.MyCreationArgs.DataTabsAreaContainer) {
            container = $(evtdc.MyCreationArgs.DataTabsAreaContainer);
        }
        if (container && container.length) {
            evtdc.DataTabsAreaContainer = container;
        } else {
            /*Add to MainDataAreaContainer*/
            evtdc.DataTabsAreaContainer = $(
                `<div id='event-datacenter-datatabs-container${Date.now()}'/>`
            ).appendTo(evtdc.MainDataAreaContainer);
        }
        if (
            !jQuery.contains(document.documentElement, evtdc.MainDataAreaContainer[0])
        ) {
            //Element is detached
            throw "Unable to find container => _DataTabsAreaContainer ";
        }

        return evtdc._DataTabsAreaContainer;
    }
    set DataTabsAreaContainer(val: JQuery) {
        let evtdc = this;
        if (val) evtdc._DataTabsAreaContainer = val;
    }

    /**The jquery container that contains tabs that contain the grids of data */
    private _DataGridAreaContainer: JQuery;
    get DataGridAreaContainer(): JQuery {
        let evtdc = this;
        if (evtdc._DataGridAreaContainer) return evtdc._DataGridAreaContainer;
        let container: JQuery;
        if (evtdc.MyCreationArgs && evtdc.MyCreationArgs.DataGridAreaContainer) {
            container = $(evtdc.MyCreationArgs.DataGridAreaContainer);
        }
        if (container && container.length) {
            evtdc.DataGridAreaContainer = container;
        } else {
            /*Add to MainDataAreaContainer*/
            evtdc.DataGridAreaContainer = $(
                `<div id='event-datacenter-datagrid-container${Date.now()}'/>`
            ).appendTo(evtdc.MainDataAreaContainer);
        }
        if (
            !jQuery.contains(document.documentElement, evtdc.MainDataAreaContainer[0])
        ) {
            //Element is detached
            throw "Unable to find container => _DataGridAreaContainer ";
        }

        return evtdc._DataGridAreaContainer;
    }
    set DataGridAreaContainer(val: JQuery) {
        let evtdc = this;
        if (val) evtdc._DataGridAreaContainer = val;
    }

    private _Locations: EventLocation[];
    get Locations(): JQueryPromise<EventLocation[]> {
        let evtdc = this;
        let d: JQueryDeferred<EventLocation[]> = $.Deferred();

        if (evtdc._Locations && evtdc._Locations.length) {
            return d.promise(d.resolve(evtdc._Locations));
        }

        new TDFRequest({
            url: '/Item/Event/Location',
            type: 'GET'
        }).MakeRequest().done((response: EventLocation[]) => {
            let locations = response.map((a) => {
                for (const prop in a.Properties) {
                    if (a.Properties.hasOwnProperty(prop)) {
                        let element = a.Properties[prop];
                        a[prop] = element;
                    }
                }
                return a;
            });

            evtdc._Locations = locations;

            d.resolve(locations);
        });

        return d.promise();
    }

    protected EventID: string;

    constructor(eventID: string, customName: string, MyCreationArgs: TDFDataCenters.ICRMDataCenterCreationArgs) {
        super(`${customName}${DomSafeID(eventID)}`);
        this.ValidateCreationArgs(MyCreationArgs);
        this.EventID = eventID;
    }

    protected GetContactSearchField(e) {

        let linkedContact = $('<a/>')
            .addClass('badge badge-primary')
            .css('cursor', 'pointer');

        if (e.editorOptions.value) {
            linkedContact
                .text(e.editorOptions.value.Name)
                .on('click', () => {
                    createitem(itemTypes.itemContact, { ItemID: e.editorOptions.value.ID })
                });
        }

        let button: DevExpress.ui.dxButton = $('<div />')
            .css('margin-right', '5px')
            .dxButton({
                icon: 'dx-icon fa fa-paperclip',
                onClick: () => {
                    let srf = new SearchRecFav(itemTypes.itemUnknown, [itemTypes.itemContact], itemTypes.itemContact, false);
                    srf.GetSRFDialog().done(selected => {
                        linkedContact.text(selected[0].Description);
                        linkedContact.on('click', () => {
                            createitem(itemTypes.itemContact, { ItemID: selected[0]['TDF GUID'] })
                        });

                        e.component.updateData(e.dataField, {
                            ID: selected[0]['TDF GUID'],
                            Name: selected[0].Description
                        })
                        srf.Dialog.close();
                    });
                }
            }).dxButton('instance');

        let element = $('<div />').append(
            button.element(),
            linkedContact
        );

        return element
    }


}