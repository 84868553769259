import { CenterType, itemTypes } from "../../enums/enums";
import { BISalesSummaryType, BSISummarySource, BSIGoalType, ISummary } from "../../enums/bi/enums";

import { eventNameSpace, EventTypes } from "../../enums/webevents/enums";
import { TDFDataCenters } from "../../interfaces/datacenters/tdfdatacenters";
import { AddHandler2, RaiseEvent2 } from "../../infrastructure/events/ui_events";
import { Preferences } from "../../infrastructure/user/preferences";
import { ISalesSumData } from "../../interfaces/bi/interfaces";
import { TDFRequest } from "../../services/request";
import { Grid } from "../../legacy-centers-grids/grid";
import { DrillHelper } from "./drillhelper";
import { GetPreference } from "../../infrastructure/user/getpreference";
import { DomSafeID } from "../../util/allutils";
import { LoadUser } from "../../infrastructure/context";
import * as Globalize from "globalize";
import { BISummaryItemSettingsSelector } from "../../infrastructure/user/bisummaryitemsettingsselector";
import { DataScopeSelection } from "../../components/dashboards/dynamicdatascopeselector";
import {
  GetBIPrefModuleFromBISummarySource,
  GetBISummarySourceFromCenterType,
  TranslateFieldName
} from "./bi_utils";

export class SalesSummary {
  private SalesSummaryURL: string = "/bi/SalesSummary";
  private CustomNum: number;
  private CustomID: string;

  private _container: JQuery;
  private _CenterType: CenterType;
  private _tabItem: TDFDataCenters.DataCenterTabs.TabItem;
  private EventLimiterName: string = "";

  private _SummarySettings: DataScopeSelection;

  private salesSummaryData: any;
  private expandAnnualSummary: boolean = false;
  private expandQuarterlySummary: boolean = false;
  private expandMonthlySummary: boolean = false;
  private expandDailySummary: boolean = false;

  private pinAnnualSummary: boolean = false;
  private pinQuarterlySummary: boolean = false;
  private pinMonthlySummary: boolean = false;
  private pinDailySummary: boolean = false;
  constructor(
    container: JQuery,
    centertype: CenterType,
    tabItem: TDFDataCenters.DataCenterTabs.TabItem,
    eventLimiterName: string
  ) {
    this._container = container;
    this._CenterType = centertype;
    this._tabItem = tabItem;
    this.EventLimiterName = eventLimiterName;

    this.CustomNum = Math.ceil(Math.random() * 999 + 1);
    let id = DomSafeID(this._tabItem.GUID + "_" + this.CustomNum.toString());
    this.CustomID = id;

    this.Init();
  }

  Init() {
    LoadUser().done(() => {
      let theAllSalesTab = this;
      let preferenceModule = GetBIPrefModuleFromBISummarySource(GetBISummarySourceFromCenterType(theAllSalesTab._CenterType));

      theAllSalesTab.pinAnnualSummary = !!parseInt(Preferences.GetPreference(theAllSalesTab.GetSalesSummaryExpansionPreferenceName(BISalesSummaryType.Annual), preferenceModule));
      theAllSalesTab.pinQuarterlySummary = !!parseInt(Preferences.GetPreference(theAllSalesTab.GetSalesSummaryExpansionPreferenceName(BISalesSummaryType.Quarterly), preferenceModule));
      theAllSalesTab.pinMonthlySummary = !!parseInt(Preferences.GetPreference(theAllSalesTab.GetSalesSummaryExpansionPreferenceName(BISalesSummaryType.Monthly), preferenceModule));
      theAllSalesTab.pinDailySummary = !!parseInt(Preferences.GetPreference(theAllSalesTab.GetSalesSummaryExpansionPreferenceName(BISalesSummaryType.Daily), preferenceModule));

      theAllSalesTab.expandAnnualSummary = theAllSalesTab.pinAnnualSummary;
      theAllSalesTab.expandQuarterlySummary = theAllSalesTab.pinQuarterlySummary;
      theAllSalesTab.expandMonthlySummary = theAllSalesTab.pinMonthlySummary;
      theAllSalesTab.expandDailySummary = theAllSalesTab.pinDailySummary;

      theAllSalesTab.AddListeners();

      let sendDFD: JQueryDeferred<DataScopeSelection> = $.Deferred();
      RaiseEvent2(
        EventTypes.BIEventTypes.currentsummaryselection,
        theAllSalesTab.EventLimiterName,
        eventNameSpace.request,
        { deferred: sendDFD }
      );

      sendDFD.done(function (scopeSettings) {
        theAllSalesTab._SummarySettings = scopeSettings;
        theAllSalesTab.RenderTab();
      });
    });
  }

  private AddListeners() {
    let theAllSalesTab = this;
    AddHandler2(EventTypes.BIEventTypes.currentsummaryselection, theAllSalesTab.EventLimiterName, eventNameSpace.notify, theAllSalesTab._container, theAllSalesTab.OnUpdateSummarySettings.bind(theAllSalesTab));
    AddHandler2(EventTypes.BIEventTypes.currentgoalselection, theAllSalesTab.EventLimiterName, eventNameSpace.notify, theAllSalesTab._container, theAllSalesTab.OnUpdateGoalSettings.bind(theAllSalesTab));
    AddHandler2(EventTypes.CenterEventTypes.gridrefresh, theAllSalesTab.EventLimiterName, eventNameSpace.request, theAllSalesTab._container, theAllSalesTab.OnRefreshButtonClicked.bind(theAllSalesTab));
  }
  
  private OnRefreshButtonClicked(e: JQueryEventObject, data) {
    let theAllSalesTab = this;
    theAllSalesTab.ReRenderTab();
  }

  private OnUpdateSummarySettings(e: JQueryEventObject, data) {
    let theAllSalesTab = this;
    theAllSalesTab._SummarySettings = data.NewSummarySettings;
    theAllSalesTab.ReRenderTab();
  }

  private OnUpdateGoalSettings(e: JQueryEventObject, data) {
    // We don't need to set anything out becuase changing the goal settings updates the user preferences, and we use the user preference.
    // But, we need to force a re-render to use the new settings.
    let theAllSalesTab = this;
    theAllSalesTab.ReRenderTab();
  }

  private ClearCachedData() {
    let theAllSalesTab = this;
    theAllSalesTab.salesSummaryData = undefined;
  }

  private ReRenderTab() {
    let theAllSalesTab = this;
    theAllSalesTab.ClearCachedData();
    theAllSalesTab.RenderTab();
  }

  private ShouldSectionExpand(summaryType: BISalesSummaryType): boolean {
    let theAllSalesTab = this;
    let returnVal: boolean = false;
    switch (summaryType) {
      case BISalesSummaryType.Annual: {
        returnVal = theAllSalesTab.expandAnnualSummary;
        break;
      }
      case BISalesSummaryType.Quarterly: {
        returnVal = theAllSalesTab.expandQuarterlySummary;
        break;
      }
      case BISalesSummaryType.Monthly: {
        returnVal = theAllSalesTab.expandMonthlySummary;
        break;
      }
      case BISalesSummaryType.Daily: {
        returnVal = theAllSalesTab.expandDailySummary;
        break;
      }
    }
    return returnVal;
  }

    private ShouldSectionBePinned(summaryType: BISalesSummaryType): boolean {
        let theAllSalesTab = this;
        let returnVal: boolean = false;

        switch (summaryType) {
            case BISalesSummaryType.Annual: {
                returnVal = theAllSalesTab.pinAnnualSummary;
                break;
            }
            case BISalesSummaryType.Quarterly: {
                returnVal = theAllSalesTab.pinQuarterlySummary;
                break;
            }
            case BISalesSummaryType.Monthly: {
                returnVal = theAllSalesTab.pinMonthlySummary;
                break;
            }
            case BISalesSummaryType.Daily: {
                returnVal = theAllSalesTab.pinDailySummary;
                break;
            }
        }
        return returnVal;
    }

  private ToggleSection(summaryType: BISalesSummaryType) {
    let theAllSalesTab = this;
    switch (summaryType) {
      case BISalesSummaryType.Annual: {
        theAllSalesTab.expandAnnualSummary = !theAllSalesTab.expandAnnualSummary;
        break;
      }
      case BISalesSummaryType.Quarterly: {
        theAllSalesTab.expandQuarterlySummary = !theAllSalesTab.expandQuarterlySummary;
        break;
      }
      case BISalesSummaryType.Monthly: {
        theAllSalesTab.expandMonthlySummary = !theAllSalesTab.expandMonthlySummary;
        break;
      }
      case BISalesSummaryType.Daily: {
        theAllSalesTab.expandDailySummary = !theAllSalesTab.expandDailySummary;
        break;
      }
    }
  }

  private TogglePinPreference(itemData) {
    let theAllSalesTab = this;

    itemData.ExpandItem = !itemData.ExpandItem;
    let prefValue = itemData.ExpandItem ? "1" : "0";
    let salesSummaryType = <BISalesSummaryType>itemData.SummaryType;
    let prefName = theAllSalesTab.GetSalesSummaryExpansionPreferenceName(salesSummaryType);
    let preferenceModule = GetBIPrefModuleFromBISummarySource(GetBISummarySourceFromCenterType(theAllSalesTab._CenterType));
    Preferences.SetPreference(prefName, prefValue, preferenceModule);

    switch (salesSummaryType) {
      case BISalesSummaryType.Annual: {
        theAllSalesTab.pinAnnualSummary = !theAllSalesTab.pinAnnualSummary;
        break;
      }
      case BISalesSummaryType.Quarterly: {
        theAllSalesTab.pinQuarterlySummary = !theAllSalesTab.pinQuarterlySummary;
        break;
      }
      case BISalesSummaryType.Monthly: {
        theAllSalesTab.pinMonthlySummary = !theAllSalesTab.pinMonthlySummary;
        break;
      }
      case BISalesSummaryType.Daily: {
        theAllSalesTab.pinDailySummary = !theAllSalesTab.pinDailySummary;
        break;
      }
    }
  }

  private RenderTab() {
    let theAllSalesTab = this;

    theAllSalesTab._container.empty();

    theAllSalesTab.GetSalesSummaryData().done((salesSumDataResponse: ISalesSumData) => {
      let container = $("<div id='allSalesWrapper_" + theAllSalesTab.CustomNum.toString() + "' class='centerTabWrapper'><div id='" + theAllSalesTab.CustomID + "'></div></div>");
        theAllSalesTab._container.height('100%').append(container);

      let salesSummaryAccordian: DevExpress.ui.dxAccordion = $("#" + theAllSalesTab.CustomID).dxAccordion({
            collapsible: true,
            multiple: true,
            items: salesSumDataResponse.Summaries,
            width: "100%",
            selectedIndex: -1, // Makes all sections collapsed by default.
            itemTitleTemplate: function (itemData, itemIndex, itemElement) {
              let salesSummaryType = <BISalesSummaryType>itemData.SummaryType;
              let expandItem: boolean = theAllSalesTab.ShouldSectionExpand(salesSummaryType);
              let pinItem: boolean = theAllSalesTab.ShouldSectionBePinned(salesSummaryType);

              itemData.ExpandItem = expandItem;

              let itemID = "biSalesSumDataTitle_" + itemIndex;
              let title = itemData.Title;
              let spaceIndex = title.indexOf(" ");
              let firstWord = spaceIndex == -1 ? "" : title.substring(0, spaceIndex);
              let otherWords = spaceIndex == -1 ? title : title.substring(spaceIndex);

              let pinBtnClass = "fa fa-thumb-tack hidden-xs";
              if (pinItem) {
                pinBtnClass = pinBtnClass + " stickyPinOn";
              }
              let pinBtn = $("<span />").addClass(pinBtnClass).css({ padding: "5px" }).on("click", function (e) {
                  theAllSalesTab.TogglePinPreference(itemData);

                  if (!$(e.target).hasClass("stickyPinOn")) {
                    $(e.target).addClass("stickyPinOn");
                  } else {
                    $(e.target).removeClass("stickyPinOn");
                  }

                  e.stopImmediatePropagation();
                });

              itemElement.append($("<div />").attr("id", itemID).attr("class", "biSectionHeader")
                .append("<span><strong>" + firstWord + "</strong>" + otherWords + "</span>")
                .append(pinBtn)
              );
            },
            itemTemplate: function (itemData, itemIndex, itemElement) {
              let itemID = "biSalesSumData_" + itemIndex;
              let itemGrid = $("<div id='" + itemID + "items'/>");
              itemElement.append(itemGrid);

              itemGrid.dxDataGrid({
                dataSource: itemData.SummaryData,
                allowColumnResizing: true,
                columnAutoWidth: true,
                columns: theAllSalesTab.TransformSalesSummaryColumns(
                  itemData.ColumnInfo,
                  itemData.Title
                ),
                showColumnLines: true,
                showRowLines: true,
                columnFixing: {
                  enabled: true
                },
                rowAlternationEnabled: true,
                showBorders: true,
                scrolling: {
                  mode: "virtual"
                },
                filterRow: {
                  visible: false
                },
                headerFilter: {
                  visible: false
                }
              });
            },
            onItemRendered: function (e) {
              if (e.itemData.ExpandItem) {
                //TODO:  Update pin class to be pinned
                (e.component as DevExpress.ui.dxAccordion).expandItem(e.itemIndex);
              }
            },
            onItemClick: function (e) {
              theAllSalesTab.ToggleSection(e.itemData.SummaryType);
            }
          })
          .dxAccordion("instance");

        $(
          "#allSalesWrapper_" + theAllSalesTab.CustomNum.toString()
        ).dxScrollView({
          //showScrollbar: "always"
        });
      });
  }

  UpdateSummarySelection(e: JQueryEventObject, data) {
    let theAllSalesTab = this;
    theAllSalesTab._SummarySettings = data.NewSummarySettings;
    theAllSalesTab.salesSummaryData = undefined;
    this.GetSalesSummaryData();
  }

  GetSalesSummaryData(): JQueryPromise<ISalesSumData> {
    let theAllSalesTab = this;
    let dfd: JQueryDeferred<ISalesSumData> = $.Deferred();

    if (theAllSalesTab.salesSummaryData) {
      return dfd.promise(dfd.resolve(theAllSalesTab.salesSummaryData));
    } else {

        let preferenceModule = GetBIPrefModuleFromBISummarySource(GetBISummarySourceFromCenterType(theAllSalesTab._CenterType));

        let settings = theAllSalesTab._SummarySettings;

        let data: { biListing: string, biLevel: ISummary.SummaryInterfaces.cBSID.EnumType, GoalType: BSIGoalType, SummarySource: BSISummarySource };

       data = {
        biListing: settings.CurrentlySelectedItemIDsAsString(),
        biLevel: settings.ActiveSelectionLevel,
          GoalType: settings.GetGoalSelection(preferenceModule),
          SummarySource: GetBISummarySourceFromCenterType(theAllSalesTab._CenterType)
        };

        if (data.biLevel === ISummary.SummaryInterfaces.cBSID.EnumType.InvalidType) {
            if (settings.IsOtherSummary === true) {
                data.biLevel = settings.otherSummarySettings.otherSummaryType;
            }
        }


      //if (theAllSalesTab._CenterType === CenterType.AccountBi) {
      //  //NEEDTODO - finish this for Account BI.
      //  /*TODO: Handle this without the concept of a legacy center */
      //  if (theAllSalesTab._CenterType === CenterType.AccountBi) {
      //    // if (theAllSalesTab.MyBICenter instanceof TDF.BiItemCenter) {
      //    //    let itemCenter: TDF.BiItemCenter = <TDF.BiItemCenter>theAllSalesTab.MyBICenter;
      //    //    if (itemCenter.ItemID) {
      //    //       data.AccountID = itemCenter.ItemID;
      //    //  }
      //    //  }
      //  }
      //}

      let request = new TDFRequest({ url: theAllSalesTab.SalesSummaryURL, type: "GET", data: data });

      request.MakeRequest().done(function (response) {
        theAllSalesTab.salesSummaryData = response;
        return dfd.resolve(response);
      });
    }

    return dfd.promise();
  }

    private GetSalesSummaryExpansionPreferenceName(salesSummaryType: BISalesSummaryType): string {
        let theAllSalesTab = this;
        let prefName: string = "";
        let prefNamePrefix: string = "";

        switch(theAllSalesTab._CenterType) {
          case CenterType.ContactBi:
            prefNamePrefix = "ContactBI";
            break;
          case CenterType.OppBi:
            prefNamePrefix = "OppBI";
            break;
          case CenterType.VendorBi:
            prefNamePrefix = "VendorBI";
            break;
          case CenterType.AccountBi:
            prefNamePrefix = ""
            break;
        }

        switch (salesSummaryType) {
          case BISalesSummaryType.Annual:
            prefName = "AnnualExpanded";
            break;
          case BISalesSummaryType.Quarterly:
            prefName = "QuarterExpanded";
            break;
          case BISalesSummaryType.Monthly:
            prefName = "MonthExpanded";
            break;
          case BISalesSummaryType.Daily:
            prefName = "DayExpanded";
            break;
        }

    return prefNamePrefix + prefName;
  }

  private TransformSalesSummaryColumns(fields, section): Array<DevExpress.ui.dxDataGridColumn> {
    let theAllSalesTab = this;
    let columns: Array<DevExpress.ui.dxDataGridColumn> = [];
    let mySection = section;

    // Add the drilldown column button.
    // TODO:  Hook this up to preferences and permissions for drill ins and maybe have it come back with the DataSource.
    let drillDownCol: DevExpress.ui.dxDataGridColumn | any = {
      alignment: "center",
      width: "40px",
      visible: true,
      allowEditing: false,
      //fixed: true,
      cellTemplate: function (container, options) {
        let section = mySection.toLowerCase();
        let startsWithResult: boolean = section.startsWith("annual") || section.startsWith("monthly");

        if (startsWithResult && (options.data.Item == "Sales" || options.data.Item == "Profit")) {
          //   alert(section + " - " + options.data.Item);
          $("<a/>").addClass("dx-link").text("+").on("dxclick", function () {
            let drillHelper = new DrillHelper(theAllSalesTab._CenterType, theAllSalesTab.EventLimiterName, theAllSalesTab._SummarySettings);
            drillHelper.Initialize().done(function () {
              drillHelper.DisplayDrilldown();
            });

            //NEEDTODO  -Work in Bill's changes that went into 2019 R1 for default drill down type
            //theAllSalesTab.defaultDrillDownSummary = theAllSalesTab.TranslateDrillDownSummaryPreference(TDF.Preferences.GetPreference("DefaultDrillDownSummaryType", "BSISummary"))
            //if (theAllSalesTab.defaultDrillDownSummary != undefined) {
            //  theAllSalesTab.MyBICenter.DrillHelper.DisplayDrilldown(undefined, theAllSalesTab.defaultDrillDownSummary);
            //}
            //else {
            //  theAllSalesTab.MyBICenter.DrillHelper.DisplayDrilldown();
            //}
          }).appendTo(container);
        }
      }
    };
    columns.push(drillDownCol);

    $.each(fields, function (key, val) {
      let col: DevExpress.ui.dxDataGridColumn | any = {
        caption: val.Caption,
        dataField: val.Key,
        visible: true,
        allowEditing: false,
        //fixed: key === 0,
        format: Grid.GetColumnFormatFromUDFFormat(val.Format),
        cellTemplate: function (cellElement: JQuery, cellInfo: any) {
          let text: string = "";

          // Margin row should be percentage based.
          if (cellInfo.rowType == "data" && cellInfo.data.Item == "Margin" && cellInfo.column.format.type) {
            if (cellInfo.value) {
              text = Globalize.formatNumber(cellInfo.value, {
                style: "percent",
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
              });
            } else {
              text = "";
            }
          } else {
            text = cellInfo.text;
          }

          if ((cellInfo.data.Item == "Sales" || cellInfo.data.Item == "Profit") &&
            (cellInfo.column.caption === "2 Years Prior" ||
              cellInfo.column.caption === "Last Year" ||
              cellInfo.column.caption === "LYTD" ||
              cellInfo.column.caption === "YTD" ||
              cellInfo.column.caption === "2 Years QTD" ||
              cellInfo.column.caption === "Last Quarter" ||
              cellInfo.column.caption === "Last Year QTD" ||
              cellInfo.column.caption === "Quarter to Date" ||
              cellInfo.column.caption === "2 Years MTD" ||
              cellInfo.column.caption === "Last Month" ||
              cellInfo.column.caption === "Last Year MTD" ||
              cellInfo.column.caption === "Month to Date" ||
              cellInfo.column.caption === "Value")
          ) {
            //cellElement.append("<div>" + text + "</div>");
            $("<a title'Drill Into' href='javascript:void (0)'>" + text + "</a>").on("click", function () {
              let drillHelper = new DrillHelper(theAllSalesTab._CenterType, theAllSalesTab.EventLimiterName, theAllSalesTab._SummarySettings);
              drillHelper.Initialize().done(function () {
                drillHelper.DisplayDrillIn(
                  TranslateFieldName(
                    cellInfo.data.Item,
                    cellInfo.column.caption
                  ),
                  cellInfo.value
                );
              });
            }).appendTo(cellElement);
          } else {
            cellElement.append("<div>" + text + "</div>");
          }
        }
      };

      if (section.toLowerCase().startsWith("daily")) {
        col.width = 200;
      }

      columns.push(col);
    });
    return columns;
  }
}
