import { ItemBase } from "./itembase";
import { itemTypes } from "../../enums/enums";
import { DisplayInfo } from "../../infrastructure/context";

export class Asset extends ItemBase {
  DisplayName: string;
  get TypeName() {
    return "Asset";
  }
  set TypeName(name) {
    if (name && this.TypeName !== name) this.TypeName = name;
  }
  get ItemType() {
    return itemTypes.itemAsset;
  }
  get RequiresParentItem(): boolean {
    return true;
  }
  constructor(args) {
    super(args);
    let theAsset = this;
    $.each(args, function(key, val) {
      if (theAsset.hasOwnProperty(key)) theAsset[key] = val;
    });

    if (!theAsset.DisplayName) {
      DisplayInfo(theAsset.ItemType).done(function(displayinfo) {
        theAsset.DisplayName = displayinfo.DisplayName;
        theAsset.TypeName = displayinfo.TypeName;
      });
    }
  }
}
