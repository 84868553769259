import { itemTypes } from "../../enums/enums";
import { ItemBase } from "./itembase";
import { DisplayInfo } from "../../infrastructure/context";
import { Dialog } from "components/dialogs/dialog";
import { TDFRequest } from "services/request";
import { DomSafeID, GimmeGUID } from "util/allutils";
import { EventDetails } from "interfaces/interfaces";
import { EventDetailControlTypes } from "./helpers/eventdetailcontroltypes";
import { Notification } from "../dialogs/notification";
import { SessionCenter } from "datacenters/eventdatacenters/sessioncenter";
import { InvitationListCenter } from "datacenters/eventdatacenters/invitationlistcenter";
import { EventSummary } from "components/event/eventsummary";
import { SearchRecFav } from "../controls/searchrecfav";

export class Event extends ItemBase {
    DisplayName: string;
    EventDetails: EventDetails;
    EnrollmentTypes: any;
    EventTypes: any;
    Organizers: any;
    DetailSectionID: string;
    DetailsForm: DevExpress.ui.dxForm;
    DetailsDirty: boolean = false;
    get TypeName() { return "Event"; }
    set TypeName(name) { if (name && this.TypeName !== name) this.TypeName = name; }
    get ItemType() { return itemTypes.itemEvent; }
    get RequiresParentItem(): boolean { return false; }
    constructor(args) {
        super(args);
        let theEvent = this;
        $.each(args, function (key, val) {
            if (theEvent.hasOwnProperty(key)) theEvent[key] = val;
        });

        if (!theEvent.DisplayName) {

            DisplayInfo(theEvent.ItemType).done(function (displayinfo) {
                theEvent.DisplayName = displayinfo.DisplayName;
                theEvent.TypeName = displayinfo.TypeName;
            })
        }

    }

    ShowInvitationWizard() {

        let that = this;

        let srf: SearchRecFav = new SearchRecFav(itemTypes.itemContact,
            [itemTypes.itemContact, itemTypes.itemLead],
            itemTypes.itemUnknown,
            true);

        srf.GetSRFDialog().done((selected) => {
            try {

                srf.Dialog.close();

                let people = (selected as any[]).map((v, i) => { return v["TDF GUID"] });

                /* Later: allow the user to set the Status, Payment, Sessions to enroll, etc
                 Show dialog asking: 
                 1) Payment Status 
                 2) Attendee Status (with dropdown of possible values)
                 3) List of sessions for the event, with ability to select which sessions 
                    that these people should be enrolled in.
                */

                let data: {
                    Items: string[],
                    Notes: string,
                    AttendeeStatus: number,
                    PaymentReceived: boolean
                } = {
                    AttendeeStatus: -1,
                    PaymentReceived: false,
                    Notes: "",
                    Items: people
                };

                let url = "/item/event/" + that.ItemID + "/invitees";

                let req = new TDFRequest({ url: url, type: "POST", data: data });

                req.MakeRequest().done((response) => {
                    new Notification({
                        message: "Success.",
                        type: "success",
                        displayTime: 1500,
                        shading: false,
                        position: { my: "bottom" }
                    });
                });

            } catch (ex) {
                console.warn(ex);
            }
        });
        
    }

    OpenInvitationList() {
        let theEvent = this;

        let bodyContent = $('<div />').attr('id', 'InvitationList').height('100%');

        let dialog = new Dialog({
            title: 'Invitation List',
            closable: true,
            body: bodyContent
        }, null, true, false);

        dialog.open().done(() => {
            let center = new InvitationListCenter(
                theEvent.ItemID,
                'Invitations',
                { MainAreaContainer: $('#InvitationList') }
            );

            center.init();
        });
    }

    OpenSessionManager() {
        let theEvent = this;

        let bodyContent = $('<div />').attr('id', 'SessionManager').height('100%');

        let dialog = new Dialog({
            title: 'Manage Sessions',
            closable: true,
            body: bodyContent
        }, null, true, false);

        dialog.open().done(() => {
            let center = new SessionCenter(
                theEvent.ItemID,
                'Sessions',
                { MainAreaContainer: $('#SessionManager') }
            );

            center.init();
        });
    }

    OpenCalendar() {
        let theEvent = this;

        let bodyContent = $('<div />').height('100%');

        // let dialog = new Dialog({
        //     title: ''
        // })
    }

    OpenEventSummary() {
        let theEvent = this;

        let summary = new EventSummary(theEvent.ItemID, theEvent.EventDetails.Name);

        summary.Init();
    }

    RenderControlGroups() {
        let theEvent = this;
        let dfd = $.Deferred();
        theEvent.DetailSectionID =
            theEvent.TypeName + "-Event_Detail-" + theEvent.DomSafeID;

        super.RenderControlGroups();

        let headerSection = $(`#${theEvent.TypeName}-Event_Specifications-${theEvent.DomSafeID}`);
        $("<div />")
            .attr({ id: theEvent.DetailSectionID })
            .addClass("tdfItemStaticSection")
            .appendTo(headerSection);

        theEvent.GetEventDetails();
        return dfd.promise();
    }

    GetEventDetails() {
        let theEvent = this;
        let request = new TDFRequest({
            url: `Item/Event/${theEvent.ItemID}/Details`,
            type: "GET"
        });
        request.MakeRequest().done(function (response) {
            theEvent.EventDetails = response.Details;
            theEvent.EnrollmentTypes = response.EnrollmentTypes;
            theEvent.EventTypes = response.EventTypes;
            theEvent.Organizers = response.Organizers;
            theEvent.RenderDetailsSection();
        });
    }

    RenderDetailsSection() {
        let theEvent = this;

        let container = $(
            "#" + theEvent.TypeName + "-Event_Detail-" + DomSafeID(theEvent.ItemID)
        );

        let opts: DevExpress.ui.dxFormOptions = {
            formData: theEvent.EventDetails,
            items: [],
            labelLocation: 'top',

            colCountByScreen: {
                lg: 3,
                md: 3,
                sm: 1,
                xs: 1
            }
        };
        let item: DevExpress.ui.dxFormSimpleItem;

        $.each(theEvent.EventDetails, function (key: string, val: any) {
            item = EventDetailControlTypes[key];
            item.dataField = key;

            item.editorOptions = {
                onValueChanged: (e) => {
                    e.field = key;
                    theEvent.SetDirty(e);
                }
            }

            switch (key) {
                case 'Organizer':
                    (<DevExpress.ui.dxSelectBoxOptions>item.editorOptions).items = theEvent.Organizers;
                    (<DevExpress.ui.dxSelectBoxOptions>item.editorOptions).displayExpr = 'Name';
                    (<DevExpress.ui.dxSelectBoxOptions>item.editorOptions).valueExpr = 'ID';
                    break;

                case 'Type':
                    (<DevExpress.ui.dxSelectBoxOptions>item.editorOptions).items = theEvent.EventTypes;
                    (<DevExpress.ui.dxSelectBoxOptions>item.editorOptions).displayExpr = 'Name';
                    (<DevExpress.ui.dxSelectBoxOptions>item.editorOptions).valueExpr = 'ID';
                    break;

                case 'EnrollmentType':
                    (<DevExpress.ui.dxSelectBoxOptions>item.editorOptions).items = theEvent.EnrollmentTypes;
                    (<DevExpress.ui.dxSelectBoxOptions>item.editorOptions).displayExpr = 'Name';
                    (<DevExpress.ui.dxSelectBoxOptions>item.editorOptions).valueExpr = 'ID';
                    break;
            }



            opts.items.push(item);
        });
        let div = $("<div />").appendTo(container);
        theEvent.DetailsForm = div.dxForm(opts).dxForm("instance");
        //container.append(form.element())
    }

    SetDirty(control) {
        let theEvent = this;

        if (control.hasOwnProperty("DataValue")) {
            super.SetDirty(control);
        } else {
            theEvent.DetailsDirty = true;
            theEvent.Dirty = true;
            theEvent.ChangeState();
        }
        return theEvent;
    }

    Save() {
        let theEvent = this;
        let d = $.Deferred();
        if (theEvent.DirtyControls && theEvent.DirtyControls.length) {
            super.Save().done(function (response) {
                d.resolve(theEvent);
            });
        }
        if (theEvent.DetailsDirty) {
            theEvent.SaveDetails();
        }

        if (!theEvent.Dirty && !theEvent.DetailsDirty) {
            new Notification({
                message: "No values to save.",
                type: "warning",
                displayTime: 1500,
                shading: false,
                position: { my: "bottom" }
            });
        }
        return d.promise(theEvent);
    }

    SaveDetails() {
        let theEvent = this;

        new TDFRequest({
            url: `Item/Event/${theEvent.ItemID}/Details`,
            type: 'POST',
            data: theEvent.DetailsForm.option('formData')
        }).MakeRequest().done(() => {
            new Notification({
                message: "Success ",
                type: "success",
                displayTime: 1500,
                shading: false,
                position: { my: "top" }
            });
            theEvent.DetailsDirty = false;
            theEvent.Dirty = false;
            theEvent.ChangeState();
        });

        return theEvent;

    }

}
