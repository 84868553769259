import { CenterType, itemTypes, enumscope, GridContainerTypes } from '../../enums/enums';
import { IGridView } from '../../interfaces/grids/interfaces';
import {
  IGridViewDefinition,
  IGridDataResponseMeta,
  IGridDataResponse
} from '../../interfaces/interfaces';
import * as moment from 'moment';
import * as Globalize from 'globalize';
import { GridSetup } from './helpers/gridsetup';
import { TDFRequest } from '../request';
import { GridViewService } from './gridviewservice';
import { ISummary } from '../../enums/bi/enums';
import dxDataSource from 'devextreme/data/data_source';
import { GetPreference } from '../../infrastructure/user/getpreference';
import dxCustomStore from 'devextreme/data/custom_store';
import { Preferences } from '../../infrastructure/user/preferences';
import { GridDataService } from './griddataservice';
import { DataScopeSelection } from 'components/dashboards/dynamicdatascopeselector';

export class BIGridDataService extends GridDataService {
  constructor(center: CenterType, view: IGridView) {
    super(center, view);
  }
  //    dataService.setDxColumns()
  //}
  protected RequestType: string = 'POST';
  //    if (typeof dataService.GridViewMeta.CanExport !== "undefined") {
  //        dataService.GridInstance.option("export", {
  //            enabled: dataService.GridViewMeta.CanExport,
  //            allowExportSelectedData: true,
  //        });
  //    }
  //    if (typeof dataService.GridViewMeta.RecordsOnPage !== "undefined") {
  //        dataService.GridInstance.option("pager", { infoText: dataService.PagingInfo })
  //    }
  //}

  //private _RequestModel: GridSetup.BIRequestModel;
  get RequestModel() {
    let dataService = this;
    if (dataService._RequestModel) return dataService._RequestModel;

    dataService.RequestModel = GridSetup.GridRequestFactory(dataService.CenterType, dataService.RequestArgs) as GridSetup.BIRequestModel;
    return dataService._RequestModel;
  }

  set RequestModel(model) {
    let dataService = this;
    if (!model.RequestArgs.GUID)
      model.RequestArgs.GUID = dataService.GridView.GUID;

    if (!model.RequestArgs.ItemType)
      model.RequestArgs.ItemType = dataService.GridView.ItemType;

    dataService._RequestModel = model;
  }

  //private _RequestArgs: GridSetup.BIRequestArgs;
  get RequestArgs(): GridSetup.BIRequestArgs {
    let dataService = this;
    if (dataService._RequestArgs)
      return dataService._RequestArgs as GridSetup.BIRequestArgs;

    dataService.RequestArgs = GridSetup.GridRequestArgsFactory(dataService.CenterType, dataService.GridView) as GridSetup.BIRequestArgs;

    return dataService._RequestArgs as GridSetup.BIRequestArgs;
  }
  set RequestArgs(args: GridSetup.BIRequestArgs) {
    let dataService = this;
    dataService._RequestArgs = args;
  }
}

export class GridViewServiceBI extends GridViewService {
  public ViewsForMenuRequest(
    itemType: itemTypes,
    containerType: GridContainerTypes,
    scope: enumscope,
    summarySettings?: DataScopeSelection
  ): TDFRequest {
    return new TDFRequest({
      url: `${
        this._route
        }/${itemType}/${containerType}/for-menu?scope=${scope}${
        summarySettings === undefined
          ? ''
          : `&biLevel=${
          summarySettings.ActiveSelectionLevel
          }&isSalesRep=${
          summarySettings.IsSalesmanLevel
          }`}`,
      type: 'GET'
    });
  }

  public LayoutForViewRequest(options: {
    viewid: string;
    isdrillin: boolean;
    summaryType: ISummary.SummaryInterfaces.cBSID.EnumType;
  }): TDFRequest {
    return new TDFRequest({
      url: `${this._route}/${options.viewid}/${options.summaryType}/${
        options.isdrillin
        }/BI/layout`,
      type: 'GET'
    });
  }
}
