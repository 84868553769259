
            export enum AccountEnum {
                Address = 0,
                BSID = 1,
                BusinessFax = 2,
                BusinessPhone = 3,
                CurrentUser = 4,
                DirectPhone = 5,
                EmailDomain = 6,
                EventSource = 7,
                LastActivityDate = 8,
                LastActivitySubject = 9,
                LastCallDate = 10,
                LastCaller = 11,
                LastCallType = 12,
                LastModifiedBy = 13,
                NextActionDate = 14,
                NextActionStep = 15,
                ParentContacts = 16,
                Subject = 17,
                UniqueId = 18,
                WebAddress = 19
            }

            export enum ActivityEnum {
                ActivityDate = 0,
                ActivityDuration = 1,
                ActivityMileage = 2,
                ActivityType = 3,
                CurrentUser = 4,
                LastModifiedBy = 5,
                Notes = 6,
                ParentContacts = 7,
                ParentUniqueId = 8,
                Subject = 9,
                UniqueId = 10
            }

            export enum AppointmentEnum {
                Attendees = 0,
                CurrentUser = 1,
                EndTime = 2,
                LastModifiedBy = 3,
                Location = 4,
                Notes = 5,
                ParentContacts = 6,
                ParentUniqueId = 7,
                StartTime = 8,
                Subject = 9,
                UniqueId = 10
            }

            export enum AssetEnum {
                CurrentUser = 0,
                LastModifiedBy = 1,
                Notes = 2,
                ParentContacts = 3,
                ParentUniqueId = 4,
                Subject = 5,
                UniqueId = 6
            }

            export enum ContactEnum {
                Address = 0,
                Anniversary = 1,
                AssistantName = 2,
                Birthday = 3,
                BSContactID = 4,
                BusinessFax = 5,
                BusinessPhone = 6,
                Categories = 7,
                CompanyName = 8,
                ContactRole = 9,
                CurrentUser = 10,
                Department = 11,
                DirectPhone = 12,
                Email = 13,
                Email2 = 14,
                Email3 = 15,
                EventSource = 16,
                FileAs = 17,
                FirstName = 18,
                HomePhone = 19,
                JobTitle = 20,
                LastActivityDate = 21,
                LastActivitySubject = 22,
                LastModifiedBy = 23,
                LastName = 24,
                ManagerName = 25,
                MiddleName = 26,
                MobilePhone = 27,
                NextActionDate = 28,
                NextActionStep = 29,
                NickName = 30,
                Office = 31,
                OptOut = 32,
                Profession = 33,
                SpouseName = 34,
                Subject = 35,
                Suffix = 36,
                Title = 37,
                UniqueId = 38,
                WebAddress = 39
            }

            export enum ConTRactEnum {
                CurrentUser = 0,
                EventSource = 1,
                LastActivityDate = 2,
                LastActivitySubject = 3,
                LastModifiedBy = 4,
                NextActionDate = 5,
                NextActionStep = 6,
                Subject = 7,
                UniqueId = 8
            }

            export enum DocumentEnum {
                Category = 0,
                CurrentUser = 1,
                LastModifiedBy = 2,
                Notes = 3,
                ParentContacts = 4,
                ParentUniqueId = 5,
                Subject = 6,
                UniqueId = 7
            }

            export enum EmailEnum {
                CurrentUser = 0,
                LastModifiedBy = 1,
                Subject = 2,
                UniqueId = 3
            }

            export enum EventEnum {
                CurrentUser = 0,
                EventSource = 1,
                LastActivityDate = 2,
                LastActivitySubject = 3,
                LastModifiedBy = 4,
                NextActionDate = 5,
                NextActionStep = 6,
                UniqueId = 7
            }

            export enum ExpenseEnum {
                ParentContacts = 0,
                ParentUniqueId = 1,
                UniqueId = 2
            }

            export enum ForecastEnum {
                EventSource = 0,
                ForecastDate = 1,
                ForecastProbability = 2,
                ForecastProbableValue = 3,
                ForecastValue = 4,
                ParentContacts = 5,
                Subject = 6,
                UniqueId = 7
            }

            export enum KnowledgeBaseEnum {
                CurrentUser = 0,
                LastModifiedBy = 1,
                Notes = 2,
                ParentContacts = 3,
                ParentUniqueId = 4,
                Subject = 5,
                UniqueId = 6
            }

            export enum LiteratureEnum {
                CurrentUser = 0,
                EventSource = 1,
                LastActivityDate = 2,
                LastActivitySubject = 3,
                LastModifiedBy = 4,
                NextActionDate = 5,
                NextActionStep = 6,
                Subject = 7,
                UniqueId = 8
            }

            export enum NoteEnum {
                CurrentUser = 0,
                LastModifiedBy = 1,
                Notes = 2,
                ParentContacts = 3,
                ParentUniqueId = 4,
                Subject = 5,
                UniqueId = 6
            }

            export enum OpportunityEnum {
                CalculationMethod = 0,
                CloseProbability = 1,
                CurrentUser = 2,
                EventSource = 3,
                ForecastValue = 4,
                LastActivityDate = 5,
                LastActivitySubject = 6,
                LastModifiedBy = 7,
                NextActionDate = 8,
                NextActionStep = 9,
                Opportunity = 10,
                ProbableValue = 11,
                ProjectedClose = 12,
                SalesStage = 13,
                SalesStageDate = 14,
                Subject = 15,
                TotalExpenses = 16,
                TotalSalesTime = 17,
                UniqueId = 18
            }

            export enum QuoteEnum {
                CurrentUser = 0,
                LastModifiedBy = 1,
                ParentContacts = 2,
                ParentUniqueId = 3,
                UniqueId = 4
            }

            export enum SupportEnum {
                CurrentUser = 0,
                EventSource = 1,
                LastActivityDate = 2,
                LastActivitySubject = 3,
                LastModifiedBy = 4,
                NextActionDate = 5,
                NextActionStep = 6,
                Status = 7,
                Subject = 8,
                SupportPriority = 9,
                UniqueId = 10
            }

            export enum TicketEnum {
                CurrentUser = 0,
                LastModifiedBy = 1,
                Notes = 2,
                ParentContacts = 3,
                ParentUniqueId = 4,
                Subject = 5,
                UniqueId = 6
            }