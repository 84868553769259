import {
  BasicCrumbInfo,
  BI_BreadcrumbInfo,
  BI_DrilldownItem
} from "../../components/grids/bi/legacy/legacybi";
import {
  BSIGoalType,
  BSIGrids,
  BSISummarySource,
  ChildGridTypes,
  ISummary
} from "../../enums/bi/enums";
import { BISummarySettingsSelector } from "../../infrastructure/user/bisummarysettingsselector";
import { TDFRequest } from "../../services/request";
import { DrilldownDialog } from "./drilldowndialog";
import { DrillInDialog_LineItems } from "./drillindialog_lineitems";
import { GetDevice } from "../../util/allutils";
import dxArrayStore from "devextreme/data/array_store";
import { DataScopeSelection } from "../../components/dashboards/dynamicdatascopeselector";
import { CenterType } from "../../enums/enums";
import {
  GetBIPrefModuleFromBISummarySource,
  GetBISummarySourceFromCenterType,
  GetCrumbInfoFromGroupSummaryRow
} from "./bi_utils";
import { eventNameSpace, EventTypes, } from "../../enums/webevents/enums";
import { AddHandler2 } from "../../infrastructure/events/ui_events";

export class DrillHelper {
  CenterType: CenterType;
  SummarySettings: DataScopeSelection;
  private EventLimiterName: string = "";

  IsInitialized: boolean = false;
  InitializationPromise: JQueryPromise<DrillHelper>;
  IsDrilldown: boolean = false;

  DrillInfo: BI_DrilldownItem[];
  CurrentDrilldownInfo: BI_DrilldownItem;
  BreadcrumbHistory: BI_BreadcrumbInfo[] = [];
  AccountList: string = "";
  GoalType: BSIGoalType = -1;
  BIGridType: BSIGrids;
  // uRLs
  BIDrillModelURL: string = "/BI/DrillinMenuItems/";

  constructor(
    centerType: CenterType,
    eventLimiterName: string,
    summarySettings: DataScopeSelection,
    accountList?: string
  ) {
    let theDrillHelper: DrillHelper = this;

    theDrillHelper.CenterType = centerType;
    theDrillHelper.SummarySettings = summarySettings;
    theDrillHelper.GoalType = summarySettings.GetGoalSelection(
      GetBIPrefModuleFromBISummarySource(
        GetBISummarySourceFromCenterType(centerType)
      )
    ); //        "BSISummary"); // goalType;
    theDrillHelper.EventLimiterName = eventLimiterName;
    if (accountList) {
      theDrillHelper.AccountList = accountList;
    }

    AddHandler2(
      EventTypes.BIEventTypes.currentDrillHelper,
      theDrillHelper.EventLimiterName,
      eventNameSpace.request,
      $("#tdfbody"),
      theDrillHelper.OnDrillHelperRequested.bind(theDrillHelper)
    );
  }
  OnDrillHelperRequested(e: JQueryEventObject, data) {
    let theDrillHelper = this;

    data.deferred.resolve(theDrillHelper);
  }
  Initialize(): JQueryPromise<DrillHelper> {
    let theDrillHelper: DrillHelper = this;
    let displayDrillinDFD: JQueryDeferred<DrillHelper> = $.Deferred();

    if (theDrillHelper.IsInitialized) {
      return displayDrillinDFD.promise(
        displayDrillinDFD.resolve(theDrillHelper)
      );
    }

    if (theDrillHelper.InitializationPromise != undefined) {
      return theDrillHelper.InitializationPromise;
    }
    theDrillHelper.DrillModel().done(function () {
      theDrillHelper
        .GetInitialBreadcrumbInfo()
        .done(function (initialCrumb: BI_BreadcrumbInfo) {
          theDrillHelper.BreadcrumbHistory = [];
          theDrillHelper.AddCrumb(initialCrumb);
          theDrillHelper.IsInitialized = true;
          return displayDrillinDFD.resolve(theDrillHelper);
        });
    });

    theDrillHelper.InitializationPromise = displayDrillinDFD.promise();
    return theDrillHelper.InitializationPromise;
  }

  Reinitialize(): JQueryPromise<DrillHelper> {
    let theDrillHelper: DrillHelper = this;
    theDrillHelper.IsInitialized = false;
    theDrillHelper.InitializationPromise = undefined;
    return theDrillHelper.Initialize();
  }

  GetInitialBreadcrumbInfo(): JQueryPromise<BI_BreadcrumbInfo> {
    let theDrillHelper: DrillHelper = this;
    let breadcrumbDFD: JQueryDeferred<BI_BreadcrumbInfo> = $.Deferred();

    let levelColumn: string = "";
    let ids: string = theDrillHelper.SummarySettings.CurrentlySelectedItemIDsAsString();
    let caption: string = "";
    let childGridType: number;

    switch (theDrillHelper.SummarySettings.ActiveSelectionLevel) {
      case ISummary.SummaryInterfaces.cBSID.EnumType.AcctPkg:
        levelColumn = "AcctPkgID";
        childGridType = 8;
        break;
      case ISummary.SummaryInterfaces.cBSID.EnumType.AcctID:
        switch (GetBISummarySourceFromCenterType(theDrillHelper.CenterType)) {
          case BSISummarySource.Contact:
            levelColumn = "BIContactID";
            break;
          case BSISummarySource.Opportunity:
            levelColumn = "R.OpportunityID";
            break;
          default:
            levelColumn = "AccountID";
        }
        childGridType = 4;
        break;
      case ISummary.SummaryInterfaces.cBSID.EnumType.AcctRoot:
        levelColumn = "AcctRootID";
        childGridType = 8;
        break;
      case ISummary.SummaryInterfaces.cBSID.EnumType.SalesRep:
        levelColumn = "SalesRepID";
        childGridType = 7;
        break;
      case ISummary.SummaryInterfaces.cBSID.EnumType.BillTo:
        levelColumn = "CompIDCustID";
        childGridType = 8;
        break;
      case ISummary.SummaryInterfaces.cBSID.EnumType.Master:
        levelColumn = "AV.BSIMasterAcctID";
        childGridType = 8;
        break;
      case ISummary.SummaryInterfaces.cBSID.EnumType.Custom:
        levelColumn = "CustomID";
        childGridType = 4;
        break;
      case ISummary.SummaryInterfaces.cBSID.EnumType.Level3:
        levelColumn = "Level3ID";
        childGridType = 8;
        break;
      case ISummary.SummaryInterfaces.cBSID.EnumType.Level4:
        levelColumn = "Level4ID";
        childGridType = 8;
        break;
      case ISummary.SummaryInterfaces.cBSID.EnumType.InvalidType:
      default:
      //alert("Active Selection Level is not yet handled");
    }

    caption = theDrillHelper.SummarySettings.CurrentlySelectedItemIDsAsString();
    if (ids.indexOf(",") < 0) {
      caption = theDrillHelper.SummarySettings.CurrentlySelectedItemsAsArray()[0]
        .Name;
    } else {
      caption =
        ISummary.SummaryInterfaces.cBSID.EnumType[
        theDrillHelper.SummarySettings.ActiveSelectionLevel
        ];
    }

    let drilldownItem: BI_DrilldownItem = new BI_DrilldownItem();
    drilldownItem.Caption =
      ISummary.SummaryInterfaces.cBSID.EnumType[
      theDrillHelper.SummarySettings.ActiveSelectionLevel
      ];
    drilldownItem.LevelColumn = levelColumn;
    //drilldownItem.GridItemType = BSIGrids        // TODO:
    drilldownItem.ChildGridType = childGridType;
    drilldownItem.DisallowedChildGridTypeChildren = [];
    drilldownItem.BILevelsUsed = [
      theDrillHelper.SummarySettings.ActiveSelectionLevel
    ];
    drilldownItem.Used = true;
    drilldownItem.Hidden = false;

    let theCrumb: BI_BreadcrumbInfo = new BI_BreadcrumbInfo(
      ids,
      caption,
      drilldownItem
    );
    return breadcrumbDFD.promise(breadcrumbDFD.resolve(theCrumb));
  }

  DisplayDrilldown(
    crumbStarter?: BasicCrumbInfo,
    nextItem?: BI_DrilldownItem
  ): JQueryPromise<DrillHelper> {
    let theDrillHelper: DrillHelper = this;
    theDrillHelper.IsDrilldown = true;

    let displayDrillinDFD: JQueryDeferred<DrillHelper> = $.Deferred();
    theDrillHelper.Initialize().done(function () {
      if (crumbStarter) {
        let drilldownItems = theDrillHelper.DrillInfo.filter(
          value => value.ChildGridType === crumbStarter.ChildGridType
        );

        if (drilldownItems && drilldownItems.length > 0) {
          let starterCrumb = new BI_BreadcrumbInfo(
            crumbStarter.Value,
            crumbStarter.Description,
            drilldownItems[0]
          );
          starterCrumb.DrilldownItem.Used = true;
          theDrillHelper.AddCrumb(starterCrumb);
        }
      }

      let myDrillDown = new DrilldownDialog(
        theDrillHelper,
        theDrillHelper.EventLimiterName
      );
      myDrillDown.DisplayDrilldown(nextItem).done(function (response) {
        return displayDrillinDFD.resolve(theDrillHelper);
      });
    });

    return displayDrillinDFD.promise(theDrillHelper);
  }

  DisplayDrillIn(
    fieldName: string,
    drillinValue: string,
    crumbValue?: string,
    crumbDescription?: string,
    crumbChildGridType?: ChildGridTypes,
    crumbIsFilter?: boolean
  ): JQueryPromise<DrillHelper> {
    let theDrillHelper: DrillHelper = this;
    let displayDrillinDFD: JQueryDeferred<DrillHelper> = $.Deferred();

    let newCrumb: BI_BreadcrumbInfo;
    if (!crumbIsFilter) {
      if (crumbValue && crumbDescription) {
        if (crumbChildGridType === undefined) {
          crumbChildGridType =
            theDrillHelper.CurrentDrilldownInfo.ChildGridType;
        }

        let drillInfo: BI_DrilldownItem[] = theDrillHelper.DrillInfo.filter(
          function (
            value: BI_DrilldownItem,
            index: number,
            array: BI_DrilldownItem[]
          ) {
            return value.ChildGridType === crumbChildGridType;
          }
        );
        if (drillInfo && drillInfo.length > 0) {
          newCrumb = new BI_BreadcrumbInfo(
            crumbValue,
            crumbDescription,
            drillInfo[0]
          );
        }
      }
    } else {
      newCrumb = new BI_BreadcrumbInfo(
        crumbValue,
        crumbDescription,
        null,
        crumbIsFilter
      );
    }

    let myDrillIn = new DrillInDialog_LineItems(
      theDrillHelper,
      theDrillHelper.EventLimiterName
    );
    myDrillIn
      .DisplayDrillin(fieldName, drillinValue, newCrumb)
      .done(function (response) {
        return displayDrillinDFD.resolve(theDrillHelper);
      });

    return displayDrillinDFD.promise(theDrillHelper);
  }

  AddCrumb(breadcrumbInfo: BI_BreadcrumbInfo) {
    let theDrillHelper: DrillHelper = this;
    theDrillHelper.BreadcrumbHistory.push(breadcrumbInfo);
    //TODO:  SHould this do the filtering and hiding of disallowed child grid items?
  }

  TruncateDrilldownBreadcrumbs(index: number): number {
    let theDrillHelper: DrillHelper = this;

    let getType: boolean = true;
    let lastSelectedChildGridType: number;

    $.each(theDrillHelper.BreadcrumbHistory, function (
      crumbIndex: number,
      crumb: BI_BreadcrumbInfo
    ) {
      if (crumbIndex > index) {
        // Set used back to false.
        crumb.DrilldownItem.Used = false;

        // Unhide drilldown items that were jumped over if they can be.
        if (crumb.DrilldownItem.DisallowedChildGridTypeChildren) {
          $.each(crumb.DrilldownItem.DisallowedChildGridTypeChildren, function (
            disallowedIndex: number,
            dissallowedItem: ChildGridTypes
          ) {
            let hidden: boolean = false;

            // 1.  Find the drilldownInfo item for the disallowedChildGridType.
            let matchingDrilldownItems: BI_DrilldownItem[] = theDrillHelper.DrillInfo.filter(
              function (
                value: BI_DrilldownItem,
                index: number,
                array: BI_DrilldownItem[]
              ) {
                return value.ChildGridType === dissallowedItem;
              }
            );

            // 2.  Loop through breadcrumbs remaining to see if it's hidden by any of those.
            $.each(theDrillHelper.BreadcrumbHistory, function (
              preCrumbIndex: number,
              preCrumb: BI_BreadcrumbInfo
            ) {
              if (preCrumbIndex <= crumbIndex) {
                if (
                  preCrumb.DrilldownItem.DisallowedChildGridTypeChildren.indexOf(
                    crumb.DrilldownItem.ChildGridType
                  ) >= 0
                ) {
                  hidden = true;
                }
              }
            });

            // 3.  Update the hidden property.
            $.each(matchingDrilldownItems, function (
              index,
              drilldownItem: BI_DrilldownItem
            ) {
              drilldownItem.Hidden = hidden;
            });
          });
        }

        if (getType) {
          // getType will be true the first item we hit greater than the index we are truncating to.
          // So we'll get it's child type, and then use that after truncating the breadcrumbs to set the picker back to the last used value.
          lastSelectedChildGridType = crumb.DrilldownItem.ChildGridType;
          getType = false;
        }
      }
    });

    theDrillHelper.BreadcrumbHistory = theDrillHelper.BreadcrumbHistory.slice(
      0,
      index + 1
    );
    return lastSelectedChildGridType;
  }

  DoesItemTypeAllowDrilldown(itemType: number): boolean {
    let retVal: boolean = false;
    if (
      itemType === BSIGrids.Acct ||
      itemType === BSIGrids.AcctPkg ||
      itemType === BSIGrids.AcctRoot ||
      itemType === BSIGrids.Alt1 ||
      itemType === BSIGrids.Alt2 ||
      itemType === BSIGrids.Alt3 ||
      itemType === BSIGrids.County ||
      itemType === BSIGrids.CustomerID ||
      itemType === BSIGrids.MasterID ||
      itemType === BSIGrids.ProductGroup ||
      itemType === BSIGrids.Salesperson ||
      itemType === BSIGrids.ShipTo ||
      itemType === BSIGrids.Vendor ||
      itemType === BSIGrids.GapAnalysis ||
      // Contact BI
      itemType === BSIGrids.BSIContactAcctGroupSummary ||
      itemType === BSIGrids.BSIContactAcctPkgGroupSummary ||
      itemType === BSIGrids.BSIContactAlt1GroupSummary ||
      itemType === BSIGrids.BSIContactAlt2GroupSummary ||
      itemType === BSIGrids.BSIContactAlt3GroupSummary ||
      itemType === BSIGrids.BSIContactProductGroupGroupSummary ||
      itemType === BSIGrids.BSIContactVendorGroupSummary ||
      // Opportunity BI
      itemType === BSIGrids.BSIJobProductGroupGroupSummary ||
      itemType === BSIGrids.BSIJobAlt1GroupSummary ||
      itemType === BSIGrids.BSIJobAlt2GroupSummary ||
      itemType === BSIGrids.BSIJobAlt3GroupSummary ||
      itemType === BSIGrids.BSIJobAcctPkgGroupSummary ||
      itemType === BSIGrids.BSIJobAcctGroupSummary ||
      itemType === BSIGrids.BSIJobVendorGroupSummary ||
      // Vendor BI
      itemType === BSIGrids.VendorProductGroupGroupSummary ||
      itemType === BSIGrids.VendorAlt1GroupSummary ||
      itemType === BSIGrids.VendorAlt2GroupSummary ||
      itemType === BSIGrids.VendorAlt3GroupSummary ||
      itemType === BSIGrids.VendorAcctPkgGroupSummary
    ) {
      retVal = true;
    }
    return retVal;
  }

  GetDrilldownFilter() {
    let theDrillHelper: DrillHelper = this;

    return [
      [
        "BILevelsUsed",
        "contains",
        theDrillHelper.SummarySettings.ActiveSelectionLevel
      ],
      "&&",
      ["Used", "=", false],
      "&&",
      ["Hidden", "=", false]
      //NEEDTODO - TEST THIS...NOT WORKING
      //"&&",
      //["GridItemType", "<>", theDrillHelper.TranslateGroupSummaryToGridItemType(theDrillHelper.CurrentDrilldownInfo.GridItemType)]
    ];
  }

  TranslateGroupSummaryToGridItemType(itemType: BSIGrids): BSIGrids {
    let theDrillHelper: DrillHelper = this;
    let translation: number = itemType;

    if (
      itemType == BSIGrids.BSIContactProductGroupGroupSummary ||
      itemType == BSIGrids.BSIJobProductGroupGroupSummary ||
      itemType == BSIGrids.VendorProductGroupGroupSummary
    ) {
      translation = BSIGrids.ProductGroup;
    } else if (
      itemType == BSIGrids.BSIContactVendorGroupSummary ||
      itemType == BSIGrids.BSIJobVendorGroupSummary
    ) {
      translation = BSIGrids.Vendor;
    } else if (
      itemType == BSIGrids.BSIContactAlt1GroupSummary ||
      itemType == BSIGrids.BSIJobAlt1GroupSummary ||
      itemType == BSIGrids.VendorAlt1GroupSummary
    ) {
      translation = BSIGrids.Alt1;
    } else if (
      itemType == BSIGrids.BSIContactAlt2GroupSummary ||
      itemType == BSIGrids.BSIJobAlt2GroupSummary ||
      itemType == BSIGrids.VendorAlt2GroupSummary
    ) {
      translation = BSIGrids.Alt2;
    } else if (
      itemType == BSIGrids.BSIContactAlt3GroupSummary ||
      itemType == BSIGrids.BSIJobAlt3GroupSummary ||
      itemType == BSIGrids.VendorAlt3GroupSummary
    ) {
      translation = BSIGrids.Alt3;
    } else if (
      itemType == BSIGrids.BSIContactAcctGroupSummary ||
      itemType == BSIGrids.BSIJobAcctGroupSummary
    ) {
      translation = BSIGrids.Acct;
    } else if (
      itemType == BSIGrids.BSIContactAcctPkgGroupSummary ||
      itemType == BSIGrids.BSIJobAcctPkgGroupSummary ||
      itemType == BSIGrids.VendorAcctPkgGroupSummary
    ) {
      translation = BSIGrids.AcctPkg;
    }

    return translation;
  }

  CreateDrilldownColumn(
    biGridType: BSIGrids
  ): DevExpress.ui.dxDataGridColumn | any {
    let theDrillHelper: DrillHelper = this;

    let drillDownCol: DevExpress.ui.dxDataGridColumn | any = {
      alignment: "center",
      width: GetDevice().isDevice ? "25px" : "40px",
      visible: true,
      allowEditing: false,
      fixed: true,
      cellTemplate: function (container: any, options: any) {
        let drilldownActionButton = $(
          "<div id='drilldownActionButton" + options.rowIndex + "'>+</div>"
        );
        let popoverItem = $("<div id='popover" + options.rowIndex + "' />");

        drilldownActionButton.appendTo(container);
        popoverItem.appendTo(container);

        drilldownActionButton.on("dxclick", function (e) {
          let childTypeSelection = popoverItem
              .dxActionSheet({
                 dataSource: {
                store: new dxArrayStore({
                  data: theDrillHelper.DrillInfo
                }),
                filter: theDrillHelper.GetDrilldownFilter()
              },
              itemTemplate: function (itemData: any,itemIndex: number,itemElement: any) {
                  return $("<a />")
                      .text(itemData.Caption)
                      .attr("href", "javascript:void(0)")
                      .css("cursor", "zoom-in");
              },
              onItemClick: function (event) {
                let data = options.data;
                let crumb: BasicCrumbInfo = GetCrumbInfoFromGroupSummaryRow(
                  options.data,
                  biGridType,
                  theDrillHelper.EventLimiterName
                );
                let nextChild = event.itemData;
                theDrillHelper.DisplayDrilldown(crumb, nextChild);
              },
              showTitle: false,
              usePopover: !GetDevice().isDevice,
              target: popoverItem,
              visible: false
            })
            .dxActionSheet("instance");

          childTypeSelection.show();
        });
      }
    };

    return drillDownCol;
  }

  DrillModel(): JQueryPromise<any> {
    let theDrillHelper: DrillHelper = this;

    let drillModelDFD: JQueryDeferred<any> = $.Deferred();
    if (theDrillHelper.DrillInfo) {
      return drillModelDFD.promise(drillModelDFD.resolve(theDrillHelper));
    }

    let request = new TDFRequest({
      url: theDrillHelper.BIDrillModelURL,
      type: "GET",
      data: {
        biType: GetBISummarySourceFromCenterType(theDrillHelper.CenterType)
      }
    });

    request.MakeRequest().done(function (response: any) {
      theDrillHelper.DrillInfo = response.DrilldownItems;
      drillModelDFD.resolve(theDrillHelper);
    });

    return drillModelDFD.promise(theDrillHelper);
  }
}
