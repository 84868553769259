import { DataScopeSelectionMode, DataScopeSelectionTypes } from "../../enums/user/datascope/enums";
import { DataScopeSelectionPanel } from "../../infrastructure/user/datascopeselectionpanel";

   export class PackageSelection extends DataScopeSelectionPanel {
       DialogTitle: string = "Select an Account Package";
      readonly ScopeSelectionType: DataScopeSelectionTypes = DataScopeSelectionTypes.CreateWizard;
      AllowDefault: boolean = false;
      SelectionMode: DataScopeSelectionMode = DataScopeSelectionMode.Single;
      OnCommit: any;
      SelectedItems: {};
      ActiveFolderLevel: number;
      ActiveSelectionLevel: any;

      constructor() {
	  super();
	  this.GetInitialSelectedItemList();
      }

      GetInitialSelectedItemList(): JQueryPromise<any[]> {
	 let panel = this;
	 panel.SelectedItems = {};
	 panel.ActiveFolderLevel = 1;
	 panel.ActiveSelectionLevel = panel.ConvertFolderTabToScopeLevel(this.ActiveFolderLevel);

	 let initialList = [];
	 let dfd: JQueryDeferred<any[]>= $.Deferred();
	 return dfd.promise(dfd.resolve(initialList));
      }

      CommitPanelSelections  (setAsDefault) {
	 var panel = this;
	 panel.OnCommit(panel.SelectedItems);
      }

      //OnCommit(callback) {
      //   let panel = this;

      //   callback(panel.SelectedItems)
      //}
   }
