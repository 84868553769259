import { RaiseEvent2 } from "../../../../infrastructure/events/ui_events";
import { ToolbarTemplateFactory } from "./toolbartemplatefactory";
import { eventNameSpace, EventTypes } from "../../../../enums/webevents/enums";

export function RefreshButton(args) {
  let centerName: string = "";

  if (args && args.length) {
    args.map((v, k) => {
      if ($.isPlainObject(v)) {
        Object.keys(v).forEach((val, key) => {
          if (val.toLowerCase() === "uniquecentername") {
            centerName = v[val];
          }
        })
      }
    })
  }
  return ToolbarTemplateFactory(
    "after",
    "auto",
    false,
    <DevExpress.ui.dxButtonOptions>{
      icon: "refresh",
      text: "Refresh Grid",
      hint: "Right click for more options",
      onInitialized(e) {
        $("#grid-refresh-options").remove();
        $("#tdfbody").append($("<div id='grid-refresh-options' />"));
        $("#grid-refresh-options").dxContextMenu(
          <DevExpress.ui.dxContextMenuOptions>{
            target: e.element,
            items: [
              { text: "Clear sorting and refresh", icon: "fa fa-sort" },
              { text: "Clear filters and refresh", icon: "fa fa-filter" },
              { text: "Clear both and refresh", icon: "fa fa-eraser" }
            ],
            hint: "Choose an option",
            onItemClick(ev) {
              let clear = "all";
              switch (ev.itemData.text) {
                case "Clear sort and refresh":
                  clear = "sort";
                  break;
                case "Clear filters and refresh":
                  clear = "filter";
                  break;
              }
              RaiseEvent2(EventTypes.CenterEventTypes.gridrefresh, centerName, eventNameSpace.request, { clear: clear });
            }
          }
        );
      },
      onClick(e) {
        RaiseEvent2(EventTypes.CenterEventTypes.gridrefresh, centerName, eventNameSpace.request, { clear: "" });
      }
    },
    "dxButton"
  );
}
