import { GapAnalysis } from "./gapanalysis";
import { CenterType, itemTypes, GridContainerTypes } from "enums/enums";
import { TDFDataCenters } from "interfaces/datacenters/tdfdatacenters";
import { GridSetup } from "services/grid/helpers/gridsetup";
import { BSIGrids } from "enums/bi/enums";
import { DataCenterTabs } from "datacenters/datacentertabs";
import { RaiseEvent2, AddHandler2 } from "infrastructure/events/ui_events";
import { EventTypes, eventNameSpace } from "enums/webevents/enums";
import { BiDataCenterTabs } from "datacenters/bidatacentertabs";
import { BasicCrumbInfo } from "components/grids/bi/legacy/legacybi";

export class GenericGroupSummary extends GapAnalysis {
    get CenterType(): CenterType {
        return CenterType.GenericGroupSummary;
    }

    protected get GridContainerType() {
        // We are setting this to anything other than BSI_Summary so we
        // can distinguish on the server side when we should get the list
        // of Acct fields and when we should get the normal views for this 
        // item type.
        return GridContainerTypes.Workflow;
    }

    constructor(
        container: JQuery,
        ParentCenterType: CenterType,
        Tab: TDFDataCenters.DataCenterTabs.TabItem,
        EventLimiterName: string
    ) {
        super(
            container,
            ParentCenterType,
            Tab,
            EventLimiterName
        );
    }

    initTabs() {
        let ggsCenter = this;
        if (ggsCenter.Tab) {
            new BiDataCenterTabs(
                ggsCenter.DataTabsAreaContainer,
                ggsCenter.CenterType,
                ggsCenter.DataGridAreaContainer,
                ggsCenter.CustomName,
                ggsCenter.InDefaultContainer,
                ggsCenter.ExtraRequestArgs as GridSetup.BIRequestArgs
            ).Init();

            RaiseEvent2(
                EventTypes.CenterEventTypes.itemtype,
                ggsCenter.CustomName,
                eventNameSpace.modify,
                { ItemType: ggsCenter.Tab.ItemType }
            );
        }
    }

    protected get ExtraRequestArgs(): Partial<GridSetup.BIRequestArgs> {
        let ggsCenter = this;

        let extraRequestArgs: Partial<GridSetup.BIRequestArgs>;
        if (ggsCenter.Tab.GUID !== `${ggsCenter.Tab.ItemType}-${ggsCenter.Tab.Name}`) {
            extraRequestArgs = {
                OptionalGroupSummaryOptions: {
                    GroupingField: ggsCenter.Tab.Name
                }
            };
        }

        return extraRequestArgs;
    }

    AddListeners() {
        let ggsCenter = this;

        super.AddListeners();

        AddHandler2(
            EventTypes.BIEventTypes.currentAccountFieldPivot,
            ggsCenter.CustomName,
            eventNameSpace.request,
            ggsCenter.DataTabsAreaContainer,
            ggsCenter.OnCurrentAccountFieldPivotRequested.bind(ggsCenter)
        );
    }

    OnCurrentAccountFieldPivotRequested(e, d: { crumb: BasicCrumbInfo }) {
        let ggsCenter = this;

        if (d.crumb) {
            d.crumb.Value = ggsCenter.Tab.Name;
        }
    }
}