import {itemTypes} from "../../enums/enums";
import { ItemBase } from "./itembase";
import { DisplayInfo } from "../../infrastructure/context";

// Eliminate need for this trivial class.

 
    export class Objective extends ItemBase {
        DisplayName: string;
        get ItemType() { return itemTypes.itemObjective; }
        get TypeName() { return "Objective" };
        set TypeName(name) { if (name && this.TypeName !== name) this.TypeName = name; }
        get RequiresParentItem(): boolean { return false; }
        constructor(args) {
            super(args);
            let theObjective = this;
            $.each(args, function (key, val) {
                if (theObjective.hasOwnProperty(key)) theObjective[key] = val;
            });

            if (!theObjective.DisplayName) {
               DisplayInfo(theObjective.ItemType).done(function (displayinfo) {
                    theObjective.DisplayName = displayinfo.DisplayName;
                    theObjective.TypeName = displayinfo.TypeName;
                })
            }

        }
    }
