import { Dialog } from '../dialogs/dialog';
import { Notification } from '../dialogs/notification';
import { itemTypes } from '../../enums/enums';

import { IControlData } from '../../interfaces/interfaces';
import { TDFRequest } from '../../services/request';
import { ItemBase } from './itembase';
import { DisplayInfo, CurrentUser } from '../../infrastructure/context';
export class Task extends ItemBase {
  DisplayName: string;
  get ItemType() {
    return itemTypes.itemTask;
  }
  get TypeName() {
    return 'Task';
  }
  set TypeName(name) {
    if (name && this.TypeName !== name) this.TypeName = name;
  }
  get RequiresParentItem(): boolean {
    return true;
  }
  constructor(args) {
    super(args);
    let theTask = this;
    $.each(args, function (key, val) {
      if (theTask.hasOwnProperty(key)) theTask[key] = val;
    });

    if (!theTask.DisplayName) {
      DisplayInfo(theTask.ItemType).done(function (displayinfo) {
        theTask.DisplayName = displayinfo.DisplayName;
        theTask.TypeName = displayinfo.TypeName;
      });
    }
  }

  OnCreate() {
    let theTask = this;
    let d = $.Deferred();
    let OwnerControl;
    $.each(theTask.Controls, function (k, v: any) {
      OwnerControl = $.grep(v, function (v1: any, k1) {
        return v1.Properties.TDFField === 'Owner';
      });
    });
    if (OwnerControl.length) {
      if (!OwnerControl[0].DataValue.ValueName) {
        OwnerControl[0].DataValue.ValueName = CurrentUser.FullName;
      }
      if (!OwnerControl[0].SaveValue) {
        OwnerControl[0].SaveValue = CurrentUser.FullName;
      }
      theTask.SetDirty(OwnerControl[0]);
    }
    return d.promise(d.resolve());
  }

  ReassignTask() {
    let theTask = this;

    new TDFRequest({ url: '/core/user/userlist/' })
      .MakeRequest()
      .done(function (users) {
        let list = new Dialog({
          size: 'size-normal',
          closable: true,
          title: 'Select New Owner',
          body: $('<div />').append(
            $('<div />').dxDataGrid({
              dataSource: users,
              selection: {
                mode: 'single'
              },
              searchPanel: { visible: true },
              columns: [
                { dataField: 'Name', visible: true },
                { dataField: 'Userid', visible: false },
                { dataField: 'Email', visible: true },
                { dataField: 'Department', visible: true },
                { dataField: 'Office', visible: true },
                { dataField: 'Source', visible: false }
              ],
              onSelectionChanged: function (e) {
                new TDFRequest({
                  url: '/item/SetUDFValueViaTDFField/',
                  data: {
                    field: 'Owner',
                    itemid: theTask.ItemID,
                    itemtype: theTask.ItemType,
                    value: e.selectedRowsData[0].Name
                  },
                  type: 'POST'
                })
                  .MakeRequest()
                  .done(function (response: any) {
                    if (true) {
                      new TDFRequest({
                        url: '/item/SetAttendee/',
                        data: {
                          itemid: theTask.ItemID,
                          itemtype: theTask.ItemType,
                          value: e.selectedRowsData[0].Name
                        },
                        type: 'POST'
                      })
                        .MakeRequest()
                        .done(function (response: any) {
                          if (true) {
                            list.close();
                            theTask.Refresh();
                          }
                        });
                    } else {
                      new Notification({
                        message: response.Message,
                        type: 'error'
                      });
                    }
                  });
              }
            })
          )
        });
        list.open();
      });
  }
}
