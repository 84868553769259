import { EnumUDFFormat, CenterType, itemTypes, menuActions } from "../enums/enums";
import * as Globalize from "globalize";
import { CurrentUser, DisplayInfo } from "../infrastructure/context";
import { GetDevice, DomSafeID, GetActionRequestParamsFromRowData, GroupActions, Debounce, MenuView, GetContainerForRecentOrFavorite } from "../util/allutils";
import { Preferences } from "../infrastructure/user/preferences";
import * as moment from "moment";
import { Notification } from "../components/dialogs/notification";
import { ItemMain } from "../components/items/helpers/itemmain";
import { TDFRequest } from "../services/request";
import { BSIGrids } from "../enums/bi/enums";
import { ItemNavigator } from "../components/items/itemnavigator";
import { CenterBase } from "./centerbase";
import { IGridDataResponse, ITDFGridOptions, IItemInfo } from "../interfaces/interfaces";
import DXDataGrid from "devextreme/ui/data_grid";
import dxCustomStore from "devextreme/data/custom_store";
import dxDataSource from "devextreme/data/data_source";
import { MenuAction } from "util/menuactions";
import devices from "devextreme/core/devices";

export type ColumnTemplateHandler = (col: any) => void;

/*
  Devextreme solution to sorting the column chooser by alpabetical order.
  */

(DXDataGrid as any).registerModule("columnChooserSorting", {
  extenders: {
    controllers: {
      columns: {
        getChooserColumns: function () {
          var result = this.callBase();

          result.sort(function (column1, column2) {
            try {
              return column1.caption.localeCompare(column2.caption);
            } catch (error) {
              console.error(error);
            }
          });

          return result;
        }
      }
    }
  }
});

export class GridWithoutViews {
  /*
    A list of fields the are not show in the grid.
    */
  readonly TDFFields?: Array<string> = [
    "tdfguid",
    "itemtype",
    "hasattachments"
  ];

  /*
    In the case where the grid my need to give the caller a reference to a TDF Item opened from the context menu or the floating buttons.
    Currently used by Expense Wizard for example.
    */
  ObjRef: any;
  /*
    A description for the grid when exported.
    */
  Description: String = "Grid Data";

  originalColumns: Array<DevExpress.ui.dxDataGridColumn>;
  extraFieldCount: number;
  protected _GridDataResponse: IGridDataResponse;
  ///*
  //An object that contains information for the grid that is loaded ... Formatting , Layout, Summaries etc.
  //*/
  //GridView: IGridViewDefinition;
  ///*
  //Information about the grid data that has been loaded ... whether the user can export the grid, Page # of the current grid data , total # of pages, total # of records, etc.
  //*/
  //Meta: IGridDataResponseMeta;
  ///*
  //The information used to request data for the currently loaded grid.
  //*/
  //Request: any;
  ///*
  //If there is already data you need to show in a grid it can be passed in the arguments of the constructor.
  //*/
  //Data: Array<Object>;
  ///*
  //A list of column names for the itemtype detail table????
  //*/
  //Schema: Array<string>;

  //TotalRecords: number;
  //TotalSummaries: any;
  //TotalTime: number;
  //UseGlobalViews: boolean;
  // Groups: any;
  /*
    The html element id where the grid lives
    */
  GridContainer: JQuery;
  /*
    Options that describe how the devextreme grid is displayed and behaves.
    */
  Options: DevExpress.ui.dxDataGridOptions = {};
  /*
    A reference to the Devextreme dxDataGrid class.
    */
  GridObject: DevExpress.ui.dxDataGrid;
  /*
    The TDFweb grid toolbar that contains buttons and controls for things like views, refreshing the grid, grid scope, etc.
    */
  //GridToolbar:GridSetup.Toolbar;

  ColumnTemplateHandler: ColumnTemplateHandler;
  /**
   *if the grid is on an item[linked items] this will be the ItemID of that item. Used for new item creation [ex: Oppotunity - Contact = Appointment]
   */
  ContainerItemID: string;
  /**
   *This is the event that will be fired when a new selection is made on the grid. This will typically be used in conjunction with ToolbarWithoutViews' SelectionChanged function.
   */
  SelectionEvent: Function = (t?: any) => { };
  /**
   *This will be used to help create the storage key for storing the current state of the grid in the session if no GridContainer is present.
   */
  ManualStorageKey: string;
  SelectedCards: any[];

  protected _GridDataSource: DevExpress.data.DataSource;

  constructor(
    gridresponse: IGridDataResponse,
    gridargs: ITDFGridOptions,
    options?: DevExpress.ui.dxDataGridOptions,
    objRef?: any,
    selectionEvent?: Function,
    colTemplateHandler?: ColumnTemplateHandler,
    manualStorageKey?: string
  ) {
    let theGridWOViews = this;
    theGridWOViews.GridDataResponse = gridresponse;
    if (objRef) theGridWOViews.ObjRef = objRef;

    if (colTemplateHandler)
      theGridWOViews.ColumnTemplateHandler = colTemplateHandler;

    if (manualStorageKey) theGridWOViews.ManualStorageKey = manualStorageKey;

    theGridWOViews.SelectionEvent = selectionEvent;

    $.each(gridargs, function (k, v) {
      theGridWOViews[k] = v;
    });
    theGridWOViews.Options = theGridWOViews.DefaultOptions();
    if (options && !$.isEmptyObject(options)) {
      //if (options.dataSource)

      $.each(options, function (key, val) {
        (theGridWOViews.Options as any)[key] = val;
      });
    }
    if (
      !theGridWOViews.Options.columns &&
      theGridWOViews.GridDataResponse.GridView &&
      theGridWOViews.GridDataResponse.GridView.Layout &&
      theGridWOViews.GridDataResponse.GridView.Layout.Columns
    ) {
      theGridWOViews.Options.columns =
        theGridWOViews.GridDataResponse.GridView.Layout.Columns;
    }
  }

  get GridDataResponse() {
    let baseGrid = this;
    if (baseGrid._GridDataResponse) return baseGrid._GridDataResponse;
  }

  set GridDataResponse(response: IGridDataResponse) {
    let baseGrid = this;
    baseGrid._GridDataResponse = response;
  }
  /**
   * Get the format that is used by the web grid from the TDF UDF Format
   * @param theformat
   */
  static GetColumnFormatFromUDFFormat(
    theformat: number,
    col?: DevExpress.ui.dxDataGridColumn
  ) {
    let format: DevExpress.ui.format = {};

    switch (theformat) {
      case EnumUDFFormat.udfCurrency:
        format = {
          type: "currency",
          precision: 2,
          currency: CurrentUser.Currency
        };
        if (col) {
          col.dataType = "number";
        }
        break;
      case EnumUDFFormat.udfPercent:
      case EnumUDFFormat.udfPercent2:
        format = {
          type: "percent",
          precision: 2
        };
        if (col) {
          col.dataType = "number";
        }
        break;
      case EnumUDFFormat.udfDate:
        format = {
          type: "shortDate"
        };
        if (col) {
          col.dataType = "date";
        }
        break;
      case EnumUDFFormat.udfDuration:
        format = {
          // TODO: Check this is hour the duration?!!
          type: "hour"
        };
        if (col) {
          col.dataType = "date";
        }
        break;
      case EnumUDFFormat.udfCombination:
      case EnumUDFFormat.udfFormula:
      case EnumUDFFormat.udfKeywords:
      case EnumUDFFormat.udfNumber:
      case EnumUDFFormat.udfText:
      case EnumUDFFormat.udfYesNo:
        format = {};
        break;
      default:
        format = {};
    }
    return format;
  }

  GetSummaryInfo(summaries, myColumns: Array<any>) {
    let theCenter = this;
    var totalItems = [];

    $.each(summaries, function (key, val) {
      var localColumns = myColumns;
      val.FieldName = val.FieldName.replace(":", "--");
      val.SummaryType = val.SummaryType.toLowerCase().replace("average", "avg");

      var colInfo = $.grep(localColumns, function (col) {
        if (col && col.dataField && val && val.FieldName) {
          return col.dataField.toLowerCase() === val.FieldName.toLowerCase();
        } else {
          return false;
        }
      })[0];
      if (colInfo !== undefined) {
        if (colInfo.format != "") {
          val.ValueFormat = {};

          if (colInfo.format) {
            if (colInfo.format.type) val.ValueFormat.type = colInfo.format.type;
            if (colInfo.format.precision)
              val.ValueFormat.precision = colInfo.format.precision;
          } else {
            val.ValueFormat.type = colInfo.format;
          }
        }

        if (colInfo.width < 100) {
          colInfo.width = 100;
        }
      }

      var dispFormat = val.DisplayFormat;
      var exp = new RegExp(/\{\d(\:.*)\}/);
      var test = dispFormat.match(exp) || [];
      if (test.length >= 2) {
        val.DisplayFormat = dispFormat.replace(test[1], "");
      }
    });

    $.each(summaries, function (key, val) {
      if (val.SummaryType.indexOf("sum") >= 0) {
        totalItems.push(
          {
            name: val.SummaryType,
            column: val.FieldName,
            displayFormat: val.DisplayFormat,
            valueFormat: val.ValueFormat,
            summaryType: val.SummaryType, //"custom",
            sumtype: val.SummaryType,
            cssClass: "btn-xs btn-info",
            customizeText: function (v) {
              if (!val.Value) return "";
              switch (val.ValueFormat.type) {
                case "percent":
                  //Only show the server summary percentage not the page total value becuase it makes no sense in summing percentages.
                  return null;
                default:
                  //Call base method here to handle default case
                  return v.valueText;
              }
            }
          },
          {
            name: val.SummaryType,
            column: val.FieldName,
            displayFormat: val.DisplayFormat,
            valueFormat: val.ValueFormat,
            summaryType: val.SummaryType.toLowerCase().replace(
              "average",
              "avg"
            ),
            sumtype: val.SummaryType,
            cssClass: "btn-xs btn-success",
            customizeText: function (v) {
              if (!val.Value) return "";
              switch (val.ValueFormat.type) {
                case "currency":
                  let tmp = Globalize.parseNumber(val.Value, {
                    style: "decimal"
                  });
                  if (isNaN(tmp)) {
                    break;
                  }
                  return (
                    "OF " +
                    Globalize.formatCurrency(
                      Globalize.parseNumber(val.Value, { style: "decimal" }),
                      CurrentUser.Currency,
                      { style: "accounting" }
                    )
                  );

                case "percent":
                  return (
                    "Total: " +
                    Globalize.formatNumber(
                      Globalize.parseNumber(val.Value, { style: "decimal" }),
                      {
                        style: "percent",
                        minimumFractionDigits: val.ValueFormat.precision,
                        maximumFractionDigits: val.ValueFormat.precision
                      }
                    )
                  ); // Globalize.parseNumber(orig.Value, { style: "decimal" })
              }

              return "OF " + val.Value;
            }
          }
        );
      } else {
        totalItems.push(
          {
            name: val.SummaryType,
            column: val.FieldName,
            displayFormat: val.DisplayFormat,
            valueFormat: val.ValueFormat,
            summaryType: val.SummaryType, //"custom",
            sumtype: val.SummaryType,
            cssClass: "btn-xs btn-info",
            skipEmptyValues: false //Set this to false so empty rows will be included when calculating averages because this is how TDF Desktop works
          },
          {
            name: val.SummaryType,
            column: val.FieldName,
            displayFormat: val.DisplayFormat,
            valueFormat: val.ValueFormat,
            summaryType: val.SummaryType.toLowerCase().replace(
              "average",
              "avg"
            ), //"custom",
            sumtype: val.SummaryType,
            cssClass: "btn-xs btn-success",
            customizeText: function (v) {
              if (!val.Value) return "";
              switch (val.ValueFormat.type) {
                case "currency":
                  return (
                    "OF " +
                    Globalize.formatCurrency(
                      Globalize.parseNumber(val.Value, { style: "decimal" }),
                      CurrentUser.Currency,
                      { style: "accounting" }
                    )
                  );
                case "percent":
                  return Globalize.formatNumber(
                    Globalize.parseNumber(val.Value, { style: "decimal" }),
                    {
                      style: "percent",
                      minimumFractionDigits: val.ValueFormat.precision,
                      maximumFractionDigits: val.ValueFormat.precision
                    }
                  ); // Globalize.parseNumber(orig.Value, { style: "decimal" })
                default:
                  return "OF " + val.Value;
              }
            }
          }
        );
      }
    });
    return {
      totalItems: totalItems,
      skipEmptyValues: false
    };
  }

  get IsBIOrOther() {
    let theGrid = this;
    return (
      $.inArray(
        theGrid.ObjRef.CenterType,
        [
          CenterType.Bi,
          CenterType.AccountBi,
          CenterType.ContactBi,
          CenterType.OppBi,
          CenterType.VendorBi,
          -1
        ]
      ) > -1
    );
  }
  get UseCards() {
    let theGrid = this;
    let notBiorOther = theGrid.ObjRef && !theGrid.IsBIOrOther;

    return (
      GetDevice().isDevice &&
      notBiorOther &&
      Preferences.GetPreference("UseCardView", "WebGrid", "1") === "1"
    );

    //return true
  }
  /**
   * Render the dxDatagrid or just create an instance of dxDataGrid
   */
  Render() {
    let theGridWOViews = this;

    if (theGridWOViews.GridContainer) {
      if (theGridWOViews.UseCards) {
        (theGridWOViews.GridObject as any) = theGridWOViews.CardView();
        (theGridWOViews.GridObject as any).getSelectedRowsData = () => {
          return theGridWOViews.SelectedCards || [];
        };
      } else {
        theGridWOViews.GridObject = theGridWOViews.GridContainer.dxDataGrid(
          theGridWOViews.Options
        ).dxDataGrid("instance");
      }
    } else {
      if (theGridWOViews.UseCards) {
        (theGridWOViews.GridObject as any) = theGridWOViews.CardView();
        (theGridWOViews.GridObject as any).getSelectedRowsData = () => {
          return theGridWOViews.SelectedCards || [];
        };
      } else {
        theGridWOViews.GridObject = $("<div />")
          .dxDataGrid(theGridWOViews.Options)
          .dxDataGrid("instance");
      }
    }
  }

  /**
   * The set of default option to be applied to the dxDataGrid. Note:* if there are options passed in with the contructor arguments that match these options they will be overridden by the arguments passed in.
   */
  DefaultOptions() {
    let theGridWOViews = this;
    let storageKey;

    if (theGridWOViews.GridContainer) {
      storageKey =
        "storage_" +
        (theGridWOViews.GridContainer
          ? $(theGridWOViews.GridContainer)
            ? $(theGridWOViews.GridContainer).attr("id")
            : ""
          : "");
    } else if (theGridWOViews.ManualStorageKey) {
      storageKey = "storage_" + theGridWOViews.ManualStorageKey;
    }

    let t: DevExpress.ui.dxDataGridOptions = {
      dataSource: theGridWOViews.DataSource,
      columnAutoWidth: true,
      columnChooser: {
        enabled: true,
        allowSearch: true,
        height: GetDevice().isDevice ? 260 : 500
        // title:"Drag to/from grid"
      },
      allowColumnReordering: true,
      allowColumnResizing: true,
      rowAlternationEnabled: true,
      showBorders: true,
      showColumnLines: true,
      showRowLines: true,
      selection: {
        mode: "multiple",
        showCheckBoxesMode: "always"
      },
      groupPanel: {
        visible: true
      },
      grouping: {
        autoExpandAll: false
      },
      onRowPrepared: function (e) { },
      filterRow: {
        visible: true,
        applyFilter: "auto"
      },
      sorting: {
        mode: "multiple"
      },
      searchPanel: {
        visible: true,
        width: 240,
        placeholder: "Search..."
      },
      headerFilter: {
        visible: true
      },
      paging: {
        enabled: true,
        pageIndex: 0,
        pageSize: parseInt(
          Preferences.GetPreference("ClientGridPageSize", "TDFMobile")
        )
      },
      pager: {
        allowedPageSizes: [10, 15, 20, 30, 50, 100],
        showInfo: true,
        showPageSizeSelector: true,
        visible: true,
        showNavigationButtons: true,
        infoText: theGridWOViews.BuildPagingInfo()
      },
      export: {
        enabled: false, //AARONS TODO: Check this== theGridWOViews.GridResponse.Meta ? theGridWOViews.GridResponse.Meta.CanExport || false : false,
        fileName:
          theGridWOViews.Description +
          " " +
          Globalize.formatDate(moment().toDate(), { date: "short" }),
        allowExportSelectedData: true
      },
      onContentReady: function (e) {
        let id = theGridWOViews.GetDOMSafeGridViewID();
        if (!id && e.element[0].id) {
          id = DomSafeID(e.element[0].id);
        }
        $(window).on("orientationchange", function () {
          if (devices.orientation() === "landscape") {
            $("#" + id).css("width", "100%");
            $("#recentitemcontainer").css("width", "100%");
          }
          // put this outside if, because some reason orientaion change on quote manager doesnt read landscape?
          $("#quotemanagergrid").css("width", "100%");
        });
      },
      onEditorPrepared: function (options: any) {
        if (
          options.parentType === "filterRow" &&
          (options.editorName === "dxTextBox" ||
            options.editorName === "dxNumberBox")
        ) {
          let editor;
          if (options.editorName === "dxTextBox") {
            editor = options.editorElement.dxTextBox("instance");
          } else {
            editor = options.editorElement.dxNumberBox("instance");
          }

          editor.option("onEnterKey", function (e) {
            let op: any[];
            if (
              options.editorElement &&
              options.editorElement.parent()[0] &&
              options.editorElement.parent()[0].previousElementSibling
            )
              op = $.grep(
                $(options.editorElement.parent()[0].previousElementSibling)
                  .dxMenu("instance")
                  .option("items")[0].items,
                function (v: any, k) {
                  return v.selected;
                }
              );

            let operation = "contains";
            if (op && op.length) operation = op[0].name;

            let filter = options.defaultCalculateFilterExpression(
              null,
              operation
            );
            if (
              theGridWOViews.ObjRef &&
              typeof theGridWOViews.ObjRef._RequestModel !== "undefined"
            ) {
              theGridWOViews.ObjRef._RequestModel.RequestArgs.filterExpression =
                "[" +
                filter[0] +
                "] " +
                theGridWOViews.FilterConverter(
                  filter[1],
                  e.jQueryEvent.target.value
                );
            }
            if (
              theGridWOViews &&
              theGridWOViews.ObjRef &&
              theGridWOViews.ObjRef.RefreshCurrentGrid
            )
              theGridWOViews.ObjRef.RefreshCurrentGrid(null, false);
          });

          editor.option("onFocusIn", e => {
            if (
              Preferences.GetPreference(
                "ShowGridFilterEnterKeyNotification",
                "TDFMobile"
              ) !== "0" &&
              (theGridWOViews &&
                theGridWOViews.ObjRef &&
                theGridWOViews.ObjRef.RefreshCurrentGrid)
            ) {
              let message: any = {};
              message.text = $("<span />").text(
                "After typing the desired filter press the enter key to filter the entire set. "
              );
              message.buttons = $("<div />")
                .append(
                  $("<span />")
                    .css("margin", "0 5px")
                    .append(
                      $("<div />").dxButton({
                        text: "Dismiss for now",
                        icon: "close",
                        type: "success",
                        onClick: function (e: any) {
                          let toast = $($(e.element[0]).parents())
                            .find(
                              ".dx-overlay.dx-widget.dx-visibility-change-handler.dx-toast"
                            )
                            .dxToast("instance");
                          toast.hide();

                          return;
                        }
                      })
                    )
                )
                .append(
                  $("<span />")
                    .css("margin", "0 5px")
                    .append(
                      $("<div />").dxButton({
                        text: "Dismiss for good",
                        icon: "remove",
                        type: "danger",
                        onClick: function (e: any) {
                          let toast = $($(e.element[0]).parents())
                            .find(
                              ".dx-overlay.dx-widget.dx-visibility-change-handler.dx-toast"
                            )
                            .dxToast("instance");
                          toast.hide();
                          Preferences.SetPreference(
                            "ShowGridFilterEnterKeyNotification",
                            "0",
                            "TDFMobile"
                          );
                        }
                      })
                    )
                );
              new Notification({
                type: "info",
                shading: false,
                displayTime: 10000,
                message: message,
                position: { at: "bottom" },
                animation: {
                  show: { type: "slide", duration: 500, from: { top: 0 } }
                }
              });

              // After showing it once for this editor, don't show it again.
              editor.option("onFocusIn", e => { });
            }
          });
        }
      },
      width: "100%",
      onContextMenuPreparing: function (e: any) {
        if (e.row && e.row.rowType === "data") {
          let t: MouseEvent = e.jQueryEvent.originalEvent;
          t.preventDefault();
          e.event.stopPropagation();
          theGridWOViews.ContextMenu(t.target, e.row.data);
          return;
        }
      },

      onRowClick: function (e) {
        if (
          (e.component as DevExpress.ui.dxDataGrid).option("selection.mode") ===
          "multiple"
        ) {
          if (
            !e.isSelected &&
            e.event.target.id.indexOf("drilldownActionButton") === -1
          )
            (e.component as DevExpress.ui.dxDataGrid).selectRows(
              e.key,
              e.jQueryEvent.ctrlKey
            );
          else (e.component as DevExpress.ui.dxDataGrid).deselectRows(e.key);
        }
      },
      onSelectionChanged: function (e) {
        if (theGridWOViews.SelectionEvent) {
          theGridWOViews.DefaultSelectionChanged(e);
        }
      },
      columnResizingMode: "widget",
      columnFixing: { enabled: true },
      stateStoring: {
        enabled: true,
        type: "custom",
        savingTimeout: 500,
        storageKey: storageKey,
        customLoad: function () {
          let dfd = $.Deferred();
          var stateObj: any = {};
          var stateStr = sessionStorage.getItem(this.storageKey);

          if (stateStr) {
            stateObj = JSON.parse(stateStr);
            for (var i = 0; i < stateObj.columns.length; i++) {
              stateObj.columns[i].sortIndex = null;
              stateObj.columns[i].sortOrder = null;
            }
          }

          return dfd.promise(dfd.resolve(stateObj));
        },
        customSave: function (state) {
          let theGridObject: any = theGridWOViews.GridObject;
          if (this.storageKey === "storage_undefined") return;

          for (var i = 0; i < state.columns.length; i++) {
            state.columns[i].sortIndex = null;
            state.columns[i].sortOrder = null;
          }

          state.selectedRowKeys = null;

          sessionStorage.setItem(this.storageKey, JSON.stringify(state));

          if (
            typeof theGridWOViews.originalColumns === "undefined" ||
            theGridWOViews.originalColumns === null
          ) {
            let visibleColumns = theGridObject._controllers.columns.getVisibleColumns();
            if (visibleColumns.length > 0) {
              theGridWOViews.originalColumns = visibleColumns;
              theGridWOViews.extraFieldCount = 0;

              if (
                theGridWOViews.ObjRef &&
                typeof theGridWOViews.ObjRef._RequestModel !== "undefined" &&
                typeof theGridWOViews.ObjRef._RequestModel.RequestArgs
                  .extraFields !== "undefined"
              ) {
                theGridWOViews.extraFieldCount =
                  theGridWOViews.ObjRef._RequestModel.RequestArgs.extraFields.length;
              }
            }
          } else {
            var altered: any[] = theGridObject._controllers.columns.getVisibleColumns();
            let newcols: any[] = theGridWOViews.Changes(
              theGridWOViews.originalColumns,
              altered
            );

            if (
              theGridWOViews.ObjRef &&
              typeof theGridWOViews.ObjRef._RequestModel !== "undefined"
            ) {
              if (newcols.length > 0) {
                if (
                  theGridWOViews.ObjRef._RequestModel.RequestArgs.extraFields
                ) {
                  // Update the visualIndex for all of the existing extraFields.
                  $.each(
                    theGridWOViews.ObjRef._RequestModel.RequestArgs.extraFields,
                    function (i, val) {
                      //Maybe just add/subtract 1 to the visible index if a newCol is added before it?
                      let existingColumns = altered.filter(function (
                        col,
                        index
                      ) {
                        return col.dataField === val.dataField;
                      });
                      if (existingColumns.length > 0) {
                        theGridWOViews.ObjRef._RequestModel.RequestArgs.extraFields[
                          i
                        ].visibleIndex =
                          existingColumns[0].visibleIndex + 1;
                      }
                    }
                  );

                  $.each(newcols, function (index, value) {
                    theGridWOViews.ObjRef._RequestModel.RequestArgs.extraFields.push(
                      value
                    );
                  });
                } else {
                  theGridWOViews.ObjRef._RequestModel.RequestArgs.extraFields = newcols;
                }

                theGridWOViews.ObjRef.RefreshCurrentGrid(null, true);

                //            //        //if ($("#add-cols-alert_" + theCenter.MainAreaID).length === 0) {
                //            //        //    $('.dx-datagrid-header-panel')
                //            //        //        .prepend($("<div></div>")
                //            //        //            .attr("id", "add-cols-alert_" + gridident)
                //            //        //            .addClass("alert alert-info columnAlert")
                //            //        //            .append($("<strong></strong>")
                //            //        //                .html("You have added columns that are not part of the original gridview. "))
                //            //        //            .append($("<a></a>")
                //            //        //                .addClass("btn btn-warning btn-xs")
                //            //        //                .html("Refresh ?")
                //            //        //                .on("click", function () {
                //            //        //                    $.ajax(settings).done(function (response) {
                //            //        //                        $("#grid_" + gridident).dxDataGrid({ dataSource: response.Data });
                //            //        //                        $("#add-cols-alert_" + gridident).remove();
                //            //        //                        $("#grid_" + gridident).dxDataGrid("instance").refresh();
                //            //        //                    })
                //            //        //                })
                //            //        //            ))
                //            //        //}
                //        }
              }
            }
          }
        }
      }
    };

    return t;
  }

  DefaultSelectionChanged(e) {
    let theGridWOViews = this;
    theGridWOViews.SelectionEvent(e);
  }

  Changes(prev: any, now) {
    let list = [];
    let newone = [];
    let oldone = [];
    $.each(now, function (key, value) {
      newone.push({
        dataField: value.dataField,
        visibleIndex: value.visibleIndex + 1
      });
    });
    $.each(prev, function (okey, ovalue) {
      oldone.push({
        dataField: ovalue.dataField,
        visibleIndex: ovalue.visibleIndex + 1
      });
    });
    //let diff = $(newone).not($(oldone)).get();
    $.each(newone, function (dKeyNew, dvalueNew) {
      if (
        oldone.filter(function (e) {
          return e.dataField === dvalueNew.dataField;
        }).length === 0
      ) {
        list.push(dvalueNew);
      }
    });

    return list;
  }

  /**
   * Render the context menu that contains the Actions menu , views menu , open button, and other relevant items.
   * @param element
   * @param rowdata
   */
  ContextMenu(element, rowdata) {
    let theGrid = this;

    //if on a phone or mobile show floating buttons
    let info = GetActionRequestParamsFromRowData(rowdata);
    if (info && info.itemtype) {
      DisplayInfo(info.itemtype).done(function (displayInfo) {
        //if (!TDF[displayInfo.TypeName]) {
        //  if (!TDF[displayInfo.DisplayName]) {
        //    //return;
        //  }
        //}
        let ContactEmail = "";
        let iMain: ItemMain;
        let menu: DevExpress.ui.dxPopover;
        if (!$("#tdfcontextmenu").length)
          $("body").append($("<div id='tdfcontextmenu' />"));

        let selected: any[] = theGrid.GridObject.getSelectedRowsData();

        let contextItemAlreadySelected: boolean = false;
        $.each(selected, function (index, value) {
          if (value["TDF GUID"] === rowdata["TDF GUID"]) {
            contextItemAlreadySelected = true;
          }
        });
        // If we right clicked on a row that is already selected, then leave the selection alone.
        // If we right clicked anywhere else in the grid, clear the selection and operate on the row that we right clicked on.
        if (!contextItemAlreadySelected) {
          theGrid.GridObject.clearSelection();
          selected = [];
          selected.push(rowdata);
        }

        menu = $("#tdfcontextmenu")
          .dxPopover({
            target: element,
            contentTemplate: function (element) {
              element.dxMenu(<DevExpress.ui.dxMenuOptions>{
                orientation: "horizontal",
                dataSource: new dxDataSource({
                  load: function (options) {
                    let dfd = $.Deferred();
                    let requestParams;
                    // If the selected item is a reminder we want to use the parent's actions instead.
                    if (rowdata.ItemType === itemTypes.itemReminder) {
                      requestParams = {
                        itemid: rowdata["ParentId"],
                        itemtype: parseInt(rowdata["LinkItemType"])
                      };
                    } else {
                      requestParams = GetActionRequestParamsFromRowData(
                        rowdata
                      );
                    }

                    let request = new TDFRequest({
                      url: "/action/getactionsforitem/",
                      type: "GET",
                      data: requestParams
                    });
                    request.MakeRequest().done(function (response: any) {
                      iMain = new ItemMain(response.ActionsModel.iMain);

                      $.each(response.ActionsModel.ActionList, function (
                        key,
                        val: any
                      ) {
                        val.beginGroup = true;

                        if (val.ContactEmail) ContactEmail = val.ContactEmail;

                        if (selected.length > 1 && val.MenuText === "Views") {
                          val.visible = false;
                        }

                        if (
                          iMain.ItemType === itemTypes.itemProduct &&
                          (val.MenuText === "Actions" ||
                            val.MenuText === "Views")
                        ) {
                          val.visible = false;
                        }

                        if (val.items) {
                          $.each(val.items, function (
                            submenuIndex,
                            submenuValue
                          ) {
                            submenuValue.disabled =
                              selected.length > 1
                                ? !submenuValue.SupportsMultipleItems
                                : false;
                            submenuValue.visible =
                              selected.length > 1
                                ? submenuValue.SupportsMultipleItems
                                : true;

                            // TODO: This is a temporary fix to get 2018R1 out. Please remove once action has a web implementation.
                            if (
                              submenuValue.MenuText === "Launch Web Access Log"
                            ) {
                              submenuValue.visible = false;
                            }
                          });
                          let masterActions = GroupActions(
                            val.items,
                            GetDevice().isDevice,
                            true
                          ); // Want it to always group the actions for the right-click menu beacuase the menu doesn't cooperate well for height.

                          val.items = masterActions;
                        }
                      });

                      dfd.resolve(response.ActionsModel.ActionList);
                    });
                    return dfd.promise();
                  }
                }),
                onSubmenuShowing: (e: any) => {
                  if (e.submenu._userOptions.items[0].Action) {
                    let dis = e.submenu;
                    dis.$contentDelimiter.prev().dxScrollView({ height: 250 });
                    //dis.parent().find('.dx-submenu').dxScrollView({ height: 250 });
                  }
                },
                onSubmenuShown: function (e: any) {
                  let distance = e.element.offset().top - $(window).scrollTop();
                  if (distance >= 600) {
                    return;
                  }
                  if (
                    e.component._visibleSubmenu &&
                    e.component._visibleSubmenu._shownSubmenus &&
                    e.component._visibleSubmenu._shownSubmenus.length
                  ) {
                    let mnu = e.component._visibleSubmenu._shownSubmenus[0];
                    let root = e.rootItem.offset();
                    let coords = mnu.offset();
                    let offset = e.component._visibleSubmenu.$contentDelimiter.offset();
                    let bottom = coords.top + mnu.outerHeight() - coords.top;
                    setTimeout(function () {
                      if (mnu.height() > 500) {
                        mnu.dxScrollView({ height: 250 });
                        let newcorrds = mnu.offset();
                        let newbottom =
                          newcorrds.top + mnu.outerHeight() - newcorrds.top;
                        if (coords.top > root.top) {
                          // open to bottom top should be ok
                        } else {
                          if (bottom !== newbottom) {
                            mnu.css(
                              "transform",
                              `translate( ${e.component._visibleSubmenu.$contentDelimiter
                                .siblings()
                                .width()}px,0px`
                            );
                          }
                        }
                      }
                    }, 100);
                  }
                },
                submenuDirection: "rightOrBottom",
                itemsExpr: "items",
                showSubmenuMode: { name: "onHover", delay: 100 },
                showFirstSubmenuMode: { name: "onHover", delay: 100 },
                displayExpr: "MenuText",
                onItemClick: Debounce(function (data: any) {
                  let itemInfo = rowdata;

                  if (data.itemData.IsView) {
                    if (data.itemData.Action) {
                      data.itemData.iteminfo = itemInfo;
                      let views = new MenuView(data.itemData);
                      menu.hide();
                    }
                  } else {
                    if (data.itemData.Action) {
                      let selected: any[] = theGrid.GridObject.getSelectedRowsData();

                      if (selected && selected.length === 0) {
                        selected = [];
                        selected.push(rowdata);
                      }

                      if (
                        data.itemData.Action === "Open" &&
                        selected.length > 1
                      ) {
                        theGrid.OpenNavigator(selected);
                        return;
                      }

                      if (
                        data.itemData.Action === menuActions.menuNewEmail &&
                        ContactEmail
                      )
                        data.itemData.Email = ContactEmail;
                      let items: Array<IItemInfo | { LinkID: string }> = [];

                      if (
                        (itemInfo["TDF GUID"] ||
                          itemInfo["ItemID"] ||
                          itemInfo["TDFItemID"]) &&
                        (itemInfo["ItemType"] || itemInfo["TDFItemType"])
                      ) {
                        $.each(selected, function (index, rowData) {
                          items.push({
                            ItemId:
                              rowData["TDF GUID"] ||
                              rowData["ItemID"] ||
                              rowData["TDFItemID"],
                            ItemType:
                              parseInt(rowData["ItemType"]) ||
                              parseInt(rowData["TDFItemType"]),
                            iMain: iMain
                          });
                        });

                        let action = new MenuAction(
                          data.itemData,
                          <IItemInfo[]>items,
                          theGrid.ContainerItemID
                        );
                      } else {
                        $.each(selected, function (index, rowData) {
                          items.push({
                            LinkID: rowData["LinkID"]
                          });
                        });

                        let action = new MenuAction(
                          data.itemData,
                          <{ LinkID: string }[]>items,
                          theGrid.ContainerItemID
                        );
                      }

                      menu.hide();
                    }
                  }

                  //$("div.dxsubmenu").dxScrollView({
                  //    height: $(window).innerHeight() * .3
                  //});
                }, 500)
              });
            },
            visible: true
          })
          .dxPopover("instance");
        menu.show();
      });
    }
  }

  OpenNavigator(selected?: any) {
    let theGrid = this;
    let ids = [];
    let itemtype = selected[0].ItemType;
    let navigatorAllowed: boolean = true;
    //theGrid.GridToolbar ? theGrid.GridToolbar.GridItemType : selected[0].ItemType;
    // The above line causes an issue for BI Group Summary where the grid data is of type Account, but the Grid Item Type is of type Acct Group Summary.
    //  I think the above line could be changed to use selected[0].ItemType, but at this point in R1, I'm not going to risk causing an issue for something else.
    // So if we are in BI Account Group summary, set the item type to Account so that the navigator knows how to handle it.
    $.each(selected, function (k, v) {
      if (!v.ItemType) {
        v.ItemType = itemTypes.itemProduct;
      }

      if (v.ItemType === BSIGrids.Acct) {
        v.ItemType = itemTypes.itemAccount;
      }

      if (v.ItemType !== itemTypes.itemProduct) {
        ids.push(v["TDF GUID"]);
      } else {
        ids.push({
          LinkID: v["LinkID"]
        });
      }

      if (
        parseInt(v.ItemType) === itemTypes.itemQuote ||
        parseInt(v.ItemType) === itemTypes.itemExpense
      ) {
        navigatorAllowed = false;
      }
    });

    if (navigatorAllowed) {
      new ItemNavigator(ids, parseInt(itemtype));
    } else {
      new Notification({
        message:
          "Multiple items detected: At least one of the selected types does not support the item navigator.",
        type: "warning",
        displayTime: 5000000
      });
    }
  }

  get ShouldShowTypeInCardHeader() {
    let theGrid = this;
    return theGrid.ObjRef && theGrid.ObjRef.CenterType === 999;
  }

  CardView() {
    let theGrid = this;
    let opts: DevExpress.ui.dxAccordionOptions = {
      dataSource: theGrid.DataSource,
      collapsible: true,
      multiple: true,
      selectedIndex: -1,
      height: theGrid.Options.height || "auto",
      itemTitleTemplate: function (data, index, element) {
        let subject = theGrid.TryGetSubject(theGrid.GridDataResponse);
        let key = "No Value";

        if (typeof subject === "object" && subject && data[subject.dataField]) {
          key = data[subject.dataField];
        } else {
          if (typeof subject === "string" && data[subject]) {
            key = data[subject];
          } else {
            if (data["TDF_Subject"]) {
              key = data["TDF_Subject"];
            } else {
              if (
                theGrid.GridDataResponse.GridView &&
                typeof data[
                theGrid.GridDataResponse.GridView.Layout.Columns[0].dataField
                ] === "string"
              ) {
                key =
                  data[
                  theGrid.GridDataResponse.GridView.Layout.Columns[0]
                    .dataField
                  ];
              } else {
                key = Object.keys(data)[0];
              }
            }
          }
        }

        let result;

        result = theGrid.ShouldShowTypeInCardHeader
          ? $(`<div class="row">
								    <div class="col-xs-1">
									<div class="recfaveselect" id='card-select-check${DomSafeID(
            data["TDF GUID"] || data["ItemID"] || data["TDFItemID"]
          )}' data-itemtype="${data.ItemType ||
          data["TDFItemType"]}"data-guid="${data["TDF GUID"] ||
          data[
          "TDFItemID"
          ]}" data-index="${index}" style="vertical-align:middle;display:inline-flex;"></div>
								    </div>
								    <div class="col-xs-1">
									<small class="tdfitem${data.ItemType ||
            data[
            "TDFItemType"
            ]}" style="vertical-align:middle;display:inline-flex;"></small>
								    </div>
								    <div class="col-xs-8" ><small style="white-space:normal;word-wrap:break-word">${key}</small></div>
								  </div>`)
          : $(`<div class="row">
			    <div class="col-xs-2">
				<span class="recfaveselect" id='card-select-check${DomSafeID(
            data["TDF GUID"] ||
            data["ItemID"] ||
            data["TDFItemID"] ||
            data["LinkID"].toString()
          )}' data-itemtype="${data.ItemType ||
          data["TDFItemType"]}"data-guid="${data["TDF GUID"] ||
          data["LinkID"] ||
          data["TDFItemID"]}" data-index="${index}"></span>
			    </div>
			    <div class="col-xs-8" ><small style="white-space:normal;word-wrap:break-word">${key}</small></div>
			</div>`);

        return result;
      },
      onItemTitleClick(e: any) {
        if ($(e.event.target).hasClass("dx-checkbox-icon")) {
          e.event.stopImmediatePropagation();
        }
      },
      onSelectionChanged(e) {
        //possibly use opened accordions as selected instead of checkboxes
      },
      itemTemplate: function (data, index, element) {
        let details = $("<div />")
          .addClass("recent-fav-details")
          .css("border-top", ".5px solid");

        details.append(`<p class="row"></p>`);
        let t = [
          "itemtype",
          "tdfguid",
          "tdf_attachmentCount",
          "favorite",
          "parentname",
          "quoteid"
        ];

        $.each(data, function (key: string, val) {
          let col = theGrid.getColumn(theGrid.GridDataResponse, key);
          let reg = new RegExp("s", "g");
          if (
            col &&
            col.length &&
            col[0].visible &&
            (typeof val !== "undefined" &&
              val !== "" &&
              val !== null &&
              val !== "null")
          ) {
            if ($.inArray(key.toLowerCase().replace(reg, ""), t) === -1) {
              if (col[0].cellTemplate) {
                let span = '<span class="col-xs-6" ></span>';
                let id = Date.now();
                details.append(`<p class="row">
						    <small class="col-xs-4">${key}:</small><span id='${id}' class="col-xs-6" ></span>
						   </p>`);

                (col[0].cellTemplate as any)(details.find(`#${id}`), { data });
              } else {
                if (
                  key === "Creation" ||
                  key === "ModDate" ||
                  key === "RecentItemDate"
                ) {
                  details.append(`<p class="row">
						    <small class="col-xs-4">${key}:</small><span class="col-xs-6">${val}</span>
						   </p>`);
                  // details.append($("<p />").text(key + " :  " + moment(new Date(val).toISOString().replace("Z", "")).format("LL")))
                } else {
                  details.append(`<p class="row">
						    <small class="col-xs-4">${key}:</small><span class="col-xs-6" style="white-space:normal;word-wrap:break-word">${val}</span>
						   </p>`);
                  // details.append($("<p />").text(key + " :  " + val))
                }
              }
            }
          } else {
            if (!theGrid.GridDataResponse.GridView) {
              if ($.inArray(key.toLowerCase().replace(reg, ""), t) === -1) {
                if (
                  key === "Creation" ||
                  key === "ModDate" ||
                  key === "RecentItemDate"
                ) {
                  details.append(`<p class="row">
						    <small class="col-xs-4">${key}:</small><span class="col-xs-6">${val}</span>
						   </p>`);
                  // details.append($("<p />").text(key + " :  " + moment(new Date(val).toISOString().replace("Z", "")).format("LL")))
                } else {
                  details.append(`<p class="row">
						    <small class="col-xs-4">${key}:</small><span class="col-xs-6" style="white-space:normal;word-wrap:break-word">${val}</span>
						   </p>`);
                  // details.append($("<p />").text(key + " :  " + val))
                }
              }
            }
          }
        });
        let result = GetContainerForRecentOrFavorite(data, details);
        result.append(`<p class="row"></p>`);
        return details;
      },
      onContentReady: function (e) {
        let id = theGrid.GridContainer[0].id;
        $(window).on("orientationchange", function () {
          $("#" + id).css("width", "100%");
          $("#" + id).css("height", "100%");
          $("#recentitemcontainer").css("width", "100%");
          //  $(`.tdf-dx-card-pager-${theGrid.GridContainer[0].id}`).css("top": $("#${theGrid.GridContainer[0].id} > div").offset().top)
        });
        theGrid.CardHeaderCheckbox();

        theGrid.CardPager(e);
      }
    };

    if (theGrid.GridContainer) {
      let accordionDiv = $('<div />');

      let scrollview = $('<div />');

      $(theGrid.GridContainer).append(
        scrollview.append(
          accordionDiv
        )
      );

      scrollview.dxScrollView({ height: '100%' });

      return accordionDiv.dxAccordion(opts).dxAccordion('instance');
    } else {
      return $("<div/>")
        .dxAccordion(opts)
        .dxAccordion("instance");
    }
  }

  CardPager(e) {
    let theGrid = this;
    let ds: DevExpress.data.DataSource = e.component.option("dataSource");
    if (!ds || !ds.totalCount || !theGrid) return;

    let id = theGrid.GridContainer[0].id;

    if (!$(`#tdf-card-page-container-${id}`).length) {
      if (
        theGrid.ObjRef &&
        theGrid.ObjRef.GridContainer &&
        typeof theGrid.ObjRef.GridContainer !== "string" &&
        theGrid.ObjRef.GridContainer.parent().length
      ) {
        $(
          `<div id='tdf-card-page-container-${id}' style='margin:20px 0;padding: 0 0 20px 0;'/>`
        ).appendTo(theGrid.ObjRef.GridContainer.parent());
      } else {
        $(
          `<div id='tdf-card-page-container-${id}' style='margin:20px 0;padding: 0 0 20px 0;'/>`
        ).appendTo("#tdfbodycontent");
      }
    } else {
      $(`#tdf-card-page-container-${id}`).empty();
    }
    if (
      ds.totalCount() >
      parseInt(Preferences.GetPreference("ClientGridPageSize", "TDFMobile"))
    ) {
      let text = theGrid.BuildPagingInfo();

      if (!$(`.tdf-dx-card-pager-${theGrid.GridContainer[0].id} `).length) {
        let pager = `<div class="tdf-dx-card-pager-${
          theGrid.GridContainer[0].id
          } row">
					    <div class='tdf-dx-card-pager-prev-${
          theGrid.GridContainer[0].id
          } col-xs-2'></div>
					<div class="col-xs-8">
					    <div  style="white-space:normal;word-wrap:break-word">${text}</div>
					</div>
				    <div class='tdf-dx-card-pager-next-${
          theGrid.GridContainer[0].id
          }  col-xs-2'></div>
				 </div>`;

        $(pager).appendTo(`#tdf-card-page-container-${id}`);
        $(`.tdf-dx-card-pager-prev-${theGrid.GridContainer[0].id}`).dxButton({
          icon: "dx-icon dx-icon-chevronprev",
          height: "50px",
          onClick(e) {
            function shouldEnableNext() {
              let visiblePageSize = ds.pageSize();
              return (
                ds.pageIndex() + 2 * visiblePageSize > ds.items.length ||
                theGrid.GridDataResponse.Meta.TotalPages >
                theGrid.GridDataResponse.Meta.CurrentPage
              );
            }
            if (ds.pageIndex() > 0) {
              ds.pageIndex(ds.pageIndex() - 1);
              ds.load().done(() => {
                if (
                  ds.pageIndex() === 0 &&
                  theGrid.GridDataResponse.Meta.CurrentPage === 1
                ) {
                  e.component.option("disabled", true);
                }
                if (shouldEnableNext()) {
                  $(`.tdf-dx-card-pager-next-${theGrid.GridContainer[0].id}`)
                    .dxButton("instance")
                    .option("disabled", false);
                }
              });
            }
          },
          //visible: theGrid.GridResponse.Meta.TotalPages > 1,
          disabled:
            ds.pageIndex() === 0 &&
            (theGrid.GridDataResponse.Meta
              ? theGrid.GridDataResponse.Meta.CurrentPage === 1
              : true)
        });
        $(`.tdf-dx-card-pager-next-${theGrid.GridContainer[0].id}`).dxButton({
          icon: "dx-icon dx-icon-chevronnext",
          height: "50px",
          onClick(e) {
            function shouldLoadNext() {
              let visiblePageSize = ds.pageSize();
              return ds.pageIndex() + 2 * visiblePageSize > ds.items.length;
            }
            function shouldEnablePrev() {
              return !(
                ds.pageIndex() === 0 &&
                theGrid.GridDataResponse.Meta.CurrentPage === 1
              );
            }
            if (theGrid.PageInfo(ds).hasMoreOnClient) {
              ds.pageIndex(1 + ds.pageIndex());
              ds.load().done(d => {
                if (
                  !(
                    theGrid.PageInfo(ds).hasMoreOnClient ||
                    theGrid.PageInfo(ds).hasMoreOnServer
                  )
                ) {
                  e.component.option("disabled", true);
                }
                if (shouldEnablePrev()) {
                  $(`.tdf-dx-card-pager-prev-${theGrid.GridContainer[0].id}`)
                    .dxButton("instance")
                    .option("disabled", false);
                }
              });
            } else {
              if (
                theGrid.ObjRef &&
                (theGrid.ObjRef as CenterBase).CenterType ===
                CenterType.InfoCenter
              ) {
                $(`#tdf-card-page-container-${id}`).empty();
                //TODO: Get paging working for cards

                ////let theCenter: CenterBase = theGrid.ObjRef;
                ////theCenter.RequestModel.RequestArgs.page += 1;
                ////(ds.store() as DevExpress.data.ArrayStore).clear();
                ////ds.reload().done(() => {
                ////    theCenter.RemoveGridFromCache(theCenter.CurrentSelectedItem.CurrentView);
                ////    theCenter.LoadGrid(theCenter.CurrentSelectedItem.CurrentView, theGrid.GridContainer, false, theCenter.RequestModel.RequestArgs.page);
                ////});
              }
            }
          },
          //   visible: theGrid.GridResponse.Meta.TotalPages > 1,
          disabled: !(
            theGrid.PageInfo(ds).hasMoreOnClient ||
            theGrid.PageInfo(ds).hasMoreOnServer
          )
        });
      }
    }
  }
  private PageInfo(ds: any) {
    let theGrid = this;
    let moreServer = theGrid.GridDataResponse.Meta
      ? theGrid.GridDataResponse.Meta.CurrentPage <
      theGrid.GridDataResponse.Meta.TotalPages
      : false;
    let moreClient = !(
      ds.isLastPage() || (ds.pageIndex() + 1) * ds.pageSize() >= ds.totalCount()
    );
    return {
      hasMoreOnServer: moreServer,
      hasMoreOnClient: moreClient
    };
  }

  protected getColumn(
    response: IGridDataResponse,
    key: any
  ): DevExpress.ui.dxDataGridColumn[] {
    let col = (response.GridView.Layout.Columns as any[]).filter((v, i) => {
      return v.caption && (v.caption === key || v.dataField === key);
    });
    return col;
  }

  private TryGetSubject(response: IGridDataResponse) {
    let subject;
    if (response && response.Data && !response.GridView) {
      if (response.Data.length) {
        if (response.Data[0]) {
          subject = Object.keys(response.Data[0])
            .map(k => {
              let index = $.inArray(k.toLowerCase(), [
                "subject",
                "description",
                "tdf_subject"
              ]);
              if (index !== -1) {
                return k;
              }
            })
            .filter(item => {
              return item;
            });
        } else {
          subject = [""];
        }
      }
    } else {
      subject = (response.GridView.Layout.Columns as any[]).filter((v, i) => {
        let found =
          v.UDFInfo &&
          v.UDFInfo.TDFField &&
          v.UDFInfo.TDFField === "TDF_Subject";
        if (!found) {
          found =
            v.caption &&
            (v.caption.toLowerCase() === "subject" ||
              (v.dataField && v.dataField.toLowerCase() === "subject"));
        }
        if (!found) {
          found =
            v.caption &&
            (v.caption.toLowerCase() === "description" ||
              (v.dataField && v.dataField.toLowerCase() === "description"));
        }
        return found;
      });
      if (subject.length > 1) {
        let tempSubject = subject.filter((v, i) => {
          return v.visible;
        });
        if (tempSubject && tempSubject.length) {
          if (response.Data[0][tempSubject[0].dataField]) {
            subject = tempSubject;
          }
        }
      }
    }
    if (!subject) subject = [""];
    return subject[0];
  }

  private CardHeaderCheckbox() {
    let theGrid = this;
    if (theGrid.SelectedCards) theGrid.SelectedCards = [];
    let opts: DevExpress.ui.dxCheckBoxOptions = {
      onValueChanged(e) {
        if (e.value) {
          let ds: DevExpress.data.DataSource = theGrid.GridObject.option(
            "dataSource"
          );
          let index = ds.pageIndex() * ds.pageSize() + e.element.data("index");

          if (theGrid.SelectedCards && theGrid.SelectedCards.length) {
            theGrid.SelectedCards.push(theGrid.GridDataResponse.Data[index]);
          } else {
            theGrid.SelectedCards = [theGrid.GridDataResponse.Data[index]];
          }
        } else {
          let it = theGrid.SelectedCards.filter((v, i) => {
            if (v["TDF GUID"]) {
              if (v["TDF GUID"] === e.element.data("guid")) {
                theGrid.SelectedCards.splice(i, 1);
              }
            } else {
              if (e.element.data("guid") === "undefined") {
                theGrid.SelectedCards.splice(i, 1);
              }
            }
          });
        }
        if (theGrid.SelectionEvent) {
          theGrid.SelectionEvent({ selectedRowsData: theGrid.SelectedCards });
        }
      }
    };

    $(".recfaveselect:not(.dx-checkbox)").dxCheckBox(opts);
  }

  BuildPagingInfo() {
    return "Page #{0}. Total: {1} ({2} items)";
  }

  // Returns empty string normally, but is meant to be overidden by child classes.
  GetDOMSafeGridViewID() {
    return "";
  }

  set DataSource(ds) {
    let baseGrid = this;
    baseGrid._GridDataSource = ds;
  }

  get DataSource(): DevExpress.data.DataSource {
    let baseGrid = this;
    /*TODO: Need to get areas where grid data is loaded outside of the grid to give this a request model somehow*/
    if (baseGrid._GridDataSource) return baseGrid._GridDataSource;

    let storeOptions: DevExpress.data.CustomStoreOptions = {
      loadMode: "raw",
      load(options) {
        let d = $.Deferred();
        return d.promise(d.resolve(baseGrid.GridDataResponse.Data));
      },
      byKey(key) {
        let d = $.Deferred();
        return d.promise();
      }
    };

    let store = new dxCustomStore(storeOptions);

    let options: DevExpress.data.DataSourceOptions = {
      store: store, // { type: "array", data: baseGrid.GridDataResponse.Data },
      pageSize: parseInt(
        Preferences.GetPreference("ClientGridPageSize", "TDFMobile")
      ),
      requireTotalCount: true,
      paginate: true
    };
    baseGrid.DataSource = new dxDataSource(options);

    return baseGrid._GridDataSource;
  }

  FilterConverter(op, text: string) {
    let theCenter = this;
    text = text.replace("'", "''");
    let converter = {
      contains: function (text) {
        return "LIKE '%" + text + "%'";
      },
      notcontains: function (text) {
        return "NOT LIKE '%" + text + "%'";
      },
      startswith: function (text) {
        return "LIKE '" + text + "%'";
      },
      endswith: function (text) {
        return "LIKE '%" + text + "'";
      },
      "=": function (text) {
        return "= '" + text + "'";
      },
      "<>": function (text) {
        return "<> '" + text + "'";
      }
    };
    return converter[op](text);
  }
}

export class GridWithoutViewsAndCards extends GridWithoutViews {
  get UseCards() {
    return false;
  }
}

//$().ready(function () {
//  (function ($) {
//    $.fn.drags = function (opt) {
//      opt = $.extend({ handle: "", /*cursor: "move"*/ }, opt);

//      if (opt.handle === "") {
//	var $el = this;
//      } else {
//	var $el = this.find(opt.handle);
//      }
//      return $el.css('cursor', opt.cursor).on("mousedown", function (e) {
//	if (opt.handle === "") {
//	  var $drag = $(this).addClass('draggable');
//	} else {
//	  var $drag = $(this).addClass('active-handle').parent().addClass('draggable');
//	}
//	var z_idx = $drag.css('z-index'),
//	  drg_h = $drag.outerHeight(),
//	  drg_w = $drag.outerWidth(),
//	  pos_y = $drag.offset().top + drg_h - e.pageY,
//	  pos_x = $drag.offset().left + drg_w - e.pageX;
//	$drag.css('z-index', 1000).parents().on("mousemove touchmove", function (e) {
//	  $('.draggable').offset({
//	    top: e.pageY + pos_y - drg_h,
//	    left: e.pageX + pos_x - drg_w
//	  }).on("mouseup", function () {
//	    $(this).removeClass('draggable').css('z-index', z_idx);
//	  });
//	});
//	e.preventDefault(); // disable selection
//      }).on("mouseup touchend", function () {
//	if (opt.handle === "") {
//	  $(this).removeClass('draggable');
//	} else {
//	  $(this).removeClass('active-handle').parent().removeClass('draggable');
//	}
//      });
//    }
//  })(jQuery);

//});

//interface JQuery {
//  drags();
//}
