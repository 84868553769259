import { RelativeDateFilterDataService } from "../services/grid/relativedatefilter/relativedatefilterdataservice";
import { RelativeDateFilterControl } from "../components/controls/relativedatefilter";
import { Dialog } from "../components/dialogs/dialog";
import { Form } from "../components/controls/form";
import "devextreme/ui/check_box";
import "devextreme/ui/number_box";
import "devextreme/ui/list";
import "devextreme/ui/select_box";
import "devextreme/ui/drop_down_box";
import "devextreme/ui/text_box";
import "devextreme/ui/text_area";
import "devextreme/ui/filter_builder";

import "../components/controls/filterbuilder_with_defaults";
import { AddHandler } from "../infrastructure/events/ui_events";
import { eventNameSpace, EventTypes } from "../enums/webevents/enums";
import { IGridView } from "../interfaces/grids/interfaces";

import {
  GeneralSettingsResponse,
  ViewColumnSetting,
  ViewFormatSettings,
  ColumnFormat,
  IConvertedViewFilter,
  FieldList,
  AccountTypeSettings,
  ISummarySettings
} from "../interfaces/admin/gridmanagement/interfaces";
import { IDateRangeSaveOptions } from "../interfaces/grids/idaterangesaveoptions";
import { GridManagementDataService } from "../services/admin/gridmanagement/gridmanagementdataservice";
import {
  EnumGridOptionsTDFActive,
  EnumGridOptionsToolbar,
  EnumGridOptionsColumn,
  EnumTDFAccountType
} from "../enums/admin/gridmanagement/enums";
import "devextreme/ui/tab_panel";
import { LoadCompany } from "../infrastructure/context";
import {
  gethash,
  AnyOf,
  NonOf,
  GetDevice,
  PeriodReplacementGUID,
  ColonReplacementGUID,
  makeFluid
} from "../util/allutils";
import { FilterBuilderHelper } from "../util/allutils";
import dxDataSource from "devextreme/data/data_source";
import dxCustomStore from "devextreme/data/custom_store";
import { captionize } from "devextreme/core/utils/inflector";
import { itemTypes } from "enums/enums";
import { BSIGrids } from "enums/bi/enums";
export const DefaultGridOptions: DevExpress.ui.dxDataGridOptions = {
  columnAutoWidth: true,
  filterRow: { visible: true },
  columnHidingEnabled: true,
  showColumnLines: true,
  headerFilter: { visible: true },
  allowColumnResizing: true,
  columnResizingMode: "widget",
  showBorders: true,
  rowAlternationEnabled: true,
  paging: { enabled: false }
};

//TODO: Maybe create an In memeory datagrid using the layout information
export class GridManager {
  static GetSections(ItemType: itemTypes | BSIGrids) {
    return [
      { Name: "General", index: 0 },
      { Name: "Columns", index: 1 },
      { Name: "Formatting", index: 2 },
      { Name: "Server-side Filters", index: 3, visible: GridManager.AllowsServerFilters(ItemType) },
      { Name: "Parent Filters", index: 4, visible: GridManager.AllowsServerFilters(ItemType) },
      { Name: "Client-side Filters", index: 5 },
      { Name: "Account Types", index: 6 },
      { Name: "Summaries", index: 7 }
    ];
  }


  get tabs(): DevExpress.ui.dxTabPanelItemTemplate[] {
    let gm = this;
    return [
      {
        title: "General",
        template(d, idx, el) {
          makeFluid(el);
          gm.GeneralGridSettingsForm(el);
        }
      },
      {
        title: "Columns",
        template(d, idx, el) {
          makeFluid(el);
          gm.ColumnVisibilitySettings(el);
        }
      },
      {
        title: "Formatting",
        template(d, idx, el) {
          makeFluid(el);
          gm.ColumnFormatSettings(el);
        }
      },
      {
        title: "Server-side Filters",
        visible: GridManager.AllowsServerFilters(gm._view.ItemType),
        template(d, idx, el) {
          makeFluid(el);
          gm.ServerSideFilterSettings(el);
        }
      },
      {
        title: "Parent Filters",
        visible: GridManager.AllowsServerFilters(gm._view.ItemType),
        template(d, idx, el) {
          makeFluid(el);
          gm.ParentFilterSettings(el);
        }
      },
      {
        title: "Client-side Filters",
        template(d, idx, el) {
          makeFluid(el);
          gm.ClientSideFilterSettings(el);
        }
      },
      {
        title: "Account Types",
        template(d, idx, el) {
          makeFluid(el);
          gm.AccountTypeSettings(el);
        }
      },
      {
        title: "Summaries",
        template(d, idx, el) {
          makeFluid(el);
          gm.SummarySettings(el);
        }
      }
    ];
  }
  static AllowsServerFilters(ItemType: itemTypes | BSIGrids): boolean {
    switch (ItemType) {
      case itemTypes.itemRecentItem:
      case itemTypes.itemFavorite:
        return false;

      default:
        return true;
    }
  }
  private _dialog: Dialog;
  private _view: IGridView;
  private _managerDataService: GridManagementDataService;
  // private _allColumns4View: string[];
  private eventLimiter: string;
  private grid: DevExpress.ui.dxDataGrid;
  private GeneralSettingsForm: Form;
  private ColumnSettingsForm: Form;
  private FormatSettingsForm: Form;
  private ServerFilterSettingsForm: Form;
  private ParentFilterSettingsForm: Form;
  private ClientFilterSettingsForm: Form;
  private AccountTypeSettingsForm: Form;
  private SummarySettingsForm: Form;
  private DateRangeForm: Form;
  private _ColumnsHash: any;
  private _UseOwnerFilter: boolean;
  ServiceLoaded = $.Deferred();
  constructor(
    view: IGridView,
    grid: DevExpress.ui.dxDataGrid,
    eventLimiter: string,
    index?,
    open = true
  ) {
    let gm = this;
    this._view = view;
    this.eventLimiter = eventLimiter;
    if (index) {
      this["index"] = index;
    }
    this.grid = grid;
    //if (location.host.search(/localhost/gi) >= 0) {
    //	window["GridManager"] = this;
    //}
    if (this.grid && this.grid.option("columns")) {
      this._ColumnsHash = gethash("dataField", this.grid.option("columns"));

      LoadCompany().done(() => {
        this._managerDataService = new GridManagementDataService(this._view, this.eventLimiter);
        if (open) {
          this.OpenDialog();
        } else {
          gm.ServiceLoaded.promise(gm.ServiceLoaded.resolve());
        }
      });
    }
  }

  private DateFilterActiveTab: string;

  private SettingsDirtyBag = {
    General: null,
    Columns: null,
    Formatting: null,
    ServerSideFilter: null,
    ParentFilter: null,
    ClientSideFilter: null,
    AccountTypes: null,
    Summaries: null
  };

  private GeneralGridSettingsForm(el: JQuery) {
    let gm = this;
    gm._managerDataService
      .GeneralSettingsForView()
      .done((response: GeneralSettingsResponse) => {
        let dateRangeFilterEditor = new RelativeDateFilterControl(
          gm._view.GUID
        );
        gm.GeneralSettingsForm = new Form(el, {
          colCountByScreen: {
            lg: 2,
            md: 2,
            sm: 1,
            xs: 0
          },
          items: [
            <DevExpress.ui.dxFormGroupItem>{
              itemType: "group",
              colCount: 1,
              colSpan: 1,
              items: [
                {
                  itemType: "group",
                  caption: "Quick Grid Mode",
                  colSpan: 1,
                  visibleIndex: 0,
                  colCount: 1,
                  items: response.Meta.AllColumnOptions.map((v, k) => {
                    if (v.Display === "Enable Quick Grid Mode")
                      return {
                        dataField: v.Display,
                        label: { visible: false },
                        editorType: "dxCheckBox",
                        helpText:
                          "When enabled, only 'visible columns' will be downloaded.",
                        colSpan: 1,
                        editorOptions: <DevExpress.ui.dxCheckBoxOptions>{
                          value:
                            response.ViewSettings.ColumnOptions.filter(
                              (val, key) => {
                                return v.ID === val.ID;
                              }
                            ).length === 1,
                          text: captionize(v.Display),
                          name: v.ID.toString(),
                          onValueChanged(e) {
                            gm.SetColumnOptionsVal();
                            //let optionid = parseInt(e.component.option("name"));

                            //if (response.ViewSettings.ColumnOptions.filter((setval, k) => { return setval.ID === optionid }).length > 0 && !e.value) {
                            //    //  gm.GenSettingsDirtyBag[v.Display] = false;
                            //    gm.SetGeneralSettingDirty("Column Options", false);
                            //} else {
                            //    gm.SetGeneralSettingDirty(v.Display, true);
                            //    //gm.GenSettingsDirtyBag[v.Display] = true;
                            //}
                          }
                        }
                      };
                  }).filter(item => {
                    return item;
                  })
                },
                {
                  itemType: "group",
                  label: "Column Settings",
                  visibleIndex: 1,
                  caption: "Column Settings",
                  colSpan: 1,
                  colCount: 2,
                  items: response.Meta.AllColumnOptions.map((v, k) => {
                    if (v.Display !== "Enable Quick Grid Mode")
                      return {
                        dataField: v.Display,
                        label: { visible: false },
                        editorType: "dxCheckBox",
                        helpText: `${
                          v.Display === "AutoResize"
                            ? "Automatically resize the grid columns based on a sampling of the data returned."
                            : "When this grid view is saved with a grouped column expand all groups."
                          }`,
                        colSpan: 1,
                        editorOptions: <DevExpress.ui.dxCheckBoxOptions>{
                          value:
                            response.ViewSettings.ColumnOptions.filter(
                              (val, key) => {
                                return v.ID === val.ID;
                              }
                            ).length === 1,
                          name: v.ID.toString(),
                          text: captionize(v.Display),
                          onValueChanged(e) {
                            gm.SetColumnOptionsVal();
                          }
                        }
                      };
                  }).filter(item => {
                    return item;
                  })
                },
                {
                  itemType: "group",
                  label: "Show In Toolbar",
                  visibleIndex: 2,
                  caption: "Show In Toolbar",
                  colSpan: 1,
                  colCount: 2,
                  items: response.Meta.AllToolbarOptions.map((v, k) => {
                    return {
                      dataField: v.Display,
                      label: { visible: false },
                      editorType: "dxCheckBox",
                      helpText: `When enabled this button will be visible in the grid toolbar.`,
                      colSpan: 1,
                      editorOptions: <DevExpress.ui.dxCheckBoxOptions>{
                        value:
                          response.ViewSettings.ToolbarOptions.filter(
                            (val, key) => {
                              return v.Value === val.ID;
                            }
                          ).length === 1,
                        name: v.Value.toString(),
                        text: captionize(v.Display),
                        onValueChanged(e) {
                          gm.SetToolbarOptionsVal();
                        }
                      }
                    };
                  }).filter(item => {
                    return item;
                  })
                },
                {
                  itemType: "group",
                  label: "Show Active/Inactive Items",
                  caption: "Show Active/Inactive Items",
                  visibleIndex: 3,
                  colSpan: 1,
                  colCount: 2,
                  items: response.Meta.AllTDFActiveOptions.map((v, k) => {
                    return {
                      dataField: v.Display,
                      label: { visible: false },
                      editorType: "dxCheckBox",
                      helpText: `Include ${
                        v.Display.search(/inactive/i) >= 0
                          ? "Inactive"
                          : "Active"
                        } items.`,
                      colSpan: 1,
                      editorOptions: <DevExpress.ui.dxCheckBoxOptions>{
                        value:
                          response.ViewSettings.ActiveOptions.filter(
                            (val, key) => {
                              return v.Value === val.ID;
                            }
                          ).length === 1,
                        text: captionize(v.Display),
                        name: v.Value.toString(),
                        onValueChanged(e) {
                          gm.SetActiveOptionsVal();
                        }
                      }
                    };
                  }).filter(item => {
                    return item;
                  })
                },
                {
                  itemType: "group",
                  label: "Paging Options",
                  visible: false,
                  caption: "Paging Options",
                  visibleIndex: 4,
                  colSpan: 1,
                  colCount: 1,
                  items: [
                    {
                      dataField: "PageSize",
                      label: "Items per page",
                      editorType: "dxNumberBox",
                      helpText: "Desktop Setting",
                      editorOptions: <DevExpress.ui.dxNumberBoxOptions>{
                        step: 50,
                        onValueChanged(e) {
                          gm.SetGeneralSettingDirty("PageSize", e.value);
                          // gm.GenSettingsDirtyBag["PageSize"] = e.value;
                        }
                      }
                    }
                  ]
                }
              ]
            },
            <DevExpress.ui.dxFormGroupItem>{
              itemType: "group",
              colSpan: 1,
              colCount: 2,
              items: [
                {
                  itemType: "group",
                  label: "Default Date Range",
                  caption: "Default Date Range",
                  colSpan: 2,
                  visibleIndex: 0,
                  colCount: 6,
                  items: <DevExpress.ui.dxFormSimpleItem[]>[
                    {
                      dataField: "Operation",
                      label: { visible: false },
                      editorType: "dxSelectBox",
                      helpText: "Select Operation",
                      editorOptions:
                        dateRangeFilterEditor._operationSelectorOptions,
                      colSpan: 2
                    },
                    {
                      itemType: "empty",
                      colSpan: 4
                    },
                    {
                      editorType: "dxDropDownBox",
                      colSpan: 6,
                      editorOptions: dateRangeFilterEditor.DropDownBoxOptions
                    },
                    {
                      editorType: "dxList",
                      colSpan: 6,
                      editorOptions: <DevExpress.ui.dxListOptions>{
                        dataSource: response.ViewSettings.DateFilters,
                        allowItemDeleting: true,
                        noDataText: "No date filters saved.",
                        onItemDeleted(e) {
                          let service = new RelativeDateFilterDataService();
                          let settings = service
                            .SettingsForView(gm._view.GUID)
                            .done(settings => {
                              let index = -1;
                              let filter = settings.DateFilters.forEach(
                                (v, k) => {
                                  if (v.FilterDetails === e.itemData) {
                                    index = k;
                                  }
                                }
                              );
                              if (index !== -1) {
                                settings.DateFilters.splice(index, 1);
                              }
                              let saveopts: IDateRangeSaveOptions = {
                                CombinePattern: settings.CombinePattern,
                                CombineType: settings.CombineType,
                                DateFilters: settings.DateFilters
                              };
                              gm.SetDateFilterVal(saveopts);
                            });
                        },
                        onInitialized(e) {
                          AddHandler(
                            EventTypes.CenterEventTypes.relativedatefilter,
                            eventNameSpace.notify,
                            e.element,
                            (ev, d: IDateRangeSaveOptions) => {
                              let items = d.DateFilters.map((v, k) => {
                                return v.FilterDetails;
                              });
                              e.component.option("items", items);
                              gm.SetDateFilterVal(d);
                            }
                          );
                        }
                      }
                    }
                  ]
                },
                {
                  itemType: "group",
                  label: "Item Specific Preferences",
                  caption: "Item Specific Preferences",
                  colSpan: 1,
                  visibleIndex: 1,
                  colCount: 2,
                  items: response.ViewSettings.ItemSpecific.AvailableItems.map(
                    v => {
                      return {
                        dataField: v.Value,
                        label: { visible: false },
                        editorType: "dxCheckBox",
                        helpText: `When checked the data will ${captionize(v.Value)
                          .toLocaleUpperCase()}`,
                        colSpan: 1,
                        editorOptions: <DevExpress.ui.dxCheckBoxOptions>{
                          value:
                            response.ViewSettings.ItemSpecific.EnabledItems.filter(
                              (val, key) => {
                                return v.Key === val.Key;
                              }
                            ).length === 1,
                          name: v.Key.toString(),
                          text: captionize(v.Value),
                          onValueChanged(e) {
                            let optionid = parseInt(e.component.option("name"));

                            if (
                              response.ViewSettings.ItemSpecific.EnabledItems.filter(
                                (setval, k) => {
                                  return setval.Key === optionid;
                                }
                              ).length > 0 &&
                              !e.value
                            ) {
                              //gm.GenSettingsDirtyBag[v.Value] = false;
                              gm.SetGeneralSettingDirty("ItemSpecific", 0);
                            } else {
                              gm.SetGeneralSettingDirty("ItemSpecific", 1);
                              //gm.GenSettingsDirtyBag[v.Value] = true;
                            }
                          }
                        }
                      };
                    }
                  ).filter(item => {
                    return item;
                  })
                },
                {
                  itemType: "empty",
                  colSpan: 1,
                  visibleIndex: 2
                },
                {
                  itemType: "group",
                  label: "Auto Refresh Interval",
                  caption: "Auto Refresh Interval",
                  colSpan: 1,
                  visibleIndex: 3,
                  colCount: 2,

                  items: [
                    {
                      dataField: "Refresh Interval",
                      editorType: "dxNumberBox",
                      label: { visible: false },
                      editorOptions: <DevExpress.ui.dxNumberBoxOptions>{
                        step: 10,
                        showClearButton: true,
                        onValueChanged(e) {
                          gm.SetGeneralSettingDirty(
                            "AutoRefreshInterval",
                            e.value
                          );
                        }
                      },
                      helpText: "Enter 0 or leave empty for never."
                    }
                  ]
                },
                { itemType: "empty", colSpan: 1, visibleIndex: 4 }
              ]
            }
          ]
        });
      });
  }

  private _allCols: DevExpress.ui.dxListItemTemplate[];

  get CombineType(): string {
    let gm = this;
    return gm.GeneralSettingsForm.FormObject.getEditor("Operation").option(
      "value"
    );
  }

  private SetDateFilterVal(val: IDateRangeSaveOptions) {
    let gm = this;
    if (!gm.SettingsDirtyBag.General) {
      gm.SettingsDirtyBag.General = {};
    }

    if (
      gm.SettingsDirtyBag.General &&
      gm.SettingsDirtyBag.General["DateFilter"]
    ) {
      if (
        gm.SettingsDirtyBag.General["DateFilter"].CombineType ===
        val.CombineType &&
        val.CombinePattern ===
        gm.SettingsDirtyBag.General["DateFilter"].CombinePattern
      ) {
        gm.SettingsDirtyBag.General["DateFilter"].DateFilters = val.DateFilters;
      }
    } else {
      gm.SetGeneralSettingDirty("DateFilter", val);
    }
    gm.EnableButtons();
  }

  private SetActiveOptionsVal() {
    let gm = this;

    let active: DevExpress.ui.dxCheckBox = gm.GeneralSettingsForm.FormObject.getEditor(
      "Active"
    );

    let inactive: DevExpress.ui.dxCheckBox = gm.GeneralSettingsForm.FormObject.getEditor(
      "Inactive"
    );
    let val: number = 0;
    if (active.option("value")) val = val | EnumGridOptionsTDFActive.Active;
    if (inactive.option("value")) val = val | EnumGridOptionsTDFActive.Inactive;

    gm.SetGeneralSettingDirty("ActiveOptions", val);
  }

  private SetToolbarOptionsVal() {
    let gm = this;
    //  EnumGridOptionsToolbar.CanPrint
    let print: DevExpress.ui.dxCheckBox = gm.GeneralSettingsForm.FormObject.getEditor(
      "Print Button"
    );
    // EnumGridOptionsToolbar.CanForward
    let forward: DevExpress.ui.dxCheckBox = gm.GeneralSettingsForm.FormObject.getEditor(
      "Forward Button"
    );
    //  EnumGridOptionsToolbar.CanNewAppointment
    let appointment: DevExpress.ui.dxCheckBox = gm.GeneralSettingsForm.FormObject.getEditor(
      "New Appointment Button"
    );
    // EnumGridOptionsToolbar.CanExport
    let cexport: DevExpress.ui.dxCheckBox = gm.GeneralSettingsForm.FormObject.getEditor(
      "Export Grid button"
    );
    // EnumGridOptionsToolbar.CanNewActivity
    let activity: DevExpress.ui.dxCheckBox = gm.GeneralSettingsForm.FormObject.getEditor(
      "New Activity Button"
    );
    // EnumGridOptionsToolbar.CanNewTask
    let task: DevExpress.ui.dxCheckBox = gm.GeneralSettingsForm.FormObject.getEditor(
      "New Task Button"
    );

    let val: number = 0;

    if (print.option("value")) val = val | EnumGridOptionsToolbar.CanPrint;
    if (forward.option("value")) val = val | EnumGridOptionsToolbar.CanForward;
    if (appointment.option("value"))
      val = val | EnumGridOptionsToolbar.CanNewAppointment;
    if (cexport.option("value")) val = val | EnumGridOptionsToolbar.CanExport;
    if (activity.option("value"))
      val = val | EnumGridOptionsToolbar.CanNewActivity;
    if (task.option("value")) val = val | EnumGridOptionsToolbar.CanNewTask;
    gm.SetGeneralSettingDirty("ToolbarOptions", val);
  }

  private SetColumnOptionsVal() {
    let gm = this;
    //  EnumGridOptionsColumn.QuickGridMode
    let QGrid: DevExpress.ui.dxCheckBox = gm.GeneralSettingsForm.FormObject.getEditor(
      "Enable Quick Grid Mode"
    );
    //  EnumGridOptionsColumn.AutoResize
    let Resize: DevExpress.ui.dxCheckBox = gm.GeneralSettingsForm.FormObject.getEditor(
      "AutoResize"
    );
    // EnumGridOptionsColumn.ExpandAllGroups
    let Groups: DevExpress.ui.dxCheckBox = gm.GeneralSettingsForm.FormObject.getEditor(
      "ExpandAllGroups"
    );

    let val: number = 0;

    if (QGrid.option("value")) val = val | EnumGridOptionsColumn.QuickGridMode;
    if (Resize.option("value")) val = val | EnumGridOptionsColumn.AutoResize;
    if (Groups.option("value"))
      val = val | EnumGridOptionsColumn.ExpandAllGroups;

    gm.SetGeneralSettingDirty("ColumnOptions", val);
  }

  private SetGeneralSettingDirty(propname, val) {
    let gm = this;
    if (!gm.SettingsDirtyBag.General) {
      gm.SettingsDirtyBag.General = {};
    }
    gm.SettingsDirtyBag.General[propname] = val;
    gm.EnableButtons();
  }

  private EnableButtons() {
    let gm = this;
    gm._dialog.Instance.option("toolbarItems[0].disabled", false);
    gm._dialog.Instance.option("toolbarItems[1].disabled", false);
  }

  get AllColumnsDataSource(): DevExpress.data.DataSource {
    let gm = this;
    let options: DevExpress.data.DataSourceOptions = {
      requireTotalCount: true,
      store: new dxCustomStore({
        loadMode: "raw",

        load(opts) {
          let d = $.Deferred();
          if (gm._allCols && gm._allCols.length)
            return d.promise(d.resolve(gm._allCols));
          gm._managerDataService
            .ColumnsForView()
            .done((response: ViewColumnSetting) => {
              let columns = response.AllColumns.map((v, k) => {
                let viscol = false;
                viscol = response.VisibleColumns.some((val, k) => {
                  return val.Name === v;
                });
                return <DevExpress.ui.dxListItemTemplate>{
                  text: v,
                  visible: !viscol
                };
              });
              gm._allCols = columns;
              d.resolve(gm._allCols);
            });
          return d.promise();
        },
        byKey(key) {
          let d = $.Deferred();
          return d.promise(
            d.resolve(
              gm._allCols.filter((v, k) => {
                return v.text === key;
              })
            )
          );
        },

        update(key, vals) {
          let d = $.Deferred();
          gm._allCols.forEach((v, k) => {
            if (v.text === key.text) {
              Object.keys(vals).forEach((val, key) => {
                v[val] = vals[val];
              });
              d.resolve(gm._allCols);
            } else {
              return d.promise(d.resolve());
            }
          });
          return d.promise();
        }
      }),
      paginate: false,
      /*Sort alphabetically on the column name.*/
      sort: [{ selector: "text", desc: false }]
    };
    return new dxDataSource(options);
  }
  private _VisCols: DevExpress.ui.dxListItemTemplate[];
  get VisibleColumnsDataSource(): DevExpress.data.DataSource {
    let gm = this;
    let options: DevExpress.data.DataSourceOptions = {
      requireTotalCount: true,
      store: new dxCustomStore({
        loadMode: "raw",
        load(opts) {
          let d = $.Deferred();
          if (gm._VisCols && gm._VisCols.length)
            return d.promise(d.resolve(gm._VisCols));
          gm._managerDataService
            .ColumnsForView()
            .done((response: ViewColumnSetting) => {
              gm._VisCols = response.VisibleColumns.map((v, k) => {
                return {
                  text: `${v.Name}`,
                  VisibleIndex: v.VisibleIndex,
                  visible: $.inArray(v.Name, ["TDF GUID", "ItemType"]) < 0
                };
              });
              d.resolve(gm._VisCols);
            });
          return d.promise();
        },

        byKey(key) {
          let d = $.Deferred();
          return d.promise(
            d.resolve(
              gm._VisCols.filter((v, k) => {
                return v.text === key;
              })
            )
          );
        },
        insert(val) {
          let d = $.Deferred();
          gm._VisCols.push(val);
          gm.SetColumnVisibilitySetting();

          return d.promise(d.resolve());
        },
        remove(key) {
          let d: JQueryDeferred<void> = $.Deferred();
          let index = -1;
          gm._VisCols.forEach((v, k) => {
            if (v.text === key.text) {
              index = k;
            }
          });
          if (index !== -1) {
            gm._VisCols.splice(index, 1);
            (gm.AllColumnsDataSource.store() as DevExpress.data.CustomStore)
              .update(key, { visible: true })
              .done(() => {
                let allfields: DevExpress.ui.dxList = gm.ColumnSettingsForm.FormObject.getEditor(
                  "AllFields"
                );
                allfields.reload();

                d.resolve();
                gm.SetColumnVisibilitySetting();
              });
          } else {
            return d.promise(d.resolve());
            //	gm.SetColumnVisibilitySetting()
          }
          return d.promise();
        }
      }),
      paginate: false,
      /*Sort on the visble index so we know when the order is changed*/
      sort: [{ selector: "VisibleIndex", desc: false }]
    };
    return new dxDataSource(options);
  }

  private SetColumnVisibilitySetting(reload = true) {
    let gm = this;
    let ordereditems = [];
    let viz = gm.ColumnSettingsForm.FormObject.getEditor(
      "VisibleFields"
    ) as DevExpress.ui.dxList;
    if (reload) viz.reload();
    gm.ColumnSettingsForm.FormObject.getEditor("VisibleFields")
      .option("items")
      .forEach((v, k) => {
        ordereditems.push(v.text);
      });
    gm.SettingsDirtyBag.Columns = { VisibleColumns: ordereditems };

    gm.EnableButtons();
  }

  private ColumnVisibilitySettings(el: JQuery) {
    let gm = this;
    gm.ColumnSettingsForm = new Form(el.append($("<div />")), {
      colCount: 2,
      height: el.outerHeight(),
      items: [
        <DevExpress.ui.dxFormGroupItem>{
          itemType: "group",
          colSpan: 1,
          caption: "Available Fields",
          label: "Available Fields",
          items: [
            // dxlist is no longer oficially supported by dxform ... but it still works
            <any>{
              editorType: "dxList",
              dataField: "AllFields",
              label: { text: "", visible: false },
              editorOptions: <DevExpress.ui.dxListOptions>{
                selectionMode: "single",
                searchEnabled: true,
                searchMode: "contains",
                searchExpr(d) {
                  return d.text;
                },
                dataSource: gm.AllColumnsDataSource,
                height: el.outerHeight() * 0.8,
                onSelectionChanged(e) {
                  if (e.addedItems && e.addedItems.length) {
                    let ds = e.component.getDataSource();
                    (ds.store() as DevExpress.data.CustomStore)
                      .update(e.addedItems[0], { visible: false })
                      .done(() => {
                        ds.reload().done(() => {
                          (gm.VisibleColumnsDataSource.store() as DevExpress.data.CustomStore)
                            .insert({
                              text: e.addedItems[0].text,
                              VisibleIndex:
                                gm.VisibleColumnsDataSource.totalCount
                            })
                            .done(() => {
                              (gm.ColumnSettingsForm.FormObject.getEditor(
                                "VisibleFields"
                              ) as DevExpress.ui.dxList).reload();
                            });
                        });
                      });
                  }
                }
              },
              helpText:
                "Select fields above to add to the visible fields. Fields are added to the end of the visible fields list when selected and will no longer appear in this list."
            }
          ]
        },
        <DevExpress.ui.dxFormGroupItem>{
          itemType: "group",
          colSpan: 1,
          label: { text: "", visible: false },

          caption: "Visible Fields",
          items: [
            // dxlist is no longer oficially supported by dxform ... but it still works
            <any>{
              label: { text: "", visible: false },
              editorType: "dxList",
              dataField: "VisibleFields",
              editorOptions: <DevExpress.ui.dxListOptions>{
                onItemReordered(e) {
                  gm.SetColumnVisibilitySetting(false);
                },
                allowItemReordering: true,
                allowItemDeleting: true,
                selectionMode: "single",
                searchEnabled: true,
                searchMode: "contains",
                searchExpr(d) {
                  return d.text;
                },
                dataSource: gm.VisibleColumnsDataSource,
                height: el.outerHeight() * 0.8
              },
              helpText: `Fields above will be visible in the grid in the order they appear here.
					       To change the order use the drag handle on the far right or select a field and hold the shift key while using the up/down arrow.
					       Use the remove [X] button to move the field to the non-visible available fields list.
				    `
            }
          ]
        },
        { itemType: "empty" },
        { itemType: "empty" }
      ]
    });
  }

  private ColumnFormatSettings(el: JQuery) {
    let gm = this;

    gm._managerDataService
      .FormatSettingsForView()
      .done((response: ViewFormatSettings) => {
        gm.FormatSettingsForm = new Form(el.append($("<div />")), {
          colCount: 2,
          height: el.outerHeight(),
          items: [
            <DevExpress.ui.dxFormGroupItem>{
              itemType: "group",
              colSpan: 1,
              label: "Available Fields",
              caption: "Available Fields",
              items: [
                // dxlist is no longer oficially supported by dxform ... but it still works
                <any>{
                  editorType: "dxList",
                  helpText:
                    "Choose a data field in this list and edit the formatting options on the right. **Only visible fields are shown**",
                  dataField: "Available Fields",
                  label: { text: "", visible: false },
                  editorOptions: <DevExpress.ui.dxListOptions>{
                    height: el.outerHeight() * 0.8,
                    selectionMode: "single",
                    searchEnabled: true,
                    searchMode: "contains",
                    searchExpr(d) {
                      return d.text;
                    },
                    dataSource: new dxDataSource({
                      store: response.VisibleFields.map((v, k) => {
                        return { text: `${v.Name}`, data: v };
                      }),
                      paginate: false,
                      sort: [{ selector: "text", desc: false }]
                    }),
                    onSelectionChanged(e) {
                      let field = e.addedItems[0].text;
                      gm.FormatSettingsForm.FormObject.beginUpdate();
                      gm._managerDataService
                        .ColumnFormatSettings(field)
                        .done((response: ColumnFormat) => {
                          let format: DevExpress.ui.dxSelectBox = gm.FormatSettingsForm.FormObject.getEditor(
                            "Format"
                          );
                          format.option(
                            "items",
                            response.Meta.FormatStringExamples || []
                          );
                          //format.option("value", response.Settings.StringFormatType);
                          let alignment: DevExpress.ui.dxSelectBox = gm.FormatSettingsForm.FormObject.getEditor(
                            "Alignment"
                          );
                          alignment.option(
                            "items",
                            response.Meta.AllAlignments || []
                          );
                          //alignment.option({ dataSource: response.Meta.AllAlignments, value: response.Settings.ColumnAlignment });

                          gm.FormatSettingsForm.FormObject.updateData({
                            Caption:
                              response.Settings.Caption ||
                              response.Settings.FieldName ||
                              e.addedItems[0].text,
                            Tooltip: response.Settings.Tooltip,
                            "Allow Sizing": response.Settings.AllowSize,
                            "Show Preview Row": response.Settings.ShowPreview,
                            Format: response.Settings.StringFormatType,
                            Alignment: response.Settings.ColumnAlignment
                          });

                          gm.FormatSettingsForm.FormObject.endUpdate();
                        });
                    }
                  }
                }
              ]
            },
            <DevExpress.ui.dxFormGroupItem>{
              itemType: "group",
              colSpan: 1,
              name: "Field",
              caption: "Field",
              items: [
                {
                  itemType: "group",
                  name: "Formatting",
                  label: "Formatting Options",
                  caption: "Formatting Options",
                  items: [
                    {
                      dataField: "Format",
                      editorType: "dxSelectBox",
                      helpText: "Choose a format from these examples.",
                      editorOptions: <DevExpress.ui.dxSelectBoxOptions>{
                        displayExpr: "Example",
                        valueExpr: "FormatString",

                        onValueChanged: e => {
                          gm.SetFormatDirty(e.value, "StringFormatType");
                        }
                      }
                    },
                    {
                      dataField: "Caption",
                      helpText: "The text that will show in the field header.",
                      editorType: "dxTextBox",
                      editorOptions: {
                        value: "",
                        onValueChanged: e => {
                          gm.SetFormatDirty(e.value, "Caption");
                        }
                      }
                    },
                    {
                      dataField: "Tooltip",
                      editorType: "dxTextBox",
                      helpText:
                        "The message that will appear when a user pauses the cursor on the field header.",
                      editorOptions: {
                        value: "",
                        onValueChanged: e => {
                          gm.SetFormatDirty(e.value, "Tooltip");
                        }
                      }
                    },
                    {
                      editorType: "dxSelectBox",
                      dataField: "Alignment",
                      helpText: "The alignment of the data in the field.",
                      editorOptions: <DevExpress.ui.dxSelectBoxOptions>{
                        displayExpr: "Display",
                        valueExpr: "ID",
                        onValueChanged: e => {
                          gm.SetFormatDirty(e.value, "ColumnAlignment");
                        }
                      }
                    }
                  ]
                },
                {
                  itemType: "group",

                  label: "Sizing",
                  caption: "Sizing",
                  items: [
                    {
                      dataField: "Width",
                      helpText: "?",
                      disabled: true,
                      visible: false
                    },
                    {
                      dataField: "Allow Sizing",
                      editorType: "dxCheckBox",
                      helpText: "Allow this field to be resized.",
                      editorOptions: {
                        value: true,
                        onValueChanged: e => {
                          gm.SetFormatDirty(e.value, "AllowSize");
                        }
                      }
                    }
                  ]
                },
                {
                  itemType: "group",
                  label: "Preview Row",
                  caption: "Preview Row",
                  items: [
                    {
                      dataField: "Show Preview Row",
                      helpText: `When enabled the data value for this field will appear in a preview row below the row in the grid. Helpful for fields with a large amount of text. **Desktop version only**`,
                      editorType: "dxCheckBox",
                      editorOptions: {
                        value: false,
                        onValueChanged: e => {
                          gm.SetFormatDirty(e.value, "ShowPreview");
                        }
                      }
                    }
                  ]
                }
              ]
            }
          ]
        });
      });
  }

  SetFormatDirty(value, property) {
    let gm = this;
    if (
      gm.FormatSettingsForm.FormObject &&
      gm.FormatSettingsForm.FormObject.getEditor("Available Fields") &&
      gm.FormatSettingsForm.FormObject.getEditor("Available Fields").option(
        "selectedItems"
      ).length
    ) {
      let field = gm.FormatSettingsForm.FormObject.getEditor(
        "Available Fields"
      ).option("selectedItems")[0].data.Name;
      let settings = gm.SettingsDirtyBag.Formatting
        ? gm.SettingsDirtyBag.Formatting[field]
        : undefined;
      if (typeof settings === "undefined") {
        gm._managerDataService
          .ColumnFormatSettings(field)
          .done((response: ColumnFormat) => {
            if (
              typeof response.Settings[property] !== "undefined" &&
              response.Settings[property] !== value
            ) {
              response.Settings[property] = value;
              if (!gm.SettingsDirtyBag.Formatting) {
                gm.SettingsDirtyBag.Formatting = [response.Settings];
              } else {
                gm.SettingsDirtyBag.Formatting.push(response.Settings);
              }
              //gm.SettingsDirtyBag.Formatting = {
              //    [field]: response.Settings
              //};
              gm.EnableButtons();
            }
          });
      } else {
        if (
          typeof settings[property] !== "undefined" &&
          settings[property] !== value
        ) {
          settings[property] = value;
        }
      }
    }
  }

  private FilterFormItems(
    field: string,
    el: JQuery,
    currentfilter,
    columns: ViewColumnSetting
  ): JQueryPromise<DevExpress.ui.dxFormSimpleItem[]> {
    let gm = this;
    let d: JQueryDeferred<DevExpress.ui.dxFormSimpleItem[]> = $.Deferred();

    let helptext = {
      ServerSideFilter:
        "Define SERVER-SIDE filters below. These filters will be applied BEFORE the data is dowloaded to the user's computer.",
      ParentFilter:
        "Define PARENT SERVER-SIDE filters below. These filters will be applied to the PARENT ACCOUNT data for relevant items.",
      ClientSideFilter:
        "Define CLIENT-SIDE filters below. These filters will be applied AFTER the data is dowloaded to the user's computer."
    };

    if (
      field === "ClientSideFilter" &&
      gm.grid /*&& gm.grid.option("filterValue")*/
    ) {
      currentfilter = gm.grid.option("filterValue");
    }

    gm.getFilterableFields(columns, field === "ParentFilter").done(fields => {
      let items = <DevExpress.ui.dxFormSimpleItem[]>[
        {
          itemType: "simple",
          label: { visible: false },
          editorType: "dxTextBox",
          editorOptions: <DevExpress.ui.dxTextBoxOptions>{
            value: helptext[field],
            disabled: true,
            elementAttr: { style: "border:none;opacity:1;" }
          }
        },
        {
          itemType: "simple",
          label: { text: field, visible: false },
          dataField: field,
          helpText: helptext[field],
          editorType: "dxFilterBuilder",
          editorOptions: <DevExpress.ui.dxFilterBuilderOptions>{
            height: el.height() * 0.7, //"90%",//GetDevice().isDevice ? 300 : 500,
            customOperations: [gm.Anyof(), gm.Noneof()],
            fields: fields,
            elementAttr: { style: " padding:10px; ", class: "shadow" },

            filterOperationDescriptions: {
              between: "Is between",
              contains: "Contains",
              endsWith: "Ends with",
              equal: "Equals",
              greaterThan: "Is greater than",
              greaterThanOrEqual: "Is greater than or equal to",
              isBlank: "Is blank",
              isNotBlank: "Is not blank",
              lessThan: "Is less than",
              lessThanOrEqual: "Is less than or equal to",
              notContains: "Does not contain",
              notEqual: "Does not equal",
              startsWith: "Starts with",
              endswith: "Ends with",
              greaterthan: "Is greater than",
              greaterthanorequal: "Is greater than or equal to",
              isblank: "Is blank",
              isnotblank: "Is not blank",
              lessthan: "Is less than",
              lessthanorequal: "Is less than or equal to",
              notcontains: "Does not contain",
              notequal: "Does not equal",
              startswith: "Starts with",
              Anyof: "Is any of",
              Noneof: "Is none of",
              anyof: "Is any of",
              noneof: "Is none of"
            },
            //customOperations: (gm.grid as any).getCustomFilterOperations(),

            value: currentfilter ? currentfilter : "",

            onValueChanged(e) {
              if (
                !(JSON.stringify(e.value) === JSON.stringify(e.previousValue))
              ) {
                FilterBuilderHelper
                  .getFilterText(
                    e.component.getFilterExpression(),
                    [gm.Anyof(), gm.Noneof()],
                    e.component.option("fields"),
                    "filterBuilder"
                  )
                  .done(ftext => {
                    gm[
                      `${field.replace(/Side/i, "")}SettingsForm`
                    ].FormObject.getEditor(`${field}-currentText`).option(
                      "value",
                      ftext
                    );
                    ftext === "" ? (ftext = "Delete") : ftext;
                    gm.SetFilterDirty(field, ftext);
                  });
              }
            }
          }
        },
        {
          itemType: "simple",
          editorType: "dxTextArea",
          dataField: `${field}-currentText`,
          label: { text: "Current Filter Text", visible: true },
          editorOptions: <DevExpress.ui.dxTextAreaOptions>{
            // value: currentfilterText,
            height: el.height() * 0.15,
            disabled: true
          }
        }
      ];

      if (field === "ServerSideFilter") {
        items.unshift({
          itemType: "simple",
          editorType: "dxCheckBox",
          dataField: "OwnerFilter",
          label: { text: "", visible: false },
          editorOptions: <DevExpress.ui.dxCheckBoxOptions>{
            text: "Owner",
            onValueChanged(e) {
              if (
                typeof gm._managerDataService.UseOwnerFilter !== "undefined" &&
                gm._managerDataService.UseOwnerFilter !== e.value
              )
                gm.SetOwnerFilterDirty(e.value);
              //e.value
            }
          }
        });
        gm._managerDataService.OwnerFilter().done(response => {
          gm.ServerFilterSettingsForm.FormObject.getEditor(
            "OwnerFilter"
          ).option("value", response);
        });
      }

      d.resolve(items);
    });

    return d.promise();
  }

  private SetOwnerFilterDirty(val) {
    let gm = this;
    if (gm.SettingsDirtyBag["ServerSideFilter"]) {
      if (
        typeof gm.SettingsDirtyBag["ServerSideFilter"]["OwnerFilter"] !==
        "undefined"
      ) {
        gm.SettingsDirtyBag.ServerSideFilter["OwnerFilter"] = val;
      } else {
        if (
          typeof gm.SettingsDirtyBag["ServerSideFilter"]["Filter"] !==
          "undefined"
        ) {
          gm.SettingsDirtyBag["ServerSideFilter"] = {
            Filter: gm.SettingsDirtyBag["ServerSideFilter"]["Filter"],
            OwnerFilter: val
          };
        } else {
          if (
            gm.ServerFilterSettingsForm.FormObject.getEditor(
              "ServerSideFilter"
            ).option("value")
          ) {
            FilterBuilderHelper
              .getFilterText(
                gm.ServerFilterSettingsForm.FormObject.getEditor(
                  "ServerSideFilter"
                ).option("value"),
                [gm.Anyof(), gm.Noneof()],
                gm.ServerFilterSettingsForm.FormObject.getEditor(
                  "ServerSideFilter"
                ).option("fields"),
                "filterBuilder"
              )
              .done(text => {
                gm.SettingsDirtyBag.ServerSideFilter = {
                  OwnerFilter: val,
                  Filter: text
                };
              });
          }
        }
      }
    } else {
      if (
        gm.ServerFilterSettingsForm.FormObject.getEditor(
          "ServerSideFilter"
        ).option("value")
      ) {
        FilterBuilderHelper
          .getFilterText(
            gm.ServerFilterSettingsForm.FormObject.getEditor(
              "ServerSideFilter"
            ).option("value"),
            [gm.Anyof(), gm.Noneof()],
            gm.ServerFilterSettingsForm.FormObject.getEditor(
              "ServerSideFilter"
            ).option("fields"),
            "filterBuilder"
          )
          .done(text => {
            gm.SettingsDirtyBag.ServerSideFilter = {
              OwnerFilter: val,
              Filter: text
            };
          });
      } else {
        gm.SettingsDirtyBag["ServerSideFilter"] = { OwnerFilter: val };
      }
    }
    gm.EnableButtons();
  }

  private SetFilterDirty(prop, val) {
    let gm = this;
    if (!gm.SettingsDirtyBag[prop]) {
      gm.SettingsDirtyBag[prop] = { Filter: val };
    } else {
      if (gm.SettingsDirtyBag[prop]["Filter"]) {
        gm.SettingsDirtyBag[prop]["Filter"] = val;
      }
    }
    gm.EnableButtons();
  }

  private Anyof(): DevExpress.ui.dxFilterBuilderCustomOperation {
    let gm = this;
    return AnyOf(gm._ColumnsHash, gm._view);
  }

  private Noneof(): DevExpress.ui.dxFilterBuilderCustomOperation {
    let gm = this;
    return NonOf(gm._ColumnsHash, gm._view);
  }

  private getFilterableFields(
    columns: ViewColumnSetting,
    parentFilter: boolean = false
  ): JQueryPromise<DevExpress.ui.dxFilterBuilderField[]> {
    let gm = this;

    let d: JQueryDeferred<DevExpress.ui.dxFilterBuilderField[]> = $.Deferred();
    ///TODO: I need to just get the view layout so i know what columns can have what operations
    if (!parentFilter) {
      return d.promise(
        d.resolve(
          gm.grid && gm.grid.option("columns")
            ? (columns.AllColumns
              //only get the items that are not already specified as visible columns
              .filter((v, k) => {
                return !gm._ColumnsHash[
                  v
                    .replace(/\./g, PeriodReplacementGUID)
                    .replace(/\:/g, ColonReplacementGUID)
                ];
              })
              .map(item => {
                return {
                  dataField: item
                };
              })
              .concat(
                this.grid.option("columns").filter((v, k) => {
                  return (
                    v["dataField"] &&
                    $.inArray(v["dataField"], ["TDF GUID"]) < 0
                  );
                })
              ) as any[])
              .map(col => {
                let filterops: any[];
                if (gm._ColumnsHash[col.dataField]) {
                  switch (gm._ColumnsHash[col.dataField].dataType) {
                    case "string":
                      filterops = [
                        "contains",
                        "notcontains",
                        "startswith",
                        "endswith",
                        "=",
                        "<>",
                        "anyof",
                        "noneof",
                        "isblank",
                        "isnotblank"
                      ];
                      break;
                    case "datetime":
                      filterops = [
                        "=",
                        "<>",
                          /*"<", ">", "<=", ">=", "between",*/ "isblank",
                        "isnotblank"
                      ];
                      break;
                    case "number":
                      filterops = [
                        "=",
                        "<>",
                        "<",
                        ">",
                        "<=",
                        ">=",
                        "between",
                        "isblank",
                        "isnotblank",
                        "anyof",
                        "noneof"
                      ];
                      break;
                  }
                }
                col.dataField = col.dataField
                  .replace(ColonReplacementGUID, ":")
                  .replace(PeriodReplacementGUID, ".");

                if (filterops) {
                  col.filterOperations = filterops;
                }
                return col;
              })
              .sort(
                (
                  a: {
                    dataField: string;
                    caption: string;
                  },
                  b: {
                    dataField: string;
                    caption: string;
                  }
                ) => {
                  if (!a.dataField) {
                    debugger;
                  }
                  if (!b.dataField) {
                    debugger;
                  }

                  if (a.dataField.toLowerCase() < b.dataField.toLowerCase())
                    return -1;
                  if (a.dataField.toLowerCase() > b.dataField.toLowerCase())
                    return 1;

                  return 0;
                  //return a.dataField.localeCompare(b.dataField,Globalize.locale().locale, {sensitivity:"base"} )
                }
              )
            : columns.AllColumns.map(v => {
              return { dataField: v };
            })
        )
      );
    } else {
      gm._managerDataService
        .GetParentAccountFilterFields()
        .MakeRequest()
        .done(response => {
          d.resolve(
            response
              .map(item => {
                item["dataField"] = item.Name;
                return item;
              })
              .filter((v, k) => {
                return (
                  v["dataField"] && $.inArray(v["dataField"], ["TDF GUID"]) < 0
                );
              })
              .map(col => {
                let filterops: any[];

                switch (col.dataType.toLowerCase()) {
                  case "string":
                    filterops = [
                      "contains",
                      "notcontains",
                      "startswith",
                      "endswith",
                      "=",
                      "<>",
                      "anyof",
                      "noneof",
                      "isblank",
                      "isnotblank"
                    ];
                    break;
                  case "datetime":
                    filterops = [
                      "=",
                      "<>",
                      "<",
                      ">",
                      "<=",
                      ">=",
                      "between",
                      "isblank",
                      "isnotblank"
                    ];
                    break;
                  case "number":
                    filterops = [
                      "=",
                      "<>",
                      "<",
                      ">",
                      "<=",
                      ">=",
                      "between",
                      "isblank",
                      "isnotblank"
                    ];
                    break;
                  //default:
                  //	filterops = ["contains", "notcontains", "startswith", "endswith", "=", "<>", "anyof", "noneof", "isblank", "isnotblank"];
                }

                if (filterops) {
                  col.filterOperations = filterops;
                }
                return col;
              })
          );
        });
    }
    return d.promise();
  }

  private ServerSideFilterSettings(el: JQuery) {
    let gm = this;
    gm._managerDataService
      .ViewServer_Converted_FilterSettings()
      .done((response: IConvertedViewFilter) => {
        gm._managerDataService
          .ColumnsForView()
          .done((colSetting: ViewColumnSetting) => {
            gm
              .FilterFormItems(
                "ServerSideFilter",
                el,
                response.Filter,
                colSetting
              )
              .done(items => {
                gm.ServerFilterSettingsForm = new Form(
                  el.append($("<div />")),
                  {
                    colCount: 1,
                    height: el.outerHeight() > 300 ? el.outerHeight() - 20 : 500,
                    items: items
                  }
                );
              });
          });
      });
  }

  private ClientSideFilterSettings(el: JQuery) {
    let gm = this;

    gm._managerDataService
      .ViewClient_Converted_FilterSettings()
      .done((response: IConvertedViewFilter) => {
        gm._managerDataService
          .ColumnsForView()
          .done((colSetting: ViewColumnSetting) => {
            gm
              .FilterFormItems(
                "ClientSideFilter",
                el,
                response.Filter,
                colSetting
              )
              .done(items => {
                gm.ClientFilterSettingsForm = new Form(
                  el.append($("<div />")),
                  {
                    colCount: 1,
                    height: el.outerHeight() > 300 ? el.outerHeight() : 500,
                    items: items
                  }
                );
              });
          });
      });
  }

  private ParentFilterSettings(el: JQuery) {
    let gm = this;

    gm._managerDataService
      .ViewParent_Converted_FilterSettings()
      .done((response: IConvertedViewFilter) => {
        gm._managerDataService
          .ColumnsForView()
          .done((colSetting: ViewColumnSetting) => {
            gm
              .FilterFormItems("ParentFilter", el, response.Filter, colSetting)
              .done(items => {
                gm.ParentFilterSettingsForm = new Form(
                  el.append($("<div />")),
                  {
                    colCount: 1,
                    height: el.outerHeight() > 300 ? el.outerHeight() : 500,
                    items: items
                  }
                );
              });
          });
      });
  }

  private AccountTypeSettings(el: JQuery) {
    let gm = this;
    gm._managerDataService
      .ViewAccountTypeFilterSettings()
      .done((response: AccountTypeSettings) => {
        gm.AccountTypeSettingsForm = new Form(el.append($("<div />")), {
          colCount: 1,
          height: el.outerHeight(),
          items: response.Meta.AvailableValues.map((v, k) => {
            return {
              dataField: v.Display,
              label: { visible: false },
              editorType: "dxCheckBox",
              helpText: "Enable to limit grid data to " + v.Display.replace("Include", "") + ", or their child items",
              colSpan: 1,
              editorOptions: <DevExpress.ui.dxCheckBoxOptions>{
                value:
                  response.UserEnabledValues.filter((val, key) => {
                    return v.ID === val;
                  }).length === 1,
                text: captionize(v.Display),
                onValueChanged(e) {
                  gm.SetAccountTypeSettingsVal();
                }
              }
            };
          }).filter(item => {
            return item;
          })
        });
      });
  }

  private SetAccountTypeSettingsVal() {
    let gm = this;
    let standard: DevExpress.ui.dxCheckBox = gm.AccountTypeSettingsForm.FormObject.getEditor(
      "Include Standard Accounts"
    );
    let sales: DevExpress.ui.dxCheckBox = gm.AccountTypeSettingsForm.FormObject.getEditor(
      "Include Sales Only Accounts"
    );
    let master: DevExpress.ui.dxCheckBox = gm.AccountTypeSettingsForm.FormObject.getEditor(
      "Include Master Accounts"
    );
    let vendor: DevExpress.ui.dxCheckBox = gm.AccountTypeSettingsForm.FormObject.getEditor(
      "Include Vendor Accounts"
    );
    let val: number = 0;
    if (standard.option("value")) val = val | EnumTDFAccountType.Regular;
    if (sales.option("value")) val = val | EnumTDFAccountType.SalesOnly;
    if (master.option("value")) val = val | EnumTDFAccountType.Master;
    if (vendor.option("value")) val = val | EnumTDFAccountType.Vendor;

    gm.SettingsDirtyBag.AccountTypes = val;
    gm.EnableButtons();
  }

  private SummarySettings(el: JQuery) {
    let gm = this;

    gm._managerDataService
      .ViewSummarySettings()
      .done((response: ISummarySettings) => {
        gm.SummarySettingsForm = new Form(el.append($("<div />")), {
          colCount: 1,
          height: el.outerHeight(),
          items: [
            <any>{
              itemType: "simple",
              editorType: "dxDataGrid",
              editorOptions: (editor => {
                let options = DefaultGridOptions;
                options.dataSource = response.FieldList;

                options.onToolbarPreparing = e => {
                  e.toolbarOptions.visible = false;
                };

                (options.height = el.height()),
                  (options.columns = [
                    { dataField: "FieldName", allowEditing: false },
                    {
                      dataField: "FieldType",
                      allowEditing: false,
                      caption: "Field Datatype",
                      customizeText(info) {
                        switch (info.valueText) {
                          case "DateTime":
                            return "Date";
                          case "Int32":
                            return "Numeric";
                          default:
                            return info.valueText;
                        }
                      }
                    },

                    {
                      dataField: "CurrentSummary",
                      allowEditing: true,
                      showEditorAlways: true,
                      editCellTemplate(el, info) {
                        let form: DevExpress.ui.dxForm;
                        let ddb: DevExpress.ui.dxDropDownBox = $("<div/>")
                          .dxDropDownBox(<DevExpress.ui.dxDropDownBoxOptions>{
                            stylingMode: "underlined",
                            value: info.data.CurrentSummary,
                            displayExpr: () => {
                              return info.data.CurrentSummary &&
                                info.data.CurrentSummary
                                ? `${info.data.CurrentSummary
                                  .SummaryTypeDisplayName ||
                                info.data.CurrentSummary.SummaryType} (${
                                info.data.CurrentSummary.FormatString
                                }) `
                                : "";
                            },
                            showClearButton: true,
                            onValueChanged(e) {
                              if (
                                !e.value &&
                                $.isPlainObject(e.previousValue)
                              ) {
                                gm.SetSummaryValDirty(e.previousValue, true);
                              } else {
                                if ($.isPlainObject(e.value)) {
                                  gm.SetSummaryValDirty(e.value);
                                }
                              }
                            },
                            openOnFieldClick: true,
                            onOpened(e) {
                              let summary = gm.GetSummary(info.data);
                              form.option("formData", summary);
                            },
                            contentTemplate(e) {
                              form = $("<div />")
                                .dxForm({
                                  formData: info.data.CurrentSummary,
                                  items: [
                                    {
                                      dataField: "FieldName",
                                      itemType: "simple",
                                      editorType: "dxTextBox",
                                      editorOptions: <DevExpress.ui.dxTextBoxOptions>{
                                        disabled: true,
                                        value: info.data.CurrentSummary
                                          ? info.data.CurrentSummary.FieldName
                                          : info.data.FieldName
                                      }
                                    },
                                    {
                                      dataField: "FormatString",
                                      label: { text: "Format" },
                                      itemType: "simple",
                                      editorType: "dxTextBox",
                                      editorOptions: <DevExpress.ui.dxTextBoxOptions>{
                                        disabled: true
                                      }
                                    },
                                    {
                                      dataField: "SummaryType",
                                      label: { text: "Type" },
                                      itemType: "simple",
                                      editorType: "dxSelectBox",
                                      editorOptions: <DevExpress.ui.dxSelectBoxOptions>{
                                        dataSource:
                                          response.AvailableSummaryTypes,
                                        displayExpr: "Display",
                                        valueExpr: "Display"
                                      }
                                    },
                                    {
                                      dataField: "DisplayMode",
                                      label: { text: "Display In" },
                                      itemType: "simple",
                                      editorType: "dxSelectBox",
                                      editorOptions: <DevExpress.ui.dxSelectBoxOptions>{
                                        dataSource:
                                          response.AvailableSummaryDisplayModes,
                                        displayExpr: "Display",
                                        valueExpr: "ID"
                                      }
                                    },
                                    {
                                      itemType: "button",
                                      buttonOptions: {
                                        text: "",
                                        icon: "check",
                                        onClick(e) {
                                          let data = form.option("formData");
                                          info.data.CurrentSummary = data;
                                          ddb.option("value", data);
                                          // gm.SetSummaryValDirty(data);
                                          ddb.close();
                                        }
                                      }
                                    }
                                  ]
                                })
                                .dxForm("instance");
                              return form.element();
                            }
                          })
                          .dxDropDownBox("instance");
                        el.append(ddb.element());
                      },

                      calculateDisplayValue(data) {
                        return data.CurrentSummary && data.CurrentSummary
                          ? `${data.CurrentSummary.SummaryTypeDisplayName} (${
                          data.CurrentSummary.FormatString
                          }) `
                          : "";
                      }
                    },
                    { dataField: "DisplayFormat", visible: false },
                    { dataField: "IsParentField", visible: false },
                    { dataField: "IsBIField", visible: false },
                    { dataField: "VisibleInView", visible: false }
                  ]);
                options.editing = {
                  mode: "batch",
                  allowUpdating: true,
                  allowDeleting: false
                };

                return options;
              })()
            }
          ]
        });
      });
  }

  private SetSummaryValDirty(val, remove: boolean = false) {
    let gm = this;
    if (!remove) {
      if (gm.SettingsDirtyBag.Summaries) {

        if (gm.SettingsDirtyBag.Summaries.Added) {
          gm.SettingsDirtyBag.Summaries.Added.push(val);
        } else {
          gm.SettingsDirtyBag.Summaries.Added = [val];
        }

      } else {
        gm.SettingsDirtyBag.Summaries = { Added: [val] };
      }
    } else {
      if (gm.SettingsDirtyBag.Summaries) {

        if (gm.SettingsDirtyBag.Summaries.Removed) {
          gm.SettingsDirtyBag.Summaries.Removed.push(val);
        } else {
          gm.SettingsDirtyBag.Summaries.Removed = [val];
        }

      } else {
        gm.SettingsDirtyBag.Summaries = { Removed: [val] };
      }
    }
    gm.EnableButtons();
  }

  private GetSummary(info: FieldList) {
    let gm = this;

    let initialLabel: string = "";
    let summaryType: string = "";

    switch (info.FieldType) {
      case "DateTime":
        initialLabel = `Recent ${info.FieldName} : `;
        summaryType = "Max";
        break;
      case "Decimal":
      case "Integer":
        initialLabel = `Total ${info.FieldName} :`;
        summaryType = "Sum";
        break;
      default:
        summaryType = "None";
        initialLabel = "Label :";
    }
    let format = info.DisplayFormat || "{0";
    if (format && !format.startsWith("{", 0)) {
      format = "{0";
    }
    if (info.DisplayFormat !== "") {
      format = `${format} : ${info.DisplayFormat}`;
    } else {
      if (info.FormatString) {
        format = `${format}:${info.FormatString}`;
      }
    }

    if (format && !format.startsWith("}", format.length - 1)) {
      format = `${format}}`;
    }

    return {
      FormatString: `${initialLabel}${format}`,
      FieldName: info.CurrentSummary
        ? info.CurrentSummary.FieldName
        : info.FieldName,
      SummaryType: info.CurrentSummary ? info.CurrentSummary.Type : summaryType,
      DisplayMode: info.CurrentSummary ? info.CurrentSummary.DisplayMode : 1
    };
  }

  OpenDialog() {
    let gm = this;
    let btns: DevExpress.ui.dxPopupToolbarItem[] = [
      {
        location: "after",
        toolbar: "bottom",
        disabled: true,
        visible: false,
        widget: "dxButton",
        options: <DevExpress.ui.dxButtonOptions>{
          text: "Apply",
          onClick(e) {
            //Apply the settings to the grid but dont save
            //porbably need to minize the dialog so it can be reopened and saved
          }
        }
      },
      {
        location: "after",
        toolbar: "bottom",
        disabled: true,
        widget: "dxButton",
        options: <DevExpress.ui.dxButtonOptions>{
          text: "Save",
          type: 'success',
          icon: 'save',
          onClick(e) {
            gm._managerDataService.Save(gm.SettingsDirtyBag);
            gm._dialog.close();
          }
        }
      }, {
        location: 'after',
        toolbar: 'bottom',
        widget: 'dxButton',
        options: <DevExpress.ui.dxButtonOptions>{
          text: 'Cancel',
          type: 'danger',
          icon: 'remove',
          onClick(e) {
            gm._dialog.close();
          }
        }
      }
    ];

    gm._dialog = new Dialog(
      {
        showHelpButton: true,
        fullScreen: GetDevice().isDevice,
        body: $("<div id='grid-manager-dialog' />"),
        buttons: btns,
        closable: true,
        title: `Grid Manager - ${gm._view.Name}`,
        size: "size-large",
        type: "type-warning"
      },
      null,
      true,
      false
    );
    gm._dialog.open().done(() => {

      let opts: DevExpress.ui.dxTabPanelOptions = {
        showNavButtons: true,
        items: gm.tabs,
        height: '100%'
      };
      if (typeof this["index"] === "number") {
        opts.selectedIndex = this["index"];
      }
      $("#grid-manager-dialog").dxTabPanel(opts);
    });
  }

  SaveTheState(state) {
    let gm = this;
    gm._managerDataService.SaveState(state, gm.grid);
  }
  //TODO: Apply the changes on save...
  //TODO: allow the user to apply the changes to the current grid
  //TODO: Ensure multiple columns are not being added to the gridview
  //TODO: notify user of unsaved changes to view
  //TODO: fix grid filter choices menu
}

//		export class GridManagementDataService {
//			UserCanEditGlobal: boolean;

//			private get _viewGuid(): string {
//				return this._view.GUID;
//			}

//			private _view: IGridView;
//			constructor(view: IGridView) {
//				this._view = view;
//				new TDFRequest({ url: `${this._fullViewRoute}/${view.ItemType}/CanEditGlobal/` }).MakeRequest().done((response) => {
//					this.UserCanEditGlobal = response;
//				});

//			}

//			private get _designRoutePrefix(): string {
//				return `design`;
//			}
//			private get _fullDesignRoute(): string {
//				return `${TDFRequest.ApiPath}/${this._designRoutePrefix}/${this._viewRoute}`;
//			}
//			private get _viewRoute(): string {
//				return `grid/views`;
//			}
//			private get _fullViewRoute(): string {
//				return `${TDFRequest.ApiPath}/${this._viewRoute}`;
//			}

//			UseOwnerFilter: boolean;
//			public OwnerFilter() {
//				let ds = this;
//				let d = $.Deferred();
//				if (typeof ds.UseOwnerFilter !== "undefined") return d.promise(d.resolve(ds.UseOwnerFilter));
//				new TDFRequest({ url: `${ds._fullDesignRoute}/${ds._viewGuid}/owner-filter` }).MakeRequest().done((response) => {
//					ds.UseOwnerFilter = response;
//					d.resolve(ds.UseOwnerFilter)
//				});
//				return d.promise()
//			}

//			private _ColumnsForView: GridManagement.ViewColumnSetting;
//			public ColumnsForView() {
//				let d: JQueryDeferred<GridManagement.ViewColumnSetting> = $.Deferred()
//				if (this._ColumnsForView) return d.promise(d.resolve(this._ColumnsForView));
//				this.ColumnsForViewRequest().MakeRequest().done((response:GridManagement.ViewColumnSetting) => {
//					this._ColumnsForView = response;

//					d.resolve(this._ColumnsForView)
//				});
//				return d.promise();
//			}
//			private ColumnsForViewRequest(): TDFRequest {
//				return new TDFRequest({
//					url: `${this._fullDesignRoute}/${this._viewGuid}/columns`,
//					type: "GET",
//				});
//			}

//			SetVisibleColumns(columns: string[]) {
//				new TDFRequest({
//					url: `${this._fullDesignRoute}/${this._viewGuid}/setVisible`,
//					type: "POST",
//					data: columns
//				}).MakeRequest().done((response) => {
//					debugger
//				});
//			}

//			private _GeneralSettingsForView;
//			public GeneralSettingsForView() {
//				let d: JQueryDeferred<GridManagement.GeneralSettingsResponse> = $.Deferred()
//				if (this._GeneralSettingsForView) return d.promise(d.resolve(this._GeneralSettingsForView));
//				this.ViewGeneralSettings().MakeRequest().done((response: GridManagement.GeneralSettingsResponse) => {
//					this._GeneralSettingsForView = response;
//					d.resolve(this._GeneralSettingsForView)
//				});
//				return d.promise();
//			}
//			private ViewGeneralSettings(): TDFRequest {
//				return new TDFRequest({
//					url: `${this._fullDesignRoute}/${this._viewGuid}/general`,
//					type: "GET",
//				});
//			}

//			private _FormatSettingsForView;
//			public FormatSettingsForView() {
//				let d: JQueryDeferred<GridManagement.ViewFormatSettings> = $.Deferred()
//				if (this._FormatSettingsForView) return d.promise(d.resolve(this._FormatSettingsForView));
//				this.ViewFormatSettings().MakeRequest().done((response: GridManagement.ViewFormatSettings) => {
//					this._FormatSettingsForView = response;
//					d.resolve(this._FormatSettingsForView)
//				});
//				return d.promise();
//			}
//			private ViewFormatSettings(): TDFRequest {
//				return new TDFRequest({
//					url: `${this._fullDesignRoute}/${this._viewGuid}/format-settings`,
//					type: "GET",
//				});
//			}

//			private _ColumnFormatSettings = {};
//			public ColumnFormatSettings(field) {
//				let d: JQueryDeferred<GridManagement.ColumnFormat> = $.Deferred()
//				if (this._ColumnFormatSettings[field]) return d.promise(d.resolve(this._ColumnFormatSettings[field]));
//				this.SingleColumnFormatSettings(field).MakeRequest().done((response: GridManagement.ColumnFormat) => {
//					if (response.Settings.FieldName) this._ColumnFormatSettings[response.Settings.FieldName] = response;
//					d.resolve(this._ColumnFormatSettings[response.Settings.FieldName] || null)
//				});
//				return d.promise();
//			}
//private SingleColumnFormatSettings(field): TDFRequest {
//  field = field.replace(/\:/g, "{colon}");
//				return new TDFRequest({
//					url: `${this._fullDesignRoute}/${this._viewGuid}/format-settings/${encodeURIComponent(field)}`,
//					type: "GET",
//				});
//			}

//			private _ViewFilterSettings;
//			public FilterSettings() {
//				let d: JQueryDeferred<GridManagement.FilterSettings> = $.Deferred()
//				if (this._ViewFilterSettings) return d.promise(d.resolve(this._ViewFilterSettings));
//				this.ViewFilterSettings().MakeRequest().done((response: GridManagement.FilterSettings) => {
//					this._ViewFilterSettings = response;
//					d.resolve(this._ViewFilterSettings)
//				});
//				return d.promise();
//			}
//			private ViewFilterSettings(): TDFRequest {
//				return new TDFRequest({
//					url: `${this._fullDesignRoute}/${this._viewGuid}/filters`
//				})
//			}

//			private _DateFilterSettings;
//			public ViewDateFilterSettings() {
//				let d = $.Deferred()
//				if (this._DateFilterSettings) return d.promise(d.resolve(this._DateFilterSettings));
//				this.DateFilterSettings().MakeRequest().done((response/*: GridManagement.*/) => {
//					this._DateFilterSettings = response;
//					d.resolve(this._DateFilterSettings)
//				});
//				return d.promise();
//			}
//			private DateFilterSettings(): TDFRequest {
//				return new TDFRequest({
//					url: `${this._fullDesignRoute}/${this._viewGuid}/date-filters`
//				});
//			}

//			private _ViewSummarySettings;
//			public ViewSummarySettings() {
//				let d: JQueryDeferred<GridManagement.ISummarySettings> = $.Deferred()
//				if (this._ViewSummarySettings) return d.promise(d.resolve(this._ViewSummarySettings));
//				this.SummarySettings().MakeRequest().done((response: GridManagement.ISummarySettings) => {
//					this._ViewSummarySettings = response;
//					d.resolve(this._ViewSummarySettings)
//				});
//				return d.promise();
//			}
//			private SummarySettings(): TDFRequest {
//				return new TDFRequest({
//					url: `${this._fullDesignRoute}/${this._viewGuid}/summaries`
//				});
//			}

//			private _ViewSingleSummarySettings;
//			public ViewSingleSummarySettings(field) {
//				let d: JQueryDeferred<GridManagement.ViewFormatSettings> = $.Deferred()
//				if (this._ViewSingleSummarySettings) return d.promise(d.resolve(this._ViewSingleSummarySettings));
//				this.SingleSummarySettings(field).MakeRequest().done((response: GridManagement.ViewFormatSettings) => {
//					this._ViewSingleSummarySettings = response;
//					d.resolve(this._ViewSingleSummarySettings)
//				});
//				return d.promise();
//			}
//private SingleSummarySettings(field): TDFRequest {
//  field = field.replace(/\:/g, "{colon}");
//				return new TDFRequest({
//					url: `${this._fullDesignRoute}/${this._viewGuid}/summaries/${field}`
//				})
//			}

//			private _ViewAccountTypeFilterSettings;
//			public ViewAccountTypeFilterSettings() {
//				let d: JQueryDeferred<GridManagement.AccountTypeSettings> = $.Deferred()
//				if (this._ViewAccountTypeFilterSettings) return d.promise(d.resolve(this._ViewAccountTypeFilterSettings));
//				this.AccountTypeFilterSettings().MakeRequest().done((response: GridManagement.AccountTypeSettings) => {
//					this._ViewAccountTypeFilterSettings = response;
//					d.resolve(this._ViewAccountTypeFilterSettings)
//				});
//				return d.promise();
//			}
//			private AccountTypeFilterSettings(): TDFRequest {
//				return new TDFRequest({
//					url: `${this._fullDesignRoute}/${this._viewGuid}/accounttypefiltering`
//				});
//			}

//			private _ViewClient_Converted_FilterSettings;
//			public ViewClient_Converted_FilterSettings() {
//				let d: JQueryDeferred<GridManagement.IConvertedViewFilter> = $.Deferred()
//				if (this._ViewClient_Converted_FilterSettings) return d.promise(d.resolve(this._ViewClient_Converted_FilterSettings));
//				this.Client_Converted_FilterSettings().MakeRequest().done((response: GridManagement.IConvertedViewFilter) => {
//					this._ViewClient_Converted_FilterSettings = response;
//					d.resolve(this._ViewClient_Converted_FilterSettings)
//				});
//				return d.promise();
//			}
//			private Client_Converted_FilterSettings(): TDFRequest {
//				return new TDFRequest({
//					url: `${this._fullViewRoute}/filtering/${this._viewGuid}/client`
//				});
//			}

//			private _ViewServer_Converted_FilterSettings;
//			public ViewServer_Converted_FilterSettings() {
//				let d: JQueryDeferred<GridManagement.IConvertedViewFilter> = $.Deferred()
//				if (this._ViewServer_Converted_FilterSettings) return d.promise(d.resolve(this._ViewServer_Converted_FilterSettings));
//				this.Server_Converted_FilterSettings().MakeRequest().done((response: GridManagement.IConvertedViewFilter) => {
//					this._ViewServer_Converted_FilterSettings = response;
//					d.resolve(this._ViewServer_Converted_FilterSettings)
//				});
//				return d.promise();
//			}
//			private Server_Converted_FilterSettings(): TDFRequest {
//				return new TDFRequest({
//					url: `${TDFRequest.ApiPath}/grid/views/filtering/${this._viewGuid}/server`
//				});
//			}

//			private _ViewParent_Converted_FilterSettings;
//			public ViewParent_Converted_FilterSettings() {
//				let d: JQueryDeferred<GridManagement.IConvertedViewFilter> = $.Deferred()
//				if (this._ViewParent_Converted_FilterSettings) return d.promise(d.resolve(this._ViewParent_Converted_FilterSettings));
//				this.Parent_Converted_FilterSettings().MakeRequest().done((response: GridManagement.IConvertedViewFilter) => {
//					this._ViewParent_Converted_FilterSettings = response;
//					d.resolve(this._ViewParent_Converted_FilterSettings)
//				});
//				return d.promise();
//			}
//			private Parent_Converted_FilterSettings(): TDFRequest {
//				return new TDFRequest({
//					url: `${this._fullViewRoute}/filtering/${this._viewGuid}/parent`
//				});
//			}

//			/**
//			 * When clearing a filter the server expects a populated string or the action will fail so here we set it to the word clear.
//			 * @param filter
//			 */
//			private checkFilterValue(filter) {
//				return !filter || filter === "null" ? "clear" : filter;
//			}

//			private ActionMap = {
//				"ServerSideFilter": "server-filter",
//				"ClientSideFilter": "client-filter",
//				"ParentFilter": "parent-filter"
//			}

//			SetServerFilter(filterType, filter, filterObjString) {
//				new TDFRequest({
//					url: `${this._fullDesignRoute}/${this._viewGuid}/${this.ActionMap[filterType]}`,
//					data: this.checkFilterValue(filter),
//					type: "PUT"
//				}).MakeRequest();
//				new TDFRequest({
//					url: `${this._fullDesignRoute}/${this._viewGuid}/dx-${this.ActionMap[filterType]}`,
//					data: this.checkFilterValue(filterObjString),
//					type: "PUT"
//				}).MakeRequest()
//			}

//			SetColumnFormat(settings) {
//				new TDFRequest({ url: `${this._fullDesignRoute}/${this._viewGuid}/multiple-format-settings`, data: settings, type: "PUT" }).MakeRequest().done((response) => {
//					debugger
//				})
//			}

//			SetGeneralViewSettings(settings) {
//				new TDFRequest({ url: `${this._fullDesignRoute}/view-general`, type: "POST", data: settings }).MakeRequest().done((response) => {
//					debugger
//				})
//			}

//			private DoSave(payload, viewcreated = false, reloadgrid = false) {
//				let ds = this;

//				new TDFRequest({ url: `${ds._fullDesignRoute}/${ds._viewGuid}/save`, type: "PUT", data: payload }).MakeRequest().done((res) => {

//    if (viewcreated) {
//    //this will needs raised when a user manages a view and instead of just saving it they created a new one
//						RaiseEvent(EventType.refreshviews, EventNameSpace.request, res);
//					}
//    if (reloadgrid) {
//      RaiseEvent(EventType.viewsaved, EventNameSpace.notify, ds._view);
//					}

//				}).fail((res: Response) => {
//					res.text().then((t: any) => {
//						let d = JSON.parse(t);
//						if (typeof d === "string") {
//							new Notification({ message: d, type: "error" })
//						} else {
//							if ($.isPlainObject(d) && d.Message) {
//								new Notification({ message: d.Message, type: "error" })
//							}
//						}
//					})
//				});

//			}

//			Save(settingsbag) {
//				let ds = this;
//				let payload = {
//					GridView: settingsbag,
//					NewViewSettings: null
//				}
//				let viewnameForm = $("<div />").dxForm({
//					labelLocation: "top",
//					items: [{
//						itemType: "simple",
//						label: { text: "Please enter a name for the new Grid View" },
//						editorType: "dxTextBox",
//						dataField: "Name",
//						editorOptions: {
//							value: ds._view.Name
//						}
//					}]
//				}).dxForm("instance");
//				let viewnamedialog = new Dialog({
//					size: "size-small", buttons: [{
//						location: "after",
//						widget: "dxButton",
//						toolbar: "bottom",

//						options: {
//							text: "OK",
//							onClick() {
//								payload.NewViewSettings["ViewName"] = viewnameForm.getEditor("Name").option("value");
//								ds.DoSave(payload, true);
//								viewnamedialog.close();
//							}
//						}
//					},
//					{
//						location: "after",
//						widget: "dxButton",
//						toolbar: "bottom",

//						options: {
//							text: "Cancel",
//							onClick() {
//								viewnamedialog.close();
//							}
//						}
//					}
//					],
//					title: "Name the new view.",
//					closable: true,
//					body: viewnameForm.element()
//				}, null, false, false);

//				let CreateAsText = `Create as ${ds._view.IsGlobalView ? "Personal" : "Global"} `;

//				let dlgSettings = {
//					title: "Please choose an option.",
//					message: "Would you like to over-write this view, or create a new view?",
//					buttons: <DevExpress.ui.dxButtonOptions[]>[
//						{
//							text: "Over-Write",
//							onClick() {
//	  ds.DoSave(payload, false, true);

//							}
//						},
//						{
//							text: `Create New`,

//							onClick() {
//								payload.NewViewSettings = { Owner: ds.UserCanEditGlobal ? "Global" :CurrentUser.ID }
//								viewnamedialog.open();

//							}
//						},
//						{
//							text: CreateAsText,
//							disabled: !ds._view.IsGlobalView && !ds.UserCanEditGlobal,
//							onClick() {
//								ds._view.IsGlobalView ?
//									payload.NewViewSettings = {
//										Owner:CurrentUser.ID
//									} :
//									payload.NewViewSettings = {
//										Owner: "Global"
//									};
//								viewnamedialog.open();
//							}
//						},
//						{
//							text: "Cancel",
//						}
//					]
//				}

//				if (typeof ds.UserCanEditGlobal !== "undefined") {
//					if (ds._view.IsGlobalView) {
//						if (ds.UserCanEditGlobal) {
//							DevExpress.ui.dialog.custom(dlgSettings).show();
//						} else {

//							DevExpress.ui.dialog.confirm("You do not have rights to save global Grid Views. Would you like to save a Personal View instead?", "Insufficient Rights.").done((result) => {
//								if (result) {
//									payload.NewViewSettings = {
//										Owner:CurrentUser.ID
//									}
//									viewnamedialog.open();
//								}
//								//else die
//							})
//						}
//					} else {
//						DevExpress.ui.dialog.custom(dlgSettings).show();
//					}
//				}

//			}

//			GetParentAccountFilterFields() {
//				return new TDFRequest({
//					url: `${this._fullViewRoute}/Filtering/ParentAccountFields`
//				})
//			}

//			get DeleleGridViewRequest():TDFRequest {
//				return new TDFRequest({ url: `${this._fullViewRoute}/${this._view.GUID}`, type:"DELETE"})
//			}

//		}
