import { Dialog } from "../dialogs/dialog";
import { Notification } from "../dialogs/notification";
import { itemTypes } from "../../enums/enums";
import * as Globalize from "globalize";


import { TDFRequest } from "../../services/request";
import { ItemBase } from "./itembase";
//import { SearchRecFav } from '../../1recyclebin/srf';

import { BuildBreadcrumbs } from "../../util/allutils";
import { DisplayInfo, CurrentCompany } from "../../infrastructure/context";
import { SearchRecFav } from "../controls/searchrecfav";

export class Reminder extends ItemBase {
  DisplayName: string;
  ReminderForm: DevExpress.ui.dxForm;
  ParentItemID: string = "";
  ParentItemDesc: string = "";
  ParentItemType: null;
  DetailItems: Array<DevExpress.ui.dxFormSimpleItem>;
  PresetDate;

  get TypeName() {
    return "Reminder";
  }
  set TypeName(name) {
    if (name && this.TypeName !== name) this.TypeName = name;
  }
  get ItemType() {
    return itemTypes.itemReminder;
  }
  get RequiresParentItem(): boolean {
    return false;
  }

  constructor(args) {
    super(args);
    let theReminder = this;
    $.each(args, function (key, val) {
      if (theReminder.hasOwnProperty(key)) theReminder[key] = val;
    });

    if (!theReminder.DisplayName) {
      DisplayInfo(theReminder.ItemType).done(function (displayinfo) {
        theReminder.DisplayName = displayinfo.DisplayName;
        theReminder.TypeName = displayinfo.TypeName;
      });
    }
  }

  getFormItems() {
    let reminderItem = this;

    reminderItem.DetailItems = [
      {
        dataField: "Subject",
        editorType: "dxTextBox",
        colSpan: 8,
        label: { text: "Reminder Subject" },
        isRequired: true,
        visibleIndex: 0
      },
      {
        dataField: "Date",
        editorType: "dxDateBox",
        colSpan: 8,
        label: { text: "Reminder Date" },
        editorOptions: { type: "datetime" },
        isRequired: true,
        visibleIndex: 2
      },
      {
        //TODO: Make multiline
        dataField: "Text",
        editorType: "dxTextArea",
        colSpan: 8,
        label: { text: "Reminder Text" },
        visibleIndex: 3
      }
    ];
  }

  getGeneralButton() {
    let reminderItem = this;
    return {
      id: "",
      autospin: true,
      cssClass: "btn-warning",
      action: function (dlg) {
        //request to create a general reminder
        dlg.close();
        reminderItem.getFormItems();
        new TDFRequest({ url: "/core/user/userlist/" })
          .MakeRequest()
          .done(function (users) {
            reminderItem.DetailItems.push({
              dataField: "Users",
              colSpan: 8,
              editorType: "dxTagBox",
              label: { text: `${CurrentCompany.Branding.Brand_Abbreviation} Users` },
              isRequired: true,
              editorOptions: {
                dataSource: users,
                displayExpr: "Name",
                searchEnabled: true
              },
              visibleIndex: 1
            });

            reminderItem.Form();
          });
      },
      icon: "glyphicon glyphicon-pencil",
      label: "Create General Reminder"
    };
  }

  Create() {
    let reminderItem = this;
    reminderItem.isNew = true;
    let d = $.Deferred();
    let SRF: SearchRecFav = new SearchRecFav(
      reminderItem.ItemType,
      [999],
      itemTypes.itemUnknown,
      false,
      0,
      reminderItem.getGeneralButton()
    );
    if (!reminderItem.ParentItemID) {
      SRF.GetSRFDialog().done(selected => {
        let parentid = selected[0]["TDF GUID"];
        let desc =
          selected[0]["Company"] ||
          selected[0]["Subject"] ||
          selected[0]["CompanyName"] ||
          selected[0]["AccountName"] ||
          selected[0]["Description"];

        //Request to open new reminder with parent

        reminderItem.ParentItemID = parentid;
        reminderItem.ParentItemDesc = desc;

        reminderItem.PrepareNewReminderForm();

        SRF.Dialog.close();

        d.resolve();
      });
    } else {
      reminderItem.PrepareNewReminderForm();

      d.resolve();
    }

    return d.promise();
  }

  PrepareNewReminderForm() {
    let reminderItem = this;

    reminderItem.getFormItems();

    new TDFRequest({ url: "/core/user/userlist/" })
      .MakeRequest()
      .done(function (users) {
        reminderItem.DetailItems.push({
          dataField: "Users",
          colSpan: 8,
          editorType: "dxTagBox",
          label: { text: `${CurrentCompany.Branding.Brand_Abbreviation} Users` },
          isRequired: true,
          editorOptions: {
            dataSource: users,
            displayExpr: "Name",
            searchEnabled: true
          },
          visibleIndex: 1
        });

        reminderItem.Form();
      });
  }

  Initialize() {
    let reminderItem = this;
    let itemddfd: JQueryDeferred<ItemBase> = $.Deferred();

    reminderItem.getFormItems();

    reminderItem.Form();

    return itemddfd.promise(itemddfd.resolve(reminderItem));
  }

  Form() {
    let reminderItem = this;

    let d: JQueryDeferred<void> = $.Deferred();

    //let data = {};
    let formOptions = {
      //formData: data,
      items: reminderItem.DetailItems,
      colCount: 8
    };

    reminderItem.ReminderForm = $("<div />").attr("id", reminderItem.ContentID).dxForm(formOptions).dxForm('instance')
    //.append(
    //$("<div />").addClass("tdfitemtabs").attr({ id: reminderItem.UpperTabsID }));



    reminderItem.ItemForm = new Dialog({
      size: "size-normal",
      closable: true,
      id: reminderItem.DomSafeID,
      title: reminderItem.DisplayName,
      body: reminderItem.ReminderForm.element(),
      type: "type-primary",
      buttons: [
        {
          widget: 'dxButton',
          location: 'after',
          toolbar: 'bottom',
          visible: reminderItem.isNew,
          options: {
            text: "Send Reminder",
            icon: 'check',
            type: 'success',
            onClick: function () {
              reminderItem.SendReminder();
            }
          },
        }, {
          widget: 'dxButton',
          location: 'after',
          toolbar: 'bottom',
          visible: !reminderItem.isNew,
          options: {
            text: 'Mark as Read',
            icon: 'check',
            type: 'success',
            onClick: () => {
              new TDFRequest({
                url: "/Item/MarkReminderAsRead",
                data: { ReminderId: reminderItem.ItemID },
                type: "POST"
              })
                .MakeRequest()
                .done(function (response) {
                  if (true) {
                    new Notification({
                      message: response.Message || "Success:",
                      type: "success",
                      displayTime: 3e3,
                      shading: true
                    });
                    reminderItem.Close();
                  } else {
                    new Notification({
                      message: response.Message || "Error:",
                      type: "error",
                      displayTime: 6e3,
                      shading: true
                    });
                  }
                });
            }
          }
        }, {
          widget: 'dxButton',
          location: 'after',
          toolbar: 'bottom',
          options: {
            text: 'Cancel',
            icon: 'remove',
            type: 'danger',
            onClick: () => {
              reminderItem.ItemForm.close();
            }
          }

        }
      ]
    }, reminderItem);

    reminderItem.GetFormData().then(() => {
      reminderItem.ItemForm.open().done(() => {
        d.resolve();
      });
    });
    return d.promise();
  }

  private GetFormData() {
    let reminderItem = this;
    let d: JQueryDeferred<any> = $.Deferred();
    let data = {};

    if (!reminderItem.isNew) {
      new TDFRequest({
        url: "/Item/OpenReminder",
        data: { ReminderId: reminderItem.ItemID }
      })
        .MakeRequest()
        .done(function (response) {
          new TDFRequest({
            url: "/Item/GetiMain",
            data: {
              itemList: [{ ItemId: response[0].ItemId, ItemType: 0 }]
            },
            type: "POST"
          })
            .MakeRequest()
            .done(function (newresponse) {
              reminderItem.iMain = newresponse[0];

              if (reminderItem.iMain.ItemSubject != null) {
                reminderItem.iMain.ItemParents.unshift({
                  Parent: response[0].ReminderSubject,
                  ParentId: reminderItem.ItemID,
                  ParentType: reminderItem.ItemType,
                  ParentLevel: 0
                });
                let small = BuildBreadcrumbs(
                  reminderItem.iMain,
                  reminderItem.ItemType
                ).addClass('bi-breadcrumb');

                reminderItem.ItemForm.getModalBody().prepend(small);
                //reminderItem.DetailItems.push({
                //    dataField: "ParentItemDesc",
                //    colSpan: 8,
                //    editorType: "dxTextBox",
                //    label: { text: "Linked Item" },
                //    visibleIndex: 0
                //});
              }

              data = {
                Subject: response[0].ReminderSubject,
                Date: response[0].Reminder,
                Text: response[0].ReminderText,
                ParentItemID: response[0].ItemId,
                ParentItemDesc: reminderItem.iMain.ItemSubject
              };

              reminderItem.ReminderForm.option("formData", data);
              reminderItem.ReminderForm.option("readOnly", true);

              d.resolve();
            });
        });
    } else {

      data = {};
      reminderItem.ReminderForm.option("formData", data);

      d.resolve();
    }

    return d.promise();
  }

  SendReminder() {
    let reminderItem = this;

    if (reminderItem.ReminderForm.validate().isValid) {

      let formData = reminderItem.ReminderForm.option("formData");
      let recipientIDs = "";
      let date = Globalize.formatDate(new Date(formData.Date), {
        datetime: "short"
      });

      $.each(formData.Users, function (k: number, v) {
        if (k + 1 < formData.Users.length) {
          recipientIDs += v.Userid + ",";
        } else {
          recipientIDs += v.Userid;
        }
      });

      let params = {
        RecipientList: recipientIDs,
        ParentItemID: reminderItem.ParentItemID,
        ParentItemType: reminderItem.ParentItemType,
        Subject: formData.Subject,
        BodyText: formData.Text,
        ReminderDate: date
      };

      let request = new TDFRequest({
        url: "/Item/SendReminder",
        data: params,
        type: "POST"
      });
      request.MakeRequest().done(function (response: any) {
        if (true) {
          new Notification({
            message: response.Message || "Success:",
            type: "success",
            displayTime: 3e3,
            shading: true
          });
          reminderItem.Close();
        } else {
          new Notification({
            message: response.Message || "Error:",
            type: "error",
            displayTime: 6e3,
            shading: true
          });
        }
      });

    }
  }
}
