import "devextreme/ui/tree_view";
import * as Globalize from "globalize";
import { BuildBreadcrumbs, CreateCollapsibleGroups, Debounce, DomSafeID, GetActionRequestParamsFromRowData, GetDevice, GroupActions, MenuView, RenderActionsPopup, RenderCompleteConvertDialog } from "../../util/allutils";
import { EnumParentType, itemAccountTypes, itemTypes, menuActions, tdfModules, tdfRights } from "../../enums/enums";
import { EnumERPProcessStatus } from "../../enums/quotes/enums";
import { CurrentUser, DisplayInfo, LoadCompany, DoesUserHaveRight } from "../../infrastructure/context";
import { Preferences } from "../../infrastructure/user/preferences";
import {
  IActionItem,
  IChangeLogData,
  IControlData,
  IControlGroup,
  ICreateDocumentArgs,
  IFormTypeInfo,
  IItemInfo,
  ILinkedItemsArgs,
  IPrepopulationData,
  ItemControls,
  ITemplateItem,
  IViewItem,
  IDisplayInfoOptions
} from "../../interfaces/interfaces";
import { create } from "../../legacy-centers-grids/linkeditemgridfactory";
import { createitem } from "./factory/itemfactory";
import { GetLinkPrompt } from "../../legacy-centers-grids/linkitems";
import * as itemDataService from "../../services/item/itemdataservice";
import { AttachmentViewer } from "../controls/attachmentviewer";
import { Control } from "../controls/controls";
import { CustomAcctPkgSelector } from "../controls/customacctpkgselector";
import { PackageSelection } from "../controls/packageselection";
import { QuickNotes } from "../controls/quicknotes";
import { SearchRecFav } from "../controls/searchrecfav";
import { Dialog } from "../dialogs/dialog";
import { Notification } from "../dialogs/notification";
import { EmailGenerator } from "./emailgenerator";
import { ItemMain } from "./helpers/itemmain";
import { ItemNavigator } from "./itemnavigator";
import dxDataSource from "devextreme/data/data_source";
import "devextreme/ui/switch";
import "bootstrap/js/src/collapse"
import { MenuAction } from "util/menuactions";
import { custom, confirm, alert } from "devextreme/ui/dialog";
import { TDFRequest } from "../../services/request";
import * as Cookies from "js-cookie";
import { LinkedItemsCenter } from "datacenters/linkeditemscenter";
import { Dialogs } from "../../interfaces/dialogs";

export abstract class ItemBase {
  abstract DisplayName: string;
  abstract TypeName: string;
  abstract RequiresParentItem: boolean;
  protected Options: IDisplayInfoOptions;

  get HeaderID() {
    return `${this.TypeName}-header-container-${this.DomSafeID}`;
  }
  get ContentID() {
    return `${this.TypeName}_Content_${this.DomSafeID}`;
  }
  get UpperTabsID() {
    return `${this.TypeName}-uppertabs-${this.DomSafeID}`;
  }
  get LowerTabsID() {
    return `${this.TypeName}-lowertabs-${this.DomSafeID}`;
  }
  get ToolbarID() {
    return `${this.TypeName}-toolbar-${this.DomSafeID}`;
  }

  ItemID: string;
  DomSafeID: string;
  "TDF GUID": string;
  /**
   *If this item was created on an linked item grid we need this. This will be the id of the item with the linked item grid that created this.
   */
  ContainerItemID: string;
  /**
   *Will be used for open profile \ default tab to open
   */
  StartingControlGroupID: string = "";
  EnableDelete: boolean = false;
  isNew: boolean = false;
  DefaultToolbarButtons: DevExpress.ui.dxToolbarItemTemplate[];
  Dirty: boolean;
  InitialSubject: string;
  InitialBody: string;
  ItemForm: Dialog;
  Toolbar: DevExpress.ui.dxToolbar;
  ItemType: itemTypes;
  DirtyControls: Array<any>;
  ControlGroups: IControlGroup[];
  iMain: ItemMain;
  CanEdit: boolean;
  Controls: ItemControls;

  MainTabs: any; //top tabs
  ProfileTabs: any; //bottom tabs
  Actions: Array<IActionItem>;
  Views: Array<IViewItem>;
  IsNavigatorItem: boolean = false;
  ScrollView: DevExpress.ui.dxScrollView;
  ChangeLogData: Array<IChangeLogData>;
  StartingTab: string;
  FormTypeInfo: IFormTypeInfo;
  EnableReassign: boolean = false;
  Navigator: ItemNavigator;
  NoteControls: Array<Control> = [];
  AddressDataList: Array<any>;
  // Dialog: Dialog;
  PrepopulationData: IPrepopulationData;
  OnSaveHandlers: any[] = [];

  constructor(args) {
    let iBase = this;
    $.each(args, function (key, val) {
      try {
        iBase[key] = val;
      } catch (e) { }
    });

    iBase["TDF GUID"] = iBase["TDF GUID"] || iBase.ItemID || "";
    if (!iBase.ItemID && iBase["TDF GUID"]) {
      iBase.ItemID = iBase["TDF GUID"];
    }
    iBase.DomSafeID = DomSafeID(iBase["TDF GUID"]);

    if (!iBase.DisplayName || !iBase.TypeName || !iBase.Options) {
      DisplayInfo(iBase.ItemType).done(function (displayinfo) {
        //TODO:  STore and use this as the IDisplayInfo object returned by DisplayInfo()
        iBase.DisplayName = displayinfo.DisplayName;
        iBase.TypeName = displayinfo.TypeName;
        iBase.Options = displayinfo.Options;
      });
    }
    //    iBase.SetIDs();
    return iBase;
  }

  Create(override = false, ParentData?) {
    let iBase = this;
    iBase.isNew = true;
    let d = $.Deferred();

    if (iBase.RequiresParentItem || override) {
      if (ParentData) {
        iBase.CreateNewItemWithParent(ParentData).done(() => {
          if (iBase.ItemType !== itemTypes.itemEmail) {
            iBase.PushControlGroups().done(() => {
              d.resolve();
            });
          } else {
            d.resolve();
          }
        });
      } else {
        itemDataService.ValidParents(iBase.ItemType).done(function (response) {
          let SRF: SearchRecFav = new SearchRecFav(
            iBase.ItemType,
            response /*.Data*/,
            itemTypes.itemUnknown,
            false
          );

          SRF.GetSRFDialog().done(selected => {
            iBase.CreateNewItemWithParent(selected[0]).done(() => {
              SRF.Dialog.close().done(() => {
                if (iBase.ItemType !== itemTypes.itemEmail) {
                  iBase.PushControlGroups().done(() => {
                    d.resolve();
                  })
                } else {
                  d.resolve();
                }
              })
            })
            //}
          });
        });
      }
    } else {
      LoadCompany().done(function () {
        if (
          Preferences.GetCompanyPreference("CustomAcctPkgLookup", "TDFItem") ===
          "0"
        ) {
          iBase.StandardAcctPkgLookup();
        } else {
          let customSelector = new CustomAcctPkgSelector(
            iBase,
            iBase.AcctPkgSelectorCreator.bind(iBase),
            iBase.StandardAcctPkgLookup.bind(iBase)
          );
          customSelector.DisplayPanel();
        }
      });
    }

    return d.promise();
  }

  private CreateNewItemWithParent(data): JQueryPromise<void> {
    let iBase = this;
    let d: JQueryDeferred<void> = $.Deferred();
    itemDataService
      .NewWithParent({
        newtype: iBase.ItemType,
        parent: data["TDF GUID"] || data.ParentID,
        subject: data.Subject,
        body: data.Body,
        bodyid: data.BodyID
      })
      .done(function (response) {
        if (!(iBase.ItemType === itemTypes.itemEmail)) {
          if (response /*.Data*/.PrePopulationData) {
            iBase.PrepopulationData =
              response /*.Data*/.PrePopulationData;
          }
          iBase.SetValues(response /*.Data*/);
          (<ItemMain>iBase.iMain).ItemParents[0] = {
            Parent: iBase.iMain.ItemSubject,
            ParentId: iBase.iMain.ItemId,
            ParentType: iBase.iMain.ItemType,
            ParentLevel: EnumParentType.Primary
          };

          d.resolve();
        } else {
          let parent: IItemInfo = {
            ItemId: data["TDF GUID"] || data.ParentID,
            ItemType: data.ItemType,
            iMain: response /*.Data*/.iMain
          };
          let email =
            data["E-mail 1"] ||
            data["TDF_Email"] ||
            data["TDF_Email2"] ||
            data["TDF_Email3"];
          new EmailGenerator(
            parent,
            response.InitialSubject || "",
            response.InitialBody || "",
            email
          );
          d.resolve();
        }
      });

    return d.promise();
  }

  private StandardAcctPkgLookup() {
    let iBase = this;
    let selector = new PackageSelection();
    if (iBase.ItemType !== itemTypes.itemAccount) {
      selector.Buttons.push({
        widget: "dxButton",
        toolbar: "bottom",
        location: "after",
        options: {
          text: "Search For an Existing Item Instead",
          icon: "fa fa-search",
          type: "info",
          onClick: function () {
            selector.Dialog.close();
            iBase.Create(true);
          }
        }
      });
    }
    selector.DisplayPanel();

    selector.OnCommit = function (response) {
      let folder = response["level" + selector.ActiveFolderLevel];
      let str = Object.keys(folder)[0];
      folder = folder[str];

      iBase.AcctPkgSelectorCreator(folder);
    };
  }

  private AcctPkgSelectorCreator(selectedAcctPkg: string) {
    let iBase = this;
    /*let request = new TDFRequest({
      url: "/item/NewNoParent/",
      data: { newtype: iBase.ItemType, folder: selectedAcctPkg },
      type: "POST",
      dataType: "application/json"
    });

    request.MakeRequest()*/
    itemDataService
      .NewNoParent({ newtype: iBase.ItemType, folder: selectedAcctPkg })
      .done(function (response) {
        if (response.ControlGroups) {
          iBase.SetValues(response);
          if (!(<ItemMain>iBase.iMain).ItemParents) {
            (<ItemMain>iBase.iMain)["ItemParents"] = [];
          }
          (<ItemMain>iBase.iMain).ItemParents[0] = {
            Parent: iBase.iMain.ItemSubject,
            ParentId: iBase.iMain.ItemId,
            ParentType: iBase.iMain.ItemType,
            ParentLevel: EnumParentType.Primary
          };
          iBase.PushControlGroups();
        }
      });
  }

  protected SetValues(response) {
    let iBase = this;
    $.each(response, function (key, val) {
      try {
        iBase[key] = val;
      } catch (e) { }
    });
    iBase.iMain = response.iMain;
    iBase.CanEdit = true;
    iBase.Dirty = true;
    iBase.isNew = true;
  }

  protected PushControlGroups() {
    let iBase = this;
    let groups = iBase.ControlGroups.filter(function (group) {
      return group.ControlGroupOrder === 0 && group.Visible;
    }).map(k => k.ControlGroupID);
    let d = $.Deferred();
    //$.each(iBase.ControlGroups, function (key, val) {
    //    if (val.ControlGroupOrder === 0 && val.Visible) {
    //        if (val.ControlGroupID ) {
    //            groups.push(val.ControlGroupID);
    //        }
    //    }
    //});
    iBase.GetControls(groups).done(function () {
      iBase.OnCreate().done(() => {
        iBase.Form();

        d.resolve();
      });
    });

    return d.promise();
  }

  Initialize(isnavigator?: boolean) {
    let iBase = this;
    let itemddfd: JQueryDeferred<ItemBase> = $.Deferred();

    iBase.GetControlGroups().done(function () {
      //TODO: get this into the refresh function as well and make this its own method like  GetStartingControlGroups or something
      let controlgroups = iBase.ControlGroups.filter(function (group) {
        if (iBase.StartingTab && group.Name === iBase.StartingTab)
          iBase.StartingControlGroupID = group.ControlGroupID;
        return group.ControlGroupOrder === 0 && group.Visible;
      }).map(k => k.ControlGroupID);

      if (controlgroups.length) {
        iBase.GetControls(controlgroups).done(function () {
          if (!isnavigator) {
            iBase.PushControlGroups().done(() => {
              itemddfd.resolve(iBase);
            });
          } else {
            itemddfd.resolve(iBase);
          }
        });

        itemDataService.AddToRecent(iBase.ItemID, iBase.ItemType);
      } else {
        new Notification({
          message:
            "It seems there are no tab sections defined for type " +
            iBase.DisplayName,
          type: "error",
          displayTime: 5000
        });
      }
    });
    return itemddfd.promise(iBase); //iBase;
  }

  Form() {
    let iBase = this;

    let d: JQueryDeferred<void> = $.Deferred();

    iBase.ItemForm = new Dialog(
      {
        size: "size-wide",
        id: iBase.DomSafeID,
        title: iBase.DisplayName,
        closable: true,
        //buttons: iBase.ToolbarItems(),
        body: $("<div />")
          .attr("id", iBase.ContentID)
          .append(
            $("<div />")
              .addClass("tdfitemtabs")
              .attr({ id: iBase.UpperTabsID })
          )
          .append(
            $("<div />")
              .addClass("tdfitemtabs")
              .attr({ id: iBase.LowerTabsID })
          ),
        type: "type-primary"
      },
      iBase
    );

    iBase.ItemForm.open().done(function (args) {
      $(
        "<div id=" + iBase.ToolbarID + " class='item-toolbar-contain'></div>"
      ).prependTo(iBase.ItemForm.getModalBody());
      iBase.RenderControlGroups();

      let t = iBase.ItemForm.getModalBody();
      let height = $(window).innerHeight() - $("#tdffooter").innerHeight();

      // This accounts for the bar safari puts at the bottom of their mobile browser.
      if (GetDevice().device.ios || GetDevice().device.generic) {
        height = height - 40;
      }

      iBase.ScrollView = $("#" + iBase.ContentID)
        .dxScrollView({
          height: height - 60,
          showScrollbar: "always",
          bounceEnabled: false
        })
        .dxScrollView("instance");
      iBase.Toolbar = $("#" + iBase.ToolbarID)
        .dxToolbar(<DevExpress.ui.dxToolbarOptions>{
          items: iBase.ToolbarItems(),
          onItemClick(e) {
            if (
              iBase.isNew &&
              (!e.itemData.options.text ||
                (e.itemData.options.text.indexOf("Save") < 0 &&
                  e.itemData.options.text.indexOf("Close") < 0))
            ) {
              new Notification({
                message: "Please save this " + iBase.DisplayName + " first.",
                type: "warning",
                displayTime: 5000
              });
            }
          }
        })
        .dxToolbar("instance");

      if (iBase.StartingControlGroupID !== "") {
        //TODO:  alert("Finish this logic for moving defaulting to a different tab.");
      }

      d.resolve();
    });

    return d.promise();
  }

  ToolbarItems(): DevExpress.ui.dxToolbarItemTemplate[] {
    let iBase = this;
    iBase.DefaultToolbarButtons = [
      iBase.btnAll(),
      iBase.btnActions(),
      iBase.btnViews(),
      iBase.btnTaskReassign(),
      iBase.btnCompleteAndConvert(),
      iBase.btnEventMoreInfo(),
      iBase.btnSave(),
      iBase.btnSaveClose(),
      iBase.btnSaveCopy(),
      iBase.btnClose()
    ];

    return iBase.DefaultToolbarButtons;
  }

  ToolbarItemsActionSheet(e) {
    let iBase = this;

    let iMain: ItemMain;
    let menu: DevExpress.ui.dxActionSheet;
    if (!$("#tdf-text-btns").length)
      $("body").append($("<div id='tdf-text-btns' />"));

    let opts: DevExpress.ui.dxActionSheetOptions = {
      target: e.element,
      usePopover: true,
      width: "250px",
      showTitle: false,
      items: [
        {
          text: "Refresh Item",
          icon: "refresh",
          action: "Refresh"
        },
        {
          text: "Delete This Item",
          icon: "remove",
          action: "Delete",
          visible: iBase.EnableDelete
        },

        {
          text: "Email Detail",
          icon: "fa fa-envelope-o",
          action: "EmailDetails",
          visible: true      // NEEDTODO:  This needs to be more dynamic eventually, but not sure what to base it on for SherpaBI.
        },
        {
          text: "View Attachments",
          icon: "fa fa-paperclip",
          action: "ViewAttachments",
          visible: iBase.Options.SupportsAttachments
        },
        {
          text: "Link Current Item to..",
          icon: "fa fa-link",
          action: "LinkItems",
          visible:
            Preferences.GetCompanyPreference(
              "IncludeLinkButtonOnItemMenu",
              "TDFMobile"
            ) === "1"
        },
        {
          text: "Send Reminder",
          icon: "fa fa-bell",
          action: "SendReminder",
          visible: CurrentUser.Modules.indexOf(tdfModules.TDF_Reminders) > -1
        },
        {
          text: "Templates",
          icon: "fa fa-file-text",
          action: "ViewTemplates",
          visible: iBase.Options.SupportsTemplateGeneration
        },
        {
          text: "Complete & Convert ",
          icon: "dx-icon icon-completeConvert",
          action: "C&C",
          visible:
            iBase.ItemType === itemTypes.itemTask ||
            iBase.ItemType === itemTypes.itemAppointment
        },
        {
          text: "Reassign Task",
          icon: "dx-icon item10-assign",
          action: "ReassignTask",
          visible: iBase.ItemType === itemTypes.itemTask
        },
        {
          text: "Open in New Window",
          icon: "fa fa-mail-forward",
          action: "OpenInNewWindow"
        },

        {
          text: "Search this item",
          icon: "fa fa-mail-forward",
          action: "QuickAnswer",
          visible: true
        }

      ],
      onItemClick: Debounce(function (e: any) {
        if (iBase[e.itemData.action]) {
          iBase[e.itemData.action]();
        } else {
          if (e.itemData.action === "C&C") {
            RenderCompleteConvertDialog(iBase.ItemType, [iBase.ItemID]);
          }
        }
        menu.hide();
      }, 500),
      visible: true
    };

    menu = $("#tdf-text-btns")
      .dxActionSheet(opts)
      .dxActionSheet("instance");
    menu.show();
  }

  GetCorrectTemplate(menu = null): Function {
    let iBase = this;
    let iMain: ItemMain;

    return GetDevice().isDevice
      ? el => {
        let listopts: DevExpress.ui.dxListOptions = {
          grouped: true,
          collapsibleGroups: true,
          groupTemplate: function (data) {
            return $("<div>" + data.MenuText + "</div>");
          },
          itemTemplate(data) {
            return $("<div>" + data.MenuText + "</div>");
          },
          dataSource: new dxDataSource({
            load: function (options) {
              let dfd = $.Deferred();
              if (iBase.Actions && iBase.Actions.length) {
                iMain = iBase.iMain;
                return dfd.promise(dfd.resolve(iBase.Actions));
              }
              /*let request = new TDFRequest({
                url:
                  "/action/getactionsonly/?" +
                  $.param({ itemid: iBase.ItemID, itemtype: iBase.ItemType }),
                type: "GET"
              });
              request.MakeRequest()*/
              itemDataService
                .ActionsOnly({
                  itemType: iBase.ItemType,
                  itemid: iBase.ItemID
                })
                .done(function (response: any) {
                  iMain = new ItemMain(response.ActionsModel.iMain);

                  $.each(response.ActionsModel.ActionList, function (
                    key,
                    val: any
                  ) {
                    if (
                      val.Action === menuActions.menuNewEmail &&
                      response.ActionsModel.ContactEmail
                    ) {
                      val.Email = response.ActionsModel.ContactEmail;
                    }
                  });
                  let actions = GroupActions(
                    response.ActionsModel.ActionList,
                    true
                  );
                  iBase.Actions = actions;
                  dfd.resolve(actions);
                });
              return dfd.promise();
            }
          }),
          onItemClick: Debounce(function (data: any) {
            let action = new MenuAction(data.itemData, [
              {
                ItemId: iBase.ItemID,
                ItemType: iBase.ItemType,
                iMain: iMain || iBase.iMain
              }
            ]);
            menu.hide();
          }, 500),
          onGroupRendered: function (e) {
            if (
              e.groupData.text != "General" &&
              e.groupData.text != "Create"
            ) {
              (e.component as DevExpress.ui.dxList).collapseGroup(
                e.groupIndex
              );
            }
          }
        };
        el.append($("<div />").dxList(listopts));
      }
      : el => {
        let theActions;
        let actionRequestParams = GetActionRequestParamsFromRowData(iBase);

        /* new TDFRequest({
          url: "/action/getactionsonly/",
          type: "GET",
          data: actionRequestParams
        })
          .MakeRequest()*/
        itemDataService.ActionsOnly(actionRequestParams).done(response => {
          let iMain = response.ActionsModel.iMain;

          $.each(response.ActionsModel.ActionList, (k, v) => {
            v.text = v.MenuText;
            v.iMain = iMain;
          });

          theActions = response.ActionsModel.ActionList;
          if (
            (actionRequestParams.itemtype === itemTypes.itemContact ||
              actionRequestParams.itemtype === itemTypes.itemLead) &&
            response.ActionsModel.ContactEmail
          ) {
            theActions.ContactEmail = response.ActionsModel.ContactEmail;
          }

          RenderActionsPopup(el, theActions, [iBase]);
        });
      };
  }

  GetActionList(e) {
    let iBase = this;
    let newSectionFlag = false;
    if (iBase.isNew) return;

    if (GetDevice().isDevice) {
      let menu: DevExpress.ui.dxPopover | DevExpress.ui.dxPopup;
      if (!$("#tdfactions").length)
        $("body").append($("<div id='tdfactions' />"));
      let menuoptions:
        | DevExpress.ui.dxPopoverOptions
        | DevExpress.ui.dxPopupOptions;
      let opts = {
        fullScreen: true,
        showCloseButton: true,
        contentTemplate: iBase.GetCorrectTemplate(menu)
      };
      menu = $("#tdfactions")
        .dxPopup(opts)
        .dxPopup("instance");
      menu.show();
    } else {
      let buildPopover = iBase.GetCorrectTemplate();
      buildPopover(e.element);
    }
  }

  GetViewList(e) {
    let iBase = this;
    if (iBase.isNew) return;
    let iMain: ItemMain;
    let menu: DevExpress.ui.dxActionSheet;
    if (!$("#tdfactions").length)
      $("body").append($("<div id='tdfactions' />"));
    menu = $("#tdfactions")
      .dxActionSheet({
        target: e.element,
        usePopover: true,
        width: "250px",
        showTitle: false,
        dataSource: new dxDataSource({
          load: function (options) {
            let dfd = $.Deferred();
            if (iBase.Views && iBase.Views.length) {
              return dfd.promise(dfd.resolve(iBase.Views));
            }
            /*  let request = new TDFRequest({
              url:
                "/action/getviewsonly/?" +
                $.param({ itemtype: iBase.ItemType }),
              type: "GET"
            });
            request.MakeRequest()*/
            itemDataService
              .ViewsOnly({ itemtype: iBase.ItemType })
              .done(function (response: any) {
                iMain = new ItemMain(response.ActionsModel.iMain);
                $.each(response.ActionsModel.ViewList, function (key, val: any) {
                  val.text = val.MenuText;
                });
                iBase.Views = response.ActionsModel.ViewList;
                dfd.resolve(response.ActionsModel.ViewList);
              });
            return dfd.promise();
          }
        }),
        itemTemplate: function (itemData, itemIndex, itemElement) {
          if (
            !itemElement
              .parent()
              .parent()
              .parent()
              .hasClass("dx-scrollview-content")
          ) {
            $(
              itemElement
                .parent()
                .parent()
                .parent()
            ).dxScrollView();
          }
          $("<div />")
            .dxButton({ icon: "", text: itemData.MenuText })
            .appendTo(itemElement);
        },
        onItemClick: Debounce(function (data: any) {
          let itemInfo = iBase;
          if (data.itemData.Action) {
            data.itemData.iteminfo = itemInfo;
            let views = new MenuView(data.itemData);
            menu.hide();
          }
        }, 500),
        visible: true
      })
      .dxActionSheet("instance");
    menu.show();
  }

  EmailDetails() {
    let iBase = this;
    let subjectcontrol: IControlData;
    let subject = "";
    $.each(iBase.Controls, (k, v: any) => {
      if (!subjectcontrol) {
        let control = v.filter((item: IControlData) => {
          return item.Properties.TDFField === "Subject";
        });
        if (control && control.length && control[0].Dx) {
          subject = control[0].Dx.option("value");
        } else {
        }
      }
    });

    if (subjectcontrol && subjectcontrol.Dx) {
      subject = subjectcontrol.Dx.option("value");
    } else {
      if (subjectcontrol) {
        if (subjectcontrol.SaveValue) {
          subject = subjectcontrol.SaveValue;
        } else {
          if (subjectcontrol.DataValue) {
            subject =
              subjectcontrol.DataValue.ValueName || iBase.iMain.ItemSubject;
          }
        }
      } else {
        subject = iBase.iMain.ItemSubject;
      }
    }

    let emailGen = new EmailGenerator(
      { ItemId: iBase.ItemID, ItemType: iBase.ItemType, iMain: iBase.iMain },
      subject || "",
      "",
      "",
      true
    );
  }

  Refresh() {
    let iBase = this;
    iBase.Controls = null;
    iBase.ControlGroups = null;
    $("#" + iBase.ContentID)
      .find(".dx-scrollview-content")
      .empty();
    $("#" + iBase.ContentID)
      .find(".dx-scrollview-content")
      .append(
        $("<div />")
          .addClass("tdfitemtabs")
          .attr({ id: iBase.UpperTabsID })
      )
      .append(
        $("<div />")
          .addClass("tdfitemtabs")
          .attr({ id: iBase.LowerTabsID })
      );

    iBase.GetControlGroups().done(function () {
      let controls = iBase.ControlGroups.filter(function (group) {
        return group.ControlGroupOrder === 0 && group.Visible;
      }).map(k => k.ControlGroupID);
      iBase.GetControls(controls).done(function () {
        iBase.RenderControlGroups();
      });
    });
    return iBase;
  }

  CanSave() {
    let iBase = this;
    /*TODO: Determine whether the item can be saved ... aren't i doing this already? Should this default to false?*/
    return iBase.CanEdit;
  }

  Close() {
    let iBase = this;
    // if (iBase.NoteControls && iBase.NoteControls.length) {
    //   let notesDirty: boolean = false;
    //   $.each(iBase.NoteControls, (k, v) => {
    //     if (v.NoteEdit.checkDirty()) {
    //       let idx = $.inArray(v.ControlData, iBase.DirtyControls);
    //       if (idx < 0) {
    //         notesDirty = true;
    //       } else {
    //         if (iBase.DirtyControls[idx].DataValue !== v.NoteEdit.getData()) {
    //           iBase.DirtyControls[idx].DataValue = encodeURIComponent(
    //             v.NoteEdit.getData()
    //           );
    //         }
    //       }
    //     }
    //   });
    //   if (notesDirty) {
    //     iBase.Dirty = true;
    //   }
    // }

    if (iBase.Dirty) {

      let pendingChangeDialog = custom({
        title: 'Pending Changes',
        messageHtml: `Changes have been made to this ${iBase.DisplayName} that have not been saved.`,
        buttons: [
          {
            text: "Save & Close",
            icon: "save",
            type: "success",
            onClick: function (e: any) {
              iBase.SaveClose();
              pendingChangeDialog.hide();
              return;
            }
          }, {
            text: "Close Without Saving",
            icon: "check",
            type: "default",
            onClick: function (e: any) {
              if (window["TDFDirty"]) {
                delete window["TDFDirty"];
              }
              if (!iBase.IsNavigatorItem) {
                iBase.ItemForm.close(true);
              } else {
                if (iBase.Navigator) {
                  iBase.Navigator.IsSafeClose = true;
                  iBase.Dirty = false;
                  iBase.Navigator.RemoveCurrent();
                }
              }

              pendingChangeDialog.hide();
            }
          }, {
            text: "Cancel",
            icon: "revert",
            type: "danger",
            onClick: function (e: any) {
              if (!iBase.IsNavigatorItem) {
                iBase.ItemForm.CloseDeferred = null;
              }

              pendingChangeDialog.hide();
              return;
            }
          }
        ]
      });

      pendingChangeDialog.show();
      return;
    }
    if (!iBase.IsNavigatorItem) {
      iBase.ItemForm.close(true);
    } else {
      if (iBase.Navigator) {
        iBase.Navigator.IsSafeClose = true;
        iBase.Navigator.RemoveCurrent();
      }
    }
    return iBase;
  }


  SaveClose() {

    let iBase = this;


    iBase.Save().done(() => {

      if (!iBase.IsNavigatorItem) {
        setTimeout(() => {
          iBase.ItemForm.close(true);
        }, 200);
      } else {
        if (iBase.Navigator) {
          setTimeout(() => {
            iBase.Dirty = false;
            iBase.Navigator.IsSafeClose = true;
            iBase.Navigator.RemoveCurrent();
          }, 200);
        }
      }

    });



    return iBase;
  }

  Save() {
    let iBase = this;
    let dfd = $.Deferred();

    if (iBase.DirtyControls && iBase.DirtyControls.length) {
      // if (iBase.NoteControls && iBase.NoteControls.length) {
      //   let notesDirty: boolean = false;
      //   $.each(iBase.NoteControls, (k, v) => {
      //     if (v.NoteEdit.checkDirty()) {
      //       let idx = $.inArray(v.ControlData, iBase.DirtyControls);
      //       if (idx < 0) {
      //         notesDirty = true;
      //       } else {
      //         if (iBase.DirtyControls[idx].DataValue !== v.NoteEdit.getData()) {
      //           iBase.DirtyControls[idx].DataValue = encodeURIComponent(
      //             v.NoteEdit.getData()
      //           );
      //         }
      //       }
      //     }
      //   });
      //   if (notesDirty) {
      //     setTimeout(() => {
      //       iBase.Save();
      //       //  dfd.resolve(iBase)
      //     }, 100);

      //     return dfd.promise(<any>dfd.resolve());
      //   }
      // }
      $.each(iBase.DirtyControls, (k, v: IControlData) => {
        if (v.Dx) {
          delete v.Dx;
        }
      });
      let d = {
        itemid: iBase.ItemID,
        itemtype: iBase.ItemType,
        dirtylaundry: iBase.DirtyControls, //JSON.stringify(iBase.DirtyControls),
        parentid:
          iBase.isNew && iBase.iMain
            ? iBase.iMain.ItemId
            : iBase.iMain.PrimaryParent.ParentId,
        isNew: iBase.isNew,
        requiresParent: iBase.RequiresParentItem,
        ContainerID: iBase.ContainerItemID || ""
      };
      /*  let request = new TDFRequest({
        url: "/Item/Save/",
        type: "POST",
        data: d,
        contentType: "application/json"
      });
      request.spinnerContainerID = iBase.ContentID;
      request.MakeRequest()*/
      itemDataService.Save(d, iBase.ContentID)
        .done(function (data) {
          // NEEDTODO:  How to handle failures here?
          //if (data.Valid) {
          new Notification({
            message: "Success ",
            type: "success",
            displayTime: 1500,
            shading: false,
            position: { my: "top" }
          });
          if (iBase.DirtyControls) iBase.DirtyControls = [];
          iBase.Dirty = false;

          let addressDFD = $.Deferred();
          addressDFD.promise();

          if (iBase.isNew) {
            if (iBase.AddressDataList && iBase.AddressDataList.length) {
              itemDataService.UpdateAddress(iBase.AddressDataList).done(r => {
                addressDFD.resolve();
              });

            } else {
              addressDFD.resolve();
            }

            iBase.isNew = false;
            iBase.iMain = data;

            try {
              if (data.ItemUniqueID) {
                iBase.UpdateControlAfterSave(data);
              }
            } catch (e) {
              console.log(e);
            }

            iBase.RenderHeader();
            itemDataService.AddToRecent(iBase.ItemID, iBase.ItemType);
            iBase.ClearInitialData();
          } else {
            addressDFD.resolve();
          }

          // Handle any random updates that need to happen post save.  This does things like load the quick notes control on an item if the user attempted to load it when the item was new.
          if (iBase.OnSaveHandlers.length > 0) {
            $.each(iBase.OnSaveHandlers, function (index, actionToRun) {
              iBase.OnSaveHandlers[index]();
            });

            iBase.OnSaveHandlers = [];
          }

          iBase.ChangeState();
          addressDFD.done(function () {
            dfd.resolve();
          });
          // } else {
          //   if (data.Message) {
          //     new Notification({
          //       message: data.Message,
          //       type: "error",
          //       displayTime: 5000,
          //       shading: true
          //     });
          //   }
          //   dfd.resolve();
          // }
        })
        .fail((data) => {
          if (data.MissingFields) {
            let buildMsg = () => {
              let fieldList: string = '';
              data.MissingFields.forEach(field => {
                fieldList += `<li>${field}</li>`;
              });

              return fieldList;
            }

            alert(
              `<p>The following fields do not meet requirements:</p><ul>${
              buildMsg()
              }</ul>`,
              'Error: Required Fields'
            )
          } else {
            new Notification({
              message: data.Message,
              type: 'error'
            });
          }
        });
    } else {
      // if (iBase.NoteControls && iBase.NoteControls.length) {
      //   let notesDirty: boolean = false;
      //   $.each(iBase.NoteControls, (k, v) => {
      //     if (v.NoteEdit.checkDirty()) {
      //       if ($.inArray(v.ControlData, iBase.DirtyControls) < 0) {
      //         notesDirty = true;
      //       }
      //     }
      //   });
      //   if (notesDirty) {
      //     setTimeout(() => {
      //       iBase.Save();
      //     }, 100);
      //     return dfd.promise(dfd.resolve());
      //   }
      // }
      new Notification({
        message: "No values to save.",
        type: "warning",
        displayTime: 1500,
        shading: false,
        position: { my: "bottom" }
      });
      dfd.resolve();
    }

    return dfd.promise();
  }

  private ClearInitialData() {
    let iBase = this;
    if (iBase.InitialSubject) {
      iBase.InitialSubject = null;
    }
    if (iBase.InitialBody) {
      iBase.InitialBody = null;
    }
  }

  private UpdateControlAfterSave(data) {
    let iBase = this;
    let control = iBase.GetControlByTDFField("UniqueId");
    if (control) {
      let changeEvent = control.Dx.option("onValueChanged");
      control.Dx.option("onValueChanged", null);
      control.Dx.option("value", data.ItemUniqueID);
      control.Dx.option("onValueChanged", changeEvent);
    }
  }

  GetControlByTDFField(TDFField: string) {
    let iBase = this;
    let control: any = "";
    Object.keys(iBase.Controls).forEach(k => {
      let itis = iBase.Controls[k].filter(controlData => {
        return (
          controlData.Properties.TDFField.toLowerCase() ===
          TDFField.toLowerCase()
        );
      });
      if (itis && itis.length) {
        control = itis;
      }
    });
    return control[0];
  }

  SaveCopy() {
    let iBase = this;

    iBase.Save().done(() => {
      //let theurl =
      //  "/item/copyitem/?itemid=" +
      //  iBase.iMain.ItemId +
      //  "&itemtype=" +
      //  iBase.iMain.ItemType;

      //let req = new TDFRequest({ url: theurl, type: "GET" });

      //req.MakeRequest()

      itemDataService
        .Copy({ itemid: iBase.ItemID, itemtype: iBase.ItemType })
        .done(function (data) {
          createitem(iBase.iMain.ItemType, { ItemID: data });
        });
    });
  }

  Delete() {
    let iBase = this;

    confirm('Are you sure you want to delete this item?', 'Delete Item').done(answer => {
      if (answer) {
        itemDataService
          .Delete([{ ItemId: iBase.ItemID, ItemType: iBase.ItemType }])
          .done(function (response) {
            //NEEDTODO:  response no longer is an object.  It only includes the Message.  Need to re-consider how to deal with reporting errors/inability to delete, and also handle closing the item only if deleting was successful.
            new Notification({
              message: response,
              type: "success",
              displayTime: 4500
            });
            if (!iBase.IsNavigatorItem) {
              if (response) {
                iBase.ItemForm.close(true);
              }
            } else {
              if (iBase.Navigator) {
                iBase.Navigator.IsSafeClose = true;
                iBase.Navigator.RemoveCurrent();
              }
            }
          });
      }
    });
  }

  GetControlGroups() {
    let iBase = this;
    let dfd = $.Deferred();
    //let request = new TDFRequest({
    //  url:
    //    "/item/getcontrolgroups/?" +
    //    $.param({ itemtype: iBase.ItemType, itemid: iBase.ItemID }),
    //  type: "GET"
    //  //data: { itemtype: iBase.ItemType, itemid: iBase.ItemID }
    //});

    //$.when(request.MakeRequest())
    itemDataService
      .ControlGroups({ itemid: iBase.ItemID, itemtype: iBase.ItemType })
      .done(function (response) {
        if (true) {
          iBase.ControlGroups = response /*.Data*/.controlgroups;
          iBase.FormTypeInfo = response /*.Data*/.FormTypeInfo;
          iBase.EnableDelete = response /*.Data*/.EnableDelete;
          iBase.iMain = response /*.Data*/.imain;
          if (
            iBase.ItemType === itemTypes.itemTask &&
            typeof response /*.Data*/.EnableReassign !== "undefined"
          ) {
            iBase.EnableReassign = response /*.Data*/.EnableReassign;
          }
          iBase.CanEdit = response /*.Data*/.EnableEdit;
          dfd.resolve(iBase);
        } else {
          new Notification({
            type: "error",
            shading: true,
            displayTime: 5000,
            message: response.Message || "Something went wrong."
          });
        }
      });

    return dfd.promise(iBase);
  }

  RenderHeader() {
    let iBase = this;
    let switched = false; // flag for inactive/active switch so it knows to switch back when no is selected.
    if ($("#" + iBase.HeaderID).children().length) {
      $("#" + iBase.HeaderID).empty();
    }
    let headerarea = $("<div />")
      .attr({ id: iBase.HeaderID })
      .addClass("item-header-container")
      .insertBefore($("#" + iBase.UpperTabsID));
    // if (!checkDevice().isDevice) {
    // headerarea.css("margin-top", "-15px")
    // }
    let accordopts: DevExpress.ui.dxAccordionOptions = {
      items: [{ title: "Something", text: "Something" }],
      itemTitleTemplate: function (data, index, element) {
        let small = BuildBreadcrumbs(iBase.iMain, iBase.ItemType);
        element.append(small);
      },
      selectedIndex: -1,
      onItemClick: function (e) {
        if (
          !$(
            "#" +
            iBase.TypeName +
            "-header-active-switch-container-" +
            iBase.DomSafeID
          ).hasClass("dx-switch")
        ) {
          let canEdit = $.grep(CurrentUser.Rights, function (key, val) {
            return key === 123;
          });
          $(
            "#" +
            iBase.TypeName +
            "-header-active-switch-container-" +
            iBase.DomSafeID
          )
            .dxSwitch({
              switchedOnText: "Active",
              switchedOffText: "InActive",
              width: "75",
              disabled: canEdit.length === 0,
              value: iBase.iMain.ItemActive === 1,
              onValueChanged: function (data) {
                if (switched === false) {
                  let previousval = data.previousValue;
                  let newVal = data.value;

                  let showdialog: boolean =
                    Preferences.GetPreference(
                      "Update_ChildActiveFlagsDialog",
                      "Dialogs"
                    ) === "0";
                  let updateChildren: boolean =
                    Preferences.GetPreference(
                      "Update_ChildActiveFlags",
                      "Dialogs"
                    ) === "1";


                  confirm(
                    previousval
                      ? "Are you sure you want to set this item to INACTIVE?"
                      : "Are you sure you want to set this item to ACTIVE?",
                    previousval
                      ? "Set INACTIVE?"
                      : "Set ACTIVE?"
                  ).done(changeActiveInput => {
                    if (changeActiveInput) {
                      if (showdialog) {

                        confirm(
                          'Update child items as well?',
                          'Update Children?'
                        ).done(updateChildrenInput => {
                          itemDataService
                            .ToggleActiveFlag({
                              itemid: iBase.ItemID,
                              itemtype: iBase.ItemType,
                              active: newVal,
                              children: updateChildrenInput ? 1 : 0
                            })
                            .done(response => {
                              if (true) {
                                new Notification({
                                  message: "Active Flag Updated",
                                  type: "success"
                                });
                              } else {
                                new Notification({
                                  message: response.Message,
                                  type: "error"
                                });
                              }
                            });
                        });

                      } else {
                        itemDataService
                          .ToggleActiveFlag({
                            itemid: iBase.ItemID,
                            itemtype: iBase.ItemType,
                            active: newVal,
                            children: updateChildren ? 1 : 0
                          })
                          .done(response => {
                            if (true) {
                              new Notification({
                                message: "Active Flag Updated",
                                type: "success"
                              });
                            } else {
                              new Notification({
                                message: response.Message,
                                type: "error"
                              });
                            }
                          });
                      }
                    } else {
                      switched = true;
                      data.component.option('value', data.previousValue);
                    }
                  });

                }
                // Reset flag
                switched = false;
              }
            })
            .dxSwitch("instance");

          if (
            $("#" + iBase.TypeName + "-ItemFormType-" + iBase.DomSafeID).length
          ) {
            $(
              "#" + iBase.TypeName + "-ItemFormType-" + iBase.DomSafeID
            ).dxSelectBox({
              dataSource: <any>iBase.FormTypeInfo.AllFormTypes,
              valueExpr: "ID",
              width: "50%",
              displayExpr: "Name",
              value:
                iBase.iMain.ItemFormType ||
                iBase.FormTypeInfo.DefaultItemFormTypeId,
              onValueChanged: function (e) {
                if (e.value !== iBase.iMain.ItemFormType) {
                  iBase.UpadateItemFormType(e.value);
                }
                // TODO:warn the user that changing the form type can affect udfvalues then change the form type and refresh the item
              },
              onOpened: function (e) {
                if (iBase.isNew) {
                  new Notification({
                    type: "warning",
                    shading: true,
                    displayTime: 2000000,
                    message:
                      "This item must be saved before changing the Item Form Type"
                  });
                }
              }
            });
          }
        }
      },
      deferRendering: false,
      itemTemplate: function (
        itemData: any,
        itemIndex: number,
        itemElement: JQuery
      ) {
        let theAccordion = this;
        let pinRowContain = $("<div  />").addClass("row");

        //TODO: Refactor sticky pin to enable it to be used anywhere we would want something to be sticky
        //Refactor:
        let pinContain = $("<div />")
          .addClass("col-md-12")
          .appendTo(pinRowContain);
        let pin = $("<div />")
          .addClass("btn pull-right header-sticky-btn")
          .attr(
            "id",
            iBase.TypeName +
            "-itemHeaderPin-" +
            iBase.ItemID.replace(/[\{\}]/g, "")
          )
          .attr("title", "Keep Header Open")
          .on("click", function () {
            if ($(this).hasClass("header-sticky-active")) {
              $(this).removeClass("header-sticky-active");
              Preferences.SetPreference(
                "KeepItemHeaderOpen",
                0,
                "ManagersConsole"
              );
              theAccordion.option("collapsible", true);
              $(this)
                .first()
                .css("color", "#333");
              theAccordion.collapseItem(0);
            } else {
              $(this).addClass("header-sticky-active");
              Preferences.SetPreference(
                "KeepItemHeaderOpen",
                1,
                "ManagersConsole"
              );
              theAccordion.option("collapsible", false);
              $(this)
                .first()
                .css("color", "#f89c20");
            }
          })
          .appendTo(pinContain);

        let pinIcon = $("<span  aria-hidden='true' />")
          .addClass("fa fa-thumb-tack sticky-pin-btn")
          .appendTo(pin);

        let pref = parseInt(
          Preferences.GetPreference("KeepItemHeaderOpen", "ManagersConsole")
        );

        if (pref) {
          $(pin).addClass("header-sticky-active");
          theAccordion.option("collapsible", false);
          $(pin)
            .first()
            .css("color", "#f89c20");
        } else {
          $(".header-sticky-btn").removeClass("header-sticky-active");
          theAccordion.option("collapsible", true);
          $(pin)
            .first()
            .css("color", "#333");
        }

        $(itemElement).append(pinRowContain);

        let keys = {
          "Item Key: ": "ItemKey",
          "Creation: ": "ItemCreation",
          "Created By: ": "ItemCreatedBy",
          "Active: ": "ItemActive",
          "Owner: ": "ItemOwner",
          "Last Mod: ": "ItemModDate",
          "Last Mod By: ": "ItemModifiedBy",
          "": ""
        };
        if (iBase.FormTypeInfo && iBase.FormTypeInfo.UseItemFormTypes) {
          keys["Item Form Type:"] = "FormType";
        }

        let headerFieldRow = $('<div />').addClass('row');

        $.each(keys, function (key, val) {
          let col = $("<div />").addClass("col-md-2");
          let span = $("<span />").appendTo(col);
          let label = $("<strong />")
            .text(key)
            .appendTo(span);
          if (val === "ItemModDate" || val === "ItemCreation") {
            $("<span />")
              .text(
                Globalize.formatDate(new Date(iBase.iMain[val]), {
                  date: "short"
                })
              )
              .appendTo(span);
          } else if (val == "ItemActive") {
            $("<div />")
              .attr(
                "id",
                iBase.TypeName +
                "-header-active-switch-container-" +
                iBase.DomSafeID
              )
              .appendTo(span);
          } else if (val == "FormType") {
            $("<div />")
              .attr({ id: iBase.TypeName + "-ItemFormType-" + iBase.DomSafeID })
              .appendTo(span);
          } else {
            $("<span />")
              .text(iBase.iMain[val])
              .appendTo(span);
          }
          headerFieldRow.append(col);
        });

        itemElement.append(headerFieldRow);
      },
      collapsible: true
    };

    let theAccordion = $("<div>").dxAccordion(accordopts);

    $(headerarea).append(theAccordion);

    return iBase;
  }

  UpadateItemFormType(formtypeid) {
    let iBase = this;

    let message: any = {};

    message.text = $("<span />").text(
      "Changing the Form Type can affect UDF Values"
    );
    message.buttons = $("<div />")
      .append(
        $("<span />")
          .css("margin", "0 5px")
          .append(
            $("<div />").dxButton({
              text: "Continue",
              icon: "check",
              type: "success",
              onClick: function (e: any) {
                let toast = $($(e.element[0]).parents())
                  .find(
                    ".dx-overlay.dx-widget.dx-visibility-change-handler.dx-toast"
                  )
                  .dxToast("instance");
                toast.hide();
                //new TDFRequest({
                //  url: "/Item/UpdateItemFormType/",
                //  data: {
                //    formtype: formtypeid,
                //    itemid: iBase.ItemID,
                //    itemtype: iBase.ItemType
                //  }
                //})
                //  .MakeRequest()
                itemDataService
                  .UpdateItemFormType({
                    newformtype: formtypeid,
                    itemid: iBase.ItemID,
                    itemtype: iBase.ItemType
                  })
                  .done(function (response) {
                    if (true) {
                      iBase.Refresh();
                    } else {
                      new Notification({
                        message: response.Message,
                        type: "error"
                      });
                    }
                  });
                return;
              }
            })
          )
      )
      .append(
        $("<span />")
          .css("margin", "0 5px")
          .append(
            $("<div />").dxButton({
              text: "Cancel",
              icon: "revert",
              type: "danger",
              onClick: function (e: any) {
                let toast = $($(e.element[0]).parents())
                  .find(
                    ".dx-overlay.dx-widget.dx-visibility-change-handler.dx-toast"
                  )
                  .dxToast("instance");
                toast.hide();
                let control = $(
                  "#" + iBase.TypeName + "-ItemFormType-" + iBase.DomSafeID
                ).dxSelectBox("instance");
                control.option("value", iBase.iMain.ItemFormType);
                return;
              }
            })
          )
      );

    new Notification({
      type: "warning",
      shading: true,
      displayTime: 2000000,
      message: message
    });
  }

  GetControls(controlgroups) {
    let iBase = this;
    let dfd = $.Deferred();
    let getControlsParams = {
      controlgroups: controlgroups,
      itemid: iBase.ItemID,
      itemtype: iBase.ItemType,
      isnew: iBase.isNew
    };
    if (
      iBase.isNew &&
      iBase.iMain.ItemParents &&
      iBase.iMain.ItemParents.length
    ) {
      getControlsParams["PendingParentID"] =
        iBase.iMain.ItemParents[0].ParentId;
    }
    //let request = new TDFRequest({
    //  url: "/item/getcontrols/",
    //  type: "POST",
    //  data: getControlsParams
    //});
    //request.ShouldShowSpinner = true;

    //request.MakeRequest()
    itemDataService.Controls(getControlsParams).done(function (response) {
      if (!iBase.Controls) {
        iBase.Controls = response.controls;
      } else {
        $.each(response.controls, function (key: string, val) {
          if (!iBase.Controls[key]) {
            iBase.Controls[key] = val;
          }
        });
      }

      dfd.resolve(iBase);
    });
    return dfd.promise(iBase);
  }

  SetDirty(control: IControlData) {
    let iBase = this;

    iBase.Dirty = true;
    iBase.DirtyControls = iBase.DirtyControls || [];
    //  let ctrl = JSON.stringify(control);
    if ($.inArray(control, iBase.DirtyControls) < 0) {
      iBase.DirtyControls.push(control);
    } else {
      iBase.DirtyControls.splice(
        $.inArray(control, iBase.DirtyControls),
        1,
        control
      );
    }

    iBase.ChangeState();

    if (control.Properties.UDFType === 1) {
      let val: any = $.grep(control.PossibleValues, (v: any, i) => {
        return control.SaveValue === v.ValueName;
      });

      if (val && val.length) {
        //new TDFRequest({
        //  url: "/item/GetValuesForSubUDF/",
        //  data: {
        //    udfid: control.Properties.Control_ID,
        //    groupid: control.Properties.ControlGroup_ID,
        //    valueid: val[0].Value_ID
        //  }
        //})
        //  .MakeRequest()
        itemDataService
          .ValuesForSubUDF({
            udfid: control.Properties.Control_ID,
            groupid: control.Properties.ControlGroup_ID,
            valueid: val[0].Value_ID
          })
          .done(response => {
            let qid =
              "Element_" + control.Properties.UDFLink_ID + iBase.DomSafeID;

            let rudfel = $("#" + qid);
            if (rudfel && rudfel.length) {
              let rudf = $.grep(
                iBase.Controls[control.Properties.ControlGroup_ID],
                (v: any, i) => {
                  return (
                    v.Properties.Control_ID === control.Properties.UDFLink_ID
                  );
                }
              );
              if (rudf.length) {
                let controlData: IControlData = rudf[0];
                controlData.PossibleValues = response /*.Data*/;
                controlData.Dx.option("dataSource", response /*.Data*/);
                if (controlData.Dx["open"]) {
                  controlData.Dx["open"]();
                }
              }
            }
          });
      }
    }

    return iBase;
  }

  ChangeState() {
    let iBase = this;
    $(".dx-item-content.dx-toolbar-item-content :contains('Save')").removeClass(
      "successflash"
    );
    $(
      ".dx-item-content.dx-toolbar-item-content :contains('Save & Copy')"
    ).removeClass("successflash");
    if (iBase.ItemForm && iBase.ItemForm.Instance) {
      if (iBase.Dirty) {
        if (!window["TDFDirty"]) {
          window["TDFDirty"] = true;
        }
        iBase.ItemForm.setType("type-warning");
      } else {
        if (window["TDFDirty"]) {
          window["TDFDirty"] = false;
        }
        iBase.ItemForm.setType("type-primary");
      }
    }
    if (iBase.Dirty) {
      $(".dx-item-content.dx-toolbar-item-content :contains('Save')").addClass(
        "successflash"
      );
      $(
        ".dx-item-content.dx-toolbar-item-content :contains('Save & Copy')"
      ).addClass("successflash");
    }
    return iBase;
  }

  FirefoxBugFix(theDiv, browser) {
    if (browser.indexOf("firefox") > -1) {
      setTimeout(function () {
        let val =
          parseInt($(theDiv).css("padding-bottom")) >= 1 ? "0px" : "1px";
        $(theDiv).css("padding-bottom", val);
      }, 500);
    }
  }

  RenderControlGroups() {
    let iBase = this;
    let dfd = $.Deferred();
    let browser = navigator.userAgent.toLowerCase();
    if (!iBase.ControlGroups) {
      console.warn(
        "Control groups have not been loded. You need to call [Item].GetControlGroups() first."
      );
      return;
    }
    iBase.RenderHeader();
    let MainTabs = [];
    let ProfileTabs = [];

    let mainTabIndex = 0;
    let profileIndex = 0;

    $.each(iBase.ControlGroups, function (key, val) {
      if (val.GroupName.toUpperCase() === "MAIN") {
        MainTabs.push(val);
        if (
          val.DefaultTab ||
          val.ControlGroupID === iBase.StartingControlGroupID
        ) {
          mainTabIndex = MainTabs.length - 1;
        }
      } else {
        ProfileTabs.push(val);
        if (val.DefaultTab) {
          profileIndex = ProfileTabs.length - 1;
        }
      }
    });

    if (!GetDevice().isDevice) {
      if (MainTabs.length > 0) {
        iBase.MainTabs = $(
          "#" + iBase.TypeName + "-uppertabs-" + iBase.DomSafeID
        )
          .dxTabPanel({
            dataSource: MainTabs,
            selectedIndex: mainTabIndex,
            swipeEnabled: false,
            loop: false,
            showNavButtons: true,
            itemTitleTemplate: function (
              itemData: any,
              itemIndex: number,
              itemElement: JQuery
            ) {
              iBase.MainTabTitle(itemData, itemElement);
            },
            itemTemplate: function (
              itemData: any,
              itemIndex: number,
              itemElement: JQuery
            ) {
              iBase.TabContent(itemData, itemElement);
            },
            onSelectionChanged: function (e) {
              let theItem =
                e.addedItems.length > 0 ? e.addedItems[0] : e.removedItems[0];
              if (!theItem || theItem.length <= 0) return;
              iBase.CheckProfileVisibility(e, theItem);
            }
          })
          .addClass("upper-item-tabs")
          .collapse()
          .dxTabPanel("instance");
      }
      if (ProfileTabs.length > 0) {
        iBase.ProfileTabs = $(
          "#" + iBase.TypeName + "-lowertabs-" + iBase.DomSafeID
        )
          .dxTabPanel({
            dataSource: ProfileTabs,
            selectedIndex: profileIndex,
            swipeEnabled: false,
            showNavButtons: true,
            loop: false,
            itemTitleTemplate: function (
              itemData: any,
              itemIndex: number,
              itemElement: JQuery
            ) {
              iBase.ProfileTabTitle(itemData, itemElement);
            },
            itemTemplate: function (
              itemData: any,
              itemIndex: number,
              itemElement: JQuery
            ) {
              iBase.TabContent(itemData, itemElement);
            }
          })
          .addClass("lower-item-tabs")
          .collapse()
          .dxTabPanel("instance");
      } else {

        // If we got now profile tabs, clean things up a bit:

        try {
          $("#" + iBase.TypeName + "-lowertabs-" + iBase.DomSafeID).hide()
        } catch (e) {
          console.log(e);
        }

      }

      dfd.resolve().done(function () {
        //Dropdown fix for firefox browsers
        iBase.FirefoxBugFix(".upper-item-tabs", browser);
        iBase.FirefoxBugFix(".lower-item-tabs", browser);
        if (iBase.ItemType === itemTypes.itemOpportunity) {
          (<any>iBase).UpdateValueControls();
        }
      });
    } else {
      if (MainTabs.length > 0) {
        iBase.MainTabs = $("#" + iBase.UpperTabsID)
          .dxAccordion({
            dataSource: MainTabs,
            selectedIndex: mainTabIndex,
            collapsible: true,
            itemTitleTemplate: function (
              itemData: any,
              itemIndex: number,
              itemElement: JQuery
            ) {
              iBase.MainTabTitle(itemData, itemElement);
            },
            itemTemplate: function (
              itemData: any,
              itemIndex: number,
              itemElement: JQuery
            ) {
              iBase.TabContent(itemData, itemElement);
            },
            onSelectionChanged: function (e) {
              let theItem =
                e.addedItems.length > 0 ? e.addedItems[0] : e.removedItems[0];
              if (!theItem || theItem.length <= 0) return;
              // iBase.CheckProfileVisibility(e, theItem);
            }
          })
          .addClass("upper-item-tabs")
          .collapse()
          .dxAccordion("instance");
      }
      if (ProfileTabs.length > 0) {
        iBase.ProfileTabs = $("#" + iBase.LowerTabsID)
          .dxAccordion({
            dataSource: ProfileTabs,
            selectedIndex: profileIndex,
            collapsible: true,
            itemTitleTemplate: function (
              itemData: any,
              itemIndex: number,
              itemElement: JQuery
            ) {
              iBase.ProfileTabTitle(itemData, itemElement);
            },
            itemTemplate: function (
              itemData: any,
              itemIndex: number,
              itemElement: JQuery
            ) {
              iBase.TabContent(itemData, itemElement);
            }
          })
          .addClass("lower-item-tabs")
          .collapse()
          .dxAccordion("instance");
      }

      dfd.resolve().done(function () {
        //Collapse fix for firefox browsers
        iBase.FirefoxBugFix(".upper-item-tabs", browser);
        iBase.FirefoxBugFix(".lower-item-tabs", browser);
        if (iBase.ItemType === itemTypes.itemOpportunity) {
          (<any>iBase).UpdateValueControls();
        }
      });
    }

    CreateCollapsibleGroups(
      $(`#${iBase.TypeName}-uppertabs-${iBase.DomSafeID}`),
      "Main"
    );

    CreateCollapsibleGroups(
      $(`#${iBase.TypeName}-lowertabs-${iBase.DomSafeID}`),
      "Profile"
    );

    return dfd.promise();
  }

  protected CheckProfileVisibility(e, theItem: any) {
    let ltabs = $(
      "#" +
      $(e.element)
        .attr("id")
        .replace("uppertabs", "lowertabs")
    );
    if (ltabs.length) {
      if (ltabs.hasClass("dx-tabpanel")) {
        let lowertabs = $(
          "#" +
          $(e.element)
            .attr("id")
            .replace("uppertabs", "lowertabs")
        ).dxTabPanel("instance");
        theItem.GroupName === "Main" && theItem.ControlGroupOrder === 0
          ? lowertabs.option("visible", true)
          : lowertabs.option("visible", false);
      }
    }
  }

  protected ProfileTabTitle(itemData: any, itemElement: JQuery) {
    let caption = itemData.FrameCaption || "**Caption Not Set**";
    if (!itemData.FrameCaption) {
      if (itemData.ControlGroupOrder === 0) {
        caption = "Summary";
      }
    }
    itemElement.append("<b>" + caption + "</b>");
  }

  protected MainTabTitle(itemData: any, itemElement: JQuery) {
    let iBase = this;
    let caption = itemData.FrameCaption || "**Caption Not Set**";
    if (!itemData.FrameCaption) {
      if (itemData.ControlGroupOrder === 0) {
        caption = "Main";
      }
    }

    if (
      itemData.ControlGroupID === "SalesData" &&
      iBase.iMain.ItemAccountType === itemAccountTypes.Vendor
    ) {
      // When the control group caption is set, we have no idea if this is a regular sales account or a vendor account.  So this is done to rename the caption appropriately.
      caption = "Vendor BI Summary";
    }
    itemElement
      .attr({ id: `item-control-group-${DomSafeID(itemData.ControlGroupID)}` })
      .append("<b>" + caption + "</b>");
  }

  protected TabContent(itemData: any, itemElement: JQuery) {
    let iBase = this;
    let container = $("<div />").addClass("tdfitemtab_content");
    let child = $("<div/>").attr({
      id: (iBase.TypeName + "-" + itemData.Name + "-" + iBase.DomSafeID).replace(/ /g, '')
    });
    container.append(child);
    itemElement.append(container);
    if (
      itemData.CustomControlEnabled &&
      !itemData.UDFEnabled &&
      !itemData.GridEnabled
    ) {
      // this is for quicknotes
      if (iBase.isNew) {
        child.append($("<div>Please save the item before adding notes.</div>"));
        iBase.OnSaveHandlers.push(function () {
          child.empty();
          iBase.RenderCustomControl(child);
        });
      } else {
        iBase.RenderCustomControl(child);
      }
    } else {
      // this is for everything else
      iBase.RenderControls(itemData, child);
    }
  }

  RenderCustomControl(container) {
    let iBase = this;

    // For now, get the api path from LoadCompany
    $.when(LoadCompany()).done(() => {
      //new TDFRequest({
      //  url: TDFRequest.ApiPath + "/quicknotes/notes?itemId=" + iBase.ItemID,
      //  type: "GET"
      //})
      //  .MakeRequest()
      itemDataService.QuickNotes(iBase.ItemID).done(response => {
        let quickNotesSection = new QuickNotes(response, container, iBase);
        quickNotesSection.RenderQuickNoteTab();
      });
    });
  }

  RenderControls(itemData, container) {
    let iBase = this;
    let dfd = $.Deferred();

    if (!itemData.IsCustomTab) {
      let id = itemData.ControlGroupID;
      let group = container;
      if (iBase.Controls && iBase.Controls[id]) {
        iBase.GetControlElements(iBase.Controls[id], container, dfd);
      } else {
        iBase.GetControls(id).done(function () {
          iBase.GetControlElements(iBase.Controls[id], container, dfd);
        });
      }
    } else {
      iBase.RenderCustomTab(itemData, container, dfd);
    }

    return dfd.promise();
  }

  GetControlElements(controlsforgroup, container: JQuery, dfd) {
    let iBase = this;

    $.each(controlsforgroup, function (key, val1) {
      if (Object.keys(val1)[0] !== "LoadThirdPartyURL") {
        let control = new Control({ Properties: val1, TDFItem: iBase });

        let row_class =
          "_" +
          control.ControlData.Properties.Control_Row +
          "_" +
          DomSafeID(control.ControlData.Properties.ControlGroup_ID) +
          iBase.DomSafeID;

        let column_class =
          control.columnTypes[control.ControlData.Positions].colclass;

        let row: JQuery = $("." + row_class);

        if (row.length > 0) {
        } else {
          row = $("<div />").addClass("row " + row_class);
          column_class +=
            " " + control.offSets[control.ControlData.Positions].offset;
        }
        let cGroup = $("<div />")
          .addClass(column_class)
          .appendTo(row);

        let fGroup =
          control.ControlData.Properties &&
            control.ControlData.Properties.Control_Type !== 9
            ? $("<div />")
              .addClass("form-group")
              .appendTo(cGroup)
            : $("<div />").appendTo(cGroup);

        container.append(row);
        control.ConvertType();

        if (
          iBase.InitialSubject &&
          control.ControlData.Properties.TDFField.toUpperCase() === "SUBJECT"
        ) {
          if (
            iBase.ItemType !== itemTypes.itemContact &&
            iBase.ItemType !== itemTypes.itemLead
          ) {
            control.ControlData.DataValue.ValueName = iBase.InitialSubject;
            control.ControlData.SaveValue = iBase.InitialSubject;
            iBase.SetDirty(control.ControlData);
          }
        }
        if (
          iBase.InitialSubject &&
          control.ControlData.Properties.TDFField.toUpperCase() ===
          "COMPANYNAME"
        ) {
          if (iBase.ItemType === itemTypes.itemLead) {
            control.ControlData.DataValue.ValueName = iBase.InitialSubject;
            control.ControlData.SaveValue = iBase.InitialSubject;
            iBase.SetDirty(control.ControlData);
          }
        }
        if (
          iBase.InitialBody &&
          control.ControlData.Properties.TDFField.toUpperCase() === "NOTES"
        ) {
          control.ControlData.DataValue.ValueName = iBase.InitialBody;
          control.ControlData.SaveValue = iBase.InitialBody;
          iBase.SetDirty(control.ControlData);
        }

        if (control.ControlData.Properties.TDFField.toUpperCase() === "BSCONTACTID") {
          if (!DoesUserHaveRight(tdfRights.rightEditBSContactID)) {
            control.ControlData.Properties.ReadOnly = true;
          }
          else {
            // check if this item's BSContactID is being maintained by Sync.
            let request = new TDFRequest({ url: "/BI/CheckContactMaintainedBySync", data: { "itemid": iBase.ItemID }, type: "POST" });
            request.MakeRequest().done(function (response) {
              // Update the ReadOnly property, but also update the control directly because it's already been painted.
              let maintained: boolean = <boolean>response;
              control.ControlData.Properties.ReadOnly = maintained;
              if (maintained) $(control.jqElement).find("input").prop("disabled", true);
            });
          }
        }

        if (!iBase.CanEdit) {
          if (iBase.ItemType === itemTypes.itemQuote) {
            if (
              iBase["QuoteDetails"].ERPProcessStatus !==
              EnumERPProcessStatus.Normal &&
              iBase["QuoteDetails"].ERPProcessStatus !==
              EnumERPProcessStatus.Processed
            ) {
              control.ControlData.Properties.Locked = 1;
            }
          } else {
            control.ControlData.Properties.Locked = 1;
          }
        }

        if (control.ConvertedType.toLowerCase() === "note") {
          cGroup.removeClass();
          cGroup.addClass("col-md-12");

          fGroup
            .append(
              $("<label />")
                .text(control.ControlData.Properties.LabelCaption)
                .css({
                  "background-color":
                    control.ControlData.Properties.LabelBackColor,
                  color: control.ControlData.Properties.LabelForeColor
                })
                .append(
                  $('<span />')
                    .text('*')
                    .css({
                      'color': 'red',
                      'display': control.ControlData.Properties.Required
                        ? 'unset'
                        : 'none'
                    })
                )
            )
            .append(
              $("<div />").attr({
                id:
                  "note_" +
                  control.ControlData.Properties.Control_ID +
                  iBase.DomSafeID
              })
            );
          // pass the id used so we can use the replace function on the element
          control[control.ConvertedType](
            "note_" +
            control.ControlData.Properties.Control_ID +
            iBase.DomSafeID
          );
          iBase.NoteControls.push(control);
        } else {


          console.log(control.ConvertedType);

          if (control.ConvertedType == "Timestamp") {
            //	debugger
          }

          control[control.ConvertedType]();

          try {

            control.jqElement.attr({
              id:
                "Element_" +
                control.ControlData.Properties.Control_ID +
                control.TDFItem.DomSafeID
            });

          } catch (e) {
            console.log(e);
          }

          if (control.ConvertedType !== "Label") {

            let label = $("<label />")
              .text(control.ControlData.Properties.LabelCaption)
              .css({
                "background-color":
                  control.ControlData.Properties.LabelBackColor,
                color: control.ControlData.Properties.LabelForeColor
              })

            if (control.ControlData.Properties.Required) {
              label.append(
                $('<span />')
                  .text('*')
                  .css('color', 'red')
              );

            }

            if (control.ControlData.Properties.FieldTrackingType > 0) {
              label.on("click", function () {
                $.when(iBase.GetChangeLogData()).done(function () {
                  if (iBase.ChangeLogData) {
                    let changes = $.grep(iBase.ChangeLogData, function (
                      val: IChangeLogData
                    ) {
                      return (
                        val.UDFName === control.ControlData.Properties.MainUDF
                      );
                    });
                    if (changes.length) {
                      iBase.GetChangeLogPopup(changes, control.jqElement);
                    }
                  }
                });
              });
            }

            if (control.ConvertedType === "LinkedAccountDropDown") {
              label.addClass("btn btn-info btn-xs").on("click", function (e) {
                if (
                  control.ControlData.DataValue &&
                  control.ControlData.DataValue.ValueName
                ) {
                  createitem(itemTypes.itemAccount, {
                    ItemID: control.ControlData.DataValue.ValueName
                  });
                }
              });
            }
            if (control.ConvertedType === "LinkedContactDropDown") {
              label.addClass("btn btn-info btn-xs").on("click", function (e) {
                if (
                  control.ControlData.DataValue &&
                  control.ControlData.DataValue.ValueName
                ) {
                  createitem(itemTypes.itemContact, {
                    ItemID: control.ControlData.DataValue.ValueName
                  });
                }
              });
            }
            if (
              control.ConvertedType === "DropDownListWithAction" &&
              control.ControlData.Properties.TDFField === "SalesStage"
            ) {
              let historyButton = $("<span />")
                .html("<i class='fa fa-clock-o' aria-hidden='true'></i>")
                .css({ "margin-left": "20px", "font-size": "14px" })
                .addClass("salesHistoryBtn btn")
                .attr("title", "Show Editable Sales History")
                .on("click", function (e) {
                  if (!$(".sales-stage-grid-container").length) {
                    //   $(".showStageGrid").attr('disabled', 'disabled');
                    //new TDFRequest({
                    //  url: "/item/SalesStageHistory/?oppid=" + iBase.ItemID
                    //})
                    //  .MakeRequest()
                    itemDataService
                      .SalesStageHistory(iBase.ItemID)
                      .done(response => {
                        if (true) {
                          //$("#Element_" + theControl.ControlData.Properties.Control_ID + theControl.TDFItem.DomSafeID).css("display", "none");
                          $(
                            "#Element_" +
                            control.ControlData.Properties.Control_ID +
                            iBase.DomSafeID
                          )
                            .parent()
                            .parent()
                            .append(
                              $(
                                "<div class='sales-stage-grid-container' />"
                              ).dxDataGrid({
                                dataSource: response /*.Data*/.History,
                                editing: {
                                  mode: "row",
                                  allowUpdating: response /*.Data*/.CanEdit,
                                  allowAdding: response /*.Data*/.CanEdit,
                                  allowDeleting: response /*.Data*/.CanEdit
                                },
                                columns: [
                                  {
                                    dataField: "SalesStage",
                                    lookup: {
                                      dataSource:
                                        control.ControlData.PossibleValues,
                                      displayExpr: "ValueName",
                                      valueExpr: "ValueName"
                                    }
                                  },
                                  {
                                    dataField: "SalesStageDate",
                                    dataType: "date",
                                    format: {
                                      type: "shortDateShortTime"
                                    },
                                    editorOptions: {
                                      type: "datetime"
                                    },
                                    sortIndex: 0,
                                    sortOrder: "asc"
                                  },
                                  {
                                    dataField: "ModifiedBy",
                                    dataType: "string",
                                    allowEditing: false
                                  },
                                  {
                                    dataField: "Days",
                                    dataType: "number",
                                    allowEditing: false
                                  },
                                  {
                                    dataField: "Notes",
                                    dataType: "string"
                                  },
                                  {
                                    dataField: "SalesProcessId",
                                    visible: false
                                  }
                                ],
                                onInitNewRow(e) {
                                  e.data["SalesStageDate"] = new Date();
                                  e.data["ModifiedBy"] = CurrentUser.FullName;
                                },

                                onRowInserted(e) {
                                  //let rq1 = new TDFRequest({
                                  //  url: "/item/UpdateSalesStage/",
                                  //  data: {
                                  //    oppid: iBase.ItemID,
                                  //    value: e.key["SalesStage"],
                                  //    notes: e.key["Notes"] || "",
                                  //    stagedate: e.key["SalesStageDate"] || ""
                                  //  },
                                  //  type: "POST"
                                  //})
                                  //  .MakeRequest()
                                  //  .done((response) => {});
                                  itemDataService.UpdateSalesStageHistory({
                                    oppid: iBase.ItemID,
                                    value: e.key.SalesStage,
                                    notes: e.key.Notes || "",
                                    stagedate: e.key.SalesStageDate || ""
                                  });
                                },
                                onRowUpdated(e) {
                                  //let rq1 = new TDFRequest({
                                  //  url: "/item/UpdateSalesStageHistory/",
                                  //  data: {
                                  //    oppid: iBase.ItemID,
                                  //    salesprocessid: e.key["SalesProcessId"],
                                  //    value: e.key["SalesStage"],
                                  //    notes: e.key["Notes"] || "",
                                  //    stagedate: e.key["SalesStageDate"] || ""
                                  //  },
                                  //  type: "POST"
                                  //})
                                  //  .MakeRequest()
                                  //  .done((response) => {});
                                  itemDataService.UpdateSalesStageHistory({
                                    oppid: iBase.ItemID,
                                    salesprocessid: e.key.SalesProcessId,
                                    value: e.key.SalesStage,
                                    notes: e.key.Notes || "",
                                    stagedate: e.key.SalesStageDate || ""
                                  });
                                }
                              })
                            );
                        } else {
                        }
                      });
                  }
                });

              $(fGroup)
                .append(label)
                .append(historyButton);
            } else {
              if (
                control.ConvertedType === "TextBoxWithAction" &&
                control.ControlData.Properties.TDFField.toLowerCase() ===
                "uniqueid"
              ) {
                let btnGenerateUniqueID = $("<span />")
                  .html("<i class='fa fa-key' aria-hidden='true'></i>")
                  .css({ "margin-left": "20px", "font-size": "14px" })
                  .addClass("salesHistoryBtn btn")
                  .attr({
                    title:
                      "Generate " + control.ControlData.Properties.LabelCaption
                  })
                  .on("click", function (e) {
                    if (
                      control.ControlData.DataValue &&
                      control.ControlData.DataValue.ValueName
                    )
                      return;
                    if (iBase.ItemID) {
                      //new TDFRequest({
                      //  url: "/item/GenerateUniqueID/",
                      //  data: { itemid: iBase.ItemID, itemtype: iBase.ItemType }
                      //})
                      //  .MakeRequest()
                      itemDataService
                        .GenerateUniqueID({
                          itemid: iBase.ItemID,
                          itemtype: iBase.ItemType
                        })
                        .done(response => {
                          if (true) {
                            if (control.ControlData.Dx && response /*.Data*/) {
                              control.ControlData.Dx.option(
                                "value",
                                response /*.Data*/
                              );
                            }
                          } else {
                            new Notification({
                              message: response.Message,
                              type: "error",
                              displayTime: 5000
                            });
                          }
                        });
                    }
                  });
                if (
                  control.ControlData.DataValue &&
                  control.ControlData.DataValue.ValueName
                ) {
                  btnGenerateUniqueID.addClass("disabled");
                }
                $(fGroup)
                  .append(label)
                  .append(btnGenerateUniqueID);
              } else {
                $(fGroup).append(label);
              }
            }
          }

          if (control.ControlData.Properties.BackColor && control.jqElement) {
            // TODO: Need to get this moved into the options of the Dx control elementAttr or inputAttr
            if (
              control.ConvertedType === "ListBox" ||
              control.ConvertedType === "ListBoxWithEdit"
            ) {
              control.jqElement.css({
                "background-color": control.ControlData.Properties.BackColor
              });
            }

            control.jqElement.find('input[id*="-_-"]').css({
              "background-color": control.ControlData.Properties.BackColor,
              color: control.ControlData.Properties.ForeColor
            });
          }

          if (control.ConvertedType.toLowerCase() !== "note") {

            //if (control.ControlData.Properties.Required === true) {
            //  if (control.jqElement) {
            //// allow for client-side validation?
            //    control.jqElement.attr("required", "required");
            //}
            //}

            fGroup.append(
              control.jqElement || $("<div />").text("Control type not available.")
            );

          }


        }

      } else {
        let height = $(`#${iBase.ContentID}`).innerHeight(); //iBase.ItemForm.Instance.content().outerHeight();
        let row = $("<div />")
          .addClass("row ")
          .append(
            `<iframe src="${val1["LoadThirdPartyURL"]}"style="height:${height *
            0.8}px; width:100%;border:none;" ></iframe>`
          );
        container.append(row);
      }
    });

    dfd.resolve();
  }

  protected ShouldRenderLinkedItemsTab() {
    return true;
  }

  RenderCustomTab(itemData, container, dfd) {
    let iBase = this;
    switch (itemData.ControlGroupID) {
      case "Change_Log_":
        iBase.RenderChangeLog(itemData, container, dfd);
        break;

      case "Linked_Items":

        if (iBase.ShouldRenderLinkedItemsTab() === true) {
          iBase.RenderLinkedItemsTab(itemData, container, dfd);
        }

        break;
    }
  }

  //#region ChangeLog
  RenderChangeLog(itemData, container, dfd) {
    let iBase = this;

    $.when(iBase.GetChangeLogData()).done(function () {
      if (!$("#changeLogPopup").length) {
        $("#tdfbody").append($("<div id='changeLogPopup' />"));
      }
      //   container.append($("<div id='changeLogPopup' />"));

      container.append(
        $("<div />").dxDataGrid({
          dataSource: iBase.ChangeLogData,
          //columnAutoWidth: true,
          allowColumnReordering: true,
          allowColumnResizing: true,
          columnResizingMode: "widget",
          showBorders: true,
          filterRow: { visible: true },
          searchPanel: { visible: true },
          customizeColumns: function (columns) {
            for (var i = 0; i < columns.length; i++) {
              columns[i].width = "auto";
            }
            columns[i - 1].width = "200";
          },
          columns: [
            {
              caption: "",
              alignment: "center",
              //width: "50px",
              cellTemplate: function (element, data) {
                let allData = data.component.option("dataSource");
                let UDFField = data.data.UDFName;

                let vals = $.grep(allData, function (val: IChangeLogData) {
                  return val.UDFName === UDFField;
                });
                element.append(
                  $("<span class='showChangeLog dx-icon dx-icon-info' />").on(
                    "click",
                    function (e) {
                      iBase.GetChangeLogPopup(vals, element.parent());
                    }
                  )
                );
              }
            },
            {
              dataField: "UDFName",
              sortOrder: "asc"
            },
            {
              dataField: "UserName",
              caption: "User Name"
            },
            {
              dataField: "UserType",
              caption: "User Type"
            },
            {
              dataField: "TimeSpan",
              caption: "Time Span",
              visible: false
            },
            {
              dataField: "ChangeDate",
              caption: "Change Date",
              dataType: "date",
              format: "shortDateShortTime"
            },
            {
              dataField: "CurrentValue",
              caption: "Current Value"
            },
            {
              dataField: "PreviousValue",
              caption: "Previous Value"
            }
          ]
        })
      );
    });
  }

  GetChangeLogPopup(vals, target) {
    let iBase = this;

    let treeStructure = [
      { id: 1, parentId: 0, text: "[No Value]", expanded: true }
    ];

    let givenDate, formattedDate;

    for (let i = vals.length - 1; i >= 0; i--) {
      givenDate = new Date(vals[i].ChangeDate);
      formattedDate = Globalize.formatDate(givenDate, { datetime: "short" }); // iBase.FormatDateTime(givenDate);

      treeStructure.push({
        id: vals.length - i + 1,
        parentId: vals.length - i,
        text:
          vals[i].CurrentValue +
          " (" +
          vals[i].UserName +
          " on " +
          formattedDate +
          ")",
        expanded: true
      });
    }
    let tree;
    if (!$("#changeLogPopup").length) {
      $("#tdfbody").append($("<div id='changeLogPopup' />"));
    }
    $("#changeLogPopup").dxPopover({
      title: vals[0].UDFName + " - Field Change Details",
      showTitle: true,
      target: target,
      onShown: function (e) {
        tree
          .element()
          .find(
            $(
              ".dx-treeview-toggle-item-visibility.dx-treeview-toggle-item-visibility-opened"
            )
          )
          .off();
      },
      width: $(window).innerWidth() > 500 ? 500 : $(window).innerWidth(),
      contentTemplate: function (contentElement) {
        tree = $("<div class='changeLogTree' />")
          .dxTreeView({
            dataSource: treeStructure,
            dataStructure: "plain",

            expandNodesRecursive: true,
            selectByClick: false
          })
          .dxTreeView("instance");
        contentElement.append(tree.element());
      }
    });

    $("#changeLogPopup")
      .dxPopover("instance")
      .show();
  }

  GetChangeLogData() {
    let iBase = this;
    let d = $.Deferred();
    if (!iBase.ChangeLogData) {

      itemDataService
        .GetChangeLogData({ itemid: iBase.ItemID })
        .done(function (response) {
          iBase.ChangeLogData = response /*.Data*/;
          d.resolve();
        });
    } else {
      return d.promise(d.resolve());
    }
    return d.promise();
  }

  //#endregion
  RenderLinkedItemsTab(itemData, container, dfd) {
    let iBase = this;


    let centerContainer = $('<div />').height(
      iBase.ItemForm.getModalBody().height() -
      (iBase.MainTabs.element().height() + iBase.MainTabs.element().offset().top)
    );
    container.append(centerContainer);

    itemDataService.GetDefaultQuickViewType({ itemtype: iBase.ItemType })
      .done(function (initialItemType) {

        let LiCenter = new LinkedItemsCenter(
          iBase.ItemID,
          iBase.ItemType,
          `LinkedItems_${DomSafeID(iBase.ItemID)}_${Date.now()}`,
          { MainAreaContainer: centerContainer },
          initialItemType
        );

        LiCenter.MainDataAreaContainer.height('100%');
        LiCenter.init();
      });
  }

  OnCreate() {
    let iBase = this;
    let d = $.Deferred();

    return d.promise(<any>d.resolve());
  }

  //#region ToolBarButtons
  protected btnClose(): DevExpress.ui.dxToolbarItemTemplate {
    let iBase = this;
    return {
      locateInMenu: "auto",
      location: "after",
      widget: "dxButton",

      showText: "always",
      options: {
        hint: "Close this " + iBase.DisplayName,
        icon: "close",
        text: "Close",
        type: "normal",
        visible: true,
        onClick: function () {
          iBase.Close();
        }
      }
    };
  }

  protected btnSaveCopy(): DevExpress.ui.dxToolbarItemTemplate {
    let iBase = this;
    return {
      locateInMenu: "auto",
      location: "after",
      widget: "dxButton",
      showText: "always",
      options: {
        visible: iBase.CanSave(),
        hint: "Save & Copy this " + iBase.DisplayName,
        icon: "fa fa-files-o",
        text: "Save & Copy",
        type: "normal",
        onClick: function () {
          iBase.SaveCopy();
        }
      }
    };
  }

  protected btnSaveClose(): DevExpress.ui.dxToolbarItemTemplate {
    let iBase = this;
    return {
      locateInMenu: "auto",
      location: "after",
      widget: "dxButton",
      showText: "always",
      options: {
        visible: iBase.CanSave(),
        hint: "Save & Close this " + iBase.DisplayName,
        icon: "save",
        text: "Save & Close",
        type: "normal",
        onClick: function () {
          iBase.SaveClose();
        }
      }
    };
  }

  protected btnSave(): DevExpress.ui.dxToolbarItemTemplate {
    let iBase = this;
    return {
      locateInMenu: "auto",
      showText: "always",
      location: "after",
      widget: "dxButton",

      options: {
        icon: "save",
        hint: "Save this " + iBase.DisplayName,
        text: "Save",
        type: "normal",
        visible: iBase.CanSave(),
        onClick: function () {
          iBase.Save();
        }
      }
    };
  }

  protected btnTemplates(): DevExpress.ui.dxToolbarItemTemplate {
    let iBase = this;
    return {
      location: "before",
      locateInMenu: "auto",
      showText: "inMenu",
      options: {
        icon: "fa fa-file-text",
        hint: "Templates",
        text: "Templates",
        type: "default",
        visible: true,
        onClick: function () {
          if (iBase.isNew) return;
          iBase.ViewTemplates();
        }
      },
      widget: "dxButton"
    };
  }

  protected ViewTemplates() {
    let iBase = this;
    //new TDFRequest({
    //  url: "/template/DocCreationArgs/",
    //  data: { itemid: iBase.ItemID }
    //})
    //  .MakeRequest()
    itemDataService
      .DocCreationArgs({ itemid: iBase.ItemID })
      .done(function (response) {
        let args: ICreateDocumentArgs;
        let templates: Array<ITemplateItem>;
        if (true) {
          args = response /*.Data*/.Args;
          templates = response /*.Data*/.Templates;
          if (!$("#templatepop").length) {
            $("#tdfbody").append($("<div />").attr({ id: "templatepop" }));
          }
          let gopts: DevExpress.ui.dxDataGridOptions = {
            dataSource: templates,
            selection: { mode: "single" },
            height: $(window).innerHeight() * 0.5,
            filterRow: { visible: true },
            headerFilter: { visible: true },
            searchPanel: { visible: true },
            groupPanel: { visible: true, allowColumnDragging: true },
            columns: [
              { dataField: "DisplayName", caption: "Name", sortIndex: 1 },
              {
                dataField: "CategoryName",
                caption: "Category",
                groupIndex: 0,
                sortIndex: 0
              },
              { dataField: "PlaceHolder", visible: false },
              { dataField: "TemplateID", visible: false, allowSearch: false },
              { dataField: "TemplateName", visible: false },
              { dataField: "TemplateType", visible: false }
            ],
            onSelectionChanged: function (e) {
              let template: ITemplateItem = e.selectedRowsData[0];
              args.TemplateID = template.TemplateID;

              pop.content().empty();
              pop.content().append(
                $("<div />")
                  .append($("<label />").text("Desired File Name"))
                  .append(
                    $("<div />").dxTextBox({
                      value: template.DisplayName,
                      inputAttr: { id: "desfilename" }
                    })
                  )
              );
              args.FileName = <string>$("#desfilename").val();

              let urlArgs = {};

              for (var z in args) {
                urlArgs[z] = args[z];
              }

              let fakeReq = new TDFRequest({ url: 'fake' });
              fakeReq.GetToken().done(authToken => {

                urlArgs["access_token"] = authToken;

                let link = $("<a/>")
                  .addClass("btn btn-primary")
                  .attr({
                    download: "download",
                    id: "previewtemplate",
                    href:
                      TDFRequest.ApiPath + "/template/PreviewDocument/?" +
                      $.param(urlArgs)
                  })
                  .text("Preview")
                  .css({ color: "#fff" })
                  .append(
                    $("<span />")
                      .addClass("fa fa-eye")
                      .css({
                        padding: "3px",
                        color: "#fff"
                      })
                  )
                  .on("click", function () {
                    let fileUploadOptions: DevExpress.ui.dxFileUploaderOptions = {
                      accept: "/*",
                      allowCanceling: true,
                      disabled: false,
                      labelText: "or drop here after previewing.",
                      multiple: true,
                      height: 300,
                      uploadButtonText: "Upload",
                      uploadUrl: TDFRequest.ApiPath + "/template/AttachDocument/?" + $.param(urlArgs),
                      visible: true,
                      // selectButtonText: btnText,
                      onUploaded: function () {
                        new Notification({
                          message: "Success: The document has been created.",
                          type: "success",
                          displayTime: 5000
                        });
                      }
                    };
                    //pop.element().replaceWith());
                    $(pop.content().children()[0]).replaceWith(
                      $("<div />").dxFileUploader(fileUploadOptions)
                    );
                    new Notification({
                      message: `Note: The document has been created but has not been linked to this ${
                        iBase.DisplayName
                        }. Please continue to upload the file when you are ready.`,
                      type: "warning",
                      displayTime: 15000
                    });
                  });
                pop.content().append(link);
              });
            }
          };
          let popts: DevExpress.ui.dxPopupOptions = {
            contentTemplate: function (contentElement) {
              contentElement.append($("<div />"));
            },
            toolbarItems: [
              //{
              //    toolbar: "bottom", widget: "dxButton", options: {
              //        text: "Preview",
              //        onClick: function (e) {
              //            args.FileName = $("#desfilename").val();
              //            if (args.FileName) {
              //                new  TDFRequest({ url: "/template/PreviewDocument/", data: { model: args }, type: "POST" })
              //                    .MakeRequest().done(function (response) {
              //                    });
              //            }
              //        }
              //    }
              //},
              {
                toolbar: "bottom",
                widget: "dxButton",
                options: {
                  text: "Link (No Preview)",
                  onClick: function (e) {
                    args.FileName = <string>$("#desfilename").val();
                    if (args.FileName) {
                      //new TDFRequest({
                      //  url: "/template/SaveDocument", // + $.param(args),
                      //  data: args,
                      //  type: "POST"
                      //})
                      //  .MakeRequest()
                      itemDataService
                        .SaveDocument(args)
                        .done(function (response) {
                          new Notification({
                            message:
                              response /*.Data*/.File +
                              " was saved and linked successfully.",
                            type: "success"
                          });
                        });
                    }
                  }
                }
              },
              {
                toolbar: "bottom",
                widget: "dxButton",
                options: {
                  text: "Cancel",
                  onClick: function (e) {
                    pop.hide();
                  }
                }
              }
            ]
          };
          let pop = $("#templatepop")
            .dxPopup(popts)
            .dxPopup("instance");
          pop.show().done(e => {
            $(pop.content().children()[0]).dxDataGrid(gopts);
          });
        } else {
          new Notification({
            message: response.Message,
            type: "error",
            displayTime: 5000
          });
        }
      });
  }

  protected QuickAnswer() {

    let that = this;
    let itemid = that.ItemID;
    let itemtype = that.ItemType;
    let formData = {};
    let options: DevExpress.ui.dxFormOptions = {
      formData: formData,
      items: [
        {
          name: "query",
          editorType: "dxTextBox",
          dataField: "query",
          label: {
            text: "A few words describing what you are looking for",
            showColon: true,
            visible: true,
            location: "top"
          }
        }
      ]
    }
    let form: DevExpress.ui.dxForm = $("<div />")
      .dxForm(options)
      .dxForm("instance");
    let grid = $("<div />").dxDataGrid(
      {
        visible: false,
        columns: [
          {
            dataField: "LabelCaption", caption: "Field", allowResizing: true
          },
          {
            dataField: "DetailValue", caption: "Value", allowResizing: true
          }
        ]

      });

    form.element().append(grid);

    let dlg = new Dialog({
      body: form.element(),
      type: "type-primary",
      size: "size-small",
      title: "What are you looking for?",
      closable: true,
      modal: "no",
      buttons: [{
        widget: <any>'dxButton',
        toolbar: "bottom",
        location: 'after',
        options: {
          text: "Search",
          icon: "fa fa-compress fa3x",
          type: 'success',
          onClick: (e) => {

            var q = formData['query'];

            new TDFRequest({
              url: "/query-item",
              data: { itemid: itemid, itemtype: itemtype, query: q },
              type: "POST"
            }).MakeRequest().done((e) => {
              grid.dxDataGrid("instance").option("visible", true);
              grid.dxDataGrid("instance").option("dataSource", e);
            });

          }
        }
      }]
    });

    dlg.open().done((e) => {

    })

  }

  protected OpenInNewWindow() {
    let iBase = this;
    iBase.Close(); // This should prompt to save if it is dirty.
    var urlString = window.location.origin + `/Item/?itemID=${iBase.ItemID}&itemType=${iBase.ItemType}`;
    window.open(urlString);
  }

  protected btnSendReminder(): DevExpress.ui.dxToolbarItemTemplate {
    let iBase = this;
    return {
      location: "before",
      locateInMenu: "auto",
      showText: "inMenu",
      options: {
        icon: "fa fa-bell",
        hint: "Send Reminder",
        type: "default",
        visible: true,
        text: "Send Reminder",
        onClick: function () {
          if (iBase.isNew) return;
          this.SendReminder();
        }
      },
      widget: "dxButton"
    };
  }

  protected SendReminder() {
    let iBase = this;
    createitem(
      itemTypes.itemReminder,
      { ParentItemID: iBase.ItemID, ParentItemType: iBase.ItemType },
      true
    );
  }

  protected btnLinkTo(): DevExpress.ui.dxToolbarItemTemplate {
    let iBase = this;

    return {
      location: "before",
      locateInMenu: "auto",
      showText: "inMenu",
      options: {
        icon: "fa fa-link",
        showText: "inMenu",
        hint: "Links the current item to ...",
        text: "Link",
        type: "default",
        visible: true,
        onClick: function () {
          if (iBase.isNew) return;
          iBase.LinkItems();
        }
      },
      widget: "dxButton"
    };
  }

  protected LinkItems() {
    let iBase = this;
    let itemInfo: IItemInfo = {
      ItemId: iBase.ItemID,
      ItemType: iBase.ItemType,
      iMain: iBase.iMain
    };

    GetLinkPrompt(itemInfo, 999);
  }

  protected btnViewAttachments(): DevExpress.ui.dxToolbarItemTemplate {
    let iBase = this;
    return {
      location: "before",
      locateInMenu: "auto",
      showText: "inMenu",
      options: {
        icon: "fa fa-paperclip",
        hint: "View Attachments",
        type: "default",
        visible: true,
        text: "Attachments",
        onClick: function () {
          if (iBase.isNew) return;
          let fileViewer = new AttachmentViewer(iBase.ItemID, iBase.ItemType);
          fileViewer.DisplayFiles();
        }
      },
      widget: "dxButton"
    };
  }

  protected ViewAttachments() {
    let iBase = this;
    let fileViewer = new AttachmentViewer(iBase.ItemID, iBase.ItemType);
    fileViewer.DisplayFiles();
  }

  protected btnEmailDetails(): DevExpress.ui.dxToolbarItemTemplate {
    let iBase = this;
    return {
      location: "before",
      locateInMenu: "auto",
      showText: "inMenu",
      options: {
        icon: "fa fa-envelope-o",
        hint: "Email Detail",
        text: "Email Details",
        visible: true,
        type: "default",
        onClick: function () {
          if (iBase.isNew) return;
          iBase.EmailDetails();
        }
      },
      widget: "dxButton"
    };
  }

  protected btnPrint(): DevExpress.ui.dxToolbarItemTemplate {
    let iBase = this;
    return {
      location: "before",
      locateInMenu: "auto",
      showText: "inMenu",
      options: {
        icon: "fa fa-print",
        hint: "Print",
        text: "Print",
        visible: false,
        onClick: function () { }
      },
      widget: "dxButton"
    };
  }

  protected btnViews(): DevExpress.ui.dxToolbarItemTemplate {
    let iBase = this;
    return {
      location: "before",
      locateInMenu: "auto",
      showText: "inMenu",
      options: {
        icon: "icomoon window2",
        hint: "View Items Linked to this Item",

        text: "Views",
        type: "normal",
        visible:
          Preferences.GetCompanyPreference("ShowViewsBtn", "TDFMobile", "1") ===
          "1",
        onClick: function (e) {
          if (iBase.isNew) return;
          iBase.GetViewList(e);
        }
      },

      widget: "dxButton"
    };
  }

  protected btnTaskReassign(): DevExpress.ui.dxToolbarItemTemplate {
    let iBase = this;
    return {
      location: "before",
      locateInMenu: "auto",
      showText: "inMenu",
      options: {
        icon: "dx-icon item10-assign",
        hint: "Reassign this " + iBase.DisplayName,

        text: "Reassign",
        type: "normal",
        visible: iBase.ItemType === itemTypes.itemTask && iBase.EnableReassign,
        onClick: function (e) {
          if (iBase.isNew) return;
          (<any>iBase).ReassignTask();
        }
      },

      widget: "dxButton"
    };
  }

  protected btnCompleteAndConvert(): DevExpress.ui.dxToolbarItemTemplate {
    let iBase = this;
    return {
      location: "before",
      locateInMenu: "auto",
      showText: "inMenu",
      options: {
        icon: "dx-icon icon-completeConvert",
        hint: "Complete & Convert this " + iBase.DisplayName,
        text: "Complete & Convert",
        type: "normal",
        visible:
          iBase.ItemType === itemTypes.itemTask ||
          iBase.ItemType === itemTypes.itemAppointment,
        onClick: function () {
          if (iBase.isNew) return;
          RenderCompleteConvertDialog(iBase.ItemType, [iBase.ItemID]);
          iBase.Close();
        }
      },
      widget: "dxButton"
    };
  }

  protected btnEventMoreInfo(): DevExpress.ui.dxToolbarItemTemplate {
    let iBase = this;
    return {
      location: "before",
      locateInMenu: "auto",
      showText: "always",
      options: {
        text: "Manage",
        type: "info",
        visible: iBase.ItemType === itemTypes.itemEvent,
        onClick: (e) => {
          if ($('#MoreEventInfoSheet').length < 1) {
            $('body').append($('<div id="MoreEventInfoSheet" />'));
          }

          let actionSheet: DevExpress.ui.dxActionSheet = $('#MoreEventInfoSheet').dxActionSheet({
            target: $(e.element),
            usePopover: !GetDevice().isDevice,
            showTitle: false,
            items: [
              {
                text: 'Sessions',
                onClick: () => {
                  (<any>iBase).OpenSessionManager();
                }
              },

              {
                text: 'Invite People',
                onClick: () => {
                  try {
                    (<any>iBase).ShowInvitationWizard();
                  } catch (e) {
                    console.warn(e);
                  }
                },
              },
              {
                text: 'View Invitations',
                onClick: () => {
                  (<any>iBase).OpenInvitationList();
                }
              }, {
                text: 'Summary',
                onClick: () => {
                  (<any>iBase).OpenEventSummary();
                }
              }
            ]
          }).dxActionSheet('instance');

          actionSheet.show();
        }
      },
      widget: "dxButton"
    }
  }

  protected btnManageLocations(): DevExpress.ui.dxToolbarItemTemplate {
    let iBase = this;
    return {
      location: "before",
      locateInMenu: "auto",
      showText: "inMenu",
      options: {
        icon: "dx-icon fa fa-map-marker",
        hint: "Manage Locations",
        text: "Manage Locations",
        type: "normal",
        visible: iBase.ItemType === itemTypes.itemEvent,
        onClick: () => {
          (<any>iBase).OpenLocationManager();
        }
      },
      widget: "dxButton"
    }
  }

  protected btnManageSessions(): DevExpress.ui.dxToolbarItemTemplate {
    let iBase = this;
    return {
      location: "before",
      locateInMenu: "auto",
      showText: "inMenu",
      options: {
        icon: "dx-icon",
        hint: "Manage Sessions",
        text: "Manage Sessions",
        type: "normal",
        visible: iBase.ItemType === itemTypes.itemEvent,
        onClick: () => {
          (<any>iBase).OpenSessionManager();
        }
      },
      widget: "dxButton"
    }
  }

  protected btnActions(): DevExpress.ui.dxToolbarItemTemplate {
    let iBase = this;
    return {
      location: "before",
      locateInMenu: "auto",
      showText: "inMenu",
      options: {
        icon: "runner",
        hint: "Actions",

        text: "Actions",
        type: "normal",
        visible: true,
        onClick: function (e) {
          if (iBase.isNew) return;
          iBase.GetActionList(e);
        }
      },

      widget: "dxButton"
    };
  }

  protected btnDelete(): DevExpress.ui.dxToolbarItemTemplate {
    let iBase = this;
    return {
      location: "before",
      locateInMenu: "auto",
      showText: "inMenu",
      options: {
        icon: "remove",
        hint: "Delete this " + iBase.DisplayName,
        text: "Delete this " + iBase.DisplayName,
        type: "danger",
        visible: iBase.EnableDelete,
        onClick: function () {
          if (iBase.isNew) return;
          iBase.Delete();
        }
      },
      widget: "dxButton"
    };
  }

  protected btnRefresh(): DevExpress.ui.dxToolbarItemTemplate {
    let iBase = this;
    return {
      location: "before",
      locateInMenu: "auto",
      showText: "inMenu",
      options: {
        icon: "fa fa-refresh",
        hint: "Refresh this " + iBase.DisplayName,
        text: "Refresh this " + iBase.DisplayName,
        type: "default",
        visible: true,
        onClick: function () {
          if (iBase.isNew) return;
          iBase.Refresh();
        }
      },
      widget: "dxButton"
    };
  }

  protected btnAll(): DevExpress.ui.dxToolbarItemTemplate {
    let iBase = this;
    return {
      location: "before",
      widget: "dxButton",
      options: {
        hint: "All other actions and options",
        icon: "fa fa-ellipsis-h",
        // text: "Group",
        type: "normal",
        visible: true,
        onClick: function (e) {
          if (iBase.isNew) return;
          iBase.ToolbarItemsActionSheet(e);
        }
      }
    };
  }

  //#endregion
  protected get MarketingSyncScoreContainerID() {
    return `marketing-sync-score-container-${this.DomSafeID}`;
  }
  protected get MarketingSummaryContainerID() {
    return `marketing-summary-container-${this.DomSafeID}`;
  }
  protected get MarketingReportContainerID() {
    return `marketing-report-container-${this.DomSafeID}`;
  }
  protected get MarketingContainerID() {
    return `marketing-${this.DomSafeID}`;
  }

  protected get MarketingSummaryContentID() {
    return `marketing-contact-summary-${this.DomSafeID}`;
  }
  protected get MarketingLastSyncContentID() {
    return `marketing-lastsync-${this.DomSafeID}`;
  }
  protected get MarketingContactScoreContentID() {
    return `maketing-contact-score-${this.DomSafeID}`;
  }
  protected get MarketingContactReportContentID() {
    return `marketing-contact-report-${this.DomSafeID}`;
  }

  protected RenderCustomTab_MarketingIntegration(itemData, container) {
    let iBase = this;
    container.append(iBase.MarketingContainers);
    $(`#${iBase.MarketingSyncScoreContainerID}`).append(
      iBase.MarketingSyncScoreChildren
    );
    $(`#${iBase.MarketingSummaryContainerID}`).append(
      iBase.MarketingSummaryContainer
    );
    $(`#${iBase.MarketingReportContainerID}`).append(
      iBase.MarketingReportContainer
    );
    iBase.GetMarketingContactReport();
  }
  protected GetMarketingContactLastSync() {
    let iBase = this;
    LoadCompany().done(() => {
      //new TDFRequest({
      //  url: `${
      //    TDFRequest.ApiPath
      //  }/marketing/marketingcore/ContactMarketingLastSync/`,
      //  data: {
      //    emailAddress: iBase.GetControlByTDFField("Email").DataValue.ValueName
      //  }
      //})
      //  .MakeRequest()
      itemDataService
        .ContactMarketingLastSync({
          emailAddress: iBase.GetControlByTDFField("Email").DataValue.ValueName
        })
        .done(r => {
          $(`#${iBase.MarketingLastSyncContentID}`).text(`${r}`);
        });
    });
  }
  protected get MarketingSyncScoreChildren() {
    let iBase = this;

    let toolbar = $('<div />').dxToolbar({
      items: <DevExpress.ui.dxToolbarItem[]>[
        {
          template: () => {
            return `<div>Last Sync: <span id="${iBase.MarketingLastSyncContentID}" /></div>`;
          },
          location: 'before'
        }, {
          template: () => {
            return `<div>Contact Score: <span id="${iBase.MarketingContactScoreContentID}" /></div>`;
          },
          location: 'center'
        }, {
          widget: 'dxButton',
          options: <DevExpress.ui.dxButtonOptions>{
            icon: 'refresh',
            hint: 'Re-Sync marketing data',
            onClick: () => {
              iBase.RefreshMarketingData();
            }
          },
          location: 'after'
        }
      ]
    }).dxToolbar('instance');

    return toolbar.element();
  }
  protected get MarketingSummaryContainer() {
    let iBase = this;
    return `<fieldset class="col-md-12">
                    <legend>Summary</legend> 
                        <div id="${iBase.MarketingSummaryContentID}" class="container-fluid"></div>
                    </fieldset>`;
  }
  protected get MarketingReportContainer() {
    let iBase = this;
    return `<fieldset class="col-md-12">
                    <legend></legend> 
                        <div id="${
      iBase.MarketingContactReportContentID
      }"></div>
                    </fieldset>`;
  }

  protected get MarketingContainers() {
    let iBase = this;
    return ` <div id='${iBase.MarketingContainerID}' > 
                        <div id='${
      iBase.MarketingSyncScoreContainerID
      }'></div>
                        <div class='row' id='${
      iBase.MarketingSummaryContainerID
      }'></div>
                        <div class='row' id=${
      iBase.MarketingReportContainerID
      }></div>
                    </div>              
                   `;
  }

  ContactReportData: any;

  protected GetMarketingContactReport(refresh: boolean = false) {
    let iBase = this;
    LoadCompany().done(() => {
      //new TDFRequest({
      //  url: `${TDFRequest.ApiPath}/marketing/marketingcore/contactreport/`,
      //  data: {
      //    email: iBase.GetControlByTDFField("Email").DataValue.ValueName,
      //    ID: iBase.ItemID
      //  }
      //})
      //  .MakeRequest()
      itemDataService
        .contactreport({
          email: iBase.GetControlByTDFField("Email").DataValue.ValueName,
          ID: iBase.ItemID,
          useCache: !refresh
        })
        .done(response => {
          iBase.ContactReportData = response.Details; //tempreport;

          $(`#${iBase.MarketingContactScoreContentID}`).text(
            `${iBase.ContactReportData.Scorecard.Score}`
          );
          $(`#${iBase.MarketingContactReportContentID}`).dxTabPanel({
            items: iBase.GetMarketingContactReportTabs(),
            itemTemplate(data, index, element) {
              switch (data.title) {
                case "All Activities":
                  iBase.FillMarketingAllActivities(element);
                  break;
                case "Form Submissions":
                  iBase.GetMarketingFormSubmission(element);
                  break;
                case "Score History":
                  iBase.GetMarketingScoreHistory(element);
                  break;
                case "Scored Activities":
                  iBase.FillMarketingScoredActivities(element);
                  break;
              }
            }
          });

          iBase.RenderMarketingContactSummary(response.Summary);
          iBase.GetMarketingContactLastSync();
        });
    });
  }

  protected RefreshMarketingData() {
    let iBase = this;

    $(`#${iBase.MarketingSummaryContentID}`).empty();

    iBase.GetMarketingContactReport(true);
  }

  protected GetMarketingContactReportTabs() {
    let iBase = this;
    let tabs: DevExpress.ui.dxTabPanelItemTemplate[] = [
      {
        title: "All Activities",
        tabTemplate(data, index, element) {
          element.text(
            `${data.title} (${iBase.ContactReportData.AllActivities.length})`
          );
        }
      },
      { title: "Form Submissions" },
      { title: "Score History" }
    ];
    if (
      Preferences.GetCompanyPreference(
        "ShowScoreTab",
        "MarketingIntegration"
      ) === "1"
    ) {
      tabs.splice(1, 0, {
        title: "Scored Activities",
        tabTemplate(data, index, element) {
          element.text(
            `${data.title} (${
            iBase.ContactReportData.Scorecard.scoreDetails.length
            })`
          );
        }
      });
    }
    return tabs;
  }
  protected FillMarketingAllActivities(element) {
    let iBase = this;
    if (iBase.ContactReportData && iBase.ContactReportData.AllActivities) {
      element.append(
        $("<div/>").dxDataGrid({
          masterDetail: {
            enabled: true,
            template: function (container, info) {
              if (info.data.Responses.length > 0) {
                $("<div>")
                  .dxDataGrid({
                    // create a div container and place the dxDataGrid widget in it
                    dataSource: info.data.Responses
                    // configure the widget here
                  })
                  .appendTo(container); // append the div container to the detail section's container
              }
            }
          },
          dataSource: iBase.ContactReportData.AllActivities,
          columns: [
            {
              dataField: "EmailAddress",
              caption: "",
              visible: false
            },
            {
              dataField: "Description",
              visible: true,
              cellTemplate(element, info) {
                let value =
                  info.text && info.text.indexOf("Web Page Visit: www", "") >= 0
                    ? `<a href="http://${info.text.replace(
                      "Web Page Visit: ",
                      ""
                    )}" target="_blank">${info.text}</a>`
                    : info.text;

                if (info.data.Responses.length > 0) {
                  element.append(
                    `<strong class="text-warning" >${value}</strong>`
                  );
                } else {
                  element.append(`<span >${value}</span>`);
                }
                return element;
              }
            },
            {
              dataField: "ActivityDate",
              caption: "Activity Date",
              dataType: "date",
              visible: true,
              sortIndex: 0,
              sortOrder: "desc"
            },
            {
              dataField: "ActivityType",
              caption: "",
              visible: true
            },
            {
              dataField: "RawType",
              caption: "",
              visible: true
            },
            {
              dataField: "InScoreRange",
              caption: "In Score Range",
              dataType: "boolean",
              visible: true
            },
            {
              dataField: "Responses",
              caption: "",
              visible: false
            },
            {
              dataField: "ID",
              caption: "",
              visible: false
            },
            {
              dataField: "ScoreValue",
              caption: "Score Value",
              visible: true
            }
          ],
          onCellPrepared: function (e: any) {
            if (e.rowType === "data" && e.column.command === "expand") {
              if (!(e.data.Responses.length > 0)) {
                e.cellElement.removeClass("dx-datagrid-expand");
                e.cellElement.empty();
              }
            }
          }
        })
      );
    }
  }

  protected FillMarketingScoredActivities(element) {
    let iBase = this;
    if (
      iBase.ContactReportData &&
      iBase.ContactReportData.Scorecard &&
      iBase.ContactReportData.Scorecard.scoreDetails
    ) {
      element
        .append(
          $(
            `<fieldset ><legend class="text-info"><small>Score Period: ${
            iBase.ContactReportData.Scorecard.ScorePeriod
            }</small></legend></fieldset>`
          )
        )
        .append(
          $("<div/>").dxDataGrid({
            dataSource: iBase.ContactReportData.Scorecard.scoreDetails
          })
        );
    }
  }
  protected GetMarketingFormSubmission(element) {
    let iBase = this;
    LoadCompany().done(() => {
      //new TDFRequest({
      //  url: `${TDFRequest.ApiPath}/marketing/marketingcore/FormSubmissions`,
      //  data: {
      //    emailaddress: iBase.GetControlByTDFField("Email").DataValue.ValueName
      //  }
      //})
      //  .MakeRequest()
      itemDataService
        .FormSubmissions({
          emailaddress: iBase.GetControlByTDFField("Email").DataValue.ValueName
        })
        .done(forms => {
          let formSelect = $("<div/>")
            .dxSelectBox({
              items: forms,
              displayExpr: "Name",
              valueExpr: "ID",
              width: "30%",
              onSelectionChanged(e) {
                //new TDFRequest({
                //  url: `${TDFRequest.ApiPath}/marketing/marketingcore/FormData`,
                //  data: {
                //    emailaddress: iBase.GetControlByTDFField("Email").DataValue
                //      .ValueName,
                //    formID: e.selectedItem.ID
                //  }
                //})
                //  .MakeRequest()
                itemDataService
                  .FormData({
                    emailaddress: iBase.GetControlByTDFField("Email").DataValue
                      .ValueName,
                    formID: e.selectedItem.ID
                  })
                  .done(response => {
                    let theGrid: DevExpress.ui.dxDataGrid;
                    if (!$(`#formdatagrid-${iBase.DomSafeID}`).length) {
                      theGrid = $(`<div id='formdatagrid-${iBase.DomSafeID}'/>`)
                        .dxDataGrid({
                          dataSource: response,
                          showBorders: true,
                          showColumnHeaders: true,
                          showColumnLines: true,
                          showRowLines: true,
                          groupPanel: { visible: true },
                          columns: [
                            { dataField: "Field", width: "auto" },
                            { dataField: "Value", width: "auto" },
                            {
                              dataField: "FieldType",
                              groupIndex: 0,
                              groupCellTemplate(element, info) {
                                element.append(
                                  `<span class="text-info">Field Type: ${
                                  info.text
                                  }</span>`
                                );
                              }
                            }
                          ]
                        })
                        .dxDataGrid("instance");

                      element.append(
                        $("<fieldset/>")
                          .append($("<legend>").text(e.selectedItem.Name))
                          .append(theGrid.element())
                      );
                    } else {
                      theGrid = $(
                        `#formdatagrid-${iBase.DomSafeID}`
                      ).dxDataGrid("instance");
                      element.find("legend").text(e.selectedItem.Name);
                      theGrid.option("dataSource", response);
                    }
                  });
              }
            })
            .dxSelectBox("instance");
          element.append(formSelect.element());
          formSelect.option("value", forms[0].ID);
        });
    });
  }
  protected GetMarketingScoreHistory(element) {
    let iBase = this;
    //new TDFRequest({
    //  url: `${TDFRequest.ApiPath}/marketing/marketingcore/ScoreHistory/`,
    //  data: { tdfGUID: iBase.ItemID }
    //})
    //  .MakeRequest()
    itemDataService.ScoreHistory({ tdfGUID: iBase.ItemID }).done(r => {
      let data = [];

      Object.keys(r[0]).forEach(k => {
        if (parseInt(k)) {
          data.push({ Display: `${k} Days Ago`, Value: r[0][k], Days: k });
        }
      });

      let palettes: any = [
        "Default",
        "Soft Pastel",
        "Harmony Light",
        "Pastel",
        "Bright",
        "Soft",
        "Ocean",
        "Vintage",
        "Violet",
        "Carmine",
        "Dark Moon",
        "Soft Blue",
        "Dark Violet",
        "Green Mist"
      ];

      import('devextreme/viz/chart').then(() => {
        element.append(
          $("<div/>").dxChart(<DevExpress.viz.charts.dxChartOptions>{
            palette: palettes[Math.floor(Math.random() * palettes.length)],
            dataSource: data,
            customizeLabel: function () {
              return {
                visible: true,

                customizeText: function () {
                  return this.valueText;
                }
              };
            },
            argumentAxis: {
              valueMarginsEnabled: true,
              discreteAxisDivisionMode: "crossLabels",
              grid: {
                visible: true
              },
              label: {
                displayMode: "rotate"
              },
              inverted: true
            },
            legend: {
              verticalAlignment: "bottom",
              horizontalAlignment: "center",
              itemTextPosition: "bottom",
              visible: false
            },
            commonSeriesSettings: {
              argumentField: "Display",
              type: "line"
            },
            title: r[0].Name,
            size: {
              width: iBase.ItemForm.Instance.content().outerWidth() * 0.9,
              height: 500
            },
            crosshair: {
              enabled: true,
              color: "#949494",
              width: 3,
              dashStyle: "dot",
              label: {
                visible: true,
                backgroundColor: "#949494",
                font: {
                  color: "#fff",
                  size: 12
                }
              }
            },
            tooltip: {
              enabled: true,
              customizeTooltip: function (arg) {
                return {
                  text: arg.valueText
                };
              }
            },
            series: [{ valueField: "Value", name: "Display" }]
          })
        );
      });
    });
  }

  protected RenderMarketingContactSummary(summary) {
    let iBase = this;

    let count = 1;
    let width = 12 / Object.keys(summary).length;
    let row = $('<div />').addClass('row');

    Object.keys(summary).forEach(k => {
      let container = $(
        `<div class="col-sm-8 col-md-${width} bs-callout wide bs-callout-${count}" style="height:18rem;"><h3>${k}</h2></div>`
      );
      if (count === 1) {
        container.css("margin-top", "-5px");
      }
      (summary[k] as Array<any>).forEach(item => {
        container.append(
          `<div style="margin-left:2rem;">${item.Key}: ${item.Value}</div>`
        );
      });

      row.append(container);
      count++;
    });

    $(`#${iBase.MarketingSummaryContentID}`)
      .append(row);
  }
}
