import { Dialog } from '../dialogs/dialog';
import { Notification } from '../dialogs/notification';
import { itemTypes } from '../../enums/enums';
import { IAttachmentInfo } from '../../interfaces/items/iattachmentinfo';
import { Grid } from '../../legacy-centers-grids/grid';
import { TDFRequest } from '../../services/request';
import { DomSafeID, GetDevice } from '../../util/allutils';
import 'devextreme/ui/file_uploader';
import * as Cookies from 'js-cookie';
import { LoadCompany } from 'infrastructure/context';
export class AttachmentViewer {
  GridContainer: JQuery;
  myGrid: Grid;
  ItemID: string;
  ItemType: itemTypes;
  NoteID: string = '';

  MainDivID: string = '';
  AttachmentsListId: string = '';
  FileUploaderSectionID: string = '';
  AttachmentWindow: Dialog;

    /*
     A list of a files passed directly; for when you already have your files and don't need to "fetch" them.
     */
    InjectedFiles: IAttachmentInfo[] = null;

    AllowAddNewFile: boolean = true;
    AllowAddImage: boolean = true;
  constructor(itemID: string, itemType: itemTypes, noteID?: string, injectedFiles?: IAttachmentInfo[]) {
    let viewer = this;
    viewer.ItemID = itemID;
    viewer.ItemType = itemType;
    if (noteID) {
      viewer.NoteID = noteID;
    }
      if (itemType === itemTypes.itemEmail) {
        viewer.AllowAddImage = false;
        viewer.AllowAddNewFile = false;
      }
    viewer.MainDivID = 'Attachments_' + DomSafeID(viewer.ItemID);
    viewer.AttachmentsListId = 'AttachmentsList_' + DomSafeID(viewer.ItemID);
    viewer.FileUploaderSectionID = 'FileUploader_' + DomSafeID(viewer.ItemID);
    viewer.InjectedFiles = injectedFiles;
  }

  FetchFiles() {
    let viewer = this;
      let viewerdfd = $.Deferred();

      if (viewer.InjectedFiles) {
          return viewerdfd.promise(viewerdfd.resolve(viewer.InjectedFiles));
      }

    LoadCompany().done(() => {

      let request = new TDFRequest({
        url: '/Attachment/list',
        type: 'POST',
        data: { itemID: viewer.ItemID, noteID: viewer.NoteID }
      });
      request.MakeRequest().done(function (response: any) {
        return viewerdfd.resolve(response);
      });
    })

    return viewerdfd.promise(viewer);
  }

  DisplayFiles() {
    let viewer = this;

    viewer.FetchFiles().done(function (files: IAttachmentInfo[] | any) {
      let attachmentMsg = $('<div />').attr('id', viewer.MainDivID);

      viewer.AttachmentWindow = new Dialog(
        {
          id: 'AttachemntsViewDialog',
          size: 'size-large',
          title: 'Attachments',
          body: attachmentMsg,
          type: 'type-primary',
          closable: true,
          fullScreen: GetDevice().isDevice ? true : false,
          buttons: [
            {
              widget: 'dxButton',
              toolbar: 'bottom',
              location: 'after',
              visible: viewer.AllowAddImage,
              options: <DevExpress.ui.dxButtonOptions>{
                text: '',
                icon: 'photo replaceme'
              }
            },
            {
              widget: 'dxButton',
              toolbar: 'bottom',
              location: 'after',
              options: {
                text: 'Close',
                icon: 'remove',
                type: 'danger',
                onClick: function () {
                  viewer.AttachmentWindow.close();
                }
              }
            }
          ]
        },
        null,
        true,
        true
      );

      viewer.AttachmentWindow.open().done(function () {
        let btn: DevExpress.ui.dxButton = (viewer.AttachmentWindow
          .Instance as any)
          .bottomToolbar()
          .find('.replaceme')
          .parents('.dx-widget')
          .first()
          .dxButton('instance');
        if (btn) {
          let fakeReq = new TDFRequest({ url: "test" });
          fakeReq.GetToken().done(token => {
            //$('#' + viewer.FileUploaderSectionID).dxFileUploader(viewer.MultiFileUploaderOptions(token));
            btn.element().replaceWith(
              $('<div />').dxFileUploader(viewer.SingleFileUploaderOptions(token))
            );
          });

        }

        $(`#${viewer.MainDivID}`)
          .css(
            'height',
            viewer.AttachmentWindow.Instance.content().height() * 0.9
          )
          .append($('<div />').attr('id', viewer.AttachmentsListId))
          .append('<hr>')
          .append(
            $('<div />')
              .attr('id', viewer.FileUploaderSectionID)
              .addClass('tdfFileUploader')
          );

        if (files.length <= 0) {
          $('#' + viewer.AttachmentsListId)[0].innerText =
            'No attachments found.';
        } else {
          let options: DevExpress.ui.dxDataGridOptions = {
            dataSource: files,
            onKeyDown(e) {
              if (
                (((e.event as unknown) as JQueryEventObject)
                  .originalEvent as KeyboardEvent).keyCode === 46
              ) {
                e.component.getSelectedRowKeys().done(r => {
                  if (r && r.length) {
                    new TDFRequest({
                      type: 'POST',
                      url: `${TDFRequest.ApiPath}/Attachment/DeleteAttachment/?id=${r[0].AttachmentID}`
                    })
                      .MakeRequest()
                      .done(response => {
                        if (response) {
                          if (true) {
                            new Notification({
                              message: response.Message,
                              type: 'success',
                              displayTime: 2000,
                              shading: false
                            });
                            viewer.AttachmentWindow.close(true);
                            viewer.DisplayFiles();
                          } else {
                            new Notification({
                              message: response.Message,
                              type: 'error',
                              displayTime: 5000,
                              shading: true
                            });
                          }
                        }
                      });
                  }
                });
              }
            },

            columns: [
              {
                dataField: 'Subject',
                caption: 'File Name'
              },
              {
                dataField: 'Created',
                caption: 'Date Created',
                dataType: 'date',
                format: 'shortDate',
                sortOrder: 'desc',
                visible: false,
                hidingPriority: 0
              },
              {
                dataField: 'AttachmentID',
                caption: 'Link',
                  cellTemplate: function (container, options) {

                      let fileInfo: IAttachmentInfo = options.data;

                  container.append(
                    $('<a />')
                      .addClass('btn btn-success')
                          .attr({

                              href: (!fileInfo.DownloadUrl) ?
                                  TDFRequest.GetApiLink("/attachment/download", { fileid: fileInfo.AttachmentID}) :
                                  TDFRequest.GetApiLink(fileInfo.DownloadUrl, null),
                        
                        target: '_blank'
                      })
                      .append($('<i></i>').addClass('fa fa-download pull-left'))
                  );
                },
                width: '10%',
                visibleIndex: 0
              }
            ],
            paging: {
              pageSize: 10
            },
            pager: {
              showPageSizeSelector: true,
              allowedPageSizes: [5, 10, 20],
              showInfo: true
            },
            selection: {
              mode: 'single',
              deferred: true
            },
            masterDetail: {
              enabled: true,
              template: function (container, options) {
                let curData = [];
                curData.push(options.data);
                $('<div />')
                  .append($('<strong>').text(curData[0].Subject))
                  .attr({ style: 'padding:5px;' })
                  .addClass('bg-info')
                  .appendTo(container); // responsive-font
                $('<div />')
                  .addClass('internal-grid')
                  .dxDataGrid({
                    dataSource: curData,
                    columnAutoWidth: true,
                    columns: [
                      {
                        dataField: 'Created',
                        caption: 'Date Created',
                        dataType: 'date',
                        format: 'shortDate'
                      },
                      {
                        dataField: 'Size',
                        dataType: 'number'
                      },
                      {
                        dataField: 'Type',
                        caption: 'File Type',
                        dataType: 'string'
                      }
                    ]
                  })
                  .appendTo(container);
              }
            },
            height: '80%'
          };

          $('#' + viewer.AttachmentsListId)
            .dxDataGrid(options)
            .dxDataGrid('instance');
        }

        //#region Uploader
        let fakeReq = new TDFRequest({ url: "test" });
        fakeReq.GetToken().done(token => {
          $('#' + viewer.FileUploaderSectionID).dxFileUploader(viewer.MultiFileUploaderOptions(token));
        });

        //#endregion
      });
    });
  }

  MultiFileUploaderOptions(token: string): DevExpress.ui.dxFileUploaderOptions {
    let viewer = this;

    let accepts: string = '/*';
    let btnText: string = 'Attach File ...';
    if (viewer.ItemType === 12) {
      accepts = 'image/*';
      btnText = 'Attach Image ...';
    }

    let fileUploadOptions: DevExpress.ui.dxFileUploaderOptions = {
      accept: accepts,
      allowCanceling: true,
        disabled: false,
      labelText: 'or drop file here',
      multiple: true,
      uploadButtonText: 'Upload All Files',
      uploadUrl: `${TDFRequest.ApiPath}/Attachment/Attach/?ItemID=${encodeURIComponent(viewer.ItemID)}&ItemType=${viewer.ItemType}&NoteID=${viewer.NoteID}`,
      uploadHeaders: {
        AUTHORIZATION: `Bearer ${token}`
      },
      visible: viewer.AllowAddNewFile,
      uploadMode: 'useButtons',
      showFileList: true,
      selectButtonText: btnText,
      onUploaded: function (e) {
        let response: any = JSON.parse(e.request.responseText);
        new Notification({
          message: response.Message,
          type: response.Valid ? 'success' : 'error',
          displayTime: response.Valid ? 2000 : 5000,
          position: { at: 'bottom' },
          animation: {
            show: { type: 'slide', duration: 500, from: { top: 0 } },
            hide: {
              type: 'slideOut',
              duration: 200,
              direction: 'right',
              to: { right: $(window).width() + 200 }
            }
          },
          shading: response.Valid ? false : true
        });

        if (
          e.component.option('progress') === 100 &&
          e.request.status === 200
        ) {
          viewer.AttachmentWindow.close(true);
          viewer.DisplayFiles();
        }
      }
    };

    return fileUploadOptions;
  }

  SingleFileUploaderOptions(token: string): DevExpress.ui.dxFileUploaderOptions {
    let viewer = this;
    let options: DevExpress.ui.dxFileUploaderOptions =
    {
      accept: 'image/*',
      multiple: false,
      labelText: '',
      uploadMethod: 'POST',
      showFileList: false,
      //width:"10%",
      uploadMode: 'instantly',
      uploadUrl: `${TDFRequest.ApiPath}/Attachment/Attach/?itemID=${encodeURIComponent(viewer.ItemID)}&itemType=${viewer.ItemType}`,    // DOES THIS NEED THE NoteID option also?
      uploadHeaders: {
        AUTHORIZATION: `Bearer ${token}`
      },
      elementAttr: { style: 'display:inline-block' },
      onContentReady(e) {
        ((e.component as any)
          ._selectButton as DevExpress.ui.dxButton).option({
            text: '',
            icon: 'photo'
          });
        (e.component as any)._$fileInput.attr('capture', 'camera');
        e.element
          .find('.dx-fileuploader-input-wrapper')
          .css({ padding: '0', border: 'none' });
        e.element.find('.dx-fileuploader-wrapper').css('padding', '0');
      },
      onUploaded(e) {
        if (e.request.status === 200 && e.request.responseText) {
          let t: any = JSON.parse(e.request.responseText);
          if (true) {
            new Notification({
              message: t.Message,
              type: 'success',
              displayTime: 2000,
              shading: false
            });
            viewer.DisplayFiles();
          } else {
            new Notification({
              message: t.Message,
              type: 'error',
              displayTime: 5000,
              shading: true
            });
          }
        } else {
          new Notification({
            message: e.request.responseText
              ? e.request.responseText
              : 'Sorry there was an error. Please try again and report any further issues.',
            type: 'error',
            displayTime: 5000,
            shading: false
          });
        }
      }
    }
    return options;
  }
}
