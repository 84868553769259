import { CenterType, itemTypes } from "../enums/enums";
import { eventNameSpace, EventTypes } from "../enums/webevents/enums";
import { AddHandler2, RaiseEvent2 } from "../infrastructure/events/ui_events";
import { Preferences } from "../infrastructure/user/preferences";
import { TDFRequest } from "../services/request";
import { GetDevice, Debounce } from "../util/allutils";
import dxDataSource from "devextreme/data/data_source";
import dxCustomStore from "devextreme/data/custom_store";
import { GetQueryStringParam } from "util/getquerystringparam";

export class MenuDrawerIDs {
    public SideNavContainerID: string;
    public MenuDrawerListContainerID: string;
    public CloseBtnID: string;
    public SideMenuPinID: string;
    public SideMenuUnpinID: string;
    public PinOptionsID: string;
}

export abstract class BaseDataCenterMenuDrawer {
    protected EventLimiterName: string = "";
    protected UniquePinnableClass: string = "";
    protected InDefaultContainer: boolean;

    protected abstract get MenuCtrlInstance(): DevExpress.ui.dxList | DevExpress.ui.dxTreeList;
    protected abstract set MenuCtrlInstance(val: DevExpress.ui.dxList | DevExpress.ui.dxTreeList);
    protected abstract get MenuListOptions(): DevExpress.ui.dxListOptions | DevExpress.ui.dxTreeListOptions;

    private _MenuContainer: JQuery;
    protected get MenuContainer(): JQuery {
        if (this._MenuContainer) return this._MenuContainer;
    }
    protected set MenuContainer(val: JQuery) {
        if (val) this._MenuContainer = val;
    }

    private _MenuItems: any[];
    get MenuItems(): any[] {
        if (this._MenuItems) return this._MenuItems;
        return null;
    }
    set MenuItems(val: any[]) {
        if (val) this._MenuItems = val;
    }

    private _CenterType: CenterType;
    protected get CenterType(): CenterType {
        if (!(typeof this._CenterType === "undefined" || this._CenterType === null))
            return this._CenterType;
        throw "Center Type not found...";
    }
    protected set CenterType(val: CenterType) {
        if (!(typeof val === "undefined" || val === null)) this._CenterType = val;
    }

    protected _MenuCtrlInstance: DevExpress.ui.dxList | DevExpress.ui.dxTreeList;

    private myFrameIDs: MenuDrawerIDs;
    public get FrameIDs(): MenuDrawerIDs {
        let md = this;
        if (!md.myFrameIDs) {
            md.myFrameIDs = new MenuDrawerIDs();
            md.myFrameIDs.SideNavContainerID = `mySidenav-${
                CenterType[md.CenterType]
                }-${md.EventLimiterName}`;
            md.myFrameIDs.MenuDrawerListContainerID = `datacenter-menudrawer-itemlist-${
                CenterType[md.CenterType]
                }-${md.EventLimiterName}`;
            md.myFrameIDs.CloseBtnID = `closeBtn-${CenterType[md.CenterType]}-${
                md.EventLimiterName
                }`;
            md.myFrameIDs.SideMenuPinID = `side-menu-pin-${
                CenterType[md.CenterType]
                }-${md.EventLimiterName}`;
            md.myFrameIDs.SideMenuUnpinID = `side-menu-unpin-${
                CenterType[md.CenterType]
                }-${md.EventLimiterName}`;
            md.myFrameIDs.PinOptionsID = `tdf-datacenter-pin-options-${
                CenterType[md.CenterType]
                }-${md.EventLimiterName}`;
        }

        return md.myFrameIDs;
    }
    protected get PreferenceInfo(): { Module: string; Property: string } {
        let md = this;
        switch (md.CenterType) {
            case CenterType.AccountBi:
            case CenterType.Bi:
            case CenterType.ContactBi:
            case CenterType.OppBi:
            case CenterType.VendorBi:
                //NEEDTODO - I don't know if this should be the same for all BIs
                return { Module: "BSISummary", Property: "LeaveBiMenuOpen" };
            case CenterType.InfoCenter:
                return {
                    Module: "ManagersConsole",
                    Property: "LeaveInfoCenterMenuOpen"
                };
            case CenterType.LinkedItems:
                return {
                    Module: "LinkedItemsCenter",
                    Property: "LeaveLinkedItemsMenuOpen"
                };
            case CenterType.Admin:
                return {
                    Module: "AdminCenter",
                    Property: "LeaveAdminMenuOpen"
                };
            case CenterType.Preference:
                return {
                    Module: "PreferenceCenter",
                    Property: "LeavePreferenceMenuOpen"
                };
            case CenterType.EventInvitation:
                return {
                    Module: "EventInvitation",
                    Property: "LeaveEventMenuOpen"
                }
        }
    }

    protected get MenuItemsPath(): string {
        let md = this;
        switch (md.CenterType) {
            case CenterType.AccountBi:
            case CenterType.Bi:
                return "/bi/menuitems/";
            case CenterType.ContactBi:
                return "/bi/contactmenuitems";
            case CenterType.OppBi:
                return "/bi/OppMenuItems";
            case CenterType.VendorBi:
                return "/bi/VendorMenuItems";
            case CenterType.InfoCenter:
                return "/infocenter/items";
            case CenterType.Admin:
                return "/admincenter/items";
            case CenterType.Preference:
                return "/preferencecenter/items";
            case CenterType.EventInvitation:
                return "/Item/Event/InvitationGridTypes";
            case CenterType.LinkedItems:
                return "Core/User/GetViewableLinkedItems";
        }
    }

    private _MenuItemsQueryString: string;
    public get MenuItemsQueryString(): string {
        if (this._MenuItemsQueryString) {
            return this._MenuItemsQueryString;
        } else {
            return '';
        }
    }
    public set MenuItemsQueryString(val: string) {
        this._MenuItemsQueryString = val;
    }

    protected get MenuItemsDataSource(): DevExpress.data.DataSource {
        let md = this;

        let storeopts: DevExpress.data.CustomStoreOptions = md.StoreOptions();
        let dsopts: DevExpress.data.DataSourceOptions = md.DSOptions(storeopts);

        return new dxDataSource(dsopts);
    }

    protected LoadDefault() {
        let md = this;

        let defaultType = itemTypes[itemTypes[parseInt(GetQueryStringParam('id'))]]
        let isAvailableType = md.MenuItems.map(a => {
            return a.ItemType
        }).indexOf(defaultType) !== -1;

        if (
            isAvailableType &&
            md.CenterType === CenterType.InfoCenter
        ) {
            RaiseEvent2(
                EventTypes.CenterEventTypes.itemtype,
                md.EventLimiterName,
                eventNameSpace.modify,
                { ItemType: defaultType }
            );
        } else {
            RaiseEvent2(
                EventTypes.CenterEventTypes.loaddefaulttoolbar,
                md.EventLimiterName,
                eventNameSpace.notify
            );
        }

    }



    protected BaseDataCenterContainer: JQuery;

    constructor(
        container: JQuery,
        centertype: CenterType,
        eventLimiterName: string,
        baseDataCenterContainer: JQuery,
        inDefaultContainer: boolean
    ) {
        if (
            !container ||
            !container.length ||
            !$.contains(document.documentElement, container[0])
        )
            throw "Menu container is either detached from document or nothing...";
        if (typeof centertype === "undefined" || centertype === null)
            throw "You must pass a Center Type";

        this.MenuContainer = container;
        this.CenterType = centertype;
        this.EventLimiterName = eventLimiterName;
        this.BaseDataCenterContainer = baseDataCenterContainer;
        this.UniquePinnableClass = "pinnable_" + eventLimiterName;
        this.InDefaultContainer = inDefaultContainer;

    }

    public Init() {
        this.CreateMenuDrawer();
    }
    protected abstract BuildMenuItem(data): { html: string | JQuery } | { caption: string };
    protected DSOptions(
        storeopts: DevExpress.data.CustomStoreOptions
    ): DevExpress.data.DataSourceOptions {
        let md = this;

        return {
            store: new dxCustomStore(storeopts),
            paginate: false,
            map(data) {
                let obj = {
                    badge: "",
                    refData: data
                };

                Object.assign(obj, md.BuildMenuItem(data));

                return obj;
            }
        };
    }

    protected StoreOptions(): DevExpress.data.CustomStoreOptions {
        let md = this;
        return {
            load(opts) {
                let d = $.Deferred();
                if (md.MenuItems) {
                    return d.promise(d.resolve(md.MenuItems));
                }
                new TDFRequest({ url: `${md.MenuItemsPath}${md.MenuItemsQueryString}`, type: "GET" })
                    .MakeRequest()
                    .done(response => {
                        md.MenuItems = response;
                        d.resolve(response);
                    });
                return d.promise();
            }
        };
    }

    protected MenuOffset() {
        // if (GetDevice().isDevice || !this.InDefaultContainer) {
        return 0;
        // } else {
        //   return $("#tdfnavbar").outerHeight();
        // }
    }
    CreateMenuDrawer() {
        let md = this;

        let pinclass = md.MenuContainer.hasClass("icons")
            ? "menu-pinned icons"
            : "menu-pinned";

        $(md.BaseDataCenterContainer).append(`<div id="${
            md.FrameIDs.SideNavContainerID
            }" class="sidenav ${
            md.MenuContainer.hasClass("menu-pinned") ? pinclass : ""
            } ${md.UniquePinnableClass}" style="margin-top:${md.MenuOffset()}px;">
                                          <span id='${
            md.FrameIDs.SideMenuUnpinID
            }' class="side-menu-unpin fa fa-thumb-tack" style="cursor:pointer;" ></span>
                                           <span id='${
            md.FrameIDs.SideMenuPinID
            }' class="side-menu-pin fa fa-thumb-tack fa-flip-vertical" style="cursor:pointer;" title='Right Click for More Options' ></span>
                                          <span id='${
            md.FrameIDs.CloseBtnID
            }' class="closebtn dx-icon dx-icon-remove" style="cursor:pointer;" ></span>
                                          <div id="${
            md.FrameIDs
                .MenuDrawerListContainerID
            }"></div>
					</div>`);

        if (
            md.MenuContainer.hasClass("icons") ||
            !md.MenuContainer.hasClass("menu-pinned")
        ) {
            md.AddOpenCloseHoverEvents();
        }

        $(`#${md.FrameIDs.CloseBtnID}`).on("click", () => {
            md.CloseNavDrawer();
        });

        $(`#${md.FrameIDs.SideMenuUnpinID}`).on("click", () => {
            Preferences.SetPreferences([
                {
                    PropertyName: md.PreferenceInfo.Property,
                    PropertyValue: '0',
                    ModuleName: md.PreferenceInfo.Module
                }, {
                    PropertyName: `${md.PreferenceInfo.Property}-icons`,
                    PropertyValue: '0',
                    ModuleName: md.PreferenceInfo.Module
                }
            ])
            $(`.${md.UniquePinnableClass}`).removeClass("menu-pinned");
            $(`.${md.UniquePinnableClass}`).removeClass("icons");
            md.AddOpenCloseHoverEvents();
        });

        $(`#${md.FrameIDs.SideMenuPinID}`).on("click", () => {
            Preferences.SetPreferences([
                {
                    PropertyName: md.PreferenceInfo.Property,
                    PropertyValue: '1',
                    ModuleName: md.PreferenceInfo.Module
                }, {
                    PropertyName: `${md.PreferenceInfo.Property}-icons`,
                    PropertyValue: '0',
                    ModuleName: md.PreferenceInfo.Module
                }
            ]);
            $(`.${md.UniquePinnableClass}`).addClass("menu-pinned");
            md.RemoveOpenCloseHoverEvents();
        });

        $(`#${md.FrameIDs.PinOptionsID}`).remove();
        $(`<div id='${md.FrameIDs.PinOptionsID}'/>`).appendTo("body");
        $(`#${md.FrameIDs.PinOptionsID}`).dxContextMenu({
            items: [
                { text: "Pin Menu Open" },
                { text: "Pin Menu Open ( Icons Only )" }
            ],
            target: `#${md.FrameIDs.SideNavContainerID} .side-menu-pin`,
            onItemClick(e) {
                switch (e.itemData.text) {
                    case "Pin Menu Open":
                        Preferences.SetPreferences([
                            {
                                PropertyName: md.PreferenceInfo.Property,
                                PropertyValue: '1',
                                ModuleName: md.PreferenceInfo.Module
                            }, {
                                PropertyName: `${md.PreferenceInfo.Property}-icons`,
                                PropertyValue: '0',
                                ModuleName: md.PreferenceInfo.Module
                            }
                        ]);
                        $(`.${md.UniquePinnableClass}`).addClass("menu-pinned");
                        $(`.${md.UniquePinnableClass}`).removeClass("icons");
                        md.RemoveOpenCloseHoverEvents();
                        break;
                    case "Pin Menu Open ( Icons Only )":
                        Preferences.SetPreferences([
                            {
                                PropertyName: md.PreferenceInfo.Property,
                                PropertyValue: '1',
                                ModuleName: md.PreferenceInfo.Module
                            }, {
                                PropertyName: `${md.PreferenceInfo.Property}-icons`,
                                PropertyValue: '1',
                                ModuleName: md.PreferenceInfo.Module
                            }
                        ]);
                        $(`.${md.UniquePinnableClass}`).addClass("menu-pinned icons");
                        md.AddOpenCloseHoverEvents();
                        break;
                }
            }
        });

        //md.MenuContainer.append($(`<span id='menu-open' style="font-size:30px;cursor:pointer">&#9776;</span>`).on("click", () => {
        //    md.OpenNavDrawer();
        //}));

        if (md.MenuContainer.hasClass("menu-pinned")) {
            md.MenuCtrlInstance;

            if (md.MenuContainer.hasClass("icons")) {
                md.AddOpenCloseHoverEvents();
            }
        }
        md.MenuItemsDataSource.load().done(() => {
            md.LoadDefault();
        });

        md.SubscribeEvents();
    }

    private AddOpenCloseHoverEvents() {
        let md = this,
            timeoutIds = [];

        $(`#${md.FrameIDs.SideNavContainerID}`)
            .mouseenter(() => {
                timeoutIds.push(
                    setTimeout(() => {
                        $(`#${md.FrameIDs.SideNavContainerID}`).removeClass("icons");
                    }, 0.3e3)
                );
            })
            .mouseleave(() => {
                for (let i = 0, length = timeoutIds.length; i < length; i++) {
                    clearTimeout(timeoutIds[i]);
                }

                $(`#${md.FrameIDs.SideNavContainerID}`).addClass("icons");
                md.CloseNavDrawer();
            });
    }

    private RemoveOpenCloseHoverEvents() {
        let md = this;
        $(`#${md.FrameIDs.SideNavContainerID}`)
            .off("mouseenter")
            .off("mouseleave");
    }

    protected SubscribeEvents() {
        let md = this;

        AddHandler2(
            EventTypes.CenterEventTypes.itemtype,
            md.EventLimiterName,
            eventNameSpace.modify,
            $(`#${md.FrameIDs.SideNavContainerID}`),
            md.OnMenuSelectionChanged.bind(md)
        );
        AddHandler2(
            EventTypes.CenterEventTypes.opendatacentermenu,
            md.EventLimiterName,
            eventNameSpace.request,
            $(`#${md.FrameIDs.SideNavContainerID}`),
            md.OnOpenMenuRequested.bind(md)
        );

        $(`#${md.FrameIDs.SideNavContainerID}`).off(
            "transitionend",
            md.OnMenuTransitioned.bind(this)
        );
        $(`#${md.FrameIDs.SideNavContainerID}`).on(
            "transitionend",
            md.OnMenuTransitioned.bind(this)
        );
    }

    OnOpenMenuRequested(e: JQueryEventObject, data) {
        let md = this;
        md.OpenNavDrawer();
    }

    protected OpenNavDrawer() {
        let md = this;
        md.MenuCtrlInstance;

        document.getElementById(md.FrameIDs.SideNavContainerID).style.width = "250px";

        document.getElementById(md.FrameIDs.SideNavContainerID).style.visibility = 'visible';
    }

    protected CloseNavDrawer() {
        let md = this;
        document.getElementById(md.FrameIDs.SideNavContainerID).style.width = "0";

        document.getElementById(md.FrameIDs.SideNavContainerID).style.visibility = 'hidden';

        $(window).off("click.md");
        $(`#${md.FrameIDs.SideNavContainerID}`).off("click.md");
    }

    OnMenuTransitioned(e: JQueryEventObject, data) {
        let md = this;
        let ev: TransitionEvent = e.originalEvent as TransitionEvent;
        if (
            ev.propertyName === "width" &&
            (ev.target as HTMLElement).style.width === "250px"
        ) {
            //NEEDTODO - what is this selector???
            let tab = $(".dx-item.dx-tab.dx-tab-selected").find("[data-tabitemtype]");
            if (tab.length > 0) {
                let type = tab.data("tabitemtype");
                let item = $(`[data-sidenavitemtype='${type}']`);
                let listitem = item
                    .parents()
                    .closest(".dx-item.dx-list-item.dx-list-slide-menu-wrapper");
                md.SelectItem(item);
            }
            $(window).on(
                "click.md",
                Debounce(
                    () => {
                        md.CloseNavDrawer();
                    },
                    100,
                    false
                )
            );
            $(`#${md.FrameIDs.SideNavContainerID}`).on("click.md", event => {
                event.stopPropagation();
            });
        }
    }

    OnMenuSelectionChanged(e: JQueryEventObject, data) {
        let md = this;
        if ($(`#${md.FrameIDs.SideNavContainerID}.menu-pinned`).length > 0) {
            let item = $(`[data-sidenavitemtype='${data.ItemType}']`);
            let listitem = item
                .parents()
                .closest(".dx-item.dx-list-item.dx-list-slide-menu-wrapper");
            md.SelectItem(listitem);
        } else {
            this.CloseNavDrawer();
        }
    }

    protected abstract SelectItem(item: JQuery)
}
