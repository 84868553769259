import { DataCenterTabs } from "./datacentertabs";
import { RaiseEvent2, AddHandler2 } from "../infrastructure/events/ui_events";
import { BiDataCenterGrid } from "../components/grids/bi/bidatacentergrid";
import { eventNameSpace, EventTypes } from "../enums/webevents/enums";
import { TDFDataCenters } from "../interfaces/datacenters/tdfdatacenters";
import { GetPreference } from "../infrastructure/user/getpreference";
import { GridSetup } from "services/grid/helpers/gridsetup";
import { BSIGrids, ISummary, BSIGoalType } from "enums/bi/enums";
import { DataScopeSelection } from "components/dashboards/dynamicdatascopeselector";
import { CloneIT } from "util/allutils";

export class BiDataCenterTabs extends DataCenterTabs {
  protected SummarySettings: DataScopeSelection;
  RollupInfoURL: string = "/bi/GetRollupInfo";

  Spectabs = [
    "SalesSummary",
    "GeneralSummary",
    "GenericGroupSummary",
    "InventorySummary"
  ];

  Spectypes = [
    BSIGrids.GapAnalysis
  ];

  RenderContent(tabItemData: TDFDataCenters.DataCenterTabs.TabItem) {
    let bidct = this;

    if (
      $.inArray(tabItemData.ItemType, bidct.Spectypes) > -1 ||
      bidct.Spectabs.some(a => {
        return tabItemData.GUID.includes(a);
      })
    ) {
      let id = tabItemData.GUID ? tabItemData.GUID : "Welcome";
      //detach the container that is currently there
      if (bidct.DataContainer.find(`#visible-grid-${bidct.EventLimiterName}`).length > 0) {
        bidct.DataContainer.find(`#visible-grid-${bidct.EventLimiterName}`).detach();
      }
      let toappend =
        bidct.GridContentCache[id] || {
          Element: $(`<div id='visible-grid-${bidct.EventLimiterName}' style='height: 100%' />`),
          NeedsUpdated: false
        };

      bidct.DataContainer.append(toappend.Element);
      if (!bidct.GridContentCache[id]) {
        let newEl = $("<div/>").height('100%');
        toappend.Element.append(newEl);
        if (id === "Welcome") {
          //newEl.append(bidct.Welcome);
        } else {

          bidct.InitSpecialTab(newEl, tabItemData);
          //new TDF[tabItemData.GUID](
          //  newEl,
          //  bidct.CenterType,
          //  tabItemData,
          //  bidct.EventLimiterName
          //);
        }
        bidct.GridContentCache[id] = toappend;
      } else {
        if (bidct.GridContentCache[id] && tabItemData.GUID) {
          if (bidct.GridContentCache[id].NeedsUpdated) {
            bidct.GridContentCache[id].NeedsUpdated = false;

            bidct.ReloadSpecialTab(toappend.Element, tabItemData);
          }

          RaiseEvent2(EventTypes.CenterEventTypes.found, bidct.EventLimiterName, eventNameSpace.notify, tabItemData);
          RaiseEvent2(EventTypes.CenterEventTypes.updateButtons, bidct.EventLimiterName, eventNameSpace.request, { ItemType: tabItemData.ItemType, CenterType: bidct.CenterType });

        }
      }
    } else {
      super.RenderContent(tabItemData);
    }
  }

  protected InitGrid(
    gridElem: JQuery,
    data: TDFDataCenters.DataCenterTabs.TabItem
  ) {
    let bidct = this;
    let grid = new BiDataCenterGrid(
      gridElem,
      bidct.CenterType,
      data,
      bidct.EventLimiterName
    )

    if (bidct.ExtraGridRequestArgs) {
      let gridRequest = grid.dataService.RequestArgs;
      for (const prop in bidct.ExtraGridRequestArgs) {
        if (bidct.ExtraGridRequestArgs.hasOwnProperty(prop)) {
          gridRequest[prop] = bidct.ExtraGridRequestArgs[prop];
        }
      }
      grid.dataService.RequestArgs = gridRequest;
    }

    grid.init();
  }

  protected InitSpecialTab(
    newEl: JQuery,
    tabItemData: TDFDataCenters.DataCenterTabs.TabItem
  ) {
    let bidct = this;

    let moduleName = tabItemData.GUID;
    if ($.inArray(tabItemData.ItemType, bidct.Spectypes) > -1) {
      moduleName = BSIGrids[bidct.Spectypes[$.inArray(tabItemData.ItemType, bidct.Spectypes)]];
    } else if (
      bidct.Spectabs.some(a => {
        return tabItemData.GUID.includes(a);
      })
    ) {
      moduleName = bidct.Spectabs[
        bidct.Spectabs.findIndex(a => {
          return tabItemData.GUID.includes(a);
        })
      ];
    }

    import(`../datacenters/bidatacenters/${moduleName.toLowerCase()}`).then(
      mod => {
        new mod[moduleName](
          newEl,
          bidct.CenterType,
          tabItemData,
          bidct.EventLimiterName
        );
        RaiseEvent2(
          EventTypes.CenterEventTypes.found,
          bidct.EventLimiterName,
          eventNameSpace.notify,
          tabItemData
        );
        RaiseEvent2(EventTypes.CenterEventTypes.updateButtons, bidct.EventLimiterName, eventNameSpace.request, {
          ItemType: tabItemData.ItemType, CenterType: bidct.CenterType
        });
      }
    );
  }

  protected ReloadSpecialTab(element, tabItemData) {
    let bidct = this;

    element.empty();
    let newEl = $('<div />');
    element.append(newEl);

    bidct.InitSpecialTab(newEl, tabItemData);
  }

  SubScribeEvents() {
    let bidct = this;

    super.SubScribeEvents();

    AddHandler2(
      EventTypes.BIEventTypes.currentsummaryselection,
      bidct.EventLimiterName,
      eventNameSpace.notify,
      bidct.TabsContainer,
      bidct.OnSummaryOrGoalSelectionChanged.bind(bidct)
    );

    AddHandler2(
      EventTypes.BIEventTypes.currentgoalselection,
      bidct.EventLimiterName,
      eventNameSpace.notify,
      bidct.TabsContainer,
      bidct.OnSummaryOrGoalSelectionChanged.bind(bidct)
    );
  }

  protected OnSummaryOrGoalSelectionChanged(
    e,
    d: { NewSummarySettings: DataScopeSelection } | { NewGoalType: BSIGoalType }
  ) {
    let bidct = this;
    let tab = bidct.TabInstance.option('selectedItem');

    for (const key in bidct.GridContentCache) {
      if (bidct.GridContentCache.hasOwnProperty(key)) {
        let content = bidct.GridContentCache[key];
        content.NeedsUpdated = true;

        if (tab.GUID === key) {
          bidct.RenderContent(tab);
        }
      }
    }
  }

  public get Welcome() {
    if (this._Welcome) {
      return this._Welcome;
    }

    this._Welcome = `<div id="biWelcome" class="center-centered-content">
              <center>
                <div style="margin-top:100px">
                  <span class="icon-BI biWelcomeIcon"></span>
                </div>
                <h3>Welcome to BI</h3>
                <h5 style="color:#A4A4A4">Select an item from the menu to get started.</h5>
                ${
      GetPreference("HomeList", "BSISummary")
        ? ``
        : `<div style="padding-bottom: 100px"></div>
                       <div id="note-create-bi" class="bg-danger">
                           Data will NOT load in the BI Center until you have made a Summary Settings selection.  Please click the Summary Settings button to do so.  
                       </div>`
      }
                </center>
            </div>
    </div>`;

    return this._Welcome;
  }
}
