import { DrillHelper } from "./drillhelper";
import { Dialog } from "../../components/dialogs/dialog";
import { Grid } from "../../legacy-centers-grids/grid";
import { GridSetup } from "../../services/grid/helpers/gridsetup";
import { GridContainerTypes, CenterType } from "../../enums/enums";
import { TDFRequest } from "../../services/request";
import { ChildGridTypes } from "../../enums/bi/enums";
import { Preferences } from "../../infrastructure/user/preferences";
import dxDataSource from "devextreme/data/data_source";
import {
  BI_DrilldownItem,
  BI_BreadcrumbInfo
} from "../../components/grids/bi/legacy/legacybi";
import { GetDevice, DomSafeID } from "../../util/allutils";
import dxArrayStore from "devextreme/data/array_store";
import { GridToolbar } from "../../components/grids/gridtoolbars/gridtoolbar";
import { BiColumnTemplateHandler } from "./bi_utils";
import devices from "devextreme/core/devices";
export class DrilldownDialog {
  DrillManager: DrillHelper;
  CenterType = -1;
  DrilldownForm: Dialog;
  DrilldownToolbar: GridToolbar;
  DrilldownGrid: Grid;
  private EventLimiterName: string = "";
  _RequestModel: GridSetup.BIDrillDataRequestModel;
  TDFContainerType: GridContainerTypes = GridContainerTypes.BIGroupSummaryDrilldown;

  // uRLs
  BIDrillDataURL: string = "/GridData/BI";

  // element IDs
  MyDialogID: string = "";
  CustomIDString: string = ""; // todo:  Customize this.
  DrilldownMainDivID: string = "";
  DrilldownHeaderAreaID: string = "";
  DrilldownBreadcrumbContainerID: string = "";
  DrilldownToolbarContainerID: string = "";
  DrilldownContentAreaID: string = "";
  DrilldownGridContainerID: string = "";
  DrilldownBreadCrumbToolbarID: string = "biBreadCrumbToolbar";
  DrilldownBreadCrumbToolbarTypeSelectorID: string = "biBreadCrumbTypeSelector";
  DrilldownBreadcrumbListID: string = "biBreadCrumbList";
  DrilldownTagCloudID: string = "";

  constructor(manager: DrillHelper, eventLimiterName: string) {
    let theDrilldownDialog: DrilldownDialog = this;
    theDrilldownDialog.DrillManager = manager;
    theDrilldownDialog.EventLimiterName = eventLimiterName;

    theDrilldownDialog.SetIDs();
  }

  SetIDs() {
    let theDrilldownDialog: DrilldownDialog = this;
    // todo:  Further customize these IDs to be very unique.
    let uniquePiece: string =
      String(
        theDrilldownDialog.DrillManager.SummarySettings.ActiveFolderLevel
      ) +
      "_" +
      Math.ceil(Math.random() * 999 + 1).toString();
    theDrilldownDialog.MyDialogID = "BI_Drilldown_Dialog_" + uniquePiece;

    theDrilldownDialog.DrilldownMainDivID =
      "BI_Drilldown_MainDiv_" + uniquePiece;
    theDrilldownDialog.DrilldownHeaderAreaID =
      "BI_Drilldown_HeaderArea_" + uniquePiece;
    theDrilldownDialog.DrilldownToolbarContainerID =
      "BI_Drilldown_ToolbarContainer_" + uniquePiece;
    theDrilldownDialog.DrilldownContentAreaID =
      "BI_Drilldown_ContentArea_" + uniquePiece;
    theDrilldownDialog.DrilldownGridContainerID =
      "BI_Drilldown_GridContainer_" + uniquePiece;
    theDrilldownDialog.DrilldownBreadcrumbContainerID =
      "BI_Drilldown_BreadcrumbContainer_" + uniquePiece;

    theDrilldownDialog.DrilldownBreadCrumbToolbarID =
      "BI_BreadCrumbToolbar_" + uniquePiece;
    theDrilldownDialog.DrilldownBreadCrumbToolbarTypeSelectorID =
      "BI_BreadCrumbTypeSelector_" + uniquePiece;
    theDrilldownDialog.DrilldownBreadcrumbListID =
      "BI_BreadCrumbList_" + uniquePiece;
    theDrilldownDialog.DrilldownTagCloudID =
      "BI_DrilldownTagCloud_" + uniquePiece;
  }

  InitialDropdownItem;
  DisplayDrilldown(
    initialDropdownItem?: BI_DrilldownItem
  ): JQueryPromise<DrilldownDialog> {
    let theDrilldownDialog: DrilldownDialog = this;
    let displayDrilldownDFD: JQueryDeferred<DrilldownDialog> = $.Deferred();
    theDrilldownDialog.InitialDropdownItem = initialDropdownItem;

    let dialogMessage = $("<div />")
      .attr("id", theDrilldownDialog.DrilldownMainDivID)
      .append(
        $("<div />")
          .attr("id", theDrilldownDialog.DrilldownHeaderAreaID)
          .append(
            $("<div />").attr(
              "id",
              theDrilldownDialog.DrilldownBreadcrumbContainerID
            )
          )
          .append(
            $("<div />").attr(
              "id",
              theDrilldownDialog.DrilldownToolbarContainerID
            )
          )
      )
      .append(
        $("<div />")
          .attr("id", theDrilldownDialog.DrilldownContentAreaID)
          .css("padding", "3px")
          .append(
            $("<div />").attr("id", theDrilldownDialog.DrilldownGridContainerID)
          )
      );

    theDrilldownDialog.DrilldownForm = new Dialog({
      size: "size-wide",
      id: theDrilldownDialog.MyDialogID,
      title: "Drill-Down Summary Details", // todo:  Or Drill-Down???
      closable: true,
      body: dialogMessage,
      type: "type-primary",
      onHiding: function (e) {
        theDrilldownDialog.DrillManager.TruncateDrilldownBreadcrumbs(0);
      }
    });

    theDrilldownDialog.DrilldownForm.open().done(function () {
      theDrilldownDialog.InitializeDrilldownBreadcrumbs();
      displayDrilldownDFD.resolve();
    });

    return displayDrilldownDFD.promise(theDrilldownDialog);
  }

  GetChildTypeDrilldownFilter() {
    let theDrilldownDialog: DrilldownDialog = this;
    return [
      [
        "BILevelsUsed",
        "contains",
        theDrilldownDialog.DrillManager.SummarySettings.ActiveSelectionLevel
      ],
      "&&",
      ["Used", "=", false],
      "&&",
      ["Hidden", "=", false]
    ];
  }

  GetNextChildTypeFilter() {
    let theDrilldownDialog: DrilldownDialog = this;
    return [
      [
        "BILevelsUsed",
        "contains",
        theDrilldownDialog.DrillManager.SummarySettings.ActiveSelectionLevel
      ],
      "&&",
      ["Used", "=", false],
      "&&",
      ["Hidden", "=", false],
      "&&",
      [
        "ChildGridType",
        "<>",
        theDrilldownDialog.DrillManager.CurrentDrilldownInfo.ChildGridType
      ]
      //"&&",
      // This is not working because the first item in filter expression is the getter and the 3rd item is the value.
      //[
      //    "!",
      //    [theDrilldownDialog.CurrentDrilldownInfo.DisallowedChildGridTypeChildren, "contains", "ChildGridType"]
      //]
    ];
  }

  InitializeDrilldownBreadcrumbs() {
    let theDrilldownDialog: DrilldownDialog = this;

    $("#" + theDrilldownDialog.DrilldownBreadcrumbContainerID).append(
      "<div id='" + theDrilldownDialog.DrilldownBreadCrumbToolbarID + "' />"
    );

    let toolbarItems = [
      {
        locateInMenu: "auto",
        location: "before",
        template: function (itemData: any, itemIndex: number, itemElement: any) {
          let html =
            "<div id='" +
            theDrilldownDialog.DrilldownTagCloudID +
            "' class='biTagCloud'><small class='bi-breadcrumb'" +
            "><ol id='" +
            theDrilldownDialog.DrilldownBreadcrumbListID +
            "' class='breadcrumb'/></small></div>";
          itemElement.append(html);
        }
      },
      {
        locateInMenu: "auto",
        location: "after",
        widget: "dxSelectBox",
        options: {
          elementAttr: {
            id: theDrilldownDialog.DrilldownBreadCrumbToolbarTypeSelectorID
          },
          dataSource: theDrilldownDialog.GetChildTypePickerDataSource(),
          displayExpr: "Caption",
          title: "Choose a type",
          // value: theDrilldownDialog.DrilldownInfo[0],     // Don't set the value here because it might be filtered out.  Instead, onMenuload, we select the first item in the drop down.
          onValueChanged: function (e: any) {
            theDrilldownDialog.DrillManager.CurrentDrilldownInfo = e.value;
            // Changing the Child Type will cause the grid to need to re-load.  I am clearing the grid so it's not confusing if it hasn't reloaded or not.
            // I ended up emptying the grid content and re-adding the div for the grid because if I simply emptied the GridContainer, the dxDataGrid would not show back up.
            $("#" + theDrilldownDialog.DrilldownContentAreaID).empty();
            $("#" + theDrilldownDialog.DrilldownContentAreaID).append(
              $("<div />").attr(
                "id",
                theDrilldownDialog.DrilldownGridContainerID
              )
            );

            theDrilldownDialog
              .DrilldownToolbarModel()
              .done(function (toolbarResponse) {
                theDrilldownDialog.GetDrilldownRequest().done(function () {
                  theDrilldownDialog.DrilldownToolbar.GridDataRequestModel =
                    theDrilldownDialog._RequestModel;
                  theDrilldownDialog.DrilldownToolbar.RenderAndLoad();
                });
              });
          }
        }
      }
    ];

    let toolbarOptions2 = {
      dataSource: {
        store: toolbarItems
      },
      onContentReady: function (e) {
        theDrilldownDialog.BuildDrilldownBreadcrumbs();
        let childPicker: DevExpress.ui.dxSelectBox | any = $(
          "#" + theDrilldownDialog.DrilldownBreadCrumbToolbarTypeSelectorID
        ).dxSelectBox("instance");
        childPicker._dataSource.load();
        childPicker.repaint();

        if (theDrilldownDialog.InitialDropdownItem) {
          childPicker.option("value", theDrilldownDialog.InitialDropdownItem);
        } else {
          childPicker.option(
            "value",
            childPicker.option("dataSource").items()[0]
          );
        }
      }
    };
    $("#" + theDrilldownDialog.DrilldownBreadCrumbToolbarID).dxToolbar(
      toolbarOptions2
    );
  }

  RebuildDrilldownBreadCrumbs() {
    let theDrilldownDialog: DrilldownDialog = this;
    $("#" + theDrilldownDialog.DrilldownBreadcrumbListID).empty();
    theDrilldownDialog.BuildDrilldownBreadcrumbs();
  }

  BuildDrilldownBreadcrumbs() {
    let theDrilldownDialog: DrilldownDialog = this;
    let count = theDrilldownDialog.DrillManager.BreadcrumbHistory.length;

    $.each(theDrilldownDialog.DrillManager.BreadcrumbHistory, function (
      index,
      breadcrumbInfo: BI_BreadcrumbInfo
    ) {
      let crumb: JQuery = $("<li />").addClass('breadcrumb-item');
      let link: JQuery = $("<span />").text(breadcrumbInfo.Caption);

      if (index < count - 1) {
        link = $("<a />").text(breadcrumbInfo.Caption);
        link.addClass("breadButton").css("cursor", "pointer");
        crumb.on("click", function (ev) {
          let newChildItem: number = theDrilldownDialog.DrillManager.TruncateDrilldownBreadcrumbs(
            index
          );

          theDrilldownDialog.RebuildDrilldownBreadCrumbs();
          theDrilldownDialog.RefreshChildTypePicker();

          // select the old top item after refreshing the child type picker.
          let childTypePicker: DevExpress.ui.dxSelectBox | any = $(
            "#" + theDrilldownDialog.DrilldownBreadCrumbToolbarTypeSelectorID
          ).dxSelectBox("instance");
          childTypePicker.option(
            "value",
            childTypePicker._dataSource._items.filter(function (
              element: any,
              index: number,
              array: any[]
            ) {
              return element.ChildGridType === newChildItem;
            })[0]
          );
        });
      }
      crumb.append(link);
      $("#" + theDrilldownDialog.DrilldownBreadcrumbListID).append(crumb);
    });
  }

  RefreshCurrentGrid() {
    let theDrilldownDialog: DrilldownDialog = this;
    theDrilldownDialog.DrilldownToolbar.RefreshCurrentGrid(theDrilldownDialog);
  }

  DrilldownToolbarModel(): JQueryPromise<DrilldownDialog> {
    let theDrilldownDialog: DrilldownDialog = this;
    let toolbarDFD: JQueryDeferred<DrilldownDialog> = $.Deferred();

    let request = new TDFRequest({
      url: "/core/user/GetToolbarInfo/",
      type: "GET",
      data: {
        type: theDrilldownDialog.DrillManager.CurrentDrilldownInfo.GridItemType,
        containertype: theDrilldownDialog.TDFContainerType
      }
    });

    request.MakeRequest().done(function (response: any) {
      response.GridDataRequestType = "POST";
      theDrilldownDialog.DrilldownToolbar = new GridToolbar(response);
      theDrilldownDialog.DrilldownToolbar.MakeColumns = theDrilldownDialog.MakeDrilldownColumns.bind(
        theDrilldownDialog
      );
      theDrilldownDialog.DrilldownToolbar.ToolbarContainerID =
        theDrilldownDialog.DrilldownToolbarContainerID;
      theDrilldownDialog.DrilldownToolbar.MakeGrid = theDrilldownDialog.MakeDrilldownGrid.bind(
        theDrilldownDialog
      );
      theDrilldownDialog.DrilldownToolbar.OnViewChanged = theDrilldownDialog.OnViewChanged.bind(
        theDrilldownDialog
      );
      if (!theDrilldownDialog.DrilldownToolbar.CurrentView) {
        theDrilldownDialog.DrilldownToolbar.CurrentView =
          theDrilldownDialog.DrilldownToolbar.DefaultView;
      }

      toolbarDFD.resolve(theDrilldownDialog);
    });

    return toolbarDFD.promise(theDrilldownDialog);
  }

  OnViewChanged(data) {
    let theDrilldownDialog: DrilldownDialog = this;

    theDrilldownDialog.DrilldownToolbar.GetGridRequestModel(data).done(
      response => {
        if (response) {
          theDrilldownDialog.MakeDrilldownGrid(response);
        }
      }
    );
  }

  RefreshChildTypePicker() {
    let theDrilldownDialog: DrilldownDialog = this;

    let childTypePicker: DevExpress.ui.dxSelectBox | any = $(
      "#" + theDrilldownDialog.DrilldownBreadCrumbToolbarTypeSelectorID
    ).dxSelectBox("instance");
    childTypePicker._dataSource = theDrilldownDialog.GetChildTypePickerDataSource();
    (childTypePicker._dataSource as any).reload();
    childTypePicker.repaint();
  }

  GetChildTypePickerDataSource() {
    let theDrilldownDialog: DrilldownDialog = this;

    return new dxDataSource({
      store: new dxArrayStore({
        data: theDrilldownDialog.DrillManager.DrillInfo
      }),
      filter: theDrilldownDialog.GetChildTypeDrilldownFilter()
    });
  }

  GetDrilldownCol() {
    let theDrilldownDialog = this;

    return {
      alignment: "center",
      width: GetDevice().isDevice ? "25px" : "40px",
      visible: true,
      allowEditing: false,
      fixed: true,
      cellTemplate: function (container: any, options: any) {
        let childTypePicker: DevExpress.ui.dxSelectBox | any = $(
          "#" + theDrilldownDialog.DrilldownBreadCrumbToolbarTypeSelectorID
        ).dxSelectBox("instance");

        if (childTypePicker._dataSource._items.length > 1) {
          let drilldownActionButton = $(
            "<div id='drilldownActionButton" + options.rowIndex + "' style='cursor:pointer'>+</div>"
          );
          let popoverItem = $("<div id='popover" + options.rowIndex + "' />");

          drilldownActionButton.appendTo(container);
          popoverItem.appendTo(container);

          drilldownActionButton.on("dxclick", function (e) {
            let childTypeSelection = popoverItem
              .dxActionSheet({
                dataSource: {
                  store: new dxArrayStore({
                    data: theDrilldownDialog.DrillManager.DrillInfo.filter(
                      function (value, index, array) {
                        return (
                          theDrilldownDialog.DrillManager.CurrentDrilldownInfo.DisallowedChildGridTypeChildren.indexOf(
                            value.ChildGridType
                          ) < 0
                        );
                      }
                    )
                  }),
                  filter: theDrilldownDialog.GetNextChildTypeFilter()
                },
                itemTemplate: function (
                  itemData: any,
                  itemIndex: number,
                  itemElement: any
                ) {
                  return $('<a />')
                      .text(itemData.Caption)
                      .attr("href", "javascript:void(0)")
                    .css('cursor', 'zoom-in');
                },
                onItemClick: function (e) {
                  let data = options.data;

                  theDrilldownDialog.DrillManager.CurrentDrilldownInfo.Used = true;

                  if (
                    theDrilldownDialog.DrillManager.CurrentDrilldownInfo
                      .DisallowedChildGridTypeChildren.length > 0
                  ) {
                    let items = theDrilldownDialog.DrillManager.DrillInfo.filter(
                      function (element: any, index: number, array: any[]) {
                        // return the items that are disallowed.
                        return (
                          theDrilldownDialog.DrillManager.CurrentDrilldownInfo.DisallowedChildGridTypeChildren.indexOf(
                            element.ChildGridType
                          ) >= 0
                        );
                      }
                    );

                    $.each(items, function (index, value) {
                      value.Hidden = true;
                    });
                  }

                  let newCrumb: BI_BreadcrumbInfo = new BI_BreadcrumbInfo(
                    data["ClassID"],
                    data["Description"],
                    theDrilldownDialog.DrillManager.CurrentDrilldownInfo
                  );
                  theDrilldownDialog.DrillManager.AddCrumb(newCrumb);
                  theDrilldownDialog.RebuildDrilldownBreadCrumbs();
                  theDrilldownDialog.RefreshChildTypePicker();

                  // select the next top item after refreshing the child type picker.
                  let childTypePicker: DevExpress.ui.dxSelectBox | any = $(
                    "#" +
                    theDrilldownDialog.DrilldownBreadCrumbToolbarTypeSelectorID
                  ).dxSelectBox("instance");
                  childTypePicker.option(
                    "value",
                    childTypePicker._dataSource._items.filter(function (
                      element: any,
                      index: number,
                      array: any[]
                    ) {
                      return element.ChildGridType === e.itemData.ChildGridType;
                    })[0]
                  );
                },
                showTitle: false,
                usePopover: !GetDevice().isDevice,
                target: popoverItem,
                visible: false
              })
              .dxActionSheet("instance");

            childTypeSelection.show();
          });
        }
      }
    };
  }

  GridOptions() {
    let theDrilldownDialog: DrilldownDialog = this;

    let overriddenGridOptions: DevExpress.ui.dxDataGridOptions = {};

    if (
      theDrilldownDialog.DrillManager.CurrentDrilldownInfo.ChildGridType !=
      ChildGridTypes.acct
    ) {
      overriddenGridOptions.selection = {
        mode: "single",
        showCheckBoxesMode: "none"
      };

      overriddenGridOptions.stateStoring = {};

      overriddenGridOptions.onRowClick = function (e) {
        // overriding this method that otherwise is defaulted.
      };

      overriddenGridOptions.onSelectionChanged = function (e) {
        // overriding this method that otherwise is defaulted.
      };
    }

    overriddenGridOptions.onContentReady = function (e) {
      let id = DomSafeID(theDrilldownDialog.DrilldownGridContainerID);

      $(window).on("orientationchange", function () {
        if (devices.orientation() === "landscape") {
          $("#" + id).css("width", "100%");
          $("#recentitemcontainer").css("width", "100%");
        }
        // put this outside if, because some reason orientaion change on quote manager doesnt read landscape?
        $("#quotemanagergrid").css("width", "100%");
      });

      if (theDrilldownDialog._RequestModel) {
        let btnNextPage = e.element.find(".dx-next-button");
        let btnPrevPage = e.element.find(".dx-prev-button");
        let pageSizes = e.element.find(".dx-page-sizes");
        let grid = e.component as DevExpress.ui.dxDataGrid;

        $(btnNextPage).off("click");
        $(btnNextPage).on("click", function (event) {
          if ($(event.currentTarget).hasClass("dx-button-disable")) return;
          if (grid.pageIndex() === grid.pageCount() - 1) {
            event.stopPropagation();
            theDrilldownDialog._RequestModel.RequestArgs.page += 1;
            //    theDrilldownDialog.RemoveGridFromCache(theGridWOViews.ObjRef.CurrentSelectedItem.CurrentView);
            grid.pageIndex(0);
            theDrilldownDialog.RefreshCurrentGrid();
            //theDrilldownDialog.LoadGrid(theCenter.CurrentSelectedItem.CurrentView, e.element, false, theCenter.RequestModel.RequestArgs.page);
          }
        });

        $(btnPrevPage).off("click");
        $(btnPrevPage).on("click", function (event) {
          if ($(event.currentTarget).hasClass("dx-button-disable")) return;
          if (grid.pageIndex() === 0) {
            if (theDrilldownDialog._RequestModel.RequestArgs.page > 1) {
              theDrilldownDialog._RequestModel.RequestArgs.page -= 1;
              //theCenter.RemoveGridFromCache(theCenter.CurrentSelectedItem.CurrentView);
              let newIndex =
                Math.ceil(
                  theDrilldownDialog._RequestModel.RequestArgs.pageSize /
                  grid.pageSize()
                ) - 1;
              grid.pageIndex(newIndex);
              theDrilldownDialog.RefreshCurrentGrid();
              //theCenter.LoadGrid(theCenter.CurrentSelectedItem.CurrentView, e.element, false, theCenter.RequestModel.RequestArgs.page)
            }
          }
        });

        $(pageSizes).on("dxclick", function (e) {
          Preferences.SetPreference(
            "ClientGridPageSize",
            $(e.target).text(),
            "TDFMobile"
          );
        });

        if ($(btnNextPage).hasClass("dx-button-disable")) {
          if (
            theDrilldownDialog._RequestModel.RequestArgs.page <
            theDrilldownDialog.DrilldownGrid.GridDataResponse.Meta.TotalPages
          ) {
            btnNextPage.removeClass("dx-button-disable");
          }
        }

        if ($(btnPrevPage).hasClass("dx-button-disable")) {
          if (theDrilldownDialog._RequestModel.RequestArgs.page > 1) {
            btnPrevPage.removeClass("dx-button-disable");
          }
        }

        $(e.element)
          .find(".dx-header-row .dx-datagrid-action")
          .off("dxclick")
          .on("dxclick", function (event) {
            if (Preferences.GetPreference("SortOnServer", "WebGrid")) {
              event.stopPropagation();

              let field;
              let cname = $(this)
                .find(".dx-datagrid-text-content")
                .text();

              let sorts = theDrilldownDialog._RequestModel.RequestArgs.Sorts;

              $.each(grid.option("columns"), function (k, v) {
                if (v.caption) {
                  if (cname === v.caption) {
                    field = v.dataField.trim();
                  }
                } else {
                  if (cname === v.dataField) {
                    field = v.dataField.trim();
                  }
                }
              });

              if (sorts && sorts.length) {
                let found = false;

                sorts.forEach((val, idx) => {
                  if (val.Field === field) {
                    found = true;
                    switch (val.Direction) {
                      case "asc":
                        val.Direction = "desc";
                        break;
                      case "desc":
                        val.Direction = "asc";
                        break;
                    }
                  }
                });

                if (!found) {
                  sorts = [{ Field: field, Direction: "asc", Order: 0 }];
                }
              } else {
                sorts = [{ Field: field, Direction: "asc", Order: 0 }];
              }

              theDrilldownDialog._RequestModel.RequestArgs.Sorts = sorts;
              theDrilldownDialog.RefreshCurrentGrid();
            }
          });
      }
    };

    overriddenGridOptions.height = theDrilldownDialog.DrilldownForm.getModalBody().height() - 80

    return overriddenGridOptions;
  }

  MakeDrilldownGrid(data) {
    let theDrilldownDialog: DrilldownDialog = this;

    let drillDownCol:
      | DevExpress.ui.dxDataGridColumn
      | any = theDrilldownDialog.GetDrilldownCol();
    data.GridView.Layout.Columns.unshift(drillDownCol);

    theDrilldownDialog.DrilldownGrid = new Grid(
      data,
      {
        GridContainer: $("#" + theDrilldownDialog.DrilldownGridContainerID),
        GridToolbar: theDrilldownDialog.DrilldownToolbar
      },
      theDrilldownDialog.GridOptions(),
      theDrilldownDialog,
      e => {
        theDrilldownDialog.DrilldownToolbar.SelectionChanged(e);
      },

      /*NEEDTODO: get this using the awesomegrid and get that providing a column template handler*/
      theDrilldownDialog.BiColumnTemplate.bind(theDrilldownDialog)
    );

    theDrilldownDialog.DrilldownGrid.Render();
    theDrilldownDialog.DrilldownToolbar.CurrentGrid =
      theDrilldownDialog.DrilldownGrid;
  }

  BiColumnTemplate(col: any) {
    let theDrilldownDialog: DrilldownDialog = this;
    let centerType = theDrilldownDialog.DrillManager.CenterType;
    BiColumnTemplateHandler(
      col,
      centerType,
      theDrilldownDialog.EventLimiterName
    );
  }

  MakeDrilldownColumns(data) {
    let theDrilldownDialog: DrilldownDialog = this;

    let drillDownCol:
      | DevExpress.ui.dxDataGridColumn
      | any = theDrilldownDialog.GetDrilldownCol();

    data.GridView.Layout.Columns.unshift(drillDownCol);

    return data.GridView.Layout.Columns;
  }

  GetDrilldownRequest(viewGUID?: string) {
    let theDrilldownDialog: DrilldownDialog = this;
    let getRequestDFD = $.Deferred();

    let currentDrilldownType = $(
      "#" + theDrilldownDialog.DrilldownBreadCrumbToolbarTypeSelectorID
    )
      .dxSelectBox("instance")
      .option("value");

    let requestArgs = new GridSetup.BIDrillRequestArgs();
    requestArgs.ItemType = currentDrilldownType.ChildGridType;
    requestArgs.GUID =
      viewGUID || theDrilldownDialog.DrilldownToolbar.DefaultView;
    requestArgs.ContainerID = theDrilldownDialog.DrillManager.SummarySettings.CurrentlySelectedItemIDsAsString();
    requestArgs.BISummaryType =
      theDrilldownDialog.DrillManager.SummarySettings.ActiveSelectionLevel;
    requestArgs.GoalType = theDrilldownDialog.DrillManager.GoalType;
    requestArgs.OptionalGroupSummaryOptions = { DrillDown: true };

    requestArgs.DataDrillSettings = {
      SummaryType:
        theDrilldownDialog.DrillManager.SummarySettings.ActiveSelectionLevel,
      LevelColumns: [], // ex:  ["Level4ID", "AccountID", "PRClassID", "VendorID", "Alternate1"],
      LevelValues: [], // ex:  ["{1103FA20-16B2-4CE2-9C28-994A795DAE2B}", "{6DFAA46D-D6C0-4A1E-8B03-679A092DBBC0}", "HN000050", "20428", "%"],
      LevelGridTypes: [], // ex:  [8, 4, 1, 0, 2],
      AccountIDs:
        theDrilldownDialog.DrillManager.SummarySettings.ActiveSelectionLevel ===
          3
          ? theDrilldownDialog.DrillManager.AccountList
          : "",
      SalesmanID: "",
      // Note:  Desktop doesn't seem to change this anywhere.  I think there's some salesman logic that isn't being used and/or tested by desktop and isn't needed.  Bill is looking into it, and I'm just going to set this to false all the time.
      SalesmanSummary: false, // theDrilldownDialog.SummarySettingsSelectionPanel.ActiveSelectionLevel === 3 ? true : false,
      IsVendorSummary: (theDrilldownDialog.DrillManager.CenterType === CenterType.VendorBi)
    };

    // loop through the breadcrumb history to add to the request args.
    $.each(theDrilldownDialog.DrillManager.BreadcrumbHistory, function (
      index: number,
      crumb: BI_BreadcrumbInfo
    ) {
      requestArgs.DataDrillSettings.LevelColumns.push(
        crumb.DrilldownItem.LevelColumn
      );
      requestArgs.DataDrillSettings.LevelValues.push(crumb.Value);
      requestArgs.DataDrillSettings.LevelGridTypes.push(
        crumb.DrilldownItem.ChildGridType
      );
    });

    // push the final wildcard option.
    requestArgs.DataDrillSettings.LevelColumns.push(
      currentDrilldownType.LevelColumn
    );
    requestArgs.DataDrillSettings.LevelValues.push("%");
    requestArgs.DataDrillSettings.LevelGridTypes.push(
      currentDrilldownType.ChildGridType
    );

    theDrilldownDialog._RequestModel = new GridSetup.BIDrillDataRequestModel(
      requestArgs
    );

    return getRequestDFD.promise(getRequestDFD.resolve(theDrilldownDialog));
  }
}
