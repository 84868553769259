import { itemTypes, itemAccountTypes } from "../../../../enums/enums";
import dxDataSource from "devextreme/data/data_source";
import { RaiseEvent2 } from "../../../../infrastructure/events/ui_events";
import { ToolbarTemplateFactory } from "./toolbartemplatefactory";
import { eventNameSpace, EventTypes } from "../../../../enums/webevents/enums";
import dxArrayStore from "devextreme/data/array_store";
import "devextreme/ui/select_box";
import { GetItemTypeDisplayInfo } from "../../../../infrastructure/context";
import { GetDevice } from "util/allutils";
export function ItemTypeSelector(args) {
  //TODO: Add Config paramter and respect it
  let UniqueCenterName = '';
  let MultiSelectAllowed = true;
  let AllowedTypesToShow = [itemTypes.itemAllItems];
  let DefaultValue = itemTypes.itemAllItems;
  if (args && args.length) {
    args.map((v, k) => {
      if ($.isPlainObject(v)) {
        Object.keys(v).forEach((val, key) => {
          if (val.toLowerCase() === "uniquecentername") {
            UniqueCenterName = v[val];
          }
          if (val.toLowerCase() === "multiselectallowed") {
            MultiSelectAllowed = v[val];
          }
          if (val.toLowerCase() === "allowedtypestoshow") {
            AllowedTypesToShow = v[val];

            if ($.inArray(itemTypes.itemAllItems, AllowedTypesToShow) === -1) {
              AllowedTypesToShow.unshift(itemTypes.itemAllItems);
            }
          }
          if (val.toLowerCase() === "defaultvalue") {
            DefaultValue = v[val];
          }
        });
      }
    });
  }
  return ToolbarTemplateFactory(
    "before",
    "auto",
    false,
    null,
    null,
    (itemData: any, itemIndex: number, itemElement: JQuery) => {
      GetItemTypeDisplayInfo().done(function (response: any) {
        let itemtypeswitcher = $("<div />")
          .dxSelectBox({
            dataSource: new dxDataSource({
              store: new dxArrayStore({
                data: $.grep(response, function (val: any) {
                  return (
                    val.TypeName !== "" &&
                    val.TypeName !== "Unknown" &&
                    (AllowedTypesToShow && AllowedTypesToShow.length === 0 ||
                      (AllowedTypesToShow && AllowedTypesToShow.length === 1 &&
                        (AllowedTypesToShow[0] === itemTypes.itemAllItems))
                      ? $.inArray(val.TypeNum,
                        [
                          17,
                          25,
                          26,
                          21,
                          23,
                          26,
                          29,
                          31,
                          33,
                          34,
                          50,
                          51,
                          52,
                          53,
                          54,
                          996,
                          997,
                          998,
                          1001,
                          1002,
                          1003,
                          1004
                        ]
                      ) === -1
                      : $.inArray(val.TypeNum, AllowedTypesToShow) !== -1)
                  );
                }),
                key: "TypeNum"
              }),
              sort: "DisplayName"
            }),
            searchEnabled: true,
            displayExpr: "DisplayNamePlural",
            valueExpr: "TypeNum",
            value: DefaultValue,
            width: GetDevice().isDevice ? '100px' : '150px',
            onValueChanged: function (e) {
              RaiseEvent2(
                EventTypes.CenterEventTypes.itemtype,
                UniqueCenterName,
                eventNameSpace.modify,
                { ItemType: e.value }
              );

              //UI_Events.Dispatch(UI_Events.GetEventWithData(UI_Events.TypeChanged, e));
            }
          })
          .dxSelectBox("instance");
        if (itemtypeswitcher.option("dataSource").store()._array.length === 2) {
          itemtypeswitcher.option("disabled", true);
        }
        itemElement.append(itemtypeswitcher.element());
        return itemElement;
      });
    }
  );
}
