import { TDFDataCenters } from "../interfaces/datacenters/tdfdatacenters";
import { CenterType } from "../enums/enums";
import { GetPreference } from "../infrastructure/user/getpreference";
import { Preferences } from "../infrastructure/user/preferences";
import { IToolbarItemOverrideOptions } from "../interfaces/grids/interfaces";

/**
 * Inheriting from this class will cause a main container to be added to tdfbody content
 *   the main container will have a sidebar menu container and a data area container
 *   ************************************************************************************
 *              ____________Nav Above Here__________________________
 *              | _____  __________________________________________|
 *              | **M**  ***************************************** |
 *              | **E**  ***************************************** |
 *              | **N**  ***********Main Area********************* |
 *              | **U**  ***************************************** |
 *              | *****  ***************************************** |
 *              | _____  _________________________________________ |
 *              ____________Footer Below Here_______________________
 *
 *   ************************************************************************************
 * */
export abstract class BaseDataCenter {
  protected MyCreationArgs: TDFDataCenters.IBaseDataCenterCreationArgs;
  protected DataCenterContainerID: string = "tdfbodycontent";
  protected UniquePinnableClass: string = "pinnable";
  protected InDefaultContainer: boolean;

  protected abstract _CenterType: CenterType;
  get CenterType(): CenterType {
    return this._CenterType;
  }

  protected abstract initMenu(): void;

  protected get MenuPreferenceInfo(): { Module: string; Property: string; Default?: string } {
    let md = this;
    switch (md.CenterType) {
      case CenterType.AccountBi:
      case CenterType.Bi:
      case CenterType.ContactBi:
      case CenterType.OppBi:
      case CenterType.VendorBi:
        return { Module: "BSISummary", Property: "LeaveBiMenuOpen" };
      case CenterType.InfoCenter:
        return {
          Module: "ManagersConsole",
          Property: "LeaveInfoCenterMenuOpen"
        };
      case CenterType.Admin:
        return {
          Module: "AdminCenter",
          Property: "LeaveAdminMenuOpen"
        };
      case CenterType.Preference:
        return {
          Module: "PreferenceCenter",
          Property: "LeavePreferenceMenuOpen"
        };
      case CenterType.EventSessionManager:
        return {
          Module: "EventSession",
          Property: "LeaveMenuOpen",
          Default: '0'
        }
      case CenterType.EventInvitation:
        return {
          Module: "EventInvitation",
          Property: "LeaveMenuOpen"
        }
      case CenterType.LinkedItems:
        return {
          Module: "LinkedItemsCenter",
          Property: "LeaveMenuOpen",
          Default: '0'
        }
      case CenterType.GapAnalysis:
      case CenterType.GenericGroupSummary:
      case CenterType.InventorySummary:
        return {
          Module: "EmbeddedCenter",
          Property: "LeaveMenuOpen",
          Default: '0'
        }
    }
  }

  protected get PinClass(): string {
    return parseInt(
      Preferences.GetPreference(
        this.MenuPreferenceInfo.Property + "-icons",
        this.MenuPreferenceInfo.Module,
        "0"
      )
    ) === 0
      ? "menu-pinned"
      : "menu-pinned icons";
  }

  protected get MenuPinnedOpen() {
    let bdc = this;
    return (
      parseInt(
        Preferences.GetPreference(
          bdc.MenuPreferenceInfo.Property,
          bdc.MenuPreferenceInfo.Module,
          bdc.MenuPreferenceInfo.Default || '1'
        )
      ) === 1
    );
  }
  protected _MainAreaContainer: JQuery;
  /** The jquery container that contains everything the first child of tdfbodycontent*/
  get MainAreaContainer(): JQuery {
    let bdc = this;
    if (bdc._MainAreaContainer) return bdc._MainAreaContainer;
    let container: JQuery;
    if (bdc.MyCreationArgs && bdc.MyCreationArgs.MainAreaContainer) {
      container = $(bdc.MyCreationArgs.MainAreaContainer);
    }
    if (container && container.length) {
      bdc._MainAreaContainer = container;
    } else {
      /*HMMMM: Should i empty tdfbodycontent ??? */
      // $(`#${bdc.DataCenterContainerID}`).empty();   //TODO:  This empty call will be a problem in Account/Vendor/Opportunity/Contact BI
      bdc._MainAreaContainer = $(`<div id='base-datacenter-container${Date.now()}' class='tdf_bcdMac '/>`)
        .appendTo(`#${bdc.DataCenterContainerID}`);

      bdc.InDefaultContainer = true;
    }

    if (!jQuery.contains(document.documentElement, bdc._MainAreaContainer[0])) {
      //Element is detached
      throw "Unable to find container => _MainAreaContainer";
    } else {
      bdc._MainAreaContainer.css('position', 'relative');
    }

    return bdc._MainAreaContainer;
  }
  set MainAreaContainer(val: JQuery) {
    let bdc = this;
    if (val) bdc._MainAreaContainer = val;
    bdc.InDefaultContainer = false;
  }

  protected _MenuAreaContainer: JQuery;
  /** The jquery container that contains the entire sidebar menu*/
  get MenuAreaContainer(): JQuery {
    let bdc = this;
    if (bdc._MenuAreaContainer) return bdc._MenuAreaContainer;
    let container: JQuery;
    if (bdc.MyCreationArgs && bdc.MyCreationArgs.MenuAreaContainer) {
      container = $(bdc.MyCreationArgs.MenuAreaContainer);
    }
    if (container && container.length) {
      bdc._MenuAreaContainer = container;
    } else {
      bdc.MenuAreaContainer = $(`<div id='base-datacenter-menuarea-container${Date.now()}' class="tdf_bdcmac ${bdc.MenuPinnedOpen ? bdc.PinClass : ''} ${bdc.UniquePinnableClass}"/>`).appendTo(bdc.MainAreaContainer);
    }

    if (!jQuery.contains(document.documentElement, bdc.MenuAreaContainer[0])) {
      //Element is detached
      throw "Unable to find container => _MenuAreaContainer ";
    }
    return bdc._MenuAreaContainer;
  }
  set MenuAreaContainer(val: JQuery) {
    let bdc = this;
    if (val) bdc._MenuAreaContainer = val;
  }

  protected _MainDataAreaContainer: JQuery;
  /**The jquery container that contains the tabs and the toolbar*/
  get MainDataAreaContainer(): JQuery {
    let bdc = this;
    if (bdc._MainDataAreaContainer) return bdc._MainDataAreaContainer;
    let container: JQuery;
    if (bdc.MyCreationArgs && bdc.MyCreationArgs.MainDataAreaContainer) {
      container = $(bdc.MyCreationArgs.MainDataAreaContainer);
    }
    if (container && container.length) {
      bdc._MainDataAreaContainer = container;
    } else {
      bdc.MainDataAreaContainer = $(`<div id='base-datacenter-maindata-container${Date.now()}' class='tdf_bdcMdac ${
        bdc.MenuPinnedOpen ? bdc.PinClass : ''
        } ${
        bdc.UniquePinnableClass
        }'/>`)
        .height('100%')
        .css({
          'display': 'flex',
          'flex-direction': 'column'
        })
        .appendTo(bdc.MainAreaContainer);
    }

    if (!jQuery.contains(document.documentElement, bdc.MainAreaContainer[0])) {
      //Element is detached
      throw "Unable to find container => _MainDataAreaContainer ";
    }
    return bdc._MainDataAreaContainer;
  }
  set MainDataAreaContainer(val: JQuery) {
    let bdc = this;
    if (val) bdc._MainDataAreaContainer = val;
  }

  protected CustomName: string = "";
  constructor(customName?: string) {
    let bdc = this;
    if (customName) {
      bdc.CustomName = customName; //TODO:  Should we wrap this in Util.DomSafeID(theAccount.ItemID)?
      //bdc.CustomIDString = "_" + bdc.CustomName;
    }
    bdc.UniquePinnableClass = "pinnable_" + bdc.CustomName;
  }
  protected initContainers() {
    let bdc = this;
    if (!bdc.MainAreaContainer.length) throw "Error main container missing";
    if (!bdc.MenuAreaContainer.length) throw "Error menu container missing";
    if (!bdc.MainDataAreaContainer.length) throw "Error data container missing";
  }

  protected ValidateCreationArgs(args: TDFDataCenters.IBaseDataCenterCreationArgs) {
    let bdc = this;
    // if (args) {
    //   if (args.MainAreaContainer && !args.MainAreaContainer.startsWith("#", 0)) throw "MainAreaContainer_ID must begin with hashtag";
    //   if (args.MenuAreaContainer && !args.MenuAreaContainer.startsWith("#", 0)) throw "MenuAreaContainer_ID must begin with hashtag";
    //   if (args.MainDataAreaContainer && !args.MainDataAreaContainer.startsWith("#", 0)) throw "MainDataAreaContainer_ID must begin with hashtag";
    // }
    bdc.MyCreationArgs = args;
  }
}
