'use strict';
import { Dialog } from '../dialogs/dialog';
import { Notification } from '../dialogs/notification';
import 'devextreme/ui/scheduler';
import { itemTypes } from '../../enums/enums';
import * as Globalize from 'globalize';
import { GetPreference } from '../../infrastructure/user/getpreference';
import { IControlData } from '../../interfaces/interfaces';
import * as moment from 'moment';
import { TDFRequest } from '../../services/request';
import { ItemBase } from './itembase';
import 'devextreme/ui/toast';
import { DisplayInfo } from '../../infrastructure/context';
import { Preferences } from '../../infrastructure/user/preferences';
export class Appointment extends ItemBase {
  DisplayName: string;
  InitialAttendee: any;
  SkipConflictCheck: boolean = false;
  get TypeName() {
    return 'Appointment';
  }
  set TypeName(name) {
    if (name && this.TypeName !== name) this.TypeName = name;
  }
  get ItemType() {
    return itemTypes.itemAppointment;
  }
  get RequiresParentItem(): boolean {
    return true;
  }
  constructor(args) {
    super(args);
    let theAppointment = this;
    $.each(args, function (key, val) {
      if (theAppointment.hasOwnProperty(key)) theAppointment[key] = val;
    });

    if (!theAppointment.DisplayName) {
      let displayinfo;
      DisplayInfo(theAppointment.ItemType).done(function (response) {
        displayinfo = response;
        theAppointment.DisplayName = displayinfo.DisplayName;
        theAppointment.TypeName = displayinfo.TypeName;
      });
    }
  }

  OnCreate() {
    let theAppointment = this;
    let d = $.Deferred();

    let datecontrols;
    let attendeecontrol;

    $.each(theAppointment.Controls, (k, v: any) => {
      let attendees = $.grep(v, (val: any) => {
        return val.Properties.TDFField === 'Attendees';
      });
      let dates = $.grep(v, function (val: any) {
        return (
          val.Properties.TDFField === 'StartTime' ||
          val.Properties.TDFField === 'EndTime'
        );
      });

      if (dates.length) {
        datecontrols = dates;
      }
      if (attendees.length) {
        attendeecontrol = attendees[0];
      }
    });

    let start = moment(new Date())
      .add(1, 'hours')
      .minutes(0)
      .seconds(0);
    $.each(datecontrols, function (k, v: IControlData) {
      if (v.Properties.TDFField === 'StartTime') {
        if (!v.DataValue.ValueName) {
          v.DataValue.ValueName = Globalize.formatDate(start.toDate(), {
            datetime: 'medium'
          });
          v.SaveValue = v.DataValue.ValueName;
          theAppointment.SetDirty(v);
        }
      } else if (v.Properties.TDFField === 'EndTime') {
        if (!v.DataValue.ValueName) {
          v.DataValue.ValueName = Globalize.formatDate(
            start.add(1, 'hours').toDate(),
            { datetime: 'medium' }
          );
          v.SaveValue = v.DataValue.ValueName;
          theAppointment.SetDirty(v);
        }
      }
    });

    if (
      theAppointment.iMain.ItemType === itemTypes.itemContact ||
      theAppointment.iMain.ItemType === itemTypes.itemLead
    ) {
      let addattendee: boolean =
        Preferences.GetPreference('Appointment_SendConfirmation', 'Dialogs') ===
        '1';

      if (
        Preferences.GetPreference(
          'Appointment_SendConfirmationDialog',
          'Dialogs'
        ) === '0'
      ) {
        //"0" means show the dialog
        DisplayInfo(theAppointment.iMain.ItemType).done(info => {
          let message: any = {};
          message.text = $('<span />').text(
            `Do you want to send a Confirmation to the parent ${
            info.DisplayName
            }`
          );
          message.buttons = $('<div />')
            .append(
              $('<span />')
                .css('margin', '0 5px')
                .append(
                  $('<div />').dxButton({
                    text: 'Send',
                    icon: 'check',
                    type: 'success',
                    onClick: function (e: any) {
                      let toast = $($(e.element[0]).parents())
                        .find(
                          '.dx-overlay.dx-widget.dx-visibility-change-handler.dx-toast'
                        )
                        .dxToast('instance');
                      toast.hide();
                      if (!theAppointment.InitialAttendee)
                        theAppointment.InitialAttendee = {};
                      theAppointment.InitialAttendee = [
                        {
                          name: theAppointment.iMain.ItemSubject,
                          contactid: theAppointment.iMain.ItemId
                        }
                      ];
                      d.resolve();
                      return;
                    }
                  })
                )
            )
            .append(
              $('<span />')
                .css('margin', '0 5px')
                .append(
                  $('<div />').dxButton({
                    text: 'Do not send ',
                    icon: 'remove',
                    type: 'danger',
                    onClick: function (e: any) {
                      let toast = $($(e.element[0]).parents())
                        .find(
                          '.dx-overlay.dx-widget.dx-visibility-change-handler.dx-toast'
                        )
                        .dxToast('instance');
                      toast.hide();
                      d.resolve();
                    }
                  })
                )
            );
          new Notification({
            type: 'warning',
            shading: true,
            displayTime: 2000000,
            message: message
          });
        });
      } else {
        if (addattendee) {
          if (!theAppointment.InitialAttendee)
            theAppointment.InitialAttendee = {};
          theAppointment.InitialAttendee = [
            {
              name: theAppointment.iMain.ItemSubject,
              contactid: theAppointment.iMain.ItemId
            }
          ];
        }
        return d.promise(<any>d.resolve());
      }
    } else {
      return d.promise(<any>d.resolve());
    }
    return d.promise();
  }
  SetDirty(control: IControlData | any) {
    let theAppointment = this;
    let conflictDialog: Dialog = null;

    let EndTime;
    let StartTime;
    let startcontrol;
    let endcontrol;
    if (control.Properties.TDFField === 'EndTime') {
      EndTime = control.SaveValue;
      $.each(theAppointment.Controls, (k, v: any) => {
        let start = $.grep(v, (val: any) => {
          return val.Properties.TDFField === 'StartTime';
        });
        if (start.length) {
          startcontrol = start[0];
          StartTime = start[0].SaveValue;
          if (!control.needsCheckConflict) {
            //needsCheckConflict is set in controls.ts in datetime function
            theAppointment.SkipConflictCheck = false;
          }
        }
      });
    } else {
      if (control.Properties.TDFField === 'StartTime') {
        StartTime = control.SaveValue;
        $.each(theAppointment.Controls, (k, v: any) => {
          let end = $.grep(v, (val: any) => {
            return val.Properties.TDFField === 'EndTime';
          });
          if (end.length) {
            endcontrol = end[0];
            EndTime = end[0].SaveValue;
            theAppointment.SkipConflictCheck = true;
          }
        });
      }
    }

    //ByVal apptStart As DateTime, ByVal apptEnd As DateTime, Optional ByVal apptid As String = ""
    if (StartTime && EndTime && !theAppointment.SkipConflictCheck) {
      if (moment(new Date(EndTime)).isAfter(moment(new Date(StartTime)))) {
        new TDFRequest({
          url: '/item/CheckApptTime/',
          data: {
            ApptStart: StartTime,
            ApptEnd: EndTime,
            ApptID: theAppointment.ItemID
          },
          type: 'POST'
        })
          .MakeRequest()
          .done((response: any) => {
            if (true) {
              if (response.ConflictsExist) {
                let conflicts = [];
                $.each(response.ConflictingAppointments, (i, v) => {
                  conflicts.push({
                    text: v.subject,
                    startDate: new Date(v['TDF_StartTime']),
                    endDate: new Date(v['TDF_EndTime']),
                    isRequestedAppointment: false
                  });
                });

                conflicts.push({
                  text:
                    theAppointment.InitialSubject +
                    ' *** Requested Appointment *** ' ||
                    'Current Requested Appointment',
                  startDate: new Date(StartTime),
                  endDate: new Date(EndTime),
                  isRequestedAppointment: true
                });

                let opts: DevExpress.ui.dxSchedulerOptions = {
                  dataSource: conflicts,
                  height: '100%',
                  currentDate: new Date(
                    response.ConflictingAppointments[0]['TDF_StartTime']
                  ),
                  currentView: 'day',
                  endDayHour:
                    new Date(
                      response.ConflictingAppointments[0]['TDF_EndTime']
                    ).getHours() + 3,
                  startDayHour:
                    new Date(
                      response.ConflictingAppointments[0]['TDF_StartTime']
                    ).getHours() - 3,
                  onAppointmentUpdating(e) {
                    if (e.newData && e.newData.isRequestedAppointment) {
                      if (!startcontrol) {
                        $.each(theAppointment.Controls, (k, v: any) => {
                          let start = $.grep(v, (val: any) => {
                            return val.Properties.TDFField === 'StartTime';
                          });
                          if (start.length) {
                            startcontrol = start[0];
                          }
                        });
                      }

                      startcontrol.Dx.option('value', e.newData.startDate);

                      if (conflictDialog) conflictDialog.close();
                    }
                  },
                  resources: [
                    {
                      fieldExpr: 'isRequestedAppointment',
                      allowMultiple: false,
                      dataSource: [
                        {
                          text: 'Requested',
                          id: true,
                          color: '#d9534f'
                        },
                        {
                          text: 'Existing',
                          id: false,
                          color: '#56ca85'
                        }
                      ],

                      label: 'Type'
                    }
                  ]
                };
                conflictDialog = new Dialog(
                  {
                    body: $("<div id='conflictscal' />"),
                    title: 'Conflicting Appointments',
                    id: 'conflicts',
                    closable: true,
                    size: 'size-normal',
                    type: 'type-danger'
                  },
                  null,
                  false,
                  false
                );
                conflictDialog.open().done(() => {
                  $('#conflictscal').dxScheduler(opts);
                });

                super.SetDirty(control);
              } else {
                super.SetDirty(control);
              }
            } else {
              new Notification({
                message: response.Message,
                type: 'error',
                displayTime: 5000
              });
            }
          });
      } else {
        if (control.Properties.TDFField === 'EndTime') {
          new Notification({
            message:
              'Oops the start of an Appointment must be before the end. The end time has been updated.',
            type: 'warning',
            displayTime: 5000
          });
          control.SaveValue = '';
          control.Dx.option(
            'value',
            Globalize.formatDate(
              moment(new Date(StartTime))
                .add(1, 'hours')
                .toDate(),
              { datetime: 'short' }
            )
          );
          return;
        } else {
          if (control.Properties.TDFField === 'StartTime') {
            new Notification({
              message:
                'Oops the start of an Appointment must be before the end. The end time has been updated.',
              type: 'warning',
              displayTime: 5000
            });
            endcontrol.SaveValue = '';
            endcontrol.Dx.option(
              'value',
              Globalize.formatDate(
                moment(new Date(StartTime))
                  .add(1, 'hours')
                  .toDate(),
                { datetime: 'short' }
              )
            );
            return;
          }
        }
      }
    } else {
      theAppointment.SkipConflictCheck = false;
      super.SetDirty(control);
    }

    return theAppointment;
  }
  //Save(verified = false) {
  //    let theAppointment = this;
  //    let d = $.Deferred();
  //    let dates = [];
  //     $.each(theAppointment.Controls, function (k, v: ItemControls) {

  //        dates = $.grep(v.ControlDataList, function (val) {
  //            return val.Properties.TDFField === "StartTime" || val.Properties.TDFField === "EndTime";
  //        });
  //    });

  //    new Request({ url: "/item/CheckApptTime/", data: {}})
  //    return d.promise(theAppointment)
  //}
}
