//import { ItemBase } from "../items/itembase";
import { HelpText } from "./helpers/helptext";
import { DialogResult, IDialogOptions } from "../../interfaces/interfaces";
import { GetDevice } from "../../util/allutils";
import { MinimizeWindowManager } from "./minimizewindowmanager";
import "devextreme/ui/popup";
export class Dialog {
  /**
   * The instance of the devextreme object
   * **/
  Instance: DevExpress.ui.dxPopup;
  type: string;
  Item: any; //ItemBase;
  private id: string;
  dfd: JQueryPromise<any>;
  buttons: DevExpress.ui.dxPopupToolbarItem[];
  CloseDeferred: JQueryDeferred<any>;
  Hiding: boolean = false;

  private helpTextButtonClass = "dialogHelpTextBtn";
  private modal: boolean;
  private HelpText: HelpText;

  get DialogID() {
    let theDialog = this;
    return theDialog.Item ? `tdfitem${theDialog.id}` : `${theDialog.id}`;
  }

  constructor(
    args: IDialogOptions,
    item?: any, // ItemBase,
    resizable = false,
    scrollview = true
  ) {
    let theDialog = this;
    let content = args.body;

    if (!args.modal) args.modal = "yes";

    theDialog.modal = args.modal == "yes";

    if (scrollview) {
      let sv = $("<div/>")
        .dxScrollView({ height: "99%" })
        .dxScrollView("instance");
      sv.content().append(args.body);
      content = sv.element();
    }

    theDialog.type = args.type;
    theDialog.id = args.id;

    if (!theDialog.id) {
      theDialog.id = Date.now().toString();
    }

    if (item) theDialog.Item = item;

    $(`#${theDialog.DialogID}`).remove();

    $(`<div id='${theDialog.DialogID}' />`).appendTo("body");

    if (args.showStandardButtons != "no") {
      args.showStandardButtons = "yes";
    }

    theDialog.HelpText = new HelpText();

    theDialog.titleButtons(args);

    let dlgopts: DevExpress.ui.dxPopupOptions = {
      showTitle: !(args.hideTitle === true),

      shading: args.modal == "yes",
      contentTemplate: function (contentElement) {
        contentElement.append(content);
      },
      resizeEnabled: true,
      title: args.title.toString(),
      onShown(e) {
        theDialog.Hiding = false;
        theDialog.OnShown(e);

        if (args.showHelpButton) {
          theDialog.HelpText.ShowHelpTextTutorial();
        }
        //theDialog.ShowHelpTextTutorial(args.showHelpButton);
      },
      onHidden(e) {
        if (args.onHidden) {
          args.onHidden(e);
        }
        if (!theDialog.Hiding) {
          MinimizeWindowManager.OnDialogClosed(theDialog.DialogID);
          e.component.dispose();
          $(`#${theDialog.DialogID}`).remove();
        }
      },
      onHiding:
        args.onHiding ||
        function (e) {
          if (
            !theDialog.CloseDeferred &&
            theDialog.Item &&
            theDialog.Item.Dirty &&
            !theDialog.Hiding
          ) {
            theDialog.close(false);
            e.cancel = true;
          }
        },
      onContentReady(e) {
        e.component.element().on("dragover", function (ev) {
          ev.stopPropagation();
          ev.preventDefault();
        });

        e.component.element()[0].addEventListener("drop", ev => {
          ev.preventDefault();
          var data = ev.dataTransfer.files;
        });
      },
      toolbarItems: args.buttons,
      animation: {
        show: {
          type: "pop", // "slide",

          //duration: 500,
          //from: { top: -1000, opacity: 1 }
        }, // show: { duration: 500, type: "slide", from: { opacity: 1, top: -200 }, to: { top: 0 } },
        hide: {
          type: "pop",
          duration: 300,
          to: { opacity: 0, scale: 0.55 },
          from: { opacity: 1, scale: 1 }
        } //type: "slide", duration: 1500, from: { left: 0 }, to: { right: -200 } }
      },
      showCloseButton:
        typeof args.closable === "undefined" ? true : args.closable,
      height: theDialog.calcHeight(args.size || "size-wide"),
      width: theDialog.calcHeight(args.size || "size-wide"),
      fullScreen: GetDevice().isDevice || args.fullScreen
    };
    theDialog.Instance = $(`#${theDialog.DialogID}`)
      .dxPopup(dlgopts)
      .dxPopup("instance");
  }

  private titleButtons(args: IDialogOptions) {
    let theDialog = this;
    let buttons: any = [];

    if (args.showHelpButton === true) {
      buttons.push(theDialog.HelpButton());
    }

    if (args.showStandardButtons != "no") {
      buttons.push(theDialog.MinimizeButton());
      buttons.push(theDialog.MaximizeButton());
      // buttons.push(theDialog.RestoreDownButton());
    }

    if (args.buttons) {
      args.buttons = args.buttons.concat(buttons);
    } else {
      args.buttons = buttons;
    }
  }

  private MinimizeButton(): DevExpress.ui.dxPopupToolbarItem {
    let theDialog = this;
    return {
      widget: "dxButton",
      toolbar: "top",
      location: "after",
      options: {
        type: "info",
        icon: "fa fa-minus",
        onClick(e) {
          theDialog.Hiding = true;
          MinimizeWindowManager.OnDialogMinimized(theDialog);
          theDialog.Instance.hide();
        }
      }
    };
  }
  private MaximizeButton(): DevExpress.ui.dxPopupToolbarItem {
    let theDialog = this;
    return {
      widget: "dxButton",
      toolbar: "top",
      location: "after",
      options: {
        type: "info",
        icon: "fa fa-window-maximize",
        onClick(e) {
          theDialog.Instance.option(
            "fullScreen",
            !theDialog.Instance.option("fullScreen")
          );
          theDialog.Instance.option("fullScreen")
            ? e.component.option("icon", "fa fa-window-restore")
            : e.component.option("icon", "fa fa-window-maximize");
        }
      }
    };
  }
  private RestoreDownButton(): DevExpress.ui.dxPopupToolbarItem {
    let theDialog = this;
    return {
      widget: "dxButton",
      toolbar: "top",
      location: "after",
      options: {
        type: "info",
        icon: "fa fa-window-restore",
        onClick(e) {
          theDialog.Instance.option("fullScreen", false);
        }
      }
    };
  }

  private HelpButton(): DevExpress.ui.dxPopupToolbarItem {
    let theDialog = this;

    return {
      widget: "dxButton",
      toolbar: "top",
      location: "after",
      //options: theDialog.HelpText.HelpButton.option(),
      template: () => {
        return theDialog.HelpText.HelpButton.element();
      }
      //TODO:  Add right-click option to this button to set preference back to showing the help text automatically.
    };
  }

  calcHeight(size) {
    let theDialog = this;

    switch (size) {
      case "size-wide":
        return theDialog.modal ? "99%" : `${window.innerWidth * 0.99}px`;
      case "size-large":
        return theDialog.modal ? "80%" : `${window.innerWidth * 0.8}px`;
      case "size-med":
        return theDialog.modal ? "35%" : `${window.innerWidth * 0.35}px`;
      case "size-normal":
        return theDialog.modal ? "50%" : `${window.innerWidth * 0.5}px`;
      case "size-small":
        return theDialog.modal ? "25%" : `${window.innerWidth * 0.25}px`;
    }
  }

  public open() {
    let theDialog = this;

    theDialog.dfd = theDialog.Instance.show();
    return theDialog.dfd;
  }

  private OnShown(e) {
    let theDialog = this;
    if (theDialog.Instance) {
      let closebtn = $(theDialog.Instance.content()[0])
        .parents()
        .find(
          ".dx-closebutton.dx-button.dx-button-normal.dx-widget.dx-button-has-icon"
        );
      closebtn.attr({ title: "[Esc]" });
      theDialog.SubscribeKeyEvents();
      $(theDialog.Instance.content().parent()[0]).css({
        border: "none",
        "border-radius": "0"
      });
      theDialog.setType(theDialog.type || "");
    }
  }

  private SubscribeKeyEvents() {
    let theDialog = this;
    if (theDialog.Instance) {
      $(theDialog.Instance.content().parent()).on("keyup", ev => {
        switch (ev.which) {
          case 27:
            theDialog.close();
            return;
          case 83:
            if (!theDialog.Item) return;

            if (ev.altKey && !ev.shiftKey) {
              theDialog.Item.Save();
              return;
            }
            if (ev.altKey && ev.shiftKey) {
              theDialog.Item.SaveClose();
            }

            return;
        }
      });
    }
  }

  public setTitle(title) {
    let theDialog = this;
    theDialog.Instance.option("title", title);
  }

  public close(safe = false, result?: DialogResult) {
    let theDialog = this;
    if (!theDialog.CloseDeferred) {
      theDialog.CloseDeferred = $.Deferred();
    }
    if (theDialog.Item) {
      if (!safe) {
        theDialog.Item.Close();
      } else {
        $(theDialog.Instance.content().parent()).off("keyup");
        theDialog.Instance.hide().done(function () {
          theDialog.CloseDeferred.resolve(result);
          //  $("#tdfitem" + theDialog.id).remove();
        });
      }
    } else {
      $(theDialog.Instance.content().parent()).off("keyup");
      theDialog.Instance.hide().done(function () {
        theDialog.CloseDeferred.resolve(result);
        //  $("#tdfitem" + theDialog.id).remove();
      });
    }
    return theDialog.CloseDeferred.promise();
  }

  public getModalFooter() {
    let theDialog = this;
    if (typeof theDialog.Instance["bottomToolbar"] === "function") {
      return theDialog.Instance["bottomToolbar"]();
    } else {
      return (theDialog.Instance as any)._$bottom as JQuery;
    }
  }

  public getModalHeader() {
    let theDialog = this;
    if (typeof theDialog.Instance["topToolbar"] === "function") {
      return theDialog.Instance["topToolbar"]();
    } else {
      return (theDialog.Instance as any)._$title as JQuery;
    }
  }

  public getModalBody() {
    let theDialog = this;
    return $(theDialog.Instance.content());
  }

  public setType(type: string) {
    let theDialog = this;

    theDialog.getModalHeader().removeClass(theDialog.type);
    switch (type) {
      case "type-primary":
        theDialog.type = "dialog-primary";
        break;
      case "type-info":
        theDialog.type = "dialog-info";
        break;

      case "type-danger":
        theDialog.type = "dialog-danger";
        break;

      case "type-warning":
        theDialog.type = "dialog-warning";
    }
    theDialog.getModalHeader().addClass(theDialog.type);
  }

  public setData(key: string, value: string) {
    let theDialog = this;
    theDialog[key] = value;
  }

  public getData(key: string) {
    let theDialog = this;
    if (theDialog[key]) {
      return theDialog[key];
    } else {
      return null;
    }
  }

  public getButton(buttonid) {
    let theDialog = this;
    return $("#" + buttonid);
  }

  public enableButtons(enable: boolean) { }

  private AddFileDropEvents(element) {
    let dialog = this;
  }
}
