import { itemTypes } from "../../enums/enums";
import { EnumQuoteToOrderActions, EnumSellPriceMethods, CopyQuoteOptionsEnum } from "../../enums/quotes/enums";
import { IQuoteDataProduct } from "../../interfaces/quotes/interfaces";
import { TDFRequest } from 'services/request';

const routes = {
  base: "quote",
  SaveDetailsPath: "SaveDetails/",
  QuoteOptionsPath: "GetQuoteOptions/",
  DetailsPath: "QuoteDetails/",
  GlobalQuoteLinesPath: "GetQuoteLineGlobalFields/",
  UserQuoteLinesPath: "GetQuoteLineUserFields/",
  GetQuoteLinesPath: "GetQuoteLineItems/",
  GetQuoteLinesSegmentsPath: "GetQuoteSegementLineItems/",
  LineItemEditSettingsPath: "GetQuoteLineEditOptions/",
  LineItemEditPath: "EditQuoteLineItem/",
  RemoveLineItemsPath: "RemoveProduct/",
  LineItemSearchOptionsPath: "GetProductSearchOptions/",
  SearchLineItemsPath: "SearchProducts/",
  AddCustomLinePath: "AddCustomProductToQuote/",
  AddLinesPath: "AddProductsToQuote/",
  GetExtendedTotalPath: "ExtendedTotal/",
  UpdateFreightPath: "UpdateFreight/",
  UpdateQuotePropertyPath: "UpdateQuoteProperty/",
  DocAPIPath: "/Document/CreateDocumentForQuote/",
  DocumentTemplateSetupPath: "TemplateSetup/",
  LinkDocPath: "AttachDocumentToQuote/",
  PreviewTemplatePath: "TemplatePreview/",
  GenerateAndLinkDocumentPath: "CreateAndLinkDocument/",
  RecentProductSearches: "GetRecentProductSearches",
  RevisionsPath: "Revisions"
}

export function AddLines(itemID, payload) {
  return new TDFRequest({
    url: `${routes.base}/${routes.AddLinesPath}/${itemID}`
    ,
    data: payload,/*  {
      quoteid: helper._Quote.ItemID,
      products: helper.ProductsToAdd,
      options: {
        ExpectedProductSegmentID: helper._Quote.SegmentsDefined
          ? helper.SearchSegment.SegmentID
          : ""
      } 
    },*/
    type: "POST"
  }).MakeRequest();
}
export function SearchLines(params: any) {
  return new TDFRequest({
    url: `${routes.base}/${routes.SearchLineItemsPath}`,
    data: params,
    type: "POST"
  }).MakeRequest();
}
export function RecentProductSearches() {
  return new TDFRequest({
    url: `${routes.base}/${routes.RecentProductSearches}`
  })
    .MakeRequest();
}
export function ProductAccessoryCount(thisCopy: any) {
  return new TDFRequest({
    url: `${routes.base}/AccessoryCount/`,
    data: {
      companyID: thisCopy["CompanyID"],
      itemcode: thisCopy["ItemCode"],
      warehouseid: thisCopy["WarehouseId"]
    }
  }).MakeRequest();
}
export function ProductAccessories(selectedproduct) {
  return new TDFRequest({
    url: `${routes.base}/Accessories/`,
    data: {
      companyID: selectedproduct.CompanyID,
      itemcode: selectedproduct.ItemCode,
      warehouseid: selectedproduct.WarehouseId // rowinfo.data["WarehouseId"]
    }
  })
    .MakeRequest();
}

export function GenerateAndLink(payload) {
  return new TDFRequest({
      url: `${routes.base}/${routes.GenerateAndLinkDocumentPath}`,
      data: payload,
      type: "POST"
  }).MakeRequest();
}
export function QuoteDocName(itemid: string, templateid: any) {
  return new TDFRequest({
    url: `${routes.base}/QuoteDocumentName/${itemid}/${templateid}`
  }).MakeRequest();
}
export function DocSetup(ItemID: string, expandKits = false)  {
  return new TDFRequest({
    url: `/Quote/${routes.DocumentTemplateSetupPath}/${ItemID}`,
    data: { expandKits: expandKits },
    type: "GET"
  }).MakeRequest();
}
export function UpdateFreight(ItemID: string, inbound: any, outbound: any)  {
  return new TDFRequest({
    url: `${routes.base}/${routes.UpdateFreightPath}/${ItemID}/${inbound}/${outbound}`,
    type: "POST"
  }).MakeRequest();
}
export function UpdateQuoteProperty(ItemID: string, thefield: any, thevalue: any, isRateField: any)  {
  return new TDFRequest({
    url: `${routes.base}/${routes.UpdateQuotePropertyPath}/${ItemID}/${thefield}/${thevalue}/${isRateField}`,
    // data: {
    //   quoteid: ItemID,
    //   field: thefield,
    //   value: thevalue,
    //   israte: isRateField
    // },
    type: "POST"
  }).MakeRequest();
}
export function UpdateLinesToHitTarget(ItemID: string, e)  {
  return new TDFRequest({
    url: `${routes.base}/UpdateLineItemsToHitTarget/${ItemID}/${e.value}`,
    type: "POST"
    // data: {
    //   quoteid: ItemID,
    //   amount: e.value
    // }
  })
    .MakeRequest();
}
export function GetExtendedTotal(ItemID: string) {
  return new TDFRequest({
    url: `${routes.base}/${routes.GetExtendedTotalPath}/${ItemID}`,
    type: "GET"
  }).MakeRequest();
}
export function PlanButtonDisplay(ItemID: string) {
  new TDFRequest({
    url: `${routes.base}/GetPlanButtonDisplayText/${ItemID}`
  }).MakeRequest();
}
/*************************************************************************************************** */
/*************************************************************************************************** */
/*************************************************************************************************** */
export function ApplyFinancePlan(ItemID: string, payload) {
  return new TDFRequest({
    url: `${routes.base}/ApplyFinancePlanForQuote/${ItemID}`,
    data: payload,
    type: "POST"
  }).MakeRequest();
}
/****************************************************************************************************/
/***************************************************************************************************
 *//*************************************************************************************************** */
export function ClearFinancePlan(ItemID: string) {
  return new TDFRequest({
    url: `${routes.base}/ClearFinancePlanForQuote/${ItemID}`,
    type: "POST"
  }).MakeRequest();
}
export function FinancePlanForQuote(ItemID: string) {
  return new TDFRequest({
    url: `${routes.base}/GetFinancePlanForQuote/${ItemID}`
  }).MakeRequest();
}
export function FinancePlans(ItemID: string) {
  return new TDFRequest({
    url: `${routes.base}/GetFinancePlans/${ItemID}`
  }).MakeRequest();
}
export function TrySetPointsForQuote(ItemID: string, currentPoints: number, mode: any) {
  return new TDFRequest({
    url: `${routes.base}/TrySetPointsForQuote/${ItemID}/${currentPoints}`,
    data: mode,
    type: "POST"
  })
    .MakeRequest();
}
export function QuoteSummary(ItemID: string) {
  return new TDFRequest({
    url: `${routes.base}/GetSummaryForQuote/${ItemID}`
  })
    .MakeRequest();
}
export function PontBookForQuote(ItemID: string) {
  return new TDFRequest({
    url: `${routes.base}/GetPointbooksForQuote/${ItemID}`
  })
    .MakeRequest();
}
export function PointsSetup() {
  return new TDFRequest({ url: `${routes.base}/GetPointSetup/` })
    .MakeRequest();
}
export function CalcStrategicFacotrs(val: number, isMargin: boolean, responseFromSPA: any, theProduct: IQuoteDataProduct) {
  return new TDFRequest({
    url: `/spa/client/CalculateFactors/?userValue=${val}&ismargin=${isMargin}&greenprice=${responseFromSPA.PriceBand.Green}&systemcost=${theProduct.Cost}`
  })
    .MakeRequest();
}
export function UpdateStrategicPrice(tdfdata: any, spaHelper, isManual: any, responseFromSPA: any) {
  return new TDFRequest({
    url: `/spa/client/updateprice`,
    type: "POST",
    data: {
      QuoteProductID: tdfdata.quoteProductID,
      Color: spaHelper.SelectedColor,
      isManual: isManual,
      Price: spaHelper.SelectedPrice,
      GreenPrice: responseFromSPA.PriceBand.Green,
      RedPrice: responseFromSPA.PriceBand.Red,
      YellowPrice: responseFromSPA.PriceBand.Yellow
    }
  })
    .MakeRequest();
}
export function CreatePriceBand(requestParams: any) {
  new TDFRequest({
    url: '/SPA/Client/CreatePriceBand',
    data: requestParams,
    type: "POST"
  }).MakeRequest();
}
export function StrategicPricingProductMeta(requestParams: any) {
  new TDFRequest({
    url: `/SPA/Client/ProductMeta/?${$.param({
      id: requestParams.QuoteProductID
    })}`,
    type: "GET"
  }).MakeRequest();
}
export function GetStrategicPricingAccessLevel() {
  return new TDFRequest({
    url: `${TDFRequest.ApiPath}/SPA/Client/UserAccessLevel`,
    type: "GET"
  })
    .MakeRequest();
}
export function AddCustomline(ItemID: string, position: any) {
  return new TDFRequest({
    url: `${routes.base}/${routes.AddCustomLinePath}/${ItemID}/${position}`,
    type: "POST"
  }).MakeRequest();
}
export function RemoveMultipleLines(selected: any[]) {
  return new TDFRequest({
    url: `${routes.base}/RemoveMultipleProducts`,
    data:  selected ,
      type: "DELETE"

  })
    .MakeRequest();
}
export function SendToKit(payload) {
  return new TDFRequest({
    url: `${routes.base}/SendToKit/`,
    data: payload,
    type: "POST"
  })
    .MakeRequest();
}
export function GetEditLineRequest(ItemID: string, prodid: string, values: any[]) {
  return new TDFRequest({
    url: `${routes.base}/${routes.LineItemEditPath}/${ItemID}/${prodid}`,
    data: values,
    type: "POST"
  });
}
export function GetRemoveLineRequest(id): TDFRequest {
    return new TDFRequest({
        url: `${routes.base}/${routes.RemoveLineItemsPath}${id}`,
        type: "DELETE"
    });
}
export function GetUpdateSellPriceRequest(ItemID: string, data: {
  amount: any;
  products: any[];
  method: EnumSellPriceMethods;
}) {
  return new TDFRequest({
    url: `${routes.base}/UpdateQuoteLineSellPrice/${ItemID}`,
    data: data,
    type: "POST"
  });
}
export function GetUpdateSellCostRequest(ItemID: string, data: {
  amount: number;
  products: any[];
  method: any;
  quoteid: string;
}) {
  return new TDFRequest({
    url: `${routes.base}/UpdateQuoteLineSellCost/${ItemID}`,
    data: data,
    type: "POST"
  });
}
export function QuoteLineEditSettings() {
  return new TDFRequest({
    url: `${routes.base}/${routes.LineItemEditSettingsPath}`,
    type: "GET"
  }).MakeRequest();
}
export function LinesForQuote(ItemID: string) {
  return new TDFRequest({
    url: `${routes.base}/${routes.GetQuoteLinesPath}/${ItemID}`,
    type: "GET"
  }).MakeRequest();
}
export function SegmentLinesForQuote(ItemID: string) {
  return new TDFRequest({
    url: `${routes.base}/${routes.GetQuoteLinesSegmentsPath}/${ItemID}`,
    type: "GET"
  }).MakeRequest();
}
export function UserQuoteFields() {
  return new TDFRequest({
    url: `${routes.base}/${routes.UserQuoteLinesPath}`,
    type: "GET"
  }).MakeRequest();
}
export function GlobalLineFields() {
  return new TDFRequest({
    url: `${routes.base}/${routes.GlobalQuoteLinesPath}`,
    type: "GET"
  }).MakeRequest();
}
export function ProductSegments() {
  return new TDFRequest({
    url: `${routes.base}/Segments`
  }).MakeRequest();
}
export function QuoteOptions(ItemID: string) {
  return new TDFRequest({
    url: `${routes.base}/${routes.QuoteOptionsPath}/${ItemID}`,
    type: "GET"
  })
    .MakeRequest();
}
export function SaveQuoteDetails(ItemID: string, payload) {
  return new TDFRequest({
    url: `${routes.base}/${routes.SaveDetailsPath}/${ItemID}`,
    data: payload,
    type: "POST",
  }).MakeRequest();
}
export function CloseQuote(ItemID: string) {
  return new TDFRequest({
    url: `${routes.base}/CloseQuote/${ItemID}`,
    type: 'POST'
  }).MakeRequest();
}
export function ChangeContract(ItemID: string, contractid: any) {
  return new TDFRequest({
    url: `${routes.base}/ChangeContract/${ItemID}/${contractid}`,
    type: "POST"
  })
    .MakeRequest();
}
export function QuoteDetails(ItemID: string) {
  return new TDFRequest({
    url: `${routes.base}/${routes.DetailsPath}/${ItemID}`,
    type: "GET"
  })
    .MakeRequest();
}
export function QuoteAuditTrail(ItemID: string) {
  return new TDFRequest({
    url: `${routes.base}/GetAuditTrail/${ItemID}`,
    type: "GET"
  })
    .MakeRequest();
}

export interface QuoteCopyOptions {
    copyOption: CopyQuoteOptionsEnum,
    newQuoteDescription: string,
    newQuoteParentID?: string,
    newQuoteParentType?: itemTypes,
    newQuoteNewParentAccountPackage?: string,
    newParentAccountName?: string
}

export function CopyQuote(ItemID: string, args: QuoteCopyOptions ) {
  return new TDFRequest({
    url: `${routes.base}/CopyQuote/${ItemID}`,
    data: args,
    type: "POST"
  })
    .MakeRequest();
}
export function ProcessERP(ItemID: string, Action: EnumQuoteToOrderActions) {
  return new TDFRequest({
    url: `${routes.base}/processERP/${ItemID}/${Action}`,
    type: "POST"
  })
    .MakeRequest();
}

export function SearchSetup(itemType) {
  return new TDFRequest({
    url: "core/search/GetSearchSetup/?" + $.param({ type: itemType }),
    type: "GET"
  }).MakeRequest();
}
export function EmailBodyForQuote(ItemID: string, contactid: string, draftid: string) {
    let options = { QuoteID : ItemID, contactid, draftid };
    return new TDFRequest({
    url: `${routes.base}/GetEmailBodyForQuote/?` + $.param(options),
    type: "GET"
  })
    .MakeRequest();
}
//TODO: Move to itemdataservice
export function ParentContactsRequest(ItemID: string, ItemType: itemTypes) {
  return new TDFRequest({
    url: `/item/getparentcontacts/${ItemID}/${ItemType}`,
  });
}
//TODO: Move to itemdataservice
export function EmailDraftsRequest(ItemType: itemTypes) {
  return new TDFRequest({
    url: `/item/EmailDrafts/${ItemType}`,
  });
}
export function NewQuoteWithParent(parentid: any, description: string) {
  return new TDFRequest({
    url: `/Quote/NewQuoteWithParent/${parentid}/` +
      `${
      description.length
        ? `?description=${encodeURIComponent(description)}`
        : ''
      }`
  }).MakeRequest();
}
export function QuickStatus(ItemID: string) {
  return new TDFRequest({
    url: `${routes.base}/QuickStatus/${ItemID}`
  })
    .MakeRequest();
}

export function LineItemSearchOptions() {
  return new TDFRequest({
    url: `${routes.base}/${routes.LineItemSearchOptionsPath}`
  })
    .MakeRequest();
}

export function CreateRevision(QuoteID: string, Title: string) {
    // todo: title
    return new TDFRequest({
    url: `${routes.base}/${routes.RevisionsPath}/${QuoteID}`,
        type: "POST", 
        data: JSON.stringify(Title)
  }).MakeRequest();
}

export function DeleteRevision(QuoteID: string) {
  return new TDFRequest({
    url: `${routes.base}/${routes.RevisionsPath}/${QuoteID}`,
    type: "DELETE"
  }).MakeRequest();
}

export function GetRevisions(QuoteID: string) {
  return new TDFRequest({
    url: `${routes.base}/${routes.RevisionsPath}/${QuoteID}`,
    type: "GET"
  }).MakeRequest();
}

export interface ICreateQuoteRevisionResponse {
    Revisions: { RevisionID: string }[];
}