export enum eventNameSpace {
    notify = 'notify',
    request = 'request',
    modify = 'modify',
    complete = 'complete',
    dashboard = 'dashboard'
}

export namespace EventTypes {
    export enum GeneralEventTypes {
        themechanged = 'themechanged'
    }

    export enum CenterEventTypes {
        gridviewselect = 'gridviewselect',
        viewcreated = 'viewcreated',
        viewsaved = 'viewsaved',
        gridrefresh = 'gridrefresh',
        gridrowselect = 'gridrowselect',
        size = 'size',
        gridviewsloaded = 'gridviewsloaded',
        gridselecteditems = 'gridselecteditems',
        itemtype = 'itemtype',
        gridscope = 'gridscope',
        found = 'found',
        notfound = 'notfound',
        ready = 'ready',
        openitem = 'openitem',
        opendatacentermenu = 'opendatacentermenu',
        itemactions = 'itemactions',
        itemviews = 'itemviews',
        scopeforitemtype = 'scopeforitemtype',
        relativedatefilter = 'relativedatefilter',
        updateButtons = 'updateButtons',
        optionsforgridtype = 'optionsforgridtype',
        refreshviews = 'refreshviews',
        loaddefaulttoolbar = 'loaddefaulttoolbar',
        gridclosed = 'gridclosed'
    }

    export enum RFCenterEventTypes {
        clear = 'clear',
        manageFavorites = 'manageFavorites'
    }

    export enum DashboardEventTypes {
        addTile = 'addTile',
        loadTileSelector = 'loadTileSelector',
        tileSelectorClosed = 'tileSelectorClosed',
        removeTile = 'removeTile',
        updateTileTitle = 'updateTileTitle',
        dashboardModeChanged = 'dashboardModeChanged',
        InitializeSortableTileLists = 'InitializeSortableTileLists',
        RefreshSortableTileLists = 'RefreshSortableTileLists',
        TileLayoutTileRemoved = 'TileLayoutTileRemoved',
        TileLayoutTileReceived = 'TileLayoutTileReceived',
        TileDetached = 'TileDetached',
        RefreshTile = 'RefreshTile',
        TileScope = 'TileScope',
        DashboardScope = 'DashboardScope',
        SaveDashboard = 'SaveDashboard',
        DashboardSaved = 'DashboardSaved',
        dashboardScopeChanged = 'dashboardScopeChanged',
        FormReady = 'FormReady',
        AddSection = 'AddSection',
        RemoveSection = 'RemoveSection',
        ChangeSectionType = 'ChangeSectionType',
        DisplayDashboardScope = 'DisplayDashboardScope',
        ChangeDashboardScope = 'ChangeDashboardScope',
        MarkDashboardDirty = 'MarkDashboardDirty',
    }

    export enum BIEventTypes {
        DisplayBISummarySelection = 'DisplayBISummarySelection',
        currentgoalselection = 'currentgoalselection',
        currentDrillHelper = 'currentDrillHelper',
        RollUp = 'RollUp',
        RollDown = 'RollDown',
        SummarySelectionNamesUpdated = 'SummarySelectionNamesUpdated',
        showHideRollUpButtons = 'showHideRollUpButtons',
        currentsummaryselection = 'currentsummaryselection',
        currentAccountFieldPivot = 'currentAccountFieldPivot'
    }

    export enum GridEventTypes {
        ExpandSelection = 'ExpandSelection'
    }

}
