import { IActionItem, IItemInfo, ItemListingNameModel } from "../interfaces/interfaces";
import { menuActions, ActionDisplayOptions, itemTypes, itemAccountTypes, EnumParent } from "../enums/enums";
import { Dialog } from "../components/dialogs/dialog";
import { TDFRequest } from "../services/request";
import { ContactEnum } from "../enums/tdffields/enums";
import { EmailGenerator } from "../components/items/emailgenerator";
import * as Globalize from "globalize/dist/globalize";
import { ItemMain } from "../components/items/helpers/itemmain";
import { SearchRecFav } from "../components/controls/searchrecfav";
import { PackageSelection } from "../components/controls/packageselection";
import { TTQuickNotes } from "../components/timetracker/ttquicknotes";
import { PropagateToContactsDialog } from "../components/controls/propagatetocontactsdialog";
import { itemDescriptor } from "../interfaces/admin/interfaces";
import { ItemCombiner } from "../admin/combineitems";
import { Notification } from "../components/dialogs/notification";
import { DisplayInfo, LoadCompany } from "../infrastructure/context";
import { NewExpenseArgs } from "../interfaces/items/interfaces";
import { GetDevice, DomSafeID, RenderCompleteConvertDialog } from "./allutils";
import { GetLinkPrompt } from "../legacy-centers-grids/linkitems";
import { AcctBIDataCenter } from "../datacenters/bidatacenters/bi_data_center_acct";
import { ContactBIDataCenter } from "../datacenters/bidatacenters/bi_data_center_contact";
import { VendorBIDataCenter } from "../datacenters/bidatacenters/bi_data_center_vendor";
import { createitem } from "components/items/factory/itemfactory";
import * as api from "./api";
import { alert } from "devextreme/ui/dialog";
import { ServerCommands } from "./servercommands";
import { TDFMassUpdate } from "./massupdate";
import { NewQuoteWithParent } from "services/quote/quotedataservice";


export class MenuAction implements IActionItem {
  items: IItemInfo[];
  singleItem: IItemInfo;
  ActionURL: string = "";
  Action: menuActions | string = menuActions.Unknown;
  AvailableInMobile: boolean = true;
  DisplayOptions: ActionDisplayOptions = ActionDisplayOptions.None;
  Enabled: boolean = false;
  ErrorMessageWhenUnavailable: string = "";
  GroupName: string = "";
  MenuText: string = "";
  MenuText2: string = "";
  RelevantType: itemTypes = itemTypes.itemUnknown;
  SHouldAppearInBreadcrumbs: boolean = false;
  ShouldAppearInFormMenu: boolean = false;
  ShouldAppearInGrid: boolean = false;
  SortOrder: number = 0;
  SupportsMultipleItems: boolean = false;
  Visibility: number = 0;
  icon?: string;
  template: Function;
  ContainerItemID: string;
  Dialog: Dialog;
  products: { LinkID: string }[];
  constructor(
    action: IActionItem,
    items: IItemInfo[] | { LinkID: string }[],
    ContainerItemID?: string
  ) {
    let theAction = this;
    $.each(action, function (key: any, val: any) {
      theAction[key] = val;
    });

    if (theAction.IsProduct(items[0])) {
      theAction.products = <{ LinkID: string }[]>items;
    } else {
      theAction.items = <IItemInfo[]>items;
    }

    if (ContainerItemID) theAction.ContainerItemID = ContainerItemID;
    // If the action only works on one item, set the singleItem.
    if (
      !theAction.SupportsMultipleItems &&
      theAction.items &&
      theAction.items.length > 0
    ) {
      theAction.singleItem = theAction.items[0];
    }

    let act;
    if (typeof theAction.Action === "number") {

      try {
        act = menuActions[theAction.Action].toString().replace("menu", "");
      } catch (e) {
        console.log("The action is unsupported. Make sure that typescript enums match server enums.");
        console.log(e);
      }

    } else {
      act = theAction.Action;
    }

    if (theAction[act]) {
      theAction[act]();
    } else {
      new Notification({
        message: "That action is not available.",
        displayTime: 3000,
        closeOnClick: true
      });
    }
  }

  IsProduct(item: IItemInfo | { LinkID: string }): item is { LinkID: string } {
    return (<{ LinkID: string }>item).LinkID !== undefined;
  }

  //Click(args) {
  //    let theAction = this;
  //    let act = menuActions[theAction.Action].toString();
  //    theAction[theAction.Action]();
  //}

  Open() {
    let theAction = this;
    let itemType: itemTypes;
    if (theAction.singleItem && theAction.singleItem.ItemId) {
      itemType = theAction.singleItem.ItemType;
    } else {
      itemType = itemTypes.itemProduct;
    }

    if (itemType === itemTypes.itemExpense) {
      DisplayInfo(itemType).done(function (displayinfo) {
        window.location.href = "/" + displayinfo.DisplayName + "/?itemid=" + theAction.singleItem.ItemId;
      });
    } else {
      if (itemType !== itemTypes.itemProduct) {
        createitem(itemType, { ItemID: theAction.singleItem.ItemId });
      } else {
        createitem(itemType, { ItemID: "", Product: theAction.products });
      }
    }
  }

  MarkAsDeleted() {

    // Handles Multiple
    let theAction = this;
    let theItemList = theAction.GetItemList();

    let message: any = {};
    message.text = $("<span />").text(
      "Are you sure you want to delete this item?"
    );
    message.buttons = $("<div />")
      .append(
        $("<span />")
          .css("margin", "0 5px")
          .append(
            $("<div />").dxButton({
              text: "Yes",
              icon: "check",
              type: "success",
              onClick: function (e: any) {
                let toast = $($(e.element[0]).parents())
                  .find(
                    ".dx-overlay.dx-widget.dx-visibility-change-handler.dx-toast"
                  )
                  .dxToast("instance");
                toast.hide();

                /*Here*/
                new TDFRequest({
                  url: "/item/deleteitem/",
                  data: theItemList,
                  type: "POST"
                })
                  .MakeRequest()
                  .done(function (response: any) {
                    let successMsg = response;

                    new Notification({
                      message: successMsg,
                      type: "success",
                      displayTime: 4500
                    });
                  });
                return;
              }
            })
          )
      )
      .append(
        $("<span />")
          .css("margin", "0 5px")
          .append(
            $("<div />").dxButton({
              text: "Cancel",
              icon: "revert",
              type: "danger",
              onClick: function (e: any) {
                let toast = $($(e.element[0]).parents())
                  .find(
                    ".dx-overlay.dx-widget.dx-visibility-change-handler.dx-toast"
                  )
                  .dxToast("instance");
                toast.hide();
                return;
              }
            })
          )
      );
    new Notification({
      type: "warning",
      shading: true,
      displayTime: 2000000,
      message: message
    });
  }

  Create(request: TDFRequest) {
    let theAction = this;

    request.MakeRequest().done(function (response: any) {
      if (true) {
        if (response.ControlGroups) {
          DisplayInfo(theAction.RelevantType).done(info => {
            const name: string = info.TypeName;
            import(`../components/items/${name.toLowerCase()}`).then(
              theitem => {
                let newitem = new theitem[name]({
                  ItemType: theAction.RelevantType
                });
                //  item.Initialize();

                // let newitem = new window[info.TypeName]({
                //   ItemType: theAction.RelevantType
                // });
                if (response.PrePopulationData) {
                  newitem.PrepopulationData = response.PrePopulationData;
                }
                if (theAction.ContainerItemID)
                  newitem.ContainerItemID = theAction.ContainerItemID;
                $.each(response, function (key, val) {
                  try { newitem[key] = val; } catch (e) {
                    console.log(e);
                    console.log("the key was '" + key.toString() + "'");
                  }
                });
                newitem.iMain = theAction.singleItem.iMain;
                newitem.CanEdit = true;
                newitem.Dirty = true;
                newitem.isNew = true;

                var controls = [];
                $.each(newitem.ControlGroups, function (key, val) {
                  if (parseInt(val.ControlGroupOrder) === 0 && val.Visible) {
                    if (val.ControlGroupID != "") {
                      controls.push(val.ControlGroupID);
                    }
                  }
                });
                newitem.GetControls(controls).done(function () {
                  newitem.OnCreate().done(() => {
                    newitem.Form();
                  });
                });
              }
            );
          });
          //let newitem = new TDF[itemTypes[theAction.RelevantType].toString().replace("item", "")]({ ItemType: theAction.RelevantType });
        }
      } else {
        new Notification({
          message: response.Message,
          type: "error",
          displayTime: 8000
        });
      }
    });
  }

  GetLinkSearch(field?: string) {
    let theAction = this;
    GetLinkPrompt(theAction.singleItem, theAction.RelevantType);
  }

  LinkItem() {
    let theAction = this;

    switch (theAction.RelevantType) {
      case itemTypes.itemContact:
        theAction.GetLinkSearch(ContactEnum[ContactEnum.CompanyName]);
        break;
      default:
        theAction.GetLinkSearch();
    }
  }

  // Note that all "non-special" Link***** functions can be removed.

  LinkAccount() {
    let theAction = this;
    theAction.GetLinkSearch();
  }

  LinkActivity() {
    let theAction = this;
    theAction.GetLinkSearch();
  }

  LinkContact() {
    let theAction = this;
    theAction.GetLinkSearch(ContactEnum[ContactEnum.CompanyName]);
  }

  LinkOpportunity() {
    let theAction = this;
    theAction.GetLinkSearch();
  }

  LinkAffiliate() {
    let theAction = this;
    theAction.GetLinkSearch();
  }

  LinkAsset() {
    let theAction = this;
    theAction.GetLinkSearch();
  }

  LinkTicket() {
    let theAction = this;
    theAction.GetLinkSearch();
  }

  LinkForecast() {
    let theAction = this;
    theAction.GetLinkSearch();
  }

  LinkQuote() {
    let theAction = this;
    theAction.GetLinkSearch();
  }

  LinkLead() {
    let theAction = this;
    theAction.GetLinkSearch();
  }

  LinkKnowledgeBase() {
    let theAction = this;
    theAction.GetLinkSearch();
  }

  LinkLiterature() {
    let theAction = this;
    theAction.GetLinkSearch();
  }

  LinkSupport() {
    let theAction = this;
    theAction.GetLinkSearch();
  }

  LinkContract() {
    let theAction = this;
    theAction.GetLinkSearch();
  }

  LinkEvent() {
    let theAction = this;
    theAction.GetLinkSearch();
  }
  LinkAppointment() {
    let theAction = this;
    theAction.GetLinkSearch();
  }

  LinkDocument() {
    let theAction = this;
    theAction.GetLinkSearch();
  }

  LinkEmail() {
    let theAction = this;
    theAction.GetLinkSearch();
  }

  LinkNote() {
    let theAction = this;
    theAction.GetLinkSearch();
  }

  LinkTask() {
    let theAction = this;
    theAction.GetLinkSearch();
  }

  LinkExpense() {
    let theAction = this;
    theAction.GetLinkSearch();
  }

  NewGeneralItem() {
    let theAction = this;

    switch (theAction.RelevantType) {
      case itemTypes.itemAccount:
        theAction.NewAccount();
        break;
      case itemTypes.itemEmail:
        theAction.NewEmail();
        break;
      case itemTypes.itemExpense:
        theAction.NewExpense();
        break;
      case itemTypes.itemQuote:
        theAction.QuoteManager();
        break;
      default:
        theAction.GetURLAndCreateItem();
    }
  }

  NewAccount() {
    let theAction = this;
    createitem(itemTypes.itemAccount, { isNew: true }, true);
    //let newItem = new Account({ isNew: true }).Create();
  }

  NewActivity() {
    let theAction = this;
    theAction.GetURLAndCreateItem();
  }

  private GetURLAndCreateItem() {
    let theAction = this;
    let newtype = theAction.RelevantType;
    if (!newtype && theAction.Action === menuActions.menuQuoteManager) {
      newtype = itemTypes[itemTypes[13].toString()];
    }
    if (menuActions[theAction.Action].toString().indexOf("New") > -1) {
      theAction.ActionURL = "/item/newwithparent/";
    }

    /*Here*/
    let request = new TDFRequest({
      url: theAction.ActionURL,
      data: { newtype: newtype, parent: theAction.singleItem.iMain.ItemId },
      type: "POST",
      dataType: "application/json"
    });
    theAction.Create(request);
  }

  NewAppointment() {
    let theAction = this;
    theAction.GetURLAndCreateItem();
  }

  NewContact() {
    let theAction = this;
    theAction.GetURLAndCreateItem();
  }

  NewDocument() {
    let theAction = this;
    theAction.GetURLAndCreateItem();
  }

  NewEmail() {
    // Handles Multiple (sometimes -> only if we're on a Contact or Lead)
    // Multiple is not yet supported in web.
    let theAction = this;
    let theItemList = theAction.GetItemList();

    let emailGen = new EmailGenerator(
      {
        ItemId: theItemList[0].ItemId,
        ItemType: theItemList[0].ItemType,
        iMain: theAction.items[0].iMain
      },
      "",
      "",
      (<any>theAction).Email || "",
      false
    );
  }

  NewExpense() {
    let theAction = this;
    let t = {
      New: true,
      Description:
        theAction.singleItem.iMain.ItemSubject +
        " -- " +
        Globalize.formatDate(new Date(), { date: "short" }),
      Dirty: true,
      ExpenseAmount: 0,
      ExpenseDate: Globalize.formatDate(new Date(), { date: "short" }),
      MileageID: 0
    };
    let nexpargs: NewExpenseArgs = {
      ParentID: theAction.singleItem.ItemId,
      ParentInfo: EnumParent.Linked,
      ReferenceExpenseID: "",
      ParentType: theAction.singleItem.ItemType
    };
    /*Here*/
    new TDFRequest({
      url: "/Expense/CreateExpense/",
      data: { args: nexpargs, expensedata: t },
      type: "POST"
    })
      .MakeRequest()
      .done(function (response: any) {
        if (true) {
          window.location.href = `/expense/?itemid=${response.ID}`;
        }
        new Notification({
          message: response.Message,
          type: "success",
          shading: false
        });
      });
  }

  NewNote() {
    let theAction = this;
    theAction.GetURLAndCreateItem();
  }

  NewOpportunity() {
    let theAction = this;
    theAction.GetURLAndCreateItem();
  }

  NewSupport() {
    let theAction = this;
    theAction.GetURLAndCreateItem();
  }

  NewTask() {
    let theAction = this;
    theAction.GetURLAndCreateItem();
  }
  NewLiterature() {
    let theAction = this;
    theAction.GetURLAndCreateItem();
  }
  OpenContactFromEmail() {
    let theAction = this;
  }

  ForwardLink() {
    let theAction = this;
  }

  TaskGroup() {
    // Excluded in Web
    let theAction = this;
  }

  AddContact() {
    let theAction = this;
  }

  EditSalesProcess() {
    // Excluded in Web
    let theAction = this;
  }

  CopyItem() {

    import("../services/item/itemdataservice").then(service => {

      let theAction = this;

      let payload = { ItemID: theAction.singleItem.ItemId, ItemType: theAction.singleItem.ItemType }

      let req = service.Copy(payload); // returns a promise

      req.done(function (data) {
        DisplayInfo(theAction.singleItem.ItemType).done(info => {
          if (
            theAction.singleItem.ItemType === itemTypes.itemQuote ||
            theAction.singleItem.ItemType === itemTypes.itemExpense
          ) {
            let url = "/";
            url += info.DisplayName + "/";
            if (url !== "/") {
              window.location.href = url + data.Data;
            }
          } else {

            createitem(theAction.singleItem.ItemType, { ItemID: data });


          }
        });
      });

    });

  }

  NewContract() {
    let theAction = this;
    theAction.GetURLAndCreateItem();
  }

  EditContract() {
    let theAction = this;
  }

  DeleteContract() {
    let theAction = this;
  }

  NewEvent() {
    let theAction = this;
    theAction.GetURLAndCreateItem();
  }

  EditEvent() {
    let theAction = this;
  }

  DeleteEvent() {
    let theAction = this;
  }

  QuoteManager() {
    let theAction = this;

    if (theAction.MenuText.indexOf("New") < 0) {
      if (!GetDevice().isDevice) {
        window.open(theAction.ActionURL, "_blank");
      } else {
        window.location.href = theAction.ActionURL;
      }
    } else {
      let Description = new Dialog({
        id: "newquoteDescription",
        body: $("<div id='getdesccontent'/>")
          .append($("<label/>").text("Please Enter A Description"))
          .append(
            $("<div />").dxTextArea({
              //3/5/2010 4:35:12 PM - Abec Inc -
              inputAttr: { id: "descriptionfornewquote" },
              value:
                Globalize.formatDate(new Date(), { datetime: "short" }) +
                "-" +
                theAction.singleItem.iMain.ItemSubject || ""
            })
          ),
        size: "size-normal",
        closable: true,
        type: "type-warning",
        title: "New Quote Description",
        buttons: [
          {
            widget: "dxButton",
            toolbar: "bottom",
            location: "after",
            options: {
              type: "success",
              onClick: function () {

                NewQuoteWithParent(
                  theAction.singleItem.ItemId,
                  $("#descriptionfornewquote").val().toString()
                ).done((id: string) => {
                  Description.close();

                  if (GetDevice().isDevice) {
                    window.location.href = `/quote/?itemid=${id}`;
                  } else {
                    let theWindow = window.open();
                    theWindow.location.href = `/quote/?itemid=${id}`;
                  }
                });

              },
              icon: "check",
              text: "OK"
            }
          }
        ]
      });
      Description.open();
    }
  }

  EditSupport() {
    let theAction = this;
  }

  DeleteSupport() {
    let theAction = this;
  }

  EditOpportunity() {
    let theAction = this;
  }

  DeleteOpportunity() {
    let theAction = this;
  }

  EditAccount() {
    let theAction = this;
  }

  EditActivity() {
    let theAction = this;
  }

  EditAppointment() {
    let theAction = this;
  }

  EditContact() {
    let theAction = this;
  }

  EditDocument() {
    let theAction = this;
  }

  EditEmail() {
    let theAction = this;
  }

  EditExpense() {
    let theAction = this;
    window.location.href = "/Expense/?itemId=" + theAction.singleItem.ItemId;
  }

  EditNote() {
    let theAction = this;
  }

  EditTask() {
    let theAction = this;
  }

  InviteContactToEvent() {
    // Handles Multiple
    let theAction = this;
  }

  OpenPublicItem() {
    let theAction = this;
  }

  OpenProfile() {
    let theAction = this;
  }

  ChangeParent() {
    // Handles Multiple
    let theAction = this;
    let theItemList = theAction.GetItemList();
    let code = Math.floor(Math.random() * 8999 + 1000);
    let form: DevExpress.ui.dxForm;
    let enteredcode = null;

    let d = $.Deferred();
    let promise = d.promise();
    let opts: DevExpress.ui.dxPopupOptions = {
      title: "Are you sure you want to change parent for this item?",
      showCloseButton: false,
      height: "auto",
      maxWidth: 600,
      contentTemplate: function (el) {
        form = $("<div />")
          .dxForm(<DevExpress.ui.dxFormOptions>{
            showValidationSummary: true,
            items: [
              <DevExpress.ui.dxFormGroupItem>{
                itemType: "group",
                caption: "Enter the verification code and press ok to confirm.",
                items: <DevExpress.ui.dxFormSimpleItem[]>[
                  {
                    itemType: "simple",
                    dataField: "theCode",
                    label: {
                      visible: true,
                      alignment: "center",
                      location: "top",
                      showColon: false,
                      text: code.toString()
                    },
                    editorOptions: <DevExpress.ui.dxTextBoxOptions>{
                      mask: "0000",
                      maskChar: "#",
                      onValueChanged(e) {
                        if (form.validate().isValid) {
                          theDialog.option("toolbarItems[0].disabled", false);
                          enteredcode = e.value;
                        } else {
                          theDialog.option("toolbarItems[0].disabled", true);
                        }
                      },
                      valueChangeEvent: "keyup"
                    },
                    validationRules: [
                      {
                        type: "compare",
                        comparisonTarget: function () {
                          return code;
                        },
                        message: "Code does not match"
                      }
                    ],
                    editorType: "dxTextBox"
                  }
                ]
              }
            ]
          })
          .dxForm("instance");
        el.append(form.element());
      },
      toolbarItems: <DevExpress.ui.dxPopupToolbarItem[]>[
        {
          widget: "dxButton",
          toolbar: "bottom",
          disabled: true,
          location: "after",
          options: {
            type: "success",
            onClick: function () {
              if (
                parseInt(form.getEditor("theCode").option("value")) === code
              ) {
                theDialog.hide().done(() => {
                  d.resolve(true);
                });
              }
            },
            icon: "check",
            text: "Ok"
          }
        },
        {
          widget: "dxButton",
          toolbar: "bottom",
          location: "after",
          options: {
            id: "cancel",
            onClick: function () {
              theDialog.hide().done(() => {
                d.resolve(null);
              });
            },
            icon: "remove",
            text: "Cancel"
          }
        }
      ]
    };
    if ($("#popup").length) {
      $("#popup").remove();
    }
    $("<div id='popup'/>").appendTo("body");
    let theDialog = $("#popup")
      .dxPopup(opts)
      .dxPopup("instance");

    new TDFRequest({
      url: "/Item/GetiMain/",
      type: "POST",
      data: {
        itemList: theItemList
      }
    })
      .MakeRequest()
      .done(function (response: any) {
        if (true) {
          let itemType: itemTypes;
          let parentType: itemTypes;
          if (response && response.length >= 1) {
            itemType = response[0].ItemType;
            parentType = response[0].PrimaryParent.ParentType; // parentType being set to the item's CURRENT parent?

            $.each(response, function (index, value: ItemMain) {
              if (parentType === null) {
                parentType = value.PrimaryParent.ParentType;
              } else {
                if (
                  (parentType === itemTypes.itemAccountPackage &&
                    value.PrimaryParent.ParentType !=
                    itemTypes.itemAccountPackage) ||
                  (parentType != itemTypes.itemAccountPackage &&
                    value.PrimaryParent.ParentType ===
                    itemTypes.itemAccountPackage)
                ) {
                  new Notification({
                    message:
                      "We cannot change the parent on items parented by Account Packages and other types at the same time.",
                    type: "error",
                    displayTime: 5000
                  });
                  return;
                }
              }
            });
          }

          if (
            parentType !== itemTypes.itemAccountPackage &&
            parentType !== itemTypes.itemUnknown
          ) {
            /*Here*/
            let request = new TDFRequest({
              url: "/item/ValidParents/",
              data: { itemtype: theAction.items[0].ItemType }
            });
            request.MakeRequest().done(function (response: any) {
              let SRF: SearchRecFav = new SearchRecFav(
                theAction.items[0].ItemType,
                response,
                itemTypes.itemUnknown,
                false
              );
              SRF.GetSRFDialog().done(selected => {
                /*Here*/
                let request = new TDFRequest({
                  url: "/item/changeparent/",
                  data: {
                    itemList: theItemList,
                    newparent: selected[0]["TDF GUID"],
                    parenttype: selected[0].ItemType // parentType || itemTypes.itemUnknown
                  },
                  type: "POST",
                  dataType: "application/json"
                });
                theDialog.show();
                $.when(promise).done(val => {
                  if (val) {
                    request
                      .MakeRequest()
                      .done(function (response: any) {
                        if (true) {
                          SRF.Dialog.close();

                          if (response.ValidList.length) {
                            $.each(response.ValidList, (k, v) => {
                              new Notification({
                                message: v,
                                type: "success",
                                displayTime: 100000
                              });
                            });
                          }

                          if (response.InvalidList.length) {
                            $.each(response.InvalidList, (k, v) => {
                              new Notification({
                                message: v,
                                type: "warning",
                                displayTime: 100000
                              });
                            });
                          }
                        } else {
                          if (response.Message) {
                            new Notification({
                              message: response.Message,
                              type: "error",
                              displayTime: 10000
                            });
                          }
                        }
                      });
                  }
                });
              });
            });
          } else {
            let selector = new PackageSelection();
            selector.Buttons.push({
              widget: "dxButton",
              toolbar: "bottom",
              location: "after",
              options: {
                text: "Search For an Existing Item Instead",
                icon: "fa fa-search",
                type: "default",
                onClick: function () {
                  selector.Dialog.close();
                }
              }
            });
            selector.DisplayPanel();

            selector.OnCommit = function (response) {
              let folder = response["level" + selector.ActiveFolderLevel];
              let str = Object.keys(folder)[0];
              folder = folder[str];

              /*Here*/
              let request = new TDFRequest({
                url: "/action/ChangeAccountPackage/",
                data: {
                  itemList: theItemList,
                  newpkgid: folder.folderid
                },
                type: "POST",
                dataType: "application/json"
              });

              request.MakeRequest().done(function (response: any) {
                if (true) {
                  new Notification({
                    message:
                      response.Message + "  You may need to refresh the item.",
                    type: "success",
                    displayTime: 10000
                  });
                } else {
                  new Notification({
                    message: response.Message,
                    type: "error",
                    displayTime: 10000
                  });
                }
              });
            };
          }
        } else {
          new Notification({
            message: response.Message,
            type: "error"
          });
        }
      });
  }

  CopyToPersonalFolder() {
    // Handles Multiple
    let theAction = this;
    let theItemList = theAction.GetItemList();
    /*Here*/
    new TDFRequest({
      url: "/item/CopyToPersonalContacts/",
      data: { itemList: theItemList },
      type: "POST"
    })
      .MakeRequest()
      .done((response: any) => {
        new Notification({
          message: response.Message,
          type: "success",
          displayTime: 3000
        });
      });
  }

  GetItemList(): Array<any> {
    let theAction = this;
    let itemList: Array<any> = [];
    $.each(theAction.items, function (index, val: IItemInfo) {
      itemList.push({
        ItemId: val.ItemId,
        ItemType: val.ItemType
      });
    });

    return itemList;
  }

  AddToFavorites() {
    // Handles Multiple
    let theAction = this;
    let theItemList = theAction.GetItemList();

    /*Here*/
    new TDFRequest({
      url: "/Action/AddFavorite/",
      type: "POST",
      data: {
        itemList: theItemList
      }
    })
      .MakeRequest()
      .done(function (response: any) {
        if (true) {
          new Notification({
            message:
              (theItemList.length > 1 ? "Items" : "Item") +
              " added to favorites.",
            type: "success"
          });
        } else {
          new Notification({ message: response.Message, type: "error" });
        }
      });
  }

  NewAsset() {
    let theAction = this;
    theAction.GetURLAndCreateItem();
  }

  EditAsset() {
    let theAction = this;
  }

  NewTicket() {
    let theAction = this;
    theAction.GetURLAndCreateItem();
  }

  EditTicket() {
    let theAction = this;
  }

  CreateSupportFromAsset() {
    let theAction = this;
  }

  CreateContractFromAsset() {
    let theAction = this;
  }

  OpenParent() {
    let theAction = this;
    let parentInfo = theAction.singleItem;

    createitem(parentInfo.iMain.PrimaryParent.ParentType, { ItemID: parentInfo.iMain.PrimaryParent.ParentId });
  }

  OpenAccount() {
    let theAction = this;
  }

  OpenContact() {
    let theAction = this;
  }

  OpenInNavigator() {
    let theAction = this;
  }

  NewForecast() {
    let theAction = this;
    theAction.GetURLAndCreateItem();
  }

  EditForecast() {
    let theAction = this;
  }

  PrintItem() {
    let theAction = this;
  }

  LaunchMap() {
    let theAction = this;
  }

  IncentivesEnroll() {
    // Excluded in Web
    // Handles Multiple
    let theAction = this;
  }

  IncentivesRequestEnroll() {
    // Excluded in Web
    // Handles Multiple
    let theAction = this;
  }

  IncentivesRedeem() {
    // Excluded in Web
    let theAction = this;
  }

  AttachFiles() {
    let theAction = this;
    let theItemList = theAction.GetItemList();
    let emailGen = new EmailGenerator(
      {
        ItemId: theItemList[0].ItemId,
        ItemType: theItemList[0].ItemType,
        iMain: theAction.items[0].iMain
      },
      "",
      "",
      (<any>theAction).Email || "",
      false
    );
    //emailGen.IncludeAttachments = true;
    emailGen.ListAttachmentFiles(theItemList[0].ItemId, emailGen.AttachMents);
    //emailGen.CreateEmail();
  }

  AddToAgenda() {
    let theAction = this;
    let theItemList = theAction.GetItemList();

    let itemIds = theItemList.map(a => {
      return a.ItemId;
    });

    LoadCompany().done(() => {
      /*Here*/
      new TDFRequest({
        url: TDFRequest.ApiPath + "/time-tracking/add-items",
        type: "POST",
        data: itemIds
      })
        .MakeRequest()
        .done(response => {
          //if no errors
          if (response === "") {
            new Notification({
              message: "Item(s) added to Agenda.",
              type: "success"
            });
          }
        });
    });
  }

  AddTimeEntry() {
    let theAction = this;
    let theItemList = theAction.GetItemList();
    let theIdList = [];
    let firstItemType = theItemList[0].ItemType || theItemList[0].TDFItemType;
    let singleItemType: boolean = true;

    let quickNoteContainerID = "quickNoteContainer";
    if (!$("#" + quickNoteContainerID).length) {
      $("body").append($("<div id=" + quickNoteContainerID + " />"));
    }

    $.each(theItemList, (k, v) => {
      let theID = v.ItemID || v.ItemId || v["TDF GUID"] || v.TDFItemID;
      let theType = v.ItemType || v.TDFItemType;

      theIdList.push(theID);

      if (theType !== firstItemType) {
        new Notification({
          message: "This action does not support multiple item types.",
          type: "error"
        });
        singleItemType = false;
      }
    });

    if (singleItemType) {
      /*Here*/
      new TDFRequest({
        url:
          TDFRequest.ApiPath +
          "/quicknotes/notes?itemId=" +
          theIdList[0] +
          "&multiItem=true",
        type: "GET"
      })
        .MakeRequest()
        .done((response: any) => {
          let quickNotes = new TTQuickNotes(
            response,
            $("#" + quickNoteContainerID),
            theItemList[0]
          );

          if (theIdList.length > 1) {
            quickNotes.multipleIds = theIdList;
          }

          quickNotes.generateNotePopup(
            quickNotes.theData,
            theItemList[0],
            quickNotes.container
          );
        });
    }
  }

  LaunchSherpaEnterprise() {
    let theAction = this;
    let theItemList = theAction.GetItemList();
    let itemIds = theItemList.map(a => {
      return a.ItemId;
    });
    let w: Window;
    try {
      // note - this works on a desktop.
      // need to suppress it from tablets/phones.
      w = window.open("tdf://action=sherpa_enterprise&id=" + itemIds[0], "zzz");
    } finally {
      if (w) w.close();
    }
  }

  SendToBSI() {
    // Handles Multiple
    let theAction = this;
    //let itemType: theAction.GetItemList()[0];

    new TDFRequest({
      url: "/Item/GetiMain/",
      type: "POST",
      data: {
        itemList: theAction.GetItemList()
      }
    })
      .MakeRequest()
      .done(function (response: ItemMain[]) {
        let theItemIdString = "";
        let theItemNameString = "";
        let theItemCount: number = 0;

        let itemListNameModel: ItemListingNameModel = { ItemListWithNames: [] };
        let itemIdList: string = "";

        if (response && response.length >= 1) {
          let accountType: itemAccountTypes;

          $.each(response, function (index, value: ItemMain) {
            theItemCount++;

            if (accountType === undefined) {
              accountType = value.ItemAccountType;
            } else {
              if (accountType != value.ItemAccountType) {
                new Notification({
                  message:
                    "We cannot handle BI data for Vendors and standard accounts at the same time.",
                  type: "error",
                  displayTime: 5000
                });
                accountType = undefined; // prevents the dialog from being opened below.
                return;
              }
            }

            itemListNameModel.ItemListWithNames.push({
              ID: value.ItemId,
              Name: value.ItemSubject
            });
            if (itemIdList != "") {
              itemIdList = itemIdList + ",";
            }
            itemIdList = itemIdList + value.ItemId;
          });

          if (accountType === itemAccountTypes.Regular) {
            var customName = DomSafeID(
              itemListNameModel.ItemListWithNames[0].ID + "et_al"
            );
            var biCenterName = "AccountBi_" + customName;

            // Frame for the Center
            let html = $("<div id='" + biCenterName + "' />").height('100%');

            let biDialog = new Dialog(
              {
                id: "biSalesDialog_" + customName,
                closable: true,
                title:
                  "Sales Data" +
                  (theItemCount > 1 ? ": " + theItemCount + " Accounts" : ""),
                body: html,
                type: "type-primary",
                size: "size-wide"
              },
              null,
              false,
              false
            );

            biDialog.open().done(function () {
              //let biAcctCenter = new TDF.BiAcctCenter(itemIdList, itemListNameModel, customName);
              //biAcctCenter.Initialize().done(function (response) {
              //    biAcctCenter.RenderCenter();
              //});
              //NEEDTODO:  Work out how to get the offset right for the BI menu
              let biCenter = new AcctBIDataCenter(
                itemIdList,
                itemListNameModel,
                { MainAreaContainer: html },
                customName
              );
              //TODO
              biCenter.InitAndRender();
            });
          } else if (accountType === itemAccountTypes.Vendor) {
            var customName = DomSafeID(
              itemListNameModel.ItemListWithNames[0].ID + "et_al"
            );
            var biCenterName = "VendorBi_" + customName;

            // Frame for the Center
            let html = $("<div id='" + biCenterName + "' />");

            let biDialog = new Dialog(
              {
                id: "biVendorDialog_" + customName,
                closable: true,
                title:
                  "Vendor BI Data" +
                  (theItemCount > 1 ? ": " + theItemCount + " Accounts" : ""),
                body: html,
                type: "type-primary",
                size: "size-wide"
              },
              null,
              false
            );

            biDialog.open().done(function () {
              let biVendorCenter = new VendorBIDataCenter(
                itemListNameModel,
                { MainAreaContainer: html },
                customName
              );
              biVendorCenter.InitAndRender();
            });
          }
        }
      });
  }

  SendToContactSummary() {
    // Handles Multiple
    let theAction = this;

    /*Here*/
    new TDFRequest({
      url: "/Item/GetiMain/",
      type: "POST",
      data: {
        itemList: theAction.GetItemList()
      }
    })
      .MakeRequest()
      .done(function (response: any) {
        let theItemCount: number = 0;

        let itemListNameModel: ItemListingNameModel = { ItemListWithNames: [] };
        let itemIdList: string = "";
        if (response && response.length >= 1) {
          $.each(response, function (index, value: ItemMain) {
            theItemCount++;
            itemListNameModel.ItemListWithNames.push({
              ID: value.ItemId,
              Name: value.ItemSubject
            });

            if (itemIdList != "") {
              itemIdList = itemIdList + ",";
            }
            itemIdList = itemIdList + value.ItemId;
          });

          var customName = DomSafeID(
            itemListNameModel.ItemListWithNames[0].ID + "et_al"
          );
          var biCenterName = "ContactBi_" + customName;

          // Frame for the Center
          let html = $("<div id='" + biCenterName + "' />");

          let biDialog = new Dialog(
            {
              closable: true,
              id: "biContactDialog_" + customName,
              title:
                "Sales Data" +
                (theItemCount > 1 ? ": " + theItemCount + " Contacts" : ""),
              body: html,
              type: "type-primary",
              size: "size-wide"
            },
            null,
            false
          );

          biDialog.open().done(function () {
            let biCenter = new ContactBIDataCenter(
              itemListNameModel,
              { MainAreaContainer: html },
              customName
            );
            biCenter.InitAndRender();
          });
        }
      });
  }

  SendToAnalysis() {
    // Handles Multiple
    // Excluded in Web
    let theAction = this;
  }

  GoToWebsite() {
    let theAction = this;
    // Note:  This request needs to be made synchronously to avoid pop up blockers when we open the address.
    /*Here*/
    new TDFRequest({
      url: "/Action/GetWebAddress/?itemid=" + theAction.singleItem.ItemId,
      async: false
    })
      .MakeRequest()
      .done(function (address) {
        if (address) {
          let url: string =
            address.indexOf("http") < 0 ? "http://" + address : address;
          if (url) {
            window.open(url, "_blank");
          }
        }
      });
  }

  ShareItem() {
    let theAction = this;
  }

  NewKnowledgeBase() {
    let theAction = this;
    theAction.GetURLAndCreateItem();
  }

  EditKnowledgeBase() {
    let theAction = this;
  }

  RemoveFavorite() {
    // Handles Multiple
    let theAction = this;
    let theItemList = theAction.GetItemList();

    /*Here*/
    new TDFRequest({
      url: "/Action/RemoveFavorite/",
      type: "POST",
      data: {
        itemList: theItemList
      }
    })
      .MakeRequest()
      .done(function (response: any) {
        if (true) {
          new Notification({
            message:
              (theItemList.length > 1 ? "Items" : "Item") +
              " removed from favorites.",
            type: "success"
          });
        } else {
          new Notification({ message: response.Message, type: "error" });
        }
      });
  }

  SendReminder() {
    let theAction = this;

    let theItem = theAction.items;
    createitem(
      itemTypes.itemReminder,
      { ParentItemID: theItem[0].ItemId, ParentItemType: theItem[0].ItemType },
      true
    );
    //let reminder = new Reminder({ "ParentItemID": theItem[0].ItemId, "ParentItemType": theItem[0].ItemType });
    //reminder.Create();
  }

  ViewTransactions() {
    // Excluded in Web
    let theAction = this;
  }

  NewConversation() {
    // Excluded in Web
    let theAction = this;
  }

  WebDataForContact() {
    let theAction = this;
  }

  CompleteAndConvert() {
    // Handles Multiple

    let theAction = this;
    let theItemList = theAction.GetItemList();
    let theItems: string[] = [];
    let theType: itemTypes = theItemList[0].ItemType;

    $.each(theItemList, function (index, value) {
      if (theType != value.ItemType) {
        new Notification({
          message:
            "Cannot complete and convert multiple types at the same time.  Please make sure you select only Tasks or only Appointments when completing and converting multiple items at the same time.",
          type: "error"
        });
        return;
      }
      theItems.push(value.ItemId);
    });

    RenderCompleteConvertDialog(theType, theItems);
  }

  PropagateAccountInformation() {
    let theAction = this;
    new PropagateToContactsDialog(
      theAction.singleItem.ItemId,
      theAction.singleItem.ItemType
    ).Create();
  }

  LeadQualify() {
    let theAction = this;
    theAction.singleItem.ItemType = itemTypes.itemLead;
    createitem(
      itemTypes.itemLead,
      theAction.singleItem.iMain,
      false,
      true,
      thelead => {
        let theDialog = new Dialog({
          body: thelead.QualifyForm(),
          id: "lead-qualify-form",
          size: "size-normal",
          closable: true,
          title: "Qualify Lead"
        });
        theDialog.Instance.option(
          "toolbarItems",
          thelead.QualifyButtons(theDialog)
        );
        theDialog.open();
      }
    );
    //let thelead = new Lead(theAction.singleItem.iMain);
  }

  LeadClose() {
    let theAction = this;
    theAction.singleItem.ItemType = itemTypes.itemLead;
    createitem(
      itemTypes.itemLead,
      {
        ItemID: theAction.singleItem.ItemId,
        ItemType: theAction.singleItem.ItemType
      },
      false,
      true,
      thelead => {
        thelead.BuildCloseForm().done((html: JQuery) => {
          let theDialog = new Dialog({
            body: html,
            size: "size-normal",
            closable: true,
            title: "Close Lead"
          });
          theDialog.Instance.option(
            "toolbarItems",
            thelead.CloseButtons(theDialog)
          );
          theDialog.open();
        });
      }
    );
  }

  LeadSetStage() {
    let theAction = this;
  }

  NewLead() {
    let theAction = this;
    theAction.GetURLAndCreateItem();
  }

  ShowActionPanel() {
    let theAction = this;
  }

  ViewWebAccessLog() {
    let theAction = this;
  }

  CreateQuoteDocument() {
    let theAction = this;
  }

  CreateMultipleForecasts() {
    let theAction = this;
  }

  SendToCombineItemsUtil() {
    // TODO: This action should only be allowed when all selected item types are of the same type.

    let theAction = this;

    if (!theAction.items) {
      alert("No items were selected.", "");
    } else if (theAction.items.length == 0) {
      alert("No items were selected.", "");
    } else {
      let model: itemDescriptor = {
        itemtype: theAction.items[0].ItemType,
        guids: theAction.items.map((val, index) => {
          return val.ItemId;
        })
      };

      new ItemCombiner({
        sourceItems: model,
        targetItem: null
      });
    }
  }

  MassUpdate() {

    let theAction = this;
    let theItem = theAction.items;

    if (theItem.length > 0) {

      // Build a "simple" selection - based only on selected items. We dont' have access to the grid from here.
      let selection: ServerCommands.TDFSelectionDescriptor = {
        Filter: "",
        Except: "",
        ItemType: theAction.RelevantType,
        ItemList: theItem.map((v, i) => { return v.ItemId })
      }

      new TDFMassUpdate.MassUpdateDlg(true, selection);

    }

  }


  ChangeAcctPkg() {

    let theAction = this;
    let dlg = new PackageSelection();
    dlg.DisplayPanel();
    dlg.OnCommit = function (response) {

      let folder = response["level" + dlg.ActiveFolderLevel];

      let str = Object.keys(folder)[0];
      folder = folder[str];

      let accounts = theAction.items.map((val, i) => {
        return { ItemID: val.ItemId, ItemType: val.ItemType }
      });

      let model: api.ItemListWithPkgID = { ItemList: accounts, NewPkgID: folder.folderid };

      let req = api.actions.changeAcctPkg(model);

      req.MakeRequest().done((e: string) => {
        new Notification({ "message": e, "type": "success", displayTime: 2500 });
      });

    };

  }
}
