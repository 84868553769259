import { itemTypes } from "../../enums/enums";
import { IProductInfo } from "../../interfaces/interfaces";
import { TDFRequest } from "../../services/request";
import { Dialog } from "../dialogs/dialog";
import { Notification } from "../dialogs/notification";
import dxDataSource from "devextreme/data/data_source";
import { createitem } from "./factory/itemfactory";
import { DomSafeID, GetDevice } from "../../util/allutils";
import dxArrayStore from "devextreme/data/array_store";
import { DisplayInfo } from "../../infrastructure/context";
export class ItemNavigator {
  protected IDList: string[] | IProductInfo[];
  protected CurrentType: itemTypes;
  protected CurrentItem: any;
  protected SelectedIndex: number;
  protected NavForm: Dialog;
  protected MultiView: DevExpress.ui.dxMultiView;
  public IsSafeClose: boolean = false;
  protected ItemCache: any[];

  constructor(list, itemtype) {
    const navigator = this;
    navigator.IDList = list;
    navigator.CurrentType = itemtype;
    navigator.Render();
  }
  public AddItemToCache(item?: any) {
    const navigator = this;
    if (item) {
      if (navigator.GetItemFromCache(item.ItemID)) {
        return;
      }
      if (!navigator.ItemCache) {
        navigator.ItemCache = [item];
      } else {
        navigator.ItemCache.push(item);
      }
    } else {
      if (navigator.GetItemFromCache(navigator.CurrentItem.ItemID)) {
        return;
      }
      if (!navigator.ItemCache) {
        navigator.ItemCache = [navigator.CurrentItem];
      } else {
        navigator.ItemCache.push(navigator.CurrentItem);
      }
    }
  }
  public GetItemFromCache(id: string) {
    const navigator = this;
    if (!navigator.ItemCache) {
      return;
    }
    return $.grep(navigator.ItemCache, (item, index) => {
      return id === item.ItemID;
    })[0];
  }
  public Render() {
    const navigator = this;
    const d = $.Deferred();
    $.when(DisplayInfo(navigator.CurrentType)).done(function (IDisplayInfo) {
      navigator.NavForm = new Dialog(
        {
          size: "size-wide",
          id: "itemnav",
          closable: true,
          onHiding(e) {
            if (navigator.CurrentItem && navigator.CurrentItem.Dirty) {
              new Notification({ message: `The current ${navigator.CurrentItem.DisplayName} must be saved!`, type: "warning", displayTime: 5000 });
              e.cancel = true;
              return;
            } else {
              navigator.RemoveAll();
            }
            // if (navigator.CurrentItem.Dirty && !navigator.IsSafeClose) {
            //    new Notification({ message: "The current " + navigator.CurrentItem.DisplayName + " must be saved!", type: "warning", displayTime: 5000 });
            //    e.cancel = true;
            //    return;
            // } else {
            //    if (navigator.IDList.length > 1) {
            //        navigator.IsSafeClose = true;
            //        navigator.RemoveCurrent();
            //        e.cancel = true;
            //        return;
            //    }
            // }
          },
          title: "Navigator - Multiple Item Types",
          body: $("<div />").attr({ id: "multiviewContainer" }),
          type: "type-primary"
        },
        navigator.CurrentItem
      );
      navigator.NavForm.Instance.option("toolbarItems", [
        {
          toolbar: "bottom",
          location: "center",
          template: (data, index, el) => {
            el.append(navigator.CreatePagination());
          }
        }
      ]);
      navigator.NavForm.open().done(function (args) {
        const opts: DevExpress.ui.dxMultiViewOptions = {
          dataSource: new dxDataSource({
            store: new dxArrayStore({
              data: navigator.IDList
            }),
            paginate: false
          }),
          selectedIndex: navigator.SelectedIndex,
          // with swipe enabled trying to sroll up and down often causes the multiview to advance\go back
          swipeEnabled: false,
          loop: true,
          itemTemplate(data, index, element) {
            if (!data) {
              return;
            }
            if (navigator.CurrentType !== itemTypes.itemProduct) {
              if (!$("#multiviewItem" + DomSafeID(data)).length) {
                const itemcontainer = $("<div />")
                  .attr({ id: "multiviewItem" + DomSafeID(data) })
                  .appendTo(element);
                let iType: itemTypes;
                new TDFRequest({ url: `/core/user/GetItemTypeFromID/`, data: { itemid: data } }).MakeRequest().done(function (response) {
                  if (response) {
                    navigator.CurrentType = response;
                  }
                  DisplayInfo(response).done(function (info) {
                    if (navigator.NavForm.Instance.option("title").indexOf(info.DisplayName) < 0 && navigator.CurrentType !== itemTypes.itemUnknown) {
                      navigator.NavForm.setTitle("Navigator - " + info.DisplayName);
                    }

                    // TODO:check this
                    createitem(response, {
                      ItemID: data,
                      ItemType: response,
                      DisplayName: info.DisplayName,
                      TypeName: info.TypeName,
                      IsNavigatorItem: true,
                      Navigator: navigator,
                      Options: info.Options
                    }, false, false, newItem => {
                      navigator.CurrentItem = newItem;
                      navigator.AddItemToCache();

                      itemcontainer.append($("<div />").attr("id", navigator.CurrentItem.ToolbarID))
                        .append($("<div />").attr("id", navigator.CurrentItem.ContentID)
                          .append($("<div />").addClass("tdfitemtabs").attr({ id: navigator.CurrentItem.UpperTabsID })
                          )
                          .append($("<div />").addClass("tdfitemtabs").attr({ id: navigator.CurrentItem.LowerTabsID }))
                        );
                      navigator.CurrentItem.Initialize(true).done(function () {
                        navigator.CurrentItem.Toolbar = $("#" + navigator.CurrentItem.ToolbarID).dxToolbar({
                          dataSource: navigator.CurrentItem.ToolbarItems()
                        }).dxToolbar("instance");
                        navigator.CurrentItem.RenderControlGroups().done(() => {
                          d.resolve();
                        });
                      });
                      if (!$("#thenav").length) {
                        navigator.CreatePagination();
                      }
                    });
                  });
                });
              }
            } else {
              const date = new Date();
              const time = date.getTime();
              DisplayInfo(itemTypes.itemProduct).done(function (info) {
                if (navigator.NavForm.Instance.option("title").indexOf(info.DisplayName) < 0 && navigator.CurrentType !== itemTypes.itemUnknown) {
                  navigator.NavForm.setTitle("Navigator - " + info.DisplayName);
                }
                //TODO:Fix This and checkit
                createitem(itemTypes.itemProduct, {
                  ItemType: itemTypes.itemProduct,
                  DisplayName: info.DisplayName,
                  TypeName: info.TypeName,
                  IsNavigatorItem: true,
                  Navigator: navigator,
                  Product: navigator.IDList[index],
                  Time: time,
                  Options: info.Options
                }).done(newitem => {
                  navigator.CurrentItem = newitem;

                  const itemcontainer = $("<div />").attr({ id: navigator.CurrentItem.ContentID + time }).appendTo(element);
                  navigator.CurrentItem.Initialize(true).done(() => { d.resolve(); });
                  if (!$("#thenav").length) {
                    navigator.CreatePagination();
                  }
                })
              });
            }
          },
          onSelectionChanged(e) {
            navigator.CurrentItem = navigator.GetItemFromCache(e.addedItems[0]);
            if (navigator.CurrentItem && navigator.CurrentItem.DisplayName) {
              navigator.NavForm.setTitle(
                "Navigator - " + navigator.CurrentItem.DisplayName
              );
              // if (!$("#thenav").length) {
              //    navigator.CreatePagination();
              // }
            }
          }
        };
        navigator.MultiView = $("#multiviewContainer")
          .dxMultiView(opts)
          .dxMultiView("instance");
        // navigator.CreatePagination();
      });
    });
    return d.promise();
  }
  public CreatePagination() {
    const navigator = this;
    //  let header = navigator.NavForm.getModalHeader();
    const nav = $("<div id='thenav' />").addClass("text-center");
    const navlist = $("<ul class='pagination ' />").appendTo(nav);

    // If not device
    if (!GetDevice().isDevice) {
      // Previous round (shows only when next round is clicked)
      navlist.append(
        $("<li/ >")
          .addClass("previous-round hidden page-item")
          .append($("<div class='page-link' />").text("<<"))
      );
    }

    // Previous Button
    navlist.append($("<li class='page-item' />").append($("<div class='page-link' />").text("Back")));

    if (!GetDevice().isDevice) {
      // Calculate number of sets to display
      let currentSet = 1;
      // First item in list
      navlist.append(
        $("<li/ >")
          .addClass("nav-set-1 page-item active")
          .append(
            $("<div />").text("1")
              .addClass("page-link")
          )
      );
      // Add remaining items from list
      $.each(navigator.IDList, function (k: number, v) {
        if (k > 0 && k < 5) {
          navlist.append(
            $("<li/ >")
              .addClass("nav-set-1 page-item")
              .append($("<div class='page-link' />").text(k + 1))
          );
        } else if (k >= 5) {
          // What set are we on?
          currentSet = Math.floor(k / 5 + 1);
          const setClass = "nav-set-" + currentSet + " hidden page-item";
          // Add set number to class
          navlist.append(
            $("<li/ >")
              .addClass(setClass)
              .append($("<div class='page-link' />").text(k + 1))
          );
        }
      });
    }

    // Next button
    navlist.append($("<li class='page-item' />").append($("<div class='page-link' />").text("Next")));

    if (!GetDevice().isDevice) {
      // Next round button
      if (navigator.IDList.length > 5) {
        navlist.append(
          $("<li/ >")
            .addClass("next-round page-item")
            .append($("<div class='page-link' />").text(">>"))
        );
      }
    }

    function moveNavigator(current, direction) {
      if (navigator.CurrentType !== itemTypes.itemProduct) {
        if (navigator.CurrentItem.Dirty) {
          new Notification({
            message:
              "The current " +
              navigator.CurrentItem.DisplayName +
              " must be saved!",
            type: "warning",
            displayTime: 5000
          });
          return;
        }
      }
      // This will give us the current set, and previous set
      let currentSet = Math.ceil((current + 1) / 5);
      const previousSet = currentSet - 1;
      const previousSetItem = current - 5 - (current - 5) % 5;
      const nextSet = currentSet + 1;
      const nextSetItem = current + 5 - (current + 5) % 5;
      if (direction === "Back" || direction === "<<") {
        if (direction === "Back") {
          if (
            parseInt(navigator.MultiView.option("selectedIndex") as string) !==
            0
          ) {
            // hide current set and show last set if at first item of set
            if (
              (parseInt(navigator.MultiView.option("selectedIndex") as string) +
                1) %
              5 ===
              1
            ) {
              $(".nav-set-" + currentSet).addClass("hidden");
              $(".nav-set-" + previousSet).removeClass("hidden");
              if (previousSet > 1) {
                $(".previous-round").removeClass("hidden");
              }
              currentSet--;
              if (currentSet < 2) {
                $(".previous-round").addClass("hidden");
              }
            }
            const Last =
              parseInt(navigator.MultiView.option("selectedIndex") as string) -
              1;
            if (Last >= 0 && Last < navigator.IDList.length) {
              navigator.MultiView.option("selectedIndex", Last);
            }
          }
        } else {
          // hide current set and show next set
          $(".nav-set-" + currentSet).addClass("hidden");
          $(".nav-set-" + previousSet).removeClass("hidden");
          if (previousSet == 1) {
            $(".previous-round").addClass("hidden");
          }
          // Select first item in next set
          if (
            previousSetItem >= 0 &&
            previousSetItem < navigator.IDList.length
          ) {
            navigator.MultiView.option("selectedIndex", previousSetItem);
            currentSet--;
          }
        }
        // If not at the last set, show >> button
        const lastSet = Math.ceil(navigator.IDList.length / 5);
        if (currentSet != lastSet) {
          $(".next-round").removeClass("hidden");
        }
      } else if (direction === "Next" || direction === ">>") {
        if (direction === "Next") {
          const next =
            parseInt(navigator.MultiView.option("selectedIndex") as string) + 1;
          if (next >= 0 && next < navigator.IDList.length) {
            if (
              (parseInt(navigator.MultiView.option("selectedIndex") as string) +
                1) %
              5 ===
              0
            ) {
              $(".nav-set-" + currentSet).addClass("hidden");
              $(".nav-set-" + nextSet).removeClass("hidden");
              if (nextSet > 1) {
                $(".previous-round").removeClass("hidden");
              }
              currentSet++;
            }

            navigator.MultiView.option("selectedIndex", next);
          }
        } else {
          // hide current set and show next set
          $(".nav-set-" + currentSet).addClass("hidden");
          $(".nav-set-" + nextSet).removeClass("hidden");
          if (nextSet > 1) {
            $(".previous-round").removeClass("hidden");
          }
          // Select first item in next set
          if (nextSetItem >= 0 && nextSetItem < navigator.IDList.length) {
            navigator.MultiView.option("selectedIndex", nextSetItem);
            currentSet++;
          }
        }
        // If at the last set, hide >> button
        const lastSet = Math.ceil(navigator.IDList.length / 5);
        if (currentSet === lastSet) {
          $(".next-round").addClass("hidden");
        }
      }
    }
    nav.on("click", function (e) {
      if (navigator.CurrentType !== itemTypes.itemProduct) {
        if (navigator.CurrentItem.Dirty) {
          new Notification({
            message:
              "The current " +
              navigator.CurrentItem.DisplayName +
              " must be saved!",
            type: "warning",
            displayTime: 5000
          });
          return;
        }
      }
      if (parseInt(e.target.textContent)) {
        const next = parseInt(e.target.textContent) - 1;
        if (next >= 0 && next < navigator.IDList.length) {
          navigator.MultiView.option("selectedIndex", next);
          $("#thenav")
            .find(".active")
            .removeClass("active");
          $(e.target.parentElement).addClass("active");
        }
      }
      switch (e.target.textContent) {
        case "Back":
          moveNavigator(
            parseInt(navigator.MultiView.option("selectedIndex") as string),
            "Back"
          );
          break;
        case "Next":
          moveNavigator(
            parseInt(navigator.MultiView.option("selectedIndex") as string),
            "Next"
          );
          break;
        case ">>":
          moveNavigator(
            parseInt(navigator.MultiView.option("selectedIndex") as string),
            ">>"
          );
          break;
        case "<<":
          moveNavigator(
            parseInt(navigator.MultiView.option("selectedIndex") as string),
            "<<"
          );
          break;
      }
      $("#thenav")
        .find(".active")
        .removeClass("active");
      const a = $("#thenav").find(
        "li:contains(" + (navigator.MultiView.option("selectedIndex") + 1) + ")"
      );
      if (a.length > 1) {
        $.each(a, function (k, v) {
          if (
            parseInt(v.innerText) ===
            navigator.MultiView.option("selectedIndex") + 1
          ) {
            $(v).addClass("active");
          }
        });
      } else {
        a.addClass("active");
      }
    });
    return nav;
    // $(header.children()[0]).append(nav);
  }
  public MoveNavigatorAfterRemove(targetIndex) {
    const navigator = this;
    const currentSet = 1;
    const targetSet = Math.ceil((targetIndex + 1) / 5);
    $(".nav-set-" + currentSet).addClass("hidden");
    $(".nav-set-" + targetSet).removeClass("hidden");
    // navigator.MultiView.option("selectedIndex", targetIndex);
    $("[class*=nav-set]:contains(1)").removeClass("active");
    $("[class*=nav-set]:contains(" + (targetIndex + 1) + ")").addClass(
      "active"
    );
    const lastSet = Math.ceil(navigator.IDList.length / 5);
    if (targetSet === lastSet) {
      $(".next-round").addClass("hidden");
    }
    if (targetSet !== 1) {
      $(".previous-round").removeClass("hidden");
    }
  }
  public RemoveCurrent() {
    const navigator = this;
    if (!navigator.IsSafeClose) {
      return;
    }
    let currentIndex = parseInt(navigator.MultiView.option(
      "selectedIndex"
    ) as string);
    navigator.IDList.splice(currentIndex, 1);
    navigator.NavForm.close();
    if (navigator.IDList.length > 1) {
      navigator.IsSafeClose = false;
    }
    if (navigator.IDList.length) {
      // remove the element from the dom so it can be recreated
      $("#tdfitemitemnav").remove();
      // TODO: remove  the cache .... maybe don't do this  maybe remove a single item??
      delete navigator.ItemCache;
      navigator.CurrentItem = null;
      if (currentIndex >= navigator.IDList.length) {
        currentIndex--;
      }
      navigator.SelectedIndex = currentIndex;
      navigator.Render().done(() => {
        navigator.MoveNavigatorAfterRemove(currentIndex);
      });
    }
  }
  public RemoveAll() {
    const navigator = this;
    if (!navigator.IsSafeClose) {
      return;
    }
    // navigator.NavForm.close();
    $("#tdfitemitemnav").remove();
    delete navigator.ItemCache;
  }
}
