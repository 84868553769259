import { GridToolbar } from "../components/grids/gridtoolbars/gridtoolbar";
import { itemTypes, GridContainerTypes } from "../enums/enums";
import { Grid } from "./grid";
import { GridSetup } from "../services/grid/helpers/gridsetup";
import {
  CompanyLoaded,
  LoadCompany,
  GetItemTypeDisplayInfo
} from "../infrastructure/context";
import { TDFRequest } from "../services/request";
import {
  IDisplayInfo,
  IGridDataResponseMeta,
  ILinkedItemsArgs,
  IGridDataResponse
} from "../interfaces/interfaces";
import { GetDevice } from "../util/allutils";
import { Preferences } from "../infrastructure/user/preferences";

import dxDataSource from "devextreme/data/data_source";
import dxArrayStore from "devextreme/data/array_store";
export class LinkedItems {
  Toolbar: GridToolbar;
  GridContainer: string;
  ToolbarContainer: string;
  private ToolbarModel?: any;
  GridItemType: itemTypes;
  TDFContainerType: GridContainerTypes;
  LinkedItemsGrid: Grid;
  LinkedItemsGridContainer: string;
  RequestModel: GridSetup.LinkedItemsRequestModel;
  ContainerItemID: string;
  ContainerItemType: itemTypes;
  GridHeight: number;
  HasToolbar: boolean = true;
  FilterExpression: string;
  ItemTypeSwitcher: DevExpress.ui.dxSelectBox;
  ServerPageSize: number = 100;
  Meta: IGridDataResponseMeta = null;
  CustomViewGUID: string;
  constructor(args: ILinkedItemsArgs) {
    let linkeditems = this;
    $.each(args, function(k, v) {
      linkeditems[k] = v;
    });
    if (!CompanyLoaded) {
      LoadCompany().done(function() {
        linkeditems.GetToolbar();
      });
    }

    linkeditems.FilterExpression =
      args && args.DataFilter ? args.DataFilter : "";
  }

  GetToolbar() {
    let linkeditems = this;
    let d = $.Deferred();
    let request = new TDFRequest({
      url: "/core/user/GetToolbarInfo/",
      type: "GET",
      data: {
        type: linkeditems.GridItemType,
        containertype: linkeditems.TDFContainerType
      }
    });
    request.MakeRequest().done(function(response) {
      // response.OnViewChanged = linkeditems.ViewChangedEvent.bind(linkeditems);
      linkeditems.ToolbarModel = response;

      linkeditems.CreateToolbar().done(function() {
        d.resolve();
      });
    });
    return d.promise();
  }

  CreateToolbar() {
    let linkeditems = this;
    let d = $.Deferred();
    if (linkeditems.CustomViewGUID)
      linkeditems.ToolbarModel.CustomViewGUID = linkeditems.CustomViewGUID;
    linkeditems.Toolbar = new GridToolbar(linkeditems.ToolbarModel);

    linkeditems.Toolbar.ToolbarContainerID = linkeditems.ToolbarContainer;
    linkeditems.Toolbar.ContainerItemID = linkeditems.ContainerItemID;
    //"GetViewableLinkedItems/";
    linkeditems.Toolbar.ExtraItems = [
      {
        location: "before",
        template: function(
          itemData: any,
          itemIndex: number,
          itemElement: JQuery
        ) {
          let request = new TDFRequest({
            url: "/core/user/GetViewableLinkeditems/",
            data: { type: linkeditems.ContainerItemType }
          });
          $.when(request.MakeRequest(), GetItemTypeDisplayInfo()).done(function(
            viewable: any,
            all: Array<IDisplayInfo>
          ) {
            let items = $.map(all, function(v, k) {
              return $.inArray(v.TypeNum, viewable) > -1 ? v : null;
            });
            linkeditems.ItemTypeSwitcher = $("<div />")
              .dxSelectBox({
                dataSource: new dxDataSource({
                  store: new dxArrayStore({
                    data: items,
                    key: "TypeNum"
                  }),
                  sort: "DisplayName"
                }),
                searchEnabled: true,
                displayExpr: "DisplayNamePlural",
                valueExpr: "TypeNum",
                value: linkeditems.GridItemType,
                onValueChanged: function(e: any) {
                  linkeditems.RequestModel.RequestArgs.ItemType = e.value;
                  linkeditems.GridItemType = e.value;
                  if (linkeditems.LinkedItemsGrid) {
                    linkeditems.LinkedItemsGrid.GridObject.option(
                      "dataSource",
                      []
                    );
                  }
                  linkeditems.GetToolbar();
                }
              })
              .dxSelectBox("instance");
            itemElement.append(linkeditems.ItemTypeSwitcher.element());
          });
        }
      }
    ];

    linkeditems.Toolbar.MakeGrid = linkeditems.MakeGrid.bind(linkeditems);

    if (!linkeditems.Toolbar.CurrentView && linkeditems.Toolbar.DefaultView)
      linkeditems.Toolbar.CurrentView = linkeditems.Toolbar.DefaultView;
    linkeditems.CreateRequest();
    linkeditems.Toolbar.GridDataRequestModel = linkeditems.RequestModel;
    linkeditems.Toolbar.RenderAndLoad();

    return d.promise(d.resolve());
  }

  BuildPagingInfo(): string {
    let linkeditems = this;
    let infoText: string = "";
    if (linkeditems.Meta) {
      let serverPageSize: number = linkeditems.ServerPageSize;
      let serverPageCount: number =
        linkeditems.Meta.TotalPages > 0 ? linkeditems.Meta.TotalPages : 1;
      let endRecordCount: number =
        (linkeditems.Meta.CurrentPage - 1) * serverPageSize + serverPageSize <
        linkeditems.Meta.TotalRecords
          ? (linkeditems.Meta.CurrentPage - 1) * serverPageSize + serverPageSize
          : linkeditems.Meta.TotalRecords;
      let currentPageRecordInfo: string =
        linkeditems.Meta.CurrentPage > 1
          ? (linkeditems.Meta.CurrentPage - 1) * serverPageSize +
            1 +
            "-" +
            endRecordCount
          : linkeditems.Meta.CurrentPage + "-" + serverPageSize;
      let totalCount: number = linkeditems.Meta.TotalRecords; // > 0 ? metaInfo.TotalRecords : results.Data.length;

      infoText =
        linkeditems.Meta.TotalRecords >= serverPageSize
          ? "Records: (" +
            currentPageRecordInfo +
            ") Of " +
            totalCount +
            " Total Records "
          : "Total Records " + totalCount;
    }

    return infoText;
  }

  LoadGrid(paging = false) {
    let linkeditems = this;
    let d = $.Deferred();
    if (!linkeditems.RequestModel || (linkeditems.HasToolbar && !paging)) {
      linkeditems.CreateRequest();
    }

    let request = new TDFRequest({
      url: linkeditems.RequestModel.Path,
      data: linkeditems.RequestModel.RequestArgs,
      type: "GET"
    });
    if (linkeditems.RequestModel.RequestArgs.GUID) {
      request.MakeRequest().done(function(response) {
        linkeditems.MakeGrid(response);
        d.resolve();
      });
    }
    return d.promise();
  }

  RefreshCurrentGrid() {
    let linkeditems = this;
    linkeditems.Toolbar.RefreshCurrentGrid(null);
  }

  get _RequestModel() {
    let linkeditems = this;
    return linkeditems.RequestModel;
  }

  MakeGrid(response) {
    let linkeditems = this;
    response.GridContainer = $("#" + linkeditems.GridContainer);
    linkeditems.Meta = response.Meta;
    let pagingInfo = linkeditems.BuildPagingInfo();
    if (GetDevice().isDevice) {
      // $("#" + linkeditems.GridContainer).parent().dxScrollView({ height: 500 });
      linkeditems.GridHeight = $(window).innerHeight() - 50;
    }

    let gridOptions: DevExpress.ui.dxDataGridOptions = {
      height: linkeditems.GridHeight || $(window).innerHeight() - 300,
      columnAutoWidth: true,
      pager: {
        showInfo: true,
        showPageSizeSelector: true,
        allowedPageSizes: [10, 15, 20, 30, 50, 100],
        showNavigationButtons: true,
        visible: true,
        infoText: pagingInfo
      },
      onContentReady(e) {
        let btnNextPage = e.element.find(".dx-next-button");
        let btnPrevPage = e.element.find(".dx-prev-button");
        let pageSizes = e.element.find(".dx-page-sizes");
        let grid = e.component as DevExpress.ui.dxDataGrid;

        $(btnNextPage).off("click");
        $(btnNextPage).on("click", function(event) {
          if ($(event.currentTarget).hasClass("dx-button-disable")) return;
          if (grid.pageIndex() === grid.pageCount() - 1) {
            event.stopPropagation();
            linkeditems.RequestModel.RequestArgs.page += 1;
            linkeditems.LoadGrid(true);
          }
        });

        $(btnPrevPage).off("click");
        $(btnPrevPage).on("click", function(event) {
          if ($(event.currentTarget).hasClass("dx-button-disable")) return;
          if (grid.pageIndex() === 0) {
            if (linkeditems.RequestModel.RequestArgs.page > 1) {
              linkeditems.RequestModel.RequestArgs.page -= 1;
              linkeditems.LoadGrid(true);
            }
          }
        });

        $(pageSizes).on("dxclick", function(e) {
          Preferences.SetPreference(
            "ClientGridPageSize",
            $(e.target).text(),
            "TDFMobile"
          );
        });

        if ($(btnNextPage).hasClass("dx-button-disable")) {
          if (
            linkeditems.RequestModel.RequestArgs.page <
            linkeditems.Meta.TotalPages
          ) {
            btnNextPage.removeClass("dx-button-disable");
          }
        }

        if ($(btnPrevPage).hasClass("dx-button-disable")) {
          if (linkeditems.RequestModel.RequestArgs.page > 1) {
            btnPrevPage.removeClass("dx-button-disable");
          }
        }
      }
    };

    if (
      linkeditems.GridItemType &&
      (linkeditems.GridItemType === itemTypes.itemAllItems ||
        linkeditems.GridItemType === itemTypes.itemAllParents)
    ) {
      let res: IGridDataResponse = response;
      if (res.GridView && res.GridView.Layout && res.GridView.Layout.Columns) {
        let itypecol = res.GridView.Layout.Columns.map(k => {
          return k.dataField.toLowerCase() === "itemtype" ? k : null;
        }).filter(i => {
          return i;
        });
        if (itypecol.length) {
          itypecol[0].visible = true;
          itypecol[0].cellTemplate = (container: JQuery, options: any) => {
            container.append(
              $("<span />").addClass(
                "tdfitem" + parseInt(options.data.ItemType)
              )
            );
          };
        }
      }
    }

    linkeditems.LinkedItemsGrid = new Grid(
      response,
      {
        GridContainer: $("#" + linkeditems.GridContainer),
        GridToolbar: linkeditems.Toolbar
      },
      gridOptions,
      linkeditems,
      e => {
        linkeditems.Toolbar.SelectionChanged(e);
      }
    );

    linkeditems.LinkedItemsGrid.ContainerItemID = linkeditems.ContainerItemID;
    linkeditems.LinkedItemsGrid.Render();
    if (linkeditems.Toolbar) {
      linkeditems.Toolbar.CurrentGrid = linkeditems.LinkedItemsGrid;
    }
  }

  CreateRequest(viewGUID?) {
    let linkeditems = this;
    let args = new GridSetup.GridRequestArgs();

    args.GUID = viewGUID
      ? viewGUID
      : linkeditems.Toolbar
        ? linkeditems.Toolbar.DefaultView
        : "";
    args.page = 1;
    args.pageSize = 100;
    args.IncludeGridViewInformation = true;
    args.ContainerID = linkeditems.ContainerItemID;
    args.filterExpression = linkeditems.FilterExpression;
    args.ItemType = linkeditems.GridItemType;

    linkeditems.RequestModel = new GridSetup.LinkedItemsRequestModel(args);
    if (linkeditems.Toolbar) {
      linkeditems.Toolbar.GridDataRequestModel = linkeditems.RequestModel;
    }
  }
}

//    export class LinkItems {
//	item: IItemInfo;
//	search: Search;
//	recent: Recent;
//	fav: Favorites;
//	SelectedItems: any;
//	DFDS: JQueryDeferred<any>[];

//	constructor(args: any) {
//	    let linkItems = this;
//	    linkItems.item = args;

//	}

//	GetLinkPrompt(singleItem: IItemInfo, relevantType) {
//	    let linkItems = this;
//	    let SRF: SearchRecFav = new SearchRecFav(relevantType, [relevantType], relevantType, true);

//	    let isDevice =GetDevice().isDevice;
//	    let time = SRF.Time;

//	    SRF.GetSRFDialog().done((selected) => {

//		if (!selected.length) return;
//		let childList = [];
//		$.each(selected, function (k, v) {
//		    childList.push(v["TDF GUID"]);
//		});

//		new TDFRequest({ url: "/core/user/GetItemTypeFromID/", data: { itemid: childList[0] } }).MakeRequest().done(function (response1: IWebResponse) {
//		    let request;
//		    if (relevantType !== 999) {
//			request = new TDFRequest({ url: "/item/AddItemLink/", data: { parentID: singleItem.ItemId, parentType: singleItem.ItemType, children: childList, childrentype: relevantType }, type: "POST" });
//		    }
//		    else {
//			request = new TDFRequest({ url: "/item/AddItemLink/", data: { parentID: childList[0], parentType: response1.Data, children: singleItem.ItemId, childrentype: singleItem.ItemType }, type: "POST" });
//		    }

//		    request.MakeRequest().done(function (response2: IWebResponse) {
//			let message = response2.Valid ? "Items Linked." : response2.Message;
//			new Notification({ message: message, type: response2.Valid ? "success" : "error", shading: !response2.Valid });
//			response2.Valid ? SRF.Dialog.close() : "";
//		    });
//		});
//	    });

//	}

//    }
