import { GridSetup } from "../services/grid/helpers/gridsetup";
import {
  ICenterItem,
  IGridDataResponseMeta,
  IGridViewMenuItem,
  IGridToolbarModel,
  IGridDataResponse,
  IGridInfo
} from "../interfaces/interfaces";
import { GridContainerTypes, CenterType } from "../enums/enums";
import { StickyMenu } from "../components/controls/slidemenu";
import * as Context from "../infrastructure/context";
import { TDFRequest } from "../services/request";
import { BasicTabToolbar } from "../components/grids/gridtoolbars/basictabtoolbar";
import { BSIGrids } from "../enums/bi/enums";
import { Preferences } from "../infrastructure/user/preferences";
import { Dialog } from "../components/dialogs/dialog";
import { GetPreference } from "../infrastructure/user/getpreference";
import { enumGridDesign } from "../enums/admin/gridmanagement/enums";
import {
  ToggleToolbarButtonVisibility,
  GetDevice,
  DomSafeID,
  RequestBuilder
} from "../util/allutils";
import dxDataSource from "devextreme/data/data_source";
import dxArrayStore from "devextreme/data/array_store";
import { ColumnTemplateHandler } from "./gridwithoutviews";
import { GridToolbar } from "../components/grids/gridtoolbars/gridtoolbar";
import { Grid } from "./grid";
export abstract class CustomCenterTab {
  MyCenter: CenterBase;
  constructor(centerObject: CenterBase) {
    let customTab = this;
    customTab.MyCenter = centerObject;
  }
  abstract RenderTab(data, index, element);
  abstract ClearCachedData();

  RefreshTab() {
    let theCustomTab = this;
    theCustomTab.ClearCachedData();
    theCustomTab.MyCenter.RefreshTabDataSource();
  }
}

export abstract class CenterBase {
  //#region Must Override
  abstract PreferenceModule: string;
  abstract StickyMenuPinPreferenceProperty: string;
  abstract TabsByTypePreferenceProperty: string;
  abstract RenderMenu();
  abstract GetRequestModel(viewGUID?: string): GridSetup.BaseGridRequestModel;

  CustomizeMainPanel() {
    //To Override if needed
  }

  ClearCustomTabData(item: ICenterItem) {
    // To Override for tabs that don't have grids.
  }
  ColumnTemplateHandler: ColumnTemplateHandler = function(col: any) {};

  //#endregion

  //#regionOther Properties
  protected readonly TDFContainerType: GridContainerTypes = GridContainerTypes.Unknown;
  /**
   *  InfoCenter = 0,   Bi = 1,   AccountBi = 2,    OppBi = 3,    ContactBi = 4,      VendorBi = 5
   */
  CenterType: CenterType;

  StickyMenu: StickyMenu;
  // StickyMenuHeaderText: string = "";

  //#region ContainerIDs
  CenterContainerID: string = "";
  MainAreaID: string = "";
  MainAreaHeaderContainerID: string;
  MainAreaBodyContainerID: string;
  ToolbarContainerID: string;
  TabsContainerID: string;
  SlideMenuID: string = "";
  SlideMenuStickyBtnsID: string = "";
  //#endregion

  ToolbarContainerElement: JQuery;
  TabPanel: DevExpress.ui.dxTabPanel;
  TabDataSource: DevExpress.data.DataSource;
  Items: Array<ICenterItem> = [];
  RequestType: string = "GET";

  /**
   *The Currently Active Type in the Center. For example (Accounts)
   */
  CurrentSelectedItem: ICenterItem;

  /**
   *The Current toolbar that is active and visible.
   */
  // CurrentToolbar:BaseToolbar;

  /**
   *Used for paging to know if we should continue to show the next button.
   */
  GridViewMetaCache: IGridDataResponseMeta;
  /**
   *This tells the center if it should only show tabs for the CurrentSelectedItem or all open tabs regardless of type .
   */
  TabsByType: boolean = false;

  /**
   *This tells the grid to sort the selected column on the server if true, instead of just sorting the data that currently on the client.
   */
  private get ServerSort(): boolean {
    return Preferences.GetPreference("SortOnServer", "WebGrid") != "0";
  }
  protected _RequestModel: GridSetup.BaseGridRequestModel;
  protected CustomName: string = "";
  protected CustomIDString: string = "";
  UseBadges: boolean = true;
  //#endregion

  constructor(centerType: CenterType, customName?: string) {
    let iCenter = this;
    iCenter.CenterType = centerType;

    if (customName) {
      iCenter.CustomName = customName;
      iCenter.CustomIDString = "_" + iCenter.CustomName;
    }

    //  LoadGlobalization();
    alert("LoadGlobalization not implemented");
    Context.LoadCompany();

    iCenter.SetContainerIDs();

    return iCenter;
  }

  private SetContainerIDs() {
    let iCenter = this;
    iCenter.CenterContainerID =
      CenterType[iCenter.CenterType] + iCenter.CustomIDString;
    iCenter.MainAreaID =
      CenterType[iCenter.CenterType] + iCenter.CustomIDString + "_MainArea";
    iCenter.MainAreaHeaderContainerID =
      CenterType[iCenter.CenterType] +
      iCenter.CustomIDString +
      "_MainAreaHeader";
    iCenter.MainAreaBodyContainerID =
      CenterType[iCenter.CenterType] + iCenter.CustomIDString + "_MainAreaBody";
    iCenter.ToolbarContainerID =
      CenterType[iCenter.CenterType] + iCenter.CustomIDString + "_Toolbar";
    iCenter.ToolbarContainerElement = $("#" + iCenter.ToolbarContainerID);
    iCenter.TabsContainerID =
      CenterType[iCenter.CenterType] +
      iCenter.CustomIDString +
      "_tabs_container";
    iCenter.SlideMenuID =
      CenterType[iCenter.CenterType] + iCenter.CustomIDString + "_SlideMenu";
    iCenter.SlideMenuStickyBtnsID =
      CenterType[iCenter.CenterType] +
      iCenter.CustomIDString +
      "_StickyMenuBtns";
  }

  Initialize(): JQueryPromise<any> {
    let theCenter = this;
    let dfd: JQueryDeferred<any> = $.Deferred();

    return dfd.promise(dfd.resolve(theCenter));
  }

  /**
   * Handle the view dropdown value changed event.
   * @param e
   */
  ViewChangedEvent(e) {
    let theCenter = this;
    if (!theCenter.TabDataSource) {
      theCenter.TabDataSource = theCenter.TabPanel.option("dataSource");
    }
    let store: DevExpress.data.Store = theCenter.TabDataSource.store();
    if (!e.value || e.value === "_") {
      return;
    } else {
      if (
        theCenter.CurrentSelectedItem.CurrentView &&
        e.value === theCenter.CurrentSelectedItem.CurrentView.ViewGUID
      ) {
        /**
	  if the values match then we probably just switched types and the view is the same, but we need to keep the tab in sync with the view .
	  */
        if (
          (theCenter.CurrentSelectedItem.Toolbar as GridToolbar)
            .CurrentScope === theCenter.CurrentSelectedItem.CurrentView.Scope
        ) {
          if (theCenter.CurrentSelectedItem.LoadedGrids.length) {
            theCenter.TrySetActiveTab(e.value);
            return;
          }
        }
      }
    }
    store.update(e.value, { Active: true, Load: true });
    if (
      theCenter.CurrentSelectedItem.CurrentView &&
      theCenter.CurrentSelectedItem.CurrentView.ViewGUID !== e.value
    ) {
      store.update(theCenter.CurrentSelectedItem.CurrentView.ViewGUID, {
        Active: false
      });
    }
    theCenter.RefreshTabDataSource().done(() => {
      theCenter.TrySetActiveTab(e.value);
    });
  }

  /**
   * This fires when an item in the menu is clicked. For example the user clicked Accounts in the info center.
   * @param e
   */
  MenuClick(e) {
    let theCenter = this;
    if (e.target.className.indexOf("fa-times") > -1) return;

    // TODO:  the below e.data.ItemType || e.data.Type is needed for now since Info Center and BI generate their menus slightly differently.
    let item = theCenter.GetItem(e.data.ItemType || e.data.Type);

    if (
      theCenter.CurrentSelectedItem &&
      theCenter.CurrentSelectedItem.Type !== e.data.ItemType
    ) {
      theCenter.CurrentSelectedItem.jQMenuItem.removeClass("activetype");
    }

    theCenter.CurrentSelectedItem = item;
    theCenter.CurrentSelectedItem.jQMenuItem.addClass("activetype");

    if (!theCenter.TabPanel) {
      theCenter.RenderTabPanel();
    }

    // theCenter.LoadPanel();
    theCenter.GetToolbar().done(function() {
      theCenter.CreateToolbar();
      // if (theCenter.TabPanel) {
      //   theCenter.RefreshTabDataSource();
      // }
      if (theCenter.CurrentSelectedItem.UsesDefaultGridBehavior) {
        if (item.CurrentView && item.CurrentView.Grid) {
          (<GridToolbar>theCenter.CurrentSelectedItem.Toolbar).CurrentGrid =
            item.CurrentView.Grid;

          let length = item.CurrentView.Grid.GridObject.getSelectedRowsData()
            .length;
          //.CurrentGrid ? (theToolbar.CurrentGrid.GridObject.getSelectedRowsData().length) : 0;

          ToggleToolbarButtonVisibility(
            theCenter.CurrentSelectedItem.Toolbar.ToolbarContainerID,
            length
          );
        } else {
          ToggleToolbarButtonVisibility(
            theCenter.CurrentSelectedItem.Toolbar.ToolbarContainerID,
            0
          );
        }
      }
    });
  }
  /**
   * looks in the TabPanel items for the passed in viewGUID or theCenter.CurrentSelectedItem.CurrentView.ViewGUID
   * and sets the TabPanel selected index if needed
   * @param viewGUID
   */
  TrySetActiveTab(viewGUID?: string) {
    let theCenter = this;
    let idx = -1;
    $.each(
      theCenter.TabPanel.option("items"),
      (index: number, val: IGridViewMenuItem) => {
        if (
          val.ViewGUID ===
          (viewGUID
            ? viewGUID
            : theCenter.CurrentSelectedItem.CurrentView.ViewGUID)
        ) {
          idx = index;
        }
      }
    );
    if (idx > -1) {
      if (parseInt(theCenter.TabPanel.option("selectedIndex")) !== idx) {
        theCenter.TabPanel.option("selectedIndex", idx);
        theCenter.CurrentSelectedItem.CurrentTabIndex = idx;
      }
    }
  }

  /**
   * (if we have not done so already for this itemtype) Make the request to the server to get the toolbar model for the selected itemtype
   * Else return the toolbar model for this itemtype
   */
  GetToolbar() {
    let theCenter = this;
    let dfd = $.Deferred();

    // check toolbar data list
    if (theCenter.CurrentSelectedItem.ToolbarModel) {
      dfd.promise(dfd.resolve(theCenter));
    } else {
      let request = new TDFRequest({
        url: "/core/user/GetToolbarInfo/",
        type: "GET",
        data: {
          type: theCenter.CurrentSelectedItem.Type,
          containertype: theCenter.TDFContainerType
        }
      });
      request.MakeRequest().done(function(response: IGridToolbarModel) {
        theCenter.CurrentSelectedItem.ToolbarModel = response;
        dfd.resolve(theCenter);
      });
    }

    return dfd.promise(theCenter);
  }

  /**
   * Filters then loads the tabpanel datasource
   */
  RefreshTabDataSource() {
    let theCenter = this;
    let dfd = $.Deferred();
    if (theCenter.TabPanel) {
      if (!theCenter.TabDataSource) {
        theCenter.TabDataSource = theCenter.TabPanel.option("dataSource");
      }
      theCenter.TabDataSource.filter(theCenter.GetDefaultFilter());
      theCenter.TabDataSource.load().done(() => {
        dfd.resolve();
      });
    } else {
      console.warn(
        "RefreshTabDataSource was called, but the TabPanel is " +
          typeof theCenter.TabPanel
      );
      return dfd.promise(dfd.resolve());
    }
    return dfd.promise();
  }

  /**
   * Clears the Currently loaded toolbar and calls theCenter.CreateGridToolbar || theCenter.CreateBasicToolbar
   */
  CreateToolbar(overridescope?: "Global" | "Personal") {
    let theCenter = this;

    if (theCenter.ToolbarContainerElement.length) {
    } else {
      theCenter.ToolbarContainerElement = $("#" + theCenter.ToolbarContainerID);
    }

    theCenter.ToolbarContainerElement.empty();
    if (theCenter.CurrentSelectedItem.UsesDefaultGridBehavior) {
      theCenter.CreateGridToolbar(overridescope);
    } else {
      theCenter.CreateBasicToolbar();
    }
  }
  /**
   * Used for things that dont use traditional views like info center grids... i think
   */
  CreateBasicToolbar() {
    let theCenter = this;
    let loaded = false;

    theCenter.CurrentSelectedItem.Toolbar = new BasicTabToolbar(
      theCenter.CurrentSelectedItem.ToolbarModel
    );

    theCenter.CurrentSelectedItem.Toolbar.ExtraItems = theCenter.GetExtraTabToolbarItems();
    theCenter.CurrentSelectedItem.Toolbar.ToolbarContainerID =
      theCenter.ToolbarContainerID;
    theCenter.CurrentSelectedItem.Toolbar.Render();

    if (!theCenter.CurrentSelectedItem.ViewList) {
      theCenter.GetAllViews();
      theCenter.AddViewsToTabPanelDataSource(
        !theCenter.CurrentSelectedItem.Toolbar.UsePersonalViews
          ? "Global"
          : "Personal"
      );
      theCenter.CurrentSelectedItem.Toolbar.CurrentView =
        theCenter.CurrentSelectedItem.Toolbar.DefaultView;
    } else {
      loaded = true;
    }

    let viewGUID = theCenter.CurrentSelectedItem.Toolbar.CurrentView;
    let store: DevExpress.data.Store = theCenter.TabDataSource.store();
    store.update(viewGUID, { Active: true, Load: true });

    theCenter.RefreshTabDataSource().done(() => {
      theCenter.TrySetActiveTab(viewGUID);
    });
  }
  /**
   * Combine the Global and personal views into one list to be added to the datasource.. they will be filtered depending on default \ scope \ etc
   */
  protected GetAllViews() {
    let theCenter = this;

    let loadGlobal = !(<GridToolbar>theCenter.CurrentSelectedItem.Toolbar)
      .UsePersonalViews;
    let defaultView = $.grep(
      (<GridToolbar>theCenter.CurrentSelectedItem.Toolbar).GetViews(),
      function(val) {
        return (
          val.ViewGUID ===
          (<GridToolbar>theCenter.CurrentSelectedItem.Toolbar).DefaultView
        );
      }
    );
    if (!theCenter.CurrentSelectedItem.CurrentView) {
      if (defaultView.length) {
        theCenter.CurrentSelectedItem.CurrentView = {
          ViewCategory: defaultView[0].ViewCategory,
          IsDefault: defaultView[0].IsDefault,
          ViewGUID: defaultView[0].ViewGUID,
          ViewName: defaultView[0].ViewName,
          Icon: "dx-icon tdfitem" + theCenter.CurrentSelectedItem.Type,
          ItemType: theCenter.CurrentSelectedItem.Type,
          Scope: loadGlobal ? "Global" : "Personal"
        };
      }
    } else {
      if (defaultView.length) {
        theCenter.CurrentSelectedItem.CurrentView.ViewCategory =
          defaultView[0].ViewCategory;
        theCenter.CurrentSelectedItem.CurrentView.IsDefault =
          defaultView[0].IsDefault;
        theCenter.CurrentSelectedItem.CurrentView.ViewGUID =
          defaultView[0].ViewGUID;
        theCenter.CurrentSelectedItem.CurrentView.ViewName =
          defaultView[0].ViewName;
      }
    }
    let allviews: Array<IGridViewMenuItem> = [];

    $.each(
      (<GridToolbar>theCenter.CurrentSelectedItem.Toolbar).GetGlobalViews(),
      function(key, val) {
        val.Scope = "Global";
        if (val.IsDefault) {
          val.Load = loadGlobal;
        } else {
          val.Load = false;
        }

        if (val.ViewGUID) allviews.push(val);
      }
    );

    $.each(
      (<GridToolbar>theCenter.CurrentSelectedItem.Toolbar).GetPersonalViews(),
      function(key, val) {
        val.Scope = "Personal";
        if (val.IsDefault) {
          val.Load = !loadGlobal;
        } else {
          val.Load = false;
        }

        if (val.ViewGUID) allviews.push(val);
      }
    );

    theCenter.CurrentSelectedItem.ViewList = [];

    $.each(allviews, function(key, val) {
      val.ItemType = theCenter.CurrentSelectedItem.Type;
      val.Icon = "dx-icon tdfitem" + val.ItemType;
      theCenter.CurrentSelectedItem.ViewList.push(val);
    });
    return allviews;
  }
  /**
   * Create the common grid toolbar ...
   * @param overridescope
   */
  CreateGridToolbar(overridescope?: "Global" | "Personal") {
    let theCenter = this;
    // let loaded = false;
    let tempScope;
    if (theCenter.CurrentSelectedItem && theCenter.CurrentSelectedItem.Toolbar)
      tempScope = (theCenter.CurrentSelectedItem.Toolbar as GridToolbar)
        .CurrentScope;

    theCenter.CurrentSelectedItem.Toolbar = new GridToolbar(
      theCenter.CurrentSelectedItem.ToolbarModel
    );

    if (tempScope)
      (theCenter.CurrentSelectedItem.Toolbar as GridToolbar).CurrentScope =
        overridescope || tempScope;
    /*Override the refresh method of the toolbar */
    (<GridToolbar>theCenter.CurrentSelectedItem
      .Toolbar).RefreshCurrentGrid = theCenter.FullRefreshCurrentGrid.bind(
      theCenter
    );
    /*Override the viewchanged event of the toobar*/
    (<GridToolbar>theCenter.CurrentSelectedItem
      .Toolbar).OnViewChanged = theCenter.ViewChangedEvent.bind(theCenter);
    /*get other toolbar buttons*/
    (<GridToolbar>theCenter.CurrentSelectedItem
      .Toolbar).ExtraItems = theCenter.GetExtraTabToolbarItems();

    (<GridToolbar>theCenter.CurrentSelectedItem.Toolbar).ToolbarContainerID =
      theCenter.ToolbarContainerID;
    // (<TDF.GridSetup.Toolbar>theCenter.CurrentSelectedItem.Toolbar).FullScreenID = theCenter.MainAreaID;
    (<GridToolbar>theCenter.CurrentSelectedItem.Toolbar).Render();

    if (!theCenter.CurrentSelectedItem.ViewList) {
      theCenter.GetAllViews();
      theCenter.AddViewsToTabPanelDataSource(
        (<GridToolbar>theCenter.CurrentSelectedItem.Toolbar).UsePersonalViews
          ? "Personal"
          : "Global"
      );
    }

    if (theCenter.CurrentSelectedItem.CurrentView) {
      if (
        theCenter.CurrentSelectedItem.ToolbarModel.GridItemType ===
        theCenter.CurrentSelectedItem.CurrentView.ItemType
      ) {
        (<GridToolbar>theCenter.CurrentSelectedItem
          .Toolbar).ViewSelector.option(
          "value",
          theCenter.CurrentSelectedItem.CurrentView.ViewGUID
        );

        //theCenter.TrySetActiveTab();
      } else {
        theCenter.CurrentSelectedItem.CurrentView = null;
        (<GridToolbar>theCenter.CurrentSelectedItem
          .Toolbar).ViewSelector.option(
          "value",
          (<GridToolbar>theCenter.CurrentSelectedItem.Toolbar).CurrentView ||
            (<GridToolbar>theCenter.CurrentSelectedItem.Toolbar).DefaultView
        );
      }
    } else {
      if (
        !(
          (<GridToolbar>theCenter.CurrentSelectedItem.Toolbar).DefaultView &&
          (<GridToolbar>theCenter.CurrentSelectedItem.Toolbar).CurrentView
        )
      ) {
        (<GridToolbar>theCenter.CurrentSelectedItem
          .Toolbar).ViewSelector.element().addClass("flash");
      } else {
        (<GridToolbar>theCenter.CurrentSelectedItem
          .Toolbar).ViewSelector.option(
          "value",
          (<GridToolbar>theCenter.CurrentSelectedItem.Toolbar).CurrentView ||
            (<GridToolbar>theCenter.CurrentSelectedItem.Toolbar).DefaultView
        );
      }
    }
  }

  /**
   * insert the views into the tab datasource based and set the load property to true if the scope matches and the view is the default
   * @param currentScope
   */
  AddViewsToTabPanelDataSource(currentScope: "Global" | "Personal") {
    let theCenter = this;
    if (!theCenter.TabDataSource) {
      theCenter.TabDataSource = theCenter.TabPanel.option("dataSource");
    }
    let store: DevExpress.data.Store = theCenter.TabDataSource.store();
    let viewToLoad: IGridViewMenuItem;
    $.each(theCenter.CurrentSelectedItem.ViewList, function(key, val) {
      if (val.ViewGUID) {
        // Clear the grid state out of the session the first time we load it so that the grid is loaded in the default state.
        let storageKey = "storage_" + DomSafeID(val.ViewGUID);
        sessionStorage.removeItem(storageKey);

        val.Load = val.IsDefault && val.Scope === currentScope;
        store.insert(val);
        if (val.Load) {
          // val.Active = true;
          viewToLoad = val;
        }
      }
    });
    // theCenter.RefreshTabDataSource().done(() => {
    //  if (viewToLoad) {
    //       theCenter.TrySetActiveTab(viewToLoad.ViewGUID);
    //  }
    // });
  }

  // NOTE:  Sub-classes CANNOT call super.RequestModel due to this issue.
  //       https://github.com/Microsoft/TypeScript/issues/338
  // If the logic in this method changes, it might have to be mimiced in sub classes as well.
  public get RequestModel(): GridSetup.BaseGridRequestModel {
    if (!this._RequestModel.RequestArgs.GUID)
      this._RequestModel.RequestArgs = new GridSetup.GridRequestArgs();
    return this._RequestModel;
  }

  /**
   * Either get the grid from the cache in CurrentSelectitem or make the request to get the new grid data
   * @param view
   * @param element
   * @param addCol
   * @param page
   */
  LoadGrid(
    view: IGridViewMenuItem,
    element,
    addCol: boolean = false,
    page?: number
  ) {
    let theCenter = this;

    /**
     * IF the grid is in the cache then we are probably comming from a different tab and the grid is already loaded so use it instead of going back to the server
     */
    let grid: IGridDataResponse;

    grid = theCenter.GetGridFromCache(view);

    if (grid) {
      let myGridOptions = theCenter.GridOptions(view);
      myGridOptions.summary = null;
      myGridOptions.export = {
        enabled: view.Grid.GridDataResponse.Meta.CanExport
      };

      (<GridToolbar>theCenter.CurrentSelectedItem
        .Toolbar).CurrentGrid = new Grid(
        grid,
        {
          GridContainer: element,
          GridToolbar: <GridToolbar>theCenter.CurrentSelectedItem.Toolbar
        },
        myGridOptions,
        theCenter,
        e => {
          (<GridToolbar>theCenter.CurrentSelectedItem.Toolbar).SelectionChanged(
            e
          );
        },
        theCenter.ColumnTemplateHandler
      );

      (<GridToolbar>theCenter.CurrentSelectedItem.Toolbar).CurrentGrid.Render();

      view.Grid = (<GridToolbar>theCenter.CurrentSelectedItem
        .Toolbar).CurrentGrid;
      //theCenter.CurrentSelectedItem.CurrentTabIndex = theCenter.TabPanel.option("selectedIndex");
      theCenter.CurrentSelectedItem.CurrentView = view;

      (<GridToolbar>theCenter.CurrentSelectedItem.Toolbar).CurrentView =
        view.ViewGUID;
    } else {
      /* If we are not simply addign a column to the current grid then we are probably changing views so go get the request model*/
      if (!addCol) {
        theCenter._RequestModel = theCenter.GetRequestModel(view.ViewGUID);
      }
      /* if we are getting the next page change it on the request model*/
      if (page) theCenter.RequestModel.RequestArgs.page = page;
      /**
       * set the requestmodel on the toolbar in case we need it there
       */
      (<GridToolbar>theCenter.CurrentSelectedItem
        .Toolbar).GridDataRequestModel =
        theCenter.RequestModel;

      if (!theCenter.RequestModel.RequestArgs.GUID) {
        /*there must not be a default view flash the selector so the user knows why there is no grid loading */
        (<GridToolbar>theCenter.CurrentSelectedItem
          .Toolbar).ViewSelector.element().addClass("flash");
        return;
      }

      let request = new TDFRequest({
        url: theCenter.RequestModel.Path,
        type: theCenter.RequestType,
        data: theCenter.RequestModel.RequestArgs
      })
        .MakeRequest()
        .done(function(response: IGridDataResponse) {
          let options = theCenter.GridOptions();
          options.summary = null;
          options.export = {
            enabled: response.Meta.CanExport
          };

          //Save the most recent metadata for each grid load [used for paging ]
          theCenter.GridViewMetaCache = response.Meta;

          (<GridToolbar>theCenter.CurrentSelectedItem
            .Toolbar).CurrentGrid = new Grid(
            theCenter.MassageData(response),
            {
              GridContainer: element,
              GridToolbar: <GridToolbar>theCenter.CurrentSelectedItem.Toolbar
            },
            options,
            theCenter,
            e => {
              (<GridToolbar>theCenter.CurrentSelectedItem
                .Toolbar).SelectionChanged(e);
            },
            theCenter.ColumnTemplateHandler
          );

          (<GridToolbar>theCenter.CurrentSelectedItem
            .Toolbar).CurrentGrid.Render();
          view.Grid = (<GridToolbar>theCenter.CurrentSelectedItem
            .Toolbar).CurrentGrid;
          (<GridToolbar>theCenter.CurrentSelectedItem.Toolbar).CurrentView =
            view.ViewGUID;
          theCenter.CurrentSelectedItem.CurrentView = view;
          //  theCenter.CurrentSelectedItem.CurrentTabIndex = theCenter.TabPanel.option("selectedIndex");
          theCenter.AddToGridCache(view, response);
          theCenter.IncrementBadge();
          if (GetDevice().device.deviceType === "phone") {
            $(
              $("<div/>")
                .addClass("paging-info-mobile")
                .append(
                  (<GridToolbar>theCenter.CurrentSelectedItem
                    .Toolbar).CurrentGrid.BuildPagingInfo()
                )
            ).insertAfter(
              $(
                (<GridToolbar>theCenter.CurrentSelectedItem.Toolbar).CurrentGrid
                  .GridContainer
              ).parent()
            );
          }
        });
    }
  }

  MassageData(gridViewResponse: IGridDataResponse): IGridDataResponse {
    let theCenter = this;
    return gridViewResponse;
  }

  /**
   * Really the only place that should call LoadGrid .. besides refreshing the grid and paging through
   * @param itemData
   * @param index
   * @param element
   */
  TabPanel_ItemTemplate(itemData, index, element) {
    let theCenter = this;
    let gridcontainer = $("<div />")
      .attr("id", DomSafeID(itemData.ViewGUID))
      .css("padding", "3px");
    element.append(gridcontainer);
    itemData.TabContent = element;
    if (GetDevice().isDevice) {
      element.css("overflow-y", "auto");
    }
    if (itemData.ItemType === theCenter.CurrentSelectedItem.Type) {
      theCenter.LoadGrid(itemData, gridcontainer, false);
      itemData.Active = true;
    }
  }

  /**
   * changes the gridview when the title of a non active grid is clicked
   * @param data
   */
  TabPanel_OnTitleClick(data) {
    let theCenter = this;

    if (
      data.event &&
      data.event.target &&
      $(data.event.target).hasClass("dx-icon-remove")
    ) {
      theCenter.CloseAView(data.itemData);
    } else {
      let item = theCenter.GetItem(data.itemData.ItemType);

      if (
        theCenter.CurrentSelectedItem &&
        theCenter.CurrentSelectedItem.Type !== data.itemData.ItemType
      ) {
        /*remove the active class on the grid menu item because we changed types*/
        theCenter.CurrentSelectedItem.jQMenuItem.removeClass("activetype");
        /*add the acitve class to the newly selected type*/
        item.jQMenuItem.addClass("activetype");
      }
      theCenter.CurrentSelectedItem = item;

      if (item.Type !== BSIGrids.GeneralSummary) {
        (theCenter.CurrentSelectedItem.Toolbar as GridToolbar).CurrentScope =
          data.itemData.Scope;
        (theCenter.CurrentSelectedItem.Toolbar as GridToolbar).GridItemType =
          data.itemData.ItemType;
        theCenter.CurrentSelectedItem.ToolbarModel.CurrentView =
          data.itemData.ViewGUID;
        theCenter.CurrentSelectedItem.CurrentView = data.itemData;
        theCenter.CurrentSelectedItem.CurrentTabIndex = data.itemIndex;
      }
      theCenter.GetToolbar().done(function() {
        // if (data.itemData.Grid) (<TDF.GridSetup.Toolbar>theCenter.CurrentSelectedItem.Toolbar).CurrentGrid = data.itemData.Grid;
        theCenter.CreateToolbar(data.itemData.Scope);
        if (data.itemData.Grid)
          (<GridToolbar>theCenter.CurrentSelectedItem.Toolbar).CurrentGrid =
            data.itemData.Grid;

        if (theCenter.CurrentSelectedItem.UsesDefaultGridBehavior) {
          if (data.itemData.Grid) {
            let length = data.itemData.Grid.GridObject.getSelectedRowsData()
              .length;
            //.CurrentGrid ? (theToolbar.CurrentGrid.GridObject.getSelectedRowsData().length) : 0;
            ToggleToolbarButtonVisibility(
              theCenter.CurrentSelectedItem.Toolbar.ToolbarContainerID,
              length
            );
            if (!data.itemData.Grid.GridObject.element().is(":visible")) {
              theCenter.LoadGrid(data.itemData, data.itemData.TabContent);
            }
          }
        }
      });
    }
  }

  /**
   * Calls CloseAView
   * @param e
   */
  CloseTab(e) {
    let theCenter = this;
    theCenter.CloseAView(e.data.view);
  }
  /**
   * set the load and active properties on the view to false, remove it from the cache, decrement the badge for the type, refresh the tabs,
   * remove the close button on the menu item if there are no more grids open for that type
   * @param view
   */
  CloseAView(view) {
    let theCenter = this;
    /**
     * Set the view in the store to not load because the user just closed it
     */
    let store: DevExpress.data.ArrayStore = theCenter.TabPanel.option(
      "dataSource"
    ).store();
    store
      .update(view.ViewGUID, { Load: false, Active: false })
      .done(function(data) {});

    let itemType = view.ItemType || theCenter.CurrentSelectedItem.Type;
    let item = theCenter.GetItem(itemType);

    if (item.UsesDefaultGridBehavior) {
      theCenter.RemoveGridFromCache(view);
    } else {
      theCenter.ClearCustomTabData(item);
    }
    theCenter.DecrementBadge(view.ItemType);

    theCenter.RefreshTabDataSource();

    if (item.LoadedGrids.length === 0) {
      let theRemoveButtonId = "remove-" + itemType.toString();
      $("#" + theRemoveButtonId).addClass("hidden");
      $("#" + theRemoveButtonId)
        .parent("li")
        .removeClass("activetype");
    }
  }
  /**
   * Gets the filter for the Views depending on if the user wants them to be separated by type or not...
   * Filters by the load property and optionally the itemtype
   */
  GetDefaultFilter(): any {
    let theCenter = this;
    let type = theCenter.CurrentSelectedItem.Type || 0;

    if (theCenter.TabsByType) {
      return [["Load", "=", true], "&&", ["ItemType", "=", type]];
    } else {
      return [["Load", "=", true]];
    }
  }
  /**
   * Get the Devextreme datasource for the views , data is an empty array because items get added later.
   */
  GetDataSource() {
    let theCenter = this;

    theCenter.TabDataSource = new dxDataSource({
      store: new dxArrayStore({
        data: [], // Initialize with nothing.  The items get added later.
        key: "ViewGUID",
        onModified: function() {
          let t = theCenter.TabPanel;
        }
      }),
      filter: theCenter.GetDefaultFilter(),
      pageSize: parseInt(
        Preferences.GetPreference("ClientGridPageSize", "TDFMobile")
      )
    });
    return theCenter.TabDataSource;
  }

  //#region Grid Stuff
  FullRefreshCurrentGrid(e, addCol: boolean = false) {
    let theCenter = this;
    theCenter.RequestModel.RequestArgs.filterExpression = "";
    theCenter.RequestModel.RequestArgs.Sorts = [];
    theCenter.RequestModel.RequestArgs.page = 1;

    theCenter.RefreshCurrentGrid(e, addCol);
  }

  // Note:  e is a parameter here to match the requirements of the Toolbar's RefreshCurrentGrid method that this is bound to.
  RefreshCurrentGrid(e, addCol: boolean = false) {
    let theCenter = this;
    if (
      theCenter.CurrentSelectedItem.Toolbar &&
      (<GridToolbar>theCenter.CurrentSelectedItem.Toolbar).CurrentGrid
    ) {
      let container = (<GridToolbar>theCenter.CurrentSelectedItem.Toolbar)
        .CurrentGrid.GridContainer;
      /*is there a reason we remove the grid here and then call  theCenter.RemoveGridFromCache*/
      let itemType = theCenter.CurrentSelectedItem.Type;
      let item = theCenter.GetItem(itemType);
      $.each(item.LoadedGrids, function(key, val: IGridInfo) {
        if (
          val.GridView.ViewGUID ===
          (<GridToolbar>theCenter.CurrentSelectedItem.Toolbar).CurrentGrid
            .GridDataResponse.ViewID
        ) {
          item.LoadedGrids.splice(key, 1);
          return false;
        }
        return false;
      });

      let viewSource: DevExpress.data.DataSource = (<GridToolbar>theCenter
        .CurrentSelectedItem.Toolbar).ViewSelector.option("dataSource");
      if (
        (<GridToolbar>theCenter.CurrentSelectedItem
          .Toolbar).ViewSelector.option("value") ===
        (<GridToolbar>theCenter.CurrentSelectedItem.Toolbar).CurrentGrid
          .GridDataResponse.ViewID
      ) {
        viewSource
          .store()
          .byKey(
            (<GridToolbar>theCenter.CurrentSelectedItem.Toolbar).CurrentGrid
              .GridDataResponse.ViewID
          )
          .done(function(view: IGridViewMenuItem) {
            theCenter.RemoveGridFromCache(view);

            let store: DevExpress.data.Store = theCenter.TabDataSource.store(); // theCenter.TabPanel.option("dataSource").store();
            store
              .update(view.ViewGUID, { Load: true, Active: true })
              .done(function(data) {
                if (!theCenter.TabPanel.option("items").length) {
                  theCenter.RefreshTabDataSource();
                } else {
                  theCenter.DecrementBadge(
                    (<GridToolbar>theCenter.CurrentSelectedItem.Toolbar)
                      .GridItemType
                  );
                  theCenter.LoadGrid(view, container, addCol);
                }
              });
          })
          .fail(d => {
            // let store: DevExpress.data.ArrayStore = theCenter.TabPanel.option("dataSource").store();
            //store.update(view.ViewGUID, { Load: true, Active: true }).done(function (data) {
            //});
          });
      } else {
        let store: DevExpress.data.Store = theCenter.TabDataSource.store();
        //store.update(toolbar.CurrentGrid.GridResponse.ViewID, { Load: false, Active: false }).done(function (data) { });
        store
          .update(
            (<GridToolbar>theCenter.CurrentSelectedItem
              .Toolbar).ViewSelector.option("value"),
            { Load: true, Active: true }
          )
          .done(function(data) {
            theCenter.RefreshTabDataSource();
          });
      }
    }
  }

  AddToGridCache(view: IGridViewMenuItem, gridresponse: IGridDataResponse) {
    let theCenter = this;
    let CacheSize = GetDevice().isDevice ? 5 : 10;

    let itemType = view.ItemType || theCenter.CurrentSelectedItem.Type; //TODO:  Why is view.ItemType not always defined???
    let item = theCenter.GetItem(itemType);

    if (item.LoadedGrids.length === CacheSize) {
      item.LoadedGrids.shift();
    }
    let info: IGridInfo = {
      GridView: view,
      GridData: gridresponse
    };
    item.LoadedGrids.push(info);
  }

  GetGridFromCache(view: IGridViewMenuItem): IGridDataResponse {
    let theCenter = this;
    let itemType = view.ItemType || theCenter.CurrentSelectedItem.Type; //TODO:  Why is view.ItemType not always defined???
    let item = theCenter.GetItem(itemType);

    let gridList: IGridInfo[] = $.grep(item.LoadedGrids, function(
      val: IGridInfo
    ) {
      return val.GridView.ViewGUID === view.ViewGUID;
    });
    if (gridList.length) {
      theCenter.GridViewMetaCache = gridList[0].GridData.Meta;
      return gridList[0].GridData;
    }
    return null;
  }

  RemoveGridFromCache(view: IGridViewMenuItem) {
    let theCenter = this;
    let itemType = view.ItemType || theCenter.CurrentSelectedItem.Type;
    let item = theCenter.GetItem(itemType);

    $.each(item.LoadedGrids, function(key, val: IGridInfo) {
      if (val && val.GridView && val.GridView.ViewGUID === view.ViewGUID) {
        item.LoadedGrids.splice(key, 1);
      }
    });
  }

  GridOptions(view?: IGridViewMenuItem): DevExpress.ui.dxDataGridOptions {
    let theCenter = this;
    let options: DevExpress.ui.dxDataGridOptions = {};

    options.sorting = { mode: "single" };

    if ($("#" + theCenter.TabsContainerID).length) {
      options.height = $("#" + theCenter.TabsContainerID).outerHeight() * 0.9;
    }

    options.onContentReady = function(e) {
      if (theCenter.CurrentSelectedItem.CurrentView) {
        let id = DomSafeID(theCenter.CurrentSelectedItem.CurrentView.ViewGUID);

        $(window).on("orientationchange", function() {
          $("#" + id).css("width", "100%");
          $("#" + id).css("height", "100%");
          $("#recentitemcontainer").css("width", "100%");
        });
      }
      if (theCenter.RequestModel) {
        let btnNextPage = e.element.find(".dx-next-button");
        let btnPrevPage = e.element.find(".dx-prev-button");
        let pageSizes = e.element.find(".dx-page-sizes");
        let grid = e.component as DevExpress.ui.dxDataGrid;

        $(btnNextPage).off("click");
        $(btnNextPage).on("click", function(event) {
          if ($(event.currentTarget).hasClass("dx-button-disable")) return;
          if (grid.pageIndex() === grid.pageCount() - 1) {
            event.stopPropagation();
            theCenter.RequestModel.RequestArgs.page += 1;
            theCenter.RemoveGridFromCache(
              theCenter.CurrentSelectedItem.CurrentView
            );
            grid.pageIndex(0);
            theCenter.LoadGrid(
              theCenter.CurrentSelectedItem.CurrentView,
              e.element,
              false,
              theCenter.RequestModel.RequestArgs.page
            );
          }
        });

        $(btnPrevPage).off("click");
        $(btnPrevPage).on("click", function(event) {
          if ($(event.currentTarget).hasClass("dx-button-disable")) return;
          if (grid.pageIndex() === 0) {
            if (theCenter.RequestModel.RequestArgs.page > 1) {
              theCenter.RequestModel.RequestArgs.page -= 1;
              theCenter.RemoveGridFromCache(
                theCenter.CurrentSelectedItem.CurrentView
              );
              let newIndex =
                Math.ceil(
                  theCenter.RequestModel.RequestArgs.pageSize / grid.pageSize()
                ) - 1;
              grid.pageIndex(newIndex);
              theCenter.LoadGrid(
                theCenter.CurrentSelectedItem.CurrentView,
                e.element,
                false,
                theCenter.RequestModel.RequestArgs.page
              );
            }
          }
        });

        $(pageSizes).on("dxclick", function(e) {
          Preferences.SetPreference(
            "ClientGridPageSize",
            $(e.target).text(),
            "TDFMobile"
          );
        });

        if ($(btnNextPage).hasClass("dx-button-disable")) {
          if (
            theCenter.RequestModel.RequestArgs.page <
            theCenter.GridViewMetaCache.TotalPages
          ) {
            btnNextPage.removeClass("dx-button-disable");
          }
        }

        if ($(btnPrevPage).hasClass("dx-button-disable")) {
          if (theCenter.RequestModel.RequestArgs.page > 1) {
            btnPrevPage.removeClass("dx-button-disable");
          }
        }

        if (
          theCenter.GridViewMetaCache &&
          theCenter.GridViewMetaCache.TotalRecords <
            parseInt(Preferences.GetPreference("ServerPageSize", "TDFMobile"))
        ) {
          // Don't set the click event to sort the data on the server.
        } else {
          $(e.element)
            .find(".dx-header-row .dx-datagrid-action")
            .off("dxclick")
            .on("dxclick", function(event) {
              if (theCenter.ServerSort) {
                event.stopPropagation();
                let field;
                let cname = $(this)
                  .find(".dx-datagrid-text-content")
                  .text();

                let sorts = theCenter.RequestModel.RequestArgs.Sorts;

                $.each(grid.option("columns"), function(k, v) {
                  if (v.caption) {
                    if (cname === v.caption) {
                      field = v.dataField.trim();
                    }
                  } else {
                    if (cname === v.dataField) {
                      field = v.dataField.trim();
                    }
                  }
                });

                if (sorts && sorts.length) {
                  let found = false;

                  sorts.forEach((val, idx) => {
                    if (val.Field === field) {
                      found = true;
                      switch (val.Direction) {
                        case "asc":
                          val.Direction = "desc";
                          break;
                        case "desc":
                          val.Direction = "asc";
                          break;
                      }
                    }
                  });

                  if (!found) {
                    sorts = [{ Field: field, Direction: "asc", Order: 0 }];
                  }
                } else {
                  sorts = [{ Field: field, Direction: "asc", Order: 0 }];
                }

                theCenter.RequestModel.RequestArgs.Sorts = sorts;
                theCenter.RefreshCurrentGrid(null, false);
              }
            });
        }
      }
    };

    //options.onToolbarPreparing = function (e) {

    //  e.toolbarOptions.items.unshift({
    //    "location": "after",
    //    "widget": "dxButton",
    //    "options": {
    //      "icon": "save",
    //      "text": "",
    //      "onClick": function (e) {

    //        let gridState = theCenter.CurrentSelectedItem.CurrentView.Grid.GridObject.state();
    //        let viewGuid = theCenter.CurrentSelectedItem.CurrentView.ViewGUID;

    //        let request = new TDFRequest({
    //          url: RequestBuilder.GridDesign.BuildURL(viewGuid, RequestBuilder. GridDesign.SaveState),
    //          type: "POST",
    //          data: gridState
    //        });

    //        request.MakeRequest().done(function (response) {
    //          //theCenter.CurrentSelectedItem.ToolbarModel = response;
    //          //dfd.resolve(theCenter);
    //        });

    //        //debugger

    //      }
    //    }

    //  })

    //};

    return options;
  }

  //#endregion

  GetItem(type: number): ICenterItem {
    let theCenter = this;
    let item = $.grep(theCenter.Items, function(val: ICenterItem) {
      return val.Type === type;
    });
    if (item.length) {
      return item[0];
    } else {
      return null;
      //throw error or warning or something
    }
  }

  DecrementBadge(type) {
    let theCenter = this;

    if (theCenter.UseBadges) {
      let badge = $(".badge.type" + type);
      let item = theCenter.GetItem(type);
      let num = item.LoadedGrids.length;
      num ? badge.text(num) : badge.text("");
    }
  }

  IncrementBadge() {
    let theCenter = this;
    if (theCenter.UseBadges) {
      if (!theCenter.TabPanel) return;
      let badge = theCenter.CurrentSelectedItem.jQMenuItem.find(".badge");

      if (theCenter.CurrentSelectedItem.UsesDefaultGridBehavior) {
        let item = theCenter.GetItem(theCenter.CurrentSelectedItem.Type);
        let num = item.LoadedGrids.length;
        num ? badge.text(num) : badge.text("");
      } else {
        let num = 1;
        badge.text(num);
      }
    }

    $("#remove-" + theCenter.CurrentSelectedItem.Type).removeClass("hidden");
  }

  /**
   * Set the options for the dxTabpanel and create it on the TabsContainerID
   */
  RenderTabPanel() {
    let theCenter = this;
    let taboptions: DevExpress.ui.dxTabPanelOptions = {};
    taboptions.itemTitleTemplate = function(
      data: IGridViewMenuItem,
      index,
      element
    ) {
      let titleCaption = data && data.ViewName ? data.ViewName : "";
      let titleCaptionShort = titleCaption;
      if (titleCaption.length > 20) {
        titleCaptionShort = titleCaption.substring(0, 17) + "...";
      }
      let iconClass = data && data.Icon ? data.Icon : "";
      let thetitlecontainer = `
					  <div class="center-tab-title">
					  <span class="center-title-icon"><small class="${iconClass}"></small></span>
					  <span class="center-title-text" title="${titleCaption}"><small>${titleCaption}</small></span>
					   <span class="center-title-close"><i class="dx-icon dx-icon-remove "></i></span>
					</div>
					`;
      //<span class="dx-button-content"><i class="dx-icon dx-icon-remove "></i></span>
      element.parent().addClass("tdf-center-tab");
      //element.parent().css({ "max-width":GetDevice().device.phone ? "220px" : "300px" });
      //if (GetDevice().device.phone) element.parent().css({ display: "table-cell" });
      element.append(thetitlecontainer);
      $(".tdf-center-tab").css(
        "height",
        element
          .parent()
          .parent()
          .height()
      );
    };

    taboptions.itemTemplate = function(data, index, element) {
      theCenter.TabPanel_ItemTemplate(data, index, element);
    };

    taboptions.deferRendering = true;

    taboptions.onTitleClick = function(data) {
      theCenter.TabPanel_OnTitleClick(data);
    };

    //  taboptions.onItemRendered = function () { };
    taboptions.dataSource = theCenter.GetDataSource();
    taboptions.showNavButtons = false;
    taboptions.height = GetDevice().isDevice
      ? "1000px"
      : $(window).outerHeight() - $("#tdfnavbar").outerHeight();
    taboptions.swipeEnabled = false;
    taboptions.loop = false;

    theCenter.TabPanel = $("#" + theCenter.TabsContainerID)
      .dxTabPanel(taboptions)
      .dxTabPanel("instance");
  }

  /**
   * Inserts the required html contianers in the page.
   */
  RenderCenter() {
    let iCenter = this;

    if ($("#" + iCenter.CenterContainerID)[0]) {
      let html = `<div id='${iCenter.SlideMenuID}'></div>
		    <div id='${iCenter.MainAreaID}'>
		    <div id='${
          iCenter.MainAreaHeaderContainerID
        }' class='centerMainAreaHeader'></div>
		    <div id='${iCenter.MainAreaBodyContainerID}'>
		    <div id='${iCenter.ToolbarContainerID}'></div>
		    <div style='top: 0; margin-left:1px;' id='${iCenter.TabsContainerID}'></div>
		    </div>
		    </div>`;

      $("#" + iCenter.CenterContainerID).css("min-height", "800px");
      $("#" + iCenter.CenterContainerID).append(html);

      $("#tdfbody").addClass("nopadding");

      iCenter.MakeMenu();
      iCenter.WelcomeTab();
    }
  }

  MakeMenu() {
    let iCenter = this;
    iCenter.StickyMenu = new StickyMenu(
      iCenter.SlideMenuID,
      iCenter.MainAreaID,
      iCenter.SlideMenuStickyBtnsID
    );
    iCenter.StickyMenu.HeaderText =
      iCenter.CenterType === CenterType.InfoCenter ? "Items" : "";
    iCenter.StickyMenu.PinPreferenceModule = iCenter.PreferenceModule;
    iCenter.StickyMenu.PinPreferenceProperty =
      iCenter.StickyMenuPinPreferenceProperty;

    iCenter.StickyMenu.BuildMenuStructure();
    iCenter.RenderMenu();
  }

  WelcomeTab() {
    let iCenter = this;

    let addButtons = function() {
      iCenter.StickyMenu.BuildButtonStructure();
    };

    let taboptions: DevExpress.ui.dxTabPanelOptions = {};
    taboptions.items = [{ title: "Welcome" }];
    taboptions.itemTemplate = $("#welcome1");
    taboptions.noDataText = "Select a Grid View.";
    taboptions.onItemRendered = function() {
      addButtons();
    };
    // taboptions.height = $(window).innerHeight() ;//GetDevice().isDevice ? "auto" : $(window).innerHeight() - 100;
    taboptions.showNavButtons = true;
    $("#" + iCenter.TabsContainerID)
      .dxTabPanel(taboptions)
      .dxTabPanel("instance");

    iCenter.CustomizeMainPanel();
  }
  /**
   * Create the templates for the tabs settings button ... includes things like the preference to sort data on the server , separate tabs by type , and if applicable the grid scope settings for grid containers
   */
  GetTabSettingsItems() {
    let theCenter = this;
    //let a: DevExpress.ui.dxActionSheetItemTemplate = {
    //    i
    //}
    let settingsItems: any = [
      /*  {
	      template: function (itemData, itemIndex, itemElement) {
		  itemElement.append("<div style='position:relative' title='Check this to only show tabs relevant to item.' id='chkWrapperOuter' class='dx-button dx-button-normal dx-widget dx-button-has-icon dx-button-has-text dx-button-content'>" +
		      "<div id='chkWrapper' class='dx-button-content' > </div>" +
		      "<i id='help-separate-tabs' class='fa fa-info-circle' aria-hidden='true' style='position:absolute;top:19px;right:4px;' onclick='notify(\"Check this to only show tabs relevant to item.\", \"info\", 6000)'></i>" +
		      "</div> ");

		  $("#chkWrapper").append($("<div />").dxCheckBox({
		      value: theCenter.TabsByType,
		      width: "auto",
		      text: "Separate Tabs by Type",
		      onValueChanged: function (data) {
			  theCenter.TabsByType = data.value;
			 Preferences.SetPreference(theCenter.TabsByTypePreferenceProperty, data.value, theCenter.PreferenceModule);
			  if (theCenter.TabPanel) {
			      theCenter.RefreshTabDataSource();
			  }
		      }
		  }));
	      },
	  },*/
      {
        icon: theCenter.ServerSort ? "fa fa-check" : "fa fa-sort",
        hint: "Sort all data instead of downloaded data only",
        text: "Sort Data on Server",
        onClick(e) {
          Preferences.SetPreference(
            "SortOnServer",
            !theCenter.ServerSort,
            "WebGrid"
          );
        }

        //template: function (itemData, itemIndex, itemElement) {

        //    itemElement.append($("<div />").dxCheckBox({
        //        value: theCenter.ServerSort,
        //        width: "auto",
        //        text: "Sort Data on Server",
        //        onValueChanged: function (data) {
        //            theCenter.ServerSort = data.value;
        //           Preferences.SetPreference("SortOnServer", data.value, "WebGrid");
        //        }
        //    })).append("<i id='help-sort-data' class='fa fa-info-circle' style='margin-left:5px;'  onclick='notify(\"Sort all data instead of downloaded data only.\", \"info\", 6000)'></i>")

        //    //itemElement.append("<div id='chksortlocal' style='position:relative; width:200px;' title='Sort all data instead of downloaded data only.' class='dx-button dx-button-normal dx-widget dx-button-has-icon dx-button-has-text dx-button-content'>" +
        //    //    "<div id='chkSortWrapper' class='dx-button-content' > </div>" +
        //    //    "<i id='help-sort-data' class='fa fa-info-circle' aria-hidden='true' style='position:absolute;top:19px;right:4px;'  onclick='notify(\"Sort all data instead of downloaded data only.\", \"info\", 6000)'></i>" +
        //    //    "</div> ");

        //    //$("#chkSortWrapper").append($("<div />").dxCheckBox({
        //    //    value: theCenter.ServerSort,
        //    //    width: "auto",
        //    //    text: "Sort Data on Server",
        //    //    onValueChanged: function (data) {
        //    //        theCenter.ServerSort = data.value;
        //    //       Preferences.SetPreference("SortOnServer", data.value, "WebGrid");
        //    //    }
        //    //}));
        //},
      }
    ];

    if (theCenter.CurrentSelectedItem.UsesDefaultGridBehavior) {
      settingsItems.push(
        {
          icon: "fa fa-th",
          text: "Edit Grid Options",
          onClick() {
            let dialog = new Dialog(
              {
                size: "size-normal",
                title: "Edit Grid Options",
                closable: true,
                body: $("<div id='grid-scope-settings'></div>"),
                type: "type-primary"
              },
              null,
              true,
              true
            );

            dialog.open().done(function() {
              Preferences.LoadGridDefaults(
                "#grid-scope-settings",
                theCenter.CurrentSelectedItem.Type
              );
            });
          }
        }
        //{
        //    template: function (itemData, itemIndex, itemElement) {
        //

        //        itemElement.append($("<div style='position:relative' id='settingsBtnWrapperOuter' title='Customize your grid.' class='dx-button dx-button-normal dx-widget dx-button-has-icon dx-button-has-text'>" +
        //            "<div id='btnWrapper' class='dx-button-content'>" +
        //            "<i id='help-separate-tabs' class='dx-icon fa fa-th'></i>" +
        //            "<div id='editGridOptions' class='dx-button-text'>Edit Grid Options</div>" +
        //            "<i class='fa fa-info-circle' aria-hidden='true' style='position:absolute;top:19px;right:4px;' onclick='notify(\"Customize your grid.\", \"info\", 6000)'></i>" +
        //            "</div>" +
        //            "</div>"));

        //        $("#editGridOptions").on("click", function () {
        //            if ($("#grid-settings-pop").length <= 0) {
        //                let opts: DevExpress.ui.dxPopupOptions = {};
        //                opts.contentTemplate = function (element) {
        //                    element.append($("<div />").attr("id", "grid-scope-settings"));
        //                };
        //                opts.fullScreen = $(window).innerWidth() < 500;
        //                opts.onShown = function () {
        //                   Preferences.LoadGridDefaults("#grid-scope-settings", theCenter.CurrentSelectedItem.Type);

        //                };

        //                let pop: DevExpress.ui.dxPopup = $("<div id='grid-settings-pop' />").dxPopup(opts).dxPopup("instance");
        //                pop.element().appendTo("body");
        //                pop.show();
        //            } else {
        //                $("#grid-settings-pop").dxPopup("instance").show();
        //            }
        //        });
        //    }
        //}
      );
    }

    return settingsItems;
  }
  /**
   * Close all the tabs open for the itemtype
   * @param e
   */
  CloseItemTabs(e) {
    let theCenter = this;
    let item: ICenterItem = theCenter.GetItem(e.data.Type);
    let store: DevExpress.data.ArrayStore = theCenter.TabPanel.option(
      "dataSource"
    ).store();

    if (item.UsesDefaultGridBehavior) {
      if (item.LoadedGrids.length > 0) {
        for (let i = item.LoadedGrids.length; i > 0; i--) {
          theCenter.CloseAView(item.LoadedGrids[i - 1].GridView);
        }
      }
    } else {
      for (let i = item.ViewList.length; i > 0; i--) {
        theCenter.CloseAView(item.ViewList[i - 1]);
      }
    }
  }

  GetExtraTabToolbarItems() {
    let theCenter = this;

    let extraItems: any = [
      {
        location: "after",
        locateInMenu: "auto",
        widget: "dxButton",
        showText: "inMenu",

        options: {
          text: "Grid Options",
          icon: "fa fa-cogs",
          type: "normal",
          onClick: function(e) {
            if (!$("#gridoptions").length) {
              $("<div id='gridoptions' />").appendTo("#tdfbody");
            }

            let actionSheet = $("#gridoptions")
              .dxActionSheet({
                dataSource: theCenter.GetTabSettingsItems(),
                target: e.element,
                //deferRendering: true,
                showTitle: false,
                width: "250px",
                usePopover: $("#" + theCenter.ToolbarContainerID)
                  .find(".dx-toolbar-button.dx-toolbar-menu-container")
                  .hasClass("dx-state-invisible")
              })
              .dxActionSheet("instance");

            actionSheet.show();
          }
        }
      }
    ];

    return extraItems;
  }
}
