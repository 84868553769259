import { BIDataCenter } from "./bidatacenter";
import { CenterType, GridContainerTypes } from "../../enums/enums";
import { ItemListingNameModel } from "../../interfaces/interfaces";
import { TDFDataCenters } from "../../interfaces/datacenters/tdfdatacenters";
import { CurrentUser } from "../../infrastructure/context";
import { DataScopeInitializerItem, DataScopeInitializer, DataScopeSelection, DynamicDataScopeSelectorSettings } from "../../components/dashboards/dynamicdatascopeselector";
import { DataScopeSelectionTypes, DataScopeSelectionMode } from "../../enums/user/datascope/enums";
import { ISummary, EnumBSIPermissionsPrimary } from "../../enums/bi/enums";
import { TDFRequest } from "../../services/request";

export class ContactBIDataCenter extends BIDataCenter {
    public static ContactBIDynamicDataScopeSelectorSettings: DynamicDataScopeSelectorSettings = {
        ScopeSelectionType: DataScopeSelectionTypes.ContactBIScope,
        DialogTitle: "Summary Settings",
        AllowDefault: false,
        SelectionMode: DataScopeSelectionMode.Multiple
    };

    _CenterType: CenterType = CenterType.ContactBi;
    _ContainerType: GridContainerTypes = GridContainerTypes.ContactSummary;

    private itemListNameModel: ItemListingNameModel;

    constructor(itemListNameModel: ItemListingNameModel, creationArgs: TDFDataCenters.ICRMDataCenterCreationArgs, customName?: string) {
        super(creationArgs, customName);
        let theContactCenter = this;
        theContactCenter.itemListNameModel = itemListNameModel;
    }

    protected GetScopeSelectorSettings() {
        let bidc = this;
        //NEEDTODO - make sure this behaves correctly for Contact BI
        return ContactBIDataCenter.ContactBIDynamicDataScopeSelectorSettings;
    }

    protected InitializeSummarySettings(): JQueryPromise<any> {
        let bidc = this;
        let dfd: JQueryDeferred<any> = $.Deferred();

        if ((CurrentUser.BIPermissions.Primary & EnumBSIPermissionsPrimary.SummaryTypeContactSummary) === EnumBSIPermissionsPrimary.SummaryTypeContactSummary) {
            let itemList: DataScopeInitializerItem[] = [];

            let itemIDList = bidc.itemListNameModel.ItemListWithNames.map((value, index) => { return value.ID });
            let request = new TDFRequest({ url: "/BI/GetContactInfo", type: "POST", data: { ItemIdList: itemIDList } });
            request.MakeRequest().done(function (response) {
                let initialItemListingWithNames: ItemListingNameModel = { ItemListWithNames: [] };
                initialItemListingWithNames.ItemListWithNames = response;

                $.each(initialItemListingWithNames.ItemListWithNames, function (index, value) {
                    itemList.push({ ItemID: value.ID, Name: value.Name });
                });

                let dataScopeInitializer: DataScopeInitializer = {
                    ActiveSelectionLevel: ISummary.SummaryInterfaces.cBSID.EnumType.AcctID,
                    SelectedItems: itemList
                };

                bidc.summarySettings = new DataScopeSelection(bidc.CustomName);
                bidc.SummarySettings.InitSettings(dataScopeInitializer);
                dfd.resolve();
            });
            //$.each(bidc.itemListNameModel.ItemListWithNames, function (index, value) {
            //    itemList.push({ ItemID: value.ID, Name: value.Name });
            //});

            //let dataScopeInitializer: DataScopeInitializer = {
            //    ActiveSelectionLevel: ISummary.SummaryInterfaces.cBSID.EnumType.AcctID,
            //    SelectedItems: itemList
            //};

            //var request = new TDFRequest({ url: "/BI/GetContactInfo", type: "POST", data: { itemIdList: itemList } });
            //request.MakeRequest().done(function (response) {

            //    //                            let initialItemListingWithNames = response.Data;

            //    //                            $.each(initialItemListingWithNames, function (index, value) {
            //    //                                scopeSelector.AddSelectedItem(scopeSelector.ActiveFolderLevel, value.ID, value.Name);
            //    //                            });

            //    //                           scopeSelector.initialItemsLoaded = true;
            //    //                           dfd.resolve(scopeSelector.CurrentlySelectedItemsAsArray());
            //});

            //bidc.summarySettings = new DataScopeSelection(bidc.CustomName);
            //bidc.SummarySettings.InitSettings(dataScopeInitializer);
        }
        else {
            return dfd.promise(dfd.resolve());
        }

        return dfd.promise();
    }
}