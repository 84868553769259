import  { TDFRequest } from "../../services/request";
import * as Util from "../../util/allutils";
import * as Context from "infrastructure/context";
import * as Enums from "enums/enums";
import { MenuView } from "../../util/allutils";
import { MenuAction } from "../../util/menuactions";
import { ItemMain } from "../items/helpers/itemmain";
import { IItemInfo } from "interfaces/interfaces";
import { RaiseEvent, RaiseEvent2 } from "infrastructure/events/ui_events";
import { EventTypes, eventNameSpace } from "enums/webevents/enums"
import dxDataSource from "devextreme/data/data_source";
import { Preferences } from "../../infrastructure/user/preferences";

export class ActionMenuHelper {
    // This is an attempt to separate the idea of "showing the Actions/Views" menu from the Grid.
    // This code is copied from datacentergrids.ts
    // Expect that the code in there should disappear and be replaced by calls to this code.

    private selected: any[]; // Assume presence of TDF GUID! And ItemType!

    private limiterName: string = ""; // meant to replace the idea of  "limiter" on the Event.

    constructor(selected: any[], limiterName?: string) {
        this.selected = selected;
        this.limiterName = limiterName;
    }

    private customItemOpener: Function;

    /**
     * Allows you to pass a function meant to replace the typical "open item logic" of the ActionMenuhelper.
     * @param fnOpener
     */
    setItemOpener(fnOpener: Function) { 
        this.customItemOpener = fnOpener;
    }

    private OpenItem(selected: any) {

        let that = this;

        if (that.customItemOpener) {

            that.customItemOpener();

        } else {
            // OpenNavigator(selected, theGrid._view.ItemType);
            RaiseEvent2(
                EventTypes.CenterEventTypes.openitem,
                that.limiterName,
                eventNameSpace.request,
                [selected]
            );

        }
        
    }

    /**
     * Render the context menu that contains the Actions menu , views menu , open button, and other relevant items.
     * @param element
     * @param rowdata
     */
    ContextMenu(element, rowdata) {

        let that = this;

        //if on a phone or mobile show floating buttons
        let info = Util.GetActionRequestParamsFromRowData(rowdata);
        if (info && info.itemtype) {

            Context.DisplayInfo(info.itemtype).done(function (displayInfo) {
                //TODO: figure this out when not in the namespace TDF
                if (!window[displayInfo.TypeName]) {
                    if (!window[displayInfo.DisplayName]) {
                        //return;
                    }
                }
                let ContactEmail = "";
                let iMain: ItemMain;
                let menu: DevExpress.ui.dxContextMenu;
                if (!$("#tdfcontextmenu").length)
                    $("body").append($("<div id='tdfcontextmenu' />"));

                //let selected: any[] = theGrid.Instance.getSelectedRowsData();

                let contextItemAlreadySelected: boolean = false;
                $.each(that.selected, function (index, value) {
                    if (value["TDF GUID"] === rowdata["TDF GUID"]) {
                        contextItemAlreadySelected = true;
                    }
                });
                // If we right clicked on a row that is already selected, then leave the selection alone.
                // If we right clicked anywhere else in the grid, clear the selection and operate on the row that we right clicked on.
                if (!contextItemAlreadySelected) {
                    //theGrid.Instance.clearSelection();
                    that.selected = [];
                    that.selected.push(rowdata);
                }

                menu = $("#tdfcontextmenu")
                    .dxContextMenu(<DevExpress.ui.dxContextMenuOptions>{
                        position: { at: "center middle" },
                        target: element,
                        displayExpr: "MenuText",
                        onHidden(e) {
                            e.component.dispose();
                        },
                        onContentReady(e) {
                            let timer;
                            timer = window.setInterval(() => {
                                let container: JQuery = (e.component as any).itemsContainer();
                                if (container && container.length) {
                                    clearInterval(timer);
                                    container.addClass("border-top border-left border-bottom");
                                }
                            }, 50);
                        },
                        dataSource: new dxDataSource({
                            load: function (options) {
                                let dfd = $.Deferred();
                                let requestParams;
                                // If the selected item is a reminder we want to use the parent's actions instead.
                                if (rowdata.ItemType === Enums.itemTypes.itemReminder) {
                                    requestParams = {
                                        itemid: rowdata["ParentId"],
                                        itemtype: parseInt(rowdata["LinkItemType"])
                                    };
                                } else {
                                    requestParams = Util.GetActionRequestParamsFromRowData(rowdata);
                                }

                                let request = new TDFRequest({
                                    url: "/action/getactionsforitem/",
                                    type: "GET",
                                    data: requestParams
                                });
                                request.MakeRequest().done(function (response: any) {
                                    iMain = new ItemMain(response.ActionsModel.iMain);

                                    $.each(response.ActionsModel.ActionList, function (
                                        key,
                                        val: any
                                    ) {
                                        val.beginGroup = true;

                                        if (val.ContactEmail) ContactEmail = val.ContactEmail;

                                        if (val.MenuText === "Views") {
                                            if (Preferences.GetCompanyPreference("ShowViewsBtn", "TDFMobile", "1") === "1") {
                                                if (that.selected.length > 1 && val.MenuText === "Views") {
                                                    val.visible = false;
                                                }
                                            }
                                            else {
                                                //Hide the Views menu always for now.
                                                val.visible = false;
                                            }
                                        }

                                        if (
                                            iMain.ItemType === Enums.itemTypes.itemProduct &&
                                            (val.MenuText === "Actions" || val.MenuText === "Views")
                                        ) {
                                            val.visible = false;
                                        }

                                        if (val.items) {
                                            // SherpaBI Stuff 
                                            // val.items = val.items.filter((v) => { if ((v.Action != menuActions.menuGoToWebsite) && (v.Action != menuActions.menuLinkItem)) { return v; } });

                                            $.each(val.items, function (submenuIndex, submenuValue) {
                                                submenuValue.disabled =
                                                    that.selected.length > 1
                                                        ? !submenuValue.SupportsMultipleItems
                                                        : false;
                                                submenuValue.visible =
                                                    that.selected.length > 1
                                                        ? submenuValue.SupportsMultipleItems
                                                        : true;

                                                // TODO: This is a temporary fix to get 2018R1 out. Please remove once action has a web implementation.
                                                if (submenuValue.MenuText === "Launch Web Access Log") {
                                                    submenuValue.visible = false;
                                                }
                                            });
                                            let masterActions = Util.GroupActions(
                                                val.items,
                                                Util.GetDevice().isDevice,
                                                true
                                            ); // Want it to always group the actions for the right-click menu beacuase the menu doesn't cooperate well for height.

                                            val.items = masterActions;
                                        }
                                    });

                                    dfd.resolve(response.ActionsModel.ActionList);
                                });
                                return dfd.promise();
                            }
                        }),
                        onItemClick: Util.Debounce(function (data: any) {
                            let itemInfo = rowdata;

                            if (data.itemData.IsView) {
                                if (data.itemData.Action) {
                                    data.itemData.iteminfo = itemInfo;
                                    let views = new MenuView(data.itemData);
                                    menu.hide();
                                }
                            } else {
                                if (data.itemData.Action) {
                                    let selected: any[] = that.selected; // theGrid.Instance.getSelectedRowsData();

                                    if (selected && selected.length === 0) {
                                        selected = [];
                                        selected.push(rowdata);
                                    }

                                    if (
                                        data.itemData.Action === "Open" /*&& selected.length > 1*/
                                    ) {

                                        that.OpenItem(selected);

                                        return;
                                    }

                                    if (
                                        data.itemData.Action === Enums.menuActions.menuNewEmail &&
                                        ContactEmail
                                    )
                                        data.itemData.Email = ContactEmail;
                                    let items: Array<IItemInfo | { LinkID: string }> = [];

                                    if (
                                        (itemInfo["TDF GUID"] ||
                                            itemInfo["ItemID"] ||
                                            itemInfo["TDFItemID"]) &&
                                        (itemInfo["ItemType"] || itemInfo["TDFItemType"])
                                    ) {
                                        $.each(selected, function (index, rowData) {
                                            items.push({
                                                ItemId:
                                                    rowData["TDF GUID"] ||
                                                    rowData["ItemID"] ||
                                                    rowData["TDFItemID"],
                                                ItemType:
                                                    parseInt(rowData["ItemType"]) ||
                                                    parseInt(rowData["TDFItemType"]),
                                                iMain: iMain
                                            });
                                        });
                                        /*TODO: Figure out if i need to make a new grid class for linked item grids*/
                                        let action = new MenuAction(
                                            data.itemData,
                                            <IItemInfo[]>items
                                        );
                                    } else {
                                        $.each(selected, function (index, rowData) {
                                            items.push({
                                                LinkID: rowData["LinkID"]
                                            });
                                        });
                                        /*TODO: Figure out if i need to make a new grid class for linked item grids*/
                                        let action = new MenuAction(data.itemData, <{
                                            LinkID: string;
                                        }[]>items);
                                    }

                                    menu.hide();
                                }
                            }
                        }, 500),
                        onItemRendered(e) {
                            if (
                                e.itemData.IsView ||
                                (e.itemData.Action &&
                                    typeof e.itemData.Action === "number" &&
                                    e.itemData.GroupName !== "Other")
                            ) {
                                let menu = e.itemElement.parents(".dx-submenu").first();
                                if (menu && menu.length && !menu.hasClass("dx-scrollview")) {
                                    menu.dxScrollView({ height: $(window).innerHeight() * 0.5 });
                                }
                            }
                        }
                    })
                    .dxContextMenu("instance");

                menu.show();
            });
        }
    }




    }
