import { Dialog } from "../components/dialogs/dialog";
import { TDFRequest } from "../services/request";
import { ILongJob, ILongJobStatusInfo } from "../interfaces/admin/interfaces";
import { DomSafeID, GimmeGUID } from "../util/allutils";


export class LongJobManager {
    constructor() {
    }
    public showJobProgress(jobInfo: ILongJob, description: string) {
        let intervalHandle: number;
        let jobCancelled: boolean = false;
        let btnCancel: DevExpress.ui.dxButton;
        const statusId: string = DomSafeID(GimmeGUID());
        const youMayClose: string = DomSafeID(GimmeGUID());
        const getUpdatedStatus = () => {
/*Here*/
            const req = new TDFRequest({ url: `${TDFRequest.ApiPath}${jobInfo.InfoURL}` });
            req.MakeRequest().done((e: ILongJobStatusInfo) => {
                if (e.TotalSteps > 0) {
                    $(`#${statusId}`).html(`Processed ${e.StepsCompleted} of ${e.TotalSteps} item(s).`);
                } else {
                    $(`#${statusId}`).html(`Processed ${e.StepsCompleted} items.`);
                }
                if (e.IsComplete === true) {
                    haltUpdates();
                    btnCancel.option("disabled", true);
                    $(`#${youMayClose}`).html("Work complete.");
                    dlg.Instance.option("title", "Work complete.");
                }
            });
        };
        const cancelRequest = () => {
            dlg.setTitle("Cancelling...");
/*Here*/
            const req = new TDFRequest({ url: `${TDFRequest.ApiPath}${jobInfo.CancelURL}`, method: "POST" });
            req.MakeRequest().done((e) => {
                jobCancelled = true;
                haltUpdates();
                dlg.setTitle("Work cancelled.");
                $(`#${youMayClose}`).html("Work complete.");
            });
        };
        const haltUpdates = () => {
            try {
                clearInterval(intervalHandle);
            } finally { }
        };
        const body = $("<div><p>" + description + "</p></div>");
        const statusInfo = $(`<div id='${statusId}'>Waiting on status...</div>`);
        body.append(statusInfo);
        body.append($("<hr />"));
        body.append($("<div id='" + youMayClose + "'>You may close this dialog now. The work will continue on the server.</div>"));
        body.append($("<hr />"));
        btnCancel = $("<div />").dxButton({
            text: "Cancel",
            icon: "fa fa-ban",
            onClick: (e) => {
                haltUpdates();
                cancelRequest();
            },
        }).dxButton("instance");
        body.append(btnCancel.element());
        const dlg = new Dialog({
            onHidden: (e) => {
                haltUpdates();
            },
            body,
            title: "Your request is being processed.",
            closable: true,
            size: "size-small",
            type: "type-info",
            showStandardButtons: "no",
            modal: "no",
        });
        dlg.open().done(() => {
            // upon the dialog opening, start polling for the status of the job.
            window.setTimeout((e) => {
                getUpdatedStatus();
                intervalHandle = window.setInterval(getUpdatedStatus, 2500);
            }, 1000);
        });
    }
}
