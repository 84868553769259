import {BI__ItemDetailBase} from "./bi_itemdetailbase"
import { CenterType, GridContainerTypes } from "../../../enums/enums";
import {  BSIGrids } from "../../../enums/bi/enums";

export class BI__ItemDetailOrderForm extends BI__ItemDetailBase {
    public readonly TDFContainerType: number = GridContainerTypes.BIDetailFormDialogs;
    public ItemType: number;
    public readonly CustomName: string = "OrderDetails";
    public readonly DisplayName = "Order Details";
    public readonly ItemHeaderURL: string = "/bi/BIOrderHeaderFormData/";
    public readonly HeaderDataCaption: string = "Order Header";
  
    constructor(ItemID: string, centerType: CenterType = CenterType.Bi) {
      super(ItemID);
      const orderDetailForm = this;
  
      switch (centerType) {
        case CenterType.Bi:
          orderDetailForm.ItemType = BSIGrids.BIFormDialogOrder;
          break;
        case CenterType.AccountBi:
          orderDetailForm.ItemType = BSIGrids.BIFormDialogOrder;
          break;
        case CenterType.OppBi:
          orderDetailForm.ItemType = BSIGrids.BIFormDialogOrder;
          break;
        case CenterType.VendorBi:
          orderDetailForm.ItemType = BSIGrids.BIFormDialogOrder;
          break;
        case CenterType.ContactBi:
          orderDetailForm.ItemType = BSIGrids.BIFormDialogOrder;
          break;
      }
    }
  }