import * as Cookies from 'js-cookie';
import { GetCompanyPreference } from "infrastructure/user/getpreference";
import { TDFRequest } from "services/request";
import { Preferences } from "infrastructure/user/preferences";
import 'devextreme/ui/form';
import { MitsContainer } from "./mitscontainer";

export class MitsSSOSetup {

    Container: JQuery;
    DFD: JQueryDeferred<void>;

    _BaseURL: string;
    get BaseURL(): string {
        if (!this._BaseURL) {
            this._BaseURL = 'poc.mits.com';//GetCompanyPreference('BaseUrl', 'MitsIntegration');
        }
        return this._BaseURL;
    }
    set BaseURL(val: string) {
        this._BaseURL = val;
        //Preferences.SetGlobalPreference('BaseUrl', val, 'MitsIntegration');
    }

    private UserName: string;
    private Password: string;

    constructor(username: string, password: string, container: JQuery, deferred: JQueryDeferred<void>) {
        //this.Init();

        this.UserName = username;
        this.Password = password;
        this.Container = container;
        this.DFD = deferred;
    }

    Init() {
        let mss = this;

        mss.Container.height(() => {
            return $('#tdffooter').offset().top - $('#tdfnavbar').height();
        });

        let form = $('<div />').dxForm({
            items: [
                {
                    dataField: 'MitsLoginUrl',
                    visible: !mss.BaseURL,
                    editorOptions: {
                        value: mss.BaseURL
                    }
                }, {
                    dataField: 'UserName',
                }, {
                    dataField: 'Password'
                }, {
                    itemType: 'button',
                    buttonOptions: {
                        text: 'Log In to MITS',
                        onClick: () => {
                            let formData = form.option('formData');

                            mss.BaseURL = formData.MitsLoginUrl;

                            let encodedMitsRedirect = encodeURIComponent(
                                `${
                                mss.BaseURL
                                }/mitsdiscover/getToken.md?username=${
                                formData.UserName
                                }&password=${
                                formData.Password
                                }`
                            );

                            new TDFRequest({
                                url: `util/mits/?url=${
                                    encodedMitsRedirect
                                    }`,
                                type: 'GET'
                            }).MakeRequest().done(tokenXml => {
                                let xmlDoc = $.parseXML(tokenXml),
                                    thisXml = $(xmlDoc),
                                    tokenNode = thisXml.find('token'),
                                    token = tokenNode.text();

                                mss.Container.empty();

                                new MitsContainer(mss.Container, `useToken.md?token=${
                                    token
                                    }&redirectUrl=displayFullScreenDashboard.flow%3FlibraryName%3DCUSTOMER.SCORECARDS%26id%3DFS.CUSTOMER.BILL.TO.STATUS.SCORECARD%26Sales%2520Rep%3D1013%26Bill%2520To%2520Customer%3D103273`);

                            });
                        }
                    }
                }
            ]
        }).dxForm('instance');

        mss.Container.append(form.element());
    }

    GetToken(): JQueryPromise<void> {
        let mss = this;
        let d: JQueryDeferred<void> = $.Deferred();

        let encodedMitsGetToken = encodeURIComponent(
            `http://${
            mss.BaseURL
            }/mitsdiscover/getToken.md?username=${
            'tdf'//mss.UserName
            }&password=${
            'integration'//mss.Password
            }`
        );

        new TDFRequest({
            url: `util/mits/?url=${
                encodedMitsGetToken
                }`,
            type: 'GET'
        }).MakeRequest().done(tokenXml => {
            let xmlDoc = $.parseXML(tokenXml),
                thisXml = $(xmlDoc),
                tokenNode = thisXml.find('token'),
                token = tokenNode.text();

            Cookies.set('mits_integration_token', token);

            mss.CompleteAuthentication(token).done(() => {
                d.resolve();
            });

        });

        return d.promise();
    }

    private CompleteAuthentication(token: string) {
        let mss = this;

        let d = $.Deferred();

        let hiddenDiv = $('<div />').css('display', 'none');
        mss.Container.append(hiddenDiv);

        new MitsContainer(
            hiddenDiv,
            `useToken.md?token=${token}`
        ).Init().done(() => {
            d.resolve();
        })

        return d.promise();
    }
}