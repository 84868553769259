import * as moment from 'moment';
import { Dialog } from '../dialogs/dialog';
import { Notification } from '../dialogs/notification';
import { itemTypes } from '../../enums/enums';
import { IItemInfo } from '../../interfaces/interfaces';
import { TDFRequest } from '../../services/request';
import { DomSafeID, GetDevice, GetEditorToolbar } from '../../util/allutils';
import dxDataSource from 'devextreme/data/data_source';
import dxCustomStore from 'devextreme/data/custom_store';
import 'devextreme/ui/html_editor';
import { SearchRecFav } from '../controls/searchrecfav';
import Globalize = require('globalize/dist/globalize');
import { CurrentUser } from 'infrastructure/context';

export class EmailGenerator {
  private UserSource: string = '/core/user/userlist/';
  private InitialSubject?: string = '';
  private InitialBody?: string = '';
  private InitialAddress?: string = '';
  IsEmailDetails: boolean = false;
  Editor: DevExpress.ui.dxHtmlEditor;
  Item?: IItemInfo = null;
  ContactID: string = null;
  private MyDialog: Dialog;
  private MyForm: DevExpress.ui.dxForm;
  private _Users: {
    Department: string;
    Email: string;
    Name: string;
    Office: string;
    Source: string;
    Userid: string;
  }[];
  IncludeItemData: boolean = false;
  FormDataForFile: FormData;
  AttachMents = [];
  TempListFiles = [];

  constructor(
    item: IItemInfo = null,
    subject?: string,
    body?: string,
    address?: string,
    isForwardItem: boolean = false
  ) {
    let ef = this;

    if (subject) ef.InitialSubject = subject;
    if (body) ef.InitialBody = body;
    if (address) ef.InitialAddress = address;
    ef.IsEmailDetails = isForwardItem;
    ef.Item = item;
    ef.Render();
  }

  private TDFDrafts() {
    let ef = this;

    new TDFRequest({ url: `/core/user/GetEmailDrafts/`, type: 'GET' })
      .MakeRequest()
      .done(function (response: any) {
        if (!$('#ddrfts').length) {
          $("<div id='ddrfts'/>").appendTo('body');
        }
        let columns: any = [
          'CategoryID',
          'CreatedBy',
          'Creation',
          'DraftID',
          'Editable',
          'HTMLBody',
          'IsGlobal',
          'InternalDescription',
          'ItemSpecific',
          'ModDate',
          'ModifiedBy',
          'Owner',
          'TDFOptions',
          'TextBody'
        ].map(k => {
          return { dataField: k, visible: false, allowSearch: false };
        });

        columns.push(
          {
            dataField: 'Active',
            groupIndex: 0,
            dataType: 'boolean',
            allowFiltering: true,
            allowHeaderFiltering: true,
            sortIndex: 0,
            sortOrder: 'desc',
            filterValues: [true]
          },
          {
            dataField: 'CategoryName',
            caption: 'Category',
            sortIndex: 1,
            dataType: 'string',
            groupIndex: 1,
            allowSearch: false,
            visible: true
            //groupCellTemplate: function (groupCell, info) {
            //    $("<strong />").html(info.text).css("font-style", "italic").css("font-weight", "bold").addClass("bg-warning").appendTo(groupCell);
            //}
          },
          {
            dataField: 'Subject',
            dataType: 'string',
            allowSearch: true,
            sortIndex: 1,
            visibleIndex: 0
          }
        );
        let t = new Dialog(
          {
            id: 'tte',
            title: 'Select',
            closable: true,
            size: 'size-normal',
            body: $('<div/>').append(
              $('<div />').dxDataGrid({
                dataSource: response,
                columns: columns,
                paging: {
                  enabled: false
                },
                selection: {
                  mode: 'single'
                },
                groupPanel: {
                  visible: true,
                  allowColumnDragging: true
                },
                filterRow: {
                  visible: true,
                  showOperationChooser: true
                },
                searchPanel: {
                  visible: true
                },
                // height:"100%",

                headerFilter: {
                  visible: true
                },
                showColumnLines: true,
                showRowLines: true,
                showBorders: true,
                rowAlternationEnabled: true,
                hoverStateEnabled: true,
                onSelectionChanged(options) {
                  let selectedDraftID = options.selectedRowsData[0].DraftID;
                  if (selectedDraftID) {
                    let request = new TDFRequest({
                      url: '/core/user/GetEmailDraft/',
                      type: 'POST',
                      data: {
                        draftid: selectedDraftID,
                        itemid:
                          ef.Item.ItemType !== 5 && ef.ContactID
                            ? ef.ContactID
                            : ef.Item.ItemId,
                        itemtype:
                          ef.Item.ItemType !== 5 && ef.ContactID
                            ? 5
                            : ef.Item.ItemType
                      }
                    });
                    request
                      .MakeRequest()
                      .done(function (response: any) {
                        if (true) {
                          if (response.Subject)
                            ef.MyForm.getEditor('Subject').option(
                              'value',
                              response.Subject
                            );
                          ef.Editor.option('value', response.Body);
                          t.close();
                        }
                        new Notification({
                          type: response.Valid ? 'success' : 'error',
                          shading: true,
                          displayTime: 2000,
                          message: response.Message
                        });
                      });
                  }
                }
              })
            )
          },
          null,
          true,
          true
        );
        t.open();
        //let theDraftPopup = $("#ddrfts").dxPopup({
        //    title: "Select",
        //    showCloseButton: true,

        //}).dxPopup("instance");

        //theDraftPopup.show().done(() => {
        //    theDraftPopup.content()
        //        .append()

        //});
      });
  }

  private AttachmentDataSource(): DevExpress.data.DataSource {
    let ef = this;
    let store = new dxCustomStore({
      load(options) {
        let d = $.Deferred();
        return d.promise(d.resolve(ef.AttachMents));
      },
      insert(values) {
        let d = $.Deferred();
        values.forEach(k => {
          ef.AttachMents.indexOf(k) < 0 ? ef.AttachMents.push(k) : $.noop();
        });
        return d.promise(d.resolve());
      }
    });
    let ds: DevExpress.data.DataSourceOptions = {
      store: store
    };
    return new dxDataSource(ds);
  }

  private DetailDrafts() {
    let ef = this;

    new TDFRequest({
      url: `/core/user/GetDetailDrafts/?itemtype=${ef.Item.ItemType}`,
      type: 'GET'
    })
      .MakeRequest()
      .done(function (response: any) {
        if (!$('#ddrfts').length) {
          $("<div id='ddrfts'/>").appendTo('body');
        }

        let theDraftPopup = $('#ddrfts')
          .dxPopup({
            contentTemplate: element => {
              element.append(
                $('<div />').dxDataGrid({
                  dataSource: response,
                  selection: {
                    mode: 'single'
                  },
                  columns: [
                    {
                      dataField: 'Name'
                    },
                    {
                      dataField: 'ID',
                      visible: false
                    }
                  ],
                  onSelectionChanged: function (options) {
                    let selectedDraftID = options.selectedRowsData[0].ID;
                    if (selectedDraftID) {
                      let request = new TDFRequest({
                        url: '/core/user/getdraftdetail/',
                        type: 'GET',
                        data: {
                          draftid: selectedDraftID,
                          itemid: ef.Item.ItemId,
                          itemtype: ef.Item.ItemType
                        }
                      });
                      request
                        .MakeRequest()
                        .done(function (response2: any) {
                          if (true) {
                            ef.Editor.option('value', response2.Body);
                            ef.MyForm.getEditor('Subject').option(
                              'value',
                              response2.Subject
                            );

                            theDraftPopup.hide();
                          }

                          let notifyType: any = response2.Valid
                            ? 'success'
                            : 'error';
                          new Notification({
                            type: notifyType,
                            shading: true,
                            displayTime: 2000,
                            message: response2.Message
                          });
                        });
                    }
                  }
                })
              );
            },
            title: 'Select',
            showCloseButton: true,
            height: 'auto'
          })
          .dxPopup('instance');
        theDraftPopup.show();
      });
  }

  private DraftMenuOptions(): DevExpress.ui.dxMenuOptions {
    let ef = this;
    return {
      onItemClick: e => {
        if (e.itemData.text !== 'Drafts')
          e.itemData.text === 'TDF Drafts' ? ef.TDFDrafts() : ef.DetailDrafts();
      },
      items: [
        {
          icon: 'doc',
          text: 'Drafts',
          selectable: false,
          items: [
            { text: 'TDF Drafts' },
            { text: 'Detail Drafts', visible: ef.IsEmailDetails }
          ]
        }
      ]
    };
  }

  private UserDataSource(): DevExpress.data.DataSource {
    let ef = this;
    let store: DevExpress.data.CustomStoreOptions = {
      load(opts) {
        let d = $.Deferred();
        if (!ef._Users || ef._Users.length <= 0) {
          // return new TDFRequest({ url: ef.UserSource, type: "GET" }).MakeRequest();
          new TDFRequest({ url: ef.UserSource, type: 'GET' })
            .MakeRequest()
            .done(response => {
              response = response.filter(a => {
                return a.Email && a.Name;
              });

              ef._Users = response;
              if (opts.searchValue) {
                let matches = ef._Users.filter(k => {
                  return (
                    (opts.searchExpr as any)(k)
                      .toLowerCase()
                      .indexOf(opts.searchValue.toLowerCase()) >= 0
                  );
                });
                if (matches && matches.length) {
                  d.resolve(matches);
                } else {
                  d.resolve(ef._Users);
                }
              } else {
                d.resolve(response);
              }
            });
        } else {
          if (opts.searchValue) {
            return d.promise(
              d.resolve(
                ef._Users.filter(k => {
                  return (
                    (opts.searchExpr as any)(k)
                      .toLowerCase()
                      .indexOf(opts.searchValue.toLowerCase()) >= 0
                  );
                })
              )
            );
          } else {
            return d.promise(d.resolve(ef._Users));
          }
        }
        return d.promise();
      },

      insert(values) {
        let d = $.Deferred();
        ef._Users.push(values);
        return d.promise(d.resolve());
      },
      key: 'Email',
      byKey: key => {
        let store: any = this;
        let d = $.Deferred();
        if (key) {
          if (ef._Users) {
            return d.promise(
              d.resolve(
                ef._Users.filter(k => {
                  return k.Email === key;
                })
              )
            );
          } else {
            let to = ef.MyForm.getEditor('Recipient');
            let ds: DevExpress.data.DataSource = to.option('dataSource');
            ds.load().done(() => {
              ds.store().insert({
                Name: ef.Item.iMain.ItemSubject || key.Email,
                Email: key.Email
              });
            });
          }
        } else {
          return d.promise(d.resolve(''));
        }
      }
    };

    let options: DevExpress.data.DataSourceOptions = {
      store: new dxCustomStore(store),
      filter: ['Email', '<>', ''],
      paginate: false
    };
    return new dxDataSource(options);
  }

  private AddressLookupOptions(): DevExpress.ui.dxTagBoxOptions {
    let ef = this;
    return {
        dataSource: ef.UserDataSource(),
      valueExpr: 'Email',
      displayExpr: 'Name',
      minSearchLength: 2,
      searchTimeout: 500,
      searchEnabled: true,
      showDataBeforeSearch: true,
      showClearButton: true,
      acceptCustomValue: true,
      showSelectionControls: true,
      placeholder: 'Begin typing a name or email...',
      noDataText:
        'The minimum amount of text used to search is 2 characters. You will then see a list of names\\email addresses that contain the characters. If there are none you may enter one. ',
      onCustomItemCreating(e) {

          function ValidateEmail(mail) {
          if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail)) {
            return true;
          }

          return false;
        }

        if (ValidateEmail(e.text)) {
          var newValue = { Name: e.text, Email: e.text },
            items = e.component.option('value'),
            ds = e.component.option('dataSource');

          (ds.store() as DevExpress.data.CustomStore)
            .insert(newValue)
            .done(() => {
              if (
                items &&
                items.length &&
                $.isArray(items) &&
                items.indexOf(newValue) < 0
              ) {
                items.push(newValue);
                e.component.option('value', items);
              } else {
                e.component.option('value', [newValue]);
              }
            });
          return newValue;
        } else {
          new Notification({ type: 'error', message: 'Invalid email.' });
        }
        return false;
      },
      tagTemplate: function (data, element) {
        let theString = `<div class="dx-tag-content" ><span>${
          data.Name
          }</span><div class="dx-tag-remove-button"></div > </div>`;
        if (data && data.Source) {
          if (data.Source === 'TDF Users') {
            element.append($(theString).addClass('btn-warning'));
          } else {
            element.append($(theString).addClass('btn-primary'));
          }
        } else {
          if (data.Email) {
            element.append($(theString).addClass('btn-danger'));
          }
        }
      },
      searchExpr: data => {
        return data.Name + ' ' + data.Email;
      }
    };
  }

  private AttachedFileBox(): DevExpress.ui.dxTagBoxOptions {
    let ef = this;
    let attachIDs = ef.AttachMents.map(a => a.ID);

    return {
      dataSource: ef.AttachmentDataSource(),
      value: attachIDs,
      valueExpr: 'ID',
      displayExpr: 'Name',
      visible: true,
      showDataBeforeSearch: false,
      placeholder: 'No attachments selected',
      noDataText: 'No attachments selected',
      onSelectionChanged(e: any) {
        ef.AttachMents = e.component._selectedItems;

        if (
          ef.FormDataForFile &&
          e.removedItems[0] &&
          ef.FormDataForFile.has('file')
        ) {
          let remainingFiles = ef.FormDataForFile.getAll('file').filter((a: any) => {
            return a.name !== e.removedItems[0].Name;
          });

          ef.FormDataForFile.delete('file');

          if (remainingFiles.length) {
            remainingFiles.forEach(file => {
              ef.FormDataForFile.append('file', file);
            });
          }
        }
      },
      onFocusIn: (e: any) => {
        ef.FindAttachmentsInSRF();
      },
      onOpened: e => {
        (e.component as DevExpress.ui.dxTagBox).close();
      }
    };
  }

  private TextFieldOptions(): DevExpress.ui.dxTextBoxOptions {
    let ef = this;
    return {
      value: ef.InitialSubject
    };
  }

  private EmailBodyOptions(): DevExpress.ui.dxTextAreaOptions {
    let ef = this;
    return {
      value: ef.InitialBody
    };
  }

  private FormItems(): DevExpress.ui.dxFormSimpleItem[] {
    let ef = this;
    let item: DevExpress.ui.dxFormSimpleItem = {
      validationRules: [
        {
          type: 'custom',
          validationCallback: function (options) {
            if (!ef.Editor.option('value')) {
              let message: any = {};
              message.text = $('<span />').text(
                'This email has no message content. Would you like to send it anyway?'
              );
              message.buttons = $('<div />')
                .append(
                  $('<span />')
                    .css('margin', '0 5px')
                    .append(
                      $('<div />').dxButton({
                        text: 'OK',
                        icon: 'todo',
                        type: 'success',
                        onClick: function (e: any) {
                          let toast = $($(e.element[0]).parents())
                            .find(
                              '.dx-overlay.dx-widget.dx-visibility-change-handler.dx-toast'
                            )
                            .dxToast('instance');
                          toast.hide();
                          ef.Send();
                          return true;
                        }
                      })
                    )
                )
                .append(
                  $('<span />')
                    .css('margin', '0 5px')
                    .append(
                      $('<div />').dxButton({
                        text: 'Cancel',
                        icon: 'revert',
                        type: 'danger',
                        onClick: function (e: any) {
                          let toast = $($(e.element[0]).parents())
                            .find(
                              '.dx-overlay.dx-widget.dx-visibility-change-handler.dx-toast'
                            )
                            .dxToast('instance');
                          toast.hide();

                          return false;
                        }
                      })
                    )
                );
              new Notification({
                type: 'warning',
                shading: true,
                displayTime: 2000000,
                message: message
              });
            } else {
              return true;
            }
          }
        }
      ]
    };

    let items: DevExpress.ui.dxFormGroupItem[] = [
      {
        itemType: 'group',
        caption: 'Address Information',
        name: 'AddressInfo',
        colCountByScreen: {
          lg: 2,
          md: 2,
          sm: 2,
          xs: 1
        },
        colSpan: 2,
        items: [
          {
            dataField: 'Recipient',
            label: { text: 'To', location: 'top' },
            colSpan: 2,
            isRequired: true,

            editorType: 'dxTagBox',
            helpText:
              'Select desired email or type an address and hit the [Enter] key.',
            editorOptions: ef.AddressLookupOptions()
          },
          {
            dataField: 'CarbonCopy',
            label: { text: 'CC', location: 'top' },
            colSpan: 2,
            editorType: 'dxTagBox',
            editorOptions: ef.AddressLookupOptions()
          },
          {
            colSpan: 2,
            dataField: 'BCC',
            label: {
              text: GetDevice().isDevice
                ? 'Self BCC'
                : 'Send this to yourself as Blind Carbon Copy'
            },
            editorType: 'dxSwitch',
            editorOptions: {
              switchedOnText: 'Yes',
              switchedOffText: 'No',
              width: 'auto'
            }
          },
          {
            colSpan: 2,
            dataField: 'Files',
            editorType: 'dxTagBox',
            label: { text: 'Attachments' },
            editorOptions: ef.AttachedFileBox()
          }
        ]
      },
      {
        itemType: 'group',
        caption: 'Subject & Body',
        name: 'SubjectBody',
        colCountByScreen: {
          lg: 2,
          md: 2,
          sm: 2,
          xs: 1
        },
        colSpan: 2,
        items: [
          {
            dataField: 'Subject',
            colSpan: 2,
            label: { text: 'Subject', location: 'top' },
            editorType: 'dxTextBox',
            editorOptions: {
              value: ef.InitialSubject,
              onValueChanged: e => {
                if (e.value) {
                  ef.InitialSubject = e.value;
                }
              }
            }
          },
          {
            dataField: 'Body',
            colSpan: 2,
            isRequired: true,
            label: { text: 'Message', visible: false },
            template(data, element) {
              // let container = $("<div />").css({ "min-height": "200px", border: "1px solid" }).append($("<div id='bodyofemail' contenteditable='true' />").css({ "min-height": "200px" })).appendTo(element);
              $("<div id='bodyofemail' />").appendTo(element);

              let timer = setInterval(() => {
                if ($('#bodyofemail').length) {
                  if (ef.Editor) {
                    let data = ef.Editor.option('value');
                    ef.RenderEditor();
                    if (data) {
                      ef.Editor.option('value', data);
                    }
                  }
                  clearInterval(timer);
                }
              }, .1e3);
            }
          }
        ]
      }
    ];
    return items;
  }

  private ToolbarButtons(): DevExpress.ui.dxPopupToolbarItem[] {
    let ef = this;

    let items: DevExpress.ui.dxPopupToolbarItem[] = [
      {
        location: 'before',
        toolbar: 'top',
        widget: 'dxMenu',

        options: ef.DraftMenuOptions()
      },

      <any>{
        visible: ef.IsEmailDetails,
        location: GetDevice().isDevice ? 'after' : 'center',
        toolbar: 'bottom',
        widget: 'dxSwitch',

        options: {
          switchedOnText: 'Include Data',
          switchedOffText: 'Just Links',
          width: 'auto',
          onValueChanged(e) {
            ef.IncludeItemData = e.value;
          }
        }
        // text: "Include Item Data ?"
      },
      {
        widget: 'dxButton',
        disabled: false,
        location: 'after',
        toolbar: 'bottom',

        options: {
          text: GetDevice().isDevice ? '' : 'Search For Attachments',
          icon: 'fa fa-search',
          type: 'normal',
          onClick() {
            ef.FindAttachmentsInSRF();
          }
        }
      },

      {
        widget: 'dxButton',
        disabled: false,
        location: 'after',
        toolbar: 'bottom',
        visible: true,
        options: {
          text: GetDevice().isDevice ? '' : 'Add Attachments',
          icon: 'fa fa-link',
          type: 'warning',
          onClick() {
            ef.ListAttachmentFiles(ef.Item.ItemId, ef.TempListFiles);
          }
        }
      },
      {
        widget: 'dxButton',
        disabled: false,
        location: 'after',
        toolbar: 'bottom',
        options: {
          text: GetDevice().isDevice ? '' : 'Add File',
          icon: 'upload',
          type: 'info',
          onClick() {
            $('#thefileuploadform').remove();
            if (!$('#thefileuploadform').length) {
              $('body').append(
                $('<form />')
                  .attr({
                    id: 'thefileuploadform',
                    enctype: 'multipart/form-data'
                  })
                  .append(
                    $('<input />')
                      .addClass('hidden')
                      .attr({
                        type: 'file',
                        name: 'file',
                        accepts: 'image/*',
                        capture: 'camera',
                        id: 'thefileupload'
                      })
                      .on('change', function (e) {
                        if (!ef.FormDataForFile) {
                          ef.FormDataForFile = new FormData();
                        }

                        ef.FormDataForFile.append(
                          'file',
                          (this as HTMLInputElement).files[0]
                        );
                        //ef.FormDataForFile = formdata;

                        let filebox = ef.MyForm.getEditor('Files');
                        let ds: DevExpress.data.DataSource = filebox.option(
                          'dataSource'
                        );
                        (ds.store() as DevExpress.data.CustomStore).insert([
                          {
                            Name: (this as HTMLInputElement).files[0].name,
                            ID: (this as HTMLInputElement).files[0].name
                          }
                        ]);
                        ds.reload();
                        filebox.option('value', ef.AttachMents.map(a => a.ID));
                      })
                  )
              );
            }

            let timer = setInterval(() => {
              if ($('#thefileupload').length) {
                clearInterval(timer);
                $('#thefileupload')
                .click();
              }
            }, .1e3);
          }
        }
      },
      {
        widget: 'dxButton',
        disabled: false,
        location: 'after',
        toolbar: 'bottom',

        options: {
          text: GetDevice().isDevice ? '' : 'Send Email',
          icon: 'email',
          type: 'success',
          useSubmitBehavior: false,
          onClick() {
            if (ef.Editor.option('value')) {
              ef.Send();
            } else {
              let message: any = {};
              message.text = $('<span />').text(
                'This email has no message content. Would you like to send it anyway?'
              );
              message.buttons = $('<div />')
                .append(
                  $('<span />')
                    .css('margin', '0 5px')
                    .append(
                      $('<div />').dxButton({
                        text: 'OK',
                        icon: 'todo',
                        type: 'success',
                        onClick: function (e: any) {
                          let toast = $($(e.element[0]).parents())
                            .find(
                              '.dx-overlay.dx-widget.dx-visibility-change-handler.dx-toast'
                            )
                            .dxToast('instance');
                          toast.hide();
                          ef.Send();
                          return;
                        }
                      })
                    )
                )
                .append(
                  $('<span />')
                    .css('margin', '0 5px')
                    .append(
                      $('<div />').dxButton({
                        text: 'Cancel',
                        icon: 'revert',
                        type: 'danger',
                        onClick: function (e: any) {
                          let toast = $($(e.element[0]).parents())
                            .find(
                              '.dx-overlay.dx-widget.dx-visibility-change-handler.dx-toast'
                            )
                            .dxToast('instance');
                          toast.hide();
                          return;
                        }
                      })
                    )
                );
              new Notification({
                type: 'warning',
                shading: true,
                displayTime: 2000000,
                message: message
              });
            }
          }
        }
      },
      {
        widget: 'dxButton',
        disabled: false,
        location: 'after',
        toolbar: 'bottom',
        options: {
          text: GetDevice().isDevice ? '' : 'Cancel',
          icon: 'remove',
          type: 'danger',
          onClick() {
            ef.MyDialog.close();
          }
        }
      }
    ];
    return items;
  }

  private Render() {
    let ef = this;

    //if this has an id and type we also get the contacts for the parent
    if (ef.Item && ef.Item.ItemId && ef.Item.ItemType)
      ef.UserSource = `${ef.UserSource}?itemid=${ef.Item.ItemId}&itemtype=${
        ef.Item.ItemType
        }`;
    let options: DevExpress.ui.dxFormOptions = {
      items: ef.FormItems(),
      //height: '100%',
      colCountByScreen: {
        lg: 2,
        md: 2,
        sm: 2,
        xs: 1
      },

      //  labelLocation: "top",
      showColonAfterLabel: false
    };
    ef.MyForm = $('<div />')
      .dxForm(options)
      .dxForm('instance');

    let thePopupOptions: DevExpress.ui.dxPopupOptions = {
      contentTemplate: element => {
        element.append(ef.MyForm.element());
      },
      showCloseButton: true,
      resizeEnabled: true,
      //   onShown(e) {
      //   ef.RenderEditor();

      // ef.MyForm.getEditor("Recipient").option("value",  [{ Email: ef.InitialAddress, Name: ef.Item.iMain.ItemSubject }])
      // },
      height: 'auto',
      title: 'Email',
      toolbarItems: ef.ToolbarButtons()
    };

    ef.MyDialog = new Dialog({
      id: 'tempemaildiv',
      buttons: ef.ToolbarButtons(),
      title: 'Email',
      closable: true,
      body: ef.MyForm.element()
    }, null, true, false);
    ef.MyDialog.open().done(() => {
      ef.RenderEditor();
      if (
        ef.Item &&
        (ef.Item.ItemType === itemTypes.itemContact ||
          ef.Item.ItemType === itemTypes.itemLead)
      ) {
        if (ef.Item.iMain.ItemSubject && ef.InitialAddress) {
          ef.MyForm.getEditor('Recipient').option('value', [
            { Name: ef.Item.iMain.ItemSubject, Email: ef.InitialAddress }
          ]);
        }
      }
      if (ef.InitialAddress) {
        ef.MyForm.getEditor('Recipient').option('value', [
          { Name: ef.InitialAddress, Email: ef.InitialAddress }
        ]);
      }
    });
  }

  private RenderEditor() {
    let ef = this;

    ef.Editor = $(`#bodyofemail`).dxHtmlEditor(<DevExpress.ui.dxHtmlEditorOptions>{
      toolbar: {
        items: [
          {
            widget: "dxButton",
            options: <DevExpress.ui.dxButtonOptions>{
              icon: "clock",
              text: "",
              stylingMode: "text",
              onClick() {
                let insertion = 0;
                let name = ` ${CurrentUser.FullName} `;
                let timestamp = `${Globalize.formatDate(new Date(), { datetime: "short" })} `;
                let range = ef.Editor.getSelection();
                if (range.length == 0) {
                  insertion = range.index;
                } else {
                  ef.Editor.formatText(range.index, range.length, { strike: true });
                  insertion = ef.Editor.getSelection();
                  ef.Editor.setSelection(range.index + range.length, 1);
                  range = ef.Editor.getSelection();
                  insertion = range.index;
                }
                ef.Editor.insertText(insertion, name, { strike: false });
                ef.Editor.insertText(insertion + name.length, timestamp, {
                  'bold': true,
                  'strike': false
                });
              }
            }
          },
          {
            widget: "dxButton",
            options: <DevExpress.ui.dxButtonOptions>{
              icon: "doc",
              text: "",
              stylingMode: "text",
              onClick() {
                let body = $("#tdfbodycontent");

                body.remove(`#templatepopup${DomSafeID(ef.Item.ItemId)}`);
                body.append(`<div id="templatepopup${DomSafeID(ef.Item.ItemId)}" />`);
                let popup = $(`#templatepopup${DomSafeID(ef.Item.ItemId)}`).dxPopup({
                  contentTemplate(el) {
                    el.append(`<div id="templategrid${DomSafeID(ef.Item.ItemId)}" />`);
                  }
                }).dxPopup("instance");
                popup.show();

                //TODO: Move this to a service class
                new TDFRequest({ url: `core/user/${itemTypes.itemEmail}/listdrafts` }).MakeRequest().done(drafts => {
                  let grid = $(`#templategrid${DomSafeID(ef.Item.ItemId)}`).dxDataGrid({
                    dataSource: drafts,
                    columnAutoWidth: true,
                    selection: {
                      mode: "single"
                    },
                    //height: "100%",
                    width: "auto",
                    showRowLines: true,
                    showColumnHeaders: false,
                    rowAlternationEnabled: true,
                    showBorders: true,
                    loadPanel: {
                      enabled: false
                    },
                    groupPanel: {
                      visible: true
                    },
                    grouping: {
                      autoExpandAll: true,
                      allowCollapsing: true,
                      expandMode: "rowClick"
                    },
                    searchPanel: {
                      visible: true,
                      width: 250
                    },
                    scrolling: {
                      mode: 'standard',
                      showScrollbar: "always",
                    },
                    columns: [

                      {
                        dataField: "Title",
                        caption: "Template Title",
                        sortIndex: 0
                      },
                      {
                        dataField: "ItemSpecific",
                        visible: false
                      },
                      {
                        dataField: "Scope",
                        caption: "Template Type",
                        groupIndex: 0,
                        showWhenGrouped: false,
                        visible: false
                      },
                      {
                        dataField: "Template",
                        visible: false
                      },
                      {
                        dataField: "TemplateID",
                        visible: false
                      },

                      {
                        dataField: "Sort",
                        visible: false
                      },

                    ],
                    onContentReady(e) {
                      e.element.find(".dx-loadindicator-icon").addClass("hidden");
                    },
                    onSelectionChanged: function (selectedItems) {
                      var data = selectedItems.selectedRowsData[0];
                      var component = selectedItems.component;
                      if (data) {

                        new TDFRequest({ url: `core/user/${data.TemplateID}/${ef.Item.ItemId}/${ef.Item.ItemType}/getdraft` }).MakeRequest()
                          .done(function (response, xhr, status) {
                            ef.Editor.option("value", response.Template);
                            component.clearSelection();
                            popup.hide();
                          })

                      }
                    }
                  }).dxDataGrid("instance");
                });

              }
            }

          },
          "separator",
          "undo",
          "redo",
          "clear",
          "separator",
          "bold",
          "color",
          "italic",
          "separator",
          "link",
          "image",
          "separator",
          "strike",
          "subscript",
          "superscript",
          "underline",
          "blockquote",
          "header",
          "separator",
          "increaseIndent",
          "decreaseIndent",
          "separator",
          "orderedList",
          "bulletList",
          "separator",
          "alignLeft",
          "alignCenter",
          "alignRight",
          "alignJustify",
          "separator",
          "background",
          "codeBlock",
        ]
      },
      value: ef.InitialBody,
      height: () => {
        return ef.MyDialog.getModalBody().height() - ef.MyForm.element().height();
      }
    }).dxHtmlEditor("instance");
  }

  private Send() {
    let ef = this;
    let recipients = [];
    let cc = [];
    let values: any[] = ef.MyForm.getEditor('Recipient').option('value');
    let ccs = ef.MyForm.getEditor('CarbonCopy').option('value');
    recipients = values.map((v, i) => {
      let email = typeof v === 'string' ? v : v.Email ? v.Email : '';
      return email;
    });
    cc = ccs.map((v, i) => {
      let email = typeof v === 'string' ? v : v.Email ? v.Email : '';
      return email;
    });

    if (recipients) {
      let params: any = {
        bcc: ef.MyForm.getEditor('BCC').option('value'),
        carboncopy: cc.join(','),
        //itemid: ef.Item.ItemId,
        //itemtype: ef.Item.ItemType,
        message: ef.Editor.option('value'),
        recipient: recipients.join(','),
        subject: ef.MyForm.getEditor('Subject').option('value')
      };

      if (ef.Item) {
        params.itemid = ef.Item.ItemId;
        params.itemtype = ef.Item.ItemType;
      }

      if (ef.IsEmailDetails) {
        params['includedata'] = ef.IncludeItemData;
      }
      if (ef.AttachMents.length > 0) {
        params['includeattachments'] = true;
        let attachIDs = ef.AttachMents.map(a => a.ID);
        params.filelist = attachIDs;
      }

      let validation = ef.MyForm.validate();
      if (validation.isValid) {

        let formDataObj = new FormData();

        for (const field in params) {
          if (params.hasOwnProperty(field)) {
            const val = params[field];
            if (val !== null && val !== undefined) {
              formDataObj.append(field, val);
            }
          }
        }

        if (ef.FormDataForFile) {
          ef.FormDataForFile.forEach((val, key) => {
            formDataObj.append(key, val);
          });
        }

        let request = new XMLHttpRequest();
        request.open(
          'POST',
          `${TDFRequest.ApiPath}/Email/${
          ef.IsEmailDetails ? 'sendemail' : 'send'
          }`,
          true
        );
        request.withCredentials = true;
        request.setRequestHeader('Accept', '*/*');
        request.onprogress = (event => {
          new Notification({
            message: (<string>request.response).replace(/\"/g, ''),
            type: request.status === 200 ? 'success' : 'error',
            displayTime: 2e3
          });

          if (request.status === 200) {
            ef.MyDialog.close();
          }
        });

        TDFRequest.GetTokenFromCookie().done(token => {
          request.setRequestHeader('AUTHORIZATION', `Bearer ${token}`);

          request.send(formDataObj);
        })
      }
    } else {
      new Notification({ message: 'Could not get value from TO field' });
    }
    return ef;
  }

  private getCheckAttachmentsButton(SRF: SearchRecFav) {
    let ef = this;
    let files = [];

    return {
      widget: 'dxButton',
      disabled: false,
      location: 'after',
      toolbar: 'bottom',

      options: {
        text: 'View Attachments',
        type: 'warning',
        icon: 'add',
        onClick() {
          let selected = SRF.GetSelected();
          if (selected) {
            ef.ListAttachmentFiles(selected[0]['TDF GUID'], ef.TempListFiles);
          }
        }
      }
    };
  }

  FindAttachmentsInSRF() {
    let ef = this;

    let SRF: SearchRecFav = new SearchRecFav(
      itemTypes.itemEmail,
      [999],
      itemTypes.itemUnknown,
      false
    );
    SRF.extraButton = ef.getCheckAttachmentsButton(SRF);

    SRF.GetSRFDialog().done(selected => {
      ef.UpdateAttachments();
      SRF.Dialog.close();
    });
  }

  UpdateAttachments() {
    let ef = this;

    let filebox = ef.MyForm.getEditor('Files');
    filebox.option('visible', true);

    let ds: DevExpress.data.DataSource = filebox.option('dataSource');
    (ds.store() as DevExpress.data.CustomStore).insert(ef.TempListFiles);
    ds.reload();
    filebox.option('value', ef.AttachMents.map(a => a.ID));
    ef.TempListFiles = [];
  }

  ListAttachmentFiles(itemID, filesTarget = this.AttachMents) {
    let ef = this;
    let files = [];
    let request = new TDFRequest({
      url: '/attachment/list/',
      type: 'POST',
      data: { itemid: itemID }
    });

    request.MakeRequest().done(function (response: any) {
      let gridID = 'attachmentsGrid_' + DomSafeID(itemID);
      let html = "<div id='" + gridID + "'></div>";

      let attachDialog = new Dialog({
        title: 'Select Attachments',
        id: 'item-attachments',
        body: html,
        closable: true,
        size: 'size-normal',
        buttons: [
          {
            widget: 'dxButton',
            toolbar: 'bottom',
            location: 'after',
            options: {
              text: 'OK',
              type: 'success',
              icon: 'check',
              onClick: function () {
                $.each(files, (k, v) => {
                  filesTarget.push({
                    Name: v.Subject,
                    ID: v.AttachmentID
                  });
                });
                ef.UpdateAttachments();
                //if (filesTarget === ef.AttachMents) {
                //    ef.CreateAttachmentTagBox();
                //}

                attachDialog.close();
              }
            }
          },
          {
            widget: 'dxButton',
            toolbar: 'bottom',
            location: 'after',
            options: {
              text: 'Cancel',
              type: 'danger',
              icon: 'remove',
              onClick: function () {
                attachDialog.close();
              }
            }
          }
        ]
      });
      attachDialog.open().done(function (response2) {
        $('#' + gridID).dxDataGrid({
          dataSource: response,
          columns: [
            {
              dataField: 'Subject',
              caption: 'File Name'
            },
            {
              dataField: 'Created',
              caption: 'Date Created',
              dataType: 'date',
              calculateCellValue: function (data: any) {
                let theDate = moment(new Date(data.Created)).toISOString();
                return theDate;
              },
              sortOrder: 'desc',
              visible: false,
              hidingPriority: 0
            },
            {
              dataField: 'AttachmentID',
              visible: false
            }
          ],
          paging: {
            pageSize: 10
          },
          pager: {
            showPageSizeSelector: true,
            allowedPageSizes: [5, 10, 20],
            showInfo: true
          },
          selection: {
            mode: 'multiple',
            allowSelectAll: true
          },
          masterDetail: {
            enabled: true,
            template: function (container, options) {
              let curData: any[] = [];
              curData.push(options.data);
              $('<div />')
                .append($('<strong>').text(curData[0].Subject))
                .attr({ style: 'padding:5px;' })
                .addClass('bg-info')
                .appendTo(container);
              $('<div />')
                .addClass('internal-grid')
                .dxDataGrid({
                  dataSource: curData,
                  columnAutoWidth: true,
                  columns: [
                    {
                      dataField: 'Created',
                      caption: 'Date Created',
                      dataType: 'date',
                      calculateCellValue: function (data: any) {
                        let theDate = moment(
                          new Date(data.Created)
                        ).toISOString();
                        return theDate;
                      }
                    },
                    {
                      dataField: 'Size',
                      dataType: 'number'
                    },
                    {
                      dataField: 'Type',
                      caption: 'File Type',
                      dataType: 'string'
                    }
                  ]
                })
                .appendTo(container);
            }
          },
          onSelectionChanged: function (data) {
            let selected = data.currentSelectedRowKeys;
            let that = ef;
            if (selected.length > 0) {
              files.push(selected[0]);
            }
          }
        });
      });
    });
  }
}
