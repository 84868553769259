import { itemTypes } from "../../enums/enums";
import { ItemBase } from "./itembase";
import { DisplayInfo } from "../../infrastructure/context";

    /*
     * Todo: make this class not hard-coded to any particular item type;
     * That is, eliminate the need for it to exist at all for "simple" item types of the future.
     * (ItemType, Typename, RequiresParentItem, etc - should be passed in via the constructor and not readonly here)
     * 
     */ 
        export class GenericRecord extends ItemBase {
        DisplayName: string;
        get ItemType() { return itemTypes.itemLiterature; }
        get TypeName() { return "Literature" };
        set TypeName(name) { if (name && this.TypeName !== name) this.TypeName = name; }
        get RequiresParentItem(): boolean { return true; }
        constructor(args) {
            super(args);
            let theLiterature = this;
            $.each(args, function (key, val) {
                if (theLiterature.hasOwnProperty(key)) theLiterature[key] = val;
            });

            if (!theLiterature.DisplayName) {

               DisplayInfo(theLiterature.ItemType).done(function (displayinfo) {
                    theLiterature.DisplayName = displayinfo.DisplayName;
                    theLiterature.TypeName = displayinfo.TypeName;
                })
            }

        }
    }

