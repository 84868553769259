import {
  itemDescriptor,
  ICombineItemsCommand,
  IFetchContacts
} from '../../interfaces/admin/interfaces';
import { TDFRequest } from '../request';
import { ServerCommands } from '../../util/servercommands';
export class ItemCombinerDataService {
  private readonly _urlBase: string = 'admin/combine-items';

  public GetItemInfo(items: itemDescriptor): TDFRequest {
    let that = this;
    return new TDFRequest({
      url: `${TDFRequest.ApiPath}/util/item-info`,
      type: 'POST',
      data: items
    });
  }

  public InitiateCombine(data: ICombineItemsCommand): TDFRequest {
		let that = this;

		let wrapper = {
			"Apply": { "Options": data }
		};

		let command: ServerCommands.ServerCommand = {
			With: null,
			Apply: { Options: data, Async: true }
		}

		return new TDFRequest({ url: `${TDFRequest.ApiPath}/commands/combine-items`, type: "POST", data: command });

    //return new TDFRequest({
    //  url: `${TDFRequest.ApiPath}/commands/combine-items`,
    //  type: 'POST',
    //  data: data
    //});

  }

  public GetContactsAtAccounts(model: IFetchContacts): TDFRequest {
    let that = this;
    return new TDFRequest({
      url: `${TDFRequest.ApiPath}/util/fetch-contacts`,
      type: 'POST',
      data: model
    });
  }
}
