import * as Globalize from "globalize";
import * as moment from "moment";

import { Notification } from "../../components/dialogs/notification";
import { BIGeneralSummaryStyle } from "../../enums/bi/enums";
import { CenterType, itemTypes } from "../../enums/enums";
import { AddHandler2, RaiseEvent2 } from "../../infrastructure/events/ui_events";
import { eventNameSpace, EventTypes } from "../../enums/webevents/enums";
import { GetPreference } from "../../infrastructure/user/getpreference";
import { Preferences } from "../../infrastructure/user/preferences";
import { TDFDataCenters } from "../../interfaces/datacenters/tdfdatacenters";

import { TDFRequest } from "../../services/request";
import { DomSafeID } from "../../util/allutils";
import "devextreme/ui/accordion";
import "devextreme/ui/scroll_view";
import "devextreme/ui/select_box";
import { CurrentUser, LoadUser } from "../../infrastructure/context";
import { DataScopeSelection } from "../../components/dashboards/dynamicdatascopeselector";
import {
  GetBIPrefModuleFromBISummarySource,
  GetBISummarySourceFromCenterType
} from "./bi_utils";

export class GeneralSummary {
  private GeneralSummaryStyleProperty: string = "GeneralSummaryStyle";
  private GeneralSummaryStyle: BIGeneralSummaryStyle;
  private generalSummaryData: any;

  private CustomNum: number;
  private CustomID: string;
  private _container: JQuery;
  private _CenterType: CenterType;

  private _tabItemData: TDFDataCenters.DataCenterTabs.TabItem;
  private EventLimiterName: string = "";

  private SummarySettings: DataScopeSelection;
  constructor(
    container: JQuery,
    centertype: CenterType,
    tabItemData: TDFDataCenters.DataCenterTabs.TabItem,
    eventLimiterName: string
  ) {
    this._container = container;
    this._CenterType = centertype;
    this._tabItemData = tabItemData;
    this.EventLimiterName = eventLimiterName;

    let preferenceModule = GetBIPrefModuleFromBISummarySource(GetBISummarySourceFromCenterType(this._CenterType));
    this.GeneralSummaryStyle = <BIGeneralSummaryStyle>parseInt(GetPreference(this.GeneralSummaryStyleProperty, preferenceModule));

    this.CustomNum = Math.ceil(Math.random() * 999 + 1);
    this.CustomID = DomSafeID(
      this._tabItemData.GUID + "_" + this.CustomNum.toString()
    );

    this.Init();
  }

  Init() {
    let theGeneralSummaryTab = this;
    LoadUser().done(() => {
      this.AddListeners();

      let sendDFD: JQueryDeferred<DataScopeSelection> = $.Deferred();
      RaiseEvent2(
        EventTypes.BIEventTypes.currentsummaryselection,
        theGeneralSummaryTab.EventLimiterName,
        eventNameSpace.request,
        { deferred: sendDFD }
      );

      sendDFD.done(function (scopeSettings) {
        theGeneralSummaryTab.SummarySettings = scopeSettings;
        theGeneralSummaryTab.RenderTab();
      });
    });
  }

  private AddListeners() {
    let theGeneralSummaryTab = this;
    AddHandler2(
      EventTypes.BIEventTypes.currentsummaryselection,
      theGeneralSummaryTab.EventLimiterName,
      eventNameSpace.notify,
      theGeneralSummaryTab._container,
      theGeneralSummaryTab.OnUpdateSummarySettings.bind(theGeneralSummaryTab)
    );
    AddHandler2(
      EventTypes.BIEventTypes.currentgoalselection,
      theGeneralSummaryTab.EventLimiterName,
      eventNameSpace.notify,
      theGeneralSummaryTab._container,
      theGeneralSummaryTab.OnUpdateGoalSettings.bind(theGeneralSummaryTab)
    );
    AddHandler2(
      EventTypes.CenterEventTypes.gridrefresh,
      theGeneralSummaryTab.EventLimiterName,
      eventNameSpace.request,
      theGeneralSummaryTab._container,
      theGeneralSummaryTab.OnRefreshButtonClicked.bind(theGeneralSummaryTab)
    );
  }

  private OnRefreshButtonClicked(e: JQueryEventObject, data) {
    let theGeneralSummaryTab = this;
    theGeneralSummaryTab.ReRenderTab();
  }

  private OnUpdateSummarySettings(e: JQueryEventObject, data) {
    let theGeneralSummaryTab = this;
    theGeneralSummaryTab.SummarySettings = data.NewSummarySettings;
    theGeneralSummaryTab.ReRenderTab();
  }

  private OnUpdateGoalSettings(e: JQueryEventObject, data) {
    // We don't need to set anything out becuase changing the goal settings updates the user preferences, and we use the user preference.
    // But, we need to force a re-render to use the new settings.
    let theGeneralSummaryTab = this;
    theGeneralSummaryTab.ReRenderTab();
  }

  private ClearCachedData() {
    let theGeneralSummaryTab = this;
    theGeneralSummaryTab.generalSummaryData = undefined;
  }
  private ReRenderTab() {
    let theGeneralSummaryTab = this;
    theGeneralSummaryTab.ClearCachedData();
    theGeneralSummaryTab.RenderTab();
  }

  RenderTab() {
    let theGeneralSummaryTab = this;
    theGeneralSummaryTab._container.empty();


    theGeneralSummaryTab.GetGeneralSummaryData().done(function (response: any) {
      let items = [];
      if (response) {
        Object.keys(response).forEach(function (key) {
          items.push(response[key]);
        });

        let container = $("<div />").attr("id", theGeneralSummaryTab.CustomID).css("padding", "3px");
        theGeneralSummaryTab._container.append(container);
        theGeneralSummaryTab._container.dxScrollView();

        let formatValue = function (itemKey: string, itemValue, cardName?: string) {
          let formattedValue = itemValue;

          if (formattedValue !== "") {
            if (typeof itemValue != "string") {
              if (itemKey.indexOf("%") >= 0) {
                formattedValue = Globalize.formatNumber(itemValue, { style: "percent", minimumFractionDigits: 2, maximumFractionDigits: 2 });
              } else {
                if (cardName && (cardName.toLowerCase().indexOf("qty") === -1 && cardName.toLowerCase().indexOf("units") === -1)) {
                  formattedValue = Globalize.formatCurrency(itemValue, CurrentUser.Currency);
                }
              }
            } else if (!isNaN(Number(itemValue))) {
              itemValue = Globalize.parseNumber(itemValue);

              if (itemKey.indexOf("%") >= 0) {
                formattedValue = Globalize.formatNumber(itemValue, { style: "percent" });
              } else {
                if (itemKey.toLowerCase().indexOf("po number") === -1 && itemKey.toLowerCase().indexOf("buyer id") === -1) {
                  formattedValue = Globalize.formatCurrency(itemValue, CurrentUser.Currency);
                }
              }
            } else if (!isNaN(Date.parse(itemValue))) {
              itemValue = itemValue.substr(0, itemValue.indexOf("T"));
              formattedValue = Globalize.formatDate(moment(itemValue, "YYYY-MM-DD").toDate());
            }
          } else {
            formattedValue = "---";
          }

          return formattedValue;
        };

        let makeInnerCard = function (obj, itemID) {
          let cardInfo = $("<div />");
          let formattedValue = "";
          let theTitle = $("<div />");
          let theData = $("<div />");

          Object.keys(obj).forEach(function (key) {
            let value = obj[key];
            if (key != "Name") {
              if (value === null) {
                value = "";
              }
              formattedValue = formatValue(key, value, obj["Name"]);
              // store data with ptags
              $(theData).append("<p>" + key + ": " + formattedValue + "</p>");
            } else {
              formattedValue = formatValue(key, value);
              //store data with h3 tags
              $(theTitle).append("<h3>" + formattedValue + "</h3><hr/>");
            }
          });

          $(cardInfo).append(theTitle).append(theData);

          return cardInfo;
        };

        if (theGeneralSummaryTab.GeneralSummaryStyle == BIGeneralSummaryStyle.CardView) {
          let id = "GeneralSummaryCardView";
          let cardView = $("<div />").attr("id", id);
          let cardRow = $("<div />").addClass("row");
          let card = $("<div />").addClass("col-md-2");
          let innerCard = $("<div />");
          let counter = 0;

          $(items).each(function (index, val) {
            let obj = val[0];

            let itemID = "biGeneralSumData_" + index;

            if (Object.keys(obj).length > 4) {
              let cardInfo = makeInnerCard(obj, itemID);
              cardInfo.addClass("biGeneralSumHead").css("min-height", "450px");
              card.append(cardInfo);
              card.appendTo(cardRow);
              card = $("<div />").addClass("col-md-2");
            } else {
              let cardInfo = makeInnerCard(obj, itemID);
              cardInfo.addClass("biGeneralSumHead").css("min-height", "225px");
              let cardInfoContain = $("<div />").addClass("col-sm-12");
              cardInfoContain.append(cardInfo);
              innerCard.append($("<div />").addClass("row").append(cardInfoContain));
              counter++;

              if (counter == 2) {
                innerCard.appendTo(card);
                card.appendTo(cardRow);
                card = $("<div />").addClass("col-md-2");
                counter = 0;
                innerCard = $("<div />");
              }
            }
          });

          if (counter == 1) {
            innerCard.appendTo(card);
            card.appendTo(cardRow);
            card = $("<div />").addClass("col-md-2");
            counter = 0;
            innerCard = $("<div />");
          }

          $(cardView).append(cardRow);
          container.append(cardView);
        } else {
          let id = "GeneralSummaryListView";
          let listView = $("<div id='listViewWrapper' class='col-sm-4 offset-sm-3'><div id='" + id + "'></div></div>");
          container.append(listView);

          let accordion: DevExpress.ui.dxAccordion = $("#" + id).dxAccordion({
            collapsible: true,
            multiple: true,
            items: items,
            width: "100%",
            itemTitleTemplate: function (itemData, itemIndex, itemElement) {
              let dataItem = itemData[0];
              let itemID = "biGeneralSumDataTitle_" + itemIndex;
              $(itemElement).attr("id", itemID);
              itemElement.append("<h3>" + dataItem.Name + "</h3>");
            },
            itemTemplate: function (itemData, itemIndex, itemElement) {
              let dataItem = itemData[0];
              let itemID = "biGeneralSumData_" + itemIndex;
              let html = "<div id='" + itemID + "items' class='table-responsive'><table class='table summaryTable'><tbody>";

              Object.keys(dataItem).forEach(function (key) {
                if (key != "Name") {
                  let itemValue = dataItem[key];
                  if (itemValue === null) {
                    itemValue = "";
                  }

                  let formattedValue = formatValue(key, itemValue);
                  html = html + "<tr><td>" + key + "</td><td>" + formattedValue + "</td></tr>";
                }
              });

              html = html + "</tbody></table></div>";
              itemElement.append(html);
            },
            onItemRendered: function (e) {
              (e.component as DevExpress.ui.dxAccordion).expandItem(e.itemIndex);
            }
          }).dxAccordion("instance");

          $("#listViewWrapper").dxScrollView({
            showScrollbar: "always",
            bounceEnabled: false
          });
        }
      } else {
        new Notification({ message: response.Message, type: "error", displayTime: 5000 });
      }
    });
  }

  GetGeneralSummaryData(): any {
    let theGeneralSummaryTab = this;
    let dfd = $.Deferred();

    if (theGeneralSummaryTab.generalSummaryData) {
      return dfd.promise(dfd.resolve(theGeneralSummaryTab.generalSummaryData));
    } else {
      let request = new TDFRequest({
        url: "/bi/GeneralSummary",
        type: "GET",
        data: {
          biListing: theGeneralSummaryTab.SummarySettings.CurrentlySelectedItemIDsAsString(),
          biLevel: theGeneralSummaryTab.SummarySettings.ActiveSelectionLevel,
          summarySource: GetBISummarySourceFromCenterType(
            theGeneralSummaryTab._CenterType
          )
        }
      });
      request.MakeRequest().done(function (response) {
        theGeneralSummaryTab.generalSummaryData = response;
        return dfd.resolve(response);
      });
    }

    return dfd.promise();
  }

  GetTabSettingsItems() {
    let theGeneralSummaryTab = this;
    let settingsItems: any = [
      {
        template: function (itemData, itemIndex, itemElement) {
          itemElement.append(
            "<div id='chkWrapperOuterGenSumStyle' >" +
            "<div id='chkWrapperGenSumStyle'> </div>" +
            "</div> "
          );

          var selectionData = [
            {
              name: "Card View",
              value: BIGeneralSummaryStyle.CardView
            },
            {
              name: "List View",
              value: BIGeneralSummaryStyle.ListView
            }
          ];
          $("#chkWrapperGenSumStyle").append(
            $("<div />").dxSelectBox({
              dataSource: selectionData,
              valueExpr: "value",
              displayExpr: "name",
              value: theGeneralSummaryTab.GeneralSummaryStyle,
              width: "auto",
              text: "Use Card View",
              onValueChanged: function (data) {
                theGeneralSummaryTab.GeneralSummaryStyle = <BIGeneralSummaryStyle>data.value;
                let preferenceModule = GetBIPrefModuleFromBISummarySource(
                  GetBISummarySourceFromCenterType(
                    theGeneralSummaryTab._CenterType
                  )
                );
                Preferences.SetPreference(
                  theGeneralSummaryTab.GeneralSummaryStyleProperty,
                  data.value,
                  preferenceModule
                );
                theGeneralSummaryTab.RenderTab();
                /*TODO:Hey there center*/
                //if (theGeneralSummaryTab.MyBICenter.TabPanel) {
                //    //This forces the items to repaint based on the item templates and thus it'll be repainted in the new style.
                //    theGeneralSummaryTab.MyBICenter.RefreshTabDataSource();
                //}
              }
            })
          );
        }
      }
    ];

    return settingsItems;
  }
}
