import { GetDevice } from "../../util/allutils";
import notify from "devextreme/ui/notify";
 
export class Notification {
  constructor(args: DevExpress.ui.dxToastOptions, overrideposition?: string) {
    let notification: Notification = this;
    notification.Setup(args);
  }
  Setup(args: any): void {
    let notification: Notification = this;
    let typeDesc: "info" | "success" | "error" | "warning" | "custom" = "info";
    switch (args.type) {
      case 1:
      case "success":
        typeDesc = "success";
        break;
      case 2:
      case "error":
        typeDesc = "error";
        break;
      case 3:
      case "warning":
        typeDesc = "warning";
        break;
      case 4:
      case "info":
        typeDesc = "info";
        break;
    }
    let position: string = GetDevice().isDevice
      ? "bottom"
      : args.position
        ? args.position.at
        : "center";
    let offset: string =
      position === "top" ? "0 10" : position === "bottom" ? "0 -10" : "0 0";

    let toastSettings: DevExpress.ui.dxToastOptions = {
      contentTemplate: function(contentElement: JQuery): void {
        let t: JQuery;
        if (typeof args.message === "string") {
          t = $("<div />");
          let block = $("<blockquote />")
            .text(args.message)
            .css({ margin: "-35px 0 0 30px", border: "none" })
            .append($("<br>"));
          t.append(block);
          if (args.closeOnClick || typeof args.closeOnClick === "undefined") {
            block.append(
              $("<footer />")
                .text("(click to dismiss)")
                .css("color", "#fff")
                .addClass("pull-right")
            );
          }
        } else {
          t = $("<div />").append(
            $("<blockquote />")
              .append(args.message.text)
              .css({ margin: "-35px 0 0 30px", border: "none" })
              .append($("<br>"))
          );
          if (args.message.buttons) {
            t.append(
              $("<footer />")
                .append(args.message.buttons)
                .css("color", "#fff")
                .addClass("pull-right")
            );
          } else {
            if (args.closeOnClick || typeof args.closeOnClick === "undefined") {
              t.append(
                $("<footer />")
                  .text("(click to dismiss)")
                  .css("color", "#fff")
                  .addClass("pull-right")
              );
            }
          }
        }

        contentElement.append(t);
      },
      type: typeDesc,
      displayTime: args.displayTime || 3000,
      position: <DevExpress.positionConfig>{
        my: position,
        at: position + " center",
        of: window,
        offset: offset
      },
      //  onHidden: callback,//typeof callback === "function" ? callback : function () { },
      onHiding: args.onHidden || $.noop,
      closeOnClick:
        typeof args.closeOnClick !== "undefined"
          ? args.closeOnClick
          : typeof args.message === "string",
      closeOnOutsideClick:
        typeof args.closeOnOutsideClick !== "undefined"
          ? args.closeOnOutsideClick
          : true,
      shading: typeof args.shading !== "undefined" ? args.shading : false,
      shadingColor: args.shadingColor || "rgba(30, 30, 30, 0.73)",
      width: GetDevice().isDevice ? $(window).width() : $(window).width() * 0.8
    };
    if (args.animation) {
      toastSettings.animation = args.animation;
    }
    notification.Open(toastSettings);
  }
  Open(settings: DevExpress.ui.dxToastOptions): void {
           notify(settings);
  }

  static Confirmation(
    args: {
      type: "success" | "info" | "warning" | "error" | "custom";
      location: "top" | "bottom" | "middle";
    },
    confirmationOptions: {
      buttons: DevExpress.ui.dxButtonOptions[];
      notificationText: string;
      additionalHintText: string;
    }
  ) {
    let settings: DevExpress.ui.dxToastOptions = {
      displayTime: 2000000,
      closeOnBackButton: false,
      closeOnClick: false,
      closeOnOutsideClick: false,
      shading: true,
      //shadingColor:"#f0ad4e",
      hint: confirmationOptions.additionalHintText,
      closeOnSwipe: false,
      type: args.type,

      position: <DevExpress.positionConfig>{
        my: GetDevice().isDevice ? "bottom" : "center",
        at: GetDevice().isDevice ? "bottom" : "center",
        of: window,
        offset: args.location
          ? args.location === "top"
            ? "0 10"
            : args.location === "bottom"
              ? "0 -10"
              : "0 0"
          : "0 0"
      },
      width: GetDevice().isDevice ? $(window).width() : $(window).width() * 0.5,
      contentTemplate(el) {
        let footer = $(
          `<footer class="pull-right" style="color: rgb(255, 255, 255);"></footer>`
        );
        confirmationOptions.buttons.forEach((val, key) => {
          footer.append(
            $(`<span style="margin: 0px 5px;" ></span>`).append(
              $(`<div />`).dxButton(val)
            )
          );
        });
        let message = $(`
                            <div >
                            <blockquote style="margin: -45px 0px 0px 30px; border: none;">
                            <span>${confirmationOptions.notificationText}</span>
                            <br>
                            </blockquote>                           
                            </div>`)
          .append(footer)
          .appendTo(el);
      }
    };
    //  settings.contentTemplate = message;
    notify(settings);
  }

  static OkCancel() {}
  static YesNoCancel() {}
}
/**
 * A way to ask the user to confirm something
 * */
export class Confirmation extends Notification {
  constructor(args: DevExpress.ui.dxToastOptions) {
    super(args);
  }
}
