export class Calculator {
  entry: Array<string> = [];
  entries: Array<string> = [];
  currOperator: string = "";
  isEntryBlank: boolean = true;
  theHtml: string = '<div class="clearfix row" id="wrapper">' +
    '  <div id="calcmain" >' +
    '    <div class="col-md-12" id="screen"></div>' +
    '    <div class="row">' +
    '<div class="col-md-12">' +
    '      <button class="calcbtn col-md-3 darker fa fa-long-arrow-left" id="back"> </button>' +
    '      <button class="calcbtn col-md-3 darker" id="clear">AC</button>' +
    '      <button class="calcbtn col-md-3 darker">+/-</button>' +
    '      <button class="calcbtn col-md-3 darker" id="divide">÷</button>' +
    '    </div>' +
    '    </div>' +
    '    <div class="row"> ' +
    '<div class="col-md-12">' +
    '      <button class="calcbtn col-md-3">7</button>' +
    '      <button class="calcbtn col-md-3">8</button>' +
    '      <button class="calcbtn col-md-3">9</button>' +
    '      <button class="calcbtn col-md-3 darker" id="multiply">×</button>' +
    '    </div>' +
    '    </div>' +
    '    <div class="row">' +
    '<div class="col-md-12">' +
    '      <button class="calcbtn col-md-3">4</button>' +
    '      <button class="calcbtn col-md-3">5</button>' +
    '      <button class="calcbtn col-md-3">6</button>' +
    '      <button class="calcbtn darker col-md-3" id="add">+</button>' +
    '    </div>' +
    '    </div>' +
    '    <div class="row">' +
    '<div class="col-md-12">' +
    '      <button class="calcbtn col-md-3">1</button>' +
    '      <button class="calcbtn col-md-3">2</button>' +
    '      <button class="calcbtn col-md-3">3</button>' +
    '      <button class="calcbtn darker col-md-3" id="subtract">-</button>' +
    '    </div>' +
    '    </div>' +
    '    <div class="row">' +
    '<div class="col-md-12">' +
    '      <button class="calcbtn col-md-6">0</button>' +
    '      <button class="calcbtn col-md-3">.</button>' +
    '      <button class="calcbtn col-md-3 blue">=</button>' +
    '    </div>' +
    '    </div>' +
    '  </main>' +
    '</div>';
  constructor(target?) {
    let calc = this;
    $("#" + target).append($("<div />").html(calc.theHtml));
    calc.init();
  }
  addToEntry(val) {
    let calc = this;
    calc.entry.push(val);
  }
  clearAll() {
    let calc = this;
    calc.entry = [];
    calc.entries = [];
  }
  clearEntry() {
    let calc = this;
    calc.entry = [];
  }
  backspaceEntry() {
    let calc = this;
    calc.entry.pop();
  }
  toggleNegative() {
    let calc = this;
    if (calc.entry[0] !== '-') {
      calc.entry.unshift('-');
    }
    else if (calc.entry[0] === '-') {
      calc.entry.shift();
    }
  }
  insertDecimal() {
    let calc = this;
    if (calc.entry.indexOf('.') === -1 && calc.entry.length < 1) {
      calc.entry.push('0', '.');
    }
    else if (calc.entry.indexOf('.') === -1) {
      calc.entry.push('.');
    }
  }
  calculate(operator) {
    let calc = this;
    // reset chain of operations if "=" is pressed with no operator afterwords
    if (calc.currOperator === "=" && calc.entry.length > 0) {
      calc.entries = [];
    }
    // if current entry isn't blank, add to entries
    if (calc.entry.length > 0) {
      calc.entries.push(calc.entry.join(''));
    }
    // perform operation for every two entries
    if (calc.entries.length >= 2) {
      calc.entries.splice(1, 0, calc.currOperator);
      let total = eval(calc.entries.join(' '));
      calc.entries = [total];
      calc.currOperator = '=';
    }
    if (operator) {
      calc.currOperator = operator;
    }
    calc.clearEntry();
  }
  init() {
    let calc = this;
    calc.render();
    $(".calcbtn").click(function () {
      // let btnText: string = $(this).text();
      let btnVal: any = $(this).text();
      if (!isNaN(parseInt(btnVal))) {
        btnVal = parseInt(btnVal);
      }
      if (btnVal === "=") {
        calc.calculate('=');
      }
      else if (btnVal === "+/-") {
        calc.toggleNegative();
      }
      else if (btnVal === "AC") {
        calc.clearAll();
      }
      else if (btnVal === 'C') {
        calc.clearEntry();
      }
      else if (btnVal === ' ') {
        calc.backspaceEntry();
      }
      else if (btnVal === "+") {
        calc.calculate('+');
      }
      else if (btnVal === "-") {
        calc.calculate('-');
      }
      else if (btnVal === "×") {
        calc.calculate('*');
      }
      else if (btnVal === "÷") {
        calc.calculate('/');
      }
      else if (btnVal === ".") {
        calc.insertDecimal();
      }
      else {
        calc.addToEntry(btnVal);
      }
      calc.render();
    });
  }
  getScreenVal(): any {
    let calc = this;
    if (calc.entry.length > 0) {
      calc.isEntryBlank = false;
      return calc.entry.join('');
    }
    else if (calc.entry.length === 0 && calc.entries[0]) {
      calc.isEntryBlank = true;
      return calc.entries[0];
    }
    else {
      calc.isEntryBlank = true;
      return 0;
    }
  }
  getCurrentOperator() {
    let calc = this;
    // return operator only when screen entry is blank
    if (calc.currOperator && calc.entries.length >= 1 && calc.entry.length < 1) {
      return calc.currOperator;
    }
  }
  render() {
    let calc = this;
    // render current total
    const screenText = calc.sciNotationFormat(calc.getScreenVal());
    $('#screen').text(screenText);
    calc.highlightOperator();
    // switch between AC and C if entry on screen / dim backspace when no entry
    if (calc.isEntryBlank) {
      $('#clear').text('AC');
      $('#back').addClass('dimmed');
    }
    else {
      $('#clear').text('C');
      $('#back').removeClass('dimmed');
    }
  }
  // highlight current operator (+, -, *, /)
  highlightOperator(target?) {
    let calc = this;
    const op = calc.getCurrentOperator();
    $('.darker').removeClass('active');
    if (op === '+') {
      $('#add').addClass('active');
    }
    else if (op === '-') {
      $('#subtract').addClass('active');
    }
    else if (op === '*') {
      $('#multiply').addClass('active');
    }
    else if (op === '/') {
      $('#divide').addClass('active');
    }
  }
  sciNotationFormat(num) {
    let numLength;
    if (typeof num === 'string') {
      numLength = num.length;
      num = parseInt(num);
    }
    else if (typeof num === 'number') {
      numLength = num.toString().length;
    }
    if (numLength >= 8) {
      return num.toExponential(2);
    }
    else {
      return num;
    }
  }
}
