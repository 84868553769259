import dxTreeView from "devextreme/ui/tree_view";
//import * as dxFilterBuilder from "devextreme/ui/filter_builder";

dxTreeView.defaultOptions({
  device: { deviceType: "desktop" },
  options: <DevExpress.ui.dxTreeViewOptions>{
    searchEnabled: true
  }
});

export * from "devextreme/ui/filter_builder";
