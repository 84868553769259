import { DataCenterMenuDrawer } from "../datacentermenudrawer";
import { BSIGrids } from "../../enums/bi/enums";
import { CenterType } from "../../enums/enums";
import { TDFRequest } from "../../services/request";
import dxCustomStore from "devextreme/data/custom_store";
import { AddHandler2, RaiseEvent2 } from "../../infrastructure/events/ui_events";
import { eventNameSpace, EventTypes } from "../../enums/webevents/enums";
import {
  DataScopeSelection
} from "../../components/dashboards/dynamicdatascopeselector";

export interface IGroupSummaryCaption {
  Field: string;
  Caption: string;
}

export interface IBIMenuItem {
  MenuSection: number;
  Caption: string;
  ItemType: number;
  BILevelsUsed: number[];
  UsesToolbar: boolean;
  UsesGoals: boolean;
  UsesDefaultGridBehavior: boolean;
}

export interface IBIMenuItemsResponse {
  GroupSummaryCaptions: IGroupSummaryCaption[];
  MenuItems: IBIMenuItem[];
}

export class BICenterMenuDrawer extends DataCenterMenuDrawer {
  private DefaultItem: BSIGrids;

  private _GroupSummaryCaptions: IBIMenuItemsResponse;
  get GroupSummaryCaptions(): IBIMenuItemsResponse {
    return this._GroupSummaryCaptions;
  }
  set GroupSummaryCaptions(val: IBIMenuItemsResponse) {
    this._GroupSummaryCaptions = val;
  }

  constructor(
    container: JQuery,
    centertype: CenterType,
    eventLimiterName: string,
    baseDataCenterContainer: JQuery,
    inDefaultContainer: boolean,
    defaultItem: BSIGrids = undefined
  ) {
    super(container, centertype, eventLimiterName, baseDataCenterContainer, inDefaultContainer);

    let md = this;
    md.DefaultItem = defaultItem;
  }

  Init() {
    let md = this;
    super.Init();

    md.AddListeners();

    md.MenuItemsDataSource.load().done(() => {
      md.InitFilterMenu();
    });
  }

  InitFilterMenu() {
    let md = this;
    // NEEDTODO can't do this here because hte menu items aren't retrieved yet
    let sumSettingsDFD = $.Deferred();

    RaiseEvent2(
      EventTypes.BIEventTypes.currentsummaryselection,
      md.EventLimiterName,
      eventNameSpace.request,
      { deferred: sumSettingsDFD }
    );

    sumSettingsDFD.done(function (sumSettings) {
      md.FilterMenu(sumSettings);
    });
  }

  AddListeners() {
    let md = this;
    AddHandler2(
      EventTypes.BIEventTypes.currentsummaryselection,
      md.EventLimiterName,
      eventNameSpace.notify,
      md.MenuContainer,
      (e, data) => { md.FilterMenu(data.NewSummarySettings); }
      //md.FilterMenu.bind(md)
    );
  }

  FilterMenu(sumSettings: DataScopeSelection) {
    let md = this;
    // let sumSettings: DataScopeSelection = data.NewSummarySettings;

    let menuItems = $(`#${md.FrameIDs.SideNavContainerID} .datacenter_sidenav_item`);
    $(menuItems).each(function (index, menuItem) {
      let data = $(menuItem).data("sidenavitemlevelsused");
      if (data.split(',').includes(sumSettings.ActiveSelectionLevel.toString())) {
        $(menuItem).parent().parent().parent().show();
      }
      else {
        $(menuItem).parent().parent().parent().hide();
      }
    });
  }

  DSOptions(
    storeopts: DevExpress.data.CustomStoreOptions
  ): DevExpress.data.DataSourceOptions {
    let md = this;
    return {
      store: new dxCustomStore(storeopts),
      paginate: false,
      map(data: IBIMenuItem) {
        return {
          html: `<span class="datacenter_sidenav_item" data-sidenavitemtype="${
            data.ItemType
            }" data-sidenavitemlevelsused="${data.BILevelsUsed}"> <span class='tdf_side_menu_text section-${data.MenuSection}' >${
            data.Caption
            }</span> </span><i class='itemIcon  biMenuItem${
            data.ItemType
            } section-${data.MenuSection}'></i>`,
          badge: "",
          refData: data
        };
      }
    };
  }

  protected StoreOptions(): DevExpress.data.CustomStoreOptions {
    let md = this;
    return {
      load(opts) {
        let d = $.Deferred();
        if (md.MenuItems) {
          return d.promise(d.resolve(md.MenuItems));
        }
        new TDFRequest({ url: `${md.MenuItemsPath}`, type: "GET" })
          .MakeRequest()
          .done(response => {
            md.GroupSummaryCaptions = response.GroupSummaryCaptions;
            md.MenuItems = response.MenuItems;
            d.resolve(md.MenuItems);
          });
        return d.promise();
      }
    };
  }

  protected LoadDefault() {
    let md = this;

    if (
      md.DefaultItem &&
      md.MenuItems.map(a => { return a.ItemType }).includes(md.DefaultItem)
    ) {
      RaiseEvent2(
        EventTypes.CenterEventTypes.itemtype,
        md.EventLimiterName,
        eventNameSpace.modify,
        { ItemType: md.DefaultItem }
      );
    } else {
      RaiseEvent2(
        EventTypes.CenterEventTypes.loaddefaulttoolbar,
        md.EventLimiterName,
        eventNameSpace.notify
      );
    }
  }
}
