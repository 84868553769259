import { Dialog } from "../dialogs/dialog";
import { Notification } from "../dialogs/notification";
import { TDFRequest } from "../../services/request";
import { itemTypes } from "./../../enums/enums";
import { DomSafeID } from "../../util/allutils";
import dxDataSource from "devextreme/data/data_source";
import "devextreme/ui/list";
export class PropagateToContactsDialog {

    // PLease clean this up to be more of a wizard like UI.

  protected DomSafeID: string = "";
  protected RandomID: string = "";
  SourceItemId: string;
  SourceItemType: itemTypes;

  Form: Dialog;
  fieldList = {};
  selectedFields = [];
  selectedContacts = [];

  // HTML IDs
  protected DialogID: string;
  protected ContentID: string;
  protected ContactOptionsID: string;
  protected HeaderID: string;
  protected FieldListID: string;

  constructor(itemID: string, itemType: itemTypes) {
    this.SourceItemId = itemID;
    this.SourceItemType = itemType;
    this.DomSafeID = DomSafeID(this.SourceItemId);
    this.RandomID = Math.ceil(Math.random() * 999 + 1).toString();
  }

  Create() {
    var propagateContactsDialog = this;
    let itemddfd = $.Deferred();

    propagateContactsDialog.SetElementIDs();

    propagateContactsDialog.NewForm().done(function () {
      itemddfd.resolve(propagateContactsDialog);
    });

    return itemddfd.promise();
  }

  SetElementIDs() {
    let propagateContactsDialog = this;
    propagateContactsDialog.DialogID =
      "Dialog_" +
      propagateContactsDialog.DomSafeID +
      "_" +
      propagateContactsDialog.RandomID;
    propagateContactsDialog.ContentID =
      "propagateContent_" +
      propagateContactsDialog.DomSafeID +
      "_" +
      propagateContactsDialog.RandomID;
    propagateContactsDialog.ContactOptionsID =
      "propagateContactOptions_" +
      propagateContactsDialog.DomSafeID +
      "_" +
      propagateContactsDialog.RandomID;
    propagateContactsDialog.HeaderID =
      "theHeader_" +
      propagateContactsDialog.DomSafeID +
      "_" +
      propagateContactsDialog.RandomID;
    propagateContactsDialog.FieldListID =
      "theFields_" +
      propagateContactsDialog.DomSafeID +
      "_" +
      propagateContactsDialog.RandomID;
  }

  NewForm() {
    var propagateContactsDialog = this;

    let itemddfd = $.Deferred();

    let buttons = propagateContactsDialog.GetDialogButtons();

    propagateContactsDialog.Form = new Dialog(
      {
        id: propagateContactsDialog.DialogID,
        closable: true,
        title: "Propagate Address Data To Child Contacts",
        body: $("<div />")
          .attr("id", propagateContactsDialog.ContentID)
            .addClass("container-fluid")
            .append("<h4>Options</h4>")
            .css("margin", "10px 0px;")
            .append(
                $("<div />").attr("id", propagateContactsDialog.ContactOptionsID)//.css("visibility", "hidden")
            ).append("<h4 />")

          .append(
            $("<div></div>")
              .attr("id", propagateContactsDialog.HeaderID)
              .addClass("row")
              .append(
                $("<h4></h4>")
                  .addClass("pull-left")
                  .html("Fields To Update:")
              )
          )
          .append(
            $("<div></div>")
              .addClass("row")
              .append(
                $("<div></div>")
                      .attr("id", propagateContactsDialog.FieldListID)
                  .addClass("well")
              )
          )
          ,
        type: "type-primary",
        buttons: buttons,
            size: "size-normal"
        
      },
      null,
      false
    );

    propagateContactsDialog.Form.open().done(function () {
      propagateContactsDialog.UpdateDialog();
      propagateContactsDialog.InitializeFieldList();
      itemddfd.resolve(propagateContactsDialog);
    });

    return itemddfd.promise();
  }

  UpdateDialog() {
    var propagateContactsDialog = this;

    var contactOption = $("<div></div>")
      .addClass("row")
      .append(
        $("<div></div>")
          .addClass("btn-group")
          .css("margin-bottom", "3px")
          .append(
            $("<button></button>")
                  .attr("id", "btn-all-child-contacts")
                  .css("margin", "2px")
              .addClass("btn active btn-sm")
              .html("All Child Contacts ")
              .on("click", function () {
                if (!$(this).hasClass("active")) {
                  $(this).addClass("active");
                  $("#btn-select-contacts").removeClass("active");
                  $("#propagatecontactarea").addClass("hidden");
                }
              })
              .append("<i></i>")
              .addClass("fa fa-users")
          )
          .append(
            $("<button></button>")
              .addClass("btn btn-sm")
              .attr("id", "btn-select-contacts")
                  .html("Select Contacts ")
                  .css("margin", "2px")
                  .on("click", function () {

                      $("#propagatecontactarea").css("display", "block");


                if (!$(this).hasClass("active")) {
                    $(this).addClass("active");
                    $("#btn-all-child-contacts").removeClass("active");

                    if (!$("#propagatecontactarea").hasClass("hidden")) {
                    let contactRequest = new TDFRequest({
                      url: "/core/user/GetChildContacts/",
                      type: "POST",
                      data: {
                        parentid: propagateContactsDialog.SourceItemId,
                        parenttype: propagateContactsDialog.SourceItemType
                      }
                    });
                    contactRequest.MakeRequest().done(function (response) {
                      var contacts = response;

                      $("#propagatecontactarea").dxList({
                        items: contacts,
                        selectionMode: "all",
                        itemTemplate: function (
                          itemData,
                          itemIndex,
                          itemElement
                        ) {
                          itemElement.append(
                            '<span> <i class="fa fa-user-plus" style="margin-right: 2%;"></i><strong>' +
                            itemData.TDF_Subject +
                            "</strong>" +
                            "<span> " +
                            itemData.TDF_JobTitle +
                            " </span>" +
                            "<span> " +
                            itemData.TDF_Department +
                            " </span></span>"
                          );
                        },
                        onSelectionChanged: function (data) {
                          if (data.addedItems.length > 0) {
                            propagateContactsDialog.selectedContacts.push(
                              data.addedItems[0].ContactID
                            );
                          } else if (data.removedItems.length > 0) {
                            propagateContactsDialog.selectedContacts = $.grep(
                              propagateContactsDialog.selectedContacts,
                              function (el) {
                                return el !== data.removedItems[0].ContactId;
                              }
                            );
                          }
                        },
                        showSelectionControls: true,
                        height:  window.innerHeight * 0.3
                      });
                    });
                  } else {
                    $("#propagatecontactarea").removeClass("hidden");
                  }
                }
              })
              .append("<i></i>")
              .addClass("fa fa-user-plus")
          )
      );
    $(contactOption).append(
      $("<div></div>")
            .addClass("well")
            .attr("id", "propagatecontactarea")
        .css("display", "none")
    );

    $("#" + propagateContactsDialog.ContactOptionsID).append(contactOption);
  }

  InitializeFieldList() {
    var propagateContactsDialog = this;

    $("#" + propagateContactsDialog.FieldListID).dxList({
      dataSource: new dxDataSource({
        load: function (loadOptions) {
          let d = $.Deferred();

          new TDFRequest({
            url: "/core/user/PropagateSetup/",
            type: "POST",
            data: {
              accountid: propagateContactsDialog.SourceItemId
            }
          })
            .MakeRequest()
            .done(function (response: any) {
              d.resolve(response.fields);
            });

          return d.promise();
        }
      }),
      selectionMode: "all",
      itemTemplate: function (itemData, itemIndex, itemElement) {
        itemElement.append("<span><b>" + itemData.Display + "</b></span>");
      },
      onSelectionChanged: function (data) {
        if (data.addedItems.length > 0) {
          propagateContactsDialog.selectedFields.push(data.addedItems[0]);
        } else if (data.removedItems.length > 0) {
          propagateContactsDialog.selectedFields = $.grep(
            propagateContactsDialog.selectedFields,
            function (el) {
              return el.Display !== data.removedItems[0].Display;
            }
          );
        }
      },
      showSelectionControls: true,
      height: "auto" // window.innerHeight * 0.4
    });
  }

  GetDialogButtons() {
    var propagateContactsDialog = this;
    let buttons: DevExpress.ui.dxPopupToolbarItem[] = [];

    buttons.push({
      widget: "dxButton",
      toolbar: "bottom",
      location: "after",
      options: {
        text: "Propagate",
        icon: "check",
        type: "success",
        onClick: function () {
          var params: any = {
            accountid: propagateContactsDialog.SourceItemId,
            fields: propagateContactsDialog.selectedFields
          };
          if (propagateContactsDialog.selectedContacts.length > 0) {
            params.contacts = propagateContactsDialog.selectedContacts;
          }

          let request = new TDFRequest({
            url: "/commands/propagate/",
            type: "POST",
            data: params
          });

          propagateContactsDialog.Form.close();

          request.MakeRequest().done(function (response) {
            new Notification({
              type: response.Valid ? "success" : "warning",
              shading: true,
              displayTime: 3000,
              message: response.Message
            });
          });
        }
      }
    });

    buttons.push({
      widget: "dxButton",
      toolbar: "bottom",
      location: "after",
      options: {
        text: "Cancel",
        type: "danger",
        icon: "remove",
        onClick: function () {
          propagateContactsDialog.Form.close();
        }
      }
    });

    return buttons;
  }
}
