import { RaiseEvent2 } from "../../../../infrastructure/events/ui_events";
import { eventNameSpace, EventTypes } from "../../../../enums/webevents/enums";
import { ToolbarTemplateFactory } from "./toolbartemplatefactory";
import { Debounce } from "../../../../util/allutils";
import { IToolbarItemOverrideOptions } from "interfaces/grids/interfaces";

export function ManageFavoritesButton(args, config: IToolbarItemOverrideOptions) {
    let centerName: string = "";
    if (args && args.length) {
        args.map((v, k) => {
            if ($.isPlainObject(v)) {
                Object.keys(v).forEach((val, key) => {
                    if (val.toLowerCase() === "uniquecentername") {
                        centerName = v[val];
                    }
                });
            }
        });
    }
    //TODO: Add Config paramter and respect it
    return ToolbarTemplateFactory(
        config && config.location ? config.location : "after",
        config && config.locateInMenu ? config.locateInMenu : "never",
        config && config.disabled ? config.disabled : false,
        <DevExpress.ui.dxButtonOptions>{
            icon: 'dx-icon dx-icon-favorites',
            text: "Manage Favorites",
            //type:"warning",
            onClick: Debounce(
                () => {
                    RaiseEvent2(
                        EventTypes.RFCenterEventTypes.manageFavorites,
                        centerName,
                        eventNameSpace.request
                    );
                },
                250,
                true
            )
        },
        "dxButton"
    );
}
