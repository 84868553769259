import { RaiseEvent2 } from "../../../../infrastructure/events/ui_events";
import { eventNameSpace, EventTypes } from "../../../../enums/webevents/enums";
import { ToolbarTemplateFactory } from "./toolbartemplatefactory";
import { CenterType } from "../../../../enums/enums";

export function RollUpButton(args) {
  let centerName: string = "";
  let centerType;

  if (args && args.length) {
    args.map((v, k) => {
      if ($.isPlainObject(v)) {
        Object.keys(v).forEach((val, key) => {
          if (val.toLowerCase() === "uniquecentername") {
            centerName = v[val];
          }
          else if (val.toLowerCase() === "centertype") {
            centerType = v[val];
          }
        });
      }
    });
  }

  return ToolbarTemplateFactory(
    "after",
    "never",
    false,
    {
      icon: "icon icon-rollup",
      type: "default",
      text: "Roll Up",
      onClick: () => {
        RaiseEvent2(EventTypes.BIEventTypes.RollUp, centerName, eventNameSpace.request);
      }
    },
    "dxButton",
    null,
    centerType == CenterType.AccountBi,
    "inMenu"
  );
}
