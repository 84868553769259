import * as Cookies from 'js-cookie';
import { Dialog } from '../dialogs/dialog';
import 'devextreme/ui/form';
import { TDFRequest } from '../../services/request';
import 'devextreme/ui/popup';
import '../../../images/sherpa/customerLogo.png';
import { GetForgotPasswordForm } from './forgot';
import { CurrentUser, ReloadUser } from 'infrastructure/context';
import { MitsSSOSetup } from 'integrations/mits/mitssso';
import { Notification } from 'components/dialogs/notification';

export function GetLoginForm(normal = false) {
  const d = $.Deferred();

  $('.page').hide();

  if (!$('html:first').attr('class')) {
    $('html:first').addClass('light-theme');
  }

  // $('#tdfbodycontent').append(
  //   `<img class="cust-logo in" id="home-welcome" src="images/customerLogo.png" alt="">`
  // );

  const fwrap = $('<div id="tdf-lgf">')
    .addClass('col-md-3')
    .append(
      $('<div id="formWidget">')
    );

  if ($('#tdflpd').length === 0) {
    $("<div id='tdflpd'/>").appendTo('body');
  }

  let debug = (window["DEBUGGING"] === true) ? true : false;

  const dllg = $('#tdflpd')
    .dxPopup(<DevExpress.ui.dxPopupOptions>{
      contentTemplate(el) {
        el.append(fwrap);
      },
      showCloseButton: false,
      closeOnOutsideClick: false,
      fullScreen: true,
      resizeEnabled: false,
      dragEnabled: false,
      visible: true,
      position: "center",
      animation: {
        show: {
          type: 'fadeIn',
          from: 0,
          to: 1,
          duration: .5e3
        },
        hide: {
          type: 'fadeOut',
          from: 1,
          to: 0,
          duration: 1e3
        }
      },
      onShown: () => {
        $('#tdf-lgf').css('opacity', '1');
      },
      titleTemplate: (e) => {
        return $('<img />')
          .attr('src', '/images/tdf/customerLogo.png')
          .height('60px');
      }
    })
    .dxPopup('instance');


  dllg.show().done(() => {
    let timer = setInterval(() => {

      if ($('#formWidget').length) {
        clearInterval(timer);
        const form: DevExpress.ui.dxForm = $('#formWidget')
          .dxForm({
            colCount: 1,
            height: '100%',
            items: GetLoginItems(),
            onEditorEnterKey: () => {
              Login();
            }
          })
          .dxForm('instance');

      }
    }, .5e3);
  });
  return d.promise();

  function GetLoginItems(): (DevExpress.ui.dxFormSimpleItem | DevExpress.ui.dxFormGroupItem | DevExpress.ui.dxFormTabbedItem | DevExpress.ui.dxFormEmptyItem | DevExpress.ui.dxFormButtonItem)[] {
    let clientInfo = TryGetClientIDFromSubDomain();

    return [
      {
        template: () => {
          return $('<h2 />')
            .text('Welcome')
        }
      },
      {
        dataField: 'client_id',
        label: { text: '', visible: false },
        editorOptions: <DevExpress.ui.dxTextBoxOptions>{
          validationMessageMode: 'always',
          placeholder: 'Company',
          value: clientInfo.clientID,
          stylingMode: 'underlined'
        },
        visible: !clientInfo.clientID && clientInfo.multitenant,
      } as DevExpress.ui.dxFormSimpleItem,
      {
        dataField: 'username',
        label: { text: '', visible: false },
        verticalAlignment: 'bottom',
        editorOptions: <DevExpress.ui.dxTextBoxOptions>{
          validationMessageMode: 'always',
          placeholder: 'User Name',
          stylingMode: 'underlined'
        },
        validationRules: [
          {
            type: 'required',
            message: 'User Name is required'
          }
        ]
      } as DevExpress.ui.dxFormSimpleItem,
      {
        dataField: 'password',
        label: { text: '', visible: false },
        //verticalAlignment: 'center',
        editorType: 'dxTextBox',
        editorOptions: <DevExpress.ui.dxTextBoxOptions>{
          mode: 'password',
          validationMessageMode: 'always',
          placeholder: 'Password',
          stylingMode: 'underlined'
        } as DevExpress.ui.dxTextBoxOptions,
        validationRules: [
          {
            type: 'required',
            message: 'Password is required'
          }
        ]
      } as DevExpress.ui.dxFormSimpleItem,
      {
        itemType: 'button',
        horizontalAlignment: 'right',
        verticalAlignment: 'top',
        buttonOptions: {
          text: 'Forgot?',
          stylingMode: 'text',
          onClick: () => {
            GetForgotPasswordForm();
          },
          elementAttr: {
            'id': 'forgot-pass'
          }
        }
      },
      {
        itemType: 'button',
        colSpan: 1,
        verticalAlignment: 'bottom',
        buttonOptions: {
          text: 'Log In',
          onClick: () => {
            Login();
          },
          elementAttr: {
            'id': 'login-btn'
          }
        }
      } as DevExpress.ui.dxFormButtonItem,
    ];
  }

  function Login() {
    let formData = $('#formWidget').dxForm('instance').option('formData');

    let loginRequests = [
      new TDFRequest({
        url: `${TDFRequest.LoginPath}/token`,
        type: "POST",
        data: `${$.param(formData)}&grant_type=password`
      }).MakeRequest()
    ];

    if (false) {
      let d = $.Deferred();
      let mitsSSO = new MitsSSOSetup(formData.username, formData.password, $('#tdflpd'), d);

      loginRequests.push(
        mitsSSO.GetToken()
      );
    }

    $.when(...loginRequests)
      .done((res) => {

        dllg.hide();

        Cookies.set("api", "");

        if (res) {
          if (res.api_path && res.api_path !== "") {
            Cookies.set("api", res.api_path);
          }
        }

        $('.page').show();
        d.resolve(res);

        ReloadUser().done((data) => {
          if (CurrentUser.NeedsNewPassword) {
            window.location.assign("/profile");
          }
        });

      })
      .fail((err) => {
        new Notification({
          message: err.error_description,
          type: 'error'
        });
      })
  }

  function TryGetClientIDFromSubDomain(): { clientID: string, multitenant: boolean } {
    let urlParts = location.hostname.split('.');
    let clientID: string = "";
    let multitenant: boolean = false;

    // Gets the clientid part of the subdomain.
    urlParts.forEach((part, index) => {
      if (part === 'tdfanywhere' && index > 0) {
        clientID = urlParts[index - 1].toLowerCase();
      }
    });

    if (clientID) {
      multitenant = true;

      // Remove _testing so we don't have to duplicate records in the multitenant db.
			clientID = clientID.replace("_testing", "");
			clientID = clientID.replace("_preview", "");

      // Ignore the multitenant subdomain so that we can use this 
      // site to test without customer specific binding.
      if (clientID === 'multitenant') {
        clientID = "";
      }
    }

    return {
      clientID: clientID,
      multitenant: multitenant
    };
  }
}