import { BSIGrids, ISummary } from "../../../../enums/bi/enums";
import { itemTypes, CenterType } from "../../../../enums/enums";
import { AddHandler2, RaiseEvent2 } from "../../../../infrastructure/events/ui_events";
import { eventNameSpace, EventTypes } from "../../../../enums/webevents/enums";
import { Preferences } from "../../../../infrastructure/user/preferences";
import { IRequestTypeGoalInfo } from "../../../../interfaces/interfaces";
import { TDFRequest } from "../../../../services/request";
import { ToolbarTemplateFactory } from "./toolbartemplatefactory";
import { GetDevice } from "../../../../util/allutils";
import "devextreme/ui/lookup";
import { DataScopeSelection } from "../../../dashboards/dynamicdatascopeselector";
import {
  GetBIPrefModuleFromBISummarySource,
  GetBISummarySourceFromCenterType
} from "../../../../datacenters/bidatacenters/bi_utils";
import { IBIMenuItem } from "../../../../datacenters/bidatacenters/bi_center_menu_drawer";
export class BIGoalLookup {
  private _container: JQuery;
  private EventLimiterName: string = "";
  private _SummarySettings: DataScopeSelection;
  private Goals: IRequestTypeGoalInfo[] = [];
  private AllGoals: IRequestTypeGoalInfo[] = [];
  get GoalType(): number {
    let goalSelector = this;
    return goalSelector._SummarySettings.GetGoalSelection(
      goalSelector._PreferenceModule
    );
  }
  set GoalType(goalValue: number) {
    let goalSel = this;
    if (goalValue === -1) {
      Preferences.SetPreference(
        "LoadGoals",
        "False",
        goalSel._PreferenceModule
      );
    } else {
      let prefName: string = goalSel._SummarySettings.GetGoalPrefName(
        goalSel._SummarySettings.ActiveSelectionLevel
      );
      Preferences.SetPreference("LoadGoals", "True", goalSel._PreferenceModule);
      Preferences.SetPreference(
        prefName,
        goalValue.toString(),
        goalSel._PreferenceModule
      );
    }
    RaiseEvent2(
      EventTypes.BIEventTypes.currentgoalselection,
      goalSel.EventLimiterName,
      eventNameSpace.notify,
      { NewGoalType: goalValue }
    );
  }

  private _CenterType: CenterType;
  private _PreferenceModule: string;
  get CenterType(): CenterType {
    let goalSel = this;
    return goalSel._CenterType;
  }
  set CenterType(val: CenterType) {
    let goalSel = this;
    goalSel._CenterType = val;
    goalSel._PreferenceModule = GetBIPrefModuleFromBISummarySource(
      GetBISummarySourceFromCenterType(goalSel._CenterType)
    );
  }

  private _ItemType: itemTypes;
  get ItemType(): itemTypes {
    let goalSelector = this;
    return goalSelector._ItemType;
  }
  set ItemType(val: itemTypes) {
    let goalSelector = this;
    goalSelector._ItemType = val;
  }

  get lookupOptions(): DevExpress.ui.dxLookupOptions {
    let selgoals = this;

    return {
      elementAttr: {
        id: "selGoalType"
      },
      dataSource: selgoals.Goals,
      popupWidth: () => {
        /*
                     this will ensure the width of the popup is wide enough because it mimics the width of the selector element and that could be small if a view with a shor name is selected
                     */
        return GetDevice().isDevice ? "" : $(window).width() * 0.3;
      },
      width: GetDevice().isDevice ? '100px' : '150px',
      displayExpr: "Caption",
      valueExpr: "GoalType",
      value: selgoals._SummarySettings.GetGoalSelection(
        selgoals._PreferenceModule
      ),
      onItemClick: function (selectedItem) {
        selgoals.GoalType = selectedItem.itemData.GoalType;
      }
    };
  }

  constructor(container, itemtype, centerType, eventLimiterName: string) {
    this._container = container;
    this.EventLimiterName = eventLimiterName;
    this.ItemType = itemtype;
    this.CenterType = centerType;
    this.AddListeners();
    this.init();
  }
  init() {
    let goalSelector = this;
    let sendDFD: JQueryDeferred<DataScopeSelection> = $.Deferred();
    // TODO:  Need to make these specific to the BI Center they apply to.  So if an Account is opened, or linked contacts/opportunity, etc, then the BI Center for that specific item doesn't conflict here.
    RaiseEvent2(
      EventTypes.BIEventTypes.currentsummaryselection,
      goalSelector.EventLimiterName,
      eventNameSpace.request,
      { deferred: sendDFD }
    );

    sendDFD.done(function (scopeSettings) {
      goalSelector._SummarySettings = scopeSettings;
      goalSelector.GetGoals().done(function () {
        goalSelector._container.dxLookup(goalSelector.lookupOptions);
      });
    });
  }

  AddListeners() {
    let goalSelector = this;
    AddHandler2(
      EventTypes.BIEventTypes.currentsummaryselection,
      goalSelector.EventLimiterName,
      eventNameSpace.notify,
      goalSelector._container,
      goalSelector.UpdateGoalOptions.bind(goalSelector)
    );
    AddHandler2(
      EventTypes.CenterEventTypes.itemtype,
      goalSelector.EventLimiterName,
      eventNameSpace.modify,
      goalSelector._container,
      goalSelector.UpdateItemType.bind(goalSelector)
    );
  }

  UpdateGoalOptions(e?: JQueryEventObject, d?) {
    let goalSel = this;

    if (d && d.NewSummarySettings) {
      goalSel._SummarySettings = d.NewSummarySettings;
    }

    goalSel.GetGoals();
  }

  UpdateItemType(e?: JQueryEventObject, d?) {
    let goalSel = this;
    if (d && d.ItemType) {
      goalSel.ItemType = d.ItemType;
    }
    goalSel.GetGoals();
  }

  GetGoals(): JQueryPromise<IRequestTypeGoalInfo[]> {
    let goalSel = this;
    // check toolbar data list
    let dfd: JQueryDeferred<IRequestTypeGoalInfo[]> = $.Deferred();
    if (goalSel.AllGoals.length) {
      let myGoals = goalSel.FilterGoalOptions();
      dfd.promise(dfd.resolve(myGoals));
    } else {
      let request = new TDFRequest({
        url: `${TDFRequest.ApiPath}/BI/GoalInfo/`,
        type: "GET"
      });
      request.MakeRequest().done(function (response) {
        goalSel.AllGoals = response.Goals;
        goalSel.Goals = goalSel.FilterGoalOptions();
        dfd.resolve(goalSel.Goals);
      });
    }

    return dfd.promise();
  }

  FilterGoalOptions(): any[] {
    let goalSel = this;
    let levelString =
      ISummary.SummaryInterfaces.cBSID.EnumType[
      goalSel._SummarySettings.ActiveSelectionLevel
      ];
    let biGridType = (goalSel.ItemType as unknown) as BSIGrids;
    let myGoals = undefined;
    let itemTypeGoals = $.grep(goalSel.AllGoals, function (
      gridTypeGoals,
      index
    ) {
      return gridTypeGoals.RequestType == biGridType;
    });

    if (itemTypeGoals.length && itemTypeGoals[0].GoalList[levelString]) {
      myGoals = JSON.parse(
        JSON.stringify(itemTypeGoals[0].GoalList[levelString])
      );
      myGoals.push({
        GoalType: -1,
        Caption: "No Goals",
        UseIfSalespersonOnly: false
      });
    }

    return myGoals;
  }
}

export function BIGoalSelector(args, config?: DevExpress.ui.dxToolbarItemTemplate) {
  //TODO: Add Config paramter and respect it
  //container, itemtype, selectionlevel
  let ItemType = itemTypes.itemUnknown;
  let Type = CenterType.Bi;
  let centerName: string = "";

  if (args && args.length) {
    args.map((v, k) => {
      if ($.isPlainObject(v)) {
        Object.keys(v).forEach((val, key) => {
          if (val.toLowerCase() === "itemtype") {
            ItemType = v[val];
          } else if (val.toLowerCase() === "centertype") {
            Type = v[val];
          } else if (val.toLowerCase() === "uniquecentername") {
            centerName = v[val];
          }
        });
      }
    });
  }

  return ToolbarTemplateFactory(
    "after",
    config && config.locateInMenu ? config.locateInMenu : "never",
    false,
    null,
    "",
    (data, index, element) => {
      let timer;
      let div = $("<div />").appendTo(element);

      timer = setInterval(function () {
        if (document.body.contains(element[0])) {
          clearInterval(timer);
          new BIGoalLookup(div, ItemType, Type, centerName);
        }
      });
    }
  );
}
