import { BaseDataCenter } from "./basedatacenter";
import { CenterType, itemTypes, GridContainerTypes } from "enums/enums";
import { DataCenterMenuDrawer } from "./datacentermenudrawer";
import { IToolbarItemOverrideOptions } from "interfaces/grids/interfaces";
import { AwesomeToolbar } from "components/grids/gridtoolbars/datacentertoolbar";
import { DataCenterTabs } from "./datacentertabs";
import { GridSetup } from "services/grid/helpers/gridsetup";
import { TDFDataCenters } from "interfaces/datacenters/tdfdatacenters";
import { AddHandler2 } from "infrastructure/events/ui_events";
import { EventTypes, eventNameSpace } from "enums/webevents/enums";
import { Preferences } from "infrastructure/user/preferences";
import { TDFRequest } from "services/request";

export class LinkedItemsCenter extends BaseDataCenter {
    protected _CenterType = CenterType.LinkedItems;
    protected MyCreationArgs: TDFDataCenters.ICRMDataCenterCreationArgs;
    private SourceItemID: string;
    private SourceItemType: itemTypes;
    private InitialTargetItemType: itemTypes;
    private DefaultToolbarLoaded: boolean;
    private AllowItemTypeChange: boolean;
    private InitialViewGuidOverride: string;

    /**The jquery container that contains the toolbar */
    private _DataToolbarAreaContainer: JQuery;
    get DataToolbarAreaContainer(): JQuery {
        let lic = this;
        if (lic._DataToolbarAreaContainer) return lic._DataToolbarAreaContainer;
        let container: JQuery;
        if (lic.MyCreationArgs && lic.MyCreationArgs.DataToolbarAreaContainer) {
            container = $(lic.MyCreationArgs.DataToolbarAreaContainer);
        }
        if (container && container.length) {
            lic._DataToolbarAreaContainer = container;
        } else {
            /*Add to MainDataAreaContainer*/
            lic.DataToolbarAreaContainer = $(
                `<div id='linkeditems-datacenter-datatoolbar-container${Date.now()}' />`
            ).appendTo(lic.MainDataAreaContainer);
        }

        if (
            !jQuery.contains(document.documentElement, lic.MainDataAreaContainer[0])
        ) {
            //Element is detached
            throw "Unable to find container => _DataToolbarAreaContainer ";
        }
        return lic._DataToolbarAreaContainer;
    }
    set DataToolbarAreaContainer(val: JQuery) {
        let evtdc = this;
        if (val) evtdc._DataToolbarAreaContainer = val;
    }

    /**The jquery container that contains tabs that contain the grids of data */
    private _DataTabsAreaContainer: JQuery;
    get DataTabsAreaContainer(): JQuery {
        let lic = this;
        if (lic._DataTabsAreaContainer) return lic._DataTabsAreaContainer;
        let container: JQuery;
        if (lic.MyCreationArgs && lic.MyCreationArgs.DataTabsAreaContainer) {
            container = $(lic.MyCreationArgs.DataTabsAreaContainer);
        }
        if (container && container.length) {
            lic.DataTabsAreaContainer = container;
        } else {
            /*Add to MainDataAreaContainer*/
            lic.DataTabsAreaContainer = $(
                `<div id='linkeditems-datacenter-datatabs-container${Date.now()}'/>`
            ).appendTo(lic.MainDataAreaContainer);
        }
        if (
            !jQuery.contains(document.documentElement, lic.MainDataAreaContainer[0])
        ) {
            //Element is detached
            throw "Unable to find container => _DataTabsAreaContainer ";
        }

        return lic._DataTabsAreaContainer;
    }
    set DataTabsAreaContainer(val: JQuery) {
        let lic = this;
        if (val) lic._DataTabsAreaContainer = val;
    }

    /**The jquery container that contains tabs that contain the grids of data */
    private _DataGridAreaContainer: JQuery;
    get DataGridAreaContainer(): JQuery {
        let lic = this;
        if (lic._DataGridAreaContainer) return lic._DataGridAreaContainer;
        let container: JQuery;
        if (lic.MyCreationArgs && lic.MyCreationArgs.DataGridAreaContainer) {
            container = $(lic.MyCreationArgs.DataGridAreaContainer);
        }
        if (container && container.length) {
            lic.DataGridAreaContainer = container;
        } else {
            /*Add to MainDataAreaContainer*/
            lic.DataGridAreaContainer = $(
                `<div id='linkeditems-datacenter-datagrid-container${Date.now()}'/>`
            ).appendTo(lic.MainDataAreaContainer);
        }
        if (
            !jQuery.contains(document.documentElement, lic.MainDataAreaContainer[0])
        ) {
            //Element is detached
            throw "Unable to find container => _DataGridAreaContainer ";
        }

        return lic._DataGridAreaContainer;
    }
    set DataGridAreaContainer(val: JQuery) {
        let evtdc = this;
        if (val) evtdc._DataGridAreaContainer = val;
    }

    private _GridContainerType: GridContainerTypes;
    protected get GridContainerType(): JQueryPromise<GridContainerTypes> {
        let d: JQueryDeferred<GridContainerTypes> = $.Deferred();

        if (this._GridContainerType) {
            return d.promise(d.resolve(this._GridContainerType));
        } else {
            new TDFRequest({
                url:
                    "/Action/GetContainerTypeFromItemType/?" +
                    $.param({ type: this.SourceItemType })
            }).MakeRequest().done(response => {
                this._GridContainerType = response;
                d.resolve(response);
            });

            return d.promise();
        }
    }

    constructor(
        SourceItemID: string,
        SourceItemType: itemTypes,
        EventLimiterName: string,
        MyCreationArgs: TDFDataCenters.ICRMDataCenterCreationArgs,
        InitialTargetItemType?: itemTypes,
        AllowItemTypeChange: boolean = true
    ) {
        super(EventLimiterName);
        this.ValidateCreationArgs(MyCreationArgs);

        this.SourceItemID = SourceItemID;
        this.SourceItemType = SourceItemType;
        this.AllowItemTypeChange = AllowItemTypeChange;

        if (InitialTargetItemType !== undefined) {
            this.InitialTargetItemType = InitialTargetItemType;
        }

    }

    init(viewGuid?: string) {
        let lic = this;

        if (viewGuid) {
            lic.InitialViewGuidOverride = viewGuid;
        }

        lic.initContainers();
        lic.initMenu();
        lic.initTabs();
        lic.SubscribeToMenuEvents();
    }

    protected initMenu() {
        let lic = this;
        let menuDrawer = new DataCenterMenuDrawer(
            lic.MenuAreaContainer,
            lic.CenterType,
            lic.CustomName,
            lic.MainAreaContainer,
            lic.InDefaultContainer
        );
        menuDrawer.MenuItemsQueryString = `?type=${lic.SourceItemType}`;
        menuDrawer.Init();
    }

    private initTabs() {
        let lic = this;

        let gridArgs = <GridSetup.GridRequestArgs>{
            ContainerID: lic.SourceItemID
        }

        if (lic.InitialViewGuidOverride) {
            gridArgs.GUID = lic.InitialViewGuidOverride;
        }

        let tabs = new DataCenterTabs(
            lic.DataTabsAreaContainer,
            lic.CenterType,
            lic.DataGridAreaContainer,
            lic.CustomName,
            lic.InDefaultContainer,
            gridArgs,
        );

        tabs.Welcome = `<div class="center-centered-content">  
            <object data = "/Content/img/customerIcon.svg" style = "max-width: 200px" />
            <h5 style = "color:#A4A4A4" > Select an item from the menu to get started.</h5>
            </div>`;

        tabs.Init();
    }

    private SubscribeToMenuEvents() {
        let lic = this;
        AddHandler2(
            EventTypes.CenterEventTypes.itemtype,
            lic.CustomName,
            eventNameSpace.modify,
            lic.DataToolbarAreaContainer,
            lic.OnMenuSelectionChanged.bind(lic)
        );
        AddHandler2(
            EventTypes.CenterEventTypes.loaddefaulttoolbar,
            lic.CustomName,
            eventNameSpace.notify,
            lic.MainAreaContainer,
            lic.LoadDefaultToolbar.bind(lic)
        );
    }

    private OnMenuSelectionChanged(e: JQueryEventObject, data) {
        let lic = this;

        if (lic.DefaultToolbarLoaded) {
            lic.DefaultToolbarLoaded = false;
            lic.DataToolbarAreaContainer.empty();
        }

        if (lic.DataToolbarAreaContainer.children().length === 0) {
            lic.MenuAreaContainer.hide();

            let ToolbarItems: IToolbarItemOverrideOptions[] = [
                { name: "OpenButton", index: 1, location: "before" },
                { name: "ActionButton", index: 2, location: "before" },
                { name: "ViewLookup", index: 4, location: "after" },
                { name: "RefreshButton", index: 5, location: "after" },
                { name: "OptionsButton", index: 6, location: "after" }
            ];

            if (lic.AllowItemTypeChange) {
                ToolbarItems.push({ name: "MenuButton", index: 0, location: "before" });
            }
            if ((Preferences.GetCompanyPreference("ShowViewsBtn", "TDFMobile", "1") === "1")) {
                ToolbarItems.push({ name: "ViewButton", index: 3, location: "before" });
            }

            lic.GridContainerType.done(gct => {
                let toolbarItemArgs = {
                    ItemType: data.ItemType,
                    gridContainerType: gct,
                    UniqueCenterName: lic.CustomName,
                    ViewGuid: lic.InitialViewGuidOverride || null
                }

                new AwesomeToolbar(
                    lic.DataToolbarAreaContainer,
                    data.ItemType,
                    gct,
                    lic.CustomName,
                    ToolbarItems,
                    toolbarItemArgs
                ).init();
            });
        }
    }

    private LoadDefaultToolbar(e: JQueryEventObject) {
        let lic = this;

        if (lic.InitialTargetItemType) {
            lic.OnMenuSelectionChanged(null, { ItemType: lic.InitialTargetItemType || itemTypes.itemAllItems });
        } else {
            lic.DefaultToolbarLoaded = true;

            if (lic.DataToolbarAreaContainer.children().length === 0) {
                lic.MenuAreaContainer.hide();

                let ToolbarItems: IToolbarItemOverrideOptions[] = [
                    { name: 'MenuButton', index: 0, location: 'before' }
                ];

                new AwesomeToolbar(
                    lic.DataToolbarAreaContainer,
                    null,
                    GridContainerTypes.InfoCenter,
                    lic.CustomName,
                    ToolbarItems,
                    {
                        gridContainerType: GridContainerTypes.InfoCenter,
                        UniqueCenterName: lic.CustomName
                    }
                ).init();
            }
        }
    }

    protected initContainers() {
        super.initContainers();
        let lic = this;
        if (!lic.DataToolbarAreaContainer.length) throw "Error toolbar container missing";
        if (!lic.DataTabsAreaContainer.length) throw "Error tabs container missing";
        if (!lic.DataGridAreaContainer.length) throw "Error grid container missing";
    }
}