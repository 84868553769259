import { RaiseEvent2 } from "../../infrastructure/events/ui_events";
import { DataScopeSelection } from "./dynamicdatascopeselector";
import { TileSettingsBase } from "./tilesettingsbase";
import { eventNameSpace, EventTypes } from "../../enums/webevents/enums";
import { DomSafeID } from "../../util/allutils";

export enum TileScopeType {
  ScopeNotSupported = 0,
  AlwaysUsesHomeList = 1,
  SupportsScopeSettings = 2
}

export class TileBase {
  protected SettingsClassRef: typeof TileSettingsBase = TileSettingsBase; // This needs overridden by any class that has their own settings class
  protected _ScopeSupportLevel: TileScopeType = TileScopeType.SupportsScopeSettings;
  public get ScopeSupportLevel(): TileScopeType {
    let tileBase = this;
    return (tileBase._ScopeSupportLevel);
  }

  protected _Name: string = "";
  public get Name(): string {
    let tileBase = this;
    return (tileBase._Name);
  }
  public readonly DefaultHeight: string = "";

  public TileInstanceID: string; // TODO:  make private with a public getter
  public Settings: TileSettingsBase;

  public Configured: boolean = true;
  public BeingConfigured: boolean = false;
  public HasInteractiveSettings: boolean = false;

  protected HasBeenRendered2: boolean = false;

  protected _NeedsConfigured: boolean = true;
  public get NeedsConfigured(): boolean {
    let tileBase = this;
    return (tileBase._NeedsConfigured);
  }

  public get ID() {
    let tileBase = this;
    return (tileBase.TileInstanceID);
  }

  constructor(tileInstanceID: string, settings: Partial<TileSettingsBase>, settingsClassRef: typeof TileSettingsBase) {
    let tileBase = this;
    tileBase.TileInstanceID = tileInstanceID;
    tileBase.SettingsClassRef = settingsClassRef;

    tileBase.Settings = new tileBase.SettingsClassRef();
    tileBase.Settings.InitSettings(settings);
  }

  protected GetScopeSettings(): JQueryPromise<DataScopeSelection> {
    let tileBase = this;

    let myDFD: JQueryDeferred<DataScopeSelection> = $.Deferred();

    let sendDFD: JQueryDeferred<DataScopeSelection> = $.Deferred();

    RaiseEvent2(EventTypes.DashboardEventTypes.TileScope, DomSafeID(tileBase.TileInstanceID), eventNameSpace.request, { deferred: sendDFD });

    sendDFD.done(function (scopeSettings) {
      myDFD.resolve(scopeSettings);
    });

    return myDFD.promise();
  }

  public RenderTile(contentElement: JQuery): JQueryPromise<any> {
    let tileBase = this;
    let dfd: JQueryDeferred<any> = $.Deferred();

    contentElement.addClass("TileBeingRendered");

    let myWait: JQueryPromise<any>;

    if (tileBase.HasBeenRendered2) {
      myWait = tileBase.ReRender(contentElement);
    } else {
      myWait = tileBase.Render(contentElement);
    }
    myWait.done(function () {
      // NEEDTODO:  This isn't being removed on BYOR class
      contentElement.removeClass("TileBeingRendered");
      tileBase.HasBeenRendered2 = true;
      return dfd.resolve();
    });

    return dfd.promise();
  }

  protected Render(contentElement: JQuery): JQueryPromise<any> {
    let dfd: JQueryDeferred<any> = $.Deferred();

    alert("rendering");

    return dfd.promise(dfd.resolve());
  }

  protected ReRender(contentElement: JQuery): JQueryPromise<any> {
    let tileBase = this;
    contentElement.empty();
    return tileBase.Render(contentElement);
  }

  // This method will create a new settings class for the tile, then update any properties in it that are set in the newSettings object passed in.
  // This method can be overridden if the tile tracks settings internally in any other fashion.
  public ApplyNewSettings(newSettings: TileSettingsBase) {
    let tileBase = this;
    tileBase.Settings = new tileBase.SettingsClassRef();
    tileBase.Settings.InitSettings(newSettings);
  }

  public GetConfigurableTileSettings(): JQueryPromise<Array<DevExpress.ui.dxFormSimpleItem | DevExpress.ui.dxFormGroupItem | DevExpress.ui.dxFormTabbedItem | DevExpress.ui.dxFormEmptyItem | DevExpress.ui.dxFormButtonItem>> {
    let dfd: JQueryDeferred<any> = $.Deferred();
    // Do nothing.  Override this method if your tile provides configurable tile "personalization" settings in the designer.
    return dfd.promise(dfd.resolve());
  }

  public GetInteractiveTileSettings(): JQueryPromise<Array<DevExpress.ui.dxFormSimpleItem | DevExpress.ui.dxFormGroupItem | DevExpress.ui.dxFormTabbedItem | DevExpress.ui.dxFormEmptyItem | DevExpress.ui.dxFormButtonItem>> {
    let dfd: JQueryDeferred<any> = $.Deferred();
    // Do nothing.  Override this method if your tile provides configurable tile interaction settings in the viewer.
    return dfd.promise(dfd.resolve());
  }

  public RefreshUI() {
    // Do nothing.  Override this method if your tile needs to do something when the tile is resized.
  }
}
