import { RecentItemsOrFavoritesGrid } from "../components/grids/datacentergrid";
import { AwesomeToolbar } from '../components/grids/gridtoolbars/datacentertoolbar';
import { CenterType, GridContainerTypes, itemTypes } from '../enums/enums';
import { eventNameSpace, EventTypes } from '../enums/webevents/enums';
import { AddHandler2, WebEvents, RaiseEvent2 } from "../infrastructure/events/ui_events";
import {
  IGridView,
  IToolbarItemOverrideOptions
} from '../interfaces/grids/interfaces';
import { GetDevice } from '../util/allutils';
import { Preferences } from '../infrastructure/user/preferences';
import { RulesManager } from "components/controls/rulesmanager";
import { FilterTarget } from "enums/rulesmanager/enums";
import { GridSetup } from "services/grid/helpers/gridsetup";

export class RFCenter {
  private EventLimiterName: string = '';
  private _CenterType: CenterType;

  get CenterType(): CenterType {
    if (this._CenterType) return this._CenterType;
    return null;
  }
  set CenterType(val: CenterType) {
    if (val) this._CenterType = val;
  }

  private _ItemType: itemTypes;
  get ItemType(): itemTypes {
    if (this._ItemType) return this._ItemType;
    this.ItemType =
      this.CenterType === CenterType.Recent
        ? itemTypes.itemRecentItem
        : itemTypes.itemFavorite;
    return this._ItemType;
  }
  set ItemType(val: itemTypes) {
    if (val) this._ItemType = val;
  }

  private _Container: JQuery;
  get Container(): JQuery {
    if (this._Container) return this._Container;
    this.Container = $(`<div id="rf-items-${Date.now()}" />`).appendTo(
      '#tdfbodycontent'
    );
    return this._Container;
  }
  set Container(val: JQuery) {
    if (val) this._Container = val;
  }

  private _ToolbarContainer: JQuery;
  get ToolbarContainer(): JQuery {
    if (this._ToolbarContainer) return this._ToolbarContainer;
    this.ToolbarContainer = $(`<div id="rf-toolbar-${Date.now()}" />`).appendTo(
      this.Container
    );
    return this._ToolbarContainer;
  }
  set ToolbarContainer(val: JQuery) {
    if (val) this._ToolbarContainer = val;
  }

  private _GridInstance: RecentItemsOrFavoritesGrid
  get GridInstance(): RecentItemsOrFavoritesGrid {
    return this._GridInstance;
  }
  set GridInstance(val: RecentItemsOrFavoritesGrid) {
    this._GridInstance = val;
  }

  private _GridContainer: JQuery;
  get GridContainer(): JQuery {
    if (this._GridContainer) return this._GridContainer;
    this.GridContainer = $(`<div id="rf-grid-${Date.now()}" />`).appendTo(
      this.DataContainer
    );
    return this._GridContainer;
  }
  set GridContainer(val: JQuery) {
    if (val) this._GridContainer = val;
  }
  private _DataContainer: JQuery;
  get DataContainer(): JQuery {
    if (this._DataContainer) return this._DataContainer;
    this.DataContainer = $(`<div />`).appendTo(this.Container);
    return this._DataContainer;
  }
  set DataContainer(val: JQuery) {
    if (val) this._DataContainer = val;
  }

  private _GridView: IGridView;
  get GridView(): IGridView {
    if (this._GridView) return this._GridView;
    return null;
  }
  set GridView(val: IGridView) {
    if (val) this._GridView = val;
  }

  private _GridOptionOverrides: DevExpress.ui.dxDataGridOptions;
  get GridOptionOverrides(): DevExpress.ui.dxDataGridOptions {
    if (this._GridOptionOverrides) {
      return this._GridOptionOverrides;
    }
    return {
      stateStoring: { enabled: false }
    };
  }
  set GridOptionOverrides(val: DevExpress.ui.dxDataGridOptions) {
    val.stateStoring = { enabled: false };
    if (val) {
      this._GridOptionOverrides = val;
    }
  }

  private _AllowedTypesToShow: itemTypes[]
  get AllowedTypesToShow(): itemTypes[] {
    if (this._AllowedTypesToShow) return this._AllowedTypesToShow;
    return [itemTypes.itemAllItems];
  }
  set AllowedTypesToShow(val: itemTypes[]) {
    if (val) this._AllowedTypesToShow = val;
  }

  private _DefaultItemType: itemTypes
  get DefaultItemType(): itemTypes {
    if (this._DefaultItemType) return this._DefaultItemType;
    return null;
  }
  set DefaultItemType(val: itemTypes) {
    if (val) this._DefaultItemType = val;
  }

  private _UseCards: boolean;
  get UseCards(): boolean {
    if (this._UseCards !== null) return this._UseCards;
    return null;
  }
  set UseCards(val: boolean) {
    if (val !== null) this._UseCards = val;
  }

  constructor(Type: CenterType, EventLimiterName: string, Container?: JQuery) {
    if (Container) {
      if ($.contains(document.documentElement, Container[0]))
        this.Container = Container;
    }
    this.CenterType = Type;
    this.EventLimiterName = EventLimiterName;
    this.SubscribeViewEvents();
    this.SubscribeEvents();
  }

  Init() {
    let rf = this;

    let toolbarButtons: IToolbarItemOverrideOptions[] = [
      { name: 'ItemTypeSelector', index: 1, location: 'before', locateInMenu: 'auto' },
      { name: 'OpenButton', index: 2, location: 'before' },
      { name: 'ActionButton', index: 3, location: 'before' },
      { name: 'ViewLookup', index: 0, location: 'after', locateInMenu: 'never' },
      { name: 'RefreshButton', index: 2, location: 'after' },
      { name: 'OptionsButton', index: 3, location: 'after' }
    ];

    if (
      Preferences.GetCompanyPreference('ShowViewsBtn', 'TDFMobile', '1') === '1'
    ) {
      toolbarButtons.push({ name: 'ViewButton', index: 3, location: 'before' });
    }

    if (rf.CenterType === CenterType.Recent) {
      toolbarButtons.push({ name: 'ClearButton', index: 1, location: 'after', locateInMenu: 'auto' });
    } else if (rf.CenterType === CenterType.Favorite) {
      toolbarButtons.push({ name: 'ManageFavoritesButton', index: 1, location: 'after', locateInMenu: 'auto' });
    }

    new AwesomeToolbar(
      rf.ToolbarContainer,
      rf.ItemType,
      GridContainerTypes.Drilldown,
      rf.EventLimiterName,
      toolbarButtons,
      {
        ItemType: rf.ItemType,
        gridContainerType: GridContainerTypes.Drilldown,
        UniqueCenterName: rf.EventLimiterName,
        AllowedTypesToShow: rf.AllowedTypesToShow,
        DefaultValue: rf.DefaultItemType ? rf.DefaultItemType : itemTypes.itemAllItems
      }
    ).init();
  }

  SubscribeViewEvents() {
    let rf = this;
    AddHandler2(
      EventTypes.CenterEventTypes.gridviewselect,
      rf.EventLimiterName,
      eventNameSpace.modify,
      rf.Container,
      rf.OnGridViewChanged.bind(rf)
    );
    AddHandler2(
      EventTypes.CenterEventTypes.viewsaved,
      rf.EventLimiterName,
      eventNameSpace.notify,
      rf.Container,
      rf.OnViewSaved.bind(rf)
    );

    AddHandler2(
      EventTypes.RFCenterEventTypes.manageFavorites,
      rf.EventLimiterName,
      eventNameSpace.request,
      rf.Container,
      rf.OnManageFavorites.bind(rf)
    );
  }
  SubscribeEvents() {
    let rf = this;
    AddHandler2(
      EventTypes.CenterEventTypes.size,
      rf.EventLimiterName,
      eventNameSpace.notify,
      rf._Container,
      rf.UpdateSize.bind(rf)
    );
  }

  OnViewSaved(e: JQueryEventObject, data: IGridView) {
    let rf = this;

    //Need to direct viewsaved-notify to viewsaved-complete because recent-favorites
    //is not using tabs. (DataCenterTabs is where this is generally handled)
    RaiseEvent2(
      EventTypes.CenterEventTypes.viewsaved,
      rf.EventLimiterName,
      eventNameSpace.complete,
      data
    );
  }

  OnManageFavorites(e, data) {
    let rf = this;

    new RulesManager(FilterTarget.Favorites).DFD.done(() => {
      RaiseEvent2(
        EventTypes.CenterEventTypes.gridrefresh,
        rf.EventLimiterName,
        eventNameSpace.request,
        { clear: "" }
      );
    })
  }

  UpdateSize(e: JQueryEventObject, data: any) {
    let rf = this;
    rf.DataContainer.height(
      `calc(100% - ${rf.ToolbarContainer.height() + 10}px)`
    );
  }

  OnGridViewChanged(e: JQueryEventObject, data: IGridView) {
    let rf = this;
    if (!data) {
      console.error('Somehow there seems to be no selection.');
      return;
    }
    if (!rf.GridView || rf.GridView.GUID !== data.GUID) {
      rf.GridView = data;
      if (rf.GridInstance) {
        rf.GridContainer.empty();
        rf.GridInstance = null;
      }
      rf.GridInstance = new RecentItemsOrFavoritesGrid(
        rf.GridContainer,
        rf.CenterType,
        data,
        rf.EventLimiterName,
        rf.DefaultItemType
      );
      if (rf.GridOptionOverrides) rf.GridInstance.OverrideDxOptions(rf.GridOptionOverrides);
      rf.GridInstance.UseCards = rf.UseCards;

      if (
        !(
          rf.AllowedTypesToShow.length === 1 &&
          rf.AllowedTypesToShow[0] === itemTypes.itemAllItems
        )
      ) {
        (<GridSetup.RecentRequestArgs>rf.GridInstance.dataService.RequestArgs).AllowedTypes = rf.AllowedTypesToShow;
      }

      rf.GridInstance.init();
    }
  }
}
