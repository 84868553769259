import "devextreme/ui/drawer";
import "devextreme/ui/list";
import { Dialog } from "./dialog";
import dxDataSource from "devextreme/data/data_source";
import dxCustomStore from "devextreme/data/custom_store";
// export namespace Dialogs {
//     export class DoNotShowAgain implements Dialogs.IDlgDoNotShowAgain {
//         options: any;
//         ShowDialog(_msg: string, _regKey: string, _title?: string, _defaultResponse?: Dialogs.DialogResult, _defaultDoNotAskText?: string, _buttons?: any, _bShowModal?: boolean): Dialogs.DialogResult {
//             throw new Error("Method not implemented.");
//         }
//         RespectUserPreferences!: boolean;
//     }
//     export class FollowupTask implements Dialogs.IFollowupTask {
//         ShowDialog(): DialogResult {
//             throw new Error("Method not implemented.");
//         }
//         DoNotShow(): boolean {
//             throw new Error("Method not implemented.");
//         }
//     }
//     export class TDFInputBox implements Dialogs.ITDFInputBox {
//         ShowDialog(_prompt: string, _title?: string, _defaultResponse?: string, _icon?: string): DialogResult {
//             throw new Error("Method not implemented.");
//         } Response!: string;
//         Password!: boolean;
//     }
//     export class WaitForm implements Dialogs.IWaitForm {
//         SetCaption(_caption: string) {
//             throw new Error("Method not implemented.");
//         } SetDescription(_description: string) {
//             throw new Error("Method not implemented.");
//         }
//         WaitForm_Show(_status: string, _caption: string) {
//             throw new Error("Method not implemented.");
//         }
//         WaitForm_Close() {
//             throw new Error("Method not implemented.");
//         }
//     }
//     export class ProgressDialog implements Dialogs.IProgressDialog {
//         TotalSteps!: number; CurrentStep!: number;
//         ProcessCancelled() {
//             throw new Error("Method not implemented.");
//         }
//         SetProgress(_stepNum: number, _bigText: string, _smallText: string) {
//             throw new Error("Method not implemented.");
//         }
//         CloseDialog() {
//             throw new Error("Method not implemented.");
//         }
//         AddMessage(_key: string, _message: string) {
//             throw new Error("Method not implemented.");
//         }
//         ClearStatus() {
//             throw new Error("Method not implemented.");
//         }
//         MarkComplete(_bComplete: boolean) {
//             throw new Error("Method not implemented.");
//         }
//         AllowCancellation(_bAllow: boolean) {
//             throw new Error("Method not implemented.");
//         }
//         Indeterminate!: boolean;
//         SetTitle(_title: string) {
//             throw new Error("Method not implemented.");
//         }
//         SetKeyLookupData(_listing: any, _valueMember: string, _displaymember: string, _keyColumnCaption: string) {
//             throw new Error("Method not implemented.");
//         }
//     }
//     export class DialogManager implements Dialogs.IDialogManager {
//         myDialog!: Dialogs.DoNotShowAgain | Dialogs.FollowupTask | Dialogs.ProgressDialog | Dialogs.WaitForm | Dialogs.TDFInputBox;
//         CreateDoNotShowAgain(okCaption: string, cancelCaption: string): Dialogs.IDlgDoNotShowAgain {
//             this.myDialog = new DoNotShowAgain();
//             this.myDialog.options = { buttons: [{ text: okCaption }, { text: cancelCaption }] }
//             return this.myDialog;
//         }
//         CreateFollupTaskDialog(): Dialogs.IFollowupTask {
//             throw new Error("Method not implemented.");
//         }
//         CreateTDFInputBox(): Dialogs.ITDFInputBox {
//             throw new Error("Method not implemented.");
//         }
//         Show(_message: string, _title: string, _buttons: DevExpress.ui.dxButtonOptions): Dialogs.DialogResult {
//             throw new Error("Method not implemented.");
//         }
//         CreateWaitForm(): Dialogs.IWaitForm {
//             throw new Error("Method not implemented.");
//         }
//         CreateProgressDialog(_totalsteps: number): Dialogs.IProgressDialog {
//             throw new Error("Method not implemented.");
//         }
//         ShowNotificationBar(_message: string, _parentControl: any, _noteName: string) {
//             throw new Error("Method not implemented.");
//         }
//         ShowWaitForm(_smallText: string, _bigText: string) {
//             throw new Error("Method not implemented.");
//         }
//         CloseWaitForm() {
//             throw new Error("Method not implemented.");
//         }
//         Yes(_message: string, _title: string): boolean {
//             throw new Error("Method not implemented.");
//         }
//         OK(_message: string, _title: string): boolean {
//             throw new Error("Method not implemented.");
//         }
//         SecurePrompt(_message: string, _title: string): boolean {
//             throw new Error("Method not implemented.");
//         }
//         ShowDialog(_msg: string, _regKey: string,
//             _title: string = "Notice",
//             _defaultResponse: Dialogs.DialogResult = Dialogs.DialogResult.OK,
//             _defaultDoNotAskText: string = "Stop displaying this message.",
//             _buttons: Dialogs.MsgBoxStyle = Dialogs.MsgBoxStyle.OkCancel,
//             _bShowModal: Boolean = true) {
//         }
//     }
// }

export class MinimizeWindowManager {
  static get ds(): DevExpress.data.DataSource {
    if (MinimizeWindowManager._ds) {
      return MinimizeWindowManager._ds;
    }
    MinimizeWindowManager.ds = new dxDataSource({
      store: new dxCustomStore({
        load: _opts => {
          const d = $.Deferred();
          return d.promise(
            d.resolve(
              MinimizeWindowManager._dialogsHash
                ? Object.keys(MinimizeWindowManager._dialogsHash).map(key => {
                    return MinimizeWindowManager._dialogsHash[key];
                  })
                : []
            )
          );
        },
        byKey(key) {
          const d = $.Deferred();
          return d.promise(d.resolve(MinimizeWindowManager._dialogsHash[key]));
        },
        insert(theDialog: Dialog) {
          const d = $.Deferred();
          if (!MinimizeWindowManager._dialogsHash) {
            MinimizeWindowManager._dialogsHash = {
              [theDialog.DialogID]: {
                text:
                  theDialog.Item && theDialog.Item.iMain
                    ? theDialog.Item.iMain.ItemSubject ||
                      theDialog.Instance.option("title")
                    : theDialog.Instance.option("title"),
                icon:
                  theDialog.Item && theDialog.Item.iMain
                    ? `fa tdfitem${theDialog.Item.iMain.ItemType} `
                    : "open",
                ref: theDialog
              }
            };
          } else {
            MinimizeWindowManager._dialogsHash[theDialog.DialogID] = {
              text:
                theDialog.Item && theDialog.Item.iMain
                  ? theDialog.Item.iMain.ItemSubject ||
                    theDialog.Instance.option("title")
                  : theDialog.Instance.option("title"),
              icon:
                theDialog.Item && theDialog.Item.iMain
                  ? `fa tdfitem${theDialog.Item.iMain.ItemType} `
                  : "open",
              ref: theDialog
            };
          }
          MinimizeWindowManager.ds.reload().done(() => {
            d.resolve();
          });
          return d.promise();
        },
        remove(key) {
          const d: JQueryDeferred<void> = $.Deferred();
          delete MinimizeWindowManager._dialogsHash[key];
          return d.promise(d.resolve());
        }
      })
    });
    return MinimizeWindowManager._ds;
  }
  static set ds(val: DevExpress.data.DataSource) {
    MinimizeWindowManager._ds = val;
  }
  private static get store(): DevExpress.data.CustomStore {
    return MinimizeWindowManager.ds.store();
  }

  private static get List(): DevExpress.ui.dxList {
    if (MinimizeWindowManager._list) {
      return MinimizeWindowManager._list;
    }
    const $list = $("<div/>");
    const bodyHeight = $("body").innerHeight() || 800;
    MinimizeWindowManager._list = $list
      .dxList({
        height: bodyHeight * 0.1999,
        dataSource: MinimizeWindowManager.ds,
        onItemClick(e) {
          e.itemData.ref.Instance.show();
        },
        elementAttr: {
          class: "warning"
        }
      })
      .dxList("instance");
    return MinimizeWindowManager._list;
  }
  private static get Drawer(): DevExpress.ui.dxDrawer {
    if (MinimizeWindowManager._drawer) {
      return MinimizeWindowManager._drawer;
    }
    const bodyHeight = $("body").innerHeight() || 800;
    MinimizeWindowManager._drawer = $("#mindraw")
      .dxDrawer({
        // target: "#mindraw",
        openedStateMode: "overlap",
        position: "bottom",
        maxSize: bodyHeight * 0.2,
        closeOnOutsideClick: true,
        revealMode: "slide",
        shading: false,
        template() {
          if (MinimizeWindowManager.List.element().length) {
            return MinimizeWindowManager.List.element();
          }
        },
        opened: false
      })
      .dxDrawer("instance");
    return MinimizeWindowManager._drawer;
  }
  public static OnDialogMinimized(theDialog: Dialog) {
    MinimizeWindowManager.store.insert(theDialog);
    if (!$("#minimized-dialogs").length) {
      const it = `<div id='minimized-dialogs'>
                            <span class="fa fa-window-restore" style="margin:auto;"></span>
                          </div>`;
      $("#tdfbody").append(it);
      $("#minimized-dialogs").on("click", _d => {
        MinimizeWindowManager.Drawer.toggle();
      });
    }
  }
  public static OnDialogClosed(id: string) {
    if (MinimizeWindowManager._dialogsHash) {
      MinimizeWindowManager.store.remove(id).done(() => {
        MinimizeWindowManager.List.reload();
        MinimizeWindowManager.Drawer.hide();
        if ($.isEmptyObject(MinimizeWindowManager._dialogsHash)) {
          MinimizeWindowManager.dispose();
        }
      });
    }
  }
  private static _ds: DevExpress.data.DataSource;
  private static _dialogsHash: {
    [key: string]: {
      text: string;
      icon: string;
      ref: Dialog;
    };
  };
  private static _list: DevExpress.ui.dxList;
  private static _drawer: DevExpress.ui.dxDrawer;
  private static dispose() {
    $("#minimized-dialogs").remove();
    MinimizeWindowManager.List.reload();
    MinimizeWindowManager.Drawer.hide();
  }
}
