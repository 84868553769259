import {BI__ItemDetailBase} from "./bi_itemdetailbase"
import { CenterType, GridContainerTypes } from "../../../enums/enums";
import { BSIGrids } from "../../../enums/bi/enums";
import { Preferences } from "../../../infrastructure/user/preferences";

export class BI__ItemDetailQuoteForm extends BI__ItemDetailBase {
    public readonly TDFContainerType: number = GridContainerTypes.BIDetailFormDialogs;
    public ItemType: number;
    public readonly CustomName: string = "QuoteDetails";
    public readonly DisplayName = "Quote Details";
    public readonly ItemHeaderURL: string = "/bi/BIQuoteHeaderFormData/";
    public readonly HeaderDataCaption: string = "Quote Header";
  
    constructor(ItemID: string, centerType: CenterType = CenterType.Bi) {
      super(ItemID);
  
        const quoteDetailForm = this;

        this.DisplayName = this.getCustomDisplayName(this.DisplayName);
        this.HeaderDataCaption = this.getCustomHeaderCaption(this.HeaderDataCaption);
  
      switch (centerType) {
        case CenterType.Bi:
          quoteDetailForm.ItemType = BSIGrids.BIFormDialogQuote;
          break;
        case CenterType.AccountBi:
          quoteDetailForm.ItemType = BSIGrids.BIFormDialogQuote;
          break;
        case CenterType.OppBi:
          quoteDetailForm.ItemType = BSIGrids.BIFormDialogQuote;
          break;
        case CenterType.VendorBi:
          quoteDetailForm.ItemType = BSIGrids.BIFormDialogQuote;
          break;
        case CenterType.ContactBi:
          quoteDetailForm.ItemType = BSIGrids.BIFormDialogQuote;
          break;
      }
    }
  }