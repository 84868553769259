import { itemTypes } from '../enums/enums';

import * as Context from '../infrastructure/context';
import { TDFRequest } from '../services/request';
import { PackageSelection } from '../components/controls/packageselection';
import { Dialog } from '../components/dialogs/dialog';
import * as Globalize from 'globalize';
import { ItemNavigator } from '../components/items/itemnavigator';
//import { Lead } from "../components/items/lead";
import dxCustomStore from 'devextreme/data/custom_store';
import { SearchRecFav } from '../components/controls/searchrecfav';
import moment = require('moment');

export const Debug: boolean =
  sessionStorage.getItem('Debug') === 'true' ? true : false;
export namespace Services {
  //#region Lead Forensics \TDF Interfaces
  export interface L_F_BusinessItem {
    /**
     * The Lead Forensics unique business identifier
     * */
    BusinessID: number;
    Name: string;
    AddressLine1: string;
    AddressLine2: string;
    AddressLine3: string;
    Locality: string;
    Town: string;
    County: string;
    PostCode: string;
    Country: string;
    Telephone: string;
    Website: string;
    Industry: string;
    SICCode: string;
    // Turnover: string;
    // RegistrationNumber: string;
    /**
     * The number of employees at the business.
     **/
    EmployeeNumber: string;
    // HotScore: number;
    //  VisitorType: string;
  }
  export interface L_F_VisitItem {
    BusinessID: number;
    VisitID: number;
    StartDateTime: string;
    EndDateTime: string;
    Keywords: string;
    Pages: number;
    Multi: number;
    ReferrerName: string;
    ReferrerLink: string;
  }
  export interface TDF_L_F_VisitItem extends L_F_VisitItem {
    Duration: object;
    PageListURL: string;
  }
  export interface Non_TDF_L_F_BusinessItem extends L_F_BusinessItem {
    Accounts: string[];
    Leads: string[];
    Visits: TDF_L_F_VisitItem[];
  }
  export interface L_F_PageItem {
    PageVisitDateTime: string;
    PageLocation: string;
    PageTitle: string;
    ReferringSite: string;
  }
  export interface NewLeadCreationArgs {
    ParentID: string;
    ParentType: itemTypes;
    PendingLead: L_F_BusinessItem;
  }
  //#endregion
  export class LeadForensicsDataService {
    private _API: string;
    DateFilter: { startDate: moment.Moment; endDate: moment.Moment } = {
      startDate: moment().add(-7, 'days'),
      endDate: moment()
    };

    ClearCachedResponses() {
      let lf = this;
      lf.LastResponseAllVisits = null;
    }

    private _TDF_GUID: string;
    get TDF_GUID(): string {
      if (this._TDF_GUID) return this._TDF_GUID;
      return undefined;
    }
    set TDF_GUID(val: string) {
      if (val) this._TDF_GUID = val;
    }

    constructor(guid?: string) {
      let lf = this;
      if (guid) lf.TDF_GUID = guid;

      Context.LoadCompany().done(() => {
        lf._API = TDFRequest.ApiPath;
      });
    }

    //#region Endpoints
    /**
     *
     * **/
    private get BaseURL(): string {
      let lf = this;
      return `${lf._API}/lead-forensics`;
    }

    /**
     * Returns a listing of "businesses" from Lead Forensics, and includes whether the given "business" matches with an existing TDF Item.
     *
     * */
    private get CompaniesBaseURL(): string {
      let lf = this;
      return `${lf.BaseURL}/companies`;
    }

    private _VisitsByTDF_GUID_URL: string;
    /*
     * Get the api url/path for retrieving visit information for a particular TDF Record by the TDF GUID
     * **/
    get VisitsByTDF_GUID_URL(): string {
      let lf = this;
      if (this._VisitsByTDF_GUID_URL) return this._VisitsByTDF_GUID_URL;
      console.assert(
        typeof lf.TDF_GUID === 'string',
        'Looks like a TDF GUID was not set correctly',
        { err: typeof lf.TDF_GUID }
      );
      lf.VisitsByTDF_GUID_URL = `${lf.CompaniesBaseURL}/${lf.TDF_GUID}/visits`;

      return lf._VisitsByTDF_GUID_URL;
    }
    set VisitsByTDF_GUID_URL(val) {
      let lf = this;
      lf._VisitsByTDF_GUID_URL = val ? val : null;
    }

    get CreateLeadsURL(): string {
      let lf = this;
      return `${lf.BaseURL}/leads`;
    }

    private _VisitsByBusinessID_URL: string;
    /*
     * Get the api url/path for retrieving visit information for a particular TDF Record by the TDF GUID
     * **/
    get VisitsByBusinessID_URL(): string {
      let lf = this;
      if (this._VisitsByTDF_GUID_URL) return this._VisitsByTDF_GUID_URL;
      return null;
    }
    /**
     *  val needs to be the business id
     * **/
    set VisitsByBusinessID_URL(val) {
      let lf = this;
      lf._VisitsByTDF_GUID_URL = `${lf.CompaniesBaseURL}/${val}/visits`;
    }

    private _PagesForVisit_URL: string;
    get PagesForVisit_URL(): string {
      if (this._PagesForVisit_URL) return this._PagesForVisit_URL;
      return null;
    }
    /**
     * val needs to be the visit id
     * **/
    set PagesForVisit_URL(val: string) {
      let lf = this;
      if (val) this._PagesForVisit_URL = `${lf.BaseURL}/visits/${val}/pages`;
    }
    //#endregion

    //#region Request Objects
    LoadVisitsByTDF_GUID_Request(): TDFRequest {
      let lf = this;
      return new TDFRequest({
        url: `${
          lf.VisitsByTDF_GUID_URL
        }/?startDate=${lf.DateFilter.startDate.format(
          'YYYY/MM/DD'
        )}&endDate=${lf.DateFilter.endDate.format('YYYY/MM/DD')}`,
        type: 'GET'
      });
    }

    LoadVisitsByBusinessID_Request(): TDFRequest {
      let lf = this;
      return new TDFRequest({
        url: `${
          lf.VisitsByBusinessID_URL
        }/?startDate=${lf.DateFilter.startDate.format(
          'YYYY/MM/DD'
        )}&endDate=${lf.DateFilter.endDate.format('YYYY/MM/DD')}`,
        type: 'GET'
      });
    }

    LoadPagesByVisitRequest(): TDFRequest {
      let lf = this;
      if (lf.PagesForVisit_URL) {
        return new TDFRequest({ url: lf.PagesForVisit_URL, type: 'GET' });
      }
      throw 'Pages for visit URL not set up ';
    }

    LoadAllVisitsRequest(): TDFRequest {
      let lf = this;
      return new TDFRequest({
        url: `${
          lf.CompaniesBaseURL
        }/?startDate=${lf.DateFilter.startDate.format(
          'YYYY/MM/DD'
        )}&endDate=${lf.DateFilter.endDate.format('YYYY/MM/DD')}`,
        type: 'GET'
      });
    }
    //#endregion

    //#region Response Caches

    private _LastResponseAllVisits: any;
    get LastResponseAllVisits(): any {
      return this._LastResponseAllVisits;
    }
    set LastResponseAllVisits(val: any) {
      this._LastResponseAllVisits = val;
    }
    //#endregion

    //#region Data Stores
    private _PagesByVisitDataStore: DevExpress.data.CustomStore;
    get PagesByVisitDataStore(): DevExpress.data.CustomStore {
      let lf = this;
      if (lf._PagesByVisitDataStore) return lf._PagesByVisitDataStore;

      this.PagesByVisitDataStore = new dxCustomStore({
        load(opts) {
          let d = $.Deferred();

          lf.LoadPagesByVisitRequest()
            .MakeRequest()
            .done(response => {
              d.resolve(response, { totalCount: response.length });
            });
          return d.promise();
        }
      });
      return lf._PagesByVisitDataStore;
    }
    set PagesByVisitDataStore(val: DevExpress.data.CustomStore) {
      let lfdc = this;
      if (val) lfdc._PagesByVisitDataStore = val;
    }

    private _VisitsByTDF_GUIDDataStore: DevExpress.data.CustomStore;
    get VisitsByTDF_GUIDDataStore(): DevExpress.data.CustomStore {
      let lf = this;
      if (lf._VisitsByTDF_GUIDDataStore) return lf._VisitsByTDF_GUIDDataStore;

      this.VisitsByTDF_GUIDDataStore = new dxCustomStore({
        load(opts) {
          let d = $.Deferred();

          lf.LoadVisitsByTDF_GUID_Request()
            .MakeRequest()
            .done(response => {
              d.resolve(response.SiteVisitList || [], {
                totalCount: response.RecordCount || 0
              });
            });
          return d.promise();
        }
      });
      return lf._VisitsByTDF_GUIDDataStore;
    }
    set VisitsByTDF_GUIDDataStore(val: DevExpress.data.CustomStore) {
      let lfdc = this;
      if (val) lfdc._VisitsByTDF_GUIDDataStore = val;
    }

    private _VisitsByBusinessID_DataStore: DevExpress.data.CustomStore;
    get VisitsByBusinessID_DataStore(): DevExpress.data.CustomStore {
      let lf = this;
      if (lf._VisitsByTDF_GUIDDataStore) return lf._VisitsByTDF_GUIDDataStore;

      this._VisitsByBusinessID_DataStore = new dxCustomStore({
        load(opts) {
          let d = $.Deferred();

          lf.LoadVisitsByBusinessID_Request()
            .MakeRequest()
            .done(response => {
              d.resolve(response.SiteVisitList || [], {
                totalCount: response.RecordCount || 0
              });
            });

          return d.promise();
        }
      });
      return lf._VisitsByBusinessID_DataStore;
    }
    set VisitsByBusinessID_DataStore(val: DevExpress.data.CustomStore) {
      let lfdc = this;
      if (val) lfdc._VisitsByBusinessID_DataStore = val;
    }

    private _VisitsByAllNonTDFCompaniesDataStore: DevExpress.data.CustomStore;
    get VisitsByAllNonTDFCompaniesDataStore(): DevExpress.data.CustomStore {
      let lf = this;
      if (lf._VisitsByAllNonTDFCompaniesDataStore)
        return lf._VisitsByAllNonTDFCompaniesDataStore;
      lf.VisitsByAllNonTDFCompaniesDataStore = new dxCustomStore({
        load(opts) {
          let d = $.Deferred();

          if (lf.LastResponseAllVisits) {
            return d.promise(
              d.resolve(lf.LastResponseAllVisits.NonTDFItems, {
                totalCount: lf.LastResponseAllVisits.NonTDFItems.length || 0
              })
            );
          }
          lf.LoadAllVisitsRequest()
            .MakeRequest()
            .done(response => {
              lf.LastResponseAllVisits = response;
              d.resolve(response.NonTDFItems || [], {
                totalCount: response.NonTDFItems
                  ? response.NonTDFItems.length
                  : 0
              });
            });
          return d.promise();
        }
      });
      return lf._VisitsByAllNonTDFCompaniesDataStore;
    }
    set VisitsByAllNonTDFCompaniesDataStore(val: DevExpress.data.CustomStore) {
      let lfdc = this;
      if (val) lfdc._VisitsByAllNonTDFCompaniesDataStore = val;
    }
    //#endregion
  }
}

export class LeadForensicsDataCenter {
  LFDataService: Services.LeadForensicsDataService;
  NewLeadParentSelection: PackageSelection;
  AllVisitGrid: DevExpress.ui.dxDataGrid;
  AllVisitsDialog: Dialog;
  PendingLeads: Services.NewLeadCreationArgs[] = [];
  SelectionIndividual: boolean = false;
  AccountVisitsDialog: Dialog;
  AccountVisitGrid: DevExpress.ui.dxDataGrid;

  constructor(itemid?: string) {
    let lfdc = this;
    lfdc.LFDataService = new Services.LeadForensicsDataService(itemid || null);
  }

  get CommonGridSettings(): DevExpress.ui.dxDataGridOptions {
    let options: DevExpress.ui.dxDataGridOptions = {
      columnAutoWidth: true,
      columnResizingMode: 'widget',
      headerFilter: { visible: true },
      filterRow: { visible: true },
      groupPanel: { visible: true },
      searchPanel: { visible: true }
    };

    return options;
  }

  get WebsiteVisitColumns(): DevExpress.ui.dxDataGridColumn[] {
    let lf = this;
    return [
      {
        dataField: 'Pages',
        dataType: 'number',
        visible: true
      },
      {
        dataField: 'Duration',
        dataType: 'string',
        visible: true,
        calculateCellValue(data) {
          return moment(new Date(data.EndDateTime)).to(
            moment(data.StartDateTime),
            true
          );
        }
      },

      {
        dataField: 'ReferrerName',
        caption: 'Referrer',
        dataType: 'string',
        visible: true
      },
      {
        dataField: 'ReferrerLink',
        caption: 'Referrer Link',
        visible: true,
        dataType: 'string',
        cellTemplate(container, options) {
          $('<a>' + options.value + '</a>')
            .attr('href', options.value)
            .attr('target', '_blank')
            .appendTo(container);
        }
      },
      {
        dataField: 'Keywords',
        dataType: 'string',
        visible: true
      },
      {
        dataField: 'StartDateTime',
        visible: true,
        dataType: 'datetime',
        format: {
          type: 'shortDateShortTime'
        }
      },
      {
        dataField: 'EndDateTime',
        visible: true,
        dataType: 'datetime',
        format: {
          type: 'shortDateShortTime'
        }
      },
      {
        dataField: 'BusinessID',
        visible: false
      },
      {
        dataField: 'VisitID',
        visible: false
      },

      {
        dataField: 'Multi',
        dataType: 'number',
        visible: false
      }
    ];
  }

  get CompanyVisitColumns(): DevExpress.ui.dxDataGridColumn[] {
    let lf = this;
    return [
      {
        dataField: 'BusinessID',
        dataType: 'number',
        visible: false
      },
      {
        dataType: 'string',
        visible: true,
        dataField: 'Name',
        visibleIndex: 0
      },
      {
        dataField: 'AddressLine1',
        dataType: 'string',
        visible: true
      },
      {
        dataField: 'AddressLine2',
        dataType: 'string',
        visible: true
      },
      {
        dataField: 'AddressLine3',
        dataType: 'string',
        visible: true
      },
      {
        dataField: 'Locality',
        dataType: 'string',
        visible: true
      },
      {
        dataField: 'Town',
        dataType: 'string',
        visible: true
      },
      {
        dataField: 'County',
        dataType: 'string',
        visible: true
      },
      {
        dataField: 'PostCode',
        dataType: 'string',
        visible: true
      },
      {
        dataField: 'Country',
        dataType: 'string',
        visible: true
      },
      {
        dataField: 'Telephone',
        dataType: 'string',
        visible: true
      },
      {
        dataField: 'WebSite',
        dataType: 'string',
        visible: true,
        visibleIndex: 1,
        cellTemplate: function(container, options) {
          $(`<a>${options.value}</a>`)
            .attr('href', `http://${options.value}`)
            .attr('target', '_blank')
            .appendTo(container);
        }
      },
      {
        dataField: 'Industry',
        dataType: 'string',
        visible: true,
        visibleIndex: 2
      },
      {
        dataField: 'SICCode',
        dataType: 'string',
        visible: true,
        visibleIndex: 3
      },
      {
        dataField: 'EmployeeNumber',
        dataType: 'string',
        visible: true,
        visibleIndex: 4
      },
      {
        dataField: 'Accounts',
        dataType: 'object',
        visible: false
      },
      {
        dataField: 'Leads',
        dataType: 'object',
        visible: false
      },
      {
        dataField: 'Visits',
        dataType: 'object',
        visible: false
      }
    ];
  }

  get PagesForVisitColumns(): DevExpress.ui.dxDataGridColumn[] {
    let lf = this;
    return [
      {
        dataField: 'PageTitle',
        dataType: 'string',
        visible: true,
        caption: 'Title'
      },
      {
        dataField: 'PageLocation',
        visible: true,
        dataType: 'string',
        cellTemplate(container, options) {
          $('<a>' + options.value + '</a>')
            .attr('href', options.value)
            .attr('target', '_blank')
            .appendTo(container);
        }
      },
      {
        dataField: 'ReferringSite',
        visible: true,
        dataType: 'string',
        cellTemplate(container, options) {
          $('<a>' + options.value + '</a>')
            .attr('href', options.value)
            .attr('target', '_blank')
            .appendTo(container);
        }
      },
      {
        dataField: 'PageVisitDateTime',
        caption: 'Visit Date',
        visible: true,
        dataType: 'datetime',
        format: { type: 'shortDateShortTime' }
      }
    ];
  }

  ViewAllVisits() {
    let lfdc = this;

    let opts = lfdc.CommonGridSettings;
    opts.masterDetail = lfdc.VisitDrillDown;
    opts.selection = {
      mode: 'multiple',
      showCheckBoxesMode: 'always',
      allowSelectAll: false
    };
    opts.dataSource = {
      store: lfdc.LFDataService.VisitsByAllNonTDFCompaniesDataStore
    };
    opts.onSelectionChanged = selectionEvent => {
      if (selectionEvent.selectedRowsData.length > 0) {
        lfdc.AddLeadCreationButtons(selectionEvent);
      } else {
        lfdc.AllVisitsDialog.Instance.option('toolbarItems', []);
      }
      lfdc.AllVisitGrid.option(
        'height',
        lfdc.AllVisitsDialog.Instance.content().height() -
          $('#lf-date-range').height()
      );
    };
    opts.paging = { enabled: false };
    opts.columns = lfdc.CompanyVisitColumns;

    lfdc.AllVisitGrid = $('<div />')
      .dxDataGrid(opts)
      .dxDataGrid('instance');

    lfdc.AllVisitsDialog = new Dialog(
      {
        //body: $("<div />").append($(lfdc.FilterDisplay).append(lfdc.DateFilterBuilder.element())).append(lfdc.AllVisitGrid.element()), title: "Business that have visited.", closable: true, size: "size-large", type: "type-warning"
        body: $('<div />')
          .append(lfdc.DateRangeSelector.element())
          .append(lfdc.AllVisitGrid.element()),
        title: 'Businesses that have visited.',
        closable: true,
        size: 'size-large',
        type: 'type-warning'
      },
      null,
      true,
      false
    );
    lfdc.AllVisitsDialog.open().done(() => {
      lfdc.ListenForEditFilterClicked(lfdc.AllVisitGrid, lfdc.AllVisitsDialog);
      //lfdc.AllVisitGrid.option("height", lfdc.AllVisitsDialog.Instance.content().height() - $("#lf-filter-display").height())
      lfdc.AllVisitGrid.option(
        'height',
        lfdc.AllVisitsDialog.Instance.content().height() -
          $('#lf-date-range').height()
      );
    });
  }

  UpdateFilterDisplayedValues() {
    let lfdc = this;
    $('#lf-start').text(
      Globalize.formatDate(lfdc.LFDataService.DateFilter.startDate.toDate())
    );
    $('#lf-end').text(
      Globalize.formatDate(lfdc.LFDataService.DateFilter.endDate.toDate())
    );
  }

  private _DateFilterBuilder: DevExpress.ui.dxFilterBuilder;
  get DateFilterBuilder(): DevExpress.ui.dxFilterBuilder {
    let lfdc = this;
    if (this._DateFilterBuilder) return this._DateFilterBuilder;

    lfdc._DateFilterBuilder = $('<div />')
      .dxFilterBuilder(<DevExpress.ui.dxFilterBuilderOptions>{
        fields: [
          {
            dataField: 'startDate',
            caption: 'Start',
            dataType: 'date',
            filterOperations: ['=']
          },
          {
            dataField: 'endDate',
            caption: 'End',
            dataType: 'date',
            filterOperations: ['=']
          }
        ],
        visible: false,

        value: [
          ['startDate', '=', lfdc.LFDataService.DateFilter.startDate.toDate()],
          'and',
          ['endDate', '=', lfdc.LFDataService.DateFilter.endDate.toDate()]
        ],
        onValueChanged(e) {
          if ($.isArray(e.value)) {
            (e.value as any[]).forEach((val, idx) => {
              if ($.isArray(val)) {
                (val as any[]).forEach((v, i) => {
                  if (typeof v === 'string') {
                    if (v === 'startDate' || v === 'endDate') {
                      lfdc.LFDataService.DateFilter[v] = moment(val[2]);
                    }
                  }
                });
              }
            });
          }
          lfdc.UpdateFilterDisplayedValues();
          if (lfdc.AllVisitGrid) {
            lfdc.AllVisitGrid.getDataSource().reload();
          }
          if (lfdc.AccountVisitGrid) {
            lfdc.AccountVisitGrid.getDataSource().reload();
          }
        }
      })
      .dxFilterBuilder('instance');

    return lfdc._DateFilterBuilder;
  }
  set DateFilterBuilder(val: DevExpress.ui.dxFilterBuilder) {
    if (val) this._DateFilterBuilder = val;
  }

  private AddLeadCreationButtons(selectionEvent: {
    component?: DevExpress.DOMComponent;
    element?: DevExpress.core.dxElement;
    model?: any;
    currentSelectedRowKeys?: any[];
    currentDeselectedRowKeys?: any[];
    selectedRowKeys?: any[];
    selectedRowsData?: any[];
  }) {
    let lfdc = this;
    if (selectionEvent.selectedRowsData.length === 1) {
      lfdc.AllVisitsDialog.Instance.option('toolbarItems', <
        DevExpress.ui.dxPopupToolbarItem[]
      >[
        {
          toolbar: 'bottom',
          location: 'after',
          widget: 'dxButton',
          options: <DevExpress.ui.dxButtonOptions>{
            text: 'Create Lead',
            icon: 'dx-icon tdfitem30',
            onClick(e) {
              lfdc.SelectAccountPackageForLead(selectionEvent.selectedRowsData);
            }
          }
        }
      ]);
    } else {
      lfdc.AllVisitsDialog.Instance.option('toolbarItems', <
        DevExpress.ui.dxPopupToolbarItem[]
      >[
        {
          toolbar: 'bottom',
          location: 'after',
          widget: 'dxButton',
          options: <DevExpress.ui.dxButtonOptions>{
            text: 'Select parent for all selected',
            icon: 'fa fa-search-plus',
            //elementAttr: {style:"color:#333"},
            // type:"warning",
            onClick(e) {
              lfdc.SelectAccountPackageForLead(selectionEvent.selectedRowsData);
            }
          }
        },
        {
          toolbar: 'bottom',
          location: 'after',
          widget: 'dxButton',
          options: <DevExpress.ui.dxButtonOptions>{
            text: 'Select parent individually',
            icon: 'search',
            //elementAttr: { style: "color:#333" },
            //type: "info",
            onClick(e) {
              lfdc.SelectAccountPackageForLead(
                selectionEvent.selectedRowsData,
                true
              );
            }
          }
        }
      ]);
    }
  }

  ViewVisitsForAccount() {
    let lfdc = this;
    let opts = lfdc.CommonGridSettings;
    opts.masterDetail = lfdc.PagesDrillDown;
    opts.dataSource = {
      store: lfdc.LFDataService.VisitsByTDF_GUIDDataStore
    };
    opts.onCellPrepared = (e: any) => {
      if (e.rowType === 'data' && e.column.command === 'expand') {
        if (!(e.data.Pages > 0)) {
          e.cellElement.removeClass('dx-datagrid-expand');
          e.cellElement.empty();
        }
      }
    };
    opts.columns = lfdc.WebsiteVisitColumns;

    lfdc.AccountVisitGrid = $('<div />')
      .dxDataGrid(opts)
      .dxDataGrid('instance');

    lfdc.AccountVisitsDialog = new Dialog(
      {
        body: $('<div />')
          .append(lfdc.DateRangeSelector.element())
          .append(lfdc.AccountVisitGrid.element()),
        title: 'Website visits',
        closable: true,
        size: 'size-large',
        type: 'type-warning'
      },
      null,
      true,
      false
    );

    lfdc.AccountVisitsDialog.open().done(() => {
      lfdc.ListenForEditFilterClicked(
        lfdc.AccountVisitGrid,
        lfdc.AccountVisitsDialog
      );
      lfdc.AccountVisitGrid.option(
        'height',
        lfdc.AccountVisitsDialog.Instance.content().height() -
          $('#lf-date-range').height()
      );
    });
  }

  get FilterDisplay(): string {
    let lfdc = this;
    let template = `<div id="lf-filter-display">
			       <span id="lf-filter-edit" title="Edit Filter" class="fa fa-pencil fa-2x" style="padding:5px 0; cursor:pointer;">
				<span id="lf-start" style="padding: 5px; vertical-align: middle;">${Globalize.formatDate(
          lfdc.LFDataService.DateFilter.startDate.toDate()
        )}</span>
				<span> - </span>
				<span id="lf-end" style="padding: 5px; vertical-align: middle;">${Globalize.formatDate(
          lfdc.LFDataService.DateFilter.endDate.toDate()
        )}</span>
				</span>
			    </div>`;
    return template;
  }

  ListenForEditFilterClicked(grid: DevExpress.ui.dxDataGrid, dialog: Dialog) {
    let lfdc = this;
    $('#lf-filter-edit').on('click', e => {
      lfdc.DateFilterBuilder.option(
        'visible',
        !lfdc.DateFilterBuilder.option('visible')
      );
      grid.option(
        'height',
        dialog.Instance.content().height() - $('#lf-filter-display').height()
      );
    });
  }

  get VisitDrillDown(): {
    enabled?: boolean;
    autoExpandAll?: boolean;
    template?:
      | string
      | Function
      | JQuery
      | Element
      | ((
          detailElement: DevExpress.core.dxElement,
          detailInfo: { key?: any; data?: any }
        ) => any);
  } {
    let lfdc = this;
    return {
      enabled: true,
      template(el, info) {
        let theStore = info.data.Visits;
        if ((!theStore || theStore.length <= 0) && info.data.BusinessID) {
          lfdc.LFDataService.VisitsByBusinessID_URL = info.data.BusinessID;
          theStore = lfdc.LFDataService.VisitsByBusinessID_DataStore;
        }

        let dgopts: DevExpress.ui.dxDataGridOptions = {
          dataSource:
            typeof theStore === 'function'
              ? {
                  store: theStore
                }
              : theStore,
          columns: lfdc.WebsiteVisitColumns,
          masterDetail: lfdc.PagesDrillDown
        };
        el.append(
          $('<div>')
            .css({
              padding: '0 0 5px 10px',
              'font-size': ' 14px',
              'font-weight': ' bold'
            })
            .text('Visits')
        ).append($('<div />').dxDataGrid(dgopts));
      }
    };
  }

  get PagesDrillDown(): {
    enabled?: boolean;
    autoExpandAll?: boolean;
    template?:
      | string
      | Function
      | Element
      | JQuery
      | ((
          detailElement: DevExpress.core.dxElement,
          detailInfo: { key?: any; data?: any }
        ) => any);
  } {
    let lfdc = this;
    return {
      enabled: true,
      template(el, info) {
        lfdc.LFDataService.PagesForVisit_URL = info.data.VisitID;
        let dgopts: DevExpress.ui.dxDataGridOptions = {
          dataSource: {
            store: lfdc.LFDataService.PagesByVisitDataStore
          },
          columns: lfdc.PagesForVisitColumns
        };
        el.append(
          $('<div>')
            .css({
              padding: '0 0 5px 10px',
              'font-size': ' 14px',
              'font-weight': ' bold'
            })
            .text('Pages')
        ).append($('<div />').dxDataGrid(dgopts));
      }
    };
  }

  SelectAccountPackageForLead(
    selected: Services.L_F_BusinessItem[],
    individual: boolean = false
  ) {
    let lfdc = this;
    let p;
    lfdc.SelectionIndividual = individual;

    if (individual && selected.length > 0) {
      lfdc.NewLeadParentSelection = new PackageSelection();
      lfdc.NewLeadParentSelection.Buttons.push(lfdc.SearchForExistingBtn);
      lfdc.NewLeadParentSelection.DialogTitle = `Select Parent for Lead.  Company:(${
        selected[0].Name
      })`;

      lfdc.NewLeadParentSelection.DialogCallBack = data => {
        lfdc.SelectAccountPackageForLead(selected, true);
      };
      lfdc.NewLeadParentSelection.OnCommit = data => {
        let it = selected.shift();
        Object.keys(data).forEach((v, k) => {
          Object.keys(data[v]).forEach((v1, k1) => {
            if (v1.search(/selectedItem/i) > -1) {
              p = data[v][v1]['folderid'];
            }
          });
        });
        if (p) {
          lfdc.PendingLeads.push({
            ParentID: p,
            ParentType: itemTypes.itemAccountPackage,
            PendingLead: it
          });
          let rowIndex = lfdc.AllVisitGrid.getRowIndexByKey(it);
          let row: JQuery = lfdc.AllVisitGrid.getRowElement(rowIndex);
          if (row) row.children('td').css('text-decoration', 'line-through');
          lfdc.AllVisitGrid.deselectRows([it]);
        }
        $(`#${lfdc.NewLeadParentSelection.Dialog.DialogID}`).remove();
      };

      lfdc.NewLeadParentSelection.DisplayPanel();
    } else {
      if (selected.length === 0) {
        if (lfdc.PendingLeads.length > 0) {
          lfdc.CreateLeads();
        }
      }

      if (selected.length > 0) {
        lfdc.NewLeadParentSelection = new PackageSelection();
        lfdc.NewLeadParentSelection.Buttons.push(lfdc.SearchForExistingBtn);
        lfdc.NewLeadParentSelection.DialogTitle = `Select Parent.`;

        lfdc.NewLeadParentSelection.OnCommit = data => {
          function createPendingLeads(businesses) {
            Object.keys(data).forEach((v, k) => {
              Object.keys(data[v]).forEach((v1, k1) => {
                if (v1.search(/selectedItem/i) > -1) {
                  p = data[v][v1]['folderid'];
                }
              });
            });

            while (businesses.length > 0) {
              let it = businesses.shift();

              if (p) {
                lfdc.PendingLeads.push({
                  ParentID: p,
                  ParentType: itemTypes.itemAccountPackage,
                  PendingLead: it
                });
                let rowIndex = lfdc.AllVisitGrid.getRowIndexByKey(it);
                let row: JQuery = lfdc.AllVisitGrid.getRowElement(rowIndex);
                if (row)
                  row.children('td').css('text-decoration', 'line-through');
                lfdc.AllVisitGrid.deselectRows([it]);
              }
            }
          }
          createPendingLeads(selected);

          $(`#${lfdc.NewLeadParentSelection.Dialog.DialogID}`).remove();

          lfdc.CreateLeads();
        };

        lfdc.NewLeadParentSelection.DisplayPanel();
      }
    }
  }

  SelectParentForLead(
    selected: Services.L_F_BusinessItem[],
    individual: boolean = false
  ) {
    let lfdc = this;
    let p, itemtype;
    if (individual && selected.length > 0) {
      let SRF: SearchRecFav = new SearchRecFav(
        itemTypes.itemLead,
        [1, 5, 8, 9, 18, 19, 27, 30, 13, 16],
        itemTypes.itemAccount,
        false
      );
      SRF.GetSRFDialog().done(items => {
        SRF.Dialog.close();
        $(`#${SRF.Dialog.DialogID}`).remove();

        let it = selected.shift();

        Object.keys(items[0]).forEach((v, k) => {
          if (v.search(/itemid/i) > -1 || v.search(/tdf guid/i) > -1) {
            p = items[0][v];
          }
          if (v.search(/itemtype/i) > -1) {
            itemtype = items[0][v];
          }
        });
        if (p) {
          lfdc.PendingLeads.push({
            ParentID: p,
            ParentType: itemtype || itemTypes.itemUnknown,
            PendingLead: it
          });
          let rowIndex = lfdc.AllVisitGrid.getRowIndexByKey(it);
          let row: JQuery = lfdc.AllVisitGrid.getRowElement(rowIndex);
          if (row) row.children('td').css('text-decoration', 'line-through');

          //lfdc.AllVisitGrid.getRowElement(lfdc.AllVisitGrid.getRowIndexByKey(it)).children('td').css("text-decoration", "line-through");
          lfdc.AllVisitGrid.deselectRows([it]);
        }
        lfdc.SelectParentForLead(selected, lfdc.SelectionIndividual);
      });
      SRF.Dialog.setTitle(
        `Select Parent for Lead.  Company:(${selected[0].Name})`
      );
    } else {
      if (selected.length === 0) {
        if (lfdc.PendingLeads.length > 0) {
          lfdc.CreateLeads();
        }
      }
      if (selected.length > 0) {
        let SRF: SearchRecFav = new SearchRecFav(
          itemTypes.itemLead,
          [1, 5, 8, 9, 18, 19, 27, 30, 13, 16],
          itemTypes.itemAccount,
          false
        );
        SRF.GetSRFDialog().done(items => {
          function createPendingleads(businesses) {
            Object.keys(items[0]).forEach((v, k) => {
              if (v.search(/itemid/i) > -1 || v.search(/tdf guid/i) > -1) {
                p = items[0][v];
              }
              if (v.search(/itemtype/i) > -1) {
                itemtype = items[0][v];
              }
            });
            while (businesses.length > 0) {
              if (p) {
                lfdc.PendingLeads.push({
                  ParentID: p,
                  ParentType: itemtype || itemTypes.itemUnknown,
                  PendingLead: businesses[0]
                });
                let rowIndex = lfdc.AllVisitGrid.getRowIndexByKey(
                  businesses[0]
                );
                let row: JQuery = lfdc.AllVisitGrid.getRowElement(rowIndex);
                if (row)
                  row.children('td').css('text-decoration', 'line-through');

                //lfdc.AllVisitGrid.getRowElement(lfdc.AllVisitGrid.getRowIndexByKey(businesses[0])).children('td').css("text-decoration", "line-through");
                lfdc.AllVisitGrid.deselectRows([businesses[0]]);
                businesses.shift();
              }
            }
          }
          createPendingleads(selected);
          lfdc.CreateLeads();
        });
      }
    }
  }

  get DateRangeSelector(): DevExpress.viz.dxRangeSelector {
    let lfdc = this;
    let rs: DevExpress.viz.dxRangeSelector = $('<div />')
      .dxRangeSelector(<DevExpress.viz.rangeSelector.dxRangeSelectorOptions>{
        elementAttr: { id: 'lf-date-range' },
        scale: {
          startValue: moment(
            moment()
              .startOf('year')
              .add(-1, 'years')
          )
            .startOf('year')
            .toDate(),
          endValue: moment()
            .add(1, 'months')
            .toDate(),
          minorTickInterval: 'day',
          tickInterval: 'month', //{ years: 1 },
          minRange: 'day',
          maxRange: { years: 1 },
          minorTick: { visible: false },
          label: { format: 'monthAndYear' },
          marker: { visible: false }
        },
        sliderMarker: {
          //   color: "#f0ad4e",
          format: 'shortDate'
        },
        value: [
          lfdc.LFDataService.DateFilter.startDate.toDate(),
          lfdc.LFDataService.DateFilter.endDate.toDate()
        ],

        shutter: { opacity: 0.5 },
        indent: {
          left: 65,
          right: 65
        },
        size: {
          height: 100
        },
        margin: {
          top: 5,
          bottom: 5
        },
        title: {
          horizontalAlignment: 'center',
          margin: {
            bottom: -10
          },
          //font: {
          //    size: "12px"
          //},
          text: 'Date Range.' //"Select a date range"
        },
        onValueChanged: function(e) {
          lfdc.LFDataService.DateFilter.startDate = moment(e.value[0]);
          lfdc.LFDataService.DateFilter.endDate = moment(e.value[1]);
          lfdc.LFDataService.ClearCachedResponses();
          if (lfdc.AllVisitGrid) {
            lfdc.AllVisitGrid.getDataSource().reload();
          }
          if (lfdc.AccountVisitGrid) {
            lfdc.AccountVisitGrid.getDataSource().reload();
          }
        }
        //  theme: 'generic.dark' // 'material.blue.light'
      })
      .dxRangeSelector('instance');

    return rs;
  }

  CreateLeads() {
    let lfdc = this;
    if (!lfdc.PendingLeads || lfdc.PendingLeads.length <= 0) return;

    new TDFRequest({
      url: lfdc.LFDataService.CreateLeadsURL,
      data: lfdc.PendingLeads,
      type: 'POST'
    })
      .MakeRequest()
      .done(response => {
        if (
          response &&
          response.CreatedItems &&
          response.CreatedItems.length > 0
        ) {
          if (response.CreatedItems.length > 1) {
            new ItemNavigator(response.CreatedItems, itemTypes.itemLead);
          } else {
            import('../components/items/lead').then(leadMod => {
              new leadMod.Lead({
                ItemID: response.CreatedItems[0]
              }).Initialize();
            });
          }
          lfdc.PendingLeads = [];
        }
      });
  }

  get SearchForExistingBtn(): DevExpress.ui.dxPopupToolbarItem {
    let lfdc = this;

    return {
      widget: 'dxButton',
      toolbar: 'bottom',
      location: 'after',
      options: {
        text: 'Search For an Exisiting Item Instead',
        icon: 'fa fa-search',
        type: 'info',
        onClick: function() {
          lfdc.NewLeadParentSelection.Dialog.close();
          let selected = lfdc.AllVisitGrid.getSelectedRowsData();
          lfdc.SelectParentForLead(selected, lfdc.SelectionIndividual);

          //SRF
        }
      }
    };
  }

  get UseForAllBtn(): DevExpress.ui.dxPopupToolbarItem {
    let lfdc = this;
    return {
      widget: 'dxButton',
      toolbar: 'bottom',
      location: 'after',
      options: {
        text: 'Use for all selected',
        icon: 'todo',
        type: 'warning',
        onClick: function() {
          lfdc.NewLeadParentSelection.Dialog.close();

          let LeadsToCreate: Services.NewLeadCreationArgs[] = [];
          lfdc.AllVisitGrid.getSelectedRowsData().forEach((v, k) => {});
        }
      }
    };
  }
}
