import { DataScopeSelectionTypes } from "../../enums/user/datascope/enums";
import { DataScopeSelectionPanel } from "../../infrastructure/user/datascopeselectionpanel";
import { ItemListingNameModel } from "../../interfaces/interfaces";
import { TDFRequest } from "../../services/request";
import { Preferences } from "../../infrastructure/user/preferences";
import { Dialog } from "components/dialogs/dialog";

export class HomeListSelection extends DataScopeSelectionPanel {
  readonly DialogTitle: string = "Home List Setting";
  readonly ScopeSelectionType: DataScopeSelectionTypes = DataScopeSelectionTypes.HomeListScope;

  private initialItemsLoaded: boolean = false;

  //PromiseHolders
  protected initialItemListPromise;

  constructor() {
    super();
    this.GetInitialSelectedItemList();
  }

  GetInitialSelectedItemList(): JQueryPromise<any[]> {
    var panel = this;

    let dfd: JQueryDeferred<any[]> = $.Deferred();
    if (!panel.initialItemsLoaded) {
      if (panel.initialItemListPromise != undefined) {
        return panel.initialItemListPromise;
      }

      var request = new TDFRequest({
        url: "/core/user/GetItemListWithNames",
        type: "GET",
        data: {
          guidsList: Preferences.GetPreference("HomeList", "ManagersConsole")
        }
      });
      request.MakeRequest().done(function (response: ItemListingNameModel) {
        let initialItemListingWithNames: ItemListingNameModel = response;

        panel.SelectedItems = {};
        panel.ActiveSelectionLevel = parseInt(
          Preferences.GetPreference("HomeType", "ManagersConsole")
        );
        panel.ActiveFolderLevel = panel.ConvertScopeLevelToFolderTab(
          panel.ActiveSelectionLevel
        );

        Object.keys(initialItemListingWithNames.ItemListWithNames).forEach(
          function (key, index) {
            panel.AddSelectedItem(
              panel.ActiveFolderLevel,
              key,
              initialItemListingWithNames.ItemListWithNames[key]
            );
          }
        );

        panel.initialItemsLoaded = true;
        dfd.resolve(panel.CurrentlySelectedItemsAsArray());
      });
    } else {
      return dfd.promise(dfd.resolve(panel.CurrentlySelectedItemsAsArray()));
    }

    panel.initialItemListPromise = dfd.promise();
    return panel.initialItemListPromise;
  }

  CommitPanelSelections(setAsDefault: boolean) {
    var panel = this;

    Preferences.SetPreferences([
      {
        PropertyName: "HomeList",
        PropertyValue: panel.CurrentlySelectedItemsAsString(),
        ModuleName: "ManagersConsole"
      }, {
        PropertyName: "HomeType",
        PropertyValue: panel.ActiveSelectionLevel,
        ModuleName: "ManagersConsole"
      }
    ]);

  }

  LoadSavedSelection(id?) {
    var panel = this;
    // do nothing.
  }

  DisplayPanel(dialogCallBack?: Function) {
    var panel = this;
    $.when<any>(
      panel.GetInitialSelectedItemList(),
      panel.GetDataScopeSelectorModel()
    ).done(function (response1, response2) {


      var homeListSelector = $("<div />")
        .attr("id", "scope-settings-container")
        .addClass("tdfitemtabs");
      var selectedItems = $(
        "<div id='homeListSettings' class='col-sm-12' style='margin:0px'><span id='num-current-selected'>0 Currently Selected</span><div id='selectedScope'></div></div>"
      );

      let body = $("<div />").append(homeListSelector, selectedItems);
      // $("#homelist-container").append(selectedItems);

      panel.Dialog = new Dialog({
        title: panel.DialogTitle,
        body: body,
        closable: true,
        onHidden: (e) => {
          if (dialogCallBack) {
            dialogCallBack();
          }
        },
        buttons: [
          {
            location: 'after',
            toolbar: 'bottom',
            widget: 'dxButton',
            options: <DevExpress.ui.dxButtonOptions>{
              text: 'OK',
              type: 'success',
              icon: 'check',
              onClick: () => {
                panel.CommitPanelSelections(true);
                panel.Dialog.close();
              }
            }
          }, {
            location: 'after',
            toolbar: 'bottom',
            widget: 'dxButton',
            options: <DevExpress.ui.dxButtonOptions>{
              text: 'Cancel',
              type: 'danger',
              icon: 'remove',
              onClick: () => {
                panel.Dialog.close();
              }
            }
          }
        ]
      })

      panel.Dialog.open().done(() => {
        panel.BuildPanel(response2);
      })
    });
  }
}
