import * as Globalize from "globalize";
import * as moment from "moment";
import { EnumParent, itemTypes, menuActions } from "../../enums/enums";
import { ExpenseDetails, ExpenseMileage, IItemInfo } from "../../interfaces/interfaces";
import { NewExpenseArgs } from "../../interfaces/items/interfaces";
import { TDFRequest } from "../../services/request";
import { AttachmentViewer } from "../controls/attachmentviewer";
import { Notification } from "../dialogs/notification";
import { ItemBase } from "./itembase";
import { GetDevice } from "../../util/allutils";
import dxDataSource from "devextreme/data/data_source";
import dxArrayStore from "devextreme/data/array_store";
import { DisplayInfo, CurrentUser, CurrentCompany } from "../../infrastructure/context";
import { SearchRecFav } from "../controls/searchrecfav";
import { MenuAction } from "util/menuactions";

class ExpenseDetailControlTypes {

  static Description: DevExpress.ui.dxFormSimpleItem = {
    dataField: "Description",
    editorType: "dxTextBox",
    label: { text: "Description" },
    visible: true,
    visibleIndex: 0,
    colSpan: 2
  };

  static group: DevExpress.ui.dxFormGroupItem = {
    itemType: "group",
    colCountByScreen: {
      lg: 3,
      md: 2,
      sm: 2,
      xs: 2
    },
    colSpan: 1,
    visible: true,
    visibleIndex: 1,
    items: [
      { itemType: "empty", colSpan: 1, visibleIndex: 2 },
      {
        dataField: "MyAttachments",
        itemType: "button",
        visibleIndex: 0,
        colSpan: 1,
        buttonOptions: {
          elementAttr: { "text-align": "left" },
          text: "Attachments",
          icon: "box"
        }
      },
      {
        dataField: "upload",
        itemType: "button",
        cssClass: "hold",
        visible: true,
        visibleIndex: 1,
        colSpan: 1,
        buttonOptions: {
          elementAttr: { "text-align": "left" },
          text: "",
          icon: "photo"
        }
      }
    ]
  };

  static ExpenseDate: DevExpress.ui.dxFormSimpleItem = {
    dataField: "ExpenseDate",
    editorType: "dxDateBox",
    label: { text: "Expense Date" },
    visible: true,
    visibleIndex: 3,
    colSpan: 1,
    editorOptions: {
      pickerType: GetDevice().isDevice ? "native" : "calendar"
    }
  };

  static ExpenseAmount: DevExpress.ui.dxFormSimpleItem = {
    dataField: "ExpenseAmount",
    editorType: "dxTextBox",
    label: { text: "Amount" },
    visible: true,
    visibleIndex: 2,
    colSpan: 1
  };

  static Mileage: DevExpress.ui.dxFormButtonItem = {
    // dataField: "Mileage",
    itemType: "button",
    visible: true,
    visibleIndex: 3,
    colSpan: 1,
    buttonOptions: {
      elementAttr: { id: "btnMileage" },
      text: "Mileage",
      icon: "edit"
    }
  };

  static ReimbursementChoices = [
    { choice: "Reimburse", choiceValue: true },
    { choice: "Company Paid", choiceValue: false }
  ];
  static Reimbursement: DevExpress.ui.dxFormSimpleItem = {
    dataField: "Reimbursement",
    editorType: "dxSelectBox",
    editorOptions: {
      dataSource: new dxDataSource({
        store: new dxArrayStore({
          data: ExpenseDetailControlTypes.ReimbursementChoices
        })
      }),
      displayExpr: "choice",
      valueExpr: "choiceValue"
    },
    label: { text: "Reimburse" },
    visible: true,
    visibleIndex: 4,
    colSpan: 1
  };

  static Age: DevExpress.ui.dxFormSimpleItem = {
    dataField: "ExpenseAge",
    editorType: "dxTextBox",
    editorOptions: { disabled: true },
    label: { text: "Age In Days" },
    visible: true,
    visibleIndex: 5,
    colSpan: 1
  };
}

export class Expense extends ItemBase {
  private readonly BaseExpensePath: string = "/Expense/";
  private readonly GetDetailsURL: string = this.BaseExpensePath +
    "ExpenseDetails";
  private readonly GetExpenseMilageURL: string = this.BaseExpensePath +
    "GetExpenseMileage";
  private readonly SaveDetailsURL: string = this.BaseExpensePath +
    "SaveExpenseDetails";
  //   private readonly ExpenseDetailsContainerID = "ExpenseDetails";
  DisplayName: string;
  get TypeName() {
    return "Expense";
  }
  set TypeName(name) {
    if (name && this.TypeName !== name) this.TypeName = name;
  }
  get ItemType() {
    return itemTypes.itemExpense;
  }
  get RequiresParentItem(): boolean {
    return false;
  }

  /* Note we are overriding the base.
     Suppress certain actions like "Save and Close", which do not behave
    in a way that makes sense for expenses. */
  ToolbarItems(): DevExpress.ui.dxToolbarItemTemplate[] {
    let iBase = this;
    iBase.DefaultToolbarButtons = [
      iBase.btnAll(),
      iBase.btnActions(),
      iBase.btnViews(),
      iBase.btnChangeParent(),
      iBase.btnSave(),
      iBase.btnSaveCopy(),
    ];

    return iBase.DefaultToolbarButtons;
  }

  ExpenseDetails: ExpenseDetails;
  DetailsDirty: boolean = false;
  MileageDirty: boolean = false;
  DirtyDetails: any = {};
  DetailSectionID: string;
  DetailTabs: any;
  MileageInfo: ExpenseMileage = undefined;
  MileageForm: DevExpress.ui.dxForm;
  PresetDate;
  ExpenseDetailForm: DevExpress.ui.dxForm;
  constructor(args) {
    super(args);
    let theExpense = this;
    $.each(args, function (key, val) {
      if (theExpense.hasOwnProperty(key)) theExpense[key] = val;
    });

    if (!theExpense.DisplayName) {
      DisplayInfo(theExpense.ItemType).done(function (displayinfo) {
        theExpense.DisplayName = displayinfo.DisplayName;
        theExpense.TypeName = displayinfo.TypeName;
      });
    }
  }

  Create(expensedata?) {
    let expenseItem = this;
    //let recent: Recent;
    //let fav: Favorites;
    let d = $.Deferred();
    let theWindow;

    let request = new TDFRequest({
      url: "/item/ValidParents/",
      data: { itemtype: expenseItem.ItemType }
    });
    request.MakeRequest().done(function (response: any) {
      let SRF: SearchRecFav = new SearchRecFav(
        itemTypes.itemExpense,
        response,
        itemTypes.itemUnknown,
        false,
        0,
        expenseItem.getGeneralExpenseButton()
      );

      SRF.GetSRFDialog().done(selected => {
        if (!GetDevice().isDevice) {
          theWindow = window.open();
        }

        let parentid = selected[0]["TDF GUID"];
        let desc =
          selected[0]["Company"] ||
          selected[0]["Subject"] ||
          selected[0]["CompanyName"] ||
          selected[0]["AccountName"] ||
          selected[0]["Description"];
        if (!expensedata) {
          expensedata = {
            New: true,
            Description:
              desc ||
              " " +
              " -- " +
              Globalize.formatDate(new Date(), { date: "short" }),
            Dirty: true,
            ExpenseAmount: 0,
            ExpenseDate: expenseItem.PresetDate
              ? Globalize.formatDate(new Date(expenseItem.PresetDate), {
                date: "short"
              })
              : Globalize.formatDate(new Date(), { date: "short" }),
            MileageID: -1,
            Reimbursement: true
          };
        } else {
          expensedata["Description"] =
            desc ||
            " " + " -- " + Globalize.formatDate(new Date(), { date: "short" });
          expensedata["Reimbursement"] = true;
        }

        let nexpargs: NewExpenseArgs = {
          ParentID: parentid,
          ParentInfo: EnumParent.Linked,
          ReferenceExpenseID: expenseItem.ItemID || "",
          ParentType: selected[0].ItemType || itemTypes.itemUnknown
        };

        new TDFRequest({
          url: "/Expense/CreateExpense/",
          data: { args: nexpargs, expensedata: expensedata },
          type: "POST"
        })
          .MakeRequest()
          .done(function (response: { Message: string, ID: string }) {

            if (window.location.href.toLowerCase().indexOf("expensewizard") > -1) {
              let exp = new Expense({ ItemID: response.ID });
              exp.Initialize();
            } else {
              if (GetDevice().isDevice) {
                window.location.href =
                  window.location.origin + "/Expense/?itemid=" + response.ID;
              } else {
                theWindow.location = `/Expense/?itemid=${response.ID}`;
              }
            }
            SRF.Dialog.close();
            d.resolve();
          }).catch((e) => {

            new Notification({
              message: e.Message,
              type: "error",
              shading: false
            });

          });
      });
    });

    return d.promise();
  }

  private getGeneralExpenseButton() {
    let theExpense = this;
    let generalExpenseButton = {
      id: "btnGeneralExp",
      autospin: true,
      cssClass: "btn-warning",
      action: function (dlg) {
        let t = {
          New: true,
          Description:
            "General " +
            " -- " +
            Globalize.formatDate(new Date(), { date: "short" }),
          Dirty: true,
          ExpenseAmount: 0,
          ExpenseDate: Globalize.formatDate(new Date(), { date: "short" }),
          MileageID: -1,
          Reimbursement: true
        };
        let nexpargs: NewExpenseArgs = {
          ParentID: "",
          ParentInfo: EnumParent.General,
          ReferenceExpenseID: theExpense ? theExpense.ItemID || "" : "",
          ParentType: itemTypes.itemUnknown
        };
        new TDFRequest({
          url: "/Expense/CreateExpense/",
          data: {
            args: nexpargs,
            expensedata: t
          },
          type: "POST"
        })
          .MakeRequest()
          .done(function (response: any) {
            if (true) {
              dlg.Dialog.close();
              window.location.href = "/Expense/?itemid=" + response;
            }
            new Notification({
              message: response.Message,
              type: response.Valid ? "success" : "error",
              shading: false
            });
          });
      },
      icon: "glyphicon glyphicon-pencil",
      label: "Create General Expense"
    };

    return generalExpenseButton;
  }

  //private AccordionClick(e) {
  //    let expenseItem = this;
  //    let parentid = e.itemData["TDF GUID"];
  //    let desc = e.itemData["Company"] || e.itemData["Subject"] || e.itemData["CompanyName"] || e.itemData["AccountName"] || e.itemData["Description"];
  //    let expenseDetails: IcExpense;
  //    if ($(e.jQueryEvent.target).hasClass("rec-fav-action-buttons")) return;
  //    expenseItem.Dialog.close();

  //    expenseDetails = {
  //        New: true,
  //        Description: desc || " " + " -- " + Globalize.formatDate(new Date(), { date: "short" }),
  //        Dirty: true,
  //        ExpenseAmount: 0,
  //        ExpenseDate: Globalize.formatDate(new Date(), { date: "short" }),
  //        MileageID: -1,
  //        Reimbursement: true
  //    }

  //    let nexpargs: NewExpenseArgs = {
  //        ParentID: parentid,
  //        ParentInfo: EnumParent.Linked,
  //        ReferenceExpenseID: expenseItem.ItemID || "",
  //        ParentType: e.itemData.ItemType || itemTypes.itemUnknown
  //    };

  //    new TDFRequest({
  //        url: "/Expense/CreateExpense/", data: {
  //            args: nexpargs, expensedata: expenseDetails, mileageinfo: expenseItem.MileageDirty ? expenseItem.MileageInfo : null
  //        }, type: "POST"
  //    }).MakeRequest().done(function (response: any) {

  //        if (true) {
  //            if (window.location.href.indexOf("expensewizard") > -1) {
  //                let exp = new Expense({ ItemID: response });
  //                exp.Initialize()
  //            } else {
  //                window.location.href = window.location.origin + "/Expense/" + response;

  //            }
  //        }
  //        new Notification({ message: response.Message, type: response.Valid ? "success" : "error", shading: false });
  //    });
  //}
  /**
   * @Overrides Form()
   */
  Form() {

    let expenseItem = this;

    let d: JQueryDeferred<void> = $.Deferred();

    $("#expenseContainer").empty();

    /*
        Add the appropriate containers
      */
    $("#expenseContainer")
      .append($("<div id=" + expenseItem.ToolbarID + "></div>"))
      .append(
        $("<div />")
          .addClass("tdfitemtabs")
          .attr({ id: expenseItem.UpperTabsID })
      );

    /*
       Since we are overriding the base class's form method we will need to manually call the render control groups method.
      */
    expenseItem.RenderControlGroups().done(() => {
      d.resolve();
    });

    let tlbritems = expenseItem.ToolbarItems();
    let exptoolbaritems = [];
    $.each(tlbritems, function (k, v: any) {
      if (
        v &&
        v.options &&
        v.options.text &&
        !(v.options.text.toLowerCase() === "save & copy")
      ) {
        exptoolbaritems.push(v);
      } else {
        if (!v.options.text && v.options.icon.indexOf("ellipsis") > -1) {
          exptoolbaritems.push(v);
        }
      }
    });
    //exptoolbaritems.push(expenseItem.btnChangeParent());
    expenseItem.Toolbar = $("#" + expenseItem.ToolbarID)
      .addClass("container-fluid item-toolbar")
      .dxToolbar({
        items: exptoolbaritems
      })
      .dxToolbar("instance");

    if ($("#newExpenseWithParentBtn").length) {
      $("#newExpenseWithParentBtn")
        .dxButton("instance")
        .option("disabled", false);
    }
    return d.promise();
  }

  RenderControlGroups() {
    let expenseItem = this;
    let dfd = $.Deferred();
    expenseItem.DetailSectionID =
      expenseItem.TypeName + "-Expense_Detail-" + expenseItem.DomSafeID;

    super.RenderControlGroups();

    let headerSection = $("#" + expenseItem.HeaderID);
    $("<div />")
      .attr({ id: expenseItem.DetailSectionID })
      .addClass("tdfItemStaticSection")
      .insertAfter(headerSection);

    expenseItem.GetExpenseDetails();
    return dfd.promise();
  }

  Refresh() {
    let expenseItem = this;
    $("#expenseContainer").empty();
    $("#expenseContainer")
      .append($("<div id=" + expenseItem.ToolbarID + "></div>"))
      // .append($("<div />").attr("id", expenseItem.DetailSectionID))
      .append(
        $("<div />")
          .addClass("tdfitemtabs")
          .attr({ id: expenseItem.UpperTabsID })
      )
      .append(
        $("<div />")
          .addClass("tdfitemtabs")
          .attr({ id: expenseItem.LowerTabsID })
      );

    let headerSection = $("#" + expenseItem.HeaderID);
    $("<div />")
      .attr({ id: expenseItem.DetailSectionID })
      .addClass("tdfItemStaticSection")
      .insertAfter(headerSection);

    super.Refresh();
    let tlbritems = expenseItem.ToolbarItems();
    let exptoolbaritems = [];
    $.each(tlbritems, function (k, v: any) {

      if (v && v.options && (!v.options.text || (v.options.text && !(v.options.text.toLowerCase() === "save & copy")))) {
        exptoolbaritems.push(v);
      }
    });
    expenseItem.Toolbar = $("#" + expenseItem.ToolbarID)
      .addClass("container-fluid item-toolbar")
      .dxToolbar({
        items: exptoolbaritems
      })
      .dxToolbar("instance");
    return expenseItem;
  }

  GetExpenseDetails() {
    let expenseItem = this;
    let request = new TDFRequest({
      url: expenseItem.GetDetailsURL,
      data: { id: expenseItem.ItemID },
      type: "GET"
    });
    request.MakeRequest().done(function (response) {
      expenseItem.ExpenseDetails = response;

      let unformattedDateString = expenseItem.ExpenseDetails.ExpenseDate;
      let timestampIndex = unformattedDateString.indexOf("T00:00:00");
      if (timestampIndex > -1) {
        unformattedDateString = unformattedDateString.substring(
          0,
          timestampIndex
        );
      }
      expenseItem.ExpenseDetails.ExpenseDate = unformattedDateString; // Globalize.formatDate(unformattedDate, { date: "short" });

      expenseItem.RenderDetailsSection();
    });
  }

  RenderMileageForm() {
    let expenseItem = this;

    let formOptions: DevExpress.ui.dxFormOptions = {
      formData: {}
    };

    let items: DevExpress.ui.dxFormSimpleItem[] = [
      {
        dataField: "DataLoaded",
        visible: false
      },
      {
        dataField: "OdometerStart",
        label: { text: "Odometer Start" },
        editorType: "dxNumberBox",
        editorOptions: {
          min: 0,
          step: 100,
          onValueChanged: function (e) {
            let end = (expenseItem.MileageForm.getEditor(
              "OdometerEnd"
            ) as DevExpress.ui.dxNumberBox).option("value");
            let start = e.value;

            if (start >= 0 && end > start) {
              (expenseItem.MileageForm.getEditor(
                "TotalMiles"
              ) as DevExpress.ui.dxNumberBox).option("value", end - start);
            }
          },
          value: expenseItem.MileageInfo.OdometerStart
        }
      },
      {
        dataField: "OdometerEnd",
        label: { text: "Odometer End" },
        editorType: "dxNumberBox",
        editorOptions: {
          min: 0,
          step: 100,
          onValueChanged: function (e) {
            let start = (expenseItem.MileageForm.getEditor(
              "OdometerStart"
            ) as DevExpress.ui.dxNumberBox).option("value");
            let end = e.value;
            if (start >= 0 && end > start) {
              (expenseItem.MileageForm.getEditor(
                "TotalMiles"
              ) as DevExpress.ui.dxNumberBox).option("value", end - start);
            }
          },
          value: expenseItem.MileageInfo.OdometerEnd
        }
      },
      {
        itemType: "empty"
      },
      {
        dataField: "TotalMiles",
        label: { text: "Total Miles" },
        editorType: "dxNumberBox",
        editorOptions: {
          //min: 0,
          step: 100,
          onValueChanged: function (e) {
            let mileageAmount: number;
            if (expenseItem.MileageInfo.UseMileageRate2 === true) {
              mileageAmount = expenseItem.MileageInfo.MileageRate * expenseItem.MileageInfo.MileageCap
              mileageAmount += (expenseItem.MileageInfo.MileageRate2 * (e.value - expenseItem.MileageInfo.MileageCap))
            } else {
              mileageAmount = expenseItem.MileageInfo.MileageRate * e.value
            };
            (expenseItem.MileageForm.getEditor(
              "TotalMileageAmount"
            ) as DevExpress.ui.dxTextBox).option(
              "value",
              Globalize.formatCurrency(
                mileageAmount,
                CurrentUser.Currency
              )
            );
          },
          value: expenseItem.MileageInfo.TotalMiles
        }
      },
      {
        dataField: "MileageRate",
        label: { text: "Mileage Rate" },
        editorType: "dxTextBox",
        editorOptions: {
          disabled: true,
          value: expenseItem.MileageInfo.MileageRate
        }
      },
      {
        dataField: "MileageCap",
        label: {
          text: "Mileage Cap",
          visible: expenseItem.MileageInfo.UseMileageRate2
        },
        editorType: "dxTextBox",
        editorOptions: {
          disabled: true,
          value: expenseItem.MileageInfo.MileageCap,
          visible: expenseItem.MileageInfo.UseMileageRate2
        }
      },
      {
        dataField: "MileageRate2",
        label: {
          text: "Mileage Rate2",
          visible: expenseItem.MileageInfo.UseMileageRate2
        },
        editorType: "dxTextBox",
        editorOptions: {
          disabled: true,
          value: expenseItem.MileageInfo.MileageRate2,
          visible: expenseItem.MileageInfo.UseMileageRate2
        }
      },
      {
        itemType: "empty"
      },
      {
        dataField: "TotalMileageAmount",
        name: "TotalMileageAmount",
        label: { text: "Total Amount" },
        editorType: "dxTextBox",
        editorOptions: {
          value: expenseItem.MileageInfo.TotalMileageAmount
        }
      }
    ];
    formOptions.items = items;
    $("#mileagepop")
      .dxPopup({
        width: "auto",
        height: "auto",
        title: "Edit Mileage",
        // fullScreen:GetDevice().isDevice,
        showTitle: true,
        dragEnabled: true,
        contentTemplate: function (contentElement) {
          contentElement.append($("<div />").attr("id", "editmileageform"));
        },
        toolbarItems: [
          {
            location: "after",
            toolbar: "bottom",
            widget: "dxButton",
            options: {
              text: "Update Mileage",
              type: "success",
              icon: 'check',
              onClick: function (e) {
                $("#mileagepop")
                  .dxPopup("instance")
                  .hide();

                if (!$("#descriptionpop").length)
                  $("<div/>")
                    .attr("id", "descriptionpop")
                    .appendTo("body");

                $("#descriptionpop")
                  .dxPopup({
                    width: "auto",
                    height: "auto",
                    title: "Update Description?",
                    contentTemplate(el) {
                      el.append(
                        $("<span />").text(
                          "Would you like to update the description?"
                        )
                      );
                    },
                    toolbarItems: [
                      {
                        location: "after",
                        toolbar: "bottom",
                        widget: "dxButton",
                        options: {
                          text: "Yes",
                          type: "success",
                          icon: 'check',
                          onClick: function (e) {
                            expenseItem.ExpenseDetails.ExpenseMileage = expenseItem.MileageForm.option(
                              "formData"
                            );

                            //expenseItem.MileageInfo;
                            let form = $(
                              "#" + expenseItem.DetailSectionID
                            ).dxForm("instance");
                            let description: any = form.getEditor(
                              "Description"
                            );
                            if (expenseItem.ExpenseDetails.ExpenseMileage.TotalMiles) {

                            };
                            if (expenseItem.MileageInfo.UseMileageRate2 === true && expenseItem.ExpenseDetails.ExpenseMileage.TotalMiles > expenseItem.MileageInfo.MileageCap) {
                              description.option(
                                "value",
                                "Mileage: " +
                                expenseItem.ExpenseDetails.ExpenseMileage.TotalMiles +
                                " " + expenseItem.MileageInfo.MileageCap + " miles at $" +
                                expenseItem.MileageInfo.MileageRate +
                                " and " + (expenseItem.ExpenseDetails.ExpenseMileage.TotalMiles - expenseItem.MileageInfo.MileageCap) + " miles at $" +
                                expenseItem.MileageInfo.MileageRate2 +
                                " per unit."
                              );
                            } else {
                              description.option(
                                "value",
                                "Mileage: " +
                                expenseItem.ExpenseDetails.ExpenseMileage
                                  .TotalMiles +
                                " miles at $" +
                                expenseItem.MileageInfo
                                  .MileageRate +
                                " per unit."
                              );
                            };

                            let expenseAmt: any = form.getEditor(
                              "ExpenseAmount"
                            );
                            expenseAmt.option(
                              "value",
                              expenseItem.ExpenseDetails.ExpenseMileage
                                .TotalMileageAmount
                            );
                            expenseItem.SetDirty({
                              field: "Description",
                              value: description.option("value")
                            });
                            expenseItem.MileageDirty = true;

                            $("#descriptionpop")
                              .dxPopup("instance")
                              .hide();
                          }
                        }
                      },
                      {
                        location: "after",
                        toolbar: "bottom",
                        widget: "dxButton",
                        options: {
                          text: "No",
                          icon: 'remove',
                          type: "danger",
                          onClick: function (e) {
                            expenseItem.ExpenseDetails.ExpenseMileage = expenseItem.MileageForm.option(
                              "formData"
                            ); //expenseItem.MileageInfo;

                            let form = $(
                              "#" + expenseItem.DetailSectionID
                            ).dxForm("instance");
                            let expenseAmt: any = form.getEditor(
                              "ExpenseAmount"
                            );
                            expenseAmt.option(
                              "value",
                              expenseItem.ExpenseDetails.ExpenseMileage
                                .TotalMileageAmount
                            );
                            expenseItem.SetDirty({
                              field: "Mileage",
                              value: expenseItem.ExpenseDetails.ExpenseMileage
                            });
                            expenseItem.MileageDirty = true;

                            $("#descriptionpop")
                              .dxPopup("instance")
                              .hide();
                          }
                        }
                      }
                    ]
                  })
                  .dxPopup("instance")
                  .show();
              }
            }
          },
          {
            location: "after",
            toolbar: "bottom",
            widget: "dxButton",
            options: {
              text: "Cancel",
              icon: "remove",
              type: "danger",
              onClick: function (e) {
                let form = $("#editmileageform").dxForm("instance");
                $("#mileagepop")
                  .dxPopup("instance")
                  .hide();
              }
            }
          }
        ]
      })
      .dxPopup("instance")
      .show();
    expenseItem.MileageForm = $("#editmileageform")
      .dxForm(formOptions)
      .dxForm("instance");
  }

  RenderMileage() {
    let expenseItem = this;

    if (!expenseItem.MileageInfo) {
      let mileageRequest = new TDFRequest({
        url: expenseItem.GetExpenseMilageURL,
        data: { ID: expenseItem.ExpenseDetails["MileageID"] },
        type: "POST"
      });
      mileageRequest.MakeRequest().done(function (response) {
        expenseItem.MileageInfo = response;
        // if (
        //   expenseItem.MileageInfo.TotalMileageAmount > 0 &&
        //   expenseItem.MileageInfo.TotalMiles > 0
        // ) {
        //   expenseItem.MileageInfo.MileageRate =
        //     expenseItem.MileageInfo.TotalMileageAmount /
        //     expenseItem.MileageInfo.TotalMiles;
        // } else {
        //   expenseItem.MileageInfo.MileageRate = expenseItem.MileageInfo.MileageRate;//CurrentCompany.MileageRate;
        //   expenseItem.MileageInfo.MileageRate2 = expenseItem.MileageInfo.MileageRate2;
        //   expenseItem.MileageInfo.MileageCap = expenseItem.MileageInfo.MileageCap;
        // }
        expenseItem.RenderMileageForm();
      });
    } else {
      expenseItem.RenderMileageForm();
    }
  }

  RenderDetailsSection() {
    let expenseItem = this;
    let container = $("#" + expenseItem.DetailSectionID);

    let opts: DevExpress.ui.dxFormOptions = {
      formData: {},
      items: [],
      colCountByScreen: {
        lg: 4,
        md: 3,
        sm: 2,
        xs: 1
      },
      onContentReady: function (item) {
        let mileageBtn = $('[aria-label="Mileage"]');
        mileageBtn.append($("<div />").attr("id", "mileagepop"));
        mileageBtn.on("click", function (data) {
          expenseItem.RenderMileage();
        });

        let fake = new TDFRequest({
          url: ""
        });

        fake.GetToken().done((token) => {

          let holder = $(".hold");
          holder.removeClass().dxFileUploader({
            uploadHeaders: { "Authorization": "Bearer " + token },
            accept: "image/*",
            multiple: false,
            labelText: "",
            uploadMethod: "POST",
            showFileList: false,
            uploadMode: "instantly",
            uploadUrl: `${TDFRequest.ApiPath}/Attachment/Attach/?itemID=${encodeURIComponent(
              expenseItem.ItemID
            )}&itemType=12`,
            elementAttr: { style: "display:inline-block" },
            onContentReady(e) {
              ((e.component as any)
                ._selectButton as DevExpress.ui.dxButton).option({
                  text: "",
                  icon: "photo"
                });
              (e.component as any)._$fileInput.attr("capture", "camera");
              e.element
                .find(".dx-fileuploader-input-wrapper")
                .css({ padding: "0", border: "none" });
              e.element.find(".dx-fileuploader-wrapper").css("padding", "0");
            },
            onUploaded(e) {
              if (e.request.status === 200 && e.request.responseText) {
                new Notification({
                  message: "Success.",
                  type: "success",
                  displayTime: 2000,
                  shading: false
                });
              } else {
                new Notification({
                  message: e.request.responseText
                    ? e.request.responseText
                    : "Sorry there was an error. Please try again and report any further issues.",
                  type: "error",
                  displayTime: 5000,
                  shading: false
                });
              }
            }
          });

        });


      }
    };
    let item: DevExpress.ui.dxFormSimpleItem | any;
    if (
      new Date().getFullYear() -
      new Date(expenseItem.ExpenseDetails.ExpenseDate).getFullYear() >
      100
    ) {
      expenseItem.ExpenseDetails.ExpenseDate = Globalize.formatDate(new Date());
    }
    $.each(ExpenseDetailControlTypes, function (key: string, val) {
      item = val;
      if (
        !item.editorOptions &&
        !item.buttonOptions &&
        item.itemType !== "group"
      ) {
        item.editorOptions = {};
      }
      if (item.editorOptions)
        item.editorOptions.disabled =
          item.editorOptions.disabled || key === "UniqueID";
      if (item.dataField === "ExpenseAge") {
        let now = moment(moment.now()).startOf("day");
        let then = moment(expenseItem.ExpenseDetails.ExpenseDate).startOf(
          "day"
        );

        let ms = moment(now).diff(moment(then));
        let d = moment.duration(ms);
        item.editorOptions.value = Math.floor(d.asDays());
      } else {
        if (item.dataField === "ExpenseAmount") {
          item.editorOptions.value = Globalize.formatCurrency(
            expenseItem.ExpenseDetails.ExpenseAmount,
            CurrentUser.Currency,
            { style: "accounting" }
          );

          item.editorOptions.showClearButton = true;
        } else {
          if (
            typeof expenseItem.ExpenseDetails[item.dataField] !== "undefined"
          ) {
            item.editorOptions.value =
              expenseItem.ExpenseDetails[item.dataField];
          }
          if (item.dataField === "Description") {
            item.editorOptions.showClearButton = true;
          }
        }
      }
      if (item.editorOptions) {
        item.editorOptions.valueChangeEvent = "blur";
        item.editorOptions.onValueChanged = function (e) {
          e.field = key;
          expenseItem.SetDirty(e);
        };
      }
      if (item.itemType === "group") {
        item.items[1].buttonOptions.onClick = () => {
          let viewer = new AttachmentViewer(
            expenseItem.ItemID,
            expenseItem.ItemType
          );
          viewer.DisplayFiles();
        };
      }

      opts.items.push(item);
    });

    expenseItem.ExpenseDetailForm = container.dxForm(opts).dxForm("instance");
  }

  Save() {
    let expenseItem = this;
    let dfd = $.Deferred();
    if (expenseItem.Dirty && expenseItem.DetailsDirty) {
      $.when(super.Save(), expenseItem.SaveDetails()).done(function (response) {
        expenseItem.ChangeState();
        dfd.resolve(expenseItem);
      });
    } else if (expenseItem.Dirty) {
      super.Save().done(function (response) {
        expenseItem.ChangeState();
        dfd.resolve(expenseItem);
      });
    } else if (expenseItem.DetailsDirty) {
      expenseItem.SaveDetails().done(function (response) {
        expenseItem.ChangeState();
        dfd.resolve(expenseItem);
      });
    } else {
    }

    return dfd.promise(expenseItem);
  }

  SaveDetails() {
    let expenseItem = this;
    let dfd = $.Deferred();

    let requestData: any = {
      details: expenseItem.DirtyDetails,
      expenseID: expenseItem.ItemID
    };

    if (expenseItem.MileageDirty && expenseItem.ExpenseDetails.ExpenseMileage) {
      requestData.mileageInfo = expenseItem.ExpenseDetails.ExpenseMileage;
      if (typeof requestData.mileageInfo.TotalMileageAmount === "string") {
        requestData.mileageInfo.TotalMileageAmount = Globalize.parseNumber(
          requestData.mileageInfo.TotalMileageAmount.replace("$", ""),
          { style: "decimal" }
        );
      }
    }

    let request = new TDFRequest({
      url: expenseItem.SaveDetailsURL,
      data: requestData,
      type: "POST",
      contentType: "application/json"
    });

    request.MakeRequest().done(function (response) {
      //new TDF.Notify({ message: response, type: "success", displayTime: 3000, shading: false })
      expenseItem.DetailsDirty = false;
      expenseItem.MileageDirty = false;
      expenseItem.DirtyDetails = {};
      dfd.resolve(expenseItem);
    });
    return dfd.promise(expenseItem);
  }

  Close() {
    let expenseItem = this;
    $("#expenseContainer").empty();
    return expenseItem;
  }

  SaveClose() {
    let expenseItem = this;
    //iBase.Dirty = false;
    expenseItem.Save();
    expenseItem.Close();

    // TODO: Make sure this is working correctly
    return expenseItem;
  }
  /**
   * Change the color of the toolbar to indicate to the user that there are unsaved values.
   */
  ChangeState() {
    let expenseItem = this;
    super.ChangeState();
    $(
      ".dx-item-content.dx-toolbar-item-content :contains('New (General)')"
    ).removeClass("successflash");
    $(
      ".dx-item-content.dx-toolbar-item-content :contains('New (Linked)')"
    ).removeClass("successflash");
    $(
      ".dx-item-content.dx-toolbar-item-content :contains('New (Keep Parent)')"
    ).removeClass("successflash");

    if (
      expenseItem.Dirty ||
      expenseItem.DetailsDirty ||
      expenseItem.MileageDirty
    ) {

      $("#" + expenseItem.ToolbarID).addClass("tdfdirty");

      if (expenseItem.ItemID) {
        $(
          $(expenseItem.Toolbar.element())
            .children()
            .find(
              ".dx-item-content.dx-toolbar-item-content :contains('Save')"
            )[0]
        )
          .dxButton("instance")
          .option("disabled", false);

      } else {

      }
    } else {
      $("#" + expenseItem.ToolbarID).removeClass("tdfdirty");
      if ($(expenseItem.Toolbar.element()).children().length)
        $(
          $(expenseItem.Toolbar.element())
            .children()
            .find(
              ".dx-item-content.dx-toolbar-item-content :contains('Close')"
            )[3]
        )
          .dxButton("instance")
          .option("disabled", false);
    }
    return expenseItem;
  }
  /**
   * We need to override the base's set dirty function because of the expense specific detials section.
   * @Overrides SetDirty()
   * @param control
   */
  SetDirty(control) {
    let expenseItem = this;

    if (control.hasOwnProperty("DataValue")) {
      super.SetDirty(control);
    } else {
      if (control.field == "Mileage") {
        expenseItem.MileageDirty = true;
        expenseItem.ChangeState();
      } else {
        if (control.field === "ExpenseAmount") {
          let val = control.value;
          let tempVal;
          if (typeof control.value === "string") {
            tempVal = Globalize.parseNumber(control.value);

            if (
              control.value.substring(0, 1) === "-" ||
              control.value.substring(0, 1) === "("
            ) {
              if (tempVal > 0) {
                tempVal = -tempVal;
              }
            }
            expenseItem.DirtyDetails[control.field] = tempVal;
          } else {
            expenseItem.DirtyDetails[control.field] = val;
          }
        } else {
          expenseItem.DirtyDetails[control.field] = control.value;
        }
        expenseItem.DetailsDirty = true;
        expenseItem.ChangeState();
      }
    }
    return expenseItem;
  }

  protected btnChangeParent(): DevExpress.ui.dxToolbarItemTemplate {
    let expenseItem = this;
    return <DevExpress.ui.dxToolbarItemTemplate>{
      location: "before",
      locateInMenu: "auto",
      showText: "inMenu",
      options: {
        icon: "fa fa-binoculars",
        hint: "Change this " + expenseItem.DisplayName + "'s Parent",
        text: "Change Parent",
        type: "default",
        visible: true,
        onClick: function () {

          let info: IItemInfo = {
            ItemId: expenseItem.ItemID,
            ItemType: expenseItem.ItemType,
            iMain: expenseItem.iMain
          };

          let action = new MenuAction(
            {
              Action: menuActions.menuChangeParent,
              AvailableInMobile: true,
              DisplayOptions: 7,
              Enabled: true,
              ErrorMessageWhenUnavailable: "",
              GroupName: "",
              MenuText: "Change Parent",
              MenuText2: null,
              RelevantType: 0,
              SHouldAppearInBreadcrumbs: true,
              ShouldAppearInFormMenu: true,
              ShouldAppearInGrid: true,
              SortOrder: 6,
              SupportsMultipleItems: true,
              Visibility: 0
            },
            [info]
          );
        }
      },
      widget: "dxButton"
    };
  }

  public ShouldRenderLinkedItemsTab() {
    return false
  }

  protected OpenInNewWindow() {
    let iBase = this;
    iBase.Close(); // This should prompt to save if it is dirty.
    var urlString = window.location.origin + `/Expense/?itemID=${iBase.ItemID}&itemType=${iBase.ItemType}`;
    window.open(urlString);
  }



}
