import { itemTypes } from "../../../enums/enums";
import { DisplayInfo } from "../../../infrastructure/context";
import { ItemBase } from "../itembase";

export function createitem(itemType: itemTypes, args: any, create: boolean = false, initialize: boolean = true, todo?: Function, creationArgs?: any): JQueryPromise<ItemBase> {
  let dfd = $.Deferred();

  DisplayInfo(itemType).done(info => {
    if (info && info.TypeName) {
      import(`../${info.TypeName.toLowerCase()}`).then(v => {
        let theitem = new v[info.TypeName](args);

        let itemInitDfd: JQueryDeferred<void> = $.Deferred();

        if (create) {
          if (creationArgs) {
            itemInitDfd = theitem.Create(false, creationArgs);
          } else {
            itemInitDfd = theitem.Create();
          }
        }
        else if (initialize) {
          itemInitDfd = theitem.Initialize();
        }

        if (todo) {
          todo(theitem);
        }

        itemInitDfd.done(() => {
          dfd.resolve(theitem);
        })
      });
    }
  });
  return dfd.promise();
}

export function openitem(itemType: itemTypes, itemId: string): JQueryPromise<ItemBase> {
  return createitem(itemType, { ItemID: itemId });
}



