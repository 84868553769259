import {
  IGridToolbarModel,
  IGridViewMenuItem
} from "../../../interfaces/interfaces";
import { BaseToolbar } from "./gridtoolbar";
  //Obsolete after BI Data Center is done.
export class BasicTabToolbar extends BaseToolbar {
  /** Interface for Devexpress toolbar options. **/
  Options: DevExpress.ui.dxToolbarOptions = {};

  /** The id of the html element that will hold the toolbar. **/
  ToolbarContainerID: string;

  /** Addtional items you would like added to the grid toolbar. **/
  ExtraItems: any;

  /** This is a container that will give the user options for the grid. **/
  ActionSheet: DevExpress.ui.dxActionSheet;

  RefreshButton: DevExpress.ui.dxButton;

  constructor(args: any) {
    super(args);
    let theToolbar = this;
  }

  Render() {
    let theToolbar = this;
    theToolbar.Options.dataSource = theToolbar.AddViewToolbarItems();
    $("#" + theToolbar.ToolbarContainerID).dxToolbar(theToolbar.Options);

    if (!theToolbar.RefreshButton) {
      theToolbar.RefreshButton = $("#refreshgrid").dxButton("instance");
    }
    if (!theToolbar.ActionSheet) {
      theToolbar.ActionSheet = $("#gridtoolbarscopeactions").dxActionSheet(
        "instance"
      );
    }

    return theToolbar;
  }

  RefreshCurrentGrid(e) {
    let theToolbar = this;
    return theToolbar;
  }

  AddViewToolbarItems() {
    let theToolbar = this;

    let items: any = [];

    if (theToolbar.ExtraItems && theToolbar.ExtraItems.length > 0) {
      $.each(theToolbar.ExtraItems, function(key, val) {
        items.push(val);
      });
    }
    return items;
  }

  GetViews() {
    let theToolbar = this;
    let theViews = theToolbar.UsePersonalViews
      ? theToolbar.GetPersonalViews()
      : theToolbar.GetGlobalViews();
    let theDefaultView = $.grep(theViews, function(val: any, key) {
      return val.IsDefault;
    });
    theToolbar.DefaultView = theDefaultView[0].ViewGUID || "";

    let lst = [];
    $.each(theViews, function(key, val: any) {
      lst.push({
        ViewName: val.ViewName,
        ViewGUID: val.ViewGUID || "_",
        IsDefault: val.IsDefault,
        ViewCategory: val.ViewCategory || "General"
      });
    });

    return lst;
  }
}
