import { TDFRequest } from '../request';
export class AlertService {
  public static AvailableAlertList(force: boolean = false): JQueryPromise<any> {
    const d: JQueryDeferred<any> = $.Deferred();
    const listStorageName: string = 'AvailAlertList';
    const listDateStorageName: string = 'AvailAlertListTime';

    const storedAlertList = JSON.parse(localStorage.getItem(listStorageName));
    const storedAlertListTime = localStorage.getItem(listDateStorageName);

    if (!force && storedAlertList && storedAlertListTime) {
      const lastUpdated = new Date(storedAlertListTime);
      const today = new Date();

      if (lastUpdated.getDate() === today.getDate()) {
        return d.promise(d.resolve(storedAlertList));
      }
    }

    new TDFRequest({ url: '/Alert/GetAvailable', type: 'GET', data: {} })
      .MakeRequest()
      .done(function(alertList) {
        localStorage.setItem(listStorageName, JSON.stringify(alertList));
        localStorage.setItem(listDateStorageName, new Date().toISOString());
        d.resolve(alertList);
      });
    return d.promise();
  }

  public static AlertData(
    mySettings: any /*LegacyAlertTileSettings*/,
    AlertSpinnerID: string
  ) {
    let d = $.Deferred();
    let request = new TDFRequest({
      url: '/Alert/Data/',
      type: 'Get',
      data: {
        mailingid: mySettings.SelectedAlertInfo.MailingID,
        alertid: mySettings.SelectedAlertInfo.AlertID
      }
    });
    request.spinnerContainerID = AlertSpinnerID;
    request.MakeRequest().done(function(data) {
      d.resolve(data);
    });
    return d.promise();
  }
}
