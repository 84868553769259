'use strict';
import { Dialog } from '../dialogs/dialog';
import { TDFRequest } from '../../services/request';
import { GetDevice, DomSafeID } from '../../util/allutils';

export namespace EventManager {
  export function updateSession(sessionid: string) {
    $('#event-msg1').empty();

    var form = document.forms['session-form-' + sessionid];

    if (form) {
      event.preventDefault();
      var params = {};

      for (var i = 0; i < form.elements.length; i++) {
        if (form.elements[i].name == 'DXScript') continue;
        params[form.elements[i].name] = form.elements[i].value;
      }

      var request = new TDFRequest({
        url: '/EventManager/Sessions',
        type: 'POST',
        data: params
      });

      request.MakeRequest().done(function(response) {
        if (response) {
          if (response.Valid == 0) {
            //alert(response.Message);
            $('#event-msg1').append(
              '<div class="alert alert-danger" role="alert">Error: Name and Description are required fields.</div>'
            );
          } else if (response.SessionAdded == true) {
            $('#event-msg1').append(
              '<div class="alert alert-success" role="alert">Session has been added.</div>'
            );
            //self.location.reload();
          } else {
            $('#event-msg1').append(
              '<div class="alert alert-success" role="alert">Session has been saved.</div>'
            );
          }
        }
      });
    }
  }

  export function quickSearch(txtQuery, divResultsContainer, btn, field) {
    let objQuery = $('#' + txtQuery).val();
    let objResults = $('#' + divResultsContainer);

    //if (!objQuery || !objResults) return;

    // User has to enter keyword to search
    if (objQuery !== '') {
      var guid = txtQuery.replace(/quick-contact-search-/g, '');

      btn.disabled = true;

      var params = { query: objQuery };
      var request = new TDFRequest({
        url: '/eventmanager/QuickContactSearch',
        type: 'POST',
        data: params
      });
      request.MakeRequest().done(function(response) {
        if (response) {
          $(objResults).empty();
          $(objResults).append(response);

          if (objResults.length) {
            var links = objResults.find('a');
            for (var i = 0; i < links.length; i++) {
              links[i].onclick = () => {
                $('#' + field + '-' + guid).val($(this).attr('rel'));
                $('#' + txtQuery).val(this.getAttribute('data-name'));
                //objQuery = this.getAttribute("data-name");
                $(objResults).empty();
              };
            }
          }
          $(objResults).css('display', 'block');

          btn.disabled = false;
        }
      });

      //var request = new Request({
      //    url: '/eventmanager/QuickContactSearch', params: { query: objQuery.value }, method: "POST",
      //    todo: function () {
      //        if (!done()) return;
      //        objResults.innerHTML = done();

      //        var links = objResults.getElementsByTagName('a');
      //        for (var i = 0; i < links.length; i++) {
      //            links[i].onclick = function () {
      //                _get(field + '-' + guid).value = this.getAttribute("rel");
      //                objQuery.value = this.getAttribute("data-name");
      //                objResults.innerHTML = "";
      //            }
      //        }

      //        objResults.style.display = "block";
      //        btn.disabled = false;
      //    }
      //});

      $(objResults).html('Searching...');
      $(objResults).css('display', 'block');
    } else {
      alert('Please enter keyword to search.');
      //notify(TDFWebClient.Dialogs.getToast("Please enter keyword to search.", 2, 2000));
    }
  }

  export function toggleEventMenu() {
    //e.preventDefault();
    $('.events-menu-cell').removeClass('hidden');

    var $body = $('#eventResTab'),
      $page = $('.events-main-cell'),
      $menu = $('.events-menu-cell'),
      /* Cross browser support for CSS "transition end" event */
      transitionEnd =
        'transitionend webkitTransitionEnd otransitionend MSTransitionEnd';

    /* When the toggle menu link is clicked, animation starts */
    $body.addClass('animating');

    /***
     * Determine the direction of the animation and
     * add the correct direction class depending
     * on whether the menu was already visible.
     */
    if ($body.hasClass('menu-visible')) {
      $body.addClass('left');
    } else {
      $body.addClass('right');
    }

    /***
     * When the animation (technically a CSS transition)
     * has finished, remove all animating classes and
     * either add or remove the "menu-visible" class
     * depending whether it was visible or not previously.
     */
    $page.on(transitionEnd, function() {
      $body.removeClass('animating left right').toggleClass('menu-visible');

      $page.off(transitionEnd);

      if ($body.hasClass('menu-visible')) {
        $('.showEvents').text('Hide Event List');
      } else if (!$body.hasClass('menu-visible')) {
        $('.showEvents').text('Show Event List');
      }
    });
  }

  export function OnPhone() {
    return GetDevice().device.deviceType === 'phone';
  }

  export function dateInput(id, date, name, inputID) {
    id = DomSafeID(id);
    id = id.replace(/-_-/g, '');

    $('#' + id)
      .dxDateBox({
        value: date,
        //elementAttr: { "class": "form-control" },
        type: 'datetime',
        width: '100%'
      })
      .find('input')
      .attr('name', name)
      .attr('id', inputID);
  }

  export function toggleItem(obj, visibleDisplayStyle) {
    if (!visibleDisplayStyle) visibleDisplayStyle = '';

    if (typeof obj == 'string') {
      if (document.getElementById(obj)) {
        var thing = document.getElementById(obj);
        var display = thing.style.display;
        //if (display == "") display = "block";
        //thing.style.display = (display != "none") ? "none" : visibleDisplayStyle;
        $(document.getElementById(obj)).slideToggle('slow');
      }
    } else if (obj) {
      //obj.style.display = (obj.style.display == "none") ? visibleDisplayStyle : "none";
      $(obj).slideToggle('slow', function() {});
    }
  }

  export function openLocation(id) {
    // if there Is a formtarg element, remove it
    if ($('.formtarg')) {
      $('.formtarg').remove();
    }

    //Creates iframe so page refreshes to same page instead of controller defined
    let targ = document.createElement('iframe');
    targ.className = 'formtarg';
    targ.name = 'formtarg';
    targ.style.display = 'none'; // hide it
    document.body.appendChild(targ); // append To body

    let params = { id: id };

    let request = new TDFRequest({
      url: '/EventManager/EditLocation/',
      type: 'POST',
      data: params
    });
    request.MakeRequest().done(function(response) {
      if (response) {
        var mymodal = new Dialog({
          body: $('<div />').html(response),
          size: 'size-normal',
          closable: true,
          title: 'Location',
          id: 'locWindow'
        });
        mymodal.open();
      }
    });
  }

  export function openNewLocation() {
    //if a formtarg element exists, remove it
    if ($('.formtarg')) {
      $('.formtarg').remove();
    }

    //Create formtarg iframe so page refreshes to same page instead of controller
    var targ = document.createElement('iframe');
    targ.className = 'formtarg';
    targ.name = 'formtarg';
    targ.style.display = 'none'; // hide it
    document.body.appendChild(targ); // append To body

    let params = { id: -99 };

    let request = new TDFRequest({
      url: '/EventManager/EditLocation/',
      type: 'POST',
      data: params
    });
    request.MakeRequest().done(function(response) {
      if (response) {
        var mymodal = new Dialog({
          body: $('<div />').html(response),
          size: 'size-normal',
          closable: true,
          title: 'Add New Location',
          id: 'newLocWindow'
        });

        mymodal.open();
      }
    });
  }

  export function getUrlParameter(sParam) {
    let sPageURL = decodeURIComponent(window.location.search.substring(1)),
      sURLVariables = sPageURL.split('&'),
      sParameterName,
      i;

    for (i = 0; i < sURLVariables.length; i++) {
      sParameterName = sURLVariables[i].split('=');

      if (sParameterName[0] === sParam) {
        return sParameterName[1] === undefined ? true : sParameterName[1];
      }
    }
  }
}
