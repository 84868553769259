import { IGridView } from '../../../interfaces/grids/interfaces';
import { TDFRequest } from '../../request';
import {
  GeneralSettingsResponse,
  ViewFormatSettings,
  ColumnFormat,
  FilterSettings,
  AccountTypeSettings,
  ViewColumnSetting,
  ISummarySettings,
  IConvertedViewFilter
} from '../../../interfaces/admin/gridmanagement/interfaces';
import { Notification } from '../../../components/dialogs/notification';
import { eventNameSpace, EventTypes } from "../../../enums/webevents/enums";
import { RaiseEvent2 } from "../../../infrastructure/events/ui_events";
import { Dialog } from '../../../components/dialogs/dialog';
import { CurrentUser } from '../../../infrastructure/context';
import {
  ColonReplacementGUID,
  PeriodReplacementGUID,
  NonOf,
  AnyOf,
  RequestBuilder
} from '../../../util/allutils';
import { FilterBuilderHelper } from '../../../util/allutils';
import { enumGridDesign } from '../../../enums/admin/gridmanagement/enums';

import { custom, confirm } from 'devextreme/ui/dialog';

export class GridManagementDataService {
  UserCanEditGlobal: boolean;

  private get _viewGuid(): string {
    return this._view.GUID;
  }

  private _view: IGridView;
  private _eventLimiter: string;
  private _permissiondfd;
  constructor(view: IGridView, eventLimiter: string) {
    this._view = view;
    this._eventLimiter = eventLimiter;
    this.GetPermission(view);
  }

  private GetPermission(view: IGridView) {
    let gm = this;
    gm._permissiondfd = new TDFRequest({
      url: `${this._fullViewRoute}/${view.ItemType}/CanEditGlobal/`
    }).MakeRequest();
    $.when(gm._permissiondfd).done(response => {
      this.UserCanEditGlobal = response;
    });
    return gm._permissiondfd.promise();
  }

  private get _designRoutePrefix(): string {
    return `design`;
  }
  private get _fullDesignRoute(): string {
    return `${TDFRequest.ApiPath}/${this._designRoutePrefix}/${
      this._viewRoute
      }`;
  }
  private get _viewRoute(): string {
    return `grid/views`;
  }
  private get _fullViewRoute(): string {
    return `${TDFRequest.ApiPath}/${this._viewRoute}`;
  }

  UseOwnerFilter: boolean;
  public OwnerFilter() {
    let ds = this;
    let d = $.Deferred();
    if (typeof ds.UseOwnerFilter !== 'undefined')
      return d.promise(d.resolve(ds.UseOwnerFilter));
    new TDFRequest({
      url: `${ds._fullDesignRoute}/${ds._viewGuid}/owner-filter`
    })
      .MakeRequest()
      .done(response => {
        ds.UseOwnerFilter = response;
        d.resolve(ds.UseOwnerFilter);
      });
    return d.promise();
  }

  private _ColumnsForView: ViewColumnSetting;
  public ColumnsForView() {
    let d: JQueryDeferred<ViewColumnSetting> = $.Deferred();
    if (this._ColumnsForView) return d.promise(d.resolve(this._ColumnsForView));
    this.ColumnsForViewRequest()
      .MakeRequest()
      .done((response: ViewColumnSetting) => {
        this._ColumnsForView = response;

        d.resolve(this._ColumnsForView);
      });
    return d.promise();
  }
  private ColumnsForViewRequest(): TDFRequest {
    return new TDFRequest({
      url: `${this._fullDesignRoute}/${this._viewGuid}/columns`,
      type: 'GET'
    });
  }

  SetVisibleColumns(columns: string[]) {
    new TDFRequest({
      url: `${this._fullDesignRoute}/${this._viewGuid}/setVisible`,
      type: 'POST',
      data: columns
    })
      .MakeRequest()
      .done(response => {
        debugger;
      });
  }

  private _GeneralSettingsForView;
  public GeneralSettingsForView() {
    let d: JQueryDeferred<GeneralSettingsResponse> = $.Deferred();
    if (this._GeneralSettingsForView)
      return d.promise(d.resolve(this._GeneralSettingsForView));
    this.ViewGeneralSettings()
      .MakeRequest()
      .done((response: GeneralSettingsResponse) => {
        this._GeneralSettingsForView = response;
        d.resolve(this._GeneralSettingsForView);
      });
    return d.promise();
  }
  private ViewGeneralSettings(): TDFRequest {
    return new TDFRequest({
      url: `${this._fullDesignRoute}/${this._viewGuid}/general`,
      type: 'GET'
    });
  }

  private _FormatSettingsForView;
  public FormatSettingsForView() {
    let d: JQueryDeferred<ViewFormatSettings> = $.Deferred();
    if (this._FormatSettingsForView)
      return d.promise(d.resolve(this._FormatSettingsForView));
    this.ViewFormatSettings()
      .MakeRequest()
      .done((response: ViewFormatSettings) => {
        this._FormatSettingsForView = response;
        d.resolve(this._FormatSettingsForView);
      });
    return d.promise();
  }
  private ViewFormatSettings(): TDFRequest {
    return new TDFRequest({
      url: `${this._fullDesignRoute}/${this._viewGuid}/format-settings`,
      type: 'GET'
    });
  }

  private _ColumnFormatSettings = {};
  public ColumnFormatSettings(field) {
    let d: JQueryDeferred<ColumnFormat> = $.Deferred();
    if (this._ColumnFormatSettings[field])
      return d.promise(d.resolve(this._ColumnFormatSettings[field]));
    this.SingleColumnFormatSettings(field)
      .MakeRequest()
      .done((response: ColumnFormat) => {
        if (response.Settings.FieldName)
          this._ColumnFormatSettings[response.Settings.FieldName] = response;
        d.resolve(
          this._ColumnFormatSettings[response.Settings.FieldName] || null
        );
      });
    return d.promise();
  }
  private SingleColumnFormatSettings(field): TDFRequest {
    field = field.replace(/\:/g, '{colon}');
    return new TDFRequest({
      url: `${this._fullDesignRoute}/${
        this._viewGuid
        }/format-settings/${encodeURIComponent(field)}`,
      type: 'GET'
    });
  }

  private _ViewFilterSettings;
  public FilterSettings() {
    let d: JQueryDeferred<FilterSettings> = $.Deferred();
    if (this._ViewFilterSettings)
      return d.promise(d.resolve(this._ViewFilterSettings));
    this.ViewFilterSettings()
      .MakeRequest()
      .done((response: FilterSettings) => {
        this._ViewFilterSettings = response;
        d.resolve(this._ViewFilterSettings);
      });
    return d.promise();
  }
  private ViewFilterSettings(): TDFRequest {
    return new TDFRequest({
      url: `${this._fullDesignRoute}/${this._viewGuid}/filters`
    });
  }

  private _DateFilterSettings;
  public ViewDateFilterSettings() {
    let d = $.Deferred();
    if (this._DateFilterSettings)
      return d.promise(d.resolve(this._DateFilterSettings));
    this.DateFilterSettings()
      .MakeRequest()
      .done((response /*: */) => {
        this._DateFilterSettings = response;
        d.resolve(this._DateFilterSettings);
      });
    return d.promise();
  }
  private DateFilterSettings(): TDFRequest {
    return new TDFRequest({
      url: `${this._fullDesignRoute}/${this._viewGuid}/date-filters`
    });
  }

  private _ViewSummarySettings;
  public ViewSummarySettings() {
    let d: JQueryDeferred<ISummarySettings> = $.Deferred();
    if (this._ViewSummarySettings)
      return d.promise(d.resolve(this._ViewSummarySettings));
    this.SummarySettings()
      .MakeRequest()
      .done((response: ISummarySettings) => {
        this._ViewSummarySettings = response;
        d.resolve(this._ViewSummarySettings);
      });
    return d.promise();
  }
  private SummarySettings(): TDFRequest {
    return new TDFRequest({
      url: `${this._fullDesignRoute}/${this._viewGuid}/summaries`
    });
  }

  private _ViewSingleSummarySettings;
  public ViewSingleSummarySettings(field) {
    let d: JQueryDeferred<ViewFormatSettings> = $.Deferred();
    if (this._ViewSingleSummarySettings)
      return d.promise(d.resolve(this._ViewSingleSummarySettings));
    this.SingleSummarySettings(field)
      .MakeRequest()
      .done((response: ViewFormatSettings) => {
        this._ViewSingleSummarySettings = response;
        d.resolve(this._ViewSingleSummarySettings);
      });
    return d.promise();
  }
  private SingleSummarySettings(field): TDFRequest {
    field = field.replace(/\:/g, '{colon}');
    return new TDFRequest({
      url: `${this._fullDesignRoute}/${this._viewGuid}/summaries/${field}`
    });
  }

  private _ViewAccountTypeFilterSettings;
  public ViewAccountTypeFilterSettings() {
    let d: JQueryDeferred<AccountTypeSettings> = $.Deferred();
    if (this._ViewAccountTypeFilterSettings)
      return d.promise(d.resolve(this._ViewAccountTypeFilterSettings));
    this.AccountTypeFilterSettings()
      .MakeRequest()
      .done((response: AccountTypeSettings) => {
        this._ViewAccountTypeFilterSettings = response;
        d.resolve(this._ViewAccountTypeFilterSettings);
      });
    return d.promise();
  }
  private AccountTypeFilterSettings(): TDFRequest {
    return new TDFRequest({
      url: `${this._fullDesignRoute}/${this._viewGuid}/accounttypefiltering`
    });
  }

  private _ViewClient_Converted_FilterSettings;
  public ViewClient_Converted_FilterSettings() {
    let d: JQueryDeferred<IConvertedViewFilter> = $.Deferred();
    if (this._ViewClient_Converted_FilterSettings)
      return d.promise(d.resolve(this._ViewClient_Converted_FilterSettings));
    this.Client_Converted_FilterSettings()
      .MakeRequest()
      .done((response: IConvertedViewFilter) => {
        this._ViewClient_Converted_FilterSettings = response;
        d.resolve(this._ViewClient_Converted_FilterSettings);
      });
    return d.promise();
  }
  private Client_Converted_FilterSettings(): TDFRequest {
    return new TDFRequest({
      url: `${this._fullViewRoute}/filtering/${this._viewGuid}/client`
    });
  }

  private _ViewServer_Converted_FilterSettings;
  public ViewServer_Converted_FilterSettings() {
    let d: JQueryDeferred<IConvertedViewFilter> = $.Deferred();
    if (this._ViewServer_Converted_FilterSettings)
      return d.promise(d.resolve(this._ViewServer_Converted_FilterSettings));
    this.Server_Converted_FilterSettings()
      .MakeRequest()
      .done((response: IConvertedViewFilter) => {
        this._ViewServer_Converted_FilterSettings = response;
        d.resolve(this._ViewServer_Converted_FilterSettings);
      });
    return d.promise();
  }
  private Server_Converted_FilterSettings(): TDFRequest {
    return new TDFRequest({
      url: `${TDFRequest.ApiPath}/grid/views/filtering/${this._viewGuid}/server`
    });
  }

  private _ViewParent_Converted_FilterSettings;
  public ViewParent_Converted_FilterSettings() {
    let d: JQueryDeferred<IConvertedViewFilter> = $.Deferred();
    if (this._ViewParent_Converted_FilterSettings)
      return d.promise(d.resolve(this._ViewParent_Converted_FilterSettings));
    this.Parent_Converted_FilterSettings()
      .MakeRequest()
      .done((response: IConvertedViewFilter) => {
        this._ViewParent_Converted_FilterSettings = response;
        d.resolve(this._ViewParent_Converted_FilterSettings);
      });
    return d.promise();
  }
  private Parent_Converted_FilterSettings(): TDFRequest {
    return new TDFRequest({
      url: `${this._fullViewRoute}/filtering/${this._viewGuid}/parent`
    });
  }

  /**
   * When clearing a filter the server expects a populated string or the action will fail so here we set it to the word clear.
   * @param filter
   */
  private checkFilterValue(filter) {
    return !filter || filter === 'null' ? 'clear' : filter;
  }

  private ActionMap = {
    ServerSideFilter: 'server-filter',
    ClientSideFilter: 'client-filter',
    ParentFilter: 'parent-filter'
  };

  SetServerFilter(filterType, filter, filterObjString) {
    new TDFRequest({
      url: `${this._fullDesignRoute}/${this._viewGuid}/${
        this.ActionMap[filterType]
        }`,
      data: this.checkFilterValue(filter),
      type: 'PUT'
    }).MakeRequest();
    new TDFRequest({
      url: `${this._fullDesignRoute}/${this._viewGuid}/dx-${
        this.ActionMap[filterType]
        }`,
      data: this.checkFilterValue(filterObjString),
      type: 'PUT'
    }).MakeRequest();
  }

  SetColumnFormat(settings) {
    new TDFRequest({
      url: `${this._fullDesignRoute}/${
        this._viewGuid
        }/multiple-format-settings`,
      data: settings,
      type: 'PUT'
    })
      .MakeRequest()
      .done(response => {
        debugger;
      });
  }

  SetGeneralViewSettings(settings) {
    new TDFRequest({
      url: `${this._fullDesignRoute}/view-general`,
      type: 'POST',
      data: settings
    })
      .MakeRequest()
      .done(response => {
        debugger;
      });
  }

  // private DoSave(payload, viewcreated = false, reloadgrid = false) {
  //   let ds = this;

  //   new TDFRequest({
  //     url: `${ds._fullDesignRoute}/${ds._viewGuid}/save`,
  //     type: "PUT",
  //     data: payload
  //   })
  //     .MakeRequest()
  //     .done(res => {
  //       if (viewcreated) {
  //         //this will needs raised when a user manages a view and instead of just saving it they created a new one
  //         RaiseEvent(EventTypes.CenterEventTypes.refreshviews, EventNameSpace.request, res);
  //       }
  //       if (reloadgrid) {
  //         RaiseEvent(EventTypes.CenterEventTypes.viewsaved, EventNameSpace.notify, ds._view);
  //       }
  //     })
  //     .fail((res: Response) => {
  //       res.text().then((t: any) => {
  //         let d = JSON.parse(t);
  //         if (typeof d === "string") {
  //           new Notification({ message: d, type: "error" });
  //         } else {
  //           if ($.isPlainObject(d) && d.Message) {
  //             new Notification({ message: d.Message, type: "error" });
  //           }
  //         }
  //       });
  //     });
  // }

  private DoSave(
    payload,
    route: string,
    method: string,
    viewcreated = false,
    reloadgrid = false
  ) {
    let ds = this;

    new TDFRequest({ url: route, type: method, data: payload })
      .MakeRequest()
      .done(res => {
        if (viewcreated) {
          //this will needs raised when a user manages a view and instead of just saving it they created a new one
          RaiseEvent2(EventTypes.CenterEventTypes.refreshviews, ds._eventLimiter, eventNameSpace.request, res);
        }
        if (reloadgrid) {
          RaiseEvent2(EventTypes.CenterEventTypes.viewsaved, ds._eventLimiter, eventNameSpace.notify, ds._view);
        }
      })
      .fail((res: Response) => {
        res.text().then((t: any) => {
          let d = JSON.parse(t);
          if (typeof d === 'string') {
            new Notification({ message: d, type: 'error' });
          } else {
            if ($.isPlainObject(d) && d.Message) {
              new Notification({ message: d.Message, type: 'error' });
            }
          }
        });
      });
  }
  Save(settingsbag) {
    let ds = this;
    let payload = {
      GridView: settingsbag,
      NewViewSettings: null
    };

    this.DoBeforeSave(
      payload,
      `${ds._fullDesignRoute}/${ds._viewGuid}/save`,
      'PUT'
    );
  }

  // Save(settingsbag) {
  //   let ds = this;
  //   let payload = {
  //     GridView: settingsbag,
  //     NewViewSettings: null
  //   };
  //   let viewnameForm = $("<div />")
  //     .dxForm({
  //       labelLocation: "top",
  //       items: [
  //         {
  //           itemType: "simple",
  //           label: { text: "Please enter a name for the new Grid View" },
  //           editorType: "dxTextBox",
  //           dataField: "Name",
  //           editorOptions: {
  //             value: ds._view.Name
  //           }
  //         }
  //       ]
  //     })
  //     .dxForm("instance");
  //   let viewnamedialog = new Dialog(
  //     {
  //       size: "size-small",
  //       buttons: [
  //         {
  //           location: "after",
  //           widget: "dxButton",
  //           toolbar: "bottom",

  //           options: {
  //             text: "OK",
  //             onClick() {
  //               payload.NewViewSettings["ViewName"] = viewnameForm
  //                 .getEditor("Name")
  //                 .option("value");
  //               ds.DoSave(payload, true);
  //               viewnamedialog.close();
  //             }
  //           }
  //         },
  //         {
  //           location: "after",
  //           widget: "dxButton",
  //           toolbar: "bottom",

  //           options: {
  //             text: "Cancel",
  //             onClick() {
  //               viewnamedialog.close();
  //             }
  //           }
  //         }
  //       ],
  //       title: "Name the new view.",
  //       closable: true,
  //       body: viewnameForm.element()
  //     },
  //     null,
  //     false,
  //     false
  //   );

  //   let CreateAsText = `Create as ${
  //     ds._view.IsGlobalView ? "Personal" : "Global"
  //   } `;

  //   let dlgSettings = {
  //     title: "Please choose an option.",
  //     message: "Would you like to over-write this view, or create a new view?",
  //     buttons: <DevExpress.ui.dxButtonOptions[]>[
  //       {
  //         text: "Over-Write",
  //         onClick() {
  //           ds.DoSave(payload, false, true);
  //         }
  //       },
  //       {
  //         text: `Create New`,

  //         onClick() {
  //           payload.NewViewSettings = {
  //             Owner: ds.UserCanEditGlobal ? "Global" : CurrentUser.ID
  //           };
  //           viewnamedialog.open();
  //         }
  //       },
  //       {
  //         text: CreateAsText,
  //         disabled: !ds._view.IsGlobalView && !ds.UserCanEditGlobal,
  //         onClick() {
  //           ds._view.IsGlobalView
  //             ? (payload.NewViewSettings = {
  //                 Owner: CurrentUser.ID
  //               })
  //             : (payload.NewViewSettings = {
  //                 Owner: "Global"
  //               });
  //           viewnamedialog.open();
  //         }
  //       },
  //       {
  //         text: "Cancel"
  //       }
  //     ]
  //   };

  //   if (typeof ds.UserCanEditGlobal !== "undefined") {
  //     if (ds._view.IsGlobalView) {
  //       if (ds.UserCanEditGlobal) {
  //         DevExpress.ui.dialog.custom(dlgSettings).show();
  //       } else {
  //         DevExpress.ui.dialog
  //           .confirm(
  //             "You do not have rights to save global Grid Views. Would you like to save a Personal View instead?",
  //             "Insufficient Rights."
  //           )
  //           .done(result => {
  //             if (result) {
  //               payload.NewViewSettings = {
  //                 Owner: CurrentUser.ID
  //               };
  //               viewnamedialog.open();
  //             }
  //             //else die
  //           });
  //       }
  //     } else {
  //       DevExpress.ui.dialog.custom(dlgSettings).show();
  //     }
  //   }
  // }

  GetParentAccountFilterFields() {
    return new TDFRequest({
      url: `${this._fullViewRoute}/Filtering/ParentAccountFields`
    });
  }

  get DeleleGridViewRequest(): TDFRequest {
    return new TDFRequest({
      url: `${this._fullViewRoute}/${this._view.GUID}`,
      type: 'DELETE'
    });
  }

  private DoBeforeSave(payload: any, route: string, method: string) {
    let ds = this;
    let viewnameForm = $('<div />')
      .dxForm({
        labelLocation: 'top',
        items: [
          {
            itemType: 'simple',
            label: { text: 'Please enter a name for the new Grid View' },
            editorType: 'dxTextBox',
            dataField: 'Name',
            editorOptions: {
              value: ds._view.Name
            }
          }
        ]
      })
      .dxForm('instance');
    let viewnamedialog = new Dialog(
      {
        size: 'size-small',
        buttons: [
          {
            location: 'after',
            widget: 'dxButton',
            toolbar: 'bottom',

            options: {
              text: 'OK',
              type: 'success',
              icon: 'check',
              onClick() {
                payload.NewViewSettings['ViewName'] = viewnameForm
                  .getEditor('Name')
                  .option('value');
                ds.DoSave(payload, route, method, true);
                viewnamedialog.close();
              }
            }
          },
          {
            location: 'after',
            widget: 'dxButton',
            toolbar: 'bottom',

            options: {
              text: 'Cancel',
              type: 'danger',
              icon: 'remove',
              onClick() {
                viewnamedialog.close();
              }
            }
          }
        ],
        title: 'Name the new view.',
        closable: true,
        body: viewnameForm.element()
      },
      null,
      false,
      false
    );

    let CreateAsText = `Create as ${
      ds._view.IsGlobalView ? 'Personal' : 'Global'
      } `;

    let dlgSettings = {
      title: 'Please choose an option.',
      message: 'Would you like to over-write this view, or create a new view?',
      buttons: <DevExpress.ui.dxButtonOptions[]>[
        {
          text: 'Over-Write',
          onClick() {
            ds.DoSave(payload, route, method, false, true);
          }
        },
        {
          text: `Create New`,
          onClick() {
            payload.NewViewSettings = {
              Owner: ds.UserCanEditGlobal ? 'Global' : CurrentUser.ID
            };
            viewnamedialog.open();
          }
        },
        {
          text: CreateAsText,
          disabled: !ds._view.IsGlobalView && !ds.UserCanEditGlobal,
          onClick() {
            ds._view.IsGlobalView
              ? (payload.NewViewSettings = {
                Owner: CurrentUser.ID
              })
              : (payload.NewViewSettings = {
                Owner: 'Global'
              });
            viewnamedialog.open();
          }
        },
        {
          text: 'Cancel'
        }
      ]
    };
    $.when(ds._permissiondfd).done(() => {
      if (typeof ds.UserCanEditGlobal !== 'undefined') {
        if (ds._view.IsGlobalView) {
          if (ds.UserCanEditGlobal) {
            custom(dlgSettings).show();
          } else {
            confirm(
              'You do not have rights to save global Grid Views. Would you like to save a Personal View instead?',
              'Insufficient Rights.'
            )
              .done(result => {
                if (result) {
                  payload.NewViewSettings = {
                    Owner: CurrentUser.ID
                  };
                  viewnamedialog.open();
                }
                //else die
              });
          }
        } else {
          custom(dlgSettings).show();
        }
      }
    });
  }

  SaveState(state, theGrid) {
    let ds = this;
    state.columns.forEach(v => {
      if (v.dataField) {
        v.dataField = v.dataField
          .replace(ColonReplacementGUID, ':')
          .replace(PeriodReplacementGUID, '.');
      }
      if (v.width) {
        // This is to offset the server adding 48 to account for filter button.
        v.width = v.width - 48;
      }
    });
    if (theGrid.option('summary').totalItems) {
      let summaries = theGrid.option('summary').totalItems;

      let saveValue = [];
      summaries.forEach((v, k) => {
        if (v.summaryType && v.summaryType.search(/custom/i) < 0) {
          saveValue.push({
            DisplayFormat: v.displayFormat,
            SummaryType: v.summaryType.replace('avg', 'average'),
            FieldName: v.column
              .replace(ColonReplacementGUID, ':')
              .replace(PeriodReplacementGUID, '.'),
            columnFormat: v.valueFormat,
            Value: null
          });
        }
      });
      state['TotalSummaries'] = saveValue;
    }

    if (state.filterValue) {
      FilterBuilderHelper
        .getFilterText(
          state.filterValue,
          [
            NonOf(theGrid._ColumnHash, theGrid._view),
            AnyOf(theGrid._ColumnHash, theGrid._view)
          ],
          (theGrid as DevExpress.ui.dxDataGrid)
            .getVisibleColumns()
            .filter(x => {
              return x.dataField;
            }),
          'SQL'
        )
        .done(filterText => {
          filterText = filterText
            .replace(ColonReplacementGUID, ':')
            .replace(PeriodReplacementGUID, ',');
          state.filterValue = filterText;
        });
    } else {
      state.filterValue = 'Delete';
    }

    ds.DoBeforeSave(
      {
        gridState: state,
        NewViewSettings: null
      },
      RequestBuilder.GridDesign.BuildURL(
        ds._view.GUID,
        enumGridDesign.SaveState
      ),
      'POST'
    );
  }
}
