import * as moment from "moment";
import { itemTypes, tdfRights, tdfModules } from "../enums/enums";
import { IDisplayInfo } from "../interfaces/interfaces";
import { TDFRequest } from "../services/request";
import { Debugger } from "./debugger";
import { GetPreference } from "./user/getpreference";
export var CurrentUser: any;
export var CurrentCompany: any;
export var CompanyLoaded: boolean;
export var UserLoaded: boolean;

export function ReloadCompany() {
  const dfd: JQuery.Deferred<any, any, any> = $.Deferred();
  /*Here*/
  const myRequest = new TDFRequest({
    url: "/core/user/GetCurrentCompany/",
    type: "GET"
  });
  myRequest.ShouldShowSpinner = false;
  myRequest.MakeRequest().done(function (response: any) {
    CurrentCompany = response;
    CompanyLoaded = true;
    // if (!CurrentCompany) CurrentCompany = CurrentCompany;
    sessionStorage.setItem("CurrentCompany", JSON.stringify(response));
    dfd.resolve();
  });
  return dfd.promise();
}

export function LoadUser() {
  const dfd = $.Deferred();
  if (!UserLoaded) {
    const user = localStorage.getItem("CurrentUser");

    if (!user || user.length <= 0) {
      if (loadUserPromise != undefined) {
        return loadUserPromise;
      }

      ReloadUser().done(function () {
        loadUserPromise = undefined;
        ConfigureDebugger();
        dfd.resolve({
          CurrntUser: CurrentUser,
          CompanyLoaded: CompanyLoaded,
          CurrentCompany: CurrentCompany,
          UserLoaded: UserLoaded
        });
      });
      loadUserPromise = dfd.promise();
      return loadUserPromise;
    } else {
      CurrentUser = JSON.parse(user);
      UserLoaded = true;
      ConfigureDebugger();
      return dfd.promise(
        dfd.resolve({
          CurrntUser: CurrentUser,
          CompanyLoaded: CompanyLoaded,
          CurrentCompany: CurrentCompany,
          UserLoaded: UserLoaded
        })
      );
    }
  }

  return dfd.promise(
    dfd.resolve({
      CurrntUser: CurrentUser,
      CompanyLoaded: CompanyLoaded,
      CurrentCompany: CurrentCompany,
      UserLoaded: UserLoaded
    })
  );
}

export function ConfigureDebugger() {
  if (GetPreference("EnableDebugger", "TDFMobile") != "0") {
    Debugger.Enabled = true;
  }
}

export function ReloadUser() {
  const dfd = $.Deferred();
  /*Here*/
  const myRequest = new TDFRequest({
    url: "/core/user/GetCurrentUser/",
    type: "GET"
  });

  myRequest.ShouldShowSpinner = false;
  myRequest.MakeRequest().done(function (response: any) {
    if (response && typeof response === "object") {
      CurrentUser = response;
      UserLoaded = true;

      moment.locale(CurrentUser.LangCode);
      localStorage.setItem("CurrentUser", JSON.stringify(response));

      dfd.resolve();
    }
  });
  return dfd.promise();
}
export function GetItemTypeDisplayInfo() {
  const dfd: JQueryDeferred<IDisplayInfo[]> = $.Deferred();
  const info = localStorage.getItem("ItemTypeDisplayInfo");

  let disInfo: IDisplayInfo[];
  if (info) {
    disInfo = JSON.parse(info);
    return dfd.resolve(disInfo);
  }

  /*Here*/
  const request = new TDFRequest({
    url: "/core/user/GetItemTypeDisplayInfo/",
    type: "GET"
  });
  request.ShouldShowSpinner = false;
  request.MakeRequest().done(function (response: any) {
    localStorage.setItem("ItemTypeDisplayInfo", JSON.stringify(response));
    dfd.resolve(response);
  });
  return dfd.promise();
}

export function DisplayInfo(itemtype: itemTypes): JQueryPromise<IDisplayInfo> {
  const dfd: JQueryDeferred<IDisplayInfo> = $.Deferred();
  // itemtype = itemtype;
  GetItemTypeDisplayInfo().done(function (response) {
    if (response) {
      const theInfo: IDisplayInfo[] = $.grep(response, function (
        key: IDisplayInfo
      ) {
        return itemtype === key.TypeNum;
      });
      dfd.resolve(theInfo[0]);
    }
  });
  return dfd.promise();
}

export function DoesUserHaveRight(rightID: tdfRights): boolean {
  let result: boolean = false;
  //TODO: Make this a call to API?
  const hasRight = $.grep(CurrentUser.Rights, function (key, val) {
    return key === rightID;
  });

  if (hasRight.length > 0) {
    result = true;
  }

  return result;
}

export function LoadCompany() {
  const dfd = $.Deferred();
  if (!CompanyLoaded) {
    const company = sessionStorage.getItem("CurrentCompany");

    if (!company || company.length <= 0) {
      if (loadCompanyPromise != undefined) {
        return loadCompanyPromise;
      }

      ReloadCompany().done(function () {
        const apiPathPref = $.grep(CurrentCompany.Preferences.PrefObj, function (
          val: any,
          idx
        ) {
          return val.ModuleName === "TDFAPI" && val.PropertyName === "ApiPath";
        });

        // if (apiPathPref[0]) {
        //   let apiPath: string = apiPathPref[0].PropertyValue;
        //   if (
        //     apiPath.substr(0, 4).toLowerCase() != "http" &&
        //     apiPath.substr(0, 1) != "/"
        //   ) {
        //     apiPath = "/" + apiPath;
        //   }
        //   if (window.location.origin.search("localhost") < 0) {
        //     if (window.location.origin.search("test") < 0) {
        //       if (
        //         window.location.origin.search("tdfd") < 0 &&
        //         window.location.origin.search("mrhp") < 0 &&
        //         window.location.origin.search("azure") < 0
        //       ) {
        //         TDFRequest.ApiPath = apiPath;
        //       }
        //     }
        //   }
        // } else {
        //   if (!TDFRequest.ApiPath) TDFRequest.ApiPath = "/Api";
        // }
        loadCompanyPromise = undefined;
        dfd.resolve({
          CurrntUser: CurrentUser,
          CompanyLoaded: CompanyLoaded,
          CurrentCompany: CurrentCompany,
          UserLoaded: UserLoaded
        });
      });
    } else {
      CurrentCompany = JSON.parse(company);
      CompanyLoaded = true;
      if (!CurrentCompany) CurrentCompany = CurrentCompany;
      const apiPathPref = $.grep(CurrentCompany.Preferences.PrefObj, function (
        val: any,
        idx
      ) {
        return val.ModuleName === "TDFAPI" && val.PropertyName === "ApiPath";
      });

      // if (apiPathPref[0]) {
      //   let apiPath: string = apiPathPref[0].PropertyValue;
      //   if (
      //     apiPath.substr(0, 4).toLowerCase() != "http" &&
      //     apiPath.substr(0, 1) != "/"
      //   ) {
      //     apiPath = "/" + apiPath;
      //   }
      //   if (window.location.origin.search("localhost") < 0) {
      //     if (window.location.origin.search("test") < 0) {
      //       if (
      //         window.location.origin.search("tdfd") < 0 &&
      //         window.location.origin.search("mrhp") < 0 &&
      //         window.location.origin.search("azure") < 0
      //       ) {
      //         TDFRequest.ApiPath = apiPath;
      //       }
      //     }
      //   }
      // } else {
      //   if (!TDFRequest.ApiPath) TDFRequest.ApiPath = "/Api";
      // }

      return dfd.promise(
        dfd.resolve({
          CurrntUser: CurrentUser,
          CompanyLoaded: CompanyLoaded,
          CurrentCompany: CurrentCompany,
          UserLoaded: UserLoaded
        })
      );
    }
    loadCompanyPromise = dfd.promise();
    return loadCompanyPromise;
  } else {
    return dfd.promise(
      dfd.resolve({
        CurrntUser: CurrentUser,
        CompanyLoaded: CompanyLoaded,
        CurrentCompany: CurrentCompany,
        UserLoaded: UserLoaded
      })
    );
  }
}

var loadCompanyPromise: JQueryPromise<any>;
var loadUserPromise: JQueryPromise<any>;

export function IsModuleLicensedAtCompany(moduleID: tdfModules) {
  return CurrentCompany.CompanyModules.indexOf(moduleID) != -1;
}

// export function LoadGlobalization() {
//  LoadCompany();
//  $.when(LoadUser()).done(function () {
//    if (!UserLoaded) { return; }
//    const t: string = CurrentUser.LangCode.toLowerCase() === "en-us" ? "en" : CurrentUser.LangCode;
//    $.when( import(`../../static/cldr/cldr-dates/en/ca-gregorian.json`),
//     import(`../../static/cldr/cldr-dates/en/timeZoneNames.json`),
//     import(`../../static/cldr/cldr-numbers/en/numbers.json`),
//     import(`../../static/cldr/cldr-numbers/en/currencies.json`),
//     import("../../static/cldr/cldr-core/supplemental/likelySubtags.json"),
//     import("../../static/cldr/cldr-core/supplemental/timeData.json"),
//     import("../../static/cldr/cldr-core/supplemental/weekData.json"),
//     import("../../static/cldr/cldr-core/supplemental/currencyData.json"),
//     import("../../static/cldr/cldr-core/supplemental/numberingSystems.json")).then(function () {
//          return [].slice.apply(arguments, [0]).map(function (result) {
//            return result;
//          })
//         }).then(Globalize.load).done(()=>{

//      Globalize.locale(CurrentUser.LangCode);
//      DevExpress.localization.locale(CurrentUser.LangCode);

//      DevExpress.config({
//        defaultCurrency: CurrentUser.Currency,
//      });

//         })

/*Here*/
//   //   const r = new TDFRequest({ url: "/static/cldr/cldr-dates/" + t + "/ca-gregorian.json" });
/*Here*/
//   //  const r1 = new TDFRequest({ url: "/static/cldr/cldr-dates/" + t + "/timeZoneNames.json" });
/*Here*/
//   //  const r2 = new TDFRequest({ url: "/static/cldr/cldr-numbers/" + t + "/numbers.json" });
/*Here*/
//   //  const r3 = new TDFRequest({ url: "/static/cldr/cldr-numbers/" + t + "/currencies.json" });
/*Here*/
//   //  const r4 = new TDFRequest({ url: "/static/cldr/cldr-core/supplemental/likelySubtags.json" });
/*Here*/
//   //  const r5 = new TDFRequest({ url: "/static/cldr/cldr-core/supplemental/timeData.json" });
/*Here*/
//   //  const r6 = new TDFRequest({ url: "/static/cldr/cldr-core/supplemental/weekData.json" });
/*Here*/
//   //  const r7 = new TDFRequest({ url: "/static/cldr/cldr-core/supplemental/currencyData.json" });
/*Here*/
//   //  const r8 = new TDFRequest({ url: "/static/cldr/cldr-core/supplemental/numberingSystems.json" });

//   //  r.ShouldShowSpinner = false;
//   //  r1.ShouldShowSpinner = false;
//   //  r2.ShouldShowSpinner = false;
//   //  r3.ShouldShowSpinner = false;
//   //  r4.ShouldShowSpinner = false;
//   //  r5.ShouldShowSpinner = false;
//   //  r6.ShouldShowSpinner = false;
//   //  r7.ShouldShowSpinner = false;
//   //  r8.ShouldShowSpinner = false;

//   //  $.when(r.MakeRequest(), r1.MakeRequest(), r2.MakeRequest(), r3.MakeRequest(),
//   //    r4.MakeRequest(), r5.MakeRequest(), r6.MakeRequest(), r7.MakeRequest(), r8.MakeRequest(),
//   //  ).then(function () {
//   //    return [].slice.apply(arguments, [0]).map(function (result) {
//   //      return result;
//   //    });

//   //  }).then(Globalize.load).then(function () {

//   //    Globalize.locale(CurrentUser.LangCode);
//   //    DevExpress.localization.locale(CurrentUser.LangCode);

//   //    DevExpress.config({
//   //      defaultCurrency: CurrentUser.Currency,
//   //    });

//   //  });
//  });

// }

//export function IsModuleEnabled(moduleID: string) {
//  const theContext = this;
//  return !!theCurrentUser.Modules.indexOf(moduleID);
//}

// export var CurrentUser: any;
// export var CurrentCompany: any;
// export var CompanyLoaded: boolean;
// export var UserLoaded: boolean;

//   export class Context {
//// TODO: Get the search history, preferences, current user and anything else relevant using the dxLocalStore

//static CurrentUser: any;
//static CurrentCompany: any;
//static CompanyLoaded: boolean;
//static UserLoaded: boolean;

//private static loadCompanyPromise: JQueryPromise<any>;
//static LoadCompany() {
//    let dfd = $.Deferred();
//     let companyLoadTime: string = sessionStorage.getItem("CurrentCompanyLoadTime");
//     if (!companyLoadTime || (new Date(companyLoadTime).getDate() != new Date().getDate())) {
//// Force a reload of the current company if we haven't loaded it at all or if we haven't loaded it today.
//Context.CompanyLoaded = false;
//sessionStorage.removeItem("CurrentCompany");
//     }
//    if (!Context.CompanyLoaded) {
//	var company = sessionStorage.getItem("CurrentCompany");

//	if (!company || company.length <= 0) {
//	    if (Context.loadCompanyPromise != undefined) {
//		return (Context.loadCompanyPromise);
//	    }

//	   ReloadCompany().done(function () {
//		let apiPathPref = $.grep(CurrentCompany.Preferences.PrefObj, function (val: any, idx) {
//		    return val.ModuleName === "TDFAPI" && val.PropertyName === "ApiPath";
//		});

//    if (apiPathPref[0] && apiPathPref[0].PropertyValue) {
//		    let apiPath: string = apiPathPref[0].PropertyValue;
//		    if ((apiPath.substr(0, 4).toLowerCase() != "http") && (apiPath.substr(0, 1) != "/")) {
//			apiPath = "/" + apiPath
//		    }
//		    TDFRequest.ApiPath = apiPath;
//		}
//		else {
//		    TDFRequest.ApiPath = "/Api";
//		}
//		Context.loadCompanyPromise = undefined;
//		dfd.resolve(Context);
//	    });
//	}
//	else {
//	   CurrentCompany = JSON.parse(company);
//	   CompanyLoaded = true;
//	    //  if (!CurrentCompany)CurrentCompany =CurrentCompany;
//	    let apiPathPref = $.grep(CurrentCompany.Preferences.PrefObj, function (val: any, idx) {
//		return val.ModuleName === "TDFAPI" && val.PropertyName === "ApiPath";
//	    });

//  if (apiPathPref[0] && apiPathPref[0].PropertyValue) {
//		let apiPath: string = apiPathPref[0].PropertyValue;
//		if ((apiPath.substr(0, 4).toLowerCase() != "http") && (apiPath.substr(0, 1) != "/")) {
//		    apiPath = "/" + apiPath
//		}
//		TDFRequest.ApiPath = apiPath;
//	    }
//	    else {
//		TDFRequest.ApiPath = "/Api";
//	    }

//	    return dfd.promise(dfd.resolve(Context));
//	}
//	Context.loadCompanyPromise = dfd.promise();
//	return (Context.loadCompanyPromise);
//    }
//    else {
//	return dfd.promise(dfd.resolve(Context));
//    }
//}

//static ReloadCompany() {
//    let dfd = $.Deferred();

//    var myRequest = new TDFRequest({ url: "/core/user/GetCurrentCompany/", type: "POST" });
//    myRequest.ShouldShowSpinner = false;
//    myRequest.MakeRequest().done(function (response: any) {
//	CurrentCompany = response;
//	Context.CompanyLoaded = true;
//	sessionStorage.setItem("CurrentCompany", JSON.stringify(response));
//sessionStorage.setItem("CurrentCompanyLoadTime", (new Date()).toISOString());

//	dfd.resolve();
//    });
//    return dfd.promise();
//}

//private static loadUserPromise: JQueryPromise<any>;
//static LoadUser(): JQueryPromise<Context> {
//    let dfd = $.Deferred();
//    if (!Context.UserLoaded) {
//	var user = localStorage.getItem("CurrentUser");

//	if (!user || user.length <= 0) {
//	    if (Context.loadUserPromise != undefined) {
//		return (Context.loadUserPromise);
//	    }

//	   ReloadUser().done(function () {
//		Context.loadUserPromise = undefined;
//		Context.ConfigureDebugger();
//		dfd.resolve(Context);
//	    });
//	   loadUserPromise = dfd.promise();
//	    return (Context.loadUserPromise);
//	}
//	else {
//	   CurrentUser = JSON.parse(user);
//	   UserLoaded = true;
//	   ConfigureDebugger();

//	    return dfd.promise(dfd.resolve(Context));
//	}
//    }

//    return dfd.promise(dfd.resolve(Context));

//}

//static ConfigureDebugger() {
//    if (Preferences.GetPreference("EnableDebugger", "TDFMobile") != "0") {
//	Debugger.Enabled = true;
//    }
//}

//static ReloadUser() {
//    let dfd = $.Deferred();
//    var myRequest = new TDFRequest({ url: "/core/user/GetCurrentUser/", type: "POST" });

//    myRequest.ShouldShowSpinner = false;
//    myRequest.MakeRequest().done(function (response: any) {
//	if (typeof response === "object") {
//	   CurrentUser = response;
//	   UserLoaded = true;

//	    moment.locale(CurrentUser.LangCode);
//	    localStorage.setItem("CurrentUser", JSON.stringify(response));

//	    dfd.resolve();
//	}
//    });
//    return dfd.promise();
//}

//static actualReinitializeServerSessionFunction = null;

//// We have limited access to write to the server-side cache.  Thus, if the web server recycles the application pool, we lose our session but are still authenticated.
//// We are detecting this on the server side, adding a response cookie of 'CreateSessionRequest' set to true, and then calling this method if the client sees that header on the response.
//static ReinitializeServerSession() {

//    let reInitFunciton = function () {
//	let dfd = $.Deferred();
//	var myRequest = new TDFRequest({ url: "/Login/ReinitializeServerSession", type: "POST" });

//	myRequest.ShouldShowSpinner = false;
//	myRequest.MakeRequest().done(function (response: any) {
//	    dfd.resolve();
//	});
//	return dfd.promise();
//    };

//    if (!Context.actualReinitializeServerSessionFunction) {
//Context.actualReinitializeServerSessionFunction = Debounce(reInitFunciton, 10000);
//    }

//   actualReinitializeServerSessionFunction();
//}

//static LoadGlobalization() {
//   LoadCompany();
//    $.when(LoadUser()).done(function () {
//	if (!Context.UserLoaded) return;
//	let t: string =CurrentUser.LangCode.toLowerCase() === "en-us" ? "en" :CurrentUser.LangCode;

//	let r = new TDFRequest({ url: "/Scripts/cldr/cldr-dates/" + t + "/ca-gregorian.json" });
//	let r1 = new TDFRequest({ url: "/Scripts/cldr/cldr-dates/" + t + "/timeZoneNames.json" });
//	let r2 = new TDFRequest({ url: "/Scripts/cldr/cldr-numbers/" + t + "/numbers.json" });
//	let r3 = new TDFRequest({ url: "/Scripts/cldr/cldr-numbers/" + t + "/currencies.json" });
//	let r4 = new TDFRequest({ url: "/Scripts/cldr/cldr-core/supplemental/likelySubtags.json" });
//	let r5 = new TDFRequest({ url: "/Scripts/cldr/cldr-core/supplemental/timeData.json" });
//	let r6 = new TDFRequest({ url: "/Scripts/cldr/cldr-core/supplemental/weekData.json" });
//	let r7 = new TDFRequest({ url: "/Scripts/cldr/cldr-core/supplemental/currencyData.json" });
//	let r8 = new TDFRequest({ url: "/Scripts/cldr/cldr-core/supplemental/numberingSystems.json" });

//	r.ShouldShowSpinner = false;
//	r1.ShouldShowSpinner = false;
//	r2.ShouldShowSpinner = false;
//	r3.ShouldShowSpinner = false;
//	r4.ShouldShowSpinner = false;
//	r5.ShouldShowSpinner = false;
//	r6.ShouldShowSpinner = false;
//	r7.ShouldShowSpinner = false;
//	r8.ShouldShowSpinner = false;

//	$.when(r.MakeRequest(), r1.MakeRequest(), r2.MakeRequest(), r3.MakeRequest(),
//	    r4.MakeRequest(), r5.MakeRequest(), r6.MakeRequest(), r7.MakeRequest(), r8.MakeRequest()
//	).then(function () {
//	    return [].slice.apply(arguments, [0]).map(function (result) {
//		return result;
//	    });

//	}).then(Globalize.load).then(function () {

//	    Globalize.locale(CurrentUser.LangCode);
//	    DevExpress.localization.locale(CurrentUser.LangCode);

//	    DevExpress.config({
//		defaultCurrency:CurrentUser.Currency
//	    })

//	});
//    });

//}

//static IsModuleEnabled(moduleID: string) {
//    var theContext = this;
//    return !!theCurrentUser.Modules.indexOf(moduleID);
//}

//static GetItemTypeDisplayInfo() {
//    var dfd: JQueryDeferred<Array<DisplayInfo>> = $.Deferred();
//    var info = localStorage.getItem("ItemTypeDisplayInfo");

//    var disInfo: Array<DisplayInfo>;
//    if (info) {
//	disInfo = JSON.parse(info);
//	return dfd.resolve(disInfo);
//    }

//    var request = new TDFRequest({ url: "/core/user/GetItemTypeDisplayInfo/", type: "GET" });
//    request.ShouldShowSpinner = false;
//    request.MakeRequest().done(function (response: any) {

//	localStorage.setItem("ItemTypeDisplayInfo", JSON.stringify(response));
//	dfd.resolve(response);

//    });
//    return dfd.promise();
//}

//static DisplayInfo(itemtype: itemTypes): JQueryPromise<DisplayInfo> {

//    var dfd: JQueryDeferred<DisplayInfo> = $.Deferred();
//    // itemtype = itemtype;
//   GetItemTypeDisplayInfo()
//	.done(function (response) {
//	    if (response) {
//		var theInfo: Array<DisplayInfo> = $.grep(response, function (key: DisplayInfo) {
//		    return itemtype === key.TypeNum

//		});
//		dfd.resolve(theInfo[0]);
//	    }
//	});
//    return dfd.promise();
//}

//static GetDevice() {
//    let device: DevExpress.Device;
//    let isDevice: boolean;
//    device = DevExpress.devices.current();

//    isDevice = (device.deviceType === 'phone') || (device.deviceType === 'tablet');
//    return { device: device, isDevice: isDevice };

//}

//static DoesUserHaveRight(rightID: tdfRights): boolean {
//    let result: boolean = false;

//    let hasRight = $.grep(CurrentUser.Rights, function (key, val) {
//	return key === rightID;
//    });

//    if (hasRight.length > 0) {
//	result = true;
//    }

//    return (result);
//}
//   }
