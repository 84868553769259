import { EventCenter } from "./baseeventcenter";
import { TDFRequest } from "services/request";
import dxCustomStore from 'devextreme/data/custom_store';
import dxDataSource from 'devextreme/data/data_source';
import { IToolbarItemOverrideOptions } from "interfaces/grids/interfaces";
import { AwesomeToolbar } from "components/grids/gridtoolbars/datacentertoolbar";
import { itemTypes, GridContainerTypes, CenterType } from "enums/enums";
import { RaiseEvent2 } from "infrastructure/events/ui_events";
import { eventNameSpace, EventTypes } from "enums/webevents/enums";
import { Dialog } from "components/dialogs/dialog";
import { NotAsAwesomeGrid } from "components/grids/notasawesomegrid";
import { TDFDataCenters } from "interfaces/datacenters/tdfdatacenters";

export class SessionCenter extends EventCenter {
    _CenterType = CenterType.EventSessionManager;

    private _GridDataSource: DevExpress.data.DataSource;
    get GridDataSource(): DevExpress.data.DataSource {
        let evtdc = this;

        if (evtdc._GridDataSource) return evtdc._GridDataSource;

        evtdc._GridDataSource = new dxDataSource(
            new dxCustomStore({
                load: () => {
                    let d: JQueryDeferred<any> = $.Deferred();
                    evtdc.Sessions.done((sessions) => {
                        d.resolve(sessions);
                    });

                    return d.promise();
                },
                key: 'SessionID',
                byKey: (key) => {
                    let d: JQueryDeferred<any[]> = $.Deferred();

                    evtdc.Sessions.done((sessions) => {
                        d.resolve(
                            sessions.filter((a) => {
                                return a.SessionID === key;
                            })[0]
                        );
                    });

                    return d.promise();
                }
            })
        );

        return evtdc._GridDataSource;
    }

    get Sessions(): JQueryPromise<any[]> {
        let evtdc = this;
        let d: JQueryDeferred<any[]> = $.Deferred();

        new TDFRequest({
            url: `/Item/Event/${evtdc.EventID}/Sessions`,
            type: 'GET'
        }).MakeRequest().done((response: any[]) => {
            d.resolve(response);
        });

        return d.promise();
    }

    private _SessionTypes: any[];
    get SessionTypes(): JQueryPromise<any[]> {
        let evtdc = this;
        let d: JQueryDeferred<any[]> = $.Deferred();

        if (evtdc._SessionTypes && evtdc._SessionTypes.length) {
            return d.promise(d.resolve(evtdc._SessionTypes));
        }

        new TDFRequest({
            url: 'Item/Event/SessionTypes',
            type: 'GET'
        }).MakeRequest().done((response: any[]) => {
            evtdc._SessionTypes = response;
            d.resolve(response);
        });

        return d.promise();
    }

    private _SessionEnrollTypes: any[];
    get SessionEnrollTypes(): JQueryPromise<any[]> {
        let evtdc = this;
        let d: JQueryDeferred<any[]> = $.Deferred();

        if (evtdc._SessionEnrollTypes && evtdc._SessionEnrollTypes.length) {
            return d.promise(d.resolve(evtdc._SessionEnrollTypes));
        }

        new TDFRequest({
            url: 'Item/Event/SessionEnrollTypes',
            type: 'GET'
        }).MakeRequest().done((response: any[]) => {
            evtdc._SessionEnrollTypes = response;
            d.resolve(response);
        });

        return d.promise();
    }

    private GridColumns: DevExpress.ui.dxDataGridColumn[] = [
        {
            dataField: 'SessionID',
            visible: false
        }, {
            dataField: 'Name',
        }, {
            dataField: 'Description',
        }, {
            dataField: 'Start',
            caption: 'Start Date',
            dataType: 'datetime'
        }, {
            dataField: 'End',
            caption: 'End Date',
            dataType: 'datetime'
        }, {
            dataField: 'Enrolled'
        }, {
            dataField: 'Limit'
        }, {
            dataField: 'Fee'
        }, {
            dataField: 'Location',
            lookup: {
                displayExpr: 'PrivateDescription',
                valueExpr: 'LocationID',
                dataSource: new dxCustomStore({
                    load: (options) => {
                        let d = $.Deferred();

                        this.Locations.done((locations) => {
                            d.resolve(locations);
                        });

                        return d.promise();
                    }
                })
            }
        }, {
            dataField: 'Presenter',
            calculateDisplayValue: 'Presenter.Name',
            allowFiltering: false,
            allowSorting: false
        }, {
            dataField: 'Caterer',
            calculateDisplayValue: 'Caterer.Name',
            allowFiltering: false,
            allowSorting: false
        }
    ];

    constructor(eventID: string, customName: string, MyCreationArgs: TDFDataCenters.ICRMDataCenterCreationArgs) {
        super(eventID, customName, MyCreationArgs);
    }

    init() {
        this.initContainers();
        this.initToolbar();
        this.InitGrid();
    }

    initMenu() {
        // Do Nothing
    }

    initToolbar() {
        let evtdc = this;

        if (evtdc.DataToolbarAreaContainer.children().length === 0) {
            let ToolbarItems: IToolbarItemOverrideOptions[] = [
                { name: "CustomAddButton", index: 1, location: "before" },
                { name: "CustomOpenButton", index: 2, location: "before" },
                { name: "RefreshButton", index: 5, location: "after" },
            ];

            new AwesomeToolbar(
                evtdc.DataToolbarAreaContainer,
                itemTypes.itemEvent,
                GridContainerTypes.Event,
                evtdc.CustomName,
                ToolbarItems,
                {
                    ItemType: itemTypes.itemEvent,
                    gridContainerType: GridContainerTypes.Event,
                    UniqueCenterName: evtdc.CustomName,
                    OpenButtonOnClick: evtdc.OpenButtonOnClick.bind(evtdc),
                    AddButtonOnClick: evtdc.AddButtonOnClick.bind(evtdc)
                }
            ).init();
        }
    }

    protected InitGrid() {
        let evtdc = this;
        let grid = new NotAsAwesomeGrid(
            evtdc.DataGridAreaContainer,
            evtdc.CenterType,
            evtdc.CustomName,
            evtdc.GridDataSource,
            evtdc.GridColumns
        )

        grid.OverrideDxOptions({
            selection: {
                mode: "single"
            },
            // editing: {
            //     allowAdding: true,
            //     mode: 'popup',
            //     popup: {
            //         height: '50%',
            //         width: '50%',
            //     },
            //     form: evtdc.GetSessionFormSettings(),
            // },
            columnChooser: {
                enabled: false
            }
        });

        grid.init();
    }

    protected OpenButtonOnClick() {
        let evtdc = this;

        let data = { items: [] };
        RaiseEvent2(EventTypes.CenterEventTypes.gridselecteditems, evtdc.CustomName, eventNameSpace.request, data);

        if (data.items.length) {
            let form = evtdc.GetSessionForm(data.items[0]);

            let dialog = evtdc.GetEditSessionDialog(form)

            dialog.open();
        }
    }

    protected AddButtonOnClick() {
        let evtdc = this;

        let form = evtdc.GetSessionForm();

        let dialog = evtdc.GetEditSessionDialog(form)

        dialog.open();
    }

    private GetEditSessionDialog(form): Dialog {
        let evtdc = this;

        let dialog = new Dialog({
            title: '',
            closable: true,
            body: form.element(),
            size: 'size-normal',
            buttons: [
                {
                    location: 'after',
                    toolbar: 'bottom',
                    widget: 'dxButton',
                    options: <DevExpress.ui.dxButtonOptions>{
                        text: 'Save',
                        type: 'success',
                        icon: 'dx-icon icon-save',
                        onClick: () => {
                            new TDFRequest({
                                url: `Item/Event/${evtdc.EventID}/Session`,
                                type: 'POST',
                                data: form.option('formData')
                            }).MakeRequest().done(() => {
                                RaiseEvent2(EventTypes.CenterEventTypes.gridrefresh, evtdc.CustomName, eventNameSpace.request, { clear: "" });
                                dialog.close();
                            })
                        }
                    }
                }, {
                    location: 'after',
                    toolbar: 'bottom',
                    widget: 'dxButton',
                    options: <DevExpress.ui.dxButtonOptions>{
                        text: 'Cancel',
                        type: 'danger',
                        icon: 'dx-icon dx-icon-remove',
                        onClick: () => {
                            dialog.close();
                        }
                    }
                }
            ]
        }, null, true, false);

        return dialog;
    }

    private GetSessionForm(data?): DevExpress.ui.dxForm {
        let evtdc = this;

        let form = $('<div />').dxForm({
            formData: data || null,
            items: [
                {
                    itemType: 'group',
                    items: [
                        {
                            dataField: 'Name',
                            label: { text: 'Title' },
                        }, {
                            dataField: 'Description',
                        }, {
                            dataField: 'Location',
                            editorType: 'dxSelectBox',
                            editorOptions: <DevExpress.ui.dxSelectBoxOptions>{
                                displayExpr: 'PrivateDescription',
                                valueExpr: 'LocationID',
                                dataSource: new dxDataSource({
                                    paginate: false,
                                    store: new dxCustomStore({
                                        load: () => {
                                            let d = $.Deferred();

                                            this.Locations.done((locations) => {
                                                d.resolve(locations);
                                            });

                                            return d.promise();
                                        },
                                        key: 'LocationID',
                                        byKey: (key) => {
                                            let d = $.Deferred();

                                            evtdc.Locations.done((locations) => {
                                                let location = locations.filter((a) => {
                                                    return a.LocationID === key;
                                                })[0];

                                                d.resolve(location);
                                            });

                                            return d.promise();
                                        }
                                    })
                                })
                            }
                        }, {
                            dataField: 'Start',
                            editorType: 'dxDateBox',
                            editorOptions: <DevExpress.ui.dxDateBoxOptions>{
                                type: 'datetime'
                            }
                        }, {
                            dataField: 'End',
                            editorType: 'dxDateBox',
                            editorOptions: <DevExpress.ui.dxDateBoxOptions>{
                                type: 'datetime'
                            }
                        }, {
                            dataField: 'Type',
                            editorType: 'dxSelectBox',
                            editorOptions: <DevExpress.ui.dxSelectBoxOptions>{
                                displayExpr: 'Description',
                                valueExpr: 'ID',
                                dataSource: new dxDataSource({
                                    paginate: false,
                                    store: new dxCustomStore({
                                        load: () => {
                                            let d = $.Deferred();

                                            evtdc.SessionTypes.done((sessionTypes) => {
                                                d.resolve(sessionTypes);
                                            });

                                            return d.promise();
                                        },
                                        key: 'ID',
                                        byKey: (key) => {
                                            let d = $.Deferred();

                                            evtdc.SessionTypes.done((sessionTypes) => {
                                                let sessionType = sessionTypes.filter((a) => {
                                                    return a.ID === key;
                                                })[0];

                                                d.resolve(sessionType);
                                            });

                                            return d.promise();
                                        }
                                    })
                                })
                            }
                        }
                    ]
                }, {
                    itemType: 'group',
                    items: [
                        {
                            dataField: 'EnrollmentType',
                            editorType: 'dxSelectBox',
                            editorOptions: <DevExpress.ui.dxSelectBoxOptions>{
                                displayExpr: 'Description',
                                valueExpr: 'ID',
                                dataSource: new dxDataSource({
                                    paginate: false,
                                    store: new dxCustomStore({
                                        load: () => {
                                            let d = $.Deferred();

                                            evtdc.SessionEnrollTypes.done((sessionEnrollTypes) => {
                                                d.resolve(sessionEnrollTypes);
                                            });

                                            return d.promise();
                                        },
                                        key: 'ID',
                                        byKey: (key) => {
                                            let d = $.Deferred();

                                            evtdc.SessionEnrollTypes.done((sessionEnrollTypes) => {
                                                let sessionEnrollType = sessionEnrollTypes.filter((a) => {
                                                    return a.ID === key;
                                                })[0];

                                                d.resolve(sessionEnrollType);
                                            });

                                            return d.promise();
                                        }
                                    })
                                })
                            }
                        }, {
                            dataField: 'Limit',
                            label: { text: 'Enrollment Limit' },
                            editorType: 'dxNumberBox'
                        }, {
                            dataField: 'Fee',
                            label: { text: 'Enrollment Fee' },
                            editorType: 'dxNumberBox'
                        }, {
                            dataField: 'Caterer',
                            label: { text: 'Caterer' },
                            template: evtdc.GetContactSearchField.bind(evtdc)
                        }, {
                            dataField: 'Presenter',
                            label: { text: 'Presenter' },
                            template: evtdc.GetContactSearchField.bind(evtdc)
                        }
                    ]
                }
            ],
            colCountByScreen: {
                lg: 2,
                md: 2,
                sm: 1,
                xs: 1
            }
        }).dxForm('instance');

        return form;
    }
}