import { DateRangeSetupOptions } from '../../../interfaces/grids/daterangesetupoptions';
import { TDFRequest } from '../../request';

export class RelativeDateFilterDataService {
  static LoadedFilterOptions: {
    [ViewID: string]: DateRangeSetupOptions;
  };
  private get _routePrefix(): string {
    return `design/grid/views`;
  }
  private get _route(): string {
    return `${TDFRequest.ApiPath}/${this._routePrefix}`;
  }
  SettingsForView(viewid: string) {
    let service = this;
    let d: JQueryDeferred<DateRangeSetupOptions> = $.Deferred();
    if (
      RelativeDateFilterDataService.LoadedFilterOptions &&
      RelativeDateFilterDataService.LoadedFilterOptions[viewid]
    )
      return d.promise(
        d.resolve(RelativeDateFilterDataService.LoadedFilterOptions[viewid])
      );
    new TDFRequest({ url: `${this._route}/${viewid}/date-filters` })
      .MakeRequest()
      .done((response: DateRangeSetupOptions) => {
        if (
          typeof RelativeDateFilterDataService.LoadedFilterOptions !==
          'undefined'
        ) {
          RelativeDateFilterDataService.LoadedFilterOptions[viewid] = response;
        } else {
          RelativeDateFilterDataService.LoadedFilterOptions = {
            [viewid]: response
          };
        }
        d.resolve(RelativeDateFilterDataService.LoadedFilterOptions[viewid]);
      });
    return d.promise();
  }
}
