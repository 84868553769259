import { DataScopeSelectionTypes } from "../../enums/user/datascope/enums";
import { DataScopeSelectionPanel } from "../../infrastructure/user/datascopeselectionpanel";
import { _get } from "../../util/allutils";


    export class ReportScopeSelection extends DataScopeSelectionPanel {
	readonly DialogTitle: string = "Report Scope";
	readonly ScopeSelectionType: DataScopeSelectionTypes = DataScopeSelectionTypes.ReportScope;
	AllowDefault = false;
	ReportTitle = "";

	constructor() {
	    super();
	    this.GetInitialSelectedItemList();
	}

	GetInitialSelectedItemList(): JQueryPromise<any[]> {
	    var panel = this;
	    this.SelectedItems = {};
	    this.ActiveFolderLevel = 1;
	    this.ActiveSelectionLevel = panel.ConvertFolderTabToScopeLevel(this.ActiveFolderLevel);

	    let initialList = [];
	    let dfd: JQueryDeferred<any[]> = $.Deferred();
	    return dfd.promise(dfd.resolve(initialList));
	}

	CommitPanelSelections(setAsDefault) {
	    var panel = this;
	    var btn = _get('btn_apply_dataScope_settings', undefined);
	    if (btn) btn.disabled = true;

	    var selection = "";

	    if (panel.ActiveFolderLevel <= 5) {                   // Saved Selections and Other tab don't use the selected items area.
		selection = panel.CurrentlySelectedItemsAsString();
	    }
	    else if (panel.ActiveFolderLevel === 6) {
		// Handle Saved Selections
		// Won't ever get through here for a saved selection, as those are direct hyperlinks.
	    }
	    else if (panel.ActiveFolderLevel === 7) {
		// Handle Other types
		var custIDElement = $("#scope-custid");
		var mastIDElement = $("#scope-masterid");
		var salesmanIDElement = $("#scope-salesid");

		if ((<HTMLInputElement>$(custIDElement)[0]).value) {
		    selection = (<HTMLInputElement>$(custIDElement)[0]).value;
		    panel.ActiveSelectionLevel = 4;
		}
		else if ((<HTMLInputElement>$(mastIDElement)[0]).value) {
		    selection = (<HTMLInputElement>$(mastIDElement)[0]).value;
		    panel.ActiveSelectionLevel = 5;
		}
		else if ((<HTMLInputElement>$(salesmanIDElement)[0]).value) {
		    selection = "rep_" + (<HTMLInputElement>$(salesmanIDElement)[0]).value;
		    panel.ActiveSelectionLevel = 3;
		}
	    }

	    var urlString = "/Report/?reportTitle=" + encodeURIComponent(panel.ReportTitle);
	    urlString += "&_bsid=" + encodeURIComponent(selection);
	    urlString += "&bsidType=" + encodeURIComponent(panel.ActiveSelectionLevel.toString());
	    //urlString += "&withMenu=true";

	    window.open(urlString);
	}

	LoadSavedSelection(id) {
	    var panel = this;

	    var urlString = "/Report/?reportTitle=" + encodeURIComponent(panel.ReportTitle);
	    urlString += "&savedSelection=" + encodeURIComponent(id);

	    window.open(urlString).focus();

	    //TODO: need a way to close the dialog on a saved selection
	    //BootstrapDialog.dialogs['scope-selection-setting-dlg'].close();
	}

    }
