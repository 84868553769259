import { EnumParentType, itemAccountTypes, itemTypes } from "../../../enums/enums";

   export class ItemMain {

      public ItemSubject: string = "";
      public ItemId: string = "";
      public ItemType: itemTypes = itemTypes.itemUnknown;
      public ItemKey: string = "";
      public ItemOwner: string = "";
      public ItemOwnerId: string = "";
      public ItemCreation: string = "";
      public ItemModDate: string = "";
      public ItemCreatedBy: string = "";
      public ItemModifiedBy: string = "";
      public ItemDeleted: number = -1;
      public ItemParentAccountId: string = "";
      public ItemActive: number = -1;
      public ItemAccountType: itemAccountTypes = -1;
      public ItemAccountTypeName: string = "";
      public ItemFormType: number = -1;
      public ItemFormName: string = "";
      public FolderLevel4: string = "";
      public FolderLevel4Id: string = "";
      public FolderLevel4Owner: string = "";
      public FolderLevel4SalesPersonId: string = "";
      public FolderLevel4TerritoryId: string = "";
      public FolderLevel3: string = "";
      public FolderLevel3Id: string = "";
      public FolderLevel3Owner: string = "";
      public FolderLevel3SalesPersonId: string = "";
      public FolderLevel3TerritoryId: string = "";
      public FolderAcctRoot: string = "";
      public FolderAcctRootId: string = "";
      public FolderAcctRootOwner: string = "";
      public FolderAcctRootSalesPersonId: string = "";
      public FolderAcctRootTerritoryId: string = "";
      public FolderAcctPkg: string = "";
      public FolderAcctPkgId: string = "";
      public FolderAcctPkgOwner: string = "";
      public FolderAcctPkgSalesPersonId: string = "";
      public FolderAcctPkgTerritoryId: string = "";
      public FolderName: string = "";
      public FolderId: string = "";
      public FolderOwner: string = "";
      public FolderSalesPersonId: string = "";
      public FolderTerritoryId: string = "";
      public FolderShare: string = "";
       // TODO: make itemmain an inteface not a class and make interfaces for ItemParents 
        public ItemParents: IItemParents[];
        public PrimaryParent: any;

		 public ItemUniqueID: string = "";

      constructor(args) {
         let iMain = this;
         $.each(args, function (key, val) {
            iMain[key] = val;
         })

      }
    }

    export interface IItemParents {
           Parent:string
      ParentId :string
      ParentType:itemTypes
      ParentLevel : EnumParentType
    }

