export function GetQueryStringParam(paramName: string, url?: string) {
  if (!url) url = window.location.href;
  paramName = paramName.replace(/[\[\]]/gi, "\\$&");
  var regex = new RegExp("[?&]" + paramName + "(=([^&#]*)|&|#|$)", "i"),
    results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return "";
  return decodeURIComponent(results[2].replace(/\+/gi, " "));
  //window.location.search.replace("?token=", "");
}
