import * as Cookies from "js-cookie";
import { Spinner } from "spin.js";
import { Notification } from "../components/dialogs/notification";
import { GetLoginForm } from "../components/login/login";
import { GetQueryStringParam } from "../util/getquerystringparam";
import AbortController from 'abort-controller';

function log(text: string, options = {}) {
    //TODO: if debug 
    console.log(text, options);
}

export interface TDFRequestSettings extends JQueryAjaxSettings { }

export class TDFRequest {

    /* 
    public static actualReinitializeServerSessionFunction = null;
    // We have limited access to write to the server-side cache.  Thus, if the web server recycles the application pool, we lose our session but are still authenticated.
    // We are detecting this on the server side, adding a response cookie of 'CreateSessionRequest' set to true, and then calling this method if the client sees that header on the response.
    public static ReinitializeServerSession() {
      const reInitFunciton = function () {
        const dfd = $.Deferred();
        
        const myRequest = new TDFRequest({
          url: "/Login/ReinitializeServerSession",
          type: "POST"
        });
  
        myRequest.ShouldShowSpinner = false;
        myRequest.MakeRequest().done(function (response: any) {
          dfd.resolve();
        });
        return dfd.promise();
      };
  
      if (!TDFRequest.actualReinitializeServerSessionFunction) {
        TDFRequest.actualReinitializeServerSessionFunction = Debounce(
          reInitFunciton,
          10000
        );
      }
      TDFRequest.actualReinitializeServerSessionFunction();
    }
   */
    public Settings: TDFRequestSettings;
    public ShouldShowSpinner: boolean = false;
    public spinnerContainerID: string = "body";
    public target: HTMLCollectionOf<HTMLBodyElement> | HTMLElement;
    public tdfspinner: Spinner;
    public SpinOpts: any /*  SpinnerOptions */ = this.SpinnerOptions();
    //public TokenRequired: boolean = true;
    private get TokenRequired() {
        try {
            return (
                TDFRequest.LoginPath.length > 0 &&
                this.Settings.url !== `${TDFRequest.LoginPath}/token` &&
                this.Settings.url !== `${TDFRequest.LoginPath}/refresh_token` &&
                this.Settings.url !== `${TDFRequest.LoginPath}/core/user/ForgotPassword` &&
                !this.Settings.url.startsWith(`${TDFRequest.ApiPath}/util/mits`)
            )
                ? this.Settings.url.indexOf(TDFRequest.ApiPath) > -1
                : false;

        } catch (error) {
            log(error);
        }
        return true;
    }
    /**The main defferred\promise that should not be resolved to the caller until the request has actually returned from the server (success or failure) */
    public Deferred: JQueryDeferred<any>;
    public ShowErrors: boolean = true;
    public OutsideApiRequest: boolean = false;
    public UseFormEncoding: boolean = false;
    public RefreshingToken: boolean = false;

    // This is used for FETCH REQUESTS ONLY! Used to abort facilitate aborting the current request.
    private _AbortController: AbortController;
    private get AbortController(): AbortController {
        if (!this._AbortController) {
            this._AbortController = new AbortController();
        }
        return this._AbortController;
    }

    // This used for AJAX REQUESTS ONLY! Used to abort facilitate aborting the current request.
    private xhr: JQueryXHR;

    private get UseFetch() {
        return typeof fetch !== "undefined";
    }

    public static get ApiPath(): string {
        let path = window["PATH_TO_API"] || "";

        if (window["DEBUGGING"]) {
            //console.log("Debugging is enabled. Using api path defined within config.js.");
            return path;
        } else {
            return Cookies.get('api') || path;
        }
    }

    public static get LoginPath(): string {
        let path = window["PATH_TO_API"] || "";

        if (window["DEBUGGING"]) {
            //console.log("Debugging is enabled. Using api path defined within config.js.");
            return path;
        } else {
            return Cookies.get('api') || window["LOGIN_API"] || path;
        }
    }

    constructor(args: TDFRequestSettings, ShowErrors: boolean = true, OutsideApiRequest: boolean = false) {

        if (this.UseFetch) this.Ensure_ContentType_Header(args);

        this.ShowErrors = ShowErrors;
        this.OutsideApiRequest = OutsideApiRequest;
        this.Settings = args;

        if (this.Settings.url.search(TDFRequest.LoginPath) < 0) {
            this.Ensure_Api_Path();
        } else {
            //log("Not ensuring api path for " + this.Settings.url);
        }

    }

    public MakeRequest(): JQueryPromise<any> {
        const request = this;
        //log(`Calling -- ${request.Settings.url}`);

        request.Deferred = $.Deferred();

        if (this.UseFetch) {
            request.MakeRequest_Fetch();
        } else {
            this.MakeRequest_Ajax(request);
        }

        return request.Deferred.promise();
    }

    private MakeRequest_Ajax(request: this) {
        if (request.TokenRequired) {
            request.Settings.crossDomain = true;
        }
        request.AddToken(request.Settings).done(function () {
            if (request.Settings && request.Settings.data) {
                request.Settings.data = JSON.parse(JSON.stringify(request.Settings.data));
            }
            request.xhr = $.ajax(request.Settings);

            request.xhr
                .done(function (response, status, xhr) {
                    if (request.ShouldShowSpinner) {
                        request.HideSkeleton();
                        request.StopSpinner();
                    }
                    if (response &&
                        typeof response === "string" &&
                        response.indexOf("logincontainer") > 0) {
                        if (window.location.pathname !== "/Login") {
                            window.location.replace("/Login");
                        }
                    }
                    if (xhr.getResponseHeader("CreateSessionRequest")) {
                        log("Somehow there is a header to reinitialize the server session ????");
                        //TDFRequest.ReinitializeServerSession();
                    }
                    request.Deferred.resolve(response);
                })
                .fail(function (args: JQueryXHR) {
                    if (request.ShouldShowSpinner) {
                        request.StopSpinner();
                        // TODO: Create some module that handles errors and saves them to the database ... in the toast give the user the option to report the issue... connected to the support site somehow ?
                        console.log(args);
                    }
                    if (args.status !== 401) {
                        request.Deferred.reject(args.responseJSON);
                    }
                })
                .always((response, xhr, status) => {
                    if (request.ShouldShowSpinner) {
                        request.HideSkeleton();
                        request.StopSpinner();
                    }
                    if (request.ShowErrors &&
                        response &&
                        (!response.Valid && response.Message)) {
                        new Notification({
                            message: response.Message,
                            displayTime: 10000,
                            closeOnClick: true,
                            type: "error",
                            shading: true
                        });
                    }
                    else if (request.ShowErrors &&
                        response &&
                        response.status &&
                        response.status !== 200) {
                        if (response.responseJSON && response.responseJSON.Message) {
                            new Notification({
                                message: response.responseJSON.Message,
                                displayTime: 10000,
                                closeOnClick: true,
                                type: "error",
                                shading: true
                            });
                        }
                        else if (response.responseText &&
                            typeof response.responseText === "string") {
                            new Notification({
                                message: response.responseText,
                                displayTime: 10000,
                                closeOnClick: true,
                                type: "error",
                                shading: true
                            });
                        }
                    }
                });
        });
    }

    public MakeRequest_Fetch() {
        const request = this;
        if (!request.Settings.type) {
            request.Settings.type = "GET";
        }
        const cache: RequestCache = "no-cache";
        const creds: RequestCredentials = request.OutsideApiRequest ? "omit" : "include";
        const mode: RequestMode = "cors";
        let headers: HeadersInit = {};

        // TODO: DO i need to make sure this is only for POST requests?
        headers = request.Ensure_Headers(headers);

        const info: RequestInit = {
            headers,
            method: request.Settings.type,
            cache,
            mode,
            credentials: creds,
            signal: request.AbortController.signal
        };

        request.Ensure_Data_Or_QueryString(info);

        let R: Request;

        request.AddToken(info).then(() => {
            R = new Request(`${request.Settings.url as string}`, info);

            fetch(R)
                .then(request.Validate.bind(request))
                .then(request.json)
                .then(data => {
                    if (request.ShouldShowSpinner) {
                        request.StopSpinner();
                    }
                    request.Deferred.resolve(data);
                })
                .catch(err => {
                    let error;

                    if (typeof err === "string") {
                        log("Request failed", err);
                        if ((err as any).status !== 401) {
                            //Don't reject if we get 401 so Validate function can attempt
                            //to retry and still resolve original deferred.
                            request.Deferred.reject();
                        }
                    }
                    else {
                        if (typeof err === "object" && err.status) {
                            //this is a Response
                            err.json().then(r => {
                                if (r) {
                                    if (typeof r === "object") {
                                        if (r.Message && request.ShowErrors) {
                                            new Notification({ message: r.Message, displayTime: 3e3, shading: true, type: 'error' });
                                        }
                                    }
                                    else {
                                        log(r);
                                    }
                                } else {
                                    log(err);
                                }

                                if (err.status !== 401) {
                                    //Don't reject if we get 401 so Validate function can attempt
                                    //to retry and still resolve original deferred.
                                    request.Deferred.reject(r);
                                }
                            });
                        } else {
                            log(err);

                            if (err.status !== 401) {
                                //Don't reject if we get 401 so Validate function can attempt
                                //to retry and still resolve original deferred.
                                request.Deferred.reject();
                            }
                        }
                    }

                })
                .finally(() => {
                    if (request.ShouldShowSpinner) {
                        request.StopSpinner();
                    }
                });
        });
    }

    public AbortRequest(): void {
        if (this.UseFetch) {
            this.AbortController.abort();
        } else {
            this.xhr.abort();
        }
    }

    public AddToken(info) {
        const request = this;
        const dfd = $.Deferred();
        if (request.TokenRequired) {
            request.GetToken().done(function (token) {
                request.AddAuthHeader(info, token);
                request.Ensure_Spinner();
                dfd.resolve();
            });
        } else {
            log("Token Not Required")
            request.Ensure_Spinner()
            return dfd.promise(dfd.resolve());
        }
        return dfd.promise();
    }

    public tokenAlmostExpired() {

        // Check the expiration of the "access" cookie; if it is missing, or present...

        //return false;

        var expirationDate = Cookies.get("token_expires");

        if (expirationDate) return Date.now() >= (parseInt(expirationDate) - 60000); // are we within a minute?

        log("Token Not Expired")
        return false;

    }

    public GetToken() {
        let request = this;
        let dfd = $.Deferred();

        TDFRequest.GetTokenFromCookie().then(token => {
            if (!token) {
                log('No Token In Cookie')
                token = this.Check_Query(token);
            }

            if (token) {
                if (request.tokenAlmostExpired() === true) {
                    log('Token Almost Expired')
                    token = null;
                }
            }

            if (token) {
                dfd.resolve(token);
            } else {
                request.GetNewToken().done(function (token) {
                    dfd.resolve(token);
                });
            }
        });
        return dfd.promise();
    }

    public static GetTokenFromCookie(): JQueryPromise<string> {
        //Use this in order to check if we are currently trying to refresh token.
        if (Window['RefreshingToken'] !== undefined) {
            //If we are, hold all requests until the request refreshing our token is complete.
            //This is ultimately resolved in GetNewToken().
            return Window['RefreshingToken'].promise();
        } else {
            let d = $.Deferred();
            return d.promise(d.resolve(Cookies.get('access')));
        }
    }

    public GetNewToken() {
        let request = this;
        let dfd = $.Deferred();
        let refresh_token = Cookies.get("refresh_token");
        if (refresh_token) {
            if (!request.RefreshingToken) {
                log('Trying Refresh Token')
                request.UseRefreshToken(refresh_token)
                    .done((tokn) => {
                        dfd.resolve(tokn);
                        Window['RefreshingToken'].resolve(tokn);
                    })
                    .fail((ex) => {
                        log("Refresh Token Failed");

                        GetLoginForm().done(tokn => {
                            TDFRequest.SaveAuthDetails(tokn)
                            dfd.resolve(tokn.access_token);
                            Window['RefreshingToken'].resolve(tokn.access_token);
                        });
                    });
            } else {
                dfd.reject();
            }
        } else {
            log('Loading Logon Form');
            GetLoginForm().done(tokn => {
                TDFRequest.SaveAuthDetails(tokn)
                dfd.resolve(tokn.access_token);

                // TODO: This is a problem. Refreshing the page too early in this process.
                location.href = location.href;
            });
        }
        return dfd.promise();
    }

    public UseRefreshToken(refresh_token: string) {
        let dfd = $.Deferred();
        var apiurl = TDFRequest.LoginPath + "/refresh_token";

        Window['RefreshingToken'] = $.Deferred();

        var data = {
            grant_type: 'refresh_token',
            refresh_token: refresh_token
        };

        var settings: JQueryAjaxSettings = { url: apiurl, type: "POST", data: data };

        var z = new TDFRequest(settings, true);
        z.RefreshingToken = true;
        z.MakeRequest()
            .done((e) => {
                if (e.access_token) {
                    log('Success Refreshed Token');
                    // Save cookie values.
                    TDFRequest.SaveAuthDetails(e);
                    dfd.resolve(e.access_token);
                } else {
                    dfd.reject();
                }
                // Success looks like: 200
                //{
                //  "access_token": "",
                //    "token_type": "bearer",
                //      "expires_in": 86399,
                //        "refresh_token": "9505a27d-680f-42d0-bf99-02ad3050587b"
                //}

                // Failure looks like: 400
                //{
                //  "error": "invalid_grant"
                //}

            })
            .fail(e => {
                log(e);
                dfd.reject();
            });
        return dfd.promise();
    }

    public Validate(res: Response): any {
        let request = this;
        let d = $.Deferred();
        if (res.ok) {
            if (request.ShouldShowSpinner) {
                request.HideSkeleton();
                request.StopSpinner();
            }
            return d.promise(d.resolve(res));

        } else {
            if (request.ShouldShowSpinner) {
                request.StopSpinner();
            }
            if (res.status === 401) {
                log('Recieved 401')
                let refresh_token = Cookies.get("refresh_token");
                if (refresh_token && !request.RefreshingToken) {
                    log('Trying Refresh Token')
                    //Refresh token or re-log in then attempt request again
                    request.UseRefreshToken(refresh_token)
                        .done(tokn => {
                            Window['RefreshingToken'].resolve(tokn);
                            //Need to call _Fetch or _Ajax because MakeRequest will create
                            //new deferred and break our chain
                            if (request.UseFetch) {
                                request.MakeRequest_Fetch();
                            } else {
                                request.MakeRequest_Ajax(request);
                            }
                        })
                        .fail(() => {
                            GetLoginForm().done(tokn => {
                                TDFRequest.SaveAuthDetails(tokn);
                                Window['RefreshingToken'].resolve(tokn.access_token);
                                if (request.UseFetch) {
                                    request.MakeRequest_Fetch();
                                } else {
                                    request.MakeRequest_Ajax(request);
                                }
                            });
                        });
                }
                log("401 And No Refresh Token")
                return d.promise();


            } else {
                return d.promise(d.reject(res));
            }
        }
    }

    public json(response: Response) {
        let json;
        try {
            const ty = response.headers.get("Content-Type");
            if (ty && ty.search(/json/i) > -1) {
                json = response.json();
            } else {
                json = response.text();
            }
        } catch (e) {
            log(e);
        } finally {
            if (json) {
                return json;
            } else {
                return "";
            }
        }
    }

    public GetQueryString(data: any) {
        const request = this;
        data = JSON.stringify(data);
        data = JSON.parse(data);
        return Object.keys(data)
            .map(k => request.GetStringParams(k, data[k]))
            .filter(item => {
                return typeof item !== "undefined";
            })
            .join("&");
    }

    public GetStringParams(k, d) {
        let request = this;
        let esc = encodeURIComponent;
        if (typeof d !== "undefined" && typeof d !== "object") {
            return `${esc(k)}=${esc(d)}`;
        } else {
            if (typeof d !== "undefined" && d)
                if ($.isArray(d)) {
                    return $.param({ [k]: d });
                } else {
                    return Object.keys(d)
                        .map(i => request.GetStringParams(i, d[i]))
                        .filter(item => {
                            return typeof item !== "undefined";
                        })
                        .join("&");
                }
        }
        return "";
    }

    public LoadSpinner() {
        var request = this;

        if (request.spinnerContainerID === "body") {
            request.target = document.getElementsByTagName(
                request.spinnerContainerID
            );
        } else {
            request.target = document.getElementById(
                request.spinnerContainerID
            ) as HTMLElement;
        }
        if (typeof request.tdfspinner === "undefined") {
            if ($(request.target).length > 0) {
                $(request.target); //.css({ "background-color": "rgba(30, 30, 30, 0.73)" });
            }
            request.tdfspinner = new Spinner(request.SpinOpts).spin();
            $(request.target).append(request.tdfspinner.el as HTMLElement);
        } else {
            if ($(request.target).length > 0) {
                $(request.target); //.css("background-color", "rgba(30, 30, 30, 0.73)");
            }
            request.tdfspinner.spin();
        }
    }

    public StopSpinner() {
        const request = this;
        if (typeof request.tdfspinner !== "undefined") {
            request.tdfspinner.stop();
            $(request.target); // .css("background-color", "");
        }
        setTimeout(function () {
            try {
                $(request.target); // .css("background-color", "");
            } catch (e) {
                alert(e.message);
            }
        },
            2000);
    }

    public HideSkeleton() {
        const request = this;
        const skeletonContainer = request.spinnerContainerID + "-skeleton";
        $("#" + skeletonContainer).hide();
    }

    public SpinnerOptions(): any /* SpinnerOptions */ {
        const request = this;
        const options: any /*  SpinnerOptions */ = {};
        // var length = request.spinnersize && request.spinnersize === 'small' ? 30 : 50;
        // var scale = request.spinnersize && request.spinnersize === 'small' ? .6 : 1;
        options.lines = 18; // The number of lines to draw
        options.length = 30; // The length of each line
        options.width = 8; // The line thickness
        options.radius = 20; // The radius of the inner circle
        options.scale = 0.5; // Scales overall size of the spinner
        options.corners = 1; // Corner roundness (0..1)
        options.color = ["#f0ad4e", "#5bc0de", "#f2f2f2"]; // '#000' // #rgb or #rrggbb or array of colors
        //    options.opacity = 0.001;            // Opacity of the lines
        options.rotate = 0; // The rotation offset
        options.direction = 1; // 1= clockwise; -1= counterclockwise
        options.speed = 2; // Rounds per second
        //     options.trail = 100;               // Afterglow percentage
        //     options.fps = 20;                  // Frames per second when using setTimeout() as a fallback for CSS
        options.zIndex = 2e9; // The z-index (defaults to 2000000000)
        options.className = "spinner"; // The CSS class to assign to the spinner
        // options.top = (window.scrollY) + window.innerHeight / 2 + "px";      //'350px' // Top position relative to parent
        // options.left = '50%';              // Left position relative to parent
        options.shadow = true; // Whether to render a shadow
        //     options.hwaccel = false;           // Whether to use hardware acceleration
        // options.position = 'absolute';     // Element positioning

        return options;
    }

    public static SaveAuthDetails(res: AuthDetails) {

        // ' { access_token:"", refresh_token: "", expires_in: 86400 }'

        Cookies.set('access', res.access_token, {
            expires: new Date(Date.now() + (res.expires_in * 1000))
        });

        Cookies.set('refresh_token', res.refresh_token, {
            expires: 365
        });

        // We want the value of the cookie to be the unix timestamp (in ms) of when the access_token will expire.
        // The actual expiration of this cookie is the same as the access_token itself.
        Cookies.set('token_expires', (Date.now() + (res.expires_in * 1000)).toString(), {
            expires: new Date(Date.now() + (res.expires_in * 1000))
        });

    }

    private Ensure_Api_Path() {
        if (!this.OutsideApiRequest && this.Settings.url.search(TDFRequest.ApiPath) < 0) {

            // Note we assume that the Settings.url either already contains the ApiPath,
            // or is RELATIVE to the API path.

            if (this.Settings.url[0] != '/') {
                this.Settings.url = '/' + this.Settings.url;
            }

            this.Settings.url = `${TDFRequest.ApiPath}${this.Settings.url}`;
        }
    }

    private Ensure_ContentType_Header(args: TDFRequestSettings) {
        if (args.type && args.type.toUpperCase() !== "GET" && args.data) {
            if (!args.contentType) {
                args.contentType = "application/json";
                args.processData = false;
            }
            if (args.data && typeof args.data !== "string") {
                args.data = JSON.stringify(args.data);
            }
        }
    }
    private Ensure_Data_Or_QueryString(info: RequestInit) {
        let request = this;
        if (request.Settings.type.toUpperCase() !== "GET") {
            info.body = typeof request.Settings.data === "string" ? request.Settings.data : JSON.stringify(request.Settings.data);
        }
        else {
            if (request.Settings.data &&
                request.Settings.type &&
                request.Settings.type.toUpperCase() === "GET") {
                request.Settings.url = request.Settings.url + "?" + request.GetQueryString(request.Settings.data);
                info.headers["Access-Control-Request-Headers"] = "Service-Worker-Allowed";
                info.headers["Service-Worker-Allowed"] = "true";
            }
        }
    }

    private Ensure_Headers(headers: HeadersInit) {
        let request = this
        if (request.Settings.data) {
            headers = {
                "Content-Type": "application/json",
                Accept: "*/*"
            };
        }
        if (request.UseFormEncoding === true) {
            headers = {
                "Content-Type": "application/x-www-form-urlencoded",
                Accept: "*/*"
            };
        }
        // TODO: IF this is to remain as a true client i think this will always be the case
        request.TokenRequired ? "" : (headers["Service-Worker-Allowed"] = "true");

        return headers;
    }

    private Ensure_Spinner() {
        let request = this;
        if (request.spinnerContainerID)
            request.ShouldShowSpinner = true;
        if (request.ShouldShowSpinner) {
            request.LoadSpinner();
        }
    }

    private AddAuthHeader(info: any, token: any) {
        if (!info.headers) {
            info.headers = {
                AUTHORIZATION: `Bearer ${token}`
            };
        }
        else {
            info.headers.AUTHORIZATION = `Bearer ${token}`;
        }
    }

    private Check_Query(token: string) {
        token = GetQueryStringParam("token") ||
            GetQueryStringParam("access_token") ||
            GetQueryStringParam("TDFAuthToken");

        if (token) {
            //The token was given in the query .. this is propbably in an iframe ...
            Cookies.set("access", token);
            window.location.href = window.location.href
                .replace(`access_token=${token}`, "")
                .replace(`TDFAuthToken=${token}`, "")
                .replace(`token=${token}`, "")
                .replace("?&", "?");
        }
        else {
            log('No Token In Query String');
        }
        return token;
    }

    public static GetApiLink(relativePath: string, queryParams?: any): string {

        if (!queryParams) queryParams = {};

        queryParams["access_token"] = Cookies.get("access");

        if (relativePath.indexOf("?") > -1) {

            let z = new URLSearchParams('?' + relativePath.split("?")[1])
            z.forEach((v, k, p) => {
                queryParams[k] = v;
            });

            relativePath = relativePath.split("?")[0];

        }

        return `${TDFRequest.ApiPath}${relativePath}?${$.param(queryParams)}`

    }

    public MultiPartUpload(formData: FormData): JQueryPromise<any> {

        const request = this;
        log("Calling " + request.Settings.url);

        request.Deferred = $.Deferred();

        let xr = new XMLHttpRequest();
        xr.open(request.Settings.type, request.Settings.url, true);
        xr.withCredentials = true;
        xr.setRequestHeader('Accept', '*/*');

        xr.onprogress = (event => {

            try {
                let msg: any = JSON.parse(xr.response);
                new Notification({
                    message: msg.Message.replace(/\"/g, ''),
                    type: xr.status === 200 ? 'success' : 'error',
                    displayTime: 2e3
                });
            } catch (e) {
                console.warn(e);
            }

        });

        request.GetToken().done((token) => {
            xr.setRequestHeader('AUTHORIZATION', `Bearer ${token}`);
            xr.send(formData);
        })

        return request.Deferred.promise();

    }

}

export interface AuthDetails {
    access_token: string,
    api_path: string,
    expires_in: number
    refresh_token: string,
    token_type: string
}