

export function ToolbarTemplateFactory(location: "after" | "before" | "center", inMenu: "always" | "auto" | "never", disable: boolean, options = null, widget = null, template = null, visible = true, showtext = "inMenu") {
    return <DevExpress.ui.dxToolbarItemTemplate>{
        location: location,
        locateInMenu: inMenu,
        disabled: disable,
        options: options,
        showText: showtext,
        widget: widget,
        template: template,
        menuItemTemplate: template,
        visible: visible
    }
}
