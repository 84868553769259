
				export enum EnumGridOptionsColumn {
					AutoResize = 1,
					ExpandAllGroups = 8,
					QuickGridMode = 16

				}

				export enum EnumGridOptionsToolbar {
					CanPrint = 1,
					CanExport = 2,
					CanForward = 4,
					CanNewActivity = 8,
					CanNewAppointment = 16,
					CanNewTask = 32
				}

				//export enum EnumGridOptionsShowItems {
				//    Account = 1,
				//    Contact = 2,
				//    Contract = 4,
				//    "Event" = 8,
				//    Opportunity = 16,
				//    Support = 32,
				//    Archive = 64,
				//    Literature = 128,
				//    Lead = 256,
				//    Quote = 512
				//}

				export enum EnumGridOptionsTDFActive {
					Active = 1,
					Inactive = 2
				}

				export enum DateFilterCombinerEnum {
					And = 0,
					Or = 1,
					NotAnd = 2,
					NotOr = 3,
					Custom = 4
				}

				export enum EnumRangeType {
					Fixed,
					Relative,
					Predefined,
					RelativeOpenEnded
				}

				export enum EnumRangeUnits {
					Day,
					Week,
					Month
				}

				export enum EnumOpenEnded {
					AllFuture,
					AllPast
				}

				export enum EnumQuickRanges {
					ThisPeriod = 0,
					ThisQuarter = 1,
					ThisYear = 2,
					LastPeriod = 3,
					LastQuarter = 4,
					LastYear = 5,
					NextPeriod = 6,
					NextQuarter = 7,
					NextYear = 8,
					Today = 9,
					ThisWeek = 10,
					LastWeek = 11,
					NextWeek = 12,
					Yesterday = 13,
					TwoYearsAgo = 14,
					BlankOrNull = 15,
					LastYTD = 16,
					LastYMTD = 17,
					LastYQTD = 18,
					LastYearDay = 19,
					TwoYTD = 20,
					TwoMTD = 21,
					TwoQTD = 22,
					TwoDay = 23,
					Tomorrow = 24,
					BSIMostRecent = 25
				}

				export enum EnumTDFAccountType {
					Master = 1,
					Regular = 2,
					SalesOnly = 4,
					Vendor = 8,
					Custom01 = 16,
					Custom02 = 32,
					Custom03 = 64,
					Custom04 = 128,
					Custom05 = 256
				}
				export enum enumGridDesign {
					SaveState = 1
				}