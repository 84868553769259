import { itemTypes } from "../../enums/enums";
import { ItemBase } from "./itembase";
import { DisplayInfo } from "../../infrastructure/context";

 
    export class KnowledgeBase extends ItemBase {
        DisplayName :string;
        get TypeName() { return "KnowledgeBase"; }
        set TypeName(name) { if (name && this.TypeName !== name) this.TypeName = name; }
        get ItemType() { return itemTypes.itemKnowledgeBase; }
        get RequiresParentItem(): boolean { return true; }
        constructor(args) {
            super(args);
            let KB = this;
            $.each(args, function (key, val) {
                if (KB.hasOwnProperty(key)) KB[key] = val;
            });
           
            if (!KB.DisplayName) {

               DisplayInfo(KB.ItemType).done(function (displayinfo) {
                    KB.DisplayName = displayinfo.DisplayName;
                    KB.TypeName = displayinfo.TypeName;
                })
            }

        }
    }
