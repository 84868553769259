import { Grid } from "./grid";
import { IGridDataResponse } from "../interfaces/interfaces";

export class EditGrid extends Grid {
  constructor(
    args: IGridDataResponse,
    gridargs,
    options?: DevExpress.ui.dxDataGridOptions,
    objRef?: any
  ) {
    super(args, gridargs, options, objRef);
  }

  DefaultOptions() {
    let theGrid = this;
    let t: DevExpress.ui.dxDataGridOptions = {
      showColumnLines: false,
      showRowLines: false,
      columnResizingMode: "widget",
      columnFixing: { enabled: true },
      allowColumnResizing: true,
      rowAlternationEnabled: true,
      showBorders: true,
      scrolling: {
        mode: "virtual"
      },
      export: {
        // TODO: Get Export Permission Logic in here
        enabled: true,
        allowExportSelectedData: true
      },
      selection: {
        mode: "multiple"
      },
      groupPanel: {
        visible: true
      },
      filterRow: {
        visible: true,
        applyFilter: "auto"
      },
      searchPanel: {
        visible: true,
        width: 240,
        placeholder: "Search..."
      },
      headerFilter: {
        visible: true
      },
      height: $(window).innerHeight() - 110,
      onContextMenuPreparing: function(e: any) {
        if (e.row && e.row.rowType === "data") {
          let t: MouseEvent = e.jQueryEvent.originalEvent;
          t.preventDefault();
          theGrid.ContextMenu(t.target, e.row.data);
          return;
        }
      },
      onToolbarPreparing: function(e) {},

      wordWrapEnabled: true,
      onSelectionChanged: function(selectedItems) {}
    };
    return t;
  }
}
