import { IGridView } from '../../../interfaces/grids/interfaces';
import { ISortList } from '../../../components/grids/datacentergrid';
import { TDFRequest } from '../../request';
import { itemTypes, CenterType } from '../../../enums/enums';
import { BSIGrids, BSIGoalType } from '../../../enums/bi/enums';
import { GroupSummaryOptions } from '../../../interfaces/bi/interfaces';
import { GetPreference } from '../../../infrastructure/user/getpreference';
import { Preferences } from '../../../infrastructure/user/preferences';
import { OppType } from 'components/event/eventsummarydrilldown';

export namespace GridSetup {
  export interface OptionalGridViewMeta {
    ItemType: number;
    TDFContainerType: number;
    Fields: string[];
    DisplayFields: string[];
  }

  export class GridRequestArgs {
    // private readonly View:IGridView
    constructor(view?: IGridView) {
      let args = this;
      if (view) {
        Object.keys(view).forEach((v, k) => {
          if (
            typeof args[v] !== 'undefined' &&
            typeof view[v] !== 'undefined'
          ) {
            args[v] = view[v];
          }
        });
      }
    }

    // private _Path?: string = "";
    private _IncludeGridViewInformation?: boolean = true;
    private _ViewID?: string;
    private _ContainerID?: string = '';
    private _page?: number = 1;
    private _pageSize?: number = parseInt(
      Preferences.GetPreference('ServerPageSize', 'TDFMobile')
    );
    private _ItemType?: itemTypes | BSIGrids;
    private _filterExpression?: string = '';
    private _groupExpression?: string = '';
    private _groupDirection?: string = '';
    private _extraFields?: { dataField: string; visibleIndex: number }[] = [];
    private _OptionalGridViewMeta?: OptionalGridViewMeta;
    private _Sorts: ISortList[];
    private _IgnoredSorts: string[];
    get IncludeGridViewInformation() {
      if (typeof this._IncludeGridViewInformation !== 'undefined')
        return this._IncludeGridViewInformation;
      return true;
    }
    set IncludeGridViewInformation(inc) {
      if (typeof inc !== 'undefined') this._IncludeGridViewInformation = inc;
    }
    get GUID() {
      if (this._ViewID) return this._ViewID;
      return '';
    }
    set GUID(val: string) {
      if (typeof val !== 'undefined') this._ViewID = val;
    }
    get ContainerID() {
      if (this._ContainerID) return this._ContainerID;
      return '';
    }
    set ContainerID(val) {
      if (typeof val !== 'undefined') this._ContainerID = val;
    }
    get page() {
      if (this._page) return this._page;
      return 1;
    }
    set page(page: number) {
      if (page) this._page = page;
    }
    get pageSize() {
      if (this._pageSize) return this._pageSize;
      this.pageSize = parseInt(
        Preferences.GetPreference('ServerPageSize', 'TDFMobile')
      );
    }
    set pageSize(size) {
      if (size) this._pageSize = size;
    }
    get ItemType(): itemTypes | BSIGrids {
      return this._ItemType;
    }
    set ItemType(val: itemTypes | BSIGrids) {
      if (val) this._ItemType = val;
    }
    get Sorts(): ISortList[] {
      if (this._Sorts) return this._Sorts;
      return [];
    }
    set Sorts(val: ISortList[]) {
      if (val) this._Sorts = val;
    }
    get IgnoredSorts(): string[] {
      if (this._IgnoredSorts) return this._IgnoredSorts;
      return null;
    }
    set IgnoredSorts(val: string[]) {
      if (val) this._IgnoredSorts = val;
    }
    get filterExpression(): string {
      if (this._filterExpression) return this._filterExpression;
      return '';
    }
    set filterExpression(val: string) {
      if (typeof val !== 'undefined') this._filterExpression = val;
    }
    get groupExpression(): string {
      if (this._groupExpression) return this._groupExpression;
      return '';
    }
    set groupExpression(val: string) {
      if (typeof val !== 'undefined') this._groupExpression = val;
    }
    get groupDirection(): string {
      if (this._groupDirection) return this._groupDirection;
      return '';
    }
    set groupDirection(val: string) {
      if (typeof val !== 'undefined') this._groupDirection = val;
    }
    get extraFields(): { dataField: string; visibleIndex: number }[] {
      if (this._extraFields) return this._extraFields;
      return [];
    }
    set extraFields(val: { dataField: string; visibleIndex: number }[]) {
      if (typeof val !== 'undefined') this._extraFields = val;
    }
    get OptionalGridViewMeta(): OptionalGridViewMeta {
      if (this._OptionalGridViewMeta) return this._OptionalGridViewMeta;
      return null;
    }
    set OptionalGridViewMeta(val: OptionalGridViewMeta) {
      if (val) this._OptionalGridViewMeta = val;
    }

    toJSON(key) {
      let theArgs = this;
      let it = {};
      Object.keys(theArgs).map((v, k) => {
        it[v.replace('_', '')] = theArgs[v];
      });
      return it;
    }
  }

  export abstract class BaseGridRequestModel {
    abstract RequestArgs: GridRequestArgs;
    abstract Path: string;
    ClearFilters_Sorting_AndResetPage() {
      let model = this;

      model.RequestArgs.filterExpression = '';
      model.RequestArgs.Sorts = [];
      model.RequestArgs.page = 1;
    }
    constructor() {
      let model = this;
      model.RequestArgs = new GridSetup.GridRequestArgs();
    }
  }

  export class RecentRequestArgs extends GridRequestArgs {
    private _ItemTypeFilter?: itemTypes = itemTypes.itemAllItems;
    public get ItemTypeFilter(): itemTypes {
      return this._ItemTypeFilter;
    }
    public set ItemTypeFilter(value: itemTypes) {
      this._ItemTypeFilter = value;
    }

    private _allowedTypes?: itemTypes[] = null;

    public get AllowedTypes(): itemTypes[] {
      return this._allowedTypes;
    }

    public set AllowedTypes(value: itemTypes[]) {
      this._allowedTypes = value;
    }

  }

  export class RecentItemsRequestModel extends BaseGridRequestModel {
    RequestArgs: RecentRequestArgs;
    readonly Path: string = TDFRequest.ApiPath + '/GridData/RecentItems/';

    constructor(args: RecentRequestArgs) {
      super();
      this.RequestArgs = args;
    }
  }

  export class FavoriteRequestArgs extends RecentRequestArgs { }

  export class FavoritesRequestModel extends BaseGridRequestModel {
    RequestArgs: RecentRequestArgs;
    readonly Path: string = TDFRequest.ApiPath + '/GridData/Favorites/';

    constructor(args: FavoriteRequestArgs) {
      super();
      this.RequestArgs = args;
    }
  }

  export class SearchRequestArgs extends GridRequestArgs {
    private _SearchField?: string;
    public get SearchField(): string {
      return this._SearchField;
    }
    public set SearchField(value: string) {
      this._SearchField = value;
    }
    private _SearchString?: string;
    public get SearchString(): string {
      return this._SearchString;
    }
    public set SearchString(value: string) {
      this._SearchString = value;
    }
  }

  export class SearchRequestModel extends BaseGridRequestModel {
    RequestArgs: SearchRequestArgs;
    readonly Path: string = TDFRequest.ApiPath + '/GridData/Search/';

    constructor(args: SearchRequestArgs) {
      super();
      this.RequestArgs = args;
    }
  }

  export class InfoCenterRequestModel extends BaseGridRequestModel {
    RequestArgs: GridRequestArgs;
    readonly Path: string = TDFRequest.ApiPath + '/GridData/InfoCenter/';

    constructor(args: GridRequestArgs) {
      super();
      this.RequestArgs = args;
    }
  }

  export class LinkedItemsRequestModel extends BaseGridRequestModel {
    RequestArgs: GridRequestArgs;
    readonly Path: string = TDFRequest.ApiPath + '/GridData/LinkedItems/';
    constructor(args: GridRequestArgs) {
      super();
      this.RequestArgs = args;
    }
  }

  export class ExpenseWizardRequestArgs extends GridRequestArgs {
    private _LoadForOwner?: string = '';

    get LoadForOwner(): string {
      return this._LoadForOwner;
    }
    set LoadForOwner(val: string) {
      this._LoadForOwner = val;
    }
  }

  export class ExpenseWizardRequestModel extends BaseGridRequestModel {
    readonly Path: string =
      TDFRequest.ApiPath + '/GridData/ExpenseWizard/Expenses/';
    RequestArgs: ExpenseWizardRequestArgs;

    constructor(args: ExpenseWizardRequestArgs) {
      super();
      this.RequestArgs = args;
    }
  }

  export class BIRequestArgs extends GridRequestArgs {
    constructor(view?: IGridView) {
      super(view);
      if (view) {
        Object.keys(view).forEach((v, k) => {
          if (this.hasOwnProperty(v) && typeof view[v] !== 'undefined') {
            this[v] = view[v];
          }
        });
      }
    }
    private _BISummaryType?: number = -1;
    private _OptionalGroupSummaryOptions?: GroupSummaryOptions;
    private _GoalType?: BSIGoalType;

    get GoalType(): BSIGoalType {
      return this._GoalType;
    }
    set GoalType(val: BSIGoalType) {
      this._GoalType = val;
    }

    get OptionalGroupSummaryOptions(): GroupSummaryOptions {
      return this._OptionalGroupSummaryOptions;
    }
    set OptionalGroupSummaryOptions(val: GroupSummaryOptions) {
      this._OptionalGroupSummaryOptions = val;
    }

    get BISummaryType(): number {
      return this._BISummaryType;
    }
    set BISummaryType(val: number) {
      this._BISummaryType = val;
    }
  }

  export class BIRequestModel extends BaseGridRequestModel {
    readonly Path: string = TDFRequest.ApiPath + '/GridData/BI/';
    RequestArgs: BIRequestArgs;

    constructor(args: BIRequestArgs) {
      super();
      if (args.GUID === 'SalesSummary') {
        this.Path = '/BI/SalesSummary';
      }
      this.RequestArgs = args;
      this.RequestArgs.OptionalGroupSummaryOptions =
        args.OptionalGroupSummaryOptions || {};
    }
  }

  export class BIDrillRequestArgs extends BIRequestArgs {
    private _DataDrillSettings?: any;

    get DataDrillSettings(): any {
      return this._DataDrillSettings;
    }

    set DataDrillSettings(val: any) {
      this._DataDrillSettings = val;
    }
  }

  export class BIDrillDataRequestModel extends BIRequestModel {
    readonly Path: string = TDFRequest.ApiPath + '/GridData/BIDrillData/';
    RequestArgs: BIDrillRequestArgs;
    constructor(args: BIDrillRequestArgs) {
      super(args);
      this.RequestArgs.DataDrillSettings = args.DataDrillSettings || {};
    }
  }

  export class ArbitraryGridRequestArgs extends GridRequestArgs {
    QueryID?: number;
  }

  export class ArbitraryGridDataRequestModel extends BaseGridRequestModel {
    readonly Path: string = TDFRequest.ApiPath + '/GridData/ArbitraryQuery/';
    RequestArgs: ArbitraryGridRequestArgs;
    constructor(args: ArbitraryGridRequestArgs) {
      super();
      this.RequestArgs = args;
    }
  }

  export class QuoteManagerRequestArgs extends GridRequestArgs {
    private _FilterEndDate?: string;
    public get FilterEndDate(): string {
      return this._FilterEndDate;
    }
    public set FilterEndDate(value: string) {
      this._FilterEndDate = value;
    }
    private _FilterStartDate?: string;
    public get FilterStartDate(): string {
      return this._FilterStartDate;
    }
    public set FilterStartDate(value: string) {
      this._FilterStartDate = value;
    }
    private _MyQuotesOnly?: boolean;
    public get MyQuotesOnly(): boolean {
      return this._MyQuotesOnly;
    }
    public set MyQuotesOnly(value: boolean) {
      this._MyQuotesOnly = value;
    }
    private _ParentID?: string;
    public get ParentID(): string {
      return this._ParentID;
    }
    public set ParentID(value: string) {
      this._ParentID = value;
    }
    private _SelectedParent?: boolean;
    public get SelectedParent(): boolean {
      return this._SelectedParent;
    }
    public set SelectedParent(value: boolean) {
      this._SelectedParent = value;
    }
    private _TDFKeyFilter?: string;
    public get TDFKeyFilter(): string {
      return this._TDFKeyFilter;
    }
    public set TDFKeyFilter(value: string) {
      this._TDFKeyFilter = value;
    }
  }

  export class QuoteManagerRequestModel extends BaseGridRequestModel {
    readonly Path: string = TDFRequest.ApiPath + '/GridData/QuoteManager/';
    RequestArgs: QuoteManagerRequestArgs;

    constructor(args: QuoteManagerRequestArgs) {
      super();
      this.RequestArgs = args;
      //  this.RequestArgs.OptionalQuoteViewMeta = args.OptionalQuoteViewMeta || {};
    }
  }

  export class EventRequestArgs extends GridRequestArgs {
    private _EventID: string;
    public get EventID(): string {
      return this._EventID;
    }
    public set EventID(value: string) {
      this._EventID = value;
    }
  }

  export class EventDrillDownRequestArgs extends EventRequestArgs {
    private _OppType: OppType;
    public get OppType(): OppType {
      return this._OppType;
    }
    public set OppType(value: OppType) {
      this._OppType = value;
    }
  }

  export class InvitationListRequestModel extends BaseGridRequestModel {
    readonly Path: string = 'GridData/InvitationList';
    RequestArgs: EventRequestArgs;

    constructor(args: EventRequestArgs) {
      super();
      this.RequestArgs = args;
    }
  }

  export class EventDrillDownRequestModel extends BaseGridRequestModel {
    readonly Path: string = 'GridData/EventDrillDown';
    RequestArgs: EventDrillDownRequestArgs;

    constructor(args: EventDrillDownRequestArgs) {
      super();
      this.RequestArgs = args;
    }
  }

  export function GridRequestFactory(
    Type: any /* CenterType |itemTypes */,
    args:
      | GridRequestArgs
      | BIRequestArgs
      | ExpenseWizardRequestArgs
      | QuoteManagerRequestArgs
      | RecentRequestArgs
      | FavoriteRequestArgs
      | SearchRequestArgs
      | EventRequestArgs
  ) {
    switch (Type) {
      case CenterType.InfoCenter:
        return new InfoCenterRequestModel(args);
      case CenterType.Bi:
      case CenterType.AccountBi:
      case CenterType.ContactBi:
      case CenterType.OppBi:
      case CenterType.VendorBi:
      case CenterType.GapAnalysis:
      case CenterType.GenericGroupSummary:
      case CenterType.InventorySummary:
        return new BIRequestModel(args as BIRequestArgs);
      case CenterType.General:
      case CenterType.LinkedItems:
        return new LinkedItemsRequestModel(args);
      case itemTypes.itemRecentItem:
        return new RecentItemsRequestModel(args as RecentRequestArgs);
      case itemTypes.itemFavorite:
        return new FavoritesRequestModel(args as FavoriteRequestArgs);
      case CenterType.EventInvitation:
        return new InvitationListRequestModel(args as EventRequestArgs);
      case CenterType.EventDrillDown:
        return new EventDrillDownRequestModel(args as EventDrillDownRequestArgs);


      default:
        return new ArbitraryGridDataRequestModel(args);

      //return new QuoteManagerRequestModel(args)
      //return new ExpenseWizardRequestModel(args)
      //return new BIDrillDataRequestModel(args);
    }
  }

  export function GridRequestArgsFactory(
    Type: any /* CenterType |itemTypes */,
    View?: IGridView
  ) {
    switch (Type) {
      case CenterType.InfoCenter:
      case CenterType.General:
      case CenterType.LinkedItems:
        return new GridRequestArgs(View);
      case CenterType.Bi:
      case CenterType.AccountBi:
      case CenterType.ContactBi:
      case CenterType.OppBi:
      case CenterType.VendorBi:
      case CenterType.GapAnalysis:
      case CenterType.GenericGroupSummary:
      case CenterType.InventorySummary:
        return new BIRequestArgs(View);
      case itemTypes.itemRecentItem:
        return new RecentRequestArgs();
      case itemTypes.itemFavorite:
        return new FavoriteRequestArgs();
      case CenterType.EventInvitation:
        return new EventRequestArgs();
      case CenterType.EventDrillDown:
        return new EventDrillDownRequestArgs();

      default:
        return new ArbitraryGridRequestArgs();
    }
  }
}
