import { itemTypes } from "../../enums/enums";

import { ItemBase } from "./itembase";
import { DisplayInfo } from "../../infrastructure/context";
export class Support extends ItemBase {
  DisplayName: string;
  get ItemType() {
    return itemTypes.itemSupport;
  }
  get TypeName() {
    return "Support";
  }
  set TypeName(name) {
    if (name && this.TypeName !== name) this.TypeName = name;
  }
  get RequiresParentItem(): boolean {
    return true;
  }
  constructor(args) {
    super(args);
    let theSupport = this;
    $.each(args, function(key, val) {
      if (theSupport.hasOwnProperty(key)) theSupport[key] = val;
    });

    if (!theSupport.DisplayName) {
      DisplayInfo(theSupport.ItemType).done(function(displayinfo) {
        theSupport.DisplayName = displayinfo.DisplayName;
        theSupport.TypeName = displayinfo.TypeName;
      });
    }
  }
}
