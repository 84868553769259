import { CalculationMethodType, itemTypes } from "../../enums/enums";
import * as Globalize from "globalize";

import { ItemListingNameModel } from "../../interfaces/interfaces";

import { ItemBase } from "./itembase";
import { DomSafeID } from "../../util/allutils";
import { DisplayInfo, CurrentUser } from "../../infrastructure/context";
import { OppBIDataCenter } from '../../datacenters/bidatacenters/bi_data_center_opp';

export class Opportunity extends ItemBase {
  DisplayName: string;
  get ItemType() {
    return itemTypes.itemOpportunity;
  }
  get TypeName() {
    return "Opportunity";
  }
  set TypeName(name) {
    if (name && this.TypeName !== name) this.TypeName = name;
  }
  get RequiresParentItem(): boolean {
    return true;
  }
  /**
   * This will be the control that has the TDFField "Opportunity"
   */
  OpportunityValue?: DevExpress.ui.Widget;
  CalculationMethod?: DevExpress.ui.Widget;
  ProbableValue?: DevExpress.ui.Widget;
  ForecastValue?: DevExpress.ui.Widget;
  CloseProbability?: DevExpress.ui.Widget;

  constructor(args) {
    super(args);
    let theOpportunity = this;
    $.each(args, function (key, val) {
      if (theOpportunity.hasOwnProperty(key)) theOpportunity[key] = val;
    });

    if (!theOpportunity.DisplayName) {
      DisplayInfo(theOpportunity.ItemType).done(function (displayinfo) {
        theOpportunity.DisplayName = displayinfo.DisplayName;
        theOpportunity.TypeName = displayinfo.TypeName;
      });
    }
  }

  RenderCustomTab(itemData, container, dfd) {
    var theOpportunity = this;
    super.RenderCustomTab(itemData, container, dfd);

    if (itemData.ControlGroupID === "OpportunityBI") {
      theOpportunity.RenderCustomTab_OpportunityData(itemData, container);
    }

    dfd.resolve();
  }

  RenderCustomTab_OpportunityData(itemData, container) {
    var theOpportunity = this;
    var customName = DomSafeID(theOpportunity.ItemID);
    var biCenterName = "OppBi_" + customName;

    // Frame for the Center
    let html = $("<div id='" + biCenterName + "'>").height(() => {
      return $(window).innerHeight() - $("#tdffooter").innerHeight() - 175;
    });
    container.append(html);

    let itemListNameModel: ItemListingNameModel = { ItemListWithNames: [] };
    itemListNameModel.ItemListWithNames.push({
      ID: theOpportunity.ItemID,
      Name: theOpportunity.iMain.ItemSubject
    });

    let biCenter = new OppBIDataCenter(
      itemListNameModel,
      { MainAreaContainer: html },
      customName
    );
    biCenter.InitAndRender();
  }

  TryCalculateProbableValue() {
    let theOpportunity = this;
    if (
      theOpportunity.CalculationMethod &&
      parseInt(theOpportunity.CalculationMethod.option("value")) ===
      CalculationMethodType.Standard
    ) {
      if (theOpportunity.OpportunityValue && theOpportunity.CloseProbability) {
        let value = Globalize.parseNumber(
          theOpportunity.OpportunityValue.option("value"),
          { style: "decimal" }
        );
        let probability = Globalize.parseNumber(
          theOpportunity.CloseProbability.option("value"),
          { style: "percent" }
        );
        if (value && probability) {
          if (probability > 1) probability = probability / 100;
          if (theOpportunity.ProbableValue)
            theOpportunity.ProbableValue.option(
              "value",
              Globalize.formatCurrency(
                value * probability,
                CurrentUser.Currency
              )
            );
        }
      }
    }
  }

  UpdateValueControls(value?: string) {
    let theOpportunity = this;

    let theValue = -1;
    theValue = value
      ? parseInt(value)
      : theOpportunity.CalculationMethod
        ? parseInt(theOpportunity.CalculationMethod.option("value"))
        : 0;

    switch (theValue) {
      case CalculationMethodType.Forecast:
        if (theOpportunity.OpportunityValue)
          theOpportunity.OpportunityValue.option("disabled", true);
        if (theOpportunity.CloseProbability)
          theOpportunity.CloseProbability.option("disabled", true);
        if (theOpportunity.ProbableValue) {
          $(theOpportunity.ProbableValue.element()[0].parentElement).css(
            "visibility",
            "hidden"
          );
        }
        if (theOpportunity.ForecastValue) {
          $(theOpportunity.ForecastValue.element()[0].parentElement).css(
            "visibility",
            "visible"
          );
        }
        break;
      case CalculationMethodType.None:
        if (theOpportunity.OpportunityValue)
          theOpportunity.OpportunityValue.option("disabled", false);
        if (theOpportunity.CloseProbability)
          theOpportunity.CloseProbability.option("disabled", false);
        if (theOpportunity.ProbableValue) {
          $(theOpportunity.ProbableValue.element()[0].parentElement).css(
            "visibility",
            "hidden"
          );
        }
        if (theOpportunity.ForecastValue) {
          $(theOpportunity.ForecastValue.element()[0].parentElement).css(
            "visibility",
            "hidden"
          );
        }
        break;
      case CalculationMethodType.Standard:
        if (theOpportunity.OpportunityValue)
          theOpportunity.OpportunityValue.option("disabled", false);
        if (theOpportunity.CloseProbability)
          theOpportunity.CloseProbability.option("disabled", false);
        if (theOpportunity.ProbableValue) {
          $(theOpportunity.ProbableValue.element()[0].parentElement).css(
            "visibility",
            "visible"
          );
        }
        if (theOpportunity.ForecastValue) {
          $(theOpportunity.ForecastValue.element()[0].parentElement).css(
            "visibility",
            "hidden"
          );
        }
        break;
    }
  }
}
