import { CenterType, GridContainerTypes, itemTypes } from "../../enums/enums";
import { RaiseEvent2, AddHandler2 } from "../../infrastructure/events/ui_events";
import { eventNameSpace, EventTypes } from "../../enums/webevents/enums";
import { CRMDataCenter } from "datacenters/crmdatacenter";
import { IToolbarItemOverrideOptions } from "interfaces/grids/interfaces";
import { Preferences } from "infrastructure/user/preferences";
import { TDFDataCenters } from "interfaces/datacenters/tdfdatacenters";
import { BSIGrids, ISummary } from "enums/bi/enums";
import { BiDataCenterTabs } from "datacenters/bidatacentertabs";
import { GridSetup } from "services/grid/helpers/gridsetup";
import { DataScopeSelection } from "components/dashboards/dynamicdatascopeselector";
import { CloneIT } from "util/allutils";

export class GapAnalysis extends CRMDataCenter {

  get CenterType(): CenterType {
    return CenterType.GapAnalysis;
  }

  protected SummarySettings: DataScopeSelection;

  protected GetInitialSummarySettings() {
    let d: JQueryDeferred<BSIGrids | itemTypes> = $.Deferred();

    let summaryDeferred: JQueryDeferred<DataScopeSelection> = $.Deferred();
    RaiseEvent2(
      EventTypes.BIEventTypes.currentsummaryselection,
      this.ParentEventLimiterName,
      eventNameSpace.request,
      { deferred: summaryDeferred }
    );

    summaryDeferred.done(a => {
      this.SummarySettings = CloneIT(a);
      d.resolve()
    });

    return d.promise();
  }

  protected get GridContainerType() {
    return GridContainerTypes.BSISummary;
  }

  private ParentEventLimiterName: string;
  protected Tab: TDFDataCenters.DataCenterTabs.TabItem;

  protected get ToolbarItems(): IToolbarItemOverrideOptions[] {
    let ToolbarItems: IToolbarItemOverrideOptions[] = [];

    ToolbarItems = ToolbarItems.concat([
      { name: "OpenButton", index: 1, location: 'before' },
      { name: "ActionButton", index: 2, location: 'before' },
      { name: "ViewLookup", index: 4, location: "after" },
      { name: "RefreshButton", index: 5, location: "after" },
      { name: "OptionsButton", index: 6, location: "after" }
    ]);

    if ((Preferences.GetCompanyPreference("ShowViewsBtn", "TDFMobile", "1") === "1")) {
      ToolbarItems.push({ name: "ViewButton", index: 3, location: "before" });
    }

    return ToolbarItems;
  }

  constructor(
    container: JQuery,
    ParentCenterType: CenterType,
    Tab: TDFDataCenters.DataCenterTabs.TabItem,
    EventLimiterName: string
  ) {
    super({ MainAreaContainer: container }, `${EventLimiterName}_GapAnalysis_${Date.now()}`);

    let gaCenter = this;
    gaCenter.ParentEventLimiterName = EventLimiterName;
    gaCenter.Tab = Tab;

    gaCenter.GetInitialSummarySettings().done(() => {
      gaCenter.AddListeners();
      gaCenter.initTabs();
    });
  }

  AddListeners() {
    let gaCenter = this;

    AddHandler2(
      EventTypes.BIEventTypes.currentsummaryselection,
      gaCenter.CustomName,
      eventNameSpace.request,
      gaCenter.MainAreaContainer,
      (e, d) => {
        RaiseEvent2(
          EventTypes.BIEventTypes.currentsummaryselection,
          this.ParentEventLimiterName,
          eventNameSpace.request,
          d
        );
      }
    );

    AddHandler2(
      EventTypes.BIEventTypes.currentsummaryselection,
      gaCenter.ParentEventLimiterName,
      eventNameSpace.notify,
      gaCenter.MainAreaContainer,
      gaCenter.OnSummarySelectionChanged.bind(gaCenter)
    );
  }

  OnSummarySelectionChanged(e, d: { NewSummarySettings: DataScopeSelection }) {
    let gaCenter = this;
    if (d) {
      if (
        d.NewSummarySettings.IsSalesmanLevel
          ? gaCenter.SummarySettings.ActiveSelectionLevel !== ISummary.SummaryInterfaces.cBSID.EnumType.SalesRep
          : gaCenter.SummarySettings.ActiveSelectionLevel === ISummary.SummaryInterfaces.cBSID.EnumType.SalesRep
      ) {
        RaiseEvent2(
          EventTypes.CenterEventTypes.itemtype,
          gaCenter.CustomName,
          eventNameSpace.modify,
          {
            ItemType: d.NewSummarySettings.IsSalesmanLevel
              ? BSIGrids.Salesperson
              : BSIGrids.Acct
          }
        )
      }

      gaCenter.SummarySettings = CloneIT(d.NewSummarySettings);
    }
  }

  initMenu() {
    // Intentionally blank. Gap Analysis does not switch item types through UI. 
    // This type of functionality comes from switching the 'View' (Gap Analysis
    // in this case) of the main BICenter View Selector.
  }

  initTabs() {
    let gaCenter = this;
    if (gaCenter.Tab) {
      new BiDataCenterTabs(
        gaCenter.DataTabsAreaContainer,
        gaCenter.CenterType,
        gaCenter.DataGridAreaContainer,
        gaCenter.CustomName,
        gaCenter.InDefaultContainer,
        gaCenter.ExtraRequestArgs as GridSetup.BIRequestArgs
      ).Init();


      RaiseEvent2(
        EventTypes.CenterEventTypes.itemtype,
        gaCenter.CustomName,
        eventNameSpace.modify,
        {
          ItemType: gaCenter.Tab.ItemSpecific
        }
      );
    }
  }

  protected get ExtraRequestArgs(): Partial<GridSetup.BIRequestArgs> {
    let gaCenter = this;

    let extraRequestArgs: Partial<GridSetup.BIRequestArgs>;
    if (gaCenter.Tab.GUID !== `${gaCenter.Tab.ItemType}-${gaCenter.Tab.Name}`) {
      extraRequestArgs = {
        OptionalGroupSummaryOptions: {
          GapID: gaCenter.Tab.GUID
        }
      };
    }

    return extraRequestArgs;
  }

  protected GetToolbarItemArgs(data?) {
    let ToolbarItemArgs: any;
    ToolbarItemArgs = {
      ItemType: data.ItemType,
      gridContainerType: this.GridContainerType,
      UniqueCenterName: this.CustomName,
    }
    return ToolbarItemArgs;
  }

  //GetAdvancedItems(): JQueryPromise<IAdvancedTabItems> {
  //    let theGapAnalysisSummariesTab = this;
  //    let dfd: JQueryDeferred<IAdvancedTabItems> = $.Deferred();

  //    if (theGapAnalysisSummariesTab.TabInfo) {
  //        return (dfd.resolve(theGapAnalysisSummariesTab.TabInfo));
  //    }
  //    else {
  //        let request = new TDFRequest({
  //            url: "/bi/GapAnalysisList", type: "POST",
  //            data: {
  //                biLevel: theGapAnalysisSummariesTab.SummarySettings.ActiveSelectionLevel,
  //                isSalesRep: false,
  //            }
  //        });
  //        request.MakeRequest().done(function (response) {
  //            // Save Response for future use
  //            theGapAnalysisSummariesTab.gapSummaries = response.GapAnalysisList;
  //            theGapAnalysisSummariesTab.gapFieldItems = [];

  //            // Set up the items collection for the acct fields
  //            $.each(theGapAnalysisSummariesTab.gapSummaries, function (index, value) {
  //                if (value) {
  //                    let gapValue = <IGapGroupSummaryBasicItem>value;

  //                    let item: IGapGroupSummaryItem = {
  //                        Caption: gapValue.Caption,
  //                        Index: gapValue.Index,
  //                        ID: gapValue.ID,
  //                        ViewList: undefined,       //don't initialize this as it determines whether we need to set this or not to the list of views.
  //                        LoadedGrids: [],
  //                        Type: 740,
  //                        RollUpType: gapValue.RollUpType,
  //                        Measure: gapValue.Measure,
  //                    };
  //                    theGapAnalysisSummariesTab.gapFieldItems.push(item);
  //                }
  //            });

  //            // Add count on Main Center Menu
  //            //   theGapAnalysisSummariesTab.MyBICenter.IncrementBadge();

  //            theGapAnalysisSummariesTab.TabInfo = {
  //                AdvancedSummaries: theGapAnalysisSummariesTab.gapSummaries,
  //                Items: theGapAnalysisSummariesTab.gapFieldItems
  //            };
  //            return (dfd.resolve(theGapAnalysisSummariesTab.TabInfo));
  //        });
  //    }
  //    return (dfd.promise());
  //}
}

//export class GapAnalysis  {

//    AdvancedTypeName: string = "GapAnalysis";
//    AdvancedSelectorPlaceholderMessage = "Choose a Gap Analysis";
//    TabPanelPlaceholderMessage = "Choose a Gap Analysis from the menu.";

//    gapSummaries: IAdvancedGroupSummaryBasicItem[];
//    gapFieldItems: IAdvancedGroupSummaryItem[];
//    TabInfo: IAdvancedTabItems;

//    AdvancedItems: IAdvancedTabItems;
//    ContainerID: string;
//    AdvFieldsToolbarContainerID: string;
//    ViewToolbarContainerID: string;
//    AdvFieldsTabPanelID: string;
//    AdvFieldsLookupID: string;

//    GeneralSummaryStyleProperty: string = "GeneralSummaryStyle";
//    GeneralSummaryStyle: BIGeneralSummaryStyle;
//    generalSummaryData: any;
//    private _container: JQuery;

//    constructor(container: JQuery, centertype:  CenterType, preferenceModule: string, url, tab: TDFDataCenters.DataCenterTabs.TabItem, dataContainer: JQuery = null) {
//      //  this._url = url;
//        this._tab = tab;
//        this._container = container;
//        this._CenterType = centertype;
//        this.AddListeners();
//        RaiseEvent(EventTypes.BIEventTypes.currentsummaryselection, EventNameSpace.request)
//        this.PreferenceModule = preferenceModule
//    }
//    AddListeners() {
//        AddHandler(EventTypes.BIEventTypes.currentsummaryselection, EventNameSpace.notify, this._container, this.GetSummariesList.bind(this))
//    }
//    ClearCachedData() {
//        let theGapAnalysisSummariesTab = this;
//        theGapAnalysisSummariesTab.TabInfo = undefined;
//     //   super.ClearCachedData();
//    }

//    GetAdvancedItems(): JQueryPromise<IAdvancedTabItems> {
//        let theGapAnalysisSummariesTab = this;
//        let dfd: JQueryDeferred<IAdvancedTabItems> = $.Deferred();

//        if (theGapAnalysisSummariesTab.TabInfo) {
//            return (dfd.resolve(theGapAnalysisSummariesTab.TabInfo));
//        }
//        else {
//            let request = new TDFRequest({
//                url: "/bi/GapAnalysisList", type: "POST",
//                data: {
//                    biLevel: theGapAnalysisSummariesTab._SummarySettings.ActiveSelectionLevel,
//                    isSalesRep: false,
//                }
//            });
//            request.MakeRequest().done(function (response) {
//                // Save Response for future use
//                theGapAnalysisSummariesTab.gapSummaries = response.GapAnalysisList;
//                theGapAnalysisSummariesTab.gapFieldItems = [];

//                // Set up the items collection for the acct fields
//                $.each(theGapAnalysisSummariesTab.gapSummaries, function (index, value) {
//                    if (value) {
//                        let gapValue = <IGapGroupSummaryBasicItem>value;

//                        let item: IGapGroupSummaryItem = {
//                            Caption: gapValue.Caption,
//                            Index: gapValue.Index,
//                            ID: gapValue.ID,
//                            ViewList: undefined,       //don't initialize this as it determines whether we need to set this or not to the list of views.
//                            LoadedGrids: [],
//                            Type: 740,
//                            RollUpType: gapValue.RollUpType,
//                            Measure: gapValue.Measure,
//                        };
//                        theGapAnalysisSummariesTab.gapFieldItems.push(item);
//                    }
//                });

//                // Add count on Main Center Menu
//             //   theGapAnalysisSummariesTab.MyBICenter.IncrementBadge();

//                theGapAnalysisSummariesTab.TabInfo = {
//                    AdvancedSummaries: theGapAnalysisSummariesTab.gapSummaries,
//                    Items: theGapAnalysisSummariesTab.gapFieldItems
//                };
//                return (dfd.resolve(theGapAnalysisSummariesTab.TabInfo));
//            });
//        }
//        return (dfd.promise());
//    }

//    GetRequestModel(viewGUID?: string):GridSetup.BaseGridRequestModel {
//        let theGapAnalysisSummariesTab = this;

//        let requestArgs = newGridSetup.BIRequestArgs();
//  // //     requestArgs.ViewID = viewGUID || (<GridToolbar>theGapAnalysisSummariesTab.CurrentToolbar).DefaultView;
//     requestArgs.ContainerID = theGapAnalysisSummariesTab._SummarySettings.CurrentlySelectedItemsAsString();
//        requestArgs.BISummaryType = theGapAnalysisSummariesTab._SummarySettings.ActiveSelectionLevel;
//        requestArgs.OptionalGroupSummaryOptions = {
//     //       // itemType: theGapAnalysisSummariesTab.GetToolbarType(),
//   //   //      GapID: theGapAnalysisSummariesTab.CurrentSelectedItem.ID
//        };
//        requestArgs.GoalType = theGapAnalysisSummariesTab._SummarySettings.GetGoalSelection(theGapAnalysisSummariesTab._SummarySettings.BIGridType, theGapAnalysisSummariesTab._SummarySettings.ActiveSelectionLevel, "BSISummary");

//        let requestModel:GridSetup.BaseGridRequestModel = newGridSetup.BIRequestModel(requestArgs);
//        return (requestModel);
//    }

//    GetToolbarType() {
//        let theAdvancedSummariesTab = this;
//        let toolbarType: number = theAdvancedSummariesTab._tab.ItemType;

//       // let rollupType:  GapRollUpType = (<IGapGroupSummaryItem>theAdvancedSummariesTab.CurrentSelectedItem).RollUpType;

//        ////switch (rollupType) {
//        ////    case  GapRollUpType.AccountID:
//        ////        toolbarType =  BSIGrids.Acct;
//        ////        break;
//        ////    case  GapRollUpType.AcctPkgID:
//        ////        toolbarType =  BSIGrids.AcctPkg;
//        ////        break;
//        ////    case  GapRollUpType.BSICustomerID:
//        ////        toolbarType =  BSIGrids.CustomerID;
//        ////        break;
//        ////    case  GapRollUpType.BSIMasterAcctID:
//        ////        toolbarType =  BSIGrids.MasterID;
//        ////        break;
//        ////    case  GapRollUpType.AcctRootID:
//        ////        toolbarType =  BSIGrids.AcctRoot;
//        ////        break;
//        ////    case  GapRollUpType.SalesmanID:
//        ////        toolbarType =  BSIGrids.Salesperson;
//        ////        break;
//        ////};

//        ////return toolbarType;
//    }

//    GetSummariesList(e?: JQueryEventObject, d?) {
//        let theAdvancedSummariesTab = this;
//        let dfd = $.Deferred();

//        if (theAdvancedSummariesTab.AdvancedItems) {
//            return (dfd.resolve(theAdvancedSummariesTab.AdvancedItems));
//        }
//        else {
//            theAdvancedSummariesTab.GetAdvancedItems().done(function (result: IAdvancedTabItems) {
//                theAdvancedSummariesTab.AdvancedItems = result;
//              theAdvancedSummariesTab.RenderTab(theAdvancedSummariesTab.TabInfo, 0, theAdvancedSummariesTab._container)
//                return (dfd.resolve(theAdvancedSummariesTab.AdvancedItems));
//            });
//        }
//        return (dfd.promise());
//    }

//    RenderTab(data, index, element) {
//        let theAdvancedSummariesTab = this;

//        theAdvancedSummariesTab.GetSummariesList().done(function (response: any) {
//            theAdvancedSummariesTab.ContainerID = ("Container_" + theAdvancedSummariesTab.AdvancedTypeName).replace(/[{}]/g, "");
//            theAdvancedSummariesTab.AdvFieldsToolbarContainerID = "Toolbar_" + theAdvancedSummariesTab.AdvancedTypeName;
//            theAdvancedSummariesTab.ViewToolbarContainerID = "ViewToolbar_" + theAdvancedSummariesTab.AdvancedTypeName;
//            theAdvancedSummariesTab.AdvFieldsLookupID = "selAdvancedFields_" + theAdvancedSummariesTab.AdvancedTypeName;
//            theAdvancedSummariesTab.AdvFieldsTabPanelID = "Tabs_" + theAdvancedSummariesTab.AdvancedTypeName;

//            let container = $("<div id='" + theAdvancedSummariesTab.ContainerID + "' style='padding:3px'><div id='" + theAdvancedSummariesTab.AdvFieldsToolbarContainerID + "'></div><div id='" + theAdvancedSummariesTab.ViewToolbarContainerID + "'></div><div id='" + theAdvancedSummariesTab.AdvFieldsTabPanelID + "'></div></div>");
//            element.append(container);

//            let toolbarItems: any = [
//                {
//                    locateInMenu: 'never',
//                    location: "after",
//                    widget: "dxLookup",
//                    options: {
//                        elementAttr: {
//                            id: theAdvancedSummariesTab.AdvFieldsLookupID,
//                        },
//                        dataSource: new dxDataSource({
//                            store: new dxArrayStore({
//                                data: theAdvancedSummariesTab.AdvancedItems.Items
//                            }),
//                        }),
//                        placeholder: theAdvancedSummariesTab.AdvancedSelectorPlaceholderMessage,
//                        closeOnOutsideClick: true,
//                        value: -1,
//                        displayExpr: "Caption",
//                        valueExpr: "Index",
//                        width: "200px",
//                        height: "30px",
//                        onItemClick: function (selectedItem) {
//                            let item = theAdvancedSummariesTab.GetItem(selectedItem.itemIndex);
//                            theAdvancedSummariesTab.CurrentSelectedItem = item;
//                            // Note:  Resetting the default view to blank so that it picks up the actual default view
//                            if (theAdvancedSummariesTab.CurrentSelectedItem.ToolbarModel) {
//                                theAdvancedSummariesTab.CurrentSelectedItem.ToolbarModel.DefaultView = "";
//                            }

//                            theAdvancedSummariesTab.LoadAdvFieldTabPanel(selectedItem);
//                        }
//                    }
//                }
//            ];

//            $("#" + theAdvancedSummariesTab.AdvFieldsToolbarContainerID).dxToolbar({
//                dataSource: toolbarItems,
//                height: "33px",
//            }).dxToolbar("instance");

//            theAdvancedSummariesTab.ViewToolbarContainer = $("#" + theAdvancedSummariesTab.ViewToolbarContainerID);
//            theAdvancedSummariesTab.RenderAdvancedFieldTabPanel();
//            $("#" + theAdvancedSummariesTab.AdvFieldsLookupID).addClass("flash");
//        });
//    }

//}
