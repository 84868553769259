import { GetCompanyPreference } from "infrastructure/user/getpreference";

export class MitsContainer {
    private Container: JQuery;
    private UrlStem: string = `http://${
        GetCompanyPreference('BaseUrl', 'MitsIntegration', 'poc.mits.com')
        }/mitsdiscover/`;

    private Path: string = `launchDashboardAsynchronously.flow?dashboardUri=displayFullScreenDashboard.flow%3FlibraryName%3DCUSTOMER.SCORECARDS%26id%3DFS.CUSTOMER.BILL.TO.STATUS.SCORECARD%26Sales%2520Rep%3D1013%26Bill%2520To%2520Customer%3D103273`;

    constructor(container: JQuery, path?: string) {
        this.Container = container;
        if (path) {
            this.Path = path;
        }
    }

    Init() {
        let d = $.Deferred();
        let iframe = $('<iframe />')
            .attr({
                'src': `${this.UrlStem}${this.Path}`,
                'marginwidth': 0,
                'marginheight': 0,
                'frameborder': 0,
                'vspace': 0,
                'hspace': 0
            })
            .height('calc(100% - 15px)')
            .width('100%')
            .css({
                'border': '1px solid #ddd',
                'margin-top': '15px'
            }).on('load', (e) => {
                d.resolve();
            });

        this.Container.append(iframe);
        return d.promise();
    }
}