import { FilterTarget } from "enums/rulesmanager/enums";
import { RelativeDateFilterControl } from "./relativedatefilter";
import { itemTypes } from "enums/enums";
import { RulesRelativeDateFilterDataService } from "services/grid/relativedatefilter/rulesrelativedatefilterdataservice";

export class RulesRelativeDateFilterControl extends RelativeDateFilterControl {
    private _filterTarget: FilterTarget;
    private _itemType: itemTypes;

    constructor(filterTarget: FilterTarget, itemType: itemTypes, el?: JQuery) {
        super('', el);
        this._filterTarget = filterTarget;
        this._itemType = itemType;
        this._dataService = new RulesRelativeDateFilterDataService()
    }

    GetSetupOptions() {
        let rdf = this;
        let d = $.Deferred();
        (<RulesRelativeDateFilterDataService>rdf._dataService).GetSettings(rdf._filterTarget, rdf._itemType).done((response) => {
            this._rangeOpts = response;
            d.resolve();
        });
        return d.promise();
    }
}