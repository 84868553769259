import { Dialog } from '../dialogs/dialog';
import {
  ICustomAcctPkgSelectorModel,
  IZipLookupData
} from '../../interfaces/interfaces';
import { TDFRequest } from '../../services/request';
import { LookupZip } from '../../util/allutils';
import { ItemBase } from '../items/itembase';
export class CustomAcctPkgSelector {
  private Buttons: DevExpress.ui.dxPopupToolbarItem[]; // Dialogs.IDialogButton[] = [];
  private CancelFallbackFunction: { (): void };
  private CreateItemFunction: { (string): void };
  private DetailItems: Array<any>;
  private SelectorForm: DevExpress.ui.dxForm;
  private ContentID: string = 'custom-AcctPkg-Selector-container';
  private SelectorContentID: string = 'acctpkg-selector-container';
  private CustomSelectorSetupInfo: ICustomAcctPkgSelectorModel;
  private theItem: ItemBase;
  private myDialog: Dialog;
  private acctPkgListDialog: Dialog;

  constructor(iBase: ItemBase, createItemFunction, cancelFallbackFunction) {
    let panel = this;
    panel.theItem = iBase;
    panel.CreateItemFunction = createItemFunction;
    panel.CancelFallbackFunction = cancelFallbackFunction;
  }

  DisplayPanel() {
    let panel = this;
    panel.AddDialogButtons();

    let selectorSetupRequest = new TDFRequest({
      url: '/core/user/GetCustomAcctPkgSelector',
      type: 'POST',
      data: {}
    });

    selectorSetupRequest.MakeRequest().done(function (response) {
      panel.CustomSelectorSetupInfo = response;
      panel.GetFormItems();

      panel.myDialog = new Dialog({
        id: 'acctpkg-lookup-dlg',
        size: 'size-normal',
        closable: true,
        title: 'Account Package Lookup',
        buttons: panel.Buttons,
        body:
          "<div id='" +
          panel.ContentID +
          "' style='padding:8px, display:block' />"
      });

      panel.myDialog.open().done(function () {
        // Create the form for input.
        let dxFormOptions = {
          formData: {},
          items: panel.DetailItems,
          colCount: 8
        };

        panel.SelectorForm = $('#' + panel.ContentID)
          .dxForm(dxFormOptions)
          .dxForm('instance');
      });
    });
  }

  private AddDialogButtons() {
    let panel = this;

    if (!panel.Buttons) panel.Buttons = [];

    var btnCancel: DevExpress.ui.dxPopupToolbarItem = {
      widget: 'dxButton',
      toolbar: 'bottom',
      location: 'after',
      options: {
        text: 'Cancel',
        icon: 'fa fa-times',
        type: 'normal',
        onClick: function () {
          panel.myDialog.close();
          panel.CancelFallbackFunction();
        }
      }
    };
    panel.Buttons.push(btnCancel);

    var btnOK: DevExpress.ui.dxPopupToolbarItem = {
      widget: 'dxButton',
      toolbar: 'bottom',
      location: 'after',
      options: {
        text: 'Lookup',
        icon: 'fa fa-check',
        type: 'normal',
        onClick: function () {
          panel.LookupAcctPkg();
        }
      }
    };
    panel.Buttons.push(btnOK);

  }

  private GetFormItems() {
    let panel = this;
    panel.DetailItems = [];
    if (
      panel.CustomSelectorSetupInfo.CompanyCaption.charAt(
        panel.CustomSelectorSetupInfo.CompanyCaption.length - 1
      ) === ':'
    ) {
      panel.CustomSelectorSetupInfo.CompanyCaption = panel.CustomSelectorSetupInfo.CompanyCaption.slice(
        0,
        panel.CustomSelectorSetupInfo.CompanyCaption.length - 1
      );
    }

    panel.DetailItems.push({
      dataField: 'Subject',
      editorType: 'dxTextBox',
      colSpan: 8,
      label: { text: panel.CustomSelectorSetupInfo.CompanyCaption },
      visibleIndex: 0
    });

    panel.DetailItems.push({
      dataField: 'Street',
      editorType: 'dxTextBox',
      colSpan: 8,
      label: { text: 'Street' },
      visibleIndex: 2
    });

    panel.DetailItems.push({
      dataField: 'City',
      editorType: 'dxTextBox',
      colSpan: 8,
      label: { text: 'City' },
      visibleIndex: 3
    });

    let stateVisibility: boolean = false;
    if (
      panel.CustomSelectorSetupInfo.CountriesWithStates[
      panel.CustomSelectorSetupInfo.MyCountry
      ]
    ) {
      stateVisibility = true;
    }

    panel.DetailItems.push({
      dataField: 'State',
      colSpan: 8,
      editorType: 'dxSelectBox',
      label: { text: 'State' },
      visible: stateVisibility,
      editorOptions: {
        dataSource:
          panel.CustomSelectorSetupInfo.CountriesWithStates[
          panel.CustomSelectorSetupInfo.MyCountry
          ],
        displayExpr: 'Name',
        displayValue: 'Abv',
        valueExpr: 'Abv',
        searchEnabled: true
      },
      visibleIndex: 4
    });

    panel.DetailItems.push({
      dataField: 'Zip',
      editorType: 'dxTextBox',
      colSpan: 7,
      label: { text: 'Zip' },
      visibleIndex: 5
    });

    panel.DetailItems.push({
      editorType: 'dxButton',
      colSpan: 1,
      editorOptions: {
        text: '...',
        onClick: function () {
          let formData = panel.SelectorForm.option('formData');
          let lookedUpData: IZipLookupData = LookupZip(
            formData.Zip,
            formData.Country
          );
          panel.SelectorForm.updateData('Country', lookedUpData.Country);
          panel.SelectorForm.updateData('City', lookedUpData.City);
          panel.SelectorForm.updateData('State', lookedUpData.StateAbv);
          panel.SelectorForm.updateData('County', lookedUpData.County);
        }
      },
      visibleIndex: 6
    });

    panel.DetailItems.push({
      dataField: 'Country',
      colSpan: 8,
      editorType: 'dxSelectBox',
      label: { text: 'Country' },
      editorOptions: {
        dataSource: panel.CustomSelectorSetupInfo.Countries,
        searchEnabled: true,
        value: panel.CustomSelectorSetupInfo.MyCountry,
        onValueChanged: function (data) {
          let currentStatesForSelectedCountry =
            panel.CustomSelectorSetupInfo.CountriesWithStates[data.value];
          if (currentStatesForSelectedCountry != undefined) {
            let stateSel: any = panel.SelectorForm.getEditor('State');
            stateSel.option('dataSource', currentStatesForSelectedCountry);
          } else {
            // Clear State List
            let stateSel: any = panel.SelectorForm.getEditor('State');
            stateSel.option('dataSource', []);
          }
        }
      },

      visibleIndex: 7
    });

    panel.DetailItems.push({
      dataField: 'County',
      editorType: 'dxTextBox',
      colSpan: 8,
      label: { text: 'County' },
      visibleIndex: 8
    });

    if (panel.CustomSelectorSetupInfo.UDF1.Label) {
      panel.DetailItems.push({
        dataField: 'UDF1',
        colSpan: 8,
        editorType: 'dxSelectBox',
        label: { text: panel.CustomSelectorSetupInfo.UDF1.Label },
        editorOptions: {
          dataSource: panel.CustomSelectorSetupInfo.UDF1.PossibleValues,
          searchEnabled: true
        },
        visibleIndex: 9
      });
    }

    if (panel.CustomSelectorSetupInfo.UDF2.Label) {
      panel.DetailItems.push({
        dataField: 'UDF2',
        colSpan: 8,
        editorType: 'dxSelectBox',
        label: { text: panel.CustomSelectorSetupInfo.UDF2.Label },
        editorOptions: {
          dataSource: panel.CustomSelectorSetupInfo.UDF2.PossibleValues,
          searchEnabled: true
        },
        visibleIndex: 10
      });
    }

    if (panel.CustomSelectorSetupInfo.UDF3.Label) {
      panel.DetailItems.push({
        dataField: 'UDF3',
        colSpan: 8,
        editorType: 'dxSelectBox',
        label: { text: panel.CustomSelectorSetupInfo.UDF3.Label },
        editorOptions: {
          dataSource: panel.CustomSelectorSetupInfo.UDF3.PossibleValues,
          searchEnabled: true
        },
        visibleIndex: 11
      });
    }
  }

  LookupAcctPkg() {
    let panel = this;
    let formData = panel.SelectorForm.option('formData');

    let selectorLookupRequest = new TDFRequest({
      url: '/core/user/LookupCustomAcctPkg',
      type: 'POST',
      data: { filters: formData }
    });
    selectorLookupRequest.MakeRequest().done(function (response) {
      if (response.Data.length > 0) {
        panel.myDialog.close();

        var buttons = [];
        var btnOK = {
          toolbar: 'bottom',
          location: 'after',
          widget: 'dxButton',
          options: {
            text: 'OK',
            icon: 'check',
            //cssClass: "btn btn-primary",
            type: 'success',
            onClick(e) {
              var selectedItem = $('#' + panel.SelectorContentID)
                .dxDataGrid('instance')
                .getSelectedRowsData();
              if (selectedItem.length == 0) {
                alert('Please select a row before clicking OK');
              } else {
                panel.acctPkgListDialog.close();
                let state = formData.State ? formData.State : '';

                //Set the item's prepoulate Data stuff so that the data entered in the initial form gets on the item.
                panel.theItem.PrepopulationData = {
                  TDFObjects: [
                    {
                      TDFField: 'Subject',
                      Value: formData.Subject
                    }
                  ],
                  AddressData: {
                    AddressString:
                      formData.Street +
                      ' ' +
                      formData.City +
                      ' ' +
                      state +
                      ' ' +
                      formData.Zip,
                    City: formData.City,
                    Country: formData.Country,
                    County: formData.County,
                    IsMailingAddress: true,
                    PostalCode: formData.Zip,
                    State: state,
                    Street: formData.Street
                  },
                  UDFData: []
                };

                if (panel.CustomSelectorSetupInfo.UDF1.Label) {
                  panel.theItem.PrepopulationData.UDFData.push({
                    UDF_ID: panel.CustomSelectorSetupInfo.UDF1.UDF_ID,
                    SimpleUdfValue: {
                      ValueName: formData.UDF1
                    }
                  });
                }

                if (panel.CustomSelectorSetupInfo.UDF2.Label) {
                  panel.theItem.PrepopulationData.UDFData.push({
                    UDF_ID: panel.CustomSelectorSetupInfo.UDF2.UDF_ID,
                    SimpleUdfValue: {
                      ValueName: formData.UDF2
                    }
                  });
                }

                if (panel.CustomSelectorSetupInfo.UDF3.Label) {
                  panel.theItem.PrepopulationData.UDFData.push({
                    UDF_ID: panel.CustomSelectorSetupInfo.UDF3.UDF_ID,
                    SimpleUdfValue: {
                      ValueName: formData.UDF3
                    }
                  });
                }

                //Create Account
                var acctPkgID = selectedItem[0]['AcctPkgId'];
                var name = selectedItem[0]['Acct Pkg'];

                let folder: any = {
                  folderid: acctPkgID,
                  level: 1,
                  name: name
                };
                panel.CreateItemFunction(folder);
              }
            }
          }
        };
        buttons.push(btnOK);

        var btnCancel = {
          toolbar: 'bottom',
          location: 'after',
          widget: 'dxButton',
          options: {
            text: 'Cancel',
            icon: 'fa fa-times',
            //cssClass: "btn btn-danger",
            type: 'danger',
            onClick(e) {
              panel.acctPkgListDialog.close();
              panel.CancelFallbackFunction();
            }
          }
        };
        buttons.push(btnCancel);

        panel.acctPkgListDialog = new Dialog({
          id: 'acctpkg-select-dlg',
          size: 'size-wide',
          title: 'Select Account Package',
          closable: true,
          buttons: buttons,
          body:
            "<div id='" +
            panel.SelectorContentID +
            "' style='padding:8px, display:block' />"
        });

        panel.acctPkgListDialog.open().done(function () {
          $('#' + panel.SelectorContentID).dxDataGrid({
            dataSource: response.Data,
            columns: panel.GetLookupColumns(response.Columns),
            columnAutoWidth: true,
            selection: { mode: 'single' },
            grouping: {
              autoExpandAll: false
            },
            height: window.innerHeight * 0.78
          });
        });
      } else {
        alert('No matching Account Packages were found.');
      }
    });
  }

  GetLookupColumns(columnList: string[]) {
    var finalColumns = [];

    $.each(columnList, function (index, colName) {
      var visible = true;

      if (colName == 'AcctPkgId') {
        visible = false;
      }

      finalColumns.push({
        dataField: colName,
        visible: visible,
        caption: colName,
        allowFiltering: true,
        allowSorting: true
      });
    });

    return finalColumns;
  }
}
