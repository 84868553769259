import { BIDataCenter } from "./bidatacenter";
import { CenterType, GridContainerTypes } from "../../enums/enums";
import { ItemListingNameModel } from "../../interfaces/interfaces";
import { TDFDataCenters } from "../../interfaces/datacenters/tdfdatacenters";
import { CurrentUser } from "../../infrastructure/context";
import { DataScopeInitializerItem, DataScopeInitializer, DataScopeSelection, DynamicDataScopeSelectorSettings } from "../../components/dashboards/dynamicdatascopeselector";
import { DataScopeSelectionTypes, DataScopeSelectionMode } from "../../enums/user/datascope/enums";
import { ISummary, EnumBSIPermissionsPrimary } from "../../enums/bi/enums";
import { TDFRequest } from "../../services/request";

export class OppBIDataCenter extends BIDataCenter {
    public static OppBIDynamicDataScopeSelectorSettings: DynamicDataScopeSelectorSettings = {
        ScopeSelectionType: DataScopeSelectionTypes.OppBiScope,
        DialogTitle: "Summary Settings",
        AllowDefault: false,
        SelectionMode: DataScopeSelectionMode.Multiple
    };

    _CenterType: CenterType = CenterType.OppBi;
    _ContainerType: GridContainerTypes = GridContainerTypes.JobSummary;

    private itemListNameModel: ItemListingNameModel;

    constructor(itemListNameModel: ItemListingNameModel, creationArgs: TDFDataCenters.ICRMDataCenterCreationArgs, customName?: string) {
        super(creationArgs, customName);
        let theOppCenter = this;
        theOppCenter.itemListNameModel = itemListNameModel;
    }

    protected GetScopeSelectorSettings() {
        let bidc = this;
        return OppBIDataCenter.OppBIDynamicDataScopeSelectorSettings;
    }

    protected InitializeSummarySettings(): JQueryPromise<any> {
        let bidc = this;
        let dfd: JQueryDeferred<any> = $.Deferred();

        if ((CurrentUser.BIPermissions.Primary & EnumBSIPermissionsPrimary.SummaryTypeOpportunityJobSummary) === EnumBSIPermissionsPrimary.SummaryTypeOpportunityJobSummary) {
            let itemList: DataScopeInitializerItem[] = [];

            let itemIDList = bidc.itemListNameModel.ItemListWithNames.map((value, index) => { return value.ID });
            let request = new TDFRequest({ url: "/BI/GetOpportunityListWithNames", type: "POST", data: { ItemIdList: itemIDList } });
            request.MakeRequest().done(function (response) {
                let initialItemListingWithNames: ItemListingNameModel = response;

                $.each(initialItemListingWithNames.ItemListWithNames, function (index, value) {
                    itemList.push({ ItemID: value.ID, Name: value.Name });
                });

                let dataScopeInitializer: DataScopeInitializer = {
                    ActiveSelectionLevel: ISummary.SummaryInterfaces.cBSID.EnumType.AcctID,
                    SelectedItems: itemList
                };

                bidc.summarySettings = new DataScopeSelection(bidc.CustomName);
                bidc.SummarySettings.InitSettings(dataScopeInitializer);
                dfd.resolve();
            });
        }
        else {
            return (dfd.promise(dfd.resolve()));
        }

        return (dfd.promise());
    }
}