import { GridContainerTypes, itemTypes } from '../../../../enums/enums';
import { AwesomeViewLookup, ViewsResponse } from './viewlookup';
import { ToolbarTemplateFactory } from './toolbartemplatefactory';
import { GridViewServiceBI } from 'services/grid/bigriddataservice';
import { RaiseEvent2, AddHandler2 } from 'infrastructure/events/ui_events';
import { EventTypes, eventNameSpace } from 'enums/webevents/enums';
import { DataScopeSelection } from 'components/dashboards/dynamicdatascopeselector';
import { BSIGrids, ISummary } from 'enums/bi/enums';
import { CloneIT } from 'util/allutils';

export class AwesomeBIViewLookup extends AwesomeViewLookup {
    protected SummarySettings: DataScopeSelection;

    AddListeners() {
        let avbs = this;
        super.AddListeners();

        AddHandler2(
            EventTypes.BIEventTypes.currentsummaryselection,
            avbs.EventLimiterName,
            eventNameSpace.notify,
            avbs._container,
            avbs.OnSummarySelectionChanged.bind(avbs)
        );
    }

    protected OnSummarySelectionChanged(e, d: { NewSummarySettings: DataScopeSelection }) {
        let avbs = this;
        if (d) {
            if (
                avbs._allViews.filter(a => {
                    return a.ItemType !== BSIGrids.GapAnalysis;
                }).length > 0
                    &&
                    d.NewSummarySettings.IsSalesmanLevel
                    ? avbs.SummarySettings.ActiveSelectionLevel !== ISummary.SummaryInterfaces.cBSID.EnumType.SalesRep
                    : avbs.SummarySettings.ActiveSelectionLevel === ISummary.SummaryInterfaces.cBSID.EnumType.SalesRep
            ) {
                avbs._allViews = avbs._allViews.filter(a => {
                    return a.ItemType !== BSIGrids.GapAnalysis;
                });
                avbs.ReloadViews(true);
            }

            avbs.SummarySettings = CloneIT(d.NewSummarySettings);
        }
    }

    get DataService(): GridViewServiceBI {
        if (this._DataService) return this._DataService;
        this.DataService = new GridViewServiceBI();

        return this._DataService;
    }
    set DataService(val: GridViewServiceBI) {
        if (val) this._DataService = val;
    }

    protected GetViewsForMenu(scopeChange: boolean): JQueryPromise<ViewsResponse> {
        let abvs = this;
        let d = $.Deferred();
        let summaryDeferred: JQueryDeferred<DataScopeSelection> = $.Deferred();
        RaiseEvent2(
            EventTypes.BIEventTypes.currentsummaryselection,
            abvs.EventLimiterName,
            eventNameSpace.request,
            { deferred: summaryDeferred }
        )

        summaryDeferred.done(a => {
            abvs.SummarySettings = CloneIT(a);

            abvs.DataService.ViewsForMenuRequest(
                abvs.ItemType,
                abvs.GridContainerType,
                scopeChange ? -1 : abvs.Scope,
                a
            ).MakeRequest().done(views => {
                d.resolve(views);
            });
        });

        return d.promise();
    }
}

export function BIViewLookup(args, config?: DevExpress.ui.dxToolbarItemTemplate) {
    let ItemType = itemTypes.itemUnknown;
    let gridContainerType = GridContainerTypes.Unknown;

    let centerName: string = "";
    if (args && args.length) {
        args.map((v, k) => {
            if ($.isPlainObject(v)) {
                Object.keys(v).forEach((val, key) => {
                    if (val.toLowerCase() === 'gridcontainertype') {
                        gridContainerType = v[val];
                    }
                    else if (val.toLowerCase() === "itemtype") {
                        ItemType = v[val];
                    }
                    else if (val.toLowerCase() === "uniquecentername") {
                        centerName = v[val];
                    }
                });
            }
        });
    }
    return ToolbarTemplateFactory(
        config && config.location ? config.location : 'before',
        config && config.locateInMenu ? config.locateInMenu : 'never',
        config && config.disabled ? config.disabled : false,
        null,
        '',
        (data, index, element) => {
            let timer;
            const div = $('<div />').appendTo(element);
            let selector;
            timer = setInterval(function () {
                if (document.body.contains(element[0])) {
                    clearInterval(timer);
                    selector = new AwesomeBIViewLookup(div, gridContainerType, ItemType, centerName);
                    selector.init();
                }
            });
        }
    );
}
