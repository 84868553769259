import { TDFRequest } from "services/request";
import { itemTypes } from "enums/enums";
import { Dialog } from "components/dialogs/dialog";
import { Notification } from "components/dialogs/notification";
import dxCustomStore from 'devextreme/data/custom_store';
import dxDataSource from 'devextreme/data/data_source';

export class AttendeeSummary {

    private _PossibleAttendeeStatuses: { statusid: number, statustext: string }[];
    get PossibleAttendeeStatuses(): JQueryPromise<{ statusid: number, statustext: string }[]> {
        let d = $.Deferred();

        if (this._PossibleAttendeeStatuses && this._PossibleAttendeeStatuses.length) {
            return d.promise(d.resolve(this._PossibleAttendeeStatuses));
        }

        new TDFRequest({
            url: `Item/Event/${this.EventID}/PossibleAttendeeStatuses`,
            type: 'GET',
        }).MakeRequest().done((response) => {
            this._PossibleAttendeeStatuses = response;
            d.resolve(response);
        });

        return d.promise();
    }

    private _AttendeeSessionGridInstance: DevExpress.ui.dxDataGrid;
    get AttendeeSessionGridInstance(): JQueryPromise<DevExpress.ui.dxDataGrid> {
        let attsum = this;
        let d: JQueryDeferred<DevExpress.ui.dxDataGrid> = $.Deferred();

        if (attsum._AttendeeSessionGridInstance) {
            return d.promise(d.resolve(attsum._AttendeeSessionGridInstance));
        }

        attsum.AttendeeData.done(aData => {

            attsum._AttendeeSessionGridInstance = $('<div />').dxDataGrid({
                dataSource: aData.Sessions,
                keyExpr: 'ID',
                height: '100%',
                columns: [
                    {
                        dataField: 'ID',
                        visible: false,
                        allowEditing: false
                    }, {
                        dataField: 'Name',
                        caption: 'Session',
                        allowEditing: false,
                        sortIndex: 1,
                        sortOrder: 'asc'
                    }, {
                        dataField: 'Starts',
                        dataType: 'datetime',
                        allowEditing: false,
                        sortIndex: 0,
                        sortOrder: 'asc'
                    }, {
                        dataField: 'Ends',
                        dataType: 'datetime',
                        allowEditing: false
                    }, {
                        dataField: 'Limit',
                        allowEditing: false
                    }, {
                        dataField: 'Enrolled',
                        caption: 'Currently Enrolled',
                        allowEditing: false
                    }, {
                        dataField: 'Attending',
                        caption: 'Attending?',
                        allowEditing: true
                    }
                ],
                editing: {
                    allowUpdating: true,
                    mode: 'cell'
                },
                onEditorPreparing: e => {
                    if (e.dataField === 'Attending') {
                        let data = e.row.data;

                        if (data.Limit <= data.Enrolled && !data.Attending) {
                            e.editorOptions.disabled = true;
                        }
                    }
                },
                onRowUpdated: e => {
                    attsum.UpdateSessionAttendance(e.key, e.data);
                }
            }).dxDataGrid('instance');

            d.resolve(attsum._AttendeeSessionGridInstance);

        });

        return d.promise();
    }

    private _AttendeeFormInstance: DevExpress.ui.dxForm;
    get AttendeeFormInstance(): JQueryPromise<DevExpress.ui.dxForm> {
        let attsum = this;
        let d: JQueryDeferred<DevExpress.ui.dxForm> = $.Deferred();

        if (attsum._AttendeeFormInstance) {
            return d.promise(d.resolve(attsum._AttendeeFormInstance));
        }

        attsum.AttendeeData.done(aData => {

            attsum._AttendeeFormInstance = $('<div />').height('50%').dxForm({
                formData: aData || null,
                items: [
                    {
                        itemType: 'group',
                        items: [
                            {
                                dataField: 'Name',
                                editorOptions: <DevExpress.ui.dxTextBoxOptions>{
                                    readOnly: true
                                }
                            }, {
                                dataField: 'JobTitle',
                                label: { text: 'Title' },
                                editorOptions: <DevExpress.ui.dxTextBoxOptions>{
                                    readOnly: true
                                }
                            }, {
                                dataField: 'Company',
                                editorOptions: <DevExpress.ui.dxTextBoxOptions>{
                                    readOnly: true
                                }
                            }, {
                                dataField: 'RegistrationNumber',
                                label: { text: 'Reg #' },
                                editorType: 'dxNumberBox',
                                editorOptions: <DevExpress.ui.dxNumberBoxOptions>{
                                    readOnly: true
                                }
                            }, {
                                dataField: 'ProcDate',
                                editorType: 'dxDateBox',
                                editorOptions: <DevExpress.ui.dxDateBoxOptions>{
                                    type: 'datetime',
                                    readOnly: true
                                }
                            }, {
                                dataField: 'AttendeeStatus',
                                label: { text: 'Status' },
                                editorType: 'dxSelectBox',
                                editorOptions: <DevExpress.ui.dxSelectBoxOptions>{
                                    dataSource: new dxDataSource({
                                        store: new dxCustomStore({
                                            load: () => {
                                                let d: JQueryDeferred<any[]> = $.Deferred();

                                                attsum.PossibleAttendeeStatuses.done(statuses => {
                                                    d.resolve(statuses);
                                                })

                                                return d.promise();
                                            },
                                            key: 'statusid',
                                            byKey: (key) => {
                                                let d: JQueryDeferred<any> = $.Deferred();

                                                attsum.PossibleAttendeeStatuses.done(statuses => {
                                                    d.resolve(statuses.filter((a) => {
                                                        return a.statusid === key;
                                                    })[0]);
                                                })

                                                return d.promise();
                                            }
                                        }),
                                        paginate: false

                                    }),
                                    valueExpr: 'statusid',
                                    displayExpr: 'statustext'
                                }
                            },
                        ]
                    }, {
                        itemType: 'group',
                        items: [
                            {
                                dataField: 'Address',
                                label: { text: 'Street' },
                                editorOptions: <DevExpress.ui.dxTextBoxOptions>{
                                    readOnly: true
                                }
                            }, {
                                dataField: 'City',
                                editorOptions: <DevExpress.ui.dxTextBoxOptions>{
                                    readOnly: true
                                }
                            }, {
                                dataField: 'State',
                                editorOptions: <DevExpress.ui.dxTextBoxOptions>{
                                    readOnly: true
                                }
                            }, {
                                dataField: 'PostalCode',
                                label: { text: 'Zip' },
                                editorOptions: <DevExpress.ui.dxTextBoxOptions>{
                                    readOnly: true
                                }
                            }, {
                                dataField: 'RegistrationDate',
                                label: { text: 'Reg Date' },
                                editorType: 'dxDateBox',
                                editorOptions: <DevExpress.ui.dxDateBoxOptions>{
                                    type: 'datetime',
                                    readOnly: true
                                }
                            },
                        ]
                    }, {
                        itemType: 'group',
                        items: [
                            {
                                dataField: 'Phone',
                                label: { text: 'Ph' },
                                editorOptions: <DevExpress.ui.dxTextBoxOptions>{
                                    mode: 'tel',
                                    readOnly: true
                                }
                            }, {
                                dataField: 'MobilePhone',
                                label: { text: 'Mobile Ph' },
                                editorOptions: <DevExpress.ui.dxTextBoxOptions>{
                                    mode: 'tel',
                                    readOnly: true
                                }
                            }, {
                                dataField: 'Fax',
                                editorOptions: <DevExpress.ui.dxTextBoxOptions>{
                                    mode: 'tel',
                                    readOnly: true
                                }
                            }, {
                                dataField: 'EmailAddress',
                                label: { text: 'Email' },
                                editorOptions: <DevExpress.ui.dxTextBoxOptions>{
                                    mode: 'email',
                                    readOnly: true
                                }
                            }, {
                                dataField: 'ModDate',
                                editorType: 'dxDateBox',
                                editorOptions: <DevExpress.ui.dxDateBoxOptions>{
                                    type: 'datetime',
                                    readOnly: true
                                }
                            },
                        ]
                    }, {
                        itemType: 'group',
                        items: [
                            {
                                dataField: 'Fees.EventFee',
                                label: { text: 'Event Fee' },
                                editorOptions: {
                                    readOnly: true,
                                }
                            }, {
                                dataField: 'Fees.SessionFee',
                                label: { text: 'Session Fee' },
                                editorOptions: {
                                    readOnly: true
                                }
                            }, {
                                dataField: 'Fees.HandoutFee',
                                label: { text: 'Handout Fee' },
                                editorOptions: {
                                    readOnly: true
                                }
                            }, {
                                dataField: 'Fees.TotalDue',
                                label: { text: 'Total Due' },
                                editorOptions: {
                                    readOnly: true
                                }
                            },
                        ]
                    }
                ],
                colCountByScreen: {
                    lg: 4,
                    md: 2,
                    sm: 1,
                    xs: 1
                }
            }).dxForm('instance');

            d.resolve(attsum._AttendeeFormInstance);

        });

        return d.promise();
    }

    private _AttendeeNotesInstance: DevExpress.ui.dxHtmlEditor;
    private get AttendeeNotesInstance(): JQueryPromise<DevExpress.ui.dxHtmlEditor> {
        let attsum = this;
        let d: JQueryDeferred<DevExpress.ui.dxHtmlEditor> = $.Deferred();

        if (attsum._AttendeeNotesInstance) {
            return d.promise(d.resolve(attsum._AttendeeNotesInstance));
        }

        attsum.AttendeeData.done(aData => {

            attsum._AttendeeNotesInstance = $('<div />').dxHtmlEditor({
                value: aData.Notes,
                toolbar: {
                    items: [
                        "separator",
                        "undo",
                        "redo",
                        "clear",
                        "separator",
                        "bold",
                        "color",
                        "italic",
                        "separator",
                        "link",
                        "image",
                        "separator",
                        "strike",
                        "subscript",
                        "superscript",
                        "underline",
                        "blockquote",
                        "header",
                        "separator",
                        "increaseIndent",
                        "decreaseIndent",
                        "separator",
                        "orderedList",
                        "bulletList",
                        "separator",
                        "alignLeft",
                        "alignCenter",
                        "alignRight",
                        "alignJustify",
                        "separator",
                        "background",
                        "codeBlock",
                    ]
                },
                height: '100%'
            }).dxHtmlEditor('instance');

            d.resolve(attsum._AttendeeNotesInstance);
        });

        return d.promise();
    }

    private _AttendeeData: Attendee;
    get AttendeeData(): JQueryPromise<Attendee> {
        let attsum = this;
        let d: JQueryDeferred<Attendee> = $.Deferred();

        if (attsum._AttendeeData) {
            return d.promise(d.resolve(attsum._AttendeeData));
        }

        new TDFRequest({
            url: `Item/Event/${attsum.EventID}/AttendeeSummary?ID=${attsum.ContactOrLeadID}&ItemType=${attsum.ItemType}`,
            type: 'GET'
        }).MakeRequest().done((response) => {
            attsum._AttendeeData = response;
            d.resolve(response);
        });

        return d.promise();
    }

    private EventID: string;
    private ContactOrLeadID: string;
    private ItemType: itemTypes;
    private SessionAttendanceUpdates: { ID: number, Attending: boolean }[] = [];

    constructor(eventID: string, contactOrLeadID: string, itemType: itemTypes) {
        let attsum = this;

        attsum.EventID = eventID;
        attsum.ContactOrLeadID = contactOrLeadID;
        attsum.ItemType = itemType;

        attsum.AttendeeData.done(data => {
            attsum.GetEditAttendeeDialog(data);
        })
    }

    private GetEditAttendeeDialog(data) {
        let attsum = this;

        let bottomTabs = attsum.GetAttendeeBottomTabs(data);

        attsum.AttendeeFormInstance.done(form => {

            let scrollview = $('<div />').height('50%');
            scrollview.append(form.element());

            let content = $('<div />').height('100%').append(
                scrollview,
                $('<div />').css({ 'padding-top': '20px' }),
                bottomTabs.element()
            )

            scrollview.dxScrollView();

            let dialog = new Dialog({
                title: 'Attendee Summary',
                closable: true,
                body: content,
                size: 'size-wide',
                buttons: [
                    {
                        location: 'after',
                        toolbar: 'bottom',
                        widget: 'dxButton',
                        options: <DevExpress.ui.dxButtonOptions>{
                            text: 'Save',
                            type: 'success',
                            icon: 'dx-icon icon-save',
                            onClick: () => {
                                attsum.Save();
                            }
                        }
                    }, {
                        location: 'after',
                        toolbar: 'bottom',
                        widget: 'dxButton',
                        options: <DevExpress.ui.dxButtonOptions>{
                            text: 'Cancel',
                            type: 'danger',
                            icon: 'dx-icon dx-icon-remove',
                            onClick: () => {
                                dialog.close();
                            }
                        }
                    }
                ],
            }, null, true, false);

            dialog.open();
        })
    }

    private GetAttendeeBottomTabs(data): DevExpress.ui.dxTabPanel {
        let attsum = this;

        let tabs = $('<div />').height('49%').dxTabPanel({
            items: [
                'Sessions',
                'Notes'
            ],
            itemTemplate: (itemData, index, element) => {
                switch (itemData) {
                    case 'Sessions':
                        attsum.AttendeeSessionGridInstance.done(grid => {
                            element.append(grid.element());
                        })
                        break;

                    case 'Notes':
                        attsum.AttendeeNotesInstance.done(notes => {
                            element.append(notes.element());
                        })
                        break;
                }
            },
            deferRendering: false
        }).dxTabPanel('instance');

        return tabs;
    }

    private RemoveAttendeeFromSessions(sessionIDs: number[]) {
        let attsum = this;

        attsum.AttendeeSessionGridInstance.done(grid => {
            for (let i = 0, length = sessionIDs.length; i < length; i++) {
                grid.cellValue(grid.getRowIndexByKey(sessionIDs[i]), 'Attending', false);
                attsum.UpdateSessionAttendance(sessionIDs[i]);
            }
        })
    }

    private UpdateSessionAttendance(key, data?: { ID: number, Attending: boolean }) {
        let attsum = this;

        let index = attsum.SessionAttendanceUpdates.map(a => {
            return a.ID;
        }).indexOf(key);

        if (index === -1 && data) {
            attsum.SessionAttendanceUpdates.push({
                ID: data.ID,
                Attending: data.Attending
            });
        } else {
            attsum.SessionAttendanceUpdates.splice(index, 1);
        }
    }

    private Save(): JQueryPromise<void> {
        let attsum = this;
        let d: JQueryDeferred<void> = $.Deferred();

        $.when(
            attsum.AttendeeFormInstance,
            attsum.AttendeeNotesInstance
        ).done((form, notes) => {

            let request = new TDFRequest({
                url: `Item/Event/${attsum.EventID}/AttendeeSummary`,
                type: 'POST',
                data: {
                    Sessions: attsum.SessionAttendanceUpdates,
                    ContactOrLeadID: attsum.ContactOrLeadID,
                    Status: form.option('formData').AttendeeStatus,
                    Notes: notes.option('value')
                }
            }).MakeRequest().done((response) => {
                if (response.Message) {
                    let output: any = {};
                    output.text = response.Message;

                    new Notification({
                        message: output,
                        type: 'error'
                    })

                    attsum.RefreshSummary();

                    d.reject();

                } else {

                    new Notification({
                        message: 'Item Saved',
                        type: 'success'
                    });
                    d.resolve();
                }

                attsum.SessionAttendanceUpdates = [];
            });
        });

        return d.promise();
    }

    private RefreshSummary() {
        let attsum = this;

        attsum._AttendeeData = null;

        attsum.AttendeeData.done(data => {
            $.when(
                attsum.AttendeeFormInstance,
                attsum.AttendeeSessionGridInstance,
                attsum.AttendeeNotesInstance,
            ).done((form, grid, notes) => {
                form.updateData(data);
                grid.option('dataSource', data.Sessions);
                notes.option('value', data.Notes);
            });
        });
    }

}

interface Attendee {
    Address: string,
    AttendeeStatus: number,
    City: string,
    Company: string,
    EmailAddress: string,
    Fax: string,
    Fees: AttendeeFees,
    JobTitle: string,
    MobilePhone: string,
    ModDate: Date,
    Name: string,
    Notes: string,
    Phone: string,
    PostalCode: string,
    RegistrationDate: Date,
    RegistrationNumber: number,
    Sessions: AttendeeSession[],
    State: string
}

interface AttendeeFees {
    EventFee: number,
    HandoutFee: number,
    SessionFee: number,
    TotalDue: number
}

interface AttendeeSession {
    Attending: boolean,
    Ends: Date,
    Enrolled: number,
    ID: number,
    Limit: number,
    Name: string,
    Starts: Date
}