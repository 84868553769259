   /**
             * The type of BI we're in (assuming like desktop that the main BI summary is a superset of Customer BI and that Customer BI)
             * */
            export enum BSISummarySource {
                Customer = 0,
                Vendor = 1,
                Opportunity = 2,
                Contact = 3,
            }

            export enum BIGeneralSummaryStyle {
                CardView = 0,
                ListView = 1,
            }

            export enum BIMenuSection {
                Summary = 1,
                GroupSummary = 2,
                Transactions = 3,
                Advanced = 4
            }
            /**
             * Similar to grid item Type in function
             * */
            export enum BSIGrids {
                Alt1 = 501,
                Alt2 = 502,
                Alt3 = 503,
                County = 504,
                CustomerID = 505,
                ProductGroup = 506,
                ShipTo = 507,
                Vendor = 508,
                Salesperson = 509,
                AcctPkg = 510,
                LineItems = 511,
                Orders = 512,
                Quotes = 513,
                Acct = 514,
                GenericGroupSummary = 515,
                Bookings = 516,
                Inventory = 517,
                MasterID = 518,
                OrderLines = 519,
                QuoteLines = 520,
                AcctRoot = 521,
                PurchaseOrders = 522,
                PurchaseOrderLines = 523,
                VendorBookings = 524,
                VendorInvoices = 525,
                VendorInvoiceLines = 526,
                VendorProducts = 527,
                SalesInvoices = 528,
                SalesInvoiceLines = 529,
                VendorSalesLineItems = 530,
                BSIJobLineItems = 535,
                BSIJobOpenOrders = 537,
                BSIJobOpenOrderLines = 538,
                BSIJobOpenQuotes = 539,
                BSIJobOpenQuoteLines = 540,
                BSIJobPurchaseOrders = 541,
                BSIJobPurchaseOrderLines = 542,
                BSIJobBookings = 543,
                BSIJobSalesInvoices = 544,
                BSIJobSalesInvoiceLines = 545,
                BSIJobVendorInvoices = 546,
                BSIJobVendorInvoiceLines = 547,
                BSIJobAlt1GroupSummary = 551,
                BSIJobAlt2GroupSummary = 552,
                BSIJobAlt3GroupSummary = 553,
                BSIJobProductGroupGroupSummary = 554,
                BSIJobAcctPkgGroupSummary = 555,
                BSIJobAcctGroupSummary = 556,
                BSIJobVendorGroupSummary = 557,
                VendorAlt1GroupSummary = 561,
                VendorAlt2GroupSummary = 562,
                VendorAlt3GroupSummary = 563,
                VendorProductGroupGroupSummary = 564,
                VendorAcctPkgGroupSummary = 565,
                VendorInventoryValue = 570,
                BSIAdvancedAnalytics = 600,
                BSIContactItemSalesHistory = 608,
                BSIContactOpenOrders = 610,
                BSIContactOpenOrderLines = 611,
                BSIContactOpenQuotes = 612,
                BSIContactOpenQuoteLines = 613,
                BSIContactBookings = 614,
                BSIContactSalesInvoices = 615,
                BSIContactSalesInvoiceLines = 616,
                BSIContactAlt1GroupSummary = 617,
                BSIContactAlt2GroupSummary = 618,
                BSIContactAlt3GroupSummary = 619,
                BSIContactProductGroupGroupSummary = 620,
                BSIContactAcctPkgGroupSummary = 621,
                BSIContactAcctGroupSummary = 622,
                BSIContactVendorGroupSummary = 623,
                BIFormDialogInvoice = 630,
                BIFormDialogOrder = 631,
                BIFormDialogQuote = 632,
                BIFormDialogInvoiceFromOBI = 640,
                BIFormDialogOrderFromOBI = 641,
                BIFormDialogQuoteFromOBI = 642,
                BIFormDialogPurchaseOrderFromOBI = 643,
                BIFormDialogVendorInvoiceFromOBI = 644,
                BIFormDialogPurchaseOrderFromVBI = 653,
                BIFormDialogVendorInvoiceFromVBI = 654,
                BIFormDialogInvoiceFromCBI = 660,
                SalesSummary = 700,
                GeneralSummary = 705,
                JobSalesSummary = 710,
                JobGeneralSummary = 715,
                VendorSalesSummary = 720,
                VendorGeneralSummary = 725,
                ContactSalesSummary = 730,
                ContactGeneralSummary = 735,
                GapAnalysis = 740
            }

            export namespace ISummary {
                export namespace SummaryInterfaces {
                    export namespace cBSID {
                        /**
            * the different types of selection that BI summary settings can be selected at and are thus used when identifying the selection as far as what the selection is
            * */
                        export enum EnumType {
                            InvalidType = -1,
                            AcctPkg = 0,
                            AcctID = 1,
                            AcctRoot = 2,
                            SalesRep = 3,
                            BillTo = 4,
                            Master = 5,
                            Custom = 6,
                            Level3 = 7,
                            Level4 = 8,
                            LeadID = 9
                        }
                    }
                }

            }
           
            /**
             * Essentially used by the breadcrumbs and drill down tracking to relate the various drill-down levels to the type of selection that needs applied to the filtering in the query
             * */
            export enum ChildGridTypes {
                vend = 0,
                prod = 1,
                alt1 = 2,
                alt2 = 3,
                acct = 4,
                cty = 5,
                shipto = 6,
                slsman = 7,
                acctpkg = 8,
                acctroot = 9,
                custid = 10,
                alt3 = 11,
                master = 12,
                lineitems = 13,
                level3 = 14
            }
            /**
             * The 4 sections of the sales summary.  Probably used for building the preferences for pinning those sections closed
             * */
            export enum BISalesSummaryType {
                Annual = 0,
                Quarterly = 1,
                Monthly = 2,
                Daily = 3,
            }

            export enum BSIGoalType {
                TotalAccount = 0,
                PeriodAccount = 1,
                TotalAcctPkg = 2,
                PeriodAcctPkg = 3,
                TotalAcctRoot = 4,
                PeriodAcctRoot = 5,
                TotalSalesperson = 6,
                PeriodSalesperson = 7,
                ActualAcctPkgRollup = 8,
                ActualAccountRollup = 9,
                ActualAcctRootRollup = 10,
                ActualSalesPersonRollup = 11,
            }

            export enum EnumBSIPermissionsPrimary  {
                SummaryTypeAccount = 1,
                SummaryTypeCustID = 2,
                SummaryTypeAcctRoot = 4,
                SummaryTypeAcctPkg = 8,
                SummaryTypeSalesmanID = 16,
                SummaryTypeMasterID = 32,
                SummaryTypeAnalysis = 64,
                SummaryTypeAccountOnly = 128,
                SummaryTypeVendorBI = 256,
                SummaryTypeOpportunityJobSummary = 512,
                SummaryTypeContactSummary = 1024
            }

            export enum EnumBSIPermissionsSecondary {
                AllowSalesSummary = 1,
                AllowGeneralSummary = 2,
                AllowGroupSummary = 4,
                AllowLineItems = 8,
                AllowOpenOrders = 16,
                AlllowOpenQuotes = 32,
                AllowGrossProfit = 64,
                AllowBookings = 128,
                AllowGrossProfit2 = 256,
                AllowAdvancedAnalytics = 512,
                AllowInventory = 1024,
                AllowPurchaseOrders = 2048,
                AllowVendorInvoices = 4096,
                AllowTopAccounts = 8192,
                AllowCharts = 16384
            }

            export enum GapRollUpType {
                AccountID = 1,
                BSIMasterAcctID = 4,
                BSICustomerID = 8,
                AcctPkgID = 256,
                AcctRootID = 512,
                SalesmanID = 1024
            }