import { BSISummarySource } from '../../enums/bi/enums';
import { DataScopeSelectionTypes } from '../../enums/user/datascope/enums';
import {
  DataScopeSelectorModel,
  ItemListingNameModel
} from '../../interfaces/interfaces';
import { TDFRequest } from '../request';

export class SummarySettings {
  DataScopeSelectorModelRequest(
    ScopeSelectionType: DataScopeSelectionTypes
  ): JQueryPromise<DataScopeSelectorModel> {
    let SummarySettings = this;
    let d: JQueryDeferred<DataScopeSelectorModel> = $.Deferred();
    new TDFRequest({
      url: '/core/user/GetDataScopeSelectorModel',
      type: 'GET',
      data: { listingSource: ScopeSelectionType }
    })
      .MakeRequest()
      .done(function(response: DataScopeSelectorModel) {
        d.resolve(response);
      });
    return d.promise();
  }

  GetFolderChildren(id, level) {
    let SummarySettings = this;
    let d = $.Deferred();
    new TDFRequest({
      url: '/core/user/GetFolderChildren/',
      data: { id: id, baseChildLevel: level }
    })
      .MakeRequest()
      .done(function(response) {
        d.resolve(response);
      });

    return d.promise();
  }

  GetSavedSelectionList() {
    let SummarySettings = this;
    let d = $.Deferred();
    new TDFRequest({ url: '/core/user/SavedSelectionsList' })
      .MakeRequest()
      .done(response => {
        d.resolve(response);
      });
    return d.promise();
  }

  GetRepList() {
    let SummarySettings = this;
    let d = $.Deferred();
    new TDFRequest({ url: '/core/user/GetRepList/' })
      .MakeRequest()
      .done(response => {
        d.resolve(response);
      });
    return d.promise();
  }

  GetVerifyRightRequest(homeType: number) {
    let d = $.Deferred();
    new TDFRequest({
      url: '/BI/VerifyBISummaryRights',
      type: 'Get',
      data: { homeType: homeType }
    })
      .MakeRequest()
      .done(function(response) {
        d.resolve(response);
      });
    return d.promise();
  }

  GetItemListWithNamesRequest(
    guidList: string,
    isSalesman: boolean,
    BiType: BSISummarySource = -1
  ) {
    let d = $.Deferred();
    let endpoint = {
      [-1]: 'GetItemListWithNames',
      [BSISummarySource.Opportunity]: 'GetOpportunityListWithNames',
      [BSISummarySource.Vendor]: 'GetVendorName',
      [BSISummarySource.Contact]: 'GetContactInfo'
    };
    new TDFRequest({
      url: `/core/user/${endpoint[BiType]}`,
      type: 'GET',
      data: { guidsList: guidList, bSalesman: isSalesman }
    })
      .MakeRequest()
      .done(function(response: ItemListingNameModel) {
        d.resolve(response);
      });
    return d.promise();
  }

  GetSavedSelectionRequest(id: any) {
    let d = $.Deferred();
    new TDFRequest({
      url: '/bi/savedselection/',
      type: 'GET',
      data: { savedSelectionID: id }
    })
      .MakeRequest()
      .done(function(response) {
        d.resolve(response);
      });
    return d.promise();
  }

  AllowableSalespeople() {
    let d = $.Deferred();
    new TDFRequest({
      url: '/BI/AllowableSalespeople'
    })
      .MakeRequest()
      .done(response => {
        d.resolve(response);
      });
    return d.promise();
  }
}
