import * as Globalize from 'globalize';
import { Dialog } from '../dialogs/dialog';
import { itemTypes } from '../../enums/enums';
import { IGridDataResponseMeta } from '../../interfaces/interfaces';
import { Grid } from '../../legacy-centers-grids/grid';
import { GridSetup } from '../../services/grid/helpers/gridsetup';
import { TDFRequest } from '../../services/request';
import {
  DomSafeID,
  GetDataResponseObject,
  AddToRecent
} from '../../util/allutils';
import dxDataSource from 'devextreme/data/data_source';
import dxCustomStore from 'devextreme/data/custom_store';
import { GridToolbar } from '../grids/gridtoolbars/gridtoolbar';
import { LoadCompany } from '../../infrastructure/context';
import { GridWithoutViews } from '../../legacy-centers-grids/gridwithoutviews';
import * as Cookies from "js-cookie";
import "devextreme/viz/pie_chart";
//"use strict"
interface IRequestOffset {
  Value: any;
  OffsetType: OffsetTypeEnum;
}

enum OffsetTypeEnum {
  Numeric,
  String
}

interface IDrillDownRequest {
  MessageID: string;
  Offset: IRequestOffset;
  DrilldownType: string;
  NextOffset: IRequestOffset;
  PreviousOffset: IRequestOffset;
  PageSize: number;
  HasMore: boolean;
}
enum ValidProductsEnum {
  None,
  ActOn,
  ConstantContact,
  MailChimp,
  Hubspot,
  Emarsys
}

export class Mailing {
  private _RecipientCountRequest: TDFRequest;
  private _MessageContentRequest: TDFRequest;
  private _ReportRequest: TDFRequest;
  private _FocusedDrillDown: string;
  private _DrillDownRequestObj: IDrillDownRequest = null;
  private _DrillDownReportRequest: TDFRequest;
  private _Meta: any;
  private _DrillDownVisibleFields: any[];
  private _RawDrillDownData: any[];
  private _ToolbarModel?: any;
  get ItemType() {
    return itemTypes.itemMailing;
  }
  get TypeName() {
    return 'Mailing';
  }
  set TypeName(name) {
    if (name && this.TypeName !== name) this.TypeName = name;
  }
  get TDFMailingID() {
    return this['TDF GUID'] || this.ItemID;
  }
  get HeaderID() {
    return `${this.TypeName}-header-container-${this.DomSafeID}`;
  }
  get ContentID() {
    return `${this.TypeName}_Content_${this.DomSafeID}`;
  }
  get MailingContactsGridID() {
    return `${this.ContentID}-contacts`;
  }
  '';
  get MailingContactsGridToolbarID() {
    return `${this.ContentID}-contacts-toolbar`;
  }
  get MailingLoadContactsLinkID() {
    return `load-mailing-contacts-${this.DomSafeID}`;
  }
  get MailingLinkExternalID() {
    return `load-all-mailings-${this.DomSafeID}`;
  }
  get MailingMessageContainerID() {
    return `-mailing-message-${this.DomSafeID}`;
  }
  get ReportSyncStatusContainerID() {
    return `mailing-report-sync-status-${this.DomSafeID}`;
  }
  get ReportChartContainerID() {
    return `mailing-report-chart-${this.DomSafeID}`;
  }
  get ReportStatsStatusContainerID() {
    return `mailing-report-stats-${this.DomSafeID}`;
  }
  get ReportDrillDownTabsContainerID() {
    return `mailing-report-tabs-${this.DomSafeID}`;
  }
  get ReportContainerID() {
    return `mailing-report-${this.DomSafeID}`;
  }
  get DomSafeID(): string {
    return DomSafeID(this['TDF GUID']);
  }

  private ExternalID: string;
  private Subject: string;
  private NumContacts: number = 0;
  private ExternalSubject: string;
  private Toolbar: GridToolbar;
  private ContactsGrid: Grid;
  private RequestModel: GridSetup.LinkedItemsRequestModel;
  private ItemID: string;
  private 'TDF GUID': string;
  private Dialog: Dialog;
  private Grid: Grid;
  private Title: JQuery;
  private ServerPageSize: number = 100;
  private MainTabsInstance: DevExpress.ui.dxTabPanel;
  private DrillDownTabsInstance: DevExpress.ui.dxTabPanel;

  get CorrectDrillDownType(): string {
    let mailing = this;
    let title: string =
      mailing.DrillDownTabsInstance &&
        mailing.DrillDownTabsInstance.option('selectedItem')
        ? mailing.DrillDownTabsInstance.option('selectedItem').title
        : '';
    return title.toUpperCase();
  }
  get ExternalSubjectLinkID() {
    let mailing = this;
    return ``;
  }
  get ExternalSubjectLink() {
    let mailing = this;
    return $('<div />')
      .append(
        $(`<span>External Subject: </span>`),
        mailing.GetButton(
          mailing.ExternalSubject || 'select',
          mailing.AddLinkMailingClickHandler.bind(mailing)
        )
      );
  }
  get MailingSubjectBox() {
    let mailing = this;
    let opts: DevExpress.ui.dxTextBoxOptions = {
      value: mailing.Subject || 'No Data for Subject',
      readOnly: true
    };
    return $('<div />').dxTextBox(opts);
  }
  get LoadContactsLink() {
    let mailing = this;
    return mailing.GetButton(
      `Load ${mailing.NumContacts} Contacts`,
      mailing.AddContactClickHandler.bind(mailing)
    );
  }
  get RecipientsTabTopRow() {
    let mailing = this;
    return $('<div class="row shadow-pad" />')
      .append(
        $('<div class="col-sm-3" />')
          .append(
            mailing.LoadContactsLink
          ),
        $('<div class="col-sm-4 col-sm-push-5" />')
          .append(
            mailing.ExternalSubjectLink
          )
      );
  }
  get ContactsGridContainer() {
    let mailing = this;
    return `<div class="container-fluid">
                    <div id="${mailing.MailingContactsGridToolbarID}"></div>
                    <div id="${mailing.MailingContactsGridID}"></div>
                    </div>
                    `;
  }
  get MailingContactsGridContainer() {
    let mailing = this;
    return `<div id="${
      mailing.MailingContactsGridToolbarID
      }"> </div>><div id="${mailing.MailingContactsGridID}"></div>`;
  }
  get ReportContainer() {
    let mailing = this;
    return `
                    <div id="${
      mailing.ReportContainerID
      }" class="container-fluid">                    
                    <div class="row ">
                    <div class="col-sm-12">
                    <div id="${mailing.ReportSyncStatusContainerID}"></div>
                    </div>  
                    <div class="col-sm-6  shadow" >
                    <div id="${mailing.ReportChartContainerID}"></div>
                    </div>
                    <div class="col-sm-3 ">
                    <div id="${mailing.ReportStatsStatusContainerID}-a"></div>
                    </div>
                    <div class="col-sm-3 shadow-warning">
                    <div id="${mailing.ReportStatsStatusContainerID}-b"></div>
                    </div>
                    </div>
                    <div class="row ">
                    <div class="col-sm-12">
                    <div id="${mailing.ReportDrillDownTabsContainerID}"></div>
                    </div>                    
                    </div>                    
                    </div>
                    `;
  }
  get FocusedDrillDown() {
    let mailing = this;
    mailing.FocusedDrillDown = mailing.CorrectDrillDownType;

    return mailing._FocusedDrillDown;
  }
  get Meta() {
    let mailing = this;
    let dfd = $.Deferred();

    if (mailing._Meta) {
      return dfd.promise(dfd.resolve(mailing._Meta));
    } else {
      new TDFRequest({
        url: `${TDFRequest.ApiPath}/marketing/marketingcore/Meta/`
      })
        .MakeRequest()
        .done(meta => {
          if (meta) {
            mailing._Meta = meta;
            dfd.resolve(mailing._Meta);
          }
        });
    }
    return dfd.promise();
  }
  set FocusedDrillDown(type) {
    let mailing = this;
    mailing._FocusedDrillDown = type || mailing.CorrectDrillDownType;
  }
  get MessageContentContainer(): JQueryPromise<JQuery> {
    let mailing = this;
    let d: JQueryDeferred<JQuery> = $.Deferred();

    let fakeReq = new TDFRequest({ url: 'fake' });
    fakeReq.GetToken().done(authToken => {

      let frame = $(`    
      <iframe id="${
        mailing.MailingMessageContainerID
        }" style="width:100%;height:100%" src="${
        TDFRequest.ApiPath
        }/marketing/marketingcore/MessageContents/${
        mailing.ExternalID
        }/?access_token=${authToken}"></iframe>
    `);

      d.resolve(frame);
    });

    return d.promise();
  }
  get MailingContentRequest() {
    let mailing = this;
    mailing._MessageContentRequest = new TDFRequest({
      url: `${TDFRequest.ApiPath}/marketing/marketingcore/MessageContents/${
        mailing.ExternalID
        }`
    });
    return mailing._MessageContentRequest;
  }
  get MessageReportRequest() {
    let mailing = this;
    mailing._ReportRequest = new TDFRequest({
      url: `${TDFRequest.ApiPath}/marketing/marketingcore/messagereport/${
        mailing.ExternalID
        }`
    });
    return mailing._ReportRequest;
  }
  get RecipientCountRequest() {
    let mailing = this;
    mailing._RecipientCountRequest = new TDFRequest({
      url: `${TDFRequest.ApiPath}/marketing/marketingcore/message/${
        mailing.ExternalID
        }/count`
    });
    return mailing._RecipientCountRequest;
  }
  get DrillDownRequestObj() {
    let mailing = this;
    let dfd: JQueryDeferred<IDrillDownRequest> = $.Deferred();

    if (!mailing._DrillDownRequestObj) {
      new TDFRequest({
        url: `${TDFRequest.ApiPath}/marketing/marketingcore/DrilldownRequest/`,
        type: 'GET'
      })
        .MakeRequest()
        .done((ddr: IDrillDownRequest) => {
          ddr.MessageID = mailing.ExternalID;
          ddr.DrilldownType = mailing.FocusedDrillDown;
          mailing._DrillDownRequestObj = ddr;
          dfd.resolve(mailing._DrillDownRequestObj);
        });
    } else {
      mailing._DrillDownRequestObj.DrilldownType = mailing.FocusedDrillDown;
      return dfd.promise(dfd.resolve(mailing._DrillDownRequestObj));
    }
    return dfd.promise();
  }
  get DrillDownReportRequest() {
    let mailing = this;
    let dfd: JQueryDeferred<TDFRequest> = $.Deferred();
    mailing.DrillDownRequestObj.done((ddr: IDrillDownRequest) => {
      mailing._DrillDownReportRequest = new TDFRequest({
        url: `${
          TDFRequest.ApiPath
          }/marketing/marketingcore/DrilldownReport/?loadAll=false`,
        data: ddr,
        type: 'POST'
      });
      mailing._DrillDownReportRequest.spinnerContainerID =
        mailing.ReportDrillDownTabsContainerID;
      dfd.resolve(mailing._DrillDownReportRequest);
    });
    return dfd.promise();
  }
  get DrillDownTabs() {
    let mailing = this;
    let dfd = $.Deferred();
    let tabs: DevExpress.ui.dxTabPanelItemTemplate[] = [];
    mailing.Meta.done(meta => {
      Object.keys(meta).map(k => {
        if (k === 'AllowableDrilldownTypes') {
          meta[k].forEach(type => {
            tabs.push({ title: type });
          });
        }
      });
      dfd.resolve(tabs);
    });

    return dfd.promise();
  }
  get AllMailingsRequest() {
    let mailing = this;
    return new TDFRequest({
      url: `${TDFRequest.ApiPath}/marketing/marketingcore/SentMessages`
    });
  }
  get LinkMailingRequest() {
    let mailing = this;
    return new TDFRequest({
      url: `${TDFRequest.ApiPath}/marketing/marketingcore/linkmailing/`,
      type: 'POST'
    });
  }
  get MainTabs(): DevExpress.ui.dxTabPanelItemTemplate[] {
    let mailing = this;
    let tabs: DevExpress.ui.dxTabPanelItemTemplate[] = [
      { title: 'Recipients' }
    ];
    if (mailing.ExternalID) {
      tabs.push({ title: 'Message Content' }, { title: 'Message Summary' });
    }
    return tabs;
  }
  get MailingInfoFromID() {
    let mailing = this;
    let dfd = $.Deferred();
    new TDFRequest({
      url: `${TDFRequest.ApiPath}/marketing/marketingcore/GetMailingByID/${
        mailing.ItemID
        }`
    })
      .MakeRequest()
      .done(response => {
        Object.keys(response).forEach(k => {
          if (!mailing[k]) {
            mailing[k] = response[k];
          }
        });
        if (
          !mailing.NumContacts &&
          (mailing['Recipients'] || response.Recipients)
        ) {
          mailing.NumContacts = mailing['Recipients'] || response.Recipients;
        }
        dfd.resolve(mailing.ExternalID);
      });

    return dfd.promise();
  }
  get DrillDownGridColums() {
    let mailing = this;
    let columns: DevExpress.ui.dxDataGridColumn[] = [];
    if (mailing._RawDrillDownData.length) {
      Object.keys(mailing._RawDrillDownData[0]).map(drdd => {
        let col: DevExpress.ui.dxDataGridColumn = {
          dataField: drdd,
          visible: false,
          caption: '',
          dataType: 'string',
          format: null
        };
        if ($.inArray(drdd, ['FirstAction', 'LastAction']) > -1) {
          col.dataType = 'datetime';
          col.format = 'longDateLongTime';
        }
        columns.push(col);
        mailing._DrillDownVisibleFields.map(vf => {
          if (drdd === vf) {
            col.visible = true;
            col.caption = vf;
          }
        });
      });
    } else {
      mailing._DrillDownVisibleFields.map(vf => {
        let col: DevExpress.ui.dxDataGridColumn = {
          dataField: vf,
          visible: true,
          caption: vf,
          dataType:
            $.inArray(vf, ['FirstAction', 'LastAction']) > -1
              ? 'datetime'
              : 'string',
          format: null
        };
        columns.push(col);
      });
    }
    return columns;
  }

  constructor(args) {
    let mailing = this;
    $.each(args, function (key, val) {
      try {
        mailing[key] = val;
      } catch (e) {
        console.log(e);
      }

    });

    mailing['TDF GUID'] = mailing['TDF GUID'] || mailing.ItemID || '';
    if (!mailing.ItemID && mailing['TDF GUID']) {
      mailing.ItemID = mailing['TDF GUID'];
    }
  }

  Initialize() {
    let mailing = this;
    LoadCompany().done(() => {
      if (mailing.ExternalID) {
        mailing.Render();
      } else {
        mailing.MailingInfoFromID.done(() => {
          mailing.Render();
        });
      }
    });
  }

  GetButton(text, action) {
    let button = $('<div />').dxButton({
      text: text,
      onClick: action,
      type: 'default'
    }).dxButton('instance');

    return button.element();
  }

  CreateDialog(content) {
    let mailing = this;
    mailing.Dialog = new Dialog(
      {
        closable: true,
        title: 'Mailing Info',
        body: $('<div />')
          .append(mailing.MailingSubjectBox)
          .append(content)
      },
      null,
      true
    );
    mailing.Dialog.open()
  }

  AddContactClickHandler() {
    let mailing = this;
    mailing.GetToolbar();
  }

  AddLinkMailingClickHandler() {
    let mailing = this;
    mailing.AllMailingsRequest.MakeRequest().done(response => {
      mailing.LinkToExternalMessageSelectionDialog(response);
    });
  }

  LinkToExternalMessageSelectionDialog(data) {
    let mailing = this;
    let dlg: Dialog;

    //let grid = $("<div/>").dxDataGrid(opts).dxDataGrid("instance");
    //let sopt: DevExpress.ui.dxScrollViewOptions = {
    //    height: "100%"
    //}
    // let sv = $("<div/>").dxScrollView(opts).dxScrollView("instance")
    dlg = new Dialog(
      {
        id: 'mailing-external-link-lg',
        body: $("<div id='mailing-external-link-grid' />"),
        closable: true,
        size: 'size-normal',
        type: 'type-info',
        title: 'Select External Mailing to Link'
      },
      null,
      true,
      false
    );
    dlg.open().done(() => {
      let opts: DevExpress.ui.dxDataGridOptions = {
        ////dataSource: data,
        columns: [
          {
            dataField: 'Id',
            visible: false
          },
          {
            dataField: 'Subject',
            dataType: 'string',
            allowFiltering: true,
            allowSorting: true,
            allowHeaderFiltering: true
          },
          {
            dataField: 'SentDate',
            dataType: 'datetime',
            allowFiltering: true,
            allowSorting: true,
            allowHeaderFiltering: true,
            format: 'longDateLongTime'
          }
        ],
        filterRow: { visible: true, showOperationChooser: true },
        headerFilter: { visible: true },
        columnResizingMode: 'widget',
        groupPanel: { visible: false },
        searchPanel: { visible: true },
        paging: { enabled: true, pageSize: 100, pageIndex: 0 },
        pager: {
          allowedPageSizes: [10, 15, 20, 30, 50, 100],
          showInfo: true,
          showPageSizeSelector: true,
          visible: true,
          showNavigationButtons: true,
          infoText: 'Page #{0}. Total: {1} ({2} items)'
        },
        selection: { mode: 'single' },
        height: '100%',
        onSelectionChanged(e) {
          if (e.selectedRowsData.length && e.selectedRowsData[0]['Id']) {
            dlg.close();
            let req = mailing.LinkMailingRequest;
            req.Settings.data = {
              TDFMailingID: mailing.ItemID,
              ExternalMailingID: e.selectedRowsData[0]['Id']
            };
            req.MakeRequest().done(response => { });
          }
        }
      };
      let grid = new GridWithoutViews(
        GetDataResponseObject(data),
        { GridContainer: $("#mailing-external-link-grid") },
        opts
      );
      grid.Render();
    });
  }

  AddMainTabs() {
    let mailing = this;

    let tabopts: DevExpress.ui.dxTabPanelOptions = {
      dataSource: new dxDataSource({
        store: new dxCustomStore({
          load(options) {
            let d = $.Deferred();
            return d.promise(d.resolve(mailing.MainTabs));
          }
        })
      }),

      itemTemplate(data, index, element: JQuery) {
        switch (data.title) {
          case 'Recipients':
            if (mailing.ExternalID) {
              mailing.RecipientCountRequest.MakeRequest().done(response => {
                mailing.NumContacts = response.Result;
                element
                  .append(mailing.RecipientsTabTopRow)
                  .append(mailing.ContactsGridContainer);
                //mailing.AddContactClickHandler();
                //mailing.AddLinkMailingClickHandler();
              });
            } else {
              if (mailing.NumContacts) {
                element
                  .append(mailing.RecipientsTabTopRow)
                  .append(mailing.ContactsGridContainer);

                //if (this.NAME && this.NAME === "dxTabPanel") {
                //    (this as dxTabPanel).option("onContentReady", () => {
                //        mailing.AddContactClickHandler();
                //        mailing.AddLinkMailingClickHandler();
                //    })
                //}
              }
            }
            break;
          case 'Message Content':
            mailing.MessageContentContainer.done(container => {
              element
                .css({
                  height: $(mailing.Dialog.Instance.content()).innerHeight(),
                  width: $(mailing.Dialog.Instance.content()).innerWidth()
                })
                .append(container);
            })
            //mailing.MailingContentRequest.MakeRequest().done((response) => {
            //    $(`#${mailing.MailingMessageContainerID}`).html(response);
            //});
            break;

          case 'Message Summary':
            element.append(mailing.ReportContainer);
            mailing.MessageReportRequest.MakeRequest().done(response => {
              mailing.RenderReport(response);
              mailing.RenderDillDownTabs();
            });
        }
      }
    };
    mailing.MainTabsInstance = $('<div />')
      .dxTabPanel(tabopts)
      .dxTabPanel('instance');
    mailing.CreateDialog(mailing.MainTabsInstance.element());
  }

  Render() {
    let mailing = this;
    AddToRecent(mailing.ItemID, mailing.ItemType);
    mailing.AddMainTabs();
  }

  RenderReport(summary) {
    let mailing = this;

    let summaryChartValues = [
      { display: 'Opened, did not click', value: summary['UniqueOpens'] },
      { display: 'Clicked', value: summary['UniqueClicks'] },
      { display: 'Did not open', value: summary['NotOpened'] },
      { display: 'Bounced', value: summary['Bounced'] }
    ];

    let chartopts: DevExpress.viz.charts.dxPieChartOptions = {
      dataSource: summaryChartValues,
      palette: ['#337ab7', '#5cb85c', '#f0ad4e', '#d9534f'],
      // theme: "generic.dark",
      pointSelectionMode: 'multiple',
      series: [
        {
          argumentField: 'display',
          valueField: 'value',

          label: {
            visible: true,
            connector: {
              visible: true,
              width: 1
            },
            customizeText: function (point) {
              return point.argumentText + ': ' + point.valueText;
            }
          }
        }
      ],
      title: 'Mailing Summary'
    };

    try {
      $(`#${mailing.ReportChartContainerID}`).dxPieChart(chartopts);
    } catch (e) {
      console.log("Rending pie chart:")
      console.log(e)
    }

    let percentages = [
      {
        display: 'Opened',
        value: summary.UniqueOpens,
        percent: summary['UniqueOpens'] / summary['TotalSent'],
        callout: 'info'
      },
      {
        display: 'Bounced',
        value: summary.Bounced,
        percent: summary['Bounced'] / summary['TotalSent'],
        callout: 'danger'
      },
      {
        display: 'Did Not Open',
        value: summary.NotOpened,
        percent: summary['NotOpened'] / summary['TotalSent'],
        callout: 'warning'
      },
      {
        display: 'Clicked',
        value: summary.UniqueClicks,
        percent: summary['UniqueClicks'] / summary['TotalSent'],
        callout: 'success'
      }
    ];
    let summarieslist = [
      { display: 'Opt-Outs', value: summary['OptOut'] },
      { display: 'Spam Complaints', value: summary['Spam'] },
      { display: 'Total Opens', value: summary['TotalOpens'] },
      { display: 'Total Effective Opens', value: summary['UniqueOpens'] },
      { display: 'Total Times Clicked', value: summary['TotalClicks'] }
    ];
    percentages.forEach(k => {
      $(`#${mailing.ReportStatsStatusContainerID}-a`).append(
        $(`<div class="bs-callout wide bs-callout-${k.callout} ">
                        <h4 class="inline">${k.display}:</h4>   
                        <h5 class="inline" style="margin-left:5px;">${
          k.value
          }</h5>
                        <h3 class="inline pull-right"> ${Globalize.formatNumber(
            k.percent || 0.0,
            {
              style: 'percent',
              minimumFractionDigits: 2,
              maximumFractionDigits: 2
            }
          )}</h3>
                       </div>`)
      );
    });
    summarieslist.forEach(k => {
      $(`#${mailing.ReportStatsStatusContainerID}-b`).append(
        `<h4><span>${k.display}:</span><span style="margin-left:5px;">${
        k.value
        }</span></h4>`
      );
    });
  }

  RenderDillDownTabs() {
    let mailing = this;
    let opts: DevExpress.ui.dxTabPanelOptions = {
      dataSource: new dxDataSource({
        store: new dxCustomStore({
          load(options) {
            return mailing.DrillDownTabs;
          }
        })
      }),
      itemTemplate(data, index, element: JQuery) {
        //let ddType = mailing.CorrectDrillDownType;
        mailing.FocusedDrillDown = data.title;
        mailing.RequestDrillDownData().done(() => {
          element.append(
            $('<div/>')
              .dxDataGrid({
                dataSource: mailing._RawDrillDownData,
                columns: mailing.DrillDownGridColums
              })
              .dxDataGrid('instance')
              .element()
          );
        });
      }
      //onSelectionChanged(e) {
      //    if (e.removedItems.length) {
      //        mailing.FocusedDrillDown = e.addedItems[0].title;
      //        mailing.RequestDrillDownData();
      //    }
      //},
      //onContentReady(e) {
      //    mailing.RequestDrillDownData();
      //}
    };
    mailing.DrillDownTabsInstance = $(
      `#${mailing.ReportDrillDownTabsContainerID}`
    )
      .css({ 'margin-top': '20px' })
      .dxTabPanel(opts)
      .dxTabPanel('instance');
  }

  RequestDrillDownData() {
    let mailing = this;
    let dfd = $.Deferred();
    mailing.DrillDownReportRequest.done((req: TDFRequest) => {
      req.MakeRequest().done(response => {
        mailing._DrillDownRequestObj = response.Request;
        mailing._RawDrillDownData = response.Data;
        mailing._DrillDownVisibleFields = response.VisibleFields;

        dfd.resolve();
      });
    });

    return dfd.promise();
  }

  CreateContactsRequest(viewGUID?) {
    let mailing = this;
    let args = new GridSetup.GridRequestArgs();

    args.GUID = viewGUID
      ? viewGUID
      : mailing.Toolbar
        ? mailing.Toolbar.DefaultView
        : '';
    args.page = 1;
    args.pageSize = 100;
    args.IncludeGridViewInformation = true;
    args.ContainerID = mailing.ItemID;
    args.ItemType = mailing.ItemType;

    mailing.RequestModel = new GridSetup.LinkedItemsRequestModel(args);
    if (mailing.Toolbar) {
      mailing.Toolbar.GridDataRequestModel = mailing.RequestModel;
    }
  }

  GetToolbar() {
    let mailing = this;
    let d = $.Deferred();
    let request = new TDFRequest({
      url: '/core/user/GetToolbarInfo/',
      type: 'GET',
      data: { type: itemTypes.itemContact, containertype: 'mailing' }
    });
    request.MakeRequest().done(function (response) {
      // response.OnViewChanged = linkeditems.ViewChangedEvent.bind(linkeditems);
      mailing._ToolbarModel = response;
      mailing.CreateToolbar().done(function () {
        d.resolve();
      });
    });
    return d.promise();
  }

  CreateToolbar() {
    let mailing = this;
    let d = $.Deferred();
    mailing.Toolbar = new GridToolbar(mailing._ToolbarModel);

    mailing.Toolbar.ToolbarContainerID = mailing.MailingContactsGridToolbarID;
    mailing.Toolbar.ContainerItemID = mailing.ItemID;

    mailing.Toolbar.MakeGrid = mailing.MakeGrid.bind(mailing);

    if (!mailing.Toolbar.CurrentView && mailing.Toolbar.DefaultView)
      mailing.Toolbar.CurrentView = mailing.Toolbar.DefaultView;
    mailing.CreateContactsRequest();
    mailing.Toolbar.GridDataRequestModel = mailing.RequestModel;
    mailing.Toolbar.RenderAndLoad();

    return d.promise(d.resolve());
  }

  BuildPagingInfo(metaInfo: IGridDataResponseMeta): string {
    let mailing = this;
    let infoText: string = '';
    if (metaInfo != undefined) {
      let serverPageSize: number = mailing.ServerPageSize;
      let serverPageCount: number =
        metaInfo.TotalPages > 0 ? metaInfo.TotalPages : 1;
      let endRecordCount: number =
        (metaInfo.CurrentPage - 1) * serverPageSize + serverPageSize <
          metaInfo.TotalRecords
          ? (metaInfo.CurrentPage - 1) * serverPageSize + serverPageSize
          : metaInfo.TotalRecords;
      let currentPageRecordInfo: string =
        metaInfo.CurrentPage > 1
          ? (metaInfo.CurrentPage - 1) * serverPageSize +
          1 +
          '-' +
          endRecordCount
          : metaInfo.CurrentPage + '-' + serverPageSize;
      let totalCount: number = metaInfo.TotalRecords; // > 0 ? metaInfo.TotalRecords : results.Data.length;

      infoText =
        metaInfo.TotalRecords >= serverPageSize
          ? 'Records: (' +
          currentPageRecordInfo +
          ') Of ' +
          totalCount +
          ' Total Records '
          : 'Total Records ' + totalCount;
    }

    return infoText;
  }

  MakeGrid(response) {
    let mailing = this;
    response.GridContainer = $('#' + mailing.MailingContactsGridID);
    let pagingInfo = mailing.BuildPagingInfo(response.Meta);
    let gridOptions = {
      height: $(window).innerHeight() - 300,
      columnAutoWidth: true,
      pager: {
        showInfo: true,
        showPageSizeSelector: true,
        allowedPageSizes: [10, 15, 20, 30, 50, 100],
        showNavigationButtons: true,
        visible: true,
        infoText: pagingInfo
      }
    };
    mailing.ContactsGrid = new Grid(
      response,
      {
        GridContainer: $('#' + mailing.MailingContactsGridID),
        GridToolbar: mailing.Toolbar
      },
      gridOptions,
      mailing,
      e => {
        mailing.Toolbar.SelectionChanged(e);
      }
    );
    mailing.ContactsGrid.ContainerItemID = mailing.ItemID;
    mailing.ContactsGrid.Render();
    if (mailing.Toolbar) {
      mailing.Toolbar.CurrentGrid = mailing.ContactsGrid;
    }
  }
}
