import { Dialog } from "components/dialogs/dialog";
import { RelativeDateFilterControl } from "./relativedatefilter";
import { itemTypes } from "enums/enums";
import { LoadCompany, LoadUser } from "infrastructure/context";
import { FilterBuilderHelper } from "util/allutils";
import { TDFRequest } from "services/request";
import { Notification } from "components/dialogs/notification";
import { AddHandler } from "infrastructure/events/ui_events";
import { EventTypes, eventNameSpace } from "enums/webevents/enums";
import { FilterTarget } from "enums/rulesmanager/enums";
import { RulesRelativeDateFilterDataService } from "services/grid/relativedatefilter/rulesrelativedatefilterdataservice";
import { RulesRelativeDateFilterControl } from "./rulesrelativedatefiltercontrol";
import { IDateRangeSaveOptions } from "interfaces/grids/idaterangesaveoptions";

export class RulesManager {
    FilterBuilderCtrl: DevExpress.ui.dxFilterBuilder;
    DateFilterCtrlHelper: RelativeDateFilterControl;
    RulesDialog: Dialog;
    FilterTarget: FilterTarget;
    ItemType: itemTypes;
    UrlStub: string = '/FilterBuilder/';
    FilterBuilderCtrlID: string = 'filterBuilderCtrl';
    DateFilterListID: string = 'filterBuilderDateFilter'
    RulesDialogID: string = 'rulesManagerDialog';
    DFD: JQueryDeferred<void>;

    private _DateFilterVal: IDateRangeSaveOptions;

    private get DateFilterVal() {
        return this._DateFilterVal;
    }
    private set DateFilterVal(val: IDateRangeSaveOptions) {
        this._DateFilterVal = val;
    }

    constructor(filterTarget: FilterTarget) {
        let rm = this;

        rm.DFD = $.Deferred();

        $.when(
            LoadUser(),
            LoadCompany()
        ).done(() => {
            rm.FilterTarget = filterTarget;
            rm.RenderFilterDialog();
        });

    }

    private RenderFilterDialog() {
        let rm = this;

        rm.RenderToolbar().done((toolbar) => {

            let filterBuilderSection = $('<div />').addClass('col-md-6').css({
                'padding-bottom': '50px'
            }).append(
                $('<h3 />').text(`${rm.GetFilterBuilderTitle()} Filter`),
                $('<div />').css('background-color', 'inherit').attr('id', rm.FilterBuilderCtrlID)
            );

            let dateFilterSection = $('<div />').addClass('col-md-6').css({
                'padding-bottom': '50px',
            }).append(
                $('<h3 />').text('Relative Date Filter'),
                $('<div />').attr('id', rm.DateFilterListID)
            );

            let filterElement = $('<div />').append(
                toolbar.element(),
                $('<div />').addClass('row').append(filterBuilderSection, dateFilterSection)
            )

            filterBuilderSection.dxScrollView({ direction: 'both' });
            dateFilterSection.dxScrollView({ direction: 'both' });

            rm.RulesDialog = new Dialog({
                id: rm.RulesDialogID,
                title: `Edit ${rm.GetFilterBuilderTitle()}`,
                size: 'size-large',
                closable: true,
                body: filterElement,
                buttons: [
                    {
                        location: 'after',
                        toolbar: 'bottom',
                        widget: 'dxButton',
                        options: <DevExpress.ui.dxButtonOptions>{
                            text: 'Save',
                            type: 'success',
                            icon: 'save',
                            onClick: (e) => {
                                rm.SaveRules().done(() => {
                                    rm.DFD.resolve();
                                    rm.RulesDialog.close();
                                });
                            }
                        }
                    }, {
                        location: 'after',
                        toolbar: 'bottom',
                        widget: 'dxButton',
                        options: <DevExpress.ui.dxButtonOptions>{
                            text: 'Cancel',
                            type: 'danger',
                            icon: 'remove',
                            onClick: (e) => {
                                rm.RulesDialog.close();
                            }
                        }
                    }
                ]
            });

            rm.RulesDialog.open();
        });
    }

    private SaveRules(): JQueryPromise<void> {
        let rm = this;

        let d: JQueryDeferred<void> = $.Deferred();

        FilterBuilderHelper.getFilterText(rm.FilterBuilderCtrl.getFilterExpression(), [], rm.FilterBuilderCtrl.option("fields"), "filterBuilder").done((ftext) => {
            new TDFRequest({
                url: `${TDFRequest.ApiPath}/RulesManager/Filter`,
                type: 'POST',
                data: {
                    filterTarget: rm.FilterTarget,
                    itemType: rm.ItemType,
                    filter: {
                        BasicFilter: ftext,
                        WebSafeFilter: rm.FilterBuilderCtrl.getFilterExpression()
                    },
                    dateFilter: rm.DateFilterVal
                }
            }).MakeRequest().done((response) => {
                new Notification({
                    message: response ? 'Filter saved successfully' : 'Something went wrong. Filter not saved',
                    type: response ? 'success' : 'error',
                });

                if (response) {
                    d.resolve();
                } else {
                    d.reject();
                }
            });

        });

        return d.promise();
    }

    private RenderToolbar(): JQueryPromise<DevExpress.ui.dxToolbar> {
        let rm = this;

        let d: JQueryDeferred<DevExpress.ui.dxToolbar> = $.Deferred();

        rm.RenderItemTypeSelector().done((itemTypeSelector) => {

            let toolbar: DevExpress.ui.dxToolbar = $('<div />').css({
                'margin-bottom': '20px',
                'padding-bottom': '10px',
                'border-bottom-style': 'solid'
            }).dxToolbar({
                items: [
                    itemTypeSelector
                ]
            }).dxToolbar('instance');

            d.resolve(toolbar);
        });

        return d.promise();
    }

    private GetFilterData(itemType) {
        let rm = this;
        rm.ItemType = itemType;

        let d = $.Deferred();

        $('#' + rm.FilterBuilderCtrlID).empty();
        $('#' + rm.DateFilterListID).empty();

        $.when(
            new TDFRequest({
                url: `/RulesManager/FiltersForUser?itemtype=${itemType}`,
                type: 'GET'
            }).MakeRequest(),
            new TDFRequest({
                url: `/RulesManager/Filter?filterTarget=${rm.FilterTarget}&itemType=${itemType}`,
                type: 'GET'
            }).MakeRequest()
        ).done((filterFields, filterValue) => {

            filterFields = filterFields.map((a) => {
                if (a.lookup.dataSource) {
                    return a;
                } else {
                    return {
                        dataField: a.dataField,
                        dataType: a.dataType
                    }
                }
            });

            // let filterValues = ['contains', 'endsWith', 'isBlank', 'isNotBlank', 'notContains', 'startsWith', 'between'];

            // $.each(filterValue.WebSafeFilter, (k, v) => {
            //     if (filterValues.indexOf(v) !== -1) {
            //         filterValue.WebSafeFilter[k] = v.toLowerCase();
            //     }
            // })

            rm.RenderFilterBuilder(filterFields, filterValue);
            rm.RenderDateFilterList(filterValue.WebSafeFilters.DateFilter);

        });
    }

    private RenderFilterBuilder(filterFields: any, filterValue: any) {
        let rm = this;

        rm.FilterBuilderCtrl = $('#' + rm.FilterBuilderCtrlID).css('margin', '10px').dxFilterBuilder(<DevExpress.ui.dxFilterBuilderOptions>{
            fields: filterFields,
            value: filterValue.WebSafeFilters.Filter,
            allowHierarchicalFields: true,
        }).dxFilterBuilder('instance');
    }

    private RenderItemTypeSelector(): JQueryPromise<DevExpress.ui.dxToolbarItem> {
        let theFilterBuilder = this;

        let d: JQueryDeferred<DevExpress.ui.dxToolbarItem> = $.Deferred();

        new TDFRequest({
            url: `${TDFRequest.ApiPath}/RulesManager/ActiveItemTypes?filterTarget=${theFilterBuilder.FilterTarget}`,
            type: 'GET'
        }).MakeRequest().done((itemtypes) => {

            let ItemTypeSelector: DevExpress.ui.dxToolbarItem = {
                location: 'before',
                widget: 'dxSelectBox',
                options: <DevExpress.ui.dxSelectBoxOptions>{
                    dataSource: itemtypes,
                    displayExpr: 'DisplayName',
                    valueExpr: 'ItemType',
                    value: itemtypes[0].ItemType,
                    onValueChanged: (e) => {
                        theFilterBuilder.GetFilterData(e.value);
                    },
                    // onContentReady: (e) => {
                    //     e.component.option('value', itemtypes[0].ItemType);
                    // }
                }
            }

            theFilterBuilder.GetFilterData(itemtypes[0].ItemType);

            d.resolve(ItemTypeSelector);
        });

        return d.promise();
    }

    private RenderDateFilterList(dateFilter: any[]) {
        let rm = this;

        let DateFilterCtrlHelper = new RulesRelativeDateFilterControl(rm.FilterTarget, rm.ItemType);

        let form: DevExpress.ui.dxForm = $('#' + rm.DateFilterListID).css('margin', '10px').dxForm({
            colCount: 6,
            items: <DevExpress.ui.dxFormSimpleItem[]>[
                {
                    dataField: "Operation",
                    label: { visible: false },
                    editorType: "dxSelectBox",
                    helpText: "Select Operation",
                    editorOptions: DateFilterCtrlHelper._operationSelectorOptions,
                    colSpan: 2
                }, {
                    itemType: "empty",
                    colSpan: 4,
                }, {
                    editorType: "dxDropDownBox",
                    colSpan: 6,
                    editorOptions: DateFilterCtrlHelper.DropDownBoxOptions

                }, {
                    editorType: "dxList",
                    colSpan: 6,
                    editorOptions: <DevExpress.ui.dxListOptions>{
                        dataSource: dateFilter.map((a) => { return a.FilterDetails }),
                        allowItemDeleting: true,
                        onItemDeleted(e) {
                            let service = new RulesRelativeDateFilterDataService();
                            service.GetSettings(rm.FilterTarget, rm.ItemType).done((settings) => {
                                let index = -1;
                                let filter = settings.DateFilters.forEach((v, k) => {
                                    if (v.FilterDetails === e.itemData) {
                                        index = k;
                                    }
                                });
                                if (index !== -1) {
                                    settings.DateFilters.splice(index, 1)
                                }
                                let saveopts: IDateRangeSaveOptions = {
                                    CombinePattern: settings.CombinePattern,
                                    CombineType: settings.CombineType,
                                    DateFilters: settings.DateFilters
                                }
                                rm.DateFilterVal = saveopts;
                            })

                        },
                        onInitialized(e) {
                            AddHandler(EventTypes.CenterEventTypes.relativedatefilter, eventNameSpace.notify, e.element, (ev, d: IDateRangeSaveOptions) => {
                                let items = d.DateFilters.map((v, k) => { return v.FilterDetails });
                                e.component.option("items", items)
                                rm.DateFilterVal = d;
                            })

                            let service = new RulesRelativeDateFilterDataService();
                            service.GetSettings(rm.FilterTarget, rm.ItemType).done((settings) => {
                                let saveopts: IDateRangeSaveOptions = {
                                    CombinePattern: settings.CombinePattern,
                                    CombineType: settings.CombineType,
                                    DateFilters: settings.DateFilters
                                }
                                rm.DateFilterVal = saveopts;
                            })
                        }

                    }
                }
            ]
        }).dxForm('instance');
    }

    private GetFilterBuilderTitle() {
        let rm = this;

        switch (rm.FilterTarget) {
            case 0:
                return 'Auto-Favorites';

            case 1:
                return 'Auto-Suggestions';
        }
    }
}